<template>
  <div class="mb-4">
    <input-label
      :label="label"
      :required="required"
    />
    <div
      class="relative mt-1 rounded-md shadow-sm"
    >
      <div v-if="editor" class="p-1 mt-4 bg-gray-200 rounded-t-md">
        <button class="px-2 rounded-md " @click="editor.commands.toggleHeading({ level: 1 })" :class="{ 'bg-gray-400 text-white': editor.isActive('h1') }">
          Rubrik
        </button>

        <button class="px-2 rounded-md " @click="editor.chain().focus().toggleBold().run()" :class="{ 'bg-gray-400 text-white': editor.isActive('bold') }">
          <strong>B</strong>
        </button>
        <button class="px-2 rounded-md" @click="editor.chain().focus().toggleItalic().run()" :class="{ 'bg-gray-400 text-white': editor.isActive('italic') }">
          <em>i</em>
        </button>
        <button class="px-2 rounded-md" @click="setLink" :class="{ 'bg-gray-400 text-white': editor.isActive('link') }">
          <em>url</em>
        </button>
      </div>
      <editor-content
        class="p-2 bg-white rounded-b-md prosemirror-container"
        :editor="editor"
        :required="required"
      />
    </div>
    <input-error v-if="error" :error="error" />
  </div>
</template>
<script>
import { Editor, EditorContent} from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit'
import Heading from '@tiptap/extension-heading'
import Link from '@tiptap/extension-link'
import Collaboration from '@tiptap/extension-collaboration'
import * as Y from 'yjs'
import { WebrtcProvider } from 'y-webrtc'
import CollaborationCursor from '@tiptap/extension-collaboration-cursor'

export default {
  components: {
    EditorContent,
  },
  data: function(){
    return {
      inputClass: '',
      editor: null,
      documentID: this.documentName,
      userName: this.name,
      note: this.content,
      sessionID: this.id
    }
  },
  props: {
    content: String,
    error: String,
    label: String,
    placeholder: String,
    required: Boolean,
    name: String,
    documentName: String,
    id: Number
  },
  watch: {
    error: function(error){
      let classes = [];
      if( error !== '' ){
        classes.push('border-red-700')
      } else {
        classes.push('border-gray')
      }
      this.inputClass = classes.join(' ')
    }
  },
  methods: {
    setLink() {
      const previousUrl = this.editor.getAttributes('link').href
      const url = window.prompt('URL', previousUrl)

      // cancelled
      if (url === null) {
        return
      }

      // empty
      if (url === '') {
        this.editor
          .chain()
          .focus()
          .extendMarkRange('link')
          .unsetLink()
          .run()

        return
      }

      // update link
      this.editor
        .chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: url })
        .run()
    },
    resetEditor(){
      this.editor.destroy()
      this.editor = new Editor({
        content: this.note,
        extensions: [
          StarterKit,
          Link.configure({
            openOnClick: false,
          }),
          Heading.configure({
            levels: [1,2],
          }),
        ],
        onUpdate: () => {
          this.$emit('update:content', this.editor.getHTML())
        },
      })
    },
    postNotes() {
      axios.put( route('coaching.booking.updateNote', {booking: this.sessionID}) , {note: this.editor.getHTML()})
    }
  },
  mounted() {
      // A new Y document
    const ydoc = new Y.Doc()
    // Registered with a WebRTC provider
    const provider = new WebrtcProvider('"'+this.sessionID+'"', ydoc)

    this.editor = new Editor({
      content: this.content,
      required: true,
      extensions: [
        StarterKit.configure({
            history: false,
        }),
        Link.configure({
          openOnClick: false,
        }),
        Heading.configure({
          levels: [1,2],
        }),
        Collaboration.configure({
            document: ydoc,
        }),
        CollaborationCursor.configure({
            provider,
            user: {
                name: this.userName,
                color: '#b2b26e',
            },
        }),
      ],
      onUpdate: () => {
        this.$emit('update:content', this.editor.getHTML())
      },
    }),

    window.setInterval(() => {
      this.postNotes()
    }, 5000)
  },
  beforeUnmount() {
    this.editor.destroy()
  },
}
</script>
<style>
  .prosemirror-container {
    overflow: scroll;
  }

  .ProseMirror {
    min-height: 4em;
    margin-bottom: 1em;
    transition: all 0.25s;
  }

  .ProseMirror {
    min-height: 20em;
  }

  .ProseMirror p {
    margin-bottom: 0.95em;
  }

  .ProseMirror a {
    text-decoration: underline;
  }

  .ProseMirror-focused {
    outline: none;
  }
  /* Give a remote user a caret */
  .collaboration-cursor__caret {
    position: relative;
    margin-left: -1px;
    margin-right: -1px;
    border-left: 1px solid #0D0D0D;
    border-right: 1px solid #0D0D0D;
    word-break: normal;
    pointer-events: none;
  }

    /* Render the username above the caret */
  .collaboration-cursor__label {
    position: absolute;
    top: -1.4em;
    left: -1px;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    user-select: none;
    color: #0D0D0D;
    padding: 0.1rem 0.3rem;
    border-radius: 3px 3px 3px 0;
    white-space: nowrap;
  }
</style>