<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div>
    <ul role="list" class="divide-y divide-gray-200">
      <li v-for="action in actions" :key="action.id">
        <a v-bind:href="'/crm/leads/' + action.lead_rel.id" class="block hover:bg-gray-50">
          <div class="px-4 py-4 sm:px-6">
            <div class="flex items-center justify-between">
              <p class="text-sm font-medium text-myflowPurple-600 truncate">
                {{ action.action }} - {{action.lead_rel.name}}
              </p>
              <!-- @TODO - ADD RELATIONSHIP TO CHECK FOR ACTIVE COMPANY -->
              <!-- type: 'Aktiv kund',
              typeClass: 'bg-myflowGreen-100 text-myflowGreen-800', -->
              <div class="ml-2 flex-shrink-0 flex">
                <p
                  class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full"
                  :class="action.typeClass"
                >
                  {{ action.type }}
                </p>
              </div>
              <!-- @TODO END - ADD RELATIONSHIP TO CHECK FOR ACTIVE COMPANY -->
            </div>
            <div class="mt-2 sm:flex sm:justify-between">
              <div class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                <CalendarIcon
                  class="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                  v-bind:class="{'text-myflowRed-400': overdue(action.date)}"
                />
                <p>
                  <time :datetime="action.date">{{ action.date }}</time>
                </p>
              </div>
            </div>
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import {
  CalendarIcon,
  MailIcon,
  PhoneOutgoingIcon
} from '@heroicons/vue/solid'

import axios from 'axios';

export default {
  components: {
    CalendarIcon,
    MailIcon,
    PhoneOutgoingIcon,
  },
  data() {
    return {
      actions: [],
    }
  },
  async mounted() {
    this.getActions();
  },
  methods: {
    async getActions(){
      const res = await axios.get('/crm/deals/next')
      this.actions = res.data;
    },
    overdue(date) {
      date = new Date(date);
      if(date.getTime() < Date.now()) {
        return true;
      } else {
        return false;
      }
    },
  }
}
</script>
