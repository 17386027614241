<script setup>
import {computed} from "vue";
const props = defineProps({
    content: {
        type: Object,
        required: false,
        default: {
            text: ''
        }
    }
})

const loomId = computed(() => {
    if (!props.content.text) {
        return null;
    }
    if( props.content.text !== '[loom]' ) {
        return props.content.text.split('/').pop();
    } else if (window.location.search.includes('loom')) {
        return window.location.search?.split('loom=')[1];
    } else {
        return null;
    }
});

</script>
<template>
    <div class="max-w-4xl mx-auto my-6 overflow-hidden rounded-custom shadow-custom">
        <div v-if="props.content.text">
            <div
                v-if="loomId"
                style="position: relative; padding-bottom: 56.25%; height: 0;"
            >
                <iframe
                    :src="'https://www.loom.com/embed/' + loomId"
                    frameborder="0"
                    webkitallowfullscreen
                    mozallowfullscreen
                    allowfullscreen
                    style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"
                ></iframe>
            </div>
            <div
                v-else
                class="p-4 text-center"
            >
                <p class="mb-1">😵‍💫 {{ _mft('pagebuilderView:loom.error.title') }}</p>
                <p class="text-gray-400 text-xs">{{ _mft('pagebuilderView:loom.error.text') }}</p>
            </div>
        </div>
    </div>
</template>
