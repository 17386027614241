<template>
    <div>
        <p v-if="success">
            <slot name="success"></slot>
        </p>
        <template v-else-if="requesting">
            <mf-spinner-small class="spinner"></mf-spinner-small>
            <div v-if="failed">
                <h5>{{ _mft('error:error.wantToTryAgain') }}</h5>

                <button class="mr-2 myflow-basic-button" size="small" @click.prevent="request">{{ _mft('shared:confirm.positive') }}</button>
                <button class="myflow-basic-button--secondary" size="small" @click.prevent="cancel">{{ _mft('shared:confirm.negative') }}</button>
            </div>
        </template>
        <button v-else class="myflow-basic-button myflow-basic-button--primary" :class="buttonClass" @click.prevent="request">
            <slot></slot>
        </button>
    </div>
</template>

<style lang="scss" scoped>
div {
    min-height: 66px;

    .spinner {
        margin: 0.5rem;
    }
}
</style>

<script>
import axios from 'axios';

export default {
    props: {
        requestUrl: { type: String, required: true },
        requestData: { type: Object, default: {} },
        buttonClass: { type: String, default: "" }
    },
    data() {
        return {
            requesting: false,
            success: false,
            failed: false
        };
    },
    methods: {
        async request() {
            this.requesting = true;
            this.failed = false;
            this.$emit('request');

            let resp = null;
            try {
                resp = await axios.post(this.requestUrl, this.requestData);

                this.success = true;
                this.$emit('success', { responseData: resp.data });
            } catch (error) {
                this.failed = true;
                this.$emit('failure', { response: resp });
            }
        },
        cancel() {
            this.requesting = false;
            this.failed = false;
            this.$emit('cancel');
        }
    },
};
</script>
