<script setup>

import {computed, useSlots} from "vue";

const props = defineProps({
    listClass: {
        type: String,
        required: false,
        default: 'mt-2 bg-white shadow-xl rounded-wl',
    }
})

const slots = useSlots();

const childCount = computed(() => {
    return slots.default().length;
})

</script>

<template>
    <div :class="listClass">
        <div
            v-if="slots.header"
            class="px-4 pt-3 pb-1 md:px-6"
        >
            <slot name="header" />
        </div>
        <div
            v-if="slots.status"
            class="pt-6 pb-4 text-center text-gray-500"
        >
            <slot name="noitems" />
        </div>
        <ul
            class="divide-y divide-gray-200"
        >
            <slot/>
        </ul>
        <div
            v-if="slots.footer"
            class="px-4 pt-1 pb-4 border-t md:px-6"
        >
            <slot name="footer" />
        </div>
    </div>
</template>
