<script setup>
import { ref } from "vue";

import IconUser from "../../icons/iconUser.vue";
import IconChevron from "../../icons/iconChevron.vue";
import ButtonTransparent from "../../buttons/buttonTransparent.vue";

const props = defineProps({
    accountCreationEnabled: {
        type: Boolean,
        required: false,
        default: false
    },
    userIsLoggedIn: {
        type: Boolean,
        required: false,
        default: false
    }
})

const showPanel = ref(false);

const logout = async () => {
    const res = await axios.post(route('logout'))
    if (res.status === 204) {
        window.location.href = route('login');
    }
};

</script>

<template>
    <div>
        <div v-if="userIsLoggedIn"
            class="relative flex flex-col items-end justify-center"
        >
            <a
                title="Gå till dashboard"
                :href="route('boIndex')"
                class="flex items-center justify-center px-4 py-2 text-xs text-center text-wlPrimary hover:text-wlPrimary-light"
            >
                <span>{{ _mft('navigation:customerArea.dashboard') }}</span>
                <IconChevron
                    class="w-4 h-4 -rotate-90 fill-wlPrimary hover:fill-wlPrimary-light"
                />
            </a>
            <button
                @click="showPanel = !showPanel"
                class="flex items-center px-4 py-0.5 space-x-2 group"
            >
<!--                <span class="flex items-center justify-center w-6 h-6 p-2 text-xs rounded-full md:h-5 md:w-5 bg-wlPrimary text-wlPrimaryContrast group-hover:bg-wlPrimary-light group-hover:text-wlPrimaryContrast-light">-->
<!--                    4-->
<!--                </span>-->
                <icon-user class="w-4 h-4 text-wlPrimary group-hover:text-wlPrimary-light" />
                <icon-chevron
                    class="w-4 h-4 transition-all"
                    :class="showPanel ? 'rotate-180 fill-wlPrimary' : 'group-hover:fill-wlPrimary-light fill-wlPrimary'"
                />
            </button>
            <div
                class="absolute transform translate-y-2 top-full -right-2 z-topbar"
                :class="showPanel ? 'h-auto' : 'h-0 overflow-hidden'"
            >
                <ul
                    class="max-w-xs pt-2 space-y-3 transition-all transform bg-white shadow-md rounded-b-wl min-w-48 md:text-xs"
                    :class="showPanel ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-4'"
                >
<!--                    <li class="px-3 mt-2">2 olästa meddelanden</li>-->
                    <li class="px-5 mt-2">
                        <a :href="route('user.coaching')" class="text-inherit">
                            {{ _mft('navigation:customerArea.coaching') }}
                        </a>
                    </li>
                    <li class="px-5 mt-2">
                        <a :href="route('user.courses')" class="text-inherit">
                            {{ _mft('navigation:customerArea.courses') }}
                        </a>
                    </li>
                    <li class="px-5 mt-2">
                        <a :href="route('user.communities')" class="text-inherit">
                            {{ _mft('navigation:customerArea.communities') }}
                        </a>
                    </li>
                    <li class="px-5 mt-2">
                        <a :href="route('user.purchases')" class="text-inherit">
                            {{ _mft('navigation:customerArea.purchases') }}
                        </a>
                    </li>
                    <li class="p-3 mt-2 border-t">
                        <ul class="flex justify-between">
                            <li>
                                <a :href="route('user.edit')" class="text-inherit">
                                    <button-transparent
                                        size="sm"
                                    >
                                        {{ _mft('navigation:customerArea.profile') }}
                                    </button-transparent>
                                </a>
                            </li>
                            <li>
                                <a :href="route('user.logout')" :title="_mft('navigation:customerArea.logOut')">
                                    <button-transparent
                                        size="sm"
                                    >
                                        {{ _mft('navigation:customerArea.logOut') }}
                                    </button-transparent>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
        <div v-else>
            <a :href="route('login')">
                <button-primary size="sm">
                    <span class="whitespace-nowrap">
                        {{ _mft('navigation:customerArea.logIn') }}
                    </span>
                </button-primary>
            </a>
        </div>
    </div>
</template>

<style scoped>

</style>
