<template>
    <div>
        <notification
            :show="showNotification"
            @close="closeNotification()"
        >
            <template v-slot:header>
                {{ notification.message }}
            </template>
        </notification>

        <div>
            <div class="">
                <input-select
                    :label="_mft('crm:leadBookingForm.selectProductFor')"
                    :options="products"
                    labelSpecification="title"
                    valueSpecification="id"
                    v-model:content="selectedProduct"
                />
            </div>
            <div
                class="grid grid-cols-2 gap-4"
                :class="[ selectedProduct ? '' : 'hidden']"
            >
                <input-number
                    :label="_mft('crm:leadBookingForm.sessionsToAddTotal')"
                    :disabled="true"
                    v-model:content="selectedTotalQuantity"
                />
                <input-number
                    :label="_mft('crm:leadBookingForm.sessionsConsumed')"
                    v-model:content="selectedSpentQuantity"
                />
            </div>
            <div class="text-right">
                <button
                    class="myflow-basic-button--danger mr-2"
                    v-on:click="$emit('cancel')"
                >{{ _mft('shared:cancel') }}</button>
                <button
                    class="myflow-basic-button--success"
                    v-on:click="submit()"
                    :disabled="!inputValidates"
                >{{ _mft('shared:add') }}</button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        lead: Object,
    },
    data: function(){
        return {
            products: null,
            open: false,
            showNotification: false,
            selectedProduct: null,
            selectedTotalQuantity: 0,
            selectedSpentQuantity: 0,
            notification: {
                message: null
            }
        }
    },
    methods: {
        async loadProducts(){
            const response = await axios.get(route('coaching.products'));
            this.products = response.data.products;
        },
        closeNotification(){
            this.notification.message = ''
        },
        async submit(){
            let res = await axios.post(route('crm.lead.createBookingSessions'), {
                lead_id: this.lead.id,
                product_id: this.selectedProduct,
                total_quantity: this.selectedTotalQuantity,
                spent_quantity: this.selectedSpentQuantity
            });

            this.notification.message = this.selectedTotalQuantity - this.selectedSpentQuantity + ' '+ _mft('crm:leadBookingForm.bookableSessionsAddedFor') +' ' + this.lead.name;
            this.showNotification = true;
            this.resetForm();
        },
        resetForm(){
            this.selectedTotalQuantity = 0;
            this.selectedSpentQuantity = 0;
            this.selectedProduct = false;
        }
    },
    mounted: function(){
        this.loadProducts();
    },
    computed: {
        inputValidates(){
            return this.selectedTotalQuantity > 0 && this.selectedProduct && (this.selectedTotalQuantity - this.selectedSpentQuantity > 0);
        }
    },
    watch: {
        selectedProduct(val){
            this.products.forEach(product => {
                if( product.id == val ){
                    this.selectedTotalQuantity = product.session_quantity;
                }
            });
        },
        selectedSpentQuantity(val){
            if( val > this.selectedTotalQuantity ){
                let self = this
                setTimeout(function(){
                    self.selectedSpentQuantity = self.selectedTotalQuantity;
                }, 1);
            }
        }
    }
}
</script>
