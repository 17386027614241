<template>
  <div>
    <button-primary
      v-on:click="open = !open"
    >
      {{ callToAction.name }}
    </button-primary>
    <basic-slideout
      v-model:open="open"
    >
      <template v-slot:header>
        <h1>{{ callToAction.heading }}</h1>
      </template>

      <template v-slot:body>
        <component :is="callToAction.componentName" v-bind="callToAction.componentParams ?? {}" />
      </template>

    </basic-slideout>
  </div>
</template>
<script>
export default {
  setup() {

  },
  props: {
    callToAction: Object
  },
  data: function(){
    return {
      open: false,
    }
  }
}
</script>
