<template>
  <div class="relative overflow-hidden">
    <Teleport
      v-if="trailerVideoUrl"
      to="#trailer-player-teleport-target">
      <trailer-video
        :trailerVideoUrl="trailerVideoUrl"
        @close="closeTrailer()"
      />
    </Teleport>
    <div
      class="absolute z-30 flex flex-col justify-between transition-all duration-300 delay-75 bg-white shadow-md rounded-2xl inset-2 faded-top faded-bottom"
      :class="[previewing ? 'top-2 bottom-2' : 'top-full -bottom-full' ]"
    >
      <div class="absolute pt-6 pb-6 overflow-y-scroll text-xs inset-x-4 top-2 bottom-2 md:text-sm" v-html="coach.description">

      </div>
      <div class="absolute flex justify-center -bottom-2 left-4 right-4">
        <button
          class="relative z-10 p-1 transition transform rotate-45 rounded-full shadow-md text-myflowPurple ring-2 ring-white"
          :class="cardButtonClass"
          @click="toggleDescription"
        >
          <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
          </svg>
        </button>
      </div>
    </div>

    <div
      class="flex flex-col justify-between h-full mb-4 overflow-hidden transition-transform shadow-lg rounded-2xl bg-myflowPurple bg-gradient-to-r from-myflowPurple-600 to-myflowPurple-900"
      :class="[previewing ? 'scale-90' : '']"
    >
      <div
        class="flex flex-col h-full"
      >
        <div
          class="relative w-full bg-top bg-cover"
          style="padding-bottom: 100%;"
          :style="{ backgroundImage: 'url(' + coach.profile_img_url + ')' }"
          v-on:click.self="redirect(route('coaching.show', coach.id))"
        >
          <div
            class="absolute inset-0 flex items-center justify-center"
            v-if="coach.trailer_video"
          >
            <Playbutton
              class="w-1/3 h-auto text-white transition-all opacity-50 cursor-pointer hover:scale-105 hover:opacity-90"
              v-on:click="openTrailer()"
            />
          </div>
        </div>
        <div
          class="relative h-full px-4 pt-2 pb-2 m-3 -mt-4 transition-all bg-white shadow-md rounded-2xl"
        >
          <div class="pt-2 pb-2 bg-white rounded-t-2xl">
            <div class="relative">
              <h2 class="mb-1 text-base leading-6 xl:text-lg text-myflowPurple-600">
                <a :href="route('coaching.show', coach.id)">
                  {{ coach.name }}
                </a>
              </h2>
              <p class="mb-0 text-xs font-bold text-gray-600 break-words xl:text-sm" v-if="coach.title">{{ coach.title }}</p>
            </div>
          </div>
          <!-- <div
            href="#"
            class="block overflow-hidden transition-all"
            :class="[previewing ? 'max-h-screen' : 'max-h-0']"
            v-html="coach.description"
          >
          </div> -->
          <!-- <button
            class="absolute bottom-0 p-1 text-white transition transform -translate-x-1/2 translate-y-1/2 rounded-full shadow-md left-1/2 bg-myflowPurple-900 ring-2 ring-white"
            :class="[previewing ? 'rotate-45' : 'rotate-0' ]"
            @click="toggleDescription"
          >
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
            </svg>
          </button> -->

          <button
            class="absolute bottom-0 p-1 transition transform -translate-x-1/2 translate-y-1/2 rounded-full text-myflowPurple left-1/2 ring-2 ring-white"
            :class="cardButtonClass"
            @click="toggleDescription"
          >
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
            </svg>
          </button>

        </div>
      </div>
      <div class="flex items-center justify-between px-4 pb-0 text-white bg-myflowPurple bg-gradient-to-r from-myflowPurple-600 to-myflowPurple-900">
        <div class="flex justify-center w-full">
            <a
                class="flex items-center w-full py-1 text-xs text-white h-9"
                :class="availableProductCount > 0 ? 'justify-between lg:justify-center lg:-mr-6' : 'justify-center'"
                :href="route('coaching.show', coach.id)"
                :title="_mft('shared:show') + ' ' + coach.name"
            >
                <span>{{ availableProductCountString }}</span>
                <svg
                    v-if="availableProductCount > 0"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    class="w-6 py-1 ml-2 text-white cursor-pointer h-9"
                >
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                </svg>
            </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Playbutton from '../icons/playbutton.vue'

export default {
  components: {
    Playbutton
  },
  props: {
    coach: Object,
    coachProfileImgUrl: String,
    availableProductCount: {
      type: Number,
      default: 0
    }
  },
  data: function(){
    return {
      previewing: false,
      profileImg: this.coachProfileImgUrl,
      trailerVideoUrl: null
    }
  },
  methods: {
    toggleDescription() {
      this.previewing = !this.previewing
    },
    closeTrailer() {
      this.trailerVideoUrl = null
    },
    async openTrailer() {
      this.trailerVideoUrl = route('home', this.coach.id);
    },
    redirect(route) {
      this.$document.locationAssign(route);
    }
  },
  mounted() {
  },
  computed: {
    fill: function(){
      if( this.favorited ){
        return 'currentColor'
      } else {
        return 'none'
      }
    },
    availableProductCountString: function(){
      if( this.availableProductCount === 0 ){
        return _mft('coaching:offer.noOffersRightNow')
      } else if (this.availableProductCount === 1) {
        return this.availableProductCount + ' ' + _mft('coaching:offer.domainNameSingular')
      } else {
        return this.availableProductCount + ' ' + _mft('coaching:offer.domainNamePlural')
      }
    },
    cardButtonClass: function(){
      return 'bg-white shadow-cardbutton'
    }
  }
}
</script>
<style scoped>
  .h-min {
    height: min-coach;
  }
  .shadow-cardbutton {
    box-shadow: 0px 0px 12px rgba(0,0,0,0.15);
  }
</style>
