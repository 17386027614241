<script setup>
    import { computed, ref } from 'vue'

    const props = defineProps({
        cronofyRedirectUri: {
            type: String,
            required: true,
        },
        cronofyClientId: {
            type: String,
            required: true,
        },
        cronofyEmbedToken: {
            type: String,
            required: true,
        },
        cronofyNonce: {
            type: String,
            required: true,
        },
        timezone: {
            type: String,
            required: true,
        },
        defaultCalendarSelected: {
            type: Boolean,
            required: false,
            default: false
        }
    })

    const currentPanel = ref('calendarIntegration')
    const defaultCalendarSelected = ref(props.defaultCalendarSelected)

    const setSection = (section) => {
        currentPanel.value = section.name
    }

    const onDefaultCalendarSelected = (calendarId) => {
        defaultCalendarSelected.value = true
    }

    const navigationOptions = computed(() => {
        const options = [
            {
                label: _mft('expertProfile:manageAvailability.calendarIntegration'),
                name: 'calendarIntegration',
            }
        ]

        if (defaultCalendarSelected.value === true) {
            options.push({
                label: _mft('expertProfile:manageAvailability.availability'),
                name: 'availability',
            })
        }

        return options
    })

    const highlightManageDefaultCalendar = computed(() => {
        return props.cronofyClientId !== '' && defaultCalendarSelected.value === false
    })

</script>
<template>
    <div>
        <button-bar
            :options="navigationOptions"
            @select="setSection"
        />

        <div :class="currentPanel === 'calendarIntegration' ? '' : 'hidden'">
            <cronofy-manage-integration
                :cronofy-client-id="props.cronofyClientId"
                :cronofy-redirect-uri="props.cronofyRedirectUri"
                :cronofy-embed-token="props.cronofyEmbedToken"
                :cronofy-nonce="props.cronofyNonce"
                class="mb-12"
            ></cronofy-manage-integration>

            <div
                v-if="props.cronofyEmbedToken"
                :class="highlightManageDefaultCalendar ? 'fixed inset-0 flex justify-center items-center z-50 bg-white/90' : ''"
            >
                <card-basic class="z-60">
                    <template v-slot:body>
                        <cronofy-manage-default-calendar
                            :cronofy-embed-token="props.cronofyEmbedToken"
                            @defaultCalendarSelected="onDefaultCalendarSelected"
                            class="mb-12"
                        ></cronofy-manage-default-calendar>
                    </template>
                </card-basic>
            </div>
        </div>

        <div :class="currentPanel === 'availability' ? '' : 'hidden'">
            <cronofy-manage-availability
                v-if="defaultCalendarSelected"
                :cronofy-embed-token="props.cronofyEmbedToken"
                :timezone="props.timezone"
            ></cronofy-manage-availability>
            <p v-else>{{ _mft('expertProfile:manageAvailability.requirementsNotification') }}</p>
        </div>

    </div>
</template>
