<template>
    <div>
        <h5>Last updated agreements</h5>

        <div>
            <div class="py-2" v-for="contract in contracts.data" :key="contract.id">
                <span><b>{{ contract.title }}</b></span><br>
                <small><b>Last updated at:</b> {{ contract.updated_at }}</small><br>

                <span>{{ contract.signed }} / {{ contract.signature_requests }} signed</span>
            </div>
            <a href="{{ route('expert-contracts.dashboard') }}" class="underline">View all</a>
        </div>
    </div>
</template>

<script setup>
    import { ref, onMounted } from 'vue';
    import axios from 'axios';

    const contracts = ref([]);

    const fetchContracts = async () => {
        try {
            const response = await axios.get(route('boIndex.contractStatus'));
            contracts.value = response.data;
        } catch (error) {
            console.error('Error fetching contracts:', error);
        }
    };

    onMounted(fetchContracts);
</script>
