<template>
    <div class="mb-4">
        <input-label
            :label="label"
            :required="required"
        />
        <div
            class="relative mt-1 rounded-md shadow-sm"
        >
            <input
                :type="inputtype"
                class="block w-full p-2 mt-1 border rounded-md"
                :class="[inputClasses, typeClass, inputTextColor, showClear ? 'pr-10' : '']"
                :placeholder="placeholder"
                :value="content"
                @input="onInput"
                @blur="onBlur"
                @focus="onFocus"
                :required="required"
                :disabled="disabled"
                :name="name"
                :pattern="pattern"
                :maxlength="maxlength ?? 255"
                ref="focusableinput"
                :autofocus="autofocus"
            />
            <span
                v-if="showClear && content"
                class="absolute top-1 right-0"
            >
                <button-base
                    style-type="transparent"
                    size="sm"
                    @click="clearInput"
                >
                    <i class="fa-solid fa-circle-xmark text-gray-500"></i>
                </button-base>
            </span>
        </div>
        <input-error v-if="error" :error="error"/>

    </div>
</template>
<script>
import {nextTick} from "vue";

export default {
    setup() {

    },
    expose: ['focusInput'],
    data: function () {
        return {
            inputClasses: this.inputClass
        }
    },
    props: {
        content: String,
        error: {type: String, default: ''},
        label: String,
        placeholder: String,
        pattern: {type: String, default: null},
        required: Boolean,
        name: String,
        disabled: {type: Boolean, default: false},
        inputtype: {type: String, default: 'text'},
        maxlength: {type: String, required: false},
        minlength: {type: Number, required: false},
        autofocus: {type: Boolean, default: false},
        inputTextColor: {type: String, default: 'text-gray-900'},
        inputClass: {type: String, default: ''},
        showClear: {type: Boolean, default: false},
    },
    methods: {
        focusInput() {
            this.$refs.focusableinput.focus();
        },
        onInput(event) {
            this.$emit('update:content', event.target.value)
        },
        onBlur(event) {
            this.$emit('blur');
            if (this.required && this.content && this.content.length > 0) {
                this.$emit('validatesAs', 'required');
                return
            }

            if (this.minlength && this.content.length > this.minlength) {
                this.$emit('validatesAs', 'minlength');
            }
        },
        onFocus(event) {
            this.$emit('focus');
        },
        onChange(event) {
            const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
            const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

            if (this.inputtype === 'password') {
                if (this.content.length > 7 && passwordPattern.test(this.content)) {
                    this.$emit('validatesAs', 'password');
                }
                return
            }

            if (this.inputtype === 'email') {
                if (emailPattern.test(this.content)) {
                    this.$emit('validatesAs', 'email');
                }
            }
        },
        clearInput() {
            this.$emit('update:content', '');
        }
    },
    computed: {
        typeClass() {
            if (this.inputtype !== 'text') {
                return 'pr-10';
            }
        }
    },
    watch: {
        content: function() {
            this.$emit('clearValidates');
            const self = this
            nextTick(function () {
                self.onChange();
            })
        },
        error: function (error) {
            let classes = [];
            if (error !== '') {
                classes.push('border-red-700')
            } else {
                classes.push('border-gray')
            }
            this.inputClasses = classes.join(' ')
        }
    },
    mounted() {
        this.onChange()
        this.onBlur()

        if (this.autofocus) {
            this.focusInput()
        }
    }
}
</script>
