/*
Basic usage:

Get a String response from the server/cache
_mfProp('company:default-currency').then(data => {
    currency.value = data
})

Forget the cache
_mfPropForget('company:default-currency')

 */

import axios from "axios";

export async function _mfProp(key) {
    const [type, check] = key.split(':');

    const res = await axios.get(route('mf.prop', [type, check]));
    return res.data
}

export async function _mfPropForget(key) {
    const [type, check] = key.split(':');
    const res = await axios.get(route('mf.prop.forget', [type, check]));
    return res.data
}
