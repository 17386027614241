<template>
    <div>
        <h2 class="p-5 pb-2 mb-1">{{ _mft('subscription:filter.title') }}</h2>
        <ul class="flex rounded-full mb-6 lg:mb-8">
            <li
                class="px-4 lg:px-8 first:rounded-l-lg lg:first:rounded-l-full lg:first:pl-10 last:rounded-r-lg lg:last:rounded-r-full lg:last:pr-10 shadow-md py-1 lg:py-1 border-r last:border-r-0 cursor-pointer"
                :class="appliedFilters.includes('video') ? 'bg-gradient-to-b from-myflowPurple-500 to-myflowPurple-700 text-white' : 'text-myflowPurple bg-white'"
                v-on:click="toggleFilter('video')"
            >
                <div class="relative text-center transition-all">
                    <span class="block w-6 h-6 lg:w-6 lg:h-6 mx-auto">
                        <PlayIcon class="w-4 h-4 fill-current" />
                    </span>
                    <span class="block text-center text-xs">{{ _mft('subscription:filter.video') }}</span>
                </div>
            </li>
            <li
                class="px-4 lg:px-8 first:rounded-l-lg lg:first:rounded-l-full lg:first:pl-10 last:rounded-r-lg lg:last:rounded-r-full lg:last:pr-10 shadow-md py-1 lg:py-1 border-r last:border-r-0 cursor-pointer"
                :class="appliedFilters.includes('download') ? 'bg-gradient-to-b from-myflowPurple-500 to-myflowPurple-700 text-white' : 'text-myflowPurple bg-white'"
                v-on:click="toggleFilter('download')"
            >
                <div class="relative text-center transition-all">
                    <span class="block w-6 h-6 lg:w-6 lg:h-6 mx-auto">
                        <DownloadIcon class="w-4 h-4 fill-current" />
                    </span>
                    <span class="block text-center text-xs">{{ _mft('subscription:filter.download') }}</span>
                </div>
            </li>
            <li
                class="px-4 lg:px-8 first:rounded-l-lg lg:first:rounded-l-full lg:first:pl-10 last:rounded-r-lg lg:last:rounded-r-full lg:last:pr-10 shadow-md py-1 lg:py-1 border-r last:border-r-0 cursor-pointer"
                :class="appliedFilters.includes('livestream') ? 'bg-gradient-to-b from-myflowPurple-500 to-myflowPurple-700 text-white' : 'text-myflowPurple bg-white'"
                v-on:click="toggleFilter('livestream')"
            >
                <div class="relative text-center transition-all">
                    <span class="block w-6 h-6 lg:w-6 lg:h-6 mx-auto">
                        <VideoCameraIcon class="w-4 h-4 fill-current" />
                    </span>
                    <span class="block text-center text-xs">{{ _mft('subscription:filter.livestream') }}</span>
                </div>
            </li>
        </ul>

        <div
            class="min-h-full"
            :class="contents.length === 0 ? 'flex flex-col justify-center items-center' : ''"
        >
            <p
                class="text-center"
                v-if="contents.length === 0 && !loading"
            >
                {{ _mft('subscription:noContentAvailableFor', {subscriptionName: subscription.subscribable.title}) }}
            </p>
            <div class="mb-8 md:mb-16">
                <div
                    class="relative pr-6 ml-px bg-gray-400 rounded-card"
                    v-for="(content, i) in stickyContents"
                    v-bind:key="i"
                >
                    <subscription-feed-item
                        :content="content"
                        :subscription="subscription"
                    />
                    <div class="absolute top-0 right-0 flex items-center w-6 h-6 text-gray-200">
                        <svg class="fill-current" viewBox="-7.5 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.52 12.72l-4.84-4.84c-0.16-0.16-0.44-0.28-0.68-0.24s-0.48 0.16-0.6 0.36l-3.52 5.16-3.72 0.84c-0.28 0.080-0.52 0.28-0.64 0.6-0.080 0.28 0 0.6 0.2 0.84l2.52 2.52-5 5c-0.32 0.32-0.32 0.84 0 1.2 0.16 0.16 0.36 0.24 0.6 0.24s0.44-0.080 0.6-0.24l5.040-5.040 2.52 2.52c0.16 0.16 0.36 0.24 0.6 0.24 0.080 0 0.16 0 0.24-0.040 0.28-0.080 0.52-0.32 0.6-0.64l0.84-3.72 5.16-3.48c0.2-0.16 0.32-0.36 0.36-0.6-0.040-0.28-0.080-0.52-0.28-0.68zM10.080 16.32c-0.16 0.12-0.28 0.32-0.36 0.52l-0.6 2.56-4.12-4.12 2.56-0.56c0.2-0.040 0.4-0.16 0.52-0.36l3.12-4.56 3.4 3.4-4.52 3.12z"></path>
                        </svg>
                    </div>
                </div>
            </div>
            <div>
                <subscription-feed-item
                    v-for="(content, i) in timelineContents"
                    v-bind:key="i"
                    :content="content"
                    :subscription="subscription"
                />
            </div>
            <div
                class="flex justify-center"
            >
                <button
                    class="myflow-basic-button myflow-basic-button--primary mb-8"
                    v-if="(pagesAvailable && !loading)"
                    v-on:click="loadMore"
                >{{ _mft('shared:pagination.loadMore') }}</button>
                <p
                    class="mb-6 italic text-center text-gray-500"
                    v-if="loading"
                >{{ _mft('shared:pagination.loading') }}</p>
                <p
                    class="mb-6 italic text-center text-gray-500"
                    v-if="!pagesAvailable && !loading && pageCount > 0"
                >{{ _mft('shared:pagination.noMoreAvailable') }}</p>
            </div>
        </div>
    </div>
</template>
<script>
import { PlayIcon, DownloadIcon, VideoCameraIcon } from '@heroicons/vue/solid';

export default {
    name: 'SubscriptionFeed',

    components: {
        PlayIcon,
        DownloadIcon,
        VideoCameraIcon
    },

    props: {
        subscription: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            contents: [],
            stickyContents: [],
            loading: false,
            page: 1,
            pageCount: 0,
            pagesAvailable: true,
            availableFilters: ['video', 'download', 'livestream'],
            appliedFilters: []
        }
    },

    methods: {
        async getFeed() {
            this.loading = true;
            let res = await axios.get(route('subscription.feed', [this.subscription.id, this.page]), {params: {filters: this.appliedFilters.join(',')}});
            this.contents.push(...res.data.contents);
            if( this.stickyContents.length === 0 ) {
                this.stickyContents.push(...res.data.sticky_contents);
            }

            this.pageCount = res.data.pages_available;
            if( res.data.pages_available === this.page || res.data.pages_available === 0 ) {
                this.pagesAvailable = false;
            }
            this.loading = false;
        },
        toggleFilter(filter) {
            if( this.appliedFilters.includes(filter) ) {
                this.appliedFilters = this.appliedFilters.filter(f => f !== filter);
            } else {
                this.appliedFilters.push(filter);
            }
            this.contents = [];
            this.stickyContents = [];
            this.page = 1;
            this.getFeed();
        },
        loadMore() {
            this.page++;
            this.getFeed();
        },
    },

    mounted() {
        this.getFeed();
    },
    computed: {
        timelineContents() {
            return this.contents;
        }
    }

}

</script>
