<template>
  <div>
    <coaching-booking
      v-for="(booking, i) in bookings"
      v-bind:key="i"
      :booking="booking"
    />
  </div>
</template>
<script>
export default {
  props: {
    bookings: Object,
  },
  data: function(){
    return {
    }
  },
  methods: {
  },
  mounted() {
  },
  computed: {
  }
}
</script>
<style scoped>
</style>