<script setup>
    import {ref} from 'vue'

    import {
        StarIcon,
        PlusIcon
    } from '@heroicons/vue/outline'

    const props = defineProps({
        item: {
            type: Object,
            required: true
        },
    })

    const isActive = ref(route().current(props.item.routeName));

</script>
<template>
    <li
        class="relative ml-3 translate-x-px first:mt-1"
    >
        <div
            v-if="props.item.current"
            class="rounded-wl bg-wlPrimary absolute left-0 top-1 bottom-1 z-40"
            style="width: 3px; margin-left: 4px;"
        ></div>
        <a
            :href="item.href"
            class="relative z-30 block py-1 text-xs hover:underline text-wlLink border-l pl-3.5 translate-x-px"
            style="margin-left: 4px;"
        >
            {{ _mft(item.name) }}
        </a>
    </li>
</template>
