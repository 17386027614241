<script setup>
import {computed} from "vue";

const props = defineProps({
    content: {
        type: Object,
        required: true,
    },
})

const title = computed(() => props.content.title);
const body = computed(() => props.content.body);
const links = computed(() => props.content.links);

</script>
<template>
    <div class="flex flex-row justify-center w-full align-center">
        <div class="flex flex-col justify-center w-auto max-w-3xl text-center">
            <h2 class="mb-2 text-xl md:text-2xl lg:text-3xl">{{title}}</h2>
            <div class="mb-2" v-html="body"></div>
            <div class="flex flex-col -mx-4 space-y-1 md:block space-x-2">
                <a
                    v-for="(link, idx) in links"
                    :key="idx"
                    :href="link.href"
                    class="inline-block cursor-pointer"
                    target="_blank"
                >
                    <button-primary>
                        {{link.text}}
                    </button-primary>
                </a>
            </div>
        </div>
    </div>
</template>

<style scoped>
.collapse-btn {
    padding: 0.8rem 1.5rem;
    height: fit-content;
    width: fit-content;
    font-weight: bold;
    cursor: pointer;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
</style>
