<script setup>

import {ref} from "vue";

const templatesIndexComponent = ref(null)

const reloadTemplates = () => {
    templatesIndexComponent.value.getTemplates()
}

</script>
<template>
    <div>
        <div class="flex justify-end">
            <story-lane-modal :module="'templates'"></story-lane-modal>
        </div>
        <template-invitations
            @reload="reloadTemplates"
            class="mb-24 xl:mt-8"
        />

        <templates-list
            ref="templatesIndexComponent"
        />
    </div>
</template>
