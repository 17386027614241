<script setup>

import {computed, onMounted, ref} from "vue";

const emit = defineEmits(['update:sendSchedule'])

const props = defineProps({
    sendSchedule: {
        type: Array,
        required: true
    }
})

const schedule = ref(props.sendSchedule)
const hoverModeEnabled = ref(false)
const hoverModeAdds = ref(true)

const days = 7;
const hours = 24
const scheduleCutoffMorning = ref(7);
const scheduleCutoffEvening = ref(18);

const toggleSlot = (day, hour) => {
    if (schedule.value.includes(day + ':' + hour)) {
        schedule.value = schedule.value.filter(slot => slot !== day + ':' + hour)
        hoverModeAdds.value = false
    } else {
        schedule.value.push(day + ':' + hour)
        hoverModeAdds.value = true
    }
    emit('update:sendSchedule', schedule.value)
}

const hoverToggleSlot = (day, hour) => {
    if (! hoverModeEnabled.value) { return; }
    if (schedule.value.includes(day + ':' + hour) && ! hoverModeAdds.value) {
        schedule.value = schedule.value.filter(slot => slot !== day + ':' + hour)
        return
    }
    if (hoverModeAdds.value) {
        schedule.value.push(day + ':' + hour)
    }
}

const noBeforeBusinessHoursScheduled = computed(() => {
    return schedule.value.filter(slot => parseInt(slot.split(':')[1]) < 7).length === 0
})

const noAfterBusinessHoursScheduled = computed(() => {
    return schedule.value.filter(slot => parseInt(slot.split(':')[1]) > 18).length === 0
})

const daysWithTimesSelected = computed(() => {
    return schedule.value.map(slot => parseInt(slot.split(':')[0]))
})

onMounted(() => {
})

</script>

<template>
    <div>
        <div
            @mousedown="hoverModeEnabled = true"
            @mouseup="hoverModeEnabled = false"
            @mouseleave="hoverModeEnabled = false"
        >
            <div>
                <input-label
                    class="mb-2"
                    :label="_mft('leadHunterCampaign:summary.emailSendSchedule.label')"
                />
                <div
                    class="grid grid-cols-9"
                >
                    <div class="col-span-2">
                        <div class="p-0.5">
                            <div
                                class="h-4 text-right text-xxs"
                            >
                                <div v-if="noBeforeBusinessHoursScheduled">
                                    <button
                                        v-if="scheduleCutoffMorning === 7"
                                        class="cursor-pointer select-none underline"
                                        @click="scheduleCutoffMorning = 0"
                                    >
                                        {{ _mft('leadHunterCampaign:schedule.earlier') }}
                                    </button>
                                    <button
                                        v-if="scheduleCutoffMorning === 0"
                                        class="cursor-pointer select-none underline"
                                        @click="scheduleCutoffMorning = 7"
                                    >
                                        {{ _mft('leadHunterCampaign:schedule.hide') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div
                            v-for="(hour, h) in hours"
                            :key="h"
                            class="overflow-hidden transition-all"
                            :class="h < scheduleCutoffMorning || h > scheduleCutoffEvening ? 'h-0' : 'h-4 p-px'"
                        >
                            <div class="flex h-4 select-none justify-end text-xxs space-x-1">
                                <span class="inline-block w-8 text-right">{{ (hour-1).toString().length === 1 ? '0' + (hour - 1) : (hour - 1) }}:00</span>
                                <span>-</span>
                                <span class="inline-block w-8">{{ (hour-1).toString().length === 1 ? '0' + (hour - 1) : (hour - 1) }}:59</span>
                            </div>
                        </div>
                        <div class="p-0.5">
                            <div
                                class="h-full text-right text-xxs"
                            >
                                <div v-if="noAfterBusinessHoursScheduled">
                                    <button
                                        v-if="scheduleCutoffEvening === 18"
                                        class="cursor-pointer select-none underline"
                                        @click="scheduleCutoffEvening = 23"
                                    >
                                        {{ _mft('leadHunterCampaign:schedule.later') }}
                                    </button>
                                    <button
                                        v-if="scheduleCutoffEvening === 23"
                                        class="cursor-pointer select-none underline"
                                        @click="scheduleCutoffEvening = 18"
                                    >
                                        {{ _mft('leadHunterCampaign:schedule.hide') }}
                                    </button>
                                </div>
                                <div v-else>
                                    &nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        v-for="(day, d) in days"
                        :key="d"
                    >
                        <div class="p-0.5">
                            <div class="h-4 select-none text-center text-xxs">
                                <div class="flex space-x-1 items-center">
                                    <span>{{ _mft('calendar:weekday.' + ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'][d] + '.short') }}</span>
                                    <div
                                        class="rounded-full bg-myflowGreen transition-all"
                                        :class="daysWithTimesSelected.includes(d+1) ? 'w-2 h-2' : 'w-0 h-0 opacity-0'"
                                    ></div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-for="(hour, h) in hours"
                            :key="h"
                            class="overflow-hidden transition-all"
                            :class="h < scheduleCutoffMorning || h > scheduleCutoffEvening ? 'h-0' : 'h-4 p-px'"
                        >
                            <div
                                class="h-full cursor-pointer"
                                :class="schedule.includes(day + ':' + (hour - 1)) ? 'bg-green-500' : 'bg-gray-200'"
                                @mousedown="toggleSlot(day, (hour - 1))"
                                @mouseenter="hoverToggleSlot(day, (hour - 1 ))"
                            ></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
