<script setup>
import {computed} from "vue";
import MediaBankEntry from "./util/media-bank-entry.vue";

const props = defineProps({
    containerRef: {
        type: String,
        required: true
    },
    uuid: {
        type: String,
        require: true
    },
    content: {
        type: Object,
        required: true
    }
})

const name = computed(() => props.content.name)
const description = computed(() => props.content.description)
const price = computed(() => props.content.price)
const includesVat = computed(() => props.content.includes_vat)
const linkHref = computed(() => props.content.link_href)

</script>
<template>
    <div class="flex flex-row justify-center w-full">
        <div class="flex justify-center w-auto">
            <div class="flex flex-col items-center justify-center w-full max-w-sm overflow-hidden text-center bg-white rounded-wl shadow-custom">
                <MediaBankEntry
                    v-if="content.media_bank_entry_id"
                    :container-ref="containerRef"
                    :block-uuid="uuid"
                    :block-content="content"
                    :media-bank-entry-id="content.media_bank_entry_id"
                    property-name="media_bank_entry_id"
                ></MediaBankEntry>
                <div class="p-8 md:px-12 md:p-6">
                    <h2 class="mb-2 text-gray-800">{{ name }}</h2>
                    <div class="text-gray-800" v-html="description"></div>
                    <p
                        v-if="price"
                        class="my-4 text-xl flex flex-col justify-center text-gray-800"
                    >
                        <span>{{ price }}</span>
                        <span class="text-xs text-gray-400 -mt-1" v-if="includesVat">{{ _mft('pagebuilderView:slimProduct.inclVat') }}</span>
                        <span class="text-xs text-gray-400 -mt-1" v-else>{{ _mft('pagebuilderView:slimProduct.exclVat') }}</span>
                    </p>
                    <a
                        v-if="linkHref"
                        :href="linkHref"
                        class="inline-block"
                    >
                        <button-primary>
                            {{ _mft('pagebuilderView:slimProduct.buyNow') }}
                        </button-primary>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>
