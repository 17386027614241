<script setup>
import {computed, onMounted, ref} from "vue";
import ToggleNavigationButton from "./toggleNavigationButton.vue";
import OverflowNavigation from "./overflowNavigation.vue";
import PrimaryNavigationItem from "./primaryNavigationItem.vue";
import ProfileBar from "./profileBar.vue";

const props = defineProps({
    logoUrl: {
        type: String,
        required: true
    },
    sidebarNavigation: {
        type: Object,
        required: true
    },
    userLoggedIn: {
        type: Boolean,
        required: false,
        default: false
    },
    accountCreationEnabled: {
        type: Boolean,
        required: false,
        default: false
    },
    navigationStyle: {
        type: String,
        required: false,
        default: 'fullwidth',
    },
});

const navigationOpen = ref(false);
const overflowNavigationOpen = ref(false);
const elementsRenderedOnScreen = ref([]);
const elementsRenderedOffScreen = ref([]);

let resizeTimer;

function debounce(func, delay) {
    return function() {
        const context = this;
        const args = arguments;
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(() => func.apply(context, args), delay);
    };
}

function checkElementsFit() {
    const container = document.querySelector('#primary-navigation');
    const elements = document.querySelectorAll('#primary-navigation > li');

    const elementsFitting = [];
    const elementsNotFitting = [];

    elements.forEach((element, i) => {
        elementsFitting.push(i);
    });
    elementsRenderedOnScreen.value = elementsFitting;

    return;
    elements.forEach((element, i) => {
        const elementRect = element.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        if (elementRect.right > containerRect.right || elementRect.bottom > containerRect.bottom) {
            elementsNotFitting.push(i);
        } else {
            elementsFitting.push(i);
        }
    });

    elementsRenderedOnScreen.value = elementsFitting;
    elementsRenderedOffScreen.value = elementsNotFitting;
}

const debouncedCheckElementsFit = debounce(checkElementsFit, 500);

checkElementsFit();

window.addEventListener('resize', debouncedCheckElementsFit);
window.addEventListener('DOMContentLoaded', checkElementsFit);
window.addEventListener('DOMContentLoaded', () => {setTimeout(function(){ checkElementsFit()}, 2000)});

const offScreenNavigationItems = computed(() => {
    return elementsRenderedOffScreen.value.map(i => props.sidebarNavigation.filter(ni => ni.visible === true)[i]);
});

const toggleNavigation = () => {
    navigationOpen.value = !navigationOpen.value;
};

const toggleOverflowNavigation = () => {
    overflowNavigationOpen.value = !overflowNavigationOpen.value;
};

const roundingClass = computed(() => {
    if (props.navigationStyle === 'fullwidth') {
        return '';
    }
    if (props.navigationStyle === 'limited') {
        return 'rounded-b-wl'
    }
    if (props.navigationStyle === 'floating') {
        return 'rounded-wl'
    }
});

onMounted(() => {
    const firstRow = document.querySelector('#primary-content .mfpbr-index-0');

    if (props.navigationStyle !== 'fullwidth') {
        const navHeight = document.getElementById('top-navigation').offsetHeight;
        if (firstRow?.querySelector('.row-paddable')) {
            firstRow.querySelector('.row-paddable').style.paddingTop = `${navHeight + 16}px`;
        } else {
            document.body.style.paddingTop = `${navHeight}px`;
        }
    }
});

</script>

<template>
    <div id="top-navigation" class="shadow-md z-topbar">
        <div class="relative h-16 px-4 py-2">
            <div
                class="absolute top-0 left-0 right-0 h-16 md:inset-0 bg-white/80 backdrop-blur-lg"
                :class="[navigationOpen ? 'open' : '', roundingClass]"
                id="primary-navigation-backdrop"
            ></div>

            <div class="relative flex items-center justify-between max-w-5xl mx-auto md:h-full">
                <div class="flex items-center justify-between w-full max-w-5xl md:space-x-2 md:justify-start">
                    <div class="w-24 h-12 shrink-0 md:mr-6">
                        <a :href="route('catchAll')" title="Gå till startsidan">
                            <img
                                class="object-contain object-left w-24 h-12"
                                :src="props.logoUrl"
                            />
                        </a>
                    </div>
                    <ul
                        class="fixed left-0 right-0 w-full top-16 md:static md:flex md:h-full md:bg-transparent md:py-0 md:overflow-hidden"
                        :class="navigationOpen ? 'open' : ''"
                        id="primary-navigation"
                        ref="primary-navigation"
                    >
                        <li
                            v-for="(navigationItem, i) in sidebarNavigation.filter(ni => ni.visible === true)"
                            class="flex items-center justify-between pr-4 md:justify-start md:pr-0"
                            :style="`transition-delay: ${i * 50}ms;`"
                        >
                            <primary-navigation-item
                                :item="navigationItem"
                            >
                                <a
                                    :href="navigationItem.href"
                                    class="py-3 pl-4 text-base font-semibold whitespace-nowrap text-wlNavigation hover:underline focus:underline focus:outline-none md:px-0 md:py-2 md:text-xs"
                                >{{ navigationItem.name }}</a>
                            </primary-navigation-item>
                        </li>
                    </ul>

                    <div
                        v-if="offScreenNavigationItems.length > 0"
                        class="relative hidden md:block"
                    >
                        <toggle-navigation-button
                            @click="toggleOverflowNavigation"
                            :class="overflowNavigationOpen ? 'active' : ''"
                        ></toggle-navigation-button>
                        <overflow-navigation
                            v-if="overflowNavigationOpen"
                            :navigation-items="offScreenNavigationItems"
                            class="absolute right-0 top-full w-96"
                        />
                    </div>

                    <div class="flex items-center space-x-2">
                        <profile-bar
                            :account-creation-enabled="props.accountCreationEnabled"
                            :user-is-logged-in="props.userLoggedIn"
                        ></profile-bar>

                        <toggle-navigation-button
                            @click="toggleNavigation"
                            class="md:hidden"
                            :class="navigationOpen ? 'active' : ''"
                        ></toggle-navigation-button>
                    </div>
                </div>
                <div>
                </div>
            </div>
        </div>
    </div>

</template>

<style scoped>
#top-navigation.wl-navigation-fullwidth {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

#top-navigation.wl-navigation-limited {
    width: 100%;
    max-width: 1058px;
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}

#top-navigation.wl-navigation-floating {
    position: fixed;
    top: 0.5rem;
    left: 0.5rem;
    right: 0.5rem;
}
@media screen and (min-width: 960px) {
    #top-navigation.wl-navigation-floating {
        width: 80%;
        max-width: 1058px;
        position: fixed;
        top: 0.5rem;
        left: 50%;
        transform: translateX(-50%);
    }
}


@media screen and (max-width: 767px) {
    #primary-navigation-backdrop {
        transition: height 150ms 150ms;
    }

    #primary-navigation-backdrop.open {
        height: 100vh !important;
        transition: height 250ms;
    }

    #primary-navigation {
        bottom: 100vh;
        transform: translateY(0rem);
        transition: all 0.25s;
        opacity: 0;
        max-height: 0;
        overflow-y: hidden;
    }

    #primary-navigation.open {
        bottom: 0;
        transform: translateY(0);
        opacity: 1;
        height: 100vh;
        max-height: 100vh;
        padding-bottom: 8rem;
        overflow-y: scroll;
    }

    #primary-navigation li {
        transform: translateY(-1rem);
        transition: all 0.25s;
        opacity: 0;
    }

    #primary-navigation.open li {
        transform: translateY(0rem);
        opacity: 1;
    }
}
</style>
