<template>
    <div class="bg-wlPrimary rounded-wl">
        <div
            class="min-h-1 text-wlPrimaryContrast"
            :class="hasTopSlot ? 'pt-1 pb-0.5 px-4 md:px-6 lg:px-8' : ''"
        >
            <slot name="top"></slot>
        </div>
        <div
            class="shadow-xl bg-white mb-10 rounded-wl transform translate-y-px overflow-hidden"
            :class="[alignmentClass, highlightClass, paddingClass]"
        >
            <div
                v-if="hasHeaderSlot"
            >
          <span class="font-body mb-4 inline-block ">
            <slot name="header"></slot>
          </span>
            </div>
            <div
                class="bg-white"
                v-if="hasImageSlot"
            >
                <slot name="image"></slot>
            </div>

            <div>
                <slot name="body"></slot>
                <slot name="default"></slot>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        alignment: String,
        compact: {
            type: Boolean,
            default: false
        },
        highlight: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        hasTopSlot() {
            return !!this.$slots.top
        },
        hasHeaderSlot() {
            return !!this.$slots.header
        },
        hasImageSlot() {
            return !!this.$slots.image
        },
        hasBodySlot() {
            return !!this.$slots.body
        },
        alignmentClass() {
            return this.alignment === 'center' ? 'text-center' : ''
        },
        highlightClass() {
            return this.highlight ? 'card-highlight' : ''
        },
        paddingClass() {
            return this.compact ? 'px-4 pt-3' : 'px-4 md:p-6 lg:p-8 pt-3 md:pt-5 lg:pt-6'
        }
    },
    setup() {

    },
}
</script>
<style scoped>
.card-highlight {
    border: 1px solid var(--whitelabel-primary);
    box-shadow: var(--whitelabel-primary) 0px 0px 7px;
}
</style>
