<script setup>
    import { computed, ref } from 'vue'
    import SignatureRequests from './signatureRequests.vue'
    import MoveFolder from './moveFolder.vue'

    const emit = defineEmits('doRefresh')

    const props = defineProps({
        expertContract: {
            type: Object,
            required: true
        }
    })

    const showSignatureRequests = ref(false)

    const statusIconClass = computed(() => {
        switch (props.expertContract.current_status) {
            case 'draft':
                return 'fa-solid fa-pen-to-square text-gray-500'

            case 'introduction':
                return 'fa-solid fa-clipboard-question text-gray-500'

            case 'pending':
                return 'fa-solid fa-envelope text-myflowYellow-900'

            case 'overdue':
                return 'fa-solid fa-envelope text-myflowRed-900'

            case 'complete':
                return 'fa-solid fa-square-check text-myflowGreen-900'

            return ''
        }
    })

    const statusLabel = computed(() => {
        switch (props.expertContract.current_status) {
            case 'draft':
                return _mft('expertContract:signatureRequest.status.draft')

            case 'introduction':
                return _mft('expertContract:signatureRequest.status.introduction')

            case 'pending':
                return _mft('expertContract:signatureRequest.status.pending')

            case 'overdue':
                return _mft('expertContract:signatureRequest.status.overdue')

            case 'complete':
                return _mft('expertContract:signatureRequest.status.complete')

            return ''
        }
    })

    const performRefresh = () => {
        emit('doRefresh')
    }

</script>
<template>
    <div class="block hover:bg-gray-50">
        <div class="flex flex-col p-4 space-y-4 md:flex-row md:items-start sm:px-6 md:space-y-0">
            <div class="flex-1 min-w-0 sm:flex sm:items-center sm:justify-between">
                <div class="w-full truncate">
                    <div class="flex space-x-2">
                        <span class="bg-gray-200 px-2 py-0.5 text-xs rounded-md mr-1">
                            <i class="mr-1" :class="statusIconClass" aria-hidden="true"></i>
                            {{ statusLabel }}
                        </span>
                        <a
                            v-if="expertContract.folder_name"
                            class="bg-gray-200 px-2 py-0.5 text-xs rounded-md mr-1"
                            :href="route('expert-contracts.showFolder', expertContract.folder_id)"
                        >
                            <i class="mr-1 fa-solid fa-folder" aria-hidden="true"></i>
                            {{ expertContract.folder_name }}
                        </a>
                    </div>

                    <div class="flex flex-col text-sm mt-0.5">
                        <a
                            :href="route('expert-contracts.edit', expertContract.id)"
                            class=""
                        >
                            <span class="space-x-1 font-bold truncate text-myflowPurple-900">
                                {{ props.expertContract.title }}
                            </span>
                        </a>

                        <small class="text-gray-500 truncate ">
                            {{ props.expertContract.created_at_tz }}
                        </small>

                        <div>
                            <div class="">
                                <div class="flex items-center">
                                    <span
                                        class="inline text-xs text-gray-500 cursor-pointer"
                                    >
                                        <span
                                            class="truncate"
                                        >
                                            {{ _mft('expertContract:signatureRequest.nRecipients', {count: props.expertContract.signature_request_count }) }}
                                        </span>
                                        <span
                                            v-if="props.expertContract.signature_request_count > 0"
                                            class="ml-1 truncate"
                                        >
                                            ({{ _mft('expertContract:signatureRequest.nSigned', {count: props.expertContract.signed_signature_request_count }) }})
                                        </span>
                                        <span class="mx-1">
                                            -
                                        </span>
                                        <button
                                            @click="showSignatureRequests = !showSignatureRequests"
                                            class="text-xs underline text-myflowMagenta"
                                        >
                                            <span v-if="!showSignatureRequests">
                                                {{ _mft('expertContract:signatureRequest.manageAndAdd') }}
                                            </span>
                                            <span v-if="showSignatureRequests">
                                                {{ _mft('expertContract:signatureRequest.manageAndAdd.close') }}
                                            </span>
                                        </button>
                                    </span>
                                </div>
                            </div>
                            <SignatureRequests
                                v-if="showSignatureRequests"
                                :expert-contract-id="props.expertContract.id"
                                @doRefresh="performRefresh"
                                class="mt-4"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div class="space-y-2">
                    <div class="flex justify-end space-x-2">
                        <MoveFolder
                            :expert-contract="expertContract"
                            @moveComplete="emit('doRefresh')"
                        />
                        <a
                            :href="route('expert-contracts.edit', expertContract.id)"
                        >
                            <button-primary size="sm" class="w-20 justify-center">
                                <span v-if="expertContract.signed_signature_request_count === 0">
                                    {{ _mft('shared:edit') }}
                                </span>
                                <span v-else>
                                    {{ _mft('shared:show') }}
                                </span>
                            </button-primary>
                        </a>
                    </div>
                    <div class="flex space-x-2">
                        <link-field
                            class="flex flex-col justify-end mt-1 space-y-2 text-xs text-right"
                            :url="route('expert-contracts.showPreview', expertContract.id)"
                            :copy-label="_mft('expertContract:signatureRequest.copyPreviewLink')"
                            :open-label="_mft('expertContract:signatureRequest.previewInNewTab')"
                        ></link-field>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
