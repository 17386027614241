<script setup>
    import { ref } from 'vue'
    import { useNotificationStore } from '@/stores/NotificationStore.js'
    import PillBase from "../pills/pillBase.vue";
    const notificationStore = useNotificationStore()

    const props = defineProps({
        availableSignatureMethods: {
            type: Object,
            required: true
        },
        enabledSignatureMethods: {
            type: Array,
            required: true
        },
        submitUrl: {
            type: String,
            required: true
        }
    })


    const signatureMethods = ref(props.enabledSignatureMethods)

    const toggleSignatureMethod = (method) => {
        if (signatureMethods.value.length === 1 && signatureMethods.value.includes(method)) {
            notificationStore.addNotification({
                type: 'warning',
                message: _mft('expertContract:signatureMethods.atLeastOneEnabled'),
                dismissAfter: 2500,
            })
            return;
        }
        signatureMethods.value.includes(method) ? signatureMethods.value.splice(signatureMethods.value.indexOf(method), 1) : signatureMethods.value.push(method)
        storeEnabledSignatureMethods()
    }

    const storeEnabledSignatureMethods = async () => {
        if (signatureMethods.value.length === 0) {
            return;
        }

        await axios.post(props.submitUrl, {
            signatureMethods: signatureMethods.value
        })
    }

</script>

<template>
    <div>
        <ul class="space-y-2">
            <li
                v-for="(label, method) in availableSignatureMethods"
                class="flex justify-between space-x-2"
            >
                <span>
                    {{ label }}
                    <pill
                        v-if="signatureMethods.includes(method)"
                        size="xs"
                        class="bg-green-200 text-green-700 ml-1"
                    >{{ _mft('expertContract:signatureMethods.enabled') }}</pill>
                </span>
                <div class="flex items-center space-x-2">
                    <button-secondary
                        v-if="!signatureMethods.includes(method)"
                        size="sm"
                        @click="toggleSignatureMethod(method)"
                    >
                        {{ _mft('expertContract:signatureMethods.enable') }}
                    </button-secondary>
                    <button-warning
                        v-if="signatureMethods.includes(method)"
                        size="sm"
                        @click="toggleSignatureMethod(method)"
                    >
                        {{ _mft('expertContract:signatureMethods.disable') }}
                    </button-warning>
                </div>
            </li>
        </ul>
    </div>
</template>

<style scoped>

</style>
