<script setup>
    const props = defineProps({
        contestant: Object,
        emoji: String
    })
</script>

<template>
    <div class="bg-white podium-card">
        <div class="relative mb-6">
            <img
                :src="props.contestant.profileImage"
                 class="object-cover object-center w-full -mt-px rounded-full aspect-square"
            >
            <div class="absolute bottom-px text-5xl transform -translate-x-1/2 left-1/2 translate-y-full">
                {{ props.emoji }}
            </div>
        </div>
        <div class="px-2 py-4">
            <p class="flex items-center justify-center font-bold min-h-12">
                <span>{{ props.contestant.name }}</span>
            </p>
            <p class="grid items-center grid-cols-3 gap-2 text-xs">
                <span class="text-2xl text-right">{{ props.contestant.analysesCompletedCount }}</span>
                <span class="col-span-2">
                    Genomförda<br>
                    analyser
                </span>
            </p>
            <p class="grid items-center grid-cols-3 gap-2 text-xs">
                <span class="text-2xl text-right">{{ props.contestant.insightMeetingsBookedCount }}</span>
                <span class="col-span-2">
                    Insight<br>
                    Meetings<br>
                    bokade
                </span>
            </p>
        </div>
    </div>
</template>

<style scoped>
    .podium-card {
        border-radius: 400px 400px 16px 16px !important;
    }
</style>
