<script setup>
import {computed, ref} from "vue";
import axios from "axios";
import { useNotificationStore } from '@/stores/NotificationStore.js'
import SlideoutWithSlots from "../elements/slideoutWithSlots.vue";
const notificationStore = useNotificationStore();

const emit = defineEmits(['selectTemplate', 'reloadTemplates']);

const props = defineProps({
    template: {
        type: Object,
        required: true,
    },
    marketplaceTemplate: {
        type: Boolean,
        required: true,
        default: false,
    },
    readOnly: {
        type: Boolean,
        required: false,
        default: false,
    },
    isSelected: {
        type: Boolean,
        required: false,
        default: false,
    },
    availableCategories: {
        type: Array,
        required: false,
        default: () => [],
    },
});

const modalOpen = ref(false)
const cloningState = ref('')
const cloningButtonMessage = ref(_mft('pagebuilderTemplate:addToMyTemplates'))
const editingTemplate = ref(false)
const savingTemplateState = ref('')
const showDescription = ref(false)
const cloningTemplate = ref(false)
const send = ref({
    email: '',
    message: null,
});

const sendTemplate = async () => {
    const response = await axios.post(route('templates.send', props.template.id), {
        email: send.value.email,
    });

    if (response.data.success) {
        send.value.email = '';
            modalOpen.value = false
        notificationStore.addNotification({
            type: 'success',
            title: response.data.message.title,
            message: response.data.message.body,
            dismissAfter: null,
        });
    } else {
        notificationStore.addNotification({
            type: 'error',
            message: response.data.message,
            dismissAfter: 3000,
        });
    }
}

const cloneTemplate = async () => {
    if (cloningState.value === 'waiting') {
        return;
    }
    cloningState.value = 'waiting';
    cloningButtonMessage.value = _mft('pagebuilderTemplate:addingToYourTemplates')

    const response = await axios.get(route('api.templates.clone', props.template.id));

    if (response.data.success) {
        cloningState.value = 'success';
        cloningButtonMessage.value = _mft('pagebuilderTemplate:addedToYourTemplates');

        notificationStore.addNotification({
            type: 'success',
            title: response.data.message.title,
            message: response.data.message.body,
            dismissAfter: 3500,
        });
        cloningTemplate.value = false;
        emit('reloadTemplates');
    } else {
        cloningState.value = 'error';
    }
}

const saveTemplate = async () => {
    savingTemplateState.value = 'waiting'
    const res = await axios.post(route('api.templates.updateTemplate', props.template.id), {
        name: props.template.name,
        description: props.template.description,
        category: props.template.category,
        available_on_marketplace: props.template.available_on_marketplace,
    });
    savingTemplateState.value = 'success'
}

const selectTemplate = () => {
    emit('selectTemplate', props.template);
}

const inputValidates = computed(() => {
    return send.value.email.length > 0;
});

</script>

<template>
    <div
        class="col-span-1 overflow-hidden bg-white shadow-xl rounded-xl"
    >
        <img
            v-if="template.img"
            class="w-full" :src="template.img"
        >
        <div
            v-else
            class="w-full aspect-square"
        ></div>
        <div class="relative p-5 pt-4 border-t">
            <h3 class="text-lg">{{ template.name }}</h3>
            <p
                class="text-xs cursor-pointer"
                :class="showDescription ? '' : 'overflow-hidden overflow-ellipsis max-h-4 whitespace-nowrap'"
                @click="showDescription = !showDescription"
            >{{ template.description }}</p>

            <div
                class="absolute flex items-center justify-center h-6 px-2 text-gray-500 bg-white rounded-full shadow-md -top-3 left-3 text-xxs"
            >
                <span>{{ _mft('pagebuilderTemplate:category.' + template.category + '.singular') }}</span>
            </div>

            <div
                v-if="! marketplaceTemplate && template.available_on_marketplace"
                class="absolute flex items-center justify-center w-6 h-6 text-sm text-gray-500 bg-white rounded-full shadow-md -top-3 right-5"
            >
                <i class="fa-light fa-store"></i>
            </div>

            <div
                v-if="marketplaceTemplate"
            >
                <div class="flex items-center py-2 my-1 mb-3 -mx-5 space-x-2 text-xs bg-gray-50">
                    <img
                        v-if="template.author.img"
                        :src="template.author.img"
                        class="object-cover object-center w-12 h-12 m-2 rounded-full"
                    >
                    <p class="mb-0">
                        Published by:<br>
                        <b>{{ template.author.name }}</b>
                    </p>
                </div>
            </div>

            <div v-if="! template.isEmptyDefault">
                <div class="grid grid-cols-5 gap-2">
                    <a
                        class="text-inherit"
                        :class="marketplaceTemplate ? 'col-span-5' : 'col-span-3'"
                        target="_blank"
                        :href="route('templates.show', template.id)"
                    >
                        <button-base
                            size="sm"
                            class="justify-center w-full"
                        >
                            {{ _mft('shared:preview') }}
                        </button-base>
                    </a>

                    <button-base
                        v-if="! marketplaceTemplate && template.page_builder_enabled"
                        size="sm"
                        class="justify-center w-full col-span-2"
                        @click="editingTemplate = true"
                    >
                        {{ _mft('shared:edit') }}
                    </button-base>

                    <div
                        v-if="marketplaceTemplate"
                        class="col-span-5"
                    >
                        <button-base
                            v-if="! cloningTemplate"
                            size="sm"
                            :style-type="cloningState === 'waiting' ? 'disabled' : 'secondary'"
                            :disabled="cloningState === 'waiting'"
                            :state="cloningState"
                            class="justify-center w-full mb-2"
                            @click="cloningTemplate = true"
                        >
                            {{ cloningButtonMessage }}
                        </button-base>

                        <div
                            v-if="cloningTemplate"
                        >
                            <p class="mb-2 text-xs italic leading-4 text-gray-500">
                                {{ _mft('pagebuilderTemplate:imagesWillBeCloned') }}

                            </p>
                            <p class="text-xs italic leading-4 text-gray-500">
                                {{ _mft('pagebuilderTemplate:yourContactDetailsWillBeShared', {name: template.author.name }) }}
                            </p>

                            <div class="flex space-x-2">
                                <button-secondary
                                    v-if="cloningState !== 'waiting'"
                                    size="sm"
                                    @click="cloningTemplate = false"
                                >
                                    {{ _mft('shared:cancel') }}
                                </button-secondary>

                                <button-base
                                    size="sm"
                                    :style-type="cloningState === 'waiting' ? 'disabled' : 'primary'"
                                    :disabled="cloningState === 'waiting'"
                                    :state="cloningState"
                                    class="justify-center w-full"
                                    @click="cloneTemplate"
                                >
                                    {{ _mft('shared:add') }}
                                </button-base>
                            </div>
                        </div>
                    </div>

                    <button-base
                        v-if="! marketplaceTemplate && ! readOnly && template.page_builder_enabled"
                        size="sm"
                        class="justify-center w-full col-span-5"
                        @click="modalOpen = true"
                    >
                        {{ _mft('pagebuilderTemplate:sendToUser') }}
                    </button-base>
                </div>
            </div>
            <button-base
                v-if="readOnly && ! marketplaceTemplate && template.page_builder_enabled"
                size="sm"
                :style-type="isSelected ? 'success' : 'primary'"
                class="justify-center w-full"
                :class="template.isEmptyDefault ? 'mt-9' : 'mt-2'"
                @click="selectTemplate"
            >
                {{ isSelected ? _mft('pagebuilderTemplate:usingThisTemplate') : _mft('pagebuilderTemplate:useThisTemplate') }}
            </button-base>

            <modal-basic
                :open="modalOpen"
                @closed="modalOpen = false"
                :showClose="true"
            >
                <template #body>
                    <div class="break-words whitespace-normal">
                        <input-text
                            label="User email"
                            placeholder="Email address"
                            v-model:content="send.email"
                        />
                        <div class="-mt-2 text-xs italic text-center text-gray-500">
                            {{ _mft('pagebuilderTemplate:userWillNeedToAccept') }}
                        </div>

                        <div class="flex justify-end">
                            <button-base
                                :style-type="inputValidates ? 'primary' : 'disabled'"
                                :disabled="! inputValidates"
                                class="mt-3 text-center"
                                v-on:click="sendTemplate"
                            >
                                {{ send.email.length > 0 ? _mft('pagebuilderTemplate:sendTo', {email: send.email }) : _mft('pagebuilderTemplate:send') }}
                            </button-base>
                        </div>
                    </div>
                </template>
            </modal-basic>
        </div>

        <slideout-with-slots
            v-if="editingTemplate"
            @close="editingTemplate = false"
        >
            <template #body>
                <div>
                    <input-text
                        :label="_mft('pagebuilderTemplate:input.name.label')"
                        v-model:content="props.template.name"
                    />

                    <input-textarea
                        :label="_mft('pagebuilderTemplate:input.description.label')"
                        maxlength="250"
                        v-model:content="props.template.description"
                    ></input-textarea>
                    <p class="-mt-2">{{props.template.description.length }} / 250</p>

                    <toggle-switch
                        class="mt-4"
                        name="public"
                        :label="_mft('pagebuilderTemplate:input.showOnMarketplace.label')"
                        v-model:content="props.template.available_on_marketplace"
                    ></toggle-switch>

                    <p
                        v-if="props.template.available_on_marketplace"
                        class="mb-4 -mt-2 text-xs text-gray-400"
                    >
                        {{ _mft('pagebuilderTemplate:inactiveTemplatesRemoval') }}
                    </p>

                    <input-select
                        :label="_mft('pagebuilderTemplate:input.category.label')"
                        :required="true"
                        :options="props.availableCategories"
                        labelSpecification="name"
                        valueSpecification="value"
                        v-model:content="props.template.category"
                    >
                    </input-select>

                </div>

                <div class="flex justify-between items-start">
                    <a
                        v-if="! marketplaceTemplate && ! template.is_pending_duplication"
                        class="text-inherit" :href="route('templates.edit', template.id)"
                        target="_blank"
                    >
                        <button-primary>
                            {{ _mft('pagebuilderTemplate:editWithPagebuilder') }}
                        </button-primary>
                    </a>

                    <div
                        v-if="template.is_pending_duplication"
                        class="flex space-x-2 items-center"
                    >
                        <mf-spinner-medium class="w-12 h-6 my-0"></mf-spinner-medium>
                        <p
                            class="text-xs text-gray-400 mb-0"
                            v-html="_mft('pagebuilderTemplate:cloningInProgressPagebuilderAvailableShortly')"
                        ></p>
                    </div>

                    <button-success
                        @click="saveTemplate"
                        :state="savingTemplateState"
                    >
                        {{ _mft('shared:action.save') }}
                    </button-success>
                </div>
            </template>

        </slideout-with-slots>
    </div>
</template>

<style scoped>

</style>
