<template>
    <div
        class="relative overflow-hidden"
        v-on:click="flip = !flip"
    >
        <div
            class="w-full transition-all pb-book book-front"
            :class="frontFlipClass"
        >
            <div class="absolute inset-0 z-20 bg-gradient-to-tr from-slate-300 to-slate-200 pt-[15%] px-[10%] rounded-sm">
                <div>
                    <h2 class="text-book-title">{{ title }}</h2>
                    <p class="text-book-author">{{ author }}</p>
                </div>
            </div>
            <div class="absolute z-30 left-[2%] w-[2%] h-full bg-gradient-to-r from-slate-500/10 via-slate-400/10 to-slate-500/10 "></div>
        </div>
        <div
            class="absolute inset-0 w-full transition-all pb-book book-back"
            :class="backFlipClass"
        >
            <div class="absolute z-10 inset-0 bg-gradient-to-tl from-slate-300 to-slate-200 rounded-sm py-[10%]">
                <div
                    class="text-book-back max-h-full overflow-scroll px-[10%]"
                    v-html="description"
                ></div>
            </div>
            <div class="absolute z-30 right-[2%] w-[2%] h-full bg-gradient-to-r from-slate-500/10 via-slate-400/10 to-slate-500/10 "></div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        title: {
            type: String,
            default: '',
            required: false
        },
        author: {
            type: String,
            default: '',
            required: false
        },
        description: {
            type: String,
            default: '',
            required: false
        },
    },
    data() {
        return {
            flip: false
        }
    },
    computed: {
        frontFlipClass () {
            return this.flip ? 'flipped' : ''
        },
        backFlipClass () {
            return this.flip ? '' : 'flipped'
        }
    },
}
</script>
<style scoped>
    .book-front, .book-back {
        backface-visibility: hidden;
        transform-origin: 100% 100%;
    }

    .flipped {
        transform: rotateY(180deg);
    }

    .pb-book {
        padding-bottom: 132.5%;
    }

    .text-book-title {
        font-size: 1.15em;
        line-height: 1.1em;
    }
    .text-book-author {
        font-size: 0.625em;
        line-height: 1.2em;
        margin-top: 0.8em;
    }
    .text-book-back {
        font-size: 0.6em;
        line-height: 1.4em;
    }
</style>
