<script setup>
import { computed, reactive } from 'vue';

    const props = defineProps({
        course: {
            type: Object,
            required: true
        },
        chapters: {
            type: Array,
            required: false,
            default: []
        },
    })

    const state = reactive({
        currentTab: 'description'
    })

    const setCurrentTab = (tab) => {
        state.currentTab = tab
    }

    const availableTabs = computed(() => {
        let tabs = [
            {
                label: 'Beskrivning',
                name: 'description'
            },
        ];
        if (props.chapters.length > 0) {
            tabs.push({
                label: props.chapters.length + ' kapitel',
                name: 'chapters'
            });
        }

        return tabs;
    });

</script>
<template>
    <div>
        <ul class="mb-4 space-x-6 overflow-x-scroll flex">
            <li
                v-for="(tab, i) in availableTabs"
                v-on:click="setCurrentTab(tab.name)"
                class="px-4 py-2 mb-4 font-bold border-b-2 cursor-pointer select-none"
                :class="{'border-myflowMagenta text-myflowMagenta' : state.currentTab === tab.name }"
            >
                {{ tab.label }}
            </li>
        </ul>

        <div
            v-if="state.currentTab === 'description'"
            v-html="props.course.description"
        >
        </div>

        <div
            v-if="state.currentTab === 'chapters'"
        >
            <ol class="space-y-2 border-l border-myflowPurple-200 pb-2">
                <li
                    v-for="(chapter, i) in props.chapters"
                    class="transform translate-y-4"
                >
                    <div class="flex items-center transform translate-y-0.5">
                        <div class="w-3 border-t border-myflowPurple-200 mr-2"></div>
                        <div>
                            {{ chapter.title }}
                        </div>
                    </div>
                </li>
            </ol>
        </div>
    </div>
</template>
