<script setup>
import {reactive, ref} from "vue";
import { useNotificationStore } from '@/stores/NotificationStore.js'
import ButtonMoreOptions from "../../buttons/buttonMoreOptions.vue";

const notificationStore = useNotificationStore()
const emit = defineEmits(['bookingSuccess', 'cancelSuccess'])

const props = defineProps({
    session: {
        type: Object,
        required: true
    }
})

const loadingCronofyPreferencesState = ref('')

const cronofyPreferences = reactive({
    available: false,
    cronofyAccountId: null,
    cronofyEmbedToken: null,
    bufferMinutesAfter: null,
    sessionDuration: null,
    startDateTime: null,
    endDateTime: null,
})

const clearCronofyPreferences = () => {
    cronofyPreferences.available = false
    cronofyPreferences.cronofyAccountId = null
    cronofyPreferences.cronofyEmbedToken = null
    cronofyPreferences.bufferMinutesAfter = null
    cronofyPreferences.sessionDuration = null
    cronofyPreferences.startDateTime = null
    cronofyPreferences.endDateTime = null
}

const onDateTimeSlotSelected = async (e) => {
    const res = await axios.post(route('api.coaching2.session.cronofy.create', props.session.id), {
        calendarProvider: 'cronofy',
        start: e.slot.start,
        end: e.slot.end,
        timezone: e.tzid,
        coach_cronofy_sub: e.slot.participants[0].sub,
    })

    if (res.data.success) {
        emit('bookingSuccess', {redirectUrl: res.data.redirect_url})
    } else {
        notificationStore.addNotification({
            type: 'error',
            title: _mft('error:error.generalOccurred'),
            message: _mft(res.data.message),
            dismissAfter: 5000,
        })
    }

}

const onCronofyError = (e) => {
    console.log('onCronofyError')
    console.error(e)
}

const initiateBooking = async (coachId) => {
    loadingCronofyPreferencesState.value = 'waiting'
    const res = await axios.get(route('api.coaching2.session.cronofy.select', [props.session.id, coachId]))

    cronofyPreferences.cronofyAccountId = res.data.cronofyAccountId
    cronofyPreferences.cronofyEmbedToken = res.data.cronofyEmbedToken
    cronofyPreferences.bufferMinutesAfter = res.data.bufferMinutesAfter
    cronofyPreferences.sessionDuration = res.data.sessionDurationMinutes
    cronofyPreferences.startDateTime = res.data.startDate
    cronofyPreferences.endDateTime = res.data.endDate

    cronofyPreferences.available = true;
    loadingCronofyPreferencesState.value = ''
}

const cancelBookedSession = async () => {
    const res = await axios.post(route('api.coaching2.session.cancel', props.session.id))

    if (res.data.success) {
        emit('cancelSuccess')
    } else {
        notificationStore.addNotification({
            type: 'error',
            title: _mft('error:error.generalOccurred'),
            message: _mft(res.data.message),
            dismissAfter: 5000,
        })
    }
}

</script>

<template>
    <div>
        <button-more-options
            v-if="(session.status === 'bookable' || session.status === 'reschedulable') && session.available_coaches.length > 1"
            :label="_mft('offerable:action.book')"
            alignment="right"
            size="sm"
            style-type="primary"
        >
            <button-more-options-option
                v-for="coach, ci in session.available_coaches"
                @click="initiateBooking(coach.id)"
                class="whitespace-nowrap"
            >
                {{ coach.name }}
            </button-more-options-option>
        </button-more-options>


        <button-primary
            v-if="(session.status === 'bookable' || session.status === 'reschedulable') && session.available_coaches.length === 1"
            size="sm"
            @click="initiateBooking(session.available_coaches[0].id)"
            :state="loadingCronofyPreferencesState"
            class="mb-1"
        >
            {{ _mft('offerable:action.book') }}
        </button-primary>

        <button-with-confirmation
            v-if="session.cancellable"
            size="sm"
            confirm-type="danger"
            :confirm-text="session.reimbursed_on_cancel ? _mft('offerable:action.cancel.text') : _mft('offerable:action.cancelWithoutReimbursement.text')"
            :confirm-button-label="_mft('offerable:action.cancel')"
            :abort-button-label="_mft('shared:action.cancel')"
            @confirmed="cancelBookedSession"
            class="mb-1"
        >
            {{ _mft('offerable:action.cancel') }}
        </button-with-confirmation>


        <slideout-with-slots
            v-if="cronofyPreferences.available"
            @close="clearCronofyPreferences"
        >
            <template #title>
                <h2>{{ _mft('offerable:action.booking') }} {{ session.title }}</h2>
            </template>
            <template #body>
                <cronofy-date-time-picker
                    :cronofy-account-id="cronofyPreferences.cronofyAccountId"
                    :embed-token="cronofyPreferences.cronofyEmbedToken"
                    :buffer-minutes-after="cronofyPreferences.bufferMinutesAfter"
                    :required-duration="cronofyPreferences.sessionDuration"
                    :start-datetime="cronofyPreferences.startDateTime"
                    :end-datetime="cronofyPreferences.endDateTime"
                    @dateTimeSlotSelected="onDateTimeSlotSelected"
                    @cronofyError="onCronofyError"
                ></cronofy-date-time-picker>
                <!--            :timezone="cronofyPreferences.props.timezone"-->
            </template>
        </slideout-with-slots>
    </div>
</template>

<style scoped>

</style>
