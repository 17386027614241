<script setup>

import { ref } from "vue";

const props = defineProps({
    analysisUuid: {
        type: String,
        required: false,
        default: ''
    }
})

const mode = ref('register')

const reloadReport = () => {
    if (props.analysisUuid !== '') {
        window.location.href = route('expert.analyze.attach', {uuid: props.analysisUuid})
        return;
    }
    window.location.reload()
}

</script>

<template>
    <div>
        <div class="flex justify-center mb-4">
            <button
                @click="mode = 'register'"
                class=" border-wlPrimary hover:border-wlPrimary-light border-2 border-r-1 px-3 py-1.5 text-sm rounded-l-wl"
                :class="mode === 'register' ? 'bg-wlPrimary text-white' : 'bg-white text-wlPrimary'"
            >Create myflow account</button>
            <button
                @click="mode = 'login'"
                class=" border-wlPrimary hover:border-wlPrimary-light border-2 border-l-1 px-3 py-1.5 text-sm rounded-r-wl"
                :class="mode === 'login' ? 'bg-wlPrimary text-white' : 'bg-white text-wlPrimary'"
            >Login</button>
        </div>

        <div class="relative">
            <div
                v-if="mode === 'login'"
                class="absolute inset-0 flex justify-center z-modal text-gray-900"
            >
                <div class="max-w-96">
                    <card-basic>
                        <h3 class="mb-4 text-center">Log in</h3>
                        <login-form
                            @successfulLogin="reloadReport"
                        ></login-form>
                    </card-basic>
                </div>
            </div>
            <div
                :class="mode === 'login' ? 'opacity-0' : 'opacity-100'"
            >
                <slot name="default"></slot>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
