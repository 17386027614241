<template>
    <h2 class="text-yellow-300 tracking-widest">
        {{ confirmationData.message }}
    </h2>
</template>

<script>
export default {
    props: {
        confirmation: Object,
    },
    data() {
        return {
            confirmationData: this.confirmation,
        };
    },
};
</script>

<style scoped></style>
