<script setup>
import {onMounted, reactive, ref, watch} from 'vue'

    const props = defineProps({
        fillColors: {
            type: Array,
            default: () => ['#7814d7', '#7814d7', '#7814d7', '#7814d7']
        },
        trackColor: {
            type: Array,
            default: () => '#ebdef8'
        },
        labelColor:{
            type: String,
            default: () => '#3f3f3f'
        },
        valueColor:{
            type: String,
            default: () => '#7814d7'
        },
        showLabel: {
            type: Boolean,
            default: true
        },
        label: {
            type: Array,
            required: false,
            default: ['Label 1', 'Label 2', 'Label 3', 'Label 4']
        },
        series: {
            type: Array,
            required: false,
            default: [86, 24, 34, 68]
        },
        lazyRender: {
            type: Boolean,
            default: false
        }
    })

    const series = ref([]);
    const chartOptions = reactive({
        chart: {
            type: 'radialBar',
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 800,
                animateGradually: {
                    enabled: true,
                    delay: 200
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 800
                }
            }
        },
        plotOptions: {
            radialBar: {
                track: {
                    background: props.trackColor,
                },
                offsetY: 0,
                hollow: {
                    size: '50%',
                },
                dataLabels: {
                    show: props.showLabel,
                    name: {
                        fontSize: '22px',
                        color: props.labelColor
                    },
                    value: {
                        fontSize: '22px',
                        color: props.valueColor,
                        fontWeight: 'bold',
                    },
                },
            },
        },
        colors: props.fillColors,
        responsive: [{
            breakpoint: 480,
            options: {
                legend: {
                    show: false
                }
            }
        }],
        labels: props.label,
    })

    const target = ref(null);
    const isVisible = ref(false);

    const observer = new IntersectionObserver(
        ([entry]) => {
            isVisible.value = entry.isIntersecting;
        },
        {
            threshold: 0.5
        }
    );

    watch(() => isVisible.value, (newVal) => {
        if (props.lazyRender) {
            series.value = props.series;
        }
    })

    onMounted(() => {
        if (props.lazyRender) {
            series.value = props.series.map(() => 0);
        } else {
            series.value = props.series;
        }
        observer.observe(target.value.$el);
    });

</script>
<template>
    <div>
        <div class="relative">
            <apexchart ref="target" type="radialBar" height="350" :options="chartOptions" :series="series"></apexchart>
            <div class="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
                <slot name="label"></slot>
            </div>
        </div>
    </div>
</template>
