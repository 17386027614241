<template>
  <div class="mb-4">
    <input-label
    :label="label"
    :required="required"
    />
    <block-editor
        ref="blockEditor"
        :modelValue="content"
        :includedBlockTypes="['cronofy']"
        @update:modelValue="captureValue($event)"
        :blockTypes="blockTypes"
    >
        <template #block-header-buttons="{ block }">
            <button
                @click.prevent="block.excludedFromAgreement = false; block.hidden = false"
                :class="[!block.excludedFromAgreement && !block.hidden ? 'bg-wlPrimary shadow-none text-white bg-gradient-to-b from-wlPrimary to-wlPrimary' : 'bg-gray-100 text-gray-900', hideToggleClass]"
            >
                {{ _mft('expertContract:setting.showInContract') }}
            </button>

            <button
                @click.prevent="block.excludedFromAgreement = true; block.hidden = false"
                :class="[block.excludedFromAgreement && !block.hidden ? 'bg-wlPrimary shadow-none text-white bg-gradient-to-b from-wlPrimary to-wlPrimary' : 'bg-gray-100 text-gray-900', hideToggleClass]"
            >
                {{ _mft('expertContract:setting.hideInContract') }}
            </button>

            <button
                @click.prevent="block.hidden = true"
                :class="[block.hidden ? 'bg-wlPrimary shadow-none text-white bg-gradient-to-b from-wlPrimary to-wlPrimary' : 'bg-gray-100 text-gray-900', hideToggleClass]"
            >
                {{ _mft('expertContract:setting.hideEverywhere') }}
            </button>
        </template>
        <template #after-add-blocks-buttons="{ blocks }">
            <div class="flex flex-col items-center justify-center">
                <ul
                    class="mt-2 overflow-scroll transition-all shadow-lg rounded-lg w-full min-w-[280px] max-w-md bg-white divide-y divide-y-gray-400 px-2"
                    :class="[showTemplates ? 'translate-y-0 opacity-1' : 'max-h-0 opacity-0 -translate-y-8']"
                >
                    <li
                        v-for="(template, i) in templates"
                        v-bind:key="i"
                        class="flex justify-between px-2 py-2"
                    >
                        <span>
                            <label class="block">{{ template.title }}</label>
                            <span class="block text-xs text-gray-400">
                                {{ _mft('shared:date.lastUpdated') }} {{ template.updated_at }}
                            </span>
                        </span>
                        <button
                            @click.prevent="addBlocksFromTemplate(blocks, template.content)"
                            class="p-2 text-white rounded-md bg-myflowPurple hover:bg-myflowPurple-700 ml-2"
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                            </svg>
                        </button>
                    </li>
                </ul>
                <button
                    type="button"
                    class="mt-4"
                    :class="[showTemplates ? 'myflow-basic-button--state-active' : 'myflow-basic-button--secondary']"
                    @click.prevent="toggleTemplates()"
                >
                    <span class="flex items-center space-x-1">
                        <svg class="w-4 h-4 transition-transform" :class="showTemplates ? 'rotate-0' : 'rotate-45'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path></svg>
                        <span>
                            {{ _mft('expertContract:addFromTemplate') }}
                        </span>
                    </span>
                </button>
            </div>
        </template>
    </block-editor>
    <input-error v-if="error" :error="error" />

    <input v-if="name" type="hidden" :name="name" :value="JSON.stringify(value)" />
  </div>
</template>
<script>
export default {
    data: function(){
        return {
            value: this.content,
            inputClass: '',
            showTemplates: false,
            templates: [],
            addedTemplate: false,
            hideToggleClass: 'px-4 first:rounded-l-lg lg:first:rounded-l-full lg:first:pl-4 last:rounded-r-lg lg:last:rounded-r-full lg:last:pr-4 py-1 lg:py-1 border-r last:border-r-0 cursor-pointer text-xs'
        }
    },
    props: {
        required: Boolean,
        content: Array,
        error: { type: String, default:''},
        label: String,
        name: String,
        blockTypes: ['text', 'headline', 'video']
    },
    async mounted() {
        await this.loadTemplates();
    },
    methods: {
        captureValue(newValue) {
            this.value = newValue;
            this.$emit('update:content', this.value);
        },
        async loadTemplates() {
            let res = await axios.get(route('expert-contracts.templates'));
            this.templates = res.data.templates;
        },
        toggleTemplates() {
            this.showTemplates = !this.showTemplates;
        },
        addBlocksFromTemplate(blocks, template){
            template.forEach(block => {
                this.$refs.blockEditor.importBlock({
                    type: block.type,
                    layout: block.layout,
                    excludedFromAgreement: block.excludedFromAgreement ? block.excludedFromAgreement : null,
                    content: block.content
                });
            });
            this.addedTemplate = true;
            this.showTemplates = false;
        }
    },
    watch: {
        error: function(error){
            let classes = [];
            if( error !== '' ){
                classes.push('border-red-700')
            } else {
                classes.push('border-gray')
            }
            this.inputClass = classes.join(' ')
        }
    }
}
</script>
