<template>
    <div class="flex flex-wrap justify-center w-screen h-auto bg-transparent align-center">
        <div id="header" :class="{'onScroll': !view.topOfPage}" class="fixed z-40 flex flex-col justify-center w-screen drop-shadow-lg lg:flex-row lg:justify-evenly align-center">
          <div class="w-full lg:w-4/12"></div>
          <div class="flex flex-col justify-center w-full overflow-hidden lg:w-4/12 align-center">
            <div class="flex justify-center w-full h-auto align-center">
              <img
                  class="w-3/12 p-2 mt-4 lg:w-6/12 md:w-3/12 lg:m-0"
                  src="https://myflow.se/app/uploads/2022/06/white.png"
                  alt="logo"
              />
            </div>



          </div>
          <div class="w-full lg:w-4/12">
            <!-- <form id="registerButton" :class="{'onScroll': !view.buttonAppear}" class="z-30 flex items-center justify-center p-0 lg:p-6">
                    <div @click="registerScroll()" class="z-40 mt-4 mb-4 text-white bg-myflowYellow-700 collapse-btn lg:m-0 bounce">
                        TILL ANMÄLAN
                    </div>
            </form> -->
          </div>
        </div>
    </div>
</template>

<script>
export default {
  el: '#header',
  el: '#registerButton',
  data () {
    return {
      view: {
        topOfPage: true,
        buttonAppear: true,
      }
    }
  },
  beforeMount() {
    window.addEventListener('scroll', this.handleScroll)
  },
  methods: {
    handleScroll(){
      if(window.pageYOffset>0){
        if(this.view.topOfPage) this.view.topOfPage = false
      } else {
        if(!this.view.topOfPage) this.view.topOfPage = true
      }

      if(window.pageYOffset>900){
        if(this.view.buttonAppear) this.view.buttonAppear = false
      } else {
        if(!this.view.buttonAppear) this.view.buttonAppear = true
      }
    },

    registerScroll() {
        const registerDiv = document.getElementById("register");
        if(registerDiv != null) {
            registerDiv.scrollIntoView({behavior: 'smooth'});
        }
    }
  },
};

</script>

<style scoped>

#header{
    background-color: transparent;
    transition: 0.5s ease-in-out;
}
#header.onScroll{
    transition: 0.5s ease-in-out;
    background-color: #622578;
}
.collapse-btn {
    padding: 0.8rem 1.5rem;
    border-radius: 25px;
    height: fit-content;
    width: fit-content;
    font-weight: bold;
    cursor: pointer;
}

#registerButton{
  opacity:0;
  pointer-events: none;
  transition: 0.5s ease-in-out;
}

#registerButton.onScroll{
  opacity: 1;
  pointer-events: all;
  transition: 0.5s ease-in-out;
}
</style>
