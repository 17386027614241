<script setup>
import {computed } from "vue";
import MediaBankEntry from "./util/media-bank-entry.vue";

const props = defineProps({
    containerRef: {
        type: String,
        required: true
    },
    uuid: {
        type: String,
        require: true
    },
    content: {
        type: Object,
        required: true,
    },
})

const horizontal = computed(() => props.content.horizontal? true : false);
const body = computed(() => props.content.body);
const title = computed(() => props.content.title);

</script>
<template>
    <div class="flex justify-center align-center">
        <div
            class=""
            :class="horizontal ? 'flex flex-col-reverse sm:items-center md:grid md:grid-cols-2 md:gap-4' : 'flex flex-col-reverse items-center'"
        >
            <section class="px-6 md:px-0">
                <h2 class="mb-2 text-xl md:text-2xl lg:text-3xl">{{ title }}</h2>
                <div v-html="body"></div>
            </section>
            <section class="mb-4" :class="horizontal ? 'md:pl-8 md:mb-0' : 'md:px-8 md:mb-8'">
                <MediaBankEntry
                    :container-ref="containerRef"
                    :block-uuid="uuid"
                    :block-content="content"
                    :media-bank-entry-id="content.media_bank_entry_id"
                    property-name="media_bank_entry_id"
                ></MediaBankEntry>
            </section>
        </div>
    </div>
</template>
