<template>

    <div>
        <div v-for="duplicate in duplicates" v-bind:key="duplicate.id">

            <div class="flex items-center justify-content-between">
                <div class="mr-5">
                    <i
                        class="text-2xl cursor-pointer fa-regular fa-square-check text-myflowGreen-800"
                        v-on:click="deselect(duplicate.id)"
                        v-if="selected.includes(duplicate.id)"
                    ></i>
                    <i
                        class="text-2xl text-gray-600 cursor-pointer fa-regular fa-square"
                        v-on:click="select(duplicate.id)"
                        v-if="!selected.includes(duplicate.id)"
                    ></i>
                </div>
                <div>
                    <div class="text-myflowGreen-900" v-if="selected[0] === duplicate.id">
                        <b>{{ _mft('crm:duplicatesList.migratingIntoThis') }}</b>
                    </div>
                    <b>{{ duplicate.name }}</b> - <a :href="route('crm.leads.view', duplicate.id)">{{ duplicate.email }}</a>
                    <br>
                    <small><b>{{ _mft('crm:duplicatesList.company') }}:</b> {{ duplicate.company }}<br></small>
                    <small><b>{{ _mft('crm:duplicatesList.id') }}:</b> {{ duplicate.id }}<br></small>
                    <small><b>{{ _mft('crm:duplicatesList.lastUpdatedAt') }}:</b> {{ Date(duplicate.updated_at).toLocaleString('sv-SE') }}</small>
                </div>
            </div>

            <hr class="mt-3 mb-3">

        </div>
        <div class="justify-end w-full p-4 space-y-2 text-center bg-white md:space-y-0 md:space-x-4 md:flex md:flex-row-reverse md:fixed md:bottom-0 md:left-52">
            <button
                type="button"
                :class="selected.length > 0 ? 'myflow-basic-button' : 'myflow-basic-button--secondary'"
                v-on:click="mergeDuplicated"
            >{{ _mft('crm:duplicatesList.mergeNContacts', {count: selected.length}) }}</button>
        </div>
    </div>

</template>

<script setup>

import { ref, reactive, computed, watch, onMounted, onUpdated, onBeforeUnmount } from 'vue'

const props = defineProps({
    'duplicates': {
        type: Array,
        required: true
    }
});

const select = (id) => {
    selected.push(id);
}

const deselect = (id) => {
    selected.splice(selected.indexOf(id), 1);
}

const mergeDuplicated = () => {
    axios.post(route('crm.merge'), {
        selected: selected
    }).then((response) => {
        window.location.href = route('crm.leads.view', response.data);
    });
}

const selected = reactive([]);

</script>

<style>

</style>
