<template>
    <div v-if="loaded" class="receipts-details" :class="containerClasses">
        <button type="button" class="float-right ml-2 myflow-basic-button print-toggle" @click.prevent="togglePrint">
            {{isPrinting ? 'Stäng' : 'Skriv ut'}}
        </button>

        <button
            type="button"
            class="float-right ml-2 myflow-basic-button--secondary print-toggle"
            v-on:click="addReference()"
            v-if="!editingReference"
        >
            {{ _mft('receipt:addReference') }}
        </button>

        <h1 class="mb-4">{{ _mft('receipt:domainNameSingular') }}: #{{receipt.id}}</h1>

        <div v-if="isPrinting">
            <h5>{{ _mft('receipt:seller') }}</h5>
            <b>MyFlow Learning AB</b><br>
            559310-5306<br>
            Karl Gustavsgatan 1A<br>
            411 25 Göteborg<br>

            support@myflow.se
            <hr class="mt-4 mb-4">
        </div>

        <div v-if="editingReference">
            <form @submit.prevent="updateReference">
                <input-text
                    v-model:content="referenceInput"
                    :label="_mft('receipt:reference.label')"
                    placeholder=""
                    name="reference"
                />

                <input-button
                    :label="_mft('receipt:reference.save')"
                    type="submit"
                />
            </form>

            <hr class="mt-3 mb-3">
        </div>

        <h5 v-if="isPrinting">{{ _mft('receipt:buyer') }}</h5>

        <div class="flex items-baseline justify-start mb-2" v-if="referenceInput">
            <i class="mr-2 fa-light fa-buildings"></i>
            <span>
                {{ referenceInput }}
            </span>
        </div>
        <div class="flex items-baseline justify-start mb-2">
            <i class="mr-2 fa-light fa-user"></i>
            <span>
               {{ receipt.user_name }} ({{ receipt.user_email }})
            </span>
        </div>
        <div class="flex items-baseline justify-start mb-2">
            <i class="mr-2 fal fa-calendar-alt"></i>
            <span>
                {{formattedCapturedAt(receipt)}}
            </span>
        </div>

        <div class="flex items-baseline justify-start mb-2">
            <i class="mr-2 fal fa-money-bill"></i>
            <span>
                {{ _mft('recipt:amount') }}: {{receipt.amount_including_vat}}<br>
                ({{ _mft('receipt:inclVat') }} {{receipt.vat_amount}})<br>
            </span>
        </div>

        <template v-if="receipt.gift_card_transactions?.length > 0">
            <hr class="my-2">
            <h5>{{ _mft('receipt:usedCoupons.title') }}</h5>

            <div v-for="(gcTransaction, gcTransactionIdx) in receipt.gift_card_transactions" :key="gcTransactionIdx">
                {{gcTransaction.amount}} {{ _mft('receipt:fromCouponCode') }} <strong>{{gcTransaction.gift_card.name}}</strong>
            </div>
        </template>

        <div v-for="(line, lineIdx) in receipt.lines" :key="lineIdx">
            <hr class="my-2">
            <h5>{{ _mft('receipt:items.title') }}</h5>
            <div class="flex items-baseline justify-start">
                <div class="mr-1">
                    {{line.count}} {{ _mft('receipt:items.pcs') }}
                </div>
                <div>
                    {{line.title}}<br>
                    <i>{{line.description}}</i>
                </div>
            </div>
        </div>
    </div>
    <mf-spinner-medium v-else
        class="w-24 h-12"
    />
</template>

<style scoped>
    .z-max {
        z-index: 9999999;
    }

    @media print {
        button.print-toggle {
            display: none;
        }
    }
</style>

<style lang="scss">
@media print {
    body.is-printing-receipts-details {
        * {
            visibility: hidden;
        }

        .receipts-details, .receipts-details * {
            visibility: visible;
        }
    }
}
</style>

<script>
import axios from 'axios';
export default {
    props: {
        id: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
            receipt: null,
            loaded: false,
            isPrinting: false,
            editingReference: false,
            referenceInput: '',
        };
    },
    computed: {
        containerClasses() {
            if (this.isPrinting) {
                return 'fixed inset-0 z-max overflow-y-scroll px-4 py-5 bg-white';
            } else {
                return '';
            }
        }
    },
    async mounted() {
        const resp = await axios.get(route('receipts.show', this.id));
        this.receipt = resp.data;
        this.referenceInput = this.receipt.reference;
        this.loaded = true;
    },
    methods: {
        togglePrint() {
            this.isPrinting = !this.isPrinting;
            if (this.isPrinting) {
                this.$nextTick(() => setTimeout(() => window.print(), 500));
                document.body.classList.add('is-printing-receipts-details');
            } else {
                document.body.classList.remove('is-printing-receipts-details');
            }
        },
        formattedCapturedAt(receipt) {
            const formatter = (new Intl.DateTimeFormat('sv-SE', {
                timeStyle: 'short',
                dateStyle: 'short'
            }));
            const date = new Date(receipt.completed_at);
            return formatter.format(date);
        },
        addReference() {
            this.editingReference = true;
        },
        updateReference() {
            const referenceData = {
                reference: this.referenceInput,
            };
            axios.post(route('receipt.updateReference', this.id), referenceData).then(function (response) {})
            this.editingReference = false;
        }
    }
};
</script>
