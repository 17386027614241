<script setup>
import {reactive, onMounted, ref, watch, computed} from "vue";
    import SlideoutWithSlots from "../elements/slideoutWithSlots.vue";
import {_mfHas} from "../../mfHas";
import UiAttention from "../containers/uiAttention.vue";

    const props = defineProps({
        product: {
            type: Object,
            required: true,
        }
    })

    const emit = defineEmits(['update:product'])
    const editing = ref(false)
    const addingProductType = ref(null)
    const linkableProducts = ref([])
    const loadingProducts = ref(false)
    const accountCreationEnabled = ref(false)
    const saveAsCustomProductStatus = ref('')

    const product = reactive({
        id: null,
        linkableType: null,
        linkableId: null,
        title: '',
        description: '',
        price: 0,
        quantity: 1,
        quantity_is_fixed: false,
        unit: '',
    })

    const resetForm = () => {
        product.linkableType = null
        product.linkableId = null
        product.title = ''
        product.description = ''
        product.price = 0
        product.quantity = 1
        product.quantity_is_fixed = false
        product.unit = ''
    }

    const loadLinkables = async (modelname) => {
        if (! modelname) {
            return
        }
        loadingProducts.value = true
        const res = await axios.get(route('api.invoice-links.invoice-linkable', {modelname}))
        linkableProducts.value = res.data.data
        loadingProducts.value = false
    }

    const unloadLinkables = () => {
        loadingProducts.value = false
        linkableProducts.value = []
    }

    const selectLinkableProduct = (_product) => {
        product.linkableType = addingProductType.value
        product.linkableId = _product.id
        product.title = _product.title
        product.description = _product.description
        product.price = _product.price
        product.quantity_is_fixed = _product.quantity_is_fixed
        product.quantity = 1
        product.unit = _product.unit

        unloadLinkables()
    }

    const saveAsCustom = async () => {
        if (! customProductValidates.value) {
            return
        }
        saveAsCustomProductStatus.value = 'waiting'
        const customProduct = {
            title: product.title,
            description: product.description,
            price: product.price,
        }

        const res = await axios.post(route('api.custom-products.create'), customProduct)

        if (res.data.success) {
            saveAsCustomProductStatus.value = 'success'
        } else {
            saveAsCustomProductStatus.value = 'error'
        }
    }

    onMounted(() => {
        product.id = props.product.id
        product.linkableType = props.product.linkableType
        product.linkableId = props.product.linkableId
        product.title = props.product.title
        product.description = props.product.description
        product.price = props.product.price
        product.quantity_is_fixed = props.product.quantity_is_fixed
        product.quantity = props.product.quantity
        product.unit = props.product.unit

        if (product.title === '') {
            editing.value = true
        }
    })

    const finishEditing = () => {
        if( isNaN(product.price)) {
            product.price = 0
        }
        if( isNaN(product.quantity)) {
            product.quantity = 0
        }
        editing.value = false
    }

    const showLinkableProducts = computed(() => {
        return addingProductType.value !== null
    })

    const showProductForm = computed(() => {
        if ( addingProductType.value === 'custom' ) {
            return true
        }

        if (product.linkableId === null && product.title !== '') {
            return true
        }

        return product.linkableId !== null;
    })

    const customProductValidates = computed(() => {
        return product.title !== '' && ! isNaN(product.price)
    })

    watch(() => product, (newVal) => {
        emit('update:product', newVal)
    }, {deep: true})

    watch(() => addingProductType.value, (modelname) => {
        resetForm()
        if (modelname !== 'custom') {
            product.linkableType = null
            loadLinkables(modelname)
        } else {
            product.linkableType = 'custom'
            unloadLinkables()
        }
    })

    onMounted( async () => {
        if (await _mfHas('company:integrity-policy') && await _mfHas('company:terms-of-use')) {
            accountCreationEnabled.value = true
        }
    })

</script>

<template>
    <div>
        <button-primary
            size="sm"
            @click="editing = true"
        >
            {{ _mft('shared:action.edit') }}
        </button-primary>
        <slideout-with-slots
            v-if="editing"
            @close="finishEditing"
        >
            <template #title>
                <h2 v-if="props.product.id">
                    {{ _mft('invoiceLink:product.edit') }}
                </h2>
                <h2 v-else>
                    {{ _mft('invoiceLink:product.add') }}
                </h2>
            </template>
            <template #actions>
                <button-success
                    @click="finishEditing"
                >
                    {{ _mft('shared:done') }}
                </button-success>
            </template>
            <template #body>
                <input-label>
                    {{ _mft('invoiceLink:product.type') }}
                </input-label>
                <div
                    v-if="! props.product.id"
                    class="flex"
                    :class="addingProductType === null ? 'flex-col items-stretch space-y-4' : 'space-x-4'"
                >
                    <button-base
                        :disabled="! accountCreationEnabled"
                        v-if="! addingProductType"
                        size="sm"
                        class="justify-center"
                        :style-type="addingProductType === 'course' ? 'primary' : 'secondary'"
                        @click="accountCreationEnabled ? addingProductType = 'course' : null"
                    >
                        {{ _mft('course:domainNameSingular') }}
                    </button-base>
                    <button-base
                        :disabled="! accountCreationEnabled"
                        v-if="! addingProductType"
                        size="sm"
                        class="justify-center"
                        :style-type="addingProductType === 'coaching-offerable' ? 'primary' : 'secondary'"
                        @click="accountCreationEnabled ? addingProductType = 'coaching-offerable' : null"
                    >
                        {{ _mft('coaching:coachingSession.domainNameSingular') }}
                    </button-base>
                    <button-base
                        :disabled="! accountCreationEnabled"
                        v-if="! addingProductType"
                        size="sm"
                        class="justify-center"
                        :style-type="addingProductType === 'community' ? 'primary' : 'secondary'"
                        @click="accountCreationEnabled ? addingProductType = 'community' : null"
                    >
                        {{ _mft('community:domainNameSingular') }}
                    </button-base>

                    <button-base
                        v-if="! addingProductType"
                        size="sm"
                        class="justify-center"
                        :style-type="addingProductType === 'product' ? 'primary' : 'secondary'"
                        @click="addingProductType = 'product'"
                    >
                        {{ _mft('invoiceLink:product.type.product') }}
                    </button-base>

                    <button-base
                        v-if="! addingProductType"
                        size="sm"
                        class="justify-center"
                        :style-type="addingProductType === 'custom' ? 'primary' : 'secondary'"
                        @click="addingProductType = 'custom'"
                    >
                        {{ _mft('invoiceLink:product.type.other') }}
                    </button-base>
                </div>

                <ui-attention
                    v-if="! accountCreationEnabled"
                    class="text-center text-xs mb-4 mt-4"
                >
                    <p>
                        {{ _mft('invoiceLink:product.createPoliciesToCreate') }}
                    </p>
                    <a
                        :href="route('settings.policies.show')"
                        class="mf-link"
                    >
                        {{ _mft('invoiceLink:product.createPoliciesToCreate.link') }}
                    </a>
                </ui-attention>

                <div v-if="showLinkableProducts">
                    <ul class="mt-4 space-y-1">
                        <li
                            v-if="!loadingProducts && linkableProducts.length === 0 && product.linkableId === null"
                        >
                            <template v-if="addingProductType === 'course'">{{ _mft('invoiceLink:product.course.nothingToShowHere') }}</template>
                            <template v-if="addingProductType === 'coaching-offerable'">{{ _mft('invoiceLink:product.coaching.nothingToShowHere') }}</template>
                        </li>

                        <li
                            v-if="loadingProducts"
                            class="text-center"
                        >
                            <mf-spinner-medium class="w-24 h-12" />
                        </li>

                        <li
                            v-for="(product, index) in linkableProducts"
                            :key="index"
                            class="flex justify-between space-x-2"
                        >
                            <span>{{ product.title }}</span>
                            <button-primary
                                size="sm"
                                @click="selectLinkableProduct(product)"
                            >
                                {{ _mft('shared:select') }}
                            </button-primary>
                        </li>
                    </ul>
                    <div
                        v-if="product.linkableId === null && addingProductType !== 'custom'"
                        class="flex justify-end mt-2"
                    >
                        <button-secondary
                            size="sm"
                            @click="addingProductType = null"
                        >
                            {{ _mft('shared:cancel') }}
                        </button-secondary>
                    </div>
                </div>


                <div v-if="showProductForm">
                    <input type="hidden" v-model="product.linkableType">
                    <input type="hidden" v-model="product.linkableId">

                    <input-text
                        :label="_mft('invoiceLink:product.name.label')"
                        v-model:content="product.title"
                    />

                    <input-editor
                        :label="_mft('invoiceLink:product.description.label')"
                        v-model:content="product.description"
                    />

                    <input-number
                        :label="_mft('invoiceLink:product.price.label')"
                        v-model:content="product.price"
                        @blur="isNaN(product.price) ? product.price = 0 : null"
                    />

                    <div
                        v-if="product.quantity_is_fixed !== true"
                    >
                        <input-number
                            :label="_mft('invoiceLink:product.quantity.label')"
                            v-model:content="product.quantity"
                        />

                        <input-text
                            :label="_mft('invoiceLink:product.unit.label')"
                            v-model:content="product.unit"
                            :placeholder="_mft('shared:egShort') + ' ' + _mft('coaching:session.domainNamePlural')"
                        />
                    </div>

                    <div
                        class="flex justify-end space-x-2"
                    >
                        <div>
                            <button-base
                                v-if="addingProductType === 'custom' && saveAsCustomProductStatus !== 'success'"
                                :style-type="customProductValidates ? 'secondary' : 'disabled'"
                                :disabled="! customProductValidates"
                                size="sm"
                                @click="saveAsCustom"
                            >
                                {{ _mft('invoiceLink:product.saveAsProduct') }}
                            </button-base>
                            <p
                                v-if="saveAsCustomProductStatus === 'success'"
                                class="text-gray-400"
                            >
                                <i class="fa-regular fa-check-circle text-myflowGreen mr-1"></i>
                                {{ _mft('invoiceLink:product.savedAsProduct') }}
                            </p>
                        </div>
                        <button-secondary
                            size="sm"
                            @click="addingProductType = null"
                        >
                            {{ _mft('shared:cancel') }}
                        </button-secondary>
                    </div>
                </div>
            </template>
        </slideout-with-slots>
    </div>
</template>

<style scoped>

</style>
