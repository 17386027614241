<template>
    <div class="px-4 -mx-4 overflow-hidden md:-mx-6 lg:-mx-8 md:px-6 lg:px-8">
        <div
            class="relative w-full py-4 mx-auto max-w-7xl aspect-video"
            v-on:mouseenter="pause = true"
            v-on:mouseleave="pause = false"
        >
            <div
                v-for="(url, i) in urls"
                v-bind:key="i"
                class="absolute inset-0 transition-all duration-500 transform bg-center bg-cover aspect-video rounded-custom shadow-custom"
                :class="[goingIn === i ? 'translate-x-1/2 opacity-0 scale-75 z-1' : '', current === i ? 'z-2' : '', goingOut === i ? '-translate-x-1/2 opacity-0 scale-75 z-3' : '', goingIn !== i && current !== i && goingOut !== i ? 'hidden' : '']"
                :style="{backgroundImage: 'url(' + url + ')'}"
            ></div>

            <button
                v-on:click.prevent="next()"
                class="absolute z-20 text-white transform -translate-y-1/2 top-1/2 right-4"
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-12 h-12">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                </svg>
            </button>
            <button
                v-on:click.prevent="prev()"
                class="absolute z-20 text-white transform -translate-y-1/2 top-1/2 left-4"
            >
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-12 h-12">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                </svg>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        mediaBankEntryDatas: { type: Array, required: true }
    },

    data() {
        return {
            current: null,
            goingOut: null,
            goingIn: null,
            pause: false
        }
    },

    computed: {
        urls() {
            return this.mediaBankEntryDatas.map((entry) => {
                if (!entry.url || !(entry.type == "image")) {
                    throw "Unknown entry data format!";
                }

                return entry.url;
            });
        }
    },

    methods: {
        next() {
            this.pause = true;
            this.current = this.current + 1 >= this.urls.length ? 0 : this.current + 1;
        },
        prev() {
            this.pause = true;
            this.current = this.current - 1 < 0 ? this.urls.length - 1 : this.current - 1;
        }
    },

    watch: {
        current(val){
            this.goingOut = this.current - 1 < 0 ? this.urls.length - 1 : this.current - 1;
            this.goingIn = this.current + 1 >= this.urls.length ? 0 : this.current + 1;
        }
    },

    mounted() {
        this.current = 0;
        setInterval(() => {
            if( this.pause ) return;
            if( this.current + 1 == this.urls.length ){
                this.current = 0;
            } else {
                this.current++;
            }
        }, 3800);
    }
};
</script>

<style scoped>
.aspect-video {
    aspect-ratio: 16 / 9;
}
.z-1 {
    z-index: 1;
}
.z-2 {
    z-index: 2;
}
.z-3 {
    z-index: 3;
}
</style>
