<template>
    <div>
        <draggable
            role="list"
            class="grid gap-4 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4"
            id="teleport-target-offers"
            :component-data="{
                tag: 'ul',
                type: 'transition-group',
            }"
            v-model="props.offers"
            v-bind="dragOptions"
            @start="drag = true"
            @end="dragEnded"
            item-key="id"
        >
            <template #item="{ element }">
                <card-basic class="relative">
                    <template v-slot:header>
                        <img
                            v-if="element.featured_image"
                            class="w-1/2 mb-4 rounded-full"
                            :src="element.featured_image"
                            :alt="'Utvald bild för ' + element.title"
                        />
                        <h2 class="pr-8 cursor-move lg:pr-8">{{ element.title }}</h2>
                        <p class="text-xs">
                            {{ element.type_label }}
                            <span
                                v-if="!element.published"
                                class="ml-1 text-xs font-bold text-myflowMagenta"
                            >- Ej publicerad</span>
                        </p>
                    </template>
                    <template v-slot:body>
                        <i class="absolute mt-2 text-xl cursor-move text-wlPrimary fas fa-arrows top-1 right-4 lg:top-4 lg:right-8"></i>

                        <div class="pb-8">
                            <div v-html="element.comment"></div>
                            <div class="absolute flex justify-end space-x-2 bottom-4 md:bottom-6 lg:bottom-8 left-4 md:left-6 lg:left-8 right-4 md:right-6 lg:right-8">
                                <button-secondary
                                    size="sm"
                                    v-on:click="editOffer(element)"
                                >Redigera</button-secondary>
                            </div>
                        </div>
                    </template>
                </card-basic>
            </template>
        </draggable>
        <Teleport
            v-if="data.doneLoading"
            to="#teleport-target-offers"
        >
            <div class="w-full h-full pb-10">
                <div class="relative flex flex-col items-center justify-center h-full p-4 pt-3 mb-10 overflow-hidden border-2 border-gray-200 border-dashed rounded-card md:p-6 lg:p-8 md:pt-5 lg:pt-6">
                    <p
                        v-if="props.offers.length > 1"
                        class="text-gray-400"
                    >Drag-och-släpp för att ändra ordning eller</p>
                    <button-primary
                        v-on:click="data.createOpen = !data.createOpen"
                        id="create-coaching-product-button"
                    >
                        Lägg till erbjudande
                    </button-primary>
                </div>
            </div>
        </Teleport>

        <div>
            <basic-slideout
                v-model:open="data.createOpen"
            >
                <template v-slot:header>
                    <h1>Lägg till erbjudande</h1>
                </template>

                <template v-slot:body>
                    <expert-profile-offer-form
                        @offerAdded="offerAdded"
                    />
                </template>
            </basic-slideout>

            <basic-slideout
                v-model:open="data.editOpen"
            >
                <template v-slot:header>
                    <h1>Ändra erbjudande</h1>
                </template>

                <template v-slot:body>
                    <expert-profile-offer-form
                        :offer="data.editingOffer"
                        @offerUpdated="offerUpdated"
                    />
                </template>
            </basic-slideout>
        </div>

    </div>
</template>
<script setup>
import axios from 'axios';
import { reactive, onMounted } from 'vue';
import draggable from 'vuedraggable';

const props = defineProps(['offers']);

const data = reactive({
    drag: false,
    newOffers: [],
    offers: [],
    doneLoading: false,
    createOpen: false,
    editOpen: false,
    editingOffer: null
})

const dragOptions = {
    animation: 200,
    group: 'description',
    disabled: false,
    ghostClass: 'ghost',
}

const dragEnded = async () => {
    data.drag = false;
    let res = await axios.post(route('manage-expert-profile.offer.storeOrder'), {
        offers: props.offers
    });
}

onMounted(() => {
    data.doneLoading = true;
    data.offers = props.offers;
})

function offerAdded(offer) {
    data.createOpen = false;
    window.location.reload();
}

function offerUpdated(offer) {
    data.createOpen = false;
    window.location.reload();
}

function editOffer(offer) {
    data.editingOffer = offer;
    data.editOpen = true;
}

</script>
