<template>
  <button
    :class="stateClass"
  >
    {{ label }}
  </button>
</template>
<script>
export default {
  props: {
    label: String,
    state: Boolean
  },
  data: function() {
    return {
      stateClass: 'myflow-basic-button--secondary'
    }
  },
  methods: {
    setStateClass(){
      if( this.state == true ){
        this.stateClass = 'myflow-basic-button'
      } else {
        this.stateClass = 'myflow-basic-button--secondary'
      }
    }
  },
  mounted: function(){
    this.setStateClass();
  },
  watch: {
    state: function(){
      this.setStateClass();
    }
  }
}
</script>