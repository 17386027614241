<script setup>
import { onMounted, ref, computed, inject, watch } from 'vue';
import axios from 'axios';

const {accountability, yesterdaysPost, activeChannel} = inject('accountability-data');

const progressOptions = [
    {
        label: '0%',
        value: 0,
        classes: 'border-myflowRed',
    },
    {
        label: '25%',
        value: 25,
        classes: 'border-myflowYellow-900',
    },
    {
        label: '50%',
        value: 50,
        classes: 'border-myflowYellow-900',
    },
    {
        label: '75%',
        value: 75,
        classes: 'border-myflowYellow-900',
    },
    {
        label: '100%',
        value: 100,
        classes: 'border-myflowGreen-800',
    },
];

</script>

<template>
    <div>
        <div class="mt-3 gap-8 md:grid md:grid-cols-2">
            <div class="col-span-1">
                <h5 class="mb-3">{{ _mft('community:channel.yesterdaysReflection.title') }}</h5>
                <div
                    v-if="yesterdaysPost !== null"
                    class="space-y-6"
                >
                    <div v-if="activeChannel.accountability_questions_actions.question1Enabled">
                        <label>
                            {{ activeChannel.accountability_questions_actions.question1 }}
                        </label>
                        <p class="mb-2 py-1 pl-2 text-xs border-l-3">
                            {{ yesterdaysPost.post_accountability_questions_actions.question1 }}
                        </p>

                        <div class="flex items-center space-x-2">
                            <label class="block">
                                {{ _mft('community:channel.yesterdaysReflection.completion.label') }}
                            </label>
                            <progress-steps
                                class="w-full"
                                :progress="accountability.yesterdaysQuestion1"
                                :steps="progressOptions"
                                @update:progress="accountability.yesterdaysQuestion1 = $event.value"
                            />
                        </div>
                    </div>
                    <div v-if="activeChannel.accountability_questions_actions.question2Enabled">
                        <label>
                            {{ activeChannel.accountability_questions_actions.question2 }}
                        </label>
                        <p class="mb-2 py-1 pl-2 text-xs border-l-3">
                            {{ yesterdaysPost.post_accountability_questions_actions.question2 }}
                        </p>

                        <div class="flex items-center space-x-2">
                            <label class="block">
                                {{ _mft('community:channel.yesterdaysReflection.completion.label') }}
                            </label>
                            <progress-steps
                                class="w-full"
                                :progress="accountability.yesterdaysQuestion2"
                                :steps="progressOptions"
                                @update:progress="accountability.yesterdaysQuestion2 = $event.value"
                            />
                        </div>
                    </div>
                    <div v-if="activeChannel.accountability_questions_actions.question3Enabled">
                        <label>
                            {{ activeChannel.accountability_questions_actions.question3 }}
                        </label>
                        <p class="mb-2 py-1 pl-2 text-xs border-l-3">
                            {{ yesterdaysPost.post_accountability_questions_actions.question3 }}
                        </p>

                        <div class="flex items-center space-x-2">
                            <label class="block">
                                {{ _mft('community:channel.yesterdaysReflection.completion.label') }}
                            </label>
                            <progress-steps
                                class="w-full"
                                :progress="accountability.yesterdaysQuestion3"
                                :steps="progressOptions"
                                @update:progress="accountability.yesterdaysQuestion3 = $event.value"
                            />
                        </div>
                    </div>
                </div>
                <div v-else>
                    <p class="text-xs italic text-gray-500">
                        {{ _mft('community:channel.yesterdaysReflection.noQuestionsAnsweredYet') }}
                    </p>
                </div>
            </div>
            <div class="col-span-1">
                <h5 class="mb-3">
                    {{ _mft('community:channel.todaysQuestions') }}
                </h5>
                <input-text
                    :label="activeChannel.accountability_questions_actions.question1"
                    v-model:content="accountability.question1"
                    v-if="activeChannel.accountability_questions_actions.question1Enabled"
                />
                <input-text
                    :label="activeChannel.accountability_questions_actions.question2"
                    v-model:content="accountability.question2"
                    v-if="activeChannel.accountability_questions_actions.question2Enabled"
                />
                <input-text
                    :label="activeChannel.accountability_questions_actions.question3"
                    v-model:content="accountability.question3"
                    v-if="activeChannel.accountability_questions_actions.question3Enabled"
                />
            </div>
        </div>
    </div>
</template>
