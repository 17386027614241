<script setup>
import {inject, ref} from "vue";
import {
    MailIcon,
    PhoneIcon,
    GlobeAltIcon,
} from '@heroicons/vue/outline';
import ButtonMoreOptions from "../buttons/buttonMoreOptions.vue";
import ButtonMoreOptionsOptionWithConfirmation from "../buttons/buttonMoreOptionsOptionWithConfirmation.vue";

const props = defineProps({
    allowDelete: {
        type: Boolean,
        required: false,
        default: true
    }
})

const emit = defineEmits(['update:lead', 'reload:lead'])
const lead = inject('lead')
const workingState = ref('')

const updateLead = () => {
    workingState.value = 'working'
    emit('update:lead')
    workingState.value = 'success'
}

const snoozeLead = async () => {
    await axios.get(route('crm.leads.snooze', lead.value.id))
    emit('reload:lead')
}

const unsubscribeLead = async () => {
    await axios.get(route('crm.leads.unsubscribe', lead.value.id))
    emit('reload:lead')
}

const deleteLead = async () => {
    await axios.get(route('crm.leads.delete', lead.value.id))
    window.location.href = route('crm.leads')
}
</script>

<template>
    <div class="@container">
        <div class="grid-cols-4 gap-x-8 md:grid @sm:grid-cols-2 @lg:grid-cols-4">
            <div class="col-span-2">
                <input-text
                    maxlength="255"
                    input-name="name"
                    input-id="name"
                    :label="_mft('crm:contact.fields.name')"
                    v-model:content="lead.name"
                />

                <div class="flex items-center space-x-2">
                    <input-text
                        maxlength="255"
                        input-name="email"
                        input-id="email"
                        class="w-full"
                        :label="_mft('crm:contact.fields.email')"
                        v-model:content="lead.email"
                    />
                    <a
                        v-if="lead.email?.length > 5"
                        :href="'mailto:' + lead.email"
                    >
                        <MailIcon class="w-6 h-6 mt-2 text-gray-400" />
                    </a>
                </div>

                <input-text
                    maxlength="255"
                    input-name="title"
                    input-id="title"
                    :label="_mft('crm:contact.fields.title')"
                    v-model:content="lead.title"
                />

                <div class="flex items-center space-x-2">
                    <input-text
                        maxlength="255"
                        input-name="phone"
                        input-id="phone"
                        class="w-full"
                        :label="_mft('crm:contact.fields.phone')"
                        v-model:content="lead.phone"
                    />
                    <a
                        v-if="lead.phone?.length > 3"
                        :href="'tel:' + lead.phone.replace(/[^0-9]/g, '')"
                    >
                        <PhoneIcon class="w-6 h-6 mt-2 text-gray-400" />
                    </a>
                </div>

                <div class="flex items-center space-x-2">
                    <input-text
                        maxlength="255"
                        input-name="linkedin"
                        input-id="linkedin"
                        class="w-full"
                        :label="_mft('crm:contact.fields.linkedIn')"
                        v-model:content="lead.linkedin"
                    />
                    <a
                        v-if="lead.linkedin?.length > 12"
                        :href="lead.linkedin"
                        target="_blank"
                    >
                        <i class="w-6 h-6 mt-2 text-2xl text-gray-400 fa-brands fa-linkedin"></i>
                    </a>
                </div>

                <div class="flex items-center space-x-2">
                    <input-text
                        maxlength="255"
                        input-name="website"
                        input-id="website"
                        class="w-full"
                        :label="_mft('crm:contact.company.fields.website')"
                        v-model:content="lead.website"
                    />
                    <a
                        v-if="lead.website?.length > 11"
                        target="_blank"
                        :href="lead.website"
                    >
                        <GlobeAltIcon class="w-6 h-6 mt-2 text-gray-400" />
                    </a>
                </div>
            </div>
            <div class="col-span-2">
                <input-text
                    maxlength="255"
                    input-name="company"
                    input-id="company"
                    :label="_mft('crm:contact.company.fields.name')"
                    v-model:content="lead.company"
                />

                <input-text
                    maxlength="255"
                    input-name="org_nr"
                    input-id="org_nr"
                    :label="_mft('crm:contact.company.fields.corporateIdentityNumber')"
                    v-model:content="lead.org_nr"
                />

                <input-text
                    maxlength="255"
                    input-name="invoice_email"
                    input-id="invoice_email"
                    :label="_mft('crm:contact.company.fields.invoiceEmail')"
                    v-model:content="lead.invoice_email"
                />

                <div class="grid grid-cols-2 gap-4">
                    <input-text
                        maxlength="255"
                        input-name="billing_street_address"
                        input-id="billing_street_address"
                        :label="_mft('crm:contact.company.fields.billingStreetAddress')"
                        v-model:content="lead.billing_street_address"
                    />

                    <input-text
                        maxlength="255"
                        input-name="billing_zipcode"
                        input-id="billing_zipcode"
                        :label="_mft('crm:contact.company.fields.billingZipcode')"
                        v-model:content="lead.billing_zipcode"
                    />
                </div>
                <div class="grid grid-cols-2 gap-4">
                    <input-text
                        maxlength="255"
                        input-name="billing_city"
                        input-id="billing_city"
                        :label="_mft('crm:contact.company.fields.billingCity')"
                        v-model:content="lead.billing_city"
                    />

                    <input-language
                        :label="_mft('crm:contact.company.fields.billingCountry')"
                        name="country"
                        v-model:country="lead.billing_country"
                        @selected="lead.billing_country = $event.label"
                    />
                </div>

                <input-number
                    input-name="employees"
                    input-id="employees"
                    v-model:content="lead.employees"
                    :label="_mft('crm:contact.company.fields.employees')"
                />

            </div>
            <crm-lead-custom-fields
                :lead-id="lead.id"
            />
        </div>
        <div class="flex justify-end space-x-2">
            <button-more-options
                :label="_mft('crm:lead.actions')"
                alignment="right"
                :cancel-on-outside-click="false"
            >
                <button-more-options-option
                    v-if="lead.active"
                    @click="snoozeLead"
                >
                    {{ _mft('crm:snooze') }}
                </button-more-options-option>
                <button-more-options-option
                    v-if="! lead.active"
                    @click="snoozeLead"
                >
                    {{ _mft('crm:unsnooze') }}
                </button-more-options-option>


                <button-more-options-option-with-confirmation
                    v-if="! lead.crm_communications_email_opted_out"
                    style-type="danger"
                    :confirm-text="_mft('crm:lead.unsubscribe.cannotBeUndone')"
                    :confirm-button-label="_mft('crm:lead.unsubscribe.confirm')"
                    :abort-button-label="_mft('shared:action.cancel')"
                    @confirmed="unsubscribeLead"
                    class="w-48"
                >
                    {{ _mft('crm:lead.unsubscribe.label') }}
                </button-more-options-option-with-confirmation>

                <button-more-options-option
                    v-if="lead.crm_communications_email_opted_out"
                    class="italic text-gray-400 cursor-not-allowed"
                >
                    {{ _mft('crm:lead.unsubscribe.alreadyUnsubscribed') }}
                </button-more-options-option>

                <button-more-options-option-with-confirmation
                    v-if="allowDelete"
                    style-type="danger"
                    :confirm-text="_mft('crm:lead.delete.cannotBeUndone')"
                    :confirm-button-label="_mft('crm:lead.delete.confirm')"
                    :abort-button-label="_mft('shared:action.cancel')"
                    @confirmed="deleteLead"
                    class="w-48"
                >
                    {{ _mft('crm:lead.delete.label') }}
                </button-more-options-option-with-confirmation>
            </button-more-options>

            <button-success
                @click="updateLead"
                :state="workingState"
            >
                {{ _mft('shared:action.update') }}
            </button-success>
        </div>
    </div>
</template>

<style scoped>

</style>
