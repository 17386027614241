<script setup>

import ButtonWithConfirmation from "../../buttons/buttonWithConfirmation.vue";
import {onMounted, ref} from "vue";
import { useNotificationStore } from '@/stores/NotificationStore.js'
const notificationStore = useNotificationStore()

const emit = defineEmits(['reload', 'close'])
const props = defineProps({
    entry: {
        type: Object,
        required: true,
    }
})

const renaming = ref(false)
const processingStatus = ref('ready')

const deleteItem = async () => {
    await axios.post(route('mediaBank.entries.destroy', [props.entry.id]),{
        _method: 'DELETE'
    })
    emit('reload')

    notificationStore.addNotification({
        type: 'success',
        title: _mft('mediaBank:delete.notification.deleted.title'),
        message: _mft('mediaBank:delete.notification.deleted.body', {name: props.entry.title}),
        dismissAfter: 5000,
    })

    emit('close')
}

const updateItemName = async () => {
    await axios.put(route('mediaBank.enstries.renameEntryTitle', props.entry.id), {
        title: props.entry.title
    })

    renaming.value = false
    emit('reload')
}

const updateProcessingStatus = async () => {
    const res = await axios.get(route('mediaBank.entries.processingStatus', props.entry.id))

    if (res.data.status === 'processing') {
        setTimeout(() => {
            updateProcessingStatus()
        }, 10000)
    }

    processingStatus.value = res.data.status
}

onMounted(async () => {
    if (props.entry.type === 'video' && props.entry.duration === null) {
        await updateProcessingStatus()
    }
})

</script>

<template>
    <media-bank-entries-display
        v-if="entry.type === 'image'"
        :data-url="route('mediaBank.entries.show', entry.id)"
    />

    <div
        v-else-if="entry.type === 'video'"
    >
        <media-bank-entries-display
            v-if="entry.duration !== null || processingStatus === 'ready'"
            :data-url="route('mediaBank.entries.show', entry.id)"
        />
        <div
            v-else
            class="flex items-center justify-center w-full mb-2 space-y-4 bg-gray-300 aspect-video"
        >
            <div v-if="processingStatus === 'processing'">
                <mf-spinner-medium class="w-24 h-12" />
                <p>{{ _mft('mediaBank:optimizingForStreaming') }}</p>
            </div>
            <div v-if="processingStatus === 'failed'">
                <p>{{ _mft('mediaBank:processingFailed') }}</p>
            </div>
        </div>
    </div>

    <media-bank-entries-display
        v-else-if="entry.type === 'audio'"
        :data-url="route('mediaBank.entries.show', entry.id)"
    />

    <div
        v-else
        class="mb-2 text-center"
    >
        <i class="text-5xl text-gray-400 fa-regular fa-file"></i>
    </div>

    <p class="text-center text-xs text-gray-400 mt-0.5">{{ entry.title }}</p>

    <div
        v-if="renaming"
        class="flex items-end px-4 py-4 mb-4 -mx-4 space-x-2 bg-gray-100 border-y border-y-gray-100 lg:-mx-6 lg:px-6"
    >

        <input-text
            :label="_mft('mediaBank:name')"
            v-model:content="entry.title"
            autofocus
            class="w-full !mb-0"
        />
        <button-success
            @click="updateItemName()"
        >
            {{ _mft('shared:action.save') }}
        </button-success>
    </div>

    <div class="flex justify-end pt-2 space-x-2">
        <button-secondary
            @click="renaming = !renaming"
        >
            {{ renaming ? _mft('shared:cancel') : _mft('mediaBank:rename') }}
        </button-secondary>
        <a
            :href="route('mediaBank.entries.downloadEntry', entry.id)"
            target="_blank"
        >
            <button-secondary>
                {{ _mft('shared:download') }}
            </button-secondary>
        </a>
        <button-danger
            v-if="entry.inUse"
            disabled
        >
            {{ _mft('shared:action.delete') }}
        </button-danger>
        <button-with-confirmation
            v-if="! entry.inUse"
            @confirmed="deleteItem()"
            modalClass="absolute -top-2 -right-2 p-2 bg-white text-black rounded shadow-md z-10 w-48"
            :buttonLabel="_mft('shared:action.delete')"
            buttonClass="text-wlPrimaryContrast"
            :confirmButtonLabel="_mft('shared:action.delete')"
            confirmButtonClass="myflow-basic-button--success whitespace-nowrap"
            :confirm-text="_mft('mediaBank:delete.cannotBeUndone')"
        />
    </div>

    <div
        v-if="entry.inUse && entry.usages.length > 0"
        class="mt-4"
    >
        <h5>{{ _mft('mediaBank:cannotBeDeleted') }}</h5>
        <ul class="space-y-1">
            <li
                v-for="(usage, i) in entry.usages"
                :key="i"
            >
                <a :href="usage.edit_url" target="_blank" class="mf-link">
                    {{ usage.title }}
                </a>
            </li>
        </ul>
    </div>
</template>

<style scoped>

</style>
