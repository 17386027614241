<template>
    <h5 class="text-dark">{{recording.title}}</h5>
    <div
        class="relative w-full bg-video-placeholder"
        style="padding-bottom: 56.25%"
    >
        <iframe
            :src="recordingVideoUrl"
            allow="autoplay;picture-in-picture"
            frameborder="0"
            class="absolute inset-0 z-10 w-full h-full"
        ></iframe>
    </div>
    <hr class="mt-3 mb-3">

</template>

<script>

export default {
    components: {
    },
    props: {
        recording: Object,
    },
    data: function(){
        return {
            recordings: this.recording,
            recordingOpenFor: null,
            recordingVideoUrl: null,
            updatingTitleFor: null,
            updatedTitle: null,
            notification: {
                type: '',
                message: ''
            },
            confirmDeleteButtonVisible: false
        }
    },
    mounted() {
        this.openTrailer()
    },
    methods: {
        async openTrailer() {
            let res = await axios.get(route('livestream.recording.playbackSession', this.recording.cloudflare_id))
            this.recordingVideoUrl = res.data.embedUrl
        },
    }
}
</script>