<script setup>
import SidebarNavigationItem from "./sidebarNavigationItem.vue";

import {
    CogIcon,
    HomeIcon,
    MenuAlt3Icon,
    PlusSmIcon,
    XIcon,
    AcademicCapIcon,
    SparklesIcon,
    GiftIcon,
    BellIcon,
    UserGroupIcon,
    AdjustmentsIcon,
    TrendingUpIcon,
    GlobeIcon,
    VideoCameraIcon,
    CashIcon,
    ViewGridAddIcon,
    UsersIcon,
    LightBulbIcon,
    RssIcon,
    UserAddIcon,
    CubeTransparentIcon,
    MapIcon,
    PresentationChartBarIcon,
    FolderIcon,
    StatusOnlineIcon,
    StarIcon,
    InboxIcon
} from '@heroicons/vue/outline'

const icons = {
    CogIcon,
    HomeIcon,
    MenuAlt3Icon,
    PlusSmIcon,
    XIcon,
    AcademicCapIcon,
    SparklesIcon,
    GiftIcon,
    BellIcon,
    UserGroupIcon,
    AdjustmentsIcon,
    TrendingUpIcon,
    GlobeIcon,
    VideoCameraIcon,
    CashIcon,
    ViewGridAddIcon,
    UsersIcon,
    LightBulbIcon,
    RssIcon,
    UserAddIcon,
    CubeTransparentIcon,
    MapIcon,
    PresentationChartBarIcon,
    FolderIcon,
    StatusOnlineIcon,
    StarIcon,
    InboxIcon
}

const props = defineProps({
    items: {
        type: Array,
        required: true
    }
})

</script>
<template>
<div class="pl-4 pr-4 md:pr-0">
    <div class="pt-2 mb-4 ml-2 shadow-xl bg-wlPrimary rounded-wl">
        <slot name="top" />
        <div class="py-2 transform translate-y-px bg-white rounded-wl">
            <ul class="pt-1 pb-3 space-y-2 -mt-4">
                <SidebarNavigationItem
                    v-for="(item, i) in items"
                    :key="i"
                    :item="item"
                    :is-last="i === items.length - 1"
                    :icon="icons[item.icon]"
                />
            </ul>
        </div>
    </div>
    <div class="flex flex-col items-center justify-between w-54 py-0 pt-8 mb-3 ml-2 bg-white rounded-wl md:ml-0 md:rounded-none md:bg-transparent md:pt-0 lg:px-4">
        <small class="text-center">
<!--            <a :href="route('changelog.index')">Versionshistorik</a><br>-->
            <a :href="route('terms.general')" class="mf-link">{{ _mft('navigation:backOffice.sidebar.termsAndConditions') }}</a><br>
        </small>
        <div class="px-4 pt-4">
            <improvement-panel />
        </div>
    </div>
</div>
</template>
