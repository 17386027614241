<template>
  <div class="relative transition-all duration-700" :class="embedContainerClasses">
        <whereby-embed
            :room="session_url"
            :displayname="display_name"
            people="off"
            lang="en"
            screenshare="on"
            floatSelf="on"
            chat="on"
            :minimal="this.display_prop"
            ref="wherebyEmbed"
            class="absolute inset-0"
        ></whereby-embed>
  </div>
</template>
<script>

export default {
    props: {
        session_url: String,
        display_name: String,
        display_prop: {String, default: 'on'},
    },
    data() {
        return {
            embedState: 'init'
        }
    },
    mounted() {
        this.$refs['wherebyEmbed'].addEventListener("ready", this.readyEventFired)
        this.$refs['wherebyEmbed'].addEventListener("join", this.joinEventFired)
    },
    methods: {
        readyEventFired(event) {
        },
        joinEventFired(event) {
            this.embedState = 'approved'
        }
    },
    computed: {
        embedContainerClasses() {
            if( this.embedState === 'approved' ){
                return 'pb-[56.25%]'
            } else {
                return 'pb-[200%] md:pb-[175%] lg:pb-[125%] xl:pb-[100%]'
            }
        }
    }
}
</script>
