<template>
    <modal-basic
        :open="open"
        @closed="open = false"
    >
        <template #header>
            <div class="text-center">
                {{ _mft('auth:session.expired.title') }}
            </div>
        </template>
        <template #body>
            <div class="flex flex-col items-center space-y-6">
                <div class="text-center">
                    {{ _mft('auth:session.expired.clickHere') }}
                </div>
                <a :href="route('login')" target="_blank">
                    <button-primary class="justify-center w-full min-w-36">
                        {{ _mft('auth:login') }}
                    </button-primary>
                </a>
                <div
                    class="text-xs text-center text-gray-400"
                    v-html="_mft('auth:session.expired.assurance')"
                >
                </div>
            </div>
        </template>
    </modal-basic>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'

const open = ref(false)

const _isAuth = () => {
    setInterval(() => {
        try {
            axios.get(route('auth-checker'))
                .then(response => {
                    window.showAuthModal = !response.data.auth;
                });
        } catch (e) {
            console.log(e);
        }
    }, 15000);
}

onMounted(() => {
    _isAuth();
    setInterval(() => {
        open.value = window.showAuthModal;
    }, 1500);
});
</script>
