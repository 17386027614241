<script setup>
import {inject, useSlots} from 'vue'

const slots = useSlots()

const props = defineProps({
    title: {
        type: String,
        required: false,
        default: '',
    },
    localizetitle: {
        type: Boolean,
        required: false,
        default: false,
    },
    value: {
        type: String,
        required: true
    },
    active: {
        type: Boolean,
        required: false,
        default: false
    },
})

const selectedTitle = inject('selectedTitle')

</script>
<template>
    <div
        v-show="value === selectedTitle"
        class="relative"
    >
        <slot></slot>

        <div
            v-if="slots['action-left'] || slots['action-right']"
            class="flex justify-between bg-gray-100 -mx-4 md:-mx-6 lg:-mx-8 -mb-4 md:-mb-6 lg:-mb-8 px-4 md:px-6 lg:px-8 py-2 rounded-b-wl transform translate-y-px relative mt-4"
        >
            <div class="absolute top-0 left-0 right-0 h-2 bg-gradient-to-b from-gray-200 to-transparent shadow-2xl"></div>
            <div><slot name="action-left"></slot></div>
            <div><slot name="action-right"></slot></div>
        </div>
    </div>
</template>
