<template>
    <list-item>
        <template #teleporter>
            <Teleport v-if="trailerVideoUrl" to="#trailer-player-teleport-target">
                <trailer-video :trailer-video-url="trailerVideoUrl" @close="closeTrailer()" />
            </Teleport>
        </template>

        <template #image v-if="showImage">
            <div class="w-4/5 mx-auto mb-4 md:mb-0 md:w-full">
                <div class="relative w-full bg-top bg-cover rounded-full" style="padding-bottom: 100%;"
                    :style="{ backgroundImage: 'url(' + content.featured_image + ')' }"
                    v-on:click.self="redirect(route('courses.show', content.id))">
                    <div class="absolute inset-0 flex items-center justify-center" v-if="content.trailer_video">
                        <Playbutton
                            class="w-1/3 h-auto text-white transition-all opacity-50 cursor-pointer hover:scale-105 hover:opacity-90"
                            v-on:click="openTrailer()" />
                    </div>
                </div>
            </div>
        </template>

        <template #title>
            <h2 class="mb-1 text-lg leading-6 text-myflowPurple-600">
                <a :href="route('courses.show', content.id)">
                    {{ content.title }}
                </a>
            </h2>
        </template>

        <template
            v-if="intro"
            #intro
        >
            <span v-html="renderedIntro"></span>
        </template>

        <template #bio>
            <p class="font-bold text-gray-900" v-if="content.provider.name">
                {{ content.provider.name }}<br>
                <span class="font-normal text-gray-500" v-if="content.provider.title">{{ content.provider.title }}</span>
                <div
                    class="flex items-center text-xs font-normal"
                    v-if="content.provider.display_rating && showRating"
                >
                    <span class="mr-2 text-xs">
                        <i :data-star="content.provider.rating"></i>
                    </span>
                    {{ content.provider.rating }} / 5.0
                </div>
            </p>
        </template>

        <template #description>
            <span
            v-html="previewing ? content.description.replace(/<\/?[^>]+(>|$)/g, ' ') : content.description.substring(0, 200).replace(/<\/?[^>]+(>|$)/g, ' ') + '... '"
            ></span>
            <span v-on:click="toggleDescription" class="italic cursor-pointer text-myflowPurple-800">
                <br> {{ previewing ? _mft('shared:collapsable.collapse') : _mft('shared:collapsable.expand') }}
            </span>
        </template>

        <template #info>
            <div class="flex justify-end">
                <a :href="route('courses.show', content.id)" class="mb-3 myflow-basic-button">
                    {{ _mft('course:navigation.goToCourse') }}
                </a>
            </div>
            <div class="mb-2 space-y-0.5">
                <div class="flex items-end justify-between space-x-4">
                    <strong>{{ _mft('course:runtimeTotal') }}:</strong>
                    <span>{{ playtimeFormatted }}</span>
                </div>
                <div class="flex items-end justify-between space-x-4">
                    <strong>{{ _mft('course:chapters') }}:</strong>
                    <span v-if="content.chapter_progress">{{ content.chapter_progress.length }}</span>
                </div>
                <div class="flex items-end justify-between space-x-4">
                    <strong>Kostnad:</strong>
                    <span v-if="content.purchase_method === 'free'">&nbsp;
                        {{ _mft('course:purchaseMethod.free') }}
                    </span>
                    <span v-else-if="content.purchase_method === 'lead'">&nbsp;
                        {{ _mft('course:purchaseMethod.lead') }}
                    </span>
                    <span v-else-if="content.purchase_method === 'cash_sek'">&nbsp;
                        {{ content.purchase_price_sek.toLocaleString('sv-SE') }}&nbsp;{{ _mft('course:purchaseMethod.cash.currency') }}
                    </span>
                    <span v-else>&nbsp;{{ _mft('course:purchaseMethod.time') }}</span>
                </div>
            </div>
        </template>
    </list-item>
</template>
<script>
import Playbutton from '../icons/playbutton.vue'

export default {
    components: {
        Playbutton
    },
    props: {
        content: Object,
        intro: Object,
        showImage: {
            type: Boolean,
            default: true
        },
        showRating: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    data: function () {
        return {
            favorited: false,
            previewing: false,
            trailerVideoUrl: null
        }
    },
    methods: {
        toggleDescription() {
            this.previewing = !this.previewing
        },
        async toggleFavorite() {
            let res = await axios.post(route('courses.toggleFavorite', this.content.id))
            this.favorited = res.data.favorited
        },
        closeTrailer() {
            this.trailerVideoUrl = null
        },
        async openTrailer() {
            this.trailerVideoUrl = route('course.trailer.playbackSession', this.content.id);
        },
        redirect(route) {
            this.$document.locationAssign(route);
        }
    },
    mounted() {
        this.favorited = this.content.favorited
    },
    computed: {
        renderedIntro: function () {
            if (this.intro && this.intro.length > 0) {
                return '<span class=""><img src="'+ this.content.featured_image +'" class="content-center inline float-left object-cover w-12 h-12 mr-4 rounded-full lg:w-14 lg:h-14 xl:w-24 xl:h-24" />' + this.intro + '</span>';
            } else {
                return null;
            }
        },
        fill: function () {
            if (this.favorited) {
                return 'currentColor'
            } else {
                return 'none'
            }
        },
        chapterProgress: function () {
            let i = 0
            this.content.chapter_progress.forEach(progress => {
                if (progress.length > 0) {
                    i++
                }
            });
            return i
        },
        playtimeFormatted: function () {
            return new Date(this.content.playtime_total * 1000).toISOString().slice(11, 19);
        },
        cardBackgroundClass: function () {
            if (this.content.provided_by_current_company) {
                return 'bg-branding bg-gradient-to-r from-branding-600 to-branding-900'
            } else {
                return 'bg-myflowPurple bg-gradient-to-r from-myflowPurple-600 to-myflowPurple-900'
            }
        },
        cardButtonClass: function () {
            if (this.content.provided_by_current_company) {
                return 'bg-branding'
            } else {
                return 'bg-myflowPurple-900'
            }
        },
        contentProviderShortname: function () {
            if (this.content.provider.name.split(' ').length > 3) {
                return this.content.provider.name;
            } else {
                return this.content.provider.name.split(' ')[0]
            }
        }
    }
}
</script>
<style scoped>
.text-mini {
    font-size: 12px;
}

.faded-top::after {
    position: absolute;
    content: '';
    top: 0.5rem;
    left: 0px;
    right: 0px;
    height: 3rem;
    background: transparent;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 1) 80%);
}

.faded-bottom::before {
    position: absolute;
    z-index: 2;
    content: '';
    bottom: 0.5rem;
    left: 0.5rem;
    right: 0.5rem;
    height: 4rem;
    background: transparent;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
}
</style>
