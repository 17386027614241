<template>
    <div class="bg-gradient-to-r from-myflowPurple-800 to-myflowPurple-700 w-full flex justify-center flex-wrap">
        <div style="max-width:1300px;" class="flex flex-col w-full h-full">
           <div class="flex flex-col md:flex-row text-xl my-14">
                <div class="text-white  md:text-start md:w-1/2 flex flex-col md:border-r-2 md:border-white p-8">
                    <h2 class="text-myflowYellow text-3xl">LÄRANDEINDEX - TA TEMPEN PÅ ERT FÖRETAG</h2>
                    <h2 class="text-3xl">ONLINE</h2> <br>
                    <p>Lärande som ger resultat, men hur? Vi gör en analys av lärandenivån på ert företag. Genom världsunika <strong>LÄRANDEINDEX</strong> får du fram specifika svar med utgångspunkt från nuläget i er verksamhet. Baserat på era svar går vi sedan igenom konkreta actions för hur ni kan höja nivån för att bli en mer lärande organisation.
                    Utveckling med målet att skapa välmående, engagerade medarbetare som formar vinnande team!</p> <br>   

                    <p> <strong>Steg 1</strong> – Gör myflows världsunika <strong> LÄRANDEINDEX </strong></p>
                    <p> <strong>Steg 2</strong> – Analys av era svar – se er score och hur ni rankar i olika kategorier inom lärande.</p>
                    <p> <strong>Steg 3</strong> – Få vetskap om er lärandetemperatur och en inblick i vilka actions ni kan ta för att utöka ert fokus på lärande.</p>
                       
                </div>
                <div class="flex md:w-1/2 items-center justify-center">  
                    <img class="w-full h-auto max-w-sm p-8" src="https://myflow.fra1.digitaloceanspaces.com/learning_index.png" alt="learning-index">
                </div>
           </div>

           <div class=" m-8 border-t-2 border-white md:border-none">
           </div>

            <div class="flex flex-col text-xl items-center justify-center md:flex-row my-14">
                <div class="flex md:w-1/2 items-center justify-center">  
                    <img class="w-full h-auto max-w-sm p-8" src="https://myflow.fra1.digitaloceanspaces.com/mastermind.png" alt="mastermind">
                </div>

                <div class="text-white md:w-1/2 flex flex-col md:border-l-2 md:border-white p-8">
                    <h2 class="text-myflowYellow text-3xl">MASTERMIND - WORKSHOP</h2>
                    <h2 class="text-3xl">FÖR DIG PÅ PLATS</h2> <br>
                    <p>Tillsammans med dina bordsgrannar kan ni djupdyka i de svar ni har fått från <strong> LÄRANDEINDEX </strong> och utbyta erfarenheter som hjälper er att komma framåt – vi kopierar framgång mellan varandra! Oavsett vad er lärandetemperatur visar så sätter vi tillsammans konkreta actions för hur ni ska kunna utöka ert lärandefokus.</p>
                    <p>Workshopen är indelad i tre block; <strong>Lärandekultur </strong>, <strong>lärandefokus </strong> och  <strong> kopiera framgång </strong>.</p>
                </div>
           </div>
           
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
};
</script>

<style scoped>

</style>
