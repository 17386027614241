<template>
    <div>
        <div class="divide-y">
            <div
                v-for="(paymentTerm, index) in _paymentTerms"
                v-bind:key="paymentTerm.id ? paymentTerm.id : paymentTerm.order"
                class="pt-2"
            >
                <paymentTermForm
                    :idx="index"
                    :paymentTerm="paymentTerm"
                    :subscribable="subscribable"
                    @removePaymentTerm="removePaymentTerm"
                />
            </div>
        </div>
        <div class="flex justify-end mt-6 mb-2">
            <button
                v-on:click.prevent="addPaymentTerm"
                class="myflow-basic-button--secondary"
            >{{ _mft('subscription:paymentTerm.addPaymentTerm') }}</button>
        </div>
    </div>
</template>
<script>
import paymentTermForm from './paymentTermForm.vue';

export default {
    components: {
        paymentTermForm
    },
    props: {
        subscribable: {
            type: Object,
            required: true
        },
        paymentTerms: {
            type: Array,
            required: true
        }
    },
    methods: {
        addPaymentTerm(){
            this._paymentTerms.push({
                id: null,
                order: this._paymentTerms.length,
                name: null,
                price_including_vat: null,
                payment_interval_days: null,
                available: null
            })
        },
        removePaymentTerm(idx) {
            this._paymentTerms.splice(idx, 1);
        },
    },
    data() {
        return {
            _paymentTerms: []
        }
    },
    mounted() {
        this._paymentTerms = this.paymentTerms;
    }
}
</script>
