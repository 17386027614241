<template>
    <div v-if="value">
        <input-text
            label="Titel / Fråga"
            :required="true"
            v-model:content="value.title"
        />
        <toggle-switch
            label="Krävs"
            v-model:content="value.required"
        ></toggle-switch>

        <div
            v-for="(alternative, alternativeIdx) in value.alternatives"
            :key="alternativeIdx"
            class="flex items-center justify-between space-x-4 mb-3"
        >
            <input-text
                :label="`Alternativ ${alternativeIdx + 1}`"
                :required="true"
                v-model:content="alternative.title"
                class="flex-grow"
            />
            <button
                v-if="alternativeIdx > 0 || value.alternatives.length > 1"
                type="button"
                class="myflow-basic-button--danger mt-2"
                @click.prevent="removeAlternative(alternativeIdx)"
            ><i class="fa-light fa-square-minus"></i></button>
        </div>
        <div class="text-right">
            <button
                type="button"
                class="myflow-basic-button"
                @click.prevent="addAlternative()"
            ><i class="fa-light fa-square-plus"></i> Lägg till alternativ</button>
        </div>
    </div>
    <mf-spinner-medium v-else class="w-16 h-8"></mf-spinner-medium>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';

export default {
    props: {
        modelValue: {},
    },
    emits: [
        'update:modelValue'
    ],
    data() {
        return {
            value: null
        };
    },
    async mounted() {
        this.captureModelValue();
    },
    watch: {
        modelValue() {
            if (JSON.stringify(this.value) === JSON.stringify(this.modelValue)) { return; }
            this.captureModelValue();
        },
        value: {
            handler() {
                this.$emit('update:modelValue', JSON.parse(JSON.stringify(this.value)));
            },
            deep: true
        }
    },
    methods: {
        captureModelValue() {
            const value = this.modelValue ? JSON.parse(JSON.stringify(this.modelValue)) : {};
            if (!value.uuid) { value.uuid = uuidv4(); }
            if (!value.title) { value.title = null; }
            if (!value.required) { value.required = false; }
            if (!value.alternatives) { value.alternatives = [{title: null}]; }
            this.value = value;
        },
        addAlternative() {
            this.value.alternatives.push({title: null});
        },
        removeAlternative(idx) {
            this.value.alternatives.splice(idx, 1);
        }
    },
}
</script>
