<template>
    <div class="pb-4">
        <input
            type="hidden"
            :name="'terms['+ idx +'][id]'"
            v-model="_paymentTerm.id"
        >
        <input-text
            :name="'terms['+ idx +'][name]'"
            label="Namn"
            :required="true"
            v-model:content="_paymentTerm.name"
        />
        <div class="grid-cols-6 gap-4 md:grid lg:block 2xl:grid">
            <div class="col-span-4">
                <input-number
                    :name="'terms['+ idx +'][price_including_vat]'"
                    :label="_mft('subscription:priceIncludingVat', {currency: 'SEK'})"
                    :required="true"
                    :content="_paymentTerm.price_including_vat"
                    v-model:content="_paymentTerm.price_including_vat"
                />
            </div>
            <div class="col-span-2">
                <input-select
                    :name="'terms['+ idx +'][payment_interval_days]'"
                    :label="_mft('subscription:paymentInterval')"
                    :required="true"
                    :options="payment_intervals"
                    labelSpecification="title"
                    valueSpecification="days"
                    v-model:content="_paymentTerm.payment_interval_days"
                />
            </div>

            <div class="col-span-3">
                <toggle-switch
                :label="_mft('subscription:active')"
                v-model:content="available"
                :name="'terms['+ idx +'][available]'"
                ></toggle-switch>
            </div>
            <div class="flex items-center justify-end col-span-3">
                <button
                    class="text-xs myflow-basic-button--danger"
                    v-on:click.prevent="removePaymentTerm"
                >
                    {{ _mft('shared:action.delete') }}
                </button>

            </div>
        </div>
    </div>
</template>
<script>
export default {
    emits: ['removePaymentTerm'],
    props: {
        idx: {
            type: Number,
            required: true
        },
        paymentTerm: {
            type: Object,
            required: true
        },
        subscribable: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            available: false,
            _paymentTerm: {
                id: null,
                name: null,
                price_including_vat: null,
                payment_interval_days: null,
                available: null
            },
            payment_intervals: [
                {
                    days: 7,
                    title: _mft('subscription:paymentInterval.weekly'),
                },
                {
                    days: 90,
                    title: _mft('subscription:paymentInterval.quarterly'),
                },
                {
                    days: 182,
                    title: _mft('subscription:paymentInterval.halfYear'),
                },
                {
                    days: 365,
                    title: _mft('subscription:paymentInterval.yearly'),
                },
                {
                    days: 30,
                    title: _mft('subscription:paymentInterval.months', {count: 1}),
                },
                {
                    days: 60,
                    title: _mft('subscription:paymentInterval.months', {count: 2}),
                },
                {
                    days: 30,
                    title: _mft('subscription:paymentInterval.months', {count: 3}),
                },
                {
                    days: 120,
                    title: _mft('subscription:paymentInterval.months', {count: 4}),
                },
                {
                    days: 150,
                    title: _mft('subscription:paymentInterval.months', {count: 5}),
                },
                {
                    days: 180,
                    title: _mft('subscription:paymentInterval.months', {count: 6}),
                },
                {
                    days: 210,
                    title: _mft('subscription:paymentInterval.months', {count: 7}),
                },
                {
                    days: 240,
                    title: _mft('subscription:paymentInterval.months', {count: 8}),
                },
                {
                    days: 270,
                    title: _mft('subscription:paymentInterval.months', {count: 9}),
                },
                {
                    days: 300,
                    title: _mft('subscription:paymentInterval.months', {count: 10}),
                },
                {
                    days: 330,
                    title: _mft('subscription:paymentInterval.months', {count: 11}),
                },
                {
                    days: 410,
                    title: _mft('subscription:paymentInterval.prePurchase'),
                },
            ]
        }
    },
    methods: {
        async removePaymentTerm() {
            if( confirm(_mft('shared:confirm.areYouSure')) ){
                if( this._paymentTerm.id ){
                    let res = await axios.delete(route('subscribables.payment_term.delete', [this.subscribable, this.paymentTerm]));

                    if( res.data.success ){
                        location.reload();
                    }
                } else {
                    this.$emit('removePaymentTerm', this.idx);
                }
            }
        }
    },
    mounted() {
        this._paymentTerm = JSON.parse(JSON.stringify(this.paymentTerm));
        this._paymentTerm.price_including_vat = this._paymentTerm.price_including_vat / 100;
        this.available = !!this._paymentTerm.available;
    },
    watch: {
        available: {
            handler: function (val) {
                this._paymentTerm.available = !!this.available;
            },
        }
    }
}
</script>
