<template>
    <div v-if="value">
        <div
            class="grid gap-5 mb-3"
            :class="layout === 'half' ? 'grid-cols-1' : 'grid-cols-1 lg:grid-cols-2'"
        >
            <input-media-bank-entry
                type="image"
                label="Välj en bild från mediabiblioteket"
                required
                v-model:content="value.media_bank_entry_id"
            />
            <media-bank-entries-display v-if="value.media_bank_entry_id" :data-url="route('mediaBank.entries.show', value.media_bank_entry_id)" />
        </div>
        <input-text
            label="Rubrik"
            required
            v-model:content="value.title"
        />
        <input-editor
            label="Innehåll"
            required
            v-model:content="value.body"
        />
        <toggle-switch
            label="Lägg bilden till höger om texten"
            v-model:content="value.horizontal"
        ></toggle-switch>
    </div>
    <mf-spinner-medium v-else class="w-16 h-8"></mf-spinner-medium>
</template>
<script>
export default {
    props: {
        modelValue: {},
        layout: {
            type: String,
            default: 'full'
        }
    },
    emits: [
        'update:modelValue'
    ],
    data() {
        return {
            value: null
        };
    },
    async mounted() {
        this.captureModelValue();
    },
    watch: {
        modelValue() {
            if (JSON.stringify(this.value) === JSON.stringify(this.modelValue)) { return; }
            this.captureModelValue();
        },
        value: {
            handler() {
                this.$emit('update:modelValue', JSON.parse(JSON.stringify(this.value)));
            },
            deep: true
        }
    },
    methods: {
        captureModelValue() {
            const value = this.modelValue ? JSON.parse(JSON.stringify(this.modelValue)) : {};
            if (!value.titel) { value.titel = null; }
            if (!value.body) { value.body = null; }
            if (!value.horizontal) { value.horizontal = false; }
            if (!value.media_bank_entry_id) { value.media_bank_entry_id = null; }
            this.value = value;
        }
    },
}
</script>
