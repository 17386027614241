<script setup>
import { inject } from 'vue';
import { useNotificationStore } from '@/stores/NotificationStore.js'
import axios from 'axios';

const notificationStore = useNotificationStore()
const emit = defineEmits(['reload', 'selectChannel'])
const {memberships} = inject('memberships');

const selectChannel = (channel) => {
    emit('selectChannel', channel);
}

const leaveCommunity = async (membership) => {
    const res = await axios.delete(route('community.member.leaveCommunity', membership.community_id));

    if (res.data.success) {
        notificationStore.addNotification({
            type: 'success',
            title: _mft('community:leaveCommunity.leftCommunity'),
            dismissAfter: 5000,
        })
    } else {
        notificationStore.addNotification({
            type: 'error',
            title: _mft('error:error.generalOccurred'),
            dismissAfter: 5000,
        })
    }

    emit('reload');
}

</script>

<template>
    <div>
        <div
            v-for="membership in memberships"
            :key="membership.id"
        >
            <list-basic>
                <template #header>
                    <div class="flex justify-between space-x-4 mb-2">
                        <h5>{{ membership.community.name }}</h5>
                        <button-with-confirmation
                            size="sm"
                            @confirmed="leaveCommunity(membership)"
                            modalClass="absolute -top-2 -right-2 p-2 bg-white text-black rounded shadow-md z-20"
                            :confirmButtonLabel="_mft('shared:confirm.continue')"
                            confirmButtonClass="myflow-basic-button--danger whitespace-nowrap"
                            :confirm-text="_mft('community:leaveCommunity.confirmation')"
                        >
                            {{ _mft('community:leaveCommunity.label') }}
                        </button-with-confirmation>
                    </div>
                </template>
                <list-basic-item
                    v-for="channel in membership.community.channels"
                    :key="channel.id"
                    :class="channel.archived ? 'opacity-50' : ''"
                >
                    <template #title>
                        # {{ channel.name }}
                    </template>

                    <template #actions>
                        <button-base
                            size="sm"
                            badge=""
                            v-on:click="selectChannel(channel)"
                            class="relative"
                        >
                            <span>
                                View
                            </span>
                        </button-base>
                    </template>
                </list-basic-item>
            </list-basic>
        </div>
    </div>
</template>
