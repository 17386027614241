<script setup>
import {ref, watch} from 'vue'

const emit = defineEmits(['successfulLogin'])
const username = ref('')
const password = ref('')
const loginError = ref('')

const doLogin = async () => {
    const res = await axios.post(route('mfLogin'), {
        email: username.value,
        password: password.value
    })

    if (res.data.error) {
        loginError.value = res.data.error
    }

    setTimeout(function(){
        emit('successfulLogin', res.data.user)
    }, 2000)
}

watch([username, password], () => {
    loginError.value = ''
}, {deep: true})

</script>

<template>
    <div>
        <input-text
            :label="_mft('auth:credentials.email')"
            v-model:content="username"
            @keyup.enter="doLogin"
            autofocus
        ></input-text>

        <input-text
            :label="_mft('auth:credentials.password')"
            inputtype="password"
            v-model:content="password"
            @keyup.enter="doLogin"
        ></input-text>

        <div class="flex items-center justify-center mt-4">
            <button-primary
                @click="doLogin"
            >
                {{ _mft('auth:doLogin') }}
            </button-primary>
        </div>

        <p class="min-h-4 text-center mt-2 mb-0 text-xs">
            <template  v-if="loginError !== ''">
                {{ _mft(loginError)}}
            </template>
        </p>

        <div class="text-center mt-2 text-xs">
            <p class="text-gray-600 mb-1">
                {{ _mft('auth:resetPassword.title') }}
            </p>
            <a :href="route('password.request')" target="_blank" class="text-inherit underline">
                {{ _mft('auth:resetPassword.button') }}
            </a>
        </div>
    </div>
</template>

<style scoped>

</style>
