<template>
    <table v-if="logs.length > 0">
        <tr>
            <th>Event</th>
            <th>Data</th>
        </tr>
        <tr v-for="(log, idx) in logs" :key="idx.id" class="mb-3">
            <td>{{log.event}}</td>
            <td>{{log.data}}</td>
        </tr>
    </table>
    <div v-else>
        <mf-spinner-medium
          class="w-24 h-12"
        />
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
import { v4 as uuid } from 'uuid';

export default {
    props: {
        userId: Number
    },
    data() {
        return {
            logs: [],
            echo: EchoFactory(),
        };
    },
    mounted() {
        this.echo
            .private(`users.${this.userId}.bankid`)
            .listenToAll(this.onEvent);
    },
    beforeUnmount() {
        this.echo.leave(`users.${this.userId}.bankid`);
    },
    methods: {
        onEvent(event, data) {
            this.logs.push({event, data});
        }
    },
};
</script>
