<script setup>

import {computed, onMounted, ref, watch} from "vue";
import ButtonWithConfirmation from "../buttons/buttonWithConfirmation.vue";

const props = defineProps({
    type: {
        type: String,
        required: true
    },
})

const emit = defineEmits('add', 'close')
const parts = ref([])

const loadParts = async () => {
    const response = await axios.get(route('pages.pagebuilder.parts', props.type))
    parts.value = response.data.parts
}

const addPart = async (part) => {
    const response = await axios.get(route('pages.pagebuilder.part', part.id))

    if (response.data.success) {
        emit('add', response.data.part)
    }
}

const deletePart = async (part) => {
    const response = await axios.delete(route('pages.pagebuilder.part', part.id))

    if (response.data.success) {
        await loadParts()
    }
}

const availableParts = computed(() => {
    return parts.value.filter(part => part.type === props.type)
})

watch(() => props.type, () => {
    if (props.type !== '') {
        loadParts()
    } else {
        parts.value = []
    }
})

onMounted(() => {
})

</script>

<template>
    <slideout-with-slots
        v-if="type !== ''"
        @close="close"
    >
        <template #title>
            <h2>{{ _mft('pagebuilder:addSavedPart.' + type) }}</h2>
        </template>

        <template #body>
            <ul class="-mx-4 divide-y lg:-mx-6">
                <li
                    v-for="(part, i) in availableParts"
                    :key="i"
                    class="flex items-start justify-between px-4 py-2 space-x-2 hover:bg-gray-100 lg:px-6"
                >
                    {{ part.title }}
                    <div class="flex space-x-1">
                        <button-with-confirmation
                            size="sm"
                            @confirmed="deletePart(part)"
                            modalClass="absolute -top-2 -right-2 p-2 bg-white rounded shadow-md z-10 text-black"
                            :buttonLabel="_mft('shared:action.delete')"
                            buttonClass="text-wlPrimaryContrast"
                            :confirmButtonLabel="_mft('shared:action.delete')"
                            confirmButtonClass="myflow-basic-button--success whitespace-nowrap"
                            :confirm-text="_mft('shared:confirm.areYouSure')"
                        ></button-with-confirmation>
                        <button-secondary
                            @click="addPart(part)"
                            size="sm"
                        >
                            {{ _mft('shared:action.add') }}
                        </button-secondary>
                    </div>
                </li>
            </ul>
            <p
                v-if="availableParts.length === 0"
                class="py-2 text-center text-gray-400"
            >
                {{ _mft('pagebuilder:addSavedPart.'+type+'.noneToShow') }}
            </p>
        </template>
    </slideout-with-slots>
</template>

<style scoped>

</style>
