<script setup>
const props = defineProps({
    content: {
        type: Object,
        required: true,
    },
})
</script>
<template>
    <div>
        <div
            :class="'text-' + props.content?.alignment"
            v-html="props.content?.text"
        ></div>
    </div>
</template>

<style scoped>
:deep(ul) {
    list-style-type: disc;
    padding-left: 1em;
}
</style>
