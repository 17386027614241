<template>
    <div
        class="bg-gradient-to-r from-myflowPurple-900 to-myflowPurple-700 h-46 w-full flex justify-center flex-col items-center"
    >
        <div
            style="max-width: 1300px"
            class="flex justify-center items-center flex-col pt-10 w-full"
        >
            <div
                class="lg:flex-row md:flex-row hidden md:flex lg:flex md:justify-evenly lg:justify-evenly w-10/12 ml-4 mr-4"
            >
                <expert-image-component
                    v-for="img in expertImages"
                    v-bind:key="img.img"
                    :img="img"
                />
            </div>

            <div class="text-white fontFamily flex flex-col text-center mb-24">
                <p class="pb-6 pt-7 text-2xl">
                    Smartaste eventet med <strong> fokus på lärande!</strong>
                    <br />
                    Genom ständig utveckling skapar vi välmående individer
                    <br />
                    – och
                    <strong> formar vinnande team. </strong>
                </p>
                <h2 class="text-2xl">
                    Få nycklar och verktyg för att komma igång med en lärande
                    kultur på ditt företag.
                </h2>
            </div>
        </div>
        <div class="">
            <svg
                class="rotate-180 h-14 w-screen svg-fill"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 1000 100"
                preserveAspectRatio="none"
            >
                <path
                    d="M194,99c186.7,0.7,305-78.3,306-97.2c1,18.9,119.3,97.9,306,97.2c114.3-0.3,194,0.3,194,0.3s0-91.7,0-100c0,0,0,0,0-0 L0,0v99.3C0,99.3,79.7,98.7,194,99z"
                ></path>
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            expertImages: [
                {
                    img: 'https://myflow.fra1.digitaloceanspaces.com/tpol/compressed/Mikael_Ahlstrom.webp',
                },
                {
                    img: 'https://myflow.fra1.digitaloceanspaces.com/tpol/compressed/Anna_Carlsson.webp',
                },
                {
                    img: 'https://myflow.fra1.digitaloceanspaces.com/tpol/compressed/Matti_Olofsson1.webp',
                },
                {
                    img: 'https://myflow.fra1.digitaloceanspaces.com/tpol/compressed/Anna_Tebelius_Bodin_1.webp',
                },
                {
                    img: 'https://myflow.fra1.digitaloceanspaces.com/tpol/compressed/Oscar_Valentin.webp',
                },
                {
                    img: 'https://myflow.fra1.digitaloceanspaces.com/tpol/compressed/Anna_Forsberg.webp',
                },
            ],
        };
    },
};
</script>

<style scoped>
.svg-fill {
    fill: white;
}
</style>
