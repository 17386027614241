<template>
  <div class="mb-4">
    <input-label
      :label="label"
      :required="required"
    />
    <div
      class="mt-1 relative rounded-md shadow-sm"
    >
      <button
          v-if="showStepUi && content > min"
          class="w-6 h-6 flex justify-center items-center rounded-full bg-wlPrimary text-wlPrimaryContrast absolute -left-2.5 top-1/2 transform -translate-y-1/2"
          @click="setValue(content - step)"
      >
          <i class="fa-solid fa-minus"></i>
      </button>
      <input
        type="number"
        class="block w-full mt-1 p-2 border rounded-md"
        :class="[inputClass, showStepUi ? 'px-4 text-center' : '']"
        :placeholder="placeholder"
        :value="content"
        @input="setValue($event.target.value)"
        @blur="$emit('blur')"
        :required="required"
        :name="name"
        :disabled="disabled"
        :min="min"
        :max="max"
        :step="step"
      />
      <button
        v-if="showStepUi && content < max"
        class="w-6 h-6 flex justify-center items-center rounded-full bg-wlPrimary text-wlPrimaryContrast absolute -right-2.5 top-1/2 transform -translate-y-1/2"
        @click="setValue(content + step)"
      >
          <i class="fa-solid fa-plus"></i>
      </button>
    </div>
    <input-error v-if="error" :error="error" />
  </div>
</template>
<script>
export default {
  setup() {

  },
  data: function(){
    return {
      inputClass: ''
    }
  },
  props: {
    content: Number,
    error: String,
    label: String,
    placeholder: String,
    showStepUi: {
        type: Boolean,
        required: false,
        default: false,
    },
    required: Boolean,
    min: {
        type: String,
        required: false,
        default: ''
    },
    max: {
        type: String,
        required: false,
        default: ''
    },
    step: {
        type: Number,
        required: false,
        default: 1,
    },
    name: String,
    disabled: {
        type: Boolean,
        default: false
    }
  },
  methods: {
    setValue(value){
        let finalValue = parseInt(value, 10)
        if (this.step && 1 > this.step) {
          finalValue = Math.round(parseFloat(value) * 100) / 100;
        }
      this.$emit('update:content', finalValue)
      this.$emit('input')
    }
  },
  watch: {
    error: function(error){
      let classes = [];
      if( error !== '' ){
        classes.push('border-red-700')
      } else {
        classes.push('border-gray')
      }
      this.inputClass = classes.join(' ')
    }
  }
}
</script>
