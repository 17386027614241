<script setup>
    import { computed, onMounted, ref } from 'vue';
    import { useNotificationStore } from '@/stores/NotificationStore.js'
    const notificationStore = useNotificationStore()

    const emit = defineEmits(['close', 'doReloadLeads']);

    const props = defineProps({
        selectedLeads: {
            type: Array,
            required: true
        },
        showOutreachInitiate: {
            type: Boolean,
            default: false
        },
        showTopbar: {
            type: Boolean,
            required: false,
            default: true,
        },
        selectedTagIds: {
            type: Array,
            required: false,
            default: () => []
        },
        instantAssign: {
            type: Boolean,
            required: false,
            default: false
        }
    });

    const availableTags = ref([]);
    const search = ref('');
    const showAll = ref(false);
    const selectedTagId = ref(null);

    const loadTags = () => {
        axios.get(route('crm.leadtags.index')).then((response) => {
            availableTags.value = response.data.tags;
        });
    }

    const createTag = () => {
        axios.post(route('crm.leadtags.store'), {
            name: search.value
        }).then((response) => {
            selectTag(response.data.tag.id)
            loadTags();

        });
    }

    const selectTag = (tagId) => {
        if (selectedTagId.value === tagId) {
            selectedTagId.value = null;
        } else {
            selectedTagId.value = tagId;
        }

        if (props.instantAssign) {
            assignTag();
        }
    }

    const assignTag = () => {
        axios.post(route('crm.assignTags', selectedTagId.value), {
            selected: props.selectedLeads
        }).then((response) => {
            search.value = '';
            if (! props.instantAssign) {
                notificationStore.addNotification({
                    type: 'success',
                    title: response.data.tag.name,
                    message: _mft('crm:tag.assigned'),
                    dismissAfter: 1500,
                })
            }
            emit('doReloadLeads')
            selectedTagId.value = null;
        });
    }

    const filteredTags = computed(() => {
        return availableTags.value.filter((tag) => {
            return ! props.selectedTagIds.includes(tag.id)
        }).filter((tag) => {
            return tag.name.toLowerCase().includes(search.value.toLowerCase());
        });
    });

    const limitedFilteredTags = computed(() => {
        if (showAll.value === true) {
            return filteredTags.value;
        } else {
            return filteredTags.value.slice(0, 5);
        }
    });

    const moreTagsAvailable = computed(() => {
        return filteredTags.value.length - 5;
    });

    const close = () => {
        emit('close');
    }

    const closeAndUpdateLeads = () => {
        emit('doReloadLeads');
        emit('close');
    }

    onMounted(() => {
        loadTags();
    });

</script>
<template>
    <div>
        <div
            v-if="props.showTopbar"
            class="flex justify-between space-x-2">
            <div class="text-gray-400">
                {{ _mft('crm:tag.leadsSelected', {count: selectedLeads.length }) }}
            </div>
            <button-danger
                class="text-white bg-red-700 !border-0"
                size="sm"
                @click="close"
            >
                {{ _mft('shared:cancel') }}
            </button-danger>
        </div>
        <div class="relative mb-2">
            <input-text
                class="!mb-0"
                v-model:content="search"
                :maxlength="24"
                :placeholder="_mft('crm:tag.searchTags')"
            />
            <button
                v-if="search"
                class="absolute top-2 right-1 bottom-1 rounded-md bg-black px-2 py-1 text-white"
                @click="createTag"
            >{{ _mft('crm:tag.createNew') }}</button>
        </div>
        <ul class="mb-4 flex flex-wrap">
            <li
                v-for="tag in limitedFilteredTags"
                :key="tag.id"
                @click="selectTag(tag.id)"
                class="mr-2 mb-1 cursor-pointer rounded-md text-black px-1.5 py-0.5 border-1"
                :class="selectedTagId === tag.id ? 'bg-gray-300 ring-2 ring-gray-500' : 'bg-gray-200 hover:bg-gray-300'"
            >
                {{ tag.name }}
            </li>
            <li
                v-if="moreTagsAvailable > 0 && !showAll"
                class="mr-2 cursor-pointer mt-0.5 underline"
                @click="showAll = true"
            >{{ _mft('crm:tag.andAnotherNMore', {count: moreTagsAvailable }) }}</li>
        </ul>
        <div class="flex justify-end space-x-4">
            <slot name="actions"></slot>
            <button-success
                v-if="!instantAssign"
                class="text-white !border-0"
                :class="!selectedTagId || selectedLeads.length === 0 ? 'bg-gray-500 cursor-not-allowed' : 'bg-green-700'"
                @click="assignTag"
                size="sm"
                :disabled="!selectedTagId || selectedLeads.length === 0"
            >
                <span v-if="selectedTagId && selectedLeads.length > 0">
                    {{ _mft('crm:tag.assignToNLeads', {count: selectedLeads.length}) }}
                </span>
                <span v-else>{{ _mft('crm:tag.selectTagToAssign') }}</span>
            </button-success>
        </div>
        <outreach-multi-initiate
            v-if="showOutreachInitiate"
            class="mt-2 border-t pt-2"
            :selected-leads="selectedLeads"
            @outreachInitiated="closeAndUpdateLeads"
        />
    </div>
</template>
