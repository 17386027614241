<script setup>
import {onMounted, ref} from "vue";
import ButtonWithConfirmation from "../buttons/buttonWithConfirmation.vue";
import IconChevron from "../icons/iconChevron.vue";
import IconMinus from "../icons/iconMinus.vue";

const emit = defineEmits(['close', 'restoreRevision'])
const props = defineProps({
    containerRef: {
        type: String,
        required: false,
        default: null
    }
})

const timeslots = ref([])
const expandedTimeslots = ref([])
const loading = ref(true)

const getRevisions = async () => {
    const res = await axios.get(route('content-revisions.getRevisions', props.containerRef))
    timeslots.value = res.data.revisions
    loading.value = false
}

const toggleTimeslot = (timeslot) => {
    if (expandedTimeslots.value.includes(timeslot)) {
        expandedTimeslots.value = expandedTimeslots.value.filter(t => t !== timeslot)
    } else {
        expandedTimeslots.value.push(timeslot)
    }
}

const restoreRevision = (revision) => {
    emit('restoreRevision', revision)
}

onMounted(() => {
    getRevisions()
})

</script>

<template>
    <slideout-with-slots
        @close="emit('close')"
        :title="_mft('pagebuilder:revisions.title')"
    >
        <template #body>
            <mf-spinner-medium
                v-if="loading"
                class="w-12 h-6"
            ></mf-spinner-medium>
            <div v-else>
                <p
                    v-if="timeslots.length === 0"
                    class="text-xs text-gray-400 text-center"
                ></p>
                <div v-else>
                    <p class="text-xs">
                        {{ _mft('pagebuilder:revisions.intro') }}
                    </p>
                    <ul class="divide-y">
                        <li
                            v-for="(revisions, timeslot) in timeslots"
                            :key="timeslot"
                            class="-mx-4 px-4 lg:-mx-6 lg:px-6"
                            :class="expandedTimeslots.includes(timeslot) ? 'bg-gray-100' : ''"
                        >
                            <div
                                class="flex items-center justify-between py-2 space-x-2"
                            >
                                <div>{{ timeslot }}</div>
                                <button-transparent
                                    @click="toggleTimeslot(timeslot)"
                                    size="sm"
                                >
                                    <icon-minus class="h-4 w-4" v-if="expandedTimeslots.includes(timeslot)" />
                                    <icon-chevron
                                        v-else
                                        class="h-4 w-4"
                                    />
                                </button-transparent>
                            </div>
                            <div
                                v-if="expandedTimeslots.includes(timeslot)"
                                class="-mx-4 border-t border-gray-200 bg-gray-100 px-4 lg:-mx-6 lg:px-6"
                            >
                                <ul class="divide-y">
                                    <li
                                        v-for="(revision, i) in revisions"
                                        :key="i"
                                        class="-mx-4 flex items-center justify-between px-4 text-xs group space-x-2 hover:bg-gray-200 lg:-mx-6 lg:px-6"
                                    >
                                        <div class="py-2">
                                            {{ _mft('shared:save.savedAt') }} {{ revision.time }}
                                        </div>
                                        <div class="">
                                            <button-with-confirmation
                                                size="sm"
                                                @confirmed="restoreRevision(revision)"
                                                modalClass="absolute -top-2 -right-2 p-2 bg-white rounded shadow-md z-40 text-black"
                                                confirmType="secondary"
                                                :buttonLabel="_mft('pagebuilder:revisions.restoreRevision')"
                                                :confirmButtonLabel="_mft('shared:confirm.continue')"
                                                :confirm-text="_mft('shared:confirm.areYouSure')"
                                            >
                                            </button-with-confirmation>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </template>
    </slideout-with-slots>
</template>

<style scoped>

</style>
