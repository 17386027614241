<script setup>
import {
    reactive,
    onMounted,
} from 'vue';

const props = defineProps({
    uuid: String,
    iconClass: {
        type: String,
        required: false,
        default: 'w-12 h-12 md:w-20 md:h-20 text-xl md:text-4xl'
    },
    iconSizeClass: {
        type: String,
        required: false,
        default: 'text-xl md:text-4xl'
    },
    containerClass: {
        type: String,
        required: false,
        default: ''
    },
});

const data = reactive({
    open: false,
    unreadCount: null,
});

function toggleOpen(event) {
    event.preventDefault();
    data.open = !data.open;
}

function updateUnreadCount(count) {
    data.unreadCount = count;
}

onMounted(() => {
    axios.get(route('expert-contracts.unread-uuid', props.uuid))
        .then(response => {
            data.unreadCount = response.data;
        })
        .catch(error => {
            console.error(error);
        });
});

</script>
<template>
    <div :class="props.containerClass">
        <button
            class="text-white duration-300 bg-gray-400 rounded-full z-90 drop-shadow-lg hover:bg-gray-500 hover:drop-shadow-2xl"
            :class="props.iconClass"
            v-on:click="toggleOpen"
        >
            <div class="relative w-full h-full flex items-center justify-center">
                <i
                    class="fa-regular fa-messages"
                    :class="[data.unreadCount > 0 ? 'wiggle' : '', iconSizeClass]"
                ></i>
                <span
                    v-if="data.unreadCount > 0"
                    class="absolute right-0 flex items-center justify-center w-6 md:w-8 h-6 md:h-8 text-sm text-white rounded-full -top-1 md:-top-2 bg-myflowRed-900"
                >{{ data.unreadCount }}</span>
            </div>
        </button>
        <basic-slideout
          v-model:open="data.open"
        >
            <template v-slot:header>
                <h3>{{ _mft('expertContract:clientMessages.conversation.domainNameSingular') }}</h3>
            </template>
            <template v-slot:body>

                <div class="absolute inset-0 overflow-hidden">
                    <message-conversation
                        :get-url="route('expert-contracts.public-message', props.uuid)"
                        :post-url="route('expert-contracts.store-message', props.uuid)"
                        :mark-as-read-url="route('expert-contracts.public-message.mark-as-read', props.uuid)"
                        :poll-for-new="true"
                        @unreadCountUpdate="updateUnreadCount"
                    ></message-conversation>
                </div>
            </template>
        </basic-slideout>
    </div>
</template>
