/*
Basic usage:

Get a Boolean response from the server/cache
_mfHas('company:integrity-policy')

Forget the cache
_mfHasForget('company:integrity-policy')

 */

import axios from "axios";

export async function _mfHas(key) {
    const [type, check] = key.split(':');

    const res = await axios.get(route('mf.has', [type, check]));
    return res.data == true;
}

export async function _mfHasForget(key) {
    const [type, check] = key.split(':');
    const res = await axios.get(route('mf.has.forget', [type, check]));
    return res.data
}
