<script setup>
    import { computed, ref } from 'vue'
    import SignatureRequests from './signatureRequests.vue'
    import ListItem from './listItem.vue'

    const props = defineProps({
        expertContracts: {
            type: Array,
            required: true
        },
        currentFolderId: {
            type: [Number, String],
            required: false,
            default: null
        }
    })

    const expertContracts = ref(props.expertContracts)
    const listOrder = ref('latest')
    const searchTerm = ref('')

    const loadExpertContracts = async () => {
        let contractRoute = route('expert-contracts.index')
        if (props.currentFolderId) {
            contractRoute = route('expert-contracts.showFolder', props.currentFolderId)
        }

        const response = await axios.get(contractRoute)
        expertContracts.value = response.data.expert_contracts
    }

    const listableExpertContracts = computed(() => {
        const filteredItems = expertContracts.value.filter((expertContract) => {
            return expertContract.title.toLowerCase().includes(searchTerm.value.toLowerCase())
        })

        if (listOrder.value === 'latest') {
            return filteredItems.reverse()
        } else {
            return filteredItems
        }
    })

</script>
<template>
    <div>
        <div class="flex justify-between items-center mb-4">
            <div>
                <input-text
                    class="!mb-0"
                    :placeholder="_mft('shared:search')"
                    v-model:content="searchTerm"
                />
            </div>
            <div>
                <button-transparent
                    v-if="listOrder === 'oldest'"
                    class="text-xs text-gray-600 underline"
                    @click="listOrder = 'latest'"
                >
                    <mf-translate domainkey="shared:showLatestFirst"></mf-translate>
                </button-transparent>
                <button-transparent
                    v-else
                    class="text-xs text-gray-600 underline"
                    @click="listOrder = 'oldest'"
                >
                    <mf-translate domainkey="shared:showOldestFirst"></mf-translate>
                </button-transparent>
            </div>
        </div>

        <div class="overflow-hidden bg-white shadow-xl sm:rounded-wl">
            <ul class="divide-y divide-gray-300">
                <li
                    v-for="(expertContract, index) in listableExpertContracts"
                >
                    <ListItem
                        :expert-contract="expertContract"
                        @doRefresh="loadExpertContracts"
                    />
                </li>
            </ul>
        </div>
    </div>
</template>
