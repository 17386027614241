<script setup>
    import { onMounted, reactive, ref, computed } from 'vue'

    const props = defineProps({
        expertContractId: {
            type: Number,
            required: true
        },
        messagingEnabled: {
            type: Boolean,
            required: false,
            default: false
        }
    })

    const state = reactive({
        loading: false,
    })

    const signatureRequests = ref([])

    const loadSignatureRequests = async () => {
        state.loading = true
        const response = await axios.get(route('expert-contracts.signature-requests.index', props.expertContractId))

        signatureRequests.value = response.data.signature_requests
        state.loading = false
    }

    const showMessaging = computed(() => {
        return props.messagingEnabled && signatureRequests.value.filter((signatureRequest) => {
            return signatureRequest.current_user_is_signee
        }).length > 0
    })

    const reload = () => {
        loadSignatureRequests()
    }

    onMounted(() => {
        loadSignatureRequests()
    })

    defineExpose({
        reload
    })
</script>
<template>
    <div v-if="showMessaging">
        <ul>
            <li
                v-for="(signatureRequest, index) in signatureRequests"
            >
                <client-messages-slideout
                    v-if="signatureRequest.current_user_is_signee"
                    :uuid="signatureRequest.uuid"
                    containerClass="flex justify-end"
                    iconSizeClass="text-lg md:text-xl"
                    iconClass="w-10 h-10 md:w-12 md:h-12"
                ></client-messages-slideout>
            </li>
        </ul>
    </div>
</template>
