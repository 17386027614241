<script setup>
    import {computed} from "vue";

    const props = defineProps({
        content: {
            type: Object,
            required: false,
            default: {
                alignment: 'center',
                label: '',
                url: '',
                cssColor: null,
                cssBackgroundColor: null
            }
        }
    })

    const classes = computed(() => {
        const classes = [];

        if (props.content.cssColor) {
            classes.push('mfpb-custom-color');
        }
        if (props.content.cssBackgroundColor) {
            classes.push('mfpb-custom-background-color');
        }

        if (props.content.size === 'sm') {
            classes.push('text-xs');
            classes.push('px-2');
            classes.push('py-1');
        }
        if (props.content.size === 'md') {
            classes.push('text-base');
            classes.push('px-3');
            classes.push('py-1.5');
        }
        if (props.content.size === 'lg') {
            classes.push('text-lg');
            classes.push('px-4');
            classes.push('py-2');
        }
        if (props.content.size === 'xl') {
            classes.push('text-xl');
            classes.push('px-5');
            classes.push('py-3');
        }
        if (props.content.size === '2xl') {
            classes.push('text-2xl');
            classes.push('px-6');
            classes.push('py-4');
        }

        if (props.content.alignment === 'fullwidth') {
            classes.push('w-full text-center');
        }

        return classes.join(' ');
    })

    const justifyClass = computed(() => {
        if (props.content.alignment === 'left') {
            return 'justify-start';
        }
        if (props.content.alignment === 'right') {
            return 'justify-end';
        }
        if (props.content.alignment === 'center') {
            return 'justify-center';
        }
        if (props.content.alignment === 'fullwidth') {
            return 'justify-center w-full';
        }
    })

</script>

<template>
    <div
        class="flex"
        :class="justifyClass"
    >
        <a
            :href="props.content.url"
            class="rounded-wl bg-wlPrimary text-wlPrimaryContrast inline-block mf-link-immune cursor-pointer"
            :class="classes"
        >
            {{ props.content.label }}
        </a>
    </div>
</template>

<style scoped>
    .mfpb-custom-color {
        color: v-bind('content.cssColor');
    }

    .mfpb-custom-background-color {
        background-color: v-bind('content.cssBackgroundColor');
    }
</style>
