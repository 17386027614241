<script setup>
    import { onMounted } from 'vue'
    import * as CronofyElements from "cronofy-elements";

    const emit = defineEmits('reloadRequired');

    const props = defineProps({
        cronofyRedirectUri: {
            type: String,
            required: true,
        },
        cronofyClientId: {
            type: String,
            required: true,
        },
        cronofyEmbedToken: {
            type: String,
            required: true,
        },
        cronofyNonce: {
            type: String,
            required: true,
        },
    });

    onMounted(() => {
        initiateCalendarSync();
    });

    const initiateCalendarSync = () => {
        CronofyElements.CalendarSync({
            target_id: "cronofy-calendar-sync",
            data_center: "de",
            element_token: props.cronofyEmbedToken,
            authorization: {
                redirect_uri: props.cronofyRedirectUri,
                client_id: props.cronofyClientId,
                scope: "free_busy_write",
                state: props.cronofyNonce,
            },
            styles: {
                prefix: "CalendarSync"
            },
            callback: notification => {
                if (notification.userinfo?.sub) {
                    // TODO maybe? Make a request to remove/disable the Cronofy integration (integrations_cronofy row) based on the notification.userinfo.sub value (eg. "acc_6572caba19ae2f476c6344ff")
                }
                emit('reloadRequired');
            }
        });
    }

    const disableCalendarSync = async () => {
        const res = await axios.delete(route('integrations.cronofy.delete'));
        window.location.reload();
    }
</script>
<template>
    <div>
        <div class="flex justify-between">
            <h3>{{ _mft('cronofy:manageIntegration') }}</h3>

            <button-with-confirmation
                v-if="props.cronofyEmbedToken"
                @confirmed="disableCalendarSync"
                modalClass="absolute -top-2 -left-2 p-2 bg-white rounded shadow-md z-20"
                :buttonLabel="_mft('cronofy:disableCalendarIntegration.button')"
                buttonClass="myflow-basic-button--danger whitespace-nowrap text-xs"
                :confirmButtonLabel="_mft('cronofy:disableCalendarIntegration.confirmButton')"
                confirmButtonClass="myflow-basic-button--danger whitespace-nowrap"
                :confirm-text="_mft('cronofy:disableCalendarIntegration.confirmationText')"
            />
        </div>
        <div id="cronofy-calendar-sync"></div>
    </div>
</template>
