<script setup>
import { reactive, onMounted } from 'vue';

const emit = defineEmits(['update:smsContent']);

const props = defineProps({
    presetSmsContent: {
        type: String,
        default: () => ''
    }
})

const state = reactive({
    smsContent:  props.presetSmsContent,
})

const updateContent = (content) => {
    state.smsContent = content;
    emit('update:smsContent', content);
}

onMounted(async () => {
    state.smsContent = props.presetSmsContent;
})

</script>
<template>
    <div>
        <h2>{{ _mft('communication:composer.title.smsContent') }}</h2>
        <sms-input-counter
            :content="state.smsContent"
            @update:content="updateContent"
        ></sms-input-counter>
    </div>
</template>
