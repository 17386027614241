<script setup>
import { onMounted, ref } from 'vue';
import axios from 'axios';

const props = defineProps({
    bookingUrl: {
        type: String,
        required: true,
    },
    reportUrl: {
        type: String,
        required: true,
    },
    bookerName: {
        type: String,
        required: true,
    },
    bookerEmail: {
        type: String,
        required: true,
    },
    bookerReportUrl: {
        type: String,
        required: true,
    },
});

const calendlyLoaded = ref(false);

// Function to check if the event is a Calendly event
function isCalendlyEvent(e) {
  return e.origin === "https://calendly.com" && e.data.event && e.data.event.indexOf("calendly.") === 0;
}

// Function to handle Calendly events
function handleCalendlyEvent(e) {
    if (isCalendlyEvent(e)) {
        if (e.data.event === 'calendly.event_scheduled') {
            axios.post(route('expert.analyze.insightMeetingBooked'), {
                eventName: e.data.event,
                eventDetails: e.data.payload,
            });
        }
    }
}

function initCalendly() {
    Calendly.initInlineWidget({
        url: props.bookingUrl + '?hide_event_type_details=1&hide_gdpr_banner=1',
        parentElement: document.getElementById('calendly-embed'),
        prefill: {
            name: props.bookerName,
            email: props.bookerEmail,
            customAnswers: {
                a1: props.bookerReportUrl,
            }
        }
    });
}

async function dismiss() {
    await axios.post(route('expert.analyze.insightMeetingDismissed'));

    window.location.reload();
}

onMounted(() => {
    let calendlyInitiator = setInterval(() => {
        if (typeof Calendly !== 'undefined') {
            initCalendly();
            clearInterval(calendlyInitiator);
            calendlyLoaded.value = true;
        }
    }, 200);

    // Add event listener for Calendly events
    window.addEventListener("message", handleCalendlyEvent);
});
</script>

<template>
    <div>
        <div v-if="!calendlyLoaded">
            <mf-spinner-medium class="w-24 h-12"></mf-spinner-medium>
        </div>
        <div id="calendly-embed" class="calendly-embed-container"></div>

        <div class="flex justify-end">
            <button-secondary
                @click="dismiss"
            >Hide</button-secondary>
        </div>
    </div>
</template>
