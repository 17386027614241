<script setup>
    import { reactive, computed, watch } from 'vue'

    const emit = defineEmits(['fileUpdated'])

    const props = defineProps({
        image: {
            type: Object,
            required: false,
            default: null
        },
        previewImageClass: {
            type: String,
            required: false,
            default: '',
        }
    })

    const state = reactive({
        previewImageSrc: '',
        changingImage: false,
        featuredImage: ''
    })

    const crop = reactive({
        cropX: '',
        cropY: '',
        cropWidth: '',
        cropHeight: '',
    })

    const profileImage = computed(() => {
        if( state.previewImageSrc ){
            return state.previewImageSrc
        } else {
            return props.image
        }
    })

    const profileImageButtonLabel = computed(() => {
        if( props.image === '' && state.previewImageSrc === '' ){
            return _mft('form:addImage')
        } else {
            return _mft('form:replaceImage')
        }
    })

    const previewImageClass = computed(() => {
        let classes = 'aspect-square w-full object-cover bg-cover bg-center';
        if( props.previewImageClass ){
            classes += ' ' + props.previewImageClass;
        } else {
            classes += ' rounded-xl';
        }
        return classes;
    })

    const fileUpdated = (file) => {
        state.featuredImage = file;
    }

    const croppingUpdated = (croppingoptions) => {
        crop.cropX = croppingoptions.x
        crop.cropY = croppingoptions.y
        crop.cropWidth = croppingoptions.width
        crop.cropHeight = croppingoptions.height
    }

    const croppingFinished = (base64Image) => {
        state.changingImage = false
        state.previewImageSrc = base64Image
        state.changingImage = false

        emit('fileUpdated', {
            file: state.featuredImage,
            crop: crop
        })
    }

    const croppingAborted = () => {
        state.changingImage = false
        delete state.featuredImage
        crop.cropX = ''
        crop.cropY = ''
        crop.cropWidth = ''
        crop.cropHeight = ''
    }

    const removeImage = () => {
        state.previewImageSrc = ''
        croppingAborted()

        emit('fileUpdated', {
            file: state.featuredImage,
            crop: crop
        })
    }

</script>
<template>
    <div class="mb-4">
        <div class="flex items-center justify-center">
            <div
                v-if="profileImage && !state.changingImage"
                :style="{backgroundImage: 'url('+ profileImage +')'}"
                class="relative mb-4 w-36 h-36 lg:w-48 lg:h-48"
                :class="previewImageClass"
            >
                <div class="absolute flex justify-center space-x-2 left-1 right-1 bottom-1">
                    <button
                        v-if="!state.changingImage"
                        v-on:click.prevent="state.changingImage = !state.changingImage"
                        class="text-xs myflow-basic-button"
                    >
                        {{ profileImageButtonLabel }}
                    </button>
                    <button
                        v-if="!state.changingImage"
                        v-on:click.prevent="removeImage"
                        class="text-xs myflow-basic-button"
                    >
                        {{ _mft('shared:action.remove') }}
                    </button>
                </div>
            </div>
            <button
                v-if="!state.changingImage && !profileImage"
                v-on:click.prevent="state.changingImage = !state.changingImage"
                class="text-xs myflow-basic-button"
            >
                {{ _mft('form:addFeaturedImage') }}
            </button>
        </div>

        <file-upload-with-cropping
            v-if="state.changingImage"
            :required="false"
            :currentImage="profileImage"
            :previewClass="previewImageClass"
            :label="_mft('form:fileUploadWithCropping.label')"
            @removedfile="state.changingImage = false"
            @fileUpdated="fileUpdated($event)"
            @croppingUpdated="croppingUpdated($event)"
            @croppingFinished="croppingFinished($event)"
            @croppingAborted="croppingAborted()"
        ></file-upload-with-cropping>
    </div>
</template>
