<template>
    <div class="mb-4">
        <div
            class="relative z-10 col-span-2 px-4 py-3 -ml-px bg-white shadow-sm rounded-card md:p-4 md:pt-4"
        >
            <div v-if="content.is_available">
                <a
                    :href="route('subscription.content', {subscription_id: subscription.id, content_id: content.id})"
                    class="block group md:px-2"
                >
                    <div>
                        <div class="flex flex-col items-start justify-between md:flex-row">
                            <h2
                            class="mt-0.5 text-base lg:text-lg"
                            :class="contentTitleClass"
                            >{{ content.title }}</h2>

                            <div class="flex justify-end w-full md:w-auto md:mt-0 md:mb-0">
                                <div class="flex ml-2">
                                    <div
                                        v-if="!content.consumed && content.consumable"
                                        :class="badgeClass"
                                        class="p-2"
                                    >{{ _mft('subscription:item.new') }}</div>
                                    <div
                                        v-if="content.purchaseable && !content.licensed && !content.consumable"
                                        :class="badgeClass"
                                        class="py-2 pl-3 pr-1"
                                    >
                                        <div class="whitespace-nowrap">{{ _mft('subscription:item.purchaseFor') }} {{ content.purchase_options.price_incl_vat }}</div>
                                        <ChevronRightIcon
                                            class="w-4 h-4 text-white"
                                            :class="content.consumed ? 'text-myflowPurple-600' : ''"
                                        />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </a>
            </div>
            <div
                class="cursor-default"
                v-else
            >
                <h2 class="text-gray-400 py-0.5 text-base lg:text-lg md:px-2">{{ content.title }}</h2>
            </div>
        </div>
        <div
            class="px-4 pt-8 pb-2 -mt-5 -ml-px shadow-lg bg-gray-50 md:px-6 rounded-b-card"
        >
        <div class="flex justify-between -mt-1">
            <p
                class="mb-0 text-xs text-myflowPurple-900"
                >
                {{ contentAvailabilityMessage }}
            </p>
            <div class="flex justify-between space-x-1 text-myflowPurple">
                <PlayIcon  title="Video" v-if="content.has_video" class="w-4 h-4 fill-current" />
                <DownloadIcon v-if="content.has_download" class="w-4 h-4 fill-current" />
                <VideoCameraIcon v-if="content.has_livestream" class="w-4 h-4 fill-current" />
            </div>
        </div>
        </div>
    </div>
</template>
<script>
import { ChevronRightIcon, PlayIcon, DownloadIcon, VideoCameraIcon } from '@heroicons/vue/solid';

export default {
    components: {
        ChevronRightIcon,
        PlayIcon,
        DownloadIcon,
        VideoCameraIcon
    },
    props: {
        subscription: Object,
        content: Object
    },
    data() {
        return {
            badgeClass: 'flex items-center justify-center text-xs text-white shadow-lg rounded-full bg-myflowMagenta'
        }
    },
    computed: {
        contentTitleClass() {
            if( this.content.consumable ) {
                return 'text-myflowPurple-700';
            }

            if( (this.content.purchaseable && !this.content.licensed) || (!this.content.licensed && this.content.purchaseable) ) {
                return 'text-myflowPurple-400';
            }
            return 'text-myflowPurple-700';
        },
        contentAvailabilityMessage() {
            if( this.content.consumable ) {
                return 'Tillgänglig till ' + this.content.available_until;
            }
            if( this.content.licensed ) {
                return 'Köpt';
            }
            if( this.content.purchaseable ) {
                return 'Tillgänglig för köp';
            }
            if( !this.content.is_available && this.content.requires_upsell ) {
                return 'Går att köpa från ' + this.content.available_from;
            }
            if( !this.content.is_available ) {
                return 'Släpps ' + this.content.available_from;
            }
        },
    }
}

</script>
