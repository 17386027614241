<script setup>

import {computed, ref} from "vue";
import FrostedBar from "../../../../components/layout/pageHeadings/frostedBar.vue";

const props = defineProps({
    courseId: {
        type: Number,
        required: true
    }
});

const useTemplateOptions = [
    {
        label: _mft('pagebuilderTemplate:useTemplate'),
        name: true,
    },
    {
        label: _mft('pagebuilderTemplate:useEmpty'),
        name: false,
    },
]
const useTemplate = ref(true)

const chapter = ref({
    title: '',
    provider_id: null,
    published: true,
    template_id: null
})

const savingState = ref('')

const save = async () => {
    if (savingState.value === 'waiting') {
        return
    }

    savingState.value = 'waiting'
    const res = await axios.post(route('api.course.backoffice.chapter.add', props.courseId), {
        title: chapter.value.title,
        provider_id: chapter.value.provider_id,
        published: chapter.value.published,
        template_id: chapter.value.template_id
    });

    if (res.data.success) {
        savingState.value = 'success'
        window.location.href = res.data.redirect_url;
    } else {
        savingState.value = 'error'
    }
}

const setUseTemplate = (e) => {
    if (e.name === false) {
        chapter.value.template_id = null;
        useTemplate.value = false
        return
    }
    useTemplate.value = true
}

const selectTemplate = (template) => {
    chapter.value.template_id = template.id;
}

const chapterValidates = computed(() => {
    return chapter.value?.title.length > 0
})

</script>

<template>
    <div class="md:sticky top-2 z-topbar">
        <frosted-bar>
            <div class="flex items-center justify-between space-x-4 f">
                <h1>{{ _mft('course:addChapter') }}</h1>

                <div class="flex items-center space-x-4">
                    <button-base
                        :style-type="chapterValidates ? 'success' : 'disabled'"
                        :disabled="! chapterValidates"
                        @click="save()"
                        :state="savingState"
                    >
                        {{ _mft('shared:action.save') }}
                    </button-base>
                </div>
            </div>
        </frosted-bar>
    </div>

    <div class="mt-12 xl:mt-24 mb-12 max-w-3xl mx-auto">
        <input-text
            v-model:content="chapter.title"
            :label="_mft('course:chapter.title')"
            required="required"
            name="title"
            ref="titleInput"
        />

        <input-provider
            :label="_mft('course:chapter.provider')"
            name="provider_id"
            v-model="chapter.provider_id"
        />

        <toggle-switch
            v-model:content="chapter.published"
            :label="_mft('course:chapter.published')"
            name="published"
        />

        <button-bar
            :options="useTemplateOptions"
            @select="setUseTemplate"
            class="mt-8 max-w-2xl mx-auto"
        />
    </div>

    <templates-list
        v-if="useTemplate"
        :show-empty-default="false"
        :read-only="true"
        :selected-template-id="chapter.template_id"
        preset-category="chapter"
        @selectTemplate="selectTemplate"
    ></templates-list>

</template>

<style scoped>

</style>
