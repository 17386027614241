<script setup>

</script>

<template>
<div>
<invoice-link-form></invoice-link-form>
</div>
</template>

<style scoped>

</style>
