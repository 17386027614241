<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { useNotificationStore } from '@/stores/NotificationStore.js'
const notificationStore = useNotificationStore()

const newField = ref({
    name: '',
    type: 'text',
});

const customFields = ref([]);

const fetchCustomFields = async () => {
    try {
        const response = await axios.get(route('api.crm.getCustomFields'));
        customFields.value = response.data;
    } catch (error) {
        console.error('Error fetching custom fields:', error);
    }
};

const addCustomField = async () => {
    if (newField.value.name.length < 3) {
        return
    }

    try {
        await axios.post(route('api.crm.storeCustomField', newField.value));
        await fetchCustomFields();

        notificationStore.addNotification({
            type: 'success',
            title: newField.value.name,
            message: _mft('crm:customFields.notification.fieldAdded'),
            dismissAfter: 2500
        });

        newField.value = { name: '', type: 'text' };
    } catch (error) {
        console.error('Error adding custom field:', error);
    }
};

const fieldTypeOptions = [
    { value: 'text', label: _mft('crm:customFields.fieldType.text') },
    { value: 'number', label: _mft('crm:customFields.fieldType.number') },
    { value: 'date', label: _mft('crm:customFields.fieldType.date') },
]

onMounted(fetchCustomFields);
</script>

<template>
    <div class="lg:grid lg:grid-cols-2 lg:gap-8">
        <div>
            <h3>{{ _mft('crm:customFields.title') }}</h3>
            <ul class="divide-y">
                <li
                    v-for="field in customFields"
                    :key="field.id"
                    class="flex items-center justify-between py-0.5"
                >
                    {{ field.name }} <pill size="sm" class="ml-2 bg-gray-200">{{ field.type }}</pill>
                </li>
                <li
                    v-if="customFields.length === 0"
                >
                    {{ _mft('crm:customFields.noneCreatedYet') }}
                </li>
            </ul>
        </div>

        <div>
            <h3>{{ _mft('crm:customFields.addCustomField') }}</h3>
            <form @submit.prevent="addCustomField">
                <div class="flex space-x-1 items-center">
                    <input-select
                        v-model:content="newField.type"
                        :options="fieldTypeOptions"
                        class="w-48"
                    />
                    <input-text
                        v-model:content="newField.name"
                        :placeholder="_mft('crm:customFields.fieldName')"
                        class="w-full"
                    />
                    <button-base
                        type="submit"
                        :style-type="newField.name.length < 3 ? 'disabled' : 'success'"
                        :disabled="newField.name.length < 3"
                        class="mb-4 whitespace-nowrap"
                    >{{ _mft('shared:add') }}</button-base>
                </div>
            </form>
        </div>
    </div>
</template>
