<template>
    <div v-if="value">
        <div
            class="grid gap-5 mb-3"
            :class="layout === 'half' ? 'grid-cols-1' : 'grid-cols-1 lg:grid-cols-2 lg:gap-4 xl:gap-8'"
        >
            <div class="flex flex-col">
                <input-media-bank-entry
                    class="order-2 lg:order-1"
                    type="image"
                    label="Välj en logga från mediabiblioteket"
                    required
                    v-model:content="value.media_bank_entry_id"
                />
                <input-option
                    class="order-1 lg:order-2 lg:mt-2"
                    label="Justering"
                    :options="[
                        { label: 'Vänster', value: 'left' },
                        { label: 'Centrerad', value: 'center' },
                        { label: 'Höger', value: 'right' },
                        { label: 'Full bredd', value: 'full-width' },
                    ]"
                    v-model:content="value.alignment"
                />
            </div>
            <media-bank-entries-display v-if="value.media_bank_entry_id" :data-url="route('mediaBank.entries.show', value.media_bank_entry_id)" />
        </div>
    </div>
    <mf-spinner-medium v-else class="w-16 h-8"></mf-spinner-medium>
</template>
<script>
export default {
    props: {
        modelValue: {},
        layout: {
            type: String,
            default: 'full'
        }
    },
    emits: [
        'update:modelValue'
    ],
    data() {
        return {
            value: null
        };
    },
    async mounted() {
        this.captureModelValue();
    },
    watch: {
        modelValue() {
            if (JSON.stringify(this.value) === JSON.stringify(this.modelValue)) { return; }
            this.captureModelValue();
        },
        value: {
            handler() {
                this.$emit('update:modelValue', JSON.parse(JSON.stringify(this.value)));
            },
            deep: true
        }
    },
    methods: {
        captureModelValue() {
            const value = this.modelValue ? JSON.parse(JSON.stringify(this.modelValue)) : {};
            if (!value.alignment) { value.alignment = 'left'; }
            if (!value.media_bank_entry_id) { value.media_bank_entry_id = null; }
            this.value = value;
        }
    },
}
</script>
