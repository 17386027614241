<script setup>

import {onMounted, ref, provide, computed} from "vue";

const props = defineProps({
    leadId: {
        type: Number,
        required: true,
    },
    lead: Object,
    events: Object,
    notes: Object,
    actions: Object,
    deals: Object,
    done_actions: Object,
    stakeholders: Object,
    internal: Number,
    invite: String,
    signatureRequests: Object,
    legacyDeals: String,
    smsDeliveries: Object,
    emailDeliveries: Object,
    processes: Object,
})

const currentTab = ref('')
const tabs = ref([
    {
        label: _mft('crm:leadPage.tabTitle.showAll'),
        name: 'all'
    },
    {
        label: _mft('crm:leadPage.tabTitle.details'),
        name: 'details'
    },
    {
        label: _mft('crm:leadPage.tabTitle.contracts'),
        name: 'signatures'
    },
    {
        label: _mft('crm:leadPage.tabTitle.automations'),
        name: 'deliveries'
    },
    {
        label: _mft('crm:leadPage.tabTitle.notes'),
        name: 'notes'
    },
    {
        label: _mft('crm:leadPage.tabTitle.actions'),
        name: 'actions'
    },
    {
        label: _mft('crm:leadPage.tabTitle.stakeholders'),
        name: 'stakeholders'
    },
])

const lead = ref(null)

const setCurrentTab = (tab) => {
    currentTab.value = tab.name
}

const loadLead = async () => {
    const res = await axios.get(route('api.crm.lead.show', props.leadId))

    lead.value = res.data.lead
}

const updateLead = async () => {
    const res = axios.put(route('api.crm.lead.update', props.leadId), lead.value)
}

const showAll = computed(() => {
    return currentTab.value === 'all';
})

onMounted(() => {
    loadLead()
    currentTab.value = tabs.value[0].name

    if (props.legacyDeals === false) {
        tabs.value.push({
            label: _mft('crm:leadPage.tabTitle.deals'),
            name: 'deals'
        })
    }
})

provide('lead', lead)

</script>
<template>
    <div v-if="lead === null">
        <mf-spinner-medium class="w-24 h-12" />
    </div>
    <div v-else>
        <div class="flex items-center space-x-2">
            <h1 class="font-semibold">{{ lead.name }} - {{ lead.company }}</h1>
            <span
                v-if="! lead.active"
                class="text-lg italic font-bold text-gray-400 transform translatey-y-4"
            >
                {{ _mft('crm:snoozed') }}
            </span>
        </div>
        <p class="text-xs"><mf-translate domainkey="crm:addedAt"></mf-translate> {{ lead.created_at_tz }}</p>

        <tags-manage-inline
            class="mb-8"
            ref="tagsManage"
            :lead-id="lead.id"
        ></tags-manage-inline>

        <ul class="flex-wrap hidden mb-4 space-x-2 xl:space-x-3 md:flex">
            <li
                v-for="(tab, i) in tabs"
                v-bind:key="i"
                v-on:click="setCurrentTab(tab)"
                class="px-1 py-2 mb-4 font-bold border-b-2 cursor-pointer select-none xl:px-3 whitespace-nowrap"
                :class="{'border-myflowMagenta text-myflowMagenta' : currentTab === tab.name }"
            >
                {{ tab.label }}
            </li>
        </ul>

        <card-basic
            v-if="currentTab === 'details' || showAll"
            class="overflow-visible"
        >
            <lead-details
                @update:lead="updateLead"
                @reload:lead="loadLead"
            />
        </card-basic>

        <signatures-list-crm
            v-if="currentTab === 'signatures' || showAll"
            :signatures="signatureRequests"
        ></signatures-list-crm>

        <deliveries-list-crm
            v-if="currentTab === 'deliveries' || showAll"
            :sms-deliveries="smsDeliveries"
            :email-deliveries="emailDeliveries"
        ></deliveries-list-crm>

        <lead-note-card
            v-if="currentTab === 'notes' || showAll"
            :lead="lead"
            :notes="notes"
        ></lead-note-card>

        <lead-action-card
            v-if="currentTab === 'actions' || showAll"
            :lead="lead"
            :actions="actions"
        ></lead-action-card>

        <lead-deal-card
            v-if="legacyDeals === false && (currentTab === 'deals' || showAll)"
            :lead="lead"
            :deals="deals"
            :internal="internal"
        ></lead-deal-card>

        <lead-stakeholder-card
            v-if="currentTab === 'stakeholders' || showAll"
            :lead="lead"
            :stakeholders="stakeholders"
        ></lead-stakeholder-card>
    </div>
</template>
