<template>

    <div class="grid-cols-2 gap-8 md:grid">
        <div class="col-span-1">
            <h5 class="mb-4">
                <i class="fa-regular fa-sparkles"></i> {{ _mft('ai:createPersonal') }}
            </h5>
            <input-text
                :label="_mft('ai:input.whoHaveYouMet.label')"
                v-model:content="value.who"
                required
                :placeholder="_mft('ai:input.whoHaveYouMet.placeholder')"
                placeholder="Oliver Hertzman Kraft"
            />
            <input-text
                :label="_mft('ai:input.whereMeet.label')"
                v-model:content="value.where"
                required
                :placeholder="_mft('ai:input.whereMeet.placeholder')"
                placeholder="myflows kontor"
            />
            <input-text
                :label="_mft('ai:input.whatAppreciated.label')"
                v-model:content="value.appreciate"
                required
                :placeholder="_mft('ai:input.whatAppreciated.placeholder')"
                placeholder="tydliga synergier kring vad vi vill uppnå"
            />
            <input-text
                :label="_mft('ai:input.goal.label')"
                v-model:content="value.goal"
                required
                :placeholder="_mft('ai:input.goal.placeholder')"
                placeholder="En digital fika för att prata mer om hur coaching kan hjälpa dig"
            />
            <button
                type="button"
                class="myflow-basic-button"
                v-if="!states.waitingForResponse"
                v-on:click="generateCoachingDescription"
            >{{ _mft('ai:generateIntroduction.button') }} <i class="fa-regular fa-sparkles"></i></button>
        </div>
        <div class="col-span-1" v-if="states.waitingForResponse">
            <h5 class="mb-4">{{ _mft('ai:result.title') }}</h5>
            <mf-spinner-medium
                class="w-24 h-12"
            />
        </div>
        <div class="col-span-1" v-if="result.data.length !== 0">
            <h5 class="mb-4">{{ _mft('ai:result.title') }}</h5>
            <div v-html="result.data.trim().replace(/(?:\r\n|\r|\n)/g, '<br>')" class="p-5 bg-gray-100 rounded-xl"></div>

            <button type="button" class="mt-4 myflow-basic-button" v-on:click="appendToinputEditor">
                {{ _mft('ai:addText.button') }}
            </button>
        </div>
    </div>

</template>

<script setup>

import { reactive } from 'vue'

const value = reactive({
    who: '',
    where: '',
    appreciate: '',
    goal: '',
});

const states = reactive({
    waitingForResponse: false,
});

var result = reactive({
    data: '',
});

const emit = defineEmits(['exportedContent'])

const appendToinputEditor = () => {
    emit('exportedContent', result.data.trim().replace(/(?:\r\n|\r|\n)/g, '<br>'));
};

const generateCoachingDescription = async () => {
    states.waitingForResponse = true;
    const response = await axios.post('/openai/ai-introduction', value);
    result.data = response.data;
    states.waitingForResponse = false;
};

</script>

<style>

</style>
