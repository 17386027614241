<template>
    <div v-if="value">
        <input-text maxlength="255" label="Rubrik" v-model:content="value.text" :required="true"/>
        <input-text maxlength="255" label="Länk till LinkedIn" v-model:content="value.linkedin"/>
        <input-text maxlength="255" label="Länk till Facebook" v-model:content="value.facebook"/>
        <input-text maxlength="255" label="Länk till Instagram" v-model:content="value.instagram"/>
        <input-text maxlength="255" label="Länk till TikTok" v-model:content="value.tiktok"/>
        <input-text maxlength="255" label="Länk till Twitter" v-model:content="value.twitter"/>
        <input-text maxlength="255" label="Länk till Youtube" v-model:content="value.youtube"/>
        <input-text maxlength="255" label="Länk till Vimeo" v-model:content="value.vimeo"/>
        <input-text maxlength="255" label="Länk till GitHub" v-model:content="value.github"/>
        <input-text maxlength="255" label="Länk till Twitch" v-model:content="value.twitch"/>
        <input-text maxlength="255" label="Länk till medium" v-model:content="value.medium"/>
        <input-text maxlength="255" label="Länk till hemsida" v-model:content="value.website"/>
    </div>
    <mf-spinner-medium v-else class="w-16 h-8"></mf-spinner-medium>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';

export default {
    props: {
        modelValue: {},
    },
    emits: [
        'update:modelValue'
    ],
    data() {
        return {
            value: null
        };
    },
    async mounted() {
        this.captureModelValue();
    },
    watch: {
        modelValue() {
            if (JSON.stringify(this.value) === JSON.stringify(this.modelValue)) { return; }
            this.captureModelValue();
        },
        value: {
            handler() {
                this.$emit('update:modelValue', JSON.parse(JSON.stringify(this.value)));
            },
            deep: true
        }
    },
    methods: {
        captureModelValue() {
            const value = this.modelValue ? JSON.parse(JSON.stringify(this.modelValue)) : {};
            if (!value.text) { value.text = null; }
            this.value = value;
        }
    },
}
</script>
