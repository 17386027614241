<script setup>
import {CashIcon, DotsHorizontalIcon, ChevronDownIcon, MinusIcon, ReceiptRefundIcon, BanIcon} from "@heroicons/vue/outline";
import ButtonSecondary from "../../buttons/buttonSecondary.vue";
import {ref} from "vue";
import ButtonWithConfirmation from "../../buttons/buttonWithConfirmation.vue";
import { useNotificationStore } from '@/stores/NotificationStore.js'
const notificationStore = useNotificationStore()

const props = defineProps({
    order: {
        type: Object,
        required: true,
    }
})

const expanded = ref(false)
const _order = ref(props.order)

const paymentStateUpdateState = ref('')
const productsDeliveredUpdateState = ref('')

const setPurchaseState = async (state) => {
    if (paymentStateUpdateState.value === 'waiting') {return;}
    paymentStateUpdateState.value = 'waiting'

    const res = await axios.post(route('api.invoice-links.set-payment-state', {
        uuid: _order.value.uuid,
        state: state,
    }))

    if (res.data.success) {
        _order.value.state = state
        paymentStateUpdateState.value = 'success'
    } else {
        paymentStateUpdateState.value = 'error'
        notificationStore.addNotification({
            type: 'error',
            message: res.data.error,
            dismissAfter: 5000,
        })
    }
}

const deliverLicenses = async () => {
    if (productsDeliveredUpdateState.value === 'waiting') {return;}
    productsDeliveredUpdateState.value = 'waiting'

    let res

    try {
        res = await axios.post(route('api.invoice-links.deliver-licenses', _order.value.uuid))
    } catch (e) {
        productsDeliveredUpdateState.value = 'error'
        return
    }

    if (res.data.success) {
        _order.value.products_delivered = true
        productsDeliveredUpdateState.value = 'success'
    } else {
        productsDeliveredUpdateState.value = 'error'
    }
}

const markAsPaidAndDeliverLicenses = () => {
    setPurchaseState('paid')
    deliverLicenses()
}

</script>
<template>
    <div
        class="flex flex-col px-4 py-4 pr-4 md:flex-row md:items-center md:justify-between md:px-6 md:pr-6"
        :class="expanded ? '' : 'hover:bg-gray-50'"
    >
        <div class="flex space-x-3">
            <div>
                <span
                    v-if="_order.state === 'initiated'"
                    class="text-xs text-gray-500"
                    :title="_mft('invoiceLink:purchase.state.initiated')"
                >
                    <DotsHorizontalIcon class="h-6 w-6" />
                </span>
                <span
                    v-if="_order.state === 'paid'"
                    class="text-xs text-myflowGreen"
                    :title="_mft('invoiceLink:purchase.state.paid')"
                >
                    <CashIcon class="h-6 w-6" />
                </span>
                <span
                    v-if="_order.state === 'cancelled'"
                    class="text-xs text-gray-500"
                    :title="_mft('invoiceLink:purchase.state.cancelled')"
                >
                    <BanIcon class="h-6 w-6" />
                </span>
                <span
                    v-if="_order.state === 'credited'"
                    class="text-xs text-gray-500"
                    :title="_mft('invoiceLink:purchase.state.credited')"
                >
                    <ReceiptRefundIcon class="h-6 w-6" />
                </span>
            </div>
            <div class="block">
                <div>
                    <span class="text-gray-400 mr-2">
                        <span class="text-gray-300">#</span>
                        <span class="select-all cursor-default">{{ _order.invoice_reference }}</span>
                    </span>
                    {{ _order.title }}
                </div>
                <div class="space-y-1">
                    <div class="flex items-center space-x-4">
                        <div class="w-32">
                            <span class="text-xs">{{ _order.created_at_user_timezone_display }}</span>
                        </div>
                        <div class="w-32">
                            <span class="text-xs">{{ _mft('invoiceLink:purchase.method.' + _order.payment_method) }}</span>
                        </div>
                    </div>
                </div>
                <div class="text-xs">

                </div>
            </div>
        </div>
        <div class="mt-0 flex justify-between lg:justify-end space-x-4">
            <div class="flex flex-col items-end text-xs space-y-1 pl-9 pt-1 lg:pl-0 lg:pt-0">
                <div>{{ _order.nominal_total_amount }} {{ _order.currency}}</div>

            </div>
            <div>
                <button-secondary
                    size="sm"
                    @click="expanded = !expanded"
                >
                    <ChevronDownIcon
                        v-if="!expanded"
                        class="h-6 w-6"
                    />
                    <MinusIcon
                        v-if="expanded"
                        class="h-6 w-6"
                    />
                </button-secondary>
            </div>
        </div>
    </div>
    <div
        v-if="expanded"
        class="grid gap-4 lg:gap-8 bg-gradient-to-b from-white to-gray-100 p-4 md:p-6 lg:grid-cols-3"
    >
        <div class="mb-4">
            <h3 class="mb-2">{{ _mft('invoiceLink:purchase.customer.title') }}</h3>
            <input-label :label="_mft('invoiceLink:purchase.billingInformation.label')"></input-label>
            <p>
                {{ _order.billing_name }}<br>
                {{ _order.billing_email }}<br>
                {{ _order.billing_phone }}
            </p>

            <p v-if="_order.billing_company || _order.billing_identityno">
                {{ _order.billing_company }}<br>
                {{ _order.billing_identityno }}
            </p>

            <p>
                {{ _order.billing_address }}<br>
                {{ _order.billing_zip }} {{ _order.billing_city }}<br>
                {{ _order.billing_state }} {{ _order.billing_country }}<br>
            </p>
        </div>
        <div>
            <div class="flex items-center space-x-2 mb-2">
                <h3>{{ _mft('invoiceLink:purchase.billing.title') }}</h3>
                <pill
                    v-if="_order.state === 'initiated'"
                    size="sm"
                    class="bg-gray-500 text-white cursor-default"
                >
                    {{ _mft('invoiceLink:purchase.state.initiated') }}
                </pill>
                <pill
                    v-if="_order.state === 'paid'"
                    size="sm"
                    class="bg-myflowGreen text-white cursor-default"
                >
                    {{ _mft('invoiceLink:purchase.state.paid') }}
                </pill>
            </div>

            <div class="flex justify-between space-x-2">
                <input-label :label="_mft('invoiceLink:purchase.totalAmount')"></input-label>
                <span>{{ _order.nominal_total_amount }} {{ _order.currency}}</span>
            </div>

            <div
                class="flex justify-between space-x-2"
                v-if="_order.total_vat > 0"
            >
                <input-label :label="_mft('invoiceLink:purchase.totalVat')"></input-label>
                <span>{{ _order.nominal_total_vat }} {{ _order.currency}}</span>
            </div>

            <div
                class="flex justify-between space-x-2"
                v-if="_order.total_vat > 0"
            >
                <input-label :label="_mft('invoiceLink:purchase.totalAmountInclVat')"></input-label>
                <span>{{ _order.nominal_total_amount_incl_vat }} {{ _order.currency}}</span>
            </div>

            <div class="flex justify-between space-x-2 mt-4">
                <input-label :label="_mft('invoiceLink:purchase.state.label')"></input-label>
                <span>
                    {{ _mft('invoiceLink:purchase.state.' + _order.state) }}
                </span>
            </div>
            <div class="flex justify-between space-x-2">
                <input-label :label="_mft('invoiceLink:purchase.paymentMethod.label')"></input-label>
                <span>
                    {{ _mft('invoiceLink:purchase.method.' + _order.payment_method) }}
                </span>
            </div>
            <div class="flex justify-between space-x-2">
                <input-label :label="_mft('invoiceLink:purchase.invoiceReference.label')"></input-label>
                <span>
                    <a
                        :href="route('invoice-link.purchase.receipt.show', _order.uuid)"
                        target="_blank"
                        class="mf-link"
                    >
                        {{ _order.invoice_reference }}
                    </a>
                </span>
            </div>

            <div class="flex flex-col items-start space-y-2 mt-4">
                <button-with-confirmation
                    v-if="_order.payment_method === 'invoice' && _order.state === 'initiated'"
                    size="sm"
                    confirm-type="primary"
                    :confirm-text="_mft('invoiceLink:purchase.state.action.markAsPaid.confirmationText')"
                    :confirm-button-label="_mft('invoiceLink:purchase.state.action.markAsPaid')"
                    :abort-button-label="_mft('shared:action.cancel')"
                    @confirmed="setPurchaseState('paid')"
                >
                    {{ _mft('invoiceLink:purchase.state.action.markAsPaid') }}
                </button-with-confirmation>

                <button-with-confirmation
                    v-if="_order.payment_method === 'invoice' && _order.state === 'initiated' && ! _order.products_delivered"
                    size="sm"
                    confirm-type="primary"
                    :confirm-text="_mft('invoiceLink:purchase.productsDelivered.action.confirmationText')"
                    :confirm-button-label="_mft('invoiceLink:purchase.state.action.markAsPaidAndDeliver')"
                    :abort-button-label="_mft('shared:action.cancel')"
                    @confirmed="markAsPaidAndDeliverLicenses"
                >
                    {{ _mft('invoiceLink:purchase.state.action.markAsPaidAndDeliver') }}
                </button-with-confirmation>

                <button-secondary
                    v-if="_order.payment_method === 'invoice' && _order.state === 'paid'"
                    size="sm"
                    @click="setPurchaseState('initiated')"
                >
                    {{ _mft('invoiceLink:purchase.state.action.markAsNotPaid') }}
                </button-secondary>

                <div class="grid grid-cols-2 gap-4">
                    <button-with-confirmation
                        v-if="_order.payment_method === 'invoice' && _order.state === 'initiated'"
                        size="sm"
                        confirm-type="danger"
                        :confirm-text="_mft('invoiceLink:purchase.state.action.cancel.confirmationText')"
                        :confirm-button-label="_mft('shared:action.continue')"
                        :abort-button-label="_mft('shared:action.cancel')"
                        @confirmed="setPurchaseState('cancelled')"
                    >
                        {{ _mft('invoiceLink:purchase.state.action.cancel') }}
                    </button-with-confirmation>
                    <button-with-confirmation
                        v-if="_order.payment_method === 'invoice' && _order.state === 'paid'"
                        size="sm"
                        confirm-type="danger"
                        :confirm-text="_mft('invoiceLink:purchase.state.action.credit.confirmationText')"
                        :confirm-button-label="_mft('shared:action.continue')"
                        :abort-button-label="_mft('shared:action.cancel')"
                        @confirmed="setPurchaseState('credited')"
                    >
                        {{ _mft('invoiceLink:purchase.state.action.credit') }}
                    </button-with-confirmation>
                </div>
            </div>

        </div>
        <div>
            <div class="flex items-center space-x-2 mb-2">
                <h3>{{ _mft('invoiceLink:purchase.order.title') }}</h3>
                <pill
                    v-if="! _order.products_delivered"
                    size="sm"
                    class="bg-gray-500 text-white cursor-default"
                >
                    {{ _mft('invoiceLink:purchase.productsDelivered.no') }}
                </pill>
                <pill
                    v-if="_order.products_delivered"
                    size="sm"
                    class="bg-myflowGreen text-white cursor-default"
                >
                    {{ _mft('invoiceLink:purchase.productsDelivered.yes') }}
                </pill>
            </div>
            <ul
                class="-mt-1 mb-4"
            >
                <li
                    v-for="(item, i) in _order.order_items"
                    :key="i"
                    :class="item.subitem ? 'pl-4 py-2 text-xs border-l' : 'py-1'"
                >
                    <div>
                        <div>{{ item.title }}</div>
                        <div
                            v-if="item.quantity > 1"
                            class="text-gray-500"
                        >{{ item.quantity }} {{ item.unit }}</div>
                    </div>
                </li>
            </ul>

            <button-with-confirmation
                v-if="! _order.products_delivered"
                size="sm"
                :state="productsDeliveredUpdateState"
                confirm-type="primary"
                :confirm-text="_mft('invoiceLink:purchase.productsDelivered.action.confirmationText')"
                :confirm-button-label="_mft('invoiceLink:purchase.productsDelivered.action.deliver')"
                :abort-button-label="_mft('shared:action.cancel')"
                @confirmed="deliverLicenses"
            >
                {{ _mft('invoiceLink:purchase.productsDelivered.action.deliver') }}
            </button-with-confirmation>
        </div>
    </div>
</template>
