<script setup>
import {computed, ref} from "vue";

const props = defineProps({
    content: {
        type: Object,
        required: true,
    },
})

const title = computed(() => props.content.title);
const body = computed(() => props.content.body);
const preview = computed(() => props.content.preview);

const collapsed = ref(true)

const collapseExpand = () => {
    collapsed.value = !collapsed.value
}

</script>
<template>
    <div
        class="overflow-hidden"
        :class="'text-' + props.content.alignment"
    >
        <h3 class="">
            {{ title }}
        </h3>
        <hr class="mt-2 mb-2">
        <div>
            <div v-if="!collapsed" v-html="body"></div>
            <div v-if="collapsed" v-html="preview"></div>
        </div>
        <p class="flex justify-end">
            <span v-if="!collapsed" class="cursor-pointer" @click="collapseExpand"><i><u>{{ _mft('shared:expand.showLess') }}</u></i></span>
            <span v-if="collapsed" class="cursor-pointer read-more" @click="collapseExpand"><i><u>{{ _mft('shared:expand.showMore') }}</u></i></span>
        </p>
    </div>
</template>
