<template>
    <div class="grid-cols-2 gap-4 md:grid">
        <div class="relative">
            <input
                class="myflow-basic-input"
                type="color"
                :class="inputClass"
                v-model="primaryColor"
            >
            <span
                class="absolute top-0 left-0 right-0 z-0 z-10 flex items-center justify-center -mt-1 text-xs text-gray-400 pointer-events-none bottom-2"
                v-if="primaryColor === '#ffffff'"
            >Tryck för att välja färg</span>
        </div>
        <div class="flex items-center">
            <button type="button" class="myflow-basic-button--secondary" @click="generateColors">Skapa färgkarta</button>
        </div>
        <label class="text-right">...välj en färg ovan, eller klista in en HEX-kod här:</label>
        <input type="text" maxlength="255" class="myflow-basic-input" v-model="primaryColor">
        <p class="col-span-2">Välj en färg och klicka på <b>Skapa färgkarta</b> för att få förslag på färger som skapar dynamik på din landningssida.</p>
        <p class="col-span-2">Vill du hellre ha full kontroll? Du kan justera alla färger till de värden du önskar nedanför.</p>
    </div>

    <hr class="mt-5 mb-5">

    <div class="grid-cols-2 gap-4 mt-5 -mb-4 md:grid">
        <div class="mb-4">
            <h5>Bakgrundsfärg</h5>
            <p class="mb-0 text-xs">Sidans huvudbakgrundsfärg.</p>
            <input
                class="myflow-basic-input"
                type="color"
                :class="inputClass"
                name="primarybackground"
                :value="selectedColors.primarybackground ?? '#ffffff'"
            >
        </div>
        <div class="mb-4">
            <h5>Sekundär bakgrundsfärg</h5>
            <p class="mb-0 text-xs">Alternerande bakgrundsfärg.</p>
            <input
                class="myflow-basic-input"
                type="color"
                :class="inputClass"
                name="secondarybackground"
                :value="selectedColors.secondarybackground ?? '#ffffff'"
            >
        </div>
        <div class="mb-4">
            <h5>Primär textfärg</h5>
            <p class="mb-0 text-xs">Huvudfärgen på texten.</p>
            <input
                class="myflow-basic-input"
                type="color"
                :class="inputClass"
                name="primaryforeground"
                :value="selectedColors.primaryforeground ?? '#622578'"
            >
        </div>
        <div class="mb-4">
            <h5>Sekundär textfärg</h5>
            <p class="mb-0 text-xs">Alternerande färg på texten.</p>
            <input
                class="myflow-basic-input"
                type="color"
                :class="inputClass"
                name="secondaryforeground"
                :value="selectedColors.secondaryforeground ?? '#622578'"
            >
        </div>
        <div class="mb-4">
            <h5>Knappar och ikoner</h5>
            <p class="mb-0 text-xs">Färgen på knappar och ikoner.</p>
            <input
                class="myflow-basic-input"
                type="color"
                :class="inputClass"
                name="primarycontrast"
                :value="selectedColors.primarycontrast ?? '#622578'"
            >
        </div>
        <div class="mb-4">
            <h5>Knapptext</h5>
            <p class="mb-0 text-xs">Färgen på texten i knapparna.</p>
            <input
                class="myflow-basic-input"
                type="color"
                :class="inputClass"
                name="ctatextcolor"
                :value="selectedColors.ctatextcolor ?? '#ffffff'"
            >
        </div>
        <div class="mb-4">
            <h5>Övertoning av bilder</h5>
            <p class="mb-0 text-xs">På de block som har övertoning, ex. Header.</p>
            <input
                class="myflow-basic-input"
                type="color"
                :class="inputClass"
                name="tint"
                :value="selectedColors.tint ?? '#622578'"
            >
        </div>
        <div class="hidden mb-4">
            <h5>Ramar</h5>
            <p class="mb-0 text-xs">Färg på ramar</p>
            <input
                class="myflow-basic-input"
                type="color"
                :class="inputClass"
                name="secondarycontrast"
                :value="selectedColors.secondarycontrast ?? '#ffffff'"
            >
        </div>

    </div>
</template>
<script>

    export default {

        props: {
            currentColors: Object,
        },

        data(){
            return {
                selectedColors: this.currentColors,
                inputClass: 'w-full h-12 bg-transparent border-gray-800 rounded-md outline-none',
                primaryColor: this.currentColors.primarybackground ?? '#ffffff',
            }
        },

        methods: {
            generateColors() {
                this.selectedColors = {
                    primarybackground: this.primaryColor,
                    primaryforeground: this.primaryTextShade(),
                    primarycontrast: this.contrastShade(),
                    secondarybackground: this.lighterShade(),
                    secondaryforeground: this.secondaryTextShade(),
                    secondarycontrast: this.secondaryContrast(),
                    tint: this.darkerShade(),
                    ctatextcolor: this.ctaShade(),
                }
            },

            lighterShade() {
                var HSL = this.HEXtoHSL(this.primaryColor);

                if(HSL.l >= 67) {
                    return '#ffffff';
                }

                return this.HSLtoHEX(HSL.h, HSL.s, HSL.l * 1.5);
            },

            darkerShade() {
                var HSL = this.HEXtoHSL(this.primaryColor);

                return this.HSLtoHEX(HSL.h, HSL.s, HSL.l * 0.8);
            },

            primaryTextShade() {
                var HSL = this.HEXtoHSL(this.primaryColor);

                if(HSL.l >= 50) {
                    return '#000000';
                } else {
                    return '#ffffff';
                }
            },

            secondaryTextShade() {
                var HSL = this.HEXtoHSL(this.primaryColor);

                if(HSL.l >= 50) {
                    return '#333333';
                } else {
                    return '#e6e6e6';
                }
            },

            secondaryContrast() {
                var HSL = this.HEXtoHSL(this.primaryColor);

                if(HSL.l >= 50) {
                    return '#000000';
                } else {
                    return '#ffffff';
                }
            },

            ctaShade() {
                var HSL = this.HEXtoHSL(this.primaryColor);

                if(HSL.l >= 50) {
                    return '#000000';
                } else {
                    return '#ffffff';
                }
            },

            contrastShade() {
                var HSL = this.HEXtoHSL(this.primaryColor);

                return this.HSLtoHEX(HSL.h - 20, HSL.s, HSL.l * 0.8);
            },

            HEXtoHSL(hex) {
                hex = hex.replace(/#/g, '');
                if (hex.length === 3) {
                    hex = hex.split('').map(function (hex) {
                        return hex + hex;
                    }).join('');
                }
                var result = /^([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})[\da-z]{0,0}$/i.exec(hex);
                if (!result) {
                    return null;
                }
                var r = parseInt(result[1], 16);
                var g = parseInt(result[2], 16);
                var b = parseInt(result[3], 16);
                r /= 255, g /= 255, b /= 255;
                var max = Math.max(r, g, b),
                    min = Math.min(r, g, b);
                var h, s, l = (max + min) / 2;
                if (max == min) {
                    h = s = 0;
                } else {
                    var d = max - min;
                    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
                    switch (max) {
                    case r:
                        h = (g - b) / d + (g < b ? 6 : 0);
                        break;
                    case g:
                        h = (b - r) / d + 2;
                        break;
                    case b:
                        h = (r - g) / d + 4;
                        break;
                    }
                    h /= 6;
                }
                s = s * 100;
                s = Math.round(s);
                l = l * 100;
                l = Math.round(l);
                h = Math.round(360 * h);

                return {
                    h: h,
                    s: s,
                    l: l
                };
            },

            HSLtoHEX(h, s, l) {
                l /= 100;
                const a = s * Math.min(l, 1 - l) / 100;
                const f = n => {
                    const k = (n + h / 30) % 12;
                    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
                    return Math.round(255 * color).toString(16).padStart(2, '0');
                };
                return `#${f(0)}${f(8)}${f(4)}`;
            }
        },

        mounted() {
        }

    }

</script>
