<template>
    <mf-table v-if="processes !== null" :data="processes" :columns="columns" :filters="filters" :searchable="searchable">

        <template #column-profile_img="{ row }">
            <img v-if="row.profile_img" class="inline-block object-cover object-center w-8 h-8 rounded-full ring-2 ring-white" :src="row.profile_img" alt="">
            <img v-else class="inline-block object-cover object-center w-8 h-8 rounded-full ring-2 ring-white" src="https://myflow.fra1.digitaloceanspaces.com/generic-profile-icon-23.jpeg" alt="">
        </template>

        <template #column-name="{ row }">
            <a :href="row.linkedin_url" target="_blank">{{ row.name }}</a>
        </template>

        <template #column-event_type="{ row }">
            {{ row.event_type }}
        </template>

        <template #column-message_sent="{ row }">
            {{ row.message_sent }}
        </template>

        <template #column-sent_by="{ row }">
            {{ row.sent_by }}
        </template>

        <template #actions="{ row }">
            <div class="flex divide-x-2">
                <a
                    v-if="(row.message_sent == '❌') && (row.name != 'Inväntar data')"
                    :href="route('sales-processes.markedAsSent', row.id)"
                    class="p-2"
                >{{ _mft('salesProcess:markAsSent') }}</a>

                <a
                    v-if="row.lead_id && (row.message_sent != '❌')"
                    :href="route('crm.leads.view', row.lead_id)"
                    class="p-2"
                >{{ _mft('salesProcess:showContact') }}</a>

                <span class="p-2">
                    <admin-sales-process-copy-content
                        v-if="(row.name != 'Inväntar data') && (row.message_sent == '❌')"
                        :content="row.message"
                        :url="route('sales-procesesses.initHelp', row.id)"
                    ></admin-sales-process-copy-content>
                </span>
            </div>
        </template>

    </mf-table>
    <div v-else class="text-center">
        <mf-spinner-medium
          class="w-24 h-12"
        />
    </div>
</template>

<style lang="scss" scoped>
</style>

<script setup>
import { ref, toRefs, watch, onMounted } from 'vue';
import axios from 'axios';

const props = defineProps({
    url: { type: String, required: true }
});

const { url } = toRefs(props);

const processes = ref(null);
const updateProcesses = async () => {
    const res = await axios.get(url.value + '?cachebust');
    processes.value = res.data.data;
}
watch(url, updateProcesses);
onMounted(updateProcesses);

const columns = [
    { label: '', key: 'profile_img', sort: 'name' },
    { label: _mft('salesProcess:fields.name.label'), key: 'name', sort: 'name' },
    { label: _mft('salesProcess:fields.sentBy.label'), key: 'sent_by', sort: 'sent_by' },
    { label: _mft('salesProcess:fields.wantsHelpWith.label'), key: 'event_type', sort: 'event_type' },
    { label: _mft('salesProcess:fields.messageSent.label'), key: 'message_sent', sort: 'message_sent' },
];
const searchable = [
    'name',
];
const filters = [
]



</script>
