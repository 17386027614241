<script setup>
import {computed, onMounted, reactive, ref, watch} from "vue";
import IconChevron from "../icons/iconChevron.vue";
import {debounce} from "lodash";

const emit = defineEmits(['focusChange', 'leadSelected'])

const searchInput = ref(null)
const leads = ref([]);
const totalLeads = ref(null);
const paginationLinks = ref([]);
const searchPossible = ref(true)
const fetchingLeadsStatus = ref('')
const loadingLeads = ref(false)
const hasFocus = ref(false)
const searchPerformed = ref(false)

const searchParameters = reactive({
    fuzzy: null,
    myOwnContacts: false,
    tags: [],
    emailStatus: 'regardless-email',
    phoneStatus: 'regardless-phone',
    orderBy: 'updated-lastest',
});

const fetchLeads = async (link = null) => {
    if (! searchPossible.value) {
        return
    }

    let url = route('api.crm.filterLeads') + '?' + searchParametersUrlParams.value

    if (link !== null) {
        url = link.url + '&' + searchParametersUrlParams.value
    }
    try {
        fetchingLeadsStatus.value = 'waiting'
        loadingLeads.value = true
        const response = await axios.get(url);
        leads.value = response.data.leads.data;
        totalLeads.value = response.data.total_count;
        paginationLinks.value = response.data.leads.links;

        fetchingLeadsStatus.value = ''
        loadingLeads.value = false
        searchPossible.value = false;
        searchPerformed.value = true;

    } catch (error) {
    }
};

const updateSearch = () => {
    leads.value = [];
    totalLeads.value = null;
    paginationLinks.value = [];
    searchPerformed.value = false;
    fetchLeads();
};

const selectLead = (lead) => {
    emit('leadSelected', lead)
    searchParameters.fuzzy = null;
    hasFocus.value = false
    updateSearch()
};

const searchParametersUrlParams = computed(() => {
    return Object.keys(searchParameters).map((key) => {
        return key + '=' + searchParameters[key]
    }).join('&')
})

const focusInput = () => {
    searchInput.value.focusInput();
}

const hasInput = computed(() => {
    return searchParameters.fuzzy !== null && searchParameters.fuzzy.length > 0
})

watch(searchParameters, () => {
    hasFocus.value = true;
    searchPossible.value = true;
});

watch(() => searchParameters, debounce(() => {
    updateSearch()
}, 500), {deep: true})


watch(hasFocus, () => {
    emit('focusChange', hasFocus.value)
});

onMounted(() => {
    fetchLeads();
});

document.addEventListener('keydown', function(event) {
    if (event.key === 'Enter') {
        updateSearch()
    }

    if (event.key === 'Escape') {
        hasFocus.value = false
    }
});

defineExpose({focusInput})

</script>

<template>
<div
    :class="hasInput ? 'absolute inset-0 bg-white p-4 md:p-6 lg:p-8 z-20 lg:pb-0' : ''"
>
    <div class="relative">
        <span
            v-if="hasInput"
            class="absolute bottom-full -left-3"
        >
            <button-base
                size="sm"
                style-type="transparent"
                @click="searchParameters.fuzzy = ''"
                class="text-center"
            >
                <span class="flex items-center">
                    <icon-chevron class="w-4 h-4 rotate-90 fill-gray-400"></icon-chevron>
                    <span>{{ _mft('shared:navigation.back') }}</span>
                </span>
            </button-base>
        </span>
        <input-text
            type="text"
            ref="searchInput"
            :placeholder="_mft('crm:filters.search.placeholder')"
            v-model:content="searchParameters.fuzzy"
            @focus="hasFocus = true"
        />

        <span
            v-if="hasInput"
            class="absolute top-2 mt-0.5 right-1.5"
        >
            <button-base
                size="sm"
                :disabled="! searchPossible"
                :style-type="searchPossible ? 'primary' : 'disabled'"
                @click="updateSearch"
                :state="fetchingLeadsStatus"
                class="text-center"
            >
                {{ _mft('crm:filters.search') }}
            </button-base>
        </span>
    </div>

    <div
        v-if="hasInput"
        class="overflow-y-scroll absolute left-0 right-0 bottom-0 top-20"
    >
        <ul
            class="divide-y"
        >
            <li
                v-for="lead in leads"
                :key="lead.id"
                @click="selectLead(lead)"
                class="hover:bg-gray-50 px-4 md:px-6 lg:px-8 cursor-pointer py-2"
            >
                <div>{{ lead.name }}</div>
                <div
                    class="text-gray-400 text-xs"
                    v-if="lead.company"
                >{{ lead.company }}</div>
                <div class="text-gray-400 text-xs">{{ lead.email }}</div>
            </li>
            <li
                v-if="searchPerformed && searchParameters.fuzzy.length > 0 && leads.length === 0"
                class="hover:bg-gray-50 px-4 md:px-6 lg:px-8 cursor-pointer py-2 text-xs text-gray-400"
            >
                No contacts found
            </li>

        </ul>
    </div>

</div>
</template>

<style scoped>

</style>
