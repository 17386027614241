<template>
    <div v-if="value">
        <input-text
            label="Rubrik ovanför formulär"
            :required="true"
            v-model:content="value.text"
        />
        <input-text
            label="Brödtext under rubrik. "
            :required="true"
            v-model:content="value.description"
        />

        <toggle-switch
            class="flex-row-reverse"
            label="Jag vill ha en notis när någon fyller i formuläret"
            v-model:content="value.notifications_enabled"
        />

        <input-text
            label="Meddelande efter insamling av kontaktuppgifter"
            v-model:content="value.success_message"
        />

        <input-text
            inputtype="url"
            label="Redirect länk (till exempel till en tack-sida)"
            v-model:content="value.redirect"
        />

        <input-text
            inputtype="url"
            label="Länk till er privacy policy/integritetspolicy"
            :required="true"
            v-model:content="value.policy"
        />
        <small>Vi kommer att länka till er policy i formuläret. <a href="https://app.myflow.se/lp/integritetspolicy-experter" target="_blank"><b><u>Läs mer</u></b></a> om varför du behöver en privacy policy/integritetspolicy.</small>

        <expandable-section>
            <template v-slot:title>
                Valfria fält
            </template>
            <template v-slot:body>
                <div class="grid gap-5 md:grid-cols-2">
                    <div>
                        <toggle-switch
                            label="Samla in Titel"
                            v-model:content="value.collect_title"
                        />

                        <toggle-switch
                            label="Samla in Telefon"
                            v-model:content="value.collect_phone"
                        />

                        <toggle-switch
                            label="Samla in LinkedIn"
                            v-model:content="value.collect_linkedin"
                        />
                    </div>

                    <div>
                        <toggle-switch
                            label="Samla in Företagsnamn"
                            v-model:content="value.collect_company"
                        />

                        <toggle-switch
                            label="Samla in Organisationsnummer"
                            v-model:content="value.collect_org_nr"
                        />

                        <toggle-switch
                            label="Samla in Webbplats"
                            v-model:content="value.collect_website"
                        />
                    </div>
                </div>
            </template>
        </expandable-section>

        <expandable-section v-if="templates">
            <template v-slot:title>
                Avtal & offert
            </template>
            <template v-slot:body>
                <div class="grid gap-5 md:grid-cols-2">
                    <div>
                        <toggle-switch
                            label="Skapa avtal & offert vid ifyllnad"
                            v-model:content="value.create_contract"
                        />
                        <toggle-switch v-if="value.create_contract"
                            label="Aktivera signering direkt"
                            v-model:content="value.contract_signable"
                        />
                    </div>
                    <div>
                        <label v-if="value.create_contract">Dokumentmall</label>
                        <select class="myflow-basic-input" v-model="value.template" required v-if="value.create_contract">
                            <option
                                v-for="template in templates"
                                v-bind:key="template.id"
                                :selected="template.id === value.template"
                                :value="template.id"
                            >{{ template.label }}</option>
                        </select>

                        <label v-if="value.create_contract">Arbetsflöde</label>
                        <select class="myflow-basic-input" v-model="value.workflow" required v-if="value.create_contract">
                            <option
                                v-for="workflow in workflows"
                                v-bind:key="workflow.id"
                                :selected="workflow.id === value.workflow"
                                :value="workflow.id"
                            >{{ workflow.label }}</option>
                        </select>
                    </div>
                </div>
            </template>
        </expandable-section>

    </div>
    <mf-spinner-medium v-else class="w-16 h-8"></mf-spinner-medium>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';

export default {
    props: {
        modelValue: {},
    },
    emits: [
        'update:modelValue'
    ],
    data() {
        return {
            value: null,
            templates: null,
            workflows: [],
        };
    },
    async mounted() {
        this.captureModelValue();
        this.getTemplates();
        this.getWorkflows();
    },
    watch: {
        modelValue() {
            if (JSON.stringify(this.value) === JSON.stringify(this.modelValue)) { return; }
            this.captureModelValue();
        },
        value: {
            handler() {
                this.$emit('update:modelValue', JSON.parse(JSON.stringify(this.value)));
            },
            deep: true
        }
    },
    methods: {
        captureModelValue() {
            const value = this.modelValue ? JSON.parse(JSON.stringify(this.modelValue)) : {};
            if (!value.text) { value.text = null; }
            if (!value.description) { value.description = null; }
            if (!value.success_message) { value.success_message = "Tack, dina uppgifter har sparats!"; }
            if (!value.redirect) { value.redirect = null; }
            if (!value.policy) { value.policy = null; }
            if (value.collect_title === undefined) { value.collect_title = false; }
            if (value.collect_phone === undefined) { value.collect_phone = false; }
            if (value.collect_linkedin === undefined) { value.collect_linkedin = false; }
            if (value.collect_company === undefined) { value.collect_company = true; }
            if (value.collect_org_nr === undefined) { value.collect_org_nr = false; }
            if (value.collect_website === undefined) { value.collect_website = false; }

            if (value.create_contract === undefined) { value.create_contract = false; }
            if (value.contract_signable === undefined) { value.contract_signable = false; }
            if (value.add_self_as_signee === undefined) { value.add_self_as_signee = false; }
            if (value.template === undefined) { value.template = false; }
            if (value.workflow === undefined) { value.workflow = false; }

            this.value = value;
        },
        async getTemplates() {
            const response = await axios.get(route('expert-contracts.templatesList'));
            this.templates = response.data;
        },
        async getWorkflows() {
            const response = await axios.get(route('expert-contracts.workflowList'));
            this.workflows = response.data;
        },
    },
}
</script>
