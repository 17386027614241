<template>
  <media-bank-entries-display :data-url="entryUrl">
    <template #cloudflare-video-entry="{ entry, attrs }">
      <cloudflare-player
        v-bind="attrs"
        :video="entry.cloudflare_video_meta"
        :update-interval="updateInterval"
        :paused="paused"
        @playheadTimeUpdate="$emit('playheadTimeUpdate', $event)"
        @timestampUpdate="$emit('timestampUpdate', $event)"
        @progressUpdate="$emit('progressUpdate', $event)"
        @playerInitialized="$emit('playerInitialized', $event)"
      ></cloudflare-player>
    </template>
    <template #spinner>
      <cloudflare-player></cloudflare-player>
    </template>
  </media-bank-entries-display>
</template>

<style scoped>
</style>

<script>
export default {
  emits: [
    'playheadTimeUpdate',
    'timestampUpdate',
    'progressUpdate',
    'playerInitialized'
  ],
  props: {
    courseId: Number,
    chapterId: Number,
    videoId: Number,
    updateInterval: {
      type: Number,
      default: 2000
    },
    paused: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    entryUrl() {
      return route('courses.chapters.videos.playbackSession', [
        this.courseId,
        this.chapterId,
        this.videoId
      ]);
    }
  }
}
</script>
