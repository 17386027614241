<script setup>
import {computed, ref} from "vue";

const props = defineProps({
    post: {
        type: Object,
        required: true
    }

})
const expanded = ref(false)

const expandable = computed(() => {
    return props.post.content.length > 200
})

const postContentClass = computed(() => {
    if (! expandable.value) {
        return ''
    }
    return expanded.value ? '' : 'h-48 lg:h-24 overflow-hidden'
})

</script>

<template>
    <div
        class="relative mb-2 whitespace-break-spaces"
        :class="postContentClass"
    >
        {{ post.content }}
        <div
            v-if="! expanded && expandable"
            class="absolute bottom-0 w-full"
        >
            <div
                class="h-6 bg-gradient-to-t from-white/90 via-white/50 to-transparent"
            ></div>
            <div
                class="bg-white/90 pb-2 text-center text-xs text-black"
            >
                <button-transparent
                    size="xs"
                    @click="expanded = true"
                >
                    {{ _mft('shared:showMore') }}
                </button-transparent>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
