<template>
    <div class="my-6">
        <div class="px-4 -mx-4 overflow-hidden md:-mx-6 lg:-mx-8 md:px-6 lg:px-8">
            <div
                class="relative w-full py-4 mx-auto max-w-7xl aspect-video"
                v-on:mouseenter="pause = true"
                v-on:mouseleave="pause = false"
            >
                <media-bank-entry
                    v-for="(propertyName, i) in mediaBankEntryPropertyNames"
                    :key="i"
                    :container-ref="containerRef"
                    :block-uuid="uuid"
                    :block-content="content"
                    :media-bank-entry-id="content[propertyName]"
                    :property-name="propertyName"
                >
                    <template #entry="{ entry }">
                        <div
                            class="absolute inset-0 transition-all duration-500 transform bg-center bg-cover aspect-video rounded-custom shadow-custom"
                            :class="[goingIn === i ? 'translate-x-1/2 opacity-0 scale-75 z-1' : '', current === i ? 'z-2' : '', goingOut === i ? '-translate-x-1/2 opacity-0 scale-75 z-3' : '', goingIn !== i && current !== i && goingOut !== i ? 'hidden' : '']"
                            :style="{backgroundImage: 'url(' + entry.url + ')'}"
                        ></div>
                    </template>
                    <template #spinner><span></span></template>
                </media-bank-entry>

                <button
                    v-on:click.prevent="next()"
                    class="absolute z-20 text-white transform -translate-y-1/2 top-1/2 right-4"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-12 h-12">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                    </svg>
                </button>
                <button
                    v-on:click.prevent="prev()"
                    class="absolute z-20 text-white transform -translate-y-1/2 top-1/2 left-4"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-12 h-12">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import MediaBankEntry from './util/media-bank-entry.vue';
export default {
    components: {
        MediaBankEntry
    },
    props: {
        containerRef: {
            type: String,
            required: true
        },
        uuid: {
            type: String,
            require: true
        },
        content: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            current: null,
            goingOut: null,
            goingIn: null,
            pause: false
        }
    },

    computed: {
        mediaBankEntryPropertyNames() {
            const propertyNames = [];
            for (let i = 0; i < this.content.media_bank_entries_count; i++) {
                propertyNames.push(`media_bank_entry_${i}_id`);
            }
            return propertyNames;
        }
    },

    methods: {
        next() {
            this.pause = true;
            this.current = this.current + 1 >= this.content.media_bank_entries_count ? 0 : this.current + 1;
        },
        prev() {
            this.pause = true;
            this.current = this.current - 1 < 0 ? this.content.media_bank_entries_count - 1 : this.current - 1;
        }
    },

    watch: {
        current(val){
            this.goingOut = this.current - 1 < 0 ? this.content.media_bank_entries_count - 1 : this.current - 1;
            this.goingIn = this.current + 1 >= this.content.media_bank_entries_count ? 0 : this.current + 1;
        }
    },

    mounted() {
        this.current = 0;
        setInterval(() => {
            if( this.pause ) return;
            if( this.current + 1 == this.content.media_bank_entries_count ){
                this.current = 0;
            } else {
                this.current++;
            }
        }, 3800);
    }
};
</script>

<style scoped>
.aspect-video {
    aspect-ratio: 16 / 9;
}
.z-1 {
    z-index: 1;
}
.z-2 {
    z-index: 2;
}
.z-3 {
    z-index: 3;
}
</style>
