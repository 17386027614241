<script setup>
/*
    Example usage:
    <widget-single-value
        main-value="5268"
        main-prefix="minst"
        main-suffix="kr"
        main-byline="Köp senaste veckan"
        main-trend="negative"
        secondary-value="2684"
        secondary-prefix="minst"
        secondary-suffix="kr"
        secondary-byline="Köp föregående vecka"
        fontawesome-class="fa-solid fa-house"
    ></widget-single-value>
 */

import {computed} from "vue";

const props = defineProps({
    mainValue: {
        type: String,
        required: false,
    },
    mainPrefix: {
        type: String,
        required: false,
    },
    mainSuffix: {
        type: String,
        required: false,
    },
    mainClass: {
        type: String,
        required: false,
        default: 'text-2xl'
    },
    mainTrend: {
        type: String,
        required: false,
        default: 'neutral',
        options: ['positive', 'neutral', 'negative'],
    },
    mainByline: {
        type: String,
        required: false,
    },
    secondaryValue: {
        type: String,
        required: false,
    },
    secondaryPrefix: {
        type: String,
        required: false,
    },
    secondarySuffix: {
        type: String,
        required: false,
    },
    secondaryClass: {
        type: String,
        required: false,
    },
    secondaryByline: {
        type: String,
        required: false,
    },
    fontawesomeClass: {
        type: String,
        required: false,
    },
})

const trendClass = computed(() => {
    return {
        'text-success': props.mainTrend === 'positive',
        'text-gray-700': props.mainTrend === 'neutral',
        'text-danger': props.mainTrend === 'negative',
    }
})

</script>

<template>
    <card-basic
        class="!py-4 !px-6"
    >
        <template #body>
            <div
                class="flex justify-center items-end space-x-0.5"
                :class="mainClass"
            >
                <span
                    v-if="mainPrefix"
                    class="text-lg"
                >
                    {{ mainPrefix }}
                </span>
                <span
                    v-if="mainValue"
                    class="font-semibold"
                    :class="trendClass"
                >
                    {{ mainValue }}
                </span>
                <span
                    v-if="mainSuffix"
                    class="text-lg"
                >
                    {{ mainSuffix }}
                </span>
            </div>
            <div
                v-if="mainByline"
                class="text-center text-xs text-gray-500"
            >
                {{ mainByline }}
            </div>

            <div class="text-gray-400 mt-2 flex flex-row-reverse items-end justify-between">
                <div>
                    <div
                        class="flex justify-center items-end space-x-0.5 text-sm"
                        :class="secondaryClass"
                    >
                        <span
                            v-if="secondaryPrefix"
                        >
                            {{ secondaryPrefix }}
                        </span>
                        <span
                            v-if="secondaryValue"
                        >
                            {{ secondaryValue }}
                        </span>
                        <span
                            v-if="secondarySuffix"
                        >
                            {{ secondarySuffix }}
                        </span>
                    </div>
                    <div
                        v-if="secondaryByline"
                        class="text-center text-xs"
                    >
                        {{ secondaryByline }}
                    </div>
                </div>
                <div
                    v-if="fontawesomeClass"
                    class="text-2xl text-gray-800"
                >
                    <i :class="fontawesomeClass"></i>
                </div>
            </div>
            <slot></slot>
        </template>
    </card-basic>
</template>

<style scoped>

</style>
