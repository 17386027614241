<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
            <h1 class="text-xl font-semibold text-gray-900">Manuella fakturor</h1>
            <p class="mt-2 text-sm text-gray-700">Alla manuella fakturor (ej kortbetalningar)</p>
        </div>
    </div>
    <card-basic >
        <template v-slot:body>
            <div class="overflow-x-scroll">
                <table class="min-w-full divide-y divide-gray-300">
                    <thead>
                        <tr>
                            <th scope="col" class="text-gray-500" :class="thClass">ID</th>
                            <th scope="col" class="text-gray-900" :class="thClass">Namn</th>
                            <th scope="col" class="text-gray-900" :class="thClass">E-post</th>
                            <th scope="col" class="text-gray-900" :class="thClass">Adress</th>
                            <th scope="col" class="text-gray-900" :class="thClass">Beställning</th>
                            <th scope="col" class="text-gray-900" :class="thClass">Summa</th>
                            <th scope="col" class="text-gray-900" :class="thClass">Datum & Tid</th>
                            <th scope="col" class="text-gray-900" :class="thClass"></th>
                        </tr>
                    </thead>
                    <tbody class="bg-white divide-y divide-gray-200">
                        <tr
                            v-for="billable in billables"
                            v-bind:key="billable.id"
                            :class="trClassInject(billable)"
                        >
                            <td :class="tdClassInject(0)" class="text-gray-500">{{ billable.id }}</td>
                            <td :class="tdClassInject(0)" class="text-gray-900">{{ billable.name }}</td>
                            <td :class="tdClassInject(0)" class="text-gray-900">{{ billable.email }}</td>
                            <td :class="tdClassInject(0)" class="text-gray-900">
                                {{ billable.billing_address_1 }}<br>
                                <span v-if="billable.billing_address_2">{{ billable.billing_address_2 }}<br></span>
                                {{ billable.billing_postal_code }} {{ billable.billing_postal_city }}<br>
                                {{ billable.billing_postal_country.toUpperCase() }}
                            </td>
                            <td :class="tdClassInject(0)" class="text-gray-900">
                                <span v-for="invoice_items in billable.invoice_items" v-bind:key="invoice_items.id">
                                    {{ invoice_items.description.substr(0, 30) }}...<br>
                                </span>
                            </td>
                            <td :class="tdClassInject(0)" class="text-gray-900">{{ billable.sum }} kr</td>
                            <td :class="tdClassInject(0)" class="text-gray-900">{{ billable.created_at }}</td>
                            <td :class="tdClassInject(0)" class="text-gray-900">
                                <a v-if="!billable.invoice_sent && !billable.invoice_paid" :href="route('payment.markAsBilled', billable.id)" class="myflow-basic-button">Markera som fakturerad</a>
                                <a v-if="billable.invoice_sent && !billable.invoice_paid" :href="route('payment.markAsPaid', billable.id)" class="myflow-basic-button">Markera som betald</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </template>
    </card-basic>
</template>

<script>
    export default {
        props: {
            billablesData: Object
        },
        data: function(){
            return {
                billables: this.billablesData,
                thClass: 'whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold',
                tdClass: 'px-2 py-2 text-sm whitespace-nowrap',

                trClassPendingSend: 'bg-yellow-100',
                trClassPendingPayment: 'bg-green-100',


                incomeClass: ' text-myflowGreen-900',
                expenseClass: ' text-myflowRed-900',
            }

        },
        methods: {
            tdClassInject(sum) {
                if(sum > 0) {
                    return this.tdClass + this.incomeClass;
                } else if(sum < 0) {
                    return this.tdClass + this.expenseClass;
                } else {
                    return this.tdClass;
                }
            },
            trClassInject(billable) {
                if(billable.invoice_sent == false) {
                    return this.trClassPendingSend;
                } else if(billable.invoice_sent == true && billable.invoice_paid == false) {
                    return '';
                } else {
                    return this.trClassPendingPayment;
                }
            }
        },
    }
</script>
