<script setup>
    import { computed, onMounted, ref } from 'vue'

    const threads = ref([]);

    const loadMessageThreads = () => {
        threads.value = [];
        axios.get(route('support.chat.message.thread.index'))
            .then(response => {
                threads.value = response.data.threads;
            })
            .catch(error => {
                console.log(error);
            });
    }

    onMounted(() => {
        loadMessageThreads();
    });

</script>
<template>
    <div class="w-full">
        <div class="flex items-center justify-between mb-4">
            <h1>Ärenden</h1>
        </div>
        <card-basic
            class="flex flex-col justify-between"
        >
            <template v-slot:body>
                <ul>
                    <li
                        v-for="thread in threads"
                        :key="thread.id"
                        class="flex justify-between py-1 space-x-4 group"
                    >
                        <a :href="route('support.chat.message.thread.show', thread.id)" class="text-myflowPurple">Visa</a>
                    </li>
                </ul>
            </template>
        </card-basic>
    </div>
</template>
