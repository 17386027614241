<template>
    <div class="flex flex-row justify-center w-full">
        <div class="flex justify-center w-auto">
            <div class="flex flex-col items-center justify-center w-full max-w-sm overflow-hidden text-center bg-white rounded-custom shadow-custom">
                <media-bank-entries-display
                    v-if="mediaBankEntryData"
                    class="w-full"
                    alt=""
                    :data="mediaBankEntryData"
                />
                <div class="p-8 md:px-12 md:p-6">
                    <h2 class="mb-2">{{name}}</h2>
                    <div v-html="description"></div>
                    <p class="my-4 text-xl flex flex-col justify-center">
                        <span>{{price}}</span>
                        <span class="text-xs text-gray-400 -mt-1" v-if="includesVat">inkl. moms</span>
                        <span class="text-xs text-gray-400 -mt-1" v-else>exkl. moms</span>
                    </p>
                    <a v-if="linkHref" :href="linkHref" class="inline-block px-4 py-2 purchase-button rounded-custom">Köp nu</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        name: { type: String, required: true },
        description: { type: String, required: true },
        price: { type: String, required: true },
        mediaBankEntryData:  { type: Object, default: null },
        includesVat: { type: Boolean, required: false, default: false},
        linkHref: { type: String, required: true }
    }
};
</script>
