<template>
    <div v-if="value">
        <input-option
            :label="_mft('blockEditor:textAlignment.label')"
            :options="[
                { label: _mft('blockEditor:textAlignment.left'), value: 'left' },
                { label: _mft('blockEditor:textAlignment.center'), value: 'center' },
                { label: _mft('blockEditor:textAlignment.right'), value: 'right' },
            ]"
            v-model:content="value.alignment"
        />
        <input-editor
            :label="_mft('blockEditor:content.label')"
            :required="true"
            v-model:content="value.text"
        />
    </div>
    <mf-spinner-medium v-else class="w-16 h-8"></mf-spinner-medium>
</template>
<script>

export default {
    props: {
        modelValue: {},
    },
    emits: [
        'update:modelValue'
    ],
    data() {
        return {
            value: null
        };
    },
    async mounted() {
        this.captureModelValue();
    },
    watch: {
        modelValue() {
            if (JSON.stringify(this.value) === JSON.stringify(this.modelValue)) { return; }
            this.captureModelValue();
        },
        value: {
            handler() {
                this.$emit('update:modelValue', JSON.parse(JSON.stringify(this.value)));
            },
            deep: true
        }
    },
    methods: {
        captureModelValue() {
            const value = this.modelValue ? JSON.parse(JSON.stringify(this.modelValue)) : {};
            if (!value.text) { value.text = null; }
            if (!value.alignment) { value.alignment = 'left'; }
            this.value = value;
        }
    },
}
</script>
