<script setup>

import {onMounted, ref, watch} from "vue";

const emit = defineEmits(['reload'])

const pendingTemplates = ref([])
const templateIdsWaitingForDuplication = ref([])

const loadInvitations = async () => {
    const response = await axios.get(route('api.templates.pendingTemplates'));
    pendingTemplates.value = response.data.pending_templates;
}

const acceptTemplate = async (template) => {
    if (templateIdsWaitingForDuplication.value.includes(template.id)) {
        return
    }

    templateIdsWaitingForDuplication.value.push(template.id)
    const res = await axios.get(route('api.templates.acceptTemplate', template.id))

    if (res.data.success) {
        templateIdsWaitingForDuplication.value = templateIdsWaitingForDuplication.value.filter(id => id !== template.id)
    }
}
const declineTemplate = async (template) => {
    const res = await axios.get(route('api.templates.declineTemplate', template.id))
    await loadInvitations();
}

watch(templateIdsWaitingForDuplication, (value) => {
    if (value.length === 0) {
        loadInvitations();
        emit('reload');
    }
}, {deep: true})

onMounted(() => {
    loadInvitations();
})

</script>
<template>
    <list-basic v-if="pendingTemplates.length > 0">
        <template #header>
            <h1 class="text-lg mb-1">
                {{ _mft('pagebuilderTemplate:invitation.title') }}
            </h1>
        </template>
        <list-basic-item
            v-for="(pendingTemplate, i) in pendingTemplates"
            class="hover:bg-gray-50"
        >
            <div>
                <div>
                    <b>{{ pendingTemplate.template.name }}</b><br>
                    <span class="text-xs text-gray-400">From {{ pendingTemplate.template.user.name }}</span>
                </div>
            </div>
            <template #actions>
                <div class="flex space-x-2">
                    <button-danger
                        v-if="! templateIdsWaitingForDuplication.includes(pendingTemplate.id)"
                        size="sm"
                        @click="declineTemplate(pendingTemplate)"
                    >
                        {{ _mft('shared:action.decline') }}
                    </button-danger>
                    <button-success
                        size="sm"
                        :state="templateIdsWaitingForDuplication.includes(pendingTemplate.id) ? 'waiting' : ''"
                        :disabled="templateIdsWaitingForDuplication.includes(pendingTemplate.id)"
                        @click="acceptTemplate(pendingTemplate)"
                    >
                        {{ _mft('shared:action.accept') }}
                    </button-success>
                </div>
            </template>
        </list-basic-item>
        <template #footer>
            <p class="mb-0 mt-2 italic text-xs text-gray-400">
                {{ _mft('pagebuilderTemplate:invitation.footer') }}
            </p>
        </template>
    </list-basic>
</template>
