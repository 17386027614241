<template>
    <div v-if="requests !== undefined">
        <div
            v-for="(request, idx) in requests"
            v-bind:key="idx"
        >
            <p v-if="request.isSigned" class="text-center"><strong>{{ _mft('contractSignature:contractHasBeenSigned') }}.</strong></p>
            <div v-else>

                <card-collapsible>
                    <template v-slot:header>
                            <div>
                                <h5 class="mt-2">
                                    {{ request.contract.name }} <small>version {{ request.contract.latest_version.date }}</small>
                                </h5>
                                <p>
                                    {{ _mft('contractSignature:needsToBeSignedBy') }} <i>{{ request.signee.name }}</i>
                                </p>
                            </div>
                        </template>
                    <template v-slot:preview>
                        <!-- <pre
                            v-if="request.can_sign"
                            class="px-2 py-3 whitespace-pre-line border pre-scrollable"
                            v-html="request.contract.latest_version.content.substr(0,120) + '...'"
                        ></pre> -->
                        <pre
                            v-if="request.can_sign"
                            class="px-2 py-3 whitespace-pre-line border pre-scrollable"
                            v-html="request.contract.latest_version.content.substr(0,200).replace(/(<([^>]+)>)/gi, ' ') + '...'"
                        ></pre>
                        <i>{{ _mft('contractSignature:clickTopRightToRead') }}</i>
                        <div v-if="request.can_sign && !request.isSigned" class="text-center">
                            <p v-if="request.isFailed">
                                {{ _mft('contractSignature:errorTryAgainLater') }}
                            </p>
                            <button v-if="!request.isSigning" class="mt-4 mb-4 myflow-basic-button" @click.prevent="sign(request)">
                                {{ _mft('contractSignature:signFor') }} <i>{{ request.contract.name }}</i>
                            </button>
                            <bankid-prompt v-else-if="request.bankIdRequest" :request="request.bankIdRequest" @completed="didSign(request)" @failed="didFailToSign(request)"></bankid-prompt>
                            <mf-spinner-medium
                                v-else
                                class="w-24 h-12"
                            />
                        </div>
                    </template>
                    <template v-slot:body>
                        <pre
                            v-if="request.can_sign"
                            class="px-2 py-3 whitespace-pre-line border pre-scrollable"
                            v-html="request.contract.latest_version.content"
                        ></pre>
                        <div v-if="request.can_sign && !request.isSigned" class="text-center">
                            <p v-if="request.isFailed">
                                {{ _mft('contractSignature:errorTryAgainLater') }}
                            </p>

                            <button v-if="!request.isSigning" class="mt-4 mb-4 myflow-basic-button" @click.prevent="sign(request)">
                                {{ _mft('contractSignature:signFor') }} <i>{{ request.contract.name }}</i>
                            </button>
                            <bankid-prompt v-else-if="request.bankIdRequest" :request="request.bankIdRequest" @completed="didSign(request)" @failed="didFailToSign(request)"></bankid-prompt>
                            <mf-spinner-medium
                                v-else
                                class="w-24 h-12"
                            />
                        </div>
                        <p v-else class="text-center">
                            <strong>
                                {{ _mft('contractSignature:notAuthorisedToSign') }}
                            </strong
                            >
                        </p>
                    </template>
                </card-collapsible>
            </div>

        </div>
    </div>
    <div v-else>
        <div class="mt-4 text-center">
            <mf-spinner-medium
                class="w-24 h-12"
            />
        </div>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    props: {
        redirectUrl: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            requests: undefined
        };
    },
    async mounted() {
        const response = await axios.get('/contract_signatures/requests');
        this.requests = response.data.data.map(request => {
            return {
                isSigning: false,
                isFailed: false,
                isSigned: false,
                bankIdRequest: null,
                ...request
            };
        });
        this.redirectIfAllIsSigned();
    },
    methods: {
        async sign(request) {
            request.isSigning = true;
            request.isFailed = false;

            const data = {
                contract_id: request.contract.id,
                contract_version_id: request.contract.latest_version.id
            };

            if (request.signee.is_company) {
                data.company_id = request.signee.id;
            }

            try {
                const response = await axios.post('/contact_sigantures?bankid=true', data);
                request.bankIdRequest = response.data;
            } catch (error) {
                this.didFailToSign(request);

                throw error;
            }
        },
        didSign(request) {
            request.isSigned = true;
            request.isSigning = false;

            this.redirectIfAllIsSigned();
        },
        didFailToSign(request) {
            request.isFailed = true;
            request.isSigning = false;
            request.bankIdRequest = null;
        },
        redirectIfAllIsSigned() {
            let allIsSigned = true;
            this.requests.forEach((request) => {
                allIsSigned = allIsSigned && request.isSigned;
            });

            if (!allIsSigned) { return; }

            this.$document.locationAssign(this.redirectUrl);
        }
    }
};
</script>
