<script setup>

import {computed, onMounted, ref} from "vue";
import {_mft} from "../../i18n";

const emit = defineEmits(['createdOrUpdated'])
const props = defineProps({
    product: {
        type: Object,
        required: false,
        default: null
    },
    existingProductNames: {
        type: Array,
        required: false,
        default: () => [],
    },
})

const _product = ref({
    id: null,
    title: '',
    description: '',
    price: 0,
    unit: '',
})
const createOrUpdateState = ref('')

const createOrUpdate = async () => {
    createOrUpdateState.value = 'waiting'
    let res
    let action
    if (_product.value.id) {
        res = await axios.put(route('api.custom-products.update', _product.value.id), _product.value)
        action = 'updated'
    } else {
        res = await axios.post(route('api.custom-products.create'), _product.value)
        action = 'created'
    }
    if (res.data.success) {
        createOrUpdateState.value = 'success'
        emit('createdOrUpdated', action)
    } else {
        createOrUpdateState.value = 'error'
    }
}

const productNameIsUnique = computed(() => {
    if (! _product) {
        return true
    }
    if (_product.value.title === props.product.title) {
        return true
    }
    return ! props.existingProductNames.includes(_product.value.title)
})

onMounted(() => {
    if (props.product.id) {
        _product.value = JSON.parse(JSON.stringify(props.product))
    }
})
</script>
<template>
    <div v-if="_product">
        <input-text
            :label="_mft('invoiceLink:product.name.label')"
            autofocus
            required
            v-model:content="_product.title"
        />
        <div
            v-if="! productNameIsUnique"
            class="text-xs text-gray-400 italic -mt-4 mb-4"
        >
            {{ _mft('customProduct:productNameExists') }}
        </div>

        <input-editor
            :label="_mft('invoiceLink:product.description.label')"
            v-model:content="_product.description"
        />

        <div class="grid grid-cols-2 gap-4">
            <input-number
                :label="_mft('invoiceLink:product.price.label')"
                v-model:content="_product.price"
                required
                @blur="isNaN(product.price) ? product.price = 0 : null"
            />

            <input-text
                :label="_mft('invoiceLink:product.unit.label')"
                v-model:content="_product.unit"
                :placeholder="_mft('customProduct:unit.placeholder')"
            />
        </div>

        <div
            class="flex justify-end"
        >
            <button-primary
                :state="createOrUpdateState"
                @click="createOrUpdate"
            >
                {{ _product.id ? _mft('shared:action.update') : _mft('shared:action.create') }}
            </button-primary>
        </div>
    </div>
</template>
