<template>
    <div
        v-if="state.open"
        class="fixed inset-x-0 bottom-0 pointer-events-none z-cta sm:flex sm:justify-center sm:px-6 sm:pb-5 lg:px-8"
    >
        <div
            class="pointer-events-auto sm:flex items-center justify-between gap-x-6 bg-gray-900 px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
            <p class="text-sm leading-6 text-white">
                <a :href="route('bootcamp.register')" class="text-white">
                    <strong class="font-semibold">{{ _mft('bootcamp:cta.register.title') }}</strong><br>
                    {{ _mft('bootcamp:cta.register.intro') }} &nbsp;
                    <span aria-hidden="true">&rarr;</span>
                </a>
            </p>
            <button type="button" v-on:click="submit" class="-m-1.5 flex-none p-1.5">
                <span class="sr-only">{{ _mft('shared:modal.close') }}</span>
                <XCircleIcon class="w-5 h-5 text-white" aria-hidden="true" />
            </button>
        </div>
    </div>
</template>

<script setup>
import { XCircleIcon } from '@heroicons/vue/solid'
import { reactive, onMounted } from 'vue'

const state = reactive({
    open: true,
})

const submit = async () => {
    axios.post(route('banner.workshopBanner')).then(function (response) {
    })
    .catch(error => {
    });

    state.open = false;
};

</script>
