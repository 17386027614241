<script setup>
import { onMounted, ref, computed } from 'vue';
import axios from 'axios';

const community = ref([]);
const invoiceLinks = ref([]);
const channels = ref([]);
const memberCount = ref([]);
const members = ref([]);
const creatingChannel = ref(false);
const previewingChannel = ref(false);
const creatingInvoiceLink = ref(false);

const props = defineProps({
    communityId: Number,
});

const createChannelData = ref({
    name: '',
    lockedForCommunityPosts: false,
    trackAccountability: false,
    dailyCheckInQuestions: {
        question1: null,
        question2: null,
        question3: null,
        question1Enabled: false,
        question2Enabled: false,
        question3Enabled: false,
    },
    reminderTime: '06:00',
    reminderDays: {
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: false,
        sunday: false,
    }
});

const previewChannel = () => {
    previewingChannel.value = true;
}

async function createChannel() {
    if (! newChannelValidates.value) {
        return;
    }
    const response = await axios.post(route('community.admin.api.storeChannel', props.communityId), createChannelData.value);

    await getCommunity();

    creatingChannel.value = false;
    previewingChannel.value = false;

    createChannelData.value = {
        name: '',
        lockedForCommunityPosts: false,
        trackAccountability: false,
        dailyCheckInQuestions: {
            question1: null,
            question2: null,
            question3: null,
            question1Enabled: false,
            question2Enabled: false,
            question3Enabled: false,
        },
        reminderTime: '06:00',
        reminderDays: {
            monday: false,
            tuesday: false,
            wednesday: false,
            thursday: false,
            friday: false,
            saturday: false,
            sunday: false,
        }
    };
}

async function getCommunity() {
    const response = await axios.get(route('community.admin.api.show', props.communityId));
    community.value = response.data.community;
    invoiceLinks.value = response.data.invoice_links;
    channels.value = response.data.channels;
    memberCount.value = response.data.member_count;
    members.value = response.data.members;
}

async function revokeMembership(memberId) {
    const response = await axios.delete(route('community.admin.api.revokeMembership', memberId));
    if (response.data.message === 'Membership revoked') {
        getCommunity();
    }
}

async function archiveChannel(channelId) {
    const response = await axios.post(route('community.admin.api.archiveChannel', channelId));
    if (response.data.message === 'Channel archived') {
        getCommunity();
    }
}

const paymentLinkCreated = () => {
    creatingInvoiceLink.value = false
    getCommunity()
}

const newChannelValidates = computed(() => {
    return createChannelData.value.name.length > 0
});

const paymentLinkProducts = computed(() => {
    return [{
        title: community.value.name,
        price: 0,
        quantity: 1,
        description: '',
        linkableId: community.value.id,
        linkableType: 'community',
        quantity_is_fixed: false,
    }]
})

onMounted(() => {
    getCommunity();
});

</script>

<template>
    <div>
        <div class="md:sticky top-2 z-topbar mb-8">
            <frosted-bar>
                <div class="flex items-center justify-between space-x-4">
                    <h1>{{ community.name }}</h1>

                    <div class="flex justify-end items-center space-x-4 mt-1 lg:mg-0">
                        <story-lane-modal :module="'communities'"></story-lane-modal>

                        <button-secondary
                            @click="creatingInvoiceLink = true"
                        >
                            {{ _mft('community:createInvoiceLink') }}
                        </button-secondary>
                    </div>
                </div>

                <template #navigation>
                    <a
                        :href="route('community.admin.index')"
                        class="mf-link text-xs"
                    >
                        {{ _mft('community:allCommunities') }}
                    </a>
                </template>

            </frosted-bar>
        </div>

        <card-tabbed>
            <card-tabbed-tab
                :title="_mft('community:members.title')"
                value="members"
                :active="true"
            >
                <ul
                    v-if="memberCount > 0"
                    class="divide-y"
                >
                    <li
                        v-for="member in members"
                        :key="member.id"
                        class="pb-2"
                    >
                        <div class="flex justify-between mt-2">
                            <div>
                                <div>
                                    {{ member.name }}
                                </div>
                                <small class="block text-gray-400 -mt-0.5">
                                    {{ _mft('community:memberSince') }} {{ member.created_at }}
                                </small>
                            </div>
                            <div>
                                <button-with-confirmation
                                    size="sm"
                                    confirm-type="danger"
                                    :confirm-text="_mft('shared:confirm.areYouSure')"
                                    :confirm-button-label="_mft('community:member.revokeMembership')"
                                    :abort-button-label="_mft('shared:action.cancel')"
                                    @confirmed="revokeMembership(member.id)"
                                >
                                    {{ _mft('community:member.revokeMembership') }}
                                </button-with-confirmation>
                            </div>
                        </div>
                    </li>
                </ul>
                <p v-else>
                    {{ _mft('community:noMembersYet') }}
                </p>
            </card-tabbed-tab>

            <card-tabbed-tab
                :title="_mft('community:channels.title')"
                value="channels"
            >
                <ul
                    v-if="channels.length > 0"
                    class="mb-4 divide-y"
                >
                    <li
                        v-for="channel in channels"
                        :key="channel.id"
                        class="pb-2 min-h-9"
                    >
                        <div class="flex items-center justify-between mt-2">
                            <div :class="channel.archived ? 'opacity-50' : ''">
                                <div>
                                    {{ channel.name }}
                                </div>
                                <small class="block text-gray-400 -mt-0.5">
                                    {{ channel.locked_for_community_posts ? _mft('community:channel.type.lockedForCommunityPosts') : '' }}
                                    {{ channel.accountability_enabled ? _mft('community:channel.type.trackAccountability') : '' }}
                                </small>
                            </div>
                            <div>
                                <button-with-confirmation
                                    v-if="! channel.archived"
                                    size="sm"
                                    confirm-type="danger"
                                    :confirm-text="_mft('shared:confirm.areYouSure')"
                                    :confirm-button-label="'Archive channel'"
                                    :abort-button-label="_mft('shared:action.cancel')"
                                    @confirmed="archiveChannel(channel.id)"
                                >
                                    {{ _mft('community:archiveChannel') }}
                                </button-with-confirmation>
                            </div>

                        </div>
                    </li>
                </ul>
                <p
                    v-else
                    class="italic text-gray-400"
                >
                    {{ _mft('community:noChannelsYet') }}
                </p>

                <div class="flex justify-end">
                    <button-primary
                        v-on:click="creatingChannel = true"
                        size="sm"
                    >
                        {{ _mft('community:addChannel') }}
                    </button-primary>
                </div>

                <slideout-with-slots
                    v-if="creatingChannel"
                    @close="creatingChannel = false"
                >
                    <template #title>
                        <h2>
                            {{ _mft('community:newChannel.title') }}
                        </h2>
                    </template>
                    <template #body>
                        <div v-if="previewingChannel">
                            <div>
                                <input-label label="Channel name" />
                                <p class="border-l py-1 pl-2 ml-0.5 min-h-6">{{ createChannelData.name }}</p>

                                <input-label
                                    v-if="createChannelData.lockedForCommunityPosts"
                                    :label="_mft('community:newChannel.lockedForCommunityPosts.label')"
                                    class="mb-8"
                                />

                                <div
                                    v-if="createChannelData.trackAccountability"
                                >
                                    <div
                                        class="mb-2"
                                        v-if="createChannelData.dailyCheckInQuestions.question1Enabled"
                                    >
                                        <input-label
                                            :label="_mft('community:newChannel.firstDayilyCheckinQuestion.label')"
                                        />
                                        <p class="border-l py-1 pl-2 ml-0.5 min-h-6">
                                            {{ createChannelData.dailyCheckInQuestions.question1 }}
                                        </p>
                                    </div>
                                    <div
                                        class="mb-2"
                                        v-if="createChannelData.dailyCheckInQuestions.question2Enabled"
                                    >
                                        <input-label
                                            :label="_mft('community:newChannel.secondDayilyCheckinQuestion.label')"
                                        />
                                        <p class="border-l py-1 pl-2 ml-0.5 min-h-6">
                                            {{ createChannelData.dailyCheckInQuestions.question2 }}
                                        </p>
                                    </div>
                                    <div
                                        class="mb-2"
                                        v-if="createChannelData.dailyCheckInQuestions.question3Enabled"
                                    >
                                        <input-label
                                            :label="_mft('community:newChannel.thirdDayilyCheckinQuestion.label')"
                                        />
                                        <p class="border-l py-1 pl-2 ml-0.5 min-h-6">
                                            {{ createChannelData.dailyCheckInQuestions.question3 }}
                                        </p>
                                    </div>

                                    <div
                                        v-if="Object.values(createChannelData.reminderDays).filter(day => day).join(', ').length > 0"
                                    >
                                        <input-label
                                            :label="_mft('community:newChannel.preview.sendReminder.label', {time: createChannelData.reminderTime})"
                                        >
                                        </input-label>
                                        <ul class="border-l py-1 pl-2 ml-0.5 space-y-1">
                                            <li v-if="createChannelData.reminderDays.monday === true">{{ _mft('calendar:weekday.monday.long') }}</li>
                                            <li v-if="createChannelData.reminderDays.tuesday === true">{{ _mft('calendar:weekday.tuesday.long') }}</li>
                                            <li v-if="createChannelData.reminderDays.wednesday === true">{{ _mft('calendar:weekday.wednesday.long') }}</li>
                                            <li v-if="createChannelData.reminderDays.thursday === true">{{ _mft('calendar:weekday.thursday.long') }}</li>
                                            <li v-if="createChannelData.reminderDays.friday === true">{{ _mft('calendar:weekday.friday.long') }}</li>
                                            <li v-if="createChannelData.reminderDays.saturday === true">{{ _mft('calendar:weekday.saturday.long') }}</li>
                                            <li v-if="createChannelData.reminderDays.sunday === true">{{ _mft('calendar:weekday.sunday.long') }}</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <p class="mt-8 mb-0 text-xs text-right text-gray-500">
                                {{ _mft('community:newChannel.preview.verifyDetails') }}
                            </p>

                            <div class="flex justify-end space-x-4">
                                <button-secondary
                                    class="mt-3 text-center"
                                    v-on:click="previewingChannel = false"
                                >
                                    {{ _mft('shared:navigation.back') }}
                                </button-secondary>
                                <button-base
                                    :style-type="newChannelValidates ? 'success' : 'disabled'"
                                    :disabled="! newChannelValidates"
                                    class="mt-3 text-center"
                                    v-on:click="createChannel()"
                                >
                                    {{ _mft('community:createChannel') }}
                                </button-base>
                            </div>
                        </div>

                        <div v-else>
                            <input-text
                                :label="_mft('community:newChannel.name.label')"
                                :placeholder="_mft('community:newChannel.name.label')"
                                :autofocus="true"
                                v-model:content="createChannelData.name"
                            />

                            <toggle-switch
                                :label="_mft('community:newChannel.lockedForCommunityPosts.label')"
                                v-model:content="createChannelData.lockedForCommunityPosts"
                                :disabled="createChannelData.trackAccountability ? true : false"
                            />

                            <toggle-switch
                                :label="_mft('community:newChannel.trackAccountability.label')"
                                v-model:content="createChannelData.trackAccountability"
                                :disabled="createChannelData.lockedForCommunityPosts ? true : false"
                            />

                            <div
                                class="px-4 py-4 -mx-4 text-xs bg-gray-100 border-y lg:-mx-6 lg:px-6"
                                v-if="createChannelData.trackAccountability"
                            >
                                <div class="mb-2">
                                    <toggle-switch
                                        :label="_mft('community:newChannel.firstDayilyCheckinQuestion.label')"
                                        v-model:content="createChannelData.dailyCheckInQuestions.question1Enabled"
                                    />
                                    <div
                                        :class="createChannelData.dailyCheckInQuestions.question1Enabled ? 'max-h-24 mb-2' : 'max-h-0'"
                                        class="-mt-2 overflow-hidden transition-all duration-300"
                                    >
                                        <input-text
                                            v-if="createChannelData.dailyCheckInQuestions.question1Enabled"
                                            label=""
                                            placeholder=""
                                            v-model:content="createChannelData.dailyCheckInQuestions.question1"
                                        />
                                    </div>
                                </div>
                                <div class="mb-2">
                                    <toggle-switch
                                        :label="_mft('community:newChannel.secondDayilyCheckinQuestion.label')"
                                        :disabled="! createChannelData.dailyCheckInQuestions.question1Enabled"
                                        v-model:content="createChannelData.dailyCheckInQuestions.question2Enabled"
                                    />
                                    <div
                                        :class="createChannelData.dailyCheckInQuestions.question2Enabled ? 'max-h-24 mb-2' : 'max-h-0'"
                                        class="-mt-2 overflow-hidden transition-all duration-300"
                                    >
                                        <input-text
                                            v-if="createChannelData.dailyCheckInQuestions.question2Enabled"
                                            label=""
                                            placeholder=""
                                            v-model:content="createChannelData.dailyCheckInQuestions.question2"
                                        />
                                    </div>
                                </div>
                                <div class="mb-2">
                                    <toggle-switch
                                        :label="_mft('community:newChannel.thirdDayilyCheckinQuestion.label')"
                                        :disabled="! createChannelData.dailyCheckInQuestions.question2Enabled"
                                        v-model:content="createChannelData.dailyCheckInQuestions.question3Enabled"
                                    />
                                    <div
                                        :class="createChannelData.dailyCheckInQuestions.question3Enabled ? 'max-h-24 mb-2' : 'max-h-0'"
                                        class="-mt-2 overflow-hidden transition-all duration-300"
                                    >
                                        <input-text
                                            v-if="createChannelData.dailyCheckInQuestions.question3Enabled"
                                            label=""
                                            placeholder=""
                                            v-model:content="createChannelData.dailyCheckInQuestions.question3"
                                        />
                                    </div>
                                </div>

                                <div class="mt-6 mb-4">
                                    <input-time
                                        v-model:content="createChannelData.reminderTime"
                                        :label="_mft('community:newChannel.sendReminder.label')"
                                        :required="required"
                                    />
                                </div>
                                <div class="pt-3 pl-2 ml-2 -mt-4 border-l">
                                    <toggle-switch
                                        :label="_mft('calendar:weekday.monday.long')"
                                        class="transform -translate-y-1"
                                        v-model:content="createChannelData.reminderDays.monday"
                                    />
                                    <toggle-switch
                                        :label="_mft('calendar:weekday.tuesday.long')"
                                        class="transform -translate-y-1"
                                        v-model:content="createChannelData.reminderDays.tuesday"
                                    />
                                    <toggle-switch
                                        :label="_mft('calendar:weekday.wednesday.long')"
                                        class="transform -translate-y-1"
                                        v-model:content="createChannelData.reminderDays.wednesday"
                                    />
                                    <toggle-switch
                                        :label="_mft('calendar:weekday.thursday.long')"
                                        class="transform -translate-y-1"
                                        v-model:content="createChannelData.reminderDays.thursday"
                                    />
                                    <toggle-switch
                                        :label="_mft('calendar:weekday.friday.long')"
                                        class="transform -translate-y-1"
                                        v-model:content="createChannelData.reminderDays.friday"
                                    />
                                    <toggle-switch
                                        :label="_mft('calendar:weekday.saturday.long')"
                                        class="transform -translate-y-1"
                                        v-model:content="createChannelData.reminderDays.saturday"
                                    />
                                    <toggle-switch
                                        :label="_mft('calendar:weekday.sunday.long')"
                                        class="transform -translate-y-1"
                                        v-model:content="createChannelData.reminderDays.sunday"
                                    />
                                </div>

                            </div>
                            <div class="flex justify-end">
                                <button-base
                                    :style-type="newChannelValidates ? 'success' : 'disabled'"
                                    :disabled="! newChannelValidates"
                                    class="mt-3 text-center"
                                    v-on:click="previewChannel()"
                                >
                                    {{ _mft('shared:action.continue') }}
                                </button-base>
                            </div>
                        </div>
                    </template>
                </slideout-with-slots>
            </card-tabbed-tab>
            <card-tabbed-tab
                v-if="invoiceLinks.length > 0"
                value="paymentLinks"
                :title="_mft('community:tab.paymentMethods.title')"
            >
                <ul class="divide-y">
                    <li
                        v-for="link in invoiceLinks"
                        :key="link.id"
                        class="flex justify-between py-1"
                    >
                            <span>
                                {{ link.title }}
                            </span>
                        <span>
                                <a
                                    :href="route('invoice-links.edit', link.uuid)"
                                    class="mf-link"
                                    target="_blank"
                                >
                                    {{ _mft('shared:action.edit') }}
                                </a>
                            </span>
                    </li>
                </ul>
            </card-tabbed-tab>
        </card-tabbed>

        <slideout-with-slots
            v-if="creatingInvoiceLink"
            @close="creatingInvoiceLink = false"
            max-width-class="max-w-xl"
        >
            <template #title>
                <h2>{{ _mft('course:createInvoiceLink') }}</h2>
            </template>
            <template #body>
                <invoice-link-form
                    :preset-products="paymentLinkProducts"
                    :preset-title="community.name"
                    :redirect="false"
                    form-style="ui"
                    :show-title="false"
                    :hidden-features="['backlink', 'navigation']"
                    preselected-tab="settings"
                    class="container"
                    @payment-link-created="paymentLinkCreated"
                ></invoice-link-form>
            </template>
        </slideout-with-slots>
    </div>
</template>
