<template>
    <div class="w-full lg:w-5/12 mb-12">
        <iframe loading="lazy" :src="videoProperties.video" class="w-full h-full" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
        <strong class="text-xl">{{videoProperties.name}}</strong> <br>
        <p>{{videoProperties.expertise}}</p>
    </div>
</template>

<script>
export default {
    props: {
        videoProperties: Object,
    },
    data() {
        return {
            videoP: this.videoProperties
        };
    },
};


</script>

<style scoped>

</style>
