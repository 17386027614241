<script setup>
    import { reactive, computed, onMounted } from 'vue'

    const props = defineProps({
        products: {
            type: Array,
            required: true
        },
        perPage: {
            type: Number,
            default: 3
        }
    })

    const state = reactive({
        currentPage: 1,
        perPage: props.perPage
    })

    const nextPage = () => {
        if( state.currentPage * state.perPage >= props.products.length ) {
            return;
        }
        state.currentPage++
    }
    const prevPage = () => {
        if( state.currentPage === 1 ) {
            return;
        }
        state.currentPage--
    }

    const paginatedProducts = computed(() => {
        return props.products.slice((state.currentPage - 1) * state.perPage, state.currentPage * state.perPage)
    })

    const pagecount = computed(() => {
        return Math.ceil(props.products.length / state.perPage)
    })

    const hasPrevPage = computed(() => {
        return state.currentPage > 1
    })

    const hasNextPage = computed(() => {
        return state.currentPage < pagecount.value
    })

    onMounted(() => {
        if ( window.innerWidth < 768 ) {
            state.perPage = 1
        }
    })


</script>
<template>
<div>
    <div class="mb-4 lg:bg-white lg:rounded-xl lg:shadow-md lg:divide-y">
        <div
            v-for="(product, i) in paginatedProducts"
            class="py-4 mb-4 bg-white shadow-md rounded-xl lg:bg-transparent lg:rounded-none lg:shadow-none lg:mb-0"
        >
            <course-listitem
                v-if="product.type === 'Course'"
                :content="product"
                :show-rating="false"
            ></course-listitem>
            <coaching-product-listitem
                v-if="product.type === 'CoachingProduct'"
                :product="product"
                :show-rating="false"
            ></coaching-product-listitem>
        </div>
    </div>
    <div class="flex items-center justify-center pb-6 space-x-4">
        <button
            :disabled="!hasPrevPage"
            :class="!hasPrevPage ? 'opacity-50' : ''"
            @click="prevPage"
        >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
            </svg>

        </button>
        <span class="flex items-center select-none">{{ state.currentPage }} / {{ pagecount }}</span>
        <button
            :disabled="!hasNextPage"
            :class="!hasNextPage ? 'opacity-50' : ''"
            @click="nextPage"
        >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75" />
            </svg>
        </button>
    </div>
</div>
</template>
