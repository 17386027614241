<script setup>
    import { ref, watch, nextTick } from 'vue'

    const emit = defineEmits('update:content')

    const props = defineProps({
        content: {
            type: String,
            required: false,
            default: '',
        },
        label: {
            type: String,
            required: false,
            default: ''
        },
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
        placeholder: {
            type: String,
            required: false,
            default: '',
        },
        name: {
            type: String,
            required: false,
            default: '',
        },
    })

    const isEditable = ref(false)
    const editableinput = ref(null)
    const editableContent = ref(props.content)

    watch(isEditable, (newValue, oldValue) => {
        if (newValue) {
            nextTick(() => {
                editableinput.value.focusInput()
            })
        } else {
            emit('update:content', editableContent.value)
        }
    })

</script>
<template>
    <div class="flex space-x-2 w-full items-center">
        <input-text
            v-if="isEditable"
            class="w-full"
            ref="editableinput"
            v-model:content="editableContent"
            :label="props.label"
            :required="props.required"
            :placeholder="props.placeholder"
            :name="props.name"
        />
        <div
            v-else
            class="w-full overflow-hidden"
        >
            <input-label
                :label="props.label"
                :required="props.required"
            />
            <div class="relative mt-1 rounded-md shadow-sm">
                <p
                    class="block w-full p-2 mt-1 border rounded-md mb-4 cursor-default"
                >
                    {{ editableContent }}&nbsp;
                </p>
            </div>
        </div>
        <button
            @click="isEditable = !isEditable"
            class="px-2 relative z-20"
        >
            <i v-if="!isEditable" class="far fa-pen text-myflowPurple-900"></i>
            <i v-else class="far fa-check text-myflowPurple-900"></i>
        </button>
    </div>
</template>
