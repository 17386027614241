<script setup>
import MediaBankEntry from "./util/media-bank-entry.vue";

const props = defineProps({
    containerRef: {
        type: String,
        required: true
    },
    uuid: {
        type: String,
        require: true
    },
    content: {
        type: Object,
        required: true
    },
    layout: {
        type: String,
        required: false,
        default: 'full'
    }
})

const formatter = new Intl.NumberFormat('sv-SE', {
    maximumFractionDigits: 0
});

</script>
<template>
    <div>
        <div
            class="flex flex-col-reverse items-center w-auto max-w-4xl grid-cols-3 mx-auto"
            :class="layout === 'full' ? 'lg:grid' : ''"
        >
            <div
                class="z-10 flex flex-col w-11/12 col-span-2 p-4
                @if($content->media_bank_entry_id) -mt-6 @endif
                @if($layout == 'full')
                @if($content->media_bank_entry_id) lg:mt-0 lg:-mr-8 @endif lg:w-4/5 @endif
                justify-self-end md:p-6 inverted rounded-custom shadow-custom"
            >
                <p class="relative px-4 text-lg font-bold">
                    {{ content.name }}
                </p>
                <div class="px-4">
                    <div v-html="content.description"></div>
                    <hr class="mt-3 mb-3">
                </div>

                <div class="px-4">
                    <p><b>{{ _mft('pagebuilderView:pricingOffer.offer') }}:</b></p>
                    <p>
                        <span v-if="content.amount !== 1">
                            {{ content.amount }} {{ content.unit }} á
                        </span>
                        {{ formatter.format(content.price) }} kr

                        <span v-if="content.rebate !== 0">
                            ({{ _mft('pagebuilderView:pricingOffer.regularPrice') }}: {{ formatter.format(content.amount * content.price) }} kr)
                        </span>
                    </p>

                    <p>
                        <span v-if="content.rebate != 0">
                            <b class="italic">
                                {{ _mft('pagebuilderView:pricingOffer.discount') }}: {{ content.rebate }}% ({{ formatter.format(content.amount * content.price * (content.rebate / 100)) }} kr)
                            </b><br>
                            <b>
                                {{ _mft('pagebuilderView:pricingOffer.total') }}: {{ formatter.format(content.amount * content.price - (content.amount * content.price * (content.rebate / 100))) }} kr
                            </b>
                        </span>
                        <span v-else>
                            <b>
                                {{ _mft('pagebuilderView:pricingOffer.total') }}: {{ formatter.format(content.amount * content.price) }} kr
                            </b>
                        </span>
                        <span
                            v-if="content.includes_vat"
                            class="ml-1 text-xs text-gray-400"
                        >{{ _mft('pagebuilderView:pricingOffer.inclVat') }}</span>
                        <span
                            v-else
                            class="ml-1 text-xs text-gray-400"
                        >{{ _mft('pagebuilderView:pricingOffer.exclVat') }}</span>
                    </p>

                    <a
                        v-if="content.link_href"
                        :href="content.link_href"
                        class="inline-block mb-3 text-center w-100"
                    >
                        <button-primary>
                            {{ _mft('pagebuilderView:pricingOffer.buyNow') }}
                        </button-primary>
                    </a><br>

                    <small class="italic">{{ _mft('pagebuilderView:pricingOffer.priceValidUntil') }} {{ content.valid_until }}</small>
                </div>
            </div>
            <div
                v-if="content.media_bank_entry_id"
                class="flex justify-center"
            >
                <MediaBankEntry
                    :container-ref="containerRef"
                    :block-uuid="uuid"
                    :block-content="content"
                    :media-bank-entry-id="content.media_bank_entry_id"
                    property-name="media_bank_entry_id"
                ></MediaBankEntry>
            </div>
        </div>
    </div>
</template>
