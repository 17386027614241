<script setup>

import {computed, onMounted, ref} from "vue";
import ToggleSwitch from "../forms/toggleSwitch.vue";
import InputCheckboxes from "../forms/inputCheckboxes.vue";
import FrostedBar from "../layout/pageHeadings/frostedBar.vue";

const props = defineProps({
    offerableId: {
        type: Number,
        required: false,
        default: null,
    }
})

const offerable = ref({
    id: null,
    title: '',
    description: '',
    price: null,
    customer_notes_enabled: false,
    coach_notes_enabled: false,
    default_customer_notes: '',
    default_coach_notes: '',
    active: false,
    minutes_per_session: 0,
    location: '',
    performed_by_coach_user_ids: [],
    invoice_links: [],
})

const coaches = ref([]);
const savingState = ref('');
const creatingInvoiceLink = ref(false);

const loadPrerequisites = async () => {
    const response = await axios.get(route('api.coaching2.offerables.create'));
    coaches.value = response.data.coaches;
    offerable.value.location = response.data.location

    if (coaches.value.length === 2) {
        offerable.value.performed_by_coach_user_ids = [coaches.value[0].id];
    }

    if (props.offerableId) {
        await loadOfferable();
    }
}

const loadOfferable = async () => {
    const response = await axios.get(route('api.coaching2.offerables.edit', props.offerableId));

    offerable.value.id = response.data.offerable.id;
    offerable.value.title = response.data.offerable.title;
    offerable.value.description = response.data.offerable.description;
    offerable.value.price = response.data.offerable.price;
    offerable.value.customer_notes_enabled = response.data.offerable.customer_notes_enabled;
    offerable.value.coach_notes_enabled = response.data.offerable.coach_notes_enabled;
    offerable.value.default_customer_notes = response.data.offerable.default_customer_notes;
    offerable.value.default_coach_notes = response.data.offerable.default_coach_notes;
    offerable.value.active = response.data.offerable.active;
    offerable.value.minutes_per_session = response.data.offerable.minutes_per_session;
    offerable.value.location = response.data.offerable.location;
    offerable.value.performed_by_coach_user_ids = response.data.offerable.performed_by_coach_user_ids;
    offerable.value.invoice_links = response.data.offerable.invoice_links;
}

const storeOrUpdate = async () => {
    if (offerableValidates.value === false) {
        return;
    }

    savingState.value = 'waiting'

    let res = null

    if (offerable.value.customer_notes_enabled === false) {
        offerable.value.default_customer_notes = '';
    }

    if (offerable.value.coach_notes_enabled === false) {
        offerable.value.default_coach_notes = '';
    }

    if (offerable.value.id) {
        res = await axios.put(route('api.coaching2.offerables.update', offerable.value.id), offerable.value);
    } else {
        res = await axios.post(route('api.coaching2.offerables.store'), offerable.value);

        offerable.value.id = res.data.offerable.id
        history.pushState({}, "", route('coaching2.offerables.edit', {id: offerable.value.id}));
    }

    savingState.value = res.status === 200 ? 'success' : 'error'
}

const paymentLinkCreated = () => {
    creatingInvoiceLink.value = false
    loadOfferable()
}

const options = computed(() => {
    return coaches.value.map((coach) => {
        return {
            label: coach.name,
            value: coach.id
        }
    });
});

const offerableValidates = computed(() => {
    return offerable.value.title.length > 0
        && offerable.value.performed_by_coach_user_ids.length > 0
        && parseInt(offerable.value.price, 10) >= 0
})

const paymentLinkProducts = computed(() => {
    return [{
        title: offerable.value.title,
        price: 0,
        quantity: 1,
        description: offerable.value.description,
        linkableId: offerable.value.id,
        linkableType: 'coaching-offerable',
        quantity_is_fixed: false,
    }]
})


onMounted(() => {
    loadPrerequisites();
})

</script>

<template>
    <div>
        <div class="md:sticky top-2 z-topbar">
            <frosted-bar>
                <div class="flex items-center justify-between space-x-4 f">
                    <h1>{{ offerable.title === '' ? _mft('offerable:newOfferable') : offerable.title }}</h1>

                    <div class="flex items-center space-x-4">
                        <story-lane-modal :module="'coaching2-v1'"></story-lane-modal>

                        <button-secondary
                            @click="creatingInvoiceLink = true"
                        >
                            {{ _mft('offerable:createInvoiceLink') }}
                        </button-secondary>

                        <button-base
                            :style-type="offerableValidates ? 'success' : 'disabled'"
                            :disabled="! offerableValidates"
                            @click="storeOrUpdate()"
                            :state="savingState"
                        >
                            Save
                        </button-base>
                    </div>
                </div>
            </frosted-bar>
        </div>

        <card-tabbed class="col-span-2 mb-16 lg:mb-0 mt-8 max-w-2xl mx-auto">
            <card-tabbed-tab
                :title="_mft('offerable:tab.details')"
                value="details"
                :active="true"
            >
                <input-text
                    :label="_mft('offerable:title')"
                    required
                    v-model:content="offerable.title"
                />

                <toggle-switch
                    :label="_mft('offerable:active')"
                    v-model:content="offerable.active"
                />

                <input-textarea
                    :label="_mft('offerable:description')"
                    v-model:content="offerable.description"
                />

                <input-number
                    :label="_mft('offerable:price')"
                    required
                    v-model:content="offerable.price"
                />

                <input-number
                    :label="_mft('offerable:minutesPerSession')"
                    v-model:content="offerable.minutes_per_session"
                />

                <input-text
                    :label="_mft('offerable:location')"
                    v-model:content="offerable.location"
                />

                <input-checkboxes
                    :label="_mft('offerable:performedByCoach')"
                    v-model:content="offerable.performed_by_coach_user_ids"
                    :options="options"
                    :optionLabel="coach => coach.name"
                    :optionValue="coach => coach.id"
                    required
                />
            </card-tabbed-tab>
            <card-tabbed-tab
                :title="_mft('offerable:tab.noteSettings')"
                value="noteSettings"
            >
                <div>
                    <toggle-switch
                        :label="_mft('offerable:customerNotesEnabled')"
                        v-model:content="offerable.customer_notes_enabled"
                    />

                    <toggle-switch
                        :label="_mft('offerable:coachNotesEnabled')"
                        v-model:content="offerable.coach_notes_enabled"
                    />
                </div>


                <input-editor
                    class="mt-4"
                    v-if="offerable.customer_notes_enabled"
                    :label="_mft('offerable:defaultCustomerNotes')"
                    v-model:content="offerable.default_customer_notes"
                />

                <input-editor
                    class="mt-4"
                    v-if="offerable.coach_notes_enabled"
                    :label="_mft('offerable:defaultCoachNotes')"
                    v-model:content="offerable.default_coach_notes"
                />
            </card-tabbed-tab>
            <card-tabbed-tab
                v-if="offerable.invoice_links?.length > 0"
                value="paymentLinks"
                :title="_mft('offerable:tab.paymentMethods.title')"
            >
                <ul class="divide-y">
                    <li
                        v-for="link in offerable.invoice_links"
                        :key="link.id"
                        class="flex justify-between py-1"
                    >
                        <div>
                            <div>{{ link.title }}</div>
                        </div>
                        <span>
                                <a
                                    :href="route('invoice-links.edit', link.uuid)"
                                    class="mf-link"
                                    target="_blank"
                                >
                                    {{ _mft('shared:action.edit') }}
                                </a>
                            </span>
                    </li>
                </ul>
            </card-tabbed-tab>
        </card-tabbed>

        <slideout-with-slots
            v-if="creatingInvoiceLink"
            @close="creatingInvoiceLink = false"
            max-width-class="max-w-xl"
        >
            <template #title>
                <h2>{{ _mft('offerable:createInvoiceLink') }}</h2>
            </template>
            <template #body>
                <invoice-link-form
                    :preset-products="paymentLinkProducts"
                    :preset-title="offerable.title"
                    :redirect="false"
                    form-style="ui"
                    :show-title="false"
                    :hidden-features="['backlink', 'navigation']"
                    preselected-tab="settings"
                    class="container"
                    @payment-link-created="paymentLinkCreated"
                ></invoice-link-form>
            </template>
        </slideout-with-slots>

    </div>
</template>
