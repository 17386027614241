<template>
    <form @submit.prevent="submit">
        <input-text maxlength="255" label="mediaBank:name" v-model:content="title" required />

        <label class="block mb-4">
            <input type="file" accept="*.mp4, *.mkv" @change="onFileChange" required />
        </label>

        <div class="w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700 mb-5">
            <div class="bg-myflowPurple-600 h-1.5 rounded-full" :style="{width: uploadPercentage + '%'}"></div>
        </div>

        <div class="flex justify-end mb-2">
            <button v-if="!submitting" type="submit" class="myflow-basic-button">
                {{ _mft('mediaBank:upload') }}
            </button>
            <mf-spinner-medium v-else class="w-16 h-8"></mf-spinner-medium>
        </div>

        <small>{{ _mft('mediaBank:maxFileSize', {size: '2048mb (2GB)'}) }}</small>
    </form>
</template>

<script>
	import axios from 'axios';

	export default {
		data(){
			return {
                title: null,
				file: '',
                submitting: false,
				uploadPercentage: 0
			}
		},
        props: {
            uploadUrl: { type: String, default: null },
            fileUploadToken: { type: String, default: null },
        },
		methods: {
			onFileChange(event) {
				this.file = event.target.files[0];
			},
			async submit() {
                this.submitting = true;

				let formData = new FormData();
				formData.append('title', this.title);
				formData.append('file_upload_token', this.fileUploadToken);
				formData.append('video_asset', this.file);
                try {
                    await axios.post(this.uploadUrl,
                        formData,
                        {
                            onUploadProgress: (progressEvent) => {
                                this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 80));
                            }
                        }
                    );
                    this.$document.locationReload();
                } catch (e) {
                    console.warn("Failed to submit form", e);
                    this.uploadPercentage = 0;
                    this.submitting = false;
                }
			},
		}
	}
</script>
