<template>
    <div class="min-h-screen pt-12 -mx-4 bg-gray-300 sm:-mx-6">

        <ul class="absolute top-0 left-0 right-0 z-20 flex justify-center w-full mb-4 space-x-6 bg-white shadow-lg">
            <li
                v-on:click="viewmode = 'unread'"
                class="px-4 py-2 mb-2 font-bold border-b-2 cursor-pointer select-none"
                :class="{'border-wlPrimary text-wlPrimary' : viewmode === 'unread' }"
            >
                {{ _mft('notification:unread') }}
            </li>
            <li
                v-on:click="viewmode = 'all'"
                class="px-4 py-2 mb-2 font-bold border-b-2 cursor-pointer select-none"
                :class="{'border-wlPrimary text-wlPrimary' : viewmode === 'all' }"
            >
                {{ _mft('notification:all') }}
            </li>
            <li
                v-on:click="showFilters = !showFilters"
                class="relative px-4 py-2 mb-2 font-bold cursor-pointer select-none"
                :class="[currentFilter !== null ? 'text-wlPrimary' : '']"
            >
                {{ _mft('notification:filter') }}
                <ul
                    class="absolute mb-4 overflow-hidden font-normal transition-all transform bg-white divide-y divide-gray-200 rounded-lg shadow-xl top-full -right-8"
                    :class="[showFilters ? '-translate-y-2 max-h-auto opacity-100' : '-translate-y-12 max-h-0 opacity-0']"
                >
                    <li
                        class="p-2 text-xs cursor-pointer"
                        v-on:click="currentFilter = null"
                        :class="[currentFilter === null ? 'bg-wlPrimary text-wlPrimaryContrast' : '']"
                    >
                        {{ _mft('notification:filter.showAll') }}
                    </li>
                    <li
                        v-for="(type, i) in notifications.types"
                        v-bind:key="i"
                        v-on:click="currentFilter = type"
                        class="p-2 text-xs cursor-pointer"
                        :class="[currentFilter?.id === type.id ? 'bg-wlPrimary text-wlPrimaryContrast' : '']"
                    >
                        {{ type.name }}
                    </li>
                </ul>
            </li>
        </ul>

        <div class="px-4 pb-8 space-y-4">
            <div
                class="block py-3 text-center text-gray-400"
                v-if="localNotifications.length === 0 || !notificationsAvailable"
            >
                <em v-if="loadingDone && currentFilter !== null">{{ _mft('notification:filter.noResults', { filterName: currentFilter.name}) }}</em>
                <em v-else-if="loadingDone && viewmode === 'unread'">{{ _mft('notification:noUreadNotifications') }}</em>
                <em v-else-if="loadingDone && viewmode === 'all'">{{ _mft('notification:noNotifications') }}</em>
                <em v-if="!loadingDone">{{ _mft('notification:loadingNotifications') }}</em>
            </div>
            <list-notification
                v-for="notice in localNotifications"
                v-bind:key="notice.id"
                :notification="notice"
                :mode="viewmode"
                :filter="currentFilter"
                @toggleRead="toggleRead"
                @markReadAndNavigate="markReadAndNavigate"
            />

            <div
                class="p-3 transition-all bg-white rounded-lg shadow-lg"
            >
                <div class="relative flex justify-between mb-2">
                    <span
                        class="w-full pr-4 text-gray-900 cursor-pointer lg:w-auto"
                    >
                        {{ _mft('notification:footer.whatsUpAtMyflow') }}
                    </span>
                </div>
                <div class="flex items-center justify-between">
                    <div class="divide-x divide-gray-400 cursor-default">
                        <small class="font-normal text-gray-600">
                        </small>
                        <small class="px-1 py-px ml-1">{{ _mft('notification:footer.whatsUpTag') }}</small>
                    </div>
                    <a
                        href="https://app.myflow.se/lp/kalendarium"
                        class="text-wlPrimary hover:text-wlPrimary-light whitespace-nowrap"
                        target="_blank"
                    >
                        {{ _mft('notification:footer.cta') }}
                    </a>
                </div>
            </div>

        </div>
    </div>

</template>

<script>
    import axios from "axios";
    export default {
        mounted() {
            this.getNotifications();
        },
        props: {
            maxcount: {
                type: Number,
                default: 20
            }
        },
        data() {
            return {
                viewmode: 'unread',
                showFilters: false,
                currentFilter: null,
                loadingDone: false,
                notifications: {
                    types: [],
                    unreadCount: 0,
                    unread: [],
                    all: []
                },
            };
        },
        methods: {
            getNotifications: async function(){
                let response = await axios.get(route('notifications'), {params: {limit: this.maxcount}});
                this.notifications.types = response.data.types;
                this.notifications.unreadCount = response.data.unread_count;
                this.notifications.unread = response.data.unread;
                this.notifications.all = response.data.all;
                this.loadingDone = true;

                this.$emit('update', this.notifications.unreadCount);
            },
            toggleRead(noteID) {
                axios.post( route('notification.markAsRead', noteID ));
            },
            async markReadAndNavigate(notification) {
                if( !notification.read ){
                    await axios.post( route('notification.markAsRead', notification.id ));
                }
                window.location.href = notification.url
            },
            markAllAsRead(){
                axios.post( route('notification.markAllAsRead' ));
                this.getNotifications();
            }
        },
        computed: {
            localNotifications: function(){
                return this.notifications[this.viewmode];
            },
            filteredNotificationCount: function(){
                return this.notifications[this.viewmode].map( x => (x.category_id === this.currentFilter.id)).filter(value => value).length
            },
            notificationsAvailable: function(){
                if( this.currentFilter !== null ){
                    if( this.filteredNotificationCount === 0 ){
                        return false;
                    }
                }
                return true;
            }
        },
    }
</script>
<style>
.unread {
    color: #4e0075;
    font-weight: 800;
}
</style>
