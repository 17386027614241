<script setup>

import {computed, onMounted, ref, watch} from "vue";
import ButtonBar from "../../../navigation/buttonBar.vue";
import { useNotificationStore } from '@/stores/NotificationStore.js'

const sessions = ref([])
const clients = ref([])
const displayMode = ref('sessions')
const notificationStore = useNotificationStore()

const displayModeOptions = [
    {
        label: _mft('offerable:session.displayMode.sessions'),
        name: 'sessions',
    },
    {
        label: _mft('offerable:session.displayMode.clients'),
        name: 'clients',
    },
]

const loadSessions = async () => {
    const res = await axios.get(route('api.coaching2.coach.sessions.index'))

    if (res.data.success) {
        sessions.value = res.data.sessions
    }
}

const exportSessionLog = async () => {
    const res = await axios.post(route('api.coaching2.exportSessionLog'))

    if (res.data.success) {
        notificationStore.addNotification({
            type: 'success',
            title: 'The export has started!',
            message: 'You will get an email with a link to download when it\'s done.',
            dismissAfter: 5000,
        })

    }
}

const loadClients = async () => {
    const res = await axios.get(route('api.coaching2.coach.clients.index'), {
        params: {
            status: 'active'
        }
    })

    if (res.data.success) {
        clients.value = res.data.clients
    }
}

const bookedSessions = computed(() => {
    return sessions.value.filter(session => session.status === 'booked')
})

const upcomingSessions = computed(() => {
    return sessions.value.filter(session => session.status === 'bookable' || session.status === 'reschedulable')
})

const oldSessions = computed(() => {
    return sessions.value.filter(session => session.status === 'no-show' || session.status === 'completed' || session.status === 'cancelled')
})

watch(() => displayMode.value, (newValue) => {
    if (newValue === 'sessions') {
        loadSessions()
    } else {
        loadClients()
    }
    _mfPrefSet('coaching-bookings-displaymode', newValue)
})

onMounted(() => {
    if (displayMode.value === 'sessions') {
        loadSessions()
    } else {
        loadClients()
    }
    _mfPrefGet('coaching-bookings-displaymode').then(data => {
        displayMode.value = data
    })
})

</script>

<template>
    <frosted-bar>
        <div class="flex items-center justify-between space-x-4 f">
            <h1>Coaching Dashboard</h1>

            <div class="flex items-center space-x-4">
                <story-lane-modal :module="'coaching-v1'"></story-lane-modal>

                <button-base
                    @click="exportSessionLog"
                >
                    Export session log
                </button-base>
            </div>
        </div>
    </frosted-bar>

    <div class="pt-16">
        <button-bar
            :options="displayModeOptions"
            :selectedOptionName="displayMode"
            @select="displayMode = $event.name"
            class="max-w-2xl mx-auto"
        />

        <div v-if="displayMode === 'sessions'">

            <p
                v-if="bookedSessions.length === 0 && upcomingSessions.length === 0 && oldSessions.length === 0"
                class="py-16 text-center text-gray-500"
            >{{ _mft('offerable:sessions.noSessionsToDisplay') }}</p>

            <h3
                v-if="bookedSessions.length > 0"
            >{{ _mft('offerable:sessions.title.booked') }}</h3>
            <list-basic class="mb-8">
                <coaching-v2-client-sessions-list-session
                    v-for="(session, index) in bookedSessions"
                    :key="index"
                    :session="session"
                    @reloadSessions="loadSessions"
                ></coaching-v2-client-sessions-list-session>
            </list-basic>

            <h3
                v-if="upcomingSessions.length > 0"
            >{{ _mft('offerable:sessions.title.bookable') }}</h3>
            <list-basic class="mb-8">
                <coaching-v2-client-sessions-list-session
                    v-for="(session, index) in upcomingSessions"
                    :key="index"
                    :session="session"
                    @reloadSessions="loadSessions"
                ></coaching-v2-client-sessions-list-session>
            </list-basic>

            <h3
                v-if="oldSessions.length > 0"
            >{{ _mft('offerable:sessions.title.completedOrNoShow') }}</h3>
            <list-basic>
                <coaching-v2-client-sessions-list-session
                    v-for="(session, index) in oldSessions"
                    :key="index"
                    :session="session"
                    @reloadSessions="loadSessions"
                ></coaching-v2-client-sessions-list-session>
            </list-basic>
        </div>

        <div
            v-if="displayMode === 'clients'"
        >
            <p
                v-if="clients.length === 0"
                class="py-16 text-center text-gray-500"
            >{{ _mft('offerable:sessions.noClientsToDisplay') }}</p>

            <list-basic>
                <coaching-v2-client-sessions-list-client
                    v-for="(client, index) in clients"
                    :key="index"
                    :client="client"
                    @reloadSessions="loadSessions"
                ></coaching-v2-client-sessions-list-client>
            </list-basic>
        </div>
    </div>
</template>
