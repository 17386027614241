<template>
    <div class="mb-4">
        <input-label
            :label="label"
            :required="true"
        />
        <div v-if="value" class="mt-1">
            <div
                class="flex rounded-full"
                :class="direction === 'horizontal' ? 'flex-row' : 'flex-col'"
            >
                <button
                    v-for="(option, index) in options"
                    :key="index"
                    @click.prevent="selectOption(option)"
                    class="px-4 lg:px-6 cursor-pointer py-1 lg:py-1"
                    :class="[content === option.value ? 'bg-wlPrimary shadow-none text-wlPrimaryContrast' : 'bg-white shadow-md', direction === 'horizontal' ? 'first:rounded-l-lg lg:first:rounded-l-full lg:first:pl-6 last:rounded-r-lg lg:last:rounded-r-full lg:last:pr-6 border-r last:border-r-0' : 'first:rounded-t-lg lg:first:rounded-t-4 last:rounded-b-lg lg:last:rounded-b-4 border-b last:border-b-0']"
                >
                    <div class="relative text-center transition-all">
                        <span class="block text-center text-xs">
                            {{ option.label }}
                        </span>
                    </div>
                </button>
            </div>
        </div>
        <mf-spinner-medium v-else class="w-16 h-8"></mf-spinner-medium>
    </div>
</template>
<script>

export default {
    props: {
        label: {
            type: String,
            default: ''
        },
        options: {
            type: Array,
            default: []
        },
        direction: {
            type: String,
            default: 'horizontal',
            options: ['horizontal', 'vertical']
        },
        content: {}
    },
    emits: [
        'update:content'
    ],
    data() {
        return {
            value: null
        };
    },
    async mounted() {
        this.captureModelValue();
    },
    watch: {
        value: {
            handler() {
                this.$emit('update:content', JSON.parse(JSON.stringify(this.value)));
            },
            deep: true
        }
    },
    methods: {
        selectOption(option) {
            this.value = option.value;
        },
        captureModelValue() {
            let value = this.content ? JSON.parse(JSON.stringify(this.content)) : '';
            if (!value) { value = null }
            this.value = value;
        }
    },
}
</script>
