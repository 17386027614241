<template>
    <card-basic>
        <template #header>
            <h5>{{label}}</h5>
        </template>
        <template #body>
            <form v-if="loaded" @submit.prevent="save">
                <input-editor v-model:content="content"></input-editor>
                <button v-if="!saving" class="myflow-basic-button" type="submit">
                    {{ _mft('shared:action.save') }}
                </button>
                <mf-spinner-medium v-else class="w-8 h-4" style="display: inline-block;" />
            </form>
            <mf-spinner-medium v-else class="w-24 h-12" />
        </template>
    </card-basic>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
    props: {
        label: {
            type: String,
            required: true
        },
        bookingId: {
            type: Number,
            required: true
        },
        noteKey: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loaded: false,
            loading: false,
            saving: false,
            content: null
        };
    },
    async mounted() {
        await this.load();
    },
    methods: {
        async load() {
            if (this.loading) { return; }
            this.loading = true;

            try {
                const resp = await axios.get(route('coaching.booking.note.show', [this.bookingId, this.noteKey]));
                this.content = resp.data.content;
                this.loading = false;
            } catch (e) {
                this.loading = false;
                throw e;
            }

            this.loaded = true;
        },
        async save() {
            if (this.saving) { return; }
            this.saving = true;

            try {
                const resp = await axios.put(
                    route('coaching.booking.note.update', [this.bookingId, this.noteKey]),
                    { content: this.content }
                );
                this.saving = false;
            } catch (e) {
                this.saving = false;
                throw e;
            }
        }
    },
};
</script>
