<template>
  <div>
    <div
      class="bg-white rounded-xl border-2 border-myflowPurple py-2 px-4 mb-2"
    >
      <div class="lg:grid lg:grid-cols-2 lg:gap-2">
        <div class="mb-2 pb-2 lg:pb-0 border-b border-myflowPurple-200 lg:border-b-0 text-xs">
          <p class="text-sm mb-0 font-bold">
            {{ booking.begins_at.date }} {{ booking.begins_at.time }} - {{ booking.ends_at.time }}
          </p>
          <p class="mb-0">{{ booking.product.title }}</p>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-2">
        <button
          class="myflow-basic-button text-xs"
          v-on:click="toggleShowNotes"
        >{{ showNotesLabel }}</button>
      </div>
      <div
        v-if="showNotes"
        class="py-2 text-xs"
        v-html="note"
      ></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    booking: Object,
  },
  data: function(){
    return {
      note: '',
      showNotes: false,
    }
  },
  methods: {
    toggleShowNotes(){
      if( this.showRelated ){
        this.showRelated = false
      }
      this.showNotes = !this.showNotes
    },
  },
  mounted() {
    if( this.booking.note === null ){
      this.note = ''
    } else {
      this.note = this.booking.note
    }
  },
  computed: {
    showNotesLabel(){
      if( this.showNotes ){
        return _mft('coachingBooking:hideNotes')
      } else {
        return _mft('coachingBooking:showNotes')
      }
    }
  }
}
</script>
<style scoped>
</style>
