<template>
    <div class="p-4 mb-5 border-2 rounded-md border-myflowGreen-100">
        <div class="flex">
            <div class="flex-shrink-0">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-myflowGreen-800">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>

            </div>
            <div class="ml-3">
                <h3 class="text-sm font-medium text-myflowGreen-800">
                    <b><slot name="header"></slot></b>
                </h3>
                <div class="mt-2 text-sm text-myflowGreen-700">
                    <slot name="body"></slot>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  computed: {
    hasHeaderSlot () {
      return !!this.$slots.header
    },
    hasBodySlot () {
      return !!this.$slots.body
    },
  },
  setup() {

  },
}
</script>
