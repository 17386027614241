<template>
  <div>
    <div class="mx-auto flex flex-col justify-center max-w-3xl min-h-screen">
      <div class="flex flex-col justify-center items-center">
        <div>
          <slot></slot>
        </div>
        <div class="scoreboard">
          <div class="score">{{ calculatedScore }}</div>
          <div class="score-mirror">{{ calculatedScore }}</div>
        </div>
      </div>
      <div class="flex flex-wrap justify-center items-center">
        <div
          v-for="n of 5"
          :key="n"
          class="w-1/3 h-24 md:h-36 flex justify-center"
        >
          <div class="hole relative">
            <div
              class="target rounded-t-full bg-black h-48 absolute left-1/2 transform -translate-x-1/2 transition-all"
              @click="onClick(n)"
              :class="[index === n ? 'mole-visible' : 'mole-hidden']"
            >
              <div class="relative">
                <div class="moletop"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "App",
    data() {
      return {
        index: 0,
        timer: undefined,
        score: 0,
      };
    },
    methods: {
      generateIndex() {
        this.index = Math.floor(Math.random() * 5);
      },
      startGame() {
        this.timer = setInterval(this.generateIndex, 900);
      },
      endGame() {
        clearInterval(this.timer);
        this.score = 0;
        this.index = 0;
      },
      onClick(n) {
        if (this.index === n) {
          // clearTimeout(this.timer)
          this.index = 0
          this.score++;
          // this.startGame()
        }
      },
    },
    computed: {
      calculatedScore() {
        return String(this.score).padStart(8, '0')
      }
    },
    mounted() {
      this.startGame()
    },
    beforeUnmount() {
      clearInterval(this.timer);
    },
  };
</script>
<style scoped>
.byline {
  color: #622578;
}
.scoreboard {
  font-family: 'Orbitron', sans-serif;
  font-size: 32px;
  min-width: 260px;
  text-align: center;
  margin-top: 2em;
  position: relative;
}
.scoreboard .score-mirror {
  top: 100%;
  transform: scaleY(-1);
  opacity: 0.1;
}
.scoreboard .score-mirror:before {
  position: absolute;
  top: 0%;
  width: 100%;
  height: 40px;
  transform: scaleY(-1);
  content: '';
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(240,240,240,1) 65%);
}
.hole {
  width: 90px;
  height: 90px;
  border: 4px solid #3f3f3f;
  border-radius: 50%;
  transform: scaleY(0.5);
  overflow: hidden;
  background: rgb(217,217,217);
  background: linear-gradient(90deg, rgba(217,217,217,1) 0%, rgba(251,251,251,1) 40%, rgba(251,251,251,1) 60%, rgba(217,217,217,1) 100%);
}
.hole:after {
  width: 90px;
  height: 90px;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  content: '';
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(0,0,0,0.1503362028405112) 100%);
  pointer-events: none;
  z-index: 5;
}

.target {
  width: 90%;
  z-index: 10;
  background: rgb(98,37,120);
  background: linear-gradient(90deg, rgba(140,140,140,1) 0%, rgba(250,250,250,1) 50%, rgba(140,140,140,1) 100%);
}

.mole-hidden {
  top: 120%;
}
.mole-visible {
  top: 0.5rem;
}
.moletop {
  position: absolute;
  border-radius: 100%;
  transform: scaleY(1.4);
  background: #7f7f7f;
  transition: background 0.15s;
  border: 1px solid #3f3f3f;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 50px;
}
.moletop:hover {
    background: #9f9f9f;
}
.container {
  display: inline-block;
}

@media screen and (min-width: 768px) {
  .hole {
    width: 150px;
    height: 150px;
  }

  .hole:after {
    width: 150px;
    height: 150px;
  }

  .moletop {
    top: 30px;
    transform: scaleY(2.4);
  }
}
</style>
