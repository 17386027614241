<template>
  <div>
    <div class="flex space-between w-full">
      <button 
        class="border-myflowPurple border-2 p-2 mx-2 rounded-md w-full" 
        :class="[interval === 'daily' ? 'bg-white text-myflowPurple' : 'bg-myflowPurple text-white']"
        v-on:click="interval = 'daily'"
      >Kopiera till varje dag</button>
      <button 
        class="border-myflowPurple border-2 p-2 mx-2 rounded-md w-full" 
        :class="[interval === 'workdaily' ? 'bg-white text-myflowPurple' : 'bg-myflowPurple text-white']"
        v-on:click="interval = 'workdaily'"
      >Kopiera till varje arbetsdag</button>
      <button 
        class="border-myflowPurple border-2 p-2 mx-2 rounded-md w-full" 
        :class="[interval === 'weekly' ? 'bg-white text-myflowPurple' : 'bg-myflowPurple text-white']"
        v-on:click="interval = 'weekly'"
      >Kopiera till varje vecka</button>
    </div>
    <div class="my-12">
      <p>
        {{ prefix }}
        <span class="relative">
          <button class="absolute top-full transform translate-y-1 -translate-x-1/2 left-1/2 bg-myflowPurple text-white w-8 h-8 inline-flex items-center justify-center rounded-full" v-on:click="quantity--">-</button>
          <input pattern="[0-9]" class="inline-input text-center font-bold text-myflowPurple" v-model="quantity" /> 
          <button class="absolute bottom-full transform -translate-y-1 -translate-x-1/2 left-1/2 bg-myflowPurple text-white w-8 h-8 inline-flex items-center justify-center rounded-full" v-on:click="quantity++">+</button>
        </span>
        {{ suffix }}
      </p>
    </div>
    <button class="myflow-basic-button float-right" v-on:click="clone">Lägg till</button>
  </div>
</template>
<script>
export default {
  props: {
    span: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      interval: 'daily',
      quantity: 1,
      weekdays: [
        'Söndag',
        'Måndag',
        'Tisdag',
        'Onsdag',
        'Torsdag',
        'Fredag',
        'Lördag',
        'Söndag'
      ]
    }
  },
  methods: {
    async clone(){
      let startQuarter
      if( this.span.firstQuarter ) startQuarter = this.span.firstQuarter
      if( this.span.startQuarter ) startQuarter = this.span.startQuarter

      await axios.post(route('calendar.cloneSpan'), {
        interval: this.interval,
        quantity: this.quantity,
        span: {
          date: this.span.date,
          weekday: this.span.weekday,
          startQuarter: startQuarter,
          endQuarter: this.span.endQuarter
        }
      })
      this.$emit('reload')
      this.$emit('close')
    },
  },
  computed: {
    prefix() {
      if( this.interval === 'daily' ) return 'Kopiera tiden till varje dag i'
      if( this.interval === 'workdaily' ) return 'Kopiera tiden till varje arbetsdag i'
      if( this.interval === 'weekly' && this.quantity === 1 ) return 'Kopiera tiden till varje ' + this.weekdays[this.span.weekday]
      if( this.interval === 'weekly' && this.quantity > 1 ) return 'Kopiera tiden till varje ' + this.weekdays[this.span.weekday]
    },
    suffix() {
      if( this.interval === 'daily' && this.quantity === 1 ) return 'dag'
      if( this.interval === 'daily' && this.quantity > 1 ) return 'dagar'
      if( this.interval === 'workdaily' && this.quantity === 1 ) return 'arbetsdag'
      if( this.interval === 'workdaily' && this.quantity > 1 ) return 'arbetsdagar'
      if( this.interval === 'weekly' && this.quantity === 1 ) return 'vecka'
      if( this.interval === 'weekly' && this.quantity > 1 ) return 'veckor'
    }
  }
}
</script>
<style scoped>
  .inline-input {
    width: 3ch;
  }
</style>