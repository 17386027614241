<script setup>
import {computed, onMounted, ref, watch} from 'vue';
    import SubQuestionSingle from "./subQuestionSingle.vue";
    import SubQuestionMulti from "./subQuestionMulti.vue";
    import SubQuestionSingleGrid from "./subQuestionSingleGrid.vue";

    const props = defineProps({
        question: {
            type: Object,
            required: true
        },
        number: {
            type: Number,
            required: true
        },
        currentQuestionNumber: {
            type: Number,
            required: true
        },
        selectedValues: {
            type: Object,
            required: false,
            default: {}
        },
        selectedValue: {
            type: Object,
            required: false,
            default: {}
        }
    });

    const emit = defineEmits(['progress', 'selected'])
    const pressedKey = ref('')
    const currentSubquestionNumber = ref(0)

    const updateSelected = (slug, value) => {
        emit('selected', props.question.slug, slug, value)
        currentSubquestionNumber.value++
        pressedKey.value = null
    }

    const questionContainerClass = computed(() => {
        if (props.number === props.currentQuestionNumber) {
            return 'transitioned-in'
        } else {
            if (props.number > props.currentQuestionNumber) {
                return 'not-transitioned-in'
            } else {
                return 'transitioned-out'
            }
        }
    });

    const isCurrentQuestion = computed(() => {
        return props.number === props.currentQuestionNumber
    })

    onMounted(() => {
        window.addEventListener('keydown', (event) => {
            if (!isCurrentQuestion.value) {
                return
            }

            pressedKey.value = event.key
        })
    })

</script>

<template>
    <div
        class="fixed inset-0 px-4 flex justify-center items-center transform transition-transform duration-700 lg:duration-1000 origin-bottom md:z-10"
        :class="questionContainerClass"
    >
        <div class="transition duration-700 lg:duration-1000 max-h-screen">
            <card-basic class="mt-4 overflow-y-auto max-h-[80vh]">
                <div class="max-w-xl">
                    <div class="flex flex-col items-center justify-center">
                        <h1 class="text-2xl font-bold text-center">{{ props.question.title }}</h1>
                        <p class="text-sm text-center text-gray-600">{{ props.question.description }}</p>

                        <div
                            class="w-full mb-8"
                            :class="props.question.subquestions.length > 1 ? 'lg:grid lg:grid-cols-2 lg:gap-4' : ''"
                        >
                            <div v-for="(subquestion, i) in props.question.subquestions">
                                <h5
                                    v-if="props.question.subquestions.length > 1"
                                    class="mb-1 mt-4 font-bold text-center transition-transform transform"
                                    :class="currentSubquestionNumber === i || props.question.subquestions.length === 1 ? 'scale-110' : 'scale-90'"
                                >
                                    {{ subquestion.label}}
                                </h5>

                                <SubQuestionSingle
                                    v-if="props.question.type === 'vertical-buttons' && props.question.choicecount === 1"
                                    :subquestion="subquestion"
                                    :subquestion-count="props.question.subquestions.length"
                                    :pressed-key="pressedKey"
                                    :isCurrentSubquestion="currentSubquestionNumber === i || props.question.subquestions.length === 1"
                                    :preselected="props.selectedValues[subquestion.slug] || null"
                                    @progress="emit('progress')"
                                    @selected="updateSelected(subquestion.slug, $event)"
                                />
                                <SubQuestionSingleGrid
                                    v-if="props.question.type === 'grid-buttons' && props.question.choicecount === 1"
                                    :subquestion="subquestion"
                                    :subquestion-count="props.question.subquestions.length"
                                    :pressed-key="pressedKey"
                                    :isCurrentSubquestion="currentSubquestionNumber === i || props.question.subquestions.length === 1"
                                    :preselected="props.selectedValues[subquestion.slug] || null"
                                    @progress="emit('progress')"
                                    @selected="updateSelected(subquestion.slug, $event)"
                                />
                                <SubQuestionMulti
                                    v-if="props.question.type === 'vertical-buttons' && props.question.choicecount === null || props.question.type === 'vertical-buttons' && props.question.choicecount > 1"
                                    :subquestion="subquestion"
                                    :subquestion-count="props.question.subquestions.length"
                                    :pressed-key="pressedKey"
                                    :isCurrentSubquestion="currentSubquestionNumber === i || props.question.subquestions.length === 1"
                                    :preselected="props.selectedValues[subquestion.slug] || null"
                                    @progress="emit('progress')"
                                    @selected="updateSelected(subquestion.slug, $event)"
                                />
                            </div>
                        </div>
                    </div>
                    <div
                        class="transition-all"
                        :class="props.number === props.currentQuestionNumber ? 'opacity-100' : 'opacity-0'"
                    >
                        <slot name="actions"></slot>
                    </div>
                </div>
            </card-basic>
        </div>
    </div>
</template>

<style scoped>
    .transitioned-in {

    }
    .not-transitioned-in {
        transform: translateX(200vw) translateY(45vh);
    }
    .not-transitioned-in > div {
        transform: rotateZ(90deg);
    }
    .transitioned-out {
        transform: translateX(-200vw) translateY(-45vh);
    }
    .transitioned-out > div {
        transform: rotateZ(90deg);
    }

    @media screen and (min-width: 1024px) {
        .not-transitioned-in {
            transform: translateX(200vw) translateY(100vh);
        }
        .not-transitioned-in > div {
            transform: rotateZ(120deg);
        }
        .transitioned-out {
            transform: translateX(-200vw) translateY(-150vh);
        }
        .transitioned-out > div {
            transform: rotateZ(120deg);
        }
    }
</style>
