<template>
    <card-basic :class="flashing ? 'flashing' : ''">
        <template #header>
            <h5>{{label}}</h5>
        </template>
        <template #body>
            <div v-if="loaded" v-html="content">
            </div>
            <mf-spinner-medium v-else class="w-24 h-12" />
        </template>
    </card-basic>
</template>

<style lang="scss" scoped>
    .flashing {
		animation: blinkingBackground 2s infinite;
	}
	@keyframes blinkingBackground {
		0%   { background-color: white;   }
	    50%	 { background-color: rgba(145, 102, 160, 0.5); }
        100% { background-color: white;   }
	}
</style>

<script>
export default {
    props: {
        label: {
            type: String,
            required: true
        },
        bookingId: {
            type: Number,
            required: true
        },
        noteKey: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loaded: false,
            loading: false,
            flashing: false,
            content: null,
            echo: EchoFactory(),
        };
    },
    async mounted() {
        await this.load();

        this.echo
            .private(`coaching.booking.${this.bookingId}.note.${this.noteKey}`)
            .listen('CoachingBookingNoteUpdated', (ev) => this.onNoteUpdated());
    },
    beforeUnmount() {
        this.echo.leave(`coaching.booking.${this.bookingId}.note.${this.noteKey}`);
    },
    methods: {
        async load() {
            if (this.loading) { return; }
            this.loading = true;

            try {
                const resp = await axios.get(route('coaching.booking.note.show', [this.bookingId, this.noteKey]));
                if (resp.data.content) {
                    this.content = resp.data.content;
                } else {
                    this.content = '<i>'+ _mft('coaching:notes.noNotesYet') +'</i>'
                }
                this.loading = false;
            } catch (e) {
                this.loading = false;
                console.warn(e);
                throw e;
            }

            this.loaded = true;
        },
        async onNoteUpdated(ev) {
            await this.load();

            this.flashing = true;
            setTimeout(() => {
                this.flashing = false;
            }, 2000);
        },
    },
};
</script>
