<template>
  <card-basic :class="[product.featured ? 'bg-gradient-to-r from-myflowPurple-900 to-myflowPurple-700' : '']">
    <template v-slot:header>
      <img
        v-if="product.featured_image"
        class="w-1/3 mb-4 rounded-full"
        :src="product.featured_image"
        :alt="'Utvald bild för ' + product.title"
      />
      <h2 :class="[product.featured ? 'text-white' : '']">{{ product.title }}</h2>
    </template>
    <template v-slot:body>
      <div :class="[product.featured ? 'text-white' : '']" v-html="product.description"></div>
        <div
          class="grid grid-cols-2 gap-2 pt-2 mt-2 text-xs border-t border-myflowPurple-200"
          :class="[product.featured ? 'text-white' : '']"
        >
          <div class="flex items-center justify-start">
            <span :class="[product.featured ? 'text-myflowPurple-300' : 'text-myflowPurple-100']">
              <svg class="w-4 h-4 mr-1 fill-current" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                width="420.777px" height="420.777px" viewBox="0 0 420.777 420.777" style="enable-background:new 0 0 420.777 420.777;"
                xml:space="preserve">
                <g>
                  <g id="Layer_8_58_">
                    <path d="M210.389,0.001C94.381,0.001,0,94.382,0,210.389c0,116.006,94.381,210.387,210.389,210.387 s210.388-94.381,210.388-210.387C420.777,94.382,326.396,0.001,210.389,0.001z M210.389,386.5 c-97.102,0-176.109-79.012-176.109-176.111c0-97.101,79.007-176.109,176.109-176.109c97.101,0,176.109,79.008,176.109,176.109 C386.498,307.489,307.49,386.5,210.389,386.5z M319.061,156.275c3.516,5.094,2.24,12.072-2.854,15.59l-101.554,70.163 c-1.906,1.312-4.136,1.98-6.372,1.98c-1.781,0-3.57-0.427-5.207-1.28c-3.684-1.947-6-5.762-6-9.926V82.639 c0-6.186,5.021-11.207,11.207-11.207c6.188,0,11.206,5.021,11.206,11.207v128.8l83.979-58.02 C308.568,149.917,315.559,151.195,319.061,156.275z"/>
                  </g>
                </g>
              </svg>
            </span>
            <span v-html="playtime"></span>
          </div>

          <div v-if="product.currency" class="flex items-center justify-end">
            <span class="text-myflowPurple-300">
              <svg class="w-5 h-5 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
            </span>
            {{ price }}
          </div>
      </div>
      <div class="flex flex-row-reverse justify-between mt-2">
        <a
          :href="route('coaching.book', [coach.id, product.id])"
          :class="[product.featured ? 'myflow-basic-button--inverted' : 'myflow-basic-button']"
        >
          Välj
        </a>
      </div>
    </template>
  </card-basic>
</template>
<script>
import Playbutton from '../icons/playbutton.vue'

export default {
  components: {
    Playbutton
  },
  props: {
    product: Object,
    coach: Object,
    playtime: {
      type: String,
      default: ''
    },
    price: {
      type: String,
      default: ''
    }
  },
  data: function(){
    return {
    }
  },
  methods: {
  }
}
</script>
