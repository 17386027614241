<script setup>
import {computed, onMounted, ref} from 'vue'

    const props = defineProps({
        containerRef: {
            type: String,
            required: true
        },
        content: {
            type: Array,
            required: true
        },
        design: {
            type: Object,
            required: true
        },
    })

    const transitionsEnabled = ref(true);

    const setDocumentFont = (headings, body) => {
        WebFont.load({
            google: {
                families: [headings + ':400,600,700', body + ':400,600,700']
            }
        });

        const style = `
            .block-editor-blocks .styleable-block *:not(i[aria-hidden="true"]) {
                font-family: ${body}, Open Sans;
            }

            .block-editor-blocks .styleable-block h1,
            .block-editor-blocks .styleable-block h2,
            .block-editor-blocks .styleable-block h3,
            .block-editor-blocks .styleable-block h4,
            .block-editor-blocks .styleable-block h5,
            .block-editor-blocks .styleable-block h6 {
                font-family: ${headings}, Open Sans;
            }
        `

        document.getElementById('pagebuilder-font').innerHTML = style;
    };

    const enableTransitions = computed(() => {
        return transitionsEnabled.value && props.design.pageSettings?.transitions?.enableTransitions
    });

    onMounted(() => {
        const url = new URL(window.location.href);
        const queryParams = new URLSearchParams(url.search);

        if (queryParams.get('export')) {
            transitionsEnabled.value = false;
        }

        if (props.design.pageSettings?.font?.body?.family) {
            setDocumentFont(props.design.pageSettings.font.headings.family, props.design.pageSettings.font.body.family);
        }
    });
</script>

<template>
    <div
        :class="enableTransitions ? 'entry-transitions-enabled' : ''"
    >
        <div
            v-for="(row, ri) in design.layout.rows"
            :key="ri"
        >
            <page-builder-row
                class="overflow-hidden"
                :class="'mfpbr-index-' + ri"
                :styles="row.styles"
                :container-ref="containerRef"
                :row-uuid="row.uuid"
                :persistent-class="row.persistentClass"
            >
                <div
                    class="grid mfpb-row"
                    :class="['grid-cols-1', row.styles.desktop.layout ? 'md:grid-cols-' + row.styles.desktop.layout?.split('|')[0] : ' md:grid-cols-' + row.columns.length]"
                >
                    <div
                        v-for="(column, ci) in row.columns"
                        :key="ci"
                        class="mfpbc"
                        :class="['mfpbc-' + column.uuid, row.styles.desktop.layout ? 'col-span-' + row.styles.desktop.layout?.split('|')[1].split('+')[ci] : 'col-span-1']"
                    >
                        <div class="mfpb-col-container">
                            <page-builder-column
                                class="rounded-wl overflow-hidden"
                                :styles="column.styles"
                            >
                                <div
                                    v-for="(blockUuid, bi) in column.blocks"
                                    :key="bi"
                                    class="mfpbb"
                                    :class="'mfpbb-' + blockUuid"
                                >
                                    <page-builder-block-view
                                        v-if="content.find(b => b.uuid === blockUuid)"
                                        :container-ref="containerRef"
                                        :block="content.find(b => b.uuid === blockUuid)"
                                    />
                                </div>
                            </page-builder-column>
                        </div>
                    </div>
                </div>
            </page-builder-row>
        </div>
        <Teleport to="#page-builder-custom-css">
            {{ design.layout.customcss }}
        </Teleport>
    </div>
</template>

<style>

@media (prefers-reduced-motion: no-preference) {
    .entry-transitions-enabled .row-transition {
        transition: transform 0.8s ease-out, opacity 0.6s 0.1s ease-out;
    }

    .entry-transitions-enabled .row-is-not-transitioned {
        transform: translateY(8rem);
        opacity: 0;
    }
}

</style>
