<template>
    <div class="overflow-hidden bg-myflowPurple-dark">
        <fixed-header/>
        <div
        class="relative z-10 flex flex-col items-center justify-start pb-16 overflow-hidden bg-gradient-to-r from-myflowPurple-dark lg:pb-0"
        >
        <video
        class="absolute z-0 object-cover w-full h-full opacity-40"
        autoplay=""
        muted=""
        playsinline=""
        loop=""
        src="https://helasverigesamlas.se/wp-content/uploads/2022/02/1053758117-hd-copy.mov"
        ></video>
                <div
                    style="max-width:1300px;"
                    class="z-30 w-8/12 mt-12 mb-8 text-center text-myflowYellow-700 lg:mt-32"
                >
                    <!-- <vue-countdown
                        :time="time"
                        v-slot="{ days, hours, minutes, seconds }"
                    >
                        <h2 class="tracking-wide">
                            <br class="block md:block lg:hidden" />
                            {{ days }} DAGAR,<br
                                class="block md:hidden lg:hidden"
                            />
                            {{ hours }} TIMMAR,<br
                                class="block md:hidden lg:hidden"
                            />
                            {{ minutes }} MINUTER,<br
                                class="block md:hidden lg:hidden"
                            />
                            {{ seconds }} SEKUNDER.
                        </h2>
                    </vue-countdown> -->
                    <h3 class="tracking-wide">
                        EVENTET HAR REDAN VARIT. PROGRAM FÖR KOMMANDE EVENT KOMMER SNART
                    </h3>
                </div>
            <div
                style="max-width: 1300px"
                class="z-20 flex flex-col justify-center p-10 text-center border-8 border-white align-center lg:p-16 lg:w-10/12 md:10/12 w-12/12 md:w-auto"
            >
                <h1 class="text-4xl text-white lg:text-7xl md:text-7xl">
                    THE POWER<br />
                    OF LEARNING
                </h1>
                <p class="mt-4 mb-4 text-3xl italic font-light tracking-wide text-white">
                    För människor som tror på människor
                </p>
                <h2 class="tracking-widest text-myflowYellow-700">
                    11 NOVEMBER - LIVE & DIGITALT
                </h2>
            </div>
            <h2 class="z-30 my-8 text-center text-white">Digitalt, kl 09.00 - 12.00. På plats i Stockholm, 08.30 - 17.00</h2>
            <!-- <form class="z-30">
                    <div @click="registerScroll()" id="registerBtn" class="z-40 mt-8 text-white bg-myflowYellow-700 collapse-btn mb-14 bounce">
                        TILL ANMÄLAN
                    </div>
            </form> -->
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        registerScroll() {
        const registerDiv = document.getElementById("register");
        if(registerDiv != null) {
            registerDiv.scrollIntoView({behavior: 'smooth'});
        }
    }
    } ,
    data() {
        const now = new Date();
        const eventDate = new Date('November 11, 2022 09:00:00');
        return {
            time: eventDate - now,
        };
    },
};
</script>

<style scoped>
.collapse-btn {
    padding: 0.8rem 1.5rem;
    border-radius: 25px;
    height: fit-content;
    width: fit-content;
    font-weight: bold;
    cursor: pointer;
}
@-webkit-keyframes bounce {
    0% {
        transform: translate(0px, 0px);
    }

    30% {
        transform: translate(0px, 10px);
    }

    100% {
        transform: translate(0px, 0px);
    }
}

.bounce {
    -webkit-animation: bounce 1.75s infinite;
}
</style>

<style>
</style>