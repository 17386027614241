<template>
    <div class="bg-myflowPurple-700 text-white flex justify-center items-center p-10 py-16">
        <div
        style="max-width: 1300px"
        class="pb-4 flex justify-start lg:justify-center md:justify-center w-full lg:p-16 flex-col bg-myflowPurple-dark rounded-2xl border border-white text-white drop-shadow-lg"
        >
        <div class="w-full">
        <div class="flex justify-center items-center w-full gap-8 py-8 lg:p-0">
            <div @click="showOnsite()" class="bg-myflowYellow-700 text-white" 
            :class="{
                    'active-btn': online,
                    'unActive-btn':onsite
                    }">
                PÅ PLATS
            </div>
            <div @click="showOnline()" class="bg-myflowYellow-700 text-white" 
            :class="{
                    'active-btn': onsite,
                    'unActive-btn' : online
                    }"  >
                ONLINE
            </div>
        </div>
    </div>
    <h2 v-if="onsite" class="text-3xl p-4 lg:p-0 mb-4">Program (på plats)</h2>
    <h2 v-else  class="text-3xl p-4 lg:p-0 mb-4">Program (online)</h2>
        <table class="lg:table-auto text-center w-full text-md md:text-xl lg:text-xl leading-5 lg:leading-10">
        <thead>
            <tr class="border-b-2 border-white text-white">
                <th>Tid</th>
                <th>Event</th>
                <th></th>
            </tr>
        </thead>
        
        <tbody>
            <schema-content-component
            :class="{
                        'show': onsite,
                        'hide':online
                        }"
            v-for="schemaProperties in schemaOnSiteArray"
            v-bind:key="schemaProperties.event"
            :schemaProperties="schemaProperties"
            />
            
            <schema-content-component 
                    :class="{
                        'show': online,
                        'hide' : onsite
                        }"  
                    v-for="schemaProperties in schemaOnlineArray"
                    v-bind:key="schemaProperties.event" 
                    :schemaProperties="schemaProperties"
            />
        </tbody>
    </table>
    </div>
    </div>
    <div id="register" class="h-32 bg-myflowPurple-700"></div>
</template>

<script>
export default {
    methods: {
            showOnline: function() {
                  this.online = true;
                  this.onsite = false;
            },
            showOnsite: function() {
                    this.online = false;
                    this.onsite = true;
            }
      },
    data() {
        return {
            online: false,
            onsite: true,
            schemaOnSiteArray: [
                {
                    time: "8:30-9:00",
                    img: "",
                    img2: "",
                    event: "Nedräkning!",
                    display: "hidden",
                    display2: "hidden",

                },
                {
                    time: "9:00-9:10",
                    img: "https://myflow.fra1.digitaloceanspaces.com/tpol/compressed/Oscar_Valentin.webp",
                    img2: "https://myflow.fra1.digitaloceanspaces.com/tpol/compressed/Anna_Forsberg.webp",
                    event: "TEAMMYFLOW",
                    display: "block",
                    display2: "md:block",

                },
                {
                    time: "9:10-9:35",
                    img: "https://myflow.fra1.digitaloceanspaces.com/tpol/compressed/Mikael_Ahlstrom.webp",
                    img2: "",
                    event: "MIKAEL AHLSTRÖM",
                    display: "block",
                    display2: "hidden",

                },
                {
                    time: "9:40-10:05",
                    img: "https://myflow.fra1.digitaloceanspaces.com/tpol/compressed/Anna_Carlsson.webp",
                    img2: "",
                    event: "ANNA CARLSSON",
                    display: "block",
                    display2: "hidden",

                },
                {
                    time: "10:10-10:30",
                    img: "",
                    img2: "",
                    event: "PAUS",
                    display: "hidden",
                    display2: "hidden",

                },
                {
                    time: "10:30-10:55",
                    img: "https://myflow.fra1.digitaloceanspaces.com/tpol/compressed/Matti_Olofsson1.webp",
                    img2: "",
                    event: "MATTI OLOFSSON",
                    display: "block",
                    display2: "hidden",

                },
                {
                    time: "11:00-11:25",
                    img: "https://myflow.fra1.digitaloceanspaces.com/tpol/compressed/Anna_Tebelius_Bodin_1.webp",
                    img2: "",
                    event: "ANNA TEBELIUS BODIN",
                    display: "block",
                    display2: "hidden",

                },
                {
                    time: "11:30-12:00",
                    img: "https://myflow.fra1.digitaloceanspaces.com/tpol/compressed/Oscar_Valentin.webp",
                    img2: "https://myflow.fra1.digitaloceanspaces.com/tpol/compressed/Anna_Forsberg.webp",
                    event: "LÄRANDEINDEX",
                    display: "block",
                    display2: "md:block",

                },
                {
                    time: "12:00-13:00",
                    img: "",
                    img2: "",
                    event: "LUNCH",
                    display: "hidden",
                    display2: "hidden",

                },
                {
                    time: "13:00-14:15",
                    img: "https://myflow.fra1.digitaloceanspaces.com/tpol/compressed/Oscar_Valentin.webp",
                    img2: "https://myflow.fra1.digitaloceanspaces.com/tpol/compressed/Anna_Forsberg.webp",
                    event: "MASTERMIND-WORKSHOP",
                    display: "block",
                    display2: "md:block",

                },
                {
                    time: "14:15-14:45",
                    img: "",
                    img2: "",
                    event: "PAUS",
                    display: "hidden",
                    display2: "hidden",

                },
                {
                    time: "14:45-16:00",
                    img: "https://myflow.fra1.digitaloceanspaces.com/tpol/compressed/Oscar_Valentin.webp",
                    img2: "https://myflow.fra1.digitaloceanspaces.com/tpol/compressed/Anna_Forsberg.webp",
                    event: "MASTERMIND-WORKSHOP",
                    display: "block",
                    display2: "md:block",

                },
                {
                    time: "16:00-17:00",
                    img: "",
                    img2: "",
                    event: "AFTERWORK!",
                    display: "hidden",
                    display2: "hidden",

                },
            ],
            schemaOnlineArray: [

                {
                    time: "8:50-9:00",
                    img: "",
                    img2: "",
                    event: "Nedräkning!",
                    display: "hidden",
                    display2: "hidden",

                },
                {
                    time: "9:00-9:10",
                    img: "https://myflow.fra1.digitaloceanspaces.com/tpol/compressed/Oscar_Valentin.webp",
                    img2: "https://myflow.fra1.digitaloceanspaces.com/tpol/compressed/Anna_Forsberg.webp",
                    event: "TEAMMYFLOW",
                    display: "block",
                    display2: "md:block",

                },
                {
                    time: "9:10-9:35",
                    img: "https://myflow.fra1.digitaloceanspaces.com/tpol/compressed/Mikael_Ahlstrom.webp",
                    img2: "",
                    event: "MIKAEL AHLSTRÖM",
                    display: "block",
                    display2: "hidden",

                },
                {
                    time: "9:40-10:05",
                    img: "https://myflow.fra1.digitaloceanspaces.com/tpol/compressed/Anna_Carlsson.webp",
                    img2: "",
                    event: "ANNA CARLSSON",
                    display: "block",
                    display2: "hidden",

                },
                {
                    time: "10:10-10:30",
                    img: "",
                    img2: "",
                    event: "PAUS",
                    display: "hidden",
                    display2: "hidden",

                },
                {
                    time: "10:30-10:55",
                    img: "https://myflow.fra1.digitaloceanspaces.com/tpol/compressed/Matti_Olofsson1.webp",
                    img2: "",
                    event: "MATTI OLOFSSON",
                    display: "block",
                    display2: "hidden",

                },
                {
                    time: "11:00-11:25",
                    img: "https://myflow.fra1.digitaloceanspaces.com/tpol/compressed/Anna_Tebelius_Bodin_1.webp",
                    img2: "",
                    event: "ANNA TEBELIUS BODIN",
                    display: "block",
                    display2: "hidden",

                },
                {
                    time: "11:30-12:00",
                    img: "https://myflow.fra1.digitaloceanspaces.com/tpol/compressed/Oscar_Valentin.webp",
                    img2: "https://myflow.fra1.digitaloceanspaces.com/tpol/compressed/Anna_Forsberg.webp",
                    event: "LÄRANDEINDEX",
                    display: "block",
                    display2: "md:block",

                },
            ]
        };
    },
};


</script>

<style scoped>
.active-btn {
    padding: 0.8rem 1.5rem;
    border-radius: 25px;
    height: fit-content;
    width: fit-content;
    font-weight: bold;
    cursor: pointer;
    pointer-events: all;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
}
.unActive-btn {
    padding: 0.8rem 1.5rem;
    border-radius: 25px;
    height: fit-content;
    width: fit-content;
    font-weight: bold;
    cursor: pointer;
    opacity: 0.5;
    pointer-events: none;
}
.hide{
    display: none;
}

.show{
    display:table-row;
}
</style>
