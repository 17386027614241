<script setup>
import OverflowNavigationItem from "./overflowNavigationItem.vue";
import {onMounted, ref} from "vue";

const transitionedIn = ref(false);

const props = defineProps({
    navigationItems: {
        type: Array,
        required: true
    },
});

onMounted(() => {
    setTimeout(function(){
        transitionedIn.value = true
    }, 50)
})

</script>

<template>
    <ul
        class="bg-white/75 backdrop-blur-lg shadow-md rounded-wl transition-all py-2 pl-6 w-auto"
        :class="transitionedIn ? 'opacity-100 translate-y-4' : 'opacity-0 translate-y-0'"
    >
        <li
            v-for="(navigationItem, i) in props.navigationItems"
            :key="i"
            class="relative"
        >
            <overflow-navigation-item
                :item="navigationItem"
                class="text-right"
            >
                <a
                    :href="navigationItem.href"
                    class="whitespace-nowrap font-semibold text-wlNavigation hover:underline focus:underline focus:outline-none px-0 pt-2 pb-1 text-xs"
                >{{ navigationItem.name }}</a>
            </overflow-navigation-item>
        </li>
    </ul>
</template>

<style scoped>

</style>
