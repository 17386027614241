<script setup>
    import * as CronofyElements from "cronofy-elements";
    import { onMounted, ref, watch } from 'vue'

    const props = defineProps({
        cronofyEmbedToken: null,
        timezone: null,
    });

    onMounted(() => {
        initiateAvailabilityRules();
    });

    const initiateAvailabilityRules = () => {
        CronofyElements.AvailabilityRules({
            element_token: props.cronofyEmbedToken,
            target_id: "cronofy-availability-rules",
            availability_rule_id: "myflow",
            data_center: "de",
            config: {
                start_time: "00:00",
                end_time: "24:00",
                duration: 15,
                week_start_day: 'monday'
            },
            styles: {
                colors: {
                    available: "#b092bb",
                    unavailable: "#fefefe"
                },
                prefix: "myflow-cronofy-availability-rules"
            },
            tzid: props.timezone,
        });
    }
</script>
<template>
    <h3>{{ _mft('cronofy:manageAvailability') }}</h3>
    <div class="cronofy-myflow-container" id="cronofy-availability-rules"></div>
</template>
<style>
    @media screen and (max-width: 1024px) {
        .cronofy-myflow-container {
            overflow-x: scroll;
            max-width: 80vw;
        }
    }
</style>
