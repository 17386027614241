<script setup>
</script>

<template>
<div class="flex h-screen flex-col items-center justify-center">
    <div class="text-center">
        <mf-spinner-medium class="mb-4 h-12 w-24"></mf-spinner-medium>
        <h1>{{ _mft('leadHunterCampaign:working.title') }}</h1>
        <p>{{ _mft('leadHunterCampaign:working.text') }}</p>
        <p class="text-xs italic">{{ _mft('leadHunterCampaign:working.byline') }}</p>
    </div>
</div>
</template>

<style scoped>

</style>
