<template>
  <button-base
    :styleType="typeClass"
    :type="type"
  >
    {{ label }}
  </button-base>
</template>
<script>
export default {
  props: {
    label: String,
    type: String,
    styleType: {
      type: String,
      required: false,
      default: null
    },
    busy: Boolean
  },
  data: function() {
    return {
      typeClass: 'bg-black'
    }
  },
  methods: {
    setTypeClass: function(){
      if( this.busy ){
        this.typeClass = 'disabled'
        return
      }
      if (this.styleType) {
          this.typeClass = this.styleType
          return
      }
      switch (this.type) {
        case 'primary':
          this.typeClass = 'primary'
          break;

        case 'secondary':
          this.typeClass = 'secondary'
          break;

        case 'disabled':
          this.typeClass = 'disabled'
          break;

        case 'submit':
          this.typeClass = 'success'
          break;

        case 'cancel':
          this.typeClass = 'secondary'
          break;

        case 'success':
          this.typeClass = 'success'
          break;

        default:
          this.typeClass = 'default'
          break;
      }
    }
  },
  mounted: function(){
    this.setTypeClass();
  },
  watch: {
    type: function(){
      this.setTypeClass();
    },
    busy: function(){
      this.setTypeClass();
    }
  }
}
</script>
