<template>
  <div class="mb-4">
    <input-label
      :label="label"
      :required="required"
    />
    <block-editor
        :modelValue="content"
        @update:modelValue="captureValue($event)"
        :block-types="blockTypes"
        :block-groups="blockGroups"
        :included-block-types="includedBlockTypes"
        :excluded-block-types="excludedBlockTypes"
        :included-block-groups="includedBlockGroups"
        :excluded-block-groups="excludedBlockGroups"
        :showVisibilityTools="showVisibilityTools"
    ></block-editor>
    <input-error v-if="error" :error="error" />

    <input v-if="name" type="hidden" :name="name" :value="JSON.stringify(value)" />
  </div>
</template>
<script>
export default {
    data: function(){
        return {
            value: this.content,
            inputClass: ''
        }
    },
    props: {
        required: Boolean,
        content: Array,
        error: { type: String, default:''},
        label: String,
        name: String,
        blockTypes: { Array, default: null},
        blockGroups: { Array, default: null},
        includedBlockTypes: { type: Array, default: [] },
        excludedBlockTypes: { type: Array, default: [] },
        includedBlockGroups: { type: Array, default: [] },
        excludedBlockGroups: { type: Array, default: [] },
        showVisibilityTools: { type: Boolean, default: true },
        fonts: Array
    },
    methods: {
        captureValue(newValue) {
            this.value = newValue;
            this.$emit('update:content', this.value);
        }
    },
    watch: {
        error: function(error){
            let classes = [];
            if( error !== '' ){
                classes.push('border-red-700')
            } else {
                classes.push('border-gray')
            }
            this.inputClass = classes.join(' ')
        }
    }
}
</script>
