<script setup>
/**
 *
 * This component takes a composite domainkey in the form of page:save or expertProfile:review.show
 * It also accepts IntlMessageFormat arguments in the args prop
 *
 * Basic usage:
 * <mf-translate domainkey="auth:credentials.password"></mf-translate>
 *
 * Args usage:
 * <mf-translate domainkey="coaching:sessionsBooked" :args="{count: 8}"></mf-translate>
 *
 */

const props = defineProps({
    domainkey: {
        type: String,
        required: true,
    },
    args: {
        type: Object,
        required: false,
        default: null
    }
})
</script>
<template>
    {{ _mft(props.domainkey, args).includes('.undefined') ? props.domainkey : _mft(props.domainkey, args) }}
</template>
