<script setup>
    import { onMounted, ref } from 'vue'
    import { useNotificationStore } from '@/stores/NotificationStore.js'

    const notificationStore = useNotificationStore()
    const props = defineProps({
        currentUserId: {
            type: Number,
            required: true
        },
        messageThreadId: {
            type: Number,
            required: true
        }
    })

    const threadMetadata = ref(null)
    const loading = ref(true)

    const loadThread = async () => {
        const res = await axios.get(route('support.chat.message.thread.metadata', props.messageThreadId))
        threadMetadata.value = res.data.thread
        loading.value = false
    }

    const createTicket = async () => {
        const res = await axios.post(route('support.chat.message.thread.create-ticket', props.messageThreadId))

        notificationStore.addNotification({
            type: 'success',
            title: 'Ärende skapat!',
            message: 'Ett supportärende har skapats för denna chatt',
            dismissAfter: 5000,
        })

        loadThread();
    }

    onMounted(() => {
        loadThread();
    });
</script>
<template>
    <div class="max-w-5xl mx-auto grid grid-cols-3 gap-8">
        <support-chat
            class="col-span-2"
            :messageThreadId="props.messageThreadId"
            :currentUserId="props.currentUserId"
            messageListClass="h-[70vh]"
        />
        <div>
            <div v-if="loading" class="text-center">
                <mf-spinner-medium
                    class="w-24 h-12"
                />
            </div>
            <div v-else>
                <card-basic>
                    <template v-slot:body>
                        <div>
                            <info-field
                                class="mb-2"
                                label="Chatt påbörjad"
                                :value="threadMetadata.created_at.display.full"
                            />
                            <info-field
                                class="mb-2"
                                label="Användare"
                                :value="threadMetadata.asking_user.name"
                            />
                            <info-field
                                class="mb-2"
                                label="Supportande företag"
                                :value="threadMetadata.supporting_company.name"
                            />
                        </div>

                        <div v-if="threadMetadata.tickets.length > 0">
                            <h4>Supportärende</h4>
                            <ul class="text-xs">
                                <li
                                    v-for="ticket in threadMetadata.tickets"
                                    :key="ticket.id"
                                    class="mb-4"
                                >
                                    <info-field
                                        class="mb-2"
                                        label="Ärendenummer"
                                        :value="ticket.id"
                                    />
                                    <info-field
                                        class="mb-2"
                                        label="Skapat"
                                        :value="ticket.created_at.display.full"
                                    />
                                    <info-field
                                        class="mb-2"
                                        label="Status"
                                        :value="ticket.ticket_status_display"
                                    />
                                </li>
                            </ul>
                        </div>

                        <div
                            v-else
                            class="flex justify-end mt-8"
                        >
                            <button
                                class="myflow-basic-button"
                                @click="createTicket"
                            >
                                Skapa supportärende
                            </button>
                        </div>
                    </template>
                </card-basic>
            </div>
        </div>
    </div>
</template>
