<script setup>

import {onMounted, ref} from "vue";

const offerables = ref([]);

const loadOfferables = async () => {
    const response = await axios.get(route('api.coaching2.offerables.index'));

    offerables.value = response.data.offerables;
}

onMounted(() => {
    loadOfferables();
})

</script>

<template>
    <div>
        <frosted-bar>
            <div class="flex items-center justify-between space-x-4">
                <h1>{{ _mft('offerable:session.types.domainNamePlural') }}</h1>

                <div class="flex items-center space-x-4">
                    <story-lane-modal :module="'offerables-v1'"></story-lane-modal>

                    <a :href="route('coaching2.offerables.create')">
                        <button-primary>
                            <mf-translate domainkey="offerable:newOfferable"></mf-translate>
                        </button-primary>
                    </a>
                </div>
            </div>
        </frosted-bar>

        <div class="mt-8">
            <p
                v-if="offerables.length === 0"
                class="text-gray-500 text-center py-16"
            >{{ _mft('offerable:noOfferablesToDisplay') }}</p>

            <list-basic>
                <list-basic-item
                    v-for="(offerable, i) in offerables"
                    :key="i"
                >
                    <template #title>
                        {{ offerable.title }}
                    </template>
                    <template #default>
                    </template>
                    <template #actions>
                        <a :href="route('coaching2.offerables.edit', {id: offerable.id})">
                            <button-secondary
                                size="sm"
                            >
                                <mf-translate domainkey="shared:edit"></mf-translate>
                            </button-secondary>
                        </a>
                    </template>
                </list-basic-item>
            </list-basic>
        </div>
    </div>
</template>
