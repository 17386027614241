<script setup>
    import { onMounted, reactive } from 'vue';
    import Playbutton from '../icons/playbutton.vue';

    const props = defineProps({
        userId: {
            type: Number,
            required: false,
            default: null,
        },
        profileImageUrl: {
            type: String,
            required: true,
        },
        trailerVideoUrl: {
            type: String,
            required: false,
            default: null
        },
    });

    const state = reactive({
        trailerVideoUrl: null,
        profileImageUrl: null,
    });

    const closeTrailer = () => {
        state.trailerVideoUrl = null
    }

    const openTrailer = () => {
        state.trailerVideoUrl = props.trailerVideoUrl;
    }

    const loadProfileImage = async (userId) => {
        const res = await axios.get(route('user.getProfileImage', userId))
        state.profileImageUrl = res.data
    }

    onMounted(() => {
        if (!props.profileImageUrl && props.userId) {
            loadProfileImage(props.userId)
        } else {
            state.profileImageUrl = props.profileImageUrl;
        }
    })

</script>
<template>
    <div>
        <div
            class="relative"
        >
            <img
                :src="state.profileImageUrl"
                class="mx-auto rounded-full shadow-xl"
            />
            <div
                class="absolute inset-0 flex items-center justify-center"
                v-if="props.trailerVideoUrl"
            >
                <Playbutton
                    class="w-1/3 h-auto text-white transition-all opacity-50 cursor-pointer hover:scale-105 hover:opacity-90"
                    v-if="!state.trailerVideoUrl"
                    @click="openTrailer"
                />
            </div>
        </div>
        <div
            class="fixed inset-0 z-50 flex items-center justify-center"
            v-if="state.trailerVideoUrl"
        >
            <trailer-video
                :trailerVideoUrl="props.trailerVideoUrl"
                @close="closeTrailer()"
            />
        </div>
    </div>
</template>
