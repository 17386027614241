<template>
  <div>
    <form ref="form">
        <div class="grid-cols-4 gap-8 md:grid">
            <div class="col-span-2">
                <card-basic>
                    <template v-slot:body>
                        <div class="flex items-center justify-between w-full">
                            <span class="text-lg font-medium text-gray-900">Gör tillgänglig för team:</span>
                            <button-primary
                                size="sm"
                                @click.prevent="submit"
                            > {{submitButtonTitle}} </button-primary>
                        </div>
                        <div class="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200">
                            <div
                                class="relative flex items-start py-4"
                                v-for="team in teamList"
                                v-bind:key="team.id"
                            >
                                <div class="flex-1 min-w-0 text-sm">
                                    <label class="font-medium text-gray-700 select-none">{{ team.name }}</label>
                                </div>
                                <div class="flex items-center h-5 ml-3">
                                    <input
                                        name="playlistTeams[]"
                                        type="checkbox"
                                        :value="team.id"
                                        v-model="playlistTeams"
                                        class="w-4 h-4 border-gray-300 rounded focus:ring-myflowPurple-500 text-myflowPurple-600">
                                </div>
                            </div>
                        </div>
                    </template>
                </card-basic>
            </div>
            <div class="col-span-2">
                <card-basic>
                    <template v-slot:body>
                        <div class="flex items-center justify-between w-full">
                            <span  class="mb-3 text-lg font-medium text-gray-900">Information om spellistan:</span>
                            <button-primary
                                size="sm"
                                @click.prevent="submit"
                            > {{submitButtonTitle}} </button-primary>
                        </div>
                        <hr class="mt-3 mb-3">
                        <input-text
                            label="Titel"
                            required="required"
                            :content="playlistTitle"
                            name="playlistTitle"
                            v-model:content="playlistTitle"
                        />

                        <input-editor
                            label="Beskrivning"
                            required="required"
                            :content="playlistDescription"
                            name="playlistDescription"
                            v-model:content="playlistDescription"
                        />

                        <toggle-switch
                            label="Skicka notis till team"
                            v-model:content="generateNotifications"
                        ></toggle-switch>
                        <p class="text-myflowRed" v-if="playlistDescriptionError">Fyll i en beskrivning</p>
                    </template>
                </card-basic>
            </div>
            <div class="col-span-2">
                <card-basic class="max-h-screen overflow-y-scroll">
                    <template v-slot:body>
                        <div class="text-lg font-medium text-gray-900">Tillgängliga föreläsningar & kapitel</div>
                        <hr class="mt-4 mb-4">
                        <div>
                            <select
                                class="block w-full py-2 pl-3 pr-10 mt-1 mb-2 text-base rounded-md border-gray focus:outline-none focus:ring-myflowPurple-500 focus:border-myflowPurple-500 sm:text-sm"
                                v-model="categoryFilter"
                            >
                                <option selected
                                    :value="0"
                                >Visa alla kategorier</option>
                                <option
                                    v-for="category in categoryList"
                                    v-bind:key="category.id"
                                    :value="category.id"
                                >Visa kapitel inom {{ category.name }}</option>
                            </select>

                            <input
                                class="block w-full p-2 pr-10 mt-1 mb-2 border rounded-md"
                                type="text"
                                placeholder="Sök.."
                                v-model="searchFilter"
                            >
                        </div>
                        <draggable
                            role="list"
                            class="divide-y divide-gray-200"
                            :component-data="{
                                tag: 'ul',
                                type: 'transition-group',
                            }"
                            v-model="chapterList"
                            v-bind="dragOptions"
                            @start="drag = true"
                            @end="drag = false"
                            item-key="id"
                        >
                            <template #item="{ element }">
                                <li v-show="matchesFilters(element)" >
                                    <a
                                        href="#"
                                        class="block hover:bg-gray-50 list-group-item"
                                        aria-hidden="true"
                                    >
                                    <div class="grid-cols-9 gap-8 p-2 md:grid">
                                        <div class="items-center col-span-1">
                                            <i class="mt-2 text-xl text-gray-500 fas fa-bars"></i>
                                        </div>
                                        <div class="col-span-8">
                                            <img
                                                :src="element.course.provider.image_url"
                                                class="float-right w-8 h-8 mt-3 rounded-full"
                                            >
                                            <p class="text-sm font-medium truncate text-myflowPurple-600">
                                                {{ element.title }}
                                            </p>
                                            <p class="items-center text-sm text-gray-500 ">
                                                    {{ element.course.title }}
                                            </p>
                                            <p class="items-center text-sm text-gray-500 ">
                                                <small>{{ element.course.provider.name }}</small>
                                            </p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </template>
                        </draggable>
                    </template>
                </card-basic>
            </div>

            <div class="col-span-2">
                <card-basic class="max-h-screen overflow-y-scroll">
                    <template v-slot:body>
                        <div class="flex items-center justify-between w-full">
                            <span class="text-lg font-medium text-gray-900">
                                Valt för spellista
                            </span>
                            <button-primary
                                size="sm"
                                @click.prevent="submit"
                            > {{submitButtonTitle}} </button-primary>
                        </div>
                        <hr class="mt-4 mb-4">
                        <p v-if="selected.length == 0">
                            Dra kapitel hit för att bygga er spellista
                        </p>
                        <draggable
                            role="list"
                            class="divide-y divide-gray-200"
                            :component-data="{
                                tag: 'ul',
                                type: 'transition-group',
                            }"
                            v-model="selected"
                            v-bind="dragOptions"
                            @start="drag = true"
                            @end="drag = false"
                            item-key="id"
                        >
                            <template #item="{ element }">
                                <li>
                                    <a
                                        href="#"
                                        class="block hover:bg-gray-50 list-group-item"
                                        aria-hidden="true"
                                    >
                                    <div class="grid-cols-9 gap-8 p-2 md:grid">
                                        <div class="items-center col-span-1">
                                            <i class="mt-2 text-xl text-gray-500 fas fa-bars"></i>
                                        </div>
                                        <div class="col-span-8">
                                            <img
                                                :src="element.course.provider.image"
                                                class="float-right w-8 h-8 mt-3 rounded-full"
                                            >
                                            <p class="text-sm font-medium truncate text-myflowPurple-600">
                                                {{ element.title }}
                                            </p>
                                            <p class="items-center text-sm text-gray-500 ">
                                                    {{ element.course.title }}
                                            </p>
                                            <p class="items-center text-sm text-gray-500 ">
                                                <small>{{ element.course.provider.name }}</small>
                                            </p>
                                            </div>
                                        </div>
                                    </a>
                                </li>
                            </template>
                        </draggable>
                    </template>
                </card-basic>
            </div>

        </div>
    </form>
  </div>
</template>
<script>
import { computed, ref } from 'vue';
import draggable from 'vuedraggable';
import {
    CalendarIcon,
    LocationMarkerIcon,
    UsersIcon,
} from '@heroicons/vue/solid';


export default {
  props: {
    id: { type: Number, default: null },
    categories: { type: Array, required: true },
    chapters: { type: Array, required: true },
    teams: { type: Array, required: true },
    currentplaylist: { type: Array, default: [] },
    currentteams: { type: Array, default: [] },
    description: { type: String, default: '' },
    title: { type: String, default: '' }
  },
  components: {
    draggable,
  },
  data() {
    return {
        chapterList: this.chapters.filter((c) => this.currentplaylist.map((plC) => plC.id).indexOf(c.id) === -1),
        categoryList: this.categories,
        selected: this.currentplaylist,
        categoryFilter: 0,
        searchFilter: null,
        teamList: this.teams,

        playlistTeams: this.currentteams,
        chapterIds: [],
        playlistTitle: this.title,
        playlistDescription: this.description,
        playlistDescriptionError: false,

        submitting: false,

        drag: false,
        dragOptions: {
            animation: 200,
            group: 'description',
            disabled: false,
            ghostClass: 'ghost',
        },

        generateNotifications: (this.id === null)
    };
  },
  computed: {
    submitButtonTitle() {
      return this.id === null ? 'Skapa' : 'Uppdatera';
    }
  },
  methods: {
    submit() {
      const form = this.$refs.form;
      if (form.checkValidity()) {
        this.save();
      } else {
        if(this.playlistDescription.length === 0) {
            this.playlistDescriptionError = true
        }
        form.reportValidity();
      }
    },
    async save() {
      const data = {
        titel: this.playlistTitle,
        description: this.playlistDescription,
        chapter_ids: this.selected.map((c) => c.id),
        company_team_ids: this.playlistTeams,
        generate_notifications: this.generateNotifications
      };

      if (this.submitting) { return; }
      this.submitting = true;

      try {
        const resp = (this.id === null) ? await axios.post(route('course_playlists.store'), data)
                                        : await axios.put(route('course_playlists.update', this.id), data);

        this.$document.locationAssign(route('course_playlists.show', resp.data.id));
      } catch (e) {
        if (console && console.warn) { console.warn(e); }
        this.submitting = false;
      }
    },
    checkedCheck(id) {
        this.form.teamlist.includes(id);
    },
    matchesFilters(chapter) {
        return this.matchesCategoryFilter(chapter) && this.matchesSearchFilter(chapter);
    },
    matchesCategoryFilter(chapter) {
        if (this.categoryFilter === 0) {
            return true;
        }

        if (chapter.course.categories === undefined) {
            return false;
        }

        return chapter.course.categories.map((c) => c.id).indexOf(this.categoryFilter) !== -1;
    },
    matchesSearchFilter(chapter) {
        if (!this.searchFilter) { return true; }

        const lowerSearch = this.searchFilter.toLowerCase();

        return chapter.title.toLowerCase().indexOf(lowerSearch) !== -1
            || chapter.course.title.toLowerCase().indexOf(lowerSearch) !== -1
            || chapter.course.provider.name.toLowerCase().indexOf(lowerSearch) !== -1;
    }
  },
};
</script>

<style lang="scss" scoped>
.ghost {
    opacity: 0.5;
    background: #dfd3e4;
}
.list-group {
    min-height: 20px;
}
.list-group-item {
    cursor: move;
}
li {
    list-style-type: none;
}
</style>
