<script setup>

import {computed, reactive, ref} from "vue";
import FrostedBar from "../layout/pageHeadings/frostedBar.vue";

const props = defineProps({
    pageType: {
        type: String,
        required: true,
    }
})

const page = reactive({
    title: '',
    password: '',
    template_id: null,
    public: true,
});

const savingState = ref('');

const useTemplateOptions = [
    {
        label: _mft('pagebuilderTemplate:useTemplate'),
        name: true,
    },
    {
        label: _mft('pagebuilderTemplate:useEmpty'),
        name: false,
    },
]
const useTemplate = ref(true)

const setUseTemplate = (e) => {
    if (e.name === false) {
        page.template_id = null;
        useTemplate.value = false
        return
    }
    useTemplate.value = true
}

const selectTemplate = (template) => {
    page.template_id = template.id;
}

const createPage = async () => {
    if (savingState.value === 'waiting') {
        return
    }
    savingState.value = 'waiting'

    const res = await axios.post(route('pages.store', props.pageType), page)

    if (res.data.success) {
        window.location.href = res.data.redirect_url
    } else {
        savingState.value = 'error'
    }

}

const pageValidates = computed(() => {
    return page.title.length > 0;
})

</script>
<template>
    <div class="md:sticky top-2 z-topbar">
        <frosted-bar>
            <div class="flex items-center justify-between space-x-4 f">
                <h1>{{ page.title === '' ? _mft('page:createPage') : page.title }}</h1>

                <div class="flex items-center space-x-4">
                    <story-lane-modal :module="'pages'"></story-lane-modal>

                    <button-base
                        :style-type="pageValidates ? 'success' : 'disabled'"
                        :disabled="! pageValidates"
                        @click="createPage()"
                        :state="savingState"
                    >
                        {{ _mft('page:createPage') }}
                    </button-base>
                </div>
            </div>
        </frosted-bar>
    </div>

    <div class="mt-12 xl:mt-24 mb-12 max-w-3xl mx-auto">
        <input-text
            name="title"
            label="page:pageTitle"
            v-model:content="page.title"
            required
            autofocus
        ></input-text>

        <input-text
            name="password"
            label="page:password"
            v-model:content="page.password"
        ></input-text>

        <toggle-switch
            class="mt-4"
            name="public"
            label="page:state.published"
            v-model:content="page.public"
        ></toggle-switch>

        <button-bar
            :options="useTemplateOptions"
            @select="setUseTemplate"
            class="mt-8 max-w-2xl mx-auto"
        />
    </div>

    <templates-list
        v-if="useTemplate"
        :show-empty-default="false"
        :read-only="true"
        :selected-template-id="page.template_id"
        :preset-category="pageType"
        @selectTemplate="selectTemplate"
    ></templates-list>

</template>
