<script setup>
import {onMounted, ref, watch} from "vue";

const props = defineProps({
    containerRef: {
        type: String,
        required: true
    },
    uuid: {
        type: String,
        require: true
    },
    content: {
        type: Object,
        required: true
    }
})

const recording = ref({
    loaded: false,
    mp4_url: null,
    hls_url: null,
    gif_url: null,
})

const loading = ref(true)

const loadVideo = async () => {
    if (! props.content.recordingUuid) {
        console.log('no uuid found')
        return
    }

    loading.value = true
    recording.value.loaded = false

    const res = await axios.get(route('api.browser-recording.show', props.content.recordingUuid))

    recording.value.mp4_url = res.data.mp4_url
    recording.value.hls_url = res.data.hls_url
    recording.value.gif_url = res.data.gif_url

    setTimeout(function(){
        if (res.data.mp4_url || res.data.hls_url) {
            recording.value.loaded = true
        }
        loading.value = false
    }, 150)
}

watch(() => props.content.recordingUuid, () => {
    loadVideo()
}, {deep: true})

onMounted(() => {
    loadVideo()
})

</script>
<template>
    <div>
        <mf-spinner-medium v-if="loading" class="w-16 h-8"></mf-spinner-medium>
        <div
            v-else
            class="mx-auto overflow-hidden rounded-wl"
        >
            <video-player
                v-if="recording.loaded === true"
                :mp4="recording.mp4_url"
                :hls="recording.hls_url"
                :poster="recording.gif_url"
            ></video-player>

            <div
                v-else
                class="w-full aspect-video rounded-lg overflow-hidden flex items-center justify-center text-white bg-gray-800"
            >
                <p class="my-4 text-gray-100">Ett problem uppstod när videon skulle visas.</p>
            </div>
        </div>
    </div>
</template>
