<script setup>

const props = defineProps({
    content: {
        type: Object,
        required: true,
    }
})

const userIsLoggedIn = window.myflow.user.loggedIn;
const pageBuilderActive = window.myflow.pagebuilderActive

const reloadPage = () => {
    window.location.reload();
}

</script>

<template>
<div>
    <div v-if="! userIsLoggedIn || pageBuilderActive">
        <h2 class="mb-2">
            {{ content.title ? content.title : _mft('pagebuilderBlock:loginForm.title.default') }}
        </h2>
        <login-form
            class="mb-4"
            @successfulLogin="reloadPage"
        />
    </div>
</div>
</template>

<style scoped>

</style>
