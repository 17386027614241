<template>
    <div>
        <div
            v-if="previewImage"
            :style="{backgroundImage: 'url('+ previewImage +')'}"
            class="mx-auto transform transition-all"
            :class="previewClass"
            ref="previewImage"
        />
        <div class="file-upload-wrapper relative rounded-lg pb-2">
            <filepond-upload
                ref="filepondUpload"
                :style="filepondUploadStyle"
                accepted-file-types="image/*"
                :required="required"
                :label-idle="label"
                @updatefiles="updateFiles($event)"
                @removedfile="removedFile($event)"
            ></filepond-upload>

            <div
                v-if="croppableImageBase64String"
                class="pb-2"
            >
                <image-cropper
                    v-if="croppableImageBase64String.length > 0"
                    :imageBase64String="croppableImageBase64String"
                    @updateCropping="updateCropping($event)"
                    @updatePreview="updatePreview($event)"
                    @finishCropping="finishCropping($event)"
                    @abortCropping="abortCropping()"
                ></image-cropper>
            </div>
        </div>
    </div>
</template>

<script>
 export default {
     props: {
         currentImage: {
             type: String,
             default: ''
         },
         required: Boolean,
         previewClass: {
            type: String,
            default: 'w-24 h-24 rounded-md bg-center bg-cover mb-4'
         },
         label: {
            type: String,
            default: _mft('form:fileUploadWithCropping.label')
         }
     },
     data() {
         return {
            croppingActive: false,
            croppableImageBase64String: '',
            previewImage: '',
            cropping: {
                x: '',
                y: '',
                width: '',
                height: ''
            }
         }
     },
     methods: {
        updateFiles(files) {
            if (files.length > 0) {
                this.$emit('fileUpdated', files[0].file)
                this.croppableImageBase64String = files[0].getFileEncodeBase64String()
                this.croppingActive = true
            } else {
                this.croppingActive = false
                this.croppableImageBase64String = ''
            }
        },
        removedFile(event) {
            this.$emit('removedfile', event)
        },
        updatePreview(base64ImageData) {
            let self = this
            if( this.$refs.previewImage ){
                this.$refs.previewImage.classList.add('scale-75')
                this.$refs.previewImage.classList.add('opacity-0')
            }
            setTimeout(function(){
                let image = base64ImageData
                self.previewImage = image

                if( self.$refs.previewImage ){
                    if( window.pageYOffset > self.$refs.previewImage.offsetTop - 20){
                        window.scrollTo({
                            top: self.$refs.previewImage.offsetTop - 20,
                            behavior: 'smooth'
                        });
                    }
                }
            }, 100);

            setTimeout(function(){
                if( self.$refs.previewImage ){
                    self.$refs.previewImage.classList.remove('scale-75')
                    self.$refs.previewImage.classList.remove('opacity-0')
                }
            }, 250);
        },
        finishCropping(base64Image) {
            this.$emit('croppingUpdated', this.cropping)
            this.$emit('croppingFinished', base64Image)
            this.cropping = {
                x: '',
                y: '',
                width: '',
                height: ''
            }
            this.croppableImageBase64String = ''
        },
        abortCropping() {
            this.$refs.filepondUpload.clearFiles()
            this.$emit('croppingAborted')
            this.cropping = {
                x: '',
                y: '',
                width: '',
                height: ''
            }
            this.croppableImageBase64String = ''
            this.$emit('croppingUpdated', this.cropping)
        },
        updateCropping(cropdata) {
            this.cropping.x = cropdata.x
            this.cropping.y = cropdata.y
            this.cropping.width = cropdata.width
            this.cropping.height = cropdata.height
        }
    },
    mounted() {
        if( this.currentImage !== '' ){
           this.previewImage = this.currentImage
        }
    },
    computed: {
        filepondUploadStyle() {
            if( this.croppableImageBase64String?.length > 0 || this.croppingActive ){
                return {maxHeight: '60px', overflow: 'hidden'}
            } else {
                return {}
            }
        }
    }
}
</script>
<style scoped>
    .file-upload-wrapper {
        background: rgba(229, 231, 235, 0.6)
    }
    :deep(.filepond--root .filepond--list){
        left: 0;
        right: 0;
    }
    :deep(.filepond--root .filepond--list .filepond--item){
        margin: 0;
    }
    :deep(.filepond--root .filepond--list-scroller){
        margin-top: 0;
    }
</style>
