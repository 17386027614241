<template>
    <div class="bg-gradient-to-r from-myflowPurple-900 to-myflowPurple-800 pt-24 pb-24 flex justify-center align-center">
        <div class="text-center text-white w-full ml-4 mr-4 lg:m-0 lg:w-6/12">
                <p class="text-2xl lg:text-3xl">
                    Få en verktygslåda för att 
                    <strong>kickstarta det löpande lärandet</strong>
                    i er organisation. Alltid med målet; behålla 
                    <strong>talanger</strong>, bygga <strong>konkurrenskraft</strong>
                    och <strong>nå tillväxtmålen.</strong>
                    </p>
            </div>
    </div>
    <div class="h-full background-image w-full">
        <div
            class="bg-gradient-to-r from-myflowPurple-800 w-full p-10 flex justify-start xl:justify-center"
        >
        <div style="max-width: 1300px" class="xl:flex xl:flex-col"> 
            <div class="flex flex-col text-2xl py-16">
                <h2 class="text-myflowYellow-800 text-4xl pb-8">På eventet</h2>

                <ul class="text-white list-disc">
                    <li>
                        På scenen; några av
                        <strong>Sveriges främsta experter </strong> <br />
                        som visar vägen till hur ni kickstartar lärandet hos er.
                        Lär och led lärandet!
                    </li>
                    <br />
                    <li>
                        <strong>Kopiera framgång </strong>
                        och
                        <strong>
                            nätverka med andra ledare (på The Park
                            Sthlm).</strong
                        >
                    </li>
                    <br />
                    <li>
                        <strong>Workshops</strong> - effektfulla övningar
                        <strong> (på The Park Sthlm). </strong>
                    </li>
                    <br />
                    <li>
                        <strong>
                            Mingel och bubbel! (på The Park Sthlm).</strong
                        >
                    </li>
                </ul>
            </div>

            
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
        };
    },
};
</script>

<style scoped>
.background-image {
    background-image: url(https://myflow.se/app/uploads/2022/06/headway-F2KRf_QfCqw-unsplash.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}
</style>
