<script setup>
    const emits = defineEmits(['include', 'exclude'])

    const props = defineProps({
        tag: {
            type: Object,
            required: true
        },
        isIncluded: {
            type: Boolean,
            default: false
        },
        isExcluded: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        }
    })

    const toggleInclude = () => {
        if (props.loading) {
            return;
        }
        if (props.isIncluded) {
            emits('uninclude', props.tag)
        } else {
            emits('unexclude', props.tag)
            emits('include', props.tag)
        }
    }

    const toggleExclude = () => {
        if (props.loading) {
            return;
        }
        if (props.isExcluded) {
            emits('unexclude', props.tag)
        } else {
            emits('uninclude', props.tag)
            emits('exclude', props.tag)
        }
    }
</script>
<template>
    <span>
        {{ tag.name }}
    </span>
    <div class="flex items-center justify-between w-full md:space-x-2 text-xs md:w-auto md:text-sm">
        <div class="flex flex-col mr-2 text-xs">
            <span>
                {{ tag.leads_with_email_count }} {{ _mft('communication:nRecipientsViaEmail') }}
            </span>
            <span>
                {{ tag.leads_with_phone_count }} {{ _mft('communication:nRecipientsViaSms') }}
            </span>
        </div>

        <div
            :class="props.loading ? 'opacity-50 cursor-wait' : ''"
            class="flex flex-col md:grid grid-cols-2 gap-2 mt-2 md:mt-0 max-w-xs transition-opacity"
        >
            <div class="h-7">
                <button
                    @click="toggleInclude"
                    class="w-24 text-xs"
                    :class="props.isIncluded ? 'myflow-basic-button--secondary' : 'myflow-basic-button'"
                >{{ _mft('communication:tag.include') }}</button>
            </div>
            <div class="h-7">
                <button
                    @click="toggleExclude"
                    class="w-24 text-xs"
                    :class="props.isExcluded ? 'myflow-basic-button--secondary' : 'myflow-basic-button'"
                >{{ _mft('communication:tag.exclude') }}</button>
            </div>
        </div>
    </div>
</template>
