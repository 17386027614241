<script setup>
import {ref} from "vue";

const props = defineProps({
    showAsModal: {
        type: Boolean,
        required: false,
        default: true
    },
    inlineContainerClass: {
        type: String,
        required: false,
        default: 'w-full mx-auto max-w-3xl'
    },
    padded: {
        type: Boolean,
        required: false,
        default: true
    }
})

const modalOpen = ref(false)

</script>
<template>
<div>
    <div
        v-if="showAsModal"
        class="inline-block"
    >
        <general-modal>
            <template v-slot:header>
                <h2><slot name="title"></slot></h2>
            </template>
            <template v-slot:body>
                <slot name="body"></slot>
            </template>
            <template v-slot:open>
                <div class="inline-block">
                    <button-secondary>
                        ?
                    </button-secondary>
                </div>
            </template>
        </general-modal>
    </div>
    <div
        v-else
        :class="[inlineContainerClass, padded ? 'my-4 md:my-6 lg:my-8' : '']"
    >
        <h2 class="mb-6 lg:mb-8"><slot name="title"></slot></h2>
        <slot name="body"></slot>
    </div>
</div>
</template>
