<template>
    <div v-if="isSigned">
        <p>
            {{ _mft('expertContract:signatureRequest.hasBeenSignedRedirectPending') }}
            <a :href="redirectUrl">
                {{ _mft('expertContract:signatureRequest.hasBeenSignedRedirectPending.cta') }}
            </a>
        </p>
    </div>
    <div v-else-if="isSigning">
        <template v-if="signRequest">
            <div class="fixed inset-0 bg-black/10 flex justify-center items-center px-4 z-modal">
                <card-basic>
                    <template #body>
                        <on-screen-signature-prompt
                            v-if="signMethod == SIGN_METHOD_ON_SCREEN_SIGNATURE"
                            :request="signRequest.on_screen_signature_request"
                            @completed="didSign()"
                            @failed="didFailToSign()"
                        ></on-screen-signature-prompt>

                        <bankid-prompt
                            v-else-if="signMethod == SIGN_METHOD_BANK_ID"
                            :request="signRequest.bank_id_request"
                            @completed="didSign()"
                            @failed="didFailToSign()"
                        ></bankid-prompt>

                        <div class="flex justify-end">
                            <button-warning
                                @click="cancel"
                            >
                                {{ _mft('shared:cancel') }}
                            </button-warning>
                        </div>
                    </template>
                </card-basic>
            </div>
        </template>

        <div v-else class="mt-4 text-center">
            <mf-spinner-medium
                class="w-24 h-12"
            />
        </div>
    </div>
    <div v-else>
        <card-basic>
            <template #header>
                <h4>
                    {{ _mft('expertContract:signatureRequest.selectSigningMethod') }}
                </h4>
            </template>
            <template #body>
                <div class="flex flex-col items-stretch mx-auto max-w-56 space-y-4">
                    <button-base
                        v-if="enabledSignatureMethods.includes(SIGN_METHOD_BANK_ID)"
                        style-type="secondary"
                        type="button"
                         @click.prevent="sign(SIGN_METHOD_BANK_ID)"
                    >
                        <template #icon>
                            <img src="https://myflow.fra1.digitaloceanspaces.com/BankID_logo.svg" class="w-6 h-auto">
                        </template>
                        BankID
                    </button-base>

                    <button-base
                        v-if="enabledSignatureMethods.includes(SIGN_METHOD_ON_SCREEN_SIGNATURE)"
                        style-type="secondary"
                        type="button"
                        @click.prevent="sign(SIGN_METHOD_ON_SCREEN_SIGNATURE)"
                    >
                        <template #icon>
                            <div class="w-6 flex justify-center">
                                <i class="fa-solid fa-signature"></i>
                            </div>
                        </template>
                        On Screen Signature
                    </button-base>

                    <a
                        :href="route('expert-contracts.public', signatureRequestUuid)"
                        class="block"
                    >
                        <button-warning
                            type="button"
                            class="w-full justify-center"
                        >
                            {{ _mft('shared:cancel') }}
                        </button-warning>
                    </a>
                </div>
                <div v-if="isFailed" class="mt-3 italic text-small text-myflowRed-400">
                    {{ _mft('expertContract:signatureRequest.signingFailed') }}
                </div>
            </template>
        </card-basic>
    </div>
</template>

<script>
import axios from 'axios';
import CardBasic from "../containers/cardBasic.vue";

export default {
    components: {CardBasic},
    props: {
        signatureRequestUuid: {
            type: String,
            required: true
        },
        enabledSignatureMethods: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            SIGN_METHOD_ON_SCREEN_SIGNATURE: 'on-screen-signature',
            SIGN_METHOD_BANK_ID: 'bank-id',

            isSigning: false,
            signMethod: null,
            isFailed: false,
            isSigned: false,
            signRequest: null
        };
    },
    computed: {
        redirectUrl() {
            return route('expert-contracts.public', this.signatureRequestUuid);
        }
    },
    methods: {
        async sign(signMethod) {
            this.isSigning = true;
            this.isFailed = false;
            this.signMethod = signMethod;

            try {
                const response = await axios.post(
                    route('expert-contracts.public-sign', this.signatureRequestUuid),
                    { signature_method: signMethod }
                );
                this.signRequest = response.data;
            } catch (error) {
                this.didFailToSign();

                throw error;
            }
        },
        cancel() {
            this.isSigning = false;
            this.signRequest = null;
            this.signMethod = null;
        },
        didSign() {
            this.isSigned = true;
            this.isSigning = false;

            this.$document.locationAssign(this.redirectUrl + '?signed=true');
        },
        didFailToSign() {
            this.isFailed = true;
            this.isSigning = false;
            this.signRequest = null;
            this.signMethod = null;
        }
    }
};
</script>
