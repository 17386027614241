<script setup>
import { ref } from 'vue';

const emit = defineEmits('reloadSignatureRequests');

const modal = ref(null);
const addingSelf = ref(false);

const props = defineProps({
    expertContractId: {
        type: Number,
        required: true,
    },
    currentUserIsRecipient: {
        type: Boolean,
        required: false,
        default: true,
    },
    activeWorkflow: {
        type: Boolean,
        required: false,
        default: false,
    },
    activeWorkflowName: {
        type: String,
        required: false,
        default: null
    }
});

const addSelfAsSignee = async () => {
    addingSelf.value = true;
    const response = await axios.post(
        route(
            'expert-contracts.signature-requests.for-current-user',
            props.expertContractId
        )
    );
    emit('reloadSignatureRequests');
};

const closeModalAndReload = () => {
    modal.value.closeModal();
    emit('reloadSignatureRequests');
};
</script>
<template>
    <div class="flex justify-end">
        <button
            v-if="!props.currentUserIsRecipient && !addingSelf"
            class="mb-2 mr-2 myflow-basic-button"
            @click="addSelfAsSignee"
        >
            {{ _mft('expertContract:addSignee.addSelf') }}
        </button>

        <general-modal ref="modal">
            <template v-slot:header>{{ _mft('expertContract:addSignee.addSelf') }}</template>
            <template v-slot:body>
                <expert-contract-signature-requests-create
                    :expert-contract-id="props.expertContractId"
                    :expert-pro-active="true"
                    :active-workflow="props.activeWorkflow"
                    :active-workflow-name="props.activeWorkflowName"
                    @reloadSignatureRequests="closeModalAndReload"
                />
            </template>
            <template v-slot:open>
                <button class="myflow-basic-button">{{ _mft('expertContract:addSignee.addSignee') }}</button>
            </template>
        </general-modal>
    </div>
</template>
