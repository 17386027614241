<template>
    <div v-if="error || errors.length > 0">
        <p
            class="text-xs mt-1 text-myflowRed-dark"
            v-if="error"
        >{{ error }}</p>
        <p
            class="text-xs mt-1 text-myflowRed-dark"
            v-for="(error, i) in errors"
            :key="i"
        >{{ error }}</p>
    </div>
</template>
<script>
export default {
  props: {
    error: {
      type: String,
      default: ''
    },
    errors: {
        type: Object,
        default: {}
    }
  }
}
</script>
