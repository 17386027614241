<script setup>
    import { ref } from 'vue'

    const emit = defineEmits('confirmed')

    const props = defineProps({
        size: {
            type: String,
            required: false,
            default: 'md'
        },
        state: {
            type: String,
            required: false,
            default: '',
            options: ['', 'waiting', 'success', 'error']
        },
        confirmType: {
            type: String,
            required: false,
            default: 'danger' // any <button-base> styletype
        },
        buttonClass: {
            type: String,
            required: false,
            default: ''
        },
        buttonLabel: {
            type: String,
            required: false,
            default: null
        },
        modalClass: {
            type: String,
            required: false,
            default: 'absolute -top-2 -right-2 p-2 bg-white rounded shadow-md text-black z-modal'
        },
        confirmButtonClass: {
            type: String,
            required: false,
            default: 'myflow-basic-button--danger whitespace-nowrap'
        },
        abortButtonClass: {
            type: String,
            required: false,
            default: 'myflow-basic-button whitespace-nowrap'
        },
        confirmText: {
            type: String,
            required: false,
            default: null
        },
        confirmButtonLabel: {
            type: String,
            required: false,
            default: 'Ja'
        },
        abortButtonLabel: {
            type: String,
            required: false,
            default: _mft('shared:modal.defaultCancel')
        }
    })

    const armed = ref(false)

    const confirm = () => {
        armed.value = false
        emit('confirmed')
    }

</script>
<template>
    <span
        class="relative"
    >
        <button-base
            :styleType="props.confirmType"
            @click="armed = true"
            :size="size"
            :state="props.state"
        >
            {{ props.buttonLabel }}
            <slot></slot>
        </button-base>
        <div
            v-if="armed"
            :class="props.modalClass"
        >
            <p v-if="props.confirmText" class="text-xs">{{ props.confirmText }}</p>
            <div class="flex justify-center space-x-2">
                <button-secondary
                    size="sm"
                    class="whitespace-nowrap"
                    @click="armed = false"
                >
                    {{ props.abortButtonLabel }}
                </button-secondary>
                <button-base
                    :styleType="props.confirmType"
                    size="sm"
                    class="whitespace-nowrap"
                    @click="confirm"
                >
                    {{ props.confirmButtonLabel }}
                </button-base>
            </div>
        </div>
    </span>
</template>
