<script setup>
import { reactive, watch, onMounted, ref } from 'vue';
import recurranceOptions from './_recurranceOptions.vue';

const emit = defineEmits(['update:automationOptions']);

const props = defineProps({
    communicationType: {
        type: String,
        required: true
    },
    presetOptions: {
        type: Object,
        default: {}
    },
    smsEnabled: {
        type: Boolean,
        required: false,
        default: false,
    },
    emailEnabled: {
        type: Boolean,
        required: false,
        default: false,
    },
})


const sendRuleTypes = []

if (props.communicationType === 'newsletter') {
    sendRuleTypes.push({
            label: _mft('communication:sendRuleTypes.onceToAll'),
            value: 'once_to_all',
        })
    sendRuleTypes.push({
        label: _mft('communication:sendRuleTypes.onceAssignedInTimeRange'),
        value: 'once_assigned_in_time_range',
    })
}
if (props.communicationType === 'automation') {
    sendRuleTypes.push({
        label: _mft('communication:sendRuleTypes.recurringAfterTagAssigned'),
        value: 'recurring_after_tag_assigned',
    })
}

let deliveryTypes = ref([])

const state = reactive({
    name: '',
    activateAt: props.presetOptions.activateAt,
    activateAtDisplay: props.presetOptions.activateAtDisplay,
    selectedDeliveryTypes: [],
    selectedSendRuleType: null,
    onceToAllDate: null,
    onceAssignedInTimeRangeStart: null,
    onceAssignedInTimeRangeEnd: null,
    recurringAfterTagAssignedDelayInHours: null
})

const updateRecurrance = (data) => {
    state.recurringAfterTagAssignedDelayInHours = data.recurringAfterTagAssignedDelayInHours;
}

watch(state, (newValue) => {
    emit('update:automationOptions', newValue);
})

onMounted(() => {
    state.name = props.presetOptions.name;
    state.selectedDeliveryTypes = props.presetOptions.selectedDeliveryTypes;
    state.selectedSendRuleType = props.presetOptions.selectedSendRuleType;
    state.recurringAfterTagAssignedDelayInHours = props.presetOptions.recurringAfterTagAssignedDelayInHours;

    if (props.smsEnabled) {
        deliveryTypes.value.push({
            label: _mft('communication:sms'),
            value: 'sms',
        })
    }
    if (props.emailEnabled) {
        deliveryTypes.value.push({
            label: _mft('communication:email'),
            value: 'email',
        })
    }
})

</script>
<template>
    <div>
        <h2 v-if="props.communicationType === 'automation'">{{ _mft('communication:automation.domainNameSingular') }}</h2>
        <h2 v-else>{{ _mft('communication:newsletter.domainNameSingular') }}</h2>
        <div class="grid gap-4 mb-4 lg:grid-cols-2">
            <div>
                <input-text
                    :content="state.name"
                    @update:content="state.name = $event"
                    :label="_mft('communication:automationName')"
                />
                <input-checkboxes
                    :content="state.selectedDeliveryTypes"
                    :options="deliveryTypes"
                    @update:content="state.selectedDeliveryTypes = $event"
                    :label="_mft('communication:deliveryType')"
                    name="deliverytype"
                />
                <p
                    v-if="!props.emailEnabled"
                    class="mb-1 -mt-2 text-xs text-gray-500"
                >
                    <i class="mr-1 fa-light fa-circle-info"></i>
                    {{ _mft('communication:email.verify.prefix') }} <a :href="route('crm.communications.settings.show')" target="_blank">{{ _mft('communication:email.verify.linkText') }}</a>
                    {{ _mft('communication:email.verify.suffix') }}
                </p>
                <p
                    v-if="!props.smsEnabled"
                    class="mb-1 -mt-2 text-xs text-gray-500"
                >
                    <i class="mr-1 fa-light fa-circle-info"></i>
                    {{ _mft('communication:sms.verify.prefix') }} <a :href="route('crm.communications.settings.show')" target="_blank">{{ _mft('communication:sms.verify.linkText') }}</a> {{ _mft('communication:sms.verify.suffix') }}
                </p>
            </div>
            <div>
                <input-radios
                    :content="state.selectedSendRuleType"
                    :options="sendRuleTypes"
                    @update:content="state.selectedSendRuleType = $event"
                    :label="_mft('communication:sendRuleType')"
                    name="sendrule"
                />

                <recurranceOptions
                    v-if="state.selectedSendRuleType === 'recurring_after_tag_assigned'"
                    :recurringAfterTagAssignedDelayInHours="state.recurringAfterTagAssignedDelayInHours"
                    @update:recurringAfterTagAssignedDelayInHours="updateRecurrance"
                />
            </div>
        </div>
    </div>
</template>
