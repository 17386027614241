<script setup>

import {computed, onMounted, ref} from "vue";

const availableTags = ref([]);
const search = ref('');
const showAll = ref(false);
const selectedTagId = ref(null);

const emit = defineEmits(['tagSelected']);

const loadTags = () => {
        axios.get(route('crm.leadtags.index')).then((response) => {
            availableTags.value = response.data.tags;
        });
    }

    const createTag = () => {
        axios.post(route('crm.leadtags.store'), {
            name: search.value
        }).then((response) => {
            selectTag(response.data.tag.id)
            loadTags();
        });
    }

    const selectTag = (tagId) => {
        if (selectedTagId.value === tagId) {
            selectedTagId.value = null;
        } else {
            selectedTagId.value = tagId;
        }
        emit('tagSelected', selectedTagId.value);
    }

    const filteredTags = computed(() => {
        return availableTags.value.filter((tag) => {
            return tag.name.toLowerCase().includes(search.value.toLowerCase());
        });
    });

    const limitedFilteredTags = computed(() => {
        if (showAll.value === true) {
            return filteredTags.value;
        } else {
            return filteredTags.value.slice(0, 5);
        }
    });

    const moreTagsAvailable = computed(() => {
        return filteredTags.value.length - 5;
    });

    onMounted(() => {
        loadTags();
    });
</script>
<template>
    <div>
        <div class="relative mb-2">
            <input-text
                class="!mb-0"
                v-model:content="search"
                :maxlength="24"
                :placeholder="_mft('crm:leadImportSelectTag.searchPlaceholder')"
            />
            <button
                v-if="search"
                class="absolute px-2 py-1 text-white bg-black rounded-md top-2 bottom-1 right-1"
                @click="createTag"
            >{{ _mft('crm:leadImportSelectTag.createNew') }}</button>
        </div>
        <ul class="flex flex-wrap mb-4">
            <li
                v-for="tag in limitedFilteredTags"
                :key="tag.id"
                @click="selectTag(tag.id)"
                class="mr-2 mb-1 text-black rounded-md px-1.5 py-0.5 cursor-pointer border-1"
                :class="selectedTagId === tag.id ? 'bg-gray-300 ring-2 ring-gray-500' : 'bg-gray-200 hover:bg-gray-300'"
            >
                {{ tag.name }}
            </li>
            <li
                v-if="moreTagsAvailable > 0 && !showAll"
                class="mr-2 mt-0.5 cursor-pointer hover:underline"
                @click="showAll = true"
            >{{ _mft('crm:tag.andAnotherNMore', {count: moreTagsAvailable}) }}</li>
        </ul>
    </div>
</template>
