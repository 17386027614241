<script setup>
    import { onMounted, ref } from 'vue'

    const props = defineProps({
        sessionRoute: {
            type: String,
            required: true,
        },
    })

    const sessions = ref({})

    const loadSessions = async () => {
        const response = await axios.get(props.sessionRoute)
        sessions.value = response.data.sessions
    }

    onMounted(() => {
        loadSessions()
    })

</script>
<template>
    <div>
        <div class="space-y-8">
            <div>
                <h3 class="mb-1">{{ _mft('coaching:overview.bookableSessions') }}</h3>
                <ul class="divide-y">
                    <li
                        v-if="sessions.bookable?.length === 0"
                        class="text-gray-500"
                    >
                        {{ _mft('coaching:overview.noNotBooked') }}
                    </li>
                    <li
                        v-for="session in sessions.bookable"
                        :key="session.id"
                        class="flex justify-between py-2 space-x-4"
                    >
                        <div class="flex space-x-4">
                            <div class="text-center w-28 border-r text-gray-400" v-html="_mft('coaching:overview.pill.notBooked')">
                            </div>
                            <div>
                                <div>
                                    <span
                                        class="font-bold"
                                    >
                                        {{ session.product.title }}
                                    </span>
                                    <span
                                        class="inline-block px-1 py-px ml-2 text-xs text-white bg-gray-600 rounded"
                                        v-if="session.session_number"
                                    >
                                        {{ _mft('coaching:session.domainNameSingular') }} {{ session.session_number }}
                                    </span>
                                </div>
                                <div class="text-xs text-gray-500">
                                    {{ session.product.provider.name }}
                                </div>
                            </div>
                        </div>
                        <div>
                            <a
                                :href="route('coaching.client.session.show', session.id)"
                                class="myflow-basic-button--success"
                            >{{ _mft('coaching:session.bookNow') }}</a>
                        </div>
                    </li>
                </ul>
            </div>

            <div
                v-if="sessions.confirmed?.length > 0"
            >
                <h3 class="mb-1">
                    {{ _mft('coaching:overview.scheduledSessions')}}
                </h3>
                <ul class="divide-y">
                    <li
                        v-for="session in sessions.confirmed"
                        :key="session.id"
                        class="flex justify-between py-2 space-x-4"
                    >
                        <div class="flex space-x-4">
                            <date-time-box
                                :startDate="session.begins_at.date"
                                :endDate="session.ends_at.date"
                                :startTime="session.begins_at.time"
                                :endTime="session.ends_at.time"
                                class="px-4 py-1 border-r w-28"
                            />
                            <div class="py-1">
                                <div class="font-bold">
                                    {{ session.product.title }}
                                </div>
                                <div class="text-xs text-gray-500">
                                    {{ session.product.provider.name }}
                                </div>
                            </div>
                        </div>
                        <div>
                            <a
                                :href="route('coaching.client.session.show', session.id)"
                                class="myflow-basic-button"
                            >{{ _mft('shared:show') }}</a>
                        </div>
                    </li>
                </ul>
            </div>

            <div
                v-if="sessions.completed?.length > 0"
            >
                <h3 class="mb-1">{{ _mft('coaching:overview.completedSessions') }}</h3>
                <ul class="divide-y">
                    <li
                        v-for="session in sessions.completed"
                        :key="session.id"
                        class="flex justify-between py-2 space-x-4"
                    >
                        <div class="flex space-x-4">
                            <date-time-box
                                :startDate="session.begins_at.date"
                                :endDate="session.ends_at.date"
                                :startTime="session.begins_at.time"
                                :endTime="session.ends_at.time"
                                class="px-4 py-1 border-r w-28"
                            />
                            <div>
                                <div class="font-bold">
                                    {{ session.product.title }}
                                </div>
                                <div class="text-xs text-gray-500">
                                    {{ session.product.provider.name }}
                                </div>
                            </div>
                        </div>
                        <div>
                            <a
                                :href="route('coaching.client.session.show', session.id)"
                                class="myflow-basic-button"
                            >{{ _mft('shared:show') }}</a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
