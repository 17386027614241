<script setup>
import { computed, ref, onMounted, onBeforeUnmount, useSlots } from 'vue';
import { ChevronRightIcon } from '@heroicons/vue/solid'

const onScreen = ref(false);
const transitioning = ref(true);
const emit = defineEmits(['close']);
const slots = useSlots()
const minimizeSlideout = ref(false)

const props = defineProps({
    title: {
        type: String,
        default: null,
        required: false,
    }
})

const showTitleSlot = () => {
    return !!slots.title
}

onMounted(() => {
    setTimeout(function(){
        onScreen.value = true;
        transitioning.value = false;
    }, 1);
})

const close = () => {
    transitioning.value = true;
    setTimeout(function(){
        onScreen.value = false;
        emit('close');
    }, 150);
}

const minimize = () => {
    minimizeSlideout.value = !minimizeSlideout.value
}

onBeforeUnmount(() => {
    onScreen.value = false;
    document.body.classList.remove('overflow-hidden');
})

const modalPositionClass = computed(() => {
    if (minimizeSlideout.value === true) {
        return 'translate-x-full -ml-4'
    }

    return transitioning.value === true ? 'translate-x-full' : 'translate-x-6 md:translate-x-0'
})

</script>
<template>
    <Teleport to="body">
        <div
            style="z-index: 998;"
            class="fixed w-screen max-w-sm transition-transform bg-white shadow-lg top-4 bottom-4 right-4 rounded-2xl"
            :class="modalPositionClass"
        >
            <div class="relative flex flex-col h-full">
                <div class="">
                    <div class="flex items-start justify-between w-full px-4 py-6 border-b text-wlPrimary sm:px-6 rounded-t-2xl">
                        <button
                            type="button"
                            class="absolute bg-white rounded-full text-wlPrimary hover:text-wlPrimary-light focus:outline-none -left-3 top-8"
                            tabindex="0"
                            @click="minimize()"
                        >
                            <span class="sr-only">{{ _mft('elements:minimize') }}</span>
                            <ChevronRightIcon
                                class="w-6 h-6 transition-transform transform"
                                :class="minimizeSlideout ? 'rotate-180' : ''"
                            />
                        </button>
                        <div>
                            <h1
                                v-if="props.title"
                            >
                                {{ props.title }}
                            </h1>
                            <slot name="title">
                            </slot>
                        </div>
                        <div class="relative flex items-center pr-4 ml-3 h-9 md:pr-0">
                            <button
                                type="button"
                                class="rounded-md text-wlPrimary hover:text-wlPrimary-light focus:outline-none"
                                tabindex="0"
                                @click="close()"
                            >
                                <span class="sr-only">{{ _mft('elements:close') }}</span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path></svg>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="h-full p-4 lg:p-6">
                    <slot name="body"></slot>
                </div>
            </div>
        </div>
    </Teleport>
</template>
