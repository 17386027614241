<template>
  <div aria-live="assertive" class="fixed inset-0 flex items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start z-[200]">
    <div class="flex flex-col items-center w-full space-y-4 sm:items-end">
      <transition enter-active-class="transition duration-300 ease-out transform" enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2" enter-to-class="translate-y-0 opacity-100 sm:translate-x-0" leave-active-class="transition duration-100 ease-in" leave-from-class="opacity-100" leave-to-class="opacity-0">
        <div v-if="show" class="w-full max-w-sm overflow-hidden bg-white border-2 rounded-lg shadow-lg pointer-events-auto border-myflowRed-900 ring-1 ring-black ring-opacity-5">
          <div class="p-5">
            <div class="flex items-start">

              <div class="flex-shrink-0">
                <ExclamationCircleIcon class="w-6 h-6 text-red-700" aria-hidden="true" />
              </div>

              <div class="ml-3 w-0 flex-1 pt-0.5">
                <p class="text-sm font-medium text-gray-900">
                    <slot name="header"></slot>
                </p>
                <p class="mt-1 text-sm text-gray-500">
                    <slot name="body"></slot>
                </p>
              </div>
              <div class="flex flex-shrink-0 ml-4">
                <button v-on:click="closeAlert()" class="inline-flex text-gray-400 bg-white rounded-md hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-myflowPurple-500">
                  <span class="sr-only">{{ _mft('shared:modal.close') }}</span>
                  <XIcon class="w-5 h-5 text-myflowRed-900" aria-hidden="true" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { CheckCircleIcon } from '@heroicons/vue/outline'
import { ExclamationCircleIcon } from '@heroicons/vue/outline'
import { XIcon } from '@heroicons/vue/solid'

export default {
  components: {
    CheckCircleIcon,
    ExclamationCircleIcon,
    XIcon,
  },
  data() {
    return {
      show: true,
    };
  },
  async mounted() {
  },
  methods: {
    closeAlert() {
      this.show = false;
    },
  }

}
</script>

