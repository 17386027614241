<script setup>

import { ref, onMounted } from 'vue'

const props = defineProps({
    url: String
})

</script>

<template>
    <card-basic class="col-span-6 lg:col-span-3">
        <template v-slot:body>
            <div class="grid-cols-3 gap-8 md:grid">
                <div class="col-span-3 text-center md:text-left">
                    <img class="w-2/3 mx-auto opacity-75 md:mx-0 md:w-full" src="https://ohk.fra1.cdn.digitaloceanspaces.com/temp/clean2.png">

                    <div class="relative z-10 -mt-24 bg-gradient-to-t from-white to-transparent pt-8 via-white/85 via-75% md:-mt-60 md:pt-24">
                        <h5>Share myflow</h5>
                        <h1 class="mt-2 mb-5">Boost your buddy’s biz</h1>

                        <p class="mb-4">Got a friend in need? Share a smart business system, packed with features for growth. Spread the word about myflow!</p>

                        <p class="mb-4">Copy the link below and share.</p>

                        <div class="p-4 mb-3 bg-gray-200 rounded-lg">
                            <input
                                class="w-full max-w-full truncate bg-transparent focus:outline-none"
                                type="text"
                                :value="props.url"
                                onfocus="event.target.select()"
                            >
                            <link-field
                                class="flex mt-2 ml-1 space-x-4 md:ml-0 md:space-x-4"
                                :url="props.url"
                            ></link-field>
                        </div>

                    </div>
                </div>
            </div>
        </template>
    </card-basic>
</template>

<style scoped>
</style>
