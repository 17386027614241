<script setup>
import { ref } from 'vue'

const emit = defineEmits(['update:content'])
const props = defineProps({
    content: {
        type: Boolean,
        required: true
    },
    disabled: {
        type: Boolean,
        default: false,
    }
})

const done = ref(props.content)

const toggleDone = () => {
    if (props.disabled) return
    done.value = !done.value
    emit('update:content', done.value)
}

</script>
<template>
    <button
        @click="toggleDone"
        class="border-2 border-gray-700 relative"
        :class="[disabled === true ? 'cursor-not-allowed' : 'cursor-pointer']"
    >
        <span
            class="block absolute inset-px transition-all duration-75"
            :class="done === true ? 'bg-gray-600 rounded-px scale-100' : 'bg-transparent rounded-full scale-0 hover:bg-gray-100'"
        ></span>
    </button>
</template>
