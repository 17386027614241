<script setup>
import { onMounted, ref, computed, inject, watch } from 'vue';
import axios from 'axios';

const commentablePost = inject('commentablePost');
const activeChannel = inject('activeChannel');

const numCommentsToDisplay = ref(3);

const getChannelData = inject('getChannelData');

const commentContent = ref('');

const props = defineProps({
    postData: Object,
});

const post = ref(props.postData);
const commentInput = ref(null)

async function addComment(postId, channelId) {
    if (! commentValidates.value) {
        return;
    }
    const response = await axios.post(route('community.member.addComment', postId), {
        content: commentContent.value,
    });

    await getChannelData(channelId);

    commentablePost.value = null;
    commentContent.value = null;
}

function loadMoreComments(postId) {
    if (numCommentsToDisplay.value === 3) {
        numCommentsToDisplay.value = 20;
    } else {
        numCommentsToDisplay.value += 20;
    }
}

async function flagComment(commentId) {
    const response = await axios.post(route('community.member.flagComment', commentId));

    await getChannelData(activeChannel.value.id);
}

const commentList = computed(() => {
    let commentList = props.postData.comments.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
    return commentList.slice(-numCommentsToDisplay.value)
});

const commentValidates = computed(() => {
    return commentContent.value !== null && commentContent.value.length > 0;
});

onMounted(() => {
    if (commentablePost.value === post.value.id) {
        setTimeout(function(){
            commentInput.value.focusInput();
        }, 250)
    }
});

</script>

<template>
    <div>
        <div>
            <div
                v-if="commentablePost === post.id"
                class="ml-10"
            >
                <input-textarea
                    input-height-class="h-16"
                    input-class="text-xs pr-28"
                    class="!mb-2"
                    ref="commentInput"
                    autofocus
                    v-model:content="commentContent"
                />
                <div
                    class="flex justify-end mb-3"
                >
                    <button-base
                        :style-type="commentValidates ? 'primary' : 'disabled'"
                        :disabled="! commentValidates"
                        v-on:click="addComment(post.id, activeChannel.id)"
                        size="sm"
                    >
                        {{ _mft('community:post.addComment') }}
                    </button-base>
                </div>
            </div>
        </div>

        <div
            v-if="numCommentsToDisplay && numCommentsToDisplay && post.comments.length > numCommentsToDisplay"
            class="pb-1 ml-10 text-center"
        >
            <button-base
                size="sm"
                @click="loadMoreComments()"
            >{{ _mft('community:post.loadMoreComments') }}</button-base>
        </div>

        <div class="mt-2 space-y-2">
            <div
                v-for="comment in commentList"
                :key="comment.id"
                class="ml-10"
            >
                <div class="flex p-4 space-x-2 text-xs bg-gray-100 rounded-wl">
                    <div class="w-6 shrink-0">
                        <img class="object-cover object-center w-6 h-6 rounded-full" :src="comment.user.profile_img" alt="avatar">
                    </div>
                    <div class="w-full">
                        <div class="flex items-center justify-between h-6 mb-0 -translate-y-px">
                            <div>
                                <span class="font-semibold">{{ comment.user.name }}</span>
                            </div>
                            <div class="flex items-center space-x-1">
                                <button-base
                                    v-if="! comment.is_flagged"
                                    v-on:click="flagComment(comment.id)"
                                    size="xs"
                                    class="text-myflowRed-600"
                                    classes="!border-myflowRed-600"
                                >
                                    <i class="fa-regular fa-flag"></i>
                                </button-base>

                                <button-base
                                    v-else
                                    v-on:click="flagComment(comment.id)"
                                    size="xs"
                                    class="text-myflowRed-600"
                                    classes="!border-myflowRed-600"
                                >
                                    <i class="fa-solid fa-flag"></i>
                                </button-base>

                                <div class="text-gray-400 text-xxs">{{ comment.created_at }}</div>
                            </div>
                        </div>
                        <p class="mb-0 whitespace-break-spaces">{{ comment.content }}</p>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
