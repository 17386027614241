<script setup>
import {inject, ref} from 'vue'
import ButtonMoreOptionsOption from "./buttonMoreOptionsOption.vue";

const emit = defineEmits('confirmed')

const props = defineProps({
    size: {
        type: String,
        required: false,
        default: 'md'
    },
    state: {
        type: String,
        required: false,
        default: '',
        options: ['', 'waiting', 'success', 'error']
    },
    confirmType: {
        type: String,
        required: false,
        default: 'danger' // any <button-base> styletype
    },
    buttonClass: {
        type: String,
        required: false,
        default: ''
    },
    confirmButtonClass: {
        type: String,
        required: false,
        default: 'myflow-basic-button--danger whitespace-nowrap'
    },
    abortButtonClass: {
        type: String,
        required: false,
        default: 'myflow-basic-button whitespace-nowrap'
    },
    confirmText: {
        type: String,
        required: false,
        default: null
    },
    confirmButtonLabel: {
        type: String,
        required: false,
        default: 'Ja'
    },
    abortButtonLabel: {
        type: String,
        required: false,
        default: _mft('shared:modal.defaultCancel')
    }
})

const armed = ref(false)
const close = inject('close')

const disarm = () => {
    setTimeout(function(){
        armed.value = false
    }, 10)
}

const confirm = () => {
    emit('confirmed')
    setTimeout(function(){
        armed.value = false
        close()
    }, 10)
}

</script>
<template>
    <button-more-options-option
        :styleType="props.confirmType"
        @click="armed = true"
        :state="props.state"
        :close-on-click="false"
    >
        <slot></slot>

        <div
            v-if="armed"
            class="pt-4"
        >
            <p v-if="props.confirmText" class="text-xs" v-html="props.confirmText"></p>
            <div class="flex flex-col items-stretch space-y-2">
                <button-base
                    :styleType="props.confirmType"
                    size="sm"
                    class="whitespace-nowrap justify-center"
                    @click="confirm"
                >
                    {{ props.confirmButtonLabel }}
                </button-base>
                <button-secondary
                    size="sm"
                    class="whitespace-nowrap justify-center"
                    @click="disarm"
                >
                    {{ props.abortButtonLabel }}
                </button-secondary>
            </div>
        </div>

    </button-more-options-option>
</template>
