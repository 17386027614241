<script setup>
import { onMounted, ref, computed, provide } from 'vue';
import axios from 'axios';

const memberships = ref([]);
const posts = ref([]);
const yesterdaysPost = ref(null);

const showChannel = ref(false);
const activeChannel = ref(null);

const commentablePost = ref(null);

const postedToday = ref(false);

const accountability = ref({
    question1: null,
    question2: null,
    question3: null,
    yesterdaysQuestion1: null,
    yesterdaysQuestion2: null,
    yesterdaysQuestion3: null,
});

provide('memberships', {memberships, goToChannel} );

provide('accountability-data', {accountability, yesterdaysPost, activeChannel} );

provide('commentablePost', commentablePost);
provide('activeChannel', activeChannel);

provide('getChannelData', getChannelData);

function goToChannel(channel) {
    activeChannel.value = channel;
    showChannel.value = true;
}

async function getMemberships() {
    const response = await axios.get(route('community.member.getCommunities'));
    memberships.value = response.data;
}

async function getChannelData(channelId) {
    const response = await axios.get(route('community.member.getChannelData', channelId));
    posts.value = response.data.posts;
    yesterdaysPost.value = response.data.yesterdays_post;
    postedToday.value = response.data.posted_today;
}

onMounted(() => {
    getMemberships();
});

</script>

<template>
    <div>
        <p
            v-if="memberships.length === 0"
            class="text-gray-400 my-4 text-xs"
        >
            {{ _mft('community:communities.noMembershipsYet') }}
        </p>
        <community-memberships-list
            class="space-y-4 lg:space-y-8"
            @select-channel="goToChannel"
            @reload="getMemberships"
        ></community-memberships-list>
    </div>

    <slideout-with-slots
        max-width-class="max-w-[90vw] md:max-w-screen"
        v-if="showChannel"
        @close="showChannel = false"
    >
        <template #title>
            <h2>{{ activeChannel.name }}</h2>
        </template>
        <template #body>
            <community-channel
                :channel-id="activeChannel.id"
                :channel="activeChannel"
            ></community-channel>
        </template>
    </slideout-with-slots>
</template>
