<template>
  <notification
    :show="showNotification"
    @close="closeNotification()"
  >
    <template
      v-slot:header
    >
      {{ notification.message }}
    </template>
  </notification>
  <card-form-content>
    <template v-slot:header><h2>{{ _mft('crm:leadStakeholderCard.domainNamePlural') }}</h2></template>
    <template v-slot:form>
    <form @submit.prevent="createStakeholder">
      <input-text
        :label="_mft('crm:leadStakeholderCard.name')"
        required="required"
        :content="creatingStakeholder.name"
        name="name"
        v-model:content="creatingStakeholder.name"
      />
      <input-text
        :label="_mft('crm:leadStakeholderCard.title')"
        required="required"
        :content="creatingStakeholder.title"
        name="title"
        v-model:content="creatingStakeholder.title"
      />
      <input-text
        :label="_mft('crm:leadStakeholderCard.email')"
        :content="creatingStakeholder.email"
        name="email"
        v-model:content="creatingStakeholder.email"
      />
      <input-text
        :label="_mft('crm:leadStakeholderCard.phone')"
        :content="creatingStakeholder.phone"
        name="phone"
        v-model:content="creatingStakeholder.phone"
      />
      <input-text
        label="LinkedIn"
        :content="creatingStakeholder.linkedin"
        name="linkedin"
        v-model:content="creatingStakeholder.linkedin"
      />

      <div class="flex justify-end mt-2">
        <button-success type="submit">
            {{ _mft('crm:leadStakeholderCard.add') }}
        </button-success>
      </div>
    </form>
    </template>
    <template v-slot:content>
      <p class=""
        v-if="localStakeholders.length === 0"
      >
          {{ _mft('crm:leadStakeholderCard.noStakeholdersAdded') }}
      </p>
      <div
        v-else
      >
        <ul
          role="list"
          class="grid-cols-2 gap-4 md:grid"
        >
          <li class="col-span-1 mb-2 bg-white divide-y divide-gray-200 rounded-lg shadow"
            v-for="(stakeholder, i) in localStakeholders"
            v-bind:key="i"
            v-on:click="openInSlideout(stakeholder)"
          >
            <div class="flex items-center justify-between w-full p-6 space-x-6">
              <div class="flex-1 truncate">
                <div class="flex items-center space-x-3">
                  <h3 class="text-sm text-gray-900 truncate font-large">{{ stakeholder.name}}</h3>
                  <span v-if="stakeholder.registered" class="myflow-success-pill">
                      {{ _mft('crm:leadStakeholderCard.registered') }}
                  </span>
                </div>
                <p class="mt-1 text-sm text-gray-500 truncate">{{stakeholder.title}}</p>
                <p class="mt-3 text-sm text-gray-900 truncate">{{stakeholder.email}}</p>
                <p class="mt-1 text-sm text-gray-900 truncate">{{stakeholder.phone}}</p>
                <p class="mt-1 text-sm text-gray-900 truncate">{{stakeholder.linkedin}}</p>

              </div>
              <img class="flex-shrink-0 w-10 h-10 bg-gray-300 rounded-full" src="https://myflow.fra1.digitaloceanspaces.com/generic-profile-icon-23.jpeg" alt="">
            </div>
          </li>
        </ul>
      </div>

      <basic-slideout
        v-model:open="open"
      >
        <template v-slot:header>
          <h1>
              {{ _mft('crm:leadStakeholderCard.manageStakeholder') }}
          </h1>
        </template>

        <template v-slot:body>
          <input-text
            :label="_mft('crm:leadStakeholderCard.name')"
            required="required"
            :content="currentlyEditingStakeholder.name"
            name="name"
            v-model:content="currentlyEditingStakeholder.name"
          />
          <input-text
            :label="_mft('crm:leadStakeholderCard.title')"
            required="required"
            :content="currentlyEditingStakeholder.title"
            name="title"
            v-model:content="currentlyEditingStakeholder.title"
          />
          <input-text
            :label="_mft('crm:leadStakeholderCard.email')"
            :content="currentlyEditingStakeholder.email"
            name="email"
            v-model:content="currentlyEditingStakeholder.email"
          />
          <input-text
            :label="_mft('crm:leadStakeholderCard.phone')"
            :content="currentlyEditingStakeholder.phone"
            name="phone"
            v-model:content="currentlyEditingStakeholder.phone"
          />
          <input-text
            label="LinkedIn"
            :content="currentlyEditingStakeholder.linkedin"
            name="linkedin"
            v-model:content="currentlyEditingStakeholder.linkedin"
          />

          <div
            class="flex justify-between"
          >
            <div class="relative">
              <button-danger
                :disabled="working"
                v-on:click="initDeleteStakeholder"
                class="float-right"
              >
                  {{ _mft('shared:action.delete') }}
              </button-danger>
              <div
                class="absolute left-0 z-10 p-1 mr-2 text-xs bg-white rounded-md shadow-md top-full"
                v-if="pendingDelete"
              >
                <p class="p-2">{{ _mft('shared:confirm.areYouSure') }}</p>
                <div class="flex justify-between space-x-2">
                  <span
                    class="px-2 py-1 text-white bg-gray-600 rounded-md cursor-pointer hover:bg-gray-800"
                    v-on:click="abortDelete()"
                  >{{ _mft('shared:confirm.cancel') }}</span>
                  <span
                    class="px-2 py-1 text-white bg-red-600 rounded-md cursor-pointer hover:bg-red-800"
                    v-on:click="confirmDelete()"
                  >{{ _mft('shared:confirm.positive') }}</span>
                </div>
              </div>
            </div>

            <input-button
              :label="_mft('shared:action.update')"
              type="success"
              :busy="working"
              v-on:click="updateStakeholder"
              class="float-right"
            />

          </div>
        </template>

      </basic-slideout>

    </template>

  </card-form-content>
</template>
<script>
export default {
  props: {
    alignment: String,
    lead: Object,
    stakeholders: Object
  },
  data: function(){
    return {
      creatingStakeholder: {
        name: '',
        title: '',
        email: '',
        phone: '',
        linkedin: ''
      },
      currentlyEditingStakeholder: {
        id: '',
        name: '',
        title: '',
        email: '',
        phone: '',
        linkedin: ''
      },
      localStakeholders: {},
      notification: {
        type: '',
        message: ''
      },
      open: false,
      pendingDelete: false,
      working: false
    }
  },
  methods: {
    openInSlideout(stakeholder){
      this.currentlyEditingStakeholder = stakeholder;
      this.open = true;
    },
    async createStakeholder(){
      const res = await axios.post( route('crm.leads.stakeholder.create', this.lead.id) , this.creatingStakeholder);

      this.notification = res.data.notification;
      this.localStakeholders = res.data.stakeholders;
      this.resetCreateStakeholderForm();
    },
    initDeleteStakeholder(){
      this.pendingDelete = true
    },
    async confirmDelete(){
      this.pendingDelete = false;
      const res = await axios.delete(route('crm.stakeholder.delete', this.currentlyEditingStakeholder.id))

      this.notification = res.data.notification;
      this.localStakeholders = res.data.stakeholders;
      this.closeSlideout();
    },
    abortDelete(){
      this.pendingDelete = false;
    },
    async updateStakeholder(){
      const res = await axios.put( route('crm.leads.stakeholder.update', this.currentlyEditingStakeholder.id) , this.currentlyEditingStakeholder);

      this.notification = res.data.notification;
      this.closeSlideout();
    },
    resetCreateStakeholderForm(){
      this.creatingStakeholder = {
        name: '',
        title: '',
        email: '',
        phone: '',
        linkedin: ''
      };
    },
    closeSlideout(){
      this.open = false;
    },
    closeNotification(){
      this.notification.message = ''
    }
  },
  mounted: function(){
    this.localStakeholders = this.stakeholders;
  },
  computed: {
    hasHeaderSlot () {
      return !!this.$slots['header-left'] && !!this.$slots['header-right'];
    },
    hasImageSlot () {
      return !!this.$slots.image
    },
    hasBodySlot () {
      return !!this.$slots.body
    },
    alignmentClass (){
      return this.alignment === 'center' ? 'text-center' : ''
    },
    showNotification (){
      return this.notification.message.length > 0
    }
  },
  setup() {

  },
}
</script>
