<script setup>
    const props = defineProps({
        record: {
            type: Object,
            required: true
        },
        isLastRecord: {
            type: Boolean,
            required: true
        }
    });
</script>

<template>
    <div class="w-full">
        <div class="flex justify-between items-center gap-3">
            <div class="flex justify-start items-baseline gap-3 flex-wrap">
                <code class="border rounded px-2 py-1 font-mono text-xs lg:text-sm whitespace-nowrap select-all">{{props.record.name}}</code>
                <code class="border rounded px-2 py-1 font-mono text-xs lg:text-sm whitespace-nowrap select-all">{{props.record.type}}</code>
                <code v-if="props.record.value" class="border rounded px-2 py-1 font-mono text-xs lg:text-sm select-all">{{props.record.value}}</code>
                <div>
                    <div v-if="props.record.instruction || props.record.examples">
                        <div
                            v-if="props.record.instruction"
                            class="py-1 italic mb-1 mt-0 text-xs"
                            v-html="_mft(props.record.instruction)"
                        ></div>
                        <div v-if=" props.record.examples" class="flex flex-wrap gap-2">
                            <code v-for="(ex, exIdx) in props.record.examples" :key="exIdx" class="border rounded px-2 py-1 whitespace-nowrap font-mono text-xs lg:text-sm select-all">{{ex}}</code>
                        </div>
                    </div>

                    <p
                        v-if="props.record.footnote"
                        class="text-xs text-gray-500 mt-3"
                        v-html="_mft(props.record.footnote)"
                    ></p>
                </div>
            </div>

            <i v-if="props.record.validation_result" class="fa-regular fa-square-check text-myflowGreen"></i>
            <i v-else class="fa-regular fa-square text-gray-300"></i>
        </div>


        <template v-if="false">
            <!-- disabled as it's of limited value and somewhat confusing that it's only available for mandrill records -->
            <div v-if="props.record.error" class="text-myflowRed mt-3 text-center">
                <i class="fa-regular fa-warning mr-1"></i>
                {{props.record.error}}
            </div>
        </template>

        <hr v-if="props.record.footnote && !props.isLastRecord" class="my-5">
    </div>
</template>
