<template>
  <div
    class="shadow-xl bg-white mb-10 rounded-card overflow-hidden md:grid grid-cols-3 gap-8"
  >
    <div class="p-4 md:p-6 lg:p-8 pt-3 md:pt-5 lg:pt-6 bg-gray-50 col-span-1">
       <div
          v-if="hasHeaderSlot"
        >
        <span class="font-body mb-4 inline-block text-myflowGradient ">
          <slot name="header"></slot>
        </span>
      </div>
      <slot name="form"></slot>
    </div>
    <div class="p-4 pt-8 md:p-6 md:pl-0 lg:p-8 lg:pl-0 md:pt-5 lg:pt-6 col-span-2">
      <slot name="content"></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
  },
  computed: {
    hasHeaderSlot () {
      return !!this.$slots.header
    },
  },
  setup() {

  },
}
</script>
