<template>
        <section style="box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;" class="text-black bg-white p-24 m-8 flex align-start justify-center flex-col text-center">
                <h2 class="mb-8">{{location}}</h2>
                <p class="mb-8">{{description}}</p>
                <h3 class="mb-8">{{price}}</h3>
        </section>
</template>

<script>
export default {
    props: {
        location: String,
        description: String,
        price: String,
        btnId: Number,
    },
    data() {
        return {
            prices: this.pricesInfo
        };
    },
};


</script>

<style scoped>

.collapse-btn{
    background-color: #38c172;
    padding: 0.8rem 1.5rem;
    border-radius: 25px;
    height: fit-content;
    width: fit-content;
}

</style>
