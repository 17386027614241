<script setup>

import {onMounted, ref, watch} from "vue";

const modalOpen = ref(false)
const transitioned = ref(false)

const close = () => {
    transitioned.value = false
    setTimeout(function(){
        modalOpen.value = false
    }, 300)
}

const toggleModal = () => {
    modalOpen.value = !modalOpen.value
}

watch(modalOpen, async (value) => {
    if (value) {
        setTimeout(function(){
            transitioned.value = true
        },1)
    } else {
        transitioned.value = false
    }
})

onMounted(() => {
    document.addEventListener('keydown', function(event) {
        if (event.key === 'Escape') {
            close()
        }
    });
})

</script>
<template>
    <div>
        <Teleport to="#teleport-target-toolbar">
            <button-secondary
                @click="toggleModal"
                size="sm"
            >
                <i class="fa-light fa-clock text-xl text-wlPrimary">

                </i>
            </button-secondary>
        </Teleport>
        <div
            v-if="modalOpen"
            class="fixed inset-0 flex justify-end items-end z-modal-backdrop pr-5 pl-8 md:pr-5 pb-9"
        >
            <div
                class="inset-0 fixed bg-black z-modal-backdrop transition-opacity"
                :class="transitioned ? 'opacity-20' : 'opacity-0'"
                @click="close"
            ></div>
            <card-basic
                class="transition-all relative z-modal w-full min-w-72 max-w-sm origin-bottom-right"
                :class="transitioned ? '' : 'opacity-0 scale-50'"
            >
                <div class="pt-2">
                    <time-tracking-form
                        @close="close"
                    ></time-tracking-form>
                </div>
            </card-basic>
        </div>
    </div>
</template>
