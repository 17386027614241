<script setup>
import { computed } from 'vue'

const props = defineProps({
    uuid: {
        type: String,
        require: true
    },
    content: {
        type: Object,
        required: true
    },
});

const fieldUuid = computed(() => props.content.uuid);
const title = computed(() => props.content.title);
const required = computed(() => props.content.required);

const options = computed(() => {
    return props.content.alternatives.map((alternative) => {
        return {
            label: alternative.title,
            value: alternative.title
        }
    });
});

</script>

<template>
    <div>
        <input
            type="hidden"
            :name="`data[${uuid}][block_uuid]`"
            :value="fieldUuid"
        />
        <input-select
            :name="`data[${uuid}][value]`"
            :label="title"
            :options="options"
            :required="required"
        />
    </div>
</template>
