<script setup>

import {computed, ref, watch} from "vue";
import { PlusIcon, XIcon } from "@heroicons/vue/outline"
import {debounce} from "lodash";

const emit = defineEmits(['addFieldOption', 'removeFieldOption'])

const props = defineProps({
    field: {
        type: Object,
        required: true,
    },
    searchUrl: {
        type: String,
        required: true
    },
    parameters: {
        type: Object,
        required: true,
    },
    placeholder: {
        type: String,
        required: false,
        default: '',
    }
})

const filter = ref('')
const showOptions = ref(false)
const options = ref([])
const searchPerformed = ref(false)

const loadParameters = async () => {
    searchPerformed.value = false
    const res = await axios.get(props.searchUrl, {
        params: {
            filter: filter.value
        }
    })
    options.value = res.data.results
    searchPerformed.value = true
}

const addFieldOption = (field, option) => {
    emit('addFieldOption', field, option)
}

const removeFieldOption = (field, option) => {
    emit('removeFieldOption', field, option)
}

const clearFilter = () => {
    filter.value = ''
    showOptions.value = false
}

const selectedValues = computed(() => {
    return props.parameters[props.field.field].map(field => field.value)
})

watch(() => filter, debounce((newVal) => {
    if (filter.value.length > 2) {
        loadParameters()
    } else {
        searchPerformed.value = false
    }
}, 1200), {deep: true})

</script>

<template>
    <div>
        <div
            v-if="showOptions"
            class="fixed inset-0 z-10"
            @click="showOptions = false"
        ></div>
        <div class="relative">
            <input-text
                :placeholder="props.placeholder"
                @focus="showOptions = true"
                v-model:content="filter"
            ></input-text>
            <div class="relative">
                <ul
                    v-if="!showOptions"
                    class="-mt-2 flex flex-wrap items-center px-0.5"
                >
                    <li
                        v-for="(option, o) in parameters[props.field.field]"
                        :key="o"
                        class="mr-1 mb-1 flex items-center justify-between bg-white px-2 py-1 text-xs shadow-md space-x-1 rounded-wl"
                    >
                        <div>{{ option.label }}</div>
                        <button
                            @click="removeFieldOption(field, option)"
                        >
                            <XIcon class="h-4 w-4" />
                        </button>
                    </li>
                </ul>
                <ul
                    v-if="showOptions"
                    class="absolute top-0 right-0 left-0 -mt-2 max-h-96 overflow-y-scroll bg-white p-3 shadow-md space-y-2 rounded-wl z-modal"
                >
                    <li
                        v-for="(option, o) in options"
                        :key="o"
                    >
                        <div
                            v-if="(showOptions && option.label.toLowerCase().includes(filter.toLowerCase())) || selectedValues.includes(option.value)"
                            class="flex items-center justify-between text-xs space-x-2"
                            :class="selectedValues.includes(option.value) ? 'text-black' : 'text-gray-500'"
                        >
                            <div>{{ option.label }}</div>
                            <button
                                v-if="! selectedValues.includes(option.value)"
                                @click="addFieldOption(field, option)"
                            >
                                <PlusIcon class="h-4 w-4" />
                            </button>
                            <button
                                v-if="selectedValues.includes(option.value)"
                                @click="removeFieldOption(field, option)"
                            >
                                <XIcon class="h-4 w-4" />
                            </button>
                        </div>
                    </li>
                    <li
                        v-if="filter.length > 2 && options.length === 0"
                        class="flex justify-center text-xs text-gray-500"
                    >
                        <mf-spinner-medium class="h-6 w-12"></mf-spinner-medium>
                    </li>
                    <li
                        v-if="searchPerformed > 0 && options.length === 0"
                        class="text-xs text-gray-500"
                    >
                        Your search returned no results
                    </li>
                    <li
                        v-if="filter.length === 0 && options.length === 0"
                        class="text-xs text-gray-500"
                    >
                        Start typing to search
                    </li>
                    <li
                        v-if="filter.length > 0 && filter.length < 3 && options.length === 0"
                        class="text-xs text-gray-500"
                    >
                        {{ 3 - filter.length }} more characters required
                    </li>
                </ul>
                <span
                    class="absolute bottom-full z-30 mb-6 transform right-1.5 translate-y-0.5"
                >
                    <button-primary
                        v-if="showOptions"
                        size="sm"
                        @click="clearFilter"
                    >
                        Done
                    </button-primary>
                </span>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
