<script setup>
    import { useNotificationStore } from '@/stores/NotificationStore.js'
    const notificationStore = useNotificationStore()

    const props = defineProps({
        title: {
            type: String,
            required: false,
            default: null
        },
        message: {
            type: String,
            required: true
        },
        type: {
            type: String,
            required: false,
            default: 'info',
        },
    })

    notificationStore.addNotification({
        type: props.type,
        title: props.title,
        message: props.message,
        dismissAfter: 5000,
    })
</script>

<template>

</template>

<style scoped>

</style>
