<script setup>

import {computed, ref, watch} from "vue";
import { useNotificationStore } from '@/stores/NotificationStore.js'

const notificationStore = useNotificationStore();

const props = defineProps({
    contentId: {
        type: Number,
        required: true
    },
    category: {
        type: String,
        required: true
    }
});

const modalOpen = ref(false);
const templateName = ref('');
const templateDescription = ref('');
const savingState = ref('');

const saveTemplate = async () => {
    if (savingState.value === 'waiting' || ! inputValidates.value) {
        return;
    }

    savingState.value = 'waiting';
    const res = await axios.post(route('templates.storeNewTemplateFromExisting', props.contentId), {
        name: templateName.value,
        description: templateDescription.value,
        category: props.category,
    })

    if (res.data.success) {
        savingState.value = 'success';
        notificationStore.addNotification({
            type: 'success',
            message: res.data.message,
            actions: [{url: res.data.edit_url, label: 'Edit template'}],
            dismissAfter: null,
        });
        modalOpen.value = false;
    } else {
        savingState.value = 'error';
        notificationStore.addNotification({
            type: 'error',
            message: _mft('error:error.generalTryAgain'),
            dismissAfter: null,
        });
    }
}

const inputValidates = computed(() => {
    return templateName.value.length > 0
        && templateDescription.value.length > 0;
});

watch(() => modalOpen.value, (value) => {
    if (value === false) {
        templateName.value = '';
        templateDescription.value = '';
    }
});

</script>
<template>
    <div>
        <button-transparent
            size="line"
            class="text-xs"
            @click="modalOpen = !modalOpen"
        >{{ _mft('pagebuilderTemplate:saveAsTemplate') }}</button-transparent>

        <modal-basic
            :open="modalOpen"
            @closed="modalOpen = false"
            :showClose="true"
        >
            <template #body>
                <input-text
                    :label="_mft('pagebuilderTemplate:templateName')"
                    :required="true"
                    v-model:content="templateName"
                />
                <input-textarea
                    :label="_mft('pagebuilderTemplate:templateDescription')"
                    :required="true"
                    v-model:content="templateDescription"
                />
                <div class="flex justify-end">
                    <button-base
                        :style-type="inputValidates ? 'success' : 'disabled'"
                        :disabled="!inputValidates"
                        :state="savingState"
                        @click="saveTemplate"
                    >{{ _mft('shared:action.save') }}</button-base>
                </div>
            </template>
        </modal-basic>
    </div>
</template>
