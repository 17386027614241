<script setup>
    import { computed, onMounted, ref } from 'vue'

    const articles = ref([]);
    const draftCount = ref(0);
    const publishedCount = ref(0);
    const currentStatus = ref('published')
    const loading = ref(true);

    const statusOptions = computed(() => {
        return [
            {
                label: `Publicerade (${publishedCount.value})`,
                name: 'published',
            },
            {
                label: `Utkast (${draftCount.value})`,
                name: 'draft',
            }
        ]
    });

    const loadArticles = () => {
        loading.value = true;
        articles.value = [];
        axios.get(route('support.admin.article.index', currentStatus.value))
            .then(response => {
                draftCount.value = response.data.draft_count;
                publishedCount.value = response.data.published_count;
                articles.value = response.data.articles;
                loading.value = false;
            })
            .catch(error => {
                console.log(error);
            });
    }

    const setStatus = (status) => {
        currentStatus.value = status.name;
        loadArticles();
    }

    onMounted(() => {
        loadArticles();
    });

</script>
<template>
    <div class="w-full">
        <div class="flex items-center justify-between mb-4">
            <h1>Supportartiklar</h1>
            <a
                class="myflow-basic-button myflow-basic-button--primary"
                :href="route('support.admin.article.create')"
            >Lägg till ny artikel</a>
        </div>
        <card-basic
            class="flex flex-col justify-between"
        >
            <template v-slot:body>
                <div class="flex justify-center">
                    <button-bar
                        :options="statusOptions"
                        @select="setStatus"
                    />
                </div>
                <ul v-if="!loading">
                    <li
                        v-for="article in articles"
                        :key="article.id"
                        class="flex justify-between py-1 mb-5 space-x-8 group"
                    >
                        <div>
                            <h3>{{ article.title }}</h3>
                            <input
                                class="w-full max-w-full truncate bg-transparent focus:outline-none"
                                type="text"
                                :value="route('support.article.show', article.id)"
                                onfocus="event.target.select()"
                            >
                            <link-field
                                class="flex mt-2 ml-1 space-x-4 md:ml-0 md:space-x-4"
                                :url="route('support.article.show', article.id)"
                            ></link-field>

                        </div>
                        <a :href="route('support.admin.article.edit', article.id)" class="text-myflowPurple">Redigera</a>

                    </li>
                </ul>
                <div v-else class="text-center">
                    <mf-spinner-medium
                    class="w-24 h-12"
                    />
                </div>
            </template>
        </card-basic>
    </div>
</template>
