<script setup>

const props = defineProps({
    userAccountsEnabled: {
        type: Boolean,
        required: true,
    }
})

const historyAvailable = window.history.length > 1;

</script>
<template>
    <div class="flex flex-col justify-center items-center min-h-screen bg-gradient-to-b from-gray-50 to-gray-200">
        <div class="flex flex-col justify-end items-center">
            <h1 class="text-wlPrimary relative block mb-0" style="line-height: 1em; font-size: clamp(54px, 10vw, 180px);">
                404
            </h1>
            <span class="flex items-center space-x-2 text-lg grayscale">
                <span>content not found</span>
                <span class="text-3xl"> 😵‍💫 </span>
            </span>
        </div>
        <div class="flex justify-center space-x-4 mt-4">
            <button-secondary
                v-if="historyAvailable"
                onclick="history.back()"
            >Go back</button-secondary>

            <a :href="route('home')">
                <button-secondary
                    v-if="historyAvailable"
                    onclick="history.back()"
                >Home</button-secondary>
            </a>

            <a :href="route('login')">
                <button-secondary
                    v-if="userAccountsEnabled"
                    onclick="history.back()"
                >Login</button-secondary>
            </a>
        </div>
    </div>
</template>
