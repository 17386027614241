<template>
  <div>
    <div
      class="relative w-2/3 mx-auto mb-4 bg-center bg-cover rounded-lg"
      style="padding-bottom: 66.67%"
      :style="trailerBackground"
    >
      <div
        class="absolute inset-0 flex items-center justify-center"
      >
        <Playbutton
          class="w-1/3 h-auto text-white transition-all opacity-50 cursor-pointer hover:scale-105 hover:opacity-90"
          v-on:click="openTrailer()"
        />
      </div>
    </div>
    <trailer-video
      v-if="trailerVideoUrl"
      :trailerVideoUrl="trailerVideoUrl"
      @close="closeTrailer()"
    />
  </div>
</template>
<script>
import Playbutton from '../icons/playbutton.vue'

export default {
  components: {
    Playbutton
  },
  props: {
    thumbnail: {
      type: String,
      default: ''
    },
    coach_id: {
      type: Number,
      default: null
    }
  },
  data: function(){
    return {
      trailerVideoUrl: null
    }
  },
  methods: {
    closeTrailer() {
      this.trailerVideoUrl = null
    },
    async openTrailer() {
      this.trailerVideoUrl = route('home', this.coach_id);
    }
  },
  computed: {
    trailerBackground: function(){
      return 'background-image: url(' + this.thumbnail + ');'
    }
  }
}
</script>
