<template>
    <div>
        <input type="hidden" name="preview_on_save" :value="previewOnSave">
        <div>
            <span class="previewable-screens-only">
                <button
                    class="myflow-basic-button--secondary"
                    :disabled="working"
                    :class="working ? 'opacity-50 cursor-wait' : ''"
                    @click="submitWithAjaxAndPreview"
                    v-if="previewForm && formAction"
                >Spara och förhandsgranska</button>
                <button v-else class="myflow-basic-button--secondary" v-on:click="saveAndPreviewInline">Spara och förhandsgranska</button>

                <div v-if="previewing" class="fixed inset-0 z-10 bg-gradient-to-t from-gray-700 to-transparent bg-opacity-40" v-on:click.prevent="previewing = false"></div>

                <div
                    class="fixed left-0 right-0 z-20 justify-center hidden w-full px-4 sm:flex"
                    :class="previewing ? 'bottom-0' : '-bottom-screen'"
                >
                    <div
                        v-if="currentDevice === 'desktop'"
                        class="z-20 w-full max-w-5xl p-2 mb-8 transition-all origin-bottom bg-black border-4 border-black device-desktop lg:border-8 xl:block aspect-desktop preview-desktop rounded-t-3xl"
                        :style="desktopPreviewStyle"
                        :class="desktopPreviewClass"
                    >
                        <div class="w-full h-full p-2 overflow-hidden bg-black">
                            <div style="width: 150%; height: 150%;">
                                <iframe style="transform: scale(0.675) translate(-24.75%, -25%);" :src="previewUrl" class="w-full h-full bg-gray-700 rounded-t-md"></iframe>
                            </div>
                        </div>
                    </div>

                    <div
                        v-if="currentDevice === 'mobile'"
                        class="z-20 w-full max-w-sm mb-12 transition-all origin-bottom scale-75 border-4 border-black device-mobile aspect-mobile bottom-8 rounded-3xl"
                        :style="mobilePreviewStyle"
                        :class="mobilePreviewClass"
                    >
                        <div class="relative w-full h-full p-2 overflow-hidden bg-black rounded-xl">
                            <iframe :src="url" class="w-full h-full bg-gray-700 rounded-xl"></iframe>
                        </div>
                    </div>
                </div>

                <div
                    class="fixed bottom-0 left-0 right-0 z-20 justify-center hidden space-x-4 transition-all sm:flex"
                    :class="previewing ? 'bottom-4' : 'top-full'"
                >
                    <div class="flex bg-white border-2 border-transparent rounded-full border-myflowPurple">
                        <button
                            v-on:click.prevent="setDevice('desktop')"
                            class="px-3 py-1 rounded-l-full"
                            :class="currentDevice === 'desktop' ? 'bg-myflowPurple text-white' : 'bg-white text-myflowPurple'"
                        >Dator</button>
                        <button
                            v-on:click.prevent="setDevice('mobile')"
                            class="px-3 py-1 rounded-r-full"
                            :class="currentDevice === 'mobile' ? 'bg-myflowPurple text-white' : 'bg-white text-myflowPurple'"
                        >Telefon</button>
                    </div>
                    <button class="myflow-basic-button--secondary" v-on:click.prevent="closePreview">Stäng förhandsgranskning</button>
                </div>
            </span>
            <span class="hidden">
                <!-- <a target="_blank" :href="url" class="myflow-basic-button--secondary">Förhandsgranska</a> -->
                <button class="myflow-basic-button--secondary" v-on:click="saveAndPreviewPopout">Spara och förhandsgranska</button>
            </span>
        </div>
    </div>
</template>
<script>

    export default {

        props: {
            url: String,
            launchPreview: {
                type: Boolean,
                default: false
            },
            previewForm: {
                type: Object,
                required: false
            },
            formAction: {
                type: String,
                required: false
            }
        },

        data(){
            return {
                previewing: false,
                currentDevice: 'desktop',
                previewOnSave: null,
                previewUrl: null,
                desktopPreviewStyle: {transform: 'scale(1)'},
                mobilePreviewStyle: {transform: 'scale(1)'},
                working: false
            }
        },

        methods: {
            async submitWithAjaxAndPreview(e) {
                this.working = true;
                e.preventDefault();
                const formData = new FormData(this.previewForm)

                await axios.post(this.formAction, formData)
                window.open(this.url, '_blank');

                this.working = false;
                return false;
            },
            closePreview() {
                this.previewing = !this.previewing;
                this.previewUrl = null;
                history.replaceState(null, null, location.pathname + location.hash);
            },
            saveAndPreviewInline(e) {
                this.previewOnSave = 'inline'
            },
            setDevice(device) {
                this.currentDevice = device;
            },
            onResize() {
                if( window.innerHeight < 840 ){
                    this.mobilePreviewStyle = {transform: 'scale('+ window.innerHeight * 0.001 +')'}
                } else {
                    this.mobilePreviewStyle = {transform: 'scale(1)'}
                }

                if( window.innerHeight < 720 ){
                    this.desktopPreviewStyle = {transform: 'scale('+ window.innerHeight * 0.0012 +')'}

                } else {
                    this.desktopPreviewStyle = {transform: 'scale(1)'}
                }
            }
        },

        mounted() {
            this.onResize();
            if( window.location.search.includes('preview') ){
                this.$nextTick(() => {
                  window.addEventListener('resize', this.onResize);
                })
                this.previewUrl = this.url;
                this.previewing = true;
            }
        },

        beforeDestroy() {
            window.removeEventListener('resize', this.onResize);
        },

        computed: {
            desktopPreviewClass() {
                if( this.previewing ) {
                    return 'bottom-8';
                } else {
                    return '-bottom-screen'
                }
            },
            mobilePreviewClass() {
                if( this.previewing ) {
                    return 'bottom-8 xl:-bottom-8';
                } else {
                    return '-bottom-screen'
                }
            },
        }
    }

</script>
<style>

.previewable-screens-only {
    display: none;
}
@media screen and (min-width: 640px) and (min-height: 320px) {
    .previewable-screens-only {
        display: block;
    }
    .non-previewable-screens-only {
        display: none;
    }
}

.-bottom-screen {
    bottom: -200vh;
}

.preview-desktop:after {
    position: absolute;
    content: '';
    left: -2.5rem;
    right: -2.5rem;
    top: calc(100% + 0.5rem);
    width: calc(100% + 5rem);
    height: 3rem;
    border-bottom: 4rem solid #939393;
	border-left: 2rem solid transparent;
	border-right: 2rem solid transparent;
}
@media screen and (max-width: 1024px) {
    .preview-desktop:after {
        left: -2.25rem;
        right: -2.25rem;
        top: 100%;
        width: auto;
    }
}

.aspect-desktop {
    aspect-ratio: 16 / 9;
}

.aspect-mobile {
    aspect-ratio: 9 / 17;
}

@media screen and (max-width: 1280px) and (max-height: 760px) {
    .device-desktop, .device-mobile {

    }
}
</style>
