<template>
  <div>
    <notification
      :show="showNotification"
      @close="closeNotification()"
    >
      <template
        v-slot:header
      >
        {{ notification.message }}
      </template>
    </notification>
    <card-form-content>
      <template v-slot:header><h2>Rekommendationer</h2></template>
      <template v-slot:form>
        <div
          v-if="lead.inreferences.length > 0"
        >
          <ul class="space-y-4">
            <li
              v-for="(reference, i) in lead.inreferences"
              v-bind:key="i"
              class="w-full transition bg-white rounded-lg shadow hover:shadow-md"
            >
              <a class="block p-6" :href="route('crm.leads.view', reference.id)">
                <h3 class="text-sm text-gray-900 truncate font-large">{{ reference.name }} </h3>
                {{ reference.company }}
              </a>
            </li>
          </ul>
        </div>

      </template>
      <template v-slot:content>
        <div class="flex justify-end mb-2">
          <input-button
            type="primary"
            label="Skapa kontakt från rekommendation"
            v-on:click="openSlideout"
          />
        </div>
        <p class=""
          v-if="localOutgoingReferences.length === 0"
        >
          Inga rekommendationer från detta lead
        </p>
        <div
          v-else
        >
          <ul
            role="list"
            class="grid-cols-2 gap-4 md:grid"
          >
            <li
              class="col-span-1 mb-2 divide-y divide-gray-200"
              v-for="(reference, i) in localOutgoingReferences"
              v-bind:key="i"
            >
              <a
                class="flex items-center justify-between w-full p-6 transition bg-white rounded-lg shadow hover:shadow-md"
                :href="route('crm.leads.view', reference.id)"
              >
                <div class="flex-1 truncate">
                  <div class="flex items-center">
                    <h3 class="text-sm text-gray-900 truncate font-large">{{ reference.name}}</h3>
                  </div>
                  <p v-if="reference.company !== ''" class="mt-3 text-sm text-gray-900">{{reference.company}}</p>

                </div>
              </a>
            </li>
          </ul>
        </div>

        <basic-slideout
          v-model:open="open"
        >
          <template v-slot:header>
            <h1>Skapa lead från rekommendation</h1>
          </template>

          <template v-slot:body>
            <form-lead-create
              :reference="lead"
            />

          </template>

        </basic-slideout>

      </template>

    </card-form-content>
  </div>
</template>
<script>
import {
  PencilAltIcon
} from '@heroicons/vue/solid'

export default {
  components: {
    PencilAltIcon
  },
  props: {
    alignment: String,
    lead: Object,
    references: Object
  },
  data: function(){
    return {
      creatingNote: {
        note: '',
        attachment: {}
      },
      currentlyEditingNote: {
        id: '',
        note: ''
      },
      localOutgoingReferences: {},
      notification: {
        type: '',
        message: ''
      },
      open: false,
      working: false
    }
  },
  methods: {
    openSlideout(note){
      this.open = true;
    },
    async createNote(){
      this.working = true;
      var formData = new FormData();
      formData.append("attachment", this.creatingNote.attachment);
      formData.append("note", this.creatingNote.note);

      const res = await axios.post( route('crm.leads.note.create', this.lead.id) , formData);

      this.notification = res.data.notification;
      this.localOutgoingReferences = res.data.references;
      this.resetCreateNoteForm();
      this.working = false;
    },
    async updateNote(){
      const res = await axios.put( route('crm.leads.note.update', this.currentlyEditingNote.id) , this.currentlyEditingNote);

      this.notification = res.data.notification;
      this.closeSlideout();
    },
    handleFile(e){
      const file = e.target.files[0];
      this.creatingNote.attachment = file;
    },
    resetCreateNoteForm(){
      this.creatingNote = {
        note: ''
      };
    },
    closeSlideout(){
      this.open = false;
    },
    closeNotification(){
      this.notification.message = ''
    }
  },
  mounted: function(){
    this.localOutgoingReferences = this.lead.outreferences;
  },
  computed: {
    hasHeaderSlot () {
      return !!this.$slots['header-left'] && !!this.$slots['header-right'];
    },
    hasImageSlot () {
      return !!this.$slots.image
    },
    hasBodySlot () {
      return !!this.$slots.body
    },
    alignmentClass (){
      return this.alignment === 'center' ? 'text-center' : ''
    },
    showNotification (){
      return this.notification.message.length > 0
    }
  },
  setup() {

  },
}
</script>