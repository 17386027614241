<script setup>
import {ref, watch} from 'vue'

    const emit = defineEmits(['select'])

    const props = defineProps({
        options: {
            type: Array,
            required: true,
        },
        selectedOptionName: {
            type: String,
            required: false,
            default: null,
        },
        size: {
            type: String,
            required: false,
            default: 'md',
        },
        responsive: {
            type: Boolean,
            required: false,
            default: true,
        }
    })

    const classes = {
        optionBaseClasses: 'bg-white cursor-pointer border-r-2 border-b-0 border-2 last:border-b-2 last:border-r-2 @sm:border-r-0 @sm:border-b-2 border-wlPrimary',
        optionSelectedClasses: 'bg-wlPrimary text-wlPrimaryContrast',
        optionNotSelectedClasses: '',
        optionIconClasses: 'block w-6 h-6 lg:w-6 lg:h-6 mx-auto',
        optionLabelClasses: 'block text-center',
    }

    const sizeBaseClasses = {
        xs: 'py-px lg:py-px text-xxs',
        sm: 'py-px lg:py-px text-xs',
        md: 'py-1 lg:py-1 text-xs',
    }

    const presetSelectedOption = props.selectedOptionName ? props.options.find(option => option.name === props.selectedOptionName) : props.options[0]
    const selectedOption = ref(presetSelectedOption)

    const select = (option) => {
        selectedOption.value = option
        emit('select', option)
    }

    watch(() => props.selectedOptionName, (newVal) => {
        if (! props.selectedOptionName) return
        selectedOption.value = props.options.find(option => option.name === newVal)
    })

</script>
<template>
    <div
        class="w-full mb-5"
        :class="responsive ? '@container' : ''"
    >
        <ul class="flex flex-col @sm:flex-row flex-grow w-full rounded-wl">
            <li
                v-for="(option, o) in props.options"
                v-bind:key="o"
                class="flex-grow px-2 basis-0 first:rounded-t-wl @sm:first:rounded-r-none @sm:first:rounded-l-wl @sm:first:pl-4 @sm:rounded-r-none @sm:last:rounded-r-wl @sm:last:pr-4 max-w-half last:rounded-b-wl @sm:last:rounded-l-none"
                :class="[selectedOption?.name === option.name ? classes.optionSelectedClasses : classes.optionNotSelectedClasses, classes.optionBaseClasses, sizeBaseClasses[props.size]]"
                v-on:click="select(option)"
            >
                <div class="relative py-1 text-center transition-all overflow-hidden">
                    <span
                        class="whitespace-nowrap"
                        :class="classes.optionLabelClasses"
                    >
                        {{ option.label }}
                    </span>
                </div>
            </li>
        </ul>
    </div>
</template>
