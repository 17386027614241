<script setup>
import MediaBankEntry from './util/media-bank-entry.vue';
import {computed} from "vue";

const props = defineProps({
    containerRef: {
        type: String,
        required: true
    },
    uuid: {
        type: String,
        require: true
    },
    content: {
        type: Object,
        required: true
    },
})

const alignmentClass = computed(() => {
    if (props.content?.alignment === 'left') {
        return 'justify-start'
    }
    if (props.content?.alignment === 'center') {
        return 'justify-center'
    }
    if (props.content?.alignment === 'right') {
        return 'justify-end'
    }
    if (props.content?.alignment === 'fullwidth') {
        return 'justify-stretch'
    }

    return 'justify-center'
})

const roundingClass = computed(() => {
    if (props.content?.rounding === 'siteAppearance') {
        return 'rounded-wl'
    }
    if (props.content?.rounding === 'none') {
        return 'rounded-none'
    }
    if (props.content?.rounding === 'slight') {
        return 'rounded-lg'
    }
    if (props.content?.rounding === 'medium') {
        return 'rounded-3xl'
    }
    if (props.content?.rounding === 'very') {
        return 'rounded-5xl'
    }

    return 'rounded-wl'
})


</script>
<template>
    <div
        class="flex mx-auto mb-6 overflow-hidden rounded-custom shadow-custom"
        :class="alignmentClass"
    >
        <component
            :is="content.linkUrl? 'a' : 'div'"
            :href="content.linkUrl"
            target="_blank"
            class="text-inherit"
            :class="props.content?.alignment === 'fullwidth' ? 'w-full' : ''"
        >
            <div class="overflow-hidden" :class="roundingClass">
                <MediaBankEntry
                    :container-ref="containerRef"
                    :block-uuid="uuid"
                    :block-content="content"
                    :media-bank-entry-id="content.media_bank_entry_id"
                    property-name="media_bank_entry_id"
                    :class="props.content?.alignment === 'fullwidth' ? 'w-full' : ''"
                ></MediaBankEntry>
            </div>
            <p
                v-if="content.caption"
                class="mt-1 text-xs text-center"
            >
                {{ content.caption}}
            </p>
        </component>
    </div>
</template>
