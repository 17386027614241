<template>
    <form @submit.prevent="submit">
        <h5>{{ _mft('mediaBank:upload') }}</h5>

        <input-text
            label="mediaBank:name"
            v-model:content="title"
            required
            maxlength="255"
        />

        <input-select
          v-if="!forcedType"
          label="mediaBank:fileType"
          v-model:content="type"
          :options="optionsForType"
          required
        />

        <label class="block mb-4">
            <input type="file" :accept="fileAcceptPattern" @change="onFileChange" required ref="file">
        </label>

        <div class="w-full bg-gray-200 rounded-full h-1.5 dark:bg-gray-700 mb-5">
            <div class="bg-myflowPurple-600 h-1.5 rounded-full" :style="{width: uploadPercentage + '%'}"></div>
        </div>

        <div class="flex justify-end mb-2">
            <button v-if="!submitting" type="submit" class="myflow-basic-button">
                {{ _mft('mediaBank:upload') }}
            </button>
            <mf-spinner-medium v-else class="w-16 h-8"></mf-spinner-medium>
        </div>

        <small>{{ _mft('mediaBank:maxFileSize', {size: '2048mb (2GB)'}) }}</small>
    </form>
</template>

<script>
import axios from 'axios';

const TYPES = [
    {
        type: 'image',
        name: 'Bild',
        acceptPattern: '*.png, image/png, *.jpg, *.jpeg, image/jpeg, image/webp, *.webp'
    },
    {
        type: 'file',
        name: 'Fil',
        acceptPattern: '*.*, */*'
    },
    {
        type: 'audio',
        name: 'Ljud',
        acceptPattern: '*.wav, audio/wav, *.mp3, audio/mpeg'
    }
]

export default {
    emits: [
        'uploaded'
    ],
    props: {
        reloadPageAfterUpload: { type: Boolean, default: true },
        forcedType: { type: String, default: null }
    },
    data(){
        if (this.forcedType && !TYPES.find((t) => t.type == this.forcedType)) {
            throw `Force type ${this.forcedType} is not supported!)`;
        }

        return {
            title: null,
            type: this.forcedType ? this.forcedType : TYPES[0].type,
            file: null,
            submitting: false,
            uploadPercentage: 0
        }
    },
    computed: {
        optionsForType() {
            return TYPES.map((t) => ({ label: t.name, value: t.type }));
        },
        fileAcceptPattern() {
            return TYPES.find(t => t.type == this.type).acceptPattern;
        },
    },
    watch: {
        type() {
            this.file = null;
            this.$refs["file"].value = null;
        }
    },
    methods: {
        onFileChange(event) {
            this.file = event.target.files[0];
        },
        async submit() {
            this.submitting = true;

            let formData = new FormData();
            formData.append('title', this.title);
            formData.append('type', this.type);
            formData.append('file', this.file);
            try {
                const response = await axios.post(route('mediaBank.entries.store'),
                    formData,
                    {
                        onUploadProgress: (progressEvent) => {
                            this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 80));
                        }
                    }
                );

                this.$emit('uploaded', response.data);

                if (this.reloadPageAfterUpload) {
                    this.$document.locationReload();
                }
            } catch (e) {
                alert(_mft('error:error.generalTryAgain'))
                console.warn("Failed to submit form", e);
                this.uploadPercentage = 0;
                this.submitting = false;
            }
        },
    }
}
</script>
