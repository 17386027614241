<script setup>
import { computed } from 'vue'

const props = defineProps({
    content: {
        type: Object,
        required: true,
    },
});

const title = computed(() => props.content.title);
const body = computed(() => props.content.body);
</script>
<template>
    <div class="my-6">
        <div class="bg-white shadow-custom rounded-wl overflow-hidden">
            <section class="px-6 py-4">
                <h1 class="pb-1 text-xl md:text-2xl lg:text-3xl">{{title}}</h1>
                <div class="text-sm" v-html="body"></div>
            </section>
        </div>
    </div>
</template>
