<script setup>
import { onMounted, ref, watch } from 'vue'
import { SearchIcon } from '@heroicons/vue/solid'
import SidebarNavigation from "../navigation/sidebarNavigation.vue";
import axios from "axios";

import {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
} from '@headlessui/vue'

import {
    CogIcon,
    HomeIcon,
    MenuAlt3Icon,
    PlusSmIcon,
    XIcon,
    AcademicCapIcon,
    SparklesIcon,
    GiftIcon,
    BellIcon,
    UserGroupIcon,
    AdjustmentsIcon,
    TrendingUpIcon,
    GlobeIcon,
    VideoCameraIcon,
    CashIcon,
    ViewGridAddIcon,
    UsersIcon,
    LightBulbIcon,
    RssIcon,
    UserAddIcon,
    CubeTransparentIcon,
    MapIcon,
    PresentationChartBarIcon,
    FolderIcon,
    StatusOnlineIcon,
    StarIcon,
    InboxIcon
} from '@heroicons/vue/outline'
import IconUser from "../icons/iconUser.vue";

const props = defineProps({
    logoUrl: {
        type: String,
        required: false,
        default: null
    },
    userId: {
        type: Number,
        required: true,
    },
    userProfileImageUrl: {
        type: String,
        required: false,
        default: null,
    },
    navigationItems: Array,
})

const notificationCount = ref(0);
const inboxMessageCount = ref(0);
const showInboxSlideout = ref(false);
const showNotificationSlideout = ref(false);

const mobileMenuOpen = ref(false)

const loadNotifications = async () => {
    let response = await axios.get(route('notifications'));
    notificationCount.value = response.data.unread_count;
}

const openNotifications = () => {
    showNotificationSlideout.value = true
}

const openInboxSlideout = () => {
    // This is disabled until we have multiple inboxes
    // showInboxSlideout.value = true
    window.location.href = route('messages.inbox');
}

const logout = async () => {
    const res = await axios.post(route('logout'))
    if (res.status === 204) {
        window.location.href = route('login');
    }
};

const markAllAsRead = async () =>{
    axios.post( route('notification.markAllAsRead' ));
    loadNotifications();
    showNotificationSlideout.value = false;
};

watch(mobileMenuOpen, (val) => {
    if( val === false ){
        loadNotifications()
    }
}, {deep: true})

onMounted(() => {
    loadNotifications();
})
</script>
<template>
    <div class="flex pt-20 bg-gray-100 md:pt-0">
        <div id="navigation-bar">
            <div class="flex flex-col items-center w-full md:py-5">
                <div class="fixed top-0 left-0 flex items-center justify-between w-full px-4 py-2 bg-white/30 md:bg-transparent backdrop-blur-lg md:backdrop-blur-none z-topbar md:static md:mb-4 md:py-0 md:pr-0 md:pl-6">
                    <a :href="route('catchAll')" title="Gå till förstasidan">
                        <img
                            v-if="logoUrl"
                            :src="logoUrl"
                            alt="Logo"
                            class="object-contain object-left w-24 h-12"
                        />
                        <logo-icon
                            v-else
                            class="w-auto h-8 mr-4 text-wlPrimary"
                        />
                    </a>
                    <div class="flex">
<!--                        <div-->
<!--                            class="flex items-center justify-center w-10 h-10 transition bg-white rounded-full shadow-md cursor-pointer circle-icon hover:bg-gray-200 hover:text-white md:h-8 md:w-8"-->
<!--                        >-->
<!--                            <content-search></content-search>-->
<!--                        </div>-->
                        <div
                            class="flex items-center justify-center ml-2"
                        >
                            <a
                                class="flex items-center justify-center badge w-10 h-10 md:h-8 md:w-8 bg-white hover:bg-gray-200 hover:text-white rounded-full shadow-md cursor-pointer circle-icon"
                                :href="route('support.article.index')"
                                target="_blank"
                            >
                                <i class="text-gray-900 fa-sharp fa-solid fa-question hover:text-white"></i>
                            </a>
                        </div>

                        <button
                            type="button"
                            class="relative ml-2 text-gray-500 group focus:outline-none md:hidden"
                            @click="mobileMenuOpen = !mobileMenuOpen"
                        >
                            <div class="relative w-6 h-6">

                                <div
                                    class="absolute flex items-center justify-center w-5 h-5 text-xs text-white transition rounded-full -top-2 -right-2 count bg-wlPrimary group-hover:bg-wlPrimary-light group-hover:text-wlPrimaryContrast-light group-focus:bg-wlPrimary-light group-focus:text-white"
                                    v-if="notificationCount > 0"
                                >
                                    {{ notificationCount }}
                                </div>
                                <span class="sr-only">{{ _mft('navigation:backOffice.openSidebar') }}</span>
                                <MenuAlt3Icon class="w-6 h-6 stroke-current group-focus:text-wlPrimary" aria-hidden="true" />
                            </div>
                        </button>

                    </div>
                </div>

                <SidebarNavigation
                    class="fixed top-0 bottom-0 py-4 overflow-y-scroll transition-transform transform md:py-0 md:bottom-auto md:top-24 right-full w-64 md:w-60 z-topbar md:z-10 md:static md:overflow-y-visible"
                    :class="mobileMenuOpen ? 'translate-x-full md:translate-x-0 bg-gradient-to-r from-gray-100  via-gray-100 transparent md:bg-none' : ''"
                    :items="navigationItems"
                >
                    <template #top>
                        <div class="flex justify-between w-full pb-1 pl-3 pr-2">
                            <div class="flex items-center space-x-2">
                                <div
                                    class="relative rounded-full cursor-pointer group"
                                    v-on:click="openInboxSlideout()"
                                >
                                    <div class="flex items-center justify-center badge text-wlPrimaryContrast group-hover:text-wlPrimaryContrast-light">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="w-5 h-5"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path d="M3 8L8.44992 11.6333C9.73295 12.4886 10.3745 12.9163 11.0678 13.0825C11.6806 13.2293 12.3194 13.2293 12.9322 13.0825C13.6255 12.9163 14.2671 12.4886 15.5501 11.6333L21 8M6.2 19H17.8C18.9201 19 19.4802 19 19.908 18.782C20.2843 18.5903 20.5903 18.2843 20.782 17.908C21 17.4802 21 16.9201 21 15.8V8.2C21 7.0799 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V15.8C3 16.9201 3 17.4802 3.21799 17.908C3.40973 18.2843 3.71569 18.5903 4.09202 18.782C4.51984 19 5.07989 19 6.2 19Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                        <div
                                            class="flex items-center justify-center rounded-full text-xs transition count -translate-x-1 -translate-y-1.5"
                                            v-if="inboxMessageCount > 0"
                                        >
                                            {{ inboxMessageCount }}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="relative rounded-full cursor-pointer group"
                                    v-on:click="openNotifications()"
                                >
                                    <div class="flex items-center justify-center badge text-wlPrimaryContrast">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="w-5 h-5"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                                        </svg>
                                        <div
                                            class="flex items-center justify-center rounded-full text-xs transition count -translate-x-1 -translate-y-1.5"
                                            v-if="notificationCount > 0"
                                        >
                                            {{ notificationCount }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex items-center space-x">
                                <Menu
                                    as="div"
                                    class="relative"
                                >
                                    <div>
                                        <MenuButton class="text-wlPrimaryContrast">
                                            <icon-user
                                                v-if="!userProfileImageUrl"
                                                class="w-4 h-4 transform translate-y-px fill-wlPrimaryContrast"
                                                fill="none"
                                            />
                                            <div
                                                v-else
                                                class="relative w-10 h-4"
                                            >
                                                <img
                                                    class="absolute left-0 right-0 z-20 object-cover w-10 h-10 transition-all translate-y-px rounded-full -top-3 -bottom-3 hover:ring ring-wlPrimaryContrast"
                                                    :src="userProfileImageUrl"
                                                />
                                            </div>
                                        </MenuButton>
                                    </div>
                                    <transition
                                        enter-active-class="transition duration-100 ease-out"
                                        enter-from-class="transform scale-95 opacity-0"
                                        enter-to-class="transform scale-100 opacity-100"
                                        leave-active-class="transition duration-75 ease-in"
                                        leave-from-class="transform scale-100 opacity-100"
                                        leave-to-class="transform scale-95 opacity-0"
                                    >
                                        <MenuItems
                                            class="absolute z-50 w-auto mt-0 overflow-hidden origin-top-right rounded-md shadow-lg top-4 -right-4 focus:outline-none md:mt-2 lg:mt-1"
                                        >
                                            <MenuItem>
                                                <a
                                                    :href="route('user.edit')"
                                                    class="flex items-center px-4 py-2 text-sm whitespace-nowrap bg-wlPrimary text-wlPrimaryContrast hover:underline lg:text-xs"
                                                >
                                                    <span class="w-4 h-4 mr-2">
                                                        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M16 9C16 11.2091 14.2091 13 12 13C9.79086 13 8 11.2091 8 9C8 6.79086 9.79086 5 12 5C14.2091 5 16 6.79086 16 9ZM14 9C14 10.1046 13.1046 11 12 11C10.8954 11 10 10.1046 10 9C10 7.89543 10.8954 7 12 7C13.1046 7 14 7.89543 14 9Z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM3 12C3 14.0902 3.71255 16.014 4.90798 17.5417C6.55245 15.3889 9.14627 14 12.0645 14C14.9448 14 17.5092 15.3531 19.1565 17.4583C20.313 15.9443 21 14.0524 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12ZM12 21C9.84977 21 7.87565 20.2459 6.32767 18.9878C7.59352 17.1812 9.69106 16 12.0645 16C14.4084 16 16.4833 17.1521 17.7538 18.9209C16.1939 20.2191 14.1881 21 12 21Z" fill="currentColor" /></svg>
                                                    </span>
                                                    {{ _mft('navigation:backOffice.widget.account') }}
                                                </a>
                                            </MenuItem>

                                            <MenuItem>
                                                <a :href="route('user.logout')" :title="_mft('navigation:backOffice.widget.logOut')">
                                                    <button
                                                        class="flex items-center px-4 py-2 text-sm whitespace-nowrap bg-wlPrimary text-wlPrimaryContrast hover:underline lg:text-xs"
                                                    >
                                                        <span class="w-4 h-4 mr-2">
                                                            <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="currentColor"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.02 3.77v1.56l1-.99V2.5l-.5-.5h-9l-.5.5v.486L2 3v10.29l.36.46 5 1.72L8 15v-1h3.52l.5-.5v-1.81l-1-1V13H8V4.71l-.33-.46L4.036 3h6.984v.77zM7 14.28l-4-1.34V3.72l4 1.34v9.22zm6.52-5.8H8.55v-1h4.93l-1.6-1.6.71-.7 2.47 2.46v.71l-2.49 2.48-.7-.7 1.65-1.65z"/></svg>
                                                        </span>
                                                        {{ _mft('navigation:backOffice.widget.logOut') }}
                                                    </button>
                                                </a>
                                            </MenuItem>
                                        </MenuItems>
                                    </transition>
                                </Menu>
                            </div>
                            <basic-slideout
                                v-model:open="showNotificationSlideout"
                            >
                                <template v-slot:header>
                                    <div>
                                        <h1>{{ _mft('notification:domainNamePlural') }}</h1>
                                        <p
                                            class="text-wlPrimaryContrast"
                                            v-if="notificationCount > 0"
                                        >{{ _mft('notification:nUnreadNotifications', {count: notificationCount}) }}</p>
                                    </div>
                                </template>
                                <template v-slot:body class="relative">
                                    <notification-index
                                        ref="notificationIndex"
                                        :maxcount="300"
                                        @update="setNotificationCount"
                                    />
                                </template>
                                <template v-slot:footer>
                                    <button-secondary
                                        size="sm"
                                        v-on:click="markAllAsRead()"
                                    >
                                        {{ _mft('notification:markAllAsRead') }}
                                    </button-secondary>

                                </template>
                            </basic-slideout>
                            <basic-slideout
                                v-model:open="showInboxSlideout"
                            >
                                <template v-slot:header>
                                    <div>
                                        <h1>Meddelanden</h1>
                                        <p
                                            class="text-wlPrimaryContrast"
                                            v-if="inboxMessageCount > 0"
                                        >
                                            {{ _mft('message:nUnreadMessages', {count: inboxMessageCount}) }}
                                        </p>
                                    </div>
                                </template>
                                <template v-slot:body class="relative">
                                    <notification-index
                                        ref="notificationIndex"
                                        :maxcount="300"
                                        @update="setNotificationCount"
                                    />
                                </template>
                                <template v-slot:footer>
                                    <button
                                        class="myflow-basic-button--secondary"
                                        v-on:click="markAllAsRead()"
                                    >
                                        {{ _mft('message:markAllAsRead') }}
                                    </button>

                                </template>
                            </basic-slideout>
                        </div>
                    </template>
                </SidebarNavigation>

            </div>
        </div>

        <!-- Content area -->
        <main class="flex-1">
            <!-- Primary column -->
            <section
                aria-labelledby="primary-heading"
                class="z-20 flex flex-col flex-1 h-full min-w-0 min-h-contentarea lg:order-last"
            >
                <div
                    id="teleport-target-sticky-before-main"
                    class="container top-0 w-full px-2 mx-auto md:sticky z-topbar"
                ></div>
                <slot></slot>
            </section>
        </main>
        <div id="teleport-target-modal"></div>
        <time-tracking-modal />
        <flash-messages />
    </div>
</template>
<style scoped>
.min-h-contentarea {
    min-height: 100vh;
    min-height: calc(100vh - 4rem);
}
</style>
