<script setup>
const props = defineProps({
    progress: {
        type: Number,
        required: true,
    },
    steps: {
        type: Array,
        required: false,
    },
})

const emit = defineEmits(['update:progress'])

const setProgress = (value) => {
    emit('update:progress', value)
}

</script>

<template>
    <div class="flex items-center justify-between bg-gray-300 h-2 rounded-full text-[8px] text-white relative">
        <button
            v-for="(step, index) in steps"
            :key="index"
            class="min-w-8 h-8 rounded-full border-2 transition-all hover:scale-110 z-20 px-0.5"
            :class="[progress >= step.value ? step.classes + ' bg-white text-black shadow-md' : 'border-gray-100 bg-gray-200 text-black']"
            @click="setProgress(step)"
        >
            {{ step.label }}
        </button>
        <div
            class="absolute left-0 top-0 bottom-0 bg-white border border-gray-300 rounded-full z-10 transition-all duration-300 ease-in]"
            :style="{ width: progress + '%' }"
        ></div>

    </div>
</template>

<style scoped>

</style>
