<script setup>
import {ClipboardCopyIcon} from "@heroicons/vue/outline";
import {Clipboard} from "v-clipboard";
import {ref} from "vue";

const props = defineProps({
    copyableText: {
        type: String,
        required: true,
    },
    showUrl: {
        type: Boolean,
        required: false,
        default: true,
    }
})

const copyState = ref('')

const copyUrl = async () => {
    let res = await Clipboard.copy(props.copyableText)
    copyState.value = res ? 'success' : 'error'
    setTimeout(() => {
        copyState.value = ''
    }, 1200)
}

</script>

<template>
    <div class="flex items-center space-x-2">
        <p
            v-if="props.showUrl"
            class="select-all text-xs mb-0 text-gray-500"
        >
            {{ copyableText }}
        </p>
        <button-secondary
            @click="copyUrl"
            size="sm"
            :state="copyState"
        >
            <ClipboardCopyIcon class="h-4 w-4" />
        </button-secondary>
    </div>
</template>

<style scoped>

</style>
