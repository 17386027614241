<script setup>

import {computed, reactive, ref} from "vue";

const showCreateCommunityModal = ref(false);
const companyCommunitiesList = ref(null);

const newCommunity = reactive({
    name: ''
});

const createCommunity = async () => {
    if (! newCommunityValidates.value) {
        return;
    }
    const res = await axios.post(route('community.admin.api.store'), newCommunity);
    await companyCommunitiesList.value.getCommunities();
    showCreateCommunityModal.value = false;

    window.location.href = route('community.admin.show', res.data.id);
}

const newCommunityValidates = computed(() => {
    return newCommunity.name.length > 0;
});

</script>
<template>
    <div>
        <frosted-bar>
            <div class="flex items-center justify-between space-x-4">
                <h1>{{ _mft('community:domainNameSingular') }}</h1>

                <div class="flex items-center space-x-4">
                    <story-lane-modal :module="'community-v1'"></story-lane-modal>

                    <button-primary
                        @click="showCreateCommunityModal = true"
                    >
                        {{ _mft('community:newCommunity') }}
                    </button-primary>
                </div>
            </div>
        </frosted-bar>

        <div class="mt-8">
            <div class="mb-8">
                <h2>{{ _mft('community:communities.whereMember') }}</h2>
                <member-communities></member-communities>
            </div>
            <div class="mb-8">
                <h2>{{ _mft('community:communities.companyOwned') }}</h2>
                <community-admin-list-communities
                    ref="companyCommunitiesList"
                ></community-admin-list-communities>
            </div>
        </div>

        <slideout-with-slots
            v-if="showCreateCommunityModal"
            @close="showCreateCommunityModal = false"
        >
            <template #title>
                <h2>{{ _mft('community:newCommunity') }}</h2>
            </template>
            <template #body>
                <input-text
                    :label="_mft('community:communityName')"
                    maxlength="255"
                    name="name"
                    v-model:content="newCommunity.name"
                    required
                />

                <div class="flex justify-end">
                    <button-base
                        :style-type="newCommunityValidates ? 'primary' : 'disabled'"
                        :disabled="! newCommunityValidates"
                        @click="createCommunity"
                    >{{ _mft('shared:action.create') }}</button-base>
                </div>
            </template>
        </slideout-with-slots>
    </div>
</template>
