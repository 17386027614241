<script setup>

import {onMounted, ref, watch} from "vue";

const props = defineProps({
    label: {
        type: String,
        required: true
    }
})

const customUserAreaEnabled = ref(false)

const loadSetting = async () => {
    const res = await axios.get(route('settings.custom-user-area.show'))

    customUserAreaEnabled.value = res.data.customUserAreaEnabled
}

const storeSetting = async () => {
    const res = await axios.post(route('settings.custom-user-area.show'), {
        customUserAreaEnabled: customUserAreaEnabled.value
    })
}

watch(customUserAreaEnabled, () => {
    storeSetting()
}, {deep: true})

onMounted(() => {
    loadSetting()
})
</script>

<template>
    <div>
        <toggle-switch
            name="custom-user-area-enabled"
            :label="props.label"
            v-model:content="customUserAreaEnabled"
        ></toggle-switch>
    </div>
</template>

<style scoped>

</style>
