<script setup>
import {onMounted, ref, computed, watch} from 'vue';
import axios from 'axios';
import TemplateListItem from './templateListItem.vue';

const emit = defineEmits(['selectTemplate']);

const props = defineProps({
    readOnly: {
        type: Boolean,
        required: false,
        default: false,
    },
    showEmptyDefault: {
        type: Boolean,
        required: false,
        default: false,
    },
    selectedTemplateId: {
        type: Number,
        required: false,
        default: null,
    },
    presetCategory: {
        type: String,
        required: false,
        default: 'all',
    }
})

const companyTemplates = ref([]);
const marketplaceTemplates = ref([]);
const selectedCategory = ref('all');

const emptyTemplate = ref({
    id: null,
    name: _mft('pagebuilderTemplate:template.emptyDefault.name'),
    description: _mft('pagebuilderTemplate:template.emptyDefault.description'),
    category: 'landingpage',
    marketplace: false,
    isEmptyDefault: true,
})

async function getTemplates() {
    const response = await axios.get(route('api.templates.getTemplates'));

    companyTemplates.value = response.data.company_templates;
    marketplaceTemplates.value = response.data.marketplace_templates;
}

const selectTemplate = (template) => {
    emit('selectTemplate', template);
}

const categories = [
    { name: 'All', value: 'all' },
    { name: _mft('pagebuilderTemplate:category.landingpage.plural'), value: 'landingpage' },
    { name: _mft('pagebuilderTemplate:category.webpage.plural'), value: 'webpage' },
    { name: _mft('pagebuilderTemplate:category.customerarea.plural'), value: 'customerarea' },
    { name: _mft('pagebuilderTemplate:category.quote.plural'), value: 'quote' },
    { name: _mft('pagebuilderTemplate:category.agreement.plural'), value: 'agreement' },
    { name: _mft('pagebuilderTemplate:category.chapter.plural'), value: 'chapter' },
];

const filteredCompanyTemplates = computed(() => {
    let templates = companyTemplates.value;

    if (selectedCategory.value !== 'all') {
        templates = templates.filter(template => template.category === selectedCategory.value);
    }

    if (props.readOnly) {
        templates = templates.filter(template => template.page_builder_enabled);
    }

    return templates;
});

const filteredMarketplaceTemplates = computed(() => {
    let templates = marketplaceTemplates.value;

    if (selectedCategory.value !== 'all') {
        templates = templates.filter(template => template.category === selectedCategory.value);
    }

    templates = templates.filter(template => template.page_builder_enabled);

    return templates;
});


watch(() => selectedCategory.value, (value) => {
    emptyTemplate.value.category = value
})

onMounted(() => {
    if (props.presetCategory !== 'all') {
        // Un-comment this once we have a good volume of templates in each category
        // selectedCategory.value = props.presetCategory;
        emptyTemplate.value.category = props.presetCategory;
    }

    getTemplates();
});

defineExpose({
    getTemplates
})

</script>

<template>
    <div>
        <label class="block mt-4 mb-2 text-center">
            {{ _mft('pagebuilderTemplate:filterByRecommendedUsage') }}
        </label>
        <div class="flex flex-wrap items-center justify-center mb-4 space-x-1">
            <button-base
                v-for="category in categories"
                :key="category.value"
                :style-type="selectedCategory === category.value ? 'primary' : 'secondary'"
                size="sm"
                class="mb-1"
                @click="selectedCategory = category.value"
            >
                {{ category.name }}
            </button-base>
        </div>

        <h2 class="mt-6">
            {{ _mft('pagebuilderTemplate:companyTemplates') }}
        </h2>

        <div class="gap-8 mt-5 grid md:grid-cols-2 lg:grid-cols-3">
            <TemplateListItem
                v-if="showEmptyDefault"
                :template="emptyTemplate"
                :marketplace-template="false"
                :read-only="readOnly"
                :is-selected="selectedTemplateId === emptyTemplate.id"
                @selectTemplate="selectTemplate"
            />

            <TemplateListItem
                v-for="template in filteredCompanyTemplates"
                :key="template.id"
                :template="template"
                :marketplace-template="false"
                :read-only="readOnly"
                :is-selected="selectedTemplateId === template.id"
                :available-categories="categories"
                @selectTemplate="selectTemplate"
            />
        </div>
        <p
            v-if="filteredCompanyTemplates.length === 0"
            class="mb-8 text-gray-400"
        >
            {{ _mft('pagebuilderTemplate:noTemplatesToShow') }}
        </p>

        <h2 class="mt-12">
            {{ _mft('pagebuilderTemplate:marketplaceTemplates') }}
        </h2>
        <div class="gap-8 mt-5 md:grid md:grid-cols-2 lg:grid-cols-3">
            <TemplateListItem
                v-for="template in filteredMarketplaceTemplates"
                :key="template.id"
                :template="template"
                :marketplace-template="true"
                :read-only="readOnly"
                @reload-templates="getTemplates"
            />
        </div>
        <p
            v-if="filteredMarketplaceTemplates.length === 0"
            class="mb-8 text-gray-400"
        >
            {{ _mft('pagebuilderTemplate:noTemplatesToShow') }}
        </p>
    </div>
</template>
