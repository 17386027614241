<script setup>
import InputProvider from '../form/inputProvider.vue';
import {computed, onMounted, ref} from "vue";
import SlideoutWithSlots from "../../elements/slideoutWithSlots.vue";
import ButtonWithConfirmation from "../../buttons/buttonWithConfirmation.vue";
import ToggleSwitch from "../../forms/toggleSwitch.vue";

const emit = defineEmits(['close'])

const props = defineProps({
    chapter: {
        type: Object,
        required: true
    },
})

const _chapter = ref(null)
const titleInput = ref(null)

const loadChapter = async () => {
    const res = await axios.get(route('api.course.backoffice.chapter.edit', {course_id: props.chapter.course_id, chapter_id: props.chapter.id}));

    _chapter.value = res.data.chapter
    setTimeout(function() {
        titleInput.value.focusInput()
    }, 1)
}

const save = async () => {
    const res = await axios.put(route('api.course.backoffice.chapter.update', {course_id: props.chapter.course_id, chapter_id: props.chapter.id}), _chapter.value);

    if (res.data.success) {
        emit('update:chapter', res.data.chapter)
    }
}

const cancel = () => {
    emit('close')
}

const deleteChapter = async () => {
    const res = await axios.delete(route('api.course.backoffice.chapter.destroy', {course_id: props.chapter.course_id, chapter_id: props.chapter.id}));

    if (res.data.success) {
        emit('delete:chapter', props.chapter)
        emit('close')
    }
}

const chapterValidates = computed(() => {
    return _chapter.value?.title.length > 0
})

onMounted(() => {
    loadChapter();
})

</script>

<template>
    <slideout-with-slots
        @close="emit('close')"
        :title="chapter.title"
    >
        <template #actions>
            <div class="mb-2 flex justify-end space-x-2">
                <button-with-confirmation
                    @confirmed="cancel"
                    modalClass="absolute -top-2 -right-2 p-2 bg-white text-black rounded shadow-md z-10"
                    :buttonLabel="_mft('shared:cancel')"
                    buttonClass="text-wlPrimaryContrast"
                    :confirmButtonLabel="_mft('shared:confirm.continue')"
                    confirmButtonClass="myflow-basic-button--success whitespace-nowrap"
                    :confirm-text="_mft('shared:confirm.changesLost')"
                ></button-with-confirmation>

                <button-success
                    :disabled="! chapterValidates"
                    @click="save"
                >{{ _mft('shared:action.save') }}</button-success>
            </div>
        </template>
        <template #body>
            <div v-if="_chapter">
                <input-text
                    v-model:content="_chapter.title"
                    :label="_mft('course:chapter.title')"
                    required="required"
                    name="title"
                    ref="titleInput"
                />

                <input-provider
                    :label="_mft('course:chapter.provider')"
                    name="provider_id"
                    v-model="_chapter.provider_id"
                />

                <toggle-switch
                    v-model:content="_chapter.published"
                    :label="_mft('course:chapter.published')"
                    name="published"
                />

                <div>
                    <button-with-confirmation
                        size="sm"
                        @confirmed="deleteChapter"
                        modalClass="absolute -top-2 -left-2 p-2 bg-white text-black rounded shadow-md z-10"
                        :buttonLabel="_mft('course:chapter.delete')"
                        buttonClass="text-wlPrimaryContrast"
                        :confirmButtonLabel="_mft('shared:confirm.continue')"
                        confirmButtonClass="myflow-basic-button--success whitespace-nowrap"
                        :confirm-text="_mft('shared:confirm.areYouSure')"
                    ></button-with-confirmation>
                </div>
            </div>
            <mf-spinner-medium v-else class="w-12 h-6" />
        </template>
    </slideout-with-slots>
</template>
