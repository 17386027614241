<template>
    <div>
        <div class="grid gap-5 mb-3" :class="layout === 'half' ? 'grid-cols-1' : 'grid-cols-1 lg:grid-cols-2'">
            <input-media-bank-entry type="image" :label="_mft('blockEditor:selectImageFromMediaLibrary.label')" :required="true"
                v-model:content="value.mediaBankEntryId" />
            <media-bank-entries-display v-if="previewUrl" :data-url="previewUrl" />
        </div>
        <div>
            <input-text
                :label="_mft('blockEditor:link.label')"
                type="url"
                :required="false"
                v-on:change="appendProtocol"
                v-model:content="value.imagelink"
            />
        </div>
    </div>
</template>
<script>
export default {
    props: {
        modelValue: {},
        layout: {
            type: String,
            default: 'full'
        }
    },
    emits: [
        'update:modelValue'
    ],
    data() {
        return {
            value: {
                mediaBankEntryId: null,
                imagelink: null
            }
        };
    },
    computed: {
        previewUrl() {
            if (!this.value.mediaBankEntryId) { return; }

            return route('mediaBank.entries.show', this.value.mediaBankEntryId);
        }
    },
    async mounted() {
        this.captureModelValue();
    },
    watch: {
        modelValue() {
            if (JSON.stringify(this.value) === JSON.stringify(this.modelValue)) { return; }
            this.captureModelValue();
        },
        value: {
            handler() {
                this.$emit('update:modelValue', JSON.parse(JSON.stringify(this.value)));
            },
            deep: true
        }
    },
    methods: {
        captureModelValue() {
            const value = this.modelValue ? JSON.parse(JSON.stringify(this.modelValue)) : {};
            this.value = value;
        },
        appendProtocol() {
            if (!this.value.imagelink) { return; }

            if (!this.value.imagelink.match(/^[a-zA-Z]+:\/\//)) {
                this.value.imagelink = 'https://' + this.value.imagelink;
            }
        }
    },
}
</script>
