<script setup>
import { ref, onMounted, onBeforeUnmount, watch } from 'vue';
import { EditorState } from '@codemirror/state';
import { EditorView, basicSetup } from 'codemirror';
import { css } from '@codemirror/lang-css';
import { oneDark } from '@codemirror/theme-one-dark';
import { indentUnit } from '@codemirror/language';


const props = defineProps({
    modelValue: {
        type: String,
        required: false,
        default: '\n\n\n\n',
    },
});

const emit = defineEmits(['update:modelValue']);

const editorContainer = ref(null);
let editorView = null;

onMounted(() => {
    editorView = new EditorView({
        state: EditorState.create({
        doc: props.modelValue,
        extensions: [
            basicSetup,
            css(),
            oneDark,
            indentUnit.of('    '),
            EditorView.updateListener.of((update) => {
            if (update.docChanged) {
                const newValue = update.state.doc.toString();
                emit('update:modelValue', newValue);
            }
            }),
        ],
        }),
        parent: editorContainer.value,
    });
});

onBeforeUnmount(() => {
    if (editorView) {
        editorView.destroy();
    }
});

watch(
    () => props.modelValue,
    (newValue) => {
        if (editorView && newValue !== editorView.state.doc.toString()) {
            editorView.dispatch({
                changes: { from: 0, to: editorView.state.doc.length, insert: newValue },
            });
        }
    }
);
</script>

<template>
    <div class="css-editor">
        <div ref="editorContainer" class="editor-container"></div>
    </div>
</template>

<style scoped>
.css-editor .editor-container {
    height: 100%;
}
</style>
