<template>
    <div class="flex justify-center align-center">
        <div
            class=""
            :class="horizontal ? 'flex flex-col-reverse sm:items-center md:grid md:grid-cols-2 md:gap-4' : 'flex flex-col-reverse items-center'"
        >
            <section class="px-6 md:px-0">
                <h2 class="mb-2 text-xl md:text-2xl lg:text-3xl">{{title}}</h2>
                <div v-html="body"></div>
            </section>
            <section class="mb-4" :class="horizontal ? 'md:pl-8 md:mb-0' : 'md:px-8 md:mb-8'">
                <media-bank-entries-display
                    class="overflow-hidden rounded-custom shadow-custom"
                    :class="horizontal ? 'sticky top-4' : ''"
                    alt=""
                    :data="mediaBankEntryData"
                />
            </section>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: { type: String, required: true },
        body: { type: String, required: true },
        mediaBankEntryData: { type: Object, required: true },
        horizontal: { type: Boolean, required: true },
    }
};
</script>

<style scoped>

.videoStyling{
    height: 14rem;
    width: auto;
}
</style>
