<script setup>
import {computed, onMounted, reactive, ref, watch} from 'vue';
import Products from './_products.vue'
import ExpandableSection from "../containers/expandableSection.vue";
import { ClipboardCopyIcon } from "@heroicons/vue/outline"
import Summary from "./_summary.vue";
import { useNotificationStore } from '@/stores/NotificationStore.js'
import InputDatepicker from "../forms/inputDatepicker.vue";
import PurchaseCompletedPageForm from "./_purchase-completed-page-form.vue";
import InputLabel from "../forms/inputLabel.vue";
import {Clipboard} from "v-clipboard";
import CopyableText from "../copyableText.vue";
const notificationStore = useNotificationStore()

const emit = defineEmits(['paymentLinkCreated'])
const props = defineProps({
    showTitle: {
        type: Boolean,
        required: false,
        default: true,
    },
    formStyle: {
        type: String,
        required: false,
        default: 'card',
    },
    stripeAvailable: {
        type: Boolean,
        required: false,
        default: false,
    },
    accountCreationEnabled: {
        type: Boolean,
        required: false,
        default: false,
    },
    invoiceLinkUuid: {
        type: String,
        required: false,
        default: null,
    },
    presetTitle: {
        type: String,
        required: false,
        default: null,
    },
    presetPrice: {
        type: Number,
        required: false,
        default: null,
    },
    presetProducts: {
        type: Array,
        required: false,
        default: () => [],
    },
    presetType: {
        type: String,
        required: false,
        default: null,
    },
    presetCreateAccount: {
        type: Boolean,
        required: false,
        default: null,
    },
    redirect: {
        type: Boolean,
        required: false,
        default: true
    },
    hiddenFeatures: {
        type: Array,
        required: false,
        default: () => [],
    },
    billingDetails: {
        type: Object,
        required: false,
        default: null
    },
    preselectedTab: {
        type: String,
        required: false,
        default: 'products'
    }
})

const purchaseTypeOptions = [
    {
        label: _mft('invoiceLink:singlePayment'),
        name: 'single-payment',
    },
    {
        label: _mft('invoiceLink:monthlyInstalments'),
        name: 'monthly-payments',
    },
]

const invoiceLinkTypeOptions = [
    {
        label: _mft('invoiceLink:multiUse'),
        name: 'multi-use',
    },
    {
        label: _mft('invoiceLink:singleUse'),
        name: 'single-use',
    },
]

const createAccountForBuyerOptions = [
    {
        label: _mft('invoiceLink:createAccountNo'),
        name: false,
    },
    {
        label: _mft('invoiceLink:createAccountYes'),
        name: true,
    },
]

const showDiscountOptions = [
    {
        label: _mft('invoiceLink:showDiscountNo'),
        name: false,
    },
    {
        label: _mft('invoiceLink:showDiscountYes'),
        name: true,
    },
]

const paymentMethodsAvailable = reactive({
    stripe: false,
    invoice: false,
})

const paymentLinkCard = ref(null)

const invoiceLinkUuid = ref(null);
const invoiceLinkTitle = ref('');
const invoiceLinkType = ref(''); // invoiceLink || oneTimePayment
const invoiceLinkActive = ref(false);
const notifyOnPurchase = ref(false);
const validFrom = ref(null);
const validTo = ref(null);
const price = ref(null);
const currency = ref(null);
const currencyObject = ref({identifier: null});
const vatPercentage = ref(0);
const instalmentCount = ref(1);
const purchaseType = ref('single-payment');
const maxPurchases = ref(null);
const taggingActive = ref(false);
const tags = ref([]);
const isEditable = ref(true);

// const paymentMethods = ref([]);
const paymentInvoiceActive = ref(false)
const paymentStripeActive = ref(false)
const paymentFreeActive = ref(false)
const accountCreationRequired = ref(false)
const createAccountForBuyer = ref(false)
const showDiscount = ref(false)

const products = ref([])
const productsValidate = ref(false)

const redirectAfter = ref(null)

const oneTimePaymentBuyer = reactive({
    name: '',
    email: '',
    emailIsValid: false,
})

const loadInvoiceLink = async () => {
    let invoiceLinkRoute;
    if (props.invoiceLinkUuid === null) {
        invoiceLinkRoute = route('api.invoice-links.new')
    } else {
        invoiceLinkRoute = route('api.invoice-links.edit', {uuid: props.invoiceLinkUuid})
    }

    const res = await axios.get(invoiceLinkRoute)
    const _link = res.data.link;

    paymentMethodsAvailable.stripe = res.data.stripeAvailable

    invoiceLinkUuid.value = _link.uuid;
    invoiceLinkTitle.value = _link.title;
    invoiceLinkType.value = _link.type;
    invoiceLinkActive.value = _link.active;
    notifyOnPurchase.value = _link.notify_on_purchase;
    validFrom.value = _link.valid_from;
    validTo.value = _link.valid_to;
    price.value = _link.price;
    currency.value = _link.currency;
    vatPercentage.value = _link.vat_percentage;
    instalmentCount.value = _link.instalment_count;
    maxPurchases.value = _link.max_purchases;
    createAccountForBuyer.value = _link.create_account_for_buyer;
    showDiscount.value = _link.show_discount;
    products.value = _link.products;
    redirectAfter.value = _link.redirect_after;
    tags.value = _link.lead_tags;
    isEditable.value = _link.is_editable;

    taggingActive.value = tags.value.length > 0;

    // Presets
    if (props.presetTitle) {
        invoiceLinkTitle.value = props.presetTitle
    }

    if (props.presetPrice) {
        price.value = props.presetPrice
    }

    if (props.presetProducts.length > 0) {
        products.value = props.presetProducts
    }

    if (props.presetType) {
        invoiceLinkType.value = props.presetType
    }

    if (props.presetCreateAccount) {
        createAccountForBuyer.value = props.presetCreateAccount
    }

    // TODO Populate paymentInvoiceActive and paymentStripeActiv
    // paymentMethods.value = JSON.parse(_link.payment_methods);

    if (JSON.parse(_link.payment_methods).includes('invoice')) {
        paymentInvoiceActive.value = true
    }

    if (JSON.parse(_link.payment_methods).includes('stripe')) {
        paymentStripeActive.value = true
    }
    if (paymentMethodsAvailable.stripe === false) {
        paymentStripeActive.value = false
    }

    if (JSON.parse(_link.payment_methods).includes('free')) {
        paymentFreeActive.value = true
    }

    if (invoiceLinkType.value === 'one-time-purchase') {
        oneTimePaymentBuyer.name = _link.one_time_payment_buyer.name;
        oneTimePaymentBuyer.email = _link.one_time_payment_buyer.email;
    }

    if (instalmentCount.value > 1) {
        purchaseType.value = 'monthly-payments'
    } else {
        purchaseType.value = 'single-payment'
    }

}

const setInvoiceLinkType = (type) => {
    invoiceLinkType.value = type.name;
}

const setPurchaseType = (type) => {
    purchaseType.value = type.name;
}

const togglePaymentMethod = (method) => {
    if (paymentMethods.value.includes(method)) {
        paymentMethods.value = paymentMethods.value.filter(m => m !== method)
    } else {
        paymentMethods.value.push(method)
    }
}

const setCreateAccountForBuyer = (value) => {
    createAccountForBuyer.value = value.name;
}

const setShowDiscount = (value) => {
    showDiscount.value = value.name;
}

const submit = async () => {
    // Validate data here one more time

    const data = {
        title: invoiceLinkTitle.value,
        price: price.value,
        type: invoiceLinkType.value,
        active: invoiceLinkActive.value,
        notify_on_purchase: notifyOnPurchase.value,
        valid_from: validFrom.value,
        valid_to: validTo.value,
        currency: currency.value,
        vat_percentage: vatPercentage.value,
        instalment_count: instalmentCount.value,
        purchase_type: purchaseType.value,
        max_purchases: maxPurchases.value,
        payment_methods: paymentMethods.value,
        account_creation_required: !!accountCreationRequired.value,
        create_account_for_buyer: !!createAccountForBuyer.value,
        show_discount: showDiscount.value,
        products: products.value,
        one_time_payment_buyer: oneTimePaymentBuyer,
        redirect_after: redirectAfter.value,
        tags: tags.value,
        billing_details: props.billingDetails,
    }

    if (invoiceLinkUuid.value === null) {
        const res = await axios.post(route('api.invoice-links.create'), data)
        invoiceLinkUuid.value = res.data.link.uuid;
        if (props.redirect) {
            history.pushState({}, "", route('invoice-links.edit', {uuid: invoiceLinkUuid.value}));
        }
        emit('paymentLinkCreated', res.data.link)
    } else {
        const res = await axios.put(route('api.invoice-links.update', {uuid: invoiceLinkUuid.value}), data)
        products.value = res.data.link.products;
    }

    notificationStore.addNotification({
        type: 'success',
        title: 'invoiceLink:notification.linkSaved',
        dismissAfter: 5000,
    })
}

const onTagsSelected = (selectedTags) => {
    tags.value = selectedTags.map(tag => tag.id)
}

watch(products, (newVal) => {
    accountCreationRequired.value = newVal.some(
        product => product.linkableType === 'course' ||
            product.linkableType === 'coaching-product' ||
            product.linkableType === 'coaching-offerable' ||
            product.linkableType === 'community'
    );
}, {deep: true})

watch(accountCreationRequired, (newVal) => {
    if (newVal === true) {
        createAccountForBuyer.value = true
    }
}, {deep: true})

watch(purchaseType, (newVal) => {
    if (newVal === 'single-payment') {
        instalmentCount.value = 1
    }
})

watch(taggingActive, (newVal) => {
    if (newVal === false) {
        tags.value = []
    }
})

const paymentMethods = computed(() => {
    const methods = []
    if (paymentFreeActive.value) {
        methods.push('free')
    }
    if (paymentInvoiceActive.value) {
        methods.push('invoice')
    }
    if (paymentStripeActive.value) {
        methods.push('stripe')
    }
    return methods
})

const invoiceLinkSettingsValidate = computed(() => {
    return invoiceLinkTitle.value
        && invoiceLinkTitle.value !== ''
        && price.value !== null
        && currency.value !== null
        && vatPercentage.value !== null
        && vatPercentage.value <= 100
        && instalmentCount.value > 0
        && invoiceLinkType.value !== null
        && paymentMethods.value.length > 0
})

const oneTimePaymentValidates = computed(() => {
    return invoiceLinkSettingsValidate.value
        && productsValidate.value
        && oneTimePaymentBuyer.name !== ''
        && oneTimePaymentBuyer.emailIsValid === true
})

const invoiceLinkValidates = computed(() => {
    return invoiceLinkSettingsValidate.value
        && productsValidate.value
})

watch(validFrom, (newVal) => {
    if (validTo.value !== null && newVal > validTo.value) {
        validTo.value = validFrom.value
    }
}, {deep:true})

watch(price, (newVal) => {
    if (newVal === 0) {
        paymentInvoiceActive.value = false
        paymentStripeActive.value = false
        paymentFreeActive.value = true
    } else {
        paymentFreeActive.value = false
        paymentInvoiceActive.value = true
    }
}, {deep: true})

onMounted(() => {
    _mfProp('company:default-vat-percent').then(data => {
        vatPercentage.value = data
    })

    loadInvoiceLink();
})

</script>

<template>
    <div class="@container">
        <div class="mb-4">
            <div v-if="showTitle">
                <h1 v-if="invoiceLinkUuid">
                    {{ _mft('invoiceLink:edit.title') }}
                </h1>
                <h1 v-else>
                    {{ _mft('invoiceLink:create.title') }}
                </h1>
            </div>
            <a v-if="! hiddenFeatures.includes('backlink')" :href="route('invoice-links.index')">&larr; {{ _mft('invoiceLink:navigate.allInvoiceLinks') }}</a>
        </div>
        <div class="items-start gap-8 @5xl:grid @5xl:grid-cols-3 @5xl:gap-12">
            <div
                v-if="! isEditable"
                class="mb-8 text-gray-400 text-center"
            >
                {{ _mft('invoiceLink:noLongerEditable') }}
            </div>
            <component
                v-if="isEditable"
                :is="props.formStyle === 'card' ? 'card-tabbed' : 'ui-tabbed'"
                class="col-span-2 mb-4 @5xl:mb-0"
                :trackState="false"
                :preselectedTab="preselectedTab"
                ref="paymentLinkCard"
            >
                <component
                    :is="props.formStyle === 'card' ? 'card-tabbed-tab' : 'ui-tabbed-tab'"
                    :title="_mft('invoiceLink:tab.products')"
                    :icon="products.length === 0 ? 'fa-solid fa-circle text-red-600 icon-pulse' : 'fa-solid fa-circle text-myflowGreen-600'"
                    value="products"
                    :active="true"
                >
                    <p
                        v-if="products.length === 0"
                        class="text-center italic text-gray-500"
                    >
                        {{ _mft('invoiceLink:containsNoProducts') }}
                    </p>

                    <Products
                        v-model:products="products"
                        :_products="products"
                        :currency="currency"
                        @productsValidate="productsValidate = $event"
                    />

                    <template
                        v-if="! hiddenFeatures.includes('navigation')"
                        #action-right
                    >
                        <button-secondary
                            size="sm"
                            @click="paymentLinkCard.selectTab('settings')"
                        >
                            {{ _mft('shared:next') }}
                        </button-secondary>
                    </template>
                </component>

                <component
                    :is="props.formStyle === 'card' ? 'card-tabbed-tab' : 'ui-tabbed-tab'"
                    :title="_mft('invoiceLink:tab.settings')"
                    :icon="invoiceLinkSettingsValidate ? 'fa-solid fa-circle text-myflowGreen-600' : 'fa-solid fa-circle text-red-600 icon-pulse'"
                    value="settings"
                    :active="false"
                >
                    <div class="col-span-2 space-y-4 @2xl:space-y-1">
                        <div class="@2xl:grid @2xl:grid-cols-2 gap-12">
                            <div>
                                <input-text
                                    :label="_mft('invoiceLink:invoiceLinkTitle')"
                                    required
                                    autofocus
                                    v-model:content="invoiceLinkTitle"
                                />
                            </div>
                            <div>
                                <toggle-switch
                                    v-if="! hiddenFeatures.includes('active')"
                                    :label="_mft('invoiceLink:active')"
                                    class="mt-4 @2xl:mt-0"
                                    v-model:content="invoiceLinkActive"
                                />

                                <toggle-switch
                                    v-if="! hiddenFeatures.includes('notifyOnPurchase')"
                                    :label="_mft('invoiceLink:notifyOnPurchase')"
                                    class="mt-2"
                                    v-model:content="notifyOnPurchase"
                                />
                            </div>
                        </div>

                        <div class="@container">
                            <div class="@2xl:grid @2xl:grid-cols-2 gap-12">
                                <div>
                                    <div v-if="! hiddenFeatures.includes('invoiceLinkType')" class="mb-4">
                                        <div>
                                            <input-label class="mb-1" :label="_mft('invoiceLink:invoiceLinkType')" required />
                                            <button-bar
                                                :options="invoiceLinkTypeOptions"
                                                :selectedOptionName="invoiceLinkType"
                                                @select="setInvoiceLinkType"
                                            />
                                        </div>
                                    </div>

                                    <input-number
                                        :label="_mft('invoiceLink:priceExclVat')"
                                        required
                                        v-model:content="price"
                                    />

                                    <input-number
                                        :label="_mft('invoiceLink:vatPercentage')"
                                        required
                                        v-model:content="vatPercentage"
                                        min="0"
                                        max="100"
                                        step="1"
                                    />

                                    <div v-if="! hiddenFeatures.includes('instalments')" class="mt-4">
                                        <input-label class="mb-1" :label="_mft('invoiceLink:instalments')" />
                                        <button-bar
                                            :options="purchaseTypeOptions"
                                            :selectedOptionName="purchaseType"
                                            @select="setPurchaseType"
                                        />
                                        <div
                                            v-if="purchaseType === 'monthly-payments'"
                                        >
                                            <input-number
                                                :label="_mft('invoiceLink:numberOfMonthlyInstalments')"
                                                v-model:content="instalmentCount"
                                                min="1"
                                                max="12"
                                                :show-step-ui="true"
                                            />
                                        </div>
                                    </div>

                                    <div
                                        v-if="! hiddenFeatures.includes('paymentMethods')"
                                        class="mb-8"
                                    >
                                        <input-label class="mb-2" :label="_mft('invoiceLink:payableVia')" required />
                                        <toggle-switch
                                            v-if="price === 0 || ! price"
                                            :label="_mft('invoiceLink:free')"
                                            class="mb-4"
                                            v-model:content="paymentFreeActive"
                                        />

                                        <toggle-switch
                                            v-if="price > 0"
                                            :label="_mft('invoiceLink:invoice')"
                                            class="mb-4"
                                            v-model:content="paymentInvoiceActive"
                                        />

                                        <toggle-switch
                                            v-if="price > 0"
                                            label="Stripe"
                                            class="mb-4"
                                            :disabled="! paymentMethodsAvailable.stripe"
                                            v-model:content="paymentStripeActive"
                                        />
                                        <p
                                            v-if="! paymentMethodsAvailable.stripe"
                                            class="-mt-3 text-xs"
                                        >
                                            {{ _mft('invoiceLink:validateStripeSettings') }}
                                        </p>

                                    </div>

                                </div>

                                <div
                                    v-if="! hiddenFeatures.includes('redirectAfter')"
                                >
                                    <PurchaseCompletedPageForm
                                        :current-page-id="redirectAfter"
                                        @update:id="redirectAfter = $event"
                                        class="mb-4"
                                    ></PurchaseCompletedPageForm>
                                    <p class="-mt-2 text-xs">
                                        {{ _mft('invoiceLink:redirectDescription') }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <template
                        v-if="! hiddenFeatures.includes('navigation')"
                        #action-left
                    >
                        <button-secondary
                            size="sm"
                            @click="paymentLinkCard.selectTab('products')"
                        >
                            {{ _mft('shared:previous') }}
                        </button-secondary>
                    </template>
                    <template
                        v-if="! hiddenFeatures.includes('navigation')"
                        #action-right
                    >
                        <button-secondary
                            size="sm"
                            @click="paymentLinkCard.selectTab('advanced')"
                        >
                            {{ _mft('shared:next') }}
                        </button-secondary>
                    </template>
                </component>

                <component
                    :is="props.formStyle === 'card' ? 'card-tabbed-tab' : 'ui-tabbed-tab'"
                    :title="_mft('invoiceLink:tab.advanced')"
                    icon="fa-solid fa-circle text-myflowGreen-600"
                    value="advanced"
                    :active="false"
                >
                    <div class="gap-8 md:grid md:grid-cols-2">
                        <div>
                            <div v-if="! hiddenFeatures.includes('createAccount')" class="max-w-lg">
                                <input-label class="mb-2" :label="_mft('invoiceLink:createAccountTitle')"></input-label>
                                <div
                                    v-if="accountCreationRequired === true"
                                    class="mb-4 flex items-center space-x-2"
                                >
                                    <button-primary
                                        size="md"
                                    >
                                        {{ _mft('invoiceLink:createAccountYes') }}
                                    </button-primary>
                                    <p class="m-0 text-xs text-gray-500">{{ _mft('invoiceLink:createAccountRequired') }}</p>
                                </div>
                                <div
                                    v-if="accountCreationRequired === false"
                                >
                                    <button-bar
                                        :options="createAccountForBuyerOptions"
                                        :selectedOptionName="createAccountForBuyer"
                                        @select="setCreateAccountForBuyer"
                                    />
                                </div>
                            </div>

                            <div class="max-w-lg">
                                <input-label class="mb-2" :label="_mft('invoiceLink:showDiscountLabel')"></input-label>
                                <button-bar
                                    :options="showDiscountOptions"
                                    :selectedOptionName="showDiscount"
                                    @select="setShowDiscount"
                                />
                            </div>


                            <div class="max-w-lg">
                                <toggle-switch
                                    :label="_mft('invoiceLink:tagOnPurchase')"
                                    class="mt-7"
                                    v-model:content="taggingActive"
                                />
                                <tags-multi-select
                                    v-if="taggingActive"
                                    @tagsSelected="onTagsSelected"
                                    :pre-selected-tags="tags"

                                />
                            </div>
                        </div>

                        <div>
                            <div v-if="! hiddenFeatures.includes('maximumNumberOfPurchases')">
                                <input-label :label="_mft('invoiceLink:maxNumberOfPurchasesLabel')" />
                                <input-number
                                    v-model:content="maxPurchases"
                                    min="0"
                                />
                                <p class="-mt-4 text-xs text-gray-500">{{ _mft('invoiceLink:maxNumberOfPurchasesLeaveEmpty') }}</p>
                            </div>

                            <input-currency
                                v-model:identifier="currency"
                                v-model:currency="currencyObject"
                            />

                            <div>
                                <div v-if="! hiddenFeatures.includes('validFrom')">
                                    <input-label :label="_mft('invoiceLink:validFrom')" required />
                                    <input-datepicker
                                        class="mt-1 mb-4"
                                        :showInline="false"
                                        :date="validFrom"
                                        @update:date="validFrom = $event"
                                    />
                                </div>
                                <div v-if="! hiddenFeatures.includes('validThrough')">
                                    <input-label :label="_mft('invoiceLink:validThrough')" />
                                    <input-datepicker
                                        class="mt-1 mb-4"
                                        :showInline="false"
                                        :date="validTo"
                                        :min-date="validFrom"
                                        @update:date="validTo = $event"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <template
                        v-if="! hiddenFeatures.includes('navigation')"
                        #action-left
                    >
                        <button-secondary
                            size="sm"
                            @click="paymentLinkCard.selectTab('settings')"
                        >
                            {{ _mft('shared:previous') }}
                        </button-secondary>
                    </template>
                </component>
            </component>
            <Summary
                :title="invoiceLinkTitle"
                :price="price ? price : 0"
                :instalment-count="instalmentCount ? instalmentCount : 1"
                :currency-label="currencyObject.identifier"
                :vat-percentage="vatPercentage ? vatPercentage : 0"
                :payment-methods="paymentMethods"
                :create-account-for-buyer="createAccountForBuyer"
                :show-discount="showDiscount"
                :products="products"
                class="pt-4"
            >
                <template #actions>
                    <div v-if="['single-use', 'sendable-invoice'].includes(invoiceLinkType)">
                        <div v-if="'' === 'Sending links no available yet'">
                            <input-text
                                :label="_mft('invoiceLink:buyerName')"
                                required
                                v-model:content="oneTimePaymentBuyer.name"
                            />

                            <input-email
                                :label="_mft('invoiceLink:buyerEmail')"
                                required
                                v-model:content="oneTimePaymentBuyer.email"
                                @isEmailValid="oneTimePaymentBuyer.emailIsValid = $event"
                            />

                            <div class="flex justify-end">
                                <button-success
                                    class="w-full justify-center"
                                    v-if="oneTimePaymentValidates"
                                    type="submit"
                                    @click="submit"
                                >
                                    {{ _mft('invoiceLink:saveAndSend') }}
                                </button-success>

                                <button-success
                                    class="w-full justify-center"
                                    v-if="!oneTimePaymentValidates"
                                    disabled
                                >
                                    {{ _mft('invoiceLink:fillAllFieldsToSend') }}
                                </button-success>
                            </div>
                        </div>

                    </div>
                    <div
                        v-if="isEditable"
                    >
                        <div v-if="invoiceLinkType === 'multi-use' || invoiceLinkType === 'single-use' || invoiceLinkType === 'sendable-invoice'">
                            <div class="flex justify-end">
                                <button-success
                                    class="w-full justify-center"
                                    v-if="invoiceLinkValidates"
                                    type="submit"
                                    @click="submit"
                                >
                                    <span v-if="invoiceLinkUuid">
                                        {{ _mft('invoiceLink:update') }}
                                    </span>
                                    <span v-else>
                                        {{ invoiceLinkType === 'sendable-invoice' ? _mft('invoiceLink:saveAndSendInvoice') : _mft('invoiceLink:save') }}
                                    </span>
                                </button-success>
                                <button-success
                                    class="w-full justify-center"
                                    v-if="! invoiceLinkValidates"
                                    disabled
                                >
                                    {{ _mft('invoiceLink:fillAllFieldsToSave') }}
                                </button-success>
                            </div>
                        </div>

                        <div
                            v-if="invoiceLinkUuid && invoiceLinkType === 'single-use'"
                            class="mt-4 border-t pt-4 lg:mt-8 lg:pt-8"
                        >
                            <h5>{{ _mft('invoiceLink:publicInvoiceLink.title') }}</h5>
                            <p class="text-xs">
                                {{ _mft('invoiceLink:publicInvoiceLink.description', {productName: invoiceLinkTitle}) }}
                            </p>
                            <copyable-text
                                :copyable-text="siteRoute('invoicing.link.show', {uuid: invoiceLinkUuid})"
                            />
                        </div>
                    </div>
                </template>
            </Summary>
        </div>
    </div>
</template>

<style scoped>

</style>
