<script setup>
import {ref, watch} from "vue";

const emit = defineEmits(['update:content'])

const props = defineProps({
    content: {
        type: String,
        required: false
    },
    label: {
        type: String,
        required: false
    },
    presets: {
        type: Array,
        required: false,
        default: []
    }
})

const measure = ref(props.content)

watch(measure, () => {
    emit('update:content', measure.value)
})

</script>
<template>
    <div class="mb-4">
        <input-text
            v-model:content="measure"
            :label="props.label"
        />
        <ul class="flex space-x-1">
            <li
                v-for="(preset, i) in props.presets"
                :key="i"
            >
                <button-base
                    size="sm"
                    :style-type="measure === preset.value ? 'primary' : 'secondary'"
                    @click="measure = preset.value"
                >{{ preset.label }}</button-base>
            </li>
        </ul>
    </div>
</template>
