export default {
    install(app, options) {
        app.config.globalProperties.$page = {
            reload: () => document.location.reload(),
            alert: (str) => alert(str),
            confirm: (str) => confirm(str)
        };
        app.provide('$page', app.config.globalProperties.$page);
    }
}
