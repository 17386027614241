<template>
    <div>

        <div
            v-for="(subscribable, i) in _subscribables"
            v-bind:key="subscribable.id"
            class="grid-cols-8 gap-8 p-5 mb-8 bg-gray-100 md:grid md:bg-gray-50 rounded-xl md:p-0"
        >

            <div class="col-span-1 px-12 md:p-0">
                <media-bank-entries-display
                    :data-url="route('subscribable.show.featured-image', subscribable.id)"
                    class="flex justify-center mb-2 text-center rounded-full width-full"
                    style="aspect-ratio: 1 / 1; object-fit: cover;"
                ></media-bank-entries-display>

                <div class="mb-4 text-center" v-if="subscribable.provider.rating != 0">
                    <span class="text-lg">
                        <i :data-star="subscribable.provider.rating"></i>
                    </span>
                    <br>{{ subscribable.provider.rating }} / 5.0
                </div>
            </div>

            <div class="col-span-5">
                <div class="grid-cols-5 gap-8 p-5 mb-8 bg-gray-100 md:grid md:bg-gray-50 rounded-xl md:p-0">

                    <div class="col-span-5">

                        <span v-if="subscribable.has_wellness" class="mb-2 text-myflowGreen-800">
                            <i class="fa-regular fa-spa"></i>
                            {{ _mft('subscription:availableAsWellness') }}
                        </span>

                        <h2 class="mb-1 text-lg leading-6 text-myflowPurple-600">
                            <a :href="route('subscription.subscribable.show', subscribable.id )">
                                {{ subscribable.title }}
                            </a>
                        </h2>
                    </div>

                    <div class="col-span-2">
                        <p class="font-bold text-gray-900" v-if="subscribable.provider.name">
                            {{ subscribable.provider.name }}<br>
                            <span class="text-xs text-gray-600" v-if="subscribable.provider.title">{{ subscribable.provider.title }}</span>
                        </p>
                    </div>

                    <div class="col-span-3">
                        <span
                            v-html="subscribable.previewing ? subscribable.description.replace(/<\/?[^>]+(>|$)/g, ' ') : subscribable.description.substring(0, 200).replace(/<\/?[^>]+(>|$)/g, ' ') + '... '"
                        ></span>
                        <span v-on:click="toggleDescription(subscribable)" class="italic text-myflowPurple-800">
                            <br>{{ subscribable.previewing ? ' ' + _mft('shared:expand.showLess') : ' ' + _mft('shared:expand.showMore') }}
                        </span>
                    </div>
                </div>
            </div>


            <div class="col-span-2 mt-3 md:mt-0">
                <a
                    v-if="subscribable.available_for_purchase"
                    :href="route('subscription.subscribable.show', subscribable.id )" class="mb-3 myflow-basic-button"
                >
                    {{ _mft('subscription:goToSubscription') }}
                </a>
                <span class="inline-block mb-3 myflow-basic-button--state-disabled" v-else>{{ _mft('subscription:notAvailable') }}</span>
                <br>
                <b>{{ _mft('subscription:price') }}:</b>

                <span v-if="subscribable.lowest_purchase_price_sek === subscribable.highest_purchase_price_sek">
                    &nbsp;<i>{{ subscribable.lowest_purchase_price_sek }}</i>
                </span>
                <span v-else>
                    &nbsp;{{ _mft('shared:price.from') }} <i>{{ subscribable.lowest_purchase_price_sek }}</i> {{ _mft('shared:price.to') }} <i>{{ subscribable.highest_purchase_price_sek }}</i>
                </span>

                <hr class="mt-3 mb-3">

            </div>

            <div class="col-span-8">
                <hr class="hidden mt-2 mb-2 md:block">
            </div>

        </div>


    </div>
</template>
<script>
export default {
    name: 'SubscribableSelectGuide',
    props: {
        subscribables: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            _subscribables: []
        }
    },
    methods: {
        async getSubscribables() {
            let res = await axios.post(route('subscription.subscribables.filter'), {
                filters: []
            });
        },
        toggleDescription(subscribable) {
            subscribable.previewing = !subscribable.previewing
        },
    },
    mounted() {
        this._subscribables = this.subscribables.map(subscribable => {
            subscribable.previewing = false;
            return subscribable;
        });
    }
}
</script>
