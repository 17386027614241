<script setup>

import {onMounted, ref} from "vue";
import SingleFeature from "./SingleFeature.vue";
import ContainerWithTooltip from "../../tooltips/containerWithTooltip.vue";

const props = defineProps({
    shownFlows: {
        type: Array,
        required: true,
    }
})
const transitioned = ref(false)

const features = [
    {
        activeForFlows: ['more-leads'],
        image: 'https://myflow.io/wp-content/uploads/2024/08/leadhunte3.png.webp',
        title: 'Perfect match! Get started with leadhunter',
        body: '<p>Say hello to your AI-driven lead generator. Get tons of leads and then convert them into paying customers.</p>',
        readmore: '<h2>Lead Hunter</h2><p>Say hello to your AI-driven lead generator. Get tons of leads and then convert them into paying customers.</p><p>You’ll get started with ease in just a few minutes, and then the system works for you - no tech skills needed.</p>',
        checklist: [
            /* {
                title: 'Select specific target audience',
                done: false,
            },
            {
                title: 'Acquire potential leads',
                done: true,
            },
            {
                title: 'Create email content',
                done: true,
            },
            {
                title: 'Create tailor made landing page',
                done: true,
            },
            {
                title: 'Schedule automatic follow-ups',
                done: true,
            },
            {
                title: 'Preview and make final adjustments',
                done: false,
            },
            {
                title: 'Launch campaign',
                done: false,
            }, */
        ]
    },
    {
        activeForFlows: ['more-leads', 'increased-win-rate'],
        image: 'https://myflow.io/wp-content/uploads/2024/08/Asset-38organ.webp',
        title: 'Additional information on a landing page',
        body: '<p>Our beloved page builder makes it super simple to create and publish landing pages and complete websites.</p>',
        readmore: '<h2>Pages</h2><p>Create your own website with AI</p><p>Our beloved page builder makes it super simple to create and publish landing pages and complete websites.</p>',
        checklist: [
            /* {
                title: 'A first draft',
                done: true
            },
            {
                title: 'Preview and make final adjustments',
                done: false
            } */
        ]
    },
    {
        activeForFlows: ['digital-products'],
        image: 'https://myflow.io/wp-content/uploads/2024/08/Asset-38organ.webp',
        title: 'Sales page setup for your digital product',
        body: '<p>Our beloved page builder makes it super simple to create and publish landing pages and complete websites.</p>',
        readmore: '<h2>Pages</h2><p>Create your own website with AI</p><p>Our beloved page builder makes it super simple to create and publish landing pages and complete websites.</p>',
        checklist: [
            /* {
                title: 'A first draft',
                done: true
            },
            {
                title: 'Preview and make final adjustments',
                done: false
            } */
        ]
    },
    {
        activeForFlows: ['digital-products'],
        image: 'https://myflow.io/wp-content/uploads/2024/08/Asset-37organ.webp',
        title: 'Course setup & curriculum',
        body: '<p>Selling your content and services with myflow is super easy.</p><p>Card or invoice payments, order management, monthly instalments, automatic account creation, CRM integrations - all done in under a minute.</p>',
        readmore: '<h2>Sell with ease</h2><p>Selling your content and services with myflow is super easy.</p><p>Card or invoice payments, order management, monthly instalments, automatic account creation, CRM integrations - all done in under a minute.</p>',
        checklist: [
            /* {
                title: 'Setup your course with titles and descriptions',
                done: true
            },
            {
                title: 'Outline and create chapters',
                done: true
            },
            {
                title: 'Create and upload content',
                done: false
            },
            {
                title: 'Preview and make final adjustments',
                done: false
            } */
        ]
    },
    {
        activeForFlows: ['increased-win-rate'],
        image: 'https://myflow.io/wp-content/uploads/2024/08/Untitled-2-02.png.webp',
        title: 'Digital Quotes',
        body: '<p>Look professional using digital quoting and signing while we handle the reminders to the customer. Design visually appealing agreements or use our pre-made templates.</p>',
        readmore: '<h2>Close more deals faster</h2><p>Look professional using digital quoting and signing while we handle the reminders to the customer. Design visually appealing agreements or use our pre-made templates.</p>',
        checklist: [
            /* {
                title: 'Invoice payments setup for your digital product',
                done: true
            },
            {
                title: 'Card payments setup for your digital product',
                done: false
            } */
        ]
    },
    {
        activeForFlows: ['digital-products', 'increased-win-rate'],
        image: 'https://myflow.io/wp-content/uploads/2024/08/Asset-38organ.webp',
        title: 'Sell with ease',
        body: '<p>Selling your content and services with myflow is super easy.</p><p>Card or invoice payments, order management, monthly instalments, automatic account creation, CRM integrations - all done in under a minute.</p>',
        readmore: '<h2>Sell with ease</h2><p>Selling your content and services with myflow is super easy.</p><p>Card or invoice payments, order management, monthly instalments, automatic account creation, CRM integrations - all done in under a minute.</p>',
        checklist: [
            /* {
                title: 'Invoice payments setup for your digital product',
                done: true
            },
            {
                title: 'Card payments setup for your digital product',
                done: false
            } */
        ]
    },
    {
        activeForFlows: ['more-leads', 'digital-products', 'increased-win-rate'],
        image: 'https://myflow.io/wp-content/uploads/2024/07/Asset-8leadhunter.png.webp',
        title: '... and much more',
        body: '<p>One platform, many tools for success.</p><p>Use myflow to generate new revenue, acquire more customers, and save time every day. All the features you need  – and some AI on top of that.</p>',
        readmore: '<h5>... and much more</h5><p>One platform, many tools for success.</p><p>Use myflow to generate new revenue, acquire more customers, and save time every day. All the features you need  – and some AI on top of that.</p>',
        checklist: []
    },
]


onMounted(() => {
    setTimeout(() => {
        transitioned.value = true;
    }, 100);
})

</script>

<template>
    <div>
        <div class="@container">
            <SingleFeature
                v-for="(feature, i) in features"
                :key="i"
                :counter="i"
                :visible="shownFlows.some(flow => feature.activeForFlows.includes(flow))"
            >
                <template #image>
                    <img :src="feature.image" :alt="feature.title" class="w-full">
                </template>
                <template #body>
                    <h5>{{ feature.title }}</h5>
                    <div v-html="feature.body"></div>

                    <ul
                        v-if="feature.checklist.length > 0"
                    >
                        <li
                            v-for="(item, i) in feature.checklist"
                            :key="i"
                            class="flex space-x-2 mb-0.5"
                            :class="item.done ? 'text-gray-400' : 'text-gray-600'"
                        >
                            <container-with-tooltip
                                v-if="item.done"
                                tooltip-class="whitespace-nowrap rounded-md bg-white px-1 text-xs text-black py-0.5"
                            >
                                <i class="mt-1 fa-regular fa-square-check"></i>

                                <template #tooltip>
                                    Taken care of by myflow
                                </template>
                            </container-with-tooltip>
                            <i v-else class="mt-1 fa-regular fa-square"></i>
                            <span>{{ item.title }}</span>
                        </li>
                    </ul>
                </template>

                <template #readmore>
                    <div v-html="feature.readmore"></div>
                </template>
            </SingleFeature>
        </div>
    </div>
</template>

<style scoped>

</style>
