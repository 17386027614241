<script setup>
const props = defineProps({
    content: {
        type: Object,
        required: true,
    },
})
</script>
<template>
    <div class="flex flex-row justify-center w-full align-center">
        <div class="w-full">
            <h2
                class="mb-4 text-xl md:text-2xl lg:text-3xl"
                :class="'text-' + props.content.alignment"
            > {{ props.content.title}}</h2>
            <p
                :class="'text-' + props.content.alignment"
                v-html="props.content.body"
            />
        </div>
    </div>
</template>
