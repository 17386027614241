<template>
    <div class="relative transition-all duration-700" :class="embedContainerClasses">
        <whereby-embed
            :room="session_url"
            :displayname="display_name"
            people="off"
            lang="en"
            screenshare="on"
            minimal
            floatSelf="on"
            ref="wherebyEmbed"
            class="absolute inset-0"
        ></whereby-embed>
    </div>
</template>
<script>

export default {
    props: {
        session_url: String,
        display_name: String
    },
    data() {
        return {
            embedState: 'init',
            isNotChrome: true
        }
    },
    mounted() {
        this.$refs['wherebyEmbed'].addEventListener("ready", this.readyEventFired)
        this.$refs['wherebyEmbed'].addEventListener("join", this.joinEventFired)

        this.browserDetection();

        addEventListener('beforeunload', this.preventAccidentalNavigation, {capture: true});
    },
    methods: {
        readyEventFired(event) {
        },
        joinEventFired(event) {
            this.embedState = 'approved'
        },
        preventAccidentalNavigation(event) {
            event.preventDefault();
            return event.returnValue = _mft('coaching:session.embedded.leaving.areYouSure');
        },

        browserDetection() {
            if(navigator.userAgent.match(/chrome|chromium|crios/i)) {
                this.isNotChrome = false;
            }
        }
    },
    computed: {
        embedContainerClasses() {
            if( this.embedState === 'approved' ){
                return 'pb-[56.25%]'
            } else {
                return 'pb-[200%] md:pb-[175%] lg:pb-[125%] xl:pb-[100%]'
            }
        }
    }
}
</script>
