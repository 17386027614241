<template>
  <div class="flex justify-between items-end">
    <label
      class="block text-sm font-medium"
    >{{ translatedLabel }}</label>
    <span
      class="inline-block text-xs opacity-80"
      v-if="required"
    >{{ _mft('form:required') }}</span>
  </div>
</template>
<script>
export default {
  props: {
    label: String,
    required: {
      type: Boolean,
      default: false
    }
  },
    computed: {
      translatedLabel() {
        if (this.label?.match(/^[^:]+:[^:]+$/)) {
          return _mft(this.label)
        } else {
          return this.label
        }
      }
    }
}
</script>
