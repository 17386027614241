<script setup>
import { computed, onMounted, ref, reactive, watch } from 'vue'

const props = defineProps({
    expertContractId: {
        type: Number,
        required: true
    },
})

const state = reactive({
    creating: false,
    submitting: false,
    slideoutOpen: false,
    loading: false
})

const audits = ref([])

const loadAudits = async () => {
    state.loading = true;
    state.slideoutOpen = true;
    const response = await axios.get(route('expert-contracts.audits', props.expertContractId));
    audits.value = response.data.data;
    state.loading = false;
}

</script>
<template>
    <div class="relative">
        <button
            class="myflow-basic-button--warning whitespace-nowrap"
            @click="loadAudits()"
        >
            {{ _mft('expertContract:auditRestore.buttonLabel') }}
        </button>
    </div>

            <slideout-with-slots
                v-if="state.slideoutOpen"
                title="Tidigare versioner"
                @close="state.slideoutOpen = false"
            >
                <template #body>
                    <div class="pb-24">
                        <ul class="divide-y">
                            <li
                                v-for="audit in audits"
                                :key="audit.id"
                                class="mb-4"
                            >
                                <div class="p-2">
                                    <p>{{ _mft('shared:date.createdAt') }} <b>{{ audit.created_at }}</b> {{ _mft('shared:by') }} <b>{{ audit.user_name }}</b></p>

                                    <general-modal>
                                        <template v-slot:header>Konfirmera återställning</template>
                                        <template v-slot:body>
                                            <div class="break-words whitespace-normal">
                                                <p>
                                                    {{ _mft('expertContract:auditRestore.restoreIntro', {createdAt: audit.created_at, userName: audit.user_name}) }}
                                                </p>
                                                <a
                                                    class="myflow-basic-button--danger"
                                                    :href="route('expert-contracts.restoreFromAudit', [audit.id, props.expertContractId])"
                                                >
                                                    {{ _mft('expertContract:auditRestore.restoreVersion') }}
                                                </a>
                                            </div>
                                        </template>
                                        <template v-slot:open>
                                            <span class="cursor-pointer text-myflowPurple-900 hover:text-myflowRed-700">
                                                {{ _mft('expertContract:auditRestore.restore') }}
                                            </span>
                                        </template>
                                    </general-modal>
                                </div>
                            </li>
                        </ul>
                        <p
                            v-if="(audits.length === 0) && !state.loading"
                            class="text-gray-400 font-italic"
                        >
                            {{ _mft('expertContract:auditRestore.noPreviousVersionsFound') }}
                        </p>
                        <mf-spinner-medium
                            v-if="state.loading"
                            class="w-24 h-12"
                        />
                    </div>
                </template>
            </slideout-with-slots>
</template>
