<template>
    <div v-if="value">
        <input-text
            label="Rubrik"
            :required="false"
            v-model:content="value.title"
        />
        <input-editor
            label="Innehåll"
            :required="false"
            v-model:content="value.body"
        />
        <div v-for="(link, idx) in value.links" :key="idx" class="flex items-center w-full">
            <input-text maxlength="255" :label="`Länktext ${idx + 1}`" class="w-full mr-2" required v-model:content="link.text" />
            <input-text maxlength="255" inputtype="url" :label="`Länk ${idx + 1}`" class="w-full mr-2" required v-model:content="link.href" />
            <button class="flex items-center" v-if="value.links.length > 1" type="button" @click="deleteLine(idx)"><TrashIcon class="w-5 h-5 text-myflowRed" aria-hidden="true" /></button>
        </div>
        <button type="button" class="mb-3 myflow-basic-button" @click="addLine">Lägg till rad</button>
    </div>
    <mf-spinner-medium v-else class="w-16 h-8"></mf-spinner-medium>
</template>
<script>
import {
    TrashIcon
} from '@heroicons/vue/outline';

export default {
    components: {
        TrashIcon
    },
    props: {
        modelValue: {},
    },
    emits: [
        'update:modelValue'
    ],
    data() {
        return {
            value: null
        };
    },
    async mounted() {
        this.captureModelValue();
    },
    watch: {
        modelValue() {
            if (JSON.stringify(this.value) === JSON.stringify(this.modelValue)) { return; }
            this.captureModelValue();
        },
        value: {
            handler() {
                this.$emit('update:modelValue', JSON.parse(JSON.stringify(this.value)));
            },
            deep: true
        }
    },
    methods: {
        captureModelValue() {
            const value = this.modelValue ? JSON.parse(JSON.stringify(this.modelValue)) : {};
            if (!value.title) { value.title = null; }
            if (!value.body) { value.body = null; }
            if (!value.links) { value.links = [{text: null, href: 'https://'}]; }
            this.value = value;
        },
        addLine() {
            this.value.links.push({text: null, href: 'https://'});
        },
        deleteLine(idx) {
            this.value.links.splice(idx, 1);
        }
    },
}
</script>
