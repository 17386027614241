<template>
    <div class="pb-16">
        <div class="relative">
            <div class="grid grid-cols-6 mb-10 space-y-6 lg:mb-24 xl:gap-x-12 xl:gap-y-8 xl:space-y-0">
                <!-- CATEGORIES -->
                <div class="col-span-6 2xl:col-span-2">
                    <h2>
                        Kategorier
                    </h2>
                    <div class="flex flex-wrap w-full -ml-2" :class="[selectedCategory.label]">
                        <div v-for="(category, i) in categories" v-bind:key="i" v-on:click="toggleCategory(category)">

                            <div class="m-2 text-center rounded-full">
                                <div class="px-4 rounded-full lg:px-4"
                                    :class="[selectedCategory === category ? optionSelectedClasses : optionNotSelectedClasses, optionBaseClasses]">
                                    <div class="relative text-center transition-all">
                                        <span :class="optionLabelClasses">
                                            {{ category.label }}
                                        </span>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <!-- TYPES -->
                <div class="col-span-6 2xl:col-span-4">
                    <h2>
                        <span v-if="selectedCategory.label">
                            Inom {{ selectedCategory.label }} vill jag se innehåll som är...
                        </span>
                        <span v-else>
                            Typ av utbildning
                        </span>
                    </h2>
                    <div class="w-full grid-cols-2 mb-1 text-xs lg:text-sm lg:grid lg:gap-x-8">
                        <div v-for="(filter, i) in filters" v-bind:key="i" class="w-full h-auto mt-2 mb-2 mr-4">
                            <p class="mb-2 text-gray-600">{{ filter.label }}</p>

                            <div class="" v-if="filter.type === 'default'">
                                <ul class="flex flex-grow w-full mb-5 rounded-full">
                                    <li v-for="(option, o) in filter.options" v-bind:key="o"
                                        class="flex-grow px-4 basis-0 lg:px-8 first:rounded-l-lg lg:first:rounded-l-full lg:first:pl-10 last:rounded-r-lg lg:last:rounded-r-full lg:last:pr-10 max-w-half"
                                        :class="[option.selected ? optionSelectedClasses : optionNotSelectedClasses, optionBaseClasses]"
                                        v-on:click="toggleFilter(filter, option)">
                                        <div class="relative text-center transition-all">
                                            <span :class="optionIconClasses" v-html="option.icon"></span>
                                            <span :class="optionLabelClasses">
                                                {{ option.label }}
                                            </span>
                                        </div>

                                    </li>
                                </ul>

                            </div>
                            <course-filter-rating v-if="filter.type === 'rating'" :filter="filter"
                                :optionBaseClasses="optionBaseClasses" :optionSelectedClasses="optionSelectedClasses"
                                :optionNotSelectedClasses="optionNotSelectedClasses" :optionIconClasses="optionIconClasses"
                                :optionLabelClasses="optionLabelClasses" v-model:options="filter.options"
                                @toggleFilter="clearAndSetAbove(filter, $event)" @clearFilter="clearFilter(filter)" />
                        </div>
                    </div>
                </div>

                <!-- FORMAT -->
                <div class="col-span-6">
                    <h2>
                        Format
                    </h2>
                    <div class="space-y-5 lg:grid lg:grid-cols-4 lg:gap-8 lg:space-y-0 lg:items-stretch">
                        <ul class="rounded-full">
                            <li class="h-full px-4 rounded-xl lg:px-8 lg:last:pr-10"
                                :class="[talkFilterSelected, optionBaseClasses]" v-on:click="talkFilter()">
                                <div class="relative pt-2 transition-all">
                                    <div class="flex justify-between w-full space-x-4">
                                        <span class="font-bold">
                                            Talk
                                        </span>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6"
                                            fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z" />
                                        </svg>
                                    </div>
                                    <p class="block text-xs leading-tight">Ett powertalk! Lyssna på en favoritexpert och få massiv utveckling med minimal ansträngning. En perfekt intro till ett ämne som du är nyfiken på.</p>
                                </div>
                            </li>
                        </ul>
                        <ul class="rounded-full">
                            <li class="h-full px-4 rounded-xl lg:px-8 lg:last:pr-10"
                                :class="[minuteFilterSelected, optionBaseClasses]" v-on:click="minuteFilter()">
                                <div class="relative pt-2 transition-all">
                                    <div class="flex justify-between w-full space-x-4">
                                        <span class="font-bold">
                                            Minutes
                                        </span>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6"
                                            fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z" />
                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z" />
                                        </svg>
                                    </div>
                                    <p class="block text-xs leading-tight">Mikroboost! Lär dig en massa nytt, på nolltid. Minutes är perfekt för dig som ständigt vill hålla hjärnkontoret igång, få flow och ha koll på senaste nytt.</p>
                                </div>
                            </li>
                        </ul>
                        <ul class="rounded-full">
                            <li class="h-full px-4 rounded-xl lg:px-8 lg:last:pr-10"
                                :class="[nanoClassFilterSelected, optionBaseClasses]" v-on:click="nanoFilter()">
                                <div class="relative pt-2 transition-all">
                                    <div class="flex justify-between w-full space-x-4">
                                        <span class="font-bold">
                                            Nano Course
                                        </span>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6"
                                                fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                    d="M13 10V3L4 14h7v7l9-11h-7z" />
                                        </svg>
                                    </div>
                                    <p class="block text-xs leading-tight">Lärande i bite-size! Korta utbildningar för snabb inlärning och möjlighet att repetera. Kurserna är max 45 minuter.</p>
                                </div>
                            </li>
                        </ul>
                        <ul class="rounded-full">
                            <li class="h-full px-4 rounded-xl lg:px-8 lg:last:pr-10"
                                :class="[masterClassFilterSelected, optionBaseClasses]" v-on:click="masterFilter()">
                                <div class="relative pt-2 transition-all">
                                    <div class="flex justify-between w-full space-x-4">
                                        <span class="font-bold">
                                            Master Class
                                        </span>
                                        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6"
                                                fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="1.5">
                                                <path d="M12 14l9-5-9-5-9 5 9 5z" />
                                                <path
                                                    d="M12 14l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14z" />
                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                    d="M12 14l9-5-9-5-9 5 9 5zm0 0l6.16-3.422a12.083 12.083 0 01.665 6.479A11.952 11.952 0 0012 20.055a11.952 11.952 0 00-6.824-2.998 12.078 12.078 0 01.665-6.479L12 14zm-4 6v-7.5l4-2.222" />
                                        </svg>
                                    </div>
                                    <p class="block text-xs leading-tight">Få mästarskills! Lärande med fördjupning inom ett specifikt ämne. Ofta med tillhörande arbetsuppgifter och längre än 45 minuter.</p>
                                </div>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>


            <h2
                class="sticky left-0 right-0 p-2 mb-4 text-xs font-light text-center text-gray-400 transition-opacity bg-white rounded-md shadow-md bottom-3 lg:bg-transparent lg:shadow-none lg:p-0 lg:text-lg lg:relative">
                <span v-if="loadingStatus === 'loading' || loadingStatus === 'unloading'" class="text-pink-50">
                    <mf-spinner-medium class="w-24 h-12" />

                </span>
                <span v-else>
                    <span v-if="filteredCourses.length > 0">
                        <span v-if="areFiltersApplied">
                            Hittade {{ resultCountString }}<span v-if="this.selectedCategory.name"> i {{
                                this.selectedCategory.name }}</span>:
                        </span>
                        <span v-else>
                            Visar allt innehåll:
                        </span>
                    </span>
                    <span v-if="filteredCourses.length === 0 && loadingStatus === 'loaded'">
                        Oops, filtreringen gav ingen träff. Testa gärna med något annat!
                    </span>

                </span>
            </h2>

            <div
                class="sticky left-0 right-0 p-2 mb-4 font-light text-center text-gray-600 transition-opacity bg-white rounded-md shadow-md bottom-3 lg:bg-transparent lg:shadow-none lg:p-0 lg:relative"
                v-if="loadingStatus !== 'loading' || loadingStatus !== 'unloading'"
            >
                <span class="text-center">
                    <span class="p-3 cursor-pointer" :class="sortOrder === 'popularity' ? 'text-myflowPurple-900 underline' : 'text-gray-600'" v-on:click="sortOrderChanged('popularity')">
                        Sortera på popularitet
                    </span>
                    <span class="p-3 cursor-pointer" :class="sortOrder === 'rating' ? 'text-myflowPurple-900 underline' : 'text-gray-600'" v-on:click="sortOrderChanged('rating')">
                        Sortera på betyg
                    </span>
                    <span class="p-3 cursor-pointer" :class="sortOrder === 'price' ? 'text-myflowPurple-900 underline' : 'text-gray-600'" v-on:click="sortOrderChanged('price')">
                        Sortera på pris
                    </span>
                </span>
            </div>

            <div class="lg:bg-white lg:rounded-xl lg:shadow-md lg:divide-y" v-if="filteredCourses.length > 0">
                <div
                    v-for="course in filteredCourses"
                    v-bind:key="course.id"
                    class="py-4 mb-4 bg-white shadow-md rounded-xl lg:bg-transparent lg:rounded-none lg:shadow-none lg:mb-0"
                >
                    <course-listitem :content="course"
                        :class="[{ 'translate-y-1 opacity-0': loadingStatus === 'unloading' }, { '-translate-y-1 opacity-0': loadingStatus === 'loading' }, { 'translate-y-0 opacity-1': loadingStatus === 'loaded' }]"
                    />
                </div>
            </div>


        </div>
        <div class="w-full" id="trailer-player-teleport-target"></div>
    </div>
</template>
<script>
export default {
    props: {
        expert: {
            type: Number,
            required: false,
            default: null
        }
    },
    data: function () {
        return {
            loadingStatus: '',
            selectedCategory: {},
            optionBaseClasses: 'py-1 lg:py-1 bg-white border-r last:border-r-0 cursor-pointer',
            optionSelectedClasses: 'bg-myflowPurple shadow-none text-white bg-gradient-to-b from-myflowPurple-500 to-myflowPurple-700',
            optionNotSelectedClasses: 'text-myflowPurple shadow-md',
            optionIconClasses: 'block w-6 h-6 lg:w-6 lg:h-6 mx-auto',
            optionLabelClasses: 'block text-center text-xs',
            categories: [],
            filters: [],
            appliedFilters: {},
            courses: [],
            filteredCourses: [],
            sortOrder: 'popularity',
        }
    },
    methods: {
        async loadFilterOptions() {
            try {
                let response = await axios.get(route('courseGuide.filteroptions'));

                this.categories = response.data.categories
                this.filters = response.data.filtergroups.filters
            } catch (error) {
                console.log(error);
            }
        },
        toggleCategory(category) {
            // this.doLoadingAnimation();
            this.transitionCoursesOut()

            if (this.selectedCategory === category) {
                this.selectedCategory = {};
            } else {
                this.selectedCategory = category;
            }

            this.setAppliedFilters()
            this.loadCourses()
        },
        sortOrderChanged(option) {
            this.sortOrder = option
            this.transitionCoursesOut()
            this.loadCourses()
        },
        toggleFilter(filter, option) {
            option.selected = !option.selected
            this.setAppliedFilters()
        },
        transitionCoursesOut() {
            let self = this
            self.loadingStatus = 'unloading'
        },
        transitionCoursesIn() {
            let self = this
            self.loadingStatus = 'loading'
            setTimeout(function () {
                self.loadingStatus = 'loaded'
            }, 200)
        },
        setAppliedFilters() {
            let appliedFilters = {}
            this.filters.forEach(function (filter, i) {
                filter.options.forEach(function (option, o) {
                    if (option.selected) {
                        if (!appliedFilters[filter.name]) {
                            appliedFilters[filter.name] = []
                        }
                        appliedFilters[filter.name].push(option.value)
                    }
                })
            })
            this.appliedFilters = appliedFilters
        },
        filterCourses() {
            let self = this
            let included = true

            this.transitionCoursesOut()

            setTimeout(function () {
                self.filteredCourses = []
                self.courses.forEach(function (c, i) {
                    included = true

                    Object.keys(self.appliedFilters).forEach(key => {
                        if (!self.appliedFilters[key].includes(c.filterdata[key])) {
                            included = false
                        }
                    })
                    if (included) {
                        self.filteredCourses.push(c)
                    }
                })

                if (self.courses.length > 0) {
                    self.transitionCoursesIn()
                }
            }, 100)
        },
        async loadCourses() {
            this.loadingStatus = 'loading'
            let response = await axios.get(route('courses.index'), {
                params: {
                    category_id: this.selectedCategory.id,
                    expert: this.expert,
                    sort_order: this.sortOrder
                }
            })
            this.courses = response.data.data
            this.filteredCourses = this.courses
            this.transitionCoursesIn()

            if (Object.keys(this.appliedFilters).length > 0) {
                this.filterCourses()
            }

        },
        talkFilter() {
            this.clearAllFilters();
            let _this = this
            this.filters.forEach(function (filter, i) {
                filter.options.forEach(function (option, o) {
                    if (option.value == 'free' && filter.name == 'purchase_method') {
                        _this.toggleFilter(filter, option);
                    }
                    if (option.value == 'medium' && filter.name == 'playtime_classification') {
                        _this.toggleFilter(filter, option);
                    }
                    if (option.value == false && filter.name == 'multichapter') {
                        _this.toggleFilter(filter, option);
                    }
                })
            })
        },
        nanoFilter() {
            this.clearAllFilters();
            let _this = this
            this.filters.forEach(function (filter, i) {
                filter.options.forEach(function (option, o) {
                    if (option.value == 'medium' && filter.name == 'playtime_classification') {
                        _this.toggleFilter(filter, option);
                    }
                    if (option.value == true && filter.name == 'multichapter') {
                        _this.toggleFilter(filter, option);
                    }
                })
            })
        },
        masterFilter() {
            this.clearAllFilters();
            let _this = this
            this.filters.forEach(function (filter, i) {
                filter.options.forEach(function (option, o) {
                    if (option.value == 'cash_sek' && filter.name == 'purchase_method') {
                        _this.toggleFilter(filter, option);
                    }
                    if (option.value == 'long' && filter.name == 'playtime_classification') {
                        _this.toggleFilter(filter, option);
                    }
                    if (option.value == true && filter.name == 'multichapter') {
                        _this.toggleFilter(filter, option);
                    }
                })
            })
        },
        minuteFilter() {
            this.clearAllFilters();
            let _this = this
            this.filters.forEach(function (filter, i) {
                filter.options.forEach(function (option, o) {
                    if (option.value == 'free' && filter.name == 'purchase_method') {
                        _this.toggleFilter(filter, option);
                    }
                    if (option.value == 'short' && filter.name == 'playtime_classification') {
                        _this.toggleFilter(filter, option);
                    }
                    if (option.value == false && filter.name == 'multichapter') {
                        _this.toggleFilter(filter, option);
                    }
                })
            })
        },
        async clearAllFilters() {
            let appliedFilters = {}
            this.filters.forEach(function (filter, i) {
                filter.options.forEach(function (option, o) {
                    option.selected = false;
                })
            })
            this.appliedFilters = appliedFilters
        },
        clearFilter(filter) {
            filter.options.forEach(function (option, o) {
                option.selected = false
            })
            this.setAppliedFilters()
        },
        clearAndSetAbove(filter, setOption) {
            filter.options.forEach(function (option, o) {
                option.selected = false
                if (option.value >= setOption.value) {
                    option.selected = true
                }
            })
            this.setAppliedFilters()
        }
    },
    computed: {
        talkFilterSelected() {
            let lenghtFilters = Object(this.filters[1]);
            let lenghtOptions = Object(lenghtFilters["options"]);

            let chapterFilters = Object(this.filters[2]);
            let chapterOptions = Object(chapterFilters["options"]);

            if (
                (Object(lenghtOptions[1]).selected == true) &&
                (Object(lenghtOptions[0]).selected == null || Object(lenghtOptions[0]).selected == false) &&
                (Object(lenghtOptions[2]).selected == null || Object(lenghtOptions[2]).selected == false) &&

                (Object(chapterOptions[0]).selected == true) &&
                (Object(chapterOptions[1]).selected == null || Object(chapterOptions[1]).selected == false)
            ) {
                return this.optionSelectedClasses;
            } else {
                return this.optionNotSelectedClasses;
            }
        },
        minuteFilterSelected() {
            let lenghtFilters = Object(this.filters[1]);
            let lenghtOptions = Object(lenghtFilters["options"]);

            let chapterFilters = Object(this.filters[2]);
            let chapterOptions = Object(chapterFilters["options"]);

            if (
                (Object(lenghtOptions[0]).selected == true) &&
                (Object(lenghtOptions[1]).selected == null || Object(lenghtOptions[1]).selected == false) &&
                (Object(lenghtOptions[2]).selected == null || Object(lenghtOptions[2]).selected == false) &&

                (Object(chapterOptions[0]).selected == true) &&
                (Object(chapterOptions[1]).selected == null || Object(chapterOptions[1]).selected == false)
            ) {
                return this.optionSelectedClasses;
            } else {
                return this.optionNotSelectedClasses;
            }
        },
        nanoClassFilterSelected() {
            let lenghtFilters = Object(this.filters[1]);
            let lenghtOptions = Object(lenghtFilters["options"]);

            let chapterFilters = Object(this.filters[2]);
            let chapterOptions = Object(chapterFilters["options"]);

            if (
                (Object(lenghtOptions[1]).selected == true) &&
                (Object(lenghtOptions[0]).selected == null || Object(lenghtOptions[0]).selected == false) &&
                (Object(lenghtOptions[2]).selected == null || Object(lenghtOptions[2]).selected == false) &&

                (Object(chapterOptions[1]).selected == true) &&
                (Object(chapterOptions[0]).selected == null || Object(chapterOptions[0]).selected == false)
            ) {
                return this.optionSelectedClasses;
            } else {
                return this.optionNotSelectedClasses;
            }
        },
        masterClassFilterSelected() {
            let purchaseFilters = Object(this.filters[0]);
            let purchaseOptions = Object(purchaseFilters["options"]);

            let lenghtFilters = Object(this.filters[1]);
            let lenghtOptions = Object(lenghtFilters["options"]);

            let chapterFilters = Object(this.filters[2]);
            let chapterOptions = Object(chapterFilters["options"]);

            if (
                (Object(purchaseOptions[2]).selected == true) &&
                (Object(purchaseOptions[0]).selected == false || Object(purchaseOptions[0]).selected == false) &&

                (Object(lenghtOptions[2]).selected == true) &&
                (Object(lenghtOptions[0]).selected == false || Object(lenghtOptions[0]).selected == false) &&

                (Object(chapterOptions[1]).selected == true) &&
                (Object(chapterOptions[0]).selected == null || Object(chapterOptions[0]).selected == false)
            ) {
                return this.optionSelectedClasses;
            } else {
                return this.optionNotSelectedClasses;
            }
        },
        resultCountString() {
            switch (this.filteredCourses.length) {
                case 0:
                    return 'ingen träff';
                case 1:
                    return 'en träff';
                case 2:
                    return 'två träffar';
                case 3:
                    return 'tre träffar';
                case 4:
                    return 'fyra träffar';
                case 5:
                    return 'fem träffar';
                case 6:
                    return 'sex träffar';
                case 7:
                    return 'sju träffar';
                case 8:
                    return 'åtta träffar';
                case 9:
                    return 'nio träffar';
                default:
                    return this.filteredCourses.length + ' träffar';
            }
        },
        areFiltersApplied() {
            if (Object.keys(this.appliedFilters).length === 0 && Object.keys(this.selectedCategory).length === 0) {
                return false;
            } else {
                return true;
            }
        }
    },
    mounted: function () {
        this.loadFilterOptions()
        this.loadCourses()

        this.categories.forEach(category => {
            category.selected = false
        })
    },
    watch: {
        filters: {
            deep: true,
            handler: function () {
                this.filterCourses()
            }
        },
    },
}
</script>
<style lang="scss" scoped>
@for $i from 1 through 100 {
    .content-cards>div:nth-child(#{$i}) {
        transition: all 0.2s ($i * 0.02 + s);
    }
}
.max-w-half {
    max-width: 50%;
}
</style>
