<script setup>
    import { ref, reactive, computed, onMounted, watch } from 'vue'

    const props = defineProps({
        location: {
            type: String,
            required: false,
            default: ''
        },
        embedMeeting: {
            type: Boolean,
            required: false,
            default: false,
        }
    })

    const meetingLocationType = ref(null)
    const meetingLocationIsUrl = ref(false)

    const meetingOptions = reactive({
        meeting_location_type_texts: {
            'Google Meet': _mft('location:googleMeet.description'),
            'Microsoft Teams': _mft('location:microsoftTeams.description'),
            'Whereby': _mft('location:whereby.description'),
            'Zoom': _mft('location:zoom.description'),
            'Fysisk plats': _mft('location:physicalLocation.description'),
            'Telefon': _mft('location:phone.description'),
            'Annan': _mft('location:other.description'),
        }
    })

    const meetingLocationLabel = computed(() => {
        return meetingOptions.meeting_location_type_texts[meetingLocationType.value] ? meetingOptions.meeting_location_type_texts[meetingLocationType.value] : _mft('location:meetingLocation.label')
    })

    const presetDeterminedLocationType = (url) => {
        if (!url) return
        let type = null
        if (url.includes('meet.google.com')) {
            type = 'Google Meet'
        } else if (url.includes('teams.microsoft.com')) {
            type = 'Microsoft Teams'
        } else if (url.includes('whereby.com')) {
            type = 'Whereby'
        } else if (url.includes('zoom.us')) {
            type = 'Zoom'
        }

        if (type) {
            meetingLocationType.value = type
            meetingLocationIsUrl.value = true
        }

        if (url.includes('http')) {
            meetingLocationIsUrl.value = true
        }
    }

    const determineIsEmbeddable = (url) => {
        if (!url) return
        if (url.includes('whereby.com')) {
            isEmbeddable.value = true
            embedMeeting.value = false
        } else {
            isEmbeddable.value = false
            embedMeeting.value = false
        }
    }

    onMounted(() => {
        presetDeterminedLocationType(props.location);
    })

</script>
<template>
    <div
        v-if="meetingLocationIsUrl"
    >
        {{ meetingLocationLabel }}:
        <a
            :href="props.location"
            target="_blank"
            rel="noopener noreferrer"
        >
            {{ props.location }}
        </a>
    </div>
    <div v-else>
        {{ meetingLocationLabel }}: {{ props.location }}
    </div>
</template>
