<script setup>

import { computed } from 'vue'

const props = defineProps({
    duration: {
        type: Number,
        required: true
    },
    size: {
        type: Number,
        required: false,
        default: 50
    },
    ringClass: {
        type: String,
        required: false,
        default: 'bg-gray-400'
    },
    backgroundClass: {
        type: String,
        required: false,
        default: 'bg-white'
    },
    hubcapSizePercent: {
        type: Number,
        required: false,
        default: 80
    }
})

const countdownStyle = computed(() => {
    return {
        width: props.size + 'px',
        height: props.size + 'px',
    }
})

const holderStyle = computed(() => {
    return {
        clip: 'rect(0px, ' + props.size + 'px, ' + props.size + 'px, ' + props.size / 2  + 'px)',
    }
})

const fillStyle = computed(() => {
    return {
        clip: 'rect(0px, ' + props.size / 2 + 'px, ' + props.size + 'px, 0px)',
    }
})

const duration = computed(() => {
    return (props.duration / 2) + 'ms'
})

const hubcapSize = computed(() => {
    return props.hubcapSizePercent + '%'
})

const hubcapMargin = computed(() => {
    return '-' + props.hubcapSizePercent / 2 + '%'
})

</script>
<template>
    <div>
        <div id="countdown" :style="countdownStyle">
            <div
                class="hubcap"
                :class="backgroundClass"
            />
            <div
                class="holder left"
                :class="backgroundClass"
                :style="holderStyle"
            >
                <div
                    class="fill"
                    :class="props.ringClass"
                    :style="fillStyle"
                ></div>
            </div>
            <div
                class="holder right"
                :class="backgroundClass"
                :style="holderStyle"
            >
                <div
                    class="fill"
                    :class="props.ringClass"
                    :style="fillStyle"
                ></div>
            </div>
        </div>
    </div>
</template>
<style scoped>
    #countdown {
        position: relative;
    }

    .hubcap {
        z-index: 4;
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        top: 50%;
        left: 50%;
        width: v-bind('hubcapSize');
        height: v-bind('hubcapSize');
        margin-left: v-bind('hubcapMargin');
        margin-top: v-bind('hubcapMargin');
        border-radius: 100%;
    }

    .holder {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        top:0;
    }

    .fill {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 100%;
    }

    .left .fill {
        z-index: 1;
        animation: fill v-bind('duration') linear both;
    }

    .right {
        z-index: 3;
        transform: rotate(180deg);
    }

    .right .fill {
        z-index: 3;
        animation: fill v-bind('duration') linear both ;
        animation-delay: v-bind('duration');
    }

    @keyframes fill {
        0%{transform:rotate(180deg);}
        100%{transform:rotate(360deg);}
    }
</style>
