<script setup>
import { onMounted, ref, computed, inject, watch } from 'vue';
import axios from 'axios';

const props = defineProps({
    previousQuestion: Object,
    question: String,
    answer: String,
    activeChannel: Object,
    enabled: Boolean,
});

const question = ref(props.question);
const enabled = ref(props.enabled);

const progressBarClass = computed(() => {
    if (props.previousQuestion === null) {
        return 'bg-gray-500';
    }

    if (props.previousQuestion === 0) {
        return 'bg-red-500 w-0';
    }

    if (props.previousQuestion === 25) {
        return 'bg-orange-500 w-1/4';
    }

    if (props.previousQuestion === 50) {
        return 'bg-orange-500 w-1/2';
    }

    if (props.previousQuestion === 75) {
        return 'bg-yellow-500 w-3/4';
    }

    if (props.previousQuestion === 100) {
        return 'bg-green-500 w-full';
    }
});

</script>

<template>
    <div>
        <div
            v-if="enabled"
            class="mb-3"
        >
            <div class="text-xs text-gray-400">{{ question }}</div>
            <div>{{ answer }}</div>
            <div class="flex h-4 w-full">
                <div
                    v-if="previousQuestion !== null && typeof(previousQuestion) !== 'undefined'"
                    class="relative flex justify-end rounded-full pr-1 text-white min-w-7 text-xxs"
                    :class="progressBarClass"
                >
                    <div >
                        {{ previousQuestion }}%
                    </div>
                </div>
                <div
                    v-else
                    class="italic text-xs"
                >
                    No answer
                </div>
            </div>
        </div>
    </div>
</template>
