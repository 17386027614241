<script setup>

    import { onMounted, ref } from "vue";

    const emit = defineEmits(['completed']);
    const fadeIn = ref(false);
    const messages = [
        {
            label: '',
            duration: 3000,
        },
        {
            label: '',
            duration: 800,
        },
        {
            label: 'Optimizing potential',
            duration: 2100,
        },
        {
            label: '',
            duration: 800,
        },
        {
            label: '',
            duration: 800,
        },
        {
            label: 'Perspecting outlook',
            duration: 1600,
        },
        {
            label: '',
            duration: 800,
        },
        {
            label: 'Ordering sushi',
            duration: 900,
        },
        {
            label: '',
            duration: 800,
        },
        {
            label: 'Sorry, wrong window!',
            duration: 1300,
        },
        {
            label: '',
            duration: 800,
        },
        {
            label: 'Strategizing choices',
            duration: 1800,
        },
        {
            label: '',
            duration: 800,
        },
        {
            label: 'Taking you to your report...',
            duration: 2100,
        },
    ]

    const currentMessage = ref(null);

    onMounted(() => {
        setTimeout(function(){
            fadeIn.value = true;
        }, 2000)

        let i = 0;
        messages.forEach((message, index) => {
            setTimeout(() => {
                currentMessage.value = index;
            }, i);
            i += message.duration;
            if (index === messages.length - 1) {
                setTimeout(() => {
                    emit('completed')
                }, i);
            }
        });
    });

</script>

<template>
    <div
        class="text-white text-center transition-opacity duration-1000"
        :class="fadeIn ? 'opacity-100' : 'opacity-0'"
    >
        <h1 class="text-3xl mb-2">That's it!</h1>
        <p class="mb-12 text-md">
            We just need a few moments to process your data
        </p>
        <ul class="relative h-4 w-full text-center text-md">
            <li
                v-for="(message, i) in messages"
                class="absolute inset-0 pointer-events-none transition-all duration-700"
                :class="i === currentMessage ? 'opacity-100' : currentMessage > i ? 'opacity-0 -translate-x-24' : 'opacity-0 translate-x-24'"
            >
                {{ message.label }}
            </li>
        </ul>
    </div>
</template>

<style scoped>

</style>
