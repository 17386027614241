<script setup>
    import * as CronofyElements from "cronofy-elements";
    import { onMounted, reactive, ref, watch } from 'vue'

    const emit = defineEmits(['dateTimeSlotSelected', 'cronofyError'])
    const cronofyLoaded = ref(false)

    const props = defineProps({
        cronofyAccountId: {
            type: String,
            required: true,
            default: null,
        },
        embedToken: {
            type: String,
            required: true,
            default: null,
        },
        availabilityRuleIds: {
            type: Array,
            required: false,
            default: () => ['myflow'],
        },
        startDatetime: {
            type: String,
            required: true,
        },
        endDatetime: {
            type: String,
            required: true,
        },
        requiredDuration: {
            type: Number,
            required: false,
            default: 60,
        },
        bufferMinutesAfter: {
            type: Number,
            required: false,
            default: 0,
        }
    })

    const cronofy = reactive({
        embedToken: null,
        nonce: null,
        timezone: null,
    });

    onMounted(() => {
        initiateDateTimePicker();
    });

    const initiateDateTimePicker = () => {
        const startDatetime = new Date(props.startDatetime).toJSON()
        const endDatetime = new Date(props.endDatetime).toJSON()

        try {
            CronofyElements.DateTimePicker({
                element_token: props.embedToken,
                target_id: "cronofy-date-time-picker",
                data_center: "de",
                config: {
                    logs: 'info',
                },
                availability_query: {
                    participants: [
                        {
                            required: "all",
                            members: [
                                {
                                    sub: props.cronofyAccountId,
                                    managed_availability: true,
                                    availability_rule_ids: props.availabilityRuleIds,
                                },
                            ],
                        }
                    ],
                    required_duration: { minutes: props.requiredDuration },
                    query_periods: [
                        {
                            "start": startDatetime,
                            "end": endDatetime
                        },
                    ],
                    buffer: {
                        "before": { "minutes": props.bufferMinutesAfter },
                        "after": { "minutes": props.bufferMinutesAfter },
                    }

                },
                styles: {
                    prefix: "myflow-cronofy-date-time-picker"
                },
                callback: data => {
                    if (data.notification.type === 'slot_selected') {
                        emit('dateTimeSlotSelected', data.notification)
                    }
                }
            });
        } catch (e) {
            console.error(e)
            emit('cronofyError', 'Kunde inte ladda kalendern')
        }
    }
</script>
<template>
    <div class="cronofy-myflow-container" id="cronofy-date-time-picker"></div>
</template>
<style scoped>
    @media screen and (max-width: 1024px) {
        .cronofy-myflow-container {
            overflow-x: scroll;
            max-width: 80vw;
        }
    }
    .cronofy-myflow-container :deep(h2) {
        font-size: 1.5em !important;
    }

    .cronofy-myflow-container :deep(h3) {
        font-size: 1em !important;
    }
</style>
