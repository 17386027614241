<template>
    <svg width="730" height="619" viewBox="0 0 730 619" xmlns="http://www.w3.org/2000/svg">
        <g id="b">
            <g id="c">
                <g id="Group">
                    <path id="Path" fill="none" stroke="#622578" stroke-width="46" stroke-linecap="round" stroke-linejoin="round" d="M 364.869995 87.070007 C 349.48999 69.809998 249.080002 -13.289978 135.179993 40.01001 C 90.129997 61.090027 54.75 98.969971 36.400002 145.190002 C -9.72 261.369995 77.949997 349.940002 77.949997 349.940002 C 77.949997 349.940002 205.919998 478.309998 262.549988 534.27002 C 303.859985 574.670044 329.820007 596 364.519989 596 L 364.869995 596 C 399.570007 596 425.519989 574.670044 466.839996 534.27002 C 523.469971 478.309998 651.440002 349.940002 651.440002 349.940002 C 651.440002 349.940002 739.099976 261.369995 692.98999 145.190002 C 674.640015 98.960022 639.26001 61.090027 594.210022 40.01001 C 489.630005 -8.929993 396.410004 57.130005 369.959991 81.630005"/>
                    <path id="path1" fill="none" stroke="#622578" stroke-width="46" stroke-linecap="round" stroke-linejoin="round" d="M 514.400024 214.48999 C 504.429993 216.450012 480.559998 219.940002 457.119995 202.470001 C 443.5 192.320007 432.950012 176.149994 420.269989 156.160004 C 396.359985 118.480011 364.869995 84.179993 344.869995 69.559998"/>
                    <path id="path2" fill="none" stroke="#622578" stroke-width="46" stroke-linecap="round" stroke-linejoin="round" d="M 234.320007 234.940002 C 297.5 296.97998 364.910004 381.630005 445.25 406.47998 C 503.470001 424.47998 617.179993 410.549988 681.799988 306.75"/>
                    <path id="path3" fill="none" stroke="#622578" stroke-width="46" stroke-linecap="round" stroke-linejoin="round" d="M 420.269989 156.160004 C 397.440002 123.369995 378.190002 100.529968 364.100006 86.219971"/>
                </g>
            </g>
        </g>
    </svg>
</template>
