<script setup>
import {computed } from "vue";
import MediaBankEntry from "./util/media-bank-entry.vue";

const props = defineProps({
    containerRef: {
        type: String,
        required: true
    },
    uuid: {
        type: String,
        require: true
    },
    content: {
        type: Object,
        required: true
    },
})

const title = computed(() => props.content.title);
const textColor = computed(() => props.content.text_color);

const textColorClass = computed(() => {
    if( textColor.value === 'light' ){
        return 'text-white';
    }
    if( textColor.value === 'dark' ){
        return 'text-gray-900';
    }
    return 'text-wlPrimary';
})

</script>
<template>
    <component :is="content.link_url? 'a' : 'div'" :href="content.link_url" target="_blank" class="text-inherit">
        <MediaBankEntry
            :container-ref="containerRef"
            :block-uuid="uuid"
            :block-content="content"
            :media-bank-entry-id="content.media_bank_entry_id"
            property-name="media_bank_entry_id"
        >
            <template #entry="{entry}">
                <div
                    class="flex justify-center w-full items-center align-center relative"
                >
                    <div class="rounded-wl overflow-hidden">
                        <img
                            :src="entry.url"
                            class="w-full"
                            alt=""
                        />
                    </div>
                    <div class="absolute inset-0 flex justify-center items-center">
                        <header
                            class="relative flex flex-col items-center justify-center w-full h-full"
                        >
                            <div class="header-text relative z-10 flex flex-col items-center justify-center p-4">
                                <h1 class="text-4xl md:text-5xl lg:text-6xl text-center">
                                    <span class="inline" :class="textColorClass">
                                        {{ title }}
                                    </span>
                                </h1>
                            </div>
                        </header>
                    </div>
                </div>
            </template>
        </MediaBankEntry>
    </component>
</template>
