<script setup>
import {computed, reactive, ref} from 'vue'

const props = defineProps({
    colors: {
        type: Array,
        default: () => ['#7814d7']
    },
    showLegend: {
        type: Boolean,
        required: false,
        default: false
    },
    labels: {
        type: Array,
        required: false,
        default: ['Label One', 'Label Two', 'Label Three', 'Label Four', 'Label Five', 'Label Six']
    },
    series: {
        type: Array,
        required: false,
        default: [42, 47, 52, 58, 65, 38]
    }
})

const monochromeEnabled = ref(props.colors.length === 1)
const fillOptions = computed(() => {
    if (props.colors.length > 1 ) {
        return {
            colors: props.colors,
            opacity: 1,
        }
    } else {
        return {}
    }
})

const options = reactive({
    chart: {
        toolbar: {
            show: false,
        },
    },
    colors: props.colors,
    legend: {
        show: props.showLegend,
        position: 'bottom',
        labels: {
            colors: props.colors,
            useSeriesColors: true
        },
    },
    labels: props.labels,
    plotOptions: {
        polarArea: {
            rings: {
                strokeWidth: 0
            },
            spokes: {
                strokeWidth: 0
            },
        }
    },
    theme: {
        monochrome: {
            enabled: monochromeEnabled,
            color: props.colors[0],
            shadeTo: 'light',
            shadeIntensity: 0.6
        }
    },
    fill: fillOptions,
    stroke: {
        show: false,
    },
    yaxis: {
        show: false
    },
})
</script>
<template>
    <apexchart type="polarArea" :options="options" :series="series"></apexchart>
</template>
