<template>
  <div>
    <notification
      :show="showNotification"
      @close="closeNotification()"
    >
      <template
        v-slot:header
      >
        {{ notification.message }}
      </template>
    </notification>
    <card-form-content>
      <template v-slot:header><h2>{{ _mft('crm:leadNoteCard.domainNamePlural') }}</h2></template>
      <template v-slot:form>
        <form @submit.prevent="createNote">
          <input-editor
            label=""
            required="required"
            :content="creatingNote.note"
            name="note"
            v-model:content="creatingNote.note"
            ref="noteEditor"
            :error="errors.content"
          />

          <input-label
            :label="_mft('crm:leadNoteCard.uploadFile')"
          />
          <input class="w-full mt-1" type="file" ref="noteFile" @change="handleFile">

          <div class="flex justify-end mt-2">
            <input-button
              :label="_mft('crm:leadNoteCard.saveNote')"
              type="submit"
              :busy="working"
            />
          </div>
        </form>
      </template>
      <template v-slot:content>
        <p class=""
          v-if="localnotes.length === 0"
        >
            {{ _mft('crm:leadNoteCard.noNotesAdded') }}
        </p>
        <div
          v-else
        >
          <ul
            role="list"
            class="grid-cols-2 gap-4 md:grid"
          >
            <li
              class="col-span-1 mt-1"
              v-for="(note, i) in localnotes"
              v-bind:key="i"
            >
              <card-collapsible>
                <template v-slot:header>
                  <div class="flex justify-between">
                    <h3 class="text-sm text-gray-900 truncate font-large">{{  note.created_at_user_timezone }}</h3>
                    <small>{{  note.user_rel.name }}</small>
                    <PencilAltIcon
                      v-on:click="openInSlideout(note)"
                      class="w-5 h-5 mr-2 text-myflowPurple-600"
                    />
                  </div>


                </template>
                <template v-slot:preview>
                  <p class="whitespace-pre-line note-content" v-html="note.note.substr(0,80) + '...'"></p>
                </template>
                <template v-slot:body>
                  <p class="whitespace-pre-line note-content" v-html="note.note"></p>
                  <a :href="note.attachment_url" target="_blank">
                    <img
                      v-if="note.attachment_url"
                      alt="Image"
                      :src="note.attachment_url"
                      class="w-48 h-auto"
                    />
                  </a>
                </template>
              </card-collapsible>
            </li>
          </ul>
        </div>

        <basic-slideout
          v-model:open="open"
        >
          <template v-slot:header>
            <h1>{{ _mft('crm:leadNoteCard.editNote') }}</h1>
          </template>

          <template v-slot:body>
            <input-editor
              label=""
              :required="false"
              :content="currentlyEditingNote.note"
              name="note"
              v-model:content="currentlyEditingNote.note"
            />

            <input-button
              :label="_mft('shared:action.update')"
              type="success"
              :busy="working"
              v-on:click="updateNote"
              class="float-right"
            />

          </template>

        </basic-slideout>

      </template>

    </card-form-content>
  </div>
</template>
<script>
import {
  PencilAltIcon
} from '@heroicons/vue/solid'

export default {
  components: {
    PencilAltIcon
  },
  props: {
    alignment: String,
    lead: Object,
    notes: Object
  },
  data: function(){
    return {
      creatingNote: {
        note: '',
        attachment: {}
      },
      currentlyEditingNote: {
        id: '',
        note: ''
      },
      localnotes: {},
      notification: {
        type: '',
        message: ''
      },
      open: false,
      working: false,
      errors: []
    }
  },
  methods: {
    openInSlideout(note){
      this.currentlyEditingNote = note;
      this.open = true;
    },
    async createNote(){
      if(this.creatingNote.note) {
        this.working = true;
        var formData = new FormData();
        formData.append("attachment", this.creatingNote.attachment);
        formData.append("note", this.creatingNote.note);

        const res = await axios.post( route('crm.leads.note.create', this.lead.id) , formData);

        this.notification = res.data.notification;
        this.localnotes = res.data.notes;
        this.resetCreateNoteForm();
        this.working = false;
      } else {
        this.errors.content = _mft('crm:leadNoteCard.fieldMissing');
      }
    },
    async updateNote(){
      const res = await axios.put( route('crm.leads.note.update', this.currentlyEditingNote.id) , this.currentlyEditingNote);

      this.notification = res.data.notification;
      this.closeSlideout();
    },
    handleFile(e){
      const file = e.target.files[0];
      this.creatingNote.attachment = file;
    },
    resetCreateNoteForm(){
      this.creatingNote = {
        note: '',
        attachment: {}
      }
      this.$refs.noteEditor.resetEditor()
      this.$refs.noteFile.value = null
    },
    closeSlideout(){
      this.open = false;
    },
    closeNotification(){
      this.notification.message = ''
    }
  },
  mounted: function(){
    this.localnotes = this.notes;
  },
  computed: {
    hasHeaderSlot () {
      return !!this.$slots['header-left'] && !!this.$slots['header-right'];
    },
    hasImageSlot () {
      return !!this.$slots.image
    },
    hasBodySlot () {
      return !!this.$slots.body
    },
    alignmentClass (){
      return this.alignment === 'center' ? 'text-center' : ''
    },
    showNotification (){
      return this.notification.message.length > 0
    }
  },
  setup() {

  },
}
</script>
<style scoped>
  .note-content a {
    text-decoration: underline;
  }
</style>
