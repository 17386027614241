<script setup>
import {ref, onMounted, inject} from 'vue';
import axios from 'axios';

const props = defineProps({
    leadId: {
        type: Number,
        required: true,
    },
});

const customFields = ref([]);
const leadCustomFields = ref({});

onMounted(() => {
    customFields.value = lead.value.custom_fields
    lead.value.custom_fields?.forEach(field => {
        leadCustomFields.value[field.custom_field.id] = field.value || '';
    });

})

let lead = inject('lead')

</script>

<template>
    <div
        v-for="(field, i) in lead.custom_fields"
        :key="i"
        class="col-span-2"
    >
        <input-text
            v-if="field.custom_field.type === 'text'"
            :label="field.custom_field.name"
            v-model:content="field.value"
            :id="'field-' + field.custom_field.uuid"
            class="mb-4"
        />
        <input-number
            v-if="field.custom_field.type === 'number'"
            :label="field.custom_field.name"
            v-model:content="field.value"
            :id="'field-' + field.custom_field.uuid"
            class="mb-4"
        />

        <div v-if="field.custom_field.type === 'date'">
            <input-label :label="field.custom_field.name"></input-label>
            <input-datepicker
                :label="field.custom_field.name"
                v-model="field.value"
                :id="'field-' + field.custom_field.uuid"
                class="mb-4 mt-1"
                :showInline="false"
            />
        </div>
        <input type="hidden" :name="'custom_fields[' + field.custom_field.uuid + ']'" v-model="leadCustomFields[field.custom_field.uuid]" />
    </div>
</template>
