<script setup>
    import { useNotificationStore } from '@/stores/NotificationStore.js'
    import { onMounted, ref, computed } from 'vue'
    import Location from '../../session/location.vue';
    import Settings from '../settings.vue';

    const notificationStore = useNotificationStore()
    const props = defineProps({
        sessionRoute: {
            type: String,
            required: true,
        },
    })

    const session = ref({})
    const notes = ref({})

    const loadSession = async () => {
        const response = await axios.get(props.sessionRoute)
        session.value = response.data.session
        notes.value = response.data.notes
    }

    const sessionLoaded = computed(() => {
        return Object.keys(session.value).length > 0
    })

    const cancelBooking = async () => {
        const response = await axios.post(route('coaching.provider.session.cancel', session.value.booking.id))

        if (response.data.success) {
            notificationStore.addNotification({
                type: 'success',
                title: response.data.message,
                dismissAfter: 5000,
            })

            loadSession();
        }
    }

    onMounted(() => {
        loadSession()
    })

</script>
<template>
    <div v-if="session.booking">
        <div class="flex flex-row items-start justify-between mb-2 space-x-4">
            <div>
                <h1>
                    {{ session.booking.product.title }}
                </h1>
                <div
                    v-if="session.booking"
                >
                    {{ _mft('coaching:sessionWith') }} <a :href="'mailto:' + session.booking.user.email">{{ session.booking.user.name }}</a>
                </div>
            </div>
            <div v-if="session.booking">
                <date-time-box
                    v-if="session.booking.begins_at.date"
                    :startDate="session.booking.begins_at.date"
                    :endDate="session.booking.ends_at.date"
                    :startTime="session.booking.begins_at.time"
                    :endTime="session.booking.ends_at.time"
                    class="px-4 py-1"
                />
                <p
                    v-else
                    class="pb-4 text-center"
                >
                    {{ _mft('coaching:session.notBooked') }}
                </p>

                <div
                    v-if="session.booking.begins_at.date"
                    class="text-center"
                >
                    <button-with-confirmation
                        @confirmed="cancelBooking"
                        modalClass="absolute -top-2 -right-2 p-2 bg-white rounded shadow-md z-20"
                        :buttonLabel="_mft('coaching:session.cancel')"
                        buttonClass="myflow-basic-button--danger whitespace-nowrap text-xs"
                        :confirmButtonLabel="_mft('coaching:session.cancel')"
                        confirmButtonClass="myflow-basic-button--danger whitespace-nowrap"
                        :confirm-text="_mft('coaching:session.youAreCancelling') + ' ' + session.booking.user.name + ' ' + _mft('coaching:sesssion.cancelling.clientNameWillBeNotified')"
                    />
                </div>
            </div>
        </div>

        <div v-if="session.booking">
            <Settings
                :bookingId="session.booking.id"
            />
        </div>
    </div>
</template>
