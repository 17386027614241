<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div>
    <SwitchGroup as="div" class="flex items-center justify-between mb-3">
        <span class="flex flex-col flex-grow">
            <SwitchLabel as="span" passive>
                {{ translatedLabel }}
            </SwitchLabel>
            <SwitchDescription as="span" v-html="this.description">
            </SwitchDescription>
        </span>

        <Switch
            v-model="enabled"
            :name="name"
            :disabled="disabled"
            :class="[enabled ? 'bg-green-600' : 'bg-red-600', disabled ? 'opacity-50' : '', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-gray-400']"
        >
            <span
                aria-hidden="true"
                :class="[enabled ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200']"
            />
        </Switch>

    </SwitchGroup>

    <input-error v-if="error" :error="error" />
  </div>
</template>

<script>
import { ref } from 'vue'
import { Switch, SwitchDescription, SwitchGroup, SwitchLabel } from '@headlessui/vue'

export default {
  components: {
    Switch,
    SwitchDescription,
    SwitchGroup,
    SwitchLabel,
  },
  data: function(){
    return {
      inputClass: '',
      enabled: this.content
    }
  },
  computed: {
    translatedLabel() {
        if (this.label.match(/^[^:]+:[^:]+$/)) {
            return _mft(this.label)
        } else {
            return this.label
        }
    }
  },
  watch: {
    error: function(error){
      let classes = [];
      if( error !== '' ){
        classes.push('border-red-700')
      } else {
        classes.push('border-gray')
      }
      this.inputClass = classes.join(' ')
    },
    content(newVal, oldVal) {
      if (newVal != oldVal) {
        this.enabled = newVal;
      }
    },
    enabled(newVal, oldVal) {
      if (newVal != oldVal) {
        this.$emit("update:content", newVal);
      }
    }
  },
  props: {
    content: { type: Boolean, default: false },
    description: String,
    error: { type:String, default: '' },
    label: String,
    placeholder: String,
    required: Boolean,
    name: String,
    disabled: { type: Boolean, default: false}
  }
}
</script>
