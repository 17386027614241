<template>
    <div class="">
        <div class="py-16 mx-auto bg-white max-w-7xl sm:py-24 sm:px-6 lg:px-8">
            <!-- xs to lg -->
            <div class="max-w-2xl mx-auto space-y-16 lg:hidden">
                <section v-for="(tier, tierIdx) in tiers" :key="tier.name">
                    <div class="px-4 mb-8">
                        <h2 class="text-lg font-medium leading-6 text-gray-900">
                            {{ tier.name }}
                        </h2>
                        <p class="mt-4">
                            <span
                                class="text-4xl font-bold tracking-tight text-gray-900 "
                            >
                                €{{ tier.priceMonthly }}
                            </span>
                            {{ ' ' }}
                            <span class="text-base font-medium text-gray-500"
                                >/ month excluding vat</span
                            >
                        </p>
                        <p class="mt-4 text-sm text-gray-500">
                            {{ tier.description }}
                        </p>
                        <a
                            :href="tier.href"
                            class="w-full text-center myflow-basic-button--secondary"
                        >
                            Buy {{ tier.name }}
                        </a>
                    </div>

                    <table
                        v-for="section in sections"
                        :key="section.name"
                        class="w-full"
                    >
                        <caption
                            class="px-4 py-3 text-sm font-medium text-left text-gray-900 border-t border-gray-200 bg-myflowPurple-100"
                        >
                            {{
                                section.name
                            }}
                        </caption>
                        <thead>
                            <tr></tr>
                        </thead>
                        <tbody class="divide-y divide-gray-200">
                            <tr
                                v-for="feature in section.features"
                                :key="feature.name"
                                class="border-t border-gray-200"
                            >
                                <th
                                    class="px-4 py-5 text-sm font-normal text-left text-gray-500 "
                                    scope="row"
                                    v-html="feature.name"
                                >
                                </th>
                                <td class="py-5 pr-4">
                                    <span
                                        v-if="
                                            typeof feature.tiers[tier.key] ===
                                            'string'
                                        "
                                        class="block text-sm text-right text-gray-700 "
                                        v-html="feature.tiers[tier.key]"
                                        ></span
                                    >
                                    <template v-else>
                                        <CheckIcon
                                            v-if="
                                                feature.tiers[tier.key] === true
                                            "
                                            class="w-5 h-5 ml-auto text-green-500 "
                                            aria-hidden="true"
                                        />
                                        <MinusIcon
                                            v-else
                                            class="w-5 h-5 ml-auto text-gray-400 "
                                            aria-hidden="true"
                                        />
                                    </template>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div
                        :class="[
                            tierIdx < tiers.length - 1
                                ? 'py-5 border-b'
                                : 'pt-5',
                            'border-t border-gray-200 px-4',
                        ]"
                    >
                        <a
                            :href="tier.href"
                            class="w-full text-center myflow-basic-button"
                            >Köp {{ tier.name }}</a
                        >
                    </div>
                </section>
            </div>

            <!-- lg+ -->
            <div class="hidden lg:block">
                <table class="w-full h-px table-fixed">
                    <thead>
                        <tr>
                            <th
                                class="px-6 pb-4 text-sm font-medium text-left text-gray-900 "
                                scope="col"
                            >
                                <span></span>
                            </th>
                            <th
                                v-for="tier in tiers"
                                :key="tier.name"
                                class="w-1/3 px-6 pb-4 text-lg font-medium leading-6 text-left text-gray-900 "
                                scope="col"
                            >
                                {{ tier.name }}
                            </th>
                        </tr>
                    </thead>
                    <tbody
                        class="border-t border-gray-200 divide-y divide-gray-200 "
                    >
                        <tr>
                            <th
                                class="px-6 py-8 text-sm font-medium text-left text-gray-900 align-top "
                                scope="row"
                            >
                                Price
                            </th>
                            <td
                                v-for="tier in tiers"
                                :key="tier.name"
                                class="h-full px-6 py-8 align-top"
                            >
                                <div class="relative table h-full">
                                    <p>
                                        <span
                                            class="text-4xl font-bold tracking-tight text-gray-900 "
                                            >€{{ tier.priceMonthly }}</span
                                        >
                                        {{ ' ' }}
                                        <span
                                            class="text-base font-medium text-gray-500 "
                                            >/ month excl vat</span
                                        >
                                    </p>
                                    <p class="mt-4 mb-5 text-sm text-gray-500">
                                        {{ tier.description }}
                                    </p>
                                    <a
                                        :href="tier.href"
                                        v-if="tier.href.length > 5"
                                        class="w-full text-center myflow-basic-button--secondary"
                                    >
                                        Buy {{ tier.name }}
                                    </a>
                                </div>
                            </td>
                        </tr>
                        <template
                            v-for="section in sections"
                            :key="section.name"
                        >
                            <tr class="border-none">
                                <th
                                    class="py-3 pl-6 text-sm text-left text-gray-900 border-white font-thick bg-myflowPurple-100"
                                    colspan="3"
                                    scope="colgroup"
                                >
                                    {{ section.name }}
                                </th>
                            </tr>
                            <tr
                                v-for="feature in section.features"
                                :key="feature.name"
                            >
                                <th
                                    class="px-6 py-5 text-sm font-normal text-left text-gray-500 "
                                    scope="row"
                                    v-html="feature.name"
                                >
                                </th>
                                <td
                                    v-for="tier in tiers"
                                    :key="tier.name"
                                    class="px-6 py-5"
                                >
                                    <span
                                        v-if="typeof feature.tiers[tier.key] ==='string'"
                                        class="block text-sm text-gray-700"
                                        v-html="feature.tiers[tier.key]"
                                        ></span
                                    >
                                    <template v-else>
                                        <CheckIcon
                                            v-if="
                                                feature.tiers[tier.key] === true
                                            "
                                            class="w-5 h-5 text-green-500"
                                            aria-hidden="true"
                                        />
                                        <MinusIcon
                                            v-else
                                            class="w-5 h-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                        <span class="sr-only"
                                            >{{
                                                feature.tiers[tier.key] === true
                                                    ? 'Included'
                                                    : 'Not included'
                                            }}
                                            in {{ tier.name }}</span
                                        >
                                    </template>
                                </td>
                            </tr>
                        </template>
                    </tbody>
                    <!-- <tfoot>
                        <tr class="border-t border-gray-200">
                            <th class="sr-only" scope="row">Välj konto</th>
                            <td
                                v-for="tier in tiers"
                                :key="tier.name"
                                class="px-6 pt-5"
                            >
                                <a
                                    :href="tier.href"
                                    class="w-full text-center myflow-basic-button"
                                    >Köp {{ tier.name }}</a
                                >
                            </td>
                        </tr>
                    </tfoot> -->
                </table>
            </div>
        </div>
    </div>
</template>

<script setup>
import { CheckIcon, MinusIcon } from '@heroicons/vue/outline';

const tiers = [
    {
        name: 'Free',
        href: '#',
        key: 'Expert',
        priceMonthly: 0,
        description: '',
    },
    {
        name: 'Pro',
        href: route('settings.company.settings'),
        key: 'ExpertPro',
        priceMonthly: 149,
        description: '',
    },
];
const sections = [
    {
        name: 'CRM',
        features: [
            {
                name: 'Contacts',
                tiers: {
                    Expert: '20',
                    ExpertPro: '<i class="fa-regular fa-infinity"></i> Unlimited',
                },
            },
            {
                name: 'Newsletters',
                tiers: {
                    Expert: false,
                    ExpertPro: true,
                },
            },
            {
                name: 'Email automation',
                tiers: {
                    Expert: false,
                    ExpertPro: true,
                },
            },
            {
                name: 'Import & Export',
                tiers: {
                    Expert: true,
                    ExpertPro: true,
                },
            },
            {
                name: 'Lead Enrichments',
                tiers: {
                    Expert: false,
                    ExpertPro: '40 Enrichments / month <br><small>€0,20 / additional enrichment</small>',
                },
            },
        ],
    },
    {
        name: 'Quotes & Agreements',
        features: [
            {
                name: 'Sent Quotes & Agreements',
                tiers: {
                    Expert: '1',
                    ExpertPro: '<i class="fa-regular fa-infinity"></i> Unlimited',
                },
            },
            {
                name: 'Signed Quotes & Agreements',
                tiers: {
                    Expert: '1',
                    ExpertPro: '<i class="fa-regular fa-infinity"></i> Unlimited',
                },
            },
        ],
    },
    {
        name: 'Coachning',
        features: [
            {
                name: 'Coaching Sessions',
                tiers: {
                    Expert: '4',
                    ExpertPro: '<i class="fa-regular fa-infinity"></i> Unlimited',
                },
            },
            {
                name: 'Syncronized calendar',
                tiers: {
                    Expert: false,
                    ExpertPro: true,
                },
            },
            {
                name: 'Private notes',
                tiers: {
                    Expert: false,
                    ExpertPro: true,
                },
            },
        ],
    },
    {
        name: 'Web Pages & Customer Area',
        features: [
            {
                name: 'Pages',
                tiers: {
                    Expert: '1',
                    ExpertPro: '<i class="fa-regular fa-infinity"></i> Unlimited',
                },
            },
            {
                name: 'Connect to custom domain',
                tiers: {
                    Expert: false,
                    ExpertPro: true,
                },
            },
            {
                name: 'Registered users',
                tiers: {
                    Expert: false,
                    ExpertPro: true,
                },
            },
        ],
    },
    {
        name: 'Courses',
        features: [
            {
                name: 'Create courses',
                tiers: {
                    Expert: '1',
                    ExpertPro: '<i class="fa-regular fa-infinity"></i> Unlimited',
                },
            },
        ],
    },
    {
        name: 'Subscriptions',
        features: [
            {
                name: 'Create subscriptions',
                tiers: {
                    Expert: '1',
                    ExpertPro: '<i class="fa-regular fa-infinity"></i> Unlimited',
                },
            },
        ],
    },
    {
        name: 'Payments',
        features: [
            {
                name: 'Accept invoice details',
                tiers: {
                    Expert: true,
                    ExpertPro: true,
                },
            },
            {
                name: 'Stripe Debit & Credit Card Payments',
                tiers: {
                    Expert: false,
                    ExpertPro: true,
                },
            },
        ],
    },
    {
        name: 'General',
        features: [
            {
                name: 'Files, Audio & Image storage',
                tiers: {
                    Expert: '0.5 GB',
                    ExpertPro: '25 GB <br><small>€0,30 / 10 GB additional storage</small>',
                },
            },
            {
                name: 'Video storage',
                tiers: {
                    Expert: '0.5 hours',
                    ExpertPro: '20 hours <br><small>€0,45 / hour additional storage</small>',
                },
            },
            {
                name: 'Emails',
                tiers: {
                    Expert: '100 emails / month',
                    ExpertPro: '1 000 email / month <br><small>€0,80 / 1 000 additional emails</small>',
                },
            },
            {
                name: 'SMS',
                tiers: {
                    Expert: false,
                    ExpertPro: '40 sms / month <br><small>€0,10 / additional sms</small>',
                },
            },
        ],
    },
];
</script>
