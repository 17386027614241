<template>
    <mf-table v-if="users !== null" :data="users" :columns="columns" :filters="filters" :searchable="searchable">
        <template #column-name="{ row }">
            <div :class="['flex', 'items-center']">
                <div class="ml-4">
                    <div class="text-sm font-medium text-gray-900">
                        {{ row.name }}
                    </div>
                </div>
            </div>
        </template>
        <template #column-created_at="{ row }">
            <span class="uppercase">
                {{ row.created_at }}
            </span>
        </template>
        <template #column-company="{ row }">
            <div v-if="row.company">
                <a :href="route('company.users.view', row.company.id)" class="text-sm font-medium text-right text-myflowPurple-600 hover:text-myflowPurple-900">
                    {{ row.company.name }}
                </a>
            </div>
        </template>

        <template #column-company_type="{ row }">
            <span
                class="mb-1 align-bottom"
                :class="classObject(row.company_type)"
                v-if="row.company_type == 'creator'"
            >
                Expert
            </span>
            <span
                class="mb-1 align-bottom"
                :class="classObject(row.company_type)"
                v-if="row.company_type == 'internal'"
            >
                Myflow
            </span>
            <span
                class="mb-1 align-bottom"
                :class="classObject(row.company_type)"
                v-if="row.company_type == 'customer'"
            >
                B2B
            </span>
        </template>
        <template #actions="{ row }">
            <a
                :href="route('admin.company', row.id)"
                class="mr-2 text-sm font-medium text-right text-myflowPurple-600 hover:text-myflowPurple-900"
            >Visa mer</a>
        </template>
    </mf-table>
    <div v-else class="text-center">
        <mf-spinner-medium
          class="w-24 h-12"
        />
    </div>
</template>

<style lang="scss" scoped>
</style>

<script setup>
import { ref, toRefs, watch, onMounted } from 'vue';
import axios from 'axios';

const props = defineProps({
    url: { type: String, required: true }
});

const { url } = toRefs(props);

const dateFormatter = new Intl.DateTimeFormat(['sv-SE'], {
    timeZone: 'Europe/Stockholm',
    dateStyle: 'short'
});

const users = ref(null);
const updateUsers = async () => {
    const res = await axios.get(url.value + '?cachebust');
    users.value = res.data.data.map((row) => ({
        ...row,
        created_at: row.created_at ? dateFormatter.format(new Date(row.created_at)) : null
    }));
}
watch(url, updateUsers);
onMounted(updateUsers);

const columns = [
    { label: 'Namn', key: 'name', sort: 'name' },
    { label: 'Tillagd', key: 'created_at', sort: 'created_at'},
    { label: 'Typ', key: 'company_type', sort: 'company_type', desktop: true },
    { label: 'Användare', key: 'user_count', sort: 'user_count', desktop: true },
    { label: 'Källa', key: 'source', sort: 'source', desktop: true },
    { label: 'Säljare', key: 'salesperson', sort: 'salesperson', desktop: true },
    { label: 'CS', key: 'customersuccess', sort: 'customersuccess', desktop: true },
];
const searchable = ['name', 'created_at', 'company_type', 'source', 'salesperson', 'customersuccess'];
const filters = [
]

const classObject =  (type) => {
    if(type == 'creator') {
        return 'myflow-success-pill';
    } else if(type == 'internal') {
        return 'myflow-danger-pill'
    } else {
        return 'myflow-basic-pill'
    }
}
</script>
