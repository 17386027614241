<script setup>

import {onMounted, ref} from "vue";
import { useNotificationStore } from '@/stores/NotificationStore.js'
const notificationStore = useNotificationStore()

const props = defineProps({
    leadId: {
        type: Number,
        required: true
    }
});

const assignedTags = ref({})

const loadTags = async () => {
    const res = await axios.get(route('crm.leads.view.tags', props.leadId))
    assignedTags.value = res.data.tags;
}

const removeTag = async (tag) => {
    const res = await axios.delete(route('crm.leads.view.tags.remove', [props.leadId, tag.id]), {})
    await loadTags()
    notificationStore.addNotification({
        type: 'success',
        title: tag.name,
        message: _mft('crm:tag.removed'),
        dismissAfter: 3000,
    })
}

const openModal = () => {
    tagModal.value.open()
}

const closeModal = () => {
    tagModal.value.close()
}

defineExpose({
    openModal,
    closeModal,
})

onMounted(() => {
    loadTags()
})

</script>

<template>
    <div>
        <div>
            <ul class="flex space-x-1 flex-wrap">
                <li
                    v-for="(tag, i) in assignedTags"
                    :key="i"
                    class="mb-1"
                >
                    <pill
                        class="border border-wlPrimary flex space-x-2 cursor-default"
                        size="sm"
                    >
                        <span>{{ tag.name }}</span>
                    </pill>
                </li>
            </ul>
        </div>

        <general-modal>
            <template v-slot:header><mf-translate domainkey="crm:manageTags"></mf-translate></template>
                <template v-slot:body>

                    <ul class="flex space-x-1 flex-wrap">
                    <li
                        v-for="(tag, i) in assignedTags"
                        :key="i"
                        class="mb-1"
                    >
                        <pill
                            class="bg-wlPrimary text-wlPrimaryContrast flex space-x-2"
                            size="sm"
                        >
                            <span>{{ tag.name }}</span>
                            <span
                                class="bg-wlPrimaryContrast hover:bg-wlPrimary-light text-wlPrimary w-4 h-4 rounded-full flex items-center justify-center cursor-pointer"
                                @click="removeTag(tag)"
                            >
                                    <i class="fa-solid fa-xmark"></i>
                                </span>
                        </pill>
                    </li>
                </ul>

                    <hr class="my-4">

                    <tags-multi-assign
                        :selected-leads="[props.leadId]"
                        @doReloadLeads="loadTags()"
                        :show-topbar="false"
                    ></tags-multi-assign>
                </template>
            <template v-slot:open>
                <button-primary class="mt-1" size="sm">
                    <mf-translate domainkey="crm:manageTags"></mf-translate>
                </button-primary>
            </template>
        </general-modal>
    </div>
</template>

<style scoped>

</style>
