<script setup>

import { ref, reactive, computed, watch, onMounted, onUpdated, onBeforeUnmount } from 'vue'
import SlideoutWithSlots from "../elements/slideoutWithSlots.vue";
import LeadQuickview from "./leadQuickview.vue";

const leads = ref([]);
const totalLeads = ref(null);
const paginationLinks = ref([]);
const searchPossible = ref(true)
const fetchingLeadsStatus = ref('')
const loadingLeads = ref(false)
const selectedLeads = ref([])
const quickviewingLead = ref(null)
const quickviewSlideout = ref(null)

const searchParameters = reactive({
    fuzzy: null,
    myOwnContacts: true,
    tags: [],
    emailStatus: 'regardless-email',
    phoneStatus: 'regardless-phone',
    orderBy: 'updated-lastest',
    perPage: 25,
});

const perPageOptions = [
    {
        label: '25 ' + _mft('crm:perPage'),
        value: '25',
    },
    {
        label: '100 ' + _mft('crm:perPage'),
        value: '100',
    },
    {
        label: '500 ' + _mft('crm:perPage'),
        value: '500',
    },
]

const fetchLeads = async (link = null) => {
    if (! searchPossible.value) {
        return
    }

    let url = route('api.crm.filterLeads') + '?' + searchParametersUrlParams.value

    if (link !== null) {
        url = link.url + '&' + searchParametersUrlParams.value
    }
    try {
        fetchingLeadsStatus.value = 'waiting'
        loadingLeads.value = true
        const response = await axios.get(url);
        leads.value = response.data.leads.data;
        totalLeads.value = response.data.total_count;
        paginationLinks.value = response.data.leads.links;

        fetchingLeadsStatus.value = ''
        loadingLeads.value = false
        searchPossible.value = false;

    } catch (error) {
        fetchingLeadsStatus.value = 'error'
    }
};

const onTagsSelected = (selectedTags) => {
    searchParameters.tags = selectedTags.map(tag => tag.id)
}

const updateSearch = () => {
    leads.value = [];
    totalLeads.value = null;
    paginationLinks.value = [];

    fetchLeads();
};

const clearSearch = () => {
    searchParameters.fuzzy = null;
    searchParameters.myOwnContacts = true;
    searchParameters.tags = [];
    searchParameters.emailStatus = 'regardless-email';
    searchParameters.phoneStatus = 'regardless-phone';
    searchParameters.orderBy = 'updated-lastest';

    updateSearch();
};

const setPage = (link) => {
    searchPossible.value = true
    fetchLeads(link)
}

const onPerPageOptionChanged = () => {
    selectedLeads.value = []
    updateSearch()
}

const selectLead = (lead) => {
    if (selectedLeads.value.includes(lead)) {
        selectedLeads.value = selectedLeads.value.filter((id) => id !== lead);
    } else {
        selectedLeads.value.push(lead);
    }
}

const selectAllLeads = () => {
    if (selectedLeads.value.length === leads.value.length) {
        selectedLeads.value = [];
    } else {
        selectedLeads.value = leads.value;
    }
}

const quickviewLead = (lead) => {
    quickviewingLead.value = lead;
}

const updateLeadFromQuickview = (lead) => {
    // This is a quick hack to avoid creating a new component and everything
    // We really only care about updating visible fields in the table anyway
    quickviewingLead.value.name = lead.name
    quickviewingLead.value.email = lead.email
    quickviewSlideout.value.close()
}

const searchParametersUrlParams = computed(() => {
    return Object.keys(searchParameters).map((key) => {
        return key + '=' + searchParameters[key]
    }).join('&')
})

const selectedLeadIds = computed(() => {
    return selectedLeads.value.map(lead => lead.id)
})

watch(searchParameters, (oldVal, newVal) => {
    _mfPrefSet('crm-search-parameters', JSON.stringify(searchParameters))

    searchPossible.value = true;
});

onMounted(() => {
    _mfPrefGet('crm-search-parameters').then(data => {
        if (data) {
            searchParameters.fuzzy = data.fuzzy
            searchParameters.myOwnContacts = data.myOwnContacts
            searchParameters.tags = data.tags
            searchParameters.emailStatus = data.emailStatus
            searchParameters.phoneStatus = data.phoneStatus
            searchParameters.orderBy = data.orderBy
            searchParameters.perPage = data.perPage
        }
        fetchLeads();
    })
});
</script>

<template>

    <div>

        <div>
            <div class="flow-root mt-8">
                <div class="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div class="flex space-between items-center space-x-2">
                        <input-text
                            type="text"
                            :placeholder="_mft('crm:filters.search.placeholder')"
                            v-model:content="searchParameters.fuzzy"
                            @keydown.enter="updateSearch"
                            class="w-full !mb-1"
                        />
                        <button-base
                            :disabled="! searchPossible"
                            :style-type="searchPossible ? 'primary' : 'disabled'"
                            @click="updateSearch"
                            :state="fetchingLeadsStatus"
                        >
                            {{ _mft('crm:filters.search') }}
                        </button-base>
                    </div>

                    <expandable-section>
                        <template v-slot:title>
                            {{ _mft('crm:filters.title') }}
                        </template>
                        <template v-slot:body>
                            <div class="grid grid-cols-1 gap-8 md:grid-cols-3">
                                <div>
                                    <label class="block mb-2 font-medium text-gray-700">
                                        {{ _mft('crm:filters.myOwnContacts.title') }}
                                    </label>
                                    <button-bar
                                        :options="[
                                            {
                                                label: _mft('crm:filters.myOwnContacts.myOwnContacts'),
                                                name: true,
                                            },
                                            {
                                                label: _mft('crm:filters.myOwnContacts.allContacts'),
                                                name: false,
                                            },
                                        ]"
                                        :selectedOptionName="searchParameters.myOwnContacts"
                                        @select="searchParameters.myOwnContacts = $event.name"
                                    />

                                    <label class="block mb-2 font-medium text-gray-700">
                                        {{ _mft('crm:filters.tags.title') }}
                                    </label>
                                    <tags-multi-select
                                        :allow-creation="false"
                                        :placeholder="_mft('crm:searchTags')"
                                        @tagsSelected="onTagsSelected"
                                    />

                                    <button-danger
                                        size="sm"
                                        @click="clearSearch"
                                        class="text-center"
                                    >
                                        {{ _mft('crm:filters.clear') }}
                                    </button-danger>
                                </div>
                                <div>
                                    <label class="block mb-2 font-medium text-gray-700">
                                        {{ _mft('crm:filters.hasEmailAddress.title') }}
                                    </label>
                                    <button-bar
                                        :options="[
                                            {
                                                label: _mft('crm:filters.hasEmailAddress.withEmail'),
                                                name: 'with-email',
                                            },
                                            {
                                                label: _mft('crm:filters.hasEmailAddress.withoutEmail'),
                                                name: 'without-email',
                                            },
                                            {
                                                label: _mft('crm:filters.hasEmailAddress.both'),
                                                name: 'regardless-email',
                                            },
                                        ]"
                                        :selectedOptionName="searchParameters.emailStatus"
                                        @select="searchParameters.emailStatus = $event.name"
                                    />

                                    <label class="block mb-2 font-medium text-gray-700">
                                        {{ _mft('crm:filters.hasPhoneNumber.title') }}
                                    </label>
                                    <button-bar
                                        :options="[
                                            {
                                                label: _mft('crm:filters.hasPhoneNumber.withPhone'),
                                                name: 'with-phone',
                                            },
                                            {
                                                label: _mft('crm:filters.hasPhoneNumber.withoutPhone'),
                                                name: 'without-phone',
                                            },
                                            {
                                                label: _mft('crm:filters.hasPhoneNumber.both'),
                                                name: 'regardless-phone',
                                            },
                                        ]"
                                        :selectedOptionName="searchParameters.phoneStatus"
                                        @select="searchParameters.phoneStatus = $event.name"
                                    />
                                </div>
                                <div>
                                    <label class="block mb-2 font-medium text-gray-700">
                                        {{ _mft('crm:filters.orderBy.title') }}
                                    </label>
                                    <button-bar
                                        :options="[
                                            {
                                                label: _mft('crm:filters.orderBy.nameAsc'),
                                                name: 'name-a',
                                            },
                                            {
                                                label: _mft('crm:filters.orderBy.nameDesc'),
                                                name: 'name-z',
                                            },
                                        ]"
                                        :selectedOptionName="searchParameters.orderBy"
                                        @select="searchParameters.orderBy = $event.name"
                                    />
                                    <button-bar
                                        :options="[
                                            {
                                                label: _mft('crm:filters.orderBy.createdDesc'),
                                                name: 'created-newest',
                                            },
                                            {
                                                label: _mft('crm:filters.orderBy.createdAsc'),
                                                name: 'created-oldest',
                                            },
                                        ]"
                                        :selectedOptionName="searchParameters.orderBy"
                                        @select="searchParameters.orderBy = $event.name"
                                    />
                                    <button-bar
                                        :options="[
                                            {
                                                label: _mft('crm:filters.orderBy.updatedDesc'),
                                                name: 'updated-lastest',
                                            },
                                            {
                                                label: _mft('crm:filters.orderBy.updatedAsc'),
                                                name: 'updated-oldest',
                                            },
                                        ]"
                                        :selectedOptionName="searchParameters.orderBy"
                                        @select="searchParameters.orderBy = $event.name"
                                    />
                                </div>
                            </div>
                        </template>
                    </expandable-section>

                    <div class="grid grid-cols-3 items-center h-12">
                        <div class="hidden md:block"></div>
                        <div
                            class="space-x-2 font-thin text-center text-gray-500"
                        >
                            <span v-if="loadingLeads" class="flex justify-center">
                                &nbsp;
                                <mf-spinner-medium class="w-8 h-4" />
                                &nbsp;
                            </span>

                            <div
                                v-else
                                class="lowercase text-xs transition-opacity"
                                :class="totalLeads !== null ? 'opacity-100' : 'opacity-0'"
                            >
                                <span>{{ totalLeads }} {{ _mft('crm:contacts') }}</span>
                            </div>
                        </div>
                        <div class="flex justify-end items-center col-span-2 md:col-span-1">
                            <input-select
                                v-if="selectedLeadIds.length === 0"
                                v-model:content="searchParameters.perPage"
                                :options="perPageOptions"
                                @update:content="onPerPageOptionChanged"
                                input-class="text-xs"
                                class="!mb-0"
                            ></input-select>
                        </div>
                    </div>

                    <table class="w-full text-left bg-white shadow-xl rounded-wl">
                        <thead class="sticky inset-0">
                            <tr class="border-b border-b-gray-200">
                                <th class="bg-white pl-1 pr-2 md:pl-4 text-center rounded-tl-wl min-w-1">
                                    <i
                                        class="text-xl text-gray-400 transition-all duration-75 cursor-pointer"
                                        :class="leads.length > 0 && selectedLeadIds.length === leads.length ? 'fa-square-check fa-regular text-myflowGreen-800' : 'fa-square fa-thin text-gray-500'"
                                        @click="selectAllLeads()"
                                    ></i>

                                </th>
                                <th scope="col" class="bg-white relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                                    {{ _mft('crm:leadIndex.columnLabel.name') }}
                                </th>
                                <th scope="col" class="bg-white hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 md:table-cell">
                                    {{ _mft('crm:leadIndex.columnLabel.company') }}
                                </th>
                                <th scope="col" class="bg-white relative py-3.5 pl-3">
                                    <span class="sr-only"></span>
                                </th>
                                <th scope="col" class="bg-white rounded-tr-wl z-20 relative py-3.5 pl-3">
                                    <span class="sr-only">
                                        {{ _mft('crm:leadIndex.columnLabel.tools') }}
                                    </span>
                                </th>
                            </tr>
                        </thead>
                        <tbody
                            v-if="leads != null"
                            class="divide-y"
                        >
                            <tr
                                v-for="lead in leads"
                                :key="lead.id"
                            >
                                <td class="pl-1 pr-2 text-center md:pl-4">
                                    <i
                                        class="text-xl text-gray-400 transition-all duration-75 cursor-pointer"
                                        :class="selectedLeadIds.includes(lead.id) ? 'fa-square-check fa-regular text-myflowGreen-800' : 'fa-square fa-thin text-gray-500'"
                                        @click="selectLead(lead)"
                                    ></i>
                                </td>
                                <td class="py-4 pr-3 text-sm font-medium text-gray-900">
                                    <a :href="route('crm.leads.view', lead.id)" target="_blank" class="text-black mf-underline">
                                        {{ lead.name }}
                                    </a><br>
                                    <small>{{ lead.email }}</small>
                                </td>
                                <td class="hidden px-3 py-4 text-sm text-gray-700 md:table-cell">
                                    {{ lead.title }}<br>
                                    <small>{{ lead.company }}</small>
                                </td>
                                <td class="relative py-4 pl-3 text-sm font-medium text-right">
                                    &nbsp;
                                </td>
                                <td class="relative py-4 pl-3 pr-4 text-sm font-medium text-right">
                                    <div class="flex flex-col items-end justify-end space-y-1 md:flex-row md:space-y-0 md:space-x-1 md:items-start">
                                        <button-secondary
                                            size="sm"
                                            @click="quickviewLead(lead)"
                                        >
                                            <i class="md:mr-1 fa-sharp fa-light fa-magnifying-glass-plus"></i>
                                            <span class="hidden md:inline">
                                                {{ _mft('crm:lead.quickview') }}
                                            </span>
                                        </button-secondary>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <nav class="flex items-center justify-between px-4 mt-8 sm:px-0">
            <div class="flex flex-1 w-0 -mt-px">
            </div>
            <div class="flex md:-mt-px">
                <a
                    v-for="(link, i) in paginationLinks"
                    :key="i"
                    :href="link.url"
                    class="relative w-8 h-8 transition-all border rounded-wl first:border-transparent last:border-transparent"
                    :class="[link.active ? 'items-center text-sm font-medium text-gray-600': 'hidden first:inline-flex last:inline-flex items-center text-sm font-medium text-gray-500 hover:text-gray-700', link.active ? paginationLinks.length < 12 ? 'border-gray-300' : 'border-transparent' : paginationLinks.length >= 12 ? 'hidden' : 'border-transparent md:inline-flex']"
                    aria-current="page"
                    @click.prevent="setPage(link)"
                >
                    <span class="absolute inset-0 flex items-center justify-center">
                        <span class="" v-html="link.label"></span>
                    </span>
                </a>
            </div>
            <div class="flex justify-end flex-1 w-0 -mt-px">
            </div>
        </nav>

        <leads-multi-manage
            :leads="selectedLeads"
        />

        <slideout-with-slots
            v-if="quickviewingLead"
            ref="quickviewSlideout"
            @close="quickviewingLead = null"
        >
            <template #title>
                <h2>{{ quickviewingLead.name }}</h2>
            </template>
            <template #body>
                <div class="relative z-modal">
                    <lead-quickview
                        :lead="quickviewingLead"
                        @update:lead="updateLeadFromQuickview"
                    />
                </div>
            </template>
        </slideout-with-slots>


    </div>

</template>

<style>

</style>
