<script setup>
import {computed} from "vue";

const props = defineProps({
    alignment: {
        type: String,
        required: false,
        default: 'left',
        options: ['left', 'center', 'right']
    },
    verticalAlignment: {
        type: String,
        required: false,
        default: 'top',
        options: ['top', 'center', 'bottom']
    },
    tooltipClass: {
        type: String,
        required: false,
        default: 'bg-white text-xs px-1 py-0.5 rounded shadow-md'
    },
    passive: {
        type: Boolean,
        default: true,
    }
})

const alignmentClass = computed(() => {
    const classes = [];

    switch (props.alignment) {
        case 'left':
            classes.push('left-0');
            break;
        case 'center':
            classes.push('left-1/2 -translate-x-1/2');
            break;
        case 'right':
            classes.push('right-0');
            break;
    }

    switch (props.verticalAlignment) {
        case 'top':
            classes.push('bottom-full');
            break;
        case 'center':
            classes.push('top-1/2 -translate-y-1/2');
            break;
        case 'bottom':
            classes.push('top-full');
            break;
    }

    return classes.join(' ');
})

const transitionClass = computed(() => {
    let origin = ''
    switch (props.verticalAlignment) {
        case 'top':
            origin = 'translate-y-2 group-hover:-translate-y-0'
            break;
        case 'center':
            origin = 'scale-50 group-hover:scale-100'
            break;
        case 'bottom':
            origin = '-translate-y-2 group-hover:translate-y-0'
            break;
    }
    return origin + ' transition transform opacity-0 group-hover:opacity-100 duration-100'
})

</script>

<template>
<span class="group relative">
    <slot/>
    <span
        class="absolute transform"
        :class="[alignmentClass, transitionClass, passive ? 'pointer-events-none' : '']"
    >
        <span
            class="hidden group-hover:block cursor-default"
            :class="tooltipClass"
        >
            <slot name="tooltip"/>
        </span>
    </span>
</span>
</template>

<style scoped>

</style>
