<script setup>

import {computed, onMounted, ref} from "vue";

const sessions = ref([])
const sessionsLoaded = ref(false)
const currentStatus = ref('bookable')

const loadSessions = async (status) => {
    const res = await axios.get(route('api.coaching2.client.sessions.index'))

    if (res.data.success) {
        sessions.value = res.data.sessions
        sessionsLoaded.value = true
    }
}

const loadAllSessions = async () => {
    await loadSessions(currentStatus.value)
}

const bookedSessions = computed(() => {
    return sessions.value.filter(session => session.status === 'booked')
})

const upcomingSessions = computed(() => {
    return sessions.value.filter(session => session.status === 'bookable' || session.status === 'reschedulable')
})

const oldSessions = computed(() => {
    return sessions.value.filter(session => session.status === 'no-show' || session.status === 'completed' || session.status === 'cancelled')
})

onMounted(() => {
    loadAllSessions()
})

</script>

<template>
    <div class="pb-16">
        <list-basic>
            <coaching-v2-client-sessions-list-session
                v-for="(session, index) in bookedSessions"
                :key="index"
                :session="session"
                @reloadSessions="loadAllSessions"
            ></coaching-v2-client-sessions-list-session>

            <coaching-v2-client-sessions-list-session
                v-for="(session, index) in upcomingSessions"
                :key="index"
                :session="session"
                @reloadSessions="loadAllSessions"
            ></coaching-v2-client-sessions-list-session>

            <coaching-v2-client-sessions-list-session
                v-for="(session, index) in oldSessions"
                :key="index"
                :session="session"
                @reloadSessions="loadAllSessions"
            ></coaching-v2-client-sessions-list-session>
            <list-basic-item
                v-if="sessions.length === 0 && sessionsLoaded"
            >
                {{ _mft('coaching:noSessionsToShowHere') }}
            </list-basic-item>
        </list-basic>
    </div>
</template>
