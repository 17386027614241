<script setup>
import { reactive, onMounted, watch } from 'vue';

const emit = defineEmits(['update:emailContent']);

const props = defineProps({
    automationId: {
        type: Number,
        required: false
    },
    presetEmailContent: {
        type: Object,
        default: {
            subject: '',
            body: []
        }
    }
})

const state = reactive({
    emailContent: props.presetEmailContent,
    contentChanged: false,
})

const updateContent = (content) => {
    state.emailContent.body = content;
}

watch(state.emailContent, (newValue) => {
    state.contentChanged = true;
    emit('update:emailContent', newValue);
})

onMounted(async () => {
    state.emailContent = props.presetEmailContent;
    setTimeout(function() {
        state.contentChanged = false;
    }, 500);
})

defineExpose({
    state
})

</script>
<template>
    <div>
        <h2>{{ _mft('communication:composer.title.emailContent') }}</h2>
        <input-text
            :label="_mft('communication:composer.subjectLine')"
            v-model:content="state.emailContent.subject"
        />

        <input-block-editor
            name="block_editor_content"
            :content="state.emailContent.body"
            :block-groups="[
                'email'
            ]"
            :showVisibilityTools="false"
            @update:content="updateContent"
        >
        </input-block-editor>

        <div id="teleport-target-block-editor-toolbar"></div>

        <div class="my-4 text-center">
            <a
                v-if="! state.contentChanged && props.automationId"
                :href="route('crm.communications.automations.preview', props.automationId)"
                class="myflow-basic-button--secondary"
                target="_blank"
            >
                {{ _mft('communication:composer.preview') }}
            </a>
            <span class="text-gray-400" v-else>{{ _mft('communication:composer.preview.saveToEnable') }}</span>
        </div>

    </div>
</template>
