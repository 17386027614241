<script setup>
    import { computed, nextTick, ref, watch, onMounted } from 'vue'

    const props = defineProps({
        content: {
            type: String,
            default: '',
        },
        error: {
            type:String,
            default:''
        },
        label: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: ''
        },
        required: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            required: true
        },
        timezoneListClass: {
            type: String,
            default: 'absolute z-10 w-full left-0 right-0 mt-1 bg-white rounded-b-md shadow-lg'
        },
    })

    const emit = defineEmits('update:content')

    const term = ref('')
    const selectedTimezone = ref(props.content)
    const timezoneRecentlySelected = ref(false)

    const timezones = [
        {countryCode: 'AF', timezone: 'Asia/Kabul', offset: 'UTC +04:30'},
        {countryCode: 'AL', timezone: 'Europe/Tirane', offset: 'UTC +02:00'},
        {countryCode: 'DZ', timezone: 'Africa/Algiers', offset: 'UTC +01:00'},
        {countryCode: 'AS', timezone: 'Pacific/Pago_Pago', offset: 'UTC -11:00'},
        {countryCode: 'AD', timezone: 'Europe/Andorra', offset: 'UTC +02:00'},
        {countryCode: 'AO', timezone: 'Africa/Luanda', offset: 'UTC +01:00'},
        {countryCode: 'AI', timezone: 'America/Anguilla', offset: 'UTC -04:00'},
        {countryCode: 'AQ', timezone: 'Antarctica/Casey', offset: 'UTC +08:00'},
        {countryCode: 'AQ', timezone: 'Antarctica/Davis', offset: 'UTC +07:00'},
        {countryCode: 'AQ', timezone: 'Antarctica/DumontDUrville', offset: 'UTC +10:00'},
        {countryCode: 'AQ', timezone: 'Antarctica/Mawson', offset: 'UTC +05:00'},
        {countryCode: 'AQ', timezone: 'Antarctica/McMurdo', offset: 'UTC +13:00'},
        {countryCode: 'AQ', timezone: 'Antarctica/Palmer', offset: 'UTC -03:00'},
        {countryCode: 'AQ', timezone: 'Antarctica/Rothera', offset: 'UTC -03:00'},
        {countryCode: 'AQ', timezone: 'Antarctica/Syowa', offset: 'UTC +03:00'},
        {countryCode: 'AQ', timezone: 'Antarctica/Troll', offset: 'UTC +02:00'},
        {countryCode: 'AQ', timezone: 'Antarctica/Vostok', offset: 'UTC +05:00'},
        {countryCode: 'AG', timezone: 'America/Antigua', offset: 'UTC -04:00'},
        {countryCode: 'AR', timezone: 'America/Argentina/Buenos_Aires', offset: 'UTC -03:00'},
        {countryCode: 'AR', timezone: 'America/Argentina/Catamarca', offset: 'UTC -03:00'},
        {countryCode: 'AR', timezone: 'America/Argentina/Cordoba', offset: 'UTC -03:00'},
        {countryCode: 'AR', timezone: 'America/Argentina/Jujuy', offset: 'UTC -03:00'},
        {countryCode: 'AR', timezone: 'America/Argentina/La_Rioja', offset: 'UTC -03:00'},
        {countryCode: 'AR', timezone: 'America/Argentina/Mendoza', offset: 'UTC -03:00'},
        {countryCode: 'AR', timezone: 'America/Argentina/Rio_Gallegos', offset: 'UTC -03:00'},
        {countryCode: 'AR', timezone: 'America/Argentina/Salta', offset: 'UTC -03:00'},
        {countryCode: 'AR', timezone: 'America/Argentina/San_Juan', offset: 'UTC -03:00'},
        {countryCode: 'AR', timezone: 'America/Argentina/San_Luis', offset: 'UTC -03:00'},
        {countryCode: 'AR', timezone: 'America/Argentina/Tucuman', offset: 'UTC -03:00'},
        {countryCode: 'AR', timezone: 'America/Argentina/Ushuaia', offset: 'UTC -03:00'},
        {countryCode: 'AM', timezone: 'Asia/Yerevan', offset: 'UTC +04:00'},
        {countryCode: 'AW', timezone: 'America/Aruba', offset: 'UTC -04:00'},
        {countryCode: 'AU', timezone: 'Antarctica/Macquarie', offset: 'UTC +11:00'},
        {countryCode: 'AU', timezone: 'Australia/Adelaide', offset: 'UTC +10:30'},
        {countryCode: 'AU', timezone: 'Australia/Brisbane', offset: 'UTC +10:00'},
        {countryCode: 'AU', timezone: 'Australia/Broken_Hill', offset: 'UTC +10:30'},
        {countryCode: 'AU', timezone: 'Australia/Darwin', offset: 'UTC +09:30'},
        {countryCode: 'AU', timezone: 'Australia/Eucla', offset: 'UTC +08:45'},
        {countryCode: 'AU', timezone: 'Australia/Hobart', offset: 'UTC +11:00'},
        {countryCode: 'AU', timezone: 'Australia/Lindeman', offset: 'UTC +10:00'},
        {countryCode: 'AU', timezone: 'Australia/Lord_Howe', offset: 'UTC +11:00'},
        {countryCode: 'AU', timezone: 'Australia/Melbourne', offset: 'UTC +11:00'},
        {countryCode: 'AU', timezone: 'Australia/Perth', offset: 'UTC +08:00'},
        {countryCode: 'AU', timezone: 'Australia/Sydney', offset: 'UTC +11:00'},
        {countryCode: 'AT', timezone: 'Europe/Vienna', offset: 'UTC +02:00'},
        {countryCode: 'AZ', timezone: 'Asia/Baku', offset: 'UTC +04:00'},
        {countryCode: 'BS', timezone: 'America/Nassau', offset: 'UTC -04:00'},
        {countryCode: 'BH', timezone: 'Asia/Bahrain', offset: 'UTC +03:00'},
        {countryCode: 'BD', timezone: 'Asia/Dhaka', offset: 'UTC +06:00'},
        {countryCode: 'BB', timezone: 'America/Barbados', offset: 'UTC -04:00'},
        {countryCode: 'BY', timezone: 'Europe/Minsk', offset: 'UTC +03:00'},
        {countryCode: 'BE', timezone: 'Europe/Brussels', offset: 'UTC +02:00'},
        {countryCode: 'BZ', timezone: 'America/Belize', offset: 'UTC -06:00'},
        {countryCode: 'BJ', timezone: 'Africa/Porto-Novo', offset: 'UTC +01:00'},
        {countryCode: 'BM', timezone: 'Atlantic/Bermuda', offset: 'UTC -03:00'},
        {countryCode: 'BT', timezone: 'Asia/Thimphu', offset: 'UTC +06:00'},
        {countryCode: 'BO', timezone: 'America/La_Paz', offset: 'UTC -04:00'},
        {countryCode: 'BQ', timezone: 'America/Kralendijk', offset: 'UTC -04:00'},
        {countryCode: 'BA', timezone: 'Europe/Sarajevo', offset: 'UTC +02:00'},
        {countryCode: 'BW', timezone: 'Africa/Gaborone', offset: 'UTC +02:00'},
        {countryCode: 'BR', timezone: 'America/Araguaina', offset: 'UTC -03:00'},
        {countryCode: 'BR', timezone: 'America/Bahia', offset: 'UTC -03:00'},
        {countryCode: 'BR', timezone: 'America/Belem', offset: 'UTC -03:00'},
        {countryCode: 'BR', timezone: 'America/Boa_Vista', offset: 'UTC -04:00'},
        {countryCode: 'BR', timezone: 'America/Campo_Grande', offset: 'UTC -04:00'},
        {countryCode: 'BR', timezone: 'America/Cuiaba', offset: 'UTC -04:00'},
        {countryCode: 'BR', timezone: 'America/Eirunepe', offset: 'UTC -05:00'},
        {countryCode: 'BR', timezone: 'America/Fortaleza', offset: 'UTC -03:00'},
        {countryCode: 'BR', timezone: 'America/Maceio', offset: 'UTC -03:00'},
        {countryCode: 'BR', timezone: 'America/Manaus', offset: 'UTC -04:00'},
        {countryCode: 'BR', timezone: 'America/Noronha', offset: 'UTC -02:00'},
        {countryCode: 'BR', timezone: 'America/Porto_Velho', offset: 'UTC -04:00'},
        {countryCode: 'BR', timezone: 'America/Recife', offset: 'UTC -03:00'},
        {countryCode: 'BR', timezone: 'America/Rio_Branco', offset: 'UTC -05:00'},
        {countryCode: 'BR', timezone: 'America/Santarem', offset: 'UTC -03:00'},
        {countryCode: 'BR', timezone: 'America/Sao_Paulo', offset: 'UTC -03:00'},
        {countryCode: 'IO', timezone: 'Indian/Chagos', offset: 'UTC +06:00'},
        {countryCode: 'BN', timezone: 'Asia/Brunei', offset: 'UTC +08:00'},
        {countryCode: 'BG', timezone: 'Europe/Sofia', offset: 'UTC +03:00'},
        {countryCode: 'BF', timezone: 'Africa/Ouagadougou', offset: 'UTC'},
        {countryCode: 'BI', timezone: 'Africa/Bujumbura', offset: 'UTC +02:00'},
        {countryCode: 'KH', timezone: 'Asia/Phnom_Penh', offset: 'UTC +07:00'},
        {countryCode: 'CM', timezone: 'Africa/Douala', offset: 'UTC +01:00'},
        {countryCode: 'CA', timezone: 'America/Atikokan', offset: 'UTC -05:00'},
        {countryCode: 'CA', timezone: 'America/Blanc-Sablon', offset: 'UTC -04:00'},
        {countryCode: 'CA', timezone: 'America/Cambridge_Bay', offset: 'UTC -06:00'},
        {countryCode: 'CA', timezone: 'America/Creston', offset: 'UTC -07:00'},
        {countryCode: 'CA', timezone: 'America/Dawson', offset: 'UTC -07:00'},
        {countryCode: 'CA', timezone: 'America/Dawson_Creek', offset: 'UTC -07:00'},
        {countryCode: 'CA', timezone: 'America/Edmonton', offset: 'UTC -06:00'},
        {countryCode: 'CA', timezone: 'America/Fort_Nelson', offset: 'UTC -07:00'},
        {countryCode: 'CA', timezone: 'America/Glace_Bay', offset: 'UTC -03:00'},
        {countryCode: 'CA', timezone: 'America/Goose_Bay', offset: 'UTC -03:00'},
        {countryCode: 'CA', timezone: 'America/Halifax', offset: 'UTC -03:00'},
        {countryCode: 'CA', timezone: 'America/Inuvik', offset: 'UTC -06:00'},
        {countryCode: 'CA', timezone: 'America/Iqaluit', offset: 'UTC -04:00'},
        {countryCode: 'CA', timezone: 'America/Moncton', offset: 'UTC -03:00'},
        {countryCode: 'CA', timezone: 'America/Rankin_Inlet', offset: 'UTC -05:00'},
        {countryCode: 'CA', timezone: 'America/Regina', offset: 'UTC -06:00'},
        {countryCode: 'CA', timezone: 'America/Resolute', offset: 'UTC -05:00'},
        {countryCode: 'CA', timezone: 'America/St_Johns', offset: 'UTC -02:30'},
        {countryCode: 'CA', timezone: 'America/Swift_Current', offset: 'UTC -06:00'},
        {countryCode: 'CA', timezone: 'America/Toronto', offset: 'UTC -04:00'},
        {countryCode: 'CA', timezone: 'America/Vancouver', offset: 'UTC -07:00'},
        {countryCode: 'CA', timezone: 'America/Whitehorse', offset: 'UTC -07:00'},
        {countryCode: 'CA', timezone: 'America/Winnipeg', offset: 'UTC -05:00'},
        {countryCode: 'CV', timezone: 'Atlantic/Cape_Verde', offset: 'UTC -01:00'},
        {countryCode: 'KY', timezone: 'America/Cayman', offset: 'UTC -05:00'},
        {countryCode: 'CF', timezone: 'Africa/Bangui', offset: 'UTC +01:00'},
        {countryCode: 'TD', timezone: 'Africa/Ndjamena', offset: 'UTC +01:00'},
        {countryCode: 'CL', timezone: 'America/Punta_Arenas', offset: 'UTC -03:00'},
        {countryCode: 'CL', timezone: 'America/Santiago', offset: 'UTC -03:00'},
        {countryCode: 'CL', timezone: 'Pacific/Easter', offset: 'UTC -05:00'},
        {countryCode: 'CN', timezone: 'Asia/Shanghai', offset: 'UTC +08:00'},
        {countryCode: 'CN', timezone: 'Asia/Urumqi', offset: 'UTC +06:00'},
        {countryCode: 'CX', timezone: 'Indian/Christmas', offset: 'UTC +07:00'},
        {countryCode: 'CC', timezone: 'Indian/Cocos', offset: 'UTC +06:30'},
        {countryCode: 'CO', timezone: 'America/Bogota', offset: 'UTC -05:00'},
        {countryCode: 'KM', timezone: 'Indian/Comoro', offset: 'UTC +03:00'},
        {countryCode: 'CG', timezone: 'Africa/Brazzaville', offset: 'UTC +01:00'},
        {countryCode: 'CD', timezone: 'Africa/Kinshasa', offset: 'UTC +01:00'},
        {countryCode: 'CD', timezone: 'Africa/Lubumbashi', offset: 'UTC +02:00'},
        {countryCode: 'CK', timezone: 'Pacific/Rarotonga', offset: 'UTC -10:00'},
        {countryCode: 'CR', timezone: 'America/Costa_Rica', offset: 'UTC -06:00'},
        {countryCode: 'HR', timezone: 'Europe/Zagreb', offset: 'UTC +02:00'},
        {countryCode: 'CU', timezone: 'America/Havana', offset: 'UTC -04:00'},
        {countryCode: 'CW', timezone: 'America/Curacao', offset: 'UTC -04:00'},
        {countryCode: 'CY', timezone: 'Asia/Famagusta', offset: 'UTC +03:00'},
        {countryCode: 'CY', timezone: 'Asia/Nicosia', offset: 'UTC +03:00'},
        {countryCode: 'CZ', timezone: 'Europe/Prague', offset: 'UTC +02:00'},
        {countryCode: 'CI', timezone: 'Africa/Abidjan', offset: 'UTC'},
        {countryCode: 'DK', timezone: 'Europe/Copenhagen', offset: 'UTC +02:00'},
        {countryCode: 'DJ', timezone: 'Africa/Djibouti', offset: 'UTC +03:00'},
        {countryCode: 'DM', timezone: 'America/Dominica', offset: 'UTC -04:00'},
        {countryCode: 'DO', timezone: 'America/Santo_Domingo', offset: 'UTC -04:00'},
        {countryCode: 'EC', timezone: 'America/Guayaquil', offset: 'UTC -05:00'},
        {countryCode: 'EC', timezone: 'Pacific/Galapagos', offset: 'UTC -06:00'},
        {countryCode: 'EG', timezone: 'Africa/Cairo', offset: 'UTC +03:00'},
        {countryCode: 'SV', timezone: 'America/El_Salvador', offset: 'UTC -06:00'},
        {countryCode: 'GQ', timezone: 'Africa/Malabo', offset: 'UTC +01:00'},
        {countryCode: 'ER', timezone: 'Africa/Asmara', offset: 'UTC +03:00'},
        {countryCode: 'EE', timezone: 'Europe/Tallinn', offset: 'UTC +03:00'},
        {countryCode: 'ET', timezone: 'Africa/Addis_Ababa', offset: 'UTC +03:00'},
        {countryCode: 'FK', timezone: 'Atlantic/Stanley', offset: 'UTC -03:00'},
        {countryCode: 'FO', timezone: 'Atlantic/Faroe', offset: 'UTC +01:00'},
        {countryCode: 'FJ', timezone: 'Pacific/Fiji', offset: 'UTC +12:00'},
        {countryCode: 'FI', timezone: 'Europe/Helsinki', offset: 'UTC +03:00'},
        {countryCode: 'FR', timezone: 'Europe/Paris', offset: 'UTC +02:00'},
        {countryCode: 'GF', timezone: 'America/Cayenne', offset: 'UTC -03:00'},
        {countryCode: 'PF', timezone: 'Pacific/Gambier', offset: 'UTC -09:00'},
        {countryCode: 'PF', timezone: 'Pacific/Marquesas', offset: 'UTC -09:30'},
        {countryCode: 'PF', timezone: 'Pacific/Tahiti', offset: 'UTC -10:00'},
        {countryCode: 'TF', timezone: 'Indian/Kerguelen', offset: 'UTC +05:00'},
        {countryCode: 'GA', timezone: 'Africa/Libreville', offset: 'UTC +01:00'},
        {countryCode: 'GM', timezone: 'Africa/Banjul', offset: 'UTC'},
        {countryCode: 'GE', timezone: 'Asia/Tbilisi', offset: 'UTC +04:00'},
        {countryCode: 'DE', timezone: 'Europe/Berlin', offset: 'UTC +02:00'},
        {countryCode: 'DE', timezone: 'Europe/Busingen', offset: 'UTC +02:00'},
        {countryCode: 'GH', timezone: 'Africa/Accra', offset: 'UTC'},
        {countryCode: 'GI', timezone: 'Europe/Gibraltar', offset: 'UTC +02:00'},
        {countryCode: 'GR', timezone: 'Europe/Athens', offset: 'UTC +03:00'},
        {countryCode: 'GL', timezone: 'America/Danmarkshavn', offset: 'UTC'},
        {countryCode: 'GL', timezone: 'America/Nuuk', offset: 'UTC -01:00'},
        {countryCode: 'GL', timezone: 'America/Scoresbysund', offset: 'UTC -01:00'},
        {countryCode: 'GL', timezone: 'America/Thule', offset: 'UTC -03:00'},
        {countryCode: 'GD', timezone: 'America/Grenada', offset: 'UTC -04:00'},
        {countryCode: 'GP', timezone: 'America/Guadeloupe', offset: 'UTC -04:00'},
        {countryCode: 'GU', timezone: 'Pacific/Guam', offset: 'UTC +10:00'},
        {countryCode: 'GT', timezone: 'America/Guatemala', offset: 'UTC -06:00'},
        {countryCode: 'GG', timezone: 'Europe/Guernsey', offset: 'UTC +01:00'},
        {countryCode: 'GN', timezone: 'Africa/Conakry', offset: 'UTC'},
        {countryCode: 'GW', timezone: 'Africa/Bissau', offset: 'UTC'},
        {countryCode: 'GY', timezone: 'America/Guyana', offset: 'UTC -04:00'},
        {countryCode: 'HT', timezone: 'America/Port-au-Prince', offset: 'UTC -04:00'},
        {countryCode: 'VA', timezone: 'Europe/Vatican', offset: 'UTC +02:00'},
        {countryCode: 'HN', timezone: 'America/Tegucigalpa', offset: 'UTC -06:00'},
        {countryCode: 'HK', timezone: 'Asia/Hong_Kong', offset: 'UTC +08:00'},
        {countryCode: 'HU', timezone: 'Europe/Budapest', offset: 'UTC +02:00'},
        {countryCode: 'IS', timezone: 'Atlantic/Reykjavik', offset: 'UTC'},
        {countryCode: 'IN', timezone: 'Asia/Kolkata', offset: 'UTC +05:30'},
        {countryCode: 'ID', timezone: 'Asia/Jakarta', offset: 'UTC +07:00'},
        {countryCode: 'ID', timezone: 'Asia/Jayapura', offset: 'UTC +09:00'},
        {countryCode: 'ID', timezone: 'Asia/Makassar', offset: 'UTC +08:00'},
        {countryCode: 'ID', timezone: 'Asia/Pontianak', offset: 'UTC +07:00'},
        {countryCode: 'IR', timezone: 'Asia/Tehran', offset: 'UTC +03:30'},
        {countryCode: 'IQ', timezone: 'Asia/Baghdad', offset: 'UTC +03:00'},
        {countryCode: 'IE', timezone: 'Europe/Dublin', offset: 'UTC +01:00'},
        {countryCode: 'IM', timezone: 'Europe/Isle_of_Man', offset: 'UTC +01:00'},
        {countryCode: 'IL', timezone: 'Asia/Jerusalem', offset: 'UTC +03:00'},
        {countryCode: 'IT', timezone: 'Europe/Rome', offset: 'UTC +02:00'},
        {countryCode: 'JM', timezone: 'America/Jamaica', offset: 'UTC -05:00'},
        {countryCode: 'JP', timezone: 'Asia/Tokyo', offset: 'UTC +09:00'},
        {countryCode: 'JE', timezone: 'Europe/Jersey', offset: 'UTC +01:00'},
        {countryCode: 'JO', timezone: 'Asia/Amman', offset: 'UTC +03:00'},
        {countryCode: 'KZ', timezone: 'Asia/Almaty', offset: 'UTC +05:00'},
        {countryCode: 'KZ', timezone: 'Asia/Aqtau', offset: 'UTC +05:00'},
        {countryCode: 'KZ', timezone: 'Asia/Aqtobe', offset: 'UTC +05:00'},
        {countryCode: 'KZ', timezone: 'Asia/Atyrau', offset: 'UTC +05:00'},
        {countryCode: 'KZ', timezone: 'Asia/Oral', offset: 'UTC +05:00'},
        {countryCode: 'KZ', timezone: 'Asia/Qostanay', offset: 'UTC +05:00'},
        {countryCode: 'KZ', timezone: 'Asia/Qyzylorda', offset: 'UTC +05:00'},
        {countryCode: 'KE', timezone: 'Africa/Nairobi', offset: 'UTC +03:00'},
        {countryCode: 'KI', timezone: 'Pacific/Kanton', offset: 'UTC +13:00'},
        {countryCode: 'KI', timezone: 'Pacific/Kiritimati', offset: 'UTC +14:00'},
        {countryCode: 'KI', timezone: 'Pacific/Tarawa', offset: 'UTC +12:00'},
        {countryCode: 'KP', timezone: 'Asia/Pyongyang', offset: 'UTC +09:00'},
        {countryCode: 'KR', timezone: 'Asia/Seoul', offset: 'UTC +09:00'},
        {countryCode: 'KW', timezone: 'Asia/Kuwait', offset: 'UTC +03:00'},
        {countryCode: 'KG', timezone: 'Asia/Bishkek', offset: 'UTC +06:00'},
        {countryCode: 'LA', timezone: 'Asia/Vientiane', offset: 'UTC +07:00'},
        {countryCode: 'LV', timezone: 'Europe/Riga', offset: 'UTC +03:00'},
        {countryCode: 'LB', timezone: 'Asia/Beirut', offset: 'UTC +03:00'},
        {countryCode: 'LS', timezone: 'Africa/Maseru', offset: 'UTC +02:00'},
        {countryCode: 'LR', timezone: 'Africa/Monrovia', offset: 'UTC'},
        {countryCode: 'LY', timezone: 'Africa/Tripoli', offset: 'UTC +02:00'},
        {countryCode: 'LI', timezone: 'Europe/Vaduz', offset: 'UTC +02:00'},
        {countryCode: 'LT', timezone: 'Europe/Vilnius', offset: 'UTC +03:00'},
        {countryCode: 'LU', timezone: 'Europe/Luxembourg', offset: 'UTC +02:00'},
        {countryCode: 'MO', timezone: 'Asia/Macau', offset: 'UTC +08:00'},
        {countryCode: 'MK', timezone: 'Europe/Skopje', offset: 'UTC +02:00'},
        {countryCode: 'MG', timezone: 'Indian/Antananarivo', offset: 'UTC +03:00'},
        {countryCode: 'MW', timezone: 'Africa/Blantyre', offset: 'UTC +02:00'},
        {countryCode: 'MY', timezone: 'Asia/Kuala_Lumpur', offset: 'UTC +08:00'},
        {countryCode: 'MY', timezone: 'Asia/Kuching', offset: 'UTC +08:00'},
        {countryCode: 'MV', timezone: 'Indian/Maldives', offset: 'UTC +05:00'},
        {countryCode: 'ML', timezone: 'Africa/Bamako', offset: 'UTC'},
        {countryCode: 'MT', timezone: 'Europe/Malta', offset: 'UTC +02:00'},
        {countryCode: 'MH', timezone: 'Pacific/Kwajalein', offset: 'UTC +12:00'},
        {countryCode: 'MH', timezone: 'Pacific/Majuro', offset: 'UTC +12:00'},
        {countryCode: 'MQ', timezone: 'America/Martinique', offset: 'UTC -04:00'},
        {countryCode: 'MR', timezone: 'Africa/Nouakchott', offset: 'UTC'},
        {countryCode: 'MU', timezone: 'Indian/Mauritius', offset: 'UTC +04:00'},
        {countryCode: 'YT', timezone: 'Indian/Mayotte', offset: 'UTC +03:00'},
        {countryCode: 'MX', timezone: 'America/Bahia_Banderas', offset: 'UTC -06:00'},
        {countryCode: 'MX', timezone: 'America/Cancun', offset: 'UTC -05:00'},
        {countryCode: 'MX', timezone: 'America/Chihuahua', offset: 'UTC -06:00'},
        {countryCode: 'MX', timezone: 'America/Ciudad_Juarez', offset: 'UTC -06:00'},
        {countryCode: 'MX', timezone: 'America/Hermosillo', offset: 'UTC -07:00'},
        {countryCode: 'MX', timezone: 'America/Matamoros', offset: 'UTC -05:00'},
        {countryCode: 'MX', timezone: 'America/Mazatlan', offset: 'UTC -07:00'},
        {countryCode: 'MX', timezone: 'America/Merida', offset: 'UTC -06:00'},
        {countryCode: 'MX', timezone: 'America/Mexico_City', offset: 'UTC -06:00'},
        {countryCode: 'MX', timezone: 'America/Monterrey', offset: 'UTC -06:00'},
        {countryCode: 'MX', timezone: 'America/Ojinaga', offset: 'UTC -05:00'},
        {countryCode: 'MX', timezone: 'America/Tijuana', offset: 'UTC -07:00'},
        {countryCode: 'FM', timezone: 'Pacific/Chuuk', offset: 'UTC +10:00'},
        {countryCode: 'FM', timezone: 'Pacific/Kosrae', offset: 'UTC +11:00'},
        {countryCode: 'FM', timezone: 'Pacific/Pohnpei', offset: 'UTC +11:00'},
        {countryCode: 'MD', timezone: 'Europe/Chisinau', offset: 'UTC +03:00'},
        {countryCode: 'MC', timezone: 'Europe/Monaco', offset: 'UTC +02:00'},
        {countryCode: 'MN', timezone: 'Asia/Hovd', offset: 'UTC +07:00'},
        {countryCode: 'MN', timezone: 'Asia/Ulaanbaatar', offset: 'UTC +08:00'},
        {countryCode: 'ME', timezone: 'Europe/Podgorica', offset: 'UTC +02:00'},
        {countryCode: 'MS', timezone: 'America/Montserrat', offset: 'UTC -04:00'},
        {countryCode: 'MA', timezone: 'Africa/Casablanca', offset: 'UTC +01:00'},
        {countryCode: 'MA', timezone: 'Africa/El_Aaiun', offset: 'UTC +01:00'},
        {countryCode: 'MZ', timezone: 'Africa/Maputo', offset: 'UTC +02:00'},
        {countryCode: 'MM', timezone: 'Asia/Yangon', offset: 'UTC +06:30'},
        {countryCode: 'NA', timezone: 'Africa/Windhoek', offset: 'UTC +02:00'},
        {countryCode: 'NR', timezone: 'Pacific/Nauru', offset: 'UTC +12:00'},
        {countryCode: 'NP', timezone: 'Asia/Kathmandu', offset: 'UTC +05:45'},
        {countryCode: 'NL', timezone: 'Europe/Amsterdam', offset: 'UTC +02:00'},
        {countryCode: 'NC', timezone: 'Pacific/Noumea', offset: 'UTC +11:00'},
        {countryCode: 'NZ', timezone: 'Pacific/Auckland', offset: 'UTC +13:00'},
        {countryCode: 'NZ', timezone: 'Pacific/Chatham', offset: 'UTC +13:45'},
        {countryCode: 'NI', timezone: 'America/Managua', offset: 'UTC -06:00'},
        {countryCode: 'NE', timezone: 'Africa/Niamey', offset: 'UTC +01:00'},
        {countryCode: 'NG', timezone: 'Africa/Lagos', offset: 'UTC +01:00'},
        {countryCode: 'NU', timezone: 'Pacific/Niue', offset: 'UTC -11:00'},
        {countryCode: 'NF', timezone: 'Pacific/Norfolk', offset: 'UTC +12:00'},
        {countryCode: 'MP', timezone: 'Pacific/Saipan', offset: 'UTC +10:00'},
        {countryCode: 'NO', timezone: 'Europe/Oslo', offset: 'UTC +02:00'},
        {countryCode: 'OM', timezone: 'Asia/Muscat', offset: 'UTC +04:00'},
        {countryCode: 'PK', timezone: 'Asia/Karachi', offset: 'UTC +05:00'},
        {countryCode: 'PW', timezone: 'Pacific/Palau', offset: 'UTC +09:00'},
        {countryCode: 'PS', timezone: 'Asia/Gaza', offset: 'UTC +03:00'},
        {countryCode: 'PS', timezone: 'Asia/Hebron', offset: 'UTC +03:00'},
        {countryCode: 'PA', timezone: 'America/Panama', offset: 'UTC -05:00'},
        {countryCode: 'PG', timezone: 'Pacific/Bougainville', offset: 'UTC +11:00'},
        {countryCode: 'PG', timezone: 'Pacific/Port_Moresby', offset: 'UTC +10:00'},
        {countryCode: 'PY', timezone: 'America/Asuncion', offset: 'UTC -03:00'},
        {countryCode: 'PE', timezone: 'America/Lima', offset: 'UTC -05:00'},
        {countryCode: 'PH', timezone: 'Asia/Manila', offset: 'UTC +08:00'},
        {countryCode: 'PN', timezone: 'Pacific/Pitcairn', offset: 'UTC -08:00'},
        {countryCode: 'PL', timezone: 'Europe/Warsaw', offset: 'UTC +02:00'},
        {countryCode: 'PT', timezone: 'Atlantic/Azores', offset: 'UTC'},
        {countryCode: 'PT', timezone: 'Atlantic/Madeira', offset: 'UTC +01:00'},
        {countryCode: 'PT', timezone: 'Europe/Lisbon', offset: 'UTC +01:00'},
        {countryCode: 'PR', timezone: 'America/Puerto_Rico', offset: 'UTC -04:00'},
        {countryCode: 'QA', timezone: 'Asia/Qatar', offset: 'UTC +03:00'},
        {countryCode: 'RO', timezone: 'Europe/Bucharest', offset: 'UTC +03:00'},
        {countryCode: 'RU', timezone: 'Asia/Anadyr', offset: 'UTC +12:00'},
        {countryCode: 'RU', timezone: 'Asia/Barnaul', offset: 'UTC +07:00'},
        {countryCode: 'RU', timezone: 'Asia/Chita', offset: 'UTC +09:00'},
        {countryCode: 'RU', timezone: 'Asia/Irkutsk', offset: 'UTC +08:00'},
        {countryCode: 'RU', timezone: 'Asia/Kamchatka', offset: 'UTC +12:00'},
        {countryCode: 'RU', timezone: 'Asia/Khandyga', offset: 'UTC +09:00'},
        {countryCode: 'RU', timezone: 'Asia/Krasnoyarsk', offset: 'UTC +07:00'},
        {countryCode: 'RU', timezone: 'Asia/Magadan', offset: 'UTC +11:00'},
        {countryCode: 'RU', timezone: 'Asia/Novokuznetsk', offset: 'UTC +07:00'},
        {countryCode: 'RU', timezone: 'Asia/Novosibirsk', offset: 'UTC +07:00'},
        {countryCode: 'RU', timezone: 'Asia/Omsk', offset: 'UTC +06:00'},
        {countryCode: 'RU', timezone: 'Asia/Sakhalin', offset: 'UTC +11:00'},
        {countryCode: 'RU', timezone: 'Asia/Srednekolymsk', offset: 'UTC +11:00'},
        {countryCode: 'RU', timezone: 'Asia/Tomsk', offset: 'UTC +07:00'},
        {countryCode: 'RU', timezone: 'Asia/Ust-Nera', offset: 'UTC +10:00'},
        {countryCode: 'RU', timezone: 'Asia/Vladivostok', offset: 'UTC +10:00'},
        {countryCode: 'RU', timezone: 'Asia/Yakutsk', offset: 'UTC +09:00'},
        {countryCode: 'RU', timezone: 'Asia/Yekaterinburg', offset: 'UTC +05:00'},
        {countryCode: 'RU', timezone: 'Europe/Astrakhan', offset: 'UTC +04:00'},
        {countryCode: 'RU', timezone: 'Europe/Kaliningrad', offset: 'UTC +02:00'},
        {countryCode: 'RU', timezone: 'Europe/Kirov', offset: 'UTC +03:00'},
        {countryCode: 'RU', timezone: 'Europe/Moscow', offset: 'UTC +03:00'},
        {countryCode: 'RU', timezone: 'Europe/Samara', offset: 'UTC +04:00'},
        {countryCode: 'RU', timezone: 'Europe/Saratov', offset: 'UTC +04:00'},
        {countryCode: 'RU', timezone: 'Europe/Ulyanovsk', offset: 'UTC +04:00'},
        {countryCode: 'RU', timezone: 'Europe/Volgograd', offset: 'UTC +03:00'},
        {countryCode: 'RW', timezone: 'Africa/Kigali', offset: 'UTC +02:00'},
        {countryCode: 'RE', timezone: 'Indian/Reunion', offset: 'UTC +04:00'},
        {countryCode: 'BL', timezone: 'America/St_Barthelemy', offset: 'UTC -04:00'},
        {countryCode: 'SH', timezone: 'Atlantic/St_Helena', offset: 'UTC'},
        {countryCode: 'KN', timezone: 'America/St_Kitts', offset: 'UTC -04:00'},
        {countryCode: 'LC', timezone: 'America/St_Lucia', offset: 'UTC -04:00'},
        {countryCode: 'MF', timezone: 'America/Marigot', offset: 'UTC -04:00'},
        {countryCode: 'PM', timezone: 'America/Miquelon', offset: 'UTC -02:00'},
        {countryCode: 'VC', timezone: 'America/St_Vincent', offset: 'UTC -04:00'},
        {countryCode: 'WS', timezone: 'Pacific/Apia', offset: 'UTC +13:00'},
        {countryCode: 'SM', timezone: 'Europe/San_Marino', offset: 'UTC +02:00'},
        {countryCode: 'ST', timezone: 'Africa/Sao_Tome', offset: 'UTC'},
        {countryCode: 'SA', timezone: 'Asia/Riyadh', offset: 'UTC +03:00'},
        {countryCode: 'SN', timezone: 'Africa/Dakar', offset: 'UTC'},
        {countryCode: 'RS', timezone: 'Europe/Belgrade', offset: 'UTC +02:00'},
        {countryCode: 'SC', timezone: 'Indian/Mahe', offset: 'UTC +04:00'},
        {countryCode: 'SL', timezone: 'Africa/Freetown', offset: 'UTC'},
        {countryCode: 'SG', timezone: 'Asia/Singapore', offset: 'UTC +08:00'},
        {countryCode: 'SX', timezone: 'America/Lower_Princes', offset: 'UTC -04:00'},
        {countryCode: 'SK', timezone: 'Europe/Bratislava', offset: 'UTC +02:00'},
        {countryCode: 'SI', timezone: 'Europe/Ljubljana', offset: 'UTC +02:00'},
        {countryCode: 'SB', timezone: 'Pacific/Guadalcanal', offset: 'UTC +11:00'},
        {countryCode: 'SO', timezone: 'Africa/Mogadishu', offset: 'UTC +03:00'},
        {countryCode: 'ZA', timezone: 'Africa/Johannesburg', offset: 'UTC +02:00'},
        {countryCode: 'GS', timezone: 'Atlantic/South_Georgia', offset: 'UTC -02:00'},
        {countryCode: 'SS', timezone: 'Africa/Juba', offset: 'UTC +02:00'},
        {countryCode: 'ES', timezone: 'Africa/Ceuta', offset: 'UTC +02:00'},
        {countryCode: 'ES', timezone: 'Atlantic/Canary', offset: 'UTC +01:00'},
        {countryCode: 'ES', timezone: 'Europe/Madrid', offset: 'UTC +02:00'},
        {countryCode: 'LK', timezone: 'Asia/Colombo', offset: 'UTC +05:30'},
        {countryCode: 'SD', timezone: 'Africa/Khartoum', offset: 'UTC +02:00'},
        {countryCode: 'SR', timezone: 'America/Paramaribo', offset: 'UTC -03:00'},
        {countryCode: 'SJ', timezone: 'Arctic/Longyearbyen', offset: 'UTC +02:00'},
        {countryCode: 'SZ', timezone: 'Africa/Mbabane', offset: 'UTC +02:00'},
        {countryCode: 'SE', timezone: 'Europe/Stockholm', offset: 'UTC +02:00'},
        {countryCode: 'CH', timezone: 'Europe/Zurich', offset: 'UTC +02:00'},
        {countryCode: 'SY', timezone: 'Asia/Damascus', offset: 'UTC +03:00'},
        {countryCode: 'TW', timezone: 'Asia/Taipei', offset: 'UTC +08:00'},
        {countryCode: 'TJ', timezone: 'Asia/Dushanbe', offset: 'UTC +05:00'},
        {countryCode: 'TZ', timezone: 'Africa/Dar_es_Salaam', offset: 'UTC +03:00'},
        {countryCode: 'TH', timezone: 'Asia/Bangkok', offset: 'UTC +07:00'},
        {countryCode: 'TL', timezone: 'Asia/Dili', offset: 'UTC +09:00'},
        {countryCode: 'TG', timezone: 'Africa/Lome', offset: 'UTC'},
        {countryCode: 'TK', timezone: 'Pacific/Fakaofo', offset: 'UTC +13:00'},
        {countryCode: 'TO', timezone: 'Pacific/Tongatapu', offset: 'UTC +13:00'},
        {countryCode: 'TT', timezone: 'America/Port_of_Spain', offset: 'UTC -04:00'},
        {countryCode: 'TN', timezone: 'Africa/Tunis', offset: 'UTC +01:00'},
        {countryCode: 'TR', timezone: 'Europe/Istanbul', offset: 'UTC +03:00'},
        {countryCode: 'TM', timezone: 'Asia/Ashgabat', offset: 'UTC +05:00'},
        {countryCode: 'TC', timezone: 'America/Grand_Turk', offset: 'UTC -04:00'},
        {countryCode: 'TV', timezone: 'Pacific/Funafuti', offset: 'UTC +12:00'},
        {countryCode: 'UG', timezone: 'Africa/Kampala', offset: 'UTC +03:00'},
        {countryCode: 'UA', timezone: 'Europe/Kyiv', offset: 'UTC +03:00'},
        {countryCode: 'UA', timezone: 'Europe/Simferopol', offset: 'UTC +03:00'},
        {countryCode: 'AE', timezone: 'Asia/Dubai', offset: 'UTC +04:00'},
        {countryCode: 'GB', timezone: 'Europe/London', offset: 'UTC +01:00'},
        {countryCode: 'US', timezone: 'America/Adak', offset: 'UTC -09:00'},
        {countryCode: 'US', timezone: 'America/Anchorage', offset: 'UTC -08:00'},
        {countryCode: 'US', timezone: 'America/Boise', offset: 'UTC -06:00'},
        {countryCode: 'US', timezone: 'America/Chicago', offset: 'UTC -05:00'},
        {countryCode: 'US', timezone: 'America/Denver', offset: 'UTC -06:00'},
        {countryCode: 'US', timezone: 'America/Detroit', offset: 'UTC -04:00'},
        {countryCode: 'US', timezone: 'America/Indiana/Indianapolis', offset: 'UTC -04:00'},
        {countryCode: 'US', timezone: 'America/Indiana/Knox', offset: 'UTC -05:00'},
        {countryCode: 'US', timezone: 'America/Indiana/Marengo', offset: 'UTC -04:00'},
        {countryCode: 'US', timezone: 'America/Indiana/Petersburg', offset: 'UTC -04:00'},
        {countryCode: 'US', timezone: 'America/Indiana/Tell_City', offset: 'UTC -05:00'},
        {countryCode: 'US', timezone: 'America/Indiana/Vevay', offset: 'UTC -04:00'},
        {countryCode: 'US', timezone: 'America/Indiana/Vincennes', offset: 'UTC -04:00'},
        {countryCode: 'US', timezone: 'America/Indiana/Winamac', offset: 'UTC -04:00'},
        {countryCode: 'US', timezone: 'America/Juneau', offset: 'UTC -08:00'},
        {countryCode: 'US', timezone: 'America/Kentucky/Louisville', offset: 'UTC -04:00'},
        {countryCode: 'US', timezone: 'America/Kentucky/Monticello', offset: 'UTC -04:00'},
        {countryCode: 'US', timezone: 'America/Los_Angeles', offset: 'UTC -07:00'},
        {countryCode: 'US', timezone: 'America/Menominee', offset: 'UTC -05:00'},
        {countryCode: 'US', timezone: 'America/Metlakatla', offset: 'UTC -08:00'},
        {countryCode: 'US', timezone: 'America/New_York', offset: 'UTC -04:00'},
        {countryCode: 'US', timezone: 'America/Nome', offset: 'UTC -08:00'},
        {countryCode: 'US', timezone: 'America/North_Dakota/Beulah', offset: 'UTC -05:00'},
        {countryCode: 'US', timezone: 'America/North_Dakota/Center', offset: 'UTC -05:00'},
        {countryCode: 'US', timezone: 'America/North_Dakota/New_Salem', offset: 'UTC -05:00'},
        {countryCode: 'US', timezone: 'America/Phoenix', offset: 'UTC -07:00'},
        {countryCode: 'US', timezone: 'America/Sitka', offset: 'UTC -08:00'},
        {countryCode: 'US', timezone: 'America/Yakutat', offset: 'UTC -08:00'},
        {countryCode: 'US', timezone: 'Pacific/Honolulu', offset: 'UTC -10:00'},
        {countryCode: 'UM', timezone: 'Pacific/Midway', offset: 'UTC -11:00'},
        {countryCode: 'UM', timezone: 'Pacific/Wake', offset: 'UTC +12:00'},
        {countryCode: 'UY', timezone: 'America/Montevideo', offset: 'UTC -03:00'},
        {countryCode: 'UZ', timezone: 'Asia/Samarkand', offset: 'UTC +05:00'},
        {countryCode: 'UZ', timezone: 'Asia/Tashkent', offset: 'UTC +05:00'},
        {countryCode: 'VU', timezone: 'Pacific/Efate', offset: 'UTC +11:00'},
        {countryCode: 'VE', timezone: 'America/Caracas', offset: 'UTC -04:00'},
        {countryCode: 'VN', timezone: 'Asia/Ho_Chi_Minh', offset: 'UTC +07:00'},
        {countryCode: 'VG', timezone: 'America/Tortola', offset: 'UTC -04:00'},
        {countryCode: 'VI', timezone: 'America/St_Thomas', offset: 'UTC -04:00'},
        {countryCode: 'WF', timezone: 'Pacific/Wallis', offset: 'UTC +12:00'},
        {countryCode: 'YE', timezone: 'Asia/Aden', offset: 'UTC +03:00'},
        {countryCode: 'ZM', timezone: 'Africa/Lusaka', offset: 'UTC +02:00'},
        {countryCode: 'ZW', timezone: 'Africa/Harare', offset: 'UTC +02:00'},
        {countryCode: 'AX', timezone: 'Europe/Mariehamn', offset: 'UTC +03:00'},
    ]

    const selectTimezone = (timezone) => {
        selectedTimezone.value = timezone.timezone
        term.value = timezone.timezone
        emit('update:content', timezone.timezone)
        nextTick(() => {
            timezoneRecentlySelected.value = true
        })
    }

    const resetSelectedTimezone = () => {
        selectedTimezone.value = null
    }

    const matchingTimezones = computed(() => {
        if (term.value.length < 2) {
            return []
        }
        return timezones.filter((timezone) => {
            return timezone.timezone.toLowerCase().includes(term.value.toLowerCase())
        })
    })

    watch(term, () => {
        timezoneRecentlySelected.value = false
    })

    onMounted(() => {
        nextTick(() => {
            if (props.content) {
                const matchingTimezone = timezones.find((timezone) => {
                    return timezone.timezone === props.content
                })
                if (matchingTimezone) {
                    term.value = matchingTimezone.timezone
                    nextTick(() => {
                        timezoneRecentlySelected.value = true
                    })
                }
            }
        })

    })

</script>
<template>
    <div class="mb-4">
        <input-label
            :label="label"
            :required="required"
        />
        <div
            class="relative mt-1 rounded-md shadow-sm"
        >
            <input
                type="text"
                class="block w-full p-2 mt-1 border rounded-md"
                :class="[inputClass]"
                :placeholder="placeholder"
                v-model="term"
                @input="resetSelectedTimezone"
            />

            <div class="overflow-hidden h-0">
                <input
                    type="text"
                    v-model="selectedTimezone"
                    :required="required"
                    :name="name"
                />
            </div>

            <ul
                v-if="!timezoneRecentlySelected"
                class="text-left"
                :class="props.timezoneListClass"
            >
                <li
                    v-for="(timezone, i) in matchingTimezones"
                    :key="i"
                    class="px-2 cursor-pointer py-0.5 hover:bg-gray-50"
                    @click="selectTimezone(timezone)"
                >
                    {{ timezone.timezone }}
                </li>
            </ul>
        </div>
        <input-error v-if="error" :error="error" />
    </div>
  </template>
