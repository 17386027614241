<template>
    <div>
        <input-select
            v-if="options"
            :error="error"
            :label="label"
            :placeholder="placeholder"
            :required="required"
            :name="name"
            :options="options"
            :content="modelValue ? modelValue.toString() : null"
            @update:content="$emit('update:modelValue', $event)"
        />
        <mf-spinner-medium v-else class="w-16 h-8"></mf-spinner-medium>
    </div>
</template>
<script>
export default {
    props: {
        error: String,
        label: String,
        placeholder: String,
        required: Boolean,
        name: String,
        modelValue: {},
    },
    emits: [
        'update:modelValue'
    ],
    data() {
        return {
            options: null
        };
    },
    async mounted() {
        const response = await axios.get(route('manageCourses.providers.index'));
        this.options = response.data.data.map((provider) => ({
            value: provider.id.toString(),
            label: provider.name
        }));

        if (! this.required) {
            this.options.push({
                value: null,
                label: _mft('course:input.provider.notSpecified')
            });
        }
    }
}
</script>
