<script setup>
    import { onMounted, ref } from 'vue'

    const emit = defineEmits('moveComplete')

    const props = defineProps({
        expertContract: {
            type: Object,
            required: true
        },
    })

    const folders = ref([])
    const selectedFolder = ref(null)
    const folderModal = ref(null)

    const loadFolders = async () => {
        const response = await axios.get(route('expert-contracts.folders.index'))
        folders.value = response.data.folders
    }

    const move = async () => {
        const res = await axios.post(route('expert-contracts.move', props.expertContract.id), { folder_id: selectedFolder.value })
        emit('moveComplete')
        folderModal.value.closeModal()
    }

    onMounted(() => {
        selectedFolder.value = props.expertContract.folder_id
        loadFolders()
    })

</script>
<template>
    <div>
        <general-modal ref="folderModal">
            <template v-slot:header>{{ _mft('expertContract:folder.moveToFolder') }}</template>
            <template v-slot:body>
                <select
                    class="myflow-basic-input"
                    name="folder_id"
                    v-model="selectedFolder"
                >
                    <option value="">{{ _mft('expertContract:folder.noFolder') }}</option>
                    <option
                        v-for="(folder, i) in folders"
                        :value="folder.id"
                    >
                        {{ folder.name }}
                    </option>
                </select>

                <button-primary
                    type="submit"
                    class="float-right"
                    @click="move"
                >{{ _mft('expertContract:folder.move') }}</button-primary><br>
            </template>
            <template v-slot:open>
                <button-secondary class="ml-2" size="sm">
                    {{ _mft('expertContract:folder.move') }} <i class="ml-1 fa-regular fa-folder-plus"></i>
                </button-secondary>
            </template>
        </general-modal>
    </div>
</template>
