<template>
    <form method="POST" :action="route('crm.communications.settings.email.update')">
        <input type="hidden" name="_token" :value="CSRF_TOKEN">

        <input-select
            v-model:content="mailerTypeRef"
            @update:content="hasChanges = true"
            :options="MAILER_TYPE_OPTIONS"
            :label="_mft('crm:emailSettings.deliveryMethod')"
            required
            name="email_mailer_type"
        ></input-select>

        <template v-if="mailerTypeRef === 'smtp'">
            <input-text
                name="smtp_server_address"
                :label="_mft('crm:emailSettings.serverAddress.label')"
                :placeholder="_mft('crm:emailSettings.serverAddress.placeholder')"
                :content="smtpServerAddress"
                @update:content="hasChanges = true"
                required
            ></input-text>

            <input-text
                name="smtp_server_port"
                :label="_mft('crm:emailSettings.serverPort.label')"
                :placeholder="_mft('crm:emailSettings.serverPort.label')"
                inputtype="tel"
                :content="smtpServerPort"
                @update:content="hasChanges = true"
                required
            ></input-text>

            <input-text
                name="smtp_server_username"
                :label="_mft('crm:emailSettings.serverUsername.label')"
                :placeholder="_mft('crm:emailSettings.serverUsername.placeholder')"
                :content="smtpServerUsername"
                @update:content="hasChanges = true"
                required
            ></input-text>

            <input-text
                name="smtp_server_password"
                :label="_mft('crm:emailSettings.serverPassword.label')"
                inputtype="password"
                :placeholder="_mft('crm:emailSettings.serverPassword.placeholder')"
                :content="smtpServerPassword"
                @update:content="hasChanges = true"
                required
            ></input-text>
            <input-text
                name="email_mailer_from"
                :label="_mft('crm:emailSettings.fromAddress.label')"
                :placeholder="_mft('shared:egShort') + ' ' + `${mailerFromExample}`"
                :content="mailerFrom"
                @update:content="hasChanges = true"
                pattern="^[A-z0-9+_.-]+@[A-z0-9+_.-]+$"
                required
            ></input-text>
        </template>
        <template v-else>
            <div class="flex items-center justify-between gap-3">
                <input-text
                    class="flex-grow"
                    name="email_mailer_from"
                    :label="_mft('crm:emailSettings.fromName.label')"
                    :placeholder="_mft('shared:egShort') + ' ' + `${mailerFromExample.split('@')[0]}`"
                    :content="mailerFrom"
                    @update:content="hasChanges = true"
                    pattern="^[A-z0-9+_.-]+$"
                    required
                ></input-text>

                <input-text
                    disabled
                    label="&nbsp;"
                    class="flex-grow-0 flex-shrink"
                    content="@"
                ></input-text>

                <input-select
                    name="email_mailer_from_confirmed_domain_id"
                    :label="_mft('crm:emailSettings.fromDomain.label')"
                    :options="optionsForConfirmedDomain"
                    :content="mailerFromConfirmedDomainId"
                    @update:content="hasChanges = true"
                    class="flex-grow"
                    required
                ></input-select>
            </div>
        </template>

        <p v-if="confirmedDomainsLoaded && optionsForConfirmedDomain.length === 0" class="italic">
            {{ _mft('crm:emailSettings.confirmedDomainMissing.text') }} <a :href="route('settings.company.settings.domains')">{{ _mft('crm:emailSettings.confirmedDomainMissing.link') }}</a>.
        </p>

        <slot v-if="!hasChanges" name="mail-validation-status">
        </slot>

        <div class="flex justify-end space-x-4">
            <input
                type="submit"
                :value="_mft('crm:emailSettings.verifyAndSave')"
                class="myflow-basic-button"
            />
        </div>
    </form>
</template>

<script setup>
    import { ref, onMounted } from 'vue';

    const MAILER_TYPE_OPTIONS = [
        {
            value: 'smtp',
            label: 'SMTP'
        },
        {
            value: 'mandrill+myflow',
            label: 'myflow mailserver'
        }
    ];

    const CSRF_TOKEN = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    const props = defineProps({
        mailerType: { type: String },
        mailerFrom: { type: String },
        mailerFromExample: { type: String, required: true },
        mailerFromConfirmedDomainId: { type: Number },
        smtpServerAddress: { type: String },
        smtpServerPort: { type: String },
        smtpServerUsername: { type: String },
        smtpServerPassword: { type: String },
    });

    const mailerTypeRef = ref(props.mailerType);
    const hasChanges = ref(false);

    const optionsForConfirmedDomain = ref([]);
    const confirmedDomainsLoaded = ref(false);

    onMounted(async () => {
        const response = await axios.get(route('domain_validations.confirmed_domains.index'))
        optionsForConfirmedDomain.value = response.data.data
            .filter(d => d.is_validated_for_email)
            .sort((a, b) => a.domain.localeCompare(b.domain))
            .map(d => ({ label: d.domain, value: d.id }));
        confirmedDomainsLoaded.value = true;
    });
</script>
