<script setup>
import {computed, onMounted, ref, watch} from "vue";
import ProductForm from "./_product-form.vue";
import IconChevron from "../icons/iconChevron.vue";

    const props = defineProps({
        _products: {
            type: Array,
            required: true,
        },
        currency: {
            type: String,
            required: false,
            default: ''
        }
    })

    const emit = defineEmits(['update:products', 'productsValidate'])


    const products = ref([])
    const addProduct = () => {
        products.value.push({
            linkableType: 'custom',
            linkableId: null,
            title: '',
            description: '',
            price: 0,
            quantity: 1,
            unit: '',
        })
    }

    const removeProduct = (index) => {
        products.value.splice(index, 1)
    }

    const moveProductUp = (product) => {
        const index = products.value.indexOf(product)
        if (index === 0) return
        const newIndex = index - 1
        products.value.splice(newIndex, 0, products.value.splice(index, 1)[0])
    }

    const moveProductDown = (product) => {
        const index = products.value.indexOf(product)
        if (index === products.value.length - 1) return
        const newIndex = index + 1
        products.value.splice(newIndex, 0, products.value.splice(index, 1)[0])
    }

    const productsValidate = computed(() => {
        if (products.value.length === 0) {
            return true
        }
        return products.value.every(product => {
            if (product.linkableType === 'custom' || product.linkableType === null) {
                return product.title !== ''
            }
            return product.linkableId !== null && product.title !== ''
        })
    })

    watch(() => props._products.value, (newVal) => {
        products.value = props._products
    }, {deep: true})

    watch(() => products.value, (newVal) => {
        emit('update:products', newVal)
        emit('productsValidate', productsValidate.value)
    }, {deep: true})

    onMounted(() => {
        emit('productsValidate', productsValidate.value)
    })

</script>

<template>
    <div>
        <ul class="mb-4 divide-y">
            <li
                v-for="(product, index) in products"
                :key="index"
                class="flex items-center py-2 space-x-2"
            >
                <div class="flex w-full flex-col items-start md:space-x-4 md:flex-row md:justify-between">
                    <div>
                        <h5 class="text-sm md:text-lg">
                            {{ product.title }}
                        </h5>
                        <div class="text-xs">
                            <span
                                v-if="product.quantity"
                            >
                                {{ product.quantity }}
                            </span>
                            <span class="text-gray-400">{{ product.quantity && product.price ? ' x ' : '' }}</span>
                            <span v-if="product.price">
                                {{ product.price }} {{ currency }}
                            </span>
                        </div>
                    </div>

                    <div class="mt-1 flex space-x-2 md:mt-0">
                        <button-danger
                            size="sm"
                            @click="removeProduct(index)"
                        >
                            {{ _mft('shared:action.remove') }}
                        </button-danger>
                        <ProductForm
                            :product="product"
                            @update:product="products[index] = $event"
                        />
                    </div>
                </div>
                <div class="flex flex-col justify-center space-y-2">
                    <button
                        @click="moveProductUp(product)"
                    ><icon-chevron class="h-4 w-4 rotate-180 fill-wlPrimary hover:opacity-80" /></button>
                    <button
                        @click="moveProductDown(product)"
                    ><icon-chevron class="h-4 w-4 fill-wlPrimary hover:opacity-80" /></button>
                </div>
            </li>
        </ul>
        <div
            :class="products.length === 0 ? 'text-center' : 'text-right'"
        >
            <button-primary
                @click="addProduct"
                size="sm"
            >
                {{ _mft('invoiceLink:addProduct') }}
            </button-primary>
        </div>
    </div>
</template>

<style scoped>

</style>
