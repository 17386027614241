<script setup>

import {computed, ref} from "vue";

const emit = defineEmits(['reloadSessions'])

const props = defineProps({
    client: {
        type: Object,
        required: true
    }
})

const sessionsExpanded = ref(false)

const loadAllSessions = () => {

}

const reloadSessions = (e) => {
    emit('reloadSessions')
}

const redirectToSession = (e) => {
    window.location.href = e.redirectUrl
}

const nextBookedSession = computed(() => {
    return props.client.sessions.find(session => session.status === 'booked')
})

const bookedSessions = computed(() => {
    return props.client.sessions.filter(session => session.status === 'booked')
})

const upcomingSessions = computed(() => {
    return props.client.sessions.filter(session => session.status === 'bookable' || session.status === 'reschedulable')
})

const oldSessions = computed(() => {
    return props.client.sessions.filter(session => session.status === 'no-show' || session.status === 'completed' || session.status === 'cancelled')
})

</script>

<template>
    <div :class="sessionsExpanded ? 'client-sessions-list last:rounded-b-wl' : ''">
        <list-basic-item
            :hightlight-on-hover="! sessionsExpanded"
        >
            <template #status>
            </template>
            <template #title>
                <h4>
                    {{ client.client.name }}
                </h4>
            </template>
            <template #default>
                <div>
                    <p
                        v-if="nextBookedSession"
                        class="space-x-1 text-gray-400 text-xs tracking-wide"
                    >
                        <span>{{ _mft('offerable:sessions.nextUp') }}: {{ _mft('offerable:session.status.count', {count: nextBookedSession.stats.session_number, total: nextBookedSession.stats.sessions_total}) }}</span>
                        <span> | </span>
                        <span>{{ nextBookedSession.scheduled_at_short }}</span>
                        <span> | </span>
                        <span>{{ nextBookedSession.stats.session_duration }}</span>
                    </p>
                </div>
            </template>
            <template #actions>
                <button-secondary
                    @click="sessionsExpanded = ! sessionsExpanded"
                    size="sm"
                    class="mb-0.5"
                >
                    {{ sessionsExpanded ? _mft('offerable:action.hideSessions') : _mft('offerable:action.showSessions') }}
                </button-secondary>
            </template>

            <template #chin>
                <list-basic
                    v-if="sessionsExpanded"
                    list-class="-mt-6"
                >
                    <h5
                        v-if="bookedSessions.length > 0"
                        class="text-base px-4 md:px-6 pt-6"
                    >{{ _mft('offerable:sessions.title.booked') }}</h5>

                    <coaching-v2-client-sessions-list-session
                        v-for="(session, si) in bookedSessions"
                        :key="si"
                        :session="session"
                        @reloadSessions="loadAllSessions"
                        :class="si === 0 ? '!border-t-0' : ''"
                    ></coaching-v2-client-sessions-list-session>

                    <h5
                        v-if="upcomingSessions.length > 0"
                        class="text-base px-4 md:px-6 pt-6"
                    >{{ _mft('offerable:sessions.title.bookable') }}</h5>

                    <coaching-v2-client-sessions-list-session
                        v-for="(session, si) in upcomingSessions"
                        :key="si"
                        :session="session"
                        @reloadSessions="loadAllSessions"
                        :class="si === 0 ? '!border-t-0' : ''"
                    ></coaching-v2-client-sessions-list-session>

                    <h5
                        v-if="oldSessions.length > 0"
                        class="text-base px-4 md:px-6 pt-6"
                    >{{ _mft('offerable:sessions.title.completedOrNoShow') }}</h5>

                    <coaching-v2-client-sessions-list-session
                        v-for="(session, si) in oldSessions"
                        :key="si"
                        :session="session"
                        @reloadSessions="loadAllSessions"
                        :class="si === 0 ? '!border-t-0' : ''"
                    ></coaching-v2-client-sessions-list-session>
                </list-basic>
            </template>
        </list-basic-item>
    </div>
</template>

<style scoped>
.client-sessions-list {
    box-shadow: inset 0 -38px 30px -38px #cccccc;
    padding-bottom: 30px;
}
</style>
