<script setup>
import {computed, onMounted, ref} from "vue";

const props = defineProps({
    courseId: {
        type: Number,
        required: true
    },
    currentChapterId: {
        type: Number,
        required: false,
        default: null,
    }
})

const course = ref(null)
const chapterCompletion = ref(null)
const open = ref()

const loadChapters = async () => {
    const res = await axios.get(route('api.course.customerarea.chapters', props.courseId))

    course.value = res.data.course
    chapterCompletion.value = res.data.chapter_completions
}

const updateCompletion = async (chapter) => {
    await axios.post(route('api.course.customerarea.chapter.toggle-completed', [props.courseId, chapter.id]))
}

onMounted(() => {
    loadChapters()
})

</script>
<template>
    <div
        class="lg:w-72 lg:shrink-0"
    >
        <span
            class="lg:hidden fixed bottom-4 right-4 z-modal-toggler"
        >
            <button-secondary
                @click="open = !open"
            >
                <span class="block relative">
                    <span :class="open ? 'opacity-0' : ''">{{ _mft('course:showChapters') }}</span>
                    <span
                        v-if="open"
                        class="block bg-white absolute inset-0 rounded-wl"
                    >
                        {{ _mft('shared:modal.close')}}
                    </span>
                </span>
            </button-secondary>
        </span>
        <div
            class="fixed top-16 bottom-0 z-modal-backdrop bg-black transition-opacity lg:hidden"
            :class="open ? 'left-0 w-screen opacity-10' : 'left-full opacity-0'"
            @click="open = false"
        >
        </div>
        <div
            class="w-4/5 md:w-72 fixed top-16 bottom-0 bg-white border-r pt-4 lg:pt-8 transition-all z-modal lg:z-auto max-h-screen overflow-y-scroll pb-24 lg:pb-24"
            :class="open ? 'right-0' : '-right-full lg:left-0 lg:right-auto'"
        >
            <div v-if="course">
                <div class="px-4 lg:px-8 mb-4">
                    <h4>
                        <a :href="route('course.customerarea.show', course.id)" class="mf-underline text-inherit">
                            {{ course.title }}
                        </a>
                    </h4>
                </div>
                <ul class="px-3 lg:px-7">
                    <li
                        v-for="(chapter, i) in course.chapters"
                        :key="chapter.id"
                        class="flex items-start justify-between relative py-2 hover:bg-gray-50 px-1"
                    >
                        <div>
                            <a
                                v-if="chapter.published && (chapter.available || course.chapters[i - 1]?.completed)"
                                :href="route('course.customerarea.chapter.show', [course.id, chapter.id])"
                                class="mf-underline text-inherit"
                                :class="chapter.id === currentChapterId ? 'font-bold' : ''"
                            >
                                {{ chapter.title }}
                                <span
                                    v-if="chapter.id === currentChapterId"
                                    class="absolute right-full top-1 bottom-1 bg-wlPrimary w-1 -translate-x-1 rounded-wl"
                                ></span>
                            </a>
                            <span
                                v-else
                                class="text-gray-400 cursor-not-allowed"
                            >
                                {{ chapter.title }}
                            </span>
                            <div
                                v-if="course.has_multiple_providers && chapter.provider"
                                class="text-gray-400 text-xs"
                            >{{ _mft('shared:by') }} {{ chapter.provider.name }}</div>
                        </div>
                        <toggle-done
                            v-if="chapter.available || course.chapters[i - 1]?.completed"
                            v-model:content="chapter.completed"
                            @update:content="updateCompletion(chapter)"
                            :disabled="! chapter.published"
                            class="w-5 h-5 rounded-sm ml-2 shrink-0"
                        >Toggle</toggle-done>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
