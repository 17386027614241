<script setup>
import {useSlots} from "vue";

const props = defineProps({
    hightlightOnHover: {
        type: Boolean,
        required: false,
        default: true
    }
})

const slots = useSlots();

</script>

<template>
    <li
        :class="hightlightOnHover ? '' : ''"
    >
        <div
            class="flex flex-col px-4 py-4 pr-4 md:flex-row md:justify-between md:px-6 md:pr-6"
        >
            <div
                v-if="slots.status"
            >
                <slot name="status" />
            </div>
            <div class="block w-full">
                <div
                    v-if="slots.title"
                    class="font-medium text-gray-900 truncate"
                >
                    <slot name="title" />
                </div>
                <slot />
            </div>
            <div class="flex justify-end -mt-5 space-x-1 md:flex-col-reverse md:mt-0 md:items-end">
                <slot name="actions" />
            </div>
        </div>
        <slot name="chin" />
    </li>
</template>

<style scoped>

</style>
