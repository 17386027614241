<script setup>
import { computed, ref, onMounted, watch } from 'vue'

const props = defineProps({
    currentUserId: {
        type: Number,
        required: false,
        default: null
    },
    messageListClass: {
        type: String,
        required: false,
        default: 'h-96'
    },
    messageThreadId: {
        type: Number,
        required: true
    }
})

const message = ref('')
const thread = ref(null)
const _currentUserId = ref(props.currentUserId)
const echo = EchoFactory()
const lastReadMessageId = ref(null)

const loadThread = async () => {
    const res = await axios.get(route('support.chat.message.thread.show', props.messageThreadId))
    thread.value = res.data.thread
    _currentUserId.value = res.data.current_user_id
}

const sendMessage = async () => {
    await axios.post(route('support.chat.message.thread.store', props.messageThreadId), {
        thread_id: props.messageThreadId,
        message: message.value
    })
    message.value = ''
}

const conversationMessages = computed(() => {
    if (!thread.value) {
        return []
    }
    return thread.value.messages.sort((a, b) => b.id - a.id)
})

const sentByCurrentUser = computed(() => {
    if (!thread.value || !_currentUserId) {
        return false
    }
    return thread.value.messages.reverse().find(message => message.user_id === _currentUserId)
})

const reportMessageRead = async (messageId) => {
    await axios.post(route('support.chat.message.read', messageId))
}

watch(() => thread, () => {
    if (!thread.value || thread.value.messages.length === 0) {
        return
    }
    lastReadMessageId.value = thread.value.messages.sort((a, b) => b.id - a.id)[0].id
}, { deep:true })

watch(() => lastReadMessageId.value, (id) => {
    reportMessageRead(id)
})

onMounted(() => {
    loadThread();

    echo
        .private('support.chat.thread.' + props.messageThreadId)
        .listen('.support.chat.thread.message', (e) => {
            thread.value.messages.push(e.message)
        });
})
</script>
<template>
    <div
        v-if="thread && _currentUserId"
        class="relative h-full"
    >
        <div class="absolute inset-0 flex flex-col justify-between">
            <ul
                class="flex flex-col-reverse overflow-y-scroll"
                :class="messageListClass"
            >
                <li
                    v-for="message in conversationMessages"
                    :key="message.id"
                >
                    <support-chat-message
                        :message="message"
                        :sentByCurrentUser="message.sent_by_user_id === _currentUserId"
                    />
                </li>
            </ul>
            <div class="">
                <div class="relative">
                    <textarea
                        type="text"
                        class="w-full min-h-12 h-24 mt-1 p-2 border rounded-lg focus:border-gray-400 focus:outline-none"
                        @keydown="e => (e.metaKey || e.ctrlKey) && e.keyCode === 13 ? sendMessage() : null"
                        :placeholder="_mft('support:writeMessage')"
                        v-model="message"
                        name="chat-input"
                    ></textarea>
                    <button
                        @click="sendMessage"
                        class="absolute bottom-3 right-3 bg-black text-white rounded-md px-2 py-1"
                    >{{ _mft('support:send') }}</button>
                </div>
            </div>
        </div>
    </div>
</template>
