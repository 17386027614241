<script setup>
import { ref, onMounted, onBeforeUnmount, useSlots } from 'vue';
const onScreen = ref(false);
const transitioning = ref(true);
const emit = defineEmits(['close']);
const slots = useSlots()

const props = defineProps({
    title: {
        type: String,
        default: null,
        required: false,
    },
    maxWidthClass: {
        type: String,
        default: 'max-w-lg',
        required: false,
    }
})

const showTitleSlot = () => {
    return !!slots.title
}

onMounted(() => {
    document.body.classList.add('overflow-hidden');

    document.onkeydown = function(e) {
        if (e.key === 'Escape') {
            close();
        }
    };

    setTimeout(function(){
        onScreen.value = true;
        transitioning.value = false;
    }, 1);
})

const close = () => {
    transitioning.value = true;
    setTimeout(function(){
        onScreen.value = false;
        emit('close');
    }, 150);
}

onBeforeUnmount(() => {
    onScreen.value = false;
    document.body.classList.remove('overflow-hidden');
})

defineExpose({
    close
})

</script>
<template>
    <Teleport to="body">
        <div
            v-if="onScreen"
            style="z-index: 998;"
            class="fixed inset-0 transition-colors"
            :class="transitioning ? 'bg-transparent' : 'bg-black/50'"
            @click="close"
        ></div>
        <div
            style="z-index: 998;"
            class="fixed top-0 right-0 bottom-0 w-screen bg-white shadow-lg transition-transform"
            :class="[transitioning ? 'translate-x-full' : 'translate-x-0', maxWidthClass]"
        >
            <div class="flex max-h-screen flex-col">
                <div class="">
                    <div class="flex w-full items-start justify-between px-4 py-6 bg-wlPrimary text-wlPrimaryContrast sm:px-6">
                        <div>
                            <h1
                                v-if="props.title"
                            >
                                {{ props.title }}
                            </h1>
                            <slot name="title">
                            </slot>
                        </div>
                        <div
                            v-if="!slots.actions"
                            class="ml-3 flex h-7 items-center">
                            <button
                                type="button"
                                class="rounded-md text-white hover:text-gray-200 focus:outline-none"
                                tabindex="0"
                                @click="close()"
                            >
                                <span class="sr-only">{{ _mft('elements:close') }}</span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true" class="h-6 w-6"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path></svg>
                            </button>
                        </div>
                        <slot name="actions"></slot>
                    </div>
                </div>
                <div class="min-h-screen overflow-y-scroll p-4 lg:p-6">
                    <slot name="body"></slot>
                    <div class="h-48"></div>
                </div>
            </div>
        </div>
    </Teleport>
</template>
