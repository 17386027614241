<script setup>
    import { ref, onMounted, reactive } from 'vue'
    import { useNotificationStore } from '@/stores/NotificationStore.js'
    import PageBuilderView from "../page-builder-view/pageBuilderView.vue";

    const notificationStore = useNotificationStore()

    const props = defineProps({
        expertContract: {
            type: Object,
            required: true
        },
        conditions: {
            type: Object,
            required: true
        },
        signingEnabled: {
            type: Boolean,
            required: true
        },
    })

    const content = ref(null)
    const design = ref(null)
    const pageBuilderEnabled = ref(null)
    const containerRef = ref(null)
    const previewing = ref(false)
    const saving = ref(false)

    const loadContent = async () => {
        const res = await axios.get(route('expert-contracts.content', props.expertContract.id))
        content.value = res.data.content
        design.value = res.data.design
        pageBuilderEnabled.value = res.data.pageBuilderEnabled,
        containerRef.value = res.data.containerRef
    }

    const saveContent = async () => {
        saving.value = true
        const res = await axios.put(route('expert-contracts.content.update', props.expertContract.id), {
            title: props.expertContract.title,
            block_editor_content: content.value,
        })
        saving.value = false

        notificationStore.addNotification({
            type: 'success',
            title: _mft('shared:save.changesSaved'),
            dismissAfter: 2500,
        })
    }

    onMounted(() => {
        loadContent()
    })

</script>
<template>
    <div>
        <div
            v-if="!props.signingEnabled && ! pageBuilderEnabled"
            class="sticky top-0 z-50 flex justify-center p-4 lg:justify-end"
        >
            <div class="p-2 space-x-4 rounded-full shadow-lg bg-gray-50">
                <button
                    v-if="!previewing"
                    type="submit"
                    class="relative myflow-basic-button--secondary"
                    @click="previewing = !previewing"
                >
                    {{ _mft('shared:preview') }}
                </button>
                <button
                    v-if="previewing"
                    type="submit"
                    class="relative myflow-basic-button--secondary"
                    @click="previewing = !previewing"
                >
                    {{ _mft('shared:edit') }}
                </button>
                <button
                    type="submit"
                    class="relative"
                    :class="saving ? 'cursor-not-allowed myflow-basic-button--state-disabled' : 'myflow-basic-button--success'"
                    @click="saveContent"
                    :disabled="saving"
                >
                    {{ _mft('shared:action.saveChanges') }}
                </button>
            </div>
        </div>

        <div v-if="!props.signingEnabled && !conditions.has_signed_signature_requests && !previewing">
            <div v-if="! pageBuilderEnabled">
                <input-expert-contract-editor
                    name="block_editor_content"
                    v-model:content="content"
                ></input-expert-contract-editor>
            </div>
            <div v-else>
                <div
                    class="flex"
                    :class="content.length === 0 ? 'justify-center flex-col items-center' : 'justify-end'"
                >
                    <span
                        v-if="content.length === 0"
                        class="italic text-center my-4"
                    >
                        {{ _mft('expertContract:contentEditor.noContentYet')}}
                    </span>
                    <a :href="route('expert-contracts.pagebuilder', props.expertContract.id)">
                        <button-primary>
                            {{ _mft('expertContract:contentEditor.editWithPageBuilder') }}
                        </button-primary>
                    </a>
                </div>
                <div class="mt-4">
                    <page-builder-view
                        :content="content"
                        :design="design"
                        :container-ref="containerRef"
                    ></page-builder-view>
                </div>
            </div>

            <div id="teleport-target-block-editor-toolbar" class="pb-24 md:ml-4"></div>
        </div>

        <div v-if="props.signingEnabled || conditions.has_signed_signature_requests || previewing">
            <div class="flex flex-col items-center justify-between space-x-4 md:flex-row">
                <h2 class="mb-4">
                    {{ _mft('expertContract:contentEditor.preview') }}
                </h2>
                <p
                    v-if="props.signingEnabled && !props.conditions.has_signed_signature_requests"
                    class="leading-4 text-gray-500"
                >
                    {{ _mft('expertContract:editingDisabledDueToSigningEnabled') }}
                </p>
                <p
                    v-if="props.conditions.has_signed_signature_requests"
                    class="leading-4 text-gray-500"
                >
                    <template v-if="props.conditions.all_signature_requests_signed">
                        {{ _mft('expertContract:editingDisabledDueToAllSigned') }}
                    </template>
                    <template v-else>
                        {{ _mft('expertContract:editingDisabledDueToSigningInProgress') }}
                    </template>
                </p>
            </div>
            <div class="mb-5 shadow-2xl">
                <iframe
                    :src="route('expert-contracts.showPreview', props.expertContract.id)"
                    class="w-full bg-gray-700 h-[900px] rounded-xl"
                ></iframe>
            </div>
        </div>
    </div>
</template>
