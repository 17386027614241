<script>
import JSConfetti from 'js-confetti';

export default {
    mounted() {
        const jsConfetti = new JSConfetti();

        jsConfetti.addConfetti({
            confettiRadius: 6,
            confettiNumber: 500,
            confettiColors: [
                '#622578',
                '#815093',
                '#53772b',
                '#759255',
                '#8cbfbb',
                '#a3cbc8',
            ],
        });
    },
};
</script>
