<template>
    <div class="flex items-center">
        <div class="flex-grow mr-4" :contenteditable="editing" ref="editable" @input="captureContent" @keydown="onKeyDown">
            <slot name="default"></slot>
        </div>
        <mf-spinner-medium v-if="submitting"
          class="w-12 h-6"
        />
        <button v-else-if="editing" type="button" @click.prevent="submit">
            <i class="far fa-check text-myflowPurple-900"></i>
            <span class="sr-only">{{ _mft('shared:action.save') }}</span>
        </button>
        <button v-else type="button" @click.prevent="edit">
            <i class="far fa-pen text-myflowPurple-900"></i>
            <span class="sr-only">{{ _mft('shared:edit') }}</span>
        </button>
    </div>
</template>
<script>
import { nextTick } from 'vue';
import axios from 'axios';

export default {
    props: {
        url: { type: String, required: true },
        name: { type: String, required: true },
        required: { type: Boolean, default: false }
    },
    data() {
        return {
            submitting: false,
            editing: false,
            content: null
        }
    },
    mounted() {
        this.captureContent();
    },
    methods: {
        async edit() {
            this.editing = true;
            this.focus();
        },
        async focus() {
            await nextTick();
            this.$refs.editable.focus();

            await nextTick();
            const range = document.createRange();
            range.selectNodeContents(this.$refs.editable);
            const sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
        },
        blur() {
            this.$refs.editable.blur();

            const sel = window.getSelection();
            sel.removeAllRanges();
        },
        async submit() {
            if (this.submitting) { return; }

            if (this.required && !this.content) {
                await this.focus();
                alert(_mft('error:warning.fieldRequired'));
                return;
            }

            this.blur();

            this.submitting = true;
            const data = {};
            data[this.name] = this.content;
            try {
                await axios.put(this.url, data);
                this.editing = false;
            } catch (error) {
                console.warn(error);
                await this.focus();
                alert(_mft('error:error.savingTryAgain'));
            } finally {
                this.submitting = false;
            }
        },
        captureContent() {
            const text = this.$refs.editable.innerText.trim();
            if (text.length > 255) {
                this.$refs.editable.innerText = text.substring(0, 255);
            }
            this.content = this.$refs.editable.innerText;
        },
        async onKeyDown(ev) {
            if (ev.key !== 'Enter') { return; }

            ev.preventDefault();
            await this.submit();
        }
    }
}
</script>
