<template>
    <a
        :href="item.href"
        class="h-full px-4 flex items-center text-myflowPurple-600 font-medium hover:text-myflowPurple-900"
        :class="[isActive ? 'bg-gray-50 relative tab rounded-t-md shadow-sm' : '']"
        v-if="!item.comingSoon"
    >
        {{ item.name }}
        <span
            v-if="item.notificationsCount"
            class="shadow ml-2 text-[11px] bg-myflowPurple-400 text-white rounded-full w-7 h-7 flex justify-center items-center transition group-hover:bg-myflowPurple-900 group-hover:text-white"
        >{{item.notificationsCount}}</span>
    </a>
</template>
<script>
export default {
    setup() {

    },
    props: {
        item: Object
    },
    computed: {
        isActive: function(){
            if( this.isCurrentRoute || this.isPartOfCurrentRoute ){
                return true
            }
        },
        isCurrentRoute: function(){
            return route().current(this.item.routeName, this.item.routeParams)
        },
        isPartOfCurrentRoute: function(){
            return route().current(this.item.routeName + '*', this.item.routeParams)
        }
    }
}
</script>
<style scoped>
    .tab {
        z-index: 50;
        position: relative;
    }
    .tab::after {
        position:absolute;
        top: 100%;
        left: 0px;
        right: 0px;
        height: 10px;
        background: rgba(249, 250, 251, var(--tw-bg-opacity));
        content: '';
        transform: translateY(-5px);
    }
</style>
