<template>
  <div class="pb-20">
    <form @submit.prevent="submit">
        <div class="mb-10">
            <input-text
                v-model:content="product.title"
                :error="errors.title"
                :label="_mft('coachingProduct:create.name.label')"
                required="required"
                name="title"
            />

            <div class="p-8">
                <FeaturedImage
                    :image="product.featured_image"
                    @file-updated="featuredImageUpdated"
                    @image-remove="featuredImageRemoved"
                />
            </div>

            <input-editor
                :label="_mft('coachingProduct:create.description.label')"
                :error="errors.description"
                :required="true"
                name="description"
                v-model:content="product.description"
            />

            <div>
                <input-select
                    v-model:content="product.session_quantity"
                    :error="errors.session_quantity"
                    :options="quantities"
                    :label="_mft('coachingProduct:create.sessionQuantity.label')"
                    required
                    placeholder=""
                    name="session_quantity"
                />

                <input-select
                    v-model:content="product.duration_quarters"
                    :error="errors.duration_quarters"
                    :options="durations"
                    :label="_mft('coachingProduct:create.sessionDuration.label')"
                    required
                    placeholder=""
                    name="duration_quarters"
                />

                <input-number
                    :error="errors.currency"
                    :label="_mft('coachingProduct:create.priceInclVat.label')"
                    v-model:content="product.price_including_vat"
                    required
                    name="coaching-pricing-field"
                />

                <toggle-switch :label="_mft('coachingProduct:published')" v-model:content="form.published" :id="'coaching-published'" />


            </div>

        </div>

        <div class="flex justify-end">
            <button
                type="submit"
                class="float-right myflow-basic-button"
            >{{ _mft('coachingProduct:updateProduct') }}</button>
        </div>
    </form>
  </div>
</template>
<script>
import FeaturedImage from '../featuredImage.vue';

export default {
    components: {
        FeaturedImage
    },

    props: {
        product: Object
    },
    data() {
        return {
            open: false,
            durations: [
                { label: '0:15', value: 1, },
                { label: '0:30', value: 2, },
                { label: '0:45', value: 3, },
                { label: '1:00', value: 4, },
                { label: '1:15', value: 5, },
                { label: '1:30', value: 6, },
                { label: '1:45', value: 7, },
                { label: '2:00', value: 8, },
                { label: '2:15', value: 9, },
                { label: '2:30', value: 10, },
                { label: '2:45', value: 11, },
                { label: '3:00', value: 12, },
                { label: '3:15', value: 13, },
                { label: '3:30', value: 14, },
                { label: '3:45', value: 15, },
                { label: '4:00', value: 16, },
            ],
            quantities: [
                { label: '1 ' + _mft('coaching:session.domainNameSingular'), value: 1 },
                { label: '2 ' + _mft('coaching:session.domainNamePlural'), value: 2 },
                { label: '3 ' + _mft('coaching:session.domainNamePlural'), value: 3 },
                { label: '4 ' + _mft('coaching:session.domainNamePlural'), value: 4 },
                { label: '5 ' + _mft('coaching:session.domainNamePlural'), value: 5 },
                { label: '6 ' + _mft('coaching:session.domainNamePlural'), value: 6 },
                { label: '7 ' + _mft('coaching:session.domainNamePlural'), value: 7 },
                { label: '8 ' + _mft('coaching:session.domainNamePlural'), value: 8 },
                { label: '9 ' + _mft('coaching:session.domainNamePlural'), value: 9 },
                { label: '10 ' + _mft('coaching:session.domainNamePlural'), value: 10 },
                { label: '11 ' + _mft('coaching:session.domainNamePlural'), value: 11 },
                { label: '12 ' + _mft('coaching:session.domainNamePlural'), value: 12 },
                { label: '13 ' + _mft('coaching:session.domainNamePlural'), value: 13 },
                { label: '14 ' + _mft('coaching:session.domainNamePlural'), value: 14 },
                { label: '15 ' + _mft('coaching:session.domainNamePlural'), value: 15 },
                { label: '16 ' + _mft('coaching:session.domainNamePlural'), value: 16 },
                { label: '17 ' + _mft('coaching:session.domainNamePlural'), value: 17 },
                { label: '18 ' + _mft('coaching:session.domainNamePlural'), value: 18 },
                { label: '19 ' + _mft('coaching:session.domainNamePlural'), value: 19 },
                { label: '20 ' + _mft('coaching:session.domainNamePlural'), value: 20 },
            ],
            form: {
                title: '',
                featured_image: '',
                featured_image_crop: '',
                description: '',
                duration_quarters: '',
                session_quantity: '',
                currency: 1,
                price_including_vat: '',
                published: false,
            },
            errors: {
                title: '',
                description: '',
                duration_quarters: '',
                session_quantity: '',
                currency: '',
                price_including_vat: '',
                published: '',
            },
            isSubmitting: false,
            submitLabel: _mft('coachingProduct:updateProduct'),
        };
    },
    methods: {
        featuredImageRemoved() {
            this.form.featured_image = ''
        },
        featuredImageUpdated(featuredImageData) {
            this.form.featured_image = featuredImageData.file
            this.form.featured_image_crop = featuredImageData.crop
        },
        submit() {
            let self = this;
            this.isSubmitting = true;

            let previousLabel = this.submitLabel;
            this.submitLabel = 'Sparar...';

            const formData = new FormData();
            this.buildFormData(formData, this.product);

            axios.post(route('coaching.updateProduct', this.product.id), formData).then(function (response) {
                if( response.status === 200 ){
                    if( response.data.redirect ){
                        window.location = response.data.redirect;
                    } else {
                        self.isSubmitting = false;
                        self.submitLabel = previousLabel;
                    }
                }
            })
            this.isSubmitting = false;
            self.submitLabel = previousLabel;
        },
        buildFormData(formData, data, parentKey) {
            if (data && typeof data === 'object'  && !(data instanceof File)) {
                Object.keys(data).forEach(key => {
                    this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
                });
            } else {
                const value = data == null ? '' : data;
                formData.append(parentKey, value);
            }
        }
    },
    mounted: function(){
        this.product.price_including_vat = this.product.price_including_vat / 100;
        this.form = this.product;
    }
};
</script>
