<script setup>
import {computed, onMounted, reactive, ref} from 'vue'

const props = defineProps({
    blockTypes: {
        type: Array,
        required: false,
        default: null
    },
    blockGroups: {
        type: Array,
        required: false,
        default: null
    },
})

const emit = defineEmits(['blockSelected', 'close'])
const blockFilterTerm = ref('')

const BLOCK_TYPES = [
    {
        type: 'headline',
        group: 'content',
        groups: ['common', 'text'],
        name: _mft('blocks:headline'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-heading'
        ]
    },
    {
        type: 'text',
        group: 'content',
        groups: ['common', 'text'],
        name: _mft('blocks:text'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-align-left'
        ]
    },
    {
        type: 'video',
        group: 'media',
        groups: ['common', 'video'],
        name: _mft('blocks:video'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-video'
        ]
    },
    {
        type: 'image',
        group: 'media',
        groups: ['common', 'image'],
        name: _mft('blocks:image'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-image'
        ]
    },
    {
        type: 'image-from-url',
        group: 'media',
        groups: ['image'],
        name: _mft('blocks:image-from-url'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-image',
            'fa-link'
        ]
    },
    {
        type: 'pexels',
        group: 'media',
        groups: ['image'],
        name: _mft('blocks:pexels'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-image',
            'fa-magnifying-glass'
        ]
    },
    {
        type: 'logo',
        group: 'media',
        groups: ['image'],
        name: _mft('blocks:logo'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-hexagon-image'
        ]
    },
    {
        type: 'audio',
        group: 'media',
        groups: ['function'],
        name: _mft('blocks:audio'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-volume'
        ]
    },
    {
        type: 'file',
        group: 'media',
        groups: ['function'],
        name: _mft('blocks:file'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-download'
        ]
    },
    {
        type: 'youtube',
        group: 'integrations',
        groups: ['video'],
        name: _mft('blocks:youtube'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-brands fa-youtube'
        ]
    },
    {
        type: 'vimeo',
        group: 'integrations',
        groups: ['video'],
        name: _mft('blocks:vimeo'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-brands fa-vimeo'
        ]
    },
    {
        type: 'vimeo-live',
        group: 'integrations',
        groups: ['video'],
        name: _mft('blocks:vimeo-live'),
        availableByDefault: true,
        availableLayouts: ['full'],
        icons: [
            'fa-brands fa-vimeo'
        ]
    },
    {
        type: 'card-text',
        group: 'content',
        groups: ['layout'],
        name: _mft('blocks:card-text'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-square'
        ]
    },
    {
        type: 'card-text-list',
        group: 'content',
        groups: ['layout'],
        name: _mft('blocks:card-text-list'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-rectangle-list'
        ]
    },
    {
        type: 'image-text',
        group: 'content',
        groups: ['image'],
        name: _mft('blocks:image-text'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-image',
            'fa-align-left'
        ]
    },
    {
        type: 'navigation-buttons',
        group: 'actions',
        groups: ['text'],
        name: _mft('blocks:navigation-buttons'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-link'
        ]
    },
    {
        type: 'single-button',
        group: 'actions',
        groups: ['common', 'text'],
        name: _mft('blocks:single-button'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-square'
        ]
    },
    {
        type: 'anchor-button',
        group: 'actions',
        groups: ['function'],
        name: _mft('blocks:anchor-button'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-anchor'
        ]
    },
    {
        type: 'quote',
        group: 'content',
        groups: ['text'],
        name: _mft('blocks:quote'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-quote-right'
        ]
    },
    {
        type: 'faq',
        group: 'content',
        groups: ['layout'],
        name: _mft('blocks:faq'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-question',
            'fa-maximize'
        ]
    },
    {
        type: 'spacer',
        group: 'content',
        groups: ['layout'],
        name: _mft('blocks:spacer'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-arrows-up-down'
        ]
    },
    {
        type: 'form',
        group: 'actions',
        groups: ['function'],
        name: _mft('blocks:form'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-input-pipe'
        ]
    },
    {
        type: 'slideshow',
        group: 'media',
        groups: ['image'],
        name: _mft('blocks:slideshow'),
        availableByDefault: true,
        availableLayouts: ['full'],
        icons: [
            'fa-presentation-screen'
        ]
    },
    {
        type: 'slim-product',
        group: 'media',
        groups: ['layout'],
        name: _mft('blocks:slim-product'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-cart-shopping'
        ]
    },
    {
        type: 'pricing-offer',
        group: 'media',
        groups: ['layout'],
        name: _mft('blocks:pricing-offer'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-cart-plus'
        ]
    },
    {
        type: 'invoice-link',
        group: 'content',
        groups: ['function'],
        name: _mft('blocks:invoice-link'),
        availableByDefault: true,
        availableLayouts: ['full'],
        icons: [
            'fa-cart-shopping'
        ]
    },
    {
        type: 'loom-embed',
        group: 'integrations',
        groups: ['video'],
        name: _mft('blocks:loom-embed'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-camera-web'
        ]
    },
    {
        type: 'video-recorder',
        group: 'media',
        groups: ['video'],
        name: _mft('blocks:video-recorder'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-camera-web'
        ]
    },
    {
        type: 'lead-capture',
        group: 'actions',
        groups: ['common', 'function'],
        name: _mft('blocks:lead-capture'),
        availableByDefault: false,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-address-card'
        ]
    },
    {
        type: 'social-media',
        group: 'actions',
        groups: ['text'],
        name: _mft('blocks:social-media'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-share-nodes'
        ]
    },
    {
        type: 'voting-suggestions',
        group: 'customerarea',
        groups: ['customerarea'],
        name: _mft('blocks:voting-suggestions'),
        availableByDefault: true,
        availableLayouts: ['full'],
        icons: [
            'fa-check-to-slot'
        ]
    },
    {
        type: 'calendly',
        group: 'integrations',
        groups: ['function'],
        name: _mft('blocks:calendly'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-calendar-days'
        ]
    },
    {
        type: 'iframe',
        group: 'function',
        groups: ['function'],
        name: _mft('blocks:iframe'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-square-poll-horizontal'
        ]
    },
    {
        type: 'cronofy',
        group: 'integrations',
        groups: ['function'],
        name: _mft('blocks:cronofy'),
        availableByDefault: false,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-calendar-days'
        ]
    },
    {
        type: 'ai-introduction',
        group: 'integrations',
        groups: ['function'],
        name: _mft('blocks:ai-introduction'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-handshake'
        ]
    },
    {
        type: 'coaching-bookings',
        group: 'customerarea',
        groups: ['customerarea'],
        name: _mft('blocks:coachingBookings'),
        availableByDefault: false,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-calendar-days'
        ]
    },
    {
        type: 'licensed-courses',
        group: 'customerarea',
        groups: ['customerarea'],
        name: _mft('blocks:licensedCourses'),
        availableByDefault: false,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-graduation-cap'
        ]
    },
    {
        type: 'login-form',
        group: 'customerarea',
        groups: ['customerarea'],
        name: _mft('blocks:loginForm'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-key'
        ]
    },
    {
        type: 'community',
        group: 'customerarea',
        groups: ['customerarea'],
        name: _mft('blocks:community'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-user-group'
        ]
    },

    // FORMS

    {
        type: 'forms-input-text',
        group: 'forms-input',
        groups: [],
        name: _mft('blocks:forms-input-text'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-input-text'
        ]
    },
    {
        type: 'forms-input-number',
        group: 'forms-input',
        groups: [],
        name: _mft('blocks:forms-input-number'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-input-numeric'
        ]
    },
    {
        type: 'forms-input-email',
        group: 'forms-input',
        groups: [],
        name: _mft('blocks:forms-input-email'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-at'
        ]
    },
    {
        type: 'forms-input-phone',
        group: 'forms-input',
        groups: [],
        name: _mft('blocks:forms-input-phone'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-phone'
        ]
    },
    {
        type: 'forms-input-date',
        group: 'forms-input',
        groups: [],
        name: _mft('blocks:forms-input-date'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-calendar'
        ]
    },
    {
        type: 'forms-input-time',
        group: 'forms-input',
        groups: [],
        name: _mft('blocks:forms-input-time'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-clock'
        ]
    },
    {
        type: 'forms-input-date-time',
        group: 'forms-input',
        groups: [],
        name: _mft('blocks:forms-input-date-time'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-calendar-clock'
        ]
    },
    {
        type: 'forms-input-textarea',
        group: 'forms-input',
        groups: [],
        name: _mft('blocks:forms-input-textarea'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-line-columns'
        ]
    },
    {
        type: 'forms-input-radio',
        group: 'forms-input',
        groups: [],
        name: _mft('blocks:forms-input-radio'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-list-radio'
        ]
    },
    {
        type: 'forms-input-checkbox',
        group: 'forms-input',
        groups: [],
        name: _mft('blocks:forms-input-checkbox'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-list-check'
        ]
    },
    {
        type: 'forms-input-select',
        group: 'forms-input',
        groups: [],
        name: _mft('blocks:forms-input-select'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-list-dropdown'
        ]
    },
    {
        type: 'forms-input-upload',
        group: 'forms-input',
        groups: [],
        name: _mft('blocks:forms-input-upload'),
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-upload'
        ]
    },

    // EMAIL

    {
        type: 'email-header',
        group: 'email',
        groups: [],
        name: _mft('blocks:email-header'),
        availableByDefault: false,
        availableLayouts: ['full'],
        icons: [
            'fa-heading'
        ]
    },
    {
        type: 'email-text',
        group: 'email',
        groups: [],
        name: _mft('blocks:email-text'),
        availableByDefault: true,
        availableLayouts: ['full'],
        icons: [
            'fa-align-left'
        ]
    },
    {
        type: 'email-panel',
        group: 'email',
        groups: [],
        name: _mft('blocks:email-panel'),
        availableByDefault: true,
        availableLayouts: ['full'],
        icons: [
            'fa-rectangle-list'
        ]
    },
    {
        type: 'email-button',
        group: 'email',
        groups: [],
        name: _mft('blocks:email-button'),
        availableByDefault: true,
        availableLayouts: ['full'],
        icons: [
            'fa-square-up-right'
        ]
    },
    {
        type: 'email-subcopy',
        group: 'email',
        groups: [],
        name: _mft('blocks:email-subcopy'),
        availableByDefault: true,
        availableLayouts: ['full'],
        icons: [
            'fa-file-lines'
        ]
    },
    {
        type: 'email-image',
        group: 'email',
        groups: [],
        name: _mft('blocks:email-image'),
        availableByDefault: false, // Replaced by email-image-with-link
        availableLayouts: ['full'],
        icons: [
            'fa-image'
        ]
    },
    {
        type: 'email-image-with-link',
        group: 'email',
        groups: [],
        name: _mft('blocks:email-image-with-link'),
        availableByDefault: true,
        availableLayouts: ['full'],
        icons: [
            'fa-image'
        ]
    },
    {
        type: 'email-social-media',
        group: 'email',
        groups: [],
        name: _mft('blocks:email-social-media'),
        availableByDefault: true,
        availableLayouts: ['full'],
        icons: [
            'fa-share-nodes'
        ]
    },
]

const GROUPS = [
    {
        id: 'forms-input',
        heading: _mft('blocks:group.forms-input'),
        availableByDefault: false
    },

    {
        id: 'common',
        heading: _mft('blocks:group.common'),
        availableByDefault: true
    },
    {
        id: 'text',
        heading: _mft('blocks:group.text'),
        availableByDefault: true
    },
    {
        id: 'image',
        heading: _mft('blocks:group.image'),
        availableByDefault: true
    },
    {
        id: 'video',
        heading: _mft('blocks:group.video'),
        availableByDefault: true
    },
    {
        id: 'function',
        heading: _mft('blocks:group.function'),
        availableByDefault: true
    },
    {
        id: 'layout',
        heading: _mft('blocks:group.layout'),
        availableByDefault: true
    },
    {
        id: 'customerarea',
        heading: _mft('blocks:group.customerarea'),
        availableByDefault: true
    },
    {
        id: 'content',
        heading: _mft('blocks:group.content'),
        availableByDefault: true
    },
    {
        id: 'media',
        heading: _mft('blocks:group.media'),
        availableByDefault: true
    },
    {
        id: 'actions',
        heading: _mft('blocks:group.actions'),
        availableByDefault: true
    },
    {
        id: 'integrations',
        heading: _mft('blocks:group.integrations'),
        availableByDefault: true
    },
    {
        id: 'email',
        heading: _mft('blocks:group.email'),
        availableByDefault: false
    },
];

const prerequisites = reactive({
    blockTypes: null,
    blockGroups: null,
    includedBlockTypes: [],
    excludedBlockTypes: [],
    includedBlockGroups: [],
    excludedBlockGroups: [],
});

const groupsWithBlockTypes = computed(() => {
    const availableBlockTypes = BLOCK_TYPES
        .filter((bt) => {
            if (prerequisites.blockTypes !== null) {
                return (prerequisites.blockTypes.indexOf(bt.type) !== -1);
            } else {
                return (
                    (bt.availableByDefault || prerequisites.includedBlockTypes.indexOf(bt.type) !== -1)
                    &&
                    prerequisites.excludedBlockTypes.indexOf(bt.type) === -1
                );
            }
        });

    return GROUPS
        .filter((group) => {
            if (prerequisites.blockGroups !== null) {
                return (prerequisites.blockGroups.indexOf(group.id) !== -1);
            } else {
                return (
                    (group.availableByDefault || prerequisites.includedBlockGroups.indexOf(group.id) !== -1)
                    &&
                    prerequisites.excludedBlockGroups.indexOf(group.id) === -1
                );
            }
        })
        .map((group) => ({
            group: group,
            blockTypes: availableBlockTypes.filter((bt) => bt.groups.includes(group.id)) // blockTypes: availableBlockTypes.filter((bt) => bt.group === group.id)
        }))
        .filter((section) => section.blockTypes.length > 0);
})

const filteredBlockTypes = computed(() => {
    return BLOCK_TYPES
        .filter((bt) => {

            if (! bt.name.toLowerCase().includes(blockFilterTerm.value.toLowerCase())) {
                return false
            }

            if (prerequisites.blockTypes !== null) {
                return (prerequisites.blockTypes.indexOf(bt.type) !== -1);
            } else {
                return (
                    (bt.availableByDefault || prerequisites.includedBlockTypes.indexOf(bt.type) !== -1)
                    &&
                    prerequisites.excludedBlockTypes.indexOf(bt.type) === -1
                );
            }
        });
})


const selectBlock = (blockType) => {
    emit('blockSelected', blockType);
}

const close = () => {
    emit('close');
}

onMounted(() => {
    if (props.blockTypes !== null) {
        prerequisites.blockTypes = props.blockTypes;
    }
    if (props.blockGroups !== null) {
        prerequisites.blockGroups = props.blockGroups;
    }
})

</script>

<template>
    <slideout-with-slots
        @close="close"
    >
        <template #title>
            <h2>{{ _mft('blocks:addBlock') }}</h2>
        </template>
        <template #body>
            <div>
                <input-text
                    v-model:content="blockFilterTerm"
                    placeholder="Search blocks"
                    autofocus
                />
                <div
                    v-if="blockFilterTerm.length === 0"
                >
                    <div
                        v-for="(section, idx) in groupsWithBlockTypes"
                        v-bind:key="idx"
                        class="pb-4"
                    >
                        <div class="-mx-4 lg:-mx-6 pl-4 lg:pl-6 border-b mb-4 flex">
                            <span class="inline-block font-bold -mb-2.5 px-2 bg-white">{{ section.group.heading }}</span>
                        </div>
                        <div class="grid grid-cols-3 gap-px mb-2">
                            <button
                                v-for="(blockType, indexBlockType) in section.blockTypes"
                                :key="indexBlockType"
                                type="button"
                                class="m-2 min-h-[4rem] transition-all bg-white rounded-xl border relative flex items-start justify-center text-xs group hover:border-wlPrimary hover:text-gray-600"
                                @click.prevent="selectBlock(blockType)"
                            >
                                <div
                                    class="flex flex-col items-center justify-center w-full h-full p-2 100 rounded-xl"
                                >
                                    <h5>
                                        <i
                                            v-for="icon in blockType.icons"
                                            v-bind:key="icon"
                                            class="p-1 fa-regular"
                                            :class="icon"
                                        ></i>
                                    </h5>
                                    <div v-html="blockType.name"></div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>

                <div
                    v-if="blockFilterTerm.length > 0"
                >
                    <div class="grid grid-cols-3 gap-px mb-2">
                        <button
                            v-for="(blockType, indexBlockType) in filteredBlockTypes"
                            :key="indexBlockType"
                            type="button"
                            class="m-2 min-h-[4rem] transition-all bg-white rounded-xl border relative flex items-start justify-center text-xs group hover:border-wlPrimary hover:text-gray-600"
                            @click.prevent="selectBlock(blockType)"
                        >
                            <div
                                class="flex flex-col items-center justify-center w-full h-full p-2 100 rounded-xl"
                            >
                                <h5>
                                    <i
                                        v-for="icon in blockType.icons"
                                        v-bind:key="icon"
                                        class="p-1 fa-regular"
                                        :class="icon"
                                    ></i>
                                </h5>
                                <div v-html="blockType.name"></div>
                            </div>
                        </button>
                    </div>
                    <p
                        v-if="filteredBlockTypes.length === 0"
                        class="text-gray-400 text-center py-4"
                    >
                        {{ _mft('blocks:noBlocksToShow') }}
                    </p>
                </div>
            </div>
        </template>
    </slideout-with-slots>
</template>

<style scoped>

</style>
