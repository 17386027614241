<script setup>
import { computed } from 'vue'

const props = defineProps({
    content: {
        type: Object,
        required: true
    },
});

const surveyId = computed(() => props.content.survey_id);
const loggedIn = window.myflow?.user?.loggedIn;

</script>
<template>
    <div>
        <voting-items
            v-if="surveyId"
            :survey-id="surveyId"
            :logged-in="loggedIn"
        ></voting-items>
    </div>
</template>
