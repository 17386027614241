<script setup>
/**
 *  Usage:
 *
 *  <ui-tabbed>
 *      <ui-tabbed-tab
 *          title="Tab One"
 *          icon="fa-regular fa-circle text-myflowGreen"
 *          value="tab-one"
 *          :active="true"
 *      >
 *          <h2>Tab one</h2>
 *          <p>Lorem ipsum</p>
 *      </ui-tabbed-tab>
 *
 *      <ui-tabbed-tab
 *          title="Tab Two"
 *          icon="fa-regular fa-circle text-myflowRed"
 *          value="tab-two"
 *          :active="false"
 *      >
 *          <h2>Tab two</h2>
 *          <p>Lorem ipsum again</p>
 *      </ui-tabbed-tab>
 *  </ui-tabbed>
 */


import {ref, provide, watchEffect, useSlots, onMounted, watch, computed} from 'vue'

const props = defineProps({
    nametab: {
        type: String
    },
    classname: {
        type: String,
        default: null
    },
    trackState: {
        type: Boolean,
        required: false,
        default: true,
    },
    preselectedTab: {
        type: String,
        required: false,
        default: null,
    },
    responsive: {
        type: Boolean,
        required: false,
        default: true,
    }
})

const emit = defineEmits(['changestatustab'])
const slots = useSlots();

const formIsFilled = ref(false)
const tabTitles = computed(() => {
    return slots.default().flatMap((tab) => tab.props !== null ? tab.props : [])
})
const isSelected = ref(Object.fromEntries(Object.entries(tabTitles.value).filter(([key, value]) => value.active === true )))
const selectedId = Object.keys(isSelected.value).length ? Object.keys(isSelected.value)[0] : null
const selectedTitle = ref(tabTitles.value[selectedId].value)

provide('selectedTitle', selectedTitle)

watchEffect(() => {
    if(selectedTitle.value && formIsFilled.value) {
        emit('changestatustab', selectedTitle.value)
    }
    formIsFilled.value = true
})

const verifyActive = (tab) => {
    if (tab.value === selectedTitle.value) {
        return true
    }
}

const selectTab = (tab) => {
    selectedTitle.value = tab
}

watch(selectedTitle, (newValue) => {
    if (! props.trackState) {
        return
    }
    if (newValue) {
        window.location.hash = newValue
    }
})

defineExpose({
    selectTab
})

onMounted(() => {
    const presetTab = window.location.hash.replace('#', '')

    if (presetTab && tabTitles.value.find(tab => tab.value === presetTab)) {
        selectedTitle.value = presetTab
    } else if (props.preselectedTab) {
        selectedTitle.value = props.preselectedTab
    }
})

</script>
<template>
    <div class="@container w-full max-w-screen">
        <ul
            v-bind:id="nametab" v-bind:class="classname"
            class="overflow-hidden block space-y-2 py-2 px-2"
            :class="responsive ? '@lg:flex space-y-2 @lg:space-y-0 py-2 @lg:py-0 px-2 @lg:px-0' : 'flex'"
        >
            <li
                v-for="(tab, index) in tabTitles"
                class="w-full flex items-end justify-center overflow-hidden overflow-ellipsis border-b-2 cursor-pointer"
                :class="verifyActive(tab) ? 'border-b-wlPrimary' : 'border-b-gray-200'"
                :key="index"
                @click.prevent="selectedTitle = tab.value"
            >
                <a
                    @click.prevent="selectedTitle = tab.value"
                    class="flex items-center space-x-1.5 px-4 py-2 @lg:py-3 text-center text-myflowText overflow-hidden overflow-ellipsis select-none"
                >
                    <i v-if="tab.icon" :class="tab.icon"></i>
                    <span>{{ tab.localizetitle ? _mft(tab.title) : tab.title }}</span>
                </a>
            </li>
        </ul>
        <div class="pt-3 md:pt-5 lg:pt-6">
            <slot/>
        </div>
    </div>
</template>
