<script setup>
import {computed, ref, watch} from 'vue';

const props = defineProps({
    progress: {
        type: Number,
        required: true
    },
    containerClass: {
        type: String,
        required: false,
        default: 'p-2'
    },
    progressClass: {
        type: String,
        required: false,
        default: 'bg-gray-200'
    },
    barClass: {
        type: String,
        required: false,
        default: 'bg-green-600'
    }
});

const transitioning = ref(false)

const progressStyle = computed(() => {
    let scale = transitioning.value ? 1.75 : 1

    return {
        transform: `scale(${scale})`,
    }
})

const barStyle = computed(() => {
    let width = props.progress > 100 ? 100 : props.progress

    return {
        width: `${width}%`,
    }
})

watch(() => props.progress, () => {
    transitioning.value = true
    setTimeout(() => {
        transitioning.value = false
    }, 300)
})

</script>

<template>
    <div
        class="overflow-hidden rounded-full"
        :class="containerClass"
    >
        <div
            class="w-full rounded-lg"
            :class="progressClass"
        >
            <div
                class="flex justify-end rounded-full pgbar"
                :class="props.barClass"
                :style="barStyle"
            >
                <div
                    class="w-2 h-2 rounded-full"
                    :class="props.barClass"
                    :style="progressStyle"
                ></div>
            </div>

        </div>
    </div>
</template>

<style scoped>
    .pgbar {
        transition: width 500ms cubic-bezier(.47,1.64,.41,.8) 100ms;
    }
    div > div > div > div {
        transition: transform 400ms cubic-bezier(.47,1.64,.41,.8) 0s;
    }
</style>
