<script setup>

import {onMounted, ref} from "vue";
import { PencilIcon } from "@heroicons/vue/outline"
import { PlayIcon, PauseIcon, CheckCircleIcon } from "@heroicons/vue/solid"

const campaigns = ref([])
const loaded = ref(false)

const loadCampaigns = async () => {
    const res = await axios.get(route('api.lead-hunter.campaign.index'))
    campaigns.value = res.data.campaigns
    loaded.value = true
}

onMounted(() => {
    loadCampaigns()
})

</script>

<template>
    <div>
        <div class="container w-full mx-auto top-2 z-topbar md:sticky">
            <frosted-bar>
                <div class="flex items-center justify-between space-x-4">
                    <h1>
                        <h1>Lead Hunter Campaigns</h1>
                    </h1>
                    <div class="flex items-center space-x-4">
                        <story-lane-modal :module="'leadhunter-v1'"></story-lane-modal>

                        <a :href="route('lead-hunter.campaign.create')">
                            <button-primary
                            >
                                New campaign
                            </button-primary>
                        </a>
                    </div>
                </div>
            </frosted-bar>
        </div>

        <div class="mt-8 overflow-hidden bg-white shadow-xl sm:rounded-wl">
            <ul class="divide-y divide-gray-300">
                <li
                    v-for="campaign in campaigns"
                    :key="campaign.id"
                    class=""
                >
                    <div class="relative block hover:bg-gray-50">
                        <div class="flex flex-col px-4 py-4 pr-10 md:flex-row md:items-center md:justify-between md:px-6 md:pr-6">
                            <div class="absolute right-0 pr-4 top-4 md:static">
                                <div class="flex flex-col justify-start space-y-2">
                                    <div class="flex justify-center w-6">
                                        <a :href="route('lead-hunter.campaign.edit', campaign.id)">
                                            <PencilIcon
                                                v-if="campaign.status === 'draft'"
                                                class="w-4 h-4 text-gray-500"
                                            ></PencilIcon>
                                            <PlayIcon
                                                v-if="campaign.status === 'playing'"
                                                class="w-6 h-6 text-gray-900"
                                            ></PlayIcon>
                                            <PauseIcon
                                                v-if="campaign.status === 'paused'"
                                                class="w-6 h-6 text-gray-400"
                                            ></PauseIcon>
                                            <CheckCircleIcon
                                                v-if="campaign.status === 'finished'"
                                                class="w-6 h-6 text-myflowGreen-500"
                                            ></CheckCircleIcon>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="w-full truncate">
                                <div class="flex flex-col w-full text-sm">
    <!--                                <div class="flex flex-col flex-wrap items-start md:space-x-2 md:flex-row">-->
    <!--                                    pill-->
    <!--                                </div>-->
                                    <p class="mb-0 font-medium text-gray-900 truncate">
                                        <a class="text-inherit" :href="route('lead-hunter.campaign.edit', campaign.id)">
                                            {{ campaign.name }}
                                        </a>
                                    </p>
                                </div>
                            </div>
                            <div class="mt-3 shrink-0 md:mt-0 md:pl-4">
                                <div class="flex mb-3 space-x-1">

                                    <div class="space-y-1 text-right">
                                        <a :href="route('lead-hunter.campaign.edit', campaign.id)">
                                            <button-primary
                                                size="sm"
                                            >{{ _mft('shared:edit') }}</button-primary>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<style scoped>

</style>
