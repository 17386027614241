<template>
    <div>
        <div v-if="loadingCronofy">
            <mf-spinner-medium
                class="w-24 h-12"
            />
        </div>
        <div
            v-else
            class="grid grid-cols-2 gap-8"
        >
            <div>
                <div
                    :class="bookableUsers.length === 0 ? 'h-0 overflow-hidden' : ''"
                >
                    <input-select
                        label="Boka i kalender för"
                        :required="true"
                        :options="bookableUsers"
                        labelSpecification="name"
                        valueSpecification="uuid"
                        v-model:content="bookableUserUuid"
                        @change="onChange"
                    >
                    </input-select>
                </div>
                <div
                    v-if="bookableUsers.length === 0"
                >
                    <p
                        class="italic py-2"
                    >
                        Minst en användare i ert företag måste ha kalenderintegration aktiverad för att kunna använda detta block.
                    </p>
                    <p>
                        <a
                            :href="route('manage-expert-profile.availability.show')"
                            target="_blank"
                        >
                            <button-base>
                                Aktivera nu
                            </button-base>
                        </a>
                    </p>
                </div>
            </div>
            <div v-if="bookableUsers.length > 0">
                <input-text
                    label="Rubrik i kalender"
                    :required="true"
                    v-model:content="value.bookingEventTitle"
                />

                <input-number
                    label="Möteslängd i minuter"
                    :required="true"
                    min="5"
                    max="480"
                    v-model:content="value.meetingLength"
                />
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        modelValue: {},
    },
    emits: [
        'update:modelValue'
    ],
    data() {
        return {
            loadingCronofy: true,
            bookableUserUuid: null,
            prerequisitesLoaded: false,
            bookableUsers: [],
            value: {
                meetingLength: null,
                bookableUserUuid: null,
                bookingEventTitle: null,
            },
        };
    },
    async mounted() {
        this.loadOptions();
        if (this.bookableUserUuid) {
            this.loadPrerequisites();
        }
        this.captureModelValue();
    },
    watch: {
        bookableUserUuid() {
            this.value.bookableUserUuid = this.bookableUserUuid;
            this.loadPrerequisites();
        },
        modelValue() {
            if (JSON.stringify(this.value) === JSON.stringify(this.modelValue)) { return; }
            this.captureModelValue();
        },
        value: {
            handler() {
                this.$emit('update:modelValue', JSON.parse(JSON.stringify(this.value)));
            },
            deep: true
        },

    },
    methods: {
        onChange() {
        },
        captureModelValue() {
            const value = this.modelValue ? JSON.parse(JSON.stringify(this.modelValue)) : {};
            if (!value.title) { value.title = null; }
            this.value = value;
            this.bookableUserUuid = value.bookableUserUuid;
        },
        async loadOptions() {
            const res = await axios.get(route('block-editor-block.booking.bookingOptions'));
            this.bookableUsers = res.data.bookable_users;

            this.loadingCronofy = false;

            if (this.bookableUsers.length === 1) {
                this.bookableUserUuid = this.bookableUsers[0].uuid;
            }
        },
        async loadPrerequisites() {
            this.loadingCronofy = true;
            const res = await axios.get(route('integrations.cronofy.index'));
            this.prerequisitesLoaded = true;
            this.loadingCronofy = false;
        }
    },
}
</script>
