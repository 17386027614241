<script setup>
import {computed, ref, watch} from 'vue';
import axios from 'axios';

const emit = defineEmits(['uploadComplete', 'remove']);

const props = defineProps({
    uploadable: {
        type: Object,
        required: true,
    },
    uploadTrigger: {
        type: Number,
        required: false,
        default: 0,
    }
});

const title = ref(props.uploadable.name.split('.')[0]);
const submitting = ref(false);
const uploadPercentage = ref(0);
const assetId = ref(null);
const estimatedSecondsRemaining = ref('');
const uploadState = ref('idle')

async function submit() {
    submitting.value = true;
    estimatedSecondsRemaining.value = '';
    uploadState.value = 'uploading'

    try {
        let response = await axios.post(route('mediaBank.entries.upload'), {
            title: title.value,
            mime: props.uploadable.file.type,
            extension: props.uploadable.file.name.split('.').pop(),
        });

        const temporaryUploadUrl = response.data.upload_url.url;
        assetId.value = response.data.asset_id;

        let formData = new FormData();
        formData.append('media', props.uploadable.file);

        const startTime = Date.now();

        await axios.put(temporaryUploadUrl, props.uploadable.file, {
            onUploadProgress: (progressEvent) => {
                const elapsedTime = Date.now() - startTime;
                const uploadSpeed = progressEvent.loaded / elapsedTime;
                const remainingBytes = progressEvent.total - progressEvent.loaded;
                estimatedSecondsRemaining.value = (remainingBytes / uploadSpeed) / 1000;

                uploadPercentage.value = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
            }
        });

        await axios.post(route('mediaBank.entries.finalizeUpload'), {
            asset_id: assetId.value,
        });

        uploadState.value = 'done';
        emit('uploadComplete', props.uploadable)
    } catch (e) {
        console.warn("Failed to submit form", e);
        uploadPercentage.value = 0;
        submitting.value = false;
    }
}

function remove() {
    emit('remove', props.uploadable);
}

const fileSize = computed(() => {
    let size = parseInt(props.uploadable.file.size, 10);
    const units = ['B', 'kB', 'MB', 'GB', 'TB'];

    let unitIndex = 0;
    while (size > 1024) {
        size /= 1024;
        unitIndex++;
    }

    return `${size.toFixed(2)} ${units[unitIndex]}`;
});

const estimatedTimeRemaining = computed(() => {
    if (estimatedSecondsRemaining.value < 10) {
        return _mft('mediaBank:uploading.justAFewSeconds');
    }
    if (estimatedSecondsRemaining.value < 45) {
        return _mft('mediaBank:uploading.lessThanAMinute');
    }
    if (estimatedSecondsRemaining.value < 75) {
        return _mft('mediaBank:uploading.aboutAMinute');
    }
    if (estimatedSecondsRemaining.value < 130) {
        return _mft('mediaBank:uploading.aFewMinutes');
    }
    return _mft('mediaBank:uploading.aboutNMinutes', {minutes: Math.round(estimatedSecondsRemaining.value / 60)});
})

const titleValid = computed(() => {
    return title.value.length > 0;
})

watch(() => props.uploadTrigger, () => {
    if (uploadState.value !== 'idle' || titleValid.value !== true) {return;}

    submit()
})

</script>

<template>
    <div class="@container relative">
        <div class="grid-cols-1 gap-4 space-x-2 @md:grid @md:grid-cols-3 @2xl:grid-cols-2">
            <div
                v-if="uploadState !== 'done'"
                class="mb-4 md:mb-0"
            >
                <img
                    v-if="uploadable.media === 'image'"
                    class="h-auto w-full"
                    :src="uploadable.url"
                    :alt="uploadable.file.name"
                    :title="uploadable.file.name"
                />
                <div
                    v-else-if="uploadable.media === 'video'"
                    class="relative"
                >
                    <video
                        class="h-auto w-full"
                        :src="uploadable.url"
                        :title="uploadable.file.name"
                        controls
                    />
                </div>
                <div
                    v-else-if="uploadable.media === 'audio'"
                    class="text-center"
                >
                    <i class="text-5xl text-gray-400 fa-regular fa-volume"></i>
                </div>
                <div
                    v-else
                    class="text-center"
                >
                    <i class="text-5xl text-gray-400 fa-regular fa-file"></i>
                </div>
                <span
                    v-if="uploadState === 'idle'"
                    class="absolute top-2 left-2"
                >
                    <button-danger
                        @click="remove"
                    >
                        <i class="fa-regular fa-trash"></i>
                    </button-danger>
                </span>
            </div>
            <div
                v-else
                class="text-base py-5"
            >
                {{ title }}
            </div>
            <div class="w-full @md:col-span-2 @2xl:col-span-1">
                <div
                    v-if="uploadState === 'idle'"
                >
                    <div
                        class="flex items-center justify-between space-x-2"
                    >
                        <input-text
                            type="text"
                            v-model:content="title"
                            class="w-full !mb-1"
                        />

                        <button-base
                            :style-type="submitting || ! titleValid ? 'disabled' : 'success'"
                            @click="submit"
                            :disabled="submitting"
                        >{{ _mft('mediaBank:upload') }}</button-base>
                    </div>
                    <p class="pl-1 text-gray-400">{{ fileSize }}</p>
                </div>
                <div
                    class="py-4 text-gray-400"
                    v-if="uploadState !== 'idle'"
                >
                    <div
                        class="overflow-hidden border-2"
                        :class="uploadState === 'done' ? 'border-transparent rounded-lg' : 'border-gray-400 rounded-wl'"
                    >
                        <div
                            class="flex justify-end p-1 px-2 transition-all min-w-10"
                            :class="uploadState === 'done' ? 'text-base cursor-default' : 'text-xs bg-gray-400 text-white'"
                            :style="{ width: `${uploadPercentage}%` }"
                        >
                            <span v-if="uploadState === 'uploading'">{{ uploadPercentage}}%</span>
                            <div class="w-full text-right text-gray-600" v-if="uploadState === 'done'">
                                <i class="fa-regular fa-circle-check text-myflowGreen"></i>
                                {{ _mft('mediaBank:uploadComplete') }}
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="uploadState === 'uploading'"
                        class="mt-1 text-center"
                    >
                        <span v-if="estimatedSecondsRemaining">
                            {{ _mft('mediaBank:uploading.withEstimation') }} {{ estimatedTimeRemaining }}
                        </span>
                        <span v-else>{{ _mft('mediaBank:working') }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
