<template>
    <div
        v-if="value"
        class="grid gap-5 mb-3"
        :class="layout === 'half' ? 'grid-cols-1' : 'grid-cols-1 lg:grid-cols-3'"
    >
        <div class="w-full col-span-2 mx-auto">
            <video-recorder
                v-if="recordNew"
                class="max-w-4xl mx-auto"
                @recordingComplete="recordingComplete"
            ></video-recorder>
            <div v-else class="w-full aspect-video flex items-center justify-center">
                <video-player
                    v-if="selectedRecordingUrl && showPlayer"
                    :mp4="selectedRecordingUrl"
                ></video-player>
                <mf-spinner-medium v-else class="w-16 h-8"></mf-spinner-medium>
            </div>
        </div>

        <div v-if="recordings.length > 0">
            <div class="flex justify-between space-x-4 mb-2 h-8 items-center px-1">
                <h4>Inspelningar</h4>
                <button
                    v-if="!recordNew"
                    class="myflow-basic-button"
                    @click="recordNew = true"
                >Spela in ny</button>
            </div>
            <ul class="h-96 space-y-1 pr-2 overflow-y-scroll">
                <li
                    v-for="(recording, i) in recordings"
                    :key="i"
                    @click="selectRecording(recording)"
                    class="cursor-pointer flex space-x-4 px-1 justify-between hover:text-gray-500 transition-all"
                    :class="{'text-black bg-gray-200': value.recordingUuid === recording.uuid}"
                >
                    <span
                        class="transition-transform"
                    >{{ recording.name }}</span>
                    <span class="text-gray-400">{{ recording.duration_display }}</span>
                </li>
            </ul>
        </div>
    </div>
    <mf-spinner-medium v-else class="w-16 h-8"></mf-spinner-medium>
</template>
<script>

export default {
    props: {
        modelValue: {},
        layout: {
            type: String,
            default: 'full'
        }
    },
    emits: [
        'update:modelValue'
    ],
    data() {
        return {
            value: null,
            recordNew: true,
            recordings: [],
            showPlayer: true,
        };
    },
    async mounted() {
        this.captureModelValue();
        this.loadRecordings();
    },
    watch: {
        modelValue() {
            if (JSON.stringify(this.value) === JSON.stringify(this.modelValue)) { return; }
            this.captureModelValue();
        },
        value: {
            handler() {
                this.$emit('update:modelValue', JSON.parse(JSON.stringify(this.value)));
            },
            deep: true
        }
    },
    computed: {
        selectedRecordingUrl() {
            if (!this.value.recordingUuid) { return; }
            const recording = this.recordings.find(r => r.uuid === this.value.recordingUuid);

            if(!recording) { return; }
            return recording.url;
        }
    },
    methods: {
        captureModelValue() {
            const value = this.modelValue ? JSON.parse(JSON.stringify(this.modelValue)) : {};
            if (!value.recordingUuid) {
                value.recordingUuid = null;
            } else {
                this.recordNew = false;
            }
            this.value = value;
        },
        async loadRecordings() {
            const response = await axios.get(route('browser-recording.index'));
            this.recordings = response.data;
        },
        recordingComplete(videodata) {
            this.loadRecordings();
            this.value.recordingUuid = videodata.recording_uuid;
            this.recordNew = false;
        },
        selectRecording(recording) {
            this.showPlayer = false;
            this.value.recordingUuid = recording.uuid;
            this.recordNew = false;
            let self = this;
            setTimeout(function(){
                self.showPlayer = true;
            }, 150)
        }
    },
}
</script>
