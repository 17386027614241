<script setup>
    import {computed} from "vue";

    const props = defineProps({
        title: {
            type: String,
            required: true,
        },
        price: {
            type: Number,
            required: true,
        },
        instalmentCount: {
            type: Number,
            required: true,
        },
        currencyLabel: {
            type: String,
            required: false,
            default: ''
        },
        vatPercentage: {
            type: Number,
            required: true,
        },
        paymentMethods: {
            type: Array,
            required: true,
        },
        createAccountForBuyer: {
            type: Boolean,
            required: true,
        },
        showDiscount: {
            type: Boolean,
            required: true,
        },
        products: {
            type: Array,
            required: true,
        },

    })

    const sumProductsPrice = computed(() => {
        return props.products.reduce((acc, product) => {
            return acc + (product.price * product.quantity)
        }, 0)
    })

    const discount = computed(() => {
        return Math.round((1 - (props.price / sumProductsPrice.value)) * 100)
    })

    const instalmentAmount = computed(() => {
        return Math.round((props.price / props.instalmentCount) * 100) / 100
    })

    const _vat = computed(() => {
        return Math.round((props.price * (props.vatPercentage / 100)) * 100) / 100
    })

</script>

<template>
    <div>
        <div class="mb-4">
            <h3 v-if="props.title === ''">{{ _mft('invoiceLink:summary.title') }}</h3>
            <h3 v-else>{{ props.title }}</h3>
        </div>

        <div class="mb-6">
            <div
                v-if="props.products.length > 0"
                class="mb-6"
            >
                <div
                    v-if="props.products.length > 1"
                    class="font-bold"
                >
                    {{ _mft('invoiceLink:summary.includedProducts') }}
                </div>
                <ul class="mt-1 text-xs space-y-1">
                    <li
                        v-for="(product, index) in props.products"
                        :key="product"
                        class="flex space-x-2 justify-between"
                    >
                        <span>
                            {{ product.title }}
                        </span>
                        <span>
                            {{ product.quantity }} {{ product.unit }}
                        </span>
                    </li>
                </ul>
            </div>


            <ul class="divide-y">
                <li class="flex justify-between py-1 text-gray-500 space-x-4">
                    <span>
                        {{ _mft('invoiceLink:summary.includedProductsValue') }}
                    </span>
                    <span>{{ sumProductsPrice }} {{ props.currencyLabel }}</span>
                </li>

                <li class="py-1">
                    <div
                        class="flex justify-between space-x-4"
                    >
                        <span class="font-bold">{{ _mft('invoiceLink:price') }}</span>
                        <span>
                            {{ props.price }} {{ props.currencyLabel }}
                        </span>
                    </div>
                    <div
                        v-if="props.instalmentCount > 1"
                        class="text-right text-xs"
                    >
                        {{ props.instalmentCount }} {{ _mft('invoiceLink:summary.instalmentsOf') }} {{ instalmentAmount }} {{ props.currencyLabel }}
                    </div>
                </li>

                <li class="flex justify-between py-1 space-x-4">
                    <span class="font-bold">{{ _mft('invoiceLink:summary.vat') }}</span>
                    <span v-if="props.vatPercentage && props.price > 0">
                        {{ _vat }} {{ props.currencyLabel }} ({{ props.vatPercentage }}%)
                    </span>
                    <span v-else>-</span>
                </li>

                <li
                    v-if="props.showDiscount"
                    class="flex justify-between py-1 space-x-4"
                >
                    <span class="font-bold">{{ _mft('invoiceLink:summary.discount') }}</span>
                    <span>{{ discount }} %</span>
                </li>
                <li
                    v-if="props.createAccountForBuyer"
                    class="pt-2 pb-1 text-center text-xs"
                >
                    {{ _mft('invoiceLink:summary.accountWillBeCreated') }}
                </li>
            </ul>
        </div>
        <slot name="actions">
        </slot>
    </div>
</template>

<style scoped>

</style>
