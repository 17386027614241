<template>
    <div class="relative z-0">
        <div
            v-if="item.comingSoon"
            class="relative flex w-full cursor-default items-center rounded-md bg-white p-2 text-xs font-medium shadow-xl text-myflowPurple-200 hgroup"
        >
            <component
                :is="item.icon"
                class="mr-2 h-6 w-6 text-myflowPurple-100"
                aria-hidden="true"
            />
            <span class="z-20">{{ item.name }}</span>
            <span v-if="item.comingSoonText" class="absolute inset-0 z-40 flex items-center justify-center rounded-md px-2 py-1 text-center text-xs text-white opacity-0 transition-opacity bg-myflowPurple-400 hover:opacity-80 focus:opacity-80">{{ item.comingSoonText }}</span>
            <span
                v-if="item.notificationsCount"
                class="ml-2 flex h-5 w-5 items-center justify-center rounded-full text-xs text-white shadow transition bg-myflowPurple-400 group-hover:bg-myflowPurple-900 group-hover:text-white"
            >{{item.notificationsCount}}</span>
        </div>
        <div>
            <div class="relative">
                <a
                    :href="item.href"
                    v-if="!item.comingSoon"
                    class="col-span-4 group"
                    :class="[isActive ? 'shadow-none bg-myflowPurple-600 md:bg-gray-50 text-white md:text-myflowPurple relative z-20' : 'bg-white text-gray-900 hover:bg-myflowPurple-600 hover:text-white', 'shadow-xl hgroup w-full p-2 rounded-md flex items-center text-xs font-medium relative']" :aria-current="isActive ? 'page' : undefined"
                    style="z-index: 20;"
                >
                    <component
                        :is="item.icon"
                        :class="[isActive ? 'text-white md:text-myflowPurple z-20' : 'text-myflowPurple-500 group-hover:text-white transform group-hover:scale-110 transition-transform', 'h-6 w-6']"
                        class="mr-2 transform group-hover:scale-110"
                        aria-hidden="true"
                    />
                    <span class="z-20">
                        {{ item.name }}
                    </span>
                    <span
                        v-if="item.notificationsCount"
                        class="absolute z-30 ml-2 flex h-7 w-7 items-center justify-center rounded-full text-white shadow text-[11px] bg-myflowPurple-400 group-hover:bg-myflowPurple-900 group-hover:text-white"
                        :class="[item.subItems?.length > 0 ? ' right-10 lg:right-2' : ' right-2']"
                    >{{item.notificationsCount}}</span>
                    <span
                        v-if="isActive"
                        class="absolute top-0 -right-4 bottom-0 left-0 z-10 hidden rounded-md rounded-r-none bg-gray-50 shadow-xl md:block"
                    ></span>
                </a>
                <button
                    class="absolute top-0 right-1 bottom-0 z-30 group focus:outline-none lg:hidden"
                    v-on:click="toggleExpanded"
                    v-if="item.subItems?.length > 0"
                >
                    <div class="relative h-8 w-8">
                        <ChevronDownIcon
                            class="absolute top-0 left-0 mt-1 ml-1 h-6 w-6 transform stroke-current transition-all text-myflowPurple-700 group-focus:text-myflowPurple-900"
                            :class="[isExpanded ? '-translate-y-1' : '']"
                        />
                        <ChevronDownIcon
                            class="absolute top-0 left-0 mt-1 ml-1 h-6 w-6 transform stroke-current transition-all text-myflowPurple-700 group-focus:text-myflowPurple-900"
                            :class="[isExpanded ? 'translate-y-1 rotate-180' : '']"
                        />
                    </div>
                </button>
            </div>
            <div
                class="transition-all"
                :class="[isExpanded ? 'translate-y-0 opacity-100' : '-translate-y-2 opacity-0']"
            >
                <ul v-if="(isActive && isExpanded) || isExpanded" class="lg:hidden">
                    <li
                        v-for="item in item.subItems"
                        v-bind:key="item.name"
                        class="mt-2"
                    >
                        <a
                        :href="item.href"
                        class="relative z-30 ml-2 block rounded-md bg-white p-2 text-xs shadow-md"
                        >
                        {{ item.name }}
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import {
    CogIcon,
    HomeIcon,
    MenuAlt3Icon,
    PlusSmIcon,
    XIcon,
    AcademicCapIcon,
    SparklesIcon,
    GiftIcon,
    BellIcon,
    UserGroupIcon,
    TrendingUpIcon,
    GlobeIcon,
    VideoCameraIcon,
    CashIcon,
    AdjustmentsIcon,
    DesktopComputerIcon,
    DocumentTextIcon,
    ViewGridAddIcon,
    UserAddIcon,
    CubeTransparentIcon,
    MapIcon,
    BriefcaseIcon,
    PresentationChartBarIcon,
    FolderIcon,
    ChevronDownIcon,
    StatusOnlineIcon,
    StarIcon,
    InboxIcon,
    ChatAlt2Icon
} from '@heroicons/vue/outline';
export default {
    components: {
        CogIcon,
        HomeIcon,
        MenuAlt3Icon,
        PlusSmIcon,
        XIcon,
        AcademicCapIcon,
        SparklesIcon,
        GiftIcon,
        BellIcon,
        UserGroupIcon,
        TrendingUpIcon,
        GlobeIcon,
        VideoCameraIcon,
        CashIcon,
        AdjustmentsIcon,
        DesktopComputerIcon,
        DocumentTextIcon,
        ViewGridAddIcon,
        UserAddIcon,
        CubeTransparentIcon,
        MapIcon,
        BriefcaseIcon,
        PresentationChartBarIcon,
        FolderIcon,
        ChevronDownIcon,
        StatusOnlineIcon,
        StarIcon,
        InboxIcon,
        ChatAlt2Icon
    },
    props: {
        item: Object
    },
    data() {
        return {
            isExpanded: false
        }
    },
    methods: {
        toggleExpanded(){
            this.isExpanded = !this.isExpanded;
        }
    },
    computed: {
        isActive: function(){
            if( this.isCurrentRoute || this.isPartOfCurrentRoute ){
                return true
            }
        },
        isCurrentRoute: function(){
            return route().current(this.item.routeName)
        },
        isPartOfCurrentRoute: function(){
            return route().current(this.item.routeName + '*')
        }
    },
    mounted() {
        if( this.isActive ){
            this.isExpanded = true;
        }
    }
}
</script>
