<script setup>
import { computed, reactive } from 'vue';

const emit = defineEmits(['submitAndPreviewAutomation']);

const props = defineProps({
    automationId: {
        type: Number,
        required: true,
    },
    userContact: {
        type: Object,
        required: true
    },
    emailEnabled: {
        type: Boolean,
        required: false,
        default: false,
    },
    smsEnabled: {
        type: Boolean,
        required: false,
        default: false,
    },
})

const state = reactive({
    working: false,
})

const submitAndPreviewAutomation = () => {
    state.working = true;
    emit('submitAndPreviewAutomation')
}

const buttonLabel = computed(() => {
    let prefix = _mft('communication:composer.preview.saveAndSend');
    if( state.working ) {
        prefix = _mft('communication:composer.preview.savingAndSending');
    }

    if (props.userContact.email && props.emailEnabled && props.userContact.phone && props.smsEnabled) {
        return prefix + ' ' + _mft('communication:composer.preview.saveAndSend.suffix.testTo') + ' ' + props.userContact.email +', ' + props.userContact.phone;
    }
    if (props.emailEnabled) {
        return prefix + ' ' + _mft('communication:composer.preview.saveAndSend.suffix.testTo') + ' ' + props.userContact.email;
    }
    if (props.smsEnabled && props.userContact.phone) {
        return prefix + ' ' + _mft('communication:composer.preview.saveAndSend.suffix.testTo') + ' ' + props.userContact.phone;
    }
    return prefix + ' ' + _mft('communication:composer.preview.saveAndSend.suffix.test');
})

const warning = computed(() => {
    if (props.smsEnabled && !props.userContact.phone) {
        return _mft('communication:composer.preview.registerNumberToEnableTestSms');
    }
    return false;
})

</script>
<template>
    <div v-if="props.userContact">
        <button-secondary
            v-if="!warning"
            size="md"
            @click="submitAndPreviewAutomation"
            :disabled="state.working"
        >
            {{ buttonLabel }}
        </button-secondary>
        <p
            v-else
            class="italic text-red-500"
        >
            {{ warning }}
        </p>
    </div>
</template>
