<template>
    <div>
        <form @submit.prevent="submit">
            <div class="mb-6">
                <h5 class="mb-1">Kontaktperson</h5>
                <input-text
                    v-model:content="form.name"
                    :error="errors.name"
                    :label="_mft('crm:contact.fields.name')"
                    placeholder="Namn"
                    required="required"
                    name="name"
                />

                <input-email
                    v-model:content="form.email"
                    :error="errors.email"
                    required="required"
                    :label="_mft('crm:contact.fields.email')"
                    name="email"
                    @isEmailValid="validateEmail"
                />

                <input-text
                    v-model:content="form.title"
                    :error="errors.title"
                    :label="_mft('crm:contact.fields.title')"
                    name="title"
                />

                <input-text
                    v-model:content="form.phone"
                    :error="errors.phone"
                    :label="_mft('crm:contact.fields.phone')"
                    name="phone"
                />

                <input-text
                    v-model:content="form.linkedin"
                    :error="errors.linkedin"
                    :label="_mft('crm:contact.fields.linkedIn')"
                    name="linkedin"
                />
            </div>
            <div class="mb-6">
                <h5 class="mb-1">Bolag</h5>
                <input-text
                    v-model:content="form.company_name"
                    :error="errors.company_name"
                    :label="_mft('crm:contact.company.fields.name')"
                    name="company_name"
                />

                <input-text
                    v-model:content="form.org_nr"
                    :error="errors.org_nr"
                    :label="_mft('crm:contact.company.fields.corporateIdentityNumber')"
                    name="org_nr"
                />

                <input-text
                    v-model:content="form.invoice_email"
                    :error="errors.invoice_email"
                    :label="_mft('crm:contact.company.fields.invoiceEmail')"
                    name="invoice_email"
                />

                <input-text
                    v-model:content="form.website"
                    :error="errors.website"
                    :label="_mft('crm:contact.company.fields.website')"
                    name="website"
                />

                <input-select
                    v-if="reference.id"
                    v-model:content="form.reference_id"
                    :options="[{value: reference.id, label: reference.name}]"
                    :error="errors.reference"
                    :label="_mft('crm:contact.fields.reference')"
                    required="required"
                    name="reference"
                />
            </div>

            <input-button
                :disabled="!emailIsValid"
                :label="submitLabel"
                :busy="isSubmitting"
                type="submit"
                class="float-right"
            />
        </form>
    </div>
</template>
<script>
export default {
    props: {
        reference: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            emailIsValid: false,
            form: {
                name: '',
                email: '',
                title: '',
                phone: '',
                linkedin: '',
                company_name: '',
                org_nr: '',
                invoice_email: '',
                website: '',
                reference_id: ''
            },
            errors: {
                name: '',
                email: '',
                title: '',
                phone: '',
                linkedin: '',
                company_name: '',
                org_nr: '',
                invoice_email: '',
                website: '',
                reference: ''
            },
            isSubmitting: false,
            submitLabel: _mft('shared:add')
        };
    },
    methods: {
        submit() {
            if( this.isSubmitting === true ) return false;

            let self = this;
            this.isSubmitting = true;

            let previousLabel = this.submitLabel;
            this.submitLabel = _mft('shared:save.saving');

            var formData = new FormData();
            for ( const key in this.form ) {
                formData.append(key, this.form[key]);
            }

            axios.post(route('crm.leads.add'), formData).then(function (response) {
                if( response.status === 200 ){
                    if( response.data.redirect ){
                        window.location = response.data.redirect;
                    } else {
                        self.isSubmitting = false;
                        self.submitLabel = previousLabel;
                    }
                }
            })
        },
        validateEmail(valid) {
            this.emailIsValid = valid;
        }
    },
    mounted: function(){
        if( this.reference.id ){
            this.form.reference_id = this.reference.id
        }
    }
};
</script>
