<script setup>
import {ref} from "vue";
import { useNotificationStore } from '@/stores/NotificationStore.js'
import {_mft} from "../../i18n";
const notificationStore = useNotificationStore()

const props = defineProps({
    postUrl: {
        type: String,
        required: true,
    }
})

const slideoutOpen = ref(false)
const selectedTemplate = ref(null)
const working = ref(false)

const selectTemplate = (template) => {
    selectedTemplate.value = template
}

const useTemplate = async () => {
    working.value = true

    const res = await axios.post(props.postUrl, {
        template_id: selectedTemplate.value.id,
    })

    if (res.data.success) {
        window.location.reload()
    } else {
        notificationStore.addNotification({
            type: 'error',
            title: _mft('error:error.generalOccurred'),
            dismissAfter: 5000,
        })
        working.value = false
    }
}

const close = () => {
    selectedTemplate.value = null
    slideoutOpen.value = false
    working.value = false
}

</script>

<template>
    <div>
        <button-transparent
            size="line"
            class="text-xs"
            @click="slideoutOpen = true"
        >{{ _mft('pagebuilderTemplate:useTemplate') }}</button-transparent>
        <slideout-with-slots
            :title="_mft('pagebuilderTemplate:useTemplate')"
            v-if="slideoutOpen"
            @close="close"
            max-width-class="max-w-[90vw] md:max-w-screen"
        >
            <template #body>
                <templates-list
                    v-if="selectedTemplate === null"
                    :read-only="true"
                    :show-empty-default="true"
                    @select-template="selectTemplate"
                />

                <div
                    v-if="selectedTemplate !== null"
                >
                    <mf-spinner-medium
                        v-if="working"
                        class="w-24 h-12 mt-8"
                    />
                    <div
                        v-else
                        class="mx-auto max-w-xl mt-8"
                    >
                        <p>{{ _mft('pagebuilderTemplate:useTemplate.warning') }}</p>
                        <p>{{ _mft('pagebuilderTemplate:useTemplate.warning.confirmation') }}</p>
                        <div class="flex justify-end space-x-2">
                            <button-base
                                @click="selectedTemplate = null"
                            >
                                {{ _mft('shared:cancel') }}
                            </button-base>
                            <button-danger
                                @click="useTemplate"
                            >{{ _mft('shared:action.continue') }}</button-danger>
                        </div>
                    </div>
                </div>

            </template>
        </slideout-with-slots>
    </div>
</template>

<style scoped>

</style>
