<template>
    <div v-if="value">

        <div class="grid gap-5 mb-3 lg:grid-cols-2">
            <div class="col-span-1">
                <input-text
                    label="Namn"
                    required
                    v-model:content="value.name"
                />
                <input-number
                    label="Pris/enhet"
                    required
                    v-model:content="value.price"
                />
                <toggle-switch
                    label="Priset inkluderar moms"
                    v-model:content="value.includes_vat"
                />
                <input-text
                    inputtype="url"
                    label="Länk till köp"
                    v-model:content="value.link_href"
                />
                <input-number
                    label="Rabatt i % (0-100, 0 = ingen rabatt)"
                    required
                    v-model:content="value.rebate"
                />
                <input-number
                    label="Antal"
                    required
                    v-model:content="value.amount"
                />
                <label>Enhet</label>
                <select v-model="value.unit" required class="myflow-basic-input">
                    <option value="st">st</option>
                    <option value="timmar">timmar</option>
                    <option value="månader">månader</option>
                    <option value="">Ingen</option>
                </select>
                <input-date
                    label="Giltig till"
                    required
                    v-model:content="value.valid_until"
                />
            </div>
            <div class="col-span-1">
                <input-editor
                    label="Beskrivning"
                    required
                    v-model:content="value.description"
                />
            </div>
        </div>

        <hr class="mt-3 mb-3">
        <div class="grid gap-5 mb-3 lg:grid-cols-2">
            <input-media-bank-entry
                type="image"
                label="Välj en bild från mediabiblioteket"
                :required="false"
                v-model:content="value.media_bank_entry_id"
            />
            <media-bank-entries-display v-if="value.media_bank_entry_id" :data-url="route('mediaBank.entries.show', value.media_bank_entry_id)" />
        </div>
    </div>
    <mf-spinner-medium v-else class="w-16 h-8"></mf-spinner-medium>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';

export default {
    props: {
        modelValue: {},
    },
    emits: [
        'update:modelValue'
    ],
    data() {
        return {
            value: null
        };
    },
    async mounted() {
        this.captureModelValue();
    },
    watch: {
        modelValue() {
            if (JSON.stringify(this.value) === JSON.stringify(this.modelValue)) { return; }
            this.captureModelValue();
        },
        value: {
            handler() {
                this.$emit('update:modelValue', JSON.parse(JSON.stringify(this.value)));
            },
            deep: true
        }
    },
    methods: {
        captureModelValue() {
            const value = this.modelValue ? JSON.parse(JSON.stringify(this.modelValue)) : {};
            if (!value.name) { value.name = null; }
            if (!value.price) { value.price = null; }
            if (!value.link_href) { value.link_href = null; }
            if (!value.rebate) { value.rebate = 0; }
            if (!value.unit) { value.unit = null; }
            if (!value.valid_until) { value.valid_until = null; }
            if (!value.description) { value.description = null; }
            if (!value.media_bank_entry_id) { value.media_bank_entry_id = null; }
            if (!value.amount) { value.amount = null; }
            if (!value.includes_vat) { value.includes_vat = null; }
            this.value = value;
        }
    },
}
</script>
