<template>
    <div>
        <template  v-if="requests !== null">
            <div class="flex items-center justify-between mb-4">
                <h1>{{ _mft('dns:requests.myDomains') }}</h1>

                <button-primary v-if="!isAdding" type="button" @click.prevent="isAdding = true">
                    {{ _mft('dns:requests.addDomain') }}
                </button-primary>
            </div>

            <p class="mb-4">{{ _mft('dns:visitGeneratedDomain') }} <b>{{ props.generatedDomain }}</b>.</p>
            <p>{{ _mft('dns:selectCustomDomainIntro') }}</p>
            <p v-html="_mft('dns:selectCustomDomainExamples')"></p>

            <ul class="mt-8">
                <li v-if="isAdding && !isSubmitting">
                    <card-basic>
                        <template #body>
                            <form @submit.prevent="onSubmit">
                                <div class="flex flex-row items-center justify-between gap-3">
                                    <div class="w-1/2">
                                        <input type="text" class="block w-full p-2 border rounded-md" required pattern="^(?:[A-z0-9](?:[A-z0-9\-]{0,61}[A-z0-9])?)(?:\.[A-z0-9](?:[A-z0-9\-]{0,61}[A-z0-9])?)+$" v-model="domainToAdd.domain" :placeholder="_mft('dns:requests.domain.add.placeholder')" maxlength="255">
                                    </div>

                                    <button-primary type="submit">
                                        {{ _mft('dns:requests.domain.add.button') }}
                                    </button-primary>
                                </div>

                                <p v-if="domainToAdd.domain?.length > 1" class="text-sm pt-2" v-html="_mft('dns:requests.domain.add.footnote', {domainToAdd: domainToAdd.domain})"></p>

                                <div class="pt-2">
                                    <input type="checkbox" class="mr-1" v-model="domainToAdd.shouldValidateForSite" :id="`${uuid}-should-validate-for-site`">
                                    <label :for="`${uuid}-should-validate-for-site`">
                                        {{ _mft('dns:domain.useForSite') }}
                                    </label>
                                </div>

                                <div class="pt-2">
                                    <input type="checkbox" class="mr-1" v-model="domainToAdd.shouldValidateForEmail" :id="`${uuid}-should-validate-for-email`">
                                    <label :for="`${uuid}-should-validate-for-email`">
                                        {{ _mft('dns:domain.useForEmail') }}
                                    </label>
                                </div>
                            </form>
                        </template>
                    </card-basic>
                </li>
                <li v-else-if="isAdding && isSubmitting" class="flex flex-row items-center justify-between p-5 rounded-lg odd:bg-gray-100">
                    <mf-spinner-medium class="w-16 h-8"></mf-spinner-medium>
                </li>

                <li v-for="request in requests" :key="request.id">
                    <card-basic>
                        <template #body>
                            <div class="w-full flex flex-row justify-between items-baseline mb-3 flex-wrap">
                                <div class="flex flex-row gap-1 items-baseline">
                                    <h6 class="mt-0 mb-1 font-bold text-lg">
                                        {{ request.domain }}
                                    </h6>
                                    <span v-if="request.last_failed_at">
                                        {{ _mft('dns:requests.lastAttempt', { time: request.formatted_last_failed_at  }) }}
                                    </span>
                                </div>

                                <div class="flex flex-row gap-3">
                                    <button-secondary size="sm" v-if="!request.should_validate_for_site" :disabled="request.enabling_for_site" @click.prevent="onEnableForSite(request)">
                                        <div class="flex flex-row gap-3">
                                            <mf-spinner-medium v-if="request.enabling_for_site" class="w-[1em] h–[1em]"></mf-spinner-medium>
                                            {{ _mft('dns:domain.useForSite') }}
                                        </div>
                                    </button-secondary>

                                    <button-secondary size="sm" v-if="!request.should_validate_for_email" :disabled="request.enabling_for_email" @click.prevent="onEnableForEmail(request)">
                                        <div class="flex flex-row gap-3">
                                            <mf-spinner-medium v-if="request.enabling_for_email" class="w-[1em] h–[1em]"></mf-spinner-medium>
                                            {{ _mft('dns:domain.useForEmail') }}
                                        </div>
                                    </button-secondary>

                                    <button-primary size="sm" v-if="!request.is_validated" type="button" :class="`${request.validating ? 'animate-pulse' : ''}`" @click.prevent="onTriggerValidation(request)" :disabled="request.validating || !request.is_available_for_validation">
                                        <i class="fa-regular fa-refresh"></i>
                                        <span class="sr-only">{{ _mft('dns:requests.refresh') }}</span>
                                    </button-primary>

                                    <button-danger size="sm" v-if="request.is_deletable" type="button" :class="`${request.deleting ? 'animate-pulse' : ''}`" @click="onDelete(request)" :disabled="request.deleting">
                                        <i class="fa-regular fa-trash"></i>
                                        <span class="sr-only">{{ _mft('dns:requests.delete') }}</span>
                                    </button-danger>
                                </div>
                            </div>

                            <p v-if="request.is_base_validated" class="mb-2">
                                ✅ {{ _mft('dns:requests.approved') }}
                            </p>
                            <div v-else class="mb-2">
                                <h6 class="font-bold mt-6 mb-2">{{ _mft('dns:requests.validation.instruction') }}</h6>

                                <div
                                    v-for="(record, recordIdx) in request.base_validation_records"
                                    :key="recordIdx"
                                    class="mb-3"
                                >
                                    <domain-validation-record-instruction
                                        :record="record"
                                        :is-last-record="recordIdx === request.base_validation_records.length - 1"
                                    ></domain-validation-record-instruction>
                                </div>
                            </div>

                            <p v-if="request.is_validated_for_site" class="mb-2">
                                ✅ <mf-translate domainkey="dns:domain.inUseForSite" :args="{domain: request.domain }"></mf-translate>
                            </p>
                            <div v-else-if="request.should_validate_for_site" class="mb-2">
                                <h6 class="font-bold mt-6 mb-2">{{ _mft('dns:domain.toEnableForSite') }}</h6>

                                <template v-if="request.site_validation_records?.length > 0">
                                    <div
                                        v-for="(record, recordIdx) in request.site_validation_records"
                                        :key="recordIdx"
                                        class="mb-3 flex"
                                    >
                                        <div class="mt-0.5 mr-2 text-gray-400 text-base font-bold">{{ recordIdx + 1 }}</div>
                                        <div class="w-full">
                                            <domain-validation-record-instruction
                                                :record="record"
                                                :is-last-record="recordIdx === request.site_validation_records.length - 1"
                                            ></domain-validation-record-instruction>
                                        </div>
                                    </div>
                                </template>
                                <p v-else>{{ _mft('dns:instructions.preparing') }}</p>
                            </div>

                            <p v-if="request.is_validated_for_email" class="mb-2">
                                ✅ {{ _mft('dns:domain.inUseForEmail') }}
                            </p>
                            <div v-else-if="request.should_validate_for_email" class="mb-2">
                                <h6 class="font-bold mt-6 mb-2">{{ _mft('dns:domain.toEnableForEmail') }}</h6>

                                <template v-if="request.email_validation_records?.length > 0">
                                    <div
                                        v-for="(record, recordIdx) in request.email_validation_records"
                                        :key="recordIdx"
                                        class="mb-3 flex"
                                    >
                                        <div class="mt-0.5 mr-2 text-gray-400 text-base font-bold">{{ recordIdx + 1 }}</div>
                                        <div class="w-full">
                                            <domain-validation-record-instruction
                                                :record="record"
                                                :is-last-record="recordIdx === request.email_validation_records.length - 1"
                                            ></domain-validation-record-instruction>
                                        </div>
                                    </div>
                                </template>
                                <p v-else>{{ _mft('dns:instructions.preparing') }}</p>
                            </div>
                        </template>
                    </card-basic>
                </li>
            </ul>
        </template>
        <mf-spinner-medium v-else class="w-16 h-8"></mf-spinner-medium>
    </div>
</template>

<script setup>
import { onMounted, ref, reactive } from 'vue'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid';

const uuid = uuidv4();

const props = defineProps({
    generatedDomain: { type: String, default: null }
})

const sleep = m => new Promise(r => setTimeout(r, m))

const dateFormatter = new Intl.DateTimeFormat(['sv-SE'], {
    timeZone: 'Europe/Stockholm',
    dateStyle: 'short',
    timeStyle: 'short'
});

const isAdding = ref(false);
const isSubmitting = ref(false);
const domainToAdd = reactive({
    domain: null,
    shouldValidateForSite: false,
    shouldValidateForEmail: false,
});
const requests = ref(null);

const decorateRequest = (request) => ({
    ...request,
    formatted_last_failed_at: request.last_failed_at ? dateFormatter.format(new Date(request.last_failed_at)) : null,
    validating: false,
    deleting: false,
    enabling_for_site: false,
    enabling_for_email: false,
});

const refreshRequests = async () => {
    const response = await axios.get(route('domain_validations.requests.index'))
    requests.value = response.data.data
        .map(decorateRequest)
        .sort((a, b) => b.id - a.id);
}

onMounted(refreshRequests);

const onSubmit = async () => {
    isSubmitting.value = true;

    try {
        const response = await axios.post(route('domain_validations.requests.store'), {
            domain: domainToAdd.domain,
            should_validate_for_site: domainToAdd.shouldValidateForSite,
            should_validate_for_email: domainToAdd.shouldValidateForEmail,
        });
        domainToAdd.domain = null;
        domainToAdd.shouldValidateForSite = false;
        domainToAdd.shouldValidateForEmail = false;
        isAdding.value = false;
        requests.value.splice(0, 0, decorateRequest(response.data));
    } finally {
        isSubmitting.value = false;
    }
}

const onTriggerValidation = async (request) => {
    request.validating = true;
    try {
        await axios.post(route('domain_validations.requests.triggerValidation', request.id));

        let waitTime = 1000;
        let newRequest = null;
        while (newRequest == null || newRequest?.updated_at === request.updated_at) {
            await sleep(waitTime); waitTime *= 1.5;

            const response = await axios.get(route('domain_validations.requests.show', request.id));
            newRequest = response.data;
        }

        const index = requests.value.findIndex(r => r.id === request.id);
        requests.value.splice(index, 1, decorateRequest(newRequest));
    } finally {
        request.validating = false;
    }
}

const onEnableForSite = async (request) => {
    request.enabling_for_site = true;
    try {
        const { data: newRequest } = await axios.post(route('domain_validations.requests.enableForSite', request.id));

        const index = requests.value.findIndex(r => r.id === request.id);
        requests.value.splice(index, 1, decorateRequest(newRequest));
    } finally {
        request.enabling_for_site = false;
    }
}

const onEnableForEmail = async (request) => {
    request.enabling_for_email = true;
    try {
        const { data: newRequest } = await axios.post(route('domain_validations.requests.enableForEmail', request.id));

        const index = requests.value.findIndex(r => r.id === request.id);
        requests.value.splice(index, 1, decorateRequest(newRequest));
    } finally {
        request.enabling_for_email = false;
    }
}

const onDelete = async (request) => {
    request.deleting = true;
    try {
        await axios.delete(route('domain_validations.requests.destroy', request.id));
        requests.value = requests.value.filter(r => r.id !== request.id);
    } finally {
        request.deleting = false;
    }
}

</script>
