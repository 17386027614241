<template>
    <div class="bg-myflowPurple-dark w-screen h-auto">

        <div class="w-full flex justify-center align-center flex-col lg:flex-row lg:justify-around">
            <section>
                <event-prices-component 
                    v-bind="pricesOnline"
                />
                <button @click="btnBoolean == true" class="collapse-btn">Köp nu!</button>
            </section>
            <section>
                <event-prices-component 
                    v-bind="pricesLive"
                />
                <button @click="btnBoolean == false" class="collapse-btn">Köp nu!</button>
            </section>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        let btnBoolean = true
        return {
            btnBoolean: btnBoolean,
            pricesOnline:
                {
                    location:"Online",
                    discription:"Online",
                    price:"9900kr",
                    btnId:1
                },
            
            pricesLive: 
                {
                    location:"På plats",
                    discription:"På plats",
                    price:"4900kr",
                    btnId:2,
                }
            

            
    };
    },
};
</script>

<style scoped>
.collapse-btn{
    background-color: #38c172;
    padding: 0.8rem 1.5rem;
    border-radius: 25px;
    height: fit-content;
    width: fit-content;
}
</style>
