<script setup>

const emit = defineEmits(['reloadSessions'])

const props = defineProps({
    session: {
        type: Object,
        required: true
    }
})

const reloadSessions = (e) => {
    emit('reloadSessions')
}

const redirectToSession = (e) => {
    window.location.href = e.redirectUrl
}

</script>

<template>
    <list-basic-item>
        <template #status>
            <div class="pr-4 hidden md:block text-center">
                <i
                    v-if="session.status === 'reschedulable'"
                    class="fa-light fa-clock-rotate-left"
                    :title="_mft('offerable:status.reschedulable')"
                ></i>
                <i
                    v-if="session.status === 'booked'"
                    class="fa-light fa-calendar-check text-myflowGreen"
                    :title="_mft('offerable:status.booked')"
                ></i>
                <i
                    v-if="session.status === 'bookable'"
                    class="fa-light fa-calendar"
                    :title="_mft('offerable:status.bookable')"
                ></i>
                <i
                    v-if="session.status === 'no-show'"
                    class="fa-light fa-calendar-xmark text-myflowRed"
                    :title="_mft('offerable:status.noShow')"
                ></i>
                <i
                    v-if="session.status === 'completed'"
                    class="fa-light fa-square-check text-myflowGreen"
                    :title="_mft('offerable:status.completed')"
                ></i>
                <i
                    v-if="session.status === 'cancelled'"
                    class="fa-light fa-square-check text-myflowGreen"
                    :title="_mft('offerable:status.cancelled')"
                ></i>
            </div>
        </template>
        <template #title>
            <div class="flex space-x-2 mb-0 text-gray-400 items-center">
                <div class="text-xs">
                    <span
                        v-if="session.status === 'booked' || session.status === 'completed' || session.status === 'no-show' || session.status === 'cancelled'"
                    >
                        <span>{{ session.scheduled_at_short }}</span>
                        <span v-if="session.scheduled_at_short" class="mx-1"> | </span>
                    </span>
                    <span>
                        {{ session.stats.session_duration }}
                    </span>
                </div>
                <pill size="xs" class="bg-gray-400 text-white">{{ _mft('offerable:status.' + session.status) }}</pill>
            </div>
            <p class="font-medium text-gray-900 truncate mb-0">{{ session.title }}</p>
        </template>
        <template #default>
            <div class="w-full">
                <p
                    v-if="session.other_party"
                    class="mb-0 text-xs text-gray-400"
                >{{ session.other_party.name }}</p>
            </div>
        </template>
        <template #actions>
            <coaching-v2-sessions-booking-modal
                :session="session"
                @bookingSuccess="redirectToSession"
                @cancelSuccess="reloadSessions"
            />

            <a
                :href="route('coaching2.session.show', session.id)"
                class="mb-1"
            >
                <button-secondary size="sm">
                    {{ _mft('offerable:action.show') }}
                </button-secondary>
            </a>

        </template>
    </list-basic-item>
</template>

<style scoped>

</style>
