<script setup>

import {computed, nextTick, onMounted, reactive, ref, watch} from "vue";

    const loaded = ref(false)
    const saving = ref(false)
    const savingState = ref('')
    const dirtyState = ref(false)
    const currencyObject = ref({identifier: null});

    const settings = reactive({
        legalName: '',
        paymentDetails: null,
        invoiceNrStart: null,
        invoiceNrPrefix: null,
        invoiceInboxEmail: null,
        legalTexts: null,
        defaultCurrency: null,
        defaultVatPercent: null,
    })

    const footer = reactive({
        columnOne: null,
        columnTwo: null,
        columnThree: null,
        columnFour: null,
    })

    const load = async () => {
        const res = await axios.get(route('settings.company.invoicing.show'));

        settings.legalName = res.data.settings.legal_name;
        settings.paymentDetails = res.data.settings.payment_details;
        settings.invoiceNrStart = res.data.settings.invoice_nr_start;
        settings.invoiceNrPrefix = res.data.settings.invoice_nr_prefix;
        settings.invoiceInboxEmail = res.data.settings.invoice_inbox_email;
        settings.invoiceTermDays = res.data.settings.due_days;
        settings.defaultCurrency = res.data.default_currency;
        settings.defaultVatPercent = res.data.default_vat_percent;
        footer.columnOne = res.data.settings.footer_column_one;
        footer.columnTwo = res.data.settings.footer_column_two;
        footer.columnThree = res.data.settings.footer_column_three;
        footer.columnFour = res.data.settings.footer_column_four;

        loaded.value = true;
        await nextTick(() => {
            dirtyState.value = false;
        })
    }

    const store = async () => {
        if (saveDisabled.value) {
            return;
        }
        saving.value = true;
        savingState.value = 'waiting';
        const res = await axios.post(route('settings.company.invoicing.update'), {
            legalName: settings.legalName,
            paymentDetails: settings.paymentDetails,
            invoiceNrStart: settings.invoiceNrStart,
            invoiceNrPrefix: settings.invoiceNrPrefix,
            invoiceInboxEmail: settings.invoiceInboxEmail,
            invoiceTermDays: settings.invoiceTermDays,
            defaultCurrency: settings.defaultCurrency,
            defaultVatPercent: settings.defaultVatPercent,
            footerColumnOne: footer.columnOne,
            footerColumnTwo: footer.columnTwo,
            footerColumnThree: footer.columnThree,
            footerColumnFour: footer.columnFour,
        });
        saving.value = false;
        if (res.data.success) {
            savingState.value = 'success';
            dirtyState.value = false;
        } else {
            savingState.value = 'error';
        }
    }

    const downloadPreview = async () => {
        window.open(route('settings.company.invoicing.pdf'));
    }

    const saveDisabled = computed(() => {
        if (saving.value === true) {
            return true;
        }

        return settings.legalName === null || settings.legalName === '';
    })

    watch([settings, footer], () => {
        dirtyState.value = true;
    })

    onMounted(() => {
        load();
    })
</script>

<template>
<div>
    <div class="md:sticky top-2 z-topbar">
        <frosted-bar>
            <div class="flex items-center justify-between space-x-4">
                <h1>{{ _mft('setting:invoicing.title') }}</h1>

                <div>
                    <div class="flex items-center justify-end space-x-2">
                        <a
                            :href="route('settings.company.invoicing.pdf')"
                        >
                        </a>
                        <span
                            v-if="dirtyState"
                            class="px-2 text-gray-600"
                        >{{ _mft('setting:invoicing.saveToPreviewPdf') }}</span>
                        <button-secondary
                            v-if="!dirtyState"
                            @click="downloadPreview"
                        >
                            {{ _mft('setting:invoicing.invoiceDownloadPdfPreview') }}
                        </button-secondary>

                        <button-success
                            @click="store"
                            :disabled="saveDisabled"
                            :state="savingState"
                        >{{ _mft('shared:action.save') }}</button-success>
                    </div>
                </div>
            </div>
        </frosted-bar>
    </div>

    <card-basic class="mt-8">
        <div class="space-y-4">
            <input-text
                :label="_mft('setting:invoicing.legalName')"
                v-model:content="settings.legalName"
            />

            <input-textarea
                :label="_mft('setting:invoicing.paymentDetails')"
                placeholder="IBAN, BankGiro, SWIFT/BIC, etc."
                v-model:content="settings.paymentDetails"
            />

            <div class="grid grid-cols-3 gap-8">
                <div>
                    <input-number
                        :label="_mft('setting:invoicing.invoiceNrStart')"
                        placeholder="1000"
                        v-model:content="settings.invoiceNrStart"
                    />
                </div>
                <div>
                    <input-text
                        :label="_mft('setting:invoicing.invoiceNrPrefix')"
                        placeholder="MYF"
                        v-model:content="settings.invoiceNrPrefix"
                    />
                </div>
                <div>
                    <input-text
                        :label="_mft('setting:invoicing.invoiceInboxEmail')"
                        placeholder="invoices@yourcompany.com"
                        v-model:content="settings.invoiceInboxEmail"
                    />
                    <p class="-mt-3 text-xs text-gray-600">{{ _mft('setting:invoicing.invoiceInboxEmail.description') }}</p>
                </div>
            </div>

            <div class="grid grid-cols-3 gap-8">
                <div>
                    <input-number
                        :label="_mft('setting:payment.invoicePaymentTerm')"
                        placeholder="30"
                        v-model:content="settings.invoiceTermDays"
                    />
                </div>
                <div>
                    <input-currency
                        v-model:identifier="settings.defaultCurrency"
                        v-model:currency="currencyObject"
                        :label="_mft('setting:invoicing.defaultCurrency')"
                    ></input-currency>
                </div>
                <div>
                    <input-number
                        :label="_mft('setting:invoicing.defaultVat')"
                        placeholder="0"
                        v-model:content="settings.defaultVatPercent"
                    />
                </div>
            </div>

            <h3>{{ _mft('setting:invoicing.invoiceFooter') }}</h3>
            <small>({{ _mft('setting:invoicing.invoiceFooterDescription') }})</small>
            <div class="@container">
                <div class="grid @sm:grid-cols-2 @4xl:grid-cols-4 4xl:grid-cols-4 gap-8">
                    <div>
                        <input-textarea
                            :label="_mft('setting:invoicing.invoiceFooterColumnNr') + ' 1'"
                            placeholder=""
                            v-model:content="footer.columnOne"
                        />
                        <small class="italic text-gray-600">
                            <b>Address</b><br>
                            MyFlow Learning AB<br>
                            Gatan 1A<br>
                            411 00 Gothenburg<br>
                            Sweden
                        </small>
                    </div>
                    <div>
                        <input-textarea
                            :label="_mft('setting:invoicing.invoiceFooterColumnNr') + ' 2'"
                            placeholder=""
                            v-model:content="footer.columnTwo"
                        />
                        <small class="italic text-gray-600">
                            <b>Contact</b><br>
                            Phone: +46 31 12 34 56<br>
                            Email: info@myflow.se<br>
                            Web: www.myflow.io
                        </small>
                    </div>
                    <div>
                        <input-textarea
                            :label="_mft('setting:invoicing.invoiceFooterColumnNr') + ' 3'"
                            placeholder=""
                            v-model:content="footer.columnThree"
                        />
                        <small class="italic text-gray-600">
                            <b>Org Nr</b><br>
                            123456-7890<br>

                            <b>VAT Nr</b><br>
                            SE123456789001
                        </small>
                    </div>
                    <div>
                        <input-textarea
                            :label="_mft('setting:invoicing.invoiceFooterColumnNr') + ' 4'"
                            placeholder=""
                            v-model:content="footer.columnFour"
                        />
                        <small class="italic text-gray-600">
                            <b>Bank</b><br>
                            IBAN: SE12 1234 1234 1234<br>
                            SWIFT/BIC: MYFLSESS
                        </small>
                    </div>
                </div>
            </div>
        </div>
    </card-basic>

</div>
</template>

<style scoped>

</style>
