<template>
    <list-item>
        <template #image v-if="showImage">
            <div class="w-4/5 mx-auto mb-4 md:mb-0 md:w-full">
                <div class="relative w-full bg-top bg-cover rounded-full" style="padding-bottom: 100%;"
                     :style="{ backgroundImage: 'url(' + specialist.user.profile_img_url + ')' }"
                     v-on:click.self="redirect(route('courses.show', specialist.id))">
                </div>
            </div>
        </template>

        <template #title>
            <h2 class="mb-1 text-lg leading-6 text-myflowPurple-600">
                    {{ specialist.title }}
            </h2>
        </template>

        <template #bio>
            <p class="font-bold text-gray-900" v-if="specialist.user.name">
                {{ specialist.user.name }}<br>
            </p>
        </template>

        <template #description>
            <span
                v-html="previewing ? specialist.description.replace(/<\/?[^>]+(>|$)/g, ' ') : specialist.description.substring(0, 200).replace(/<\/?[^>]+(>|$)/g, ' ') + '... '"
            ></span>
            <span v-on:click="toggleDescription" class="italic cursor-pointer text-myflowPurple-800">
                <br>{{ previewing ? ' ' + _mft('shared:expand.showLess') : ' ' + _mft('shared:expand.showMore') }}
            </span>
        </template>

        <template #info>
            <div class="mb-2 space-y-0.5 relative">
                <div
                    v-if="completedOnboardingStepCount > 0"
                >
                    <button-primary
                        class="w-full justify-center"
                        @click="toggleOnboardingSteps"
                    >
                        {{ _mft('specialist:hasUsedModuleCount', {count: completedOnboardingStepCount}) }}
                    </button-primary>
                </div>
                <div v-else>
                    <button-primary class="w-full justify-center" disabled>{{ _mft('specialist:hasUsedNoModules') }}</button-primary>
                </div>
                <div
                    v-if="showOnboardingSteps"
                    class="px-4 py-2"
                >
                    <ul>
                        <li
                            v-for="(step, i) in specialist.onboardingSteps"
                        >
                            <div
                                v-if="step.isCompleted || true"
                                class="mb-2 text-gray-800 flex space-x-1"
                            >
                                <i class="fa-regular fa-star text-myflowYellow-900"></i>
                                <div class="text-xs">
                                    {{ step.name }}
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </template>
        <template #footer>
            <div class="md:flex md:flex-wrap md:space-x-4">
                <card-basic
                    v-for="(offer, i) in filteredOffers"
                    :key="i"
                    :compact="true"
                    class="md:w-64 relative"
                >
                    <template #body>
                        <div class="pb-8">
                            <div class="text-right">
                                <div class="text-myflowPurple-300 pt-2">
                                    {{ offer.purpose.label }}
                                </div>
                            </div>

                            <h5 class="text-sm">{{ offer.title }}</h5>
                            <p class="mb-2">{{ _mft('specialist:priceFrom') }} {{ offer.price_from_display }}</p>

                            <div class="absolute bottom-4 left-4 right-4">
                                <a :href="route('specialist.offer.show', offer.id)" class="myflow-basic-button">
                                    {{ _mft('specialist:readMoreAndHire') }}
                                </a>
                            </div>
                        </div>
                    </template>
                </card-basic>

            </div>
        </template>
    </list-item>
</template>
<script>
import Playbutton from '../icons/playbutton.vue'

export default {
    components: {
        Playbutton
    },
    props: {
        specialist: Object,
        appliedFilters: Object,
        showImage: {
            type: Boolean,
            default: true
        },
    },
    data: function () {
        return {
            favorited: false,
            previewing: false,
            trailerVideoUrl: null,
            showOnboardingSteps: false,
        }
    },
    methods: {
        toggleDescription() {
            this.previewing = !this.previewing
        },
        redirect(route) {
            this.$document.locationAssign(route);
        },
        toggleOnboardingSteps() {
            this.showOnboardingSteps = !this.showOnboardingSteps
        }
    },
    mounted() {
    },
    computed: {
        fill: function () {
            if (this.favorited) {
                return 'currentColor'
            } else {
                return 'none'
            }
        },
        completedOnboardingStepCount: function () {
            return this.specialist.onboardingSteps.filter(step => step.isCompleted).length
        },
        filteredOffers: function () {
            if (this.appliedFilters.purpose) {
                return this.specialist.offers.filter(offer => offer.purpose.id === this.appliedFilters.purpose.id)
            } else {
                return this.specialist.offers
            }
        }
    }
}
</script>
<style scoped>
.text-mini {
    font-size: 12px;
}

.faded-top::after {
    position: absolute;
    content: '';
    top: 0.5rem;
    left: 0px;
    right: 0px;
    height: 3rem;
    background: transparent;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 1) 80%);
}

.faded-bottom::before {
    position: absolute;
    z-index: 2;
    content: '';
    bottom: 0.5rem;
    left: 0.5rem;
    right: 0.5rem;
    height: 4rem;
    background: transparent;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
}
</style>
