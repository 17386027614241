<script setup>
    import { ref } from 'vue'
    import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
    import { ExclamationIcon } from '@heroicons/vue/outline'


    const emit = defineEmits(['open', 'close'])
    const open = ref(false)

    const closeModal = () => {
        open.value = false
    }

    const openModal = () => {
        open.value = true
    }

    defineExpose({
        open,
        closeModal,
        openModal
    })
</script>


<template>
    <div @click="open = true; emit('open')">
        <slot name="open" class="cursor-pointer"></slot>
    </div>
    <div v-if="open">
        <div class="fixed inset-0 z-modal-backdrop" @click="open = false"></div>
        <div class="" aria-labelledby="modal-title" role="dialog" aria-modal="true">
            <div class="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0 fixed inset-0 z-modal overflow-y-scroll">
                <div class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" aria-hidden="true"></div>
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <div class="relative z-modal inline-block w-full px-4 pt-5 pb-4 overflow-hidden text-left align-bottom transition-all transform bg-white rounded-wl shadow-xl sm:my-8 sm:align-middle sm:max-w-xl sm:p-6">
                    <div>
                        <div class="mt-3 sm:mt-0 sm:ml-4">
                            <h3 class="text-lg font-medium leading-6 text-gray-900" id="modal-title"><slot name="header"></slot></h3>
                            <div class="mt-2">
                                <slot name="body"></slot>
                            </div>
                        </div>
                    </div>
                    <div class="mt-5 sm:mt-4 flex justify-between space-x-2">
                        <button-secondary
                            @click="open = false; emit('close')"
                        >
                            {{ _mft('shared:modal.close') }}
                        </button-secondary>
                        <slot name="actions"></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
