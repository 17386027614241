<script setup>
    import { computed, reactive } from 'vue'
    import { ChevronDownIcon, MinusSmIcon } from '@heroicons/vue/solid'

    const emit = defineEmits('folder-favorited')

    const props = defineProps({
        isCurrent: {
            type: Boolean,
            required: false,
            default: false
        },
        folder: {
            type: Object,
            required: true
        },
        showRouteName: {
            type: String,
            required: true,
        },
        showRouteParams: {
            type: Array,
            required: true,
        },
        routeParams: {
            type: Object,
            required: false,
            default: () => {}
        }
    })

    const state = reactive({
        showContents: false,
    })

    const toggleContents = () => {
        state.showContents = !state.showContents
    }

    const toggleFavorite = async (folder) => {
        folder.favorited = ! folder.favorited
        const res = await axios.post(route('pages.folder.toggle-favorite', [folder.id]))
        folder.favorited = res.data.favorited
        emit('folder-favorited')
    }

    const combinedRouteParams = computed(() => {
        let params = [];
        if (props.routeParams) {
            for (const [param, paramValue] of Object.entries(props.routeParams)) {
                params[param] = paramValue
            }
        }

        props.showRouteParams.forEach(param => {
            if (props.folder[param]) {
                params[param] = props.folder[param]
            }
        })

        return Object.values(params);
    })

</script>
<template>
    <div>
        <div class="relative flex items-center">
            <span
                :class="props.isCurrent ? 'font-bold' : ''"
                class="w-4 h-5 -translate-y-1"
            >
                <span class="relative text-lg text-gray-400">
                    <i
                        class="fa-thin fa-folder"
                    ></i>
                    <i
                        v-if="props.folder.is_smart"
                        :class="props.folder.icon_class"
                        class="absolute transform scale-50 -translate-x-1/2 translate-y-1/2 fa-regular left-1/2 bottom-1/2"
                    ></i>
                    <i
                        v-else
                        class="absolute z-50 top-2 left-1 cursor-pointer fa-star text-xxs transform -translate-x-px"
                        :class="folder.favorited ? 'fa-solid text-yellow-300' : 'fa-regular text-gray-300 hover:text-yellow-300'"
                        @click="toggleFavorite(folder)"
                    ></i>
                </span>
            </span>
            <div
                class="relative w-full pr-6 overflow-hidden whitespace-nowrap overflow-ellipsis"
            >
                <a
                    :href="route(props.showRouteName, combinedRouteParams)"
                    class="block ml-2 mr-1 mf-link text-xs"
                >
                    <span v-if="props.folder.is_smart">{{ _mft(props.folder.name) }}</span>
                    <span v-else>{{ props.folder.name }}</span>
                </a>
                <span
                    v-if="props.folder.content_count > 0"
                    @click="toggleContents"
                >
                    <small
                        v-if="false"
                        class="absolute top-0 bottom-0 right-0 flex px-1 py-0.5"
                        :class="props.folder.content_count > 0 ? 'text-gray-500' : ''"
                    >
                        {{ props.folder.content_count }}

                        <div
                            v-if="props.folder.contents.length > 0"
                            class="relative text-center"
                        >
                            <MinusSmIcon v-if="state.showContents" class="w-4 h-4" />
                            <ChevronDownIcon v-if="!state.showContents" class="w-4 h-4" />
                        </div>
                    </small>
                </span>
            </div>
        </div>
        <ul
            v-if="state.showContents"
            class="pl-4 ml-2 border-l"
        >
            <li
                v-for="(item, i) in props.folder.contents"
                :key="i"
            >
                <a
                    :href="item.url"
                    class="block pt-1 overflow-hidden hover:underline whitespace-nowrap overflow-ellipsis"
                >
                    {{ item.label }}
                </a>
            </li>
        </ul>
    </div>
</template>
