<template>
        <tr class="border-b-2 border-white">
            <td>{{schemaProperties.time}}</td>

            <td>{{schemaProperties.event}}</td>

            <td>
                <div class="flex flex-row gap-4">
                    <img loading="lazy" :src="schemaProperties.img" :class="schemaProperties.display" class="h-12 w-auto rounded-full border-white" alt="expert image">
                    <img loading="lazy" :src="schemaProperties.img2" :class="schemaProperties.display2" class="h-12 hidden w-auto rounded-full border-white" alt="expert image">
                </div>
            </td>
        </tr>
</template>

<script>
export default {
    props: {
        schemaProperties: Object,
    },
    data() {
        return {
            schemaP: this.schemaProperies
        };
    },
};


</script>

<style scoped>

</style>
