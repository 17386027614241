<template>

    <div>
        <i
            class="float-right p-1 fas fa-question-circle text-myflowPurple"
            v-on:click="openInSlideout"
        ></i>
    </div>

    <basic-slideout
        v-model:open="open"
    >
        <template v-slot:header>
            <h1>{{articleContent.title}}</h1>
        </template>

        <template v-slot:body>
            <div v-html="articleContent.description"></div>
            <hr class="mt-3 mb-3">
            <div v-html="articleContent.body"></div>
        </template>
    </basic-slideout>
</template>
<script>
import axios from "axios";

export default {
    props: {
        articlenumber: Number,
    },
    data: function(){
        return {
            open: false,
            articleID: this.articlenumber,
            articleContent: []
        }
    },
    methods: {
        openInSlideout(){
            this.getArticle();
            this.open = true;
        },
        closeSlideout(){
            this.open = false;
        },
        async getArticle() {
            var config = {
                method: 'get',
                url: 'https://corsproxy.oliverkraft.io/https://api.intercom.io/articles/' + this.articleID,
                headers: {
                    'Authorization': 'Bearer dG9rOmI1YTQ2MmZkXzIyNDlfNGE1YV84ODU5X2EzM2UxNDczNWIyMDoxOjA=',
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            };

            axios(config)
                .then((response) => {
                    this.articleContent = response.data;
                })
                .catch((error) => {
                    console.error(error);
                });
        },
    },
    mounted: function(){
        this.getArticle();
    },
    computed: {
    },
    setup() {
    },
}
</script>
<style>
.intercom-align-left {
    padding-bottom: 0em !important;
    padding-top: 0.5em !important;
}
</style>
