<template>
  <div>
    <input-color
        class="w-full"
        :showGradientPreview="true"
        :primaryColor="primaryColor"
        :secondaryColor="secondaryColor"
        @updateSelectedColor="updateSelectedColor"
        @updateCalculatedSecondaryColor="updateCalculatedSecondaryColor"
    />
    <button
      class="float-right mt-3 myflow-basic-button"
      v-on:click="saveColors"
    >{{ _mft('shared:action.save') }}</button>
  </div>
</template>
<script>
export default {
  props: {
    primaryColor: {
      type: String,
      default: ''
    },
    secondaryColor: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selectedPrimaryColor: null,
      selectedSecondaryColor: null
    }
  },
  methods: {
    updateSelectedColor(color){
      this.selectedPrimaryColor = color
    },
    updateCalculatedSecondaryColor(color){
      this.selectedSecondaryColor = color
    },
    saveColors(){
      axios.post(route('company.colors.update'), {
        primaryColor: this.selectedPrimaryColor,
        secondaryColor: this.selectedSecondaryColor
      })
      .then(() => {
        window.location.reload()
      });
    }
  },

}
</script>
