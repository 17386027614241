<template>
    <modal-basic
        :open="modalOpen"
        @closed="closed()"
        :showClose="true"
        :isDirty="isDirty"
        ref="modalElement"
    >
        <template v-slot:header>
            <h2 v-if="!submitted" class="myflow-h2">{{ _mft('modal:feedback.provideFeedbaack') }}</h2>
            <h2 v-if="submitted" class="myflow-h2 text-center">{{ _mft('modal:feedback.submitted') }}</h2>
        </template>
        <template v-slot:body>
            <div v-if="!submitted">
                <p v-html="_mft('modal:feedback.intro')"></p>

                <div class="mb-2">
                    <div>
                        <button
                            class="mr-2 mb-2"
                            :class="[form.feedbackType === 'bugreport' ? 'myflow-basic-button--state-active' : 'myflow-basic-button']"
                            v-on:click="setFeedbackType('bugreport')"
                        >{{ _mft('modal:feedback.foundBug') }}</button>
                        <button
                            class="mr-2 mb-2"
                            :class="[form.feedbackType === 'improvementsuggestion' ? 'myflow-basic-button--state-active' : 'myflow-basic-button']"
                            v-on:click="setFeedbackType('improvementsuggestion')"
                        >{{ _mft('modal:feedback.suggestion') }}</button>
                    </div>
                </div>
                <div
                    v-if="form.feedbackType === 'bugreport'"
                    class="-ml-4 -mr-4 lg:-ml-8 lg:-mr-8 bg-gradient-to-r from-myflowPurple-700 to-myflowPurple-900 text-white p-4 mb-4 px-4 lg:px-8"
                >
                    <div class="grid md:grid-cols-3">
                        <p class="col-span-2 md:mb-0">{{ _mft('modal:feedback.supportAvailable') }}</p>
                        <span class="col-span-1 flex justify-end md:justify-center items-center">
                            <button
                                v-on:click="toggleIntercom()"
                                class="myflow-basic-button--inverted"
                                v-if="!supportOpen"
                            >{{ _mft('modal:feedback.openSupport') }}</button>
                            <button
                                v-on:click="toggleIntercom()"
                                class="myflow-basic-button--inverted"
                                v-if="supportOpen"
                            >{{ _mft('modal:feedback.closeSupport') }}</button>
                        </span>
                    </div>
                </div>
                <div class="mb-4" v-if="form.feedbackType !== ''">
                    <input-select
                        v-if="categories"
                        :error="errors.feedbackCategory?.join(', ')"
                        :label="_mft('modal:feedback.category')"
                        :required="true"
                        name="feedbackCategory"
                        :options="categories"
                        v-model:content="form.feedbackCategory"
                    />
                </div>
                <div
                    class="mb-4"
                    v-if="form.feedbackType !== ''"
                >
                    <input-textarea
                        :label="feedbackTextLabel"
                        :error="errors.feedbackText?.join(', ')"
                        v-model:content="form.feedbackText"
                    />
                </div>
                <div class="flex justify-end">
                    <button
                        :class="[formIsValid ? 'myflow-basic-button' : 'myflow-basic-button--state-disabled cursor-not-allowed']"
                        v-on:click="submitFeedback()"
                        v-if="form.feedbackType !== ''"
                        :disabled="!formIsValid"
                    >
                        {{ _mft('modal:feedback.send') }}
                    </button>
                </div>
            </div>
            <div v-else class="text-center">
                <p>{{ _mft('modal:feedback.thanks') }}</p>
                <div class="flex justify-end">
                    <button class="myflow-basic-button" v-on:click="close">{{ _mft('shared:modal.close') }}</button>
                </div>
            </div>
        </template>
    </modal-basic>
</template>
<script>
export default {
    props: {
        modalOpen: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isDirty: false,
            errors: {
                feedbackCategory: [],
                feedbackType: [],
                feedbackText: [],
            },
            form: {
                feedbackCategory: '',
                feedbackType: '',
                feedbackText: '',
                contextData: {
                    currentUrl: null,
                    useragent: null,
                    screenSize: null
                }
            },
            categories: [],
            submitted: false,
            supportOpen: false
        }
    },
    methods: {
        closed(){
            this.form = {
                feedbackCategory: '',
                feedbackType: '',
                feedbackText: '',
                contextData: {
                    currentUrl: null,
                    useragent: null,
                    screenSize: null
                }
            }
            this.isDirty = false
            this.$emit('closed');
        },
        close(){
            this.$refs.modalElement.closeModal()
        },
        collectContextData(){
            this.form.contextData.currentUrl = window.location.href;
            this.form.contextData.useragent = navigator.userAgent;
            this.form.contextData.screenSize = window.innerWidth + "x" + window.innerHeight;

        },
        async getOptions(){
            let response = await axios.get(route('feedback.getOptions'));
            this.categories = response.data.categories;
        },
        async submitFeedback(){
            try {
                let response = await axios.post(route('feedback.create'), this.form)
                this.isDirty = false;
                this.submitted = true;
            } catch(error) {
                this.errors = error.response.data.errors;
            }

        },
        toggleIntercom(){
            if( this.supportOpen ) {
                Intercom('hide');
            } else {
                Intercom('show');
            }
            this.supportOpen = !this.supportOpen;
        },
        setFeedbackType(type){
            this.form.feedbackType = type
        }
    },
    mounted() {
        this.getOptions();
        this.collectContextData();
    },
    computed: {
        feedbackTextLabel(){
            if( this.form.feedbackType === 'bugreport' ){
                return _mft('modal:feedback.describeBug')
            }
            if( this.form.feedbackType === 'improvementsuggestion' ){
                return _mft('modal:feedback.describeSuggestion')
            }
        },
        formIsValid(){
            if( this.form.feedbackCategory !== ''
            && this.form.feedbackType !== ''
            && this.form.feedbackText !== '' ){
                return true;
            }
            return false;
        }
    },
    watch: {
        form: {
            deep: true,
            handler(e) {
                if( e.feedbackType !== '' ){
                    this.isDirty = true
                }
            }
        }
    }
}
</script>
