<template>
  <div class="flex flex-col items-end">
    <button v-if="!confirming" class="myflow-basic-button--danger" @click.prevent="confirming = true">
        {{ _mft('coaching:session.cancel') }}
    </button>
    <div v-show="confirming">
      <p class="mb-1 text-xs">
          {{ _mft('coaching:session.cancel.areYouSure') }}
      </p>
      <div class="flex items-end justify-end space-x-2">
        <a :href="route('coaching.session.cancel', this.bookingId)" class="myflow-basic-button--danger">{{ _mft('coaching:session.cancel') }}</a>
        <button class="myflow-basic-button--secondary" @click="confirming = false">{{ _mft('shared:cancel') }}</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
  bookingId: { type: Number, required: true }
});

const confirming = ref(false);
</script>
