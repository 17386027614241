<template>
    <div>
        <nav aria-label="Progress">
            <ol role="list" class="flex space-x-8 space-y-0 space-y-4">
                <li v-for="step in steps" :key="step.id" class="flex-1">
                    <a
                        class="flex flex-col py-2 pl-4 text-center border-t-4 group md:border-l-0 md:pb-0 md:pl-0 md:pt-4"
                        :class="state.currentStep === step.id ? 'border-myflowPurple-600 hover:border-myflowPurple-900' : 'border-gray-200 hover:border-gray-300'"
                    >
                        <span class="text-lg font-medium text-gray-500 group-hover:text-gray-700">{{ step.id }}</span>
                        <span class="hidden text-sm font-medium sm:block">{{ step.name }}</span>
                    </a>

                </li>
            </ol>
        </nav>

        <div v-if="state.currentStep == 1">
            <div class="lg:flex sm:p-12">
                <img :src="props.inviteeImage" class="w-24 h-24 mx-auto mb-5 rounded-full lg:mb-0 sm:w-36 sm:h-36 lg:mr-8">
                <div class="text-lg">
                    <p>{{ _mft('salesProcess:onboarding.text.first', {name: props.inviteeName}) }}</p>
                    <p>{{ _mft('salesProcess:onboarding.text.second') }}</p>
                    <p>{{ _mft('salesProcess:onboarding.text.third') }}</p>
                    <p>{{ _mft('salesProcess:onboarding.text.fourth') }}</p>
                    <p>/ {{ props.inviteeName }}</p>

                    <button @click="state.currentStep = 2" class="w-full myflow-basic-button secondary">{{ _mft('salesProcess:onboarding.proceed') }}</button>
                </div>
            </div>
        </div>

        <div v-if="state.currentStep == 2">
            <fieldset class="mt-5">
                <div class="rounded-lg">
                    <label
                        v-for="area in help_areas.value" :key="area.id"
                        class="relative flex p-4 border rounded-lg cursor-pointer focus:outline-none"
                        :class="state.selectedHelpArea == area.id ? 'z-10 border-myflowPurple-200 bg-myflowPurple-50 border-2' : 'border-gray-200 m-2'"
                        @click="state.selectedHelpArea = area.id"
                    >
                        <input type="radio" name="privacy-setting" value="Public access" class="mt-0.5 h-4 w-4 shrink-0 cursor-pointer text-myflowPurple-600 border-gray-300 focus:ring-myflowPurple-600 active:ring-2 active:ring-offset-2 active:ring-myflowPurple-600" >
                        <span class="flex flex-col ml-3">
                            <span
                                class="block text-sm font-medium"
                                :class="state.selectedHelpArea == area.id ? 'text-myflowPurple-900' : 'text-gray-900'"
                            >{{ area.name  }}</span>
                        </span>
                    </label>
                </div>
            </fieldset>
            <button v-if="state.selectedHelpArea" @click="selectEventType()" class="w-full text-lg myflow-basic-button secondary">{{ _mft('salesProcess:onboarding.proceed') }}</button>
        </div>

        <div class="relative sm:p-5" v-if="state.currentStep == 3">
            <div
                class="relative z-20"
                :class="!state.bookingReady ? 'block' : 'hidden'"
            >
                <input type="text" maxlength="255" v-model="props.clientName" class="myflow-basic-input" :placeholder="_mft('salesProcess:onboarding.placeholders.name')">
                <input type="email" v-model="state.clientEmail" class="myflow-basic-input" placeholder="_mft('salesProcess:onboarding.placeholders.email')">
                <button
                    v-if="emailIsValid(state.clientEmail)"
                    @click="enhanceLead()"
                    class="w-full text-lg myflow-basic-button"
                >{{ _mft('salesProcess:onboarding.scheduleMeeting') }}</button>
            </div>

            <sales-process-booking-cronofy-calendar
                v-if="state.bookingReady"
                :client-id="props.clientId"
                :client-email="state.clientEmail"
                :invitee-name="props.inviteeName"
                @bookingCompleted="onBookingCompleted"
                @bookingFailed="onBookingFailed"
            />
            <p
                v-if="state.bookingFailedMessage !== ''"
                class="italic text-center"
            >{{ state.bookingFailedMessage }}</p>

        </div>

        <div v-if="state.currentStep == 4" class="p-5 mt-5 text-center">

            <h3 class="mb-5">{{ state.booking.message }}</h3>
            <p>{{ _mft('salesProcess:onboarding.createAnAccount') }}:</p>

            <a :href="props.registerUrl" class="myflow-basic-button">
                {{ _mft('salesProcess:onboarding.createFreeAccount') }}
            </a>
        </div>

    </div>
</template>

<script setup>
    import { reactive, toRefs, watch, onMounted, ref } from 'vue';

    const state = reactive({
        currentStep: 1,
        selectedHelpArea: null,
        clientEmail: '',
        bookingReady: false,
        bookingFailedMessage: '',
        booking: null
    })

    const props = defineProps({
        registerUrl: {
            type: String,
            required: true
        },
        clientId: {
            type: Number,
            required: true
        },
        inviteeName: {
            type: String,
            required: true
        },
        inviteeImage: {
            type: String,
            required: true
        },
        clientName: {
            type: String,
            required: true
        },
    })

    const steps = [
        {
            id: 1,
            name: _mft('salesProcess:onboarding.steps.welcome')
        },
        {
            id: 2,
            name: _mft('salesProcess:onboarding.steps.selectChallenge'),
        },
        {
            id: 3,
            name: _mft('salesProcess:onboarding.steps.scheduleMeeting'),
        },
        {
            id: 4,
            name: _mft('salesProcess:onboarding.steps.finalize'),
        },
    ]

    const help_areas = reactive([])


    const getAvailableAreas = async () => {
        const resp = await axios.get(route('sales-processes.availableAreas', [props.clientId]));

        help_areas.value = resp.data;
    }

    const onBookingCompleted = (booking) => {
        state.booking = booking;
        state.currentStep = 4;
    }
    const onBookingFailed = (booking) => {
        state.bookingFailedMessage = booking.message;
    }

    const selectEventType = async () => {
        axios.post(route('sales-procesesses.addEventSelection', [props.clientId, state.selectedHelpArea]));

        state.currentStep = 3;
    }

    const emailIsValid = (email) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
    }

    const enhanceLead = async () => {
        await axios.post(route('sales-procesesses.enhanceLead', [props.clientId]), {
            name: props.clientName,
            email: state.clientEmail,
        });
        state.bookingReady = true
    }

    window.addEventListener("message", function(e) {

    });


    watch(() => state.currentStep, (currentStep) => {
        if (currentStep === 3) {
        }
    });

    onMounted(() => {
        getAvailableAreas();
        if (state.currentStep === 3) {
        }
    });




</script>
