<template>
    <div class="">
        <div class="flex justify-center mb-8 lg:m-0 lg:justify-between items-center">
               <img loading="lazy" :class="[imageProperties.width, imageProperties.style]" :src="imageProperties.img" alt="partner image">
        </div>
    </div>
</template>

<script>
export default {
    props: {
        imageProperties: Object,
    },
    data() {
        return {
            imgP: this.imageProperties
        };
    },
};


</script>

<style scoped>

</style>
