<template>
    <mf-table v-if="users !== null" :data="users" :columns="columns" :loading="loading" :filters="filters" :searchable="searchable">
        <template #column-name="{ row }">
            <div :class="['flex', 'items-center']">
                <div class="flex-shrink-0 w-10 h-10">
                    <img
                        :src="row.profile_img_url ? row.profile_img_url : defaultProfileImageUrl"
                        class="object-cover object-center w-10 h-10 rounded-full"
                        alt=""
                    >
                </div>
                <div class="ml-4">
                    <div class="text-sm font-medium text-gray-900">
                        {{ row.name }}
                    </div>
                </div>
            </div>
        </template>
        <template #column-created_at="{ row }">
            <span class="uppercase">
                {{ row.created_at }}
            </span>
        </template>
        <template #column-role="{ row }">
            <div v-if="row.role">
                <span
                    class="mb-1 align-bottom myflow-success-pill"
                    v-if="row.role == 'User'"
                >
                    User
                </span>
                <span
                    class="mb-1 align-bottom myflow-basic-pill"
                    v-if="row.role == 'Company Admin'"
                >
                    Company Admin
                </span>
            </div>
        </template>
        <template #actions="{ row }">
            <a
                :href="route('admin.impersonate', row.id)"
                v-if="row.impersonatable"
                class="mr-2 text-sm font-medium text-right text-myflowPurple-600 hover:text-myflowPurple-900 myflow-warning-button"
            >Impersonate</a>
            <a
                :href="route('admin.user', row.id)"
                class="mr-2 text-sm font-medium text-right text-myflowPurple-600 hover:text-myflowPurple-900 myflow-basic-button"
            >Visa mer</a>
        </template>
    </mf-table>
    <div v-else class="text-center">
        <mf-spinner-medium
          class="w-24 h-12"
        />
    </div>
</template>

<style lang="scss" scoped>
</style>

<script setup>
import { ref, toRefs, watch, onMounted } from 'vue';
import axios from 'axios';

const props = defineProps({
    url: { type: String, required: true }
});

const { url } = toRefs(props);

const defaultProfileImageUrl = 'https://myflow.fra1.digitaloceanspaces.com/generic-profile-icon-23.jpeg';
const dateFormatter = new Intl.DateTimeFormat(['sv-SE'], {
    timeZone: 'Europe/Stockholm',
    dateStyle: 'short'
});

const users = ref(null);

const loading = ref(false);

const updateUsers = async () => {
    let nextUrl = url.value;
    users.value = [];

    loading.value = true;

    while (nextUrl) {
        const resp = await axios.get(nextUrl);
        nextUrl = resp.data.links?.next;

        const respData = resp.data.data.map((row) => ({
            ...row,
            created_at: row.created_at ? dateFormatter.format(new Date(row.created_at)) : null
        }));
        users.value.push(...respData);
    }

    loading.value = false;
}
watch(url, updateUsers);
onMounted(updateUsers);

const columns = [
    { label: 'Namn', key: 'name', sort: 'name' },
    { label: 'E-post', key: 'email', sort: 'email', desktop: true },
    { label: 'Tillagd', key: 'created_at', sort: 'created_at'},
    { label: 'Site', key: 'site', sort: 'site', desktop: true },
    { label: 'Role', key: 'role', sort: 'role', desktop: true },
];
const searchable = ['name', 'email', 'company', 'created_at', 'company_type'];
const filters = [
]

const classObject =  (type) => {
    if(type == 'creator') {
        return 'myflow-success-pill';
    } else if(type == 'internal') {
        return 'myflow-danger-pill'
    } else {
        return 'myflow-basic-pill'
    }
}
</script>
