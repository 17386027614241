<template>
    <ul v-if="loaded">
        <li v-for="(receipt, receiptIdx) in receipts" :key="receipt.id" class="p-3 bg-white rounded-lg">
            <hr v-if="receiptIdx > 0" class="my-3" />

            <a :href="route('receipts.show', receipt.id)" class="text-black">
                <h5 class="mb-2">{{ _mft('receipt:domainNameSingular') }}: #{{receipt.id}}</h5>

                <div class="flex items-baseline justify-start mb-2" v-if="receipt.reference">
                    <i class="mr-2 fa-light fa-buildings"></i>
                    <span>
                        {{ receipt.reference }}
                    </span>
                </div>
                <div class="flex items-baseline justify-start mb-2">
                    <i class="mr-2 fal fa-calendar-alt"></i>
                    <span>
                        {{formattedCapturedAt(receipt)}}
                    </span>
                </div>
                <div class="flex items-baseline justify-start mb-2">
                    <i class="mr-2 fal fa-money-bill"></i>
                    <span>
                        {{ _mft('receipt:amount') }}: {{receipt.amount_including_vat}}
                    </span>
                </div>
            </a>
        </li>
    </ul>
    <mf-spinner-medium v-else
        class="w-24 h-12"
    />
</template>

<script>
export default {
    props: {
        limit: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            receipts: [],
            loaded: false
        };
    },
    async mounted() {
        const resp = await axios.get(route('receipts.index'));
        let receipts = resp.data.data
            .map((receipt) => ({
                ...receipt,
                completed_at: new Date(receipt.completed_at),
            }))
            .sort((r1, r2) => r2.completed_at - r1.completed_at);

        if (this.limit !== null) {
            receipts = receipts.slice(0, this.limit);
        }

        this.receipts = receipts;
        this.loaded = true;
    },
    methods: {
        formattedCapturedAt(receipt) {
            const formatter = (new Intl.DateTimeFormat('sv-SE', {
                dateStyle: 'short',
                timeStyle: 'short'
            }));
            return formatter.format(receipt.completed_at);
        }
    }
};
</script>
