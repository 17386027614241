<script setup>

import {computed, ref, watch} from "vue";
import { PlusIcon, XIcon } from "@heroicons/vue/outline"
import {debounce} from "lodash";

const emit = defineEmits(['addFieldOption', 'removeFieldOption'])

const props = defineProps({
    field: {
        type: Object,
        required: true,
    },
    parameters: {
        type: Object,
        required: true,
    },
    placeholder: {
        type: String,
        required: false,
        default: '',
    }
})

const textInput = ref('')
const textInputs = ref([])

const addTextInput = () => {
    textInputs.value.push(textInput.value)
    emit('addFieldOption', props.field, {label: textInput.value, value: textInput.value})
    textInput.value = ''
}

const removeTextInput = (option) => {
    emit('removeFieldOption', props.field, option)
}

const clearFilter = () => {
    textInput.value = ''
}

</script>

<template>
    <div>
        <div class="relative">
            <div class="relative">
                <input-text
                    :placeholder="props.placeholder"
                    v-model:content="textInput"
                    @keyup.enter="addTextInput()"
                ></input-text>
                <div class="absolute right-2 top-2.5">
                    <button-primary
                        v-if="textInput.length > 1"
                        @click="addTextInput()"
                        size="sm"
                    >
                        {{ _mft('shared:add') }}
                    </button-primary>
                </div>
            </div>

            <ul
                v-if="parameters[props.field.field].length > 0"
                class="-mt-2 flex flex-wrap items-center px-0.5"
            >
                <li
                    v-for="(option, o) in parameters[props.field.field]"
                    :key="o"
                    class="mr-1 mb-1 flex items-center justify-between bg-white px-2 py-1 text-xs shadow-md space-x-1 rounded-wl"
                >
                    <div>{{ option.label }}</div>
                    <button
                        @click="removeTextInput(option)"
                    >
                        <XIcon class="h-4 w-4" />
                    </button>
                </li>
            </ul>
        </div>
    </div>
</template>

<style scoped>

</style>
