<script setup>

import {computed, nextTick, onMounted, ref} from "vue";
import ButtonBar from "../navigation/buttonBar.vue";
import MfSpinnerMedium from "../mf/spinner/medium.vue";

const emit = defineEmits(['close'])

const props = defineProps({
    url: {
        type: String,
        required: true
    }
})

const currentMode = ref('desktop')
const revealed = ref(false)
const windowWidth = ref(null)
const windowHeight = ref(null)

const modeOptions = [
    {
        label: 'Desktop',
        name: 'desktop'
    },
    {
        label: 'Phone',
        name: 'phone'
    }
]

const setMode = (mode) => {
    currentMode.value = mode
}

const close = () => {
    revealed.value = false
    setTimeout(function(){
        emit('close')
    }, 300)
}

const desktopScale = computed(() => {
    if (windowWidth === null) {
        return 'scale(1)';
    }

    let scale = 1

    if (windowWidth.value < 2040) {
        scale = windowWidth.value / 2040
    }

    if (windowHeight.value < 1400) {
        if ((windowHeight.value / 1400) < scale) {
            scale = windowHeight.value / 1400
        }
    }

    return 'scale('+scale+')';
})

const phoneScale = computed(() => {
    let scale = 1;
    if (windowHeight.value < 800) {
            scale = windowHeight.value / 800
    }
    return 'scale('+scale+')';
})

onMounted(() => {
    setTimeout(function(){
        revealed.value = true
    }, 100)

    windowWidth.value = window.innerWidth;
    windowHeight.value = window.innerHeight;
    window.addEventListener('resize', () => {
        windowWidth.value = window.innerWidth;
        windowHeight.value = window.innerHeight;
    })

    document.onkeydown = function(e) {
        if (e.key === 'Escape') {
            close();
        }
    };
})

</script>

<template>
    <div
        class="fixed inset-0 z-modal-backdrop flex flex-col justify-start items-center transition-colors"
        :class="revealed ? 'bg-black/80' : 'bg-black/0'"
    >
        <div
            class="w-full pt-8 transition-all"
            :class="revealed ? 'translate-y-0 opacity-100' : 'translate-y-8 opacity-0'"
        >
            <div class="flex justify-center items-center space-x-2">
                <button-bar
                    :options="modeOptions"
                    @select="setMode($event.name)"
                    class="max-w-sm"
                />
                <a
                    class="mb-5"
                    :href="url" target="_blank"
                    @click="close"
                >
                    <button-secondary>
                        <i class="far fa-arrow-up-right-from-square" aria-hidden="true"></i>
                    </button-secondary>
                </a>
                <button-secondary
                    @click="close"
                    class="mb-5"
                >
                    {{ _mft('shared:modal.close') }}
                </button-secondary>
            </div>

            <div class="flex justify-center items-start relative">
                <mf-spinner-medium class="z-10 absolute top-24 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-24 h-12"></mf-spinner-medium>
                <div class="z-20">
                    <iframe
                        v-if="currentMode === 'desktop'"
                        :src="url"
                        class="rounded-t-lg rounded-b-sm transform preview-desktop"
                    />
                    <iframe
                        v-if="currentMode === 'phone'"
                        :src="url"
                        class="rounded-2xl transform preview-phone mx-auto"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
.preview-desktop {
    width: 1920px;
    height: 1080px;
    transform: v-bind('desktopScale');
    transform-origin: top center;
    aspect-ratio: 16 / 9;
    border: 8px solid #000;
    box-shadow: 0 0 0 2px #9f9f9f;
}

.preview-phone {
    height: 600px;
    width: 300px;
    transform: v-bind('phoneScale');
    transform-origin: top center;
    aspect-ratio: 9 / 16;
    border: 4px solid #000;
    box-shadow: 0 0 0 3px #9f9f9f;
}
</style>
