<script setup>
import {ref, watch} from "vue";
import {debounce} from "lodash";
import { useNotificationStore } from '@/stores/NotificationStore.js'
const notificationStore = useNotificationStore()

const emit = defineEmits(['imageSelected'])

const query = ref('');
const page = ref(1);
const searching = ref(false);
const results = ref([]);
const previewing = ref(null);

const search = async () => {
    if (searching.value === true || query.value === '') {
        return
    }
    searching.value = true

    const res = await axios.get(route('api.pexels.search'), {
        params: {
            query: query.value,
            per_page: 12,
            page: page.value
        }
    });

    searching.value = false

    if (res.data.error) {
        notificationStore.addNotification({
            type: 'error',
            title: 'An error occurred',
            message: 'Could not load images, please try again later.',
            dismissAfter: 5000,
        })
        return;
    }

    results.value = res.data.photos
}

const selectImage = (image) => {
    emit('imageSelected', image)
    previewing.value = null
    results.value = []
}

const clearSearch = () => {
    query.value = ''
    results.value = []
}

const togglePreview = (index) => {
    if (previewing.value === index) {
        previewing.value = null
    } else {
        previewing.value = index
    }
}

const nextPage = () => {
    page.value++
    search()
}

const prevPage = () => {
    if (page.value === 1) {
        return
    }
    page.value--
    search()
}

watch(() => query, debounce((newVal) => {
    page.value = 1
    search()
}, 1000), {deep: true})

</script>
<template>
<div class="@container">
    <div class="relative">
        <input-text
            v-model:content="query"
            :placeholder="_mft('shared:search')"
            @keyup.enter="search"
            class="w-full"
        />
        <div class="absolute right-2 top-2.5">
            <mf-spinner-medium
                v-if="searching"
                class="h-6 w-12"
            />
            <button-primary
                v-else
                size="sm"
                @click="search"
            >
                {{ _mft('shared:search') }}
            </button-primary>
        </div>
    </div>

    <teleport to="body">
        <div
            v-if="previewing !== null"
            class="fixed inset-0 flex items-center justify-center bg-black/60 z-modal"
        >
            <div class="relative h-full w-full">
                <div class="fixed inset-0 w-full h-full flex justify-center items-center z-30">
                    <div
                        class="absolute inset-0 z-10"
                        @click="togglePreview(null)"
                    ></div>
                    <div class="mx-auto relative z-40">
                        <img
                            :src="results[previewing].src.large2x"
                            alt="Image preview"
                            style="max-width: 90vw; max-height: 90vh; margin: 5vh 5vw;"
                            class="max-w-screen max-h-screen"
                        />
                        <div class="absolute right-4 bottom-[5vh] left-4 z-50 flex justify-center space-x-4 mb-4">
                            <button-secondary
                                @click="togglePreview(null)"
                            >{{ _mft('shared:modal.close') }}</button-secondary>
                            <button-primary
                                @click="selectImage(results[previewing])"
                            >{{ _mft('shared:select') }}</button-primary>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </teleport>

    <ul
        v-if="results.length > 0"
        class="results mb-4"
        :class="searching ? 'opacity-50' : ''"
    >
        <li
            v-for="(result ,i) in results"
            :key="i"
            class="relative group bg-black"
        >
            <img
                :src="result.src.medium"
                class="h-auto w-full cursor-pointer transition-opacity group-hover:opacity-50"
                :alt="result.alt"
                @click="togglePreview(i)"
            />
            <i class="fa-solid fa-magnifying-glass-plus absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 text-2xl text-white opacity-0 group-hover:opacity-100 transition-all mt-4 group-hover:mt-0 pointer-events-none"></i>
        </li>
    </ul>
    <div
        v-if="results.length > 0"
        class="flex justify-between space-x-4 pt-4 mb-8"
    >
        <button-base
            size="sm"
            @click="prevPage"
        >
            {{ _mft('shared:previous') }}
        </button-base>
        <button-danger
            size="sm"
            @click="clearSearch"
        >{{ _mft('shared:action.clear') }}</button-danger>
        <button-base
            size="sm"
            @click="nextPage"
        >
            {{ _mft('shared:next') }}
        </button-base>
    </div>
</div>
</template>
<style scoped>
.results {
    column-count: 3;
    column-gap: 0.25rem;
    width: 100%;
}

.results li {
    break-inside: avoid;
    margin-bottom: 0.25rem;
}
</style>

