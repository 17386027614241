<template>
    <div class="p-4 mb-5 border-2 rounded-md border-myflowYellow-400">
        <div class="flex">
            <div class="flex-shrink-0">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 text-myflowYellow-900">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0M3.124 7.5A8.969 8.969 0 015.292 3m13.416 0a8.969 8.969 0 012.168 4.5" />
                </svg>

            </div>
            <div class="ml-3">
                <h3 class="text-sm font-medium text-myflowYellow-900">
                    <b><slot name="header"></slot></b>
                </h3>
                <div class="mt-2 text-sm text-myflowYellow-900">
                    <slot name="body"></slot>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
  computed: {
    hasHeaderSlot () {
      return !!this.$slots.header
    },
    hasBodySlot () {
      return !!this.$slots.body
    },
  },
  setup() {

  },
}
</script>
