<script setup>
import { computed } from 'vue';

const props = defineProps({
    id: { type: Number, required: true, },
    startDate: String,
    startTime: String,
    endTime: String
});

const requestData = computed(() => {
    return {
        booking: {
            startDate: props.startDate,
            startTime: props.startTime,
            endTime: props.endTime
        }
    };
})
</script>

<template>
    <purchase-button-base
        :request-url="route('coaching.purchaseWithUnclaimedBookings', props.id)"
        :request-data="requestData"
        @success="$document.locationAssign(route('coaching.my-bookings-overview', {state: 'confirmLatest'}))"
    >
        <template #default><slot></slot></template>
    </purchase-button-base>
</template>
