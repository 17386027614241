<template>
    <div class="relative">
        <input-text
            class="!mb-0"
            v-model:content="search"
            :placeholder="_mft('form:searchFont')"
            id="google-font-input"
        />
        <ul
            v-if="search"
            class="absolute left-0 right-0 z-20 overflow-y-scroll top-full max-h-64 shadow-lg rounded-lg"
        >
            <li
                class="p-2 bg-white cursor-pointer hover:bg-gray-50"
                v-for="(font, i) in filteredFonts"
                v-bind:key="i"
                @click="selectFont(font)"
                >
                {{ font.family }}
            </li>
        </ul>
    </div>
</template>
<script>

export default {
    data() {
        return {
            fonts: [],
            search: '',
            selectedFont: {}
        }
    },

    methods: {
        async loadFonts(){
            let res = await axios.get(route('googlefonts'));
            this.fonts = res.data.fonts;
        },
        selectFont(font){
            this.selectedFont = font;
            this.search = '';
            this.$emit('fontSelected', this.selectedFont);
        },
    },

    mounted() {
        this.loadFonts();
    },

    computed: {
        filteredFonts(){
            return this.fonts.filter(
                o => o.family.toLowerCase().includes(
                        this.search.toLowerCase()
                )
            );
        },
    }

}
</script>
