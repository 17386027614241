<template>
    <div>
        <div v-if="componentData !== null">
            <BarChart :options="chartOptions" :chart-data="chartData" />
        </div>
        <div v-else class="text-center">
            <mf-spinner-medium
                class="w-24 h-12"
            />
        </div>
    </div>
</template>

<script>
import { BarChart } from 'vue-chart-3'
import { ref, onMounted, computed } from 'vue'
import axios from 'axios'
import 'chart.js/auto';

export default {
    components: {
        BarChart
    },
    props: {
        componentDataUrl: {
            type: String,
            default: () => ''
        },
    },
    setup(props) {
        const componentData = ref([]);
        const dataAsArray = Object.entries(componentData.value).map(([id, count]) => ({id, count}));


        const fetchComponentData = async () => {
            try {
                const response = await axios.get(props.componentDataUrl);
                componentData.value = response.data;
            } catch (error) {
                console.error(error);
            }
        };

        const calculateMovingAverage = (data, dayPeriod) => {
            let result = [];
            for (let i = 0; i < dayPeriod - 1; i++) {
                result[i] = null;
            }

            for (let i = dayPeriod - 1; i < data.length; i++) {
                let sum = 0;
                for (let j = 0; j < dayPeriod; j++) {
                    sum += data[i - j].count;
                }
                result[i] = sum / dayPeriod;
            }

            return result;
        }

        onMounted(async () => {
            await fetchComponentData();
        });

        const chartData = computed(() => {
    let labels, data;

    if (typeof componentData.value === 'object' && componentData.value !== null) {
        const arrayData = Object.values(componentData.value);
        labels = arrayData.map(item => item.id);
        data = arrayData.map(item => item.count);
    } else {
        labels = [];
        data = [];
    }

    return {
        labels: labels,
        datasets: [
            {
                label: 'Data',
                data: data,
                backgroundColor: 'rgba(98, 37, 120,0.2)',
                borderColor: 'rgba(98, 37, 120, 1)',
                borderWidth: 1,
                tension: 0.2,
                pointRadius: 0.5,
            },
        ]
    }
});


        const chartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    mode: 'index',
                    intersect: false,
                },
            },
            interaction: {
                mode: 'nearest',
                axis: 'x',
                intersect: false
            },
        }

        return {
            chartData,
            chartOptions
        }
    }
}
</script>
