<script setup>
import { useNotificationStore } from '@/stores/NotificationStore.js'
import { computed, ref, reactive, watch, onMounted, nextTick } from 'vue';
import Location from '../session/locationInput.vue';

const notificationStore = useNotificationStore()
const loading = ref(true);
const buffer_hours = ref(null)

const meetingLocationType = ref(null)

const expertProfileSettings = reactive({
    active: false,
    refund_unstarted: false,
    refund_max_hours_before_begins_at: null,
    required_quarters_between_bookings: null,
    default_meeting_location: null,

})

const expertProfileOptions = reactive({
    refunding_intervals: [],
    required_quarters_between_bookings: [],
})

const loadExpertProfileSettings = async () => {
    const response = await axios.get(route('coaching.profile.settings'))
    expertProfileSettings.refund_unstarted = response.data.coaching_provider_preferences.refund_unstarted;
    expertProfileSettings.refund_max_hours_before_begins_at = response.data.coaching_provider_preferences.refund_max_hours_before_begins_at;
    expertProfileSettings.required_quarters_between_bookings = response.data.coaching_provider_preferences.required_quarters_between_bookings;
    expertProfileSettings.bookable_days_in_future = response.data.coaching_provider_preferences.bookable_days_in_future;
    expertProfileSettings.buffer_hours = response.data.coaching_provider_preferences.buffer_hours;
    buffer_hours.value = response.data.coaching_provider_preferences.buffer_hours;
    expertProfileSettings.default_meeting_location = response.data.coaching_provider_preferences.default_meeting_location;
    expertProfileSettings.embed_meetings = response.data.coaching_provider_preferences.embed_meetings;

    expertProfileOptions.refunding_intervals = response.data.expert_profile_options.refunding_intervals
    expertProfileOptions.quarters_between_bookings = response.data.expert_profile_options.quarters_between_bookings
    expertProfileOptions.bookable_days_in_future = response.data.expert_profile_options.bookable_days_in_future

    nextTick(() => {
        loading.value = false;
    })
};

const updateExpertProfileSettings = async () => {
    try {
        const response = await axios.post(route('coaching.profile.updateSettings'), expertProfileSettings)
    } catch(err) {
        notificationStore.addNotification({
            type: 'error',
            title: _mft('error:error.generalOccurred'),
            message: _mft('error:error.generalTryAgain'),
            dismissAfter: 5000,
        })
        return;
    }
    notificationStore.addNotification({
        type: 'success',
        title: _mft('coaching:setting.saved'),
        dismissAfter: 2500,
    })
}

watch(expertProfileSettings, (newVal, oldVal) => {
    if (loading.value === false) {
        updateExpertProfileSettings()
    }
}, {deep: true});

onMounted(() => {
    loadExpertProfileSettings();
});

</script>
<template>
    <div>
        <mf-spinner-medium
            v-if="loading"
            class="w-12 h-6"
        ></mf-spinner-medium>
        <div
            v-else
        >
            <div class="py-4">
                <h3>{{ _mft('coaching:setting.bookableFutureTime') }}</h3>
                <div class="grid-cols-2 gap-16 md:grid">
                    <div>
                        <p>{{ _mft('coaching:setting.bookableFutureTime.description') }}</p>
                    </div>
                    <div>
                        <input-select
                            v-model:content="expertProfileSettings.bookable_days_in_future"
                            :options="expertProfileOptions.bookable_days_in_future"
                            :label="_mft('coaching:setting.bookableFutureTime.label')"
                            required
                            placeholder=""
                            name="bookable_days_in_future"
                        />
                    </div>
                </div>
            </div>

            <div class="py-4">
                <h3>{{ _mft('coaching:setting.timeBeforeFirstBookableSlot') }}</h3>
                <div class="grid-cols-2 gap-16 md:grid">
                    <div>
                        <p>{{ _mft('coaching:setting.timeBeforeFirstBookableSlot.description') }}</p>
                    </div>
                    <div class="flex items-center space-x-1">
                        <input-number
                            v-model:content="buffer_hours"
                            :label="_mft('coaching:setting.timeBeforeFirstBookableSlot')"
                            required
                            placeholder=""
                            name="buffer_hours"
                            class="w-full"
                        />
                        <button
                            class="myflow-basic-button"
                            @click="expertProfileSettings.buffer_hours = buffer_hours"
                        >
                            {{ _mft('shared:action.save') }}
                        </button>
                    </div>
                </div>
            </div>

            <div class="py-4">
                <h3>{{ _mft('coaching:setting.timeBetweenBookableSlots') }}</h3>
                <div class="grid-cols-2 gap-16 md:grid">
                    <div>
                        <p>{{ _mft('coaching:setting.timeBetweenBookableSlots.description') }}</p>
                    </div>
                    <div>
                        <input-select
                            v-model:content="expertProfileSettings.required_quarters_between_bookings"
                            :options="expertProfileOptions.quarters_between_bookings"
                            :label="_mft('coaching:setting.timeBetweenBookableSlots')"
                            required
                            placeholder=""
                            name="required_quarters_between_bookings"
                        />
                    </div>
                </div>
            </div>

            <div class="py-4">
                <h3>{{ _mft('coaching:setting.advanceNoticeForCancellation') }}</h3>
                <div class="grid-cols-2 gap-16 md:grid">
                    <div>
                        <p>{{ _mft('coaching:setting.advanceNoticeForCancellation.description') }}</p>
                    </div>
                    <div>
                        <input-select
                            v-model:content="expertProfileSettings.refund_max_hours_before_begins_at"
                            :options="expertProfileOptions.refunding_intervals"
                            :label="_mft('coaching:setting.advanceNoticeForCancellation.label')"
                            required
                            placeholder=""
                            name="refund_max_hours_before_begins_at"
                        />
                    </div>
                </div>
            </div>

            <div class="py-4">
                <h3>{{ _mft('coaching:setting.repayUnstartedSessions') }}</h3>
                <div class="grid-cols-2 gap-16 md:grid">
                    <div>
                        <p>{{ _mft('coaching:setting.repayUnstartedSessions.description') }}</p>
                    </div>
                    <div>
                        <toggle-switch
                            label="coaching:setting.repayUnstartedSessions.label"
                            v-model:content="expertProfileSettings.refund_unstarted"
                        />
                    </div>
                </div>
            </div>

            <div class="py-4">
                <h3>{{ _mft('coaching:setting.standardLocation') }}</h3>
                <div class="grid-cols-2 gap-16 md:grid">
                    <div>
                        <p>{{ _mft('coaching:setting.standardLocation.description') }}</p>
                        <p>{{ _mft('coaching:setting.standardLocation.description.justDefault')}}</p>
                    </div>
                    <div>
                        <Location
                            v-model:location="expertProfileSettings.default_meeting_location"
                            v-model:embedMeetings="expertProfileSettings.embed_meetings"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
