<template>
    <div
        v-if="value"
        class="grid gap-5 mb-3"
        :class="layout === 'half' ? 'grid-cols-1' : 'grid-cols-1 lg:grid-cols-2'"
    >
        <div>
            <input-media-bank-entry
                type="video"
                label="Välj en video från mediabiblioteket"
                :required="true"
                :content="value.media_bank_entry_id"
                @update:content="value.media_bank_entry_id = $event?.toString()"
            />
            <!-- <toggle-switch
                label="Inloggade användare kan göra anteckningar kopplade till videon"
                v-model:content="value.enable_notes"
            ></toggle-switch> -->
        </div>
        <media-bank-entries-display v-if="previewUrl" :data-url="previewUrl" />
    </div>
    <mf-spinner-medium v-else class="w-16 h-8"></mf-spinner-medium>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';

export default {
    props: {
        modelValue: {},
        layout: {
            type: String,
            default: 'full'
        }
    },
    emits: [
        'update:modelValue'
    ],
    data() {
        return {
            value: null
        };
    },
    computed: {
      previewUrl() {
        if (!this.value.media_bank_entry_id) { return; }

        return route('mediaBank.entries.show', this.value.media_bank_entry_id);
      }
    },
    async mounted() {
        this.captureModelValue();
    },
    watch: {
        modelValue() {
            if (JSON.stringify(this.value) === JSON.stringify(this.modelValue)) { return; }
            this.captureModelValue();
        },
        value: {
            handler() {
                this.$emit('update:modelValue', JSON.parse(JSON.stringify(this.value)));
            },
            deep: true
        }
    },
    methods: {
        captureModelValue() {
            const value = this.modelValue ? JSON.parse(JSON.stringify(this.modelValue)) : {};
            if (!value.uuid) { value.uuid = uuidv4(); }
            if (!value.media_bank_entry_id) { value.media_bank_entry_id = null; }
            // if (!value.enable_notes) { value.enable_notes = false; }
            this.value = value;
        }
    },
}
</script>
