<script setup>
import axios from 'axios'
import { ref, watch } from 'vue'

const emit = defineEmits(['update:content'])

const props = defineProps({
    label: String,
    callableUrl: String,
    initialValue: Boolean,
    reloadOnToggle: { type: Boolean, default: false }
})

const localValue = ref(props.initialValue)

watch(localValue, (newValue) => {
    axios.post(props.callableUrl, {
        value: newValue
    }).then((res) => {
        localValue.value = res.data.value
        emit('update:content', res.data.value)
        if(props.reloadOnToggle){
            location.reload()
        }
    }).catch((err) => {
        console.log(err)
    })
})

</script>
<template>
    <div>
        <toggle-switch
            :label="props.label"
            v-model:content="localValue"
        />
    </div>
</template>
