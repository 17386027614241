<script setup>

import {computed} from "vue";

const props = defineProps({
    status: {
        type: String,
        required: true,
        options: ['success', 'warning', 'danger', 'info']
    }
})

const indicatorClass = computed(() => {
    return {
        'bg-success': props.status === 'success',
        'bg-warning': props.status === 'warning',
        'bg-danger': props.status === 'danger',
        'bg-blue-500': props.status === 'info',
    }
})

</script>
<template>
    <div
        class="rounded-full overflow-hidden text-2xl"
        :class="indicatorClass"
    >
        <div v-if="status === 'success'" class="w-full h-full flex items-center justify-center relative text-white">
            <i class="fa-solid fa-check"></i>
            <div class="bg-white/30 success-animation absolute inset-0"></div>
        </div>
        <div v-if="status === 'warning'" class="w-full h-full flex items-center justify-center warning-animation rounded-full">
            <i class="fa-regular fa-clock"></i>
        </div>
        <div v-if="status === 'danger'" class="w-full h-full flex items-center justify-center rounded-full relative text-white">
            <i class="fa-solid fa-exclamation z-10"></i>
            <div class="danger-animation rounded-full border-4 border-danger-dark absolute inset-0"></div>
        </div>
    </div>
</template>
<style scoped>
.success-animation {
    animation: success 5s infinite;
    height: 200%;
    margin-top: -50%;
}
.warning-animation {
    animation: warning 5s infinite 1s;
    color: rgba(255, 255, 0, 1);
}
.danger-animation {
    animation: danger 5s infinite;
}

@keyframes success {
    0% {
        transform: translateX(-100%) rotate(-30deg) scaleY(2) scaleX(0.5);
    }
    40% {
        transform: translateX(200%) rotate(10deg) scaleY(2) scaleX(1);
    }
    100% {
        transform: translateX(200%) rotate(10deg) scaleY(2) scaleX(0.5);
    }
}

@keyframes warning {
    0% {
        color: rgba(255,255,0,1);
        transform: scale(1);
    }
    15% {
        color: rgba(255, 255, 255, 1);
        transform: scale(1.15);
    }
    30% {
        color: rgba(255,255,0,1);
        transform: scale(1);

    }
    100% {
        color: rgba(255,255,0,1);
        transform: scale(1);
    }
}

@keyframes danger {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    10% {
        transform: scale(0);
        opacity: 0;
    }
    20% {
        opacity: 0.5;
    }
    50% {
        transform: scale(1);
        opacity: 0;
    }
    100% {
        transform: scale(0);
        opacity: 0;
    }
}
</style>
