<template>
    <purchase-button-base
        v-if="isGiftCardPurchasable"
        :request-url="requestUrl"
        :request-data="requestDataGiftCode"
        @success="$document.locationAssign(route('coaching.my-bookings-overview', {state: 'confirmLatest'}))"
    >
        <template #default>
            <slot></slot> (med värdekod)
        </template>
    </purchase-button-base>

    <purchase-button-cash
        v-else
        :request-url="requestUrl"
        :request-data="requestData"
        :gift-card-balance="giftCardBalance"
    >
        <template #default>
            <slot></slot>
        </template>
    </purchase-button-cash>
</template>

<script>
export default {
    props: {
        id: { type: Number, required: true, },
        startDate: String,
        startTime: String,
        endTime: String,
        isGiftCardPurchasable: { type: Boolean, required: true },
        giftCardBalance: { type: Number, default: null },
    },
    data() {
        return {
            initiationUrl: null
        };
    },
    computed: {
        requestUrl() {
            return route("coaching.purchaseWithCashSek", this.id);
        },
        requestData() {
            return {
                booking: {
                    startDate: this.startDate,
                    startTime: this.startTime,
                    endTime: this.endTime
                }
            };
        },
        requestDataGiftCode() {
            return {
                payment_method: 'gift-card',
                ...this.requestData
            };
        }
    },
    methods: {
        async onSuccess({responseData}) {
            this.initiationUrl = responseData.initiation_url;
            this.$document.locationAssign(responseData.initiation_url);
        }
    },
};
</script>
