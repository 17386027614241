<template>
  <div>
    <ul class="justify-center hidden mb-4 space-x-6 lg:flex">
      <li
        v-for="(tab, i) in tabs"
        v-bind:key="i"
        v-on:click="setCurrentTab(tab)"
        class="px-4 py-2 mb-4 font-bold border-b-2 cursor-pointer select-none"
        :class="{'border-myflowMagenta text-myflowMagenta' : currentTab === tab.name }"
      >
       {{ tab.label }}
      </li>
    </ul>

    <signatures-list-crm
      v-if="currentTab === 'signatures' || showAll"
      :signatures="signatureRequests"
    ></signatures-list-crm>

    <deliveries-list-crm
      v-if="currentTab === 'deliveries' || showAll"
      :sms-deliveries="smsDeliveries"
      :email-deliveries="emailDeliveries"
    ></deliveries-list-crm>

    <lead-note-card
      v-if="currentTab === 'notes' || showAll"
      :lead="lead"
      :notes="notes"
    ></lead-note-card>

    <lead-action-card
      v-if="currentTab === 'actions' || showAll"
      :lead="lead"
      :actions="actions"
    ></lead-action-card>

    <lead-deal-card
      v-if="legacyDeals === false"
      :lead="lead"
      :deals="deals"
      :internal="internal"
    ></lead-deal-card>

    <lead-stakeholder-card
      v-if="currentTab === 'stakeholders' || showAll"
      :lead="lead"
      :stakeholders="stakeholders"
    ></lead-stakeholder-card>

  </div>
</template>
<script>
export default {
  props: {
    lead: Object,
    events: Object,
    notes: Object,
    actions: Object,
    deals: Object,
    done_actions: Object,
    stakeholders: Object,
    internal: Number,
    invite: String,
    signatureRequests: Object,
    legacyDeals: String,
    smsDeliveries: Object,
    emailDeliveries: Object,
    processes: Object,
  },
  data: function(){
    return {
      currentTab: '',
      tabs: [
        {
          label: _mft('crm:leadPage.tabTitle.showAll'),
          name: 'all'
        },
        {
          label: _mft('crm:leadPage.tabTitle.contracts'),
          name: 'signatures'
        },
        {
          label: _mft('crm:leadPage.tabTitle.automations'),
          name: 'deliveries'
        },
        {
          label: _mft('crm:leadPage.tabTitle.notes'),
          name: 'notes'
        },
        {
          label: _mft('crm:leadPage.tabTitle.actions'),
          name: 'actions'
        },
        {
          label: _mft('crm:leadPage.tabTitle.stakeholders'),
          name: 'stakeholders'
        },
      ]
    }
  },
  methods: {
    setCurrentTab(tab){
      this.currentTab = tab.name
    }
  },
  mounted: function(){
    this.currentTab = this.tabs[0].name
  },
  computed: {
    showAll: function(){
      if( this.currentTab === 'all' ){
        return true
      } else {
        return false
      }
    }
  }
}
</script>
