<script setup>
import { computed } from 'vue';

const props = defineProps({
    id: { type: Number, required: true, },
    isFree: { type: Boolean, required: true },
    couponCode: { type: String, default: undefined }
});

const routeData = computed(() => {
    const data = { eventId: props.id, _query: {} };
    if (props.couponCode) {
        data._query.coupon_code = props.couponCode;
    }
    return data;
})
</script>
<template>
    <purchase-button-cash
        v-if="!isFree"
        :request-url="route('home', routeData)"
    >
        <template #default><slot></slot></template>
    </purchase-button-cash>

    <purchase-button-base
        v-else
        :request-url="route('home', routeData)"
        @success="$document.locationAssign(route('home'))"
    >
        <template #default><slot></slot></template>
    </purchase-button-base>
</template>
