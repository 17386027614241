<script setup>
    import { computed, onMounted, ref } from 'vue'

    const categories = ref([]);

    const loadCategories = () => {
        categories.value = [];
        axios.get(route('support.article.indexData'))
            .then(response => {
                categories.value = response.data;
            })
            .catch(error => {
                console.log(error);
            });
    }

    onMounted(() => {
        loadCategories();
    });

</script>
<template>
    <div class="w-full">
        <div class="flex items-center justify-between mb-4">
            <h1>Support</h1>
        </div>
        <div>
            <a
                v-for="category in categories.data"
                :key="category.id"
                class="m-1 myflow-basic-button--secondary"
                :href="'#category'+category.id"
            >
                {{ category.title }}
            </a>
        </div>
            <div
                v-for="category in categories.data"
                :key="category.id"
                class="space-x-4 group"
            >
                <h3 class="mt-5 text-gray-900" :id="'category'+category.id">{{ category.title }}</h3>

                <ul role="list" class="divide-y divide-gray">
                    <li v-for="article in category.articles" :key="article.id" class="relative flex items-center py-4">
                        <div class="flex-auto min-w-0">
                            <div class="flex items-center gap-x-3">
                                <h2 class="min-w-0 text-sm font-semibold">
                                    <a :href="route('support.article.show', article.id)" class="flex gap-x-2">
                                        <span class="truncate">{{ article.title }}</span>
                                        <span class="absolute inset-0" />
                                    </a>
                                </h2>
                            </div>
                            <div class="mt-3 flex items-center gap-x-2.5 text-xs leading-5 text-gray-400">
                                <p class="truncate">
                                    Updated {{ article.updated_at }}
                                </p>
                            </div>
                        </div>
                        <div
                            v-if="article.is_onboarding_article"
                            class="flex-none px-2 py-1 text-xs font-medium text-green-600 rounded-full bg-green-400/10 ring-1 ring-inset"
                        >
                            Kom igång
                        </div>
                        <ChevronRightIcon class="flex-none w-5 h-5 text-gray-400" aria-hidden="true" />
                    </li>
                </ul>
            </div>
    </div>
</template>






