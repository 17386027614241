<template>
  <div class="mb-4">
    <input-label
      :label="label"
      :required="required"
    />
    <div
      class="mt-1 relative rounded-md shadow-sm"
    >
      <input
        type="time"
        class="block w-full pr-10 mt-1 p-2 border rounded-md"
        :class="inputClass"
        :placeholder="placeholder"
        :value="content"
        @input="$emit('update:content', $event.target.value)"
        :required="required"
        :name="name"
      />
    </div>
    <input-error v-if="error" :error="error" />
  </div>
</template>
<script>
export default {
  setup() {

  },
  data: function(){
    return {
      inputClass: ''
    }
  },
  props: {
    content: String,
    error: String,
    label: String,
    placeholder: String,
    required: Boolean,
    name: String
  },
  watch: {
    error: function(error){
      let classes = [];
      if( error !== '' ){
        classes.push('border-red-700')
      } else {
        classes.push('border-gray')
      }
      this.inputClass = classes.join(' ')
    }
  }
}
</script>

