<script setup>

import {ref, provide, watch, computed, useSlots} from "vue";
import { DotsVerticalIcon, XIcon } from "@heroicons/vue/outline"

const props = defineProps({
    styleType: {
        type: String,
        required: false,
        default: 'secondary'
    },
    size: {
        type: String,
        required: false,
        default: 'md'
    },
    alignment: {
        type: String,
        required: false,
        default: 'left',
        options: ['left', 'center', 'right']
    },
    optionsClass: {
        type: String,
        required: false,
        default: ''
    },
    label: {
        type: String,
        required: false,
        default: ''
    },
    cancelOnOutsideClick: {
        type: Boolean,
        required: false,
        default: true
    }
})

const slots = useSlots()
const showMoreOptions = ref(false)
const iconSizes = {
    xs: 'h-3 w-3',
    sm: 'h-4 w-4',
    md: 'h-5 w-5',
    lg: 'h-6 w-6',
}

const close = () => {
    showMoreOptions.value = false
}

const optionsVisible = ref(false)

const showingMoreOptions = ref(false)
const stateClass = computed(() => {
    return showingMoreOptions.value ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-2 scale-95'
})

const transformOrigin = computed(() => {
    return {
        left: 'origin-top-left',
        center: 'origin-top',
        right: 'origin-top-right',
    }[props.alignment]
})

watch(showMoreOptions, (value) => {
    if (value) {
        optionsVisible.value = true
        setTimeout(function() {
            showingMoreOptions.value = true
        }, 1)
    } else {
        showingMoreOptions.value = false
        setTimeout(function() {
            optionsVisible.value = false
        }, 350)
    }

})

provide('size', props.size)
provide('close', close)

</script>
<template>
    <div>
        <div class="relative">
            <button-base
                :style-type="styleType"
                :size="size"
                @click="showMoreOptions = !showMoreOptions"
            >
                <span class="flex items-center space-x-1">
                    <span class="inline-block relative" :class="iconSizes[size]">
                        <DotsVerticalIcon
                            v-if="!slots.icon"
                            class="absolute inset-0 transition-all duration-300"
                            :class="[iconSizes[size], ! showMoreOptions ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-1/2']"
                        />

                        <span
                            v-if="slots.icon"
                            class="absolute inset-0 transition-all duration-300"
                            :class="! showMoreOptions ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-1/2'"
                        >
                            <slot name="icon"></slot>
                        </span>

                        <XIcon
                            class="absolute inset-0 transition-all duration-300"
                            :class="[iconSizes[size], showMoreOptions ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-1/2']"
                        />
                    </span>
                    <span v-if="label !== ''">
                        {{ label }}
                    </span>
                </span>
            </button-base>
            <div
                v-if="optionsVisible"
                class="absolute top-full mt-2 max-w-lg z-more-options-menu transition-all"
                :class="[{
                    'left-0': alignment === 'left',
                    'right-0': alignment === 'right',
                    'left-1/2 -translate-x-1/2': alignment === 'center'
                }, transformOrigin, optionsClass, stateClass]"
            >
                <ul>
                   <slot></slot>
                </ul>
            </div>
        </div>
        <teleport to="body">
            <div
                v-if="showMoreOptions && cancelOnOutsideClick"
                class="fixed inset-0 z-more-options-backdrop"
                @click="showMoreOptions = false"
            ></div>
        </teleport>
    </div>
</template>
