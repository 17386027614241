<script setup>

import {onMounted, ref} from "vue";

const courses = ref([]);
const coursesLoaded = ref(false);

const loadCourses = async () => {
    const res = await axios.get(route('api.course.customerarea.index'));
    if (res.data.success)    {
        courses.value = res.data.courses
        coursesLoaded.value = true
    }
};

onMounted(() => {
    loadCourses();
});

</script>

<template>
    <div>
        <div
            v-if="coursesLoaded"
            class="pb-8"
        >
            <p
                v-if="courses.length === 0"
                class="text-center py-4"
            >
                {{ _mft('course:noLicensedCoursesToShow') }}
            </p>

            <list-basic v-else>
                <list-basic-item
                    v-for="(course, index) in courses"
                >
                    <template #title>
                        <a
                            :href="route('course.customerarea.show', course.id)"
                            class="mf-underline text-inherit"
                        >
                            {{ course.title }}
                        </a>
                    </template>
                    <template #actions>
                        <a
                            :href="route('course.customerarea.show', course.id)"
                            class="mf-underline text-inherit"
                        >
                            <button-primary
                                size="sm"
                            >
                                {{ _mft('shared:action.show') }}
                            </button-primary>
                        </a>
                    </template>
                </list-basic-item>
            </list-basic>
        </div>
        <mf-spinner-medium v-else class="w-24 h-12" />
    </div>
</template>

<style scoped>

</style>
