<template>
    <div
        class="p-3 transition-all rounded-lg bg-white"
        :class="[ notification.read && mode === 'unread' ? 'opacity-50' : 'shadow-lg']"
        v-if="filter === null || filter?.id === notification.category_id"
    >
        <div class="flex justify-between mb-2 relative">
            <div
                v-if="notification.time_sensitive && !notification.read"
                class="absolute -top-5 -left-5 p-1 bg-wlPrimary text-wlPrimaryContrast rounded-full"
            >
                <svg class="w-3 h-3" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
                  <path d="M6.002 14a2 2 0 1 1 4 0 2 2 0 0 1-4 0zm.195-12.01a1.81 1.81 0 1 1 3.602 0l-.701 7.015a1.105 1.105 0 0 1-2.2 0l-.7-7.015z"/>
                </svg>
            </div>
            <span
                class="w-full lg:w-auto text-gray-900 pr-4 cursor-pointer"
                @click="markReadAndNavigate"
            >
                {{notification.content}}
            </span>
            <a
                v-on:click="toggleRead(notification.id)"
                class="cursor-pointer"
                :class="[ notification.read ? 'text-gray-400' :  'text-gray-800 hover:text-gray-500' ]"
            >
                <i class="far fa-eye"></i>
            </a>
        </div>
        <div class="flex items-center justify-between">
            <div class="cursor-default divide-x divide-gray-400">
                <small class="text-gray-600 font-normal">
                    {{notification.created_at}}
                </small>
                <small class="px-1 py-px ml-1">{{ notification.category.name }}</small>
            </div>
            <button
                @click="markReadAndNavigate"
                class="hidden lg:inline-block whitespace-nowrap mf-link"
            >
                {{notification.cta}}
            </button>
        </div>
    </div>
</template>
<script>

export default {

    props: {
        notification: Object,
        mode: String,
        filter: Object
    },

    methods: {
        markReadAndNavigate(){
            this.$emit( 'markReadAndNavigate', this.notification );
        },
        toggleRead(id){
            this.$emit( 'toggleRead', id )
            this.notification.read = !this.notification.read
        }
    }

}

</script>
