<template>
    <div
        class="p-4 pt-3 mb-10 overflow-hidden bg-white shadow-xl rounded-wl md:p-6 lg:p-8 md:pt-5 lg:pt-6"
    >
        <div v-on:click="collapseExpand" class="float-right text-myflowPurple-600 cursor-pointer">
            <button-base styleType="transparent" size="sm">
                <template #icon>
                    <ChevronDownIcon v-if="collapsed" class="w-4 h-4" />
                    <MinusSmIcon v-if="!collapsed" class="w-4 h-4" />
                </template>

            </button-base>
        </div>

        <slot name="header"></slot>

        <hr class="mt-2 mb-2">

        <div
            class="overflow-hidden"
            :class="collapsed ? '' : hiddenClass"
        >
            <slot name="preview"></slot>
        </div>

        <div
            class="overflow-hidden"
            :class="!collapsed ? '' : hiddenClass"
        >
            <slot name="body"></slot>
        </div>

    </div>
</template>
<script>
import {ChevronDownIcon, MinusSmIcon} from "@heroicons/vue/solid";

export default {
    components: {MinusSmIcon, ChevronDownIcon},
  data() {
    return {
      collapsed: true,
    };
  },
  async mounted() {
  },
  methods: {
    collapseExpand() {
      if(this.collapsed === true) {
        this.collapsed = false;
      } else {
        this.collapsed = true;
      }
    },
  },
  computed: {
    hiddenClass() {
      return 'max-h-0 focus-within:max-h-full';
    },
  }
}
</script>
