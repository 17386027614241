<script setup>
    import {computed, nextTick, onMounted, reactive, ref, watch} from 'vue'
    import BlockEditor from "./blockEditor.vue";
    import { useNotificationStore } from '@/stores/NotificationStore.js'
    import BlockSelector from "./blockSelector.vue";
    import { v4 as uuid } from 'uuid'
    import StyleEditor from "./styleEditor.vue";
    import PageBuilderParts from "./PageBuilderParts.vue";
    import RevisionsManager from "./RevisionsManager.vue";
    import SlideoutWithSlots from "../elements/slideoutWithSlots.vue";
    import IconPlus from "../icons/iconPlus.vue";
    import IconTrashcan from "../icons/iconTrashcan.vue";
    import IconSettings from "../icons/iconSettings.vue";
    import FrostedBar from "../layout/pageHeadings/frostedBar.vue";
    import IconChevron from "../icons/iconChevron.vue";
    import ButtonMoreOptions from "../buttons/buttonMoreOptions.vue";
    import ButtonMoreOptionsOption from "../buttons/buttonMoreOptionsOption.vue";
    import { current } from 'tailwindcss/colors';
    import CssEditor from './CssEditor.vue';
    import InlinePreview from "./_inlinePreview.vue";

    const notificationStore = useNotificationStore()

    const props = defineProps({
        containerRef: {
            type: String,
            required: true
        },
        defaultRowStyles: {
            type: Object,
            required: true,
        },
        defaultColStyles: {
            type: Object,
            required: true,
        },
        loadContentUrl: {
            type: String,
            required: true
        },
        updateContentUrl: {
            type: String,
            required: true
        },
        blockTypes: {
            type: Array,
            required: false,
            default: () => null
        },
        blockGroups: {
            type: Array,
            required: false,
            default: () => null
        },
        pageTitle: {
            type: String,
            required: false,
            default: _mft('pagebuilder:defaultTitle')
        },
        defaultFont: {
            type: String,
            required: false,
            default: 'Open Sans'
        },
        inlinePreviewUrl: {
            type: String,
            required: false,
            default: null,
        }
    })

    const blockEditorContent = ref(null)
    const pickedUpBlockSource = ref(null)
    const pickedUpBlockUuid = ref(null)
    const editingBlock = ref(null)
    const editingStyle = ref(null)
    const editingType = ref(null)
    const editingUuid = ref(null)
    const addingBlockAt = ref({row: null, col: null})
    const saving = ref(false)
    const savingState = ref('')
    const pageTitle = ref(props.pageTitle)
    const stateIsDirty = ref(false)
    const showPageSettings = ref(false)
    const showRevisions = ref(false)
    const devmodeEnabled = ref(false)
    const replaceColors = ref(false)
    const currentColors = ref({})
    const stagedForChange = ref(null)
    const newColor = ref(null)
    const showInlinePreview = ref(false)
    const addingFromSavedPageBuilderPartType = reactive({
        type: '',
        row: null,
        column: null,
    })

    const design = reactive({
        layout: {
            customcss: '',
            rows: []
        }
    })

    const loadPage = async () => {
        const res = await axios.get(props.loadContentUrl)

        blockEditorContent.value = res.data.blockEditorContent
        design.layout = res.data.design.layout
        design.pageSettings = res.data.design.pageSettings
        design.pageSettings = setDefaultPageSettings(design.pageSettings)
        pageTitle.value = res.data.page.title
        await nextTick()
        stateIsDirty.value = false

        findHexCodesWithKeys(design.layout)
        if (design.pageSettings.font) {
            setFont('headings', design.pageSettings.font.headings.family)
            setFont('body', design.pageSettings.font.body.family)
        }
    }

    const setDefaultPageSettings = (pageSettings) => {
        if (! pageSettings) {
            pageSettings = {
                transitions: {
                    enableTransitions: true,
                }
            }
        }
        return pageSettings;
    }

    const updatePage = async ()  => {
        if (saving.value === true) {return;}
        savingState.value = 'waiting'

        saving.value = true
        try {
            const res = await axios.post(props.updateContentUrl, {
                blockEditorContent: blockEditorContent.value,
                design: design
            })
            if (res.data.success) {
                savingState.value = 'success'
            } else {
                savingState.value = 'error'
            }

            stateIsDirty.value = false
        } catch (e) {
            savingState.value = 'error'
        }
        saving.value = false

    }

    const getPersistentClass = (length) => {
        let result = 'mfpbr-persistent-';
        const characters = 'abcdefghijklmnopqrstuvwxyz123456789';
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
            counter += 1;
        }
        return result;
    }

    const addRow = (row) => {
        if (row === null) {
            design.layout.rows.push({
                uuid: uuid(),
                persistentClass: getPersistentClass(8),
                styles: props.defaultRowStyles,
                columns: []
            })

            addCol(design.layout.rows.length - 1)
        } else {
            design.layout.rows.push(row)
        }
    }

    const removeRow = (ri) => {
        if (design.layout.rows[ri].columns.length > 0) {
            notificationStore.addNotification({
                type: 'warning',
                message: _mft('pagebuilder:row.warning.removeColumnsFirst'),
                dismissAfter: 3500,
            })
            return
        }
        design.layout.rows.splice(ri, 1)
    }

    const addCol = (ri, column = null) => {
        design.layout.rows[ri].styles.desktop.layout = null
        if (column === null) {
            design.layout.rows[ri].columns.push({
                uuid: uuid(),
                styles: props.defaultColStyles,
                blocks: []
            })
        } else {
            design.layout.rows[ri].columns.push(column)
        }
    }

    const removeCol = (ri, ci) => {
        if (design.layout.rows[ri].columns[ci].blocks.length > 0) {
            notificationStore.addNotification({
                type: 'warning',
                message: _mft('pagebuilder:column.warning.removeBlocksFirst'),
                dismissAfter: 3500,
            })
            return
        }
        design.layout.rows[ri].styles.desktop.layout = null
        design.layout.rows[ri].columns.splice(ci, 1)
    }

    const addBlock = (ri, ci) => {
        if (pickedUpBlockUuid.value !== null) {
            return
        }
        addingBlockAt.value = {row: ri, col: ci}
    }

    const initAddingColumnFromPageBuilderPart = (ri) => {
        addingFromSavedPageBuilderPartType.type = 'column';
        addingFromSavedPageBuilderPartType.row = ri;
    }

    const resetAddingFromSavedPageBuilderPartType = () => {
        addingFromSavedPageBuilderPartType.type = ''
        addingFromSavedPageBuilderPartType.row = null
        addingFromSavedPageBuilderPartType.column = null
    }

    const addFromPageBuilderPart = (part) => {

        if (addingFromSavedPageBuilderPartType.type === 'row') {
            for (const [key, value] of Object.entries(part.content)) {
                blockEditorContent.value.push(value)
            }
            addRow(part.design)
        }

        if (addingFromSavedPageBuilderPartType.type === 'column') {
            for (const [key, value] of Object.entries(part.content)) {
                blockEditorContent.value.push(value)
            }
            addCol(addingFromSavedPageBuilderPartType.row, part.design)
        }

        resetAddingFromSavedPageBuilderPartType()
    }

    const insertBlock = (block) => {
        if (!block.uuid) {
            block.uuid = uuid();
        }
        blockEditorContent.value.push(block)
        design.layout.rows[addingBlockAt.value.row].columns[addingBlockAt.value.col].blocks.push(block.uuid)
        addingBlockAt.value = {row: null, col: null}
        editingBlock.value = block
    }

    const pickUpBlock = (pickupData) => {
        pickedUpBlockUuid.value = pickupData.blockUuid
        pickedUpBlockSource.value = {row: pickupData.rowIndex, col: pickupData.colIndex}
    }

    const cloneBlock = (cloneData) => {
        const blockClone = JSON.parse(JSON.stringify(cloneData.block))
        blockClone.uuid = uuid()
        blockEditorContent.value.push(blockClone)
        design.layout.rows[cloneData.rowIndex].columns[cloneData.colIndex].blocks.splice(design.layout.rows[cloneData.rowIndex].columns[cloneData.colIndex].blocks.indexOf(cloneData.block.uuid) + 1, 0, blockClone.uuid)
    }

    const putBackPickedUpBlock = () => {
        pickedUpBlockUuid.value = null
        pickedUpBlockSource.value = null
    }

    const putBlockDown = (ri, ci, insertAfterBlockUuid) => {
        if (pickedUpBlockSource.value.row !== null && pickedUpBlockSource.value.col !== null) {
            design.layout.rows[pickedUpBlockSource.value.row].columns[pickedUpBlockSource.value.col].blocks = design.layout.rows[pickedUpBlockSource.value.row].columns[pickedUpBlockSource.value.col].blocks.filter(b => b !== pickedUpBlockUuid.value)
        }
        design.layout.rows[ri].columns[ci].blocks.splice(insertAfterBlockUuid ? design.layout.rows[ri].columns[ci].blocks.indexOf(insertAfterBlockUuid) + 1 : 0, 0, pickedUpBlockUuid.value)
        pickedUpBlockUuid.value = null
    }

    const deleteBlock = (blockUuid) => {
        blockEditorContent.value = blockEditorContent.value.filter(b => b.uuid !== blockUuid)
        design.layout.rows.forEach(row => {
            row.columns.forEach(col => {
                col.blocks = col.blocks.filter(b => b !== blockUuid)
            })
        })
    }
    const editBlock = (blockUuid) => {
        editingBlock.value = blockEditorContent.value.find(b => b.uuid === blockUuid)
    }

    const endAndSaveEditing = () => {
        editingBlock.value = null
        updatePage()
    }

    const endEditing = () => {
        editingBlock.value = null
    }

    const editStyle = (styles, type, uuid) => {
        editingUuid.value = uuid
        editingType.value = type
        editingStyle.value = styles
    }

    const updateStyle = (styles) => {
        if (editingType.value === 'row') {
            design.layout.rows.find(r => r.uuid === editingUuid.value).styles = styles
        }
        if (editingType.value === 'column') {
            const allCols = design.layout.rows.reduce((acc, row) => {
                return acc.concat(row.columns)
            }, [])

            // set style of column with uuid
            allCols.find(c => c.uuid === editingUuid.value).styles = styles
        }
    }

    const stopEditing = () => {
        editingUuid.value = null
        editingType.value = null
        editingStyle.value = null
    }

    const shiftRowUp = (ri) => {
        if (ri === 0) {
            return
        }
        const row = design.layout.rows.splice(ri, 1)[0]
        design.layout.rows.splice(ri - 1, 0, row)
    }

    const shiftRowDown = (ri) => {
        if (ri === design.layout.rows.length - 1) {
            return
        }
        const row = design.layout.rows.splice(ri, 1)[0]
        design.layout.rows.splice(ri + 1, 0, row)
    }

    const savePart = reactive({
        title: '',
        type: '',
        content: [],
        design: {}
    })

    const resetSavePart = () => {
        savePart.title = ''
        savePart.type = ''
        savePart.content = []
        savePart.design = {}
    }

    const saveRow = async (row) => {
        savePart.type = 'row'
        savePart.design = row

        row.columns.forEach(col => {
            col.blocks.forEach(blockUuid => {
                savePart.content.push(blockEditorContent.value.find(b => b.uuid === blockUuid))
            })
        })
    }

    const saveCol = (column) => {
        savePart.type = 'column'
        savePart.design = column

        column.blocks.forEach(blockUuid => {
            savePart.content.push(blockEditorContent.value.find(b => b.uuid === blockUuid))
        })
    }

    const saveBlock = (block) => {
        savePart.type = 'block'
        savePart.content = block
    }

    const savePageBuilderPart = async () => {
        const nonNullContentItems = savePart.content.filter(item => item !== null)

        const res = await axios.post(route('pages.pagebuilder.savePart'), {
            title: savePart.title,
            type: savePart.type,
            design: savePart.design,
            content: nonNullContentItems
        });

        resetSavePart()

        notificationStore.addNotification({
            type: 'success',
            message: _mft('shared:save.success'),
            dismissAfter: 2500,
        })
    }

    const selectForChange = (key, color) => {
        stagedForChange.value = { key, color };

    };

    const updateColors = () => {
        if (!stagedForChange.value || !newColor.value) {
            return;
        }
        replaceColorsInObject(design.layout, stagedForChange.value.color, newColor.value);
        stagedForChange.value = null;
        newColor.value = null;
        findHexCodesWithKeys(design.layout);
    };

    const replaceColorsInObject = (obj, colorToReplace, newColorValue) => {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (typeof obj[key] === 'object' && obj[key] !== null) {
                    replaceColorsInObject(obj[key], colorToReplace, newColorValue);
                } else if (typeof obj[key] === 'string') {
                    if (obj[key] === colorToReplace) {
                        obj[key] = newColorValue;
                    }
                }
            }
        }
    }

    const findHexCodesWithKeys = (obj) => {
        let hexCodes = {};

        function searchForHexCodes(obj, path = '') {
            for (let key in obj) {
                if (typeof obj[key] === 'object' && obj[key] !== null) {
                    searchForHexCodes(obj[key], path + key + '.');
                } else if (typeof obj[key] === 'string') {
                    let hexMatch = obj[key].match(/#([0-9a-fA-F]{6}|[0-9a-fA-F]{3})\b|rgba?\(([^)]+)\)/);
                    if (hexMatch) {
                        const keyEnd = key.split('.').pop();

                        if (!hexCodes[keyEnd]) {
                            hexCodes[keyEnd] = [];
                        }

                        if (!hexCodes[keyEnd].includes(hexMatch[0])) {
                            hexCodes[keyEnd].push(hexMatch[0]);
                        }
                    }
                }
            }
        }

        searchForHexCodes(obj);
        currentColors.value = hexCodes;
    };

    const restoreRevision = (revision) => {
        blockEditorContent.value = revision.content
        design.layout = revision.design.layout
        design.pageSettings = revision.design.pageSettings
        design.pageSettings = setDefaultPageSettings(design.pageSettings)
        findHexCodesWithKeys(design.layout)
    }

    const setFont = (target, font) => {
        if (font) {
            if (! design.pageSettings.font) {
                design.pageSettings.font = {
                    headings: {
                        family: props.defaultFont
                    },
                    body: {
                        family: props.defaultFont
                    }
                }
            }
            design.pageSettings.font[target].family = font
            setDocumentFonts(design.pageSettings.font.headings.family, design.pageSettings.font.body.family)
        }
    }

    const setDocumentFonts = (headings, body) => {
        WebFont.load({
            google: {
                families: [headings + ':400,600,700', body + ':400,600,700']
            }
        });

        document.getElementById('pagebuilder-font').innerHTML = `
            .block-editor-blocks .styleable-block *:not(i[aria-hidden="true"]):not(h1, h2, h3, h4, h5, h6),
            .font-selector.body .font-selector-preview {
                font-family: ${body}, Open Sans;
            }

            .block-editor-blocks .styleable-block h1,
            .block-editor-blocks .styleable-block h2,
            .block-editor-blocks .styleable-block h3,
            .block-editor-blocks .styleable-block h4,
            .block-editor-blocks .styleable-block h5,
            .block-editor-blocks .styleable-block h6,
            .font-selector.headings .font-selector-preview {
                font-family: ${headings}, Open Sans;
            }
        `
    };

    const defaultStyles = computed(() => {
        if (editingType.value === 'row') {
            return props.defaultRowStyles
        }
        if (editingType.value === 'column') {
            return props.defaultColStyles
        }
    })

    const showSavePartModal = computed(() => {
        return savePart.type !== ''
    })

    watch(() => blockEditorContent, () => {
        stateIsDirty.value = true
    }, {deep: true})

    watch(() => design, () => {
        stateIsDirty.value = true;
        findHexCodesWithKeys(design.layout)
    }, {deep: true})

    watch(() => showPageSettings.value, () => {
        if (showPageSettings.value === false) {
            updatePage()
        }
    })

    onMounted(() => {
        window.myflow.pagebuilderActive = true
        loadPage()

        window.onbeforeunload = function() {
            if (stateIsDirty.value === true) {
                return _mft('pagebuilder:warning.unsavedChanges')
            }
        }
    })

</script>

<template>
    <div class="page-builder-editor block-editor-blocks">
        <div
            v-if="pickedUpBlockUuid"
            class="fixed text-xs transition-all top-2 right-2 z-modal"
        >
            <div
                :class="pickedUpBlockUuid ? 'scale-100 opacity-100 delay-75' : 'scale-0 opacity-0'"
                class="p-2 transition-all bg-white border shadow-md rounded-wl"
            >
                <button-danger
                    size="sm"
                    @click="putBackPickedUpBlock"
                >{{ _mft('pagebuilder:block.cancelMove') }}</button-danger>
            </div>
        </div>

        <BlockEditor
            v-if="editingBlock"
            @done="endAndSaveEditing"
            @close="endEditing"
            :block="editingBlock"
        >
            <template #sloteditortools="{ block }">
                <slot
                    name="sloteditor"
                    :block="block"
                ></slot>
            </template>
        </BlockEditor>


        <Teleport to="#teleport-target-sticky-before-main" v-if="blockEditorContent">
            <frosted-bar class="transition-transform" :class="pickedUpBlockUuid ? '-translate-y-24' : ''">
                <div class="items-center justify-between space-x-4 md:flex">
                    <h1 class="md:whitespace-nowrap overflow-hidden overflow-ellipsis">{{ pageTitle }}</h1>
                    <div class="flex items-center justify-end space-x-2">
                        <slot name="toolbar"></slot>
                        <button-secondary
                            v-if="inlinePreviewUrl"
                            @click="showInlinePreview = true"
                        >
                            {{ _mft('shared:preview') }}
                        </button-secondary>
                        <button-more-options
                            alignment="right"
                            :cancelOnOutsideClick="false"
                        >
                            <button-more-options-option
                                class="text-xs whitespace-nowrap"
                                @click="showPageSettings = true"
                                :close-on-click="true"
                            >
                                {{ _mft('pagebuilder:pageSettings.showSettingsLabel') }}
                            </button-more-options-option>

                            <button-more-options-option
                                class="text-xs whitespace-nowrap"
                                @click="showRevisions = true"
                                :close-on-click="true"
                            >
                                {{ _mft('pagebuilder:showRevisions') }}
                            </button-more-options-option>

                            <button-more-options-option
                                class="text-xs whitespace-nowrap"
                                @click="replaceColors = true"
                                :close-on-click="true"
                            >
                                {{ _mft('pagebuilder:colorReplacer.button') }}
                            </button-more-options-option>

                            <button-more-options-option
                                v-if="devmodeEnabled === false"
                                class="text-xs whitespace-nowrap"
                                @click="devmodeEnabled = true"
                                :close-on-click="true"
                            >
                                {{ _mft('pagebuilder:cssEditor.enableDevmode') }}
                            </button-more-options-option>

                            <button-more-options-option
                                v-if="devmodeEnabled === true"
                                class="text-xs whitespace-nowrap"
                                @click="devmodeEnabled = false"
                                :close-on-click="true"
                            >
                                {{ _mft('pagebuilder:cssEditor.disableDevmode') }}
                            </button-more-options-option>

                            <slot name="more-options"></slot>
                        </button-more-options>
                        <button-success
                            :disabled="saving"
                            @click="updatePage"
                            :state="savingState"
                        >{{ _mft('shared:action.save') }}</button-success>
                    </div>
                </div>
            </frosted-bar>
        </Teleport>
        <div>
            <div
                v-for="(row, ri) in design.layout.rows"
                :key="ri"
                class="relative flex-row-reverse border-2 border-blue-300 rounded-r-md rounded-l-wl lg:flex"
            >
                <span
                    v-if="devmodeEnabled"
                    class="absolute -top-3 rounded-md bg-white/70 p-1 right-0.5 text-xxs z-tooltip"
                >
                    <span class="select-all">.mfpbr.mfpbr-{{ row.uuid }}</span>
                    <span v-if="row.persistentClass">, <br> <span class="select-all">.{{ row.persistentClass }}</span></span>
                </span>
                <div class="flex items-center justify-end p-1 mb-1 space-x-1 bg-blue-300 lg:space-x-0 lg:space-y-2 lg:mb-0 lg:flex-col lg:justify-start">
                    <button @click="shiftRowUp(ri)">
                        <icon-chevron
                            class="w-4 h-4 rotate-180"
                            :class="ri === 0 ? 'fill-gray-300 cursor-not-allowed' : 'fill-white'"
                        />
                    </button>
                    <button-danger
                        size="sm"
                        @click="removeRow(ri)"
                    >
                        <icon-trashcan class="w-4 h-4 text-danger" />
                    </button-danger>
                    <button-base
                        size="sm"
                        @click="editStyle(row.styles, 'row', row.uuid)"
                    >
                        <icon-settings class="w-4 h-4" />
                    </button-base>

                    <button-more-options
                        size="sm"
                        alignment="right"
                    >
                        <button-more-options-option
                            class="text-xs whitespace-nowrap"

                            @click="saveRow(row)"
                        >
                            Save row
                        </button-more-options-option>
                    </button-more-options>

                    <button-more-options
                        size="sm"
                        alignment="right"
                        v-if="row.columns.length < 5"
                    >
                        <template #icon>
                            <icon-plus class="w-4 h-4 text-success-dark" />
                        </template>
                        <button-more-options-option
                            @click="addCol(ri)"
                            class="whitespace-nowrap"
                        >
                            {{ _mft('pagebuilder:addColumn.empty') }}
                        </button-more-options-option>

                        <button-more-options-option
                            @click="initAddingColumnFromPageBuilderPart(ri)"
                            class="whitespace-nowrap"
                        >
                            {{ _mft('pagebuilder:addColumn.saved') }}
                        </button-more-options-option>
                    </button-more-options>

                    <button @click="shiftRowDown(ri)">
                        <icon-chevron
                            class="w-4 h-4"
                            :class="ri === design.layout.rows.length - 1 ? 'fill-gray-300 cursor-not-allowed' : 'fill-white'"
                        />
                    </button>
                </div>
                <page-builder-row
                    :styles="row.styles"
                    :container-ref="containerRef"
                    :row-uuid="row.uuid"
                    :persistent-class="row.persistentClass"
                    class="mfpb-row"
                >
                    <div
                        class="relative grid w-full gap-1"
                        :class="['grid-cols-1', row.styles.desktop.layout ? 'md:grid-cols-' + row.styles.desktop.layout?.split('|')[0] : ' md:grid-cols-' + row.columns.length]"
                    >
                        <div
                            v-for="(column, ci) in row.columns"
                            :key="ci"
                            class="relative transition-all border-2 border-slate-200 rounded-wl group mfpbc hover:border-slate-300"
                            :class="[row.styles.desktop.layout ? 'col-span-' + row.styles.desktop.layout?.split('|')[1].split('+')[ci] : 'col-span-1', 'mfpbc-' + column?.uuid]"
                        >
                            <span
                                v-if="devmodeEnabled"
                                class="absolute top-2 select-all rounded-md bg-white/70 p-1 -left-0.5 text-xxs z-tooltip"
                            >
                                .mfpbc.mfpbc-{{ column.uuid }}
                            </span>
                            <div
                                v-if="!pickedUpBlockUuid"
                                class="absolute top-0 z-50 flex items-center justify-end p-1 mb-0 space-x-1 transition-all transform -translate-y-1/2 rounded-lg left-4 bg-slate-100 group-hover:bg-slate-200"
                            >
                                <div class="flex items-center space-x-1">
                                    <button-more-options
                                        size="sm"
                                        alignment="left"
                                        :cancel-on-outside-click="false"
                                    >
                                        <button-more-options-option
                                            class="text-xs whitespace-nowrap"
                                            @click="saveCol(column)"
                                        >
                                            Save column
                                        </button-more-options-option>
                                    </button-more-options>
                                    <button-base
                                        size="sm"
                                        @click="editStyle(column.styles, 'column', column.uuid)"
                                    >
                                        <icon-settings class="w-4 h-4" />
                                    </button-base>
                                    <button-danger
                                        size="sm"
                                        @click="removeCol(ri, ci)"
                                    >
                                        <icon-trashcan class="w-4 h-4 text-danger" />
                                    </button-danger>
                                </div>
                            </div>

                            <div class="mfpb-col-container">
                                <div class="relative">
                                    <button v-if="pickedUpBlockUuid" class="absolute top-0 z-20 py-1 text-xs text-black transform -translate-y-1/2 bg-gray-100 border-2 border-gray-300 right-4 left-4 rounded-wl hover:border-gray-400" @click="putBlockDown(ri, ci, null)">{{ _mft('pagebuilder:block.moveHere') }}</button>
                                </div>
                                <page-builder-column
                                    :styles="column.styles"
                                    class="overflow-hidden rounded-wl"
                                    :class="pickedUpBlockUuid ? '!pb-6' : ''"
                                >
                                    <div
                                        v-for="(blockUuid, bi) in column.blocks"
                                        :key="bi"
                                        :data-block-uuid="blockUuid"
                                        class="relative"
                                    >
                                        <span
                                            v-if="devmodeEnabled"
                                            class="absolute top-8 select-all rounded-md bg-white/70 p-1 right-0.5 text-xxs z-tooltip"
                                        >
                                            .mfpbb.mfpbb-{{ blockUuid }}
                                        </span>
                                        <page-builder-block-edit
                                            :rowIndex="ri"
                                            :colIndex="ci"
                                            :blockIndex="bi"
                                            :container-ref="containerRef"
                                            :block="blockEditorContent.find(b => b.uuid === blockUuid)"
                                            :isPickedUp="blockUuid === pickedUpBlockUuid"
                                            @chosenForEditing="editBlock"
                                            @deleted="deleteBlock"
                                            @pickedUp="pickUpBlock"
                                            @clone="cloneBlock"
                                            @save="saveBlock"
                                        />
                                        <div class="relative">
                                            <button v-if="pickedUpBlockUuid" class="absolute top-0 z-20 py-1 text-xs text-black transform -translate-y-1/2 bg-gray-100 border-2 border-gray-300 right-4 left-4 rounded-wl hover:border-gray-400" @click="putBlockDown(ri, ci, blockUuid)">{{ _mft('pagebuilder:block.moveHere') }}</button>
                                        </div>
                                    </div>
                                </page-builder-column>
                                <div class="flex justify-end px-5 py-1">
                                    <button-base
                                        :disabled="!!pickedUpBlockUuid"
                                        size="sm"
                                        @click="addBlock(ri, ci)"
                                    >
                                        <template #icon>
                                            <icon-plus class="w-4 h-4 -ml-1" />
                                        </template>
                                        {{ _mft('pagebuilder:column.addContent') }}
                                    </button-base>
                                </div>
                            </div>
                        </div>
                    </div>
                </page-builder-row>
            </div>
            <div class="flex justify-center py-2 space-x-4 mb-24 mt-1 border-2 border-gray-300 rounded-wl">
                <button-base
                    style-type="secondary"
                    @click="addRow(null)"
                    size="sm"
                    class="whitespace-nowrap"
                >
                    <template #icon>
                        <icon-plus class="w-4 h-4 -ml-1" />
                    </template>
                    {{ _mft('pagebuilder:addRow.empty.long') }}
                </button-base>

                <button-base
                    style-type="secondary"
                    @click="addingFromSavedPageBuilderPartType.type = 'row'"
                    size="sm"
                    class="whitespace-nowrap"
                >
                    <template #icon>
                        <icon-plus class="w-4 h-4 -ml-1" />
                    </template>
                    {{ _mft('pagebuilder:addRow.saved.long') }}
                </button-base>
            </div>
        </div>

        <Teleport
            v-if="inlinePreviewUrl"
            to="body"
        >
            <InlinePreview
                v-if="showInlinePreview"
                :url="inlinePreviewUrl"
                @close="showInlinePreview = false"
            />
        </Teleport>


        <BlockSelector
            v-if="addingBlockAt.row !== null"
            @close="addingBlockAt = {row: null, col: null}"
            @blockSelected="insertBlock"
            :blockTypes="blockTypes"
            :blockGroups="blockGroups"
        />

        <StyleEditor
            v-if="editingStyle !== null"
            @close="stopEditing"
            @save="updatePage"
            :styles="editingStyle"
            :default-styles="defaultStyles"
            :col-count="editingType !== 'row' ? null : design.layout.rows[design.layout.rows.findIndex(r => r.uuid === editingUuid)].columns.length"
            @update:style="updateStyle"
        />

        <PageBuilderParts
            :type="addingFromSavedPageBuilderPartType.type"
            @add="addFromPageBuilderPart"
            @close="resetAddingFromSavedPageBuilderPartType"
        ></PageBuilderParts>

        <RevisionsManager
            v-if="showRevisions"
            :container-ref="containerRef"
            @close="showRevisions = false"
            @restore-revision="restoreRevision"
        ></RevisionsManager>

        <slideout-with-slots
            v-if="showPageSettings"
            @close="showPageSettings = false"
            :title="_mft('pagebuilder:pageSettings.title')"
        >
            <template #body>
                <div class="space-y-4">
                    <div class="mb-4">
                        <input-label :label="_mft('page:fontTarget.heading')"></input-label>
                        <block-editor-font-options
                            class="w-full font-selector headings"
                            :font="design.pageSettings.font?.headings?.family ? design.pageSettings.font?.headings?.family : defaultFont"
                            @update:modelValue="setFont('headings', $event)"
                        />
                    </div>
                    <div class="mb-8">
                        <input-label :label="_mft('page:fontTarget.body')"></input-label>
                        <block-editor-font-options
                            class="w-full font-selector body"
                            :font="design.pageSettings.font?.body?.family ? design.pageSettings.font?.body?.family : defaultFont"
                            @update:modelValue="setFont('body', $event)"
                        />
                    </div>

                    <toggle-switch
                        :label="_mft('pagebuilder:pageSettings.transitions.enableTransitions')"
                        v-model:content="design.pageSettings.transitions.enableTransitions"
                        class="pt-3"
                    ></toggle-switch>

                    <div class="pt-4">
                        <input-label :label="_mft('pagebuilder:cssEditor.editCss')" />
                        <div class="w-full mb-4">
                            <CssEditor v-model="design.layout.customcss" />
                        </div>
                        <p
                            class="text-xs text-gray-500"
                            v-html="_mft('pagebuilder:cssEditor.withGreatPower')"
                        ></p>
                    </div>

                    <div class="flex justify-end">
                        <button-primary
                            @click="showPageSettings = false"
                        >
                            {{ _mft('shared:done') }}
                        </button-primary>
                    </div>
                </div>
            </template>
        </slideout-with-slots>

        <slideout-with-slots
            v-if="replaceColors"
            @close="replaceColors = false"
        >
            <template #body>
                <h3 class="mt-5 mb-4">{{ _mft('pagebuilder:colorReplacer.mark') }}</h3>
                <div
                    v-for="(color, key) in currentColors"
                    :key="key"
                    class="mb-2"
                >
                    <input-label
                        v-if="! stagedForChange"
                        :label="_mft('pagebuilder:styleEditor.'+key)"
                    />

                    <div v-for="c in color" :key="c">
                        <input-label
                            v-if="stagedForChange && stagedForChange.key === key && stagedForChange.color"
                            :label="_mft('pagebuilder:styleEditor.'+key)"
                        />

                        <div
                            v-if="!stagedForChange  || stagedForChange && stagedForChange.key === key && stagedForChange.color"
                            class="flex items-center overflow-hidden border rounded-lg cursor-pointer"
                            v-on:click="selectForChange(key, c)"
                            :class="stagedForChange && stagedForChange.key === key && stagedForChange.color === c ? 'border-gray-500' : 'border-gray-100'"
                        >
                            <div
                                :style="{ backgroundColor: c }"
                                class="w-12 h-8 border-r"
                                :class="stagedForChange && stagedForChange.key === key && stagedForChange.color === c ? 'border-gray-500' : 'border-gray-100'"
                            ></div>
                            <div class="px-3 py-1 text-xs">{{ c }}</div>
                        </div>
                    </div>
                </div>

                <div
                    v-if="stagedForChange"
                >
                    <h3 class="mt-5">{{ _mft('pagebuilder:colorReplacer.replace') }}</h3>
                    <color-picker
                        :color="newColor"
                        @colorChanged="color => newColor = color"
                        :show-palette="true"
                        format="prgb"
                        :disable-alpha="false"
                    ></color-picker>

                    <div class="flex justify-end space-x-2">
                        <button-danger
                            v-on:click="stagedForChange = null"
                            class="mt-4"
                        >
                            {{ _mft('shared:cancel') }}
                        </button-danger>
                        <button-success
                            v-on:click="updateColors"
                            class="mt-4"
                        >
                            {{ _mft('shared:action.update') }}
                        </button-success>
                    </div>
                </div>
            </template>
        </slideout-with-slots>

        <modal-basic
            :open="showSavePartModal"
            @closed="resetSavePart"
        >
            <template #header>
                {{ _mft('pagebuilder:savePart.'+ savePart.type +'.title') }}
            </template>
            <template #body>
                <input-text v-model:content="savePart.title" label="Title" />
                <div class="flex justify-end">
                    <button-primary
                        size="sm"
                        @click="savePageBuilderPart"
                    >{{ _mft('shared:action.save') }}</button-primary>
                </div>
            </template>
        </modal-basic>

        <Teleport to="#page-builder-custom-css">
            {{ design.layout.customcss }}
        </Teleport>

    </div>
</template>

<style scoped>

</style>
