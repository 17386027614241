<template>
  <div>
    <form @submit.prevent="submit">
        <div class="mb-10">
            <input-text
                v-model:content="form.name"
                :error="errors.name"
                placeholder="John Doe"
                label="Namn"
                required="required"
                name="provider_name"
            />

            <input-text
                v-model:content="form.title"
                placeholder="Utbildare"
                label="Titel"
                required="required"
                name="provider_title"
            />

            <label>Profilbild</label>
            <div class="italic text-red-800 text-xs mb-1" v-if="errors.image">{{ errors.image.join('<br>') }}</div>
            <div 
                class="mb-4 w-full"
            >
                <div class="relative mb-4">
                    <div
                        v-if="profileImage && !changingProfilePicture"
                        :style="{backgroundImage: 'url('+ profileImage +')'}"
                        class="bg-center bg-cover rounded-card w-full pb-[100%]"
                    >
                    </div>

                    <div 
                        v-if="profileImage === '' && !changingProfilePicture" 
                        class="border-2 border-gray-200 rounded-card border-dashed flex items-center justify-center" style="padding-top: 25%;padding-bottom: 25%;"
                    >
                        <button
                            v-if="!changingProfilePicture"
                            v-on:click.prevent="changingProfilePicture = !changingProfilePicture"
                            :class="[profileImage ? 'absolute text-xs transform -translate-x-1/2 myflow-basic-button left-1/2 bottom-1' : 'myflow-basic-button']"
                        >
                            {{ profileImageButtonLabel }}
                        </button>
                    </div>
                    
                    <div v-if="profileImage !== ''">
                        <button
                            v-if="!changingProfilePicture"
                            v-on:click.prevent="changingProfilePicture = !changingProfilePicture"
                            :class="[profileImage ? 'absolute text-xs transform -translate-x-1/2 myflow-basic-button left-1/2 bottom-1' : 'myflow-basic-button']"
                        >
                            {{ profileImageButtonLabel }}
                        </button>
                    </div>
                </div>
                <file-upload-with-cropping
                    v-if="changingProfilePicture"
                    :required="true"
                    :currentImage="provider.profile_img_url"
                    @fileUpdated="fileUpdated($event)"
                    @croppingUpdated="croppingUpdated($event)"
                    @croppingFinished="croppingFinished($event)"
                    @croppingAborted="croppingAborted()"
                ></file-upload-with-cropping>
            </div>
        </div>

        <input-button
            :label="submitLabel"
            :busy="isSubmitting"
            type="submit"
            :disabled="changingProfilePicture"
            class="float-right"
        />
    </form>
  </div>
</template>
<script>

export default {
    props: {
        provider: {
            type: Object,
            default: {
                editing: false
            }
        },
    },
    data() {
        return {
            editing: false,
            croppingActive: false,
            changingProfilePicture: false,
            croppableImageBase64String: '',
            previewImage: '',
            previewImageSrc: '',
            form: {
                id: '',
                name: '',
                title: '',
                image: '',
                cropX: '',
                cropY: '',
                cropWidth: '',
                cropHeight: '',
            },
            errors: {
                name: '',
                title: '',
                image: ''
            },
            isSubmitting: false,
            submitLabel: 'Spara',
        };
    },
    methods: {
        fileUpdated(file) {
            this.form.image = file;
        },
        croppingUpdated(croppingoptions){
            this.form.cropX = croppingoptions.x
            this.form.cropY = croppingoptions.y
            this.form.cropWidth = croppingoptions.width
            this.form.cropHeight = croppingoptions.height
        },
        croppingFinished(base64Image){
            this.changingProfilePicture = false
            this.previewImageSrc = base64Image
        },
        croppingAborted(){
            let self = this
            this.changingProfilePicture = false
            delete this.form.image
        },
        submit() {
            let self = this;
            this.isSubmitting = true;

            if( this.changingProfilePicture ){
                this.croppingAborted();
            }

            let previousLabel = this.submitLabel;
            this.submitLabel = 'Sparar...';

            var formData = new FormData();
            for ( const key in this.form ) {
                formData.append(key, this.form[key]);
            }

            axios.post(route('manageCourses.providers.storeOrUpdate', this.provider.id), formData)
                .then(function (response) {
                    if( response.status === 200 ){
                        if( response.data.redirect ){
                            window.location = response.data.redirect;
                        } else {
                            self.isSubmitting = false;
                            self.submitLabel = previousLabel;
                        }
                    }
                })
                .catch(error => {
                    if (error.response) {
                        this.errors = error.response.data.errors;
                    }
                });

            this.isSubmitting = false;
            self.submitLabel = previousLabel;
        },
    },
    computed: {
        profileImage() {
            if( this.previewImageSrc ){
                return this.previewImageSrc
            } else {
                return this.provider.image_url
            }
        },
        profileImageButtonLabel() {
            if( this.profileImage === '' && this.previewImageSrc === '' ){
                return 'Välj profilbild'
            } else {
                return 'Byt profilbild'
            }
        }
    },
    mounted: function(){
        if( this.provider.id !== null ){
            this.submitLabel = 'Spara profil'
            this.editing = true
            this.form.name = this.provider.name
            this.form.title = this.provider.title
        }
    }
};
</script>