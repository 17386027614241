<template>
    <div class="grid grid-cols-2 gap-2 lg:gap-4">
        <div
            v-for="(block, blockIdx) in value"
            :key="blockIdx"
            :id="[blockIdx === value.length - 1 ? 'last' : '']"
            class="p-2 my-4 mb-2 bg-white shadow-xl rounded-2xl lg:p-4"
            :class="[{ half: 'col-span-2 lg:col-span-1', full: 'col-span-2'}[block.layout], 'col-span-2']"

        >
            <div class="pb-2 mb-3 border-b">
                <div class="items-center mb-2 md:flex lg:mb-0">
                    <h3 class="pl-px md:pl-0"><i>Block {{blockIdx + 1}}:</i> {{namesForBlocks[blockIdx]}}</h3>
                    <div class="md:ml-4" v-if="showVisibilityTools">
                        <slot name="block-header-buttons" v-bind:block="block">
                            <div class="flex rounded-full">
                                <button
                                    @click.prevent="block.hidden = false"
                                    :class="[!block.hidden ? 'bg-myflowPurple shadow-none text-white bg-gradient-to-b from-myflowPurple-500 to-myflowPurple-700' : 'bg-gray-100 text-myflowPurple', hideToggleClass]"
                                >
                                    {{ _mft('shared:show') }}
                                </button>

                                <button
                                    @click.prevent="block.hidden = true"
                                    :class="[block.hidden ? 'bg-myflowPurple shadow-none text-white bg-gradient-to-b from-myflowPurple-500 to-myflowPurple-700' : 'bg-gray-100 text-myflowPurple', hideToggleClass]"
                                >
                                    {{ _mft('shared:hide') }}
                                </button>
                            </div>
                        </slot>
                    </div>
                </div>
                <div class="flex items-center justify-between">
                    <div>
                        <button
                            type="button"
                            class="text-myflowRed"
                            @click.prevent="removeBlock(blockIdx)"
                        >{{ _mft('shared:action.delete') }}</button>
                    </div>
                    <div class="flex items-center justify-end space-x-2 align-center">
                        <block-editor-layout-selector
                            v-if="blockTypesForBlocks[blockIdx]"
                            class="pr-3 mr-1 border-r"
                            :block="block"
                            :available-layouts="blockTypesForBlocks[blockIdx].availableLayouts"
                        ></block-editor-layout-selector>

                        <div class="pr-3 mr-1 border-r">
                            <span class="relative group">
                                <span class="text-gray-500">#block-{{ blockIdx }}</span>
                                <span class="absolute hidden px-2 py-1 text-xs transform -translate-x-1/2 bg-white rounded-md group-hover:block left-1/2 bottom-full whitespace-nowrap">Använd detta som ändelse på adressen om du vill länka direkt till detta blocket</span>
                            </span>
                        </div>

                        <button
                            :disabled="blockIdx === 0"
                            type="button"
                            class="myflow-basic-button"
                            @click.prevent="moveUp(blockIdx)"
                        >&uarr;</button>
                        <button
                            :disabled="(blockIdx + 1) === value.length"
                            type="button"
                            class="myflow-basic-button"
                            @click.prevent="moveDown(blockIdx)"
                        >&darr;</button>
                    </div>
                </div>
            </div>

            <component
                class="transition-opacity duration-300 ease-in-out"
                :class="[block.hidden ? 'opacity-50 hover:opacity-100' : '']"
                :key="blockIdx"
                :is="`blocks-${block.type}`"
                :layout="block.layout"
                v-model="block.content"
            ></component>
        </div>
    </div>

    <div
        class="absolute inset-0"
        v-if="blockpanelOpen"
        v-on:click="blockpanelOpen = false"
    ></div>
    <Teleport
        :disabled="value.length === 0"
        v-if="loaded"
        to="#teleport-target-block-editor-toolbar"
    >
        <div class="items-center justify-center ">
            <div
                class="relative flex flex-col items-center justify-center"
            >
                <div
                    class="z-20 px-2 overflow-hidden transition-all shadow-xl bg-gray-50 w-96 md:w-screen md:max-w-2xl rounded-2xl bottom-full"
                    :class="[blockpanelOpen ? 'mb-4 pt-2 duration-[425ms]' : 'max-h-0 duration-75']"
                >
                    <div v-for="(section, idx) in groupsWithBlockTypes" v-bind:key="idx">
                        <span class="p-3 mb-0 font-bold text-myflowPurple-600">{{ section.group.heading }}</span>
                        <div class="grid grid-cols-3 gap-px mb-2 lg:grid-cols-4">
                            <button
                                v-for="(blockType, indexBlockType) in section.blockTypes"
                                :key="indexBlockType"
                                type="button"
                                class="m-2 min-h-[4rem] transition-all bg-white rounded-xl shadow-lg relative flex items-start justify-center text-xs group"
                                :class="[blockpanelOpen ? 'opacity-1 translate-y-0' : 'opacity-0 -translate-y-4']"
                                :style="'transition-delay: '+ (15 * indexBlockType + 100) + 'ms'"
                                @click.prevent="addBlock(blockType.type)"
                            >
                                    <div
                                        class="flex flex-col items-center justify-center w-full h-full p-2 hover:bg-gray-100 rounded-xl"
                                    >
                                        <h5>
                                            <i v-for="icon in blockType.icons" v-bind:key="icon" class="p-1 fa-regular" :class="icon"></i>
                                        </h5>
                                        <div v-html="blockType.name"></div>
                                    </div>
                            </button>
                        </div>
                    </div>
                </div>
                <div v-if="$slots['after-add-blocks-buttons']" class="mb-4">
                    <slot name="after-add-blocks-buttons"></slot>
                </div>
                <button
                    @click.prevent="blockpanelOpen = !blockpanelOpen"
                    class="myflow-basic-button"
                >
                    <span class="flex items-center">
                        <svg class="w-4 h-4 transition-transform" :class="blockpanelOpen ? 'rotate-0' : 'rotate-45'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                        <span class="ml-1">{{ _mft('blockEditor:addBlock') }}</span>

                    </span>
                </button>
            </div>
        </div>
    </Teleport>
</template>
<style lang="scss">
button:disabled.myflow-basic-button {
    opacity: 0.5;
    pointer-events: none;
}

</style>
<script>
import { v4 as uuidv4 } from 'uuid';

import BlocksHeadline           from './blocks/edit/headline.vue';
import BlocksText               from './blocks/edit/text.vue';
import BlocksVideo              from './blocks/edit/video.vue';
import BlocksVideoWithNotes     from './blocks/edit/videoWithNotes.vue';
import BlocksImage              from './blocks/edit/image.vue';
import BlocksFile               from './blocks/edit/file.vue';
import BlocksCardText           from './blocks/edit/cardText.vue';
import BlocksCardTextList       from './blocks/edit/cardTextList.vue';
import BlocksHeader             from './blocks/edit/header.vue';
import BlocksImageDescription   from './blocks/edit/imageDescription.vue';
import BlocksImageText          from './blocks/edit/imageText.vue';
import BlocksFullWidthImage     from './blocks/edit/fullWidthImage.vue';
import BlocksNavigationButtons  from './blocks/edit/navigationButtons.vue';
import BlocksQuote              from './blocks/edit/quote.vue';
import BlocksSlideshow          from './blocks/edit/slideshow.vue';
import BlocksSlimProduct        from './blocks/edit/slimProduct.vue';
import BlocksTextWithTitle      from './blocks/edit/textWithTitle.vue';
import BlocksLoomEmbed          from './blocks/edit/loomEmbed.vue';
import BlocksVideoRecorder      from './blocks/edit/videoRecorder.vue';
import BlocksLeadCapture        from './blocks/edit/leadCapture.vue';
import BlocksSocialMedia        from './blocks/edit/socialMedia.vue';
import BlocksVotingSuggestions  from './blocks/edit/votingSuggestions.vue';
import BlocksLivestream         from './blocks/edit/livestream.vue';
import BlocksToc                from './blocks/edit/tableOfContents.vue';
import BlocksCalendly           from './blocks/edit/calendly.vue';
import BlocksAiIntroduction     from './blocks/edit/aiIntroduction.vue';
import BlocksPricingOffer       from './blocks/edit/pricingOffer.vue';
import BlocksImageFromUrl       from './blocks/edit/imageFromUrl.vue';
import BlocksFaq                from './blocks/edit/faq.vue';
import BlocksAudio              from './blocks/edit/audio.vue';
import BlocksVimeo              from './blocks/edit/vimeo.vue';
import BlocksVimeoLive          from './blocks/edit/vimeo-live.vue';
import BlocksForm               from './blocks/edit/form.vue';
import BlocksFormsInputText     from './blocks/edit/forms-input/_basic.vue';
import BlocksFormsInputNumber   from './blocks/edit/forms-input/_basic.vue';
import BlocksFormsInputEmail    from './blocks/edit/forms-input/_basic.vue';
import BlocksFormsInputPhone    from './blocks/edit/forms-input/_basic.vue';
import BlocksFormsInputDate     from './blocks/edit/forms-input/_basic.vue';
import BlocksFormsInputTime     from './blocks/edit/forms-input/_basic.vue';
import BlocksFormsInputDateTime from './blocks/edit/forms-input/_basic.vue';
import BlocksFormsInputTextarea from './blocks/edit/forms-input/_basic.vue';
import BlocksFormsInputRadio    from './blocks/edit/forms-input/_multi.vue';
import BlocksFormsInputCheckbox from './blocks/edit/forms-input/_multi.vue';
import BlocksFormsInputSelect   from './blocks/edit/forms-input/_multi.vue';
import BlocksFormsInputUpload   from './blocks/edit/forms-input/_basic.vue';
import BlocksLogo               from './blocks/edit/logo.vue';
import BlocksCronofy            from './blocks/edit/cronofy.vue';
import BlocksEmailHeader        from './blocks/edit/email/header.vue';
import BlocksEmailPanel         from './blocks/edit/email/panel.vue';
import BlocksEmailButton        from './blocks/edit/email/button.vue';
import BlocksEmailText          from './blocks/edit/email/text.vue';
import BlocksEmailSubcopy       from './blocks/edit/email/subcopy.vue';
import BlocksEmailImage         from './blocks/edit/email/image.vue';
import BlocksEmailImageWithLink from './blocks/edit/email/imageWithLink.vue';
import BlocksEmailSocialMedia   from './blocks/edit/email/socialMedia.vue';

const BLOCK_TYPES = [
    {
        type: 'headline',
        group: 'content',
        name: 'Rubrik (centrerad)',
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-heading'
        ]
    },
    {
        type: 'header',
        group: 'content',
        name: 'Header med bakgrundsbild',
        availableByDefault: true,
        availableLayouts: ['full'],
        icons: [
            'fa-heading'
        ]
    },
    {
        type: 'text-with-title',
        group: 'content',
        name: 'Text med rubrik',
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-heading',
            'fa-align-left'
        ]
    },
    {
        type: 'text',
        group: 'content',
        name: 'Text',
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-align-left'
        ]
    },
    {
        type: 'video',
        group: 'media',
        name: 'Video',
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-video'
        ]
    },
    {
        type: 'video-with-notes',
        group: 'media',
        name: 'Video med anteckningar',
        availableByDefault: false,
        availableLayouts: ['full'],
        icons: [
            'fa-video'
        ]
    },
    {
        type: 'image',
        group: 'media',
        name: 'Bild',
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-image'
        ]
    },
    {
        type: 'image-from-url',
        group: 'media',
        name: 'Bild från URL',
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-image',
            'fa-link'
        ]
    },
    {
        type: 'full-width-image',
        group: 'media',
        name: 'Bild i fullbredd',
        availableByDefault: true,
        availableLayouts: ['full'],
        icons: [
            'fa-image-landscape'
        ]
    },
    {
        type: 'logo',
        group: 'media',
        name: 'Logo',
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-hexagon-image'
        ]
    },
    {
        type: 'audio',
        group: 'media',
        name: 'Ljud',
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-volume'
        ]
    },
    {
        type: 'file',
        group: 'media',
        name: 'Fil (nedladdningslänk)',
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-download'
        ]
    },
    {
        type: 'vimeo',
        group: 'integrations',
        name: 'Vimeo',
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-brands fa-vimeo'
        ]
    },
    {
        type: 'vimeo-live',
        group: 'integrations',
        name: 'Vimeo Live + Chat',
        availableByDefault: true,
        availableLayouts: ['full'],
        icons: [
            'fa-brands fa-vimeo'
        ]
    },
    {
        type: 'card-text',
        group: 'content',
        name: 'Kort',
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-square'
        ]
    },
    {
        type: 'card-text-list',
        group: 'content',
        name: 'Kort med lista',
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-rectangle-list'
        ]
    },
    {
        type: 'image-description',
        group: 'media',
        name: 'Bild + beskrivning',
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-message-image'
        ]
    },
    {
        type: 'image-text',
        group: 'content',
        name: 'Bild + text',
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-image',
            'fa-align-left'
        ]
    },
    {
        type: 'navigation-buttons',
        group: 'actions',
        name: 'Länkar',
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-link'
        ]
    },
    {
        type: 'quote',
        group: 'content',
        name: 'Citat',
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-quote-right'
        ]
    },
    {
        type: 'faq',
        group: 'content',
        name: 'FAQ (Rubrik + utfällbar text)',
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-question',
            'fa-maximize'
        ]
    },
    {
        type: 'form',
        group: 'actions',
        name: 'Formulär',
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-input-pipe'
        ]
    },
    {
        type: 'slideshow',
        group: 'media',
        name: 'Slideshow',
        availableByDefault: true,
        availableLayouts: ['full'],
        icons: [
            'fa-presentation-screen'
        ]
    },
    {
        type: 'slim-product',
        group: 'media',
        name: 'Enkelt pris',
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-cart-shopping'
        ]
    },
    {
        type: 'pricing-offer',
        group: 'media',
        name: 'Utförligt pris',
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-cart-plus'
        ]
    },
    {
        type: 'loom-embed',
        group: 'integrations',
        name: 'Loom video',
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-camera-web'
        ]
    },
    {
        type: 'video-recorder',
        group: 'media',
        name: 'Filminspelning',
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-camera-web'
        ]
    },
    {
        type: 'lead-capture',
        group: 'actions',
        name: 'Kontaktformulär',
        availableByDefault: false,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-address-card'
        ]
    },
    {
        type: 'social-media',
        group: 'actions',
        name: 'Sociala medier',
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-share-nodes'
        ]
    },
    {
        type: 'voting-suggestions',
        group: 'actions',
        name: 'Omröstning & feedback',
        availableByDefault: true,
        availableLayouts: ['full'],
        icons: [
            'fa-check-to-slot'
        ]
    },
    {
        type: 'livestream',
        group: 'media',
        name: 'Livestream',
        availableByDefault: true,
        availableLayouts: ['full'],
        icons: [
            'fa-signal-stream'
        ]
    },
    {
        type: 'calendly',
        group: 'integrations',
        name: 'Calendly',
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-calendar-days'
        ]
    },
    {
        type: 'cronofy',
        group: 'integrations',
        name: 'Mötesbokning',
        availableByDefault: false,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-calendar-days'
        ]
    },
    {
        type: 'ai-introduction',
        group: 'integrations',
        name: 'AI Introduktion',
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-handshake'
        ]
    },
    {
        type: 'table-of-contents',
        group: 'actions',
        name: 'Innehållsförteckning',
        availableByDefault: true,
        availableLayouts: ['full'],
        icons: [
            'fa-check-to-slot'
        ]
    },

    // FORMS

    {
        type: 'forms-input-text',
        group: 'forms-input',
        name: 'Text',
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-input-text'
        ]
    },
    {
        type: 'forms-input-number',
        group: 'forms-input',
        name: 'Nummer',
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-input-numeric'
        ]
    },
    {
        type: 'forms-input-email',
        group: 'forms-input',
        name: 'E-post',
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-at'
        ]
    },
    {
        type: 'forms-input-phone',
        group: 'forms-input',
        name: 'Telefonnummer',
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-phone'
        ]
    },
    {
        type: 'forms-input-date',
        group: 'forms-input',
        name: 'Datum',
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-calendar'
        ]
    },
    {
        type: 'forms-input-time',
        group: 'forms-input',
        name: 'Tid',
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-clock'
        ]
    },
    {
        type: 'forms-input-date-time',
        group: 'forms-input',
        name: 'Datum och tid',
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-calendar-clock'
        ]
    },
    {
        type: 'forms-input-textarea',
        group: 'forms-input',
        name: 'Lång text',
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-line-columns'
        ]
    },
    {
        type: 'forms-input-radio',
        group: 'forms-input',
        name: 'Ett val',
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-list-radio'
        ]
    },
    {
        type: 'forms-input-checkbox',
        group: 'forms-input',
        name: 'Flera val',
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-list-check'
        ]
    },
    {
        type: 'forms-input-select',
        group: 'forms-input',
        name: 'Dropdown',
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-list-dropdown'
        ]
    },
    {
        type: 'forms-input-upload',
        group: 'forms-input',
        name: 'Filuppladdning',
        availableByDefault: true,
        availableLayouts: ['half', 'full'],
        icons: [
            'fa-upload'
        ]
    },

    // EMAIL

    {
        type: 'email-header',
        group: 'email',
        name: _mft('blockEditor:block.heading.name'),
        availableByDefault: false,
        availableLayouts: ['full'],
        icons: [
            'fa-heading'
        ]
    },
    {
        type: 'email-text',
        group: 'email',
        name: _mft('blockEditor:block.content.name'),
        availableByDefault: true,
        availableLayouts: ['full'],
        icons: [
            'fa-align-left'
        ]
    },
    {
        type: 'email-panel',
        group: 'email',
        name: _mft('blockEditor:block.panel.name'),
        availableByDefault: true,
        availableLayouts: ['full'],
        icons: [
            'fa-rectangle-list'
        ]
    },
    {
        type: 'email-button',
        group: 'email',
        name: _mft('blockEditor:block.button.name'),
        availableByDefault: true,
        availableLayouts: ['full'],
        icons: [
            'fa-square-up-right'
        ]
    },
    {
        type: 'email-subcopy',
        group: 'email',
        name: _mft('blockEditor:block.footer.name'),
        availableByDefault: true,
        availableLayouts: ['full'],
        icons: [
            'fa-file-lines'
        ]
    },
    {
        type: 'email-image',
        group: 'email',
        name: _mft('blockEditor:block.image.name'),
        availableByDefault: false, // Replaced by email-image-with-link
        availableLayouts: ['full'],
        icons: [
            'fa-image'
        ]
    },
    {
        type: 'email-image-with-link',
        group: 'email',
        name: _mft('blockEditor:block.image.name'),
        availableByDefault: true,
        availableLayouts: ['full'],
        icons: [
            'fa-image'
        ]
    },
    {
        type: 'email-social-media',
        group: 'email',
        name: _mft('blockEditor:block.socialMedia.name'),
        availableByDefault: true,
        availableLayouts: ['full'],
        icons: [
            'fa-share-nodes'
        ]
    },
];
const GROUPS = [
    {
        id: 'forms-input',
        heading: 'Formulärfält',
        availableByDefault: false
    },
    {
        id: 'content',
        heading: 'Innehåll',
        availableByDefault: true
    },
    {
        id: 'media',
        heading: 'Media',
        availableByDefault: true
    },
    {
        id: 'actions',
        heading: 'Actions',
        availableByDefault: true
    },
    {
        id: 'integrations',
        heading: 'Integrationer',
        availableByDefault: true
    },
    {
        id: 'email',
        heading: _mft('blockEditor:group.email.name'),
        availableByDefault: false
    },
];
export default {
    components: {
        BlocksAiIntroduction,
        BlocksAudio,
        BlocksCalendly,
        BlocksCardText,
        BlocksCardTextList,
        BlocksCronofy,
        BlocksFaq,
        BlocksFile,
        BlocksForm,
        BlocksFormsInputCheckbox,
        BlocksFormsInputDate,
        BlocksFormsInputDateTime,
        BlocksFormsInputEmail,
        BlocksFormsInputNumber,
        BlocksFormsInputPhone,
        BlocksFormsInputRadio,
        BlocksFormsInputSelect,
        BlocksFormsInputText,
        BlocksFormsInputTextarea,
        BlocksFormsInputTime,
        BlocksFormsInputUpload,
        BlocksHeader,
        BlocksHeadline,
        BlocksImage,
        BlocksImageDescription,
        BlocksImageFromUrl,
        BlocksImageText,
        BlocksFullWidthImage,
        BlocksLeadCapture,
        BlocksLivestream,
        BlocksLogo,
        BlocksLoomEmbed,
        BlocksNavigationButtons,
        BlocksPricingOffer,
        BlocksQuote,
        BlocksSlideshow,
        BlocksSlimProduct,
        BlocksSocialMedia,
        BlocksText,
        BlocksTextWithTitle,
        BlocksToc,
        BlocksVideo,
        BlocksVideoRecorder,
        BlocksVideoWithNotes,
        BlocksVimeo,
        BlocksVimeoLive,
        BlocksVotingSuggestions,
        BlocksEmailHeader,
        BlocksEmailPanel,
        BlocksEmailButton,
        BlocksEmailText,
        BlocksEmailSubcopy,
        BlocksEmailImage,
        BlocksEmailImageWithLink,
        BlocksEmailSocialMedia,
    },
    props: {
        modelValue: Array,
        blockTypes: { type: Array, default: null },
        blockGroups: { type: Array, default: null },
        includedBlockTypes: { type: Array, default: [] },
        excludedBlockTypes: { type: Array, default: [] },
        includedBlockGroups: { type: Array, default: [] },
        excludedBlockGroups: { type: Array, default: [] },
        showVisibilityTools: { type: Boolean, default: true },
    },
    emits: [
        'update:modelValue'
    ],
    data() {
        return {
            value: null,
            blockpanelOpen: false,
            blockCount: 0,
            loaded: false,
            hideToggleClass: 'px-4 first:rounded-l-lg lg:first:rounded-l-full lg:first:pl-4 last:rounded-r-lg lg:last:rounded-r-full lg:last:pr-5 py-1 lg:py-1 border-r last:border-r-0 cursor-pointer text-xs'
        };
    },
    computed: {
        namesForBlocks() {
            return this.value.map(block => {
                const bt = BLOCK_TYPES.find((bt) => bt.type == block.type);
                return bt ? bt.name : block.type;
            });
        },
        blockTypesForBlocks() {
            return this.value.map(block => BLOCK_TYPES.find((bt) => bt.type == block.type));
        },
        groupsWithBlockTypes() {
            const availableBlockTypes = BLOCK_TYPES
                .filter((bt) => {
                    if (this.blockTypes !== null) {
                        return (this.blockTypes.indexOf(bt.type) !== -1);
                    } else {
                        return (
                            (bt.availableByDefault || this.includedBlockTypes.indexOf(bt.type) !== -1)
                            &&
                            this.excludedBlockTypes.indexOf(bt.type) === -1
                        );
                    }
                });

            return GROUPS
                .filter((group) => {
                    if (this.blockGroups !== null) {
                        return (this.blockGroups.indexOf(group.id) !== -1);
                    } else {
                        return (
                            (group.availableByDefault || this.includedBlockGroups.indexOf(group.id) !== -1)
                            &&
                            this.excludedBlockGroups.indexOf(group.id) === -1
                        );
                    }
                })
                .map((group) => ({
                    group: group,
                    blockTypes: availableBlockTypes.filter((bt) => bt.group == group.id)
                }))
                .filter((section) => section.blockTypes.length > 0);
        }
    },
    mounted() {
        this.captureModelValue();
        let self = this;
        setTimeout(function(){
            self.loaded = true;
        }, 1);
    },
    watch: {
        modelValue() {
            if (JSON.stringify(this.value) === JSON.stringify(this.modelValue)) { return; }
            this.captureModelValue();
        },
        value: {
            handler() {
                this.$emit('update:modelValue', JSON.parse(JSON.stringify(this.value)));
                let self = this;
                setTimeout(function(){
                    self.blockCount = self.value.length;
                }, 250); // Update blockcount one animation for blockpanel has ended
            },
            deep: true
        }
    },
    methods: {
        captureModelValue() {
            this.value = this.modelValue ? JSON.parse(JSON.stringify(this.modelValue)) : [];
        },
        addBlock(type) {
            this.value.push({
                type: type,
                uuid: uuidv4(),
                layout: 'full',
                content: {}
            });
            this.blockpanelOpen = false;
            this.scrollToLastBlock();
        },
        importBlock(block) {
            this.value.push(block);
        },
        scrollToLastBlock() {
            setTimeout(function(){
                const element = document.getElementById("last");
                element.scrollIntoView({behavior: "smooth"});
            }, 1); // scroll once animation has ended
        },
        removeBlock(idx) {
            if( confirm('Denna åtgärd går inte att ångra. Är du säker?') ){
                this.value.splice(idx, 1);
            }
        },
        moveUp(idx) {
            const block = this.value.splice(idx, 1);
            this.value.splice(idx - 1, 0, JSON.parse(JSON.stringify(block[0])));
        },
        moveDown(idx) {
            const block = this.value.splice(idx, 1);
            this.value.splice(idx + 1, 0, JSON.parse(JSON.stringify(block[0])));
        }
    }
}
</script>
