<script setup>
import {computed, onMounted, ref, watch} from "vue";
import IconChevron from "../icons/iconChevron.vue";

const props = defineProps({
    label: {
        type: String,
        default: null
    },
    required: {
        type: Boolean,
        default: false
    },
    type: {
        type: String,
        required: true
    },
    content: null
})

const emit = defineEmits(['update:content'])

const mediaBankEntryOptions = ref(null)
const value = ref(props.content)
const searchterm = ref('')
const showGallery = ref(false)
const currentPage = ref(1)

const nextPage = () => {
    if (currentPage.value >= mediaBankEntryOptions.value.length / 6) { return; }
    currentPage.value += 1;
}

const prevPage = () => {
    if (currentPage.value === 1) { return; }
    currentPage.value -= 1;
}

const selectItem = (item) => {
    value.value = item.value;
    showGallery.value = false;
}

const filteredEntries = computed(() => {
    if (searchterm.value === '') {
        return mediaBankEntryOptions.value;
    }

    return mediaBankEntryOptions.value.filter((entry) => entry.label.toLowerCase().includes(searchterm.value.toLowerCase()))
})

const latestEntries = computed(() => {
    return filteredEntries.value.slice(6 * (currentPage.value - 1), 6 * currentPage.value)
});

watch(props.content, () =>{
    if (value.value === props.content) { return; }
    value.value = parseInt(this.content, 10);
})


watch(value, () => {
    emit('update:content', value.value);
})

const reload = async () => {
    mediaBankEntryOptions.value = null;
    const response = await axios.get(route('mediaBank.entries.index'));
    mediaBankEntryOptions.value = response.data.data
        .filter((entry) => entry.type === props.type)
        .map((entry) => ({ value: entry.id, label: entry.title }))
    searchterm.value = ''
}

onMounted(() => {
    reload()
})
</script>

<template>
<div>
    <div v-if="mediaBankEntryOptions" class="mb-4">
        <media-upload
            @upload-complete="reload"
        />

        <div class="relative">
            <input-text
                v-model:content="searchterm"
                :label="_mft('mediaBank:searchTheMediaLibrary')"
                :placeholder="_mft('shared:search')"
            ></input-text>
            <span
                v-if="searchterm.length > 0"
                class="absolute top-7 right-2 mt-0.5"
            >
                <button-base
                    size="sm"
                    @click="searchterm = ''"
                ><i class="fa-solid fa-xmark"></i></button-base>
            </span>
        </div>

        <ul class="grid grid-cols-3 gap-2">
            <li
                v-for="item in latestEntries"
                :key="item.value"
                class="relative overflow-hidden group"
            >
                <div
                    v-if="type === 'image'"
                    class="flex aspect-square items-center bg-gray-100"
                >
                    <media-bank-entries-display
                        :data-url="route('mediaBank.entries.show', item.value)"
                        @click="selectItem(item)"
                        class="aspect-square cursor-pointer border border-transparent object-contain transition-colors hover:border-gray-400"
                    />

                    <div
                        class="absolute right-0 bottom-full left-0 bg-gray-400 pt-4 text-center text-xs text-white transition-all group-hover:-mb-5"
                    >
                        {{ item.label }}
                    </div>
                </div>
                <div
                    v-if="type === 'video'"
                    class="flex aspect-video items-center bg-gray-100"
                    @click="selectItem(item)"
                    :title="route('mediaBank.entries.show', item.value)"
                >
                    <div class="pointer-events-none flex h-full w-full items-center">
                        <media-bank-entries-display
                            :data-url="route('mediaBank.entries.show', item.value)"
                            class="aspect-square h-full w-full cursor-pointer border border-transparent object-contain transition-colors hover:border-gray-400"
                            :controls="false"
                        />
                    </div>

                    <div
                        class="absolute right-0 bottom-full left-0 overflow-hidden overflow-ellipsis whitespace-nowrap bg-gray-400 px-2 pt-4 text-center text-xs text-white transition-all group-hover:-mb-5"
                    >
                        {{ item.label }}
                    </div>
                </div>
            </li>
        </ul>

        <div class="mt-2 flex items-center justify-between space-x-2">
            <div class="flex items-center space-x-2">
                <button-base
                    size="sm"
                    :style-type="currentPage === 1 ? 'disabled' : 'secondary'"
                    @click="prevPage"
                >
                    <icon-chevron
                        class="h-4 w-4 rotate-90 fill-current transition-all group-hover:text-wlPrimary"
                    />
                </button-base>
                <span class="text-gray-400 text-xs">{{ currentPage > 1 ? currentPage - 1 : '' }}</span>
            </div>
            <div class="flex space-x-2">
                <button-secondary
                    size="sm"
                    type="button"
                    @click.prevent="reload()"
                >
                    <i class="fa-solid fa-arrows-rotate"></i>
                </button-secondary>
                <button-secondary
                    size="sm"
                    @click="showGallery = true"
                >{{ _mft('mediaBank:showGallery') }}</button-secondary>
            </div>
            <div class="flex items-center space-x-2">
                <span class="text-gray-400 text-xs">{{ currentPage + 1 }}</span>
                <button-base
                    size="sm"
                    :style-type="currentPage >= filteredEntries.length / 6 ? 'disabled' : 'secondary'"
                    @click="nextPage"
                >
                    <icon-chevron
                        class="h-4 w-4 -rotate-90 fill-current transition-all"
                    />
                </button-base>
            </div>
        </div>

        <teleport
            v-if="showGallery"
            to="body"
        >
            <div
                class="fixed inset-0 bg-black/20 z-modal-backdrop"
                @click="showGallery = false"
            ></div>
            <div class="fixed inset-8 overflow-y-scroll bg-white p-4 z-modal">
                <div class="fixed top-4 right-4 mb-4">
                    <button-secondary @click="showGallery = false">
                        {{ _mft('mediaBank:hideGallery') }}
                    </button-secondary>
                </div>
                <ul class="grid grid-cols-2 gap-2 md:grid-cols-4 lg:grid-cols-6">
                    <li
                        v-for="item in mediaBankEntryOptions"
                        :key="item.value"
                        @click="selectItem(item)"
                        class="aspect-square cursor-pointer border border-transparent bg-gray-100 object-contain transition-colors hover:border-gray-400"
                    >
                        <div
                            v-if="type === 'image'"
                        >
                            <media-bank-entries-display
                                :data-url="route('mediaBank.entries.show', item.value)"
                                class="aspect-square object-contain"
                            />
                            <div class="p-1 text-xs">{{ item.label}}</div>
                        </div>
                    </li>
                </ul>
            </div>
        </teleport>
    </div>
    <mf-spinner-medium v-else class="h-8 w-16"></mf-spinner-medium>
</div>
</template>
