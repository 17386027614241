<template>
  <div
    class="mb-4"
  >
    <template v-if="!valueSpecification">
        <input-label
        :label="label"
        :required="required"
        />
        <select
        :name="name"
        class="block w-full pr-10 mt-1 p-2 border rounded-md bg-white text-gray-900"
        :class="_inputClass"
        :required="required"
        v-model="_content"
        :disabled="disabledByDefault"
        @change="$emit('update:content', $event.target.value)"
        >
        <option value="" disabled></option>

        <option
            v-for="(option, i) in options"
            v-bind:key="i"
            :value="option.value"
            :disabled="option.disabled"
        >
            {{ translatedLabel(option.label) }}
        </option>
        </select>
        <input-error v-if="error" :error="error" />
    </template>
    <template v-else>
        <input-label
        :label="label"
        :required="required"
        />
        <select
        :name="name"
        @change="$emit('update:content', $event.target.value)"
        class="block w-full pr-10 mt-1 p-2 border rounded-md bg-white text-gray-900"
        :class="_inputClass"
        :required="required"
        v-model="_content"
        >
        <option value="" disabled></option>

        <option
            v-for="(option, i) in options"
            v-bind:key="i"
            :value="option[valueSpecification]"
        >
            {{ option[labelSpecification] }}
        </option>
        </select>
        <input-error v-if="error" :error="error" />
    </template>
  </div>
</template>
<script>
export default {
  data: function(){
    return {
      _inputClass: '',
      _content: this.content
    }
  },
  props: {
    content: {},
    options: Array,
    error: String,
    label: String,
    placeholder: String,
    required: Boolean,
    name: String,
    inputClass: {
        type: String,
        required: false,
        default: '',
    },
    disabledByDefault: {
        type: Boolean,
        required: false,
        default: false,
    },
    valueSpecification: String,
    labelSpecification: String,
  },
  methods: {
    translatedLabel(label) {
      if (label?.match(/^[^:]+:[^:]+$/)) {
        if(label.length > 5) {
          return _mft(label)
        }
        return label
      } else {
        return label
      }
    }
  },
    mounted() {
        this._inputClass = this.inputClass;
    },
    watch: {
    error: function(error){
      let classes = [];
      if( error !== '' ){
        classes.push('border-red-700')
      } else {
        classes.push('border-gray')
      }
      this._inputClass = classes.join(' ')
    },
    content() {
        this._content = this.content;
    },
  }
}
</script>
