/**
* Usave
    Usage:
    import { useAppStateStore } from '@/stores/AppStateStore.js'

    const appStateStore = useAppStateStore();

    // Trigger an action in AppStateStore
    appStateStore.triggerTimeTrackingEntriesReload();

    // Watch a state in AppStateStore
    watch(() => appStateStore.reloadTimeTrackingEntriesFlag, () => {
        loadEntries();
    });

 */

import { defineStore } from 'pinia';

export const useAppStateStore = defineStore(
    'appStateStore',
    {
        state: () => ({
            reloadTimeTrackingEntriesFlag: false,
        }),
        actions: {
            triggerTimeTrackingEntriesReload() {
                this.reloadTimeTrackingEntriesFlag = !this.reloadTimeTrackingEntriesFlag;
            },
        },
    }
);
