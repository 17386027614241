<template>
  <div v-if="filter.type === 'rating'">
    <ul class="flex rounded-full">
      <li
        class="px-4 lg:px-8 first:rounded-l-lg lg:first:rounded-l-full lg:first:pl-10 last:rounded-r-lg lg:last:rounded-r-full lg:last:pr-10 flex items-center text-xs"
        :class="[ selectedOption.value ? optionSelectedClasses : optionNotSelectedClasses, optionBaseClasses]"
        v-on:click="clearFilter"
      >{{ filter.label }}</li>
      <li
        v-for="(option, o) in options"
        v-bind:key="o"
        class="px-2 first:rounded-l-lg lg:first:rounded-l-full lg:first:pl-10 last:rounded-r-lg lg:last:rounded-r-full lg:last:pr-4"
        :class="[ optionNotSelectedClasses, optionBaseClasses]"
        v-on:click="toggleFilter(option)"
      >
        <div
          class="relative text-center transition-all"
        >
          <span
            :class="ratingClass(o)"
            class="my-2 flex"
            v-html="option.icon"
          ></span>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    filter: {
      type: Object
    },
    options: {
      type: Object
    },
    optionBaseClasses: {
      type: String,
      default: ''
    },
    optionSelectedClasses: {
      type: String,
      default: ''
    },
    optionNotSelectedClasses: {
      type: String,
      default: ''
    },
    optionIconClasses: {
      type: String,
      default: ''
    },
    optionLabelClasses: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      selectedOption: {
        value: null
      }
    }
  },
  methods: {
    clearFilter(){
      this.selectedOption.value = null
      this.$emit('clearFilter')
    },
    ratingClass(o){
      if( !this.selectedOption.value ){
        return ''
      }
      if( o < this.selectedOption.value ){
        return 'included'
      }
      if( o >= this.selectedOption.value ){
        return 'selected'
      }
    },
    toggleFilter(option){
      if( option.value === this.selectedOption.value ){
        this.selectedOption.value = null
        this.$emit('clearFilter')
      } else {
        this.selectedOption.value = option.value
        this.$emit('toggleFilter', option)
      }
    }
  }
}
</script>
<style scoped>
  :deep(.included svg) {
    stroke: rgb(212, 154, 37);
  }
</style>