<script setup>
import MediaBankEntry from './util/media-bank-entry.vue';
import {computed} from "vue";

const props = defineProps({
    containerRef: {
        type: String,
        required: true
    },
    uuid: {
        type: String,
        require: true
    },
    content: {
        type: Object,
        required: true
    }
})

const text = computed(() => props.content.text);

</script>
<template>
    <div class="flex flex-row justify-center w-full align-center">
        <div class="ml-4 mr-4 max-w-7xl">
            <div class="flex flex-col justify-center w-auto max-w-3xl">
                <div class="flex justify-center w-auto h-auto mb-3 overflow-hidden rounded-custom shadow-custom">
                    <MediaBankEntry
                        :container-ref="containerRef"
                        :block-uuid="uuid"
                        :block-content="content"
                        :media-bank-entry-id="content.media_bank_entry_id"
                        property-name="media_bank_entry_id"
                    ></MediaBankEntry>
                </div>
                <div>
                    <p class="text-xs text-center">{{text}}</p>
                </div>
            </div>
        </div>
    </div>
</template>
