<script setup>

import {computed, nextTick, onMounted, reactive, ref, watch} from "vue";
import LeadSearch from "./leadSearch.vue";
import ProductSearch from "./productSearch.vue";
import { useAppStateStore } from '@/stores/AppStateStore.js'

const appStateStore = useAppStateStore();

const emit = defineEmits(['close', 'reload'])
const props = defineProps({
    editingEntry: {
        type: Object,
        required: false,
        default: null,
    }
})

const titleInput = ref(null)
const currency = ref('')

const leadSearchEl = ref(null)

const searchingLeads = ref(false)
const submitState = ref('idle')
const hoursChangedManually = ref(false)
const hours = ref(null)
const selectingDate = ref(false)
const todayDate = ref(new Date())
const entry = reactive({
    title: '',
    date: new Date(),
    start_time: null,
    end_time: null,
    minutes: 0,
    billable: true,
    customer: null,
    product: {
        id: null,
        title: null,
        price: null
    },
})

const parseTime = (timestring) => {
    let hours
    let minutes

    if (timestring.includes(':')) {
        if (timestring.length !== 5) {
            return
        }
        [hours, minutes] = timestring.split(':')
    } else if (timestring.includes('.')) {
        if (timestring.length !== 5) {
            return
        }
        [hours, minutes] = timestring.split('.')
    } else {
        if (timestring.length === 1) {
            hours = '0' + timestring
            minutes = '00'
        } else if (timestring.length === 2) {
            hours = timestring
            minutes = '00'
        } else if (timestring.length === 4) {
            hours = timestring.substring(0, 2)
            minutes = timestring.substring(2, 4)
        } else if (timestring.length === 3) {
            if (timestring.substr(0,1) !== '0') {
                hours = timestring.substring(0, 1)
                minutes = timestring.substring(1, 3)
            }
        } else {
            return
        }
    }

    const date = new Date(0, 0, 0, hours, minutes)

    if (date.toString() === 'Invalid Date') {
        return
    }

    return date.toLocaleTimeString('sv-SE', {hour: '2-digit', minute: '2-digit'})
}

const manualHoursInput = () => {
    if (hours.value === null) {
        hoursChangedManually.value = false
        return
    }
    hoursChangedManually.value = true
    updateHours(hours.value)
}

const updateHours = (h) => {
    if (h === '') {
        entry.minutes = 0
        return
    }

    if (hoursChangedManually.value === true) {
        entry.minutes = parseFloat(String(h).replace(',', '.')) * 60
    } else {
    }
}

const submit = async () => {
    if (! entryIsValid.value || submitState.value === 'waiting') {
        return
    }

    submitState.value = 'waiting'

    const entryData = {
        title: entry.title,
        date: entry.date.toISOString().split('T')[0],
        start_time: interpretedStartTime.value,
        end_time: interpretedEndTime.value,
        minutes: entry.minutes,
        billable: entry.billable,
        customer: entry.customer,
        product: entry.product,
    }

    let res
    if (props.editingEntry) {
        res = await axios.put(route('api.time-tracking.entries.update', props.editingEntry.id), entryData)
    } else {
        res = await axios.post(route('api.time-tracking.entries.store'), entryData)
    }

    if (! res.data.success) {
        submitState.value = 'error'
        return
    }

    submitState.value = 'success'

    if (! props.editingEntry) {
        resetInputs()
        titleInput.value.focusInput()
    }
    emit('reload')
    appStateStore.triggerTimeTrackingEntriesReload();
}

const setCustomerFromLead = (lead) => {
    entry.customer = {
        lead_id: lead.id,
        name: lead.name,
        company: lead.company,
        email: lead.email,
    }
}

const loadCustomerFromEntry = async () => {
    if (props.editingEntry.lead_id) {
        const res = await axios.get(route('api.crm.lead.show', props.editingEntry.lead_id))
        setCustomerFromLead(res.data.lead)
    }
}


const initSelectLead = () => {
    entry.customer = null
    nextTick(() => {
        leadSearchEl.value.focusInput()
    })
}

const setProduct = (product) => {
    entry.product.id = product.id
    entry.product.title = product.title
    entry.product.price = product.price

    if (entry.title === '') {
        entry.title = product.title
    }
}

const clearProduct = () => {
    entry.product.id = null
    entry.product.title = null
    entry.product.price = null
}

const resetInputs = () => {
    entry.title = ''
    entry.start_time = null
    entry.end_time = null
    entry.minutes = 0
    entry.customer = null

    entry.product.id = null
    entry.product.title = null
    entry.product.price = null

    hours.value = null
    hoursChangedManually.value = false
    selectingDate.value = false
}

const interpretedStartTime = computed(() => {
    if (entry.start_time) {
        return parseTime(entry.start_time)
    }
    return null
})

const interpretedEndTime = computed(() => {
    if (entry.end_time) {
        return parseTime(entry.end_time)
    }
    return null
})

const timeDiffInMinutes = computed(() => {
    if (interpretedStartTime.value && interpretedEndTime.value) {
        const start = new Date(0, 0, 0, parseInt(interpretedStartTime.value.split(':')[0], 10), parseInt(interpretedStartTime.value.split(':')[1], 10))
        const end = new Date(0, 0, 0, parseInt(interpretedEndTime.value.split(':')[0], 10), parseInt(interpretedEndTime.value.split(':')[1], 10))
        const diff = end - start
        return Math.round(diff / 60000)
    }
    return null
})

const timeDiffInHours = computed(() => {
    if (timeDiffInMinutes.value) {
        let minutes = timeDiffInMinutes.value
        if (timeDiffInMinutes.value < 0) {
            minutes = minutes + 1440
        }

        return Math.round((minutes / 60) * 100) / 100
    }
    return null
})

const entryIsValid = computed(() => {
    return !(entry.title === '' || hours.value === null);
})

const displayDate = computed(() => {
    if (entry.date) {
        return entry.date.toLocaleDateString('sv-SE')
    }
    return null
})

const entryMonetaryValue = computed(() => {
    if (entry.product.price) {
        return entry.product.price * (entry.minutes / 60) + ' ' + currency.value
    }
    return null
})

watch(hours, (val) => {
    updateHours(val)
})

watch(timeDiffInHours, (value) => {
    if (hoursChangedManually.value === false) {
        entry.minutes = value * 60
        hours.value = value
    }
})

onMounted(() => {
    _mfProp('company:default-currency').then(data => {
        currency.value = data
    })
    entry.date = new Date()
    todayDate.value = new Date();
    submitState.value = 'idle'

    if (props.editingEntry) {
        entry.title = props.editingEntry.title
        entry.date = new Date(props.editingEntry.date)
        entry.start_time = props.editingEntry.start_time
        entry.end_time = props.editingEntry.end_time
        entry.minutes = props.editingEntry.minutes
        entry.billable = props.editingEntry.billable
        entry.customer = props.editingEntry.customer
        entry.product = props.editingEntry.product
        hours.value = props.editingEntry.minutes / 60

        loadCustomerFromEntry()
    }

    document.addEventListener('keydown', function(event) {
        if (searchingLeads.value === true) {
            return
        }

        if (event.key === 'Enter') {
            if ((event.ctrlKey && !event.metaKey) || (!event.ctrlKey && event.metaKey)) {
                event.preventDefault();
                submit()
            }
        }
    });
})



</script>
<template>
    <div>
        <div class="mt-2 md:-mt-2 mb-2">
            <h1
                v-if="! entry.title"
                class="text-2xl mb-4"
            >{{ _mft('timeTracking:title') }}</h1>
            <div
                v-else
                class="w-full"
            >
                <div class="flex justify-between space-x-4 -mx-4 md:-mx-6 lg:-mx-8 px-4 md:px-6 lg:px-8">
                    <div class="text-xs">
                        <div class="mb-1 font-semibold text-md">{{ entry.title ? entry.title : ' ' }}</div>
                        <div
                            v-if="interpretedStartTime && interpretedEndTime"
                            class="flex items-center space-x-1 text-xs text-gray-400"
                        >
                            <div>{{ interpretedStartTime ? interpretedStartTime : ' ' }}</div>
                            <span class="transform -translate-y-0.5">-</span>
                            <div>{{ interpretedEndTime ? interpretedEndTime : ' ' }}</div>
                        </div>
                    </div>
                    <div class="flex flex-col justify-start space-x-4">
                        <div
                            v-if="entry.product.price && hours > 0"
                            class="text-xs text-right text-gray-400 pb-1"
                        >
                            {{ entryMonetaryValue }}
                        </div>
                        <div class="text-xs text-right text-gray-400 whitespace-nowrap">
                            {{ hours ? hours : ' ' }}
                            {{ hours ? _mft('shared:date.hour.short') : ' ' }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-col space-y-4">
            <input-text
                v-model:content="entry.title"
                :placeholder="_mft('timeTracking:entry.title')"
                ref="titleInput"
                class="!mb-0"
                autofocus
            />

            <div class="grid grid-cols-2 gap-2">
                <div>
                    <input-text
                        v-model:content="entry.start_time"
                        :label="_mft('timeTracking:entry.startTime')"
                        placeholder="9:00"
                        :disabled="hoursChangedManually"
                        class="!mb-1"
                    />
                </div>
                <div>
                    <input-text
                        v-model:content="entry.end_time"
                        :label="_mft('timeTracking:entry.endTime')"
                        placeholder="11:30"
                        :disabled="hoursChangedManually"
                        class="!mb-1"
                    />
                </div>
                <div
                    class="col-span-2 text-xs cursor-pointer flex items-center -mt-2"
                >
                        <span
                            @click="selectingDate = true"
                            class="flex items-center"
                        >
                            <i class="fa-regular fa-calendar mr-1 text-gray-500"></i>
                            {{ displayDate }}
                        </span>
                    <button-base
                        v-if="entry.date.toISOString() !== todayDate.toISOString()"
                        size="line"
                        class="ml-1 !bg-gray-200 !px-1 hover:opacity-70"
                        @click="entry.date = todayDate"
                    ><i class="fa-regular fa-close"></i></button-base>
                </div>
            </div>

            <div
                v-if="selectingDate"
                class="absolute inset-0 z-20 bg-white flex justify-center items-start pt-20 w-full"
            >
                <div>
                    <input-datepicker
                        :date="entry.date"
                        @update:date="entry.date = $event"
                        @date-selected="selectingDate = false"
                        :min-date="new Date(null)"
                    />
                    <button-primary
                        size="sm"
                        class="absolute bottom-2 left-52"
                        @click="selectingDate = false"
                    >{{ _mft('shared:done') }}</button-primary>
                </div>
            </div>

            <div>
                <input-number
                    v-model:content="hours"
                    :label="_mft('timeTracking:entry.hoursSpent')"
                    :show-step-ui="true"
                    :step="0.1"
                    min="0"
                    max="999"
                    @input="manualHoursInput"
                    placeholder="Eg. 4.5"
                    class="!mb-0"
                />
            </div>

            <div class="-mb-4">
                <toggle-switch
                    :label="_mft('timeTracking:entry.billable')"
                    :content="entry.billable"
                    v-model:content="entry.billable"
                    class="font-medium"
                />

                <div class="flex justify-between">
                    <input-label :label="_mft('timeTracking:entry.product')" />
                    <a
                        :href="route('custom-products.index')"
                        class="mf-link text-xs"
                        target="_blank"
                    >{{ _mft('timeTracking:entry.product.manageProducts') }}</a>
                </div>
                <ProductSearch
                    v-if="! entry.product.id"
                    :currency="currency"
                    @product-selected="setProduct"
                    class="-mb-4"
                />
                <div
                    v-if="entry.product.id"
                    class="bg-gray-50 w-full text-xs p-2 border rounded-md flex justify-between space-x-2 items-center"
                >
                    <div class="space-x-2">
                        <span>{{ entry.product.title }}</span> <span class="text-gray-400">{{ entry.product.price }} {{ currency }}/ h</span>
                    </div>
                    <button-base
                        style-type="transparent"
                        @click="clearProduct"
                        class="!py-0"
                    >
                        <i class="fa-regular fa-xmark w-4 h-4"></i>
                    </button-base>
                </div>
            </div>

            <div>
                <input-label
                    :label="_mft('timeTracking:entry.customer')"
                />
                <div
                    v-if="entry.customer"
                    class="bg-gray-50 w-full text-xs p-2 border rounded-md flex justify-between space-x-2 items-start"
                >
                    <div>
                        <div>{{ entry.customer.name }}</div>
                        <div v-if="entry.customer.company">{{ entry.customer.company }}</div>
                        <div>{{ entry.customer.email }}</div>
                    </div>
                    <button-base
                        style-type="transparent"
                        @click="initSelectLead"
                    >
                        <i class="fa-regular fa-xmark w-4 h-4"></i>
                    </button-base>
                </div>
                <LeadSearch
                    :class="entry.customer ? 'hidden' : ''"
                    :selected-lead="entry.customer"
                    ref="leadSearchEl"
                    @lead-selected="setCustomerFromLead"
                    @focus-change="searchingLeads = $event"
                />
            </div>

            <div class="grid grid-cols-3 gap-4">
                <button-base
                    class="w-full justify-center"
                    @click="emit('close')"
                >{{ _mft('shared:modal.close') }}</button-base>

                <button-primary
                    class="w-full justify-center col-span-2"
                    :state="submitState"
                    :disabled="!entryIsValid"
                    @click="submit"
                >{{ _mft('shared:action.save') }}</button-primary>
            </div>
        </div>
    </div>
</template>
