<script setup>

import {computed, onMounted, ref, watch} from "vue";

const props = defineProps({
    shownFlows: Array
})

const averageDealSize = ref(20000);
const conversionRate = ref(5);
const meetingRate = ref(2);
const currentMeetingsPerMonth = ref(5);
const additionalDeals = ref(2);
const monthlyCustomers = ref(3);
const digitalUpsellPercentage = ref(20);
const interestedPercentage = ref(50);

const leadhunterEmailsYearly = ref(440);

const transitioned = ref(false)
const calculating = ref(false);
const seeResult = ref(false);

const moreLeadsResults = computed(() => {
    let meetings = leadhunterEmailsYearly.value * (meetingRate.value / 10);
    let customers = meetings * (conversionRate.value / 10);
    let newRevenue = customers * averageDealSize.value;
    let costOfNoAction = newRevenue / 220;

    return {
        meetings: meetings.toLocaleString('en-US', {maximumFractionDigits:0}).replaceAll(',', ' '),
        customers: customers.toLocaleString('en-US', {maximumFractionDigits:0}).replaceAll(',', ' '),
        newRevenue: newRevenue.toLocaleString('en-US', {maximumFractionDigits:0}).replaceAll(',', ' '),
        newRevenueRaw: newRevenue,
        costOfNoAction: costOfNoAction.toLocaleString('en-US', {maximumFractionDigits:0}).replaceAll(',', ' '),
        costOfNoActionRaw: costOfNoAction,
    };
})

const digitalProductsResults = computed(() => {
    const revenue = averageDealSize.value * (digitalUpsellPercentage.value / 100) * monthlyCustomers.value * 12 * (interestedPercentage.value / 100);
    const costOfNoAction = revenue / 220

    return {
        newRevenue: revenue.toLocaleString('en-US', {maximumFractionDigits:0}).replaceAll(',', ' '),
        newRevenueRaw: revenue,
        costOfNoAction: costOfNoAction.toLocaleString('en-US', {maximumFractionDigits:0}).replaceAll(',', ' '),
        costOfNoActionRaw: costOfNoAction,
    }
})

const increasedWinRateResults = computed(() => {
    const revenue = (averageDealSize.value * additionalDeals.value) * 12;
    const costOfNoAction = revenue / 220

    return {
        newRevenue: revenue.toLocaleString('en-US', {maximumFractionDigits:0}).replaceAll(',', ' '),
        newRevenueRaw: revenue,
        costOfNoAction: costOfNoAction.toLocaleString('en-US', {maximumFractionDigits:0}).replaceAll(',', ' '),
        costOfNoActionRaw: costOfNoAction,
    }
})

const total = computed(() => {
    let newRevenue = 0;
    let costOfNoAction = 0;

    if (props.shownFlows.includes('more-leads')) {
        newRevenue += moreLeadsResults.value.newRevenueRaw
        costOfNoAction += moreLeadsResults.value.costOfNoActionRaw
    }

    if (props.shownFlows.includes('digital-products')) {
        newRevenue += digitalProductsResults.value.newRevenueRaw
        costOfNoAction += digitalProductsResults.value.costOfNoActionRaw
    }

    if (props.shownFlows.includes('increased-win-rate')) {
        newRevenue += increasedWinRateResults.value.newRevenueRaw;
        costOfNoAction += increasedWinRateResults.value.costOfNoActionRaw
    }

    return {
        newRevenue: newRevenue.toLocaleString('en-US', {maximumFractionDigits:0}).replaceAll(',', ' '),
        costOfNoAction: costOfNoAction.toLocaleString('en-US', {maximumFractionDigits:0}).replaceAll(',', ' ')
    }
})

const calculateInitial = () => {
    calculating.value = true

    setTimeout(function(){
        seeResult.value = true;

        setTimeout(function() {
            window.scrollBy({
                top: 500,
                behavior: 'smooth'
            });
        }, 300);
    }, 300)
}

watch(() => props.shownFlow, () => {
    seeResult.value = false
    calculating.value = false

    setTimeout(() => {
        transitioned.value = true;
    }, 100);
})

onMounted(() => {
    setTimeout(() => {
        transitioned.value = true;
    }, 100);
})

</script>

<template>
    <div>
        <card-basic
            class="transition-all duration-500"
            :class="transitioned ? 'translate-y-0 scale-100' : '-translate-y-4 opacity-0 scale-95'"
        >
            <div class="grid-cols-2 gap-8 md:grid">
                <div
                    class="col-span-2 p-2 text-center"
                >
                    <p class="mb-2">
                        What is your average value per deal for your most popular product?
                    </p>
                    <input-number
                        max="10000000"
                        min="0"
                        :step="1000"
                        class="mx-auto w-36"
                        v-model:content="averageDealSize"
                        :show-step-ui="true"
                        placeholder="Average deal size"
                    />
                </div>

                <div
                    v-if="shownFlows.includes('more-leads')"
                    class="col-span-2 p-2 text-center"
                >
                    <p class="mb-2">
                        If you have meetings with 10 people, how many of them become customers? <span class="block text-xs text-gray-400">(Given that they all are in your target audience)</span>
                    </p>
                    <input-number
                        max="10"
                        min="0"
                        class="mx-auto w-36"
                        v-model:content="conversionRate"
                        :show-step-ui="true"
                        placeholder="Conversion rate"
                    />
                </div>

                <div
                    v-if="shownFlows.includes('more-leads')"
                    class="col-span-2 p-2 text-center"
                >
                    <p class="mb-2">
                        If you send 10 linkedin messages, how many of them are interested in a meeting?
                    </p>
                    <input-number
                        max="10"
                        min="0"
                        class="mx-auto w-36"
                        v-model:content="meetingRate"
                        :show-step-ui="true"
                        placeholder="Conversion rate"
                    />
                </div>

                <div
                    v-if="shownFlows.includes('increased-win-rate')"
                    class="col-span-2 p-2 text-center"
                >
                    <p class="mb-2">
                        How many meetings do you have in a month on average?
                    </p>
                    <input-number
                        max="10000000"
                        min="0"
                        class="mx-auto w-36"
                        v-model:content="currentMeetingsPerMonth"
                        :show-step-ui="true"
                        placeholder="Meetings per month"
                    />
                </div>

                <div
                    v-if="shownFlows.includes('increased-win-rate')"
                    class="col-span-2 p-2 text-center"
                >
                    <p class="mb-2">
                        How many more deals will you close each month using myflow?
                    </p>
                    <input-number
                        max="10000000"
                        min="0"
                        class="mx-auto w-36"
                        v-model:content="additionalDeals"
                        :show-step-ui="true"
                        placeholder="Additional closed deals"
                    />
                </div>

                <div
                    v-if="shownFlows.includes('digital-products')"
                    class="col-span-2 p-2 text-center"
                >
                    <p class="mb-2">
                        Compared to your regular products, how much do you think you price your digital products at?
                    </p>
                    <div class="mx-auto md:w-64">
                        <input-range
                            :content="digitalUpsellPercentage"
                            min="5"
                            max="100"
                            :step="5"
                            class="w-full"
                            @update:content="digitalUpsellPercentage = $event"
                        />{{ digitalUpsellPercentage }} %
                    </div>
                </div>

                <div
                    v-if="shownFlows.includes('digital-products')"
                    class="col-span-2 p-2 text-center"
                >
                    <p class="mb-2">
                        How many monthly customers do you currently have?
                    </p>
                    <input-number
                        max="10"
                        min="0"
                        class="mx-auto w-36"
                        v-model:content="monthlyCustomers"
                        :show-step-ui="true"
                        placeholder="Monthly customers"
                    />
                </div>

                <div
                    v-if="shownFlows.includes('digital-products')"
                    class="col-span-2 p-2 text-center"
                >
                    <p class="mb-2">
                        What percentage of your customers are interested in digital products?
                    </p>
                    <div class="mx-auto md:w-64">
                        <input-range
                            :content="interestedPercentage"
                            min="5"
                            max="100"
                            :step="5"
                            class="w-full"
                            @update:content="interestedPercentage = $event"
                        />{{ interestedPercentage }} %
                    </div>
                </div>

                <div
                    v-if="! seeResult"
                    class="flex justify-center col-span-2"
                >
                    <button-primary
                        class="justify-center w-48 mx-auto transition-all"
                        :class="calculating ? 'translate-y-8 opacity-0' : ''"
                        @click="calculateInitial"
                    >
                        Calculate
                    </button-primary>
                </div>

                <div
                    v-if="calculating"
                    class="col-span-2 -mx-4 -mt-6 -mb-4 transition-colors duration-1000 text-wlPrimaryContrast md:-mx-6 md:-mb-6 lg:-mx-8 lg:-mb-8"
                    :class="seeResult ? 'bg-wlPrimary' : 'bg-transparent'"
                >
                    <div class="w-full h-12 transform -translate-y-px bg-white rounded-b-wl"></div>
                    <div
                        class="col-span-2 p-4 pt-3 transition-all duration-1000 rounded-b-wl md:p-6 md:pt-4 lg:p-8 lg:pt-5"
                        :class="seeResult ? 'translate-y-0 opacity-100 max-h-screen' : '-translate-y-8 opacity-0 max-h-0'"
                    >
                        <div
                            v-if="shownFlows.includes('more-leads')"
                            class="flex mb-8 space-x-4"
                        >
                            <div>
                                <i class="mt-1 text-2xl fa-regular fa-people-group"></i>
                            </div>
                            <div>
                                <p>In a year, this would give you <strong>{{ moreLeadsResults.meetings }} meetings</strong> with relevant customers.</p>

                                <p>Out of these meetings, you would get <strong>{{ moreLeadsResults.customers }} new customers</strong>.</p>

                                <p>These customers would generate <strong>{{ moreLeadsResults.newRevenue }}</strong> in new revenue.</p>

                                <p>
                                    Cost of no action: <strong>{{ moreLeadsResults.costOfNoAction }}</strong> per day
                                </p>
                            </div>
                        </div>

                        <div
                            v-if="shownFlows.includes('digital-products')"
                            class="flex mb-8 space-x-4"
                        >
                            <div>
                                <i class="mt-1 text-2xl fa-regular fa-cloud-arrow-up"></i>
                            </div>
                            <div>
                                <p>Your digital products would generate <strong>{{ digitalProductsResults.newRevenue }}</strong> in new revenue each year.</p>

                                <p>
                                    Cost of no action: <strong>{{ digitalProductsResults.costOfNoAction }}</strong> per day
                                </p>
                            </div>
                        </div>

                        <div
                            v-if="shownFlows.includes('increased-win-rate')"
                            class="flex mb-8 space-x-4"
                        >
                            <div>
                                <i class="mt-1 text-2xl fa-regular fa-sack-dollar"></i>
                            </div>
                            <div>
                                <p>Your increased win rate would generate <strong>{{ increasedWinRateResults.newRevenue }}</strong> in new revenue each year.</p>

                                <p>
                                    Cost of no action: <strong>{{ increasedWinRateResults.costOfNoAction }}</strong> per day
                                </p>
                            </div>
                        </div>

                        <div class="pt-3 mt-4 -mb-3 border-t">
                            <p
                                class="font-bold text-md"
                            >
                                Total new revenue: <strong>{{ total.newRevenue }}</strong> per year                            </p>
                            <p
                                class="font-bold text-md"
                            >
                                Cost of no action: <strong>{{ total.costOfNoAction }}</strong> per day
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </card-basic>
    </div>
</template>

<style scoped>

</style>
