<template>
    <template v-if="$slots['success-content'] && succeded">
        <slot name="success-content"></slot>
    </template>
    <form v-else @submit.prevent="submit" ref="form">
      <slot></slot>
    </form>
</template>

<style lang="scss" scoped>
</style>

<script>
import axios from 'axios';

export default {
  props: {
    method: { type: String, required: true },
    action: { type: String, required: true },
    shouldSubmit: { type: Function, required: false }
  },
  data() {
    return {
      submitting: false,
      succeded: false
    };
  },
  methods: {
    submit: async function() {
      if (this.submitting) { return; }
      if (this.shouldSubmit && !this.shouldSubmit()) { return; }

      this.$emit('submit');

      try {
        this.submitting = true;
        const response = await axios.request({
          method: this.method,
          url: this.action,
          data: new FormData(this.$refs.form)
        });

        this.$emit('success', response);
        this.succeded = true;
        this.submitting = false;
      } catch (error) {
        this.$emit('failure', error);
        this.submitting = false;
        console.warn("Failed to submit form..", error);
      }
    }
  }

}
</script>
