<script setup>
import {computed, onMounted, ref, watch} from "vue";

const props = defineProps({
    identifier: {
        type: String,
        required: false,
        default: null
    },
    currentIdentifier: {
        type: String,
        required: false,
        default: null
    },
    label: {
        type: String,
        required: false,
        default: _mft('shared:currency')
    }
})

const emit = defineEmits(['update:identifier', 'update:currency'])

const currentCurrencyIdentifier = ref(props.identifier)
const allCurrencies = ref([])

const searchableCurrencies = computed(() => {
    return allCurrencies.value.filter(currency => {
        currency.compositename = currency.identifier.toLowerCase() + ' ' + currency.label.toLowerCase()
        currency.searchResultLabel = '<div class="w-full flex space-x-2 justify-between"><span>' + currency.label + '</span><span>' + currency.identifier + '</span></div>';
        return currency
    })
})

const currentCurrencyObject = computed(() => {
    return allCurrencies.value.find(currency => currency.value === currentCurrencyIdentifier.value)
})

const selectCurrency = (currencyIdentifier) => {
    currentCurrencyIdentifier.value = currencyIdentifier

    emit('update:identifier', currencyIdentifier)
    if (currentCurrencyObject.value === undefined) {
        return
    }
    emit('update:currency', {value: currentCurrencyObject.value.value, label: currentCurrencyObject.value.label, identifier: currentCurrencyObject.value.identifier})
}

const handleSelect = (currencyObject) => {
    selectCurrency(currencyObject.value)

}

const handlePresetIdentifier = () => {
    if (allCurrencies.value.length > 0) {
        selectCurrency(allCurrencies.value.find(currency => currency.value === props.identifier?.toLowerCase())?.value)
    }
}

const placeholder = computed( () => {
    return currentCurrencyObject.value ? currentCurrencyObject.value.label : _mft('setting:currency.searchCurrency')
})

onMounted(async () => {
    const res = await axios.get(route('currencies.index'))
    allCurrencies.value = res.data
})


watch(() => allCurrencies.value, (newVal) => {
    handlePresetIdentifier()
}, {deep: true})

watch(() => props.identifier, (newVal) => {
    handlePresetIdentifier()
}, {deep: true})

</script>

<template>
    <input-label :label="label" />
    <input-searchable
        :searchableItems="searchableCurrencies"
        searchableField="compositename"
        labelField="searchResultLabel"
        :placeholder="placeholder"
        v-model:content="currentCurrencyObject"
        @selected="handleSelect"
    ></input-searchable>
</template>

<style scoped>

</style>
