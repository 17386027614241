<template>
    <div class="w-full md:w-auto">
        <div class="flex justify-between w-full px-2 md:px-4 md:justify-between md:space-x-1 lg:space-x-2 md:mb-0">

            <div
                :class="circleClasses"
                class="transition bg-white rounded-full shadow-md cursor-pointer circle-icon hover:bg-myflowPurple-600 hover:text-white"
            >
                    <content-search></content-search>
            </div>
            <div
                :class="circleClasses"
                class="transition bg-white rounded-full shadow-md cursor-pointer circle-icon hover:bg-myflowPurple-600 hover:text-white"
            >
                    <a
                        class="flex items-center justify-center badge "
                        :href="route('support.article.index')"
                        target="_blank"
                    >
                        <i class="text-gray-900 fa-sharp fa-solid fa-question hover:text-white"></i>
                    </a>
            </div>
            <!-- <div
                :class="circleClasses"
                class="transition bg-white rounded-full shadow-md cursor-pointer circle-icon"
            >
                <support-top-bar-panel />
            </div> -->
            <div
                :class="circleClasses"
                class="transition bg-white rounded-full shadow-md cursor-pointer circle-icon hover:bg-myflowPurple-600 hover:text-white group"
                v-on:click="openInboxSlideout()"
            >
                <div class="flex items-center justify-center badge">
                    <div
                        class="absolute flex items-center justify-center w-5 h-5 text-xs text-white transition rounded-full count -bottom-1 -right-1 bg-myflowPurple-400 group-hover:bg-myflowPurple-900 group-hover:text-white"
                        v-if="inboxMessageCount > 0"
                    >
                        {{ inboxMessageCount }}
                    </div>
                    <svg
                    xmlns="http://www.w3.org/2000/svg"
                        class="w-5 h-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path d="M3 8L8.44992 11.6333C9.73295 12.4886 10.3745 12.9163 11.0678 13.0825C11.6806 13.2293 12.3194 13.2293 12.9322 13.0825C13.6255 12.9163 14.2671 12.4886 15.5501 11.6333L21 8M6.2 19H17.8C18.9201 19 19.4802 19 19.908 18.782C20.2843 18.5903 20.5903 18.2843 20.782 17.908C21 17.4802 21 16.9201 21 15.8V8.2C21 7.0799 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V15.8C3 16.9201 3 17.4802 3.21799 17.908C3.40973 18.2843 3.71569 18.5903 4.09202 18.782C4.51984 19 5.07989 19 6.2 19Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
            </div>
            <div
                :class="circleClasses"
                class="transition bg-white rounded-full shadow-md cursor-pointer circle-icon hover:bg-myflowPurple-600 hover:text-white group"
                v-on:click="openNotifications()"
            >
                <div class="flex items-center justify-center badge">
                    <div
                        class="absolute flex items-center justify-center w-5 h-5 text-xs text-white transition rounded-full count -bottom-1 -right-1 bg-myflowPurple-400 group-hover:bg-myflowPurple-900 group-hover:text-white"
                        v-if="notificationCount > 0"
                    >
                        {{ notificationCount }}
                    </div>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-5 h-5"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                    </svg>
                </div>
            </div>
            <Menu
                as="div"
                :class="circleClasses"
                class="relative transition rounded-full shadow-md cursor-pointer group bg-myflowPurple-600 hover:text-white"
            >
                <div>
                    <MenuButton class="w-10 h-10 rounded-full md:w-8 md:h-8">
                        <img
                            class="object-cover object-center w-full h-full transition rounded-full group-hover:opacity-80"
                            :src="userProfileImgUrl ? userProfileImgUrl : 'https://myflow.fra1.digitaloceanspaces.com/generic-profile-icon-23.jpeg'"
                            alt=""
                        >
                    </MenuButton>
                </div>
                <transition
                    enter-active-class="transition duration-100 ease-out"
                    enter-from-class="transform scale-95 opacity-0"
                    enter-to-class="transform scale-100 opacity-100"
                    leave-active-class="transition duration-75 ease-in"
                    leave-from-class="transform scale-100 opacity-100"
                    leave-to-class="transform scale-95 opacity-0"
                >
                    <MenuItems
                        class="absolute z-50 w-auto mt-4 overflow-hidden origin-top-right rounded-md shadow-lg md:mt-2 lg:mt-1 -right-4 top-8 focus:outline-none"
                    >
                        <MenuItem
                            v-for="item in userNavigation"
                            :key="item.name"
                            v-slot="{ active }"
                        >
                            <a
                                :href="item.href"
                                @click="onUserNavigationClick(item)"
                                class="flex items-center px-4 py-2 text-sm text-white whitespace-nowrap lg:text-xs bg-myflowPurple-900 hover:bg-myflowPurple-700 "
                            >
                                <span class="w-4 h-4 mr-2" v-html="item.icon"></span>
                                {{ item.name }}
                            </a>
                        </MenuItem>
                    </MenuItems>
                </transition>
            </Menu>
        </div>
        <basic-slideout
            v-model:open="showNotificationSlideout"
        >
            <template v-slot:header>
                <div>
                    <h1>Notiser</h1>
                    <p
                        class="text-myflowPurple-200"
                        v-if="notificationCount > 0"
                    >Du har {{ notificationCount }} olästa notiser.</p>
                </div>
            </template>
            <template v-slot:body class="relative">
                <notification-index
                    ref="notificationIndex"
                    :maxcount="300"
                    @update="setNotificationCount"
                />
            </template>
            <template v-slot:footer>
                <button
                    class="myflow-basic-button--secondary"
                    v-on:click="markAllAsRead()"
                >
                    Markera alla som lästa
                </button>

            </template>
        </basic-slideout>
        <basic-slideout
            v-model:open="showInboxSlideout"
        >
            <template v-slot:header>
                <div>
                    <h1>Meddelanden</h1>
                    <p
                        class="text-myflowPurple-200"
                        v-if="inboxMessageCount > 0"
                    >Du har {{ inboxMessageCount }} <span v-if="inboxMessageCount === 1">oläst meddelande</span><span v-else>olästa meddelanden</span>.</p>
                </div>
            </template>
            <template v-slot:body class="relative">
                <notification-index
                    ref="notificationIndex"
                    :maxcount="300"
                    @update="setNotificationCount"
                />
            </template>
            <template v-slot:footer>
                <button
                    class="myflow-basic-button--secondary"
                    v-on:click="markAllAsRead()"
                >
                    Markera alla som lästa
                </button>

            </template>
        </basic-slideout>
    </div>
</template>
<script>
import { ref } from 'vue'
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
} from '@headlessui/vue'
import route from 'ziggy';
import axios from 'axios';

const userNavigation = [
    {
        name: 'Konto',
        href: route('user.edit'),
        icon: '<svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M16 9C16 11.2091 14.2091 13 12 13C9.79086 13 8 11.2091 8 9C8 6.79086 9.79086 5 12 5C14.2091 5 16 6.79086 16 9ZM14 9C14 10.1046 13.1046 11 12 11C10.8954 11 10 10.1046 10 9C10 7.89543 10.8954 7 12 7C13.1046 7 14 7.89543 14 9Z" fill="currentColor" /><path fill-rule="evenodd" clip-rule="evenodd" d="M12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1ZM3 12C3 14.0902 3.71255 16.014 4.90798 17.5417C6.55245 15.3889 9.14627 14 12.0645 14C14.9448 14 17.5092 15.3531 19.1565 17.4583C20.313 15.9443 21 14.0524 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12ZM12 21C9.84977 21 7.87565 20.2459 6.32767 18.9878C7.59352 17.1812 9.69106 16 12.0645 16C14.4084 16 16.4833 17.1521 17.7538 18.9209C16.1939 20.2191 14.1881 21 12 21Z" fill="currentColor" /></svg>'
    },
    {
        name: 'Logga ut',
        onClick: async () => {
            await axios.post(route('logout'));
            document.location = route('home');
        },
        icon: '<svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="currentColor"><path fill-rule="evenodd" clip-rule="evenodd" d="M11.02 3.77v1.56l1-.99V2.5l-.5-.5h-9l-.5.5v.486L2 3v10.29l.36.46 5 1.72L8 15v-1h3.52l.5-.5v-1.81l-1-1V13H8V4.71l-.33-.46L4.036 3h6.984v.77zM7 14.28l-4-1.34V3.72l4 1.34v9.22zm6.52-5.8H8.55v-1h4.93l-1.6-1.6.71-.7 2.47 2.46v.71l-2.49 2.48-.7-.7 1.65-1.65z"/></svg>'
    },
]

export default {
    components: {
        Menu,
        MenuItem,
        MenuItems,
        MenuButton
    },
    setup() {
        return {
            userNavigation
        }
    },
    props: {
        userProfileImgUrl: String,
        items: Object
    },
    data: function(){
        return {
            circleClasses: 'w-10 h-10 md:w-8 md:h-8',
            notificationCount: 0,
            inboxMessageCount: 0,
            showNotificationSlideout: false,
            showInboxSlideout: false,
        }
    },
    methods: {
        openNotifications() {
            this.showNotificationSlideout = true
        },
        openInboxSlideout() {
            // This is disabled until we have multiple inboxes
            // this.showInboxSlideout = true
            window.location.href = route('messages.inbox');
        },
        async onUserNavigationClick(item) {
            if (item.href) { return; }

            if (item.onClick) {
                await item.onClick();
            }
        },
        setNotificationCount(count){
            this.notificationCount = count;
            this.$emit('updateNotificationCount', count);
        },
        getNotificationCount: async function() {
            let response = await axios.get(route('notifications'));
            this.notificationCount = response.data.unread_count;
        },
        getInboxMessageCount: async function() {
            let response = await axios.get(route('messages.unread-count'));
            this.inboxMessageCount = response.data.total;
        },
        markAllAsRead(){
            this.$refs.notificationIndex.markAllAsRead()
        }
    },
    mounted: function(){
        this.getNotificationCount();
        this.getInboxMessageCount();
    }
}
</script>
<style scoped>
    .circle-icon {
        position: relative;
        /* width: 25%;
        padding-bottom: 25%; */
    }

    .circle-icon > * {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .pb-25p {
        padding-bottom: 25%;
    }
</style>
