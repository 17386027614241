<script setup>
    import {ref} from 'vue'

    import {
        ChevronDownIcon,
        MinusIcon,
        StopIcon
    } from '@heroicons/vue/outline'
    import SidebarNavigationSubItem from "./sidebarNavigationSubItem.vue";

    const props = defineProps({
        item: {
            type: Object,
            required: true
        },
        isLast: {
            type: Boolean,
            required: false,
            default: false
        }
    })


    const toggleExpanded = () => {
        isExpanded.value = !isExpanded.value
    }
    const isActive = props.item.current;
    const isExpanded = ref(false)
</script>
<template>
    <li v-if="(item.type != 'heading') && Object.keys(item).length > 0">
        <div
            class="relative flex justify-between group"
            :class="item.active ? 'opacity-100' : 'opacity-50'"
        >
            <a
                :href="item.active ? item.href : '#'"
                :aria-current="isActive ? 'page' : undefined"
                class="flex space-x-1 items-start pt-1.5"
            >
                    <div
                        class="flex items-center justify-center ml-2 mr-px translate-y-px text-center transition-all w-5 h-5"
                        :class="isActive ? 'text-wlPrimary' : 'text-gray-500'"
                    >
                        <i :class="item.icon" class="w-4 h-4 leading-4"></i>
                    </div>
                <div>
                    <div
                        class="text-sm text-wlLink"
                        :class="item.active ? 'hover:underline' : ''"
                    >
                        {{ _mft(item.name) }}
                    </div>
                </div>
            </a>
            <button
                v-if="(item.subItems?.length > 0) && item.active"
                class="relative focus:outline-none group"
                v-on:click="toggleExpanded"
            >
                <span class="relative block w-6 h-4 overflow-hidden">
                    <ChevronDownIcon
                        v-if="!isActive"
                        class="absolute w-4 h-4 ml-1 transition-all transform right-2 stroke-gray-300 group-focus:stroke-gray-500"
                        :class="[isActive || isExpanded ? '-top-6' : '']"
                    />
                    <MinusIcon
                        v-if="!isActive"
                        class="absolute w-4 h-4 ml-1 transition-all transform right-2 stroke-gray-300 group-focus:stroke-gray-500"
                        :class="[isActive || isExpanded ? '' : '-top-6']"
                    />
                </span>
            </button>
        </div>
        <div
            v-if="(!isActive && !isExpanded) && item.byline"
            class="pl-7 pr-2 -mt-0.5 text-xxs text-gray-400"
        >
            {{ item.byline }}
        </div>
        <ul
            v-if="isActive || isExpanded"
            class="pb-2"
        >
            <SidebarNavigationSubItem
                v-for="item in item.subItems"
                v-bind:key="item.name"
                :item="item"
            />

            <li
                v-if="item.primaryCallToAction"
                class="relative pl-8 pr-2"
                :class="item.subItems?.length > 0 ? 'pt-1' : 'pt-2'"
            >
                <a
                    :href="item.primaryCallToAction.href"
                    class="relative z-30 block text-xs w-full -translate-x-0.5"
                >
                    <button-secondary
                        size="xs"
                        class="-ml-3 w-full justify-center"
                    >
                        <span class="text-xs">{{ _mft(item.primaryCallToAction.name) }}</span>
                    </button-secondary>
                </a>
            </li>
        </ul>
<!--        <hr-->
<!--            v-if="!isLast"-->
<!--            class="w-3/4 mx-auto -mb-2"-->
<!--        >-->
    </li>
    <li v-if="(item.type === 'heading') && Object.keys(item).length > 0">
        <div class="pr-4 -mb-1 pb-1 pl-2 text-center border-b border-gray-100 text-xs font-semibold text-gray-400 mt-4">
            {{ _mft(item.name) }}
        </div>
    </li>
</template>
