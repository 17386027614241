<script setup>
import { computed } from 'vue';
import MediaBankEntry from './util/media-bank-entry.vue';

const props = defineProps({
    containerRef: {
        type: String,
        required: true
    },
    uuid: {
        type: String,
        require: true
    },
    content: {
        type: Object,
        required: true,
    }
})

const title = computed(() => props.content.title);
const links = computed(() => props.content.links);
</script>
<template>
    <div>
        <MediaBankEntry
            :container-ref="containerRef"
            :block-uuid="uuid"
            :block-content="content"
            :media-bank-entry-id="content.media_bank_entry_id"
            property-name="media_bank_entry_id"
        >
            <template #entry="{ entry }">
                <div
                    class="flex justify-center w-full bg-center bg-cover align-center"
                    :style="{ backgroundImage: 'url(' + entry.url + ')' }"
                >
                    <header
                        class="relative flex flex-col items-center justify-center w-full h-full"
                    >
                        <div class="absolute inset-0 backdrop"></div>

                        <div class="relative z-10 flex flex-col items-center justify-center p-4 header-text">
                            <h1 class="text-4xl text-center md:text-5xl lg:text-6xl">{{ title }}</h1>
                            <div class="flex flex-col items-center justify-end w-full p-6 space-y-4 font-medium text-md md:text-lg md:flex-col md:justify-center md:space-y-8">

                                <div v-for="(link, idx) in links" :key="idx" class="text-center">
                                    <a
                                        :href="link.href"
                                        class="block px-4 py-2 text-center text-black border-2 border-white md:py-1 lg:p-4 bg-secondary rounded-custom"
                                        v-if="link.href"
                                    >
                                        {{link.text}}
                                    </a>
                                    <span
                                        class="px-4 py-2 mt-4 text-2xl text-center text-black md:py-1 lg:p-6"
                                        v-if="!link.href"
                                    >
                                        {{link.text}}
                                    </span>
                                </div>

                            </div>

                        </div>
                    </header>
                </div>
            </template>
        </MediaBankEntry>
    </div>
</template>

<style scoped>
    header {
        min-height: 100vw;
        padding-top: 10vw;
        padding-bottom: 10vw;
    }
    @media screen and (min-width: 640px) {
        header {
            min-height: 40vw;
            padding-top: 5vw;
            padding-bottom: 5vw;
        }
    }
</style>
