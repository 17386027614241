<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" style="z-index: 999999999999;" class="fixed inset-0 z-50 overflow-hidden" @close="close()">
      <div class="absolute inset-0 overflow-hidden">
        <TransitionChild as="template" enter="ease-in-out duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-500" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="absolute inset-0 transition-opacity bg-gray-800 bg-opacity-90" />
        </TransitionChild>

        <div class="fixed inset-y-0 right-0 flex max-w-full pl-10">
          <TransitionChild as="template" enter="transform transition delay-75 ease-in-out duration-300 sm:duration-300" enter-from="translate-x-full" enter-to="translate-x-0" leave="transform transition delay-75 ease-in-out duration-300 sm:duration-300" leave-from="translate-x-0" leave-to="translate-x-full">
            <div class="w-screen max-w-lg">
              <div class="flex flex-col h-full overflow-y-scroll bg-white shadow-xl">
                <div
                  class="px-4 py-6 text-wlPrimaryContrast shadow-lg sm:px-6 bg-wlPrimary"
                >
                  <div class="flex items-start justify-between">
                    <slot name="header"></slot>
                    <div class="flex items-center ml-3 h-7">
                      <button type="button" class="text-white rounded-md hover:text-gray-200 focus:outline-none" @click="close()">
                        <span class="sr-only">Stäng</span>
                        <XIcon class="w-6 h-6" aria-hidden="true" />
                      </button>
                    </div>
                  </div>
                </div>
                <div class="relative flex-1 px-4 sm:px-6">
                  <!-- Replace with your content -->
                  <div class="absolute inset-0">
                    <div class="flex flex-col h-full" aria-hidden="true">
                      <div class="flex-1 h-0 px-4 overflow-y-auto sm:px-6">
                        <div
                          v-if="hasBodySlot"
                          class="pt-6"
                        >
                          <slot name="body"></slot>
                        </div>
                      </div>
                      <div
                        v-if="hasFooterSlot"
                        class="flex justify-end flex-shrink-0 px-4 py-4 bg-gray-200 shadow-lg sm:px-6"
                      >
                        <slot name="footer"></slot>
                      </div>
                    </div>
                  </div>
                  <!-- /End replace -->
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { XIcon } from '@heroicons/vue/outline'

export default {
  components: {
    Dialog,
    DialogOverlay,
    TransitionChild,
    TransitionRoot,
    XIcon,
  },
  props: {
    open: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    close(){
      this.$emit('update:open', false)
    }
  },
  computed: {
    hasHeaderSlot () {
      return !!this.$slots.header
    },
    hasBodySlot () {
      return !!this.$slots.body
    },
    hasFooterSlot () {
      return !!this.$slots.footer
    },
  },
}
</script>
