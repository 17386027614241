<script setup>
import { ref, watchEffect } from 'vue';
import axios from 'axios';

const props = defineProps({
    slug: {
        type: String,
        required: true
    }
});

const isLoaded = ref(false);
const formData = ref(null);

watchEffect(async () => {
    if (!props.slug) {
        isLoaded.value = false;
        formData.value = null;
    }

    isLoaded.value = false;
    const response = await axios.get(route('form-submissions.create', props.slug));
    formData.value = response.data;
    isLoaded.value = true;
});

</script>

<template>
    <div>
        <axios-form v-if="isLoaded && formData" method="post" :action="route('form-submissions.store', formData.slug)" enctype="multipart/form-data">
            <page-builder-view
                :container-ref="formData.container_ref"
                :content="formData.content"
                :design="formData.design"
            ></page-builder-view>

            <div class="text-center mb-3">
                <button-primary type="submit" class="inline-block">
                    {{ _mft('form:submit') }}
                </button-primary>
            </div>
            <template #success-content>
                <div class="w-full flex justify-center items-center mt-4 md:mt-6 lg:mt-12">
                    <p class="flex justify-center items-center p-5 shadow-custom rounded-custom tint-background">
                        {{ _mft('form:submitConfirmation') }}
                    </p>
                </div>
            </template>
        </axios-form>
        <div v-else-if="isLoaded" class="w-full flex justify-center items-center mt-4 md:mt-6 lg:mt-12">
            <p class="flex justify-center items-center p-5 shadow-custom rounded-custom tint-background">
                {{ _mft('form:notFound') }}
            </p>
        </div>
        <mf-spinner-medium v-else class="w-12 h-6"></mf-spinner-medium>
    </div>
</template>
