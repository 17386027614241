<template>
    <ul role="list" class="grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">

        <li
            class="flex flex-col col-span-1 text-center bg-white divide-y divide-gray-200 rounded-lg shadow-xl"
            v-for="booking in bookingList"
            v-bind:key="booking.id"
        >
            <div class="flex flex-col flex-1 p-8">
                <img class="flex-shrink-0 object-cover object-center w-32 h-32 mx-auto rounded-full shadow" :src="this.coachView ? booking.client_profile_img : booking.coach_profile_img">

                <h3 class="mt-6 text-sm font-medium text-gray-900">
                    {{ this.coachView ? booking.client_name : booking.coach_name }}
                    <a
                        class="block text-xs"
                        :href="'mailto:' + this.coachView ? booking.client_email : booking.coach_email">{{ this.coachView ? booking.client_email : booking.coach_email }}</a>
                </h3>
                <dl class="flex flex-col justify-between flex-grow mt-1">
                    <dd class="mt-3">
                        <span
                            v-if="booking.next_booking"
                            class="px-2 py-1 text-xs font-medium rounded-full text-myflowGreen-900 bg-myflowGreen-100"
                        >
                            {{ booking.next_booking }}
                        </span>
                        <span
                            v-else
                            class="px-2 py-1 text-xs font-medium rounded-full text-myflowRed-800 bg-myflowRed-100"
                        >
                            {{ _mft('coaching:session.noTimeBooked') }}
                        </span>
                    </dd>
                </dl>
            </div>
            <div>
                <div class="flex -mt-px divide-x divide-gray-200">
                    <div class="flex flex-1 w-0">
                        <a :href="route('coaching.show', booking.coach_id)" class="relative inline-flex items-center justify-center flex-1 w-0 py-4 -mr-px text-sm font-medium text-gray-700 border border-transparent rounded-bl-lg hover:text-gray-500">
                            <span class="ml-3">
                                {{ booking.usage }}
                                <small><br>{{ _mft('coaching:session.sessionsBooked') }}</small>
                            </span>
                        </a>
                    </div>
                    <div class="flex flex-1 w-0 -ml-px">
                        <a :href="'/coach/relationship/'+booking.coach_id+'/'+booking.client_id" class="relative inline-flex items-center justify-center flex-1 w-0 py-4 text-sm font-medium border border-transparent rounded-br-lg text-myflowPurple hover:text-myflowPurple-500">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 text-myflowPurple-400">
                                <path fill-rule="evenodd" d="M1 6a3 3 0 013-3h12a3 3 0 013 3v8a3 3 0 01-3 3H4a3 3 0 01-3-3V6zm4 1.5a2 2 0 114 0 2 2 0 01-4 0zm2 3a4 4 0 00-3.665 2.395.75.75 0 00.416 1A8.98 8.98 0 007 14.5a8.98 8.98 0 003.249-.604.75.75 0 00.416-1.001A4.001 4.001 0 007 10.5zm5-3.75a.75.75 0 01.75-.75h2.5a.75.75 0 010 1.5h-2.5a.75.75 0 01-.75-.75zm0 6.5a.75.75 0 01.75-.75h2.5a.75.75 0 010 1.5h-2.5a.75.75 0 01-.75-.75zm.75-4a.75.75 0 000 1.5h2.5a.75.75 0 000-1.5h-2.5z" clip-rule="evenodd" />
                                </svg>

                            <span class="ml-3">{{ _mft('shared:showMore') }}</span>
                        </a>
                    </div>
                </div>
            </div>
        </li>

    </ul>
</template>
<script>

export default {
    props: {
        bookings: Array,
        coachView: Boolean
    },
    data() {
        return {
            bookingList: this.bookings,
            isCoach: this.coachView
        }
    },
    mounted() {
    },
    methods: {
    },
}
</script>
