<script setup>
import {ref, watch} from "vue";

const intercomOpen = ref(false)

const openIntercom = () => {
    window.Intercom('show')
}

const closeIntercom = () => {
    window.Intercom('hide')
}

watch(intercomOpen, (newVal) => {
    if (newVal) {
        openIntercom()
    } else {
        closeIntercom()
    }
})

</script>
<template>
    <div
        id="teleport-target-toolbar"
        class="fixed bottom-4 right-4 z-modal flex flex-row-reverse space-x-2"
    >
        <button-secondary
            @click="intercomOpen = !intercomOpen"
            size="sm"
            class="ml-2"
        >
            <i class="fa-light fa-message text-xl text-wlPrimary"></i>
        </button-secondary>
    </div>
</template>
