<template>
    <mf-table v-if="processes !== null" :data="processes" :columns="columns" :filters="filters" :searchable="searchable">
        <template #column-name="{ row }">
            {{ row.name }}
        </template>
        <template #column-description="{ row }">
            <div v-html="row.description"></div>
        </template>
        <template #column-needs_analysis="{ row }">
            {{ row.needs_analysis }}%<br>
            <span class="text-gray-500">{{ row.needs_analysis_time }}</span>
        </template>
        <template #column-booking_rate="{ row }">
            {{ row.booking_rate }}%<br>
            <span class="text-gray-500">{{ row.booking_rate_time }}</span>
        </template>
        <template #column-signup_rate="{ row }">
            {{ row.signup_rate }}%<br>
            <span class="text-gray-500">{{ row.signup_rate_time }}</span>
        </template>
        <template #column-conversion_rate="{ row }">
            {{ row.conversion_rate }}%
        </template>
        <template #actions="{ row }">
            <a
                :href="route('sales-processes.view', row.id)"
                class="mr-2 text-sm font-medium text-right text-myflowPurple-600 hover:text-myflowPurple-900 myflow-basic-button"
            >{{ _mft('shared:showMore') }}</a>
        </template>
    </mf-table>
    <div v-else class="text-center">
        <mf-spinner-medium
          class="w-24 h-12"
        />
    </div>
</template>

<style lang="scss" scoped>
</style>

<script setup>
import { ref, toRefs, watch, onMounted } from 'vue';
import axios from 'axios';

const props = defineProps({
    url: { type: String, required: true }
});

const { url } = toRefs(props);

const processes = ref(null);
const updateProcesses = async () => {
    const res = await axios.get(url.value + '?cachebust');
    processes.value = res.data.data;
}
watch(url, updateProcesses);
onMounted(updateProcesses);

const columns = [
    { label: 'Namn', key: 'name', sort: 'name' },
    { label: 'Skickad till', key: 'count', sort: 'count' },
    { label: 'Målgrupp', key: 'description', sort: 'description' },
    { label: '% Behovsanalys', key: 'needs_analysis', sort: 'needs_analysis' },
    { label: '% Möte', key: 'booking_rate', sort: 'booking_rate' },
    { label: '% Registrering', key: 'signup_rate', sort: 'signup_rate' },
    { label: '% Köp', key: 'conversion_rate', sort: 'conversion_rate' },
];
const searchable = [
    'name',
    'count',
    'description',
];
const filters = [
]



</script>
