<script setup>
import { computed } from 'vue'

const props = defineProps({
    content: {
        type: Object,
        required: true
    },
});

const formSlug = computed(() => props.content?.form_slug);
</script>

<template>
    <forms-view :slug="formSlug"></forms-view>
</template>
