<template>
  <li
    class="relative flex transition-all w-4/5"
    :class="[ 'col-start-' + bookedSpan.weekday ]"
    :style="{gridRow: (((bookedSpan.startQuarter - firstAvailableQuarter) + 1) * 3) - 1 + ' / span ' + ((bookedSpan.endQuarter - bookedSpan.startQuarter) * 3) }"

  >
    <div
      class="group absolute inset-1 flex flex-col rounded-lg bg-myflowPurple-800 text-xs leading-5 hover:bg-myflowPurple-900 cursor-pointer group"
      :class="[open ? 'overflow-y-visible' : 'overflow-y-auto']"
      v-on:click="toggleOpen"
    >
      <svg
        class="w-4 h-4 text-gray-300 goup-hover:text-gray-200 absolute top-2 right-2 fill-current"
        version="1.1" id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 460 460"
        style="enable-background:new 0 0 460 460;"
        xml:space="preserve"
      >
        <g id="XMLID_1055_">
          <g>
            <path d="M230,0C102.975,0,0,102.975,0,230s102.975,230,230,230s230-102.974,230-230S357.025,0,230,0z M268.333,377.36
              c0,8.676-7.034,15.71-15.71,15.71h-43.101c-8.676,0-15.71-7.034-15.71-15.71V202.477c0-8.676,7.033-15.71,15.71-15.71h43.101
              c8.676,0,15.71,7.033,15.71,15.71V377.36z M230,157c-21.539,0-39-17.461-39-39s17.461-39,39-39s39,17.461,39,39
              S251.539,157,230,157z" />
          </g>
        </g>
      </svg>

      <div class="order-1 mt-2 text-white px-2">
          {{ _mft('coaching:session.booked') }}
        <div
          v-if="open"
          class="absolute bottom-full -left-2 -right-2 text-gray-500 rounded-md p-2 bg-white transition-all shadow-sm"
          :class="[animated ? 'translate-y-2 opacity-100 scale100' : 'translate-y-12 opacity-0 scale-90']"
        >
          <div class="relative">
            <strong>{{ bookedSpan.booking.user.name }}</strong><br>
            {{ bookedSpan.booking.begins_at.time }}-{{ bookedSpan.booking.ends_at.time }}<br>
            {{ bookedSpan.booking.product.title }}<br>
            <div class="w-4 h-4 absolute rotate-45 bg-white top-full left-1/2 -translate-x-1/2"></div>
          </div>
        </div>
        <div
          class="text-gray-400"
        >
          {{ bookedSpan.booking.user.name }}
        </div>
      </div>
    </div>
  </li>
</template>
<script>

export default {
  props: {
    bookedSpan: Object,
    firstAvailableQuarter: Number
  },
  data() {
    return {
      open: false,
      animated: false
    }
  },
  methods: {
    toggleOpen(){
      let self = this
      if( this.open ){
        self.animated = false
        setTimeout(function(){
          self.open = false
        }, 151)
      } else {
        this.open = true
        setTimeout(function(){
          self.animated = true
        },10)
      }


    }
  }
}
</script>
