<template>
    <div class="flex flex-row justify-center w-full align-center">
        <div class="flex flex-col justify-center w-auto max-w-3xl text-center">
            <h2 class="mb-2 text-xl md:text-2xl lg:text-3xl">{{title}}</h2>
            <div class="mb-2" v-html="body"></div>
            <div class="flex flex-col -mx-4 space-y-1 md:block">
                <a
                    v-for="(link, idx) in links"
                    :key="idx"
                    :href="link.href"
                    class="inline-block px-4 py-2 mx-4 overflow-hidden rounded-custom shadow-custom"
                    target="_blank"
                >{{link.text}}</a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: { type: String, required: true },
        body: { type: String, required: true },
        links: { type: Array, required: true },
    },
    data() {
        return {
            navbtn : this.navigationButtons
        }
    },
};
</script>

<style scoped>
.collapse-btn {
    padding: 0.8rem 1.5rem;
    height: fit-content;
    width: fit-content;
    font-weight: bold;
    cursor: pointer;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
</style>
