<script setup>
    import { computed, onMounted, ref } from 'vue'
    import { useNotificationStore } from '@/stores/NotificationStore.js'
    const notificationStore = useNotificationStore()

    const props = defineProps({
        articleId: {
            type: Number,
            required: false,
            default: null
        },
    })

    const article = ref(null);
    const categories = ref([]);
    const category_id = ref(null);
    const loading = ref(true);

    const loadCategories = () => {
        axios.get(route('support.admin.article-category.index'))
            .then(response => {
                categories.value = response.data.categories;
            })
            .catch(error => {
                console.log(error);
            });
    }

    const loadArticle = () => {
        loading.value = true
        if (props.articleId === null) {
            axios.get(route('support.admin.article.create'))
                .then(response => {
                    article.value = response.data.article;
                    article.value.block_editor_content = response.data.block_editor_content;
                    loading.value = false
                })
                .catch(error => {
                    console.log(error);
                });
        } else {
            axios.get(route('support.admin.article.edit', props.articleId))
                .then(response => {
                    article.value = response.data.article;
                    loading.value = false
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }

    const updateContent = (content) => {
        article.value.block_editor_content.content = content;
    }

    const save = () => {
        if (article.value.id === null) {
            axios.post(route('support.admin.article.store'), article.value)
                .then(response => {
                    window.location.href = response.data.redirect;
                    notificationStore.addNotification({
                        type: 'success',
                        message: 'Artikel skapad!',
                        dismissAfter: 2000,
                    })
                })
                .catch(error => {
                    notificationStore.addNotification({
                        type: 'error',
                        title: 'Åh nej, ett fel uppstod',
                        message: 'Något gick snett när artikeln skulle skapas.',
                        dismissAfter: 5000,
                    })
                });
        } else {
            axios.put(route('support.admin.article.update', props.articleId), article.value)
                .then(response => {
                    notificationStore.addNotification({
                        type: 'success',
                        message: 'Artikel uppdaterad!',
                        dismissAfter: 2000,
                    })
                })
                .catch(error => {
                    notificationStore.addNotification({
                        type: 'error',
                        title: 'Åh nej, ett fel uppstod',
                        message: 'Något gick snett när artikeln skulle sparas.',
                        dismissAfter: 5000,
                    })
                });
        }
    }

    const selectableCategories = computed(() => {
        return categories.value.map(category => {
            return {
                label: category.title,
                value: category.id,
            }
        })
    })

    const title = computed(() => {
        if (article.value.id === null) {
            return 'Ny artikel';
        } else {
            return article.value.title;
        }
    })

    onMounted(() => {
        loadCategories();
        loadArticle();
    });

</script>
<template>
    <div v-if="article === null">

    </div>
    <div v-if="loading" class="text-center w-full mt-12">
        <mf-spinner-medium
        class="w-24 h-12"
        />
    </div>
    <div
        v-else
        class="w-full"
    >
        <a :href="route('support.admin.article.index')">Tillbaka till artikellistan</a>
        <div class="sticky top-0 z-50 flex justify-between p-4 mb-2 -ml-4 -mr-4 lg:px-8 bg-gray-50/80">
            <h1>{{ title }}</h1>
            <button
                class="myflow-basic-button myflow-basic-button--primary"
                @click="save"
            >Spara</button>
        </div>
        <card-basic
            class="flex flex-col justify-between"
        >
            <template v-slot:body>
                <input-text
                    label="Artikelns titel"
                    required
                    v-model:content="article.title"
                />
                <div class="gap-4 lg:grid lg:grid-cols-2">
                    <div>
                        <input-select
                            v-if="selectableCategories.length > 0"
                            v-model:content="article.support_article_category_id"
                            :options="selectableCategories"
                            label="Artikelkategori"
                        ></input-select>
                    </div>
                    <div>
                        <toggle-switch
                            label="Publicera"
                            v-model:content="article.is_published"
                            class="mt-8"
                        />
                    </div>
                </div>

                <input-block-editor
                    name="block_editor_content"
                    :content="article.block_editor_content.content"
                    :showVisibilityTools="false"
                    @update:content="updateContent"
                >
                </input-block-editor>
                <div id="teleport-target-block-editor-toolbar"></div>
            </template>
        </card-basic>
    </div>
</template>
