<script setup>
  import { storeToRefs } from 'pinia'
  import { useNotificationStore } from '@/stores/NotificationStore.js'
  import flashMessage from './flashMessage.vue'

  const notificationStore = useNotificationStore()
  const { notifications } = storeToRefs(notificationStore)

    const dismissNotification = (id) => {
        notificationStore.dismissNotification(id)
    }
</script>

<template>
    <div class="fixed z-[1001] top-8 right-8">
        <ul class="relative space-y-2">
            <li
                v-for="m in notifications"
                :key="m.id"
            >
                <flash-message
                    :message="m"
                    @selfDestruct="dismissNotification"
                ></flash-message>
            </li>
        </ul>
    </div>
</template>
