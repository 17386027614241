<template>
    <div class="overflow-hidden bg-myflowPurple-800 flex justify-center">
        <div class="flex flex-col justify-center text-white">
            <div class="w-full p-4 md:p-16 lg:p-16">
                <h2 class="text-4xl text-center text-white">
                    Experter på <b>The Power of Learning</b>
                </h2>
            </div>

            <!-- ExpertBlock on mobile when the div is collapsed -->

            <div class="block md:hidden lg:hidden" v-if="collapsed">
                <div class="z-20 h-screen">
                    <event-expert-info-component
                        v-for="expert in limitArray(experts, 4)"
                        v-bind:key="expert.name"
                        :expert="expert"
                    />
                </div>

                <section
                    class="flex justify-center items-center h-48 w-full z-30 bg-gradient-to-t from-white"
                >
                    <button
                        class="block collapse-btn drop-shadow-lg bg-myflowYellow-700"
                        v-on:click="collapseExpand"
                    >
                        <h2>Visa alla experter</h2>
                    </button>
                </section>
            </div>

            <div>
                <!-- ExpertBlock on mobile when the div is extended -->

                <div class="block md:hidden lg:hidden" v-if="!collapsed">
                    <event-expert-info-component
                        v-for="expert in experts"
                        v-bind:key="expert.name"
                        :expert="expert"
                    />
                </div>

                <!-- ExpertBlock when not on mobile -->

                <div class="hidden md:block lg:block">
                    <event-expert-info-component
                        v-for="expert in experts"
                        v-bind:key="expert.name"
                        :expert="expert"
                    />

                    <event-statistics-block />

                    <event-text-block-component v-bind="textBlockContent" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            collapsed: true,

            experts: [
                {
                    id: 1,
                    introText: 'MÖTESPLATSER FÖR LIVSLÅNGT LÄRANDE',
                    name: 'MIKAEL AHLSTRÖM',
                    expertise: 'ENTREPENÖR OCH INTERNATIONELL TALARE',
                    expertInfo: 'Under föreläsningen beskriver Mikael den stora samhällsutmaning - eller "samhällsmöjlighet" som han kallar det - då utbildningar som tidigare räckte för 30 år i arbetslivet nu endast är aktuella i fem år. Mikael driver devisen: "Growing together - arbetsplatsen är platsen där vi lär" och han inspirerar oss att skapa en lärande kultur i arbetslivet. Förändringstakten i omvärlden ökar exponentiellt med digitala trender, tekniska fenomen och nya beteendemönster som nu starkt formar den framtid vi skall leva och verka i. Det är nu arbetsvardagens mötesplatser med rätt design kan stödja individernas kompetensbehov och livslånga lärande.',
                    expertIntro: 'Mikael Ahlström är ofta anlitad som talare internationellt kring digital transformation. Han brinner för att designa metoder för livslångt lärande baserat på evidens och har också suttit i regeringens samverkansgrupp för kompetensförsörjning. Mikael är entreprenören som sedan 90-talet varit med och startat över 20 bolag med digitalisering som superkraft. Är grundare av co-working stället The Park med 1200 medlemmar och delägare i bland annat medieskolan Hyper Island och startups som live commerce tjänsten Bambuser och den obemannade butikskedjan Lifvs.',
                    flexSetting: 'lg:flex-row',
                    img: 'https://myflow.fra1.digitaloceanspaces.com/tpol/compressed/Mikael_Ahlstrom.webp',
                },
                {
                    id: 2,
                    introText: 'FRAMTIDSSPANING - LEARNING & GROWTH',
                    name: 'ANNA CARLSSON',
                    expertise: 'DIGITALISERINGSCOACH OCH UTBILDARE INOM HR',
                    expertInfo: 'Anna är utbildare, rådgivare och inspiratör kring framtidens HR där hon tar sin utgångspunkt i vad digitala verktyg kan möjliggöra för nya sätt att möta HR:s utmaningar. Anna driver även HR Digitaliseringspodden sedan 2019. Marknaden inom digitala lösningar utvecklas i en explosiv takt. Under de senaste åren har speciellt området Learning & Development förändrats i grunden och dagens möjligheter är oändliga i hur du kan stödja din organisation på sin utvecklingsresa.',
                    expertIntro: 'Under föreläsningen tar vi utgångspunkt i ordet Skills (kompetens, färdigheter & förmågor) och tittar närmare på hur marknaden nu diskuterar att vi byter till Learning & Growth eller även People & Growth för att tydliggöra att vi behöver tänka hela individens möjligheter för att kunna möta våra kompetensutmaningar.',
                    flexSetting: 'lg:flex-row-reverse',
                    img: 'https://myflow.fra1.digitaloceanspaces.com/tpol/compressed/Anna_Carlsson.webp',
                },
                {
                    id: 3,
                    introText: 'SÅ SKAPAR OCH MÄTER DU EFFEKT AV LÄRANDE',
                    name: 'MATTI OLOFSSON',
                    expertise: 'ENTREPENÖR MED EN PASSION FÖR LÄRANDEPROCESSER',
                    expertInfo: 'Matti har arbetat i över 25 år med digitalt lärande med fokus på hur man skapar en effektiv läroprocess med en mätbar effekt. De senaste 7 åren har Matti varit Sverigechef för Junglemap där 30% av Sveriges alla arbetstagare gått deras NanoLearningutbildningar.',
                    expertIntro: 'Matti föreläser om vad forskningen säger om hur vi människor lär oss. Vilka faktorer ger ett effektivare lärande och hur kan vi mäta effekten av utbildningar med konkreta resultat från forskning och verkliga case.',
                    flexSetting: 'lg:flex-row',
                    img: 'https://myflow.fra1.digitaloceanspaces.com/tpol/compressed/Matti_Olofsson1.webp',
                },
                {
                    id: 4,
                    introText: 'LÄR DIG MER PÅ KORTARE TID',
                    name: 'ANNA TEBELIUS BODIN',
                    expertise: 'FOLKBILDARE OM HJÄRNAN',
                    expertInfo:
                        'Anna är pedagog, föreläsare och författare, utbildad vid Harvard University, där hon även assisterade forskare. Hon har gett över 800 föreläsningar om psykologin bakom lärande, psykiskt välbefinnande och självledarskap. Våren 2020 hedrades Anna med årets Mensa-pris.',
                    expertIntro:
                        'Få människor förstår kraften av lärande som Anna gör. Hon tar oss med på en resa där vår fyrtiotusenåriga hjärna får spela huvudrollen. Hur kan vi förvalta möjligheten vi har fått med vår välutvecklade hjärna? Anna guidar oss genom stresshantering, motivationsfaktorer och ger oss råd för att optimera lärandet.',
                    flexSetting: ' lg:flex-row-reverse',
                    img: 'https://myflow.fra1.digitaloceanspaces.com/tpol/compressed/Anna_Tebelius_Bodin_1.webp',
                },
                
            ],

            textBlockContent: {
                content:
                    'Kickstarta en lärande kultur i företaget – för att behålla talanger, bygga konkurrenskraft och nå tillväxtmålen!',
            },
        };
    },
    async mounted() {},
    methods: {
        collapseExpand() {
            if (this.collapsed === true) {
                this.collapsed = false;
            } else {
                this.collapsed = true;
            }
        },
        limitArray(arr, length = 8) {
            if (arr && arr.length) {
                if (length == -1) {
                    return arr;
                }
                if (length > arr.length) {
                    return arr;
                }

                return arr.slice(0, length);
            }

            return null;
        },
    },
};
</script>

<style scoped>
/* @import "resources/sass/_buttons.scss"; */

.collapse-btn {
    padding: 0.8rem 1.5rem;
    border-radius: 25px;
    height: fit-content;
    width: fit-content;
}
</style>
