<script setup>
import { ref } from 'vue';

const scrollingPossible = ref(true)

window.addEventListener("scroll", () => {
    scrollingPossible.value = document.body.scrollHeight > (window.scrollY + window.innerHeight + 60);
});

</script>

<template>
    <div
        class="fixed left-1/2 text-center fill-green-400 bg-white shadow-2xl py-2 px-4 rounded-full transition-all"
        :class="scrollingPossible ? 'bottom-8' : '-bottom-24'"
    >
        <icon-chevron class="w-8 h-8"></icon-chevron>
    </div>
</template>

<style scoped>
div {
    animation: keep-scrolling-animation 4s infinite;
}

@keyframes keep-scrolling-animation {
    0% {
        transform: translateY(0) translateX(-50%);
    }
    50% {
        transform: translateY(0.5rem) translateX(-50%);
    }
    100% {
        transform: translateY(0) translateX(-50%);
    }
}

</style>
