<script setup>
import {ref, watch} from "vue";

const emit = defineEmits(['update:content'])

const props = defineProps({
    content: {
        type: String,
        required: false
    },
    label: {
        type: String,
        required: false
    },
    presets: {
        type: Array,
        required: false,
        default: []
    },
    colCount: {
        type: Number,
        required: false,
        default: 1
    }
})

const layout = ref(props.content)

const setLayout = (l, requiredCols) => {
    if (requiredCols === null || requiredCols === props.colCount) {
        layout.value = l
    }
}

watch(layout, () => {
    emit('update:content', layout.value)
})

</script>
<template>
    <div class="mb-4">
        <ul class="flex space-x-1">
            <li>
                <button-base
                    size="sm"
                    :style-type="layout === null ? 'primary' : 'secondary'"
                    @click="setLayout(null, null)"
                >Auto</button-base>
            </li>
            <li>
                <button-base
                    v-if="colCount === 2"
                    size="sm"
                    :style-type="layout === '4|1+3' ? 'primary' : 'secondary'"
                    @click="setLayout('4|1+3', 2)"
                >
                    <div class="flex items-stretch h-4 space-x-px w-12">
                        <div class="h-full bg-gray-400 w-1/4"></div>
                        <div class="h-full bg-gray-400 w-3/4"></div>
                    </div>
                </button-base>
            </li>
            <li>
                <button-base
                    v-if="colCount === 2"
                    size="sm"
                    :style-type="layout === '3|1+2' ? 'primary' : 'secondary'"
                    @click="setLayout('3|1+2', 2)"
                >
                    <div class="flex items-stretch h-4 space-x-px w-12">
                        <div class="h-full bg-gray-400 w-1/3"></div>
                        <div class="h-full bg-gray-400 w-2/3"></div>
                    </div>
                </button-base>
            </li>
            <li>
                <button-base
                    v-if="colCount === 2"
                    size="sm"
                    :style-type="layout === '3|2+1' ? 'primary' : 'secondary'"
                    @click="setLayout('3|2+1', 2)"
                >
                    <div class="flex items-stretch h-4 space-x-px w-12">
                        <div class="h-full bg-gray-400 w-2/3"></div>
                        <div class="h-full bg-gray-400 w-1/3"></div>
                    </div>
                </button-base>
            </li>
            <li>
                <button-base
                    v-if="colCount === 2"
                    size="sm"
                    :style-type="layout === '4|3+1' ? 'primary' : 'secondary'"
                    @click="setLayout('4|3+1', 2)"
                >
                    <div class="flex items-stretch h-4 space-x-px w-12">
                        <div class="h-full bg-gray-400 w-3/4"></div>
                        <div class="h-full bg-gray-400 w-1/4"></div>
                    </div>
                </button-base>
            </li>

            <li>
                <button-base
                    v-if="colCount === 3"
                    size="sm"
                    :style-type="layout === '4|2+1+1' ? 'primary' : 'secondary'"
                    @click="setLayout('4|2+1+1', 3)"
                >
                    <div class="flex items-stretch h-4 space-x-px w-12">
                        <div class="h-full bg-gray-400 w-2/4"></div>
                        <div class="h-full bg-gray-400 w-1/4"></div>
                        <div class="h-full bg-gray-400 w-1/4"></div>
                    </div>
                </button-base>
            </li>
            <li>
                <button-base
                    v-if="colCount === 3"
                    size="sm"
                    :style-type="layout === '4|1+1+2' ? 'primary' : 'secondary'"
                    @click="setLayout('4|1+1+2', 3)"
                >
                    <div class="flex items-stretch h-4 space-x-px w-12">
                        <div class="h-full bg-gray-400 w-1/4"></div>
                        <div class="h-full bg-gray-400 w-1/4"></div>
                        <div class="h-full bg-gray-400 w-2/4"></div>
                    </div>
                </button-base>
            </li>
        </ul>
    </div>
</template>
