<script setup>

import {computed, onMounted, ref} from "vue";
import ButtonWithConfirmation from "../../components/buttons/buttonWithConfirmation.vue";
import { useNotificationStore } from '@/stores/NotificationStore.js'
const notificationStore = useNotificationStore()

const mediaBankEntries = ref(null)
const initialMediaBankEntryIds = ref([])
const filter = ref('')
const search = ref('')
const showingItem = ref({
    id: null,
    type: null,
    title: null,
    inUse: true,
    duration: null,
    usages: [],
})

const checkUsage = async (item) => {
    const res = await axios.get(route('mediaBank.entries.checkUsage', {id: item.id}))
    return res.data
}

const loadItems = async () => {
    mediaBankEntries.value = null;
    const response = await axios.get(route('mediaBank.entries.index'));
    mediaBankEntries.value = response.data.data
    if (initialMediaBankEntryIds.value.length === 0) {
        initialMediaBankEntryIds.value = mediaBankEntries.value.map((entry) => entry.id)
    }
}

const showItem = async (item) => {
    showingItem.value.id = item.id
    showingItem.value.type = item.type
    showingItem.value.title = item.title
    showingItem.value.duration = item.duration

    const usage = await checkUsage(item)
    showingItem.value.inUse = usage.in_use
    showingItem.value.usages = usage.usages
}

const closeItem = () => {
    showingItem.value = {
        id: null,
        type: null,
        title: null,
        inUse: true,
        duration: null,
        usages: [],
    }
}

const setFilter = (selectedFilter) => {
    filter.value = selectedFilter
}

const filteredMediaBankEntries = computed(() => {
    let res = mediaBankEntries.value
    if (!res) {
        return []
    }

    if (search.value !== '') {
        res = res.filter((entry) => entry.title.toLowerCase().includes(search.value.toLowerCase()))
    }

    if (filter.value === '') {
        return res
    }

    if (filter.value === 'other') {
        return res.filter((entry) => !['image', 'video', 'audio'].includes(entry.type))
    }

    return res.filter((entry) => entry.type === filter.value)
})

onMounted(() => {
    loadItems()
})

</script>
<template>
    <div>
        <frosted-bar>
            <div class="flex items-center justify-between space-x-4">
                <h1>{{ _mft('mediaBank:mediaLibrary') }}</h1>

                <div class="flex items-center space-x-4">

                </div>
            </div>
        </frosted-bar>

        <div class="mt-8 pb-24">
            <media-upload
                class="mx-auto max-w-4xl"
                :hide-on-completion="true"
                @upload-complete="loadItems"
            ></media-upload>


            <div class="md:flex items-center justify-end @container space-x-2">
                <button-bar
                    size="xs"
                    class="max-w-xs !mb-0"
                    :responsive="false"
                    :options="[
                        { label: _mft('mediaBank:filetype.all'), name: '' },
                        { label: _mft('mediaBank:filetype.image'), name: 'image' },
                        { label: _mft('mediaBank:filetype.video'), name: 'video' },
                        { label: _mft('mediaBank:filetype.sound'), name: 'audio' },
                        { label: _mft('mediaBank:filetype.file'), name: 'file' },
                    ]"
                    :selected-option-name="filter"
                    @select="setFilter($event.name)"
                />
                <input-text
                    class="!mb-0 mt-2 md:-mt-1"
                    input-class="text-xs"
                    :placeholder="_mft('shared:search')"
                    v-model:content="search"
                />

            </div>

            <list-basic>
                <list-basic-item
                    v-if="filteredMediaBankEntries.length === 0"
                >
                    <span class="text-gray-400">
                        {{ _mft('mediaBank:noMediaToShowHere') }}
                    </span>
                </list-basic-item>
                <list-basic-item
                    v-for="item in filteredMediaBankEntries"
                    :key="item.value"
                >
                    <div class="flex justify-between space-x-2">
                        <div class="flex items-center space-x-2">
                            <div class="text-lg text-gray-500 relative">
                                <i v-if="item.type === 'image'" class="fa-regular fa-image"></i>
                                <i v-else-if="item.type === 'video'" class="fa-regular fa-video"></i>
                                <i v-else-if="item.type === 'audio'" class="fa-regular fa-volume"></i>
                                <i v-else class="fa-regular fa-file"></i>

                                <span
                                    v-if="! Object.values(initialMediaBankEntryIds).includes(item.id)"
                                    class="h-4 leading-4 px-1 absolute -top-1 -left-2 bg-wlPrimary text-wlPrimaryContrast text-xxs rounded-md flex items-center opacity-80"
                                >{{ _mft('mediaBank:new') }}</span>
                            </div>
                            <div>
                                {{ item.title }}
                            </div>
                        </div>
                        <button-secondary
                            size="sm"
                            @click="showItem(item)"
                        >
                            {{ _mft('shared:action.show') }}
                        </button-secondary>
                    </div>
                </list-basic-item>
            </list-basic>
        </div>

        <slideout-with-slots
            v-if="showingItem.type !== null"
            @close="closeItem"
        >
            <template #body>
                <media-bank-entries-manage
                    :entry="showingItem"
                    @reload="loadItems"
                    @close="closeItem"
                />

            </template>
        </slideout-with-slots>

    </div>
</template>
