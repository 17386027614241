<template>
    <div
        class="grid gap-5 mb-3"
        :class="layout === 'half' ? 'grid-cols-1' : 'grid-cols-1 lg:grid-cols-2'"
    >
        <input-text
            inputtype="number"
            label="Video Id"
            :required="true"
            v-model:content="vimeoVideoId"
        />
    </div>
</template>
<script>
export default {
    props: {
        modelValue: {},
        layout: {
            type: String,
            default: 'full'
        }
    },
    emits: [
        'update:modelValue'
    ],
    data() {
        return {
            vimeoVideoId: null
        };
    },
    computed: {
      previewUrl() {
        if (!this.vimeoVideoId) { return; }

        return `https://player.vimeo.com/video/${this.vimeoVideoId}`;
      }
    },
    async mounted() {
        this.captureModelValue();
    },
    watch: {
        modelValue() {
            this.captureModelValue();
        },
        vimeoVideoId() {
            this.$emit('update:modelValue', {
                vimeo_video_id: this.vimeoVideoId
            });
        }
    },
    methods: {
        captureModelValue() {
            const newValue = (this.modelValue ? this.modelValue.vimeo_video_id : null);
            if (this.vimeoVideoId === newValue) { return; }

            this.vimeoVideoId = newValue;
        }
    },
}
</script>
