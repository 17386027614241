<script setup>
import {computed} from "vue";

const props = defineProps({
    content: {
        type: Object,
        required: true
    }
})

const title = computed(() => props.content.text);
const items = computed(() => {
    const items = [];
    for (const [k, v] of Object.entries(props.content)) {
        if (k === 'text') {
            continue;
        }
        if (!v) {
            continue;
        }
        items.push({
            name: k,
            url: v
        })
    }

    return items;

});

</script>
<template>
    <div class="my-6 text-center">
        <h2
            v-if="title"
            class="mb-4"
        >{{ title }}</h2>

        <ul class="flex items-center justify-center space-x-2 flex-wrap">
            <li
                v-for="(item, i) in items"
                :key="i"
            >
                <a
                    :href="item.url"
                    class="inline-block overflow-hidden p-2 rounded-custom shadow-custom text-inherit"
                    target="_blank"
                >
                    <i v-if="item.name === 'website'" class="text-xl fa-light fa-globe"></i>
                    <i
                        v-else
                        class="text-xl fa-brands"
                        :class="'fa-' + item.name"
                    ></i>
                </a>
            </li>
        </ul>
    </div>
</template>
