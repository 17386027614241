<script setup>
    import { computed, onMounted, ref } from 'vue'

    const props = defineProps({
        sessionRoute: {
            type: String,
            required: true,
        },
    })

    const sessions = ref({})

    const loadSessions = async () => {
        const response = await axios.get(props.sessionRoute)
        sessions.value = response.data.sessions
    }

    const upcomingSession = computed(() => {
        return sessions.value.confirmed.length > 0 ? sessions.value.confirmed[0] : false
    })

    onMounted(() => {
        loadSessions()
    })

</script>
<template>
    <div>
        <div class="space-y-8">
            <div
                v-if="sessions.confirmed?.length > 0"
            >
                <card-basic
                    v-if="upcomingSession"
                >
                    <template v-slot:header>
                        <h2>{{ _mft('coaching:overview.nextSession') }}</h2>
                    </template>
                    <template v-slot:body>
                        <li
                            class="flex justify-between py-2 space-x-4"
                        >
                            <div class="flex space-x-4">
                                <date-time-box
                                    :startDate="upcomingSession.begins_at.date"
                                    :endDate="upcomingSession.ends_at.date"
                                    :startTime="upcomingSession.begins_at.time"
                                    :endTime="upcomingSession.ends_at.time"
                                    class="px-4 py-1 border-r w-28"
                                />
                                <div class="py-1">
                                    <div class="font-bold">
                                        {{ upcomingSession.product.title }}
                                    </div>
                                    <div class="text-xs text-gray-500">
                                        {{ upcomingSession.user.name }}
                                    </div>
                                    <div class="flex space-x-2">
                                        <div
                                            v-if="upcomingSession.has_private_note"
                                            class="px-1 py-px mt-1 text-xs text-white bg-gray-600 rounded"
                                        >
                                            {{ _mft('coaching:note.private')}}
                                        </div>
                                        <div
                                            v-if="upcomingSession.has_shared_note"
                                            class="px-1 py-px mt-1 text-xs text-white bg-gray-600 rounded"
                                        >
                                            {{ _mft('coaching:note.shared')}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex flex-col space-y-1">
                                <a
                                    :href="route('coaching.provider.session.show', upcomingSession.id)"
                                    class="text-xs text-center myflow-basic-button"
                                >{{ _mft('shared:show') }}</a>
                                <a
                                    :href="route('coaching.provider.session.edit', upcomingSession.id)"
                                    class="text-xs text-center myflow-basic-button--secondary"
                                >{{ _mft('shared:manage') }}</a>
                            </div>
                        </li>
                    </template>
                </card-basic>

                <h1 class="mb-1">{{ _mft('coaching:overview.scheduledSessions') }}</h1>
                <ul class="divide-y">
                    <li
                        v-for="session in sessions.confirmed"
                        :key="session.id"
                        class="flex justify-between py-2 space-x-4"
                    >
                        <div class="flex space-x-4">
                            <date-time-box
                                :startDate="session.begins_at.date"
                                :endDate="session.ends_at.date"
                                :startTime="session.begins_at.time"
                                :endTime="session.ends_at.time"
                                class="px-4 py-1 border-r w-28"
                            />
                            <div class="py-1">
                                <div class="font-bold">
                                    {{ session.product.title }}
                                </div>
                                <div class="text-xs text-gray-500">
                                    {{ session.user.name }}
                                </div>
                                <div class="flex space-x-2">
                                    <div
                                        v-if="session.has_private_note"
                                        class="px-1 py-px mt-1 text-xs text-white bg-gray-600 rounded"
                                    >
                                        {{ _mft('coaching:note.private') }}
                                    </div>
                                    <div
                                        v-if="session.has_shared_note"
                                        class="px-1 py-px mt-1 text-xs text-white bg-gray-600 rounded"
                                    >
                                        {{ _mft('coaching:note.shared') }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1">
                            <a
                                :href="route('coaching.provider.session.show', session.id)"
                                class="text-xs text-center myflow-basic-button"
                            >{{ _mft('shared:show') }}</a>
                            <a
                                :href="route('coaching.provider.session.edit', session.id)"
                                class="text-xs text-center myflow-basic-button--secondary"
                            >{{ _mft('shared:manage') }}</a>
                        </div>
                    </li>
                </ul>
            </div>

            <div>
                <h1 class="mb-1">{{ _mft('coaching:overview.soldNotScheduled') }}</h1>
                <ul class="divide-y">
                    <li
                        v-if="sessions.bookable?.length === 0"
                        class="text-gray-500"
                    >
                        {{ _mft('coaching:overview.noNotBooked') }}
                    </li>
                    <li
                        v-for="session in sessions.bookable"
                        :key="session.id"
                        class="flex justify-between py-2 space-x-4"
                    >
                        <div class="flex space-x-4">
                            <div class="text-center w-28 border-r text-gray-400" v-html="_mft('coaching:overview.pill.notBooked')">
                            </div>
                            <div>
                                <div>
                                    <span
                                        class="font-bold"
                                    >
                                        {{ session.product.title }}
                                    </span>
                                    <span
                                        class="inline-block px-1 py-px ml-2 text-xs text-white bg-gray-600 rounded"
                                        v-if="session.session_number"
                                    >
                                        {{ _mft('coaching:session.domainNameSingular') }} {{ session.session_number }}
                                    </span>
                                </div>
                                <div class="text-xs text-gray-500">
                                    {{ session.user.name }}
                                </div>
                            </div>
                        </div>
                        <div class="flex flex-col space-y-1">
                            <a
                                :href="route('coaching.provider.session.show', session.id)"
                                class="text-xs text-center myflow-basic-button"
                            >{{ _mft('shared:show') }}</a>
                            <a
                                :href="route('coaching.provider.session.edit', session.id)"
                                class="text-xs text-center myflow-basic-button--secondary"
                            >{{ _mft('shared:manage') }}</a>
                        </div>

                    </li>
                </ul>
            </div>

            <div
                v-if="sessions.completed?.length > 0"
            >
                <h1 class="mb-1">{{ _mft('coaching:overview.completedSessions') }}</h1>
                <ul class="divide-y">
                    <li
                        v-for="session in sessions.completed"
                        :key="session.id"
                        class="flex justify-between py-2 space-x-4"
                    >
                        <div class="flex space-x-4">
                            <date-time-box
                                :startDate="session.begins_at.date"
                                :endDate="session.ends_at.date"
                                :startTime="session.begins_at.time"
                                :endTime="session.ends_at.time"
                                class="px-4 py-1 border-r w-28"
                            />
                            <div>
                                <div class="font-bold">
                                    {{ session.product.title }}
                                </div>
                                <div class="text-xs text-gray-500">
                                    {{ session.user.name }}
                                </div>
                            </div>
                        </div>
                        <div>
                            <a
                                :href="route('coaching.client.session.show', session.id)"
                                class="myflow-basic-button"
                            >{{ _mft('shared:show') }}</a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
