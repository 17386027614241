<template>
  <div>
    <form @submit.prevent="submit">
      <div class="mb-6">
        <h5 class="mb-1">Skapa nytt team</h5>
        <input-text
          v-model:content="form.name"
          :error="errors.name"
          label="Teamnamn"
          placeholder=""
          required="required"
          name="name"
        />
      </div>

      <input-button
        :label="submitLabel"
        :busy="isSubmitting"
        type="submit"
        class="float-right"
      />
    </form>
  </div>
</template>
<script>
export default {
  props: {
  },
  data() {
    return {
      form: {
        name: '',
      },
      errors: {
        name: '',
      },
      isSubmitting: false,
      submitLabel: 'Lägg till'
    };
  },
  methods: {
    submit() {
      let self = this;
      this.isSubmitting = true;

      let previousLabel = this.submitLabel;
      this.submitLabel = 'Sparar...';

      var formData = new FormData();
      for ( const key in this.form ) {
        formData.append(key, this.form[key]);
      }

      axios.post(route('company.teams.add'), formData).then(function (response) {
        if( response.status === 200 ){
          if( response.data.redirect ){
            window.location = response.data.redirect;
          } else {
            self.isSubmitting = false;
            self.submitLabel = previousLabel;
          }
        }
      })
      this.isSubmitting = false;
      self.submitLabel = previousLabel;
    }
  },
  mounted: function(){
  }
};
</script>