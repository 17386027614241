<template>
    <div class="col-span-1 mb-8">
        <form @submit.prevent="submit" ref="form">
            <card-basic>
                <template v-slot:header>
                    <div class="flex items-center">
                        <h3 class="text-gray-900 truncate font-large">
                            Expertprofil
                        </h3>
                    </div>
                </template>
                <template v-slot:preview>
                    Här hittar du inställningar för din profil
                </template>
                <template v-slot:body>
                    <img
                        v-if="expertProfile.profile_img && typeof(expertProfile.profile_img) === 'string'"
                        :src="expertProfile.profile_img"
                        alt="Profilbild"
                        class="mx-auto mb-4 transition-all transform w-36 h-36 lg:w-48 lg:h-48 myflow-profile-image"
                    >

                    <file-upload-with-cropping
                        v-if="state.fileUploadVisible"
                        :required="!state.editing"
                        :currentImage="expertProfile.profile_img"
                        previewClass="w-36 h-36 lg:w-48 lg:h-48 mb-4 myflow-profile-image"
                        @removedfile="state.changingProfilePicture = false"
                        @fileUpdated="fileUpdated($event)"
                        @croppingUpdated="croppingUpdated($event)"
                        @croppingFinished="croppingFinished($event)"
                        @croppingAborted="croppingAborted()"
                    ></file-upload-with-cropping>

                    <input-media-bank-entry
                        class="mt-4"
                        type="video"
                        label="Välj en video från mediabiblioteket"
                        v-model:content="expertProfile.trailer_media_bank_entry_id"
                    />

                    <input-text
                        label="Namn"
                        :content="expertProfile.name"
                        required="required"
                        name="name"
                        v-model:content="expertProfile.name"
                    />
                    <input-error class="-mt-2" :errors="state.errors.name"></input-error>
                    <input-text
                        label="Titel"
                        required="required"
                        :content="expertProfile.title"
                        name="title"
                        v-model:content="expertProfile.title"
                    />
                    <input-error class="-mt-2" :errors="state.errors.title"></input-error>
                    <input-editor
                        label="Beskrivning/Introduktion"
                        required="required"
                        :content="expertProfile.description"
                        name="description"
                        v-model:content="expertProfile.description"
                    />
                    <input-error class="-mt-2" :errors="state.errors.description"></input-error>
                    <input-text
                        label="E-post (Valfri, visas för besökare)"
                        :content="expertProfile.email"
                        name="email"
                        v-model:content="expertProfile.email"
                    />
                    <input-error class="-mt-2" :errors="state.errors.email"></input-error>
                    <input-text
                        label="Telefon (Valfri, visas för besökare)"
                        :content="expertProfile.phone"
                        name="phone"
                        v-model:content="expertProfile.phone"
                    />
                    <input-error class="-mt-2" :errors="state.errors.phone"></input-error>
                    <input-text
                        label="Webbplats (Valfri, visas för besökare)"
                        :content="expertProfile.url"
                        name="url"
                        v-model:content="expertProfile.url"
                    />
                    <input-error class="-mt-2" :errors="state.errors.url"></input-error>
                    <input-text
                        label="LinkedIn (Valfri, visas för besökare)"
                        :content="expertProfile.linkedin"
                        name="linkedin"
                        v-model:content="expertProfile.linkedin"
                    />
                    <input-error class="-mt-2" :errors="state.errors.linkedin"></input-error>
                    <toggle-switch
                        label="Aktiv"
                        v-model:content="expertProfile.published"
                    ></toggle-switch>
                    <toggle-switch
                        label="Publikt synlig (För ej inloggade)"
                        v-model:content="expertProfile.visible_for_non_logged_in"
                    ></toggle-switch>
                    <toggle-switch
                        label="Tillgänglig för kontaktförfrågningar"
                        v-model:content="expertProfile.contact_request_enabled"
                    ></toggle-switch>
                    <toggle-switch
                        label="Visa omdöme på profilsida"
                        v-model:content="expertProfile.display_rating"
                    ></toggle-switch>
                </template>
            </card-basic>

            <card-basic>
                <template v-slot:header>
                    <div class="flex items-center">
                        <h3 class="text-gray-900 truncate font-large">
                            Mina kategorier
                        </h3>
                    </div>
                </template>
                <template v-slot:preview>
                    <span v-if="expertProfile.categories.length > 0">
                        Du syns i {{ expertProfile.categories.length }} kategori<span v-if="expertProfile.categories.length > 1">er</span><span v-if="expertProfile.categories.length < availableCategories.length">, men det går bra att välja fler</span>.
                    </span>
                    <span v-if="expertProfile.categories.length === 0">
                        <strong>Du har inte valt någon kategori ännu.</strong><br>
                        Vi rekommenderar att välja minst en kategori så att du syns.
                    </span>
                </template>
                <template v-slot:body>
                    <fieldset v-for="(category, i) in props.availableCategories" v-bind:key="category.id">
                        <div class="mt-2 border-b border-gray-200 divide-y divide-gray-200">
                            <div class="relative flex items-start py-4">
                                <div class="flex-1 min-w-0 text-sm">
                                    <label
                                        class="text-gray-700 select-none"
                                        :class="[profileCategories.map(pc => pc.id).includes(category.id) ? 'font-bold' : 'font-medium']"

                                    >{{ category.name }}</label>
                                </div>
                                <div class="flex items-center h-5 ml-3">
                                    <input
                                        name="categoryarray[]"
                                        type="checkbox"
                                        class="w-4 h-4 border-gray-300 rounded focus:ring-myflowPurple-500 text-myflowPurple-600"
                                        :value="category.id"
                                        v-model="expertProfile.categories"
                                        :id="'category-i-' + i"
                                    >
                                </div>
                            </div>
                        </div>
                    </fieldset>
                </template>
            </card-basic>

            <div class="flex items-center justify-end mt-4">
                <p
                    class="mr-2 text-right text-myflowRed-800"
                    v-if="state.formContainsErrors"
                >
                    Formuläret innehåller fel, kontrollera fälten och försök igen.
                </p>
                <button-success
                    type="submit"
                    :class="state.isSubmitting ? 'cursor-wait' : ''"
                    :disabled="state.isSubmitting"
                >
                    {{ state.submitLabel }}
                </button-success>
            </div>
        </form>
    </div>
</template>

<script setup>
import { reactive, onMounted } from 'vue'

const props = defineProps(['profile', 'editing', 'availableCategories', 'profileCategories'])

onMounted(() => {
    state.editing = props.editing;
    state.previewImageSrc = props.profile_img;
    Object.keys(props.profile).forEach((prop) => {
        expertProfile[prop] = props.profile[prop]
    })
    expertProfile.categories = props.profileCategories.map(pc => pc.id)
})

const expertProfile = reactive({
    name: '',
    title: '',
    description: '',
    email: '',
    phone: '',
    url: '',
    linkedin: '',
    published: false,
    visible_for_non_logged_in: false,
    contact_request_enabled: false,
    display_rating: false,
    cropX: '',
    cropY: '',
    cropWidth: '',
    cropHeight: '',
    profile_img: '',
    trailer_media_bank_entry_id: null,
    categories: []
})

const state = reactive({
    fileUploadVisible: true,
    previewImageSrc: '',
    changingProfilePicture: false,
    formContainsErrors: false,
    isSubmitting: false,
    submitLabel: 'Spara',
    errors: {}
})

const submit = async () => {
    state.formContainsErrors = false;
    state.isSubmitting = true;
    state.previousLabel = state.submitLabel;
    state.submitLabel = 'Sparar...';
    const formData = new FormData();
    buildFormData(formData, expertProfile);

    axios.post(route('manage-expert-profile.update'), formData).then(function (response) {
        state.errors = {};
        if( response.status === 200 ){
            if( response.data.redirect ){
                window.location = response.data.redirect;
            } else {
                state.isSubmitting = false;
                state.submitLabel = state.previousLabel;
            }
        }
    })
    .catch(error => {
        if (error.response) {
            state.errors = error.response.data.errors;
            state.isSubmitting = false;
            state.submitLabel = state.previousLabel;
            state.formContainsErrors = true;
        }
    });
};

const buildFormData = (formData, data, parentKey) => {
    if (data && typeof data === 'object'  && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? '' : data;
        formData.append(parentKey, value);
    }
}

const fileUpdated = (file) => {
    expertProfile.profile_img = file
}

const croppingUpdated = (croppingoptions) => {
    expertProfile.cropX = croppingoptions.x
    expertProfile.cropY = croppingoptions.y
    expertProfile.cropWidth = croppingoptions.width
    expertProfile.cropHeight = croppingoptions.height
}

const croppingFinished = (base64Image) => {
    state.changingProfilePicture = false
    state.previewImageSrc = base64Image
}

const croppingAborted = () => {
    state.changingProfilePicture = false
    delete form.profile_img
}

</script>
