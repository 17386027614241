<template>
    <div
        class="flex justify-center w-full bg-center bg-cover align-center"
        :style="headerStyles"
    >
        <header
            class="relative flex flex-col items-center justify-center w-full h-full"
        >
            <div class="header-text relative z-10 flex flex-col items-center justify-center p-4">
                <h1
                    class="text-4xl md:text-5xl lg:text-6xl text-center"
                >
                    <div class="inline" :class="textColorClass">
                        {{ title }}
                    </div>
                </h1>
            </div>
        </header>
    </div>
</template>

<script>
export default {
    props:{
        title: { type: String, required: true },
        mediaBankEntryData: { type: Object, required: true },
        textColor: { type: String, required: false, default: 'theme'}
    },
    data() { return {}; },
    computed: {
        headerStyles(){
            if (!this.mediaBankEntryData.url || !(this.mediaBankEntryData.type == "image")) {
                throw "Unknown entry data format!";
            }

            if( this.mediaBankEntryData.url ){
                return { backgroundImage: 'url('+ this.mediaBankEntryData.url +')' }
            }
        },
        textColorClass(){
            if( this.textColor === 'light' ){
                return 'text-white';
            }
            if( this.textColor === 'dark' ){
                return 'text-gray-900';
            }
            return '';
        }
    }
};
</script>

<style scoped>
    header {
        height: 100vh;
        max-height: 80vh;
        min-height: min(400px, 43.75vw);
    }
</style>
