<script setup>
    import {ref} from "vue";
    import Calculator from "./DashboardCalculator/Calculator.vue";
    import Features from "./DashboardCalculator/Features.vue";

    const shownFlows = ref([]);

    const toggleFlow = (flow) => {
        if (shownFlows.value.includes(flow)) {
            shownFlows.value = shownFlows.value.filter(f => f !== flow);
        } else {
            shownFlows.value.push(flow);
        }
    }
</script>

<template>
    <div>
        <div class="grid grid-cols-3 gap-8 mb-8">
            <div class="col-span-3 text-center">
                <h2>How can we help?</h2>
                <p class="col-span-2">
                    Choose what would have the biggest impact on your business
                </p>
            </div>
            <button-base
                :style-type="shownFlows.includes('more-leads') ? 'primary' : 'secondary'"
                class="justify-center col-span-1"
                v-on:click="toggleFlow('more-leads')"
            >
                <i class="text-2xl fa-regular fa-people-group"></i><br>
                More Leads
            </button-base>

            <button-base
                :style-type="shownFlows.includes('digital-products') ? 'primary' : 'secondary'"
                class="justify-center col-span-1"
                v-on:click="toggleFlow('digital-products')"
            >
                <i class="text-2xl fa-regular fa-cloud-arrow-up"></i><br>
                Online Products
            </button-base>

            <button-base
                :style-type="shownFlows.includes('increased-win-rate') ? 'primary' : 'secondary'"
                class="justify-center col-span-1"
                v-on:click="toggleFlow('increased-win-rate')"
            >
                <i class="text-2xl fa-regular fa-sack-dollar"></i><br>
                Number of deals closed
            </button-base>
        </div>

        <div class="grid gap-8 md:grid-cols-4">
            <Calculator
                v-if="shownFlows.length > 0"
                class="col-span-2"
                :shown-flows="shownFlows"
            />
            <Features
                :shown-flows="shownFlows"
                class="col-span-2"
            />
        </div>
    </div>
</template>

<style scoped>
</style>
