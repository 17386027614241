<script setup>
    import {computed, ref} from "vue";

    const props = defineProps({
        navigationType: {
            type: String,
            required: true
        }
    })

    const emit = defineEmits(['cancel', 'itemAdded'])
    const navigatables = ref([])
    const navigatableType = ref(null)
    const search = ref('')
    const loading = ref(false)

    const getNavigatable = async () => {
        loading.value = true
        const res = await axios.get(route('settings.navigation.navigatable'), {
            params: {
                navigationType: props.navigationType,
                type: navigatableType.value,
            }
        })
        navigatables.value = [...res.data.navigatables.static, ...res.data.navigatables.dynamic]
        loading.value = false
    }

    const setNavigatableType = (type) => {
        search.value = ''
        navigatableType.value = type
        getNavigatable()
    }

    const addItem = (item) => {
        emit('itemAdded', item)
    }

    const cancel = () => {
        emit('cancel')
    }

    const filteredNavigatables = computed(() => {
        return navigatables.value.filter(item => {
            return item.title.toLowerCase().includes(search.value.toLowerCase())
        })
    })

</script>

<template>
    <div>
        <div
            v-if="! navigatableType"
            class="flex flex-col space-y-1"
        >
            <button-primary
                @click="setNavigatableType('page')"
                size="md"
                class="justify-center"
            >
                {{ _mft('navigation:setting.addPage') }}
            </button-primary>
            <button-primary
                @click="setNavigatableType('course')"
                size="md"
                class="justify-center"
            >
                {{ _mft('navigation:setting.addCourse') }}
            </button-primary>
            <button-primary
                @click="setNavigatableType('coaching_product')"
                size="md"
                class="justify-center"
            >
                {{ _mft('navigation:setting.addCoachingProduct') }}
            </button-primary>
            <button-primary
                @click="setNavigatableType('subscribable')"
                size="md"
                class="justify-center"
            >
                {{ _mft('navigation:setting.addSubscription') }}
            </button-primary>
            <button-base
                @click="cancel"
                size="md"
                class="justify-center"
            >
                {{ _mft('shared:cancel') }}
            </button-base>
        </div>
        <div
            v-if="navigatableType"
            class="p-2 text-sm"
        >
            <p
                v-if="loading"
            >
                {{ _mft('shared:filter.loading') }}
            </p>

            <div
                v-if="!loading"
            >
                <div>
                    <input-text
                        :label="_mft('shared:search')"
                        v-model:content="search"
                        class="text-sm"
                    />
                    <p
                        v-if="filteredNavigatables.length === 0"
                        class="italic"
                    >
                        {{ _mft('shared:search.noResults') }}
                    </p>
                    <ul class="space-y-1">
                        <li
                            v-for="(item, i) in filteredNavigatables"
                            :key="i"
                        >
                            <button-transparent
                                @click="addItem(item)"
                                size="line"
                            >
                                <span class="hover:text-wlPrimary">
                                    {{ item.title }}
                                </span>
                            </button-transparent>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>
