<template>
  <div class="h-full">
    <ol 
      class="col-start-1 col-end-2 row-start-1 grid grid-cols-1 sm:grid-cols-1 h-full"  
      style="grid-template-rows: 1.75rem repeat(288, minmax(0, 1fr)) auto"
    >
      <li 
        v-for="(quarter, i) in [...Array(96).keys()]"
        v-bind:key="i"
        class="relative flex" 
        :class="'sm:col-start-1'"
        :style="{gridRow: 2 + (i*3) + '/ span ' + 3 }"
        @mouseover="setCurrentTile(i+2)"
      >
        <div 
          class="group absolute inset-1 flex flex-col rounded-md overflow-y-hidden p-1 text-xs leading-5 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
          :class="[!creatingSpan ? 'hover:bg-myflowPurple-100' : '' ]"
        >
          <p 
            class="order-1 font-semibold text-myflowPurple-700 text-xs text-center"
            v-on:click="createSpan(i+1)"
          >
            <span
              :class="{'opacity-0' : creatingSpan}"
            >+</span>
          </p>
        </div>
      </li>

      <li
        class="relative flex transition-all pointer-events-none" 
        :class="'sm:col-start-1'"
        :style="{gridRow: (newSpan.startQuarter * 3) - 1 + ' / span ' + (newSpan.quarters * 3) }"
        v-if="creatingSpan"
      >
        <a href="#" class="group absolute z-30 inset-1 flex flex-col overflow-y-auto rounded-lg bg-myflowPurple-200 px-2 text-xs leading-5 hover:bg-myflowPurple-300 opacity-50">
          <p class="order-1 font-semibold text-green-700"></p>
        </a>
      </li>
    </ol>
  </div>
</template>
<script>
export default {
  emits: ["addSpan"],
  props: {
    firstAvailableQuarter: Number,
    day: Object
  },
  data() {
    return {
      creatingSpan: false,
      currentTile: false,
      newSpan: {
        endSet: false,
        startQuarter: 0,
        endQuarter: 0,
        quarters: 0,
        weekday: 0,
        date: 0,
        comparedate: 0
      },
    }
  },
  methods: {
    createSpan(quarter){
      if( this.creatingSpan ){
        this.newSpan.endQuarter = quarter
        this.newSpan.quarters = this.newSpan.endQuarter - this.newSpan.startQuarter

        this.newSpan.startQuarter = this.firstAvailableQuarter + this.newSpan.startQuarter - 1
        this.newSpan.endQuarter = this.firstAvailableQuarter + this.newSpan.endQuarter
        
        this.newSpan.endSet = true
        this.creatingSpan = false
        this.$emit('addSpan', this.newSpan)
        this.resetNewSpan()
        return
      }
      this.creatingSpan = true
      this.newSpan.weekday = this.day.weekday
      this.newSpan.date = this.day.date
      this.newSpan.comparedate = this.day.comparedate
      this.newSpan.startQuarter = quarter
      this.newSpan.quarters = 1
    },
    resetNewSpan(){
      this.newSpan = {
        endSet: false,
        startQuarter: 0,
        endQuarter: 0,
        quarters: 0,
        weekday: 0,
        comparedate: 0,
        date: 0
      }
    },
    setDuration(duration){
      this.newSpan.quarters = duration
    },
    setCurrentTile(tile){
      if( this.newSpan.endSet === true ){
        return
      }

      this.currentTile = tile
      this.newSpan.endQuarter = tile
      this.newSpan.quarters = this.newSpan.endQuarter - this.newSpan.startQuarter
    },
  }
}
</script>