<script setup>
import { ref, onMounted, watch } from 'vue'
import CardBasic from "../../containers/cardBasic.vue";

const tags = ref([])
const selectedTags = ref([])
const loading = ref(false)

const activeAutomationIntervals = ref([])

const loadTags = async () => {
    loading.value = true
    const res = await axios.get(route('crm.communications.automation.tags'))
    loading.value = false
    tags.value = res.data.tags
}

const performQuery = async () => {
    loading.value = true
    const res = await axios.post(route('crm.communications.automation.tagQuery'), {
        tag_ids: selectedTags.value
    })

    activeAutomationIntervals.value = res.data.automationIntervals
    loading.value = false
}

const toggleTag = (tag) => {
    const index = selectedTags.value.indexOf(tag.id)

    if (index === -1) {
        selectedTags.value.push(tag.id)
    } else {
        selectedTags.value.splice(index, 1)
    }
}

watch(selectedTags, () => {
    performQuery()
}, { deep: true });

onMounted(() => {
    loadTags()
});

</script>
<template>
<div>
    <ul class="flex flex-wrap">
        <li
            v-for="tag in tags"
            :key="tag.id"
            class="mb-1"
        >
            <span
                @click="toggleTag(tag)"
                class="mr-2 mb-1 text-black rounded-md px-1.5 py-0.5 cursor-pointer border-1 text-xs"
                :class="selectedTags.includes(tag.id) ? 'bg-gray-300 ring-2 ring-gray-500' : 'bg-gray-200 hover:bg-gray-300'"
            >
                {{ tag.name }}
            </span>
        </li>
    </ul>

    <div>
        <ul class="space-y-2 border-l pl-4 mt-8 mb-8">
            <li
                v-if="selectedTags.length > 0 && activeAutomationIntervals.length === 0 && !loading"
            >
                {{ _mft('crm:noAutomations') }}
            </li>
            <li
                v-for="(interval, hrs) in activeAutomationIntervals"
                :key="interval.id"
                class="transform -translate-y-4"
            >
                <div
                    v-for="(day) in interval.days_since_previous_automation"
                    class="h-2 relative"
                >
                    <div class="absolute w-4 h-4 rounded-full border-2 bg-gray-50 -left-6 top-1.5"></div>
                </div>

                <div class="relative">
                    <div class="absolute w-4 h-4 rounded-full border-2 bg-gray-50 -left-6 top-1.5"></div>
                    <h3>{{ _mft('crm:tagQuery.sentDaysAfterAssignment', {count: interval.label}) }}</h3>
                    <div class="flex flex-wrap items-stretch">
                        <card-basic
                            v-for="(automation, i) in interval.automations"
                            class="w-48 p-2 mr-2 mb-2"
                            :compact="true"
                        >
                            <p class="mb-2">
                                <a
                                    :href="route('crm.communications.automations.show', automation.id)"
                                    target="_blank"
                                >
                                    {{ automation.name }}
                                </a>
                            </p>
                            <div class="flex space-x-2">
                                <span
                                    v-if="automation.sms_enabled"
                                    class="mr-2 mb-1 py-1 px-2 myflow-basic-pill whitespace-nowrap text-sm"
                                >{{ _mft('communication:sms') }}</span>
                                <span
                                    v-if="automation.email_enabled"
                                    class="mr-2 mb-1 py-1 px-2 myflow-basic-pill whitespace-nowrap text-sm"
                                >{{ _mft('communication:email') }}</span>
                            </div>
                        </card-basic>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</div>
</template>
