<template>
	<div>
		<div
			class="p-4 text-white bg-gray-700"
			v-if="trialEligible == 'true'"
		>
			<div class="flex justify-between items-center">
				<h5>{{ _mft('expertPro:cta.tryPro.title') }}</h5>
				<p class="font-semibold mb-0">
                    {{ _mft('expertPro:cta.tryPro.sellIn') }}
                </p>
				<div>
					<button-success @click="open = true">
                        {{ _mft('expertPro:cta.tryPro.button') }}
                    </button-success>

					<basic-slideout v-model:open="open">
						<template v-slot:body>
							<myflow-pro-trial
								:public-key="props.publicKey"
							></myflow-pro-trial>
						</template>
					</basic-slideout>
				</div>
			</div>
		</div>

		<div
			class="p-4 text-white bg-gray-700"
			v-if="companyType === 'TRIAL'"
		>
			<div class="flex justify-between items-center">
				<h5>{{ _mft('expertPro:cta.trial.upgrade.title') }}</h5>
				<div>
					<a :href="route('settings.company.settings')" class="mr-2 font-semibold">
                        <button-primary>{{ _mft('expertPro:cta.trial.upgrade.upgradeButton') }}</button-primary>
                    </a>
					<a :href="route('settings.company.settings')">
                        <button-secondary>{{ _mft('expertPro:cta.trial.upgrade.manageTrialButton') }}</button-secondary>
                    </a>
				</div>
			</div>
		</div>
	</div>

</template>

<script setup>

import { computed, onMounted, ref, reactive, watch } from 'vue';

const open = ref(false);

const props = defineProps({
	companyType: {
		type: String,
		required: true,
		default: null
	},
	trialEligible: {
		type: String,
		required: true,
		default: 'false'
	},
	publicKey: String
});

</script>
