<template>
    <div>
        <video-recorder-record-and-trim
            @recordingComplete="recordingComplete"
        />
    </div>
</template>
<script>
export default {
    methods: {
        recordingComplete(videodata) {
            this.$emit('recordingComplete', videodata)
        }
    }
}
</script>
