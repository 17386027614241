<template>
    <mf-table v-if="users !== null" :data="users" :columns="columns" :filters="filters" :searchable="searchable">
        <template #column-name="{ row }">
            <div :class="['flex', 'items-center']">
                <div class="ml-4">
                    <a
                        :href="route('admin.company', row.id)"
                        class="mr-2 text-sm font-medium text-right text-myflowPurple-900 hover:underline"
                    >
                        <b>{{ row.name }}</b><br>
                        {{ row.creator }}
                    </a>
                </div>
            </div>
        </template>
        <template #column-created_at="{ row }">
            <span class="uppercase">
                {{ row.updated_at }}
            </span>
        </template>
        <template #column-pro_until="{ row }">
            <div v-html="row.pro_until">
            </div>
        </template>

    </mf-table>
    <div v-else class="text-center">
        <mf-spinner-medium
          class="w-24 h-12"
        />
    </div>
</template>

<style lang="scss" scoped>
</style>

<script setup>
import { ref, toRefs, watch, onMounted } from 'vue';
import axios from 'axios';

const props = defineProps({
    url: { type: String, required: true }
});

const { url } = toRefs(props);

const dateFormatter = new Intl.DateTimeFormat(['sv-SE'], {
    timeZone: 'Europe/Stockholm',
    dateStyle: 'short'
});

const users = ref(null);
const updateUsers = async () => {
    const res = await axios.get(url.value + '?cachebust');
    users.value = res.data.data.map((row) => ({
        ...row,
        updated_at: row.updated_at ? dateFormatter.format(new Date(row.updated_at)) : null
    }));
}
watch(url, updateUsers);
onMounted(updateUsers);

const columns = [
    { label: 'Namn', key: 'name', sort: 'name' },
    { label: 'Källa', key: 'source', sort: 'source', desktop: true },
    { label: 'Senaste aktivitet', key: 'updated_at', sort: 'updated_at', desktop: true },
    { label: 'Expertavtal', key: 'agreements', sort: 'agreements', desktop: true },
    { label: 'Pro prenumeration', key: 'expert_pro_enabled', sort: 'expert_pro_enabled', desktop: true },
    { label: 'Pro aktivt tills', key: 'pro_until', sort: 'pro_until', desktop: true },
    { label: 'Expertprofil(er)', key: 'expert_profiles', sort: 'expert_profiles', desktop: true },
    { label: 'Utbildningar', key: 'course', sort: 'course', desktop: true },
    { label: 'Prenumerationer', key: 'subscribables', sort: 'subscribables', desktop: true },
    { label: 'Skickade avtal', key: 'expert_contracts', sort: 'expert_contracts', desktop: true },
    { label: 'Landningssidor', key: 'landing_pages', sort: 'landing_pages', desktop: true },
    { label: 'Bootcamps', key: 'workshops', sort: 'workshops', desktop: true },
    { label: 'Founding Members', key: 'founding_members', sort: 'founding_members', desktop: true },
];
const searchable = [
    'name',
    'source',
    'updated_at',
    'creator',
    'agreements',
    'coachingprofile',
    'course'
];
const filters = [
    {
        label: 'Bootcamps',
        items: [
            { label: "Deltagit", filter: { custom: (_v, row) => row.filter_workshops } },
            { label: "INTE Deltagit", filter: { custom: (_v, row) => !row.filter_workshops } },
        ]
    },
    {
        label: 'Founding Member',
        items: [
            { label: "Är Founding Member", filter: { custom: (_v, row) => row.filter_founding_member } },
            { label: "Är INTE Founding Member", filter: { custom: (_v, row) => !row.filter_founding_member } },
        ]
    },
    {
        label: 'Pro Status',
        items: [
            { label: "Redan förfallit", filter: { custom: (_v, row) => row.is_not_pro } },
            { label: "Aldrig haft", filter: { custom: (_v, row) => row.never_had_pro } },
            { label: "Inom 7 dagar", filter: { custom: (_v, row) => row.loses_within_7_days } },
            { label: "Inom 14 dagar", filter: { custom: (_v, row) => row.loses_within_14_days } },
            { label: "Inom 30 dagar", filter: { custom: (_v, row) => row.loses_within_30_days } },
        ]
    },
]

const classObject = (type) => {
    if(type == 'creator') {
        return 'myflow-success-pill';
    } else if(type == 'internal') {
        return 'myflow-danger-pill'
    } else {
        return 'myflow-basic-pill'
    }
}
</script>
