<script setup>
import {computed} from "vue";

const props = defineProps({
    containerRef: {
        type: String,
        required: true
    },
    uuid: {
        type: String,
        require: true
    },
    content: {
        type: Object,
        required: true
    },
})

const roundingClass = computed(() => {
    if (props.content?.rounding === 'siteAppearance') {
        return 'rounded-wl'
    }
    if (props.content?.rounding === 'none') {
        return 'rounded-none'
    }
    if (props.content?.rounding === 'slight') {
        return 'rounded-lg'
    }
    if (props.content?.rounding === 'medium') {
        return 'rounded-3xl'
    }
    if (props.content?.rounding === 'very') {
        return 'rounded-5xl'
    }

    return 'rounded-wl'
})

</script>
<template>
    <div
        class="mx-auto my-6 flex overflow-hidden rounded-custom shadow-custom"
        :class="content.alignment === 'left' ? 'justify-start' : content.alignment === 'right' ? 'justify-end' : 'justify-center'"
    >
        <component :is="content.linkUrl? 'a' : 'div'" :href="content.linkUrl" target="_blank" class="text-inherit">
            <div class="overflow-hidden" :class="roundingClass">
                <img
                    :src="content.imageUrl"
                />
            </div>
            <p
                v-if="content.caption"
                class="mt-1 text-center text-xs"
            >
                {{ content.caption}}
            </p>
        </component>
    </div>
</template>
