<template>
    <div v-if="value">
        <div v-for="(entry, idx) in value.media_bank_entries" :key="idx" class="flex items-start w-full relative">
            <div class="grid w-full grid-cols-3 lg:grid-cols-2 gap-5 mb-3 mr-3">
                <input-media-bank-entry
                    class="col-span-2 lg:col-span-1"
                    type="image"
                    label="Välj en bild från mediabiblioteket"
                    :required="true"
                    v-model:content="entry.id"
                />
                <media-bank-entries-display v-if="entry.id" :data-url="route('mediaBank.entries.show', entry.id)" />
            </div>
            <button
                v-if="value.media_bank_entries.length > 1"
                type="button"
                @click="deleteLine(idx)"
                class="absolute top-4 right-5 bg-myflowRed rounded-full p-2 shadow-xl"
            >
                <TrashIcon class="h-5 w-5 text-white" aria-hidden="true" />
            </button>
        </div>
        <button type="button" class="mb-3 myflow-basic-button" @click="addLine">Lägg till rad</button>
    </div>
    <mf-spinner-medium v-else class="w-16 h-8"></mf-spinner-medium>
</template>
<script>
import {
    TrashIcon
} from '@heroicons/vue/outline';

export default {
    components: {
        TrashIcon
    },
    props: {
        modelValue: {},
    },
    emits: [
        'update:modelValue'
    ],
    data() {
        return {
            value: null
        };
    },
    async mounted() {
        this.captureModelValue();
    },
    watch: {
        modelValue() {
            if (this.value.media_bank_entries.length === this.modelValue.media_bank_entries_count ) {
                return;
            }
            this.captureModelValue();
        },
        value: {
            handler() {
                const newValue = { media_bank_entries_count: this.value.media_bank_entries.length };
                this.value.media_bank_entries.forEach((entry, idx) => {
                    newValue[`media_bank_entry_${idx}_id`] = entry.id;
                });
                this.$emit('update:modelValue', JSON.parse(JSON.stringify(newValue)));
            },
            deep: true
        }
    },
    methods: {
        captureModelValue() {
            const newValue = { media_bank_entries: [] };

            if (!this.modelValue || !this.modelValue.media_bank_entries_count) {
                newValue.media_bank_entries.push({id: null})
            } else {
                for (let idx = 0; idx < this.modelValue.media_bank_entries_count; idx++) {
                    newValue.media_bank_entries.push({
                        id: this.modelValue[`media_bank_entry_${idx}_id`]
                    });
                }
            }

            this.value = newValue;
        },
        addLine() {
            this.value.media_bank_entries.push({id: null});
        },
        deleteLine(idx) {
            this.value.media_bank_entries.splice(idx, 1);
        }
    },
}
</script>
