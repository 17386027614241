<script setup>
import { reactive, ref, onMounted, computed } from 'vue'

const cronofyAccountId = ref(null)
const cronofyEmbedToken = ref(null)
const cronofyEnabled = ref(false)
const bufferMinutesAfter = ref(null)
const startDateTime = ref(null)
const endDateTime = ref(null)
const timezone = ref(null)
const cronofyError = ref(null)
const dateTimeSlotSelected = ref(false)
const displayBookingCalendar = ref(false)
const bookingCompleted = ref(false)
const completion = ref(null)
const emit = defineEmits(['bookingCompleted', 'bookingFailed'])

const props = defineProps({
    containerRef: {
        type: String,
        required: true,
    },
    bookableUserUuid: {
        type: String,
        required: true,
    },
    meetingMinutes: {
        type: Number,
        required: true,
    },
    bookingEventTitle: {
        type: String,
        required: true,
    },
    blockUuid: {
        type: String,
        required: false,
    },
})

const selectedSlot = reactive({
    start: null,
    end: null,
    timezone: null,
    coach_cronofy_sub: null,
})

const client = reactive({
    name: null,
    email: null,
})

const loadBookingPrerequisites = async () => {
    const response = await axios.post(route('block-editor-block.booking.getMeetingBooking'), {
        block_location: props.containerRef,
        block_key: props.blockUuid,
        user_uuid: props.bookableUserUuid
    })
    if (!response.data.cronofyEnabled) {
        cronofyError.value = 'Kalenderbokning är inte aktiverat för denna användare.'
        return;
    }

    bufferMinutesAfter.value = response.data.bufferMinutesAfter;
    startDateTime.value = response.data.startDate;
    endDateTime.value = response.data.endDate;
    cronofyAccountId.value = response.data.cronofyAccountId;
    cronofyEmbedToken.value = response.data.cronofyEmbedToken;
    timezone.value = response.data.timezone;

    cronofyEnabled.value = true
}

const onDateTimeSlotSelected = (dateTimeSlot) => {
    dateTimeSlotSelected.value = true;

    selectedSlot.start = dateTimeSlot.slot.start;
    selectedSlot.end = dateTimeSlot.slot.end;
    selectedSlot.timezone = dateTimeSlot.tzid;
    selectedSlot.coach_cronofy_sub = dateTimeSlot.slot.participants[0].sub;

    submitBooking();
}

const clearSelectedTimeSlot = () => {
    dateTimeSlotSelected.value = false;
    selectedSlot.start = null;
    selectedSlot.end = null;
    selectedSlot.timezone = null;
    selectedSlot.coach_cronofy_sub = null;
}

const submitBooking = async () => {
    const response = await axios.post(route('block-editor-block.booking.collectMeetingBooking'), {
        block_location: props.containerRef,
        block_key: props.blockUuid,
        user_uuid: props.bookableUserUuid,
        client_name: client.name,
        client_email: client.email,
        event_title: props.bookingEventTitle,
        calendarProvider: 'cronofy',
        start: selectedSlot.start,
        end: selectedSlot.end,
        timezone: selectedSlot.timezone,
        coach_cronofy_sub: selectedSlot.coach_cronofy_sub,
    })

    if (response.data.success) {
        completion.value = response.data;
        bookingCompleted.value = true
        emit('bookingCompleted', response.data);
    } else {
        emit('bookingFailed', response.data);
    }
}

const continueToBookingAvailable = computed(() => {
    return client.name && client.email && client.email.includes('@') && client.email.includes('.')
})

const cancelBooking = () => {
    clearSelectedTimeSlot();
    displayBookingCalendar.value = false;
}

const continueToBooking = () => {
    if (!client.name || !client.email) {
        cronofyError.value = 'Fyll i namn och e-postadress för att fortsätta.'
        return;
    }

    displayBookingCalendar.value = true;
}

const onCronofyError = (error) => {
    cronofyError.value = error;
}

onMounted(() => {
    loadBookingPrerequisites();
})

</script>
<template>
    <div>
        <mf-spinner-medium
            v-if="!cronofyEnabled && !cronofyError"
            class="w-24 h-12"
        />
        <div v-if="bookingCompleted">
            <card-basic v-if="completion.success">
                <h3>{{ _mft('pagebuilderBlock:meetingBooking.confirmation.title') }}</h3>
                <p>{{ _mft('pagebuilderBlock:meetingBooking.confirmation.message', {email: completion.confirmation.clientEmail, date: completion.confirmation.startDate }) }}</p>

                <div class="border-t pt-2">
                    <div class="text-md font-bold">{{ completion.confirmation.eventTitle }}</div>
                    <div class="flex items-center my-1">
                        <i class="fa-regular fa-user text-lg text-gray-400 mr-2"></i> {{ completion.booking.providerName }}
                    </div>
                    <div class="flex items-center">
                        <i class="fa-regular fa-calendar text-lg text-gray-400 mr-2"></i> {{ completion.confirmation.startDate }} {{ completion.confirmation.startTime }} - {{ completion.confirmation.endTime }}
                    </div>
                </div>
                <!-- !! Also, add event details box here !! -->
            </card-basic>
            <p class="font-bold" v-else>{{ _mft('pagebuilderBlock:meetingBooking.error.message') }}</p>
        </div>
        <div v-if="!bookingCompleted && cronofyEnabled" id="block-cronofy-date-time-picker">
            <div
                v-if="!displayBookingCalendar"
                class="grid-cols-2 gap-4 md:grid"
            >
                <div class="col-span-1">
                    <input-text
                        label="Namn"
                        v-model:content="client.name"
                    />
                    <input-text
                        label="E-post"
                        v-model:content="client.email"
                    />
                    <div
                        v-if="continueToBookingAvailable"
                        class="flex justify-center"
                    >
                        <button-primary
                            @click="continueToBooking"
                            class="inline-block w-full px-4 py-2 mt-2 overflow-hidden rounded-custom shadow-custom justify-center"
                        >
                            {{ _mft('pagebuilderView:cronofy.continueToBooking') }}
                        </button-primary>
                    </div>
                    <div
                        v-else
                    >
                        <div>
                            <button class="inline-block px-4 py-2 mt-2 overflow-hidden text-white bg-gray-400 cursor-default rounded-custom shadow-custom">
                                {{ _mft('pagebuilderView:cronofy.info.fillNameAndEmailToContinue') }}
                            </button>
                        </div>

                    </div>
                </div>
                <div class="col-span-1">
                    <dummy-blurred-calendar></dummy-blurred-calendar>
                </div>
            </div>

            <div
                v-if="displayBookingCalendar"
            >
                <button
                    @click="cancelBooking"
                    v-if="displayBookingCalendar"
                    class="inline-block px-4 py-2 mt-2 overflow-hidden rounded-custom shadow-custom"
                >
                    Tillbaka
                </button>
                <cronofy-date-time-picker
                    :class="dateTimeSlotSelected ? 'hidden' : ''"
                    :cronofy-account-id="cronofyAccountId"
                    :embed-token="cronofyEmbedToken"
                    :buffer-minutes-after="bufferMinutesAfter"
                    :start-datetime="startDateTime"
                    :end-datetime="endDateTime"
                    :timezone="props.timezone"
                    :requiredDuration="props.meetingMinutes"
                    @dateTimeSlotSelected="onDateTimeSlotSelected"
                    @cronofyError="onCronofyError"
                ></cronofy-date-time-picker>
            </div>
        </div>
        <div v-if="cronofyError">
            Ett fel uppstod: {{ cronofyError }}
        </div>
    </div>
</template>
<style>
</style>
