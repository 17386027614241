<script setup>
import axios from 'axios';
import { ref } from 'vue';

const props = defineProps({
    request: {
        type: Object,
        required: true
    },
});

const emit = defineEmits([
    'completed',
    'failed'
]);

const isDone = ref(false);

const onCompleted = (data) => {
    if (isDone.value) { return; }

    isDone.value = true;
    emit('completed');
};

const onFailed = (data) => {
    if (isDone.value) { return; }

    isDone.value = true;
    emit('failed');
};

const signaturePad = ref(null);
const hasData = ref(false);

const updateHasData = () => {
    hasData.value = !signaturePad.value.isEmpty()
};

const onBeginStroke = () => {
    // Needed when in modal
    signaturePad.value.resizeCanvas();

    updateHasData();
};

const onEndStroke = () => {
    updateHasData();
};

const submitting = ref(false);

const onSign = async () => {
    const { isEmpty, data } = signaturePad.value.saveSignature();

    if (isEmpty) {
        updateHasData();
        return;
    }

    try {
        submitting.value = true;

        await axios.post(route('on_screen_signatures.store'), {
            ref: props.request.ref,
            data
        })

        onCompleted({ ref: props.request.ref });
    } catch (error) {
        console.log(error);
        submitting.value = false;

        onFailed();
    }
};

const onClear = () => {
    signaturePad.value.clearSignature();
    updateHasData();
};

</script>
<template>
    <div v-if="!isDone" class="mb-4">
        <h5>{{ _mft('expertContract:onScreenSignature.title') }}</h5>

        <p>
            {{ _mft('expertContract:onScreenSignature.intro') }}
        </p>

        <div class="w-[calc(100%-1.5rem)] max-w-[400px] px-3 h-[100px] border rounded mx-auto my-3">
            <vue-signature-pad :options="{ onBegin: onBeginStroke, onEnd: onEndStroke }" ref="signaturePad" />
        </div>
        <div
            v-if="!submitting"
            class="space-x-2"
        >
            <button-base
                style-type="secondary"
                type="button"
                :disabled="!hasData"
                @click.prevent="onClear"
            >
                <template #icon>
                    <i class="fas fa-undo"></i>
                </template>
                {{ _mft('expertContract:onScreenSignature.clear') }}
            </button-base>

            <button-base
                style-type="success"
                type="button"
                :disabled="!hasData"
                @click.prevent="onSign"
            >
                <template #icon>
                    <i class="fas fa-pen mr-1"></i>
                </template>
                {{ _mft('expertContract:onScreenSignature.sign') }}
            </button-base>
        </div>
        <div v-else>
            <mf-spinner-medium
                class="w-12 h-6"
            ></mf-spinner-medium>
        </div>
    </div>
</template>
