<script setup>
    import { CheckIcon, SwitchVerticalIcon } from "@heroicons/vue/outline"
    import draggable from 'vuedraggable';
    import { useNotificationStore } from '@/stores/NotificationStore.js'
    import { computed, ref, onMounted } from 'vue';

    const notificationStore = useNotificationStore()
    const onboardingModules = ref([])

    const loadTasks = async () => {
        const res = await axios.get(route('onboarding.index'));
        onboardingModules.value = res.data.data;
    }

    const updateOrder = async (event) => {
        const moduleOrder = onboardingModules.value.map((item) => {
            return item.id;
        });
        const res = await axios.post(route('onboarding.modules.update-order'), {moduleOrder: moduleOrder});

        notificationStore.addNotification({
            type: 'success',
            message: 'Ordning sparad!',
            dismissAfter: 2000,
        })
    }

    const toggleImportance = async (item) => {
        const res = await axios.post(route('onboarding.module.toggle', item.id));
        item.isActive = res.data.isActive;
        if (!item.isActive) {
            const index = onboardingModules.value.indexOf(item);
            onboardingModules.value.splice(index, 1);
            onboardingModules.value.push(item);
        } else {
            const index = onboardingModules.value.indexOf(item);
            onboardingModules.value.splice(index, 1);
            onboardingModules.value.unshift(item);
        }
    }

    const dragOptions = computed(() => {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    })

    const stickyOnboardingModules = computed(() => {
        return onboardingModules.value.filter((item) => {
            return item.template_id === 1;
        });
    })

    const reorderableOnboardingModules = computed(() => {
        return onboardingModules.value.filter((item) => {
            return item.template_id !== 1;
        });
    })

    onMounted(() => {
        loadTasks();
    })

</script>
<template>
    <div class="text-center">
        <h2 class="mt-4 mb-2">
            {{ _mft('expertOnboarding:title') }}
        </h2>
        <p class="mb-4">{{ _mft('expertOnboarding:intro') }}</p>
    </div>
    <ul role="list" class="">
        <li
            v-for="element in stickyOnboardingModules"
            :key="element.id"
            :class="[element.isCompleted ? 'bg-gradient-to-tr from-white to-myflowGreen-100' : 'bg-white']"
            class="relative z-20 justify-between p-5 m-2 border-2 gap-x-6 rounded-xl"
        >
            <div class="grid-cols-5 gap-8 lg:grid">
                <div class="col-span-3">
                    <div class="">
                        <p class="relative flex items-center pr-10 text-sm font-semibold leading-6 text-gray-900 xl:pr-0">
                            <i
                                class="flex-none mr-2 text-xl fa-light"
                                :class="[element.icon, element.isCompleted ? 'text-green-600' : 'text-gray-900']"
                            ></i>
                            <span :class="element.isCompleted ? 'line-through text-green-700' : ''">
                                {{ element.name }}
                            </span>
                        </p>
                        <p class="mt-1 text-xs leading-5 text-gray-500">{{ element.description }}</p>
                    </div>
                </div>
                <div class="col-span-2">
                    <div class="flex" v-for="task in element.subtasks" v-bind:key="task.id">
                        <i class="mt-1 mr-1 lg:-ml-4 fa-regular" :class="task.isDone ? 'fa-check-square text-myflowGreen-900' : 'fa-square text-myflowYellow-900'"></i>
                        <a
                            :href="task.url"
                            target="_blank"
                            class="text-gray-900 underline decoration-gray-400"
                        >{{ task.name }}</a>
                    </div>
                </div>
            </div>
        </li>

        <draggable
            v-model="reorderableOnboardingModules"
            @end="updateOrder"
            tag="transition-group"
            v-bind="dragOptions"
            :component-data="{
                type: 'transition-group',
                name: !drag ? 'flip-list' : null
            }"
        >
            <template v-slot:item="{ element }">
                <li
                    :class="[element.isActive ? '' : 'opacity-50', element.isCompleted && element.isActive ? 'bg-gradient-to-tr from-white to-myflowGreen-100' : 'bg-white']"
                    class="relative z-20 justify-between p-5 m-2 border-2 gap-x-6 rounded-xl"
                >
                    <SwitchVerticalIcon class="absolute z-30 w-6 h-6 text-gray-400 cursor-move top-6 right-6" aria-hidden="true" />
                    <div class="grid-cols-5 gap-8 lg:grid">
                        <div class="col-span-3">
                            <div class="">
                                <p class="relative flex items-center pr-10 text-sm font-semibold leading-6 text-gray-900 xl:pr-0">
                                    <i
                                        class="flex-none mr-2 text-xl fa-light"
                                        :class="[element.icon, element.isCompleted ? 'text-green-600' : 'text-gray-900']"
                                    ></i>
                                    <span :class="element.isCompleted ? 'line-through text-green-700' : ''">
                                        {{ element.name }}
                                    </span>
                                </p>
                                <p class="mt-1 text-xs leading-5 text-gray-500">{{ element.description }}</p>
                                <button-secondary size="sm" @click="toggleImportance(element)">
                                    <span v-if="element.isActive" class="text-gray-400 italic flex items-center group">
                                        <span class="inline-block w-3 h-3 rounded-sm border border-wlPrimary group-hover:border-wlPrimary-light mr-1"></span>
                                        {{ _mft('expertOnboarding:notRelevant') }}
                                    </span>
                                    <span v-if="!element.isActive" class="text-gray-400 italic">
                                        {{ _mft('expertOnboarding:undoNotRelevant') }}
                                    </span>
                                </button-secondary>
                            </div>
                        </div>
                        <div class="col-span-2">
                            <div class="flex" v-for="task in element.subtasks" v-bind:key="task.id">
                                <i class="mt-1 mr-1 lg:-ml-4 fa-regular" :class="task.isDone ? 'fa-check-square text-myflowGreen-900' : 'fa-square text-myflowYellow-900'"></i>
                                <a
                                    :href="task.url"
                                    target="_blank"
                                    class="text-gray-900 underline decoration-gray-400"
                                >{{ task.name }}</a>
                            </div>
                        </div>
                    </div>
                </li>
            </template>
        </draggable>
    </ul>
</template>
<style>
    .flip-list-move {
    transition: transform 0.5s;
    }
</style>
