<script setup>
import { reactive, ref, onMounted } from 'vue'

const cronofyAccountId = ref(null)
const cronofyEmbedToken = ref(null)
const cronofyEnabled = ref(false)
const bufferMinutesAfter = ref(null)
const startDateTime = ref(null)
const endDateTime = ref(null)
const sessionDuration = ref(null)
const timezone = ref(null)
const cronofyError = ref(null)
const dateTimeSlotSelected = ref(false)

const emit = defineEmits(['bookingCompleted', 'bookingFailed'])

const props = defineProps({
    bookingId: {
        type: Number,
        required: true,
    },
})

const selectedSlot= reactive({
    start: null,
    end: null,
    timezone: null,
    coach_cronofy_sub: null,
})

const loadBookingPrerequisites = async () => {
    const response = await axios.get(route('coaching.product.bookingPrerequisites', props.bookingId))
    if (! response.data.cronofyEnabled) {
        cronofyError.value = _mft('coaching:booking.calendarBookingNotEnabled')
        return;
    }

    bufferMinutesAfter.value = response.data.bufferMinutesAfter;
    sessionDuration.value = response.data.sessionDurationMinutes;
    startDateTime.value = response.data.startDate;
    endDateTime.value = response.data.endDate;
    cronofyAccountId.value = response.data.cronofyAccountId;
    cronofyEmbedToken.value = response.data.cronofyEmbedToken;
    timezone.value = response.data.timezone;

    cronofyEnabled.value = true
}

const onDateTimeSlotSelected = (dateTimeSlot) => {
    dateTimeSlotSelected.value = true;

    selectedSlot.start = dateTimeSlot.slot.start;
    selectedSlot.end = dateTimeSlot.slot.end;
    selectedSlot.timezone = dateTimeSlot.tzid;
    selectedSlot.coach_cronofy_sub = dateTimeSlot.slot.participants[0].sub;

    submitBooking();
}

const clearSelectedTimeSlot = () => {
    dateTimeSlotSelected.value = false;
    selectedSlot.start = null;
    selectedSlot.end = null;
    selectedSlot.timezone = null;
    selectedSlot.coach_cronofy_sub = null;
}

const submitBooking = async () => {
    const response = await axios.post(route('coaching.product.book', props.bookingId), {
        calendarProvider: 'cronofy',
        start: selectedSlot.start,
        end: selectedSlot.end,
        timezone: selectedSlot.timezone,
        coach_cronofy_sub: selectedSlot.coach_cronofy_sub,
    })

    if (response.data.success) {
        emit('bookingCompleted', response.data);
    } else {
        emit('bookingFailed', response.data);
    }
}

const onCronofyError = (error) => {
    cronofyError.value = error;
}

onMounted(() => {
    loadBookingPrerequisites();
})

</script>
<template>
    <div>
        <div v-if="cronofyEnabled">
            <cronofy-date-time-picker
                :class="dateTimeSlotSelected ? 'hidden' : ''"
                :cronofy-account-id="cronofyAccountId"
                :embed-token="cronofyEmbedToken"
                :buffer-minutes-after="bufferMinutesAfter"
                :required-duration="sessionDuration"
                :start-datetime="startDateTime"
                :end-datetime="endDateTime"
                :timezone="props.timezone"
                @dateTimeSlotSelected="onDateTimeSlotSelected"
                @cronofyError="onCronofyError"
            ></cronofy-date-time-picker>
        </div>
        <div v-if="cronofyError">
            {{ _mft('error:error.generalOccurred') }}: {{ cronofyError }}
        </div>
    </div>
</template>
