<script setup>
import {computed, onMounted, ref, watch} from "vue";
import IconChevron from "../../../icons/iconChevron.vue";
import IconMinus from "../../../icons/iconMinus.vue";
import ModalInvoiceForm from "./components/modal-invoice-form.vue";
import ModalFreeForm from "./components/modal-free-form.vue";
import { useNotificationStore } from '@/stores/NotificationStore.js'
const notificationStore = useNotificationStore()

const props = defineProps({
    content: {
        type: Object,
        required: true,
    },
})

const link = ref(null)
const expandedProduct = ref(null)
const purchaseIntiated = ref(false)
const selectedPurchaseMethod = ref(null)
const performingPurchase = ref(false)
const cancelUrl = ref(null)

const loadLink = async () => {
    if (! props.content.paymentLinkUuid) return;
    const res = await axios.get(route('invoice.public.show', {uuid: props.content.paymentLinkUuid}))

    link.value = res.data.link
    maybeShowNotification()
}

const toggleProduct = (i) => {
    if (expandedProduct.value === i) {
        expandedProduct.value = null
    } else {
        expandedProduct.value = i
    }
}

const initiatePurchase = () => {
    if (freeAvailable.value) {
        selectedPurchaseMethod.value = 'free'
        return
    }

    purchaseIntiated.value = true
}

const selectPurchaseMethod = (method) => {
    selectedPurchaseMethod.value = method
}

const cancelPurchase = () => {
    purchaseIntiated.value = false
    selectedPurchaseMethod.value = null
}

const purchaseCompleted = () => {
    purchaseIntiated.value = false
    selectedPurchaseMethod.value = null
}

const performPurchase = async (data) => {
    if (performingPurchase.value) return

    performingPurchase.value = true
    const res = await axios.post(route('invoicing.initiatePurchaseWithDetails', {paymentMethod: selectedPurchaseMethod.value, uuid: link.value.uuid}), {
        cancel_url: cancelUrl.value,
        invoice_details: data
    })

    if (res.data.error) {
        notificationStore.addNotification({
            type: 'error',
            title: _mft('error:error.generalOccurred'),
            message: res.data.error,
            dismissAfter: null,
        })
        return
    }

    purchaseCompleted()

    if (res.data.success) {
        notificationStore.addNotification({
            type: 'success',
            title: _mft('pagebuilderBlock:invoiceLink.purchaseCompleted.notification.title'),
            message: res.data.success,
            dismissAfter: null,
        })
    }

    if (res.data.redirect) {
        window.location.href = res.data.redirect
    }

    performingPurchase.value = false
}

const maybeShowNotification = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const state = urlParams.get('state');

    if (state === 'purchase-completed') {
        notificationStore.addNotification({
            type: 'success',
            title: _mft('pagebuilderBlock:invoiceLink.purchaseCompleted.notification.title'),
            message: _mft('pagebuilderBlock:invoiceLink.purchaseCompleted.notification.genericBody'),
            dismissAfter: 5000,
        })
    }
}

const setCancelUrl = () => {
    cancelUrl.value = window.location.href
}

const freeAvailable = computed(() => {
    return link.value.payment_methods.includes('free')
})

const stripeAvailable = computed(() => {
    return link.value.payment_methods.includes('stripe')
})

const invoiceAvailable = computed(() => {
    return link.value.payment_methods.includes('invoice')
})

const showPriceInclVat = computed(() => {
    return props.content.vatDisplayMode === 'inclVat'
})

const modalTitle = computed(() => {
    switch (selectedPurchaseMethod.value) {
        case 'free':
            return _mft('pagebuilderBlock:invoiceLink.payWithFree')
        case 'stripe':
            return _mft('pagebuilderBlock:invoiceLink.payWithStripe')
        case 'invoice':
            return _mft('pagebuilderBlock:invoiceLink.payWithInvoice')
    }
})

watch(() => props.content.paymentLinkUuid, (newVal) => {
    loadLink()
}, {deep: true})

onMounted(() => {
    setCancelUrl()
    loadLink()
})

</script>
<template>
    <div class="flex flex-row justify-center w-full align-center">
        <div class="w-full">
            <div v-if="link">
                <h2 class="mb-4">
                    {{ link.title }}
                </h2>

                <div class="@container">
                    <div :class="content.showProducts ? 'block space-y-8 @3xl:space-y-0 @3xl:grid @3xl:grid-cols-2 @3xl:gap-8' : ''">
                        <div v-if="content.showProducts">
                            <ul class="space-y-2">
                                <li
                                    v-for="(product, i) in link.products"
                                >
                                    <div class="flex justify-between space-x-2">
                                        <div>
                                            <h6 class="font-bold">{{ product.title }}</h6>
                                            <p
                                                v-if="product.quantity > 1"
                                                class="text-xs opacity-80"
                                            >{{ product.quantity }} {{ product.unit }}</p>
                                        </div>
                                        <button-transparent
                                            @click="toggleProduct(i)"
                                            size="sm"
                                        >
                                            <icon-chevron class="w-4 h-4" v-if="expandedProduct !== i" />
                                            <icon-minus class="w-4 h-4" v-if="expandedProduct === i" />
                                        </button-transparent>
                                    </div>
                                    <div
                                        v-if="expandedProduct === i"
                                        class="mb-6"
                                        v-html="product.description"
                                    >
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div class="@container">
                            <div class="@md:grid @md:grid-cols-5 @md:gap-4 items-start">
                                <div
                                    v-if="link.price_incl_vat !== 0"
                                    class="col-span-3"
                                >
                                    <div class="flex space-x-2 items-end">
                                        <div class="">
                                            <div
                                                v-if="link.show_discount && link.discount"
                                                class="whitespace-nowrap -mb-1"
                                            >
                                                {{ link.discount }}% off
                                            </div>
                                            <p class="mb-0 text-lg font-bold">
                                                <template
                                                    v-if="! showPriceInclVat"
                                                >
                                                    {{ link.price_display }} {{ link.currency }}
                                                </template>
                                                <template
                                                    v-if="showPriceInclVat"
                                                >
                                                    {{ link.price_incl_vat_display }} {{ link.currency }}
                                                </template>
                                            </p>
                                        </div>
                                    </div>
                                    <div class="text-xs opacity-80">
                                        <template
                                            v-if="! showPriceInclVat"
                                        >
                                            {{ _mft('pagebuilderBlock:invoiceLink.exclVat', {amount: link.vat_amount_display, currency: link.currency}) }}
                                        </template>
                                        <template
                                            v-if="showPriceInclVat"
                                        >
                                            {{ _mft('pagebuilderBlock:invoiceLink.inclVat', {amount: link.vat_amount_display, currency: link.currency}) }}
                                        </template>
                                    </div>
                                </div>

                                <div
                                    class="col-span-2"
                                    :class="link.show_discount ? 'mt-3' : ''"
                                >
                                    <button-primary
                                        v-if="! purchaseIntiated"
                                        @click="initiatePurchase"
                                        class="w-full justify-center"
                                    >
                                        {{ props.content.purchaseButtonText }}
                                    </button-primary>

                                    <div
                                        v-if="purchaseIntiated"
                                        class="flex flex-col space-y-4"
                                    >
                                        <button-primary
                                            v-if="stripeAvailable"
                                            @click="selectPurchaseMethod('stripe')"
                                            class="w-full justify-center"
                                        >
                                            {{ _mft('pagebuilderBlock:invoiceLink.payWithStripe') }}
                                        </button-primary>

                                        <button-primary
                                            v-if="invoiceAvailable"
                                            @click="selectPurchaseMethod('invoice')"
                                            class="w-full justify-center"
                                        >
                                            {{ _mft('pagebuilderBlock:invoiceLink.payWithInvoice') }}
                                        </button-primary>

                                        <button-secondary
                                            @click="cancelPurchase"
                                            class="w-full justify-center"
                                        >
                                            {{ _mft('shared:action.cancel') }}
                                        </button-secondary>
                                    </div>
                                </div>

                                <div class="mt-2 @md:mt-0 @md:col-span-5 col-span-2">
                                    <p
                                        v-if="link.instalment_count > 1"
                                        class="mb-0"
                                    >
                                        <span v-if="! showPriceInclVat">
                                            {{ _mft('pagebuilderBlock:invoiceLink.paidInInstallments', {instalment_count: link.instalment_count, amount: link.amount_per_instalment_display, currency: link.currency}) }}
                                        </span>
                                        <span v-else>
                                            {{ _mft('pagebuilderBlock:invoiceLink.paidInInstallments', {instalment_count: link.instalment_count, amount: link.amount_incl_vat_per_instalment_display, currency: link.currency}) }}
                                        </span>

                                    </p>
                                    <p
                                        v-if="link.create_account_for_buyer"
                                        class="italic mt-2 text-xs"
                                    >
                                        {{ _mft('pagebuilderBlock:invoiceLink.accountAutomaticallyCreated') }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <mf-spinner-medium v-else class="w-16 h-8"></mf-spinner-medium>

            <Teleport to="body">
                <modal-free-form
                    :open="selectedPurchaseMethod !== null && selectedPurchaseMethod === 'free'"
                    :title="modalTitle"
                    :performing-purchase="performingPurchase"
                    :purchase-label="props.content.purchaseButtonText"
                    :creates-account="link?.create_account_for_buyer"
                    @close="selectedPurchaseMethod = null"
                    @submit="performPurchase"
                />

                <modal-invoice-form
                    :open="selectedPurchaseMethod !== null && selectedPurchaseMethod !== 'free'"
                    :title="modalTitle"
                    :performing-purchase="performingPurchase"
                    :purchase-label="props.content.purchaseButtonText"
                    :creates-account="link?.create_account_for_buyer"
                    @close="selectedPurchaseMethod = null"
                    @submit="performPurchase"
                />
            </Teleport>
        </div>
    </div>
</template>
