/*
Basic usage:
 _mft('auth:credentials.password')

 Args usage:
  _mft('coaching:sessionsBooked', {count: 34})

 For some use case I cannot think of you may want to pass domain and key separately using the _mftkd method:
 _mftkd('coaching', 'sessionsBooked', {count: 34})

 */

import IntlMessageFormat from 'intl-messageformat';

const LOCALES = {};

const localeFiles = import.meta.glob('./locales/**/*.json', { eager: true });
Object.entries(localeFiles).forEach(([path, content]) => {
    for (const [key, value] of Object.entries(content.default)) {
        const messageId = path
            .replace(/^\.\/locales\//, '')
            .replace(/\.json$/, '')
            .split('/')
            .concat([key])
            .join('.');
        LOCALES[messageId] = value;
    }
});

const i18n = {
    cache: {},
    locale: document.documentElement.lang.substr(0, 2) || 'en',
    fallbackLocale: 'en',
    translate(key, domain, args = {}) {
        const messageId = `${this.locale || this.fallbackLocale}.${domain}.${key}`;

        if (!this.cache[messageId]) {
            let template = LOCALES[messageId];
            if (!template) {
                console.error('Missing translation', messageId);

                if (this.locale === this.fallbackLocale) {
                    // Return domain as a best effort to deliver the original string, uncluttered
                    return domain;
                }

                const fallbackMessageId = `${this.fallbackLocale}.${domain}.${key}`;
                template = LOCALES[fallbackMessageId];
                if (!template) {
                    if (!key) {
                        return domain;
                    }
                    return messageId;
                }

                console.warn('Using fallback translation', fallbackMessageId)
            }

            this.cache[messageId] = new IntlMessageFormat(template, this.locale);
        }

        try {
            return this.cache[messageId].format(args);
        } catch (err) {
            return messageId;
        }
    }
}

export function _mftkd(key, domain, args = {}) {
    return i18n.translate(key, domain, args);
}

export function _mft(domainKey, args = {}) {
    if (!domainKey) return 'Translation error: domainKey missing';
    const [domain, key] = domainKey.split(':');
    return i18n.translate(key, domain, args).replaceAll('[[', '<').replaceAll(']]', '>');
}
