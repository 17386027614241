<script setup>
const props = defineProps({
    containerRef: {
        type: String,
        required: true
    },
    block: {
        type: Object,
        required: true
    },
})

const anchorableId = props.block.uuid?.substring(props.block.uuid?.length - 12)

</script>
<template>
    <div
        class="styleable-block anchorable"
        :id="anchorableId"
    >
        <component
            :is="'page-builder-block-view-' + props.block.type"
            :container-ref="containerRef"
            v-bind="props.block"
            :data-blocktype="props.block.type"
        ></component>
    </div>
</template>
