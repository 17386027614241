<script setup>
import { ref } from "vue";

const props = defineProps({
    actionableSteps: {
        type: Array,
        required: true,
    }
})

const showSolutions = ref(false)

</script>

<template>
<div id="actions">
    <h1>Actionable Steps</h1>
    <div class="rounded-2xl bg-white mt-8 mb-6">
        <div class="divide-y ">
            <div
                v-for="(step, s) in props.actionableSteps"
                :key="s"
                class="flex space-x-2 md:space-x-6 p-4 md:p-6"
            >
                <div>
                    <div
                        class="-ml-1.5 md:ml-0 mt-1 md:mt-1.5 w-6 h-6 relative"
                        v-if="step.solved_with_myflow !== 'false'"
                    >
                        <div
                            class="inset-0 absolute transform transition-all"
                            :class="showSolutions ? '-translate-y-full scale-0 opacity-0' : ''"
                            :style="{transitionDelay: (s * 75) + 'ms'}"
                        >
                            <div
                                class="rounded-full bg-wlPrimary w-4 h-4 mx-1"
                            ></div>
                        </div>
                        <div
                            class="inset-0 absolute transform transition-all"
                            :class="[showSolutions ? '' : 'translate-y-full scale-0 opacity-0', step.solved_with_myflow === 'partial' ? 'opacity-20' : '']"
                            :style="{transitionDelay: (s * 75) + 'ms'}"
                        >
                            <logo-icon
                                class="transition-all w-6 h-6 mr-1 -mt-0.5 md:-mt-0"
                            ></logo-icon>
                        </div>
                    </div>
                    <div
                        class="-ml-1.5 md:ml-0 mt-1 md:mt-1.5 w-6 h-6 relative"
                        v-else
                    >
                        <div class="inset-0 absolute transform transition-all">
                            <div class="rounded-full bg-wlPrimary w-4 h-4 mx-1"></div>
                        </div>
                    </div>
                </div>
                <div>
                    <div class="flex">
                        <div
                            class="h-6 overlow-hidden"
                        >

                        </div>
                        <h3>{{ step.title }}</h3>
                    </div>
                    <div
                        class="text-md md:text-lg"
                        v-html="step.description"
                    ></div>
                </div>
            </div>
        </div>
        <div class="flex justify-end p-4 md:p-6 pt-0 md:pt-0">
            <button
                class="text-xs font-italic"
                @click="showSolutions = !showSolutions"
            >How can myflow help?</button>
        </div>
    </div>
</div>
</template>

<style scoped>

</style>
