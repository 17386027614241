<script setup>
import {computed, onMounted, reactive, ref, watch} from 'vue'
import ButtonWithConfirmation from "../../components/buttons/buttonWithConfirmation.vue";

    const links = ref([])
    const filterTerm = ref('')
    const editingPaymentLink = ref(null)

    const filterOptions = reactive({
        type: [
            {
                label: _mft('invoiceLink:filter.all'),
                name: 'all',
            },
            {
                label: _mft('invoiceLink:sendableInvoice'),
                name: 'sendable-invoice',
            },
            {
                label: _mft('invoiceLink:singleUse'),
                name: 'single-use',
            },
            {
                label: _mft('invoiceLink:multiUse'),
                name: 'multi-use',
            },
        ],
        active: [
            {
                label: _mft('invoiceLink:filter.all'),
                name: 'all'
            },
            {
                label: _mft('invoiceLink:filter.active'),
                name: 'active'
            },
            {
                label: _mft('invoiceLink:filter.notActive'),
                name: 'not-active'
            },
        ]
    })

    const filters = ref({
        type: null,
        active: null,
    })

    const loadLinks = async () => {
        const res = await axios.get(route('api.invoice-links.index', filters.value))
        links.value = res.data.links
    }

    const setFilter = (filter, event) => {
        filters.value[filter] = event.name
        _mfPrefSet('invoicing-link-'+filter+'-filter', event.name)
    }

    const duplicateLink = async (link) => {
        const res = await axios.post(route('api.invoice-links.duplicate', link.uuid))
        window.location.href = res.data.redirect
    }

    const filteredLinks = computed(() => {
        return links.value.filter(link => {
            if (filterTerm.value === '') return true
            return link.title.toLowerCase().includes(filterTerm.value.toLowerCase())
        })
    })

    watch(filters, () => {
        loadLinks()
    }, {deep: true})

    onMounted(() => {
        _mfPrefGet('invoicing-link-type-filter').then(data => {
            filters.value.type = data
            _mfPrefGet('invoicing-link-active-filter').then(data => {
                filters.value.active = data
                loadLinks()
            })
        })
    })
</script>

<template>
<div>
    <frosted-bar>
        <div class="flex items-center justify-between space-x-4">
            <h1>{{ _mft('invoiceLink:domainNamePlural') }}</h1>

            <div class="flex items-center space-x-4">
                <story-lane-modal :module="'invoice-links-v1'"></story-lane-modal>

                <a :href="route('invoice-links.new')">
                    <button-primary>
                        <mf-translate domainkey="invoiceLink:newLink"></mf-translate>
                    </button-primary>
                </a>
            </div>
        </div>
    </frosted-bar>

    <div class="flex flex-col items-end justify-end mt-8 md:space-x-4 md:flex-row md:items-center @container">
        <div class="w-full flex justify-end mb-2 space-x-4 md:mb-0">
            <div>
                <button-bar
                    size="xs"
                    class="!mb-0"
                    :responsive="false"
                    :options="filterOptions.type"
                    :selected-option-name="filters.type"
                    @select="setFilter('type', $event)"
                ></button-bar>
            </div>

            <div>
                <button-bar
                    size="xs"
                    class="!mb-0"
                    :responsive="false"
                    :options="filterOptions.active"
                    :selected-option-name="filters.active"
                    @select="setFilter('active', $event)"
                ></button-bar>
            </div>
        </div>
        <input-text
            v-model:content="filterTerm"
            :placeholder="_mft('shared:search')"
            class="w-48 !mb-0 -mt-1"
            input-class="text-xs"
        />
    </div>

    <div class="mt-2 bg-white shadow-xl rounded-wl">
        <p
            class="pt-6 pb-4 text-center text-gray-500"
            v-if="links.length === 0"
        >
            {{ _mft('invoiceLink:noLinksToShow') }}
        </p>
        <ul class="divide-y divide-gray-200">
            <li
                v-for="(link, index) in filteredLinks"
                :key="index"
            >
                <div
                    class="flex flex-col px-4 py-4 pr-4 hover:bg-gray-50 md:flex-row md:items-center md:justify-between md:px-6 md:pr-6"
                >
                    <div class="block">
                        <a :href="route('invoice-links.edit', link.uuid)" class="mf-link">
                            {{ link.title }}
                        </a>
                        <div class="space-y-1">
                            <div class="flex items-center space-x-4">
                                <div class="w-16">
                                    <span v-if="link.active" class="text-xs text-myflowGreen">{{ _mft('invoiceLink:active') }}</span>
                                    <span v-if="! link.active" class="text-xs opacity-50 text-myflowRed">{{ _mft('invoiceLink:notActive') }}</span>
                                </div>
                                <div class="w-24 text-xs">{{ _mft('invoiceLink:nProducts', {count: link.products.length}) }}</div>
                                <div class="text-xs">{{ link.price_display }} {{ link.currency}}</div>
                            </div>
                            <div class="text-xs">
                                <template
                                    v-if="link.valid_in_future"
                                >
                                    {{ _mft('invoiceLink:validFrom') }} {{ link.valid_from_short }}
                                </template>
                                <template v-else>
                                    <template v-if="link.valid_to && link.valid_no_longer">{{ _mft('invoiceLink:expiredOn') }} {{ link.valid_to_short }}</template>
                                    <template v-else-if="link.valid_to">
                                        {{ _mft('invoiceLink:validThrough') }} {{ link.valid_to_short }}
                                    </template>
                                </template>
                            </div>
                        </div>
                        <div class="text-xs">
                            <div
                                v-if="link.type === 'multi-use'"
                            >
                                <span>{{ _mft('invoiceLink:multiUse.listLabel') }}</span>
                            </div>
                            <div
                                v-if="link.type === 'sendable-invoice'"
                            >
                                <span>{{ _mft('invoiceLink:sendableInvoice.listLabel') }}</span>
                            </div>
                            <div
                                v-if="link.type === 'single-use'"
                                class="flex items-center space-x-2"
                            >
                                <span>{{ _mft('invoiceLink:singleUse.listLabel') }}</span>
                                <copyable-text
                                    :show-url="false"
                                    :copyable-text="siteRoute('invoicing.link.show', {uuid: link.uuid})"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="flex justify-end -mt-5 space-x-1 md:flex-col-reverse md:mt-0 md:items-end">
                        <button-with-confirmation
                            size="sm"
                            confirm-type="secondary"
                            :confirm-text="_mft('invoiceLink:duplicate.dialog.title')"
                            :confirm-button-label="_mft('invoiceLink:duplicate.dialog.confirm')"
                            :abort-button-label="_mft('shared:action.cancel')"
                            @confirmed="duplicateLink(link)"
                            class="mr-1 md:mr-0 md:mt-1"
                        >
                            {{ _mft('shared:action.duplicate') }}
                        </button-with-confirmation>
                        <button-primary
                            size="sm"
                            @click="editingPaymentLink = link"
                        >
                            <mf-translate domainkey="shared:action.edit"></mf-translate>
                        </button-primary>
                    </div>
                </div>
            </li>
        </ul>
    </div>

    <slideout-with-slots
        v-if="editingPaymentLink"
        @close="editingPaymentLink = null"
        max-width-class="max-w-xl"
        :title="editingPaymentLink.title"
    >
        <template #body>
            <invoice-link-form
                :invoice-link-uuid="editingPaymentLink.uuid"
                form-style="ui"
                :show-title="false"
                :hidden-features="['backlink', 'navigation']"
            ></invoice-link-form>
        </template>
    </slideout-with-slots>
</div>
</template>

<style scoped>

</style>
