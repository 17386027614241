<script setup>
    import { ref, reactive, computed, onMounted, watch } from 'vue'

    const emit = defineEmits(['update:location', 'embedMeetingsUpdate'])

    const props = defineProps({
        location: {
            type: String,
            required: false,
            default: ''
        },
        embedMeetings: {
            type: Boolean,
            required: false,
            default: false,
        }
    })

    const meetingLocationType = ref(null)

    const editableLocation = ref(props.location)

    const isEmbeddable = ref(false)
    const embedMeetings = ref(props.embedMeetings)

    const meetingOptions = reactive({
        meeting_location_types: [
            {
                label: 'Google Meet',
                value: 'google-meet',
            },
            {
                label: 'Microsoft Teams',
                value: 'microsoft-teams',
            },
            {
                label: 'Whereby',
                value: 'whereby',
            },
            {
                label: 'Zoom',
                value: 'zoom',
            },
            {
                label: 'Fysisk plats',
                value: 'on-location',
            },
            {
                label: 'Telefon',
                value: 'phone',
            },
            {
                label: 'Annan',
                value: 'other',
            }
        ],
        meeting_location_type_texts: {
            'google-meet': _mft('location:googleMeet.description'),
            'microsoft-teams': _mft('location:microsoftTeams.description'),
            'whereby': _mft('location:whereby.description'),
            'zoom': _mft('location:zoom.description'),
            'on-location': _mft('location:physicalLocation.description'),
            'phone': _mft('location:phone.description'),
            'other': _mft('location:other.description'),
        }
    })

    const meetingLocationLabel = computed(() => {
        return meetingOptions.meeting_location_type_texts[meetingLocationType.value] ? meetingOptions.meeting_location_type_texts[meetingLocationType.value] : _mft('location:other.description')
    })

    const presetDeterminedLocationType = (url) => {
        if (!url) return
        let type = null
        if (url.includes('meet.google.com')) {
            type = 'google-meet'
        } else if (url.includes('teams.microsoft.com')) {
            type = 'microsoft-teams'
        } else if (url.includes('whereby.com')) {
            type = 'whereby'
        } else if (url.includes('zoom.us')) {
            type = 'zoom'
        }

        if (type) {
            meetingLocationType.value = type
        }
    }

    const determineIsEmbeddable = (url) => {
        if (!url) return
        if (url.includes('whereby.com')) {
            isEmbeddable.value = true
            // embedMeetings.value = false
        } else {
            isEmbeddable.value = false
            // embedMeetings.value = false
        }
    }

    watch(meetingLocationType, (newValue, oldValue) => {
        if (newValue === 'whereby') {
            isEmbeddable.value = true
        } else {
            isEmbeddable.value = false
            embedMeetings.value = false
        }
    })

    watch(editableLocation, (newValue, oldValue) => {
        presetDeterminedLocationType(editableLocation.value);
        determineIsEmbeddable(editableLocation.value);
        emit('update:location', newValue)
    })

    watch(embedMeetings, (newValue, oldValue) => {
        emit('update:embedMeetings', newValue)
    })

    onMounted(() => {
        presetDeterminedLocationType(editableLocation.value);
        determineIsEmbeddable(editableLocation.value);
    })

</script>
<template>
    <div>
        <!-- <input-select
            v-model:content="meetingLocationType"
            :options="meetingOptions.meeting_location_types"
            label="Platstyp"
            placeholder=""
            name="meeting_location_type"
        /> -->
        <togglable-input
            v-model:content="editableLocation"
            :label="meetingLocationLabel"
            required
            placeholder=""
            name="default_meeting_location"
        />

        <toggle-switch
            v-if="isEmbeddable"
            :label="_mft('coaching:embedMeeting')"
            v-model:content="embedMeetings"
        />
    </div>
</template>
