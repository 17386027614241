<script setup>

import { computed, ref, onMounted } from 'vue';

const specialists = ref([]);
const purposes = ref([]);
const purposeFilter = ref(null);

const loadSpecialists = async () => {
    const res = await axios.get(route('specialist.index'));
    specialists.value = res.data.specialists;
    purposes.value = res.data.purposes;
};

const setPurposeFilter = (purpose) => {
    if( purposeFilter.value === purpose ) {
        purposeFilter.value = null;
    } else {
        purposeFilter.value = purpose;
    }
};

const filteredSpecialists = computed(() => {
    if( purposeFilter.value === null ) {
        return specialists.value;
    } else {
        return specialists.value.filter((specialist) => {
            return specialist.offers.some((offer) => {
                return offer.purpose.id === purposeFilter.value.id;
            });
        });
    }
});

onMounted(() => {
    loadSpecialists();
});

</script>

<template>
    <div class="w-full">

        <p class="mb-1 text-xs">{{ _mft('specialist:showOnlyOffersPertaining') }}</p>
        <div class="flex flex-wrap mb-8 space-x-2">
            <div
                v-for="(purpose, i) in purposes"
                v-bind:key="i"
            >
                <button-base
                    @click="setPurposeFilter(purpose)"
                    size="sm"
                    :active="purposeFilter === purpose"
                    class="p-1 m-1"
                >
                    {{ purpose.label }}
                </button-base>
            </div>
        </div>

        <div class="mb-8 lg:bg-white lg:rounded-xl lg:shadow-md lg:divide-y">
            <div
                v-for="(specialist, i) in filteredSpecialists"
                v-bind:key="i"
                class="py-4 mb-4 bg-white shadow-md rounded-xl lg:bg-transparent lg:rounded-none lg:shadow-none lg:mb-0"
            >
                <specialist-listitem
                    :specialist="specialist"
                    :appliedFilters="{purpose: purposeFilter}"
                />
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
