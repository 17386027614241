<script setup>
import { reactive, ref } from 'vue';

    const emit = defineEmits(['updateParent']);
    const props = defineProps({
        question: {
            type: Object,
            required: true
        }
    });

    const state = reactive({
        deleting: false
    });

    async function deleteVotingQuestion() {
        const res = await axios.delete( route('surveys.deleteItem', [props.question.survey_id, props.question.id]));
        emit('updateParent');
    }

    function initDeletion() {
        state.deleting = true;
    }

    function cancel() {
        state.deleting = false;
    }
</script>
<template>
    <div>
        <div
            class="fixed inset-0 bg-black bg-opacity-25 z-50"
            @click="cancel"
            v-if="state.deleting"
        ></div>

        <div class="relative">
            <button @click="initDeletion">
                Ta bort
            </button>
            <div
                class="absolute shadow-lg -bottom-2 -right-2 p-4 bg-white rounded-lg w-64 text-center z-50"
                v-if="state.deleting"
            >
                <p>
                    <strong class="block">Är du säker?</strong>
                    Du tar bort alternativet och alla röster det fått.
                </p>
                <div class="flex justify-around space-x-4">
                    <button
                        class="myflow-basic-button--danger"
                        @click="deleteVotingQuestion"
                    >Ta bort</button>
                    <button
                        class="myflow-basic-button--secondary"
                        @click="cancel"
                    >Avbryt</button>
                </div>
            </div>
        </div>
    </div>
</template>
