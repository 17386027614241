<script setup>

import {ref} from "vue";

const props = defineProps({
    visible: {
        type: Boolean,
        required: false,
        default: false,
    },
    counter: {
        type: Number,
        required: true,
    }
})
const readingMore = ref(false)

</script>

<template>
    <div
        class="grid-cols-6 @md:gap-4 transition-all duration-500 @md:grid px-2"
        :class="visible ? 'translate-y-0 mb-4' : '-translate-y-4 scale-95 opacity-0 h-0 overflow-hidden'"
        :style="{transitionDelay: (counter + 1) * 100 + 'ms'}"
    >
        <div class="col-span-1 @lg:col-span-1 hidden @md:block">
            <slot name="image"></slot>
        </div>
        <div class="col-span-6 @md:col-span-5">
            <slot name="body"></slot>

            <div class="text-right">
                <span class="cursor-pointer mf-link" @click="readingMore = true">More...</span>
            </div>
        </div>

        <modal-basic
            :open="readingMore"
            @closed="readingMore = false"
        >
            <template #body>
                <slot name="readmore"></slot>
            </template>
        </modal-basic>
    </div>
</template>

<style scoped>

</style>
