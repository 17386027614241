<script setup>
import { onMounted, ref, computed } from 'vue';
import axios from 'axios';

const communities = ref([]);

async function getCommunities() {
    const response = await axios.get(route('community.admin.api.index'));
    communities.value = response.data;
}

onMounted(() => {
    getCommunities();
});

defineExpose({
    getCommunities,
});

</script>

<template>
    <div>
        <p
            v-if="communities.length === 0"
            class="text-gray-400 mt-4 text-xs"
        >
            {{ _mft('community:communities.noCommunitiesYet') }}
        </p>
        <list-basic>
            <list-basic-item
                v-for="community in communities"
                :key="community.id"
            >
                <template #title>
                    {{ community.name }}
                </template>

                <template #actions>
                    <a
                        class="text-center text-inherit"
                        :href="route('community.admin.show', community.id)"
                    >
                        <button-base
                            size="sm"
                        >
                            Manage
                        </button-base>
                    </a>
                </template>
            </list-basic-item>
        </list-basic>
    </div>
</template>
