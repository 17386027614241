<template>
    <div v-if="value">
        <select
            v-model="value.survey_id"
            :name="value.survey_id"
            class="myflow-basic-input"
        >
            <option
                v-for="survey in surveys"
                v-bind:key="survey.id"
                :value="survey.id"
                :selected="survey.id === value.survey_id"
            >
                {{ survey.label }}
            </option>
        </select>

        <a :href="route('surveys.manage')" class="myflow-basic-button--secondary" target="_blank">Hantera omröstningar</a>

        <button type="button" v-on:click="getSurveys()" class="ml-2 myflow-basic-button--warning">Ladda om tillgängliga omröstningar</button>
    </div>
    <mf-spinner-medium v-else class="w-16 h-8"></mf-spinner-medium>
</template>
<script>
export default {
    props: {
        modelValue: {},
    },
    emits: [
        'update:modelValue'
    ],
    data() {
        return {
            value: null,
            surveys: []
        };
    },
    async mounted() {
        this.captureModelValue();
        this.getSurveys();
    },
    watch: {
        modelValue() {
            if (JSON.stringify(this.value) === JSON.stringify(this.modelValue)) { return; }
            this.captureModelValue();
        },
        value: {
            handler() {
                this.$emit('update:modelValue', JSON.parse(JSON.stringify(this.value)));
            },
            deep: true
        }
    },
    methods: {
        captureModelValue() {
            const value = this.modelValue ? JSON.parse(JSON.stringify(this.modelValue)) : {};
            if (!value.survey_id) { value.survey_id = null; }
            this.value = value;
        },
        async getSurveys() {
            const res = await axios.get( route('surveys.getSurveys'));
            this.surveys = res.data;
        },
    },
}
</script>
