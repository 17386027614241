<template>
    <div>
        <div v-if="isDataLoaded">
            <LineChart :options="chartOptions" :chart-data="chartData" />

            <div class="text-center">
                <button type="button" :class="buttonClasses(7)" @click="chartPeriod(7)">Senaste 7 dagar</button>
                <button type="button" :class="buttonClasses(14)" @click="chartPeriod(14)">Senaste 14 dagar</button>
                <button type="button" :class="buttonClasses(30)" @click="chartPeriod(30)">Senaste 30 dagar</button>
                <button type="button" :class="buttonClasses(90)" @click="chartPeriod(90)">Senaste 90 dagar</button>
                <button type="button" :class="buttonClasses(365)" @click="chartPeriod(365)">Senaste 365 dagar</button>
                <button type="button" class="p-2 text-myflowRed-900" @click="reset">Återställ</button>
                <br>
                <i class="p-2">Eller välj specifika datum:</i>
                <input type="date" v-model="startDate" @change="manualUpdate" />
                <input type="date" v-model="endDate" @change="manualUpdate" />
            </div>
        </div>
        <div v-else class="text-center">
            <mf-spinner-medium class="w-24 h-12" />
        </div>
    </div>
</template>

<script>
import { LineChart } from 'vue-chart-3';
import { ref, onMounted, computed } from 'vue';
import axios from 'axios';
import 'chart.js/auto';
import 'chartjs-adapter-date-fns';

export default {
    components: {
        LineChart,
    },
    props: {
        componentDataUrl: {
            type: String,
            default: () => '',
        },
    },
    setup(props) {
        const componentData = ref([]);
        const projectionData = ref([]);
        const startDate = ref('');
        const endDate = ref('');
        const selectedPeriod = ref(null);
        const isDataLoaded = ref(false);

        const fetchComponentData = async () => {
            try {
                const response = await axios.get(props.componentDataUrl);

                componentData.value = Array.isArray(response.data.historicalData)
                ? response.data.historicalData
                : [];
                projectionData.value = Array.isArray(response.data.projectionData)
                ? response.data.projectionData
                : [];

                console.log('Component Data:', componentData.value);
                console.log('Projection Data:', projectionData.value);

                if (projectionData.value.length > 0) {
                    const lastProjectionDate = projectionData.value[projectionData.value.length - 1].date;
                    endDate.value = lastProjectionDate;
                } else if (componentData.value.length > 0) {
                    endDate.value = componentData.value[componentData.value.length - 1].date;
                }

                if (componentData.value.length > 0) {
                startDate.value = componentData.value[0].date;
                }

                isDataLoaded.value = true;
            } catch (error) {
                console.error('Error fetching data', error);
                componentData.value = [];
                projectionData.value = [];
                isDataLoaded.value = true;
            }
        };

        const chartPeriod = (days) => {
            selectedPeriod.value = days;
            const end = new Date();
            endDate.value = end.toISOString().slice(0, 10);

            const start = new Date();
            start.setDate(start.getDate() - days);
            startDate.value = start.toISOString().slice(0, 10);
        };

        const reset = () => {
            selectedPeriod.value = null;
            if (componentData.value.length > 0) {
                startDate.value = componentData.value[0].date;
                endDate.value =
                projectionData.value.length > 0
                    ? projectionData.value[projectionData.value.length - 1].date
                    : componentData.value[componentData.value.length - 1].date;
            }
        };

        const manualUpdate = () => {
            selectedPeriod.value = null;
            updateChart();
        };

        const buttonClasses = (day) => {
            return {
                'p-2 text-myflowPurple-700 underline': selectedPeriod?.value === day,
                'p-2 text-myflowPurple-700 italic': selectedPeriod?.value !== day,
            };
        };

        onMounted(async () => {
            await fetchComponentData();
            reset();
        });

        const chartData = computed(() => {
        if (!Array.isArray(componentData.value) || !Array.isArray(projectionData.value)) {
            return {};
        }

        console.log('Start Date:', startDate.value);
        console.log('End Date:', endDate.value);

        const filteredData = componentData.value.filter(
            (item) => item.date >= startDate.value && item.date <= endDate.value
        );
        const filteredProjectionData = projectionData.value.filter(
            (item) => item.date >= startDate.value && item.date <= endDate.value
        );

        const labels = Array.from(
            new Set([
            ...filteredData.map((item) => item.date),
            ...filteredProjectionData.map((item) => item.date),
            ])
        ).sort();

        console.log('Filtered Data:', filteredData);
        console.log('Filtered Projection Data:', filteredProjectionData);
        console.log('Labels:', labels);

        return {
            labels: labels,
            datasets: [
            {
                label: 'Actual Data',
                data: labels.map((date) => {
                    const item = filteredData.find((data) => data.date === date);
                    return item ? item.count : null;
                }),
                backgroundColor: 'rgba(98, 37, 120,0.2)',
                borderColor: 'rgba(98, 37, 120,1)',
                borderWidth: 1,
                tension: 0.2,
                pointRadius: 0.5,
                fill: false,
            },
            {
                label: 'Projected Data',
                data: labels.map((date) => {
                    const item = filteredProjectionData.find((data) => data.date === date);
                    return item ? item.count : null;
                }),
                backgroundColor: 'rgba(98, 37, 120,0.2)',
                borderColor: 'rgba(98, 37, 120, 0.2)',
                borderWidth: 1,
                tension: 0.2,
                pointRadius: 0.5,
                fill: -1,
            },
            ],
        };
    });

    const updateChart = () => {
        chartData.value;
    };

    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
            },
            tooltip: {
                mode: 'index',
                intersect: false,
            },
        },
        interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false,
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                },
            },
        },
    };

    return {
        buttonClasses,
        chartData,
        startDate,
        endDate,
        updateChart,
        chartOptions,
        chartPeriod,
        reset,
        selectedPeriod,
        manualUpdate,
        isDataLoaded,
        };
    },
};

</script>
