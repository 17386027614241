<template>
  <div
    :class="computedclass"
  >
    <div 
      class="toolbar"
      :class="toolbarclass"
    >
    {{}}
      <button
        @click="toggleFullscreen"
      >
        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          class="h-6 w-6" 
          fill="none" 
          viewBox="0 0 24 24" 
          stroke="currentColor" 
          stroke-width="2"
          v-if="!fullscreen"
        >
          <path stroke-linecap="round" stroke-linejoin="round" d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4" />
        </svg>

        <svg 
          xmlns="http://www.w3.org/2000/svg" 
          class="h-6 w-6" 
          fill="none" 
          viewBox="0 0 24 24" 
          stroke="currentColor" 
          stroke-width="2"
          v-if="fullscreen"
        >
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg>
        
      </button>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: {
    toolbarclass: {
      type: String,
      default: ''
    }
  },
  data(){
    return {
      fullscreen: false
    }
  },
  methods: {
    toggleFullscreen()
    {
      this.fullscreen = !this.fullscreen;
    }
  },
  computed: {
    computedclass(){
      if( this.fullscreen ){
        return 'focusmodeActive w-full h-full bg-white fixed inset-0 z-999 overflow-y-scroll'
      } else {
        return ''
      }
    }
  }
}
</script>
<style scoped>
  .z-999 {
    z-index: 999;
  }
</style>