<template>
    <div
        class="flex items-center justify-center badge"
        @click="init()"
    >
        <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-5 h-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
        >
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
        </svg>
        <basic-slideout
            v-model:open="showSearchSlideout"
        >
            <template v-slot:header>
                <div>
                    <h1>Sök experter</h1>
                </div>
            </template>
            <template v-slot:body>

                <input
                    type="text"
                    placeholder="Vad letar du efter?"
                    class="myflow-basic-input"
                    v-model="searchTermInput"
                    id="search-term-input"
                />

                <p
                    v-if="resultQuery.length === 0"
                    class="text-sm text-gray-400"
                >
                    <em>Inga resultat matchade din sökning</em>
                </p>
                <div
                    v-for="result in resultQuery"
                    v-bind:key="result.id"
                    class="text-gray-700 transition-all transform"
                    :class="[loadingResults ? 'opacity-0 translate-y-4' : 'opacity-100 translate-y-0']"
                >
                    <a :href="'/experts/'+result.id">
                        <h5 class="text-gray-700">{{ result.name }}</h5>
                        <b class="text-gray-700">{{ result.title }}</b>
                    </a>
                    <hr class="mt-3 mb-3">
                </div>

            </template>
            <template v-slot:footer>
                <button
                    class="myflow-basic-button"
                    v-on:click="closeSlideout()"
                >
                    Stäng
                </button>
            </template>
        </basic-slideout>
    </div>
</template>
<script>
import axios from 'axios';
import { nextTick } from 'vue'

export default {
    data() {
        return {
            searchTermInput: '',
            searchTerm: '',
            loadingResults: false,
            showSearchSlideout: false,
            searchResult: [],
        };
    },
    methods: {
        async init() {
            const response = await axios.get(route('searchCourse'));
            this.searchResult = response.data;

            this.showSearchSlideout = true
        },
        closeSlideout() {
            this.showSearchSlideout = false
        },
        debounce (fn, delay) {
            let timeoutID = null
            return function () {
                clearTimeout(timeoutID)
                let args = arguments
                let self = this
                timeoutID = setTimeout(function () {
                   fn.apply(self, args)
                }, delay)
            }
        }
    },
    computed: {
        resultQuery() {
            if (this.searchTerm) {
                return this.searchResult.filter(item => {
                    return this.searchTerm
                        .toLowerCase()
                        .split(" ")
                        .every(v => item.raw.toLowerCase().includes(v));
                    }
                );
            } else {
                return this.searchResult;
            }
        }
    },
    watch: {
        searchTermInput(...args) {
            this.loadingResults = true
            this.debouncedWatch(...args);
        },
        showSearchSlideout() {
            if( this.showSearchSlideout ){
                nextTick(function () {
                    document.getElementById('search-term-input').focus()
                })
            }
        }
    },
    created() {
        this.debouncedWatch = this.debounce((newValue) => {
            this.searchTerm = newValue
            this.loadingResults = false
        }, 500);
    },
    beforeUnmount() {
        if(this.debouncedWatch.cancel){
            this.debouncedWatch.cancel();
        }
    },
    mounted: function(){
    }
};
</script>

<style lang="scss" scoped>
</style>
