<template>
    <div>
        <div v-if="componentData !== null">
            <BarChart :options="chartOptions" :chart-data="chartData" />

            <div class="text-center">
                <button type="button" :class="buttonClasses(7)" @click="chartPeriod(7)">Senaste 7 dagar</button>
                <button type="button" :class="buttonClasses(14)" @click="chartPeriod(14)">Senaste 14 dagar</button>
                <button type="button" :class="buttonClasses(30)" @click="chartPeriod(30)">Senaste 30 dagar</button>
                <button type="button" :class="buttonClasses(90)" @click="chartPeriod(90)">Senaste 90 dagar</button>
                <button type="button" :class="buttonClasses(365)" @click="chartPeriod(365)">Senaste 365 dagar</button>

                <button type="button" class="p-2 text-myflowRed-900" @click="reset">Återställ</button>

                <br>

                <i class="p-2">Eller välj specifika datum:</i>
                <input type="date" v-model="startDate" @change="manualUpdate" />
                <input type="date" v-model="endDate" @change="manualUpdate" />
            </div>
        </div>
        <div v-else class="text-center">
            <mf-spinner-medium
                class="w-24 h-12"
            />
        </div>
    </div>
</template>

<script>
import { BarChart } from 'vue-chart-3'
import { ref, onMounted, computed } from 'vue'
import axios from 'axios'
import 'chart.js/auto';

export default {
    components: {
        BarChart
    },
    props: {
        componentDataUrl: {
            type: String,
            default: () => ''
        },
    },
    setup(props) {
        const componentData = ref([]);
        const startDate = ref('');
        const endDate = ref('');
        const selectedPeriod = ref(null);

        const fetchComponentData = async () => {
            try {
                const response = await axios.get(props.componentDataUrl);
                componentData.value = response.data;
            } catch (error) {
            }
        };

        const chartPeriod = (days) => {
            selectedPeriod.value = days;
            const end = new Date();
            endDate.value = end.toISOString().slice(0, 10);

            const start = new Date();
            start.setDate(start.getDate() - days);
            startDate.value = start.toISOString().slice(0, 10);
        };

        const reset = () => {
            selectedPeriod.value = null;
            if (componentData.value.length > 0) {
                startDate.value = componentData.value[0].date;
                endDate.value = componentData.value[componentData.value.length - 1].date;
            }
        };

        const calculateMovingAverage = (data, dayPeriod) => {
            let result = [];
            for (let i = 0; i < dayPeriod - 1; i++) {
                result[i] = null;
            }

            for (let i = dayPeriod - 1; i < data.length; i++) {
                let sum = 0;
                for (let j = 0; j < dayPeriod; j++) {
                sum += data[i - j].count;
                }
                result[i] = sum / dayPeriod;
            }

            return result;
        }

        const manualUpdate = () => {
            selectedPeriod.value = null;
            updateChart();
        };

        const buttonClasses = (day) => {
            return {
                'p-2 text-myflowPurple-700 underline': selectedPeriod?.value === day,
                'p-2 text-myflowPurple-700 italic': selectedPeriod?.value !== day
            }
        };

        onMounted(async () => {
            await fetchComponentData();
            reset();
        });

        const chartData = computed(() => {
            let movingAverage = calculateMovingAverage(componentData.value, 30);

            let filteredData = componentData.value.filter(item => item.date >= startDate.value && item.date <= endDate.value);
            let filteredAverage = movingAverage.slice(-filteredData.length);

            return {
                labels: filteredData.map(item => item.date),
                datasets: [
                    {
                        label: 'Data',
                        data: filteredData.map(item => item.count),
                        backgroundColor: 'rgba(98, 37, 120,0.2)',
                        borderColor: 'rgba(98, 37, 120, 1)',
                        borderWidth: 1,
                        tension: 0.2,
                        pointRadius: 0.5,
                    },
                    {
                        type: 'line',
                        label: '30-Day Moving Average',
                        data: filteredAverage,
                        backgroundColor: 'rgba(0, 0, 0, 0)',
                        borderColor: 'rgba(83, 119, 43, 1)',
                        borderWidth: 1.2,
                        tension: 0.2,
                        pointRadius: 0.5,
                        fill: false,
                    },
                ]
            }
        });



        const updateChart = () => {
            // Simply triggering a reactivity by calling the computed property
            chartData.value
        };

        const chartOptions = {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                },
                tooltip: {
                    mode: 'index',
                    intersect: false,
                },
            },
            interaction: {
                mode: 'nearest',
                axis: 'x',
                intersect: false
            },
        }

        return {
            buttonClasses,
            chartData,
            startDate,
            endDate,
            updateChart,
            chartOptions,
            chartPeriod,
            reset,
            selectedPeriod,
            manualUpdate
        }
    }
}
</script>
