<script setup>
    import { ref, onMounted } from 'vue';
    import { useNotificationStore } from '@/stores/NotificationStore.js'

    const notificationStore = useNotificationStore()
    const emit = defineEmits(['outreachInitiated']);

    const props = defineProps({
        selectedLeads: {
            type: Array,
            required: true
        }
    });

    const salesProcesses = ref([]);
    const showList = ref(false);

    const loadSalesProcesses = () => {
        axios.get(route('sales-processes.list')).then((response) => {
            salesProcesses.value = response.data.salesProcesses;
        });
    }

    const initiateOutreach = () => {
        showList.value = !showList.value;
    }

    const startOutreach = (processId) => {
        axios.post(route('crm.initiateOutreach', processId), {
            selected: props.selectedLeads
        }).then((response) => {
            notificationStore.addNotification({
                type: 'success',
                message: _mft('crm:outreach.campaignInitiated'),
                dismissAfter: 2500,
            })
            emit('outreachInitiated');
        });
    }

    onMounted(() => {
        loadSalesProcesses();
    });

</script>
<template>
    <div>
        <div class="flex justify-end">
            <button-base
                v-if="!showList"
                size="sm"
                @click="initiateOutreach"
            >
                {{ _mft('crm:outreach.addToCampaign') }}
            </button-base>
            <button-base
                v-else
                size="sm"
                @click="showList = false"
            >
                {{ _mft('crm:outreach.hideSalesProcesses') }}
            </button-base>
        </div>
        <ul v-if="showList" class="mt-2">
            <li
                v-for="salesProcess in salesProcesses"
                :key="salesProcess.id"
                class="py-1 text-xs flex space-x-2 justify-between items-center"
            >
                <span>{{ salesProcess.name }}</span>
                <button-primary
                    size="sm"
                    @click="startOutreach(salesProcess.id)"
                >
                    {{ _mft('crm:outreach.startCampaign') }}
                </button-primary>
            </li>
        </ul>
    </div>
</template>
