<template>
    <div class="mt-5 space-y-2">
        <div
            class="flex cursor-pointer"
            v-on:click="setOption('')"
        >
            <span
                class="relative inline-block w-8 h-8 mt-1 mr-2 bg-white border rounded-full shadow-sm"
            >
                <span
                    class="absolute rounded-full inset-1"
                    :class="_purchaseableSetting === '' ? 'bg-myflowPurple-700' : 'bg-white'"
                ></span>
            </span>
            <div>
                <h5>{{ _mft('subscription:availableToSubscribers.title') }}</h5>
                <p>{{ _mft('subscription:availableToSubscribers.text') }}</p>
            </div>
        </div>
        <div
            class="flex cursor-pointer"
            v-on:click="setOption('aftersell_enabled')"
        >
            <span
                class="relative inline-block w-8 h-8 mt-1 mr-2 bg-white border rounded-full shadow-sm"
            >
                <span
                    class="absolute rounded-full inset-1"
                    :class="_purchaseableSetting === 'aftersell_enabled' ? 'bg-myflowPurple-700' : 'bg-white'"
                ></span>
            </span>
            <div>
                <h5>{{ _mft('subscription:aftersellEnabled.title') }}</h5>
                <p>
                    {{ _mft('subscription:aftersellEnabled.text') }}
                </p>
                <input-number
                    v-if="_purchaseableSetting === 'aftersell_enabled'"
                    :required="true"
                    class="w-full max-w-xs"
                    :label="_mft('subscription:aftersellPrice.label', {currency: 'SEK'})"
                    :content="_purchasePrice"
                    v-model:content="_purchasePrice"
                />
            </div>
        </div>
        <div
            class="flex cursor-pointer"
            v-on:click="setOption('upsell_enabled')"
        >
            <span
                class="relative inline-block w-8 h-8 mt-1 mr-2 bg-white border rounded-full shadow-sm"
            >
                <span
                    class="absolute rounded-full inset-1"
                    :class="_purchaseableSetting === 'upsell_enabled' ? 'bg-myflowPurple-700' : 'bg-white'"
                ></span>
            </span>
            <div>
                <h5>{{ _mft('subscription:upsellEnabled.title') }}</h5>
                <p>
                    {{ _mft('subscription:upsellEnabled.text') }}
                </p>
                <input-number
                    v-if="_purchaseableSetting === 'upsell_enabled'"
                    :required="true"
                    class="w-full max-w-xs"
                    :label="_mft('subscription:upsellPrice.label', {currency: 'SEK'})"
                    :content="_purchasePrice"
                    v-model:content="_purchasePrice"
                />
            </div>
        </div>

        <input type="hidden" name="purchaseable_setting" v-model="_purchaseableSetting">
        <input type="hidden" name="purchaseable_price" v-model="_purchasePrice">
    </div>
</template>
<script>
export default {
    components: {
    },
    props: {
        purchasePrice: {
            type: Number,
            required: false,
            default: null
        },
        aftersellEnabled: {
            type: Boolean,
            required: true,
            default: false
        },
        upsellEnabled: {
            type: Boolean,
            required: true,
            default: false
        },
    },
    methods: {
        setOption(option) {
            this._purchaseableSetting = option;
        }
    },
    data() {
        return {
            _purchaseableSetting: '',
            _purchasePrice: null,
            purchaseOptions: [
                {
                    value: '',
                    label: _mft('subscription:purchaseOption.default')
                },
                {
                    value: 'aftersell_enabled',
                    label: _mft('subscription:purchaseOption.aftersellEnabled')
                },
                {
                    value: 'upsell_enabled',
                    label: _mft('subscription:purchaseOption.upsellEnabled')
                },
            ]
        }
    },
    mounted() {
        if( this.aftersellEnabled ){
            this._purchaseableSetting = 'aftersell_enabled';
        }
        if( this.upsellEnabled ){
            this._purchaseableSetting = 'upsell_enabled';
        }
        this._purchasePrice = this.purchasePrice;
    }
}
</script>
