<template>
    <div class="relative overflow-hidden">
        <Teleport
            v-if="data.trailerVideoUrl"
            to="#trailer-player-teleport-target"
        >
            <trailer-video
                :trailerVideoUrl="data.trailerVideoUrl"
                @close="closeTrailer()"
            />
        </Teleport>
        <div
            class="absolute z-30 flex flex-col justify-between transition-all duration-300 delay-75 bg-white shadow-md rounded-2xl inset-2 faded-top faded-bottom"
            :class="[data.previewing ? 'top-2 bottom-2' : 'top-full -bottom-full' ]"
        >
            <div
                class="absolute pt-6 pb-6 overflow-y-scroll text-xs inset-x-4 top-2 bottom-2 md:text-sm"
                v-html="expert.description"
            ></div>
            <div class="absolute flex justify-center -bottom-2 left-4 right-4">
                <button
                    class="relative z-10 p-1 transition transform rotate-45 rounded-full shadow-md text-myflowPurple ring-2 ring-white"
                    :class="data.cardButtonClass"
                    @click="toggleDescription"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                    </svg>
                </button>
            </div>
        </div>

        <div
            class="flex flex-col justify-between h-full mb-4 overflow-hidden transition-transform shadow-lg rounded-2xl bg-myflowPurple bg-gradient-to-r from-myflowPurple-600 to-myflowPurple-900"
            :class="[data.previewing ? 'scale-90' : '']"
        >
            <div
                class="flex flex-col h-full"
            >
                <div
                    class="relative h-full px-4 pt-2 pb-2 m-3 -mt-4 transition-all bg-white shadow-md rounded-2xl"
                >
                    <div class="pt-2 pb-2 bg-white rounded-t-2xl">
                        <div class="relative">
                            <p
                                class="mb-0 text-xs font-bold text-gray-600 break-words xl:text-sm"
                                v-if="expert.title"
                            >{{ expert.title }}</p>
                        </div>
                    </div>
                    <button
                        class="absolute bottom-0 p-1 transition transform -translate-x-1/2 translate-y-1/2 rounded-full text-myflowPurple left-1/2 ring-2 ring-white"
                        :class="data.cardButtonClass"
                        @click="toggleDescription"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
                        </svg>
                    </button>
                </div>
            </div>
            <div class="flex items-center justify-between px-4 pb-0 text-white bg-myflowPurple bg-gradient-to-r from-myflowPurple-600 to-myflowPurple-900">
                <div class="flex justify-center w-full">
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
import { reactive, computed } from 'vue';
import Playbutton from '../icons/playbutton.vue';

const props = defineProps({
    expert: {
        type: Object,
        required: true,
    },
});

const data = reactive({
    previewing: false,
    trailerVideoUrl: null,
    cardButtonClass: 'bg-white shadow-cardbutton'
});

const availableProductCountString = computed(() => {
    if( props.expert.available_offer_count === 0 ){
        return 'Inga erbjudanden just nu'
    } else if (props.expert.available_offer_count === 1) {
        return props.expert.available_offer_count + ' erbjudande'
    } else {
        return props.expert.available_offer_count + ' erbjudanden'
    }
});

const closeTrailer = (() => {
    data.trailerVideoUrl = null
});

const toggleDescription = (() => {
    data.previewing = !data.previewing;
});

const redirect = ((route) => {
    window.location.assign(route);
})

</script>
<style scoped>
    .shadow-cardbutton {
        box-shadow: 0px 0px 12px rgba(0,0,0,0.15);
    }
</style>
