<script setup>
    import { computed } from 'vue';

    const props = defineProps({
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        size: {
            type: String,
            default: 'md'
        },
        styleType: {
            type: String,
            default: 'default', // success, warning, danger etc
        },
        classes: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: 'button'
        },
        state: {
            type: String,
            required: false,
            default: '',
            options: ['', 'waiting', 'success', 'error']
        },
        badge: {
            type: String,
            required: false,
            default: '',
        },
        shimmer: {
            type: Boolean,
            required: false,
            default: false,
        }
    })

    const styles = {
        'default': 'mf-button-body-default mf-button-text-default mf-button-border-default',
        'primary': 'mf-button-body-primary mf-button-text-primary mf-button-border-primary',
        'secondary': 'mf-button-body-secondary mf-button-text-secondary mf-button-border-secondary',
        'success': 'mf-button-body-success mf-button-text-success mf-button-border-success',
        'warning': 'mf-button-body-warning mf-button-text-warning mf-button-border-warning',
        'danger': 'mf-button-body-danger mf-button-text-danger mf-button-border-danger',
        'disabled': 'mf-button-body-disabled mf-button-text-disabled mf-button-border-disabled',
        'transparent': 'mf-button-body-transparent mf-button-text-transparent mf-button-border-transparent',
    }

    const disabledOrType = computed(() => {
        if (props.disabled === true) {
            return styles['disabled']
        }
        return styles[props.styleType] ? styles[props.styleType] : ''
    })

    const indicatorClass = computed(() => {
        return props.state === 'success' ? 'bg-white text-myflowGreen' : 'bg-myflowRed'
    })

    const buttonClasses = computed(() => {
        const sizes = {
            'line': 'rounded-wl', // rounded-md on lemurcron launch
            'xs': 'px-1.5 py-px text-xxs rounded-wl border active:scale-90', // rounded-md on lemurcron launch
            'sm': 'px-2 py-1 text-xs rounded-wl border active:scale-90', // rounded-md on lemurcron launch
            'md': 'px-3 py-1.5 text-sm rounded-wl border-2 active:scale-95', // rounded-lg on lemurcron launch
            'lg': 'px-4 py-2 text-base rounded-wl border-2 active:scale-95', // rounded-xl on lemurcron launch
        }

        return [
            'inline-flex items-center focus:outline-none focus:ring-1 focus:ring-offset-2 active:ring-offset-4 focus:ring-gray-400 transition-all duration-100 relative',
            sizes[props.size],
            disabledOrType.value,
            props.classes
        ]
    })

</script>
<template>
    <button
        :type="type"
        :class="buttonClasses"
    >
        <slot name="icon"></slot>
        <span class="relative z-10">
            <slot></slot>
        </span>
        <span
            v-if="props.state === 'waiting'"
            class="absolute inset-0 block overflow-hidden pointer-events-none rounded-wl"
        >
            <span class="relative left-0 right-0 block w-full h-full bg-black/30 animation-fill"></span>
        </span>
        <span
            v-if="props.badge !== ''"
            class="absolute z-20 block overflow-hidden transform -translate-y-1/2 rounded-full pointer-events-none -right-3 top-0"
        >
            <span
                class="w-6 h-6 flex items-center justify-center bg-gray-400"
            >
                <span class="text-xxs text-white">{{ props.badge }}</span>
            </span>
        </span>
        <span
            v-if="props.state === 'success'"
            class="absolute z-20 block overflow-hidden transform -translate-y-1/2 rounded-full pointer-events-none -right-3 top-1/2 animation-completed"
            :class="indicatorClass"
        >
            <svg
                class="w-6 h-6 fill-current"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12ZM18.4158 9.70405C18.8055 9.31268 18.8041 8.67952 18.4127 8.28984L17.7041 7.58426C17.3127 7.19458 16.6796 7.19594 16.2899 7.58731L10.5183 13.3838L7.19723 10.1089C6.80398 9.72117 6.17083 9.7256 5.78305 10.1189L5.08092 10.8309C4.69314 11.2241 4.69758 11.8573 5.09083 12.2451L9.82912 16.9174C10.221 17.3039 10.8515 17.301 11.2399 16.911L18.4158 9.70405Z" />
            </svg>
        </span>
        <span
            v-if="props.state === 'error'"
            class="absolute z-20 block overflow-hidden transform -translate-y-1/2 bg-red-400 rounded-full pointer-events-none -right-3 top-1/2 animation-completed"
            :class="indicatorClass"
        >
            <svg class="w-6 h-6 fill-white" viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg">
                <path d="M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896zm0 832a384 384 0 0 0 0-768 384 384 0 0 0 0 768zm48-176a48 48 0 1 1-96 0 48 48 0 0 1 96 0zm-48-464a32 32 0 0 1 32 32v288a32 32 0 0 1-64 0V288a32 32 0 0 1 32-32z"/>
            </svg>
        </span>
        <span
            v-if="shimmer"
            class="absolute inset-0 block overflow-hidden pointer-events-none rounded-wl success-cta"
        ></span>
    </button>
</template>
<style scoped>
    .animation-fill {
        animation: fill 2s infinite;
    }
    @keyframes fill {
        0% {
            top: 100%;
            bottom: 0;
        }
        80% {
            top: 0;
            bottom: 0;
        }
        100% {
            top: -100%;
            bottom: 100%;
        }
    }

    .animation-completed  {
        transform-origin: top;
        animation: completed 3s forwards;
    }
    @keyframes completed {
        0% {
            transform: scale(0) translateY(-50%);
        }
        10% {
            transform: scale(0) translateY(-50%);
        }
        15% {
            transform: scale(1.2) translateY(-50%);
        }
        20% {
            transform: scale(0.9) translateY(-50%) rotateZ(0deg);
        }
        27% {
            transform: scale(1) translateY(-50%);
        }
        90% {
            opacity: 1;
            transform: scale(1) translateY(-50%);
        }
        100% {
            opacity: 0;
            transform: scale(0) translateY(-50%);
        }
    }

    .success-cta {
        background: linear-gradient(270deg, rgba(255,255,255,0), rgba(255,255,255,0), rgba(255, 253, 240, 0.2), rgba(255,255,255,0), rgba(255,255,255,0));
        animation: SuccessCta 7s linear infinite;
    }

    @keyframes SuccessCta {
        0%{
            background-position: 100% 50%;
            background-size: 1000% 1000%;
        }
        35%{
            background-position: 100% 50%;
            background-size: 1000% 1000%;
        }
        50%{
            background-size: 300% 300%;
        }
        65%{
            background-position: 0% 50%;
            background-size: 1000% 1000%;
        }
        100%{
            background-position: 0% 50%;
            background-size: 1000% 1000%;
        }
    }
</style>
