<template>
    <div class="flex">
        <a
            v-bind:href="url"
            target="_blank"
            class="space-x-1 text-center sm:text-left mf-link"
        >
            <i class="far fa-arrow-up-right-from-square"></i>
            <span class="block sm:inline">{{ openLabel }}</span>
        </a>
        <span class="relative">
            <button-transparent
                size="line  "
                class="space-x-1 text-center sm:text-left"
                @click="copyUrl"
                v-html="buttonLabel"
            >
            </button-transparent>
        </span>

        <general-modal>
            <template v-slot:header><div class="text-center">{{ _mft('linkField:qrCode.title') }}</div></template>
            <template v-slot:body>
                <div class="text-center">
                    <div class="break-words whitespace-normal">
                        <p>
                            <i>{{ _mft('linkField:qrCode.description') }}</i>
                        </p>
                    </div>
                    <div>

                    <vue-qr id="link-field-qr" ref="link-field-qr" :text="url" :size="380" :correctLevel="3" :callback="compileDownloadUrl"></vue-qr>
                    </div>
                    <div>
                        <a
                            download="myflow-qr.png"
                            :href="downloadUrl"
                        >
                            <button-base size="sm">
                                {{ _mft('linkField:qrCode.download') }}
                            </button-base>
                        </a>
                    </div>
                </div>
            </template>
            <template v-slot:open>
                <button-base style-type="transparent" size="line" class="block text-center cursor-pointer sm:text-left">
                    <template #icon>
                        <i class="fa-regular fa-qrcode mr-1"></i>
                    </template>
                    <span class="block sm:inline">{{ _mft('linkField:qrCode.show') }}</span>
                </button-base>
            </template>
        </general-modal>
    </div>
</template>

<script>
import { Clipboard } from "v-clipboard"

export default {
    props: {
        copyLabel: {
            type: String,
            required: false,
            default: _mft('linkField:copyLink'),
        },
        openLabel: {
            type: String,
            required: false,
            default: _mft('linkField:openInNewTab'),
        },
        qrLabel: {
            type: String,
            required: false,
            default: _mft('linkField:qrCode.show'),
        },
        url: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            buttonLabel: '<i class="fa-light fa-copy"></i> <span class="block sm:inline">' + this.copyLabel + '</span>',
            downloadUrl: '',
        };
    },
    methods: {
        async copyUrl() {
            let buttonLabel = this.buttonLabel;
            let res = await Clipboard.copy(this.url)
            if( res === true ){
                this.buttonLabel = buttonLabel + '<span class="absolute inset-0 bg-white rounded-sm"><i class="far fa-check"></i> <span class="block sm:inline">'+ _mft('linkField:copied') +'</span></span>';
                setTimeout(() => {
                    this.buttonLabel = buttonLabel;
                }, 2000);
            }
        },
        compileDownloadUrl() {
            this.downloadUrl = this.$refs['link-field-qr'].imgUrl;
        }
    },
};
</script>

<style scoped>
</style>
