<template>
    <div :class="hasImage ? '' : 'pt-8'">
        <div
            class="w-auto mx-auto"
            :class="[horizontal ? 'flex flex-col-reverse lg:grid grid-cols-2 items-center' : 'flex flex-col-reverse items-center mx-auto', containerWidthClass]"
        >
            <div
                class="z-10 flex flex-col p-4 -mt-6 md:p-6 quotecontainer rounded-custom shadow-custom"
                :class="[horizontal ? 'lg:mt-0 lg:-mr-8 justify-self-end lg:text-right w-11/12 lg:w-4/5' : '-mt-8 w-11/12 lg:w-4/5']"
            >
                <div class="flex justify-start w-full">

                </div>
                <p
                    class="px-4 font-bold"
                    :class="quoteFontSizeClass"
                >
                    <span class="relative">
                        <strong class="absolute right-full bottom-0 text-xl opacity-70 translate-y-0.5">"</strong>
                    </span>
                    <span class="whitespace-pre-wrap" v-html="quote"></span>
                    <span class="relative">
                        <strong class="text-xl opacity-70 translate-y-0.5 left-full">"</strong>
                    </span>
                </p>
                <p
                    class="px-4 font-thin text-right"
                    :class="quoteFontSizeClass"
                >- {{source}}</p>
            </div>
            <div class="flex justify-center">
                <media-bank-entries-display
                    v-if="mediaBankEntryData"
                    class="overflow-hidden rounded-custom shadow-custom"
                    alt=""
                    :data="mediaBankEntryData"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        quote: { type: String, required: true },
        source: { type: String, required: true },
        mediaBankEntryData:  { type: Object, default: null },
        horizontal: { type: Boolean, required: true },
    },
    computed: {
        hasImage(){
            return this.mediaBankEntryData !== null;
        },
        quoteFontSizeClass(){
            if( this.mediaBankEntryData ){
                return '';
            } else {
                return 'text-xl';
            }
        },
        containerWidthClass(){
            if( this.hasImage && this.horizontal){ return 'max-w-4xl' }
            if( this.hasImage ) { return 'max-w-2xl' }
            if( this.horizontal ) { return 'max-w-4xl' }
        }
    }
};
</script>
