<template>
    <div class="relative h-screen">
        <div>
            <div class="grid-cols-3 gap-8 p-4 md:p-8 md:grid">
                <div class="col-span-3 p-4 mb-8 rounded-lg shadow-2xl md:mb-0">
                    <div class="flex flex-col justify-between h-full">
                        <div>
                            <h1 class="mb-3 text-center"><i class="fa-regular fa-list"></i></h1>
                            <ul>
                                <li class="flex space-x-1">
                                    <i class="mt-1 fa-solid fa-check"></i>
                                    <span>Add to CRM</span>
                                </li>
                            </ul>
                        </div>
                        <button @click="selectProcess(1)" type="button" class="mt-3 myflow-basic-button">Select</button>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="state.completed"
            class="fixed inset-0 flex items-center justify-center bg-white/90"
        >
            <div class="w-full max-w-lg text-center">
                <h2>{{ state.message }}</h2>
                <div
                    class="h-2 bg-myflowPurple mt-4 transition-all duration-[3s] mx-auto ease-linear"
                    :class="state.countdownStarted ? 'w-0' : 'w-full'"
                ></div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { reactive, ref, onMounted } from 'vue'

const localProcesses = ref([]);

const state = reactive({
    selected: null,
    message: null,
    completed: false,
    countdownStarted: false,
});

const props = defineProps({
    processes: {
        type: Array,
        required: true
    },
    token: {
        type: String,
        required: true
    },
    url: {
        type: String,
        required: true
    },
})

const closeModal = () => {
    window.parent.postMessage('closeModal', '*');
}

const closeCurtains = () => {
    state.completed = true;
    setTimeout(function(){
        state.countdownStarted = true;
    }, 300)
    setTimeout(function(){
        closeModal();
    }, 3300);
}

const selectProcess = (process) => {
    state.selected = process;

    if (process === 1) {
        submitProcess();
    } else if (process === 2) {
        submitProcess();
    } else if (process === 3) {

    }
}

const submitProcess = async () => {
    const res = await axios.post(route('chrome-plugin.store'), {
        token: props.token,
        type: state.selected,
        url: props.url,
    });
    if (res.data.success) {
        state.message = res.data.message;
        closeCurtains();
    }
}

const submitProcessWithOutreach = async (id) => {
    const res = await axios.post(route('chrome-plugin.store'), {
        token: props.token,
        type: state.selected,
        url: props.url,
        salesprocess_id: id,
    });
    if (res.data.success) {
        state.message = res.data.message;
        closeCurtains();
    }
}

onMounted(() => {
    localProcesses.value = props.processes;
});

</script>
