<template>
    <div>
        <div class="-mx-2 bg-white md:-mx-6 shadow-custom rounded-custom overflow-hidden">
            <section class="px-6 py-4">
                <h1 class="pb-1 text-xl md:text-2xl lg:text-3xl">{{title}}</h1>
                <div class="text-sm" v-html="body"></div>
            </section>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        title: { type: String, required: true },
        body: { type: String, required: true },
    }
};
</script>

<style scoped>

</style>
