<script setup>

import {computed, onMounted, ref} from "vue";

const props = defineProps({
    courseId: {
        type: Number,
        required: true
    }
});

const course = ref(null);
const savingState = ref('')
const editingSettingsChapter = ref(null)
const addingChapterState = ref('')
const chapterBeingMoved = ref(null)
const creatingInvoiceLink = ref(false)

const loadCourse = async () => {
    const res = await axios.get(route('api.course.backoffice.edit', props.courseId));
    course.value = res.data.course
}

const updateCourse = async () => {
    savingState.value = 'waiting'
    const res = await axios.put(route('api.course.backoffice.update', props.courseId), course.value);
    course.value = res.data.course
    if(res.data.success) {
        savingState.value = 'success'
    } else {
        savingState.value = 'error'
    }
}

const updateChapterOrder = async () => {
    let orderedChapters = {}
    course.value.chapters.forEach((chapter, index) => {
        orderedChapters[chapter.id] = index + 1
    })

    const res = await axios.put(route('api.course.backoffice.chapter.order', props.courseId), {
        order: orderedChapters
    });
}

const moveChapterUp = (chapter) => {
    const index = course.value.chapters.indexOf(chapter)
    if (index === 0) return
    chapterBeingMoved.value = chapter
    setTimeout(function(){
        const newIndex = index - 1
        course.value.chapters.splice(newIndex, 0, course.value.chapters.splice(index, 1)[0])
        updateChapterOrder()

        setTimeout(function() {
            chapterBeingMoved.value = null
        }, 150)
    }, 150)
}

const moveChapterDown = (chapter) => {
    const index = course.value.chapters.indexOf(chapter)
    if (index === course.value.chapters.length - 1) return
    chapterBeingMoved.value = chapter
    setTimeout(function(){
        const newIndex = index + 1
        course.value.chapters.splice(newIndex, 0, course.value.chapters.splice(index, 1)[0])
        updateChapterOrder()

        setTimeout(function(){
            chapterBeingMoved.value = null
        }, 200)
    }, 200)
}

// const addChapter = async () => {
//     addingChapterState.value = 'waiting'
//
//     const res = await axios.post(route('api.course.backoffice.chapter.add', props.courseId), {
//         title: 'New chapter'
//     });
//
//     course.value.chapters.push(res.data.chapter)
//
//     addingChapterState.value = ''
//     editingSettingsChapter.value = course.value.chapters[course.value.chapters.length - 1]
// }

const updateFromEdit = (_chapter) => {
    editingSettingsChapter.value.title = _chapter.title
    editingSettingsChapter.value.published = _chapter.published
    editingSettingsChapter.value = null
}

const paymentLinkCreated = () => {
    creatingInvoiceLink.value = false
    loadCourse()
}

const courseValidates = computed(() => {
    return course.value.title.length > 0
})

const paymentLinkProducts = computed(() => {
    return [{
        title: course.value.title,
        price: 0,
        quantity: 1,
        description: course.value.description,
        linkableId: course.value.id,
        linkableType: 'course',
        quantity_is_fixed: false,
    }]
})

onMounted(() => {
    loadCourse();
})

</script>
<template>
    <div>
        <div v-if="course">
            <div class="md:sticky top-2 z-topbar">
                <frosted-bar>
                    <div class="lg:flex items-center justify-between lg:space-x-4">
                        <h1>{{ course.title }}</h1>

                        <div class="flex justify-end items-center space-x-4 mt-1 lg:mg-0">
                            <story-lane-modal :module="'courses'"></story-lane-modal>

                            <button-secondary
                                @click="creatingInvoiceLink = true"
                            >
                                {{ _mft('course:createInvoiceLink') }}
                            </button-secondary>

                            <button-base
                                :style-type="courseValidates ? 'success' : 'disabled'"
                                :disabled="! courseValidates"
                                @click="updateCourse()"
                                :state="savingState"
                            >
                                {{ _mft('shared:action.save') }}
                            </button-base>
                        </div>
                    </div>
                </frosted-bar>
            </div>

            <card-tabbed class="mt-24">
                <card-tabbed-tab value="general" :title="_mft('course:tab.general.title')" :active="true">
                    <input-text
                        v-model:content="course.title"
                        :label="_mft('course:input.title')"
                    ></input-text>

                    <input-provider
                        :label="_mft('course:input.provider')"
                        name="provider_id"
                        v-model="course.provider.id"
                    />

                    <input-editor
                        v-model:content="course.description"
                        :label="_mft('course:input.description')"
                    ></input-editor>
                </card-tabbed-tab>

                <card-tabbed-tab value="chapters" :title="_mft('course:tab.chapters.title')">
                    <toggle-switch
                        v-model:content="course.chapters_must_be_consumed_in_order"
                        :label="_mft('course:setting.forcedChapterOrder.label')"
                    />

                    <ul class="mt-8 divide-y -mx-4 md:-mx-6 lg:-mx-8">
                        <li
                            v-for="(chapter, i) in course.chapters"
                            :key="chapter.id"
                            class="flex justify-between space-x-4 py-2 px-4 md:px-6 lg:px-8 hover:bg-gray-50 transition-all duration-150"
                            :class="chapterBeingMoved === chapter ? 'opacity-0 scale-y-90' : ''"
                        >
                            <div>
                                <div>{{ chapter.title }}</div>
                                <div v-if="! chapter.published" class="text-xs text-gray-400 italic">
                                    {{ _mft('course:chapter.status.draft') }}
                                </div>
                            </div>
                            <div class="flex space-x-2">
                                <div class="flex space-x-1">
                                    <div class="flex flex-col space-y-1 items-end">
                                        <a :href="route('manageCourses.courses.chapters.pageBuilder', [course.id, chapter.id])">
                                            <button-primary
                                                size="sm"
                                            >
                                                {{ _mft('course:chapter.editContent') }}
                                            </button-primary>
                                        </a>
                                        <div class="flex space-x-1">
                                            <button-secondary
                                                size="sm"
                                                @click="editingSettingsChapter = chapter"
                                            >
                                                {{ _mft('course:chapter.settings') }}
                                            </button-secondary>
                                            <button-more-options
                                                size="sm"
                                                :cancel-on-outside-click="false"
                                                alignment="right"
                                            >
                                                <a
                                                    :href="route('course.backoffice.chapter.preview', [course.id, chapter.id])"
                                                    target="_blank"
                                                    class="mf-link text-xs"
                                                >
                                                    <button-secondary size="sm" class="whitespace-nowrap">
                                                        {{ _mft('shared:previewInNewTab') }}
                                                    </button-secondary>
                                                </a>
                                            </button-more-options>
                                        </div>
                                    </div>
                                </div>
                                <div class="flex flex-col">
                                    <button
                                        @click="moveChapterUp(chapter)"
                                    >
                                        <icon-chevron
                                            class="h-4 w-4 rotate-180 hover:opacity-80"
                                            :class="i > 0 ? 'fill-wlPrimary' : 'fill-gray-400 cursor-not-allowed'"
                                        />
                                    </button>
                                    <button
                                        @click="moveChapterDown(chapter)"
                                    >
                                        <icon-chevron
                                            class="h-4 w-4 hover:opacity-80"
                                            :class="i < course.chapters.length - 1 ? 'fill-wlPrimary' : 'fill-gray-400 cursor-not-allowed'"
                                        />
                                    </button>
                                </div>
                            </div>
                        </li>
                        <li class="flex justify-end py-2 px-4 md:px-6 lg:px-8 ">
                            <a :href="route('course.backoffice.chapter.add', course.id)">
                                <button-primary>
                                    {{ _mft('course:addChapter') }}
                                </button-primary>
                            </a>
                        </li>
                    </ul>

                </card-tabbed-tab>
                <card-tabbed-tab
                    v-if="course.invoice_links?.length > 0"
                    value="paymentLinks"
                    :title="_mft('course:tab.paymentMethods.title')"
                >
                    <ul class="divide-y">
                        <li
                            v-for="link in course.invoice_links"
                            :key="link.id"
                            class="flex justify-between py-1"
                        >
                            <span>
                                {{ link.title }}
                            </span>
                            <span>
                                <a
                                    :href="route('invoice-links.edit', link.uuid)"
                                    class="mf-link"
                                    target="_blank"
                                >
                                    {{ _mft('shared:action.edit') }}
                                </a>
                            </span>
                        </li>
                    </ul>
                </card-tabbed-tab>
            </card-tabbed>
        </div>
        <mf-spinner-medium v-else class="w-12 h-6" />

        <course-chapter-settings
            v-if="editingSettingsChapter"
            :chapter="editingSettingsChapter"
            @update:chapter="updateFromEdit"
            @delete:chapter="loadCourse"
            @close="editingSettingsChapter = null"
        />

        <slideout-with-slots
            v-if="creatingInvoiceLink"
            @close="creatingInvoiceLink = false"
            max-width-class="max-w-xl"
        >
            <template #title>
                <h2>{{ _mft('course:createInvoiceLink') }}</h2>
            </template>
            <template #body>
                <invoice-link-form
                    :preset-products="paymentLinkProducts"
                    :preset-title="course.title"
                    :redirect="false"
                    form-style="ui"
                    :show-title="false"
                    :hidden-features="['backlink', 'navigation']"
                    preselected-tab="settings"
                    class="container"
                    @payment-link-created="paymentLinkCreated"
                ></invoice-link-form>
            </template>
        </slideout-with-slots>

    </div>
</template>
