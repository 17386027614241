<template>
  <div>
    <form @submit.prevent="submit">
      <div class="mb-6">
        <input-text
          v-model:content="form.title"
          :error="errors.title"
          label="Titel (som coach, påverkar ej din generella titel)"
          placeholder="Titel"
          required="required"
          name="title"
        />

        <input-editor
            label="Beskrivning (som coach, påverkar ej din generella beskrivning)"
            :required="true"
            name="description"
            v-model:content="form.description"
        />

      </div>

      <input-button
        :label="submitLabel"
        :busy="isSubmitting"
        type="submit"
        class="float-right"
      />
    </form>
  </div>
</template>
<script>
export default {
  props: {
    prevTitle: String,
    prevDescription: String
  },
  data() {
    return {
      form: {
        title: this.prevTitle,
        description: this.prevDescription,
      },
      errors: {
        title: '',
        description: '',
      },
      isSubmitting: false,
      submitLabel: 'Uppdatera'
    };
  },
  methods: {
    async submit() {
      axios.post('/coaching/profile', {
          title: this.form.title,
          description: this.form.description
      });
    }
  },
  mounted: function(){
  }
};
</script>