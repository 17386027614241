<script setup>

import {onMounted, ref} from 'vue'
import InputEditor from "../../components/forms/inputEditor.vue";

const footers = ref({
    primaryFooter: null,
    secondaryFooter: null
})

const storeSettingsState = ref('')
const pages = ref([])

const loadFooterContent = async () => {
    const res = await axios.get(route('settings.footer.show'))

    footers.value.primaryFooter = res.data.primaryFooter;
    footers.value.secondaryFooter = res.data.secondaryFooter;
}

const storeFooter = async () => {
    const res = await axios.post(route('settings.footer.update'), {
        primaryFooter: footers.value.primaryFooter,
        secondaryFooter: footers.value.secondaryFooter
    })
    if(res.data.success !== true) {
        storeSettingsState.value = 'error'
    }
}


const storeSettings = async () => {
    storeSettingsState.value = 'waiting'
    storeFooter()
    setTimeout(function(){
        if (storeSettingsState.value === 'waiting') {
            storeSettingsState.value = 'success'
        }
    }, 800)
}

onMounted(() => {
    loadFooterContent()
})

</script>

<template>
    <div>
        <div class="grid gap-8 md:grid-cols-2">
            <div>
                <h2>{{ _mft('setting:footer.leftFooter.title') }}</h2>
                <input-editor
                    v-model:content="footers.primaryFooter"
                />
            </div>
            <div>
                <h2>{{ _mft('setting:footer.middleFooter.title') }}</h2>
                <input-editor
                    v-model:content="footers.secondaryFooter"
                />
            </div>
        </div>

        <p class="mt-4">{{ _mft('shared:preview') }}</p>
        <div class="grid gap-8 p-4 bg-white md:grid-cols-3 rounded-wl">
            <div v-html="footers.primaryFooter">

            </div>
            <div v-html="footers.secondaryFooter">

            </div>
            <div>
                <ul class="space-y-2">
                    <li>
                        {{ _mft('setting:footer.preview.firstLink') }}
                    </li>
                    <li>
                        {{ _mft('setting:footer.preview.secondLink') }}
                    </li>
                </ul>
            </div>
        </div>

        <div class="flex items-center justify-end space-x-4 my-4">
            <button-success
                @click="storeSettings"
                :state="storeSettingsState"
            >{{ _mft('setting:saveSettings') }}</button-success>
        </div>
    </div>
</template>

<style scoped>

</style>
