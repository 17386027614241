<script setup>
import { computed, onMounted, ref} from "vue";

const props = defineProps({
    admins: {
        type: Array,
        required: false,
        default: () => []
    }
})

const policies = ref([])
const accountCreationEnabled = ref(false)
const domain = ref(false)
const csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content')

const loadPolicies = async () => {
    const res = await axios.get(route('settings.policies.show'))

    accountCreationEnabled.value = res.data.accountCreationEnabled
    policies.value = res.data.policies
    domain.value = res.data.domain
}

const createPolicy = async (type) => {
    const res = await axios.post(route('settings.policies.create'), {
        type: type
    })
    if(res.data.success === true) {
        policies.value.push(res.data.policy)
    }
}

onMounted(() => {
    loadPolicies()
})

const integrityPolicyExists = computed(() => {
    return policies.value.filter(policy => policy.type === 'integrity-policy').length > 0
})

const termsOfUsePolicyExists = computed(() => {
    return policies.value.filter(policy => policy.type === 'terms-of-use').length > 0
})

const integrityPolicy = computed(() => {
    return policies.value.filter(policy => policy.type === 'integrity-policy')[0]
})

const termsOfUsePolicy = computed(() => {
    return policies.value.filter(policy => policy.type === 'terms-of-use')[0]
})

const currentUserId = computed(() => {
    return window.myflow.user.id
})

</script>

<template>
    <div class="mt-16 mb-4">
        <card-tabbed>
            <card-tabbed-tab
                :title="_mft('setting:policies.userAccounts.tabTitle')"
                value="user-accounts"
                :active="true"
            >
                <div class="grid md:grid-cols-2 gap-4 md:gap-8">
                    <div>
                        <h5 class="mb-4">
                            {{ _mft('setting:policies.toggleRegistration.title') }}
                        </h5>
                        <settings-account-creation-enabled
                            @update:content="accountCreationEnabled = $event"
                        />
                        <p
                            v-if="! accountCreationEnabled"
                            class="text-xs italic"
                        >
                            {{ _mft('setting:policies.toggleRegistration.requiredForFeatures') }}
                        </p>

                        <div v-if="integrityPolicyExists && termsOfUsePolicyExists && accountCreationEnabled">
                            <h5>
                                {{ _mft('setting:policies.linkToRegistration') }}
                            </h5>
                            <input
                                class="w-full max-w-full truncate bg-transparent focus:outline-none"
                                type="text"
                                :value="'https://'+domain+'/register'"
                                onfocus="event.target.select()"
                            >
                        </div>

                    </div>
                    <div class="space-y-8">
                        <div>
                            <h5>
                                {{ _mft('company:admin.currentAdmins')}}
                            </h5>
                            <ul class="divide-y">
                                <li
                                    v-for="(admin, index) in admins"
                                    :key="index"
                                    class="flex justify-between space-x-4 py-2"
                                >
                                    <div>
                                        <div>{{ admin.name }}</div>
                                        <div class="text-xs text-gray-400">{{ admin.email }}</div>
                                    </div>
                                    <a
                                        v-if="admin.id !== currentUserId"
                                        :href="route('settings.deleteAdmin', admin.id)"
                                    >
                                        <button-danger
                                            size="sm"
                                        >
                                            {{ _mft('shared:action.remove')}}
                                        </button-danger>
                                    </a>
                                </li>
                                <li
                                    v-if="admins.length === 0"
                                    class="py-2 text-gray-400"
                                >
                                    {{ _mft('company:admin.noAdmins') }}
                                </li>
                            </ul>
                        </div>

                        <div>
                            <h5 class="mb-4">
                                {{ _mft('company:admin.headline') }}
                            </h5>
                            <p>{{ _mft('company:admin.description') }}</p>

                            <form
                                :action="route('settings.company.addAdmin')"
                                method="POST"
                            >
                                <input type="hidden" name="_token" :value="csrf">

                                <div class="flex items-center space-x-4">
                                    <input-text
                                        type="email"
                                        name="email"
                                        placeholder="Email"
                                        class="w-full"
                                    />

                                    <button type="submit">
                                        <button-success
                                            class="justify-center w-full mb-4 mt-1"
                                        >
                                            {{ _mft('shared:action.save') }}
                                        </button-success>
                                    </button>
                                </div>
                            </form>

                            <p
                                v-if="! accountCreationEnabled"
                                class="italic text-xs"
                            >
                                {{ _mft('company:admin.accountCreationNotEnabled') }}
                            </p>
                        </div>
                    </div>
                </div>
            </card-tabbed-tab>

            <card-tabbed-tab
                value="integrity-policy"
                :title="_mft('auth:terms.integrityPolicy')"
            >
                <settings-policy
                    v-if="integrityPolicyExists"
                    :policy-type="integrityPolicy.type"
                />

                <div
                    v-else
                    class="flex justify-center py-8"
                >
                    <button-primary
                        @click="createPolicy('integrity-policy')"
                    >
                        {{ _mft('setting:policies.createPrivacyPolicy') }}
                    </button-primary>
                </div>
            </card-tabbed-tab>

            <card-tabbed-tab
                value="terms-of-use"
                :title="_mft('auth:terms.termsOfService')"
            >
                <settings-policy
                    v-if="termsOfUsePolicyExists"
                    :policy-type="termsOfUsePolicy.type"
                />

                <div
                    v-else
                    class="flex justify-center py-8"
                >
                    <button-primary
                        @click="createPolicy('terms-of-use')"
                    >
                        {{ _mft('setting:policies.createTermsOfUse') }}
                    </button-primary>
                </div>
            </card-tabbed-tab>
        </card-tabbed>
    </div>
</template>

<style scoped>

</style>
