<script setup>
import {ref, reactive, watch, onMounted} from 'vue'

    const props = defineProps({
        fillColors: {
            type: Array,
            default: () => ['#7814d7', '#9f9f9f']
        },
        labels: {
            type: Array,
            required: false,
            default: ['Label 1', 'Label 2', 'Label 3', 'Label 4']
        },
        lazyRender: {
            type: Boolean,
            default: false
        },
        series: {
            type: Array,
            required: false,
            default: [
                {
                    name: 'Series 1',
                    data: [86, 24, 34, 68]
                },
                {
                    name: 'Series 2',
                    data: [12, 19, 21, 16]
                },
            ]
        },
        showDataLabels: {
            type: Boolean,
            required: false,
            default: false
        },
        dataLabelPrefix: {
            type: String,
            required: false,
            default: ''
        },
        dataLabelSuffix: {
            type: String,
            required: false,
            default: ''
        },
        showLegend: {
            type: Boolean,
            required: false,
            default: false
        },
        showTooltip: {
            type: Boolean,
            required: false,
            default: false
        }
    })

    const cseries = ref([]);

    const chartOptions = reactive({
        chart: {
            animations: {
                enabled: true,
                easing: 'easeinout',
                speed: 600,
                animateGradually: {
                    enabled: true,
                    delay: 100
                },
                dynamicAnimation: {
                    enabled: true,
                    speed: 400
                }
            },
            toolbar: {
                show: false,
            },
        },
        legend: {
            show: props.showLegend,
            labels: {
                colors: props.fillColors,
            },
            markers: {
                width: 0,
                height: 0,
                fillColors: props.fillColors,
            },
        },
        tooltip: {
            enabled: props.showTooltip,
            y: {
                formatter: function (val) {
                    return props.dataLabelPrefix + val + props.dataLabelSuffix
                }
            }
        },
        xaxis: {
            categories: props.labels
        },
        dataLabels: {
            enabled: props.showDataLabels,
            formatter: function (val, opts) {
                return props.dataLabelPrefix + val + props.dataLabelSuffix
            },
        },
        fill: {
            show: true,
            width: 2,
            colors: props.fillColors
        },
    })

    const target = ref(null);
    const isVisible = ref(false);

    const observer = new IntersectionObserver(
        ([entry]) => {
            isVisible.value = entry.isIntersecting;
        },
        {
            threshold: 0.5
        }
    );

    watch(() => isVisible.value, (newVal) => {
        if (props.lazyRender) {
            cseries.value = props.series;
        }
    })

    onMounted(() => {
        if (props.lazyRender) {
            cseries.value = props.series.map(() => 0);
        } else {
            cseries.value = props.series;
        }
        observer.observe(target.value.$el);
    });

</script>

<template>
    <apexchart
        ref="target"
        type="bar"
        height="350"
        :options="chartOptions"
        :series="cseries"
    ></apexchart>
</template>

<style scoped>

</style>
