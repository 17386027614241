<template>
    <nav aria-label="Progress">
        <ol role="list" class="overflow-hidden">
            <li
                v-for="alternative in alternatives"
                v-bind:key="alternative.id"
                class="mb-5"
            >
                <div
                    v-on:click="pickAlternative(alternative.value)"
                    class="relative flex items-start cursor-default group"
                >
                    <span class="flex items-center h-9">
                        <span
                            :class="selected(alternative.value)"
                        >
                            <CheckIcon
                                class="w-5 h-5 text-white"
                                aria-hidden="true"
                                v-if="alternative.value === selectedAlternative"
                            />
                            <span
                                class="
                                    h-2.5
                                    w-2.5
                                    rounded-full
                                    bg-transparent
                                    group-hover:bg-gray-300
                                "
                                v-else
                            />
                        </span>
                    </span>
                    <span class="flex flex-col min-w-0 ml-4">
                        <span class="text-sm font-bold">
                            {{ alternative.answer }}
                        </span>
                        <span class="text-sm text-gray-500">
                            {{ alternative.description }}
                        </span>
                    </span>
                </div>
            </li>
        </ol>

        <input
            type="hidden"
            v-model="selectedAlternative"
            name="alternative_id"
        >

    </nav>
</template>

<script>

import { CheckIcon, MinusIcon } from '@heroicons/vue/solid';

export default {
    components: {
        CheckIcon, MinusIcon
    },
    props: {
        alternativeList: Object
    },

    data: function() {
        return {
            alternatives: this.alternativeList,
            selectedAlternative: null
        }
    },

    mounted() {
    },

    methods: {
        selected(choice) {
            if (this.selectedAlternative == choice) {
                return 'relative transition-all z-10 flex items-center justify-center w-8 h-8 rounded-full bg-myflowPurple-600 group-hover:bg-myflowPurple-800';
            } else {
                return 'relative transition-all z-10 flex items-center justify-center w-8 h-8 bg-white border-2 border-gray-300 rounded-full group-hover:border-gray-400';
            }
        },

        pickAlternative(alternative) {
            this.SelectedAlternative = null;
            this.selectedAlternative = alternative;
        }
    }
}
</script>
