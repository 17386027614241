<script setup>

import {computed, onMounted, ref, watch} from "vue";
import { useNotificationStore } from '@/stores/NotificationStore.js'
import FrostedBar from "../../components/layout/pageHeadings/frostedBar.vue";
const notificationStore = useNotificationStore()

const props = defineProps({
    showPreview: {
        type: Boolean,
        required: false,
        default: true
    },
});

const settings = ref(null)
const initialLanguage = ref(null)
const storeSettingsState = ref('');
const previewImageSrc = ref(null)
const previewFaviconSrc = ref(null)
const documentRoot = document.documentElement;
const logoFile = ref(null)
const previewingHeader = ref(false)
const faviconFile = ref(null)

const updateDesignCssVariables = () => {
    documentRoot.style.setProperty("--whitelabel-rounding", settings.value.borderRoundness);
    documentRoot.style.setProperty("--whitelabel-navigation-layout", settings.value.navigationLayout);
    documentRoot.style.setProperty("--whitelabel-glass-blur", settings.value.glassBlur);
    documentRoot.style.setProperty("--whitelabel-glass-opacity", settings.value.glassOpacity);
}

const updateColorCssVariables = () => {
    documentRoot.style.setProperty("--whitelabel-primary", settings.value.primaryColor);
    documentRoot.style.setProperty("--whitelabel-primary-light", settings.value.primaryColorLight);
    documentRoot.style.setProperty("--whitelabel-primary-contrast", settings.value.primaryForegroundColor);
    documentRoot.style.setProperty("--whitelabel-primary-contrast-light", settings.value.primaryForegroundColorLight);
}

const previewSettings = async () => {
    const lang = settings.value.language;
    const customCss = settings.value.customCss;
    const res = await axios.post(route('settings.appearance.preview'), settings.value);
    settings.value = res.data.settings;
    settings.value.language = lang
    updateColorCssVariables();
    settings.value.customCss = customCss;
}

const loadSettings = async () => {
    const res = await axios.get(route('settings.appearance.show'));
    settings.value = res.data.settings;
    initialLanguage.value = res.data.settings.language;
    previewImageSrc.value = settings.value.logoUrl

    updateColorCssVariables();
}

const storeSettings = async () => {
    storeSettingsState.value = 'waiting';
    if (logoFile.value) {
        const formData = new FormData();
        formData.append("logoFile", logoFile.value.file);

        const logoRes = await axios.post(route('settings.appearance.storeLogo'), formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });

        if (!logoRes.data.success) {
            storeSettingsState.value = '';
            notificationStore.addNotification({
                type: 'error',
                title: _mft('setting:appearance.uploadLogo.error'),
                dismissAfter: 0,
            })

            return;
        }
    }

    if (faviconFile.value) {
        const formData = new FormData();
        formData.append("faviconFile", faviconFile.value.file);

        const faviconRes = await axios.post(route('settings.appearance.storeFavicon'), formData, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        });

        if (!faviconRes.data.success) {
            storeSettingsState.value = '';
            notificationStore.addNotification({
                type: 'error',
                title: _mft('setting:appearance.uploadFavicon.error'),
                dismissAfter: 0,
            })

            return;
        }
    }

    await previewSettings();
    const res = await axios.post(route('settings.appearance.store'), settings.value);

    if(res.data.reload) {
        location.reload();
    }

    if (res.data.success) {
        storeSettingsState.value = 'success';
    }

}

const closePreview = () => {
    previewingHeader.value = false
}

const navigationSmallPreviewClasses = computed(() => {
    return {
        'fullwidth': 'w-full',
        'limited': 'w-3/5',
        'floating': 'transform translate-y-2 w-3/5'
    }[settings.value.navigationLayout]
})

const navigationBigPreviewClasses = computed(() => {
    return {
        'fullwidth': 'w-full',
        'limited': 'w-2/3 mx-auto rounded-b-wl',
        'floating': 'transform translate-y-2 w-2/3 mx-auto rounded-wl'
    }[settings.value.navigationLayout]
})


const updateFiles = (files) => {
    logoFile.value = files[0]
    previewImageSrc.value = 'data:image/png;base64, ' + files[0]?.getFileEncodeBase64String()
    previewingHeader.value = true
}

const updateFaviconFiles = (files) => {
    faviconFile.value = files[0]

    previewFaviconSrc.value = 'data:image/png;base64, ' + files[0]?.getFileEncodeBase64String()
}

const removedFile = () => {
    setTimeout(function(){
        previewImageSrc.value = settings.value.logoUrl
        previewingHeader.value = false
    }, 100)
}

watch(() => settings.value, (oldVal, newVal) => {
    updateDesignCssVariables();
}, {deep: true});

onMounted(() => {
    loadSettings();
});

</script>

<template>
    <div v-if="settings">
        <div class="flex flex-col-reverse gap-4 lg:grid lg:grid-cols-3 xl:gap-8">
            <div
                v-if="showPreview"
                class="col-span-2"
            >
                <div class="grid gap-4 xl:grid-cols-2 xl:gap-8">
                    <div>
                        <h2>{{ _mft('setting:appearance.exampleHeading') }}</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed hendrerit sapien eu consequat gravida. Curabitur eu velit vitae dui rutrum tempus. Nullam aliquam nibh risus, vitae laoreet libero mollis a. Nam convallis, nulla at placerat bibendum, lacus nisl ultrices augue, ut convallis turpis magna a metus. Nullam urna metus, vestibulum in velit quis, convallis maximus nisl. Donec a ultricies eros. Vivamus porttitor augue eu turpis iaculis porttitor.</p>
                        <div class="flex justify-end pb-4 space-x-2 md:pb-0">
                            <button-secondary>
                                {{ _mft('setting:appearance.exampleButton') }}
                            </button-secondary>
                            <button-primary>
                                {{ _mft('setting:appearance.exampleButton') }}
                            </button-primary>
                        </div>
                    </div>
                    <div>
                        <card-branded>
                            <template #top>
                                <div>
                                    <strong>{{ _mft('setting:appearance.exampleCard') }}</strong>
                                </div>
                            </template>
                            <template #header>
                                <h3>{{ _mft('setting:appearance.exampleHeading') }}</h3>
                            </template>
                            <template #body>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed hendrerit sapien eu consequat gravida. Curabitur eu velit vitae dui rutrum tempus. Nullam aliquam nibh risus, vitae laoreet libero mollis a. Nam convallis, nulla at placerat bibendum, lacus nisl ultrices augue, ut convallis turpis magna a metus. Nullam urna metus, vestibulum in velit quis, convallis maximus nisl. Donec a ultricies eros. Vivamus porttitor augue eu turpis iaculis porttitor.</p>
                                <div class="flex justify-end pb-4 space-x-2 md:pb-0">
                                    <button-secondary>
                                        {{ _mft('setting:appearance.exampleButton') }}
                                    </button-secondary>
                                    <button-primary>
                                        {{ _mft('setting:appearance.exampleButton') }}
                                    </button-primary>
                                </div>
                            </template>
                        </card-branded>
                    </div>
                </div>
            </div>
            <div>
                <card-basic>
                    <template #header>
                        <h3>{{ _mft('setting:domainNamePlural') }}</h3>
                    </template>
                    <template #body>
                        <div class="mb-4">

                            <button-bar
                                size="sm"
                                :options="[
                                    {name: 'en', label: 'English'},
                                    {name: 'sv', label: 'Svenska'},
                                ]"
                                :selected-option-name="settings.language"
                                @select="settings.language = $event.name"
                            />
                        </div>

                        <div class="mb-4">

                            <input-label
                                :label="'Logo'"
                            ></input-label>

                            <filepond-upload
                                ref="filepondUpload"
                                :showPreview="true"
                                accepted-file-types="image/png,image/jpeg,image/jpg,image/gif"
                                :required="false"
                                max-file-size="8MB"
                                :label-idle="_mft('setting:appearance.uploadLogo.labelIdle')"
                                @updatefiles="updateFiles($event)"
                                @removedfile="removedFile($event)"
                            ></filepond-upload>

                            <button-base
                                v-if="previewImageSrc"
                                class="justify-center w-full mt-4"
                                size="sm"
                                @click="previewingHeader = !previewingHeader"
                            >
                                <span v-if="previewingHeader">
                                    {{ _mft('setting:appearance.hidePreview') }}
                                </span>
                                <span v-else>
                                    {{ _mft('setting:appearance.previewLogo') }}
                                </span>
                            </button-base>

                        </div>

                        <div class="mb-4">

                            <input-label
                                :label="'Favicon'"
                            ></input-label>

                            <filepond-upload
                                ref="filepondFaviconUpload"
                                :showPreview="true"
                                accepted-file-types="image/png,image/jpeg,image/jpg,image/gif"
                                :required="false"
                                max-file-size="8MB"
                                :label-idle="_mft('setting:appearance.uploadFavicon.labelIdle')"
                                @updatefiles="updateFaviconFiles($event)"
                                @removedfile=""
                            ></filepond-upload>

                            <p
                                class="text-xs text-gray-500"
                            >
                                {{ _mft('setting:appearance.favicon.instructions') }}
                            </p>

                            <div
                                v-if="previewFaviconSrc?.length > 40 || previewFaviconSrc?.includes('https://')"
                                class="pt-2 border-b mb-4 pl-2"
                            >
                                <div class="w-full max-w-48 h-8 rounded-t-lg bg-gray-200 py-1 px-2 flex space-x-2 items-center">
                                    <div>
                                        <img
                                            class="object-cover object-center w-4 h-4 aspect-square"
                                            :src="previewFaviconSrc"
                                        />
                                    </div>
                                    <span class="text-xs">{{ _mft('setting:appearance.faviconPreviewTabLabel') }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="mb-4">
                            <div class="grid gap-4 md:grid-cols-2">
                                <div>
                                    <input-label :label="_mft('setting:appearance.backgroundColor')"></input-label>
                                    <color-picker
                                        :color="settings.primaryColor"
                                        :show-palette="true"
                                        @colorChanged="color => settings.primaryColor = color"
                                    ></color-picker>
                                </div>
                                <div>
                                    <input-label :label="_mft('setting:appearance.textColor')"></input-label>
                                    <color-picker
                                        :color="settings.primaryForegroundColor"
                                        :show-palette="true"
                                        @colorChanged="color => settings.primaryForegroundColor = color"
                                    ></color-picker>
                                </div>
                            </div>
                            <button-base
                                class="justify-center w-full mt-2"
                                size="sm"
                                @click="previewSettings"
                            >{{ _mft('shared:preview') }}</button-base>
                        </div>
                        <div class="mb-4">
                            <input-option
                                :label="_mft('setting:appearance.roundedCorners.label')"
                                direction="vertical"
                                :required="false"
                                :options="[
                                    { label: _mft('setting:appearance.roundedCorners.none'), value: '0px' },
                                    { label: _mft('setting:appearance.roundedCorners.tiny'), value: '0.33rem' },
                                    { label: _mft('setting:appearance.roundedCorners.aBit'), value: '0.75rem' },
                                    { label: _mft('setting:appearance.roundedCorners.quite'), value: '1.35rem' },
                                    { label: _mft('setting:appearance.roundedCorners.very'), value: '1.95rem' },
                                ]"
                                v-model:content="settings.borderRoundness"
                            />
                        </div>
                        <div class="mb-4 group">
                            <input-option
                                :label="_mft('setting:appearance.navigationStyle.title')"
                                :required="false"
                                direction="vertical"
                                :options="[
                                    { label: _mft('setting:appearance.navigationStyle.wide'), value: 'fullwidth' },
                                    { label: _mft('setting:appearance.navigationStyle.limited'), value: 'limited' },
                                    { label: _mft('setting:appearance.navigationStyle.floating'), value: 'floating' },
                                ]"
                                v-model:content="settings.navigationLayout"
                            />
                            <div
                                class="fixed top-0 left-0 right-0 hidden transition-transform transform -translate-y-full z-topbar group-focus-within:translate-y-0 group-hover:translate-y-0 lg:block"
                                :class="previewingHeader === true ? 'translate-y-0' : ''"
                                @mouseenter="closePreview"
                                @click="closePreview"
                            >
                                <div
                                    class="transition-transform transform -translate-y-full group-focus-within:translate-y-0 group-hover:translate-y-0"
                                    :class="previewingHeader === true ? 'translate-y-0' : ''"
                                >
                                    <div
                                        class="mx-auto transition-all delay-75 bg-white bg-opacity-glass backdrop-blur-glass"
                                        :class="navigationBigPreviewClasses"
                                    >
                                        <div class="flex items-center justify-between w-full h-20 px-4 lg:px-6">
                                            <div
                                                class="w-24 h-12 text-left"
                                                :class="previewImageSrc ? '' : 'bg-wlPrimary'"
                                            >
                                                <img
                                                    v-if="previewImageSrc?.length > 40 || previewImageSrc?.includes('https://')"
                                                    class="object-contain object-left w-24 h-12"
                                                    :src="previewImageSrc"
                                                />
                                            </div>
                                            <div class="flex items-center space-x-4">
                                                <div class="w-12 h-4 bg-black"></div>
                                                <div class="w-12 h-4 bg-black"></div>
                                                <div class="w-12 h-4 bg-black"></div>
                                                <div class="w-12 h-4 bg-black"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="relative flex justify-center w-full bg-gray-200 border shadow-md lg:hidden" style="padding-bottom: 50%;">
                                <div
                                    class="bg-white h-4 transition-all flex justify-between items-center px-0.5"
                                    :class="navigationSmallPreviewClasses"
                                >
                                    <div class="w-5 h-2 bg-wlPrimary"></div>
                                    <div class="flex items-center space-x-1">
                                        <div class="w-4 h-1 bg-black"></div>
                                        <div class="w-4 h-1 bg-black"></div>
                                        <div class="w-4 h-1 bg-black"></div>
                                        <div class="w-4 h-1 bg-black"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="mb-4">
                            <input-label :label="_mft('setting:appearance.customCss')"></input-label>

                            <css-editor
                                v-model="settings.customCss"
                            />
                        </div>
                        <div class="flex items-center justify-end">
                            <button-success
                                @click="storeSettings"
                                :state="storeSettingsState"
                            >{{ _mft('setting:saveSettings') }}</button-success>
                        </div>
                    </template>
                </card-basic>
            </div>
        </div>
    </div>
</template>

<style>
</style>
