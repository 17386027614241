<template>
    <div v-if="value">
        <input-text
            label="Namn"
            required
            v-model:content="value.name"
        />
        <input-editor
            label="Beskrivning"
            required
            v-model:content="value.description"
        />
        <div class="grid lg:grid-cols-2 gap-5 mb-3">
            <input-media-bank-entry
                type="image"
                label="Välj en bild från mediabiblioteket"
                :required="false"
                v-model:content="value.media_bank_entry_id"
            />
            <media-bank-entries-display v-if="value.media_bank_entry_id" :data-url="route('mediaBank.entries.show', value.media_bank_entry_id)" />
        </div>
        <div class="grid lg:grid-cols-2 mb-3">
            <input-text
                label="Pris"
                placeholder="T.ex. 1495 kr, 1995 kr/mån eller 995 kr per timme"
                required
                v-model:content="value.price"
            />
            <div></div>
            <toggle-switch
                label="Priset inkluderar moms"
                v-model:content="value.includes_vat"
            />
        </div>
        <input-text
            inputtype="url"
            label="Köp-länk"
            v-model:content="value.link_href"
        />
    </div>
    <mf-spinner-medium v-else class="w-16 h-8"></mf-spinner-medium>
</template>
<script>
export default {
    props: {
        modelValue: {},
    },
    emits: [
        'update:modelValue'
    ],
    data() {
        return {
            value: null
        };
    },
    async mounted() {
        this.captureModelValue();
    },
    watch: {
        modelValue() {
            if (JSON.stringify(this.value) === JSON.stringify(this.modelValue)) { return; }
            this.captureModelValue();
        },
        value: {
            handler() {
                this.$emit('update:modelValue', JSON.parse(JSON.stringify(this.value)));
            },
            deep: true
        }
    },
    methods: {
        captureModelValue() {
            const value = this.modelValue ? JSON.parse(JSON.stringify(this.modelValue)) : {};
            if (!value.name) { value.name = null; }
            if (!value.description) { value.description = null; }
            if (!value.media_bank_entry_id) { value.media_bank_entry_id = null; }
            if (!value.price) { value.price = null; }
            if (!value.link_href) { value.link_href = null; }
            if (!value.includes_vat) { value.includes_vat = null; }
            this.value = value;
        }
    },
}
</script>
