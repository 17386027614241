<template>
    <div class="w-full px-4 pt-4 pb-0 space-y-2">
        <h3
            class="px-1 text-center text-xs font-semibold text-gray-500 uppercase tracking-wider md:mb-3"
            v-if="heading != ''"
        >
            {{ heading }}
        </h3>
        <primary-navigation-item
            v-for="item in items"
            :key="item.name"
            :item="item"
        />
    </div>
</template>
<script>
export default {
    setup() {

    },
    props: {
        heading: {
            default: '',
            type: String
        },
        items: Object
    }
}
</script>
