<script setup>

import {computed, onMounted, reactive, ref} from "vue";
import SendSettings from './_sendSettings.vue'
import SendSchedule from './_sendSchedule.vue'
import EmailEditor from './_emailEditor.vue'
import FrostedBar from "../../components/layout/pageHeadings/frostedBar.vue";
import { DocumentIcon, PlayIcon, PauseIcon, CheckCircleIcon } from "@heroicons/vue/outline"
import { PlayIcon as SolidPlayIcon } from "@heroicons/vue/solid"
import { useNotificationStore } from '@/stores/NotificationStore.js'

const notificationStore = useNotificationStore()
const emit = defineEmits(['update:campaign'])

const props = defineProps({
    campaign: {
        type: Object,
        required: true,
    },
    workingState: {
        type: String,
        required: false,
        default: ''
    }
})

const _campaign = ref(props.campaign)
const campaignStatusWorkingState = ref('')
const addingEmail = ref(false)
const authWindowOpened = ref(false)
const showLeads = ref(false)
const emailRecentlyConnected = ref(false)
const poller = ref(null)
const collectedLeads = ref({})

const addEmail = () => {
    if (_campaign.value.emails.length >= 5 || addingEmail.value === true) {
        return
    }
    addingEmail.value = true

    _campaign.value.emails.push({
        id: _campaign.value.emails.length + 1,
        subject: '',
        content: '',
        delayDays: 3,
        pageEnabled: false,
        pageContent: '',
    })
}

const removeEmail = (i) => {
    if (i === 0) {return;}
    _campaign.value.emails.splice(i, 1)
}

const updateCampaign = () => {
    emit('update:campaign', _campaign.value)
}

const runCampaign = () => {
    setCampaignStatus('running')

    notificationStore.addNotification({
        type: 'success',
        title: _mft('leadHunterCampaign:notification.launched.title'),
        message: _mft('leadHunterCampaign:notification.launched.message'),
        dismissAfter: 5000,
    })
}

const setCampaignStatus = (status) => {
    campaignStatusWorkingState.value = 'waiting'
    axios.put(route('api.lead-hunter.campaign.update-status', [_campaign.value.id, status]))
        .then(res => {
            if (res.data.success) {
                _campaign.value.status = status
                window.scrollTo(0, 0, {behavior: 'smooth'})
            }
        })
    campaignStatusWorkingState.value = 'success'
}

const updateEmail = (e, i) => {
    _campaign.value.emails[i] = e
    updateCampaign()
}

const openAuthWindow = (provider) => {
    let authorizationUrl = '';

    switch (provider) {
        case 'google':
            authorizationUrl = route('google.auth.redirect')
            break

        case 'outlook':
            return;
    }

    authWindowOpened.value = true
    const authWindow = window.open(authorizationUrl, "", `toolbar=1,scrollbars=1,location=0,statusbar=0,menubar=1,resizable=1,width=500,height=800,top=0`);

    poller.value = setInterval(function() {
        if(authWindow.closed && authWindowOpened.value) {
            console.log('Successfully detected window closed')
        } else {
            console.log('oAuth window is still open (or blocked by browser)')
        }
        pollForConnectedEmail()
    }, 2000)
}

const showLeadsSlideout = async () => {
    showLeads.value = true

    const res = await axios.get(route('api.lead-hunter.campaign.leads', _campaign.value.id))

    collectedLeads.value = res.data
}

const pollForConnectedEmail = async () => {
    const res = await axios.get(route('api.lead-hunter.campaign.show', _campaign.value.id))

    if (res.data.campaign.has_connected_email === true) {
        clearInterval(poller.value)
        emailRecentlyConnected.value = true
        authWindowOpened.value = false

        notificationStore.addNotification({
            type: 'success',
            title: _mft('leadHunterCampaign:notification.emailConnected.title'),
            dismissAfter: 5000,
        })
    }
}

const contentValidates = computed(() => {
    let validates = true

    _campaign.value.emails.forEach(email => {
        if (!email.subject || !email.content) {
            validates = false
        }
        if (email.pageEnabled && email.pageContent === '') {
            validates = false
        }
    })

    return validates
})

</script>

<template>
    <div>
        <div
            v-if="! campaign.loaded"
            class="flex flex-col items-center justify-center h-screen"
        >
            <div class="text-center">
                <mf-spinner-medium class="w-12 h-6 mb-8"></mf-spinner-medium>
                {{ _mft('leadHunterCampaign:summary.loading') }}
            </div>
        </div>
        <div v-else>
            <div class="container w-full mx-auto md:sticky top-2 z-topbar">
                <frosted-bar>
                    <div class="items-center justify-between space-x-4 md:flex">
                        <div class="flex items-center space-x-2">
                            <div class="w-12">
                                <DocumentIcon
                                    v-if="campaign.status === 'draft'"
                                    class="w-8 h-8 text-gray-400"
                                />
                                <PlayIcon
                                    v-if="campaign.status === 'running'"
                                    class="w-12 h-12 text-myflowGreen-500"
                                />
                                <PauseIcon
                                    v-if="campaign.status === 'paused'"
                                    class="w-12 h-12 text-gray-500"
                                />
                                <CheckCircleIcon
                                    v-if="campaign.status === 'finished'"
                                    class="w-12 h-12 text-myflowGreen-500"
                                />
                            </div>
                            <h1 class="overflow-ellipsis line-clamp-1">{{ campaign.name }}</h1>
                        </div>
                        <div class="flex items-center justify-end space-x-2">
                            <slot name="toolbar">
                                <button-secondary
                                    v-if="campaign.status === 'paused' || campaign.status === 'running'"
                                    @click="showLeadsSlideout()"
                                >
                                    <span class="whitespace-nowrap">Leads</span>
                                </button-secondary>

                                <button-secondary
                                    v-if="campaign.status === 'running'"
                                    @click="setCampaignStatus('paused')"
                                    :state="campaignStatusWorkingState"
                                >
                                    <span class="whitespace-nowrap">{{ _mft('leadHunterCampaign:summary.running.pause') }}</span>
                                </button-secondary>

                                <button-secondary
                                    v-if="campaign.status === 'paused'"
                                    @click="setCampaignStatus('running')"
                                    :state="campaignStatusWorkingState"
                                >
                                    <span class="whitespace-nowrap">{{ _mft('leadHunterCampaign:summary.paused.resume') }}</span>
                                </button-secondary>
                            </slot>
                            <button-success
                                :disabled="! contentValidates"
                                @click="updateCampaign"
                                :state="workingState"
                            >{{ _mft('shared:action.save') }}</button-success>
                        </div>
                    </div>
                </frosted-bar>
            </div>

            <card-basic
                v-if="campaign.status !== 'finished'"
                class="mt-8 lg:mt-12"
            >
                <div class="mx-auto mb-4 md:grid md:grid-cols-2 md:gap-8">
                    <div>
                        <div v-if="campaign.status === 'draft'">
                            <h1>{{ _mft('leadHunterCampaign:summary.draft.title') }}</h1>
                            <p>{{ _mft('leadHunterCampaign:summary.draft.byline') }}</p>
                        </div>

                        <SendSettings
                            class="mb-4"
                            :sent-per-day="_campaign.emailsSentPerDay"
                            @update:sentPerDay="_campaign.emailsSentPerDay = $event"
                        />

                        <div v-if="campaign.status === 'running'">
                            <h1>{{ _mft('leadHunterCampaign:summary.running.title') }}</h1>
                            <p class="text-xs text-gray-500">
                                {{ _mft('leadHunterCampaign:summary.running.projectedEnd', {endAt: campaign.projectedEndAt}) }}
                            </p>
                        </div>
                        <div v-if="campaign.status === 'paused'">
                            <h1>{{ _mft('leadHunterCampaign:summary.paused.title') }}</h1>
                            <p class="text-xs text-gray-500">{{ _mft('leadHunterCampaign:summary.paused.byline') }}</p>
                        </div>
                        <div v-if="campaign.status === 'finished'">
                            <h1>{{ _mft('leadHunterCampaign:summary.finished.title') }}</h1>
                        </div>

                        <div class="mt-4 pt-2 border-t">
                            <h5 class="text-sm">{{ _mft('leadHunterCampaign:audience.locations.title') }}</h5>
                            <ul class="mb-2 flex flex-wrap">
                                <li
                                    v-for="location in campaign.lead_search.location"
                                    :key="location"
                                >
                                    <span class="inline-block text-xs rounded-md bg-gray-100 p-1 mb-1 mr-1">{{ location.value }}</span>
                                </li>
                                <li
                                    v-if="campaign.lead_search.location.length === 0"
                                    class="text-gray-400 text-xs"
                                >
                                    {{ _mft('leadHunterCampaign:audience.noneSelected') }}
                                </li>
                            </ul>

                            <h5 class="text-sm">{{ _mft('leadHunterCampaign:audience.jobTitles.title') }}</h5>
                            <ul class="mb-2 flex flex-wrap">
                                <li
                                    v-for="job_title in campaign.lead_search.job_title"
                                    :key="job_title"
                                >
                                    <span class="inline-block text-xs rounded-md bg-gray-100 p-1 mb-1 mr-1">{{ job_title.value }}</span>
                                </li>
                                <li
                                    v-if="campaign.lead_search.job_title.length === 0"
                                    class="text-gray-400 text-xs"
                                >
                                    {{ _mft('leadHunterCampaign:audience.noneSelected') }}
                                </li>
                            </ul>

                            <h5 class="text-sm">{{ _mft('leadHunterCampaign:audience.companySize.title') }}</h5>
                            <ul class="mb-2 flex flex-wrap">
                                <li
                                    v-for="company_size in campaign.lead_search.company_size"
                                    :key="company_size"
                                >
                                    <span class="inline-block text-xs rounded-md bg-gray-100 p-1 mb-1 mr-1">{{ company_size.value }}</span>
                                </li>
                                <li
                                    v-if="campaign.lead_search.company_size.length === 0"
                                    class="text-gray-400 text-xs"
                                >
                                    {{ _mft('leadHunterCampaign:audience.noneSelected') }}
                                </li>
                            </ul>

                            <h5 class="text-sm">{{ _mft('leadHunterCampaign:audience.companyIndustry.title') }}</h5>
                            <ul class="mb-2 flex flex-wrap">
                                <li
                                    v-for="company_industry in campaign.lead_search.company_industry"
                                    :key="company_industry"
                                >
                                    <span class="inline-block text-xs rounded-md bg-gray-100 p-1 mb-1 mr-1">{{ company_industry.value }}</span>
                                </li>
                                <li
                                    v-if="campaign.lead_search.company_industry.length === 0"
                                    class="text-gray-400 text-xs"
                                >
                                    {{ _mft('leadHunterCampaign:audience.noneSelected') }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div>
                        <SendSchedule
                            :send-schedule="_campaign.sendSchedule"
                            @update:send-schedule="_campaign.sendSchedule = $event"
                        />
                    </div>
                </div>
            </card-basic>

            <EmailEditor
                v-for="(email, i) in _campaign.emails"
                :key="email.id"
                :email="email"
                :sequence-number="i"
                :campaign-id="_campaign.id"
                :previous-content="_campaign.emails[i]?.content"
                :previous-subject="_campaign.emails[i]?.subject"
                @update:email="updateEmail($event, i)"
                @remove="removeEmail(i)"
                @emailGenerated="addingEmail = false"
            />

            <div class="flex flex-col items-center justify-center">
                <button-primary
                    @click="addEmail"
                    :disabled="_campaign.emails.length >= 5 || addingEmail"
                >{{ _mft('leadHunterCampaign:addEmail') }}</button-primary>
                <p
                    v-if="_campaign.emails.length < 5"
                    class="mt-2 text-xs text-center text-gray-400"
                    v-html="_mft('leadHunterCampaign:addEmailDisclaimer')"
                ></p>
                <p
                    v-if="_campaign.emails.length >= 5"
                    class="mt-2 text-xs text-center text-gray-400"
                    v-html="_mft('leadHunterCampaign:maximumEmailsAdded')"
                ></p>
            </div>

            <!-- <div
                v-if="!campaign.hasConnectedEmail && ! emailRecentlyConnected"
                class="mt-16"
            >
                <div class="grid gap-2 lg:grid-cols-2 lg:gap-4">
                    <div class="text-center lg:text-right">
                        <h1>{{ _mft('leadHunterCampaign:summary.lastStep.title') }}</h1>
                        <p>
                            {{ _mft('leadHunterCampaign:summary.lastStep.bylineOne') }} <span class="lg:block">{{ _mft('leadHunterCampaign:summary.lastStep.bylineTwo') }}</span>
                        </p>
                        <p class="mb-0 text-xs text-gray-500">
                            <em>{{ _mft('leadHunterCampaign:summary.lastStep.reloadNeeded') }}</em>
                        </p>
                        <button-secondary
                            v-if="authWindowOpened"
                            class="mt-4"
                            @click="pollForConnectedEmail"
                        >{{ _mft('leadHunterCampaign:summary.lastStep.verifyConnection') }}</button-secondary>
                    </div>
                    <div class="flex justify-center lg:-mt-3 lg:items-center lg:justify-start">
                        <div class="flex flex-col mt-4 space-y-4 lg:mt-4">
                            <button-base
                                style-type="secondary"
                                @click="openAuthWindow('google')"
                            >
                                <template #icon>
                                    <img width="20" style="margin-bottom:3px; margin-right:5px" alt="Google sign-in" src="https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Gmail_icon_%282020%29.svg/1024px-Gmail_icon_%282020%29.svg.png" />
                                </template>
                                <span class="text-lg">{{ _mft('leadHunterCampaign:summary.lastStep.connectWithGoogle') }}</span>
                            </button-base>

                            <button-base
                                style-type="secondary"
                                @click="openAuthWindow('outlook')"
                            >
                                <template #icon>
                                    <img width="20" style="margin-bottom:3px; margin-right:5px" alt="Google sign-in" src="https://upload.wikimedia.org/wikipedia/commons/f/f7/Microsoft_Outlook_2013-2019_logo.svg" />
                                </template>
                                <span class="text-lg">{{ _mft('leadHunterCampaign:summary.lastStep.connectWithOutlook') }}</span>
                            </button-base>

                            <a href="#" class="mt-4">
                                <button-base
                                    style-type="secondary"
                                >
                                    <template #icon>
                                        <i style="margin-bottom:3px; margin-right:5px" class="fa-light fa-mailbox"></i>
                                    </template>
                                    <span class="text-lg">Use SMTP integration</span>
                                </button-base>
                            </a>
                        </div>
                    </div>
                </div>
            </div> -->

            <div
                v-if="campaign.status === 'draft' && (campaign.hasConnectedEmail)"
                class="mt-16"
            >
                <div class="grid gap-2 lg:grid-cols-2 lg:gap-4">
                    <div class="text-center lg:text-right">
                        <h1>{{ _mft('leadHunterCampaign:summary.launch.title') }}</h1>
                        <p>{{ _mft('leadHunterCampaign:summary.launch.allSetText') }}</p>
                        <p class="mb-0 text-xs text-gray-500">
                            {{ _mft('leadHunterCampaign:summary.launch.clickTheButton', {leadCount: campaign.leadCount, runtimeDaysEstimate: campaign.runtimeDaysEstimate}) }}
                        </p>
                    </div>
                    <div class="flex justify-center lg:-mt-3 lg:items-center lg:justify-start">
                        <button-base
                            @click="runCampaign"
                            style-type="secondary"
                        >
                            <template #icon>
                                <SolidPlayIcon class="w-12 h-12 -ml-2 text-wlPrimary" />
                            </template>
                            <span class="text-lg">{{ _mft('leadHunterCampaign:summary.launch.buttonLabel') }}</span>
                        </button-base>
                    </div>
                </div>
            </div>
            <div v-if="campaign.status === 'draft' && (!campaign.hasConnectedEmail)" class="mt-6">
                <div class="flex justify-center">
                    <div>
                        <h1>{{ _mft('leadHunterCampaign:summary.lastStep.title') }}</h1>
                        <p>
                            {{ _mft('leadHunterCampaign:summary.lastStep.bylineOne') }} <span class="lg:block">{{ _mft('leadHunterCampaign:summary.lastStep.bylineTwo') }}</span>
                        </p>
                    </div>
                    <a :href="route('crm.communications.settings.show')">
                        <button-secondary
                        >{{ _mft('leadHunterCampaign:summary.connectSmtp') }}</button-secondary>
                    </a>
                </div>
            </div>
        </div>

        <slideout-with-slots
            v-if="showLeads"
            @close="showLeads = false"
        >
            <template #title>
                <h2>Leads</h2>
            </template>
            <template #body>
                <div v-for="(lead, key)  in collectedLeads" :key="key">
                    <h5 class="mt-3 mb-3">{{ key }}</h5>
                    <div v-for="l in lead" :key="l.id">
                        <div class="grid grid-cols-2">
                            <div>
                                <b>{{ l.name }}</b><br>
                                {{ l.email }}
                            </div>
                            <div>
                                <small><b>{{ l.job_title }}</b><br></small>
                                <small>{{ l.company }}</small>
                            </div>
                        </div>
                        <hr class="mt-5 mb-5">
                    </div>
                </div>
            </template>
        </slideout-with-slots>

    </div>
</template>

<style scoped>

</style>
