<template>
    <div>
        <div
            v-if="!changingFont"
            class="flex space-x-2 justify-between items-center py-1"
        >
            <span class="text-lg" :class="font !== '' ? 'font-selector-preview' : ''">{{ selectedFont }}</span>
            <button-secondary
                v-on:click.prevent="changingFont = true"
            ><mf-translate domainkey="page:fontOptions.changeFont"></mf-translate></button-secondary>
        </div>
        <div v-if="changingFont" class="flex justify-between space-x-2 items-center pt-0.5">
            <font-select
                class="w-full"
                ref="fontinput"
                @fontSelected="fontSelected"
            ></font-select>
            <button-secondary
                v-on:click.prevent="changingFont = false"
                class="mt-1"
            ><mf-translate domainkey="shared:action.cancel"></mf-translate></button-secondary>
        </div>
        <input type="hidden" name="font" v-model="selectedFont">
    </div>
</template>
<script>

export default {
    props: {
        font: String
    },

    data() {
        return {
            changingFont: false,
            selectedFont: ''
        }
    },

    mounted() {
        this.selectedFont = this.font;
        this.$emit('update:modelValue', '');
    },

    methods: {
        fontSelected(font){
            this.selectedFont = font.family;
            this.changingFont = false;

            this.$emit('update:modelValue', font.family);
        }
    },

    watch: {
        changingFont(val, oldVal){
            if( this.changingFont === true ){
                setTimeout(function(){
                    document.querySelector('#google-font-input input').focus();
                }, 1)
            }
        }
    }
}

</script>

<style scoped>
    .fontname {
        font-family: v-bind('selectedFont') !important;
    }
</style>
