<template>
  <notification
    :show="showNotification"
    @close="closeNotification()"
  >
    <template
      v-slot:header
    >
      {{ notification.message }}
    </template>
  </notification>
  <card-form-content>
    <template v-slot:header><h2>{{ _mft('crm:leadActionCard.domainNamePlural') }}</h2></template>
    <template v-slot:form>
      <form @submit.prevent="submit" ref="form">
        <input-text
          :label="_mft('crm:leadActionCard.title')"
          required="required"
          :content="creatingAction.action"
          name="action"
          v-model:content="creatingAction.action"
        />

        <input-date
            :label="_mft('crm:leadActionCard.date')"
          required="required"
          :content="creatingAction.date"
          name="date"
          v-model:content="creatingAction.date"
        />

        <div>
            <toggle-switch
                label="crm:leadActionCard.reminderAtTime"
                :content="timeEnabled"
                v-model:content="timeEnabled"
            />
        </div>
        <input-time
            v-if="timeEnabled"
            :content="creatingAction.time"
            name="time"
            v-model:content="creatingAction.time"
        />

        <input-select
          v-model:content="creatingAction.prio"
          :options="priorityOptions"
          :label="_mft('crm:leadActionCard.priority')"
          required
          :placeholder="_mft('crm:leadActionCard.priority')"
          name="prio"
        />

        <div class="flex justify-end mt-2">
          <button-success
            type="success"
            :busy="working"
            :disabled="submitIsDisabled"
            :class="submitIsDisabled ? 'cursor-not-allowed' : ''"
            v-on:click="createNote"
          >
              {{ _mft('shared:action.save') }}
          </button-success>
        </div>
      </form>
    </template>
    <template v-slot:content>
      <p class=""
        v-if="localActions.length === 0"
      >
        {{ _mft('crm:leadActionCard.noRemindersAdded') }}
      </p>
      <div
        v-else
      >
        <ul
          role="list"
          class=""
        >
          <li
            class="col-span-1 mt-1"
            v-for="(action, i) in localActions"
            v-bind:key="i"
          >
            <crm-lead-action-item
              :item="action"
              :owner="action.user_info_rel"
              @reloadItems="reloadItems"
            />
          </li>
        </ul>
      </div>
    </template>

  </card-form-content>
</template>
<script>
export default {
  props: {
    alignment: String,
    lead: Object,
    actions: Object
  },
  data: function(){
    return {
        timeEnabled: false,
      creatingAction: {
        action: '',
        date: '',
        time: '00:00',
        prio: ''
      },
      currentlyEditingAction: {
        id: '',
        action: '',
        date: '',
        prio: ''
      },
      localActions: {},
      notification: {
        type: '',
        message: ''
      },
      open: false,
      priorityOptions: [
        {value: 1, label: '1 ('+ _mft('shared:priority.high') +')'},
        {value: 2, label: '2'},
        {value: 3, label: '3'},
        {value: 4, label: '4'},
        {value: 5, label: '5 ('+ _mft('shared:priority.low') +')'}
      ],
      working: false
    }
  },
  methods: {
    openInSlideout(note){
      this.currentlyEditingAction = note;
      this.open = true;
    },
    async createNote(){
      const res = await axios.post( route('crm.leads.action.create', this.lead.id) , this.creatingAction);

      this.notification = res.data.notification;
      this.reloadItems();
      let self = this
      setTimeout(function(){
        self.localActions = res.data.actions;
        self.resetCreateActionForm();
      }, 1);

    },
    async updateNote(){
      const res = await axios.put( route('crm.leads.note.update', this.currentlyEditingAction.id) , this.currentlyEditingAction);

      this.notification = res.data.notification;
      this.closeSlideout();
    },
    async reloadItems(){
      const res = await axios.get(route('crm.leads.actions', this.lead.id))
      this.localActions = {}
      let self = this
      setTimeout(function(){
        self.localActions = res.data.actions;
        self.resetCreateActionForm();
        self.working = false;
      }, 1);

    },
    resetCreateActionForm(){
      this.creatingAction = {
        action: '',
        date: '',
        prio: '1'
      };
    },
    closeSlideout(){
      this.open = false;
    },
    closeNotification(){
      this.notification.message = ''
    }
  },
  mounted: function(){
    this.localActions = this.actions;
  },
  computed: {
    hasHeaderSlot () {
      return !!this.$slots['header-left'] && !!this.$slots['header-right'];
    },
    hasImageSlot () {
      return !!this.$slots.image
    },
    hasBodySlot () {
      return !!this.$slots.body
    },
    alignmentClass (){
      return this.alignment === 'center' ? 'text-center' : ''
    },
    showNotification (){
      return this.notification.message.length > 0
    },
    submitIsDisabled (){
        return this.creatingAction.action.length === 0 || this.creatingAction.date.length === 0 || this.creatingAction.prio === ''
    }
  },
  setup() {

  },
}
</script>
