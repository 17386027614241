<script setup>
    import { computed, onMounted, ref } from 'vue'

    const props = defineProps({
        statusOptions: {
            type: Array,
            required: true
        },
        status: {
            type: String,
            required: false,
            default: 'open'
        }
    })

    const loading = ref(true);
    const tickets = ref([]);
    const draftCount = ref(0);
    const publishedCount = ref(0);
    const currentStatus = ref(props.status)

    const loadTickets = () => {
        tickets.value = [];
        axios.get(route('support.admin.ticket.index', currentStatus.value))
            .then(response => {
                tickets.value = response.data.tickets;
                loading.value = false
            })
            .catch(error => {
                console.log(error);
            });
    }

    const setStatus = (status) => {
        currentStatus.value = status.name;
        history.pushState({}, "", route('support.admin.ticket.index', currentStatus.value));
        loadTickets();
    }

    const statusOptionsWithCounts = computed(() => {
        return props.statusOptions.map(status => {
            return {
                ...status,
                name: status.name,
                label: status.ticketcount ? status.plural_label + ' (' + status.ticketcount + ')' : status.plural_label
            }
        })
    })

    onMounted(() => {
        loadTickets();
    });

</script>
<template>
    <div class="w-full">
        <div class="flex items-center justify-between mb-4">
            <h1>Supportärenden</h1>
        </div>
        <card-basic
            class="flex flex-col justify-between"
        >
            <template v-slot:body>
                <div class="flex justify-center">
                    <button-bar
                        :options="statusOptionsWithCounts"
                        :selected-option-name="props.status"
                        @select="setStatus"
                    />
                </div>
                <div v-if="loading" class="text-center">
                    <mf-spinner-medium
                        class="w-24 h-12"
                    />
                </div>
                <ul
                    v-else
                    class="divide-y"
                >
                    <li
                        v-for="ticket in tickets"
                        :key="ticket.id"
                    >
                        <a
                            :href="route('support.admin.ticket.show', ticket.id)"
                            class="block py-2 group"
                        >
                            <div>
                                <h5 class="group-hover:text-gray-400">{{ ticket.user.name }}</h5>
                                <div>{{ ticket.company.name }}</div>
                                <div class="text-gray-400 text-xs">{{ ticket.created_at.display.full }}</div>
                            </div>
                        </a>
                    </li>
                </ul>
            </template>
        </card-basic>
    </div>
</template>
