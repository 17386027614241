<script setup>
import { computed } from 'vue'

const props = defineProps({
    uuid: {
        type: String,
        require: true
    },
    content: {
        type: Object,
        required: true
    },
});

const fieldUuid = computed(() => props.content.uuid);
const title = computed(() => props.content.title);
const required = computed(() => props.content.required);
</script>

<template>
    <div>
        <input
            type="hidden"
            :name="`data[${uuid}][block_uuid]`"
            :value="fieldUuid"
        />
        <input
            type="hidden"
            :name="`data[${uuid}][type]`"
            value="file"
        />

        <input-label
            :label="title"
            :required="required"
        />

        <filepond-upload
            class="mt-1"
            :name="`data[${uuid}][value]`"
            :accepted-file-types="null"
            :required="required"
            :store-as-file="true"
            :allow-file-encode="false"
            :show-preview="true"
            :label-idle="_mft('pagebuilderView:formsInputUpload.labelIdle')"
        ></filepond-upload>
    </div>
</template>
