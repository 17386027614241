<template>
    <form v-if="course" @submit.prevent="save">

        <danger-banner v-if="(providerCount == 0) || (assetCount == 0)">
            <template v-slot:header>
                Innan du skapar din utbildning
            </template>
            <template v-slot:body>
                <ul>
                    <li
                        class="mb-3"
                        v-if="(providerCount == 0)"
                    >
                        Det verkar inte som att du har skapat någon expertprofil, klicka <a :href="route('manage-expert-profile.edit')" class="text-myflowRed-800"><u><b>här</b></u></a> för att skapa en.
                    </li>
                    <li
                        class="mb-3"
                        v-if="(assetCount == 0)"
                    >
                        Det verkar inte som att du har laddat upp några videos, klicka <a :href="route('mediaBank.entries.index')" target="_blank" class="text-myflowRed-800"><u><b>här</b></u></a> för att ladda upp.
                    </li>
                </ul>
            </template>
        </danger-banner>

        <card-basic>
            <template v-slot:header>
                <div class="flex items-center">
                    <h5>{{ _mft('course:infoAbout') }}</h5>
                </div>
            </template>
            <template v-slot:preview>{{ _mft('course:infoPreview') }}</template>
            <template v-slot:body>
                <input-text maxlength="255" :label="_mft('course:input.title')" v-model:content="course.title" required />

                <input-editor :label="_mft('course:input.description')" v-model:content="course.description" required />

                <input-provider
                    v-if="course.provider.id"
                    :label="_mft('course:input.provider')"
                    v-model="course.provider.id"
                    required
                />

                <input-select :label="_mft('course:input.purchaseMethod')" v-model:content="course.purchase_method" :options="availablePurchaseMethodOptions" required />

                <input-number v-if="course.purchase_method == 'cash_sek'" :label="_mft('course:input.priceInclVat')" v-model:content="course.purchase_price" required />

                <div class="mt-4 2xl:grid 2xl:grid-cols-3 2xl:gap-4 2xl:items-center 2xl:mt-8">
                    <div class="mb-4 text-center 2xl:mb-0">
                        <FeaturedImage
                            class="mb-0"
                            previewImageClass="rounded-full"
                            :image="course.featured_image"
                            @file-updated="featuredImageUpdated"
                            @image-remove="featuredImageRemoved"
                        />
                    </div>

                    <div class="col-span-2 text-center">
                        <div v-if="course.trailer_video" class="flex items-start justify-between">
                            <div class="flex-grow mb-3 mr-3">
                                <h5>{{ _mft('course:trailer') }}</h5>
                                <input-video label="Trailer" v-model="course.trailer_video" required />
                                <hr>
                            </div>
                            <button type="button" class="mt-6 myflow-basic-button--danger" @click.prevent="course.trailer_video = undefined">
                                {{ _mft('course:trailer.remove') }}
                            </button>
                        </div>
                        <button v-else type="button" class="-mt-4 text-xs myflow-basic-button" @click.prevent="course.trailer_video = {}">
                            {{ _mft('course:trailer.add') }}
                        </button>
                    </div>
                </div>

            </template>
        </card-basic>

        <card-basic>
            <template v-slot:header>
                <div class="flex items-center">
                    <h5>{{ _mft('course:category.domainNamePlural') }}</h5>
                </div>
            </template>
            <template v-slot:preview>{{ _mft('course:category.whichAreCovered') }}</template>
            <template v-slot:body>
                <div
                    v-for="(category, categoryIdx) in course.categories"
                    v-bind:key="categoryIdx"
                    class="flex items-start justify-between">
                    <div class="flex-grow mb-3 mr-3">
                        <input-category :label="''" v-model="category.id" required />
                        <hr>
                    </div>
                    <button v-if="categoryIdx > 0" type="button" class="mt-6 myflow-basic-button--danger" @click.prevent="removeCategory(categoryIdx)">
                        {{ _mft('shared:action.delete') }}
                    </button>
                </div>
                <button type="button" class="myflow-basic-button" @click.prevent="addCategory">{{ _mft('course:category.add') }}</button>

            </template>
        </card-basic>

        <div v-if="course.id">
            <card-basic>
                <template v-slot:body>
                    <h5 class="my-2">{{ _mft('course:chapter.domainNameSingular') }}</h5>

                    <hr class="mt-5 mb-5">

                    <div
                        v-for="(chapter, chapterIdx) in course.chapters" :key="chapterIdx"
                        class="relative mt-3 mb-3 overflow-visible"
                    >
                        <div class="flex justify-between my-2">
                            <h4>{{ _mft('course:chapter.domainNameSingular') }} {{chapterIdx + 1}}: {{ chapter.title }}</h4>

                            <div class="flex justify-end">
                                <button-secondary
                                    size="sm"
                                    :disabled="chapterIdx === 0"
                                    type="button"
                                    class="mr-1"
                                    @click.prevent="moveChapterUp(chapterIdx)"
                                >&uarr;</button-secondary>
                                <button-secondary
                                    size="sm"
                                    :disabled="(chapterIdx + 1) === course.chapters.length"
                                    type="button"
                                    class="mr-4"
                                    @click.prevent="moveChapterDown(chapterIdx)"
                                >&darr;</button-secondary>
                                <button-danger size="sm" type="button" v-if="course.chapters.length > 1" @click.prevent="removeChapter(chapterIdx)">
                                    {{ _mft('course:chapter.delete') }}
                                </button-danger>
                            </div>

                        </div>

                        <div class="flex justify-end space-x-2">
                            <a
                                :href="route('manageCourses.courses.chapters.show', [course.id, chapter.id])"
                            >
                                <button-primary
                                    size="sm"
                                >
                                    {{ _mft('course:chapter.settings') }}
                                </button-primary>
                            </a>
                            <a
                                :href="route('manageCourses.courses.chapters.pageBuilder', [course.id, chapter.id])"
                            >
                                <button-primary
                                    size="sm"
                                >
                                    {{ _mft('course:chapter.editContent') }}
                                </button-primary>
                            </a>
                        </div>
                        <hr class="mt-5 mb-5">
                    </div>

                    <div
                        class="flex justify-end my-2"
                    >
                        <a
                            :href="route('manageCourses.courses.chapters.create', [course.id])"
                            class="myflow-basic-button"
                        >{{ _mft('course:chapter.addNew') }}</a>
                    </div>
                </template>
            </card-basic>

        </div>

        <card-basic>
            <template v-slot:header>
                <div class="flex items-center">
                    <h5>{{ _mft('course:settings.title') }}</h5>
                </div>
            </template>
            <template v-slot:preview>{{ _mft('course:setting.availabilityPublishStatus') }}</template>
            <template v-slot:body>
                <div class="grid grid-cols-1 gap-6 mb-3 xl:grid-cols-1">
                    <div>
                        <toggle-switch label="course:setting.published.label" v-model:content="course.published" />
                        <hr class="mt-1">
                    </div>
                    <div>
                        <toggle-switch label="course:setting.consumable.label" v-model:content="course.consumable" />
                        <p class="text-xs italic">{{ _mft('course:setting.consumable.description') }}</p>
                        <hr class="mt-1">
                    </div>
                    <div>
                        <toggle-switch label="course:setting.purchasable.label" v-model:content="course.purchasable" />
                        <p class="text-xs italic" v-html="_mft('course:setting.purchasable.description')"></p>
                        <hr class="mt-1">
                    </div>
                    <div>
                        <toggle-switch label="course:setting.hidden.label" :class="!canEditHidden ? 'pointer-events-none' : ''" v-model:content="course.hidden" />
                        <p class="text-xs italic">
                            {{ _mft('course:setting.purchasable.byline') }}
                        </p>
                        <p class="text-xs italic" v-if="!canEditHidden">
                            {{ _mft('course:setting.purchasable.editByline') }}
                        </p>
                        <hr class="mt-1">
                    </div>
                    <div>
                        <toggle-switch label="course:setting.forcedChapterOrder.label" v-model:content="course.chapters_must_be_consumed_in_order" />
                        <p class="text-xs italic">{{ _mft('course:setting.forcedOrder.description') }}.</p>
                        <hr class="mt-1">
                    </div>
                    <div>
                        <toggle-switch label="course:setting.private.label" v-model:content="course.only_for_company_users" />
                        <p class="text-xs italic">{{ _mft('course:setting.private.description') }}</p>
                    </div>
                </div>

            </template>
        </card-basic>

        <div class="pb-24">
            <div v-if="!isWellness">
                <mf-spinner-medium v-if="saving" class="w-16 h-8"></mf-spinner-medium>
                <button type="submit" class="float-right myflow-basic-button">{{ id ? _mft('course:save') : _mft('course:saveToAddChapters') }}</button>
            </div>
            <div v-else>
                <span class="float-right opacity-50 myflow-basic-button">{{ id ? _mft('course:save') : _mft('course:saveToAddChapters') }}</span>
                <i>{{ _mft('course:wellnessAppliedNotEditable') }}.</i>
            </div>
        </div>
    </form>
    <mf-spinner-medium v-else class="w-24 h-12"></mf-spinner-medium>
</template>

<style lang="scss" scoped>
form {
}
</style>

<script>
import { v4 as uuid } from 'uuid';
import axios from 'axios';

import InputCategory from './form/inputCategory.vue';
import InputProvider from './form/inputProvider.vue';
import InputVideo from './form/inputVideo.vue';
import InputArticle from './form/inputArticle.vue';
import InputQuestionnaire from './form/inputQuestionnaire.vue';
import FeaturedImage from '../forms/featuredImage.vue';

export default {
    components: {
        InputCategory,
        InputProvider,
        InputVideo,
        InputArticle,
        InputQuestionnaire,
        FeaturedImage
    },
    props: {
        id: { type: Number, default: null },
        canEditHidden: { type: Boolean, default: false },
        isWellness: { type: String, default: null },
        providerCount: { type: Number, default: 0 },
        assetCount: { type: Number, default: 0 },
    },
    data() {
        return {
            uuid: uuid(),
            saving: false,

            course: null,

            purchaseMethodOptions: [
                //{ label: "Gratis", value: "free", available: (c) => !c.only_for_company_users },
                { label: _mft('course:setting.purchaseMethod.cash'), value: "cash_sek", available: (c) => true },
                { label: _mft('course:setting.purchaseMethod.free'), value: "free" , available: (c) => true },
            ]
        };
    },
    computed: {
        availablePurchaseMethodOptions() {
            if (!this.course) { return []; }

            return this.purchaseMethodOptions.filter((o) => o.available(this.course));
        },
        typeOptions() {
            if (!this.course) { return []; }

            return TYPE_OPTIONS.filter((o) => o.maxCourseCount >= this.course.chapters.length);
        }
    },
    watch: {
        'course.purchase_method'(_, oldValue) {
            if (oldValue !== 'cash_sek') { return; }

            this.course.purchase_price = null;
        }
    },
    async mounted() {
        let course = null;
        if (this.id) {
            const response = await axios.get(route('manageCourses.courses.index', this.id));
            course = response.data;
            course.purchase_price = course.purchase_price / 100;
        } else {
            course = {};
        }

        if (!course.chapters) { course.chapters = []; }
        if (!course.provider) { course.provider = { id: null }; }
        if (!course.categories) { course.categories = []; }
        this.course = course;

        if (course.categories.length == 0) { this.addCategory(); }
    },
    methods: {
        addChapter() {
            this.course.chapters.push({
                provider: { id: this.course.provider.id }
            });
        },
        moveChapterUp(idx) {
            const chapter = this.course.chapters.splice(idx, 1);
            this.course.chapters.splice(idx - 1, 0, JSON.parse(JSON.stringify(chapter[0])));
        },
        moveChapterDown(idx) {
            const chapter = this.course.chapters.splice(idx, 1);
            this.course.chapters.splice(idx + 1, 0, JSON.parse(JSON.stringify(chapter[0])));
        },
        removeChapter(chapterIdx) {
            this.course.chapters.splice(chapterIdx, 1);
        },
        addCategory() {
            this.course.categories.push({ id: null });
        },
        removeCategory(chapterIdx) {
            this.course.categories.splice(chapterIdx, 1);
        },
        async save() {
            if (this.saving) { return; }

            this.saving = true;

            const formData = new FormData();
            this.buildFormData(formData, this.course);

            try {
                const response = await axios.post(route('manageCourses.courses.storeOrUpdate', { id: this.id }), formData);

                this.$document.locationAssign(route('manageCourses.courses.index', response.data.id));
            } catch (error) {
                this.saving = false;
                console.warn(error);
            }
        },
        buildFormData(formData, data, parentKey) {
            if (data && typeof data === 'object'  && !(data instanceof File)) {
                Object.keys(data).forEach(key => {
                    this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
                });
            } else {
                const value = data == null ? '' : data;
                formData.append(parentKey, value);
            }
        },
        featuredImageRemoved() {
            this.course.featured_image = ''
        },
        featuredImageUpdated(featuredImageData) {
            this.course.featured_image = featuredImageData.file
            this.course.featured_image_crop = featuredImageData.crop
        },
    },
};
</script>
