<script setup>
    import { ref } from 'vue'
    import IconChevron from "../../icons/iconChevron.vue";

    const props = defineProps({
       item: {
          type: Object,
          required: true
       }
    });

    const navigationExpanded = ref(false)

    const toggleNavigation = () => {
        navigationExpanded.value = !navigationExpanded.value
    }

</script>
<template>
    <div
        class="w-full md:w-auto"
        @mouseenter="navigationExpanded = true"
        @mouseleave="navigationExpanded = false"
    >
        <div
            class="flex items-center justify-between pl-2 md:pl-0 group md:justify-start"
            :class="item.subItems?.length > 0 ? 'md:pr-2' : 'md:pr-6'"
        >
            <slot></slot>
            <button
                v-if="item.subItems?.length > 0"
                @click="toggleNavigation"
                class="relative ml-2 flex h-6 w-8 items-center text-gray-400 md:m-0.5 md:h-4 md:w-6"
            >
                <icon-chevron
                    class="absolute inset-0 h-6 w-6 transform transition md:h-4 md:w-4"
                    :class="navigationExpanded ? 'rotate-180 fill-wlPrimary' : 'fill-wlPrimary-light'"
                />
            </button>
        </div>
        <div
            v-if="item.subItems?.length > 0"
            class="-mt-2 w-full md:z-topbar md:absolute md:top-full md:right-0 md:left-0 md:pt-8"
            :class="navigationExpanded ? 'block' : 'hidden'"
        >
            <ul
                class="ml-7 border-l-2 px-2 border-wlPrimary md:mx-0 md:ml-0 md:grid md:grid-cols-3 md:rounded-lg md:border-0 md:bg-white/80 md:p-4 md:shadow-md md:backdrop-blur-lg"
            >
                <li
                    v-for="(subItem, i) in item.subItems.filter(si => si.visible === true)"
                    class="px-1 py-2 md:p-4"
                >
                    <a
                        :href="subItem.href"
                        class="text-wlNavigation hover:underline hover:outline-none"
                    >
                        {{ subItem.name }}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>
<style>
    .subblur {
        backdrop-filter: blur(10px);
    }
</style>
