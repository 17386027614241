<script setup>
import { onMounted, ref, computed, provide } from 'vue';
import axios from 'axios';
import PostContent from "./_postContent.vue";

const props = defineProps({
    channelId: {
        type: Number,
        required: true,
    },
    channel: {
        type: Object,
        required: false,
    }
})

const loadingChannel = ref(false);
const memberships = ref([]);
const posts = ref([]);
const yesterdaysPost = ref(null);

const showChannel = ref(false);
const activeChannel = ref(null);

const newPostContent = ref(null);

const commentablePost = ref(null);

const postedToday = ref(false);

const currentUserCanPost = ref(false)

const accountability = ref({
    question1: null,
    question2: null,
    question3: null,
    yesterdaysQuestion1: null,
    yesterdaysQuestion2: null,
    yesterdaysQuestion3: null,
});

provide('memberships', {memberships} );

provide('accountability-data', {accountability, yesterdaysPost, activeChannel} );

provide('commentablePost', commentablePost);
provide('activeChannel', activeChannel);

provide('getChannelData', getChannelData);

async function getChannelData(channelId) {
    loadingChannel.value = true;
    const response = await axios.get(route('community.member.getChannelData', channelId));
    posts.value = response.data.posts;
    yesterdaysPost.value = response.data.yesterdays_post;
    postedToday.value = response.data.posted_today;
    currentUserCanPost.value = response.data.current_user_can_post;
    loadingChannel.value = false;
}

async function submitPost(channelId) {
    if (newPostValidates.value === false) {
        return;
    }

    const response = await axios.post(route('community.member.addPost', channelId), {
        content: newPostContent.value,
        accountability: accountability.value,
    });

    await getChannelData(channelId);

    newPostContent.value = null;
    accountability.value = {
        question1: null,
        question2: null,
        question3: null,
        yesterdaysQuestion1: null,
        yesterdaysQuestion2: null,
        yesterdaysQuestion3: null,
    };
}

async function highFivePost(postId) {
    const response = await axios.post(route('community.member.highFivePost', postId));

    await getChannelData(activeChannel.value.id);
}

async function flagPost(postId) {
    const response = await axios.post(route('community.member.flagPost', postId));

    await getChannelData(activeChannel.value.id);
}

function prepareComment(post) {
    if (commentablePost.value === post.id) {
        commentablePost.value = null;
        return;
    }
    commentablePost.value = post.id;
}

const canPost = computed(() => {
    if (activeChannel.value.accountability_enabled) {
        return !postedToday.value;
    }

    if (activeChannel.value.archived === true) {
        return false;
    }

    return currentUserCanPost.value;
})

const newPostValidates = computed(() => {
    if (newPostContent.value === null || newPostContent.value.length === 0) {
        return false
    }

    if (! activeChannel.value.accountability_enabled) {
        return true
    }
    if (activeChannel.value.accountability_questions_actions.question1 !== null && (! accountability.value.question1 || accountability.value.question1.length === 0)) {
        return false
    }

    if (activeChannel.value.accountability_questions_actions.question2 !== null && (! accountability.value.question2 || accountability.value.question2.length === 0)) {
        return false
    }

    if (activeChannel.value.accountability_questions_actions.question3 !== null && (! accountability.value.question3 || accountability.value.question3.length === 0)) {
        return false
    }

    return true
});

onMounted(() => {
    getChannelData(props.channelId);
    activeChannel.value = props.channel;
    showChannel.value = true;
});

</script>

<template>
    <div
        v-if="showChannel"
        class="relative"
    >
        <div
            v-if="loadingChannel"
            class="absolute inset-0 flex items-center justify-center h-screen bg-white/90"
        >
            <mf-spinner-medium class="w-24 h-12" />
        </div>
        <div>

            <div
                v-if="canPost"
                class="px-4 pt-4 -mx-4 -mt-4 bg-gray-100 border-b lg:-mx-6 lg:-mt-6 lg:px-6 lg:pt-6"
            >
                <input-textarea
                    :placeholder="_mft('community:channel.newPost.placeholder')"
                    v-model:content="newPostContent"
                ></input-textarea>

                <div v-if="activeChannel.accountability_enabled">
                    <accountability-post-data></accountability-post-data>
                </div>

                <div class="flex justify-end -mt-1">
                    <button-base
                        :style-type="newPostValidates ? 'primary' : 'disabled'"
                        class="mb-3 text-center"
                        :disabled="! newPostValidates"
                        v-on:click="submitPost(activeChannel.id)"
                    >
                        {{ _mft('community:channel.newPost.addPost') }}
                    </button-base>
                </div>
            </div>

            <p
                v-if="posts.length === 0 && ! loadingChannel"
                class="py-8 text-center text-gray-400"
            >
                {{ _mft('community:channel.noPosts') }}
            </p>

            <div
                v-for="post in posts"
                :key="post.id"
            >
                <div class="flex py-4 space-x-2">
                    <div class="w-6 shrink-0 md:w-10">
                        <img class="object-cover object-center w-6 h-6 rounded-full md:h-10 md:w-10" :src="post.user.profile_img" alt="avatar">
                    </div>
                    <div class="w-full">
                        <div class="flex items-center justify-between h-6 -translate-y-px md:h-10">
                            <div>
                                <span class="font-semibold">{{ post.user.name }}</span>
                            </div>
                            <div>
                                <span class="text-xs text-gray-400">{{ post.created_at }}</span>
                            </div>
                        </div>
                        <PostContent
                            :post="post"
                            class="pr-4"
                        />

                        <div
                            v-if="channel.accountability_enabled"
                            class="grid gap-4 p-4 mb-4 -ml-4 border rounded-wl md:grid-cols-2 md:gap-8"
                        >
                            <div>
                                <h5 class="text-sm mb-1">
                                    {{ _mft('community:channel.yesterdaysReflection.title') }}
                                </h5>

                                <community-yesterday-reflection
                                    :active-channel="activeChannel"
                                    :enabled="activeChannel.accountability_questions_actions.question1Enabled"

                                    :question="activeChannel.accountability_questions_actions.question1"
                                    :answer="post.previous_post_accountability_questions_actions?.question1"

                                    :previous-question="post.post_accountability_questions_actions.yesterdaysQuestion1"
                                ></community-yesterday-reflection>

                                <community-yesterday-reflection
                                    :active-channel="activeChannel"
                                    :enabled="activeChannel.accountability_questions_actions.question2Enabled"

                                    :question="activeChannel.accountability_questions_actions.question2"
                                    :answer="post.previous_post_accountability_questions_actions?.question2"

                                    :previous-question="post.post_accountability_questions_actions.yesterdaysQuestion2"
                                ></community-yesterday-reflection>

                                <community-yesterday-reflection
                                    :active-channel="activeChannel"
                                    :enabled="activeChannel.accountability_questions_actions.question3Enabled"

                                    :question="activeChannel.accountability_questions_actions.question3"
                                    :answer="post.previous_post_accountability_questions_actions?.question3"

                                    :previous-question="post.post_accountability_questions_actions.yesterdaysQuestion3"
                                ></community-yesterday-reflection>

                            </div>

                            <div>
                                <h5 class="text-sm mb-1">
                                    {{ _mft('community:channel.dailyActions.title') }}
                                </h5>
                                <community-todays-post-action
                                    :enabled="activeChannel.accountability_questions_actions.question1Enabled"
                                    :question="activeChannel.accountability_questions_actions.question1"
                                    :answer="post.post_accountability_questions_actions.question1"
                                ></community-todays-post-action>

                                <community-todays-post-action
                                    :enabled="activeChannel.accountability_questions_actions.question2Enabled"
                                    :question="activeChannel.accountability_questions_actions.question2"
                                    :answer="post.post_accountability_questions_actions.question2"
                                ></community-todays-post-action>

                                <community-todays-post-action
                                    :enabled="activeChannel.accountability_questions_actions.question3Enabled"
                                    :question="activeChannel.accountability_questions_actions.question3"
                                    :answer="post.post_accountability_questions_actions.question3"
                                ></community-todays-post-action>
                            </div>
                        </div>

                        <div class="flex mt-4 space-x-1 md:space-x-2">
                            <button-base
                                :style-type="commentablePost === post.id ? 'secondary' : 'base'"
                                size="sm"
                                v-on:click="prepareComment(post)"
                            >
                                <i class="mr-1 fa-regular fa-comment"></i>
                                {{ _mft('community:post.comment') }}
                            </button-base>

                            <button-base
                                v-if="! post.is_high_fived"
                                v-on:click="highFivePost(post.id)"
                                size="sm"
                            >
                                <i class="mr-1 fa-regular fa-hand-wave"></i>
                                {{ _mft('community:post.highFive') }}
                            </button-base>
                            <button-base
                                v-else
                                style-type="transparent"
                                size="sm"
                                class="text-myflowGreen"
                                v-on:click="highFivePost(post.id)"
                            >
                                <i class="mr-1 fa-regular fa-hand-wave"></i>
                                {{ _mft('community:post.highFived') }}
                            </button-base>

                            <button-base
                                v-if="! post.is_flagged"
                                v-on:click="flagPost(post.id)"
                                size="sm"
                                class="text-myflowRed-600"
                                classes="!border-myflowRed-600"
                            >
                                <i class="mr-1 fa-regular fa-flag"></i>
                                {{ _mft('community:post.flag') }}
                            </button-base>
                            <button-base
                                v-else
                                v-on:click="flagPost(post.id)"
                                size="sm"
                                style-type="transparent"
                                class="text-myflowRed-600"
                                classes="!border-myflowRed-600"
                            >
                                <i class="mr-1 fa-solid fa-flag"></i>
                                {{ _mft('community:post.flagged') }}
                            </button-base>
                        </div>
                    </div>

                </div>

                <community-channel-comments-list
                    :post-data="post"
                ></community-channel-comments-list>
            </div>
        </div>
    </div>
</template>
