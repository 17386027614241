<template>
    <div>
        <div class="mt-4 mb-4 divide-gray-400 separator">
            <button-base :styleType="stateClass" v-on:click="collapseExpand">
                <span v-if="collapsed">
                    <i class="fa-sharp fa-solid fa-plus"></i> <slot name="title"></slot>
                </span>

                <span v-if="!collapsed">
                    <i class="fa-regular fa-minus"></i> <slot name="title"></slot>
                </span>
            </button-base>
        </div>

        <div
             :class="!collapsed ? '' : hiddenClass"
        >
            <slot name="body"></slot>
        </div>

    </div>
</template>
<script>
export default {
    props: {
        state: {
            type: String,
            default: null,
        },
        labelAlignment: {
            type: String,
            default: 'center',
        },
        initalCollapsed: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            collapsed: true,
        };
    },
    async mounted() {
        this.collapsed = this.initalCollapsed;
    },
    methods: {
        collapseExpand() {
            if(this.collapsed === true) {
                this.collapsed = false;
            } else {
                this.collapsed = true;
            }
        },
    },
    computed: {
        hiddenClass() {
            return 'max-h-0 focus-within:max-h-full overflow-hidden';
        },
        stateClass() {
            if(! this.collapsed) {
                return 'secondary'
            }

            if(this.state == null) {
                return 'primary';
            } else if(this.state == 'success') {
                return 'success';
            } else if(this.state == 'error') {
                return 'danger';
            } else if(this.state == 'warning') {
                return 'warning';
            } else if(this.state == 'info') {
                return 'secondary';
            }
        }
    }
}
</script>

<style scoped>
    .separator {
        display: flex;
        align-items: center;
        text-align: center;
    }

    .separator::before,
    .separator::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid darkgrey;
    }

    .separator:not(:empty)::before {
        margin-right: 1.50em;
    }

    .separator:not(:empty)::after {
        margin-left: 1.50em;
    }
</style>
