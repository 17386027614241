<template>
    <list-item>
        <template #teleporter>

        </template>
        <template #image v-if="showImage">
            <div class="w-4/5 mx-auto mb-4 md:mb-0 md:w-full">
                <div class="relative w-full bg-top bg-cover rounded-full" style="padding-bottom: 100%;"
                    :style="{ backgroundImage: 'url(' + featuredImage + ')' }"
                    v-on:click.self="redirect(route('coaching.book', [product.provider.id, product.id]))">
                </div>
            </div>
        </template>

        <template #title>
            <span v-if="product.wellness_confirmed_at" class="mb-2 text-myflowGreen-800">
                <i class="fa-regular fa-spa"></i>
                {{ _mft('coachingProduct:availableAsWellness') }}
            </span>
            <h2 class="mb-1 text-lg leading-6 text-gray-900">
                <a :href="route('coaching.book', [product.provider.id, product.id])" class="text-gray-900">
                    {{ product.title }}
                </a>
            </h2>
        </template>

        <template
            v-if="intro"
            #intro
        >
            <span v-html="renderedIntro"></span>
        </template>

        <template #bio>
            <p v-if="product.provider.profile.name">
                <a :href="route('coaching.show', product.provider.id)">
                    <span class="block font-bold text-gray-900">
                        {{ product.provider.profile.name }}
                    </span>
                    <span class="text-gray-500">{{ product.provider.profile.title }}</span>
                    <div
                        class="flex items-center text-xs"
                        v-if="product.provider.profile.display_rating && showRating"
                    >
                        <span class="mr-2 text-xs">
                            <i :data-star="product.profile.average_rating"></i>
                        </span>
                        {{ product.profile.average_rating }} / 5.00
                    </div>
                </a>
            </p>
        </template>

        <template #description>
            <span
                v-html="previewing ? product.description.replace(/<\/?[^>]+(>|$)/g, ' ') : product.description.substring(0, 200).replace(/<\/?[^>]+(>|$)/g, ' ') + '... '"
            ></span>
            <span v-on:click="toggleDescription" class="italic cursor-pointer text-myflowPurple-800">
                <br>{{ previewing ? ' Dölj' : ' Läs mer' }}
            </span>
        </template>

        <template #info>
            <div class="justify-end md:flex">
                <a :href="route('coaching.book', [product.provider.id, product.id])" class="block w-full mb-3 text-center myflow-basic-button md:w-auto md:inline-block">
                    {{ _mft('coachingProduct:readMoreAndBook') }}
                </a>
            </div>

            <div class="mb-2 space-y-0.5" v-if="product.session_quantity > 1">
                <div class="flex items-end justify-between space-x-4">
                    <strong>{{ _mft('coachingProduct:numberOfSessions') }}:</strong>
                    <span>{{ product.session_quantity }}</span>
                </div>
                <div class="flex items-end justify-between space-x-4">
                    <strong>{{ _mft('coachingProduct:sessionLength') }}:</strong>
                    <span>{{ product.duration_display }}</span>
                </div>

                <div class="flex items-end justify-between space-x-4">
                    <strong>{{ _mft('coachingProduct:pricePerSession') }}:</strong>
                    <span>{{ product.price_per_session_including_vat_display }}</span>
                </div>
                <div class="flex items-end justify-between space-x-4">
                    <strong>{{ _mft('coachingProduct:totalPrice') }}:</strong>
                    <span>{{ product.price_including_vat_display }}</span>
                </div>
            </div>
            <div class="mb-2 space-y-0.5" v-else>
                <div class="flex items-end justify-between space-x-4">
                    <strong>{{ _mft('coachingProduct:duration') }}:</strong>
                    <span>{{ product.duration_display }}</span>
                </div>
                <div class="flex items-end justify-between space-x-4">
                    <strong>{{ _mft('coachingProduct:price') }}:</strong>
                    <span>{{ product.price_including_vat_display }}</span>
                </div>
            </div>

        </template>
    </list-item>
</template>
<script>
import Playbutton from '../icons/playbutton.vue'

export default {
  components: {
    Playbutton
  },
  props: {
    intro: String,
    product: Object,
    showImage: {
        type: Boolean,
        default: true
    },
    showRating: {
        type: Boolean,
        required: false,
        default: true,
    }
  },
  data: function(){
    return {
      previewing: false,
      profileImg: this.coachProfileImgUrl,
    }
  },
  methods: {
    toggleDescription() {
      this.previewing = !this.previewing
    },
    redirect(route) {
      this.$document.locationAssign(route);
    }
  },
  mounted() {
  },
  computed: {
    fill: function(){
      if( this.favorited ){
        return 'currentColor'
      } else {
        return 'none'
      }
    },
    availableProductCountString: function(){
      if( this.availableProductCount === 0 ){
        return _mft('coachingProduct:noOffersRightNow')
      } else if (this.availableProductCount === 1) {
        return this.availableProductCount + ' ' + _mft('coachingProduct:domainNameSingular')
      } else {
        return this.availableProductCount + ' ' + _mft('coachingProduct:domainNamePlural')
      }
    },
    cardButtonClass: function(){
      return 'bg-white shadow-cardbutton'
    },
    featuredImage: function(){
        if (this.product.featured_image) {
            return this.product.featured_image
        } else {
            return this.product.provider.image_url
        }
    },
    renderedIntro: function () {
        if (this.intro && this.intro.length > 0) {
            if (this.product.featured_image) {
                return '<span class=""><img src="'+ this.product.featured_image +'" class="content-center inline float-left object-cover w-12 h-12 mr-4 rounded-full lg:w-14 lg:h-14 xl:w-24 xl:h-24" />' + this.intro + '</span>';
            } else {
                return this.intro;
            }
        } else {
            return null;
        }
    },
  }
}
</script>
<style scoped>
  .h-min {
    height: min-coach;
  }
  .shadow-cardbutton {
    box-shadow: 0px 0px 12px rgba(0,0,0,0.15);
  }
</style>
