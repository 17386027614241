<template>
  <notification
    :show="showNotification"
    @close="closeNotification()"
  >
    <template
      v-slot:header
    >
      {{ notification.message }}
    </template>
  </notification>
  <card-form-content>
    <template v-slot:header><h2>{{ _mft('crm:leadDealCard.domainNamePlural') }}</h2></template>
    <template v-slot:form>
      <input-select
        v-model:content="creatingDeal.type"
        :options="typeOptions"
        valueSpecification="id"
        labelSpecification="type_name"
        :label="_mft('crm:leadDealCard.type')"
        required
        placeholder=""
        name="type"
      />

      <input-select
        v-model:content="creatingDeal.status"
        :options="statusOptions"
        :label="_mft('crm:leadDealCard.status')"
        required
        placeholder=""
        name="status"
      />

      <input-number
        :label="_mft('crm:leadDealCard.estimatedValue')"
        :content="parseInt( creatingDeal.value, 10 ) ? parseInt( creatingDeal.value, 10 ) : null"
        name="value"
        required
        v-model:content="creatingDeal.value"
      />

      <div class="flex justify-end mt-2">
        <input-button
          :label="_mft('shared:action.save')"
          type="success"
          :disabled="!creatingDealIsValid"
          :busy="working"
          v-on:click="createDeal"
        />
      </div>
    </template>
    <template v-slot:content>
      <p class=""
        v-if="localDeals.length === 0"
      >
          {{ _mft('crm:leadDealCard.noDealsAdded') }}
      </p>
      <div
        v-else
      >
        <ul
          role="list"
          class="grid-cols-2 gap-4 md:grid"
          v-if="(isInternal == 1)"
        >
          <li class="col-span-1 mb-2 bg-white divide-y divide-gray-200 rounded-lg shadow"
            v-for="(deal, i) in localDeals"
            v-bind:key="i"
            v-on:click="deal.invoice == null ? openInSlideout(deal) : null"
          >
            <div class="flex items-center justify-between w-full p-6 space-x-6">
              <div class="flex-1 truncate">
                <div class="flex items-center space-x-3">
                  <h3 class="text-sm text-gray-900 truncate font-large">{{ deal.lead_deal_type_rel?.type_name}}</h3>
                </div>
                <h5
                  class="mt-3 text-myflowGreen-700"
                  v-if="deal.invoice != null"
                >Fakturerad: {{ deal.invoice !== '2099-11-30' ? deal.invoice : 'Under bearbetning' }}</h5>
                <h5
                  class="mt-3 text-myflowGreen-700"
                  v-if="deal.invoice != null"
                >Betald: {{ deal.paid }}</h5>
                <p class="mt-1 text-sm text-gray-500 truncate">{{deal.status}}</p>
                <p class="mt-3 text-sm text-gray-900 truncate">{{deal.value.toLocaleString()}} kr</p>

              </div>
            </div>
          </li>
        </ul>
        <ul
          role="list"
          class="grid-cols-2 gap-4 md:grid"
          v-else
        >
          <li class="col-span-1 mb-2 bg-white divide-y divide-gray-200 rounded-lg shadow"
            v-for="(deal, i) in localDeals"
            v-bind:key="i"
            v-on:click="openInSlideout(deal)"
          >
            <div class="flex items-center justify-between w-full p-6 space-x-6">
              <div class="flex-1 truncate">
                <div class="flex items-center space-x-3">
                  <h3 class="text-sm text-gray-900 truncate font-large">{{ deal.lead_deal_type_rel?.type_name}}</h3>
                </div>
                <p class="mt-1 text-sm text-gray-500 truncate">{{deal.status}}</p>
                <p class="mt-3 text-sm text-gray-900 truncate">{{deal.value.toLocaleString()}} kr</p>

              </div>
            </div>
          </li>
        </ul>
      </div>

      <basic-slideout
        v-model:open="open"
      >
        <template v-slot:header>
          <h1>{{ _mft('crm:leadDealCard.updateDeal') }}</h1>
        </template>

        <template v-slot:body>
          <input-select
            v-model:content="currentlyEditingDeal.type"
            :options="typeOptions"
            valueSpecification="id"
            labelSpecification="type_name"
            :label="_mft('crm:leadDealCard.type')"
            required
            placeholder=""
            name="type"
          />

          <input-select
            v-model:content="currentlyEditingDeal.status"
            :options="statusOptions"
            :label="_mft('crm:leadDealCard.status')"
            required
            placeholder=""
            name="status"
          />

          <input-number
              :label="_mft('crm:leadDealCard.estimatedValue')"
            :content="currentlyEditingDeal.value"
            name="value"
            v-model:content="currentlyEditingDeal.value"
          />

          <input-button
            :label="_mft('shared:action.update')"
            type="success"
            :busy="working"
            :disabled="!currentlyEditingDealIsValid"
            v-on:click="updateDeal"
            class="float-right"
          />

          <a :href="route('crm.deal.delete', currentlyEditingDeal.id)" class="text-myflowRed-700">
              {{ _mft('crm:leadDealCard.deleteDeal') }}
          </a>

        </template>

      </basic-slideout>

    </template>

  </card-form-content>
</template>
<script>
export default {
  props: {
    alignment: String,
    lead: Object,
    deals: Object,
    internal: Number
  },
  data: function(){
    return {
      creatingDeal: {
        type: null,
        status: null,
        value: null
      },
      currentlyEditingDeal: {
        id: '',
        type: '',
        status: '',
        value: ''
      },
      localDeals: {},
      typeOptions: [],
      statusOptions: [
        { value: _mft('crm:leadDealCard.status.initialContact'), label: _mft('crm:leadDealCard.status.initialContact') },
        { value: _mft('crm:leadDealCard.status.meetingBooked'), label: _mft('crm:leadDealCard.status.meetingBooked') },
        { value: _mft('crm:leadDealCard.status.offerSent'), label: _mft('crm:leadDealCard.status.offerSent') },
        { value: _mft('crm:leadDealCard.status.dealWon'), label: _mft('crm:leadDealCard.status.dealWon') },
        { value: _mft('crm:leadDealCard.status.dealLost'), label: _mft('crm:leadDealCard.status.dealLost') },
        { value: _mft('crm:leadDealCard.status.onHold'), label: _mft('crm:leadDealCard.status.onHold') },
      ],
      notification: {
        type: '',
        message: ''
      },
      open: false,
      working: false,
      isInternal: this.internal
    }
  },
  methods: {
    openInSlideout(deal){
      this.currentlyEditingDeal = deal;
      this.open = true;
    },
    async createDeal(){
      const res = await axios.post( route('crm.leads.deals.add', this.lead.id) , this.creatingDeal);

      this.notification = res.data.notification;
      this.localDeals = res.data.deals;
      this.resetCreatedealForm();
    },
    async updateDeal(){
      const res = await axios.post( route('crm.deal.update', this.currentlyEditingDeal.id) , this.currentlyEditingDeal);

      this.notification = res.data.notification;
      this.closeSlideout();
    },
    resetCreatedealForm(){
      this.creatingDeal = {
        type: null,
        status: null,
        value: null
      };
    },
    closeSlideout(){
      this.open = false;
    },
    closeNotification(){
      this.notification.message = ''
    },
    async getDealTypes() {
      const response = await axios.get(route('crm.deal.types'));
      this.typeOptions = response.data;
    },
  },
  mounted: function(){
    this.localDeals = this.deals;
    this.getDealTypes();
  },
  computed: {
    hasHeaderSlot () {
      return !!this.$slots['header-left'] && !!this.$slots['header-right'];
    },
    hasImageSlot () {
      return !!this.$slots.image
    },
    hasBodySlot () {
      return !!this.$slots.body
    },
    alignmentClass (){
      return this.alignment === 'center' ? 'text-center' : ''
    },
    showNotification (){
      return this.notification.message.length > 0
    },
    currentlyEditingDealIsValid() {
        if( isNaN(this.currentlyEditingDeal.value) ){
            return false;
        }
        if( this.currentlyEditingDeal.type === null || this.currentlyEditingDeal.status === null || this.currentlyEditingDeal.value === null ){
            return false;
        }
        return true;
    },
    creatingDealIsValid() {
        if( isNaN(this.creatingDeal.value) ){
            return false;
        }
        if( this.creatingDeal.type === null || this.creatingDeal.status === null || this.creatingDeal.value === null ){
            return false;
        }
        return true;
    }
  },
  setup() {

  },
}
</script>
