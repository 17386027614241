<script setup>

</script>

<template>
    <div>
        <div>
            <settings-front-page
                :label="_mft('setting:footer.frontPage.loggedOut.label')"
                front-page-type="website"
            />

            <settings-front-page
                :label="_mft('setting:footer.frontPage.loggedIn.label')"
                front-page-type="customerarea"
            />
        </div>

        <settings-footer />
    </div>
</template>

<style scoped>

</style>
