<script setup>
    import { onMounted, reactive, ref } from 'vue'

    const state = reactive({
        slideoutOpen: false,
        recordings: [],
        loading: false,
    })

    const preview = reactive({
        active: false,
        hlsUrl: null,
        posterUrl: null,
    })

    const recordingComplete = (videodata) => {
        state.slideoutOpen = false
        loadRecordings()
    }

    const loadRecordings = async () => {
        if (state.recordings.length === 0) {
            state.loading = true
        }
        const res = await axios.get(route('browser-recording.index'))
        let loadedRecordings = res.data

        if ( JSON.stringify(loadedRecordings) !== JSON.stringify(state.recordings) ) {
            state.recordings = loadedRecordings
        }

        state.loading = false
    }

    const openPreview = (recording) => {
        preview.active = true
        preview.hlsUrl = recording.hls_url
        preview.posterUrl = recording.gif_url
    }

    const closePreview = () => {
        preview.active = false
        preview.hlsUrl = null
        preview.posterUrl = null
    }

    onMounted(() => {
        setInterval(() => {
            loadRecordings()
        }, 10000)
        loadRecordings()
    })

</script>
<template>
    <div class="flex min-h-screen">
        <div class="w-full">
            <card-basic>
                <template v-slot:header>
                    <div class="flex items-center justify-between w-full mb-4 space-x-4">
                        <h5>{{ _mft('browserRecording.domainNamePlural') }}</h5>

                        <button
                            @click="state.slideoutOpen = !state.slideoutOpen"
                            class="myflow-basic-button"
                        >{{ _mft('browserRecording:createNew') }}</button>
                    </div>
                </template>
                <template v-slot:body>
                    <mf-spinner-medium
                        v-if="state.loading"
                        class="float-left w-24 h-16"
                    />
                    <p
                        v-if="!state.loading && state.recordings.length === 0"
                        class="italic text-gray-500"
                    >
                        {{ _mft('browserRecording:noRecordingsToShow') }}
                    </p>
                    <ul class="-mt-4 divide-y">
                        <li
                            v-for="(entry, i) in state.recordings"
                            class="py-4"
                            :id="'media-bank-entry-' + entry.id"
                        >
                            <div class="flex justify-between gap-1">
                                <div>
                                    <div class="flex space-x-2">
                                        <expert-profile-image-with-trailer
                                            class="w-10 h-10 bg-gray-200 rounded-full md:w-12 md:h-12 shrink-0"
                                            :userId="entry.user.id"
                                        />
                                        <div>
                                            <h6 class="font-bold md:space-x-2">
                                                <span>
                                                    {{ entry.name }}
                                                </span>
                                                <div class="block space-x-2 md:inline">
                                                    <span
                                                        v-if="entry.duration"
                                                        class="font-normal"
                                                    >{{ entry.duration }} {{ _mft('shared:date.second', {count: entry.duration}) }}</span>
                                                </div>
                                            </h6>
                                            <div>
                                                <small class="space-x-2">
                                                    <span>{{ entry.user.name }}</span>
                                                    <span>{{ entry.created_at_display }}</span>
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <link-field
                                            class="flex mt-4 ml-1 space-x-4 text-sm md:ml-0 md:space-x-4"
                                            :url="route('browser-recording.show', entry.uuid)"
                                        ></link-field>
                                    </div>
                                </div>

                                <div v-if="(entry.hls_entry_path != null) && (entry.trimmed_file_path != null)">
                                    <button
                                        @click="openPreview(entry)"
                                        class="inline-flex items-center justify-center w-6 h-6 p-2 text-white rounded-md bg-myflowPurple kerning-0"
                                    >
                                        <i class="text-xs fa fa-play"></i>
                                    </button>
                                </div>
                                <span
                                    v-else
                                    class="text-xs text-center text-myflowMagenta"
                                >
                                    <mf-spinner-medium class="w-12 h-6" />
                                    {{ _mft('browserRecording:processing') }}
                                </span>
                            </div>
                        </li>
                    </ul>
                </template>
            </card-basic>
            <slideout-with-slots
                v-if="state.slideoutOpen"
                :title="_mft('browserRecording:createNew')"
                @close="state.slideoutOpen = false"
            >
                <template #body>
                    <div class="pb-24">
                        <video-recorder
                            @recordingComplete="recordingComplete"
                        ></video-recorder>
                    </div>
                </template>
            </slideout-with-slots>

            <slideout-with-slots
                v-if="preview.active"
                :title="_mft('shared:preview')"
                @close="closePreview"
            >
                <template #body>
                    <video-player
                        :hls="preview.hlsUrl"
                        :poster="preview.gifUrl"
                    ></video-player>
                </template>
            </slideout-with-slots>
        </div>
    </div>
</template>
