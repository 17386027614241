<template>
  <div>
    <div
      class="px-4 py-2 mb-2 bg-white border-2 rounded-xl border-myflowPurple"
    >
      <div class="lg:grid lg:grid-cols-2 lg:gap-2">
        <div class="pb-2 mb-2 text-xs border-b lg:pb-0 border-myflowPurple-200 lg:border-b-0">
          <p class="mb-0 text-sm font-bold">
            {{ booking.begins_at.date }}, {{ booking.begins_at.time }} - {{ booking.ends_at.time }}
          </p>
          <p class="mb-0">{{ booking.product.title }}</p>
        </div>
        <div class="mb-4 text-xs lg:mb-0 lg:text-right">
          <p class="mb-0">
            <span class="mb-0 text-sm font-bold">{{ booking.user.name }}</span><br>
            <a
              v-if="booking.user.phone"
              :href="'tel:' + booking.user.phone"
            >
              {{ booking.user.phone }}<br>
            </a>
            <a :href="'mailto:' + booking.user.email">{{  booking.user.email }}</a>
          </p>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-2">
        <div
          class="col-span-2 text-center"
          v-if="confirming || confirmNotification !== ''"
        >
          <p class="text-xs" v-if="confirming">Godkänner bokning...</p>
          <p class="flex items-center justify-center py-1 mb-0 text-green-800" v-if="confirmNotification !== ''">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
              <path stroke-linecap="round" stroke-linejoin="round" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            {{ confirmNotification }}
          </p>
        </div>
        <button
          class="text-xs myflow-basic-button"
          v-on:click="toggleShowRelated"
        >{{ showRelatedLabel }}</button>
        <button
          class="text-xs myflow-basic-button"
          v-on:click="toggleShowNotes"
        >{{ showNotesLabel }}</button>
        <button
          v-if="!booking.confirmed"
          class="col-span-2 text-xs myflow-basic-button--warning"
          v-on:click="confirmBooking"
        >Godkänn bokning</button>
        <button
          v-if="booking.confirmed"
          class="col-span-1 text-xs myflow-basic-button--danger"
          v-on:click="beginCancelBooking"
        >Avboka</button>
        <a
          v-if="booking.confirmed"
          class="col-span-1 text-xs text-center myflow-basic-button--success"
          :href="route('coaching.session', booking.id)"
        >Anslut till samtal</a>
      </div>
      <div
        v-if="showRelated"
      >
        <ul class="divide-y">
          <li
            class="py-1 my-1 text-xs"
            v-for="(relatedBooking, i) in booking.related_bookings"
            v-bind:key="i"
          >
            <p class="mb-0 font-bold">{{ relatedBooking.begins_at.date }}, {{ relatedBooking.begins_at.time }} - {{ relatedBooking.ends_at.time }}</p>
            <p class="mb-0">{{ relatedBooking.product.title }}</p>
          </li>
        </ul>
      </div>
      <div
        v-if="showNotes"
      >
        <input-editor
          label=""
          required=""
          :content="note"
          name="note"
          v-model:content="note"
          ref="noteEditor"
          :error="errors.note"
        />
        <div class="flex items-center justify-end">
          <p class="mb-0 mr-2 text-xs text-right">{{ notification }}</p>
          <input-button
            class="text-xs"
            label="Uppdatera"
            type="success"
            :busy="savingNote"
            v-on:click="updateNote"
          />
        </div>
      </div>
      <div
        v-if="cancelModalVisible"
        class="pt-2 mt-2 text-center border-t border-myflowMagenta"
      >
        <p class="mb-1 text-xs">Är du säker på att du vill avboka?</p>
        <div class="flex justify-center space-x-2">
            <button
                class="col-span-1 text-xs myflow-basic-button"
                v-on:click="cancelModalVisible = false"
            >Avbryt</button>
            <button
                class="col-span-1 text-xs myflow-basic-button--danger"
                v-on:click="cancelBooking"
            >Avboka</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    booking: Object,
  },
  data: function(){
    return {
      confirming: false,
      errors: {
        note: ''
      },
      notification: '',
      confirmNotification: '',
      note: '',
      showRelated: false,
      showNotes: false,
      savingNote: false,
      cancelModalVisible: false
    }
  },
  methods: {
    toggleShowRelated(){
      if( this.showNotes ){
        this.showNotes = false
      }
      this.showRelated = !this.showRelated
    },
    toggleShowNotes(){
      if( this.showRelated ){
        this.showRelated = false
      }
      this.showNotes = !this.showNotes
    },
    async confirmBooking(){
      this.confirming = true
      const res = await axios.post( route('coaching.booking.confirm', {booking: this.booking.id}))
      this.booking.confirmed = res.data.booking.confirmed
      this.confirmNotification = res.data.notification
      this.confirming = false
      let self = this
    },
    beginCancelBooking() {
        this.cancelModalVisible = true;
    },
    async cancelBooking(){
        const res = await axios.post( route('coaching.booking.cancel', {booking: this.booking.id}))
        window.location.reload();
    },
    async updateNote(){
      this.savingNote = true
      const res = await axios.put( route('coaching.booking.updateNote', {booking: this.booking.id}) , {note: this.note})
      this.notification = res.data.notification
      this.savingNote = false
      let self = this
      setTimeout(function(){
        self.notification = ''
      }, 3000)
    },
  },
  mounted() {
    this.note = this.booking.note
  },
  computed: {
    showRelatedLabel(){
      if( this.showRelated ){
        return 'Dölj relaterade bokningar'
      } else {
        return 'Visa relaterade bokningar'
      }
    },
    showNotesLabel(){
      if( this.showNotes ){
        return 'Dölj anteckningar'
      } else {
        return 'Visa anteckningar'
      }
    }
  }
}
</script>
<style scoped>
</style>
