<template>
    <div class="-mx-2 md:-mx-6 shadow-custom rounded-custom">
        <div ref="tocNavOuter" class="p-6">
            <h2 class="pb-1 text-xl md:text-2xl lg:text-3xl">Innehåll</h2>
            <ul class="space-y-3 max-x-2xl border-l ml-1 pl-1">
                <li
                    v-for="(section, i) in sections"
                    :key="i"
                    class="-ml-2"
                >
                    <span
                        v-on:click="scrollTo(section.id)"
                        class="rounded-custom pr-1 pl-4 px-2 cursor-pointer inline-block relative transform group"
                    >
                        <span class="border-2 border-gray-400 group-hover:border-gray-800 transition-colors bg-white rounded-full h-3 w-3 inline-block absolute -left-0.5 top-1/2 -translate-y-1/2"></span>
                        {{ section.title }}
                    </span>
                </li>
            </ul>
        </div>

        <div
            class="fixed top-0 bottom-0 flex items-center transition-all w-6"
            :class="visible ? '-right-4 md:right-0 lg:right-4' : '-right-6'"
        >
            <ul class="flex flex-col justify-center space-y-6 max-h-screen py-8">
                <li
                    v-for="(section, i) in sections"
                    :key="i"
                    v-on:click="scrollTo(section.id)"
                    class="relative cursor-pointer"
                >
                    <div
                        class="w-4 h-4 rounded-full border-white border-2 hover:border-4 group relative transition-all transform"
                        :class="current == section.id ? 'bg-white scale-125 shadow-point-current' : 'bg-transparent shadow-point'"
                    >
                        <span
                            class="absolute top-1/2 text-right text-xs transform group-hover:-translate-y-1/2 text-gray-800 opacity-0 group-hover:opacity-100 -left-4 translate-y-0 transition-all pointer-events-none -translate-x-full"
                        >
                            <span class="bg-white shadow-md inline-block whitespace-nowrap py-1 px-2 rounded-lg">
                                {{ section.title }}
                            </span>
                        </span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props: {
    },
    data() {
        return {
            sections: [],
            visible: true,
            current: null,
            autohide: false,
            skippedBlocktypes: [
                'table-of-contents',
                'loom-embed',
                'lead-capture',
                'voting-suggestions'
            ],
            customLabels: {
                video: 'Video',
                image: 'Bild',
                slideshow: 'Bildspel',
            }
        }
    },
    methods: {
        scrollTo(id) {
            let self = this;
            let element = document.getElementById(id);
            element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
            setTimeout(function(){
                self.current = id;
            }, 400);
        },
        collectElements() {
            let elements = document.querySelectorAll('.block-editor-blocks [data-blocktype]');
            let self = this;
            elements.forEach((element) => {
                if( this.skippedBlocktypes.includes(element.dataset.blocktype) ){ return };
                let label = this.getLabel(element);
                if( !label ){ return; }

                self.sections.push({
                    id: element.id,
                    title: label,
                    blocktype: element.dataset.blocktype,
                });
            });
        },
        observeScrollstate() {
            const observer = new IntersectionObserver(([e]) => this.visible = e.intersectionRatio < 1, { threshold: [1] });
            observer.observe(this.$refs.tocNavOuter);
        },
        observeCurrentSection() {
            let self = this;
            var observer = new IntersectionObserver(function(e) {
                if( e[0].intersectionRatio < 0.1 ) return;
                if(e[0].isIntersecting === false) return;


                self.current = e[0].target.id;
            }, { threshold: [0.1] });

            document.querySelectorAll(".block-editor-blocks [data-blocktype]").forEach((element) => {
                observer.observe(element);
            });
        },
        getTocPosition() {
            let blocks = document.querySelectorAll('.block-editor-blocks [data-blocktype]');
            if( blocks[0]?.dataset.blocktype === 'table-of-contents' || blocks[1]?.dataset.blocktype === 'table-of-contents') {
                this.autohide = true;
            }
        },
        getLabel(element) {
            let label = element.textContent.length > 32 ? element.textContent.substring(0, 32) + '...' : element.textContent;
            if( label.length === 0 ){
                if( this.customLabels[element.dataset.blocktype] ){
                    return this.customLabels[element.dataset.blocktype];
                }
                return false;
            }
            return label;
        }
    },
    mounted() {
        this.collectElements();
        this.getTocPosition();
        if( this.autohide ){
            this.observeScrollstate();
        } else {
            this.visible = true;
        }
        this.observeCurrentSection();
    },
};
</script>
<style scoped>
    .shadow-point {
        box-shadow: 0px 0px 8px rgba(0,0,0,0.15), inset 0px 0px 6px rgba(0,0,0,0.10);
    }
    .shadow-point-current {
        box-shadow: 0px 0px 8px rgba(0,0,0,0.15);
    }
</style>
