<script setup>
import {computed, nextTick, ref} from 'vue';
import SingleUpload from "./singleUpload.vue";

const emit = defineEmits(['uploadComplete']);

const props = defineProps({
    hideOnCompletion: {
        type: Boolean,
        required: false,
        default: false,
    }
})

const files = ref([]);
const dropAreaState = ref('inactive')
const legacyFilePicker = ref(null)
const uploadAllKey = ref(0)

function onFileChange(event) {
    addFiles(event.target.files)
}

const dropHandler = (event) => {
    event.preventDefault();
    addFiles(event.dataTransfer.files)
    setInactive()
}

const setActive = () => {
    dropAreaState.value = 'active'
}

const setInactive = () => {
    dropAreaState.value = 'inactive'
}

const addFiles = (newFiles) => {
    let newUploadableFiles = [...newFiles]
        .map((file) => new UploadableFile(file))
        .filter((file) => !fileExists(file.id))
    files.value = files.value.concat(newUploadableFiles)
}

const uploadAll = () => {
    uploadAllKey.value++
}

function fileExists(otherId) {
    return files.value.some(({ id }) => id === otherId)
}

const removeFile = (file) => {
    files.value = files.value.filter(({ id }) => id !== file.id)
}

const uploadComplete = (file) => {
    if (props.hideOnCompletion) {
        nextTick(() => {
            files.value = files.value.filter(({id}) => id !== file.id)
        })
    }
    setTimeout(function(){
        emit('uploadComplete', file)
    }, 500)
}

const dropAreaPlaceholder = computed(() => {
    if (dropAreaState.value === 'active') {
        return _mft('mediaBank:fileSelect.dropFiles')
    }

    return _mft('mediaBank:fileSelect.dragFilesHere')
})

class UploadableFile {
    constructor(file) {
        this.file = file
        this.name = file.name
        this.id = `${file.name}-${file.size}-${file.lastModified}-${file.type}`
        this.media = file.type.split('/')[0]
        this.filetype = file.type
        this.size = file.size
        this.url = URL.createObjectURL(file)
    }
}

</script>
<template>
    <div class="@container">
        <div
            @drop.prevent="dropHandler"
            @dragenter.prevent="setActive"
            @dragover.prevent="setActive"
            @dragleave.prevent="setInactive"
            class="mb-4 flex h-16 @lg:h-24 w-full flex-col items-center justify-center border-2 border-dashed border-gray-400 transition-all rounded-wl hover:border-gray-500"
            :class="dropAreaState === 'active' ? 'bg-myflowGreen-100' : 'bg-gray-50'"
            @click="$refs.legacyFilePicker.click()"
        >
            <div class="pointer-events-none flex cursor-default items-center">
                <i v-if="dropAreaState === 'inactive'" class="mr-2 text-xl text-gray-400 fa-regular fa-file-import"></i>
                <i v-if="dropAreaState === 'active'" class="mr-2 text-xl text-gray-400 fa-regular fa-thumbs-up"></i>
                {{ dropAreaPlaceholder }}
            </div>
            <div class="pointer-events-none cursor-default text-xs text-gray-400">{{ _mft('mediaBank:fileSelect.orClickToAdd') }}</div>
        </div>

        <div class="relative h-0 w-0 overflow-hidden">
            <input
                type="file"
                @change="onFileChange"
                ref="legacyFilePicker"
                class="absolute bottom-full left-full"
                multiple
            />
        </div>

        <div
            v-if="files.length > 1"
            class="my-2 flex justify-end py-4"
        >
            <button-success
                @click="uploadAll"
            >
                {{ _mft('mediaBank:uploadAll') }}
            </button-success>
        </div>

        <div
            class="-mx-4 divide-y lg:-mx-6"
            :class="files.length > 0 ? 'mb-8' : ''"
        >
            <div
                v-for="file in files"
                :key="file.id"
                class="px-4 py-4 md:px-6 md:py-2 lg:px-8"
            >
                <SingleUpload
                    :uploadable="file"
                    :upload-trigger="uploadAllKey"
                    @upload-complete="uploadComplete"
                    @remove="removeFile"
                />
            </div>

        </div>
    </div>
</template>
