 <template>
    <Teleport
        v-if="isLoaded"
        to="#teleport-target-modal"
        class="z-50"
    >
        <div
            class="fixed inset-0 z-modal flex items-start justify-center transition-all duration-200 bg-opacity-20"
            :class="containerTransitionClass"
            v-if="isOpen"
        >
            <div
                class="fixed inset-0 z-modal cursor-pointer"
                v-on:click="closeModal"
            ></div>
            <div
                class="relative z-modal p-4 transition-all duration-200 transform bg-white shadow-xl max-w-screen rounded-xl lg:p-8 w-modal mt-modal"
                :class="[modalTransitionClass, showClose ? 'pt-8' : '']"
            >
                <div
                    class="absolute p-px bg-white border-2 rounded-full top-1 right-1 text-wlPrimary hover:text-wlPrimary-light border-wlPrimary hover:border-wlPrimary-light cursor-pointer"
                    v-on:click="closeModal"
                    v-if="showClose"
                >
                    <svg viewBox="0 0 24 24" class="w-4 h-4 fill-current" xmlns="http://www.w3.org/2000/svg"><path d="m0 0h24v24h-24z" opacity="0" transform="matrix(-1 0 0 -1 24 24)"/><path d="m13.41 12 4.3-4.29a1 1 0 1 0 -1.42-1.42l-4.29 4.3-4.29-4.3a1 1 0 0 0 -1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z"/></svg>
                </div>
                <h2
                    v-if="this.$slots.header"
                    class="mb-2"
                >
                    <slot name="header"></slot>
                </h2>
                <slot name="body">
                    <component :is="specifiedComponent" @exportedContent="exportedContent" />
                </slot>
            </div>
        </div>
    </Teleport>
</template>
<script>

export default {
    emits: ['close', 'export'],
    props: {
        aiComponent: {
            type: String,
            default: null
        },
        open: {
            type: Boolean,
            default: false
        },
        showClose: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            isOpen: this.open,
            isLoaded: false,
            state: null,
            specifiedComponent: this.aiComponent
        }
    },
    methods: {
        exportedContent(exportedContent){
            this.$emit('export', exportedContent);
            this.closeModal();
        },
        closeModal(){
            let close = true;
            this.isOpen = false;
            if( close ){
                let self = this
                self.setTransitionState('transition-out')
                setTimeout(function(){
                    self.$emit('close', false);
                    self.isOpen = false;
                }, 200);
            }
        },
        setTransitionState(state){
            this.state = state
        }
    },
    mounted(){
        this.isLoaded = true
    },
    watch: {
        open() {
            this.isOpen = this.open;
        },
        isOpen(newVal, oldVal) {
            let self = this;
            setTimeout(function(){
                if( oldVal === false ){
                    self.setTransitionState('transition-in')
                } else {
                    self.setTransitionState('transition-out')
                }
            }, 1)
        }
    },
    computed: {
        modalTransitionClass() {
            if( this.state === 'transition-in' ){
                return 'translate-y-0 scale-100 opacity-1'
            } else {
                return 'translate-y-24 scale-95 opacity-0'
            }
        },
        containerTransitionClass() {
            if( this.state === 'transition-in' ){
                return 'bg-black'
            } else {
                return ''
            }
        }
    }
}
</script>
<style scoped>
    .w-modal {
        width: 90vw;
        height: 90vh;
        width: calc(100vw - 32px);
    }
    .mt-modal {
        margin-top: 5vh;
    }
</style>
