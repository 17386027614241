<template>
    <div>
        <input-select
            v-if="!props.offer"
            :options="data.candidates"
            label="Produkt att erbjuda"
            v-model:content="data.selected"
        ></input-select>

        <input-textarea
            label="Kommentar"
            v-model:content="data.comment"
            required="true"
        ></input-textarea>
        <p v-if="data.emptyComment" class="text-myflowRed-900">Fyll i en kommentar</p>

        <div class="flex flex-row-reverse justify-between space-x-4">
            <input-button
                tabindex="1"
                :label="data.submitLabel"
                :busy="data.isSubmitting"
                type="submit"
                :disabled="!offerValidates"
                v-on:click="saveOffer"
            />

            <input-button
                v-if="props.offer"
                tabindex="0"
                label="Ta bort erbjudande"
                :busy="data.isSubmitting"
                type="submit"
                style-type="danger"
                :disabled="!props.offer"
                v-on:click="deleteOffer"
            />
        </div>
    </div>
</template>
<script setup>
    import axios from 'axios';
    import { watch, reactive, onMounted, computed } from 'vue';

    const emit = defineEmits(['offerAdded']);

    const props = defineProps({
        offer: {
            type: Object,
            default: null,
        }
    });

    const data = reactive({
        selected: null,
        selectedId: null,
        selectedType: null,
        comment: null,
        submitLabel: 'Spara erbjudande',
        isSubmitting: false,
        candidates: [],
        emptyComment: false,
    })

    const offerValidates = computed(() => {
        return data.selectedId !== null || props.offer;
    })

    watch(() => data.selected, (selected, prevSelected) => {
        data.selectedType = selected.split(':')[0];
        data.selectedId = selected.split(':')[1];
    })

    onMounted(() => {
        loadOfferableCandidates();
        if (props.offer) {
            data.comment = props.offer.comment;
        }
    })

    async function saveOffer() {
        if (data.comment === null) {
            data.emptyComment = true;
        } else {
            if( props.offer ) {
            let res = await axios.put(route('offerable.update', props.offer.id), {
                comment: data.comment,
            });
            emit('offerUpdated', res.data.offer);
        } else {
            let res = await axios.post(route('offerable.store'), {
                offerable_id: data.selectedId,
                offerable_type: data.selectedType,
                comment: data.comment,
            });
            emit('offerAdded', res.data.offer);
        }

        }
    }

    async function deleteOffer() {
        if( props.offer ) {
            if( !confirm('Är du säker på att du vill ta bort erbjudandet?') ) return;
            let res = await axios.delete(route('offerable.delete', props.offer.id));
            location.reload();
        }
    }

    async function loadOfferableCandidates() {
        let res = await axios.get(route('offerables.candidates'));

        res.data.courses.items.forEach(item => {
            data.candidates.push({
                id: item.id,
                value: res.data.courses.type + ':' + item.id,
                title: item.title,
                label: res.data.courses.label + ': ' + item.title,
            });
        });
        res.data.coaching_products.items.forEach(item => {
            data.candidates.push({
                id: item.id,
                value: res.data.coaching_products.type + ':' + item.id,
                title: item.title,
                label: res.data.coaching_products.label + ': ' + item.title,
            });
        });
        res.data.subscribables.items.forEach(item => {
            data.candidates.push({
                id: item.id,
                value: res.data.subscribables.type + ':' + item.id,
                title: item.title,
                label: res.data.subscribables.label + ': ' + item.title,
            });
        });
    }

</script>
