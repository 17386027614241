<template>
    <div>
        <div v-if="mediaBankEntryOptions" class="mb-4">
            <input-label
                class="mb-1"
                :label="label"
                :required="required"
            />
            <v-select
                :options="mediaBankEntryOptionsIncludingDefault"
                :reduce="(o) => o.value"
                v-model="value"
                class="mb-1"
            >
                <template v-if="type == 'image'" v-slot:option="option">
                    <div v-if="option.value" class="flex">
                        <media-bank-entries-display
                            class="w-1/5 mr-2"
                            :data-url="route('mediaBank.entries.show', option.value)"
                        />
                        <div class="flex-1 font-semibold">{{ option.label }}</div>
                    </div>
                    <div v-else class="font-semibold text-center">{{ option.label }}</div>
                </template>
                <template v-if="required" #search="{attributes, events}">
                    <input
                        class="vs__search"
                        :required="!content"
                        v-bind="attributes"
                        v-on="events"
                    />
                </template>
            </v-select>
            <div class="mt-2">
                <a :href="uploadLinkUrl" target="_blank">
                    <button-secondary size="sm">{{uploadLinkText}}</button-secondary>
                </a>
                <button-warning size="sm" type="button" @click.prevent="reload()" class="ml-2">{{reloadButtonText}}</button-warning>
            </div>
        </div>
        <mf-spinner-medium v-else class="w-16 h-8"></mf-spinner-medium>
    </div>
</template>
<script>
import vSelect from 'vue-select';
export default {
    components: {
        vSelect
    },
    props: {
        label: { type: String, default: null },
        required: { type: Boolean, default: false },
        type: { type: String, required: true },
        content: null
    },
    emits: [
        'update:content'
    ],
    data() {
        return {
            mediaBankEntryOptions: null,
            value: this.content,
            uploading: false
        };
    },
    computed: {
        mediaBankEntryOptionsIncludingDefault() {
            const options = [];
            if (!this.required) {
                options.push({value: null, label: '- ingen -'});
            }
            return options.concat(this.mediaBankEntryOptions);
        },
        uploadLinkUrl() {
            if (this.type == 'video') {
                return route('mediaBank.cloudflareStreamAssets.index');
            } else {
                return route('mediaBank.entries.index', {type: this.type});
            }
        },
        uploadLinkText() {
            return {
                file: _mft('mediaBank:manageFiles'),
                video: _mft('mediaBank:manageVideos'),
                image: _mft('mediaBank:manageImages'),
                audio: _mft('mediaBank:manageSounds'),
            }[this.type];
        },
        reloadButtonText() {
            return {
                file:  _mft('mediaBank:reloadAvailableFiles'),
                video: _mft('mediaBank:reloadAvailableVideos'),
                image: _mft('mediaBank:reloadAvailableImages'),
                audio: _mft('mediaBank:reloadAvailableSounds'),
            }[this.type];
        }
    },
    async mounted() {
        await this.reload();
    },
    watch: {
        content() {
            if (this.value === this.content) { return; }

            // Some data sources store media bank entry id as string,
            // so we need to clean it up..
            this.value = parseInt(this.content, 10);
        },
        value() {
            this.$emit('update:content', this.value);
        }
    },
    methods: {
        async reload() {
            this.mediaBankEntryOptions = null;
            const response = await axios.get(route('mediaBank.entries.index'));
            this.mediaBankEntryOptions = response.data.data
                .filter((entry) => entry.type == this.type)
                .map((entry) => ({ value: entry.id, label: entry.title }))
        }
    }
}
</script>
