<template>
  <span class="spinner inline-block w-4">
    <svg class="w-full h-auto" enable-background="new 0 0 204.481 204.481" viewBox="0 0 204.481 204.481" xmlns="http://www.w3.org/2000/svg"><path d="m162.116 38.31c.163-.215.315-.438.454-.67.033-.055.068-.109.1-.164.156-.276.297-.561.419-.857.014-.034.024-.069.038-.104.102-.26.188-.528.261-.801.019-.069.037-.137.053-.207.068-.288.124-.581.157-.881.002-.017.006-.034.008-.052.028-.262.043-.527.043-.796v-26.278c0-4.142-3.358-7.5-7.5-7.5h-107.817c-4.142 0-7.5 3.358-7.5 7.5v26.279c0 .269.016.534.043.796.002.017.006.034.008.052.034.3.089.593.157.881.016.069.035.138.053.207.073.273.159.541.261.801.013.034.024.069.038.104.121.296.262.581.419.857.032.056.067.109.1.164.14.232.291.455.454.67.027.035.047.074.074.109l50.255 63.821-50.255 63.821c-.028.035-.047.074-.074.109-.163.215-.315.438-.454.67-.033.055-.068.109-.1.164-.156.276-.297.561-.419.857-.014.034-.024.069-.038.104-.102.26-.188.528-.261.801-.019.069-.037.137-.053.207-.068.288-.124.581-.157.881-.002.017-.006.034-.008.052-.028.262-.043.527-.043.796v26.279c0 4.142 3.358 7.5 7.5 7.5h107.817c4.142 0 7.5-3.358 7.5-7.5v-26.279c0-.269-.016-.534-.043-.796-.002-.017-.006-.034-.008-.052-.034-.3-.089-.593-.157-.881-.016-.069-.035-.138-.053-.207-.073-.273-.159-.541-.261-.801-.013-.034-.024-.069-.038-.104-.121-.296-.262-.581-.419-.857-.032-.056-.067-.109-.1-.164-.14-.232-.291-.455-.454-.67-.027-.035-.047-.074-.074-.109l-50.255-63.821 50.255-63.821c.028-.035.047-.074.074-.11zm-13.467-23.31v11.279h-92.817v-11.279zm-92.817 174.481v-11.279h92.817v11.279zm84.866-26.279h-76.914l38.457-48.838zm-38.457-73.084-38.457-48.839h76.914z"/></svg>
    <span class="sr-only">{{label}}</span>
  </span>
</template>
<style scoped>
.spinner {
  animation: rotation 3s infinite linear;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(179deg);
  }
  50% {
    transform: rotate(179deg);
  }
  60% {
    transform: rotate(359deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
</style>
<script>
export default {
    props: {
        label: String
    }
}
</script>
