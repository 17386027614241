<template>
    <div class="bg-gradient-to-r from-myflowPurple-900 to-myflowPurple-800 pt-24 pb-24 flex justify-center align-center">
        <div class="text-center text-white w-full ml-4 mr-4 lg:m-0 lg:w-6/12">
                <p class="text-2xl lg:text-3xl">{{content}}</p>
            </div>
    </div>
</template>

<script>
export default {
    props: {
        content : String,
    },
    data() {
        return {
        };
    },
};


</script>

<style scoped>

</style>
