<template>
  <nav class="hidden w-full px-4 mx-auto bg-gray-200 sm:px-6 lg:pr-4 xl:pr-4 lg:pl-4 xl:pl-7 lg:block" aria-label="Top">
    <div class="flex items-center justify-between w-full h-full">
      <div class="flex items-center h-full">
        <div class="items-center hidden h-full pt-4 space-x-2 md:flex lg:text-xs xl:text-sm">
          <secondary-navigation-item
            v-for="(item) in navigation.navigationItems"
            v-bind:key="item.name"
            :item="item"
          />
        </div>
      </div>

        <div class="flex items-center xl:items-end lg:-mb-3 lg:text-xs xl:text-sm">
            <div class="pt-0 ml-4 space-x-4 lg:hidden xl:block">
                <div
                  v-if="navigation.primaryCallToAction || navigation.secondaryCallToAction"
                >
                    <a
                        v-if="navigation.secondaryCallToAction && (navigation.secondaryCallToAction.type === 'link')"
                        :href="navigation.secondaryCallToAction.href"
                        target="_blank"
                        class="inline-block px-2 py-1 mr-2 border rounded-full border-myflowPurple hover:bg-myflowPurple-100 text-myflowPurple"
                    >
                        {{ navigation.secondaryCallToAction.name }}
                        <i :class="navigation.secondaryCallToAction.icon" class="ml-1"></i>
                    </a>

                    <call-to-action-with-slideout
                        v-if="navigation.secondaryCallToAction && (navigation.secondaryCallToAction.type === 'slideout')"
                        :callToAction="navigation.secondaryCallToAction"
                    />
                    <a
                        v-if="navigation.primaryCallToAction?.type === 'link'"
                        :href="navigation.primaryCallToAction?.href"
                        class="inline-block px-2 py-1 text-white border border-transparent rounded-full bg-myflowPurple hover:bg-myflowPurple-600"
                    >
                        {{ navigation.primaryCallToAction.name }}
                    </a>

                    <call-to-action-with-slideout
                        v-if="navigation.primaryCallToAction?.type === 'slideout'"
                        :callToAction="navigation.primaryCallToAction"
                    />
                </div>
            </div>
            <div>
                <primary-navigation-header
                :user-profile-img-url="userProfileImgUrl"
                />
            </div>
        </div>
    </div>
  </nav>
</template>
<script>
export default {
  setup() {

  },
  props: {
    navigation: Object,
    userProfileImgUrl: String
  }
}
</script>
