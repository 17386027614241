<template>
  <div>
    <div>
      <img
        class="block w-full crop-image"
        ref="cropimage"
        id="image"
        :src="imageUrl"
      />
    </div>
    <div id="preview" class="block w-full overflow-hidden"></div>
    <div class="mx-4">
      <button v-on:click.prevent="preview()" class="w-full mt-4 text-xs myflow-basic-button">{{ _mft('shared:preview') }}</button>
      <div class="flex justify-end mt-4">
        <button v-on:click.prevent="abortCropping()" class="w-full mr-2 text-xs myflow-basic-button--danger">{{ _mft('shared:cancel') }}</button>
        <button v-on:click.prevent="finishCropping()" class="w-full ml-2 text-xs myflow-basic-button--success">{{ _mft('shared:done') }}</button>
      </div>
    </div>
  </div>
</template>
<script>
import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';
import { nextTick } from 'vue'

export default {
  props: {
    imageBase64String: {
      type: String,
      default: ''
    },
  },
  data(){
    return {
      cropper: null,
      croppedImageBase64String: '',
      cropping: {
        x: null,
        y: null,
        width: null,
        height: null
      }
    }
  },
  methods: {
    preview(){
      this.croppedImageBase64String = this.cropper.getCroppedCanvas().toDataURL()
      this.$emit('updatePreview', this.croppedImageBase64String)
    },
    finishCropping(){
      this.preview()
      this.$emit('finishCropping', this.croppedImageBase64String)
    },
    abortCropping(){
      this.$emit('abortCropping')
    },
    setImage(){
      let self = this
      const image = this.$refs.cropimage
      this.cropper = new Cropper(image, {
        aspectRatio: 1 / 1,
        viewMode: 2,

        crop(event) {
          self.cropping.x = event.detail.x
          self.cropping.y = event.detail.y
          self.cropping.width = event.detail.width
          self.cropping.height = event.detail.height
        },
      });
    },
    resetCropper(){
      this.cropperActive = false
    }
  },
  mounted(){
    this.setImage()
  },
  computed: {
    imageUrl() {
      return 'data:image/png;base64, ' + this.imageBase64String
    }
  },
  watch: {
    cropping: {
      deep: true,
      handler: function() {
        this.$emit('updateCropping', this.cropping)
      }
    }
  }
}
</script>
<style scoped>
  img.crop-image {
    max-height: 55vh;
  }
</style>
