<template>
    <div class="mb-4">
        <input-label
            :label="label"
            :required="required"
        />
        <div
            class="relative mt-1 rounded-md shadow-sm"
        >
            <textarea
                type="text"
                class="block w-full p-2 pr-10 mt-1 border rounded-md h-36"
                :class="inputClass"
                :placeholder="placeholder"
                :value="content"
                @input="handleInput"
                :required="required"
                :name="name"
            ></textarea>
        </div>
        <div>{{ smsCount }}</div>
        <input-error v-if="error" :error="error" />
    </div>
</template>

<script>
export default {
    data() {
        return {
            inputClass: '',
            smsCount: '',
            encoding: {
                UTF16: [70, 64, 67],
                GSM_7BIT: [160, 146, 153],
                GSM_7BIT_EX: [160, 146, 153],
            },
            charset: {
                gsmEscaped: '\\^{}\\\\\\[~\\]|€',
                gsm: '@£$¥èéùìòÇ\\nØø\\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !"#¤%&\'()*+,-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà',
            }
        };
    },
    props: {
        content: String,
        error: String,
        label: String,
        placeholder: String,
        required: Boolean,
        name: String
    },
    watch: {
        error(error) {
            this.inputClass = error ? 'border-red-700' : 'border-gray';
        },
        content() {
            this.calculateSMSCount();
        }
    },
    methods: {
        handleInput(event) {
            this.$emit('update:content', event.target.value);
            this.calculateSMSCount();
        },
        regex() {
            return {
                gsm: RegExp(`^[${this.charset.gsm}]*$`),
                gsmEscaped: RegExp(`^[\\${this.charset.gsmEscaped}]*$`),
                gsmFull: RegExp(`^[${this.charset.gsm}${this.charset.gsmEscaped}]*$`),
            };
        },
        detectEncoding(text) {
            if (text.match(this.regex().gsm)) {
                return this.encoding.GSM_7BIT;
            } else if (text.match(this.regex().gsmFull)) {
                return this.encoding.GSM_7BIT_EX;
            } else {
                return this.encoding.UTF16;
            }
        },
        getEscapedCharCount(text) {
            return [...text].reduce((acc, char) => acc + (char.match(this.regex().gsmEscaped) ? 1 : 0), 0);
        },
        getPartData(totalLength, encoding) {
            let maxCharCount = encoding[2];
            let numberOfSMS = Math.ceil(totalLength / maxCharCount);
            let remaining = maxCharCount - (totalLength - (encoding[0] + encoding[1] + (encoding[2] * (numberOfSMS - 3))));

            if (totalLength <= encoding[0]) {
                maxCharCount = encoding[0];
                numberOfSMS = 1;
                remaining = maxCharCount - totalLength;
            } else if (totalLength > encoding[0] && totalLength <= (encoding[0] + encoding[1])) {
                maxCharCount = encoding[1];
                numberOfSMS = 2;
                remaining = maxCharCount - (totalLength - encoding[0]);
            }

            return {
                maxCharCount,
                numberOfSMS,
                remaining,
                totalLength,
            };
        },
        getCount(text) {
            let length = text.length;
            const encoding = this.detectEncoding(text);

            if (encoding === this.encoding.GSM_7BIT_EX) {
                length += this.getEscapedCharCount(text);
            }

            return this.getPartData(length, encoding);
        },
        calculateSMSCount() {
            const count = this.getCount(this.content);
            this.smsCount = _mft('communication:composer.sms.sentAsSmsCount', {count: count.numberOfSMS});
        }
    },
    mounted() {
        this.calculateSMSCount();
    }
};
</script>
