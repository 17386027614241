<template>
    <div>
        <div
            class="block w-full pr-10 mt-1 p-2 text-gray-900"
            :class="inputClass"
        >
            <label
                class="flex items-center"
            >
                <input
                    class="mr-2 w-4 h-4"
                    type="checkbox"
                    :value="value"
                    :name="name"
                    :required="required"
                    v-model="value"
                    @change="$emit('update:content', value)"
                />
                {{ label }}
            </label>
        </div>
        <input-error v-if="error" :error="error" />
    </div>
</template>

<script>

export default {
    data: function(){
        return {
            inputClass: '',
            value: false,
        }
    },
    props: {
        content: Boolean,
        options: Array,
        error: String,
        label: String,
        required: Boolean,
        name: String
    },
    mounted() {
        this.value = this.content
    },
    watch: {
        content: {
            handler() {
                if (JSON.stringify(this.content) === JSON.stringify(this.value)) { return; }

                this.value = this.content ? JSON.parse(JSON.stringify(this.content)) : [];
            },
            immediate: true
        },
        error: function(error){
            let classes = [];
            if( error !== '' ){
                classes.push('border-red-700')
            } else {
                classes.push('border-gray')
            }
            this.inputClass = classes.join(' ')
        }
    }
}
</script>
