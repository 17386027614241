<script setup>

import {computed, onMounted, ref} from "vue";

const props = defineProps({
    sessionId: {
        type: Number,
        required: true,
    }
})

const session = ref(null)
const savingNotesState = ref('')
const editingLocation = ref(false)
const locationSaveState = ref('')

const loadSession = async () => {
    const res = await axios.get(route('api.coaching2.coach.session.show', props.sessionId))

    if (res.data.success) {
        session.value = res.data.session
    }
}

const setStatus = async (status) => {
    const res = await axios.post(route('api.coaching2.coach.session.setStatus', [props.sessionId, status]))
    session.value.status = res.data.status
}

const updateLocation = async () => {
    locationSaveState.value = 'waiting'
    const res = await axios.put(route('api.coaching2.coach.session.updateLocation', props.sessionId), {
        location: session.value.location
    })

    if (res.data.success) {
        editingLocation.value = false
        locationSaveState.value = 'success'
        return
    }

    locationSaveState.value = 'error'
}

const saveNotes = async () => {
    savingNotesState.value = ''
    const res = await axios.put(route('api.coaching2.coach.session.notes.update', session.value.id), {
        notes: session.value.customer_notes
    })

    if (res.data.success) {
        savingNotesState.value = 'success'
    } else {
        savingNotesState.value = 'error'
    }
}

const reloadPage = (e) => {
    location.reload()
}

const sessionLocationIsUrl = computed(() => {
    return session.value && session.value.location && session.value.location.startsWith('http')
})

onMounted(() => {
    loadSession();
})

</script>

<template>
    <div
    >
        <div v-if="session">
            <p
                class="w-full mx-auto mt-4 md:mt-8 lg:mt-12 pl-4 md:pl-6 lg:pl-8"
                :class="session.coach_notes_enabled ? 'max-w-5xl ' : 'max-w-2xl '"
            >
                <a :href="route('coaching2.coach.home')">
                    <i class="fa-solid fa-arrow-left mr-1"></i>
                    {{ _mft('offerable:session.backToList') }}
                </a>
            </p>

            <card-basic
                class="w-full mx-auto mt-4"
                :class="session.coach_notes_enabled ? 'max-w-5xl ' : 'max-w-2xl '"
            >
                <div>
                    <div class="flex justify-between items-start text-gray-500">
                        <div class="w-full mb-2">
                            <h1 class="text-lg">{{ session.title }}</h1>
                            <p class="mb-2">{{ session.client.name }}<span v-if="session.coach"> & {{ session.coach.name }}</span></p>
                            <p
                                v-if="session.scheduled"
                                class="text-xs text-gray-500 space-x-2 mb-0"
                            >
                                <span>{{ session.scheduled_at_date }}</span>
                                <span>
                                    {{ session.scheduled_at_time_start }} - {{ session.scheduled_at_time_end }}
                                </span>
                            </p>
                            <p class="mb-0 text-xs space-x-1 tracking-wide">
                                <span>{{ _mft('offerable:session.status.count', {count: session.stats.session_number, total: session.stats.sessions_total}) }}</span>
                                <span class="text-gray-300"> | </span>
                                <span>{{ session.stats.session_duration }}</span>
                            </p>
                        </div>

                        <div class="flex flex-col items-end justify-end">
                            <span class="font-bold whitespace-nowrap">
                                {{ _mft('offerable:status.' + session.status) }}
                            </span>
                            <coaching-v2-sessions-booking-modal
                                :session="session"
                                @bookingSuccess="reloadPage"
                                @cancelSuccess="reloadPage"
                            />
                            <div
                                v-if="session.completable || true"
                                class="flex flex-col items-end"
                            >
                                <button-primary
                                    v-if="session.status === 'booked'"
                                    size="sm"
                                    @click="setStatus('completed')"
                                    class="mt-1 lg:whitespace-nowrap"
                                >
                                    {{ _mft('offerable:action.markSessionCompleted') }}
                                </button-primary>
                                <button-secondary
                                    v-if="session.status === 'completed' || session.status === 'no-show'"
                                    size="sm"
                                    @click="setStatus('booked')"
                                    class="mt-1 lg:whitespace-nowrap"
                                >
                                    {{ _mft('offerable:action.markSessionNotCompleted') }}
                                </button-secondary>
                                <button-secondary
                                    v-if="session.status === 'booked'"
                                    size="sm"
                                    @click="setStatus('no-show')"
                                    class="mt-1 lg:whitespace-nowrap"
                                >
                                    {{ _mft('offerable:action.markSessionNoShow') }}
                                </button-secondary>
                                <button-secondary
                                    v-if="session.status === 'cancelled'"
                                    size="sm"
                                    @click="setStatus('reschedulable')"
                                    class="mt-1 lg:whitespace-nowrap"
                                >
                                    {{ _mft('offerable:action.markSessionReschedulable') }}
                                </button-secondary>
                            </div>
                        </div>
                    </div>

                    <p class="mb-0 text-xs text-gray-500 mt-2">
                        {{ _mft('offerable:location') }}
                    </p>
                    <div
                        v-if="editingLocation"
                        class="flex items-center"
                    >
                        <input-text
                            v-model:content="session.location"
                            class="max-w-sm !mb-0"
                        ></input-text>
                        <button-success
                            @click="updateLocation"
                            class="ml-2"
                            size="sm"
                        >{{ _mft('shared:action.save') }}</button-success>
                    </div>
                    <p v-else>
                        <a
                            v-if="sessionLocationIsUrl"
                            :href="session.location.trim()"
                            class="text-myflowGreen underline"
                            target="_blank"
                        >
                            {{ session.location }}
                        </a>
                        <span v-else>
                            {{ session.location }}
                        </span>
                        <button-secondary
                            @click="editingLocation = true"
                            class="ml-2"
                            size="sm"
                            :state="locationSaveState"
                        >{{ _mft('shared:action.edit') }}</button-secondary>
                    </p>

                    <div
                        v-if="session.customer_notes_enabled"
                    >
                        <input-editor
                            v-model:content="session.customer_notes"
                        ></input-editor>
                        <div class="flex justify-end">
                            <button-success
                                :state="savingNotesState"
                                @click="saveNotes"
                            >
                                {{ _mft('offerable:action.saveNotes') }}
                            </button-success>
                        </div>
                    </div>
                </div>
            </card-basic>
        </div>
        <mf-spinner-medium
            v-else
            class="w-24 h-12"
        />
    </div>
</template>
