<script setup>
import {computed, onMounted, reactive, ref, watch} from 'vue'

const orders = ref([])
const loading = ref(false)

const filterOptions = reactive({
    state: [
        {
            label: _mft('invoiceLink:purchase.state.all'),
            name: 'all',
        },
        {
            label: _mft('invoiceLink:purchase.state.initiated'),
            name: 'initiated',
        },
        {
            label: _mft('invoiceLink:purchase.state.paid'),
            name: 'paid',
        },
    ],
    paymentMethod: [
        {
            label: _mft('invoiceLink:purchase.method.all'),
            name: 'all',
        },
        {
            label: _mft('invoiceLink:purchase.method.stripe'),
            name: 'stripe',
        },
        {
            label: _mft('invoiceLink:purchase.method.invoice'),
            name: 'invoice',
        },
    ],
})

const initialLoadDone = ref(false)
const filterTerm = ref('')
const filters = ref({
    state: 'all',
    paymentMethod: 'all',
})

const loadOrders = async () => {
    loading.value = true
    orders.value = []
    const res = await axios.get(route('api.invoice-links.orders.index', {
        state: filters.value.state,
        paymentMethod: filters.value.paymentMethod,
    }))
    orders.value = res.data.orders
    initialLoadDone.value = true
    loading.value = false
}

const setFilter = (filter, event) => {
    filters.value[filter] = event.name
    if (filter === 'state') {
        _mfPrefSet('invoicing-link-order-state-filter', event.name)
    }
    if (filter === 'paymentMethod') {
        _mfPrefSet('invoicing-link-order-payment-method-filter', event.name)
    }
}

const filteredOrders = computed(() => {
    return orders.value.filter(order => {
        if (filterTerm.value === '') return true
        return order.title.toLowerCase().includes(filterTerm.value.toLowerCase()) ||
            order.billing_name.toLowerCase().includes(filterTerm.value.toLowerCase()) ||
            order.invoice_reference.toLowerCase().includes(filterTerm.value.toLowerCase()) ||
            order.billing_email.toLowerCase().includes(filterTerm.value.toLowerCase())
    })
})

watch(filters.value, () => {
    if (initialLoadDone.value) {
        loadOrders()
    }
}, {deep: true})

onMounted(() => {
    _mfPrefGet('invoicing-link-order-state-filter').then(data => {
        filters.value.state = data
        _mfPrefGet('invoicing-link-order-payment-method-filter').then(data => {
            filters.value.paymentMethod = data
            loadOrders()
        })
    })
})
</script>

<template>
    <div>
        <div class="md:sticky top-2 z-topbar">
            <frosted-bar>
                <div class="flex items-center justify-between space-x-4">
                    <h1>
                        {{ _mft('invoiceLink:purchase.domainNamePlural')}}
                    </h1>
                </div>
            </frosted-bar>
        </div>

        <div class="flex justify-between space-x-1 items-center mb-4 mt-8">
            <div class="flex flex-col items-end justify-end md:space-x-4 md:flex-row md:items-center w-full @container">
                <div class="mb-0 flex items-center justify-end space-x-4">
                    <button-bar
                        size="xs"
                        class="!mb-0"
                        :options="filterOptions.paymentMethod"
                        :selected-option-name="filters.paymentMethod"
                        @select="setFilter('paymentMethod', $event)"
                        :responsive="false"
                    ></button-bar>
                    <button-bar
                        size="xs"
                        class="!mb-0"
                        :options="filterOptions.state"
                        :selected-option-name="filters.state"
                        @select="setFilter('state', $event)"
                        :responsive="false"
                    ></button-bar>
                    <input-text
                        v-model:content="filterTerm"
                        :placeholder="_mft('shared:search')"
                        class="w-48 lg:w-96 !mb-0 -mt-1"
                        input-class="text-xs"
                    />
                </div>
            </div>
        </div>

        <div
            v-if="! loading && initialLoadDone"
            class="mt-2 bg-white shadow-xl rounded-wl"
        >
            <p
                class="pt-6 pb-4 text-center text-gray-500"
                v-if="filteredOrders && filteredOrders.length === 0"
            >
                {{ _mft('invoiceLink:noLinksToShow') }}
            </p>
            <ul class="divide-y divide-gray-200">
                <li
                    v-for="(order, index) in filteredOrders"
                    :key="order.uuid"
                >
                    <invoice-link-order-listitem
                        :order="order"
                    />
                </li>
            </ul>
        </div>
        <mf-spinner-medium
            v-else
            class="w-24 h-12"
        />
    </div>
</template>

<style scoped>

</style>
