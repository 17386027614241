<template>
    <div class="bg-myflowPurple-dark overflow-hidden">
        <div
            class="overflow-hidden z-10 bg-gradient-to-r from-myflowPurple-dark relative lg:min-h-screen lg:pb-0 pb-16 flex items-center justify-start flex-col"
        >
            <video
                class="absolute z-0 opacity-40 object-cover w-full h-full"
                autoplay=""
                muted=""
                playsinline=""
                loop=""
                src="https://helasverigesamlas.se/wp-content/uploads/2022/02/1053758117-hd-copy.mov"
            ></video>
            <div
                style="max-width: 1300px"
                class="flex justify-center align-center flex-col border-white border-8 lg:p-16 lg:w-10/12 md:10/12 w-12/12 md:w-auto text-center z-20 p-10"
            >
                <h1 class="text-white lg:text-3xl md:text-7xl text-4xl">
                    TACK FÖR DITT KÖP
                </h1>
                <message-display
                    v-for="confirmation in limitArray(confirmation, 2)"
                    v-bind:key="confirmation.message"
                    :confirmation="confirmation"
                />
            </div>
            <h2 class="text-white text-center lg:tracking-wider pt-4 z-20"></h2>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            confirmation: [
                {
                    id: 1,
                    message:
                        'Vi ses online den 11 november! Information och länk skickas till ditt mejl.',
                },
                {
                    id: 2,
                    message:
                        'Vi ser fram emot att ha dig med på plats den 11 november! Mer information skickas till ditt mejl.',
                },
            ],
        };
    },
    methods: {
        limitArray(arr, length = 8) {
            if (arr && arr.length) {
                if (length == -1) {
                    return arr;
                }
                if (length > arr.length) {
                    return arr;
                }

                return arr.slice(0, length);
            }

            return null;
        },
    },
};
</script>

<style scoped></style>
