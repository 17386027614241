<script setup>
import {computed, inject} from 'vue'

const emit = defineEmits(['close'])

const props = defineProps({
    styleType: {
        type: String,
        required: false,
        default: 'default'
    },
    closeOnClick: {
        type: Boolean,
        required: false,
        default: true,
    },
})

const size = inject('size')
const close = inject('close')

const styles = {
    'default': 'mf-button-text-default mf-button-border-default',
    'primary': 'mf-button-text-primary mf-button-border-primary',
    'secondary': 'mf-button-text-secondary mf-button-border-secondary',
    'success': 'mf-button-text-success mf-button-border-success',
    'warning': 'mf-button-text-warning mf-button-border-warning',
    'danger': 'mf-button-text-danger mf-button-border-danger',
    'disabled': 'mf-button-text-disabled mf-button-border-disabled',
    'transparent': 'mf-button-text-transparent mf-button-border-transparent',
}

const textSizes = {
    'sm': 'text-xs',
    'md': 'text-sm',
    'lg': 'text-base',
}

const paddings = {
    'sm': 'py-1 px-1.5',
    'md': 'py-1.5 px-2',
    'lg': 'py-2 px-3',
}

const textSize = computed(() => {
    return textSizes[size] ? textSizes[size] : textSizes['md']
})

const padding = computed(() => {
    return paddings[size] ? paddings[size] : paddings['md']
})

const itemClass = computed(() => {
    return [styles[props.styleType], textSize.value, padding.value].join(' ')
})

const maybeClose = () => {
    if (props.closeOnClick) {
        close()
    }
}
</script>

<template>
<li
    class="border-2 first:rounded-t-md last:rounded-b-md border-t-0 first:border-t-2 bg-white shadow-lg cursor-pointer"
    :class="itemClass"
    @click="maybeClose"
>
    <slot></slot>
</li>
</template>

<style scoped>

</style>
