<script setup>
import {computed} from "vue";
import MediaBankEntry from "./util/media-bank-entry.vue";

const props = defineProps({
    containerRef: {
        type: String,
        required: true
    },
    uuid: {
        type: String,
        require: true
    },
    content: {
        type: Object,
        required: true
    }
})

const quote = computed(() => props.content.quote);
const source = computed(() => props.content.source);
const horizontal = computed(() => props.content.horizontal);

const hasImage = computed(() => props.content.media_bank_entry_id !== null);

const quoteFontSizeClass = computed(() => {
  if (props.content.media_bank_entry_id) {
    return '';
  } else {
    return 'text-xl';
  }
})

const containerWidthClass = computed(() => {
    if( hasImage.value && props.content.horizontal){ return 'max-w-4xl' }
    if( hasImage.value ) { return 'max-w-2xl' }
    if( props.content.horizontal ) { return 'max-w-4xl' }
})

</script>
<template>
    <div :class="hasImage ? '' : 'pt-8'">
        <div
            class="w-auto mx-auto"
            :class="[horizontal ? 'flex flex-col-reverse lg:grid grid-cols-2 items-center' : 'flex flex-col-reverse items-center mx-auto', containerWidthClass]"
        >
            <div
                class="z-10 flex flex-col p-4 pb-6 md:p-6 quotecontainer rounded-custom shadow-custom"
                :class="[horizontal ? 'lg:mt-0 lg:-mr-8 justify-self-end lg:text-right w-11/12 lg:w-4/5' : 'w-11/12 lg:w-4/5']"
            >
                <div class="flex justify-start w-full">

                </div>
                <p
                    class="px-4 font-bold"
                    :class="quoteFontSizeClass"
                >
                    <span class="relative">
                        <strong class="absolute right-full bottom-0 text-xl opacity-70 translate-y-0.5">"</strong>
                    </span>{{quote}}<span class="relative">
                        <strong class="text-xl opacity-70 translate-y-0.5 left-full">"</strong>
                    </span>
                </p>
                <p
                    class="px-4 font-thin text-right"
                    :class="quoteFontSizeClass"
                >- {{source}}</p>
            </div>
            <div class="flex justify-center">
                <MediaBankEntry
                    v-if="hasImage"
                    :container-ref="containerRef"
                    :block-uuid="uuid"
                    :block-content="content"
                    :media-bank-entry-id="content.media_bank_entry_id"
                    property-name="media_bank_entry_id"
                ></MediaBankEntry>
            </div>
        </div>
    </div>
</template>
