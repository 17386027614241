<script setup>
    import {computed, ref} from "vue";

    const props = defineProps({
        item: {
            type: Object,
            required: true
        }
    });
    const emit = defineEmits(['itemUpdated', 'cancel'])
    const itemLabel = ref(props.item.label)
    const itemVisibility = ref(props.item.visibility)

    const visibilityOptions = [
        {
            label: _mft('navigation:setting.visibility.loggedOut'),
            value: 'logged_out'
        },
        {
            label: _mft('navigation:setting.visibility.loggedIn'),
            value: 'logged_in'
        }
    ]

    const cancel = () => {
        emit('cancel')
    }

    const save = () => {
        props.item.label = itemLabel.value
        props.item.visibility = itemVisibility
        emit('itemUpdated')
    }

</script>

<template>
    <div>
        <input-text
            v-model:content="itemLabel"
            :label="_mft('navigation:setting.title')"
        />
        <div class="mb-4 -mt-2">
            <a :href="item.href" target="_blank" class="text-wlPrimary underline">{{ _mft('navigation:setting.openInNewTab') }}</a>
        </div>
        <div class="flex justify-end space-x-2">
            <button-secondary
                @click="cancel"
            >{{ _mft('shared:cancel') }}</button-secondary>
            <button-success
                @click="save"
            >{{ _mft('shared:action.save') }}</button-success>
        </div>
    </div>
</template>
