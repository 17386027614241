<template>
    <card-basic>
        <template v-slot:header><h2>{{ _mft('crm:delivery.domainNamePlural') }}</h2></template>
        <template v-slot:body>

            <div class="lg:grid lg:grid-cols-2 lg:gap-8">
                <div class="mb-4">
                    <h3 class="text-gray-600">{{ _mft('crm:delivery.email.title') }}</h3>
                    <div v-for="(delivery, i) in emailDeliveries" v-bind:key="delivery.id">
                        <div
                            v-if="emailDeliveriesDisplayLimit === null || i < emailDeliveriesDisplayLimit"
                        >
                            <i v-if="delivery.opened" class="mr-2 text-sm fa-solid fa-envelope-open text-myflowPurple-900"></i>
                            <i v-else class="mr-2 text-sm fa-solid fa-envelope text-myflowPurple-900"></i>

                            <b>{{ _mft('crm:delivery.automation.label') }}: </b>
                            <a
                                :href="route('crm.communications.automations.show', delivery.automation_id)"
                                target="_blank"
                                :title="_mft('crm:delivery.toToAutomation')"
                            >
                                {{ delivery.automation_name }}
                            </a>
                            <br>
                            <b>{{ _mft('crm:delivery.sentAt') }}:</b> {{ delivery.sent_at }}<br>
                            <b>{{ _mft('crm:delivery.title') }}:</b> {{ delivery.subject }}<br>

                            <hr class="mt-4 mb-4">
                        </div>
                    </div>

                    <div v-if="emailDeliveries.length === 0">
                        <i>{{ _mft('crm:delivery.noEmailsSent') }}.</i>
                    </div>
                    <button-secondary
                        v-if="emailDeliveries.length > emailDeliveriesDisplayLimit && emailDeliveriesDisplayLimit !== null"
                        @click="emailDeliveriesDisplayLimit = null"
                    >
                        {{ _mft('shared:expand.showAll') }}
                    </button-secondary>
                </div>

                <div>
                    <h3 class="text-gray-600">{{ _mft('crm:delivery.sms.title') }}</h3>
                    <div v-for="delivery in smsDeliveries" v-bind:key="delivery.id">
                        <i class="mr-2 text-sm fa-solid fa-mobile text-myflowPurple-900"></i>

                        <b>{{ _mft('crm:delivery.automation.label') }}: </b>
                        <a
                            :href="route('crm.communications.automations.show', delivery.automation_id)"
                            target="_blank"
                            :title="_mft('crm:delivery.toToAutomation')"
                        >
                            {{ delivery.automation_name }}
                        </a>
                        <br>
                        <b>{{ _mft('crm:delivery.sentAt') }}:</b> {{ delivery.sent_at }}<br>

                        <hr class="mt-4 mb-4">
                    </div>

                    <div v-if="smsDeliveries.length === 0">
                        <i>{{ _mft('crm:delivery.noSmsSent') }}.</i>
                    </div>
                    <button-secondary
                        v-if="smsDeliveries.length > smsDeliveriesDisplayLimit && smsDeliveriesDisplayLimit !== null"
                        @click="smsDeliveriesDisplayLimit = null"
                    >
                        {{ _mft('shared:expand.showAll') }}
                    </button-secondary>
                </div>
            </div>
        </template>
    </card-basic>

</template>

<script>
export default {
    components: {
    },
    props: {
        smsDeliveries: Object,
        emailDeliveries: Object,
    },
    data: function(){
        return {
            smsDeliveries: null,
            emailDeliveries: null,
            emailDeliveriesDisplayLimit: 5,
            smsDeliveriesDisplayLimit: 5,
        }
    },
    methods: {
    },
    mounted: function(){
    },
}
</script>
