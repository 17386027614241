<template>
  <div
    class="shadow-xl bg-white mb-10 rounded-card overflow-hidden text-left md:text-center lg:text-left p-4 md:p-6 lg:p-8 pt-3 md:pt-5 lg:pt-6"
    :class="alignmentClass"
  >
    <div
      class="grid md:block lg:grid grid-cols-2 items-center gap-2 md:mb-2 lg:mb-0" 
      v-if="hasHeaderSlot"
    >
      <div class="mb-0 md:mb-2 lg:mb-0">
        <slot name="header-left"></slot>
      </div>
      <div>
        <slot name="header-right"></slot>
      </div>
    </div>
    <div class="grid md:block lg:grid grid-cols-2 items-center gap-2">
      <div class="mb-0 md:mb-2 lg:mb-0">
        <slot name="column-left"></slot>
      </div>
      <div>
        <slot name="column-right"></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    alignment: String
  },
  computed: {
    hasHeaderSlot () {
      return !!this.$slots['header-left'] && !!this.$slots['header-right'];
    },
    hasImageSlot () {
      return !!this.$slots.image
    },
    hasBodySlot () {
      return !!this.$slots.body
    },
    alignmentClass (){
      return this.alignment === 'center' ? 'text-center' : ''
    }
  },
  setup() {
    
  },
}
</script>