<script setup>
import { useNotificationStore } from '@/stores/NotificationStore.js'
import {computed, onMounted, reactive, ref} from "vue";

const notificationStore = useNotificationStore()
const currentCurrency = ref()
const allCurrencies = ref([])

const searchableCurrencies = computed(() => {
    return allCurrencies.value.filter(currency => {
        currency.compositename = currency.identifier.toLowerCase() + ' ' + currency.label.toLowerCase()
        currency.searchResultLabel = '<div class="w-full flex space-x-2 justify-between"><span>' + currency.label + '</span><span>' + currency.identifier + '</span></div>';
        return currency
    })
})

const currentCurrencyObject = computed(() => {
    return allCurrencies.value.find(currency => currency.value === currentCurrency.value)
})

const selectCurrency = async (currency) => {
    currentCurrency.value = currency.value

    const res = await axios.post(route('settings.currency.update'), {
        currency: currency.value
    })

    if (res.data.success) {
        notificationStore.addNotification({
            type: 'success',
            title: _mft('setting:currency.currencySaved.title'),
            message: _mft('setting:currency.currencySaved.message', {currency: currency.label}),
            dismissAfter: 5000,
        })
    }
}

onMounted(async () => {
    const res = await axios.get(route('settings.currency.show'))

    currentCurrency.value = res.data.current_currency
    allCurrencies.value = res.data.all_currencies
})

</script>

<template>
    <div>
        <h2>{{ _mft('setting:currency.title') }}</h2>
        <p class="mb-0">{{ _mft('setting:currency.selectedCurrency') }}: {{ currentCurrencyObject?.label }}</p>

        <input-searchable
            :searchableItems="searchableCurrencies"
            searchableField="compositename"
            labelField="searchResultLabel"
            :placeholder="_mft('setting:currency.searchCurrency')"
            v-model:content="currentCurrencyObject"
            @selected="selectCurrency"
        ></input-searchable>

    </div>
</template>

<style scoped>

</style>
