<template>
    <card-collapsible>
        <template v-slot:header>
            <div class="flex items-center">
                <h3 class="text-gray-900 truncate font-large">
                    Coaching
                </h3>
            </div>
        </template>
        <template v-slot:preview>
            Vilken skillnad myflow kan göra för dig som coachar
        </template>
        <template v-slot:body>
            <div class="mt-5 mb-5">
                <input-number
                    label="Vad tar du idag per coaching? Säljer du främst paket, beräkna det genomsnittliga priset per coachingtillfälle."
                    :content="pricePerCoaching"
                    v-model:content="pricePerCoaching"
                />
            </div>

            <div class="mt-5 mb-5">
                <input-number
                    label="Hur många coachingtillfällen genomför du per månad?"
                    :content="numberOfCoaching"
                    v-model:content="numberOfCoaching"
                />
            </div>

            <div class="mt-5 mb-5">
                <label class="text-gray-700">Vad skulle du uppskatta att ditt nya pris hade kunnat vara när du får en teknisk infrastruktur kring dina coachingar?</label>
                <div class="mt-3 mb-4 rounded-xl">
                    <nav class="flex rounded-xl">
                        <a :class="setClassesForNewPricePerCoaching(0)" v-on:click="setNewPricePerCoaching(0)">
                            <span>0 kr</span>
                        </a>

                        <a :class="setClassesForNewPricePerCoaching(100)" v-on:click="setNewPricePerCoaching(100)">
                            <span>+100 kr</span>
                        </a>

                        <a :class="setClassesForNewPricePerCoaching(200)" v-on:click="setNewPricePerCoaching(200)">
                            <span>+200 kr</span>
                        </a>

                        <a :class="setClassesForNewPricePerCoaching(300)" v-on:click="setNewPricePerCoaching(300)">
                            <span>+300 kr</span>
                        </a>

                        <a :class="setClassesForNewPricePerCoaching(400)" v-on:click="setNewPricePerCoaching(400)">
                            <span>+400 kr</span>
                        </a>

                        <a :class="setClassesForNewPricePerCoaching(500)" v-on:click="setNewPricePerCoaching(500)">
                            <span>+500 kr</span>
                        </a>
                    </nav>
                </div>
            </div>

            <div class="mt-5 mb-5">
                <label class="text-gray-700">Hur mycket tid lägger du på förberedelse och sammanställning i samband med ett coachingstillfälle?<br><small>T.ex. bokning, ombokning, betalning, inläsning av anteckningar, summering av anteckningar, delning av inspelning osv</small></label>
                <div class="mt-3 mb-4 rounded-xl">
                    <nav class="flex rounded-xl">
                        <a :class="setClassesTimePerCoaching(0)" v-on:click="setTimePerCoaching(0)">
                            <span><b>0</b><br>minuter</span>
                        </a>

                        <a :class="setClassesTimePerCoaching(0.25)" v-on:click="setTimePerCoaching(0.25)">
                            <span><b>15</b><br>minuter</span>
                        </a>

                        <a :class="setClassesTimePerCoaching(0.5)" v-on:click="setTimePerCoaching(0.5)">
                            <span><b>30</b><br>minuter</span>
                        </a>

                        <a :class="setClassesTimePerCoaching(0.75)" v-on:click="setTimePerCoaching(0.75)">
                            <span><b>45</b><br>minuter</span>
                        </a>

                        <a :class="setClassesTimePerCoaching(1)" v-on:click="setTimePerCoaching(1)">
                            <span><b>60</b><br>minuter</span>
                        </a>

                        <a :class="setClassesTimePerCoaching(1.5)" v-on:click="setTimePerCoaching(1.5)">
                            <span><b>90</b><br>minuter</span>
                        </a>
                    </nav>
                </div>
            </div>
        </template>
    </card-collapsible>

    <card-collapsible>
        <template v-slot:header>
            <div class="flex items-center">
                <h3 class="text-gray-900 truncate font-large">
                    Försäljning
                </h3>
            </div>
        </template>
        <template v-slot:preview>
            Hur myflow kan hjälpa dig öka och effektivisera dina försäljningsinsatser
        </template>
        <template v-slot:body>
            <div class="mt-5 mb-5">
                <input-number
                    label="Antal skickade offerter per månad idag"
                    :content="sentQuotes"
                    v-model:content="sentQuotes"
                />
            </div>


            <div class="mt-5 mb-5">
                <input-number
                    label="Snittvärde per offert"
                    :content="averageQuoteValue"
                    v-model:content="averageQuoteValue"
                />
            </div>


            <div class="mt-5 mb-5">
                <label class="text-gray-700">Hur lång tid tar det idag att skapa, skicka och signera en offert?</label>
                <div class="mt-3 mb-4 rounded-xl">
                    <nav class="flex rounded-xl">
                        <a :class="setClassesTimePerQuote(0)" v-on:click="setTimePerQuote(0)">
                            <span><b>0</b><br>minuter</span>
                        </a>

                        <a :class="setClassesTimePerQuote(0.25)" v-on:click="setTimePerQuote(0.25)">
                            <span><b>15</b><br>minuter</span>
                        </a>

                        <a :class="setClassesTimePerQuote(0.5)" v-on:click="setTimePerQuote(0.5)">
                            <span><b>30</b><br>minuter</span>
                        </a>

                        <a :class="setClassesTimePerQuote(0.75)" v-on:click="setTimePerQuote(0.75)">
                            <span><b>45</b><br>minuter</span>
                        </a>

                        <a :class="setClassesTimePerQuote(1)" v-on:click="setTimePerQuote(1)">
                            <span><b>60</b><br>minuter</span>
                        </a>

                        <a :class="setClassesTimePerQuote(1.5)" v-on:click="setTimePerQuote(1.5)">
                            <span><b>90</b><br>minuter</span>
                        </a>
                    </nav>
                </div>
            </div>


            <div class="mt-5 mb-5">
                <input-number
                    label="Hur många fler offerter skulle signeras om de paketerades på ett presentabelt sätt?"
                    :content="additionalQuotesClosed"
                    v-model:content="additionalQuotesClosed"
                />
            </div>

        </template>
    </card-collapsible>

    <card-collapsible>
        <template v-slot:header>
            <div class="flex items-center">
                <h3 class="truncate text-myflowGreen-900 font-large">
                    RESULTAT
                </h3>
            </div>
        </template>
        <template v-slot:preview>
            Vad kan egentligen myflow innebära för dig?
        </template>
        <template v-slot:body>

            <h5>Tidsbesparing</h5>
            <h1>{{ Math.round((coachingTimeSavings + quoteTimeSavings) / 20 * 10).toLocaleString('sv') / 10 }} timmar per dag</h1>
            <h1>{{ Math.round(coachingTimeSavings + quoteTimeSavings).toLocaleString('sv') }} timmar i månaden</h1>
            <h1>{{ Math.round((coachingTimeSavings + quoteTimeSavings) * 12).toLocaleString('sv') }} timmar per år</h1>

            <hr class="mt-5 mb-5">

            <h5>Direkt ökade intäkter per månad och år</h5>
            <h1>+{{ Math.round(coachingRevenueChange + quotesRevenueChange).toLocaleString('sv') }} kr i månaden</h1>
            <h1>+{{ Math.round((coachingRevenueChange + quotesRevenueChange) * 12).toLocaleString('sv') }} kr per år</h1>

            <hr class="mt-5 mb-5">

            <h5>Möjlig ökning från frigjord tid</h5>
            <h1>+{{ Math.round((coachingTimeSavings + quoteTimeSavings) * (this.newPricePerCoaching + this.pricePerCoaching)).toLocaleString('sv') }} kr i månaden</h1>
            <h1>+{{ Math.round(((coachingTimeSavings + quoteTimeSavings) * (this.newPricePerCoaching + this.pricePerCoaching)) * 12).toLocaleString('sv') }} kr per år</h1>

            <hr class="mt-5 mb-5">

            <h5>Skillnad i livet</h5>
            <h1>
                {{ Math.round(((coachingTimeSavings + quoteTimeSavings) * 12) / 40).toLocaleString('sv') }}
                veckor extra semester baserat på den besparade tiden, där du kan avnjuta din nyskapade intjäning motsvarande {{ Math.round(((coachingRevenueChange + quotesRevenueChange) * 12) / 52).toLocaleString('sv') }} kr per vecka.
            </h1>

            <hr class="mt-5 mb-5">

            <button type="button" class="w-full p-2 m-2 myflow-basic-button--success">Låt oss förändra min verksamhet!</button>
            <button type="button" class="w-full p-2 m-2 myflow-basic-button--danger">Avbryt - jag vill inte förändra min verksamhet</button>
        </template>
    </card-collapsible>

    <card-collapsible>
        <template v-slot:header>
            <div class="flex items-center">
                <h3 class="text-gray-900 truncate font-large">
                    Hur har vi räknat?
                </h3>
            </div>
        </template>
        <template v-slot:preview>
            Detaljerad uträkning
        </template>
        <template v-slot:body>
            <h5 class="mt-4">Coaching-admin</h5>
            <b>Tidigare:</b> {{ this.numberOfCoaching * this.timePerCoaching }} timmar per månad<br>
            <b>Nu:</b> {{ this.numberOfCoaching * 0.1 }} timmar per månad (6 minuter per coachingtillfälle)<br>

            <h5 class="mt-4">Offerter</h5>
            <b>Tidigare:</b> {{ this.sentQuotes * this.timePerQuote }} timmar per månad<br>
            <b>Nu:</b> {{ this.sentQuotes * 0.05 }} timmar per månad (3 minuter per offert vid skapande från mall)<br>

        </template>
    </card-collapsible>

</template>
<script>
import inputNumber from '../forms/inputNumber.vue';

export default {
    components: { inputNumber },
    data: function(){
        return {
            pricePerCoaching: 0,
            numberOfCoaching: 0,
            newPricePerCoaching: 0,
            timePerCoaching: 0,

            sentQuotes: 0,
            averageQuoteValue: 0,
            timePerQuote: 0,
            additionalQuotesClosed: 0,
        }
    },
    methods: {
        setNewPricePerCoaching(value) {
            this.newPricePerCoaching = value;
        },
        setClassesForNewPricePerCoaching(value) {
            if (value === this.newPricePerCoaching) {
                return 'relative flex-1 min-w-0 px-4 py-4 overflow-hidden shadow-xl text-sm font-bold text-center text-white bg-myflowPurple-500 hover:text-white group hover:bg-myflowPurple-600 focus:z-10 rounded-xl';
            } else {
                return 'relative flex-1 min-w-0 px-4 py-4 overflow-hidden text-sm font-medium text-center text-gray-500 hover:text-gray-700 group hover:bg-gray-50 focus:z-10 rounded-xl';
            }
        },
        setTimePerCoaching(value) {
            this.timePerCoaching = value;
        },
        setClassesTimePerCoaching(value) {
            if (value === this.timePerCoaching) {
                return 'relative flex-1 min-w-0 px-4 py-4 overflow-hidden shadow-xl text-sm font-bold text-center text-white bg-myflowPurple-500 hover:text-white group hover:bg-myflowPurple-600 focus:z-10 rounded-xl';
            } else {
                return 'relative flex-1 min-w-0 px-4 py-4 overflow-hidden text-sm font-medium text-center text-gray-500 hover:text-gray-700 group hover:bg-gray-50 focus:z-10 rounded-xl';
            }
        },
        setTimePerQuote(value) {
            this.timePerQuote = value;
        },
        setClassesTimePerQuote(value) {
            if (value === this.timePerQuote) {
                return 'relative flex-1 min-w-0 px-4 py-4 overflow-hidden shadow-xl text-sm font-bold text-center text-white bg-myflowPurple-500 hover:text-white group hover:bg-myflowPurple-600 focus:z-10 rounded-xl';
            } else {
                return 'relative flex-1 min-w-0 px-4 py-4 overflow-hidden text-sm font-medium text-center text-gray-500 hover:text-gray-700 group hover:bg-gray-50 focus:z-10 rounded-xl';
            }
        }
    },
    mounted: function(){
    },
    computed: {
        coachingTimeSavings() {
            return (this.numberOfCoaching * this.timePerCoaching) - (this.numberOfCoaching * 0.1);
        },
        quoteTimeSavings() {
            return (this.sentQuotes * this.timePerQuote) - (this.sentQuotes * 0.05);
        },

        coachingRevenueChange() {
            return (this.numberOfCoaching * (this.newPricePerCoaching + this.pricePerCoaching)) - (this.numberOfCoaching * this.pricePerCoaching);
        },
        quotesRevenueChange() {
            return (this.additionalQuotesClosed * this.averageQuoteValue);
        },
    },
    setup() {
    },
}
</script>
<style>
</style>