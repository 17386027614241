<script setup>
import { computed, reactive, onBeforeUnmount, onMounted } from 'vue'
import axios from 'axios';
import 'video.js/dist/video-js.css';
import 'videojs-record/dist/css/videojs.record.css';
import videojs from 'video.js';

import 'webrtc-adapter'
import Record from 'videojs-record/dist/videojs.record.js'

const emit = defineEmits(['videoTrimmed'])

onBeforeUnmount(() => {
    if (playerref) {
        playerref.dispose();
    }
})

const state = reactive({
    uploading: false,
    countdown: null,
    countdownInterval: null
})

let playerref = null;

const videostate = reactive({
    playing: false,
    recording: false,
    recorded: false,
    preparingTrimming: false,
    recordedDuration: 0,
    start: null,
    end: null,
    videoUuid: null,
    recordingname: '',
    options: {
        controls: true,
        autoplay: false,
        fluid: true,
        loop: false,
        bigPlayButton: false,
        progressControl: true,
        controlBar: {
            volumePanel: true,
            progressBar: true,
            fullscreenToggle: false
        },
        plugins: {
            record: {
                audio: true,
                video: {
                    width: { min: 640, ideal: 1920, max: 3840 },
                    height: { min: 480, ideal: 1080, max: 2160 }
                },
                debug: false,
                timeSlice: 1000,
                maxLength: 28800,
            }
        }
    }
})

const sendVideoJsRecorderData = (() => {
    let recordingData = playerref.recordedData;
    let formData = new FormData();

    const blob = new Blob([recordingData], { type: 'video/mp4' });
    formData.append('video', blob, recordingData.name);

    axios
        .post(route('browser-recording.store'), formData)
        .then((response) => {
            videostate.videoUuid = response.data.uuid;
            videostate.videoUrl = response.data.url;
        })
        .catch((error) => {
            console.error('Error uploading video:', error);
        });

    state.uploading = false;
})

const updateTrimOverlay = () => {
    const duration = playerref.duration();
    const startPercentage = (videostate.start / duration) * 100;
    const endPercentage = (videostate.end / duration) * 100;

    const progressBar = playerref.controlBar.progressControl.seekBar;
    const trimBar = progressBar.el_.querySelector('.vjs-trim-bar');

    if (!trimBar) {
        const bar = document.createElement('div');
        bar.className = 'vjs-trim-bar';
        progressBar.el_.appendChild(bar);
    }

    if (trimBar) {
        trimBar.style.left = `${startPercentage}%`;
        trimBar.style.width = `${endPercentage - startPercentage}%`;
    }
}

onMounted(() => {
    playerref = videojs('#videoRecorder', videostate.options, () => {});

    playerref.on('startRecord', () => {
        videostate.recorded = false;
        videostate.recording = true;
    });

    playerref.on('timestamp', () => {
        videostate.recordedDuration += 1000;
    });

    playerref.on('stopRecord', () => {
        // ugly hack to prevent inexplicable playback as soon as possible after finished recording
        setTimeout(function(){
            playerref.pause();
            setTimeout(function(){
                playerref.pause();
                setTimeout(function(){
                    playerref.pause();
                }, 200)
            }, 200)
        }, 100)
    })

    playerref.on('finishRecord', () => {
        videostate.preparingTrimming = true;
        videostate.recording = false;
        videostate.recorded = true;
        sendVideoJsRecorderData();

        // This is a hack to enable full length seeking
        const targetTime = (videostate.recordedDuration / 1000) - 2;
        setTimeout(function(){
            playerref.currentTime(targetTime);

        }, 1000);
        setTimeout(function(){
            jumpToStart();
            setTimeout(function(){
                videostate.preparingTrimming = false;
            }, 200)
        }, 1500)

        videostate.recordingname = _mft('videoRecorder:recordAndTrim.recording') + ' ' + new Date().toLocaleString();
    });

    playerref.on('error', (element, error) => {
        console.warn(error);
    });

    playerref.on('play', () => {
        videostate.playing = true;
    });

    playerref.on('pause', () => {
        videostate.playing = false;
    });

    playerref.on('deviceError', () => {
        console.error('device error:', playerref.deviceErrorCode);
    });

    playerref.record().getDevice();
})

const startCountdown = () => {
    state.countdown = 3;

    state.countdownInterval = setInterval(() => {
        state.countdown--;
        if (state.countdown === 0) {
            clearInterval(state.countdownInterval);
            startRecording();
        }
    }, 1000);
}

const togglePlayback = () => {
    if (playerref.paused()) {
        videostate.playing = true;
        playerref.play();
    } else {
        videostate.playing = false;
        playerref.pause();
    }
}

const jumpToStart = () => {
    playerref.currentTime(0);
    updateProgressBar();
}
const rewind = (seconds) => {
    const currentTime = playerref.currentTime();
    playerref.currentTime(currentTime - seconds);
}
const forward = (seconds) => {
    const currentTime = playerref.currentTime();
    playerref.currentTime(currentTime + seconds);
}
const jumpToEnd = () => {
    playerref.currentTime(playerref.duration());
    updateProgressBar();
}
const markStart = () => {
    videostate.start = parseInt(Math.floor(playerref.currentTime()), 10);
    updateTrimOverlay();
}
const markEnd = () => {
    const endTime = playerref.currentTime() <= playerref.duration() ? playerref.currentTime() : playerref.duration();
    videostate.end = parseInt(Math.ceil(endTime), 10);
    updateTrimOverlay();
    playerref.pause();
}
const jumpToTime = (time) => {
    playerref.currentTime(parseFloat(time));
    updateProgressBar();
}
const updateProgressBar = () => {
    setTimeout(function(){
        playerref.play();
        setTimeout(function(){
            playerref.pause();
        }, 50);
    }, 125);
}

const trimVideo = async () => {
    if (videostate.start === null) {
        videostate.start = 0;
    }
    if (videostate.end === null) {
        videostate.end = playerref.duration();
    }


    const formData = new FormData();
    formData.append('videoUuid', videostate.videoUuid);
    formData.append('start', videostate.start);
    formData.append('end', videostate.end);
    formData.append('name', videostate.recordingname)
    state.uploading = true

    try {
        const response = await axios.post(route('browser-recording.trim'), formData);
        state.uploading = false
        emit('recordingComplete', response.data);
        videostate.recorded = false
        playerref.reset()
        playerref.record().getDevice();
    } catch (error) {
        console.error("Error trimming the video:", error);
    }
}

const startRecording = () => {
    playerref.record().start()
}

const stopRecording = (e) => {
    e.preventDefault();
    playerref.record().stop()
    state.countdown = null;
}

const startTime = computed(() => {
    const time = Math.floor(videostate.start);
    const minutes = String(Math.floor(time / 60)).padStart(2, '0');
    const seconds = String(time % 60).padStart(2, '0');
    return `${minutes}:${seconds}`;
})

const endTime = computed(() => {
    const time = Math.floor(videostate.end);
    const minutes = String(Math.floor(time / 60)).padStart(2, '0');
    const seconds = String(time % 60).padStart(2, '0');
    return `${minutes}:${seconds}`;
})

</script>
<template>
    <div class="">
        <div class="px-12 md:px-0">
            <div
                class="relative overflow-hidden rounded-xl"
                :class="videostate.recorded ? 'video-recorded' : ''"
            >
                <div
                    class="absolute top-0 left-0 right-0 z-10 bottom-10"
                ></div>

                <div
                    v-if="state.countdown"
                    class="absolute inset-0 z-20 flex items-center justify-center bg-black/40"
                >
                    <span
                        class="text-center text-white drop-shadow-lg"
                    >
                        {{ _mft('videoRecorder:recordAndTrim.recordingStartsIn') }}<br>
                        <span class="text-4xl font-bold">
                            {{ state.countdown }}
                        </span>
                    </span>
                </div>

                <video
                    ref="video"
                    id="videoRecorder"
                    class="mx-auto video-js vjs-default-skin"
                    controls
                    autoPlay="true"
                    playsInline="true"
                ></video>
                <div
                    v-if="videostate.preparingTrimming"
                    class="absolute inset-0 z-40 flex flex-col items-center justify-center text-white bg-black"
                >
                    <mf-spinner-medium class="w-24 h-12"/>
                </div>

                <div>
                    <button
                        v-if="!videostate.recorded && !videostate.recording && !state.countdown"
                        class="absolute z-20 px-3 py-1 text-black -translate-x-1/2 rounded-md bottom-1/2 left-1/2 translate-y-1/4 bg-white/80"
                        @click="startCountdown"
                    >
                        {{ _mft('videoRecorder:recordAndTrim.startRecording') }}
                    </button>
                    <button
                        v-if="videostate.recording"
                        class="absolute z-40 px-2 py-1 text-xs text-white rounded-md bottom-1 right-1 bg-red-400/80"
                        @click="stopRecording"
                    >
                        {{ _mft('videoRecorder:recordAndTrim.stopRecording') }}
                    </button>
                </div>
            </div>
        </div>

        <div
            v-if="videostate.recorded && !videostate.preparingTrimming"
            class="relative z-30 flex justify-between mt-4 space-x-1 md:justify-center md:space-x-4"
        >
            <div class="flex flex-col items-center">
                <button
                    class="inline-block px-3 py-1 text-white border border-transparent rounded-full rounded-tl-none bg-myflowPurple"
                    @click.prevent="markStart"
                >
                    {{ _mft('videoRecorder:recordAndTrim.markTrimStart') }}
                </button>
                <button
                    v-if="videostate.start !== null"
                    class="mt-1 text-xs myflow-basic-button--tintless"
                    @click.prevent="jumpToTime(videostate.start)"
                >
                    {{ _mft('videoRecorder:recordAndTrim.jumpTo') }} {{ startTime }}
                </button>
            </div>
            <div
                class="mx-4 mt-1 space-x-2 xl:space-x-4"
            >
                <button
                    @click.prevent="jumpToStart()"
                    class="relative group"
                >
                    <i class="fa-solid fa-backward-fast"></i>
                    <div
                        class="absolute hidden px-1 py-px mb-1 text-xs text-white transform -translate-x-1/2 bg-gray-600 rounded-md group-hover:block bottom-full left-1/2 whitespace-nowrap"
                    >
                        {{ _mft('videoRecorder:recordAndTrim.jumpToStart') }}
                    </div>
                </button>
                <button
                    @click.prevent="rewind(1)"
                    class="relative group"
                >
                    <i class="fa-solid fa-backward"></i>
                    <div
                        class="absolute hidden px-1 py-px mb-1 text-xs text-white transform -translate-x-1/2 bg-gray-600 rounded-md group-hover:block bottom-full left-1/2 whitespace-nowrap"
                    >
                        {{ _mft('videoRecorder:recordAndTrim.reverseOneSecond') }}
                    </div>
                </button>
                <button
                    v-if="videostate.playing"
                    @click.prevent="togglePlayback"
                    class="w-4"
                >
                    <i class="fa-solid fa-pause"></i>
                </button>
                <button
                    v-else
                    @click.prevent="togglePlayback"
                    class="w-4"
                >
                    <i class="fa-solid fa-play"></i>
                </button>
                <button
                    @click.prevent="forward(1)"
                    class="relative group"
                >
                    <i class="fa-solid fa-forward"></i>
                    <div
                        class="absolute hidden px-1 py-px mb-1 text-xs text-white transform -translate-x-1/2 bg-gray-600 rounded-md group-hover:block bottom-full left-1/2 whitespace-nowrap"
                    >
                        {{ _mft('videoRecorder:recordAndTrim.forwardOneSecond') }}
                    </div>
                </button>
                <button
                    @click.prevent="jumpToEnd()"
                    class="relative group"
                >
                    <i class="fa-solid fa-forward-fast"></i>
                    <div
                        class="absolute hidden px-1 py-px mb-1 text-xs text-white transform -translate-x-1/2 bg-gray-600 rounded-md group-hover:block bottom-full left-1/2 whitespace-nowrap"
                    >
                        {{ _mft('videoRecorder:recordAndTrim.jumpToEnd') }}
                    </div>
                </button>
            </div>
            <div class="flex flex-col items-center">
                <button
                    class="inline-block px-3 py-1 text-white border border-transparent rounded-full rounded-tr-none bg-myflowPurple"
                    @click.prevent="markEnd"
                >
                    {{ _mft('videoRecorder:recordAndTrim.markTrimEnd') }}
                </button>
                <button
                    v-if="videostate.end !== null"
                    class="mt-1 text-xs myflow-basic-button--tintless"
                    @click.prevent="jumpToTime(videostate.end)"
                >
                    {{ _mft('videoRecorder:recordAndTrim.jumpTo') }} {{ endTime }}
                </button>
            </div>
        </div>

        <div
            v-if="videostate.recorded && !state.uploading && !videostate.preparingTrimming"
            class="flex flex-col justify-center max-w-sm mx-auto mt-4 md:flex-row md:space-x-4"
        >
            <input-text
                type="text"
                v-model:content="videostate.recordingname"
                :placeholder="_mft('videoRecorder:recordAndTrim.clipName.placeholder')"
                class="w-full"
            ></input-text>
            <button
                class="mb-3 whitespace-nowrap"
                :class="videostate.recordingname === '' ? 'myflow-basic-button--state-disabled' : 'myflow-basic-button--success'"
                :disabled="videostate.recordingname === ''"
                @click.prevent="trimVideo"
            >
                {{ _mft('videoRecorder:recordAndTrim.saveClip') }}
            </button>
        </div>

        <div v-if="state.uploading" class="text-center">
            <mf-spinner-medium class="w-24 h-12"/>
            <h5>{{ _mft('videoRecorder:recordAndTrim.uploading') }}</h5>
        </div>

    </div>
</template>
<style scoped>
:deep(.vjs-progress-holder.vjs-slider.vjs-slider-horizontal) {
    height:10px;
}

:deep(.video-js .vjs-progress-control) {
    display: none !important;
}

:deep(.video-recorded .video-js .vjs-progress-control) {
    display: flex !important;
    visibility: visible !important;
    opacity: 1 !important;
    width: 89%;
}

:deep(.vjs-trim-bar) {
    position: absolute;
    bottom: 0px;
    height: 100%;
    background-color: #dddddd;
    opacity: 0.8;
}

:deep(div.video-js > .vjs-progress-control.vjs-control) {
    display: none !important;
}

:deep(.vjs-play-control) {
    display: none !important;
}

:deep(.vjs-record-button) {
    display: none !important;
}

:deep(.vjs-time-control.vjs-time-divider) {
    padding-left: 0px;
    padding-right: 0px;
    min-width: 0px;
}
</style>
