<script setup>

import {computed, ref, watch} from "vue";

    const props = defineProps({
        content: {
            type: Object,
            required: false,
            default: {}
        },
        searchableItems: {
            type: Array,
            required: true
        },
        searchableField: {
            type: String,
            required: false,
            default: 'value'
        },
        labelField: {
            type: String,
            required: false,
            default: 'label'
        },
        placeholder: {
            type: String,
            required: false,
            default: ''
        }
    })

    const emit = defineEmits(['selected'])

    const searchTerm = ref('')
    const highlitItem = ref(null)

    const selectItem = (item) => {
        emit('selected', item)
        searchTerm.value = ''
    }

    watch(() => searchTerm.value, () => {
        highlitItem.value = null
    })

    const highlightNextItem = () => {
        if (!results.value.length) return

        if (!highlitItem.value) {
            highlitItem.value = results.value[0]
            return
        }

        const currentIndex = results.value.indexOf(highlitItem.value)

        if (currentIndex === results.value.length - 1) {
            highlitItem.value = results.value[0]
            return
        }

        highlitItem.value = results.value[currentIndex + 1]
    }

    const highlightPreviousItem = () => {
        if (!results.value.length) return

        if (!highlitItem.value) {
            highlitItem.value = results.value[results.value.length - 1]
            return
        }

        const currentIndex = results.value.indexOf(highlitItem.value)

        if (currentIndex === 0) {
            highlitItem.value = results.value[results.value.length - 1]
            return
        }

        highlitItem.value = results.value[currentIndex - 1]
    }

    const results = computed(() => {
        if (!searchTerm.value) return []

        return props.searchableItems.filter(item => {
            return item[props.searchableField].toLowerCase().includes(searchTerm.value.toLowerCase())
        })
    })

</script>

<template>
    <div class="mb-4">
        <input-text
            v-model:content="searchTerm"
            :placeholder="props.placeholder"
            @keydown.down="highlightNextItem"
            @keydown.up="highlightPreviousItem"
            @keyup.enter.prevent="selectItem(highlitItem)"
        ></input-text>
        <ul
            class="block w-full p-2 border rounded-b-md pt-6 -mt-8"
            v-if="results.length > 0"
        >
            <li
                v-for="(item, i) in results"
                :key="i"
                v-html="item[props.labelField]"
                class="cursor-pointer hover:text-gray-500 py-0.5 px-1"
                :class="[item === props.content ? 'font-bold' : '', item === highlitItem ? 'bg-gray-800 text-white rounded-md' : '']"
                @click="selectItem(item)"
            ></li>
        </ul>
    </div>
</template>

<style scoped>

</style>
