<script setup>
import { reactive, ref, onMounted, watch } from 'vue'
import Location from '../session/locationInput.vue';
import { useNotificationStore } from '@/stores/NotificationStore.js'
const notificationStore = useNotificationStore()

const props = defineProps({
    bookingId: {
        type: Number,
        required: true,
    },
})

const booking = ref({})
const sessionLocation = ref()

const loadBooking = async () => {
    const response = await axios.get(route('coaching.provider.session.edit', props.bookingId))

    booking.value = response.data
}

const updateSessionLocation = async () => {
    try {
        const response = await axios.put(
            route('coaching.provider.session.updateLocation', props.bookingId),
            {
                location: booking.value.session.booking.meeting_location,
                embed_meeting: booking.value.session.booking.embed_meeting,
            }
        )
    } catch(err) {
        notificationStore.addNotification({
            type: 'error',
            title: _mft('error:error.generalOccurred'),
            message: _mft('error:error.settingsTryAgain'),
            dismissAfter: 5000,
        })
        return;
    }
    notificationStore.addNotification({
        type: 'success',
        title: _mft('shared:save.success'),
        dismissAfter: 3000,
    })
}

watch(booking, (newVal, oldVal) => {
    if (oldVal.session) {
        updateSessionLocation()
    }
}, { deep: true });


onMounted(() => {
    loadBooking();
})

</script>
<template>
    <card-basic>
        <template v-slot:header>
            <h1>{{ _mft('coaching:session.settings') }}</h1>
        </template>
        <template v-slot:body>
            <div v-if="booking.session">
                <h3>{{ _mft('coaching:session.location') }}</h3>
                <div class="grid-cols-2 gap-16 md:grid">
                    <div>
                        <p>{{ _mft('coaching:session.location.changeHere') }}</p>
                    </div>
                    <div>
                        <Location
                            v-model:location="booking.session.booking.meeting_location"
                            v-model:embedMeetings="booking.session.booking.embed_meeting"
                        />
                    </div>
                </div>
            </div>
        </template>
    </card-basic>
</template>
