<script setup>
    import { reactive } from 'vue'

    const props = defineProps({
        strokeColors: {
            type: Array,
            default: () => ['#7814d7', '#9f9f9f']
        },
        fillColors: {
            type: Array,
            default: () => ['#ebdef8', '#9f9f9f']
        },
        showLegend: {
            type: Boolean,
            required: false,
            default: false
        },
        labels: {
            type: Array,
            required: false,
            default: ['Label One', 'Label Two', 'Label Three', 'Label Four', 'Label Five', 'Label Six']
        },
        series: {
            type: Array,
            required: false,
            default: [
                {
                    name: "Example Series 1",
                    data: [45, 52, 38, 24, 33, 10]
                },
                {
                    name: "Example Series 2",
                    data: [26, 21, 20, 6, 8, 15]
                }
            ]
        }
    })

    const options = reactive({
        chart: {
            toolbar: {
                show: false,
            },
        },
        legend: {
            show: props.showLegend,
            labels: {
                colors: props.strokeColors,
                useSeriesColors: true
            },
        },
        series: props.series,
        labels: props.labels,
        fill: {
            colors: props.fillColors
        },
        stroke: {
            colors: props.strokeColors
        },
        markers: {
            colors: props.strokeColors
        },
    })
</script>
<template>
    <apexchart type="radar" :options="options" :series="series"></apexchart>
</template>
