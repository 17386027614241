<template>
    <div
        class="flex justify-center w-full bg-center bg-cover align-center"
        :style="headerStyles"
    >
        <header
            class="relative flex flex-col items-center justify-center w-full h-full"
        >
            <div class="absolute inset-0 backdrop"></div>

            <div class="relative z-10 flex flex-col items-center justify-center p-4 header-text">
                <h1 class="text-4xl text-center md:text-5xl lg:text-6xl">{{ title }}</h1>
                <div class="flex flex-col items-center justify-end w-full p-6 space-y-4 font-medium text-md md:text-lg md:flex-col md:justify-center md:space-x-2 md:space-y-8">

                    <div v-for="(link, idx) in links" :key="idx" class="text-center">
                        <a
                            :href="link.href"
                            class="block px-4 py-2 text-center text-black border-2 border-white md:py-1 lg:p-4 bg-secondary rounded-custom"
                            v-if="link.href"
                        >
                            {{link.text}}
                        </a>
                        <span
                            class="px-4 py-2 mt-4 text-2xl text-center text-black md:py-1 lg:p-6"
                            v-if="!link.href"
                        >
                            {{link.text}}
                        </span>
                    </div>

                </div>

            </div>
        </header>
    </div>
</template>

<script>
export default {
    props:{
        title: { type: String, required: true },
        mediaBankEntryData: { type: Object, required: true },
        links:  { type: Array, required: true },
    },
    data() { return {}; },
    computed: {
        headerStyles(){
            if (!this.mediaBankEntryData.url || !(this.mediaBankEntryData.type == "image")) {
                throw "Unknown entry data format!";
            }

            if( this.mediaBankEntryData.url ){
                return { backgroundImage: 'url('+ this.mediaBankEntryData.url +')' }
            }
        }
    }
};
</script>

<style scoped>
    header {
        min-height: 100vw;
        padding-top: 10vw;
        padding-bottom: 10vw;
    }
    @media screen and (min-width: 640px) {
        header {
            min-height: 40vw;
            padding-top: 5vw;
            padding-bottom: 5vw;
        }
    }
</style>
