import { _mftkd, _mft } from '../i18n.js'

export default {
    install(app, options) {
        app.config.globalProperties._mftkd = (key, domain, args = {}) =>
            _mftkd(key, domain, args);

        app.config.globalProperties._mft = (domainKey, args = {}) =>
            _mft(domainKey, args);
    }
}
