<template>
  <div
    class="relative pb-8"
    :class="{'opacity-25' : working }"
  >
    <span class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
    <div class="relative flex space-x-3">
      <div>
        <span
          class="flex items-center justify-center w-8 h-8 rounded-full bg-myflowGreen-900 ring-8 ring-white"
          v-if="action.done == true"
          v-on:click="toggleItemDone"
        >
          <i class="text-white far fa-check-square"></i>
        </span>
        <span
          class="flex items-center justify-center w-8 h-8 transition-opacity bg-gray-400 rounded-full ring-8 ring-white"
          v-on:click="toggleItemDone"
          v-else
        >
          <i class="text-white far fa-square"></i>
        </span>
      </div>
      <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
        <div>
            <p
              class="text-sm text-gray-500"
              v-if="action.done == true"
            >{{ action.action }}</p>
            <p
              class="text-sm font-semibold text-gray-900"
              v-else
            >{{ action.action }}</p>
          <small class="text-gray-500"><b>{{ _mft('crm:leadActionItem.priority') }}:</b> {{ action.prio }}</small><br>
          <small class="text-gray-500"><b>{{ _mft('crm:leadActionItem.owner') }}:</b> {{ ownerData?.name }}</small>
        </div>
        <div class="text-sm text-right text-gray-500 whitespace-nowrap">
          <time class="block">{{ action.date }} </time>
          <div class="relative inline-block">
            <TrashIcon
              class="inline-block w-4 h-4 cursor-pointer"
              v-on:click="initDelete()"
            />
            <div
              class="absolute z-10 p-1 mr-2 text-xs bg-white rounded-md shadow-md -top-1/2 right-full"
              v-if="pendingDelete"
            >
              <p class="p-2">{{ _mft('shared:confirm.areYouSure') }}?</p>
              <div class="flex justify-between">
                <span
                  class="px-2 py-1 text-white bg-red-600 rounded-md cursor-pointer hover:bg-red-800"
                  v-on:click="confirmDelete()"
                >{{ _mft('shared:confirm.positive') }}</span>
                <span
                  class="px-2 py-1 text-white bg-gray-600 rounded-md cursor-pointer hover:bg-gray-800"
                  v-on:click="abortDelete()"
                >{{ _mft('shared:confirm.cancel') }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  TrashIcon
} from '@heroicons/vue/solid'

export default {
  components: {
    TrashIcon
  },
  props: {
    item: Object,
    owner: Object
  },
  data: function(){
    return {
      action: {},
      ownerData: {},
      working: false,
      pendingDelete: false
    }
  },
  mounted: function(){
    this.action = this.item
    this.ownerData = this.owner
  },
  methods: {
    abortDelete() {
      this.pendingDelete = false;
    },
    async confirmDelete() {
      this.pendingDelete = false;
      this.working = true
      const res = await axios.delete( route('crm.leads.action.delete', this.action.id))
      this.$emit('reloadItems')
    },
    initDelete() {
      this.pendingDelete = true;
    },
    async toggleItemDone(){
      this.working = true
      const res = await axios.get( route('crm.leads.action.toggleDone', this.action.id))
      this.action.done = res.data.done
      this.working = false
    },
  }
}
</script>
