<script setup>

import {onMounted, ref} from "vue";

const courses = ref([]);
const addingNewCourse = ref(false)
const addingNewCourseState = ref('')

const newCourse = ref({
    title: '',
    provider_id: null,
    description: ''
})

const loadCourses = async () => {
    const res = await axios.get(route('api.course.backoffice.index'));
    courses.value = res.data.courses
}

const saveNewCourse = async () => {
    addingNewCourseState.value = 'waiting'
    const res = await axios.post(route('api.course.backoffice.store'), newCourse.value);
    if (res.data.success) {
        addingNewCourse.value = false
        window.location.href = res.data.redirect_url
    } else {
        addingNewCourseState.value = 'error'
    }
}

onMounted(() => {
    loadCourses();
})

</script>
<template>
<div>
    <div class="md:sticky top-2 z-topbar">
        <frosted-bar>
            <div class="flex items-center justify-between space-x-4 f">
                <h1>{{ _mft('course:domainNamePlural') }}</h1>

                <div class="flex items-center space-x-4">
                    <story-lane-modal :module="'courses'"></story-lane-modal>

                    <button-primary
                        @click="addingNewCourse = true"
                    >
                        {{ _mft('course:newCourse') }}
                    </button-primary>
                </div>
            </div>
        </frosted-bar>
    </div>

    <list-basic class="mt-8">
        <list-basic-item
            v-for="course in courses"
            :key="course.id"
            :highlight-on-hover="true"
        >
            <template #status>
            </template>
            <template #title>
                <a
                    class="font-medium text-gray-900 truncate mf-underline"
                    :href="route('course.backoffice.edit', course.id) + '#chapters'"
                >
                    {{ course.title }}
                </a>
            </template>
            <template #actions>
                <a
                    class="font-medium text-gray-900 truncate mf-underline"
                    :href="route('course.backoffice.edit', course.id) + '#chapters'"
                >
                    <button-secondary
                        size="sm"
                        class="mb-0.5"
                    >
                        {{ _mft('shared:action.edit') }}
                    </button-secondary>
                </a>
            </template>
        </list-basic-item>
    </list-basic>

    <slideout-with-slots
        v-if="addingNewCourse"
        @close="addingNewCourse = false"
        :title="_mft('course:newCourse')"
    >
        <template #actions>
            <div class="mb-2 flex justify-end space-x-2">
                <button-secondary
                    @click="addingNewCourse = false"
                >
                    {{ _mft('shared:cancel') }}
                </button-secondary>
                <button-success
                    @click="saveNewCourse"
                >
                    {{ _mft('shared:action.save') }}
                </button-success>
            </div>
        </template>
        <template #body>
            <input-text
                v-model:content="newCourse.title"
                :label="_mft('course:input.title')"
                autofocus
            ></input-text>

            <input-editor
                v-model:content="newCourse.description"
                :label="_mft('course:input.description')"
            ></input-editor>
        </template>
    </slideout-with-slots>

</div>
</template>
