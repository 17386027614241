<script setup>
import axios from "axios";
import { ref, onMounted, reactive, computed } from "vue";

const experts = ref(null);
const categories = ref(null);

const state = reactive({
    loading: false,
    active_categories: [],
    selectedCategories: [],
    optionBaseClasses: 'py-1 lg:py-1 bg-white border-r last:border-r-0',
    optionSelectedClasses: 'bg-myflowPurple shadow-none text-white bg-gradient-to-b from-myflowPurple-500 to-myflowPurple-700',
    optionNotSelectedClasses: 'text-myflowPurple shadow-md',
    optionLabelClasses: 'block text-center text-xs',
});

const categoryAvailableClass = computed(() => {
    if( state.loading ) {
        return 'cursor-wait';
    } else {
        return 'cursor-pointer';
    }
});

onMounted(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const params = urlParams.get('categories');
    if( params ){
        params.split(',').forEach((id) => {
            state.selectedCategories.push(parseInt(id));
        });
    }
    loadExperts();
});

const toggleCategory = (category) => {
    if (state.selectedCategories.indexOf(category.id) === -1) {
        if( state.active_categories.filter(c => c.id === category.id).length > 0) {
            state.selectedCategories.push(category.id);
        }
    } else {
        state.selectedCategories.splice(state.selectedCategories.indexOf(category.id), 1);
    }

    if( state.selectedCategories.length === 0 ){
        window.history.replaceState( {} , null, window.location.href.split('?')[0]);
    } else {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('categories', state.selectedCategories.join(','));
        window.history.replaceState( {} , null, window.location.href.split('?')[0] + '?' + urlParams );
    }


    loadExperts();
}
</script>
<template>
    <div>
        <div
            class="flex flex-wrap w-full mb-8"
        >
            <div
                v-for="(category, i) in categories"
                v-bind:key="i"
                :class="state.active_categories.filter(c => c.id === category.id).length > 0 ? categoryAvailableClass : 'opacity-20 cursor-default'"
                v-on:click="toggleCategory(category)"
            >
                <div class="m-2 text-center rounded-full">
                    <div
                        class="px-4 rounded-full lg:px-8"
                        :class="[ state.selectedCategories.includes(category.id) ? state.optionSelectedClasses : state.optionNotSelectedClasses, state.optionBaseClasses ]"
                    >
                        <div
                            class="relative text-center transition-all"
                        >
                            <span :class="state.optionLabelClasses" class="text-base select-none">
                                {{ category.name }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="grid-cols-2 gap-8 md:grid xl:grid-cols-3 2xl:grid-cols-4">
            <template v-if="experts">
                <expert-card
                    v-for="(expert, i) in experts"
                    v-bind:key="i"
                    v-bind:expert="expert"
                ></expert-card>
            </template>
        </div>
        <div class="z-50" id="trailer-player-teleport-target"></div>
    </div>
</template>
