<script setup>
import {nextTick, onMounted, reactive, ref, watch} from 'vue'
    import Signee from './signature-requests/signee.vue'
    import AddSignee from './signature-requests/addSignee.vue'

    const emit = defineEmits(['doRefresh', 'preferencesUpdated'])

    const props = defineProps({
        expertContractId: {
            type: Number,
            required: true
        },
        selectedWorkflow: {
            type: Object,
            required: false,
            default: null,
        }
    })

    const state = reactive({
        loading: true,
    })

    const signatureRequests = ref([])
    const canAddMore = ref(true)
    const expertProLink = ref(null)
    const currentUserIsRecipient = ref(false)
    const enabledWorkflowName = ref(null)

    const preferences = ref({})
    const conditions = ref({})
    const availableSignatureMethods = ref({})

    const loadPreferences = async () => {
        state.loading = true
        const response = await axios.get(route('expert-contract-preferences.show', props.expertContractId))
        preferences.value = response.data.preferences
        conditions.value = response.data.conditions
        availableSignatureMethods.value = response.data.available_signature_methods

        await nextTick(() => {
            state.loading = false
        })
    }

    const updatePreferences = async () => {
        const response = await axios.post(route('expert-contract-preferences.update', props.expertContractId), {
            messages_enabled: preferences.value.messages_enabled,
        })
        emit('preferencesUpdated', preferences.value)
    }

    const loadSignatureRequests = async () => {
        state.loading = true
        const response = await axios.get(route('expert-contracts.signature-requests.index', props.expertContractId))

        signatureRequests.value = response.data.signature_requests
        canAddMore.value = response.data.can_add_more
        expertProLink.value = response.data.expert_pro_link
        currentUserIsRecipient.value = response.data.current_user_is_recipient
        enabledWorkflowName.value = response.data.enabled_workflow_name
        state.loading = false
    }

    const reload = () => {
        loadPreferences()
    }

    const reloadSignatureRequests = () => {
        emit('doRefresh')
        loadSignatureRequests()
    }

    onMounted(() => {
        loadSignatureRequests()
        loadPreferences()
    })

    watch(preferences, () => {
        if (!state.loading) {
            updatePreferences()
        }
    }, { deep: true })

    defineExpose({
        reload
    })

</script>
<template>
    <div v-if="signatureRequests">
        <card-basic>
            <template v-slot:body>
                <div v-if="!conditions.has_signed_signature_requests">
                    <h5>{{ _mft('expertContract:preferences.messages.title') }}</h5>
                    <toggle-switch
                        name="messages_enabled"
                        label="expertContract:preferences.messages.label"
                        v-model:content="preferences.messages_enabled"
                        required
                    ></toggle-switch>
                </div>

                <mf-spinner-medium v-if="state.loading" class="w-12 h-6"></mf-spinner-medium>

                <div v-else>
                    <ul v-if="signatureRequests.length > 0">
                        <li
                            v-for="(signatureRequest, index) in signatureRequests"
                        >
                            <Signee
                                :signature-request="signatureRequest"
                                :expert-contract-id="props.expertContractId"
                                @reloadSignatureRequests="reloadSignatureRequests"
                            />
                        </li>
                    </ul>
                    <p v-else>
                        {{ _mft('expertContract:signatureRequest.noRecipients') }}
                    </p>
                </div>

                <div class="flex justify-end mt-4 space-x-4">
                    <div v-if="canAddMore">
                        <AddSignee
                            :expert-contract-id="props.expertContractId"
                            :current-user-is-recipient="currentUserIsRecipient"
                            :active-workflow="enabledWorkflowName !== null"
                            :active-workflow-name="enabledWorkflowName"
                            @reloadSignatureRequests="reloadSignatureRequests"
                        />
                    </div>
                    <div v-else>
                        <p>{{ _mft('expertContract:missingPro.toAddMoreSignatureRequests') }} <a class="underline" :href="expertProLink">{{ _mft('expertContract:missingPro.callToAction') }} &rarr;</a></p>
                    </div>
                </div>
            </template>
        </card-basic>
    </div>
</template>
