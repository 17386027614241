<template>
    <axios-form method="POST" :action="submitUrl" @success="onSuccess" @failure="onFailure" ref="leadform">
        <label>{{ _mft('expertContract:signatureRequest.create.recipient.name') }}*</label>
        <input class="myflow-basic-input" type="search" autocomplete="off" name="signee_search" required v-model="signee_name" @input="searching = (signee_name.length > 0)">
        <input type="hidden" name="signee_name" :value="signee_name">
        <input type="hidden" name="send_immediately" :value="send_immediately">

        <div v-if="leads && searching" class="mt-2 mb-3 px-2 max-h-[8rem] overflow-y-auto">
            <h6 class="font-bold">{{ _mft('expertContract:signatureRequest.create.recipient.loadFromContact') }}:</h6>
            <ul>
                <li
                    v-for="lead in filteredLeads"
                    :key="lead.id"
                    class="px-1 my-1 bg-gray-200 rounded cursor-pointer"
                    @click.prevent="populateFromLead(lead); searching = false"
                >{{lead.nameAndCompany}}</li>
            </ul>
        </div>

        <label>{{ _mft('expertContract:signatureRequest.create.recipient.email') }}</label><br>
        <input class="myflow-basic-input" type="email" name="signee_email" v-model="signee_email">

        <div v-if="expertProActive === true">
            <label>{{ _mft('expertContract:signatureRequest.create.recipient.phone') }}</label><br>
            <small>{{ _mft('expertContract:signatureRequest.create.recipient.phoneByline') }}.</small>
            <input class="myflow-basic-input" type="tel" name="signee_phone" v-model="signee_phone">
        </div>

        <p
            v-if="activeWorkflow"
            class="italic whitespace-normal"
            v-html="_mft('expertContract:signatureRequest.create.activeWorkflow', {workflowName: activeWorkflowName})"
        >
        </p>
        <p v-else>
            {{ _mft('expertContract:signatureRequest.create.noWorkflowActive') }}
        </p>

        <div class="justify-end lg:flex lg:space-x-2">
            <button-success
                v-if="!activeWorkflow"
                :disabled="!contactMethodsValidate"
                @click="submitAndSend"
            >
                {{ _mft('expertContract:signatureRequest.create.addRecipientAndSend') }}
            </button-success>

            <button-primary
                :disabled="!contactMethodsValidate"
                type="submit"
                class="float-right"
            >
                {{ _mft('expertContract:signatureRequest.create.addRecipient') }}
            </button-primary>
        </div>
    </axios-form>
</template>

<script>
import axios from 'axios';

export default {
    props: {
        expertContractId: {
            type: Number,
            required: true
        },
        expertProActive: {
            type: Boolean,
            required: false,
            default: false
        },
        activeWorkflow: {
            type: Boolean,
            required: false,
            default: false
        },
        activeWorkflowName: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            leads: null,
            searching: false,
            signee_name: '',
            signee_email: '',
            signee_phone: '',
            send_immediately: false
        };
    },
    computed: {
        submitUrl() {
            return route('expert-contracts.signature-requests', this.expertContractId);
        },
        filteredLeads() {
            return this.leads?.filter((lead) => lead.nameAndCompany.toLowerCase().indexOf(this.signee_name.toLowerCase()) !== -1);
        },
        contactMethodsValidate() {
            const emailIsValid = this.signee_email.length > 0 && this.signee_email.includes('@') && this.signee_email.includes('.');
            if (this.signee_name.length > 0 && (emailIsValid || this.signee_phone.length > 0)) {
                return true;
            }
        }
    },
    async mounted() {
        const response = await axios.get(route('crm.leadsContractSearch'));
        this.leads = response.data.map((lead) => ({
            ...lead,
            nameAndCompany: lead.company ? `${lead.name} - ${lead.company}` : lead.name
        }));
    },
    methods: {
        onSuccess(event) {
            this.$emit('reloadSignatureRequests');
        },
        onFailure(err) {
            this.$page.alert(_mft('error:error.generalOccurred'));
        },
        populateFromLead(lead) {
            this.signee_name = lead.name;
            this.signee_phone = lead.phone;
            this.signee_email = lead.email;
        },
        submitAndSend(e) {
            e.preventDefault()
            if (!this.contactMethodsValidate) {
                return;
            }

            this.send_immediately = true
            this.$nextTick(() => {
                this.$refs.leadform.submit();
            })
        }
    }
};
</script>
