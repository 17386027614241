<script setup>

import { ref, onMounted } from 'vue'

const open = ref(false)

</script>

<template>
    <div class="col-span-4 bg-white shadow-xl rounded-wl card-highlight">
        <div class="flex flex-col items-center lg:grid lg:grid-cols-5 xl:grid-cols-3 lg:gap-8 lg:flex-row lg:items-stretch">
            <div class="flex justify-center col-span-1 lg:col-span-2 xl:col-span-1 lg:justify-start">
                <img
                    src="https://ohk.fra1.cdn.digitaloceanspaces.com/temp/BLACK_DEAL_PRO.png"
                    class="object-cover w-full h-full shadow-xl rounded-xl"
                    alt="Black Deal Pro"
                />
            </div>
            <div class="flex flex-col justify-center col-span-2 p-12 lg:col-span-3 xl:col-span-2 lg:pl-0">
                <h1 class="mb-5">45% discount. <span class="tapered">Forever.</span></h1>
                <p class="mb-5 text-base font-thin lg:text-lg">Black Friday, Black Week, Black Month. Forget all that. Introducing... Black Deal. Your smartest deal ever.</p>
                <p class="mb-5 text-base font-thin lg:text-lg"><b>Get 45% off on myflow Pro</b> every year, from now and forever. No matter how much prices go up.</p>

                <div class="flex flex-col items-center xl:block">
                    <a
                        :href="route('settings.company.settings')"
                        class="block w-full mt-4 md:w-auto md:inline-block"
                    >
                        <button-success
                            size="lg"
                            class="justify-center w-full md:w-auto"
                            shimmer
                        >
                            Lock the reduced price
                        </button-success>
                    </a>

                    <p class="mt-3 text-center text-gray-400 md:text-left">
                        <small class="font-thin" v-on:click="open = true">
                            Why 45%? Because it's a sweet number. Click <span class="underline cursor-pointer">here</span> to see why.
                        </small>
                    </p>
                </div>
            </div>
        </div>
    </div>


    <modal-basic
        :open="open"
        @closed="open = false"
    >
        <template #header>
            <div class="text-center">
                Buckle up, these are some nerdy facts about the number 45 and why we chose it.
            </div>
        </template>
        <template #body>
            <ul>
                <li>The Roman numeral for 45 is XLV. It’s an <em>extra-large victory</em> for your business!</li>
                <li>In the periodic table, 45 is the atomic number of rhodium, one of the rarest and most valuable metals. Just like your unique business potential with myflow Pro!</li>
                <li>45 is a <em>triangular number</em>, meaning it’s the sum of the numbers 1 through 9. We’re here to help you bring all your business elements into perfect harmony!</li>
                <li>In binary, 45 is represented as <em>101101</em>. It’s a nod to the tech power myflow Pro delivers to your business.</li>
                <li>The factors of 45 are 1, 3, 5, 9, 15, and 45. With myflow, you won’t need to split your focus—we streamline it all!</li>
                <li>In music, a <em>45 RPM record</em> symbolizes timeless quality. That’s exactly the kind of value we aim to bring to your business!</li>
                <li>The <em>45th parallel</em> marks halfway between the equator and the North Pole. Similarly, myflow Pro helps you find the perfect balance in your workflow.</li>
                <li>Basketball fans will know that the <em>45-second shot clock</em> pushes players to make quick, strategic moves. With myflow, you’ll always stay ahead of the game!</li>
            </ul>

            <a :href="route('settings.company.settings')" class="w-full mt-4 text-center">
                <button-success class="w-full mt-4 text-center">
                    I agree that 45 is a sweet number, let's lock the reduced price!
                </button-success>
            </a>
        </template>
    </modal-basic>
</template>

<style scoped>
.tapered {
    background-image: linear-gradient(181deg, #9166a0 0%, #622578 50%, transparent 54%, transparent 100%);
}

span {
    background-size: 100% 17%;
    background-repeat: repeat-x;
    background-position: left 0% bottom -1%;
}

ul {
    list-style-type: circle;
    padding: 0;
}

li {
    padding: 5px 0;
}

/* .highlight {
    background-color: #f5f0ff;
    padding: 2px 4px;
    border-radius: 4px;
    font-weight: bold;
    color: #622578;
} */

</style>
