<script setup>

</script>

<template>
    <button
        class="relative h-12 w-12 overflow-hidden"
    >
        <div class="absolute right-0 left-0 flex h-12 w-12 flex-col items-center justify-center">
            <span class="block w-4/5 rounded-md bg-wlPrimary"></span>
            <span class="block w-4/5 rounded-md bg-wlPrimary"></span>
            <span class="block w-4/5 rounded-md bg-wlPrimary"></span>
        </div>
        <div class="absolute right-0 left-0 flex h-12 w-12 flex-col items-center justify-center">
            <svg
                aria-hidden="true"
                class="h-10 w-10 rotate-180 fill-wlPrimary"
                viewBox="0 0 320 512" xmlns="http://www.w3.org/2000/svg"
            >
                <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path>
            </svg>
        </div>
    </button>
</template>

<style scoped>
button span {
    width: 70%;
    height: 3px;
    display: block;
    background-color: var(--whitelabel-primary);
    transition: all 150ms;
}
button > div:nth-child(1) {
    transition: all 350ms;
    top: 0;
}
button > div:nth-child(1) > span {
    margin: 3px 0;
    transition: all 400ms;
}
button > div:nth-child(2) {
    transition: all 150ms 100ms;
    bottom: 100%;
    opacity: 0;
}
button.active > div:nth-child(1) {
    top: 100%;
    opacity: 0;
}
button.active > div:nth-child(1) > span {
    transform: scaleY(300%);
    opacity: 0
}
button.active > div:nth-child(2) {
    bottom: 0;
    opacity: 1;
}
</style>
