<script setup>

import {onMounted, ref, watch} from "vue";

    const emit = defineEmits(['update:content'])

    const accountCreationEnabled = ref(false)
    const policiesInPlace = ref(false)

    const loadPolicies = async () => {
        const res = await axios.get(route('settings.account-creation-enabled.show'))

        accountCreationEnabled.value = res.data.accountCreationEnabled
        policiesInPlace.value = res.data.policiesExist
    }

    const storePolicy = async () => {
        const res = await axios.post(route('settings.account-creation-enabled.update'), {
            account_creation_enabled: accountCreationEnabled.value
        })
    }

    const goToTab = (tab) => {
        window.location.href = route('settings.policies.show') + '#' + tab
        setTimeout(function(){
            window.location.reload()
        }, 250)
    }

    watch(accountCreationEnabled, () => {
        storePolicy()
        emit('update:content', accountCreationEnabled.value)
    })

    onMounted(() => {
        loadPolicies()
    })
</script>

<template>
    <div>
        <ui-attention
            v-if="!policiesInPlace"
            class="text-center mb-4"
        >
            <p class="font-bold">
                {{ _mft('setting:policies.toggleRegistration.policyRequired') }}
            </p>
            <div class="flex justify-center space-x-2">
                <button-secondary @click="goToTab('integrity-policy')">{{ _mft('auth:terms.integrityPolicy') }}</button-secondary>
                <button-secondary @click="goToTab('terms-of-use')">{{ _mft('auth:terms.termsOfService') }}</button-secondary>
            </div>
        </ui-attention>

        <p>
            {{ _mft('setting:policies.toggleRegistration.intro') }}
        </p>
        <toggle-switch
            name="account-creation-enabled"
            :disabled="!policiesInPlace"
            :label="_mft('setting:policies.toggleRegistration.label')"
            v-model:content="accountCreationEnabled"
        ></toggle-switch>
    </div>
</template>

<style scoped>

</style>
