<template>
    <div class="mb-12">
        <div class="relative z-10 p-4 pb-0 bg-white shadow-md rounded-2xl">
            <div class="items-center mb-4 space-x-4 lg:flex">
                <div
                    v-if="searchable && searchable.length > 0"
                    class="relative w-full mb-4 rounded-md shadow-sm"
                >
                    <input-text
                        placeholder="Sök"
                        label=""
                        :required="false"
                        :content="searchPhrase"
                        name="searchPhrase"
                        v-model:content="searchPhrase"
                        class="mb-0"
                    />

                    <button
                        class="absolute flex items-center justify-center px-2 text-white rounded-md top-2 right-1 bottom-1 bg-myflowPurple focus:bg-myflowMagenta"
                        v-if="searchPhrase"
                        @click="clearSearchPhrase"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>

                </div>
                <div v-if="pageSizeOptions.length > 1" class="flex-shrink-0 mb-4">
                    {{ _mft('shared:pagination.show') }}
                    <select
                        class="my-1 bg-transparent rounded-md"
                        v-model="pageSize"
                    >
                        <option
                            v-for="val in pageSizeOptions"
                            :key="val"
                            :value="val"
                        >
                            {{val}}
                        </option>
                    </select>
                    {{ _mft('shared:pagination.perPage') }}
                </div>
            </div>
            <div
                class="flex"
                :class="[filters.length > 1 ? 'flex-col lg:flex-row justify-between lg:items-start' : 'flex-row justify-between items-center' ]"
            >
                <div v-if="filters && filters.length > 0" class="grid items-start gap-2 mb-4 lg:grid-cols-2">
                    <div v-for="(filter, index) in filters" :key="index" class="p-2">
                        <b>{{ filter.label }}</b><br>
                        <toggle-button
                            v-for="(filter, index) in filter.items"
                            :key="index"
                            :label="filter.label"
                            :state="isFilterEnabled(filter)"
                            @click.prevent="toggleFilter(filter)"
                            class="px-2 py-1 m-1 text-xs"
                        />
                    </div>

                </div>
                <button
                    class="flex items-center justify-center rounded-md text-myflowPurple focus:text-myflowMagenta"
                    v-if="enabledFilters.length > 0"
                    @click.prevent="clearFilters"
                >
                    {{ _mft('shared:filter.clearAllFilters') }}
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>

            <h5 class="pb-4 italic font-normal text-center text-gray-500">
                {{ _mft('shared:filter.nResults', {count: filteredResultsCount }) }} 
                <span v-if="loading" class="italic"><br>{{ _mft('shared:filter.loading') }}</span></h5>
        </div>

        <div
        class="pt-5 -mt-4 overflow-x-scroll bg-white shadow-lg rounded-b-2xl"
        >
            <VTable
                :data="data"
                :page-size="pageSize"
                :filters="activeFilters"
                v-model:currentPage="currentPage"
                @totalPagesChanged="onTotalPageChange($event)"
                class="min-w-full divide-y divide-gray-200"
            >
                <template #head>
                    <tr class="">
                        <template
                            v-for="(col, index) in columns"
                                :key="index"
                        >
                            <VTh v-if="col.sort" :key="col.key" :sort-key="col.sort" scope="col" :class="thClasses(col)">
                                <i
                                    v-if="col.key === 'select'"
                                    class="text-2xl text-gray-600 cursor-pointer fa-regular fa-square ml-4"
                                    @click="emitSelectAll"
                                ></i>
                                <span v-else>
                                    {{ col.label }}
                                </span>
                            </VTh>
                            <th v-else :key="col.key" scope="col" :class="thClasses(col)">
                                {{ col.label }}
                            </th>
                        </template>
                        <th v-if="$slots.actions" scope="col"></th>
                    </tr>
                </template>
                <template #body="{ rows }">
                    <tr
                        v-for="row in rows"
                        :key="row.id"
                        class="evenodd"
                    >
                        <td v-for="col in columns" :key="col.key" :class="tdClasses(col)">
                            <slot :name="`column-${col.key}`" :row="row">{{row[col.key]}}</slot>
                        </td>
                        <td v-if="$slots.actions" class="px-6 py-4 whitespace-nowrap">
                            <slot name="actions" :row="row"></slot>
                        </td>
                    </tr>
                </template>
            </VTable>

            <VTPagination
                v-if="totalPages > 0"
                v-model:currentPage="currentPage"
                :total-pages="totalPages"
                :hide-single-page="false"
                class="flex justify-center py-3"
            />
        </div>
    </div>
</template>

<style lang="scss" scoped>
th.v-th:deep(div) {
    @apply flex flex-row justify-between items-center;
}

.vt-pagination:deep(ul) {
    @apply flex flex-row justify-center items-center space-x-3;
}

.vt-pagination:deep(ul .disabled) {
    @apply opacity-50 cursor-not-allowed;
}

.vt-pagination:deep(ul .active) {
    @apply font-bold;
}

.evenodd:nth-child(odd){
    @apply bg-gray-100;
}

</style>

<script setup>
import { ref, unref, toRefs, computed } from 'vue';

const emit = defineEmits(['selectAll', 'filtersChanged']);

const props = defineProps({
    // Array of objects, could contain pretty much anything
    data: { type: Array, required: true },
    // Array of { label: String, key: String, sort: String?, desktop: Boolean? }
    // Sort can also be used to provide a sort key function as described in https://vue-smart-table.netlify.app/sorting.html#sort-key
    columns: { type: Array, required: true },
    // Array of string, each string representing a key in data that are included in search
    searchable: { type: Array, required: false },
    // Array of objects, each object representing a search filter, e.g:
    //   - { label: "Visa enbart aktiva", filter: { custom: (_v, row) => row.active } }
    //   - { label: "Name = test", filter: { value: 'test', keys: ['name'], exact: true } },
    //   - { label: "Name ~ test", filter: { value: 'test', keys: ['name'] } },
    //   - { label: "lower(title) = cto", filter: { value: 'cto', custom: (value, row) => row.title.toLowerCase() == value } },
    filters: { type: Array, required: false },
    // Number, default page size. Should be included in pageSizeOptions
    pageSize: { type: Number, default: 20 },
    // Array of number, options available for page size
    pageSizeOptions: { type: Array, default: [ 20, 50, 100, 200, 500 ] },
    loading: { type: Boolean, default: false }
});

const { data, columns, searchable, filters, pageSizeOptions } = toRefs(props);
// we need to use the scalar to create a mutable ref for this one
const pageSize = ref(props.pageSize);

const enabledFilters = ref([]);
const isFilterEnabled = (filter) => enabledFilters.value.indexOf(filter) !== -1;
const toggleFilter = (filter) => {
    const index = enabledFilters.value.indexOf(filter);
    if (index === -1) {
        enabledFilters.value.push(filter);
    } else {
        enabledFilters.value.splice(index, 1);
    }

    emit('filtersChanged', enabledFilters.value);
};
const clearFilters = () => { enabledFilters.value = []; };

const clearSearchPhrase = () => { searchPhrase.value = ''; };

const filteredResults = computed(() => {
    let results = unref(data);

    // Apply search-based filtering (if any)
    if (searchPhrase.value && searchable.value.length) {
        results = results.filter(row => {
            for (const key of searchable.value) {
                if (String(row[key]).toLowerCase().includes(searchPhrase.value.toLowerCase())) {
                    return true;
                }
            }
            return false;
        });
    }

    // Apply checkbox-based filtering
    for (const filterObj of enabledFilters.value) {
        const { filter } = filterObj;
        results = results.filter(row => {
            if (filter.custom) {
                return filter.custom(filter.value, row);
            } else {
                for (const key of filter.keys) {
                    if (filter.exact) {
                        return String(row[key]) === String(filter.value);
                    } else {
                        if (String(row[key]).toLowerCase().includes(String(filter.value).toLowerCase())) {
                            return true;
                        }
                    }
                }
                return false;
            }
        });
    }
    return results;
});

const filteredResultsCount = computed(() => filteredResults.value.length);


const searchPhrase = ref(null);
const activeFilters = computed(() => {
    const f = {};
    for (const { label, filter } of unref(enabledFilters)) {
        f[label] = filter;
    }
    if (searchable && searchPhrase.value) {
        f.__search = { value: searchPhrase.value, keys: searchable };
    }
    return f;
});

const emitSelectAll = () => {
    emit('selectAll', filteredResults.value.map(row => row.id));
};

const totalPages = ref(1);
const currentPage = ref(1);
const onTotalPageChange = (newTotalPageCount) => { totalPages.value = newTotalPageCount; };

const thClasses = (col) => {
    const classes = [
        'px-6',
        'py-3',
        'text-left',
        'text-xs',
        'font-bold',
        'text-black',
        'cursor-pointer'
    ];

    if (col.sort) {
        classes.push('coursor-pointer');
    }

    if (col.desktop) {
        classes.push('hidden');
        classes.push('lg:table-cell');
    }

    return classes;
};

const tdClasses = (col) => {
    const classes = [
        'px-6',
        'py-4',
        'lg:whitespace-nowrap'
    ];

    if (col.desktop) {
        classes.push('hidden');
        classes.push('lg:table-cell');
    }

    return classes;
}
</script>
