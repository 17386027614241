<script setup>

import {computed, onMounted, ref} from "vue";

const props = defineProps({
    content: {
        type: Object,
        required: true,
    }
})

const sessions = ref([]);
const sessionsLoaded = ref(false);
const userIsLoggedIn = window.myflow.user.loggedIn;

const loadSessions = async () => {
    const res = await axios.get(route('api.coaching2.client.sessions.index'));
    if (res.data.success)    {
        sessions.value = res.data.sessions
        sessionsLoaded.value = true
    }
};

const reloadPage = () => {
    window.location.reload();
}

const bookedSessions = computed(() => {
    return sessions.value?.filter(session => session.status === 'booked')
})

const upcomingSessions = computed(() => {
    return sessions.value?.filter(session => session.status === 'bookable' || session.status === 'reschedulable')
})

const oldSessions = computed(() => {
    return sessions.value?.filter(session => session.status === 'no-show' || session.status === 'completed' || session.status === 'cancelled')
})

const pageBuilderActive = computed(() => {
    return window.myflow?.pagebuilderActive;
});

onMounted(() => {
    if (userIsLoggedIn) {
        loadSessions();
    }
});

</script>

<template>
    <div>
        <h2 v-if="content.title">{{ content.title }}</h2>
        <div v-if="userIsLoggedIn">
            <div v-if="pageBuilderActive">
                <list-basic>
                    <list-basic-item>
                        <template #title>
                            <div class="text-center text-gray-500 py-4">{{ _mft('pagebuilderBlock:coachingBookings.placeholder.listItem') }}</div>
                        </template>
                        <template #actions>
                            <span></span>
                        </template>
                    </list-basic-item>
                </list-basic>
            </div>
            <div v-else>
                <div
                    v-if="sessionsLoaded"
                    class="pb-8"
                >
                    <p
                        v-if="sessions.length === 0"
                        class="text-center py-4"
                    >
                        {{ content.noSessionsMessage }}
                    </p>

                    <list-basic v-else>
                        <coaching-v2-client-sessions-list-session
                            v-for="(session, index) in bookedSessions"
                            :key="index"
                            :session="session"
                            @reloadSessions="loadSessions"
                        ></coaching-v2-client-sessions-list-session>

                        <coaching-v2-client-sessions-list-session
                            v-for="(session, index) in upcomingSessions"
                            :key="index"
                            :session="session"
                            @reloadSessions="loadSessions"
                        ></coaching-v2-client-sessions-list-session>

                        <coaching-v2-client-sessions-list-session
                            v-for="(session, index) in oldSessions"
                            :key="index"
                            :session="session"
                            @reloadSessions="loadSessions"
                        ></coaching-v2-client-sessions-list-session>
                    </list-basic>
                </div>
                <mf-spinner-medium v-else class="w-24 h-12" />
            </div>
        </div>
        <login-form
            v-else
            class="my-4"
            @successfulLogin="reloadPage"
        />
    </div>

</template>

<style scoped>

</style>
