<script setup>

import {onMounted, ref} from "vue";
import { useNotificationStore } from '@/stores/NotificationStore.js'
const notificationStore = useNotificationStore()

const products = ref([])
const currency = ref('')
const editingProduct = ref(null)

const loadProducts = async () => {
    const res = await axios.get(route('api.custom-products.index'))
    products.value = res.data.products
}

const onCreatedOrUpdated = (action) => {
    let title
    if (action === 'created') {
        title = _mft('customProduct:productCreated')
    } else {
        title = _mft('customProduct:productUpdated')
    }
    notificationStore.addNotification({
        type: 'success',
        title: title,
        dismissAfter: 2500,
    })

    editingProduct.value = null
    loadProducts();
}

onMounted(() => {
    _mfProp('company:default-currency').then(data => {
        currency.value = data
    })

    loadProducts()
})

</script>
<template>
    <div>
        <frosted-bar>
            <div class="flex items-center justify-between space-x-4">
                <h1>{{ _mft('customProduct:domainNamePlural') }}</h1>

                <div class="flex items-center space-x-4">
                    <story-lane-modal :module="'custom-products-v1'"></story-lane-modal>

                    <button-primary
                        @click="editingProduct = {}"
                    >
                        <mf-translate domainkey="customProduct:createNew"></mf-translate>
                    </button-primary>
                </div>
            </div>
        </frosted-bar>

        <div class="mt-8">
            <list-basic>
                <list-basic-item
                    v-for="(product, index) in products"
                >
                    <template #title>
                        {{ product.title }}
                    </template>
                    <div class="text-gray-400 text-xs">
                        {{ product.price }} {{ currency }}
                        <span v-if="product.unit">/ {{ product.unit}}</span>
                    </div>
                    <template #actions>
                        <button-primary
                            size="sm"
                            @click="editingProduct = product"
                        >
                            {{ _mft('shared:action.edit') }}
                        </button-primary>
                    </template>
                </list-basic-item>
                <list-basic-item
                    v-if="products.length === 0"
                >
                    <div class="text-gray-400 text-center">
                        {{ _mft('customProduct:noProducts') }}
                    </div>
                </list-basic-item>
            </list-basic>
        </div>
        <slideout-with-slots
            v-if="editingProduct"
            @close="editingProduct = null"
        >
            <template #title>
                {{ editingProduct.title }}
            </template>
            <template #body>
                <custom-product-form
                    :product="editingProduct"
                    :existing-product-names="products.map(p => p.title)"
                    @createdOrUpdated="onCreatedOrUpdated"
                ></custom-product-form>
            </template>
        </slideout-with-slots>
    </div>
</template>
