<script setup>

import CardBasic from "../containers/cardBasic.vue";
import SlideoutWithSlots from "../elements/slideoutWithSlots.vue";
import StyleSetting from "../page-builder/styleSetting.vue";
import ButtonWithConfirmation from "../buttons/buttonWithConfirmation.vue";
import {computed, ref} from "vue";

const props = defineProps({
    leads: {
        type: Array,
        required: false,
        default: () => []
    },
})

const slideoutOpen = ref(false)

const modalClass = computed(() => {
    if (slideoutOpen.value) {
        return 'top-8 translate-y-0 opacity-0'
    }
    return props.leads.length > 0 ? 'top-8 translate-y-0' : 'top-0 -mt-48 -translate-y-full'
})

</script>

<template>
    <div>
        <div
            class="fixed transition-all left-1/2 -translate-x-1/2 z-modal"
            :class="modalClass"
        >
            <div class="bg-white shadow-2xl rounded-wl p-2 pl-3">
                <div class="flex justify-between items-center space-x-4 text-xs">
                    <p class="mb-0">{{ leads.length }} leads selected</p>
                    <button-primary
                        size="sm"
                        @click="slideoutOpen = true"
                    >
                        Add tags
                    </button-primary>
                </div>
            </div>

            <slideout-with-slots
                v-if="slideoutOpen"
                @close="slideoutOpen = false"
            >
                <template #title>
                    <h2>Manage {{ leads.length }} leads</h2>
                </template>
                <template #body>
                    <div>
                        <tags-multi-assign
                            :showTopbar="false"
                            :selected-leads="leads.map(lead => lead.id)"
                        />
                    </div>
                </template>
            </slideout-with-slots>

        </div>
    </div>
</template>
