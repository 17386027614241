<script setup>
    import { reactive } from 'vue'

    const emit = defineEmits('reloadSignatureRequests')

    const props = defineProps({
        signatureRequest: {
            type: Object,
            required: true
        },
        expertContractId: {
            type: Number,
            required: true
        }
    })

    const state = reactive({
        deleting: false,
        copySent: false,
    })

    const editableSignatureRequest = reactive({
        signee_email: props.signatureRequest.signee_email,
        signee_phone: props.signatureRequest.signee_phone
    })

    const deleteSignatureRequest = async () => {
        const response = await axios.delete(route('expert-contracts.signature-requests.destroy', [props.expertContractId, props.signatureRequest.id]))
        emit('reloadSignatureRequests')
    }

    const updateSignatureRequest = async () => {
        const response = await axios.post(route('expert-contracts.signature-requests.enhance', [props.expertContractId, props.signatureRequest.id]), {
            signee_email: editableSignatureRequest.signee_email,
            signee_phone: editableSignatureRequest.signee_phone
        })

        emit('reloadSignatureRequests')
    }

    const sendCopy = async () => {
        state.copySent = true
        const response = await axios.get(route('expert-contracts.signature-requests.sendCopy', [props.expertContractId, props.signatureRequest.id]))
        emit('reloadSignatureRequests')
    }

</script>
<template>
    <div
        class="flex flex-col items-center justify-between py-3 my-2 lg:flex-row lg:items-start gap-x-5"
        :class="signatureRequest.is_disabled ? 'opacity-40' : ''"
    >

        <div class="w-full">
            <div class="flex items-center space-x-2">
                <div
                    v-if="signatureRequest.is_signed"
                >
                    <span class="bg-myflowGreen-100 px-2 py-0.5 text-xs rounded-md mr-1">
                        <i class="mr-1 fa-solid fa-signature-lock text-myflowGreen-900"></i>
                        {{ _mft('expertContract:signee.signed') }}
                    </span>
                </div>
                <div
                    v-else
                >
                    <span class="bg-gray-200 px-2 py-0.5 text-xs rounded-md mr-1">
                        <i class="mr-1 fa-solid fa-signature-slash text-myflowRed-900"></i>
                        {{ _mft('expertContract:signee.notSigned') }}
                    </span>
                </div>

                <general-modal
                    v-if="signatureRequest.opens.length > 0"
                >
                    <template v-slot:header>{{ _mft('expertContract:signatureRequest.opensBySignee', {name: signatureRequest.signee_name}) }}</template>
                    <template v-slot:body>
                        <ul class="divide-y">
                            <li
                                v-for="(o, i) in signatureRequest.opens"
                                :key="i"
                            >
                                {{ _mft('expertContract:signatureRequest.openedAt') }} {{ o.opened_at }}
                            </li>
                        </ul>
                    </template>
                    <template v-slot:open>
                        <small class="cursor-pointer text-myflowPurple-700">
                            {{ _mft('expertContract:signatureRequest.lastOpenedAt') }} {{ signatureRequest.last_open.opened_at }}
                        </small></template>
                </general-modal>
                <small v-else class="text-gray-500">{{ _mft('expertContract:signatureRequest.notOpened') }}</small>

            </div>

            <div class="flex items-center">
                <span class="font-bold">{{ signatureRequest.signee_name }}</span>
                <a
                    v-if="signatureRequest.lead_id"
                    :href="route('crm.leads.view', signatureRequest.lead_id)"
                    target="_blank"
                    class="ml-2 space-x-2 text-xs underline cursor-pointer text-myflowPurple-700"
                >
                    <i>{{ _mft('expertContract:signatureRequest.lead.showInCrm') }}</i>
                </a>
                <a
                    v-else
                    class="ml-2 space-x-2 text-xs underline cursor-pointer text-myflowPurple-700"
                    target="_blank"
                    :href="route('crm.leads.create', {
                        name: signatureRequest.signee_name,
                        phone: signatureRequest.signee_phone ?? '',
                        email: signatureRequest.signee_email ?? ''
                    })"
                >
                    <i>{{ _mft('expertContract:signatureRequest.lead.addToCrm') }}</i>
                </a>
            </div>

            <div
                v-if="signatureRequest.signee_email"
                class="text-gray-500"
            >
                {{ signatureRequest.signee_email }}
            </div>

            <div
                v-if="signatureRequest.signee_phone"
                class="text-gray-500"
            >
                {{ signatureRequest.signee_phone }}
            </div>

            <div
                v-if="!signatureRequest.disabled"
            >
                <general-modal
                    v-if="!signatureRequest.signee_email || !signatureRequest.signee_phone"
                >
                    <template v-slot:header>{{ _mft('expertContract:signatureRequest.signee.completeDetails') }}</template>
                    <template v-slot:body>
                        <label>{{ _mft('shared:name') }}</label>
                        <input type="text" maxlength="255" disabled :value="signatureRequest.signee_name" class="myflow-basic-input">

                        <label>{{ _mft('shared:email') }}</label>
                        <input
                            type="text"
                            maxlength="255"
                            :diabled="signatureRequest.signee_email"
                            v-model="editableSignatureRequest.signee_email" name="signee_email"
                            class="myflow-basic-input"
                        >

                        <label>{{ _mft('shared:phone') }}</label>
                        <input
                            type="text"
                            maxlength="255"
                            :disabled="signatureRequest.signee_phone"
                            v-model="editableSignatureRequest.signee_phone" name="signee_phone"
                            class="myflow-basic-input"
                        >

                        <div class="flex justify-end mt-4">
                            <button
                                @click="updateSignatureRequest"
                                type="submit"
                                class="myflow-basic-button whitespace-nowrap"
                            >{{ _mft('expertContract:signatureRequest.signee.saveDetails') }}</button>
                        </div>
                    </template>
                    <template v-slot:open>
                        <span
                            class="text-xs underline cursor-pointer text-myflowPurple-700"
                        >
                            {{ _mft('expertContract:signatureRequest.signee.completeDetails.title', {name: signatureRequest.signee_name }) }}
                        </span>
                    </template>
                </general-modal>
                <general-modal v-if="signatureRequest.signee_email">
                    <template v-slot:header>{{ _mft('expertContract:signatureRequest.sendLink') }}</template>
                    <template v-slot:body>
                        <p>{{ _mft('expertContract:signatureRequest.sendLink.description') }}</p>

                        <p>{{ _mft('expertContract:signatureRequest.sendLink.configurationInfo') }}</p>

                        <button
                            class="whitespace-nowrap"
                            :class="state.copySent ? 'myflow-basic-button--state-disabled' : 'myflow-basic-button'"
                            :disabled="state.copySent"
                            @click="sendCopy"
                        >
                            {{ _mft('expertContract:signatureRequest.sendContractLink') }}
                        </button>
                    </template>
                    <template v-slot:open>
                        <span class="text-xs cursor-pointer text-myflowPurple-700">
                            <span class="underline">{{ _mft('expertContract:signatureRequest.sendLinkTo', { name: signatureRequest.signee_name}) }}</span>
                            <span
                                v-if="signatureRequest.last_manually_sent_at != null"
                                class="ml-1 text-gray-600"
                            >
                                {{ '(' + _mft('expertContract:signatureRequest.lastSentAt') + ' ' + signatureRequest.last_manually_sent_at + ')' }}
                            </span>
                        </span>
                    </template>
                </general-modal>
            </div>
        </div>

        <div
            v-if="signatureRequest.is_deletable"
            class="relative w-full mt-1 text-right"
        >
            <button-with-confirmation
                buttonClass="myflow-basic-button--danger whitespace-nowrap"
                :buttonLabel="_mft('shared:action.delete')"
                :confirmText="_mft('shared:confirm.areYouSure')"
                :confirmButtonLabel="_mft('shared:confirm.positive')"
                :abortButtonLabel="_mft('shared:confirm.cancel')"
                @confirmed="deleteSignatureRequest"
            />
        </div>
        <div
            v-else-if="signatureRequest.disabled"
            class="relative w-full mt-1 text-right"
        >
            <button
                type="submit"
                class="myflow-basic-button--secondary whitespace-nowrap"
            >
                {{ _mft('shared:deleted') }}
            </button>
        </div>
    </div>
</template>
