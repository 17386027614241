<template>
  <div class="mb-4">
    <input-label
      :label="label"
      :required="required"
    />
    <div
      class="relative mt-1 rounded-md shadow-sm"
    >
      <textarea
        type="text"
        class="block w-full p-2 pr-10 mt-1 border rounded-md"
        :class="[inputClass, inputTextColor, inputHeightClass, inputClass]"
        :placeholder="placeholder"
        :value="content"
        @input="$emit('update:content', $event.target.value)"
        :required="required"
        :name="name"
        ref="focusableinput"
        :autofocus="autofocus"
      ></textarea>
    </div>
    <input-error v-if="error" :error="error" />
  </div>
</template>
<script>
export default {
  setup() {

  },
  expose: ['focusInput'],
  data: function(){
    return {
      inputClass: ''
    }
  },
  props: {
    content: String,
    error: String,
    label: String,
    placeholder: String,
    required: Boolean,
    name: String,
    inputTextColor: {type: String, default: 'text-gray-900'},
    inputHeightClass: {type: String, required: false, default: 'h-36'},
    inputClass: {type: String, required: false, default: ''},
    autofocus: {type: Boolean, default: false},
  },
  methods: {
    focusInput() {
      this.$refs.focusableinput.focus();
    },
  },
  watch: {
    error: function(error){
      let classes = [];
      if( error !== '' ){
        classes.push('border-red-700')
      } else {
        classes.push('border-gray')
      }
      this.inputClass = classes.join(' ')
    }
  },
  mounted() {
    if (this.autofocus) {
      this.focusInput()
    }
  }
}
</script>
