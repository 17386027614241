<script setup>
    import { computed, nextTick, ref, watch, onMounted } from 'vue'

    const props = defineProps({
        content: {
            type: String,
            default: '',
        },
        country: {
            type: String,
            default: '',
        },
        error: {
            type:String,
            default:''
        },
        label: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: ''
        },
        required: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            required: true
        },
        disabled: {
            type:Boolean,
            default:false
        },
        languageListClass: {
            type: String,
            default: 'absolute z-10 w-full left-0 right-0 mt-1 bg-white rounded-b-md shadow-lg'
        },
        valueType: {
            type: String,
            default: 'countryCode',
            options: ['countryCode', 'countryName']
        },
    })

    const emit = defineEmits('update:content', 'update:name', 'selected')

    const term = ref('')
    const selectedLanguageCode = ref(props.content)
    const selectedLanguageName = ref(null)
    const languageRecentlySelected = ref(false)
    const focused = ref(false)

    const languages = [
        {
            code: "ad",
            label: "Andorra"
        },
        {
            code: "ae",
            label: "United Arab Emirates"
        },
        {
            code: "af",
            label: "Afghanistan"
        },
        {
            code: "ag",
            label: "Antigua and Barbuda"
        },
        {
            code: "ai",
            label: "Anguilla"
        },
        {
            code: "al",
            label: "Albania"
        },
        {
            code: "am",
            label: "Armenia"
        },
        {
            code: "ao",
            label: "Angola"
        },
        {
            code: "aq",
            label: "Antarctica"
        },
        {
            code: "ar",
            label: "Argentina"
        },
        {
            code: "as",
            label: "American Samoa"
        },
        {
            code: "at",
            label: "Austria"
        },
        {
            code: "au",
            label: "Australia"
        },
        {
            code: "aw",
            label: "Aruba"
        },
        {
            code: "ax",
            label: "Åland Islands"
        },
        {
            code: "az",
            label: "Azerbaijan"
        },
        {
            code: "ba",
            label: "Bosnia and Herzegovina"
        },
        {
            code: "bb",
            label: "Barbados"
        },
        {
            code: "bd",
            label: "Bangladesh"
        },
        {
            code: "be",
            label: "Belgium"
        },
        {
            code: "bf",
            label: "Burkina Faso"
        },
        {
            code: "bg",
            label: "Bulgaria"
        },
        {
            code: "bh",
            label: "Bahrain"
        },
        {
            code: "bi",
            label: "Burundi"
        },
        {
            code: "bj",
            label: "Benin"
        },
        {
            code: "bl",
            label: "Saint Barthélemy"
        },
        {
            code: "bm",
            label: "Bermuda"
        },
        {
            code: "bn",
            label: "Brunei Darussalam"
        },
        {
            code: "bo",
            label: "Bolivia (Plurinational State of)"
        },
        {
            code: "bq",
            label: "Bonaire, Sint Eustatius and Saba"
        },
        {
            code: "br",
            label: "Brazil"
        },
        {
            code: "bs",
            label: "Bahamas"
        },
        {
            code: "bt",
            label: "Bhutan"
        },
        {
            code: "bv",
            label: "Bouvet Island"
        },
        {
            code: "bw",
            label: "Botswana"
        },
        {
            code: "by",
            label: "Belarus"
        },
        {
            code: "bz",
            label: "Belize"
        },
        {
            code: "ca",
            label: "Canada"
        },
        {
            code: "cc",
            label: "Cocos (Keeling) Islands"
        },
        {
            code: "cd",
            label: "Congo, Democratic Republic of the"
        },
        {
            code: "cf",
            label: "Central African Republic"
        },
        {
            code: "cg",
            label: "Congo"
        },
        {
            code: "ch",
            label: "Switzerland"
        },
        {
            code: "ci",
            label: "Côte d'Ivoire"
        },
        {
            code: "ck",
            label: "Cook Islands"
        },
        {
            code: "cl",
            label: "Chile"
        },
        {
            code: "cm",
            label: "Cameroon"
        },
        {
            code: "cn",
            label: "China"
        },
        {
            code: "co",
            label: "Colombia"
        },
        {
            code: "cr",
            label: "Costa Rica"
        },
        {
            code: "cu",
            label: "Cuba"
        },
        {
            code: "cv",
            label: "Cabo Verde"
        },
        {
            code: "cw",
            label: "Curaçao"
        },
        {
            code: "cx",
            label: "Christmas Island"
        },
        {
            code: "cy",
            label: "Cyprus"
        },
        {
            code: "cz",
            label: "Czechia"
        },
        {
            code: "de",
            label: "Germany"
        },
        {
            code: "dj",
            label: "Djibouti"
        },
        {
            code: "dk",
            label: "Denmark"
        },
        {
            code: "dm",
            label: "Dominica"
        },
        {
            code: "do",
            label: "Dominican Republic"
        },
        {
            code: "dz",
            label: "Algeria"
        },
        {
            code: "ec",
            label: "Ecuador"
        },
        {
            code: "ee",
            label: "Estonia"
        },
        {
            code: "eg",
            label: "Egypt"
        },
        {
            code: "eh",
            label: "Western Sahara"
        },
        {
            code: "er",
            label: "Eritrea"
        },
        {
            code: "es",
            label: "Spain"
        },
        {
            code: "et",
            label: "Ethiopia"
        },
        {
            code: "fi",
            label: "Finland"
        },
        {
            code: "fj",
            label: "Fiji"
        },
        {
            code: "fk",
            label: "Falkland Islands (Malvinas)"
        },
        {
            code: "fm",
            label: "Micronesia (Federated States of)"
        },
        {
            code: "fo",
            label: "Faroe Islands"
        },
        {
            code: "fr",
            label: "France"
        },
        {
            code: "ga",
            label: "Gabon"
        },
        {
            code: "gb",
            label: "United Kingdom of Great Britain and Northern Ireland"
        },
        {
            code: "gd",
            label: "Grenada"
        },
        {
            code: "ge",
            label: "Georgia"
        },
        {
            code: "gf",
            label: "French Guiana"
        },
        {
            code: "gg",
            label: "Guernsey"
        },
        {
            code: "gh",
            label: "Ghana"
        },
        {
            code: "gi",
            label: "Gibraltar"
        },
        {
            code: "gl",
            label: "Greenland"
        },
        {
            code: "gm",
            label: "Gambia"
        },
        {
            code: "gn",
            label: "Guinea"
        },
        {
            code: "gp",
            label: "Guadeloupe"
        },
        {
            code: "gq",
            label: "Equatorial Guinea"
        },
        {
            code: "gr",
            label: "Greece"
        },
        {
            code: "gs",
            label: "South Georgia and the South Sandwich Islands"
        },
        {
            code: "gt",
            label: "Guatemala"
        },
        {
            code: "gu",
            label: "Guam"
        },
        {
            code: "gw",
            label: "Guinea-Bissau"
        },
        {
            code: "gy",
            label: "Guyana"
        },
        {
            code: "hk",
            label: "Hong Kong"
        },
        {
            code: "hm",
            label: "Heard Island and McDonald Islands"
        },
        {
            code: "hn",
            label: "Honduras"
        },
        {
            code: "hr",
            label: "Croatia"
        },
        {
            code: "ht",
            label: "Haiti"
        },
        {
            code: "hu",
            label: "Hungary"
        },
        {
            code: "id",
            label: "Indonesia"
        },
        {
            code: "ie",
            label: "Ireland"
        },
        {
            code: "il",
            label: "Israel"
        },
        {
            code: "im",
            label: "Isle of Man"
        },
        {
            code: "in",
            label: "India"
        },
        {
            code: "io",
            label: "British Indian Ocean Territory"
        },
        {
            code: "iq",
            label: "Iraq"
        },
        {
            code: "ir",
            label: "Iran (Islamic Republic of)"
        },
        {
            code: "is",
            label: "Iceland"
        },
        {
            code: "it",
            label: "Italy"
        },
        {
            code: "je",
            label: "Jersey"
        },
        {
            code: "jm",
            label: "Jamaica"
        },
        {
            code: "jo",
            label: "Jordan"
        },
        {
            code: "jp",
            label: "Japan"
        },
        {
            code: "ke",
            label: "Kenya"
        },
        {
            code: "kg",
            label: "Kyrgyzstan"
        },
        {
            code: "kh",
            label: "Cambodia"
        },
        {
            code: "ki",
            label: "Kiribati"
        },
        {
            code: "km",
            label: "Comoros"
        },
        {
            code: "kn",
            label: "Saint Kitts and Nevis"
        },
        {
            code: "kp",
            label: "Korea (Democratic People's Republic of)"
        },
        {
            code: "kr",
            label: "Korea, Republic of"
        },
        {
            code: "kw",
            label: "Kuwait"
        },
        {
            code: "ky",
            label: "Cayman Islands"
        },
        {
            code: "kz",
            label: "Kazakhstan"
        },
        {
            code: "la",
            label: "Lao People's Democratic Republic"
        },
        {
            code: "lb",
            label: "Lebanon"
        },
        {
            code: "lc",
            label: "Saint Lucia"
        },
        {
            code: "li",
            label: "Liechtenstein"
        },
        {
            code: "lk",
            label: "Sri Lanka"
        },
        {
            code: "lr",
            label: "Liberia"
        },
        {
            code: "ls",
            label: "Lesotho"
        },
        {
            code: "lt",
            label: "Lithuania"
        },
        {
            code: "lu",
            label: "Luxembourg"
        },
        {
            code: "lv",
            label: "Latvia"
        },
        {
            code: "ly",
            label: "Libya"
        },
        {
            code: "ma",
            label: "Morocco"
        },
        {
            code: "mc",
            label: "Monaco"
        },
        {
            code: "md",
            label: "Moldova, Republic of"
        },
        {
            code: "me",
            label: "Montenegro"
        },
        {
            code: "mf",
            label: "Saint Martin (French part)"
        },
        {
            code: "mg",
            label: "Madagascar"
        },
        {
            code: "mh",
            label: "Marshall Islands"
        },
        {
            code: "mk",
            label: "North Macedonia"
        },
        {
            code: "ml",
            label: "Mali"
        },
        {
            code: "mm",
            label: "Myanmar"
        },
        {
            code: "mn",
            label: "Mongolia"
        },
        {
            code: "mo",
            label: "Macao"
        },
        {
            code: "mp",
            label: "Northern Mariana Islands"
        },
        {
            code: "mq",
            label: "Martinique"
        },
        {
            code: "mr",
            label: "Mauritania"
        },
        {
            code: "ms",
            label: "Montserrat"
        },
        {
            code: "mt",
            label: "Malta"
        },
        {
            code: "mu",
            label: "Mauritius"
        },
        {
            code: "mv",
            label: "Maldives"
        },
        {
            code: "mw",
            label: "Malawi"
        },
        {
            code: "mx",
            label: "Mexico"
        },
        {
            code: "my",
            label: "Malaysia"
        },
        {
            code: "mz",
            label: "Mozambique"
        },
        {
            code: "na",
            label: "Namibia"
        },
        {
            code: "nc",
            label: "New Caledonia"
        },
        {
            code: "ne",
            label: "Niger"
        },
        {
            code: "nf",
            label: "Norfolk Island"
        },
        {
            code: "ng",
            label: "Nigeria"
        },
        {
            code: "ni",
            label: "Nicaragua"
        },
        {
            code: "nl",
            label: "Netherlands, Kingdom of the"
        },
        {
            code: "no",
            label: "Norway"
        },
        {
            code: "np",
            label: "Nepal"
        },
        {
            code: "nr",
            label: "Nauru"
        },
        {
            code: "nu",
            label: "Niue"
        },
        {
            code: "nz",
            label: "New Zealand"
        },
        {
            code: "om",
            label: "Oman"
        },
        {
            code: "pa",
            label: "Panama"
        },
        {
            code: "pe",
            label: "Peru"
        },
        {
            code: "pf",
            label: "French Polynesia"
        },
        {
            code: "pg",
            label: "Papua New Guinea"
        },
        {
            code: "ph",
            label: "Philippines"
        },
        {
            code: "pk",
            label: "Pakistan"
        },
        {
            code: "pl",
            label: "Poland"
        },
        {
            code: "pm",
            label: "Saint Pierre and Miquelon"
        },
        {
            code: "pn",
            label: "Pitcairn"
        },
        {
            code: "pr",
            label: "Puerto Rico"
        },
        {
            code: "ps",
            label: "Palestine, State of"
        },
        {
            code: "pt",
            label: "Portugal"
        },
        {
            code: "pw",
            label: "Palau"
        },
        {
            code: "py",
            label: "Paraguay"
        },
        {
            code: "qa",
            label: "Qatar"
        },
        {
            code: "re",
            label: "Réunion"
        },
        {
            code: "ro",
            label: "Romania"
        },
        {
            code: "rs",
            label: "Serbia"
        },
        {
            code: "ru",
            label: "Russian Federation"
        },
        {
            code: "rw",
            label: "Rwanda"
        },
        {
            code: "sa",
            label: "Saudi Arabia"
        },
        {
            code: "sb",
            label: "Solomon Islands"
        },
        {
            code: "sc",
            label: "Seychelles"
        },
        {
            code: "sd",
            label: "Sudan"
        },
        {
            code: "se",
            label: "Sweden"
        },
        {
            code: "se",
            label: "Sverige"
        },
        {
            code: "sg",
            label: "Singapore"
        },
        {
            code: "sh",
            label: "Saint Helena, Ascension and Tristan da Cunha"
        },
        {
            code: "si",
            label: "Slovenia"
        },
        {
            code: "sj",
            label: "Svalbard and Jan Mayen"
        },
        {
            code: "sk",
            label: "Slovakia"
        },
        {
            code: "sl",
            label: "Sierra Leone"
        },
        {
            code: "sm",
            label: "San Marino"
        },
        {
            code: "sn",
            label: "Senegal"
        },
        {
            code: "so",
            label: "Somalia"
        },
        {
            code: "sr",
            label: "Suriname"
        },
        {
            code: "ss",
            label: "South Sudan"
        },
        {
            code: "st",
            label: "Sao Tome and Principe"
        },
        {
            code: "sv",
            label: "El Salvador"
        },
        {
            code: "sx",
            label: "Sint Maarten (Dutch part)"
        },
        {
            code: "sy",
            label: "Syrian Arab Republic"
        },
        {
            code: "sz",
            label: "Eswatini"
        },
        {
            code: "tc",
            label: "Turks and Caicos Islands"
        },
        {
            code: "td",
            label: "Chad"
        },
        {
            code: "tf",
            label: "French Southern Territories"
        },
        {
            code: "tg",
            label: "Togo"
        },
        {
            code: "th",
            label: "Thailand"
        },
        {
            code: "tj",
            label: "Tajikistan"
        },
        {
            code: "tk",
            label: "Tokelau"
        },
        {
            code: "tl",
            label: "Timor-Leste"
        },
        {
            code: "tm",
            label: "Turkmenistan"
        },
        {
            code: "tn",
            label: "Tunisia"
        },
        {
            code: "to",
            label: "Tonga"
        },
        {
            code: "tr",
            label: "Türkiye"
        },
        {
            code: "tt",
            label: "Trinidad and Tobago"
        },
        {
            code: "tv",
            label: "Tuvalu"
        },
        {
            code: "tw",
            label: "Taiwan, Province of China"
        },
        {
            code: "tz",
            label: "Tanzania, United Republic of"
        },
        {
            code: "ua",
            label: "Ukraine"
        },
        {
            code: "ug",
            label: "Uganda"
        },
        {
            code: "um",
            label: "United States Minor Outlying Islands"
        },
        {
            code: "us",
            label: "United States of America"
        },
        {
            code: "uy",
            label: "Uruguay"
        },
        {
            code: "uz",
            label: "Uzbekistan"
        },
        {
            code: "va",
            label: "Holy See"
        },
        {
            code: "vc",
            label: "Saint Vincent and the Grenadines"
        },
        {
            code: "ve",
            label: "Venezuela (Bolivarian Republic of)"
        },
        {
            code: "vg",
            label: "Virgin Islands (British)"
        },
        {
            code: "vi",
            label: "Virgin Islands (U.S.)"
        },
        {
            code: "vn",
            label: "Viet Nam"
        },
        {
            code: "vu",
            label: "Vanuatu"
        },
        {
            code: "wf",
            label: "Wallis and Futuna"
        },
        {
            code: "ws",
            label: "Samoa"
        },
        {
            code: "ye",
            label: "Yemen"
        },
        {
            code: "yt",
            label: "Mayotte"
        },
        {
            code: "za",
            label: "South Africa"
        },
        {
            code: "zm",
            label: "Zambia"
        },
        {
            code: "zw",
            label: "Zimbabwe"
        }
    ]

    const selectLanguage = (lang) => {
        selectedLanguageCode.value = lang.code
        selectedLanguageName.value = lang.label
        term.value = lang.label
        emit('update:content', lang.code)
        emit('update:name', lang.label)
        emit('selected', lang)
        nextTick(() => {
            languageRecentlySelected.value = true
        })
    }

    const resetSelectedLanguage = () => {
        selectedLanguageCode.value = null
        selectedLanguageName.value = null
    }

    const matchingLanguages = computed(() => {
        if (term.value.length < 2) {
            return []
        }
        return languages.filter((lang) => {
            return lang.label.toLowerCase().includes(term.value.toLowerCase())
        })
    })

    const selectedLanguage = computed(() => {
        if (props.valueType === 'countryCode') {
            return selectedLanguageCode.value
        } else {
            return selectedLanguageName.value
        }
    })

    const inputClass = computed(() => {
        if (focused.value) {
            return ''
        }

        if (! languages.map(lang => lang.label).includes(term.value)) {
            return 'border-red-500'
        }
    })

    watch(term, () => {
        languageRecentlySelected.value = false
    })

    onMounted(() => {
        if (props.content.length > 0) {
            const matchingLanguage = languages.find((lang) => {
                return lang.code === props.content
            })
            if (matchingLanguage) {
                term.value = matchingLanguage.label
                nextTick(() => {
                languageRecentlySelected.value = true
                })
            }
        }
        if (props.country?.length > 0) {
            const matchingLanguage = languages.find((lang) => {
                return lang.label === props.country
            })
            if (matchingLanguage) {
                term.value = matchingLanguage.label
                nextTick(() => {
                    languageRecentlySelected.value = true
                })
            }
        }
    })

</script>
<template>
    <div class="mb-4">
        <input-label
            :label="label"
            :required="required"
        />
        <div
            class="relative mt-1 rounded-md shadow-sm"
        >
            <input
                type="text"
                class="block w-full p-2 mt-1 border rounded-md"
                :class="[inputClass]"
                :placeholder="placeholder"
                v-model="term"
                @input="resetSelectedLanguage"
                @focus="focused = true"
                @blur="focused = false"
            />

            <div class="overflow-hidden h-0">
                <input
                    type="text"
                    v-model="selectedLanguage"
                    :required="required"
                    :name="name"
                />
            </div>

            <ul
                v-if="!languageRecentlySelected"
                class="text-left"
                :class="props.languageListClass"
            >
                <li
                    v-for="(lang, i) in matchingLanguages"
                    :key="i"
                    class="px-2 cursor-pointer py-0.5 hover:bg-gray-50"
                    @click="selectLanguage(lang)"
                >
                    {{ lang.label }}
                </li>
            </ul>
        </div>
        <input-error v-if="error" :error="error" />
    </div>
  </template>
