<script setup>

import {onMounted, reactive, ref, watch} from "vue";
import { useNotificationStore } from '@/stores/NotificationStore.js'
const notificationStore = useNotificationStore()

const props = defineProps({
    campaignId: {
        type: Number,
        required: false,
        default: null
    }
})

const stages = ['details', 'search', 'working', 'summary'];
const currentStage = ref('details');
const details = ref({})
const campaignId = ref(props.campaignId)
const poller = ref(null)
const workingState = ref('')

const campaign = reactive({
    id: null,
    loaded: false,
    status: null,
    runtimeDaysEstimate: null,
    emails: [],
    emailsSentPerDay: 10,
    sendSchedule: [],
    projectedEndAt: null,
    launchedAt: null,
    hasConnectedEmail: false,
})

const nextStage = () => {
    const currentIndex = stages.indexOf(currentStage.value);
    if( currentIndex < stages.length - 1 ) {
        currentStage.value = stages[currentIndex + 1];
        setTimeout(function() {
            window.scrollTo(0, 0, 'smooth')
        }, 100)
    }
};

const prevStage = () => {
    const currentIndex = stages.indexOf(currentStage.value);
    if( currentIndex > 0 ) {
        currentStage.value = stages[currentIndex - 1];
        setTimeout(function() {
            window.scrollTo(0, 0, 'smooth')
        }, 100)
    }
};

const submit = async (parameters) => {
    workingState.value = 'waiting'
    if (campaignId.value) {
        const res = await axios.put(route('api.lead-hunter.campaign.update', campaignId.value), {
            campaign: campaign,
        });

        if (!res.data.success) {
            workingState.value = 'error'
            return
        }

        workingState.value = 'success'
    } else {
        const res = await axios.post(route('api.lead-hunter.campaign.store'), {
            details: details.value,
            parameters: parameters
        });
        campaignId.value = res.data.campaign_id;
        currentStage.value = 'working'

        window.location.href = route('lead-hunter.campaign.edit', res.data.campaign_id)
    }
}

const loadCampaign = async () => {
    const res = await axios.get(route('api.lead-hunter.campaign.show', campaignId.value))

    if (res.data.campaign.locked_to_scrape_and_generate && ! res.data.campaign.available_for_editing) {
        currentStage.value = 'working'

        poller.value = setTimeout(async () => {
            await loadCampaign()
        }, 5000)

        return
    }

    campaign.id = res.data.campaign.id;
    campaign.name = res.data.campaign.name;
    campaign.status = res.data.campaign.status;
    campaign.lead_search = res.data.campaign.lead_search;
    campaign.leadCount = res.data.campaign.lead_count;
    campaign.runtimeDaysEstimate = res.data.campaign.runtime_days_estimate;

    campaign.emailsSentPerDay = res.data.campaign.emails_sent_per_day;
    campaign.sendSchedule = res.data.campaign.send_schedule;
    campaign.emails = res.data.campaign.emails;

    campaign.launchedAt = res.data.campaign.launched_at;
    campaign.projectedEndAt = res.data.campaign.projected_end_at;
    campaign.hasConnectedEmail = res.data.campaign.has_connected_email;

    currentStage.value = 'summary'

    campaign.loaded = true
}

const updateCampaign = (c) => {
    campaign.initialEmailSubject = c.initialEmailSubject
    campaign.initialEmailContent = c.initialEmailContent

    campaign.firstFollowupEmailSubject = c.firstFollowupEmailSubject
    campaign.firstFollowupEmailContent = c.firstFollowupEmailContent

    campaign.secondFollowupEmailSubject = c.secondFollowupEmailSubject
    campaign.secondFollowupEmailContent = c.secondFollowupEmailContent

    submit()
}

watch(() => currentStage, (newVal) => {
    window.location.hash = newVal.value;
}, {deep: true})

onMounted(() => {
    if (campaignId.value) {
        loadCampaign()
    } else if (window.location.hash && stages.includes(window.location.hash.substring(1))) {
        currentStage.value = window.location.hash.substring(1);
    }
});

</script>

<template>
    <div>
        <lead-hunter-details
            class="mb-8"
            :class="currentStage === 'details' ? '' : 'hidden'"
            @update:details="details = $event"
        >
            <template #actions>
                <button-primary @click="nextStage">{{ _mft('shared:action.continue') }}</button-primary>
            </template>
        </lead-hunter-details>

        <lead-hunter-search
            class="mb-8"
            :class="currentStage === 'search' ? '' : 'hidden'"
            @submitSearch="submit"
        >
            <template #actions>
                <button-secondary @click="prevStage">{{ _mft('shared:navigation.back') }}</button-secondary>
            </template>
        </lead-hunter-search>

        <lead-hunter-working
            class="mb-8"
            :class="currentStage === 'working' ? '' : 'hidden'"
            @setStage="currentStage = $event"
        />

        <lead-hunter-summary
            class="mb-8"
            :class="currentStage === 'summary' ? '' : 'hidden'"
            :campaign="campaign"
            :working-state="workingState"
            @update:campaign="updateCampaign"
        />
    </div>
</template>

<style scoped>

</style>
