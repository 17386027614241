<script setup>
    import { onMounted, ref, computed } from 'vue'
    import Location from '../../session/location.vue';

    const props = defineProps({
        sessionRoute: {
            type: String,
            required: true,
        },
    })

    const session = ref({})
    const notes = ref({})

    const loadSession = async () => {
        const response = await axios.get(props.sessionRoute)
        session.value = response.data.session
        notes.value = response.data.notes
    }

    const sessionLoaded = computed(() => {
        return Object.keys(session.value).length > 0
    })

    onMounted(() => {
        loadSession()
    })

</script>
<template>
    <div>
        <div class="flex flex-row items-start justify-between space-x-4">
            <div>
                <h1>
                    {{ session.booking?.product.title }}
                </h1>
                <div
                    v-if="session.booking"
                >
                    {{ _mft('coaching:sessionWith') }} <a :href="'mailto:' + session.booking.user.email">{{ session.booking.user.name }}</a>
                </div>
            </div>
            <div v-if="session.booking">
                <date-time-box
                    v-if="session.booking.begins_at.date"
                    :startDate="session.booking.begins_at.date"
                    :endDate="session.booking.ends_at.date"
                    :startTime="session.booking.begins_at.time"
                    :endTime="session.booking.ends_at.time"
                    class="px-4 py-1"
                />
                <p v-else>
                    {{ _mft('coaching:session.notBooked') }}
                </p>
            </div>
        </div>

        <div class="mb-4" v-if="session.booking">
            <Location
                v-if="session.booking.meeting_location"
                :location="session.booking.meeting_location"
                :embedMeeting="false"
            />
            <!-- {{ session.booking?.meeting_location }} -->
        </div>

        <div class="grid gap-8 lg:grid-cols-2">
            <coaching-booking-note-editor
                v-if="sessionLoaded"
                :label="_mft('coaching:note.private.label')"
                :booking-id="session.booking.id"
                note-key="coach-private"
            ></coaching-booking-note-editor>

            <coaching-booking-note-editor
                v-if="sessionLoaded"
                :label="_mft('coaching:note.shared.label')"
                :booking-id="session.booking.id"
                note-key="coach-shared"
            ></coaching-booking-note-editor>
        </div>
    </div>
</template>
