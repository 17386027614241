<template>
    <div
        class="text-center flex flex-col items-center"
    >
        <small class="mb-1" v-if="!minimal">
            {{ _mft('navigation:backOffice.sidebar.improvementPanel') }}
        </small>
        <div
            :class="[minimal ? 'inline-block' : 'space-y-2 flex flex-col']"
        >
            <button-transparent
                v-on:click="currentModal = 'suggestion'"
                size="xs"
                class="mf-link"
                :class="[minimal ? 'mx-4' : '']"
            >
                {{ _mft('navigation:backOffice.sidebar.leaveFeedback') }}
            </button-transparent>
            <button
                v-on:click="highFive"
                class="text-center"
            ><span class="inline-block cursor-grab transform -rotate-12">✋</span></button>
        </div>
        <div class="relative">
            <div class="absolute -top-24 -right-20 z-40 transform text-9xl transition-all cursor-grab"
                :class="highfiveClass"
            >✋</div>
        </div>

        <feedback-modal
            :modalOpen="modalOpen"
            :key="modalKey"
            @closed="modalClosed"
        />
    </div>
</template>
<script>
export default {
    props: {
        minimal: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            currentModal: null,
            modalKey: 0,
            modalOpen: false,
            highfiveClass: 'hidden'
        }
    },
    methods: {
        highFive(){
            let self = this;
            self.highfiveClass = 'highfive';
            setTimeout(function(){
                self.highfiveClass = 'hidden';
            }, 999)
        },
        modalClosed(){
            this.currentModal = null
            this.modalKey++
        }
    },
    watch: {
        currentModal(){
            if( !this.currentModal ){
                this.modalOpen = false
            } else {
                this.modalOpen = true
            }
        },
        modalOpen(val){
            if( val === true){
                this.$emit('modalOpened');
            }
        }
    }
}
</script>
<style scoped>
    .highfive {
        animation: highfive;
        animation-duration: 1s;
        animation-iteration-count: 1;
        transition: all 0.25s;
        opacity: 0;
    }

    @keyframes highfive {
        0% {
            transform: rotateZ(-20deg) scale(1.0) translateX(0%);
            opacity: 0;
        }
        10% {
            opacity: 1;
        }
        50% {
            transform: rotateZ(-30deg) scale(0.9) translateX(-50%);
        }
        60% {
            transform: rotateZ(-5deg) scale(1.2) translateX(10%);
        }
        75% {
            transform: rotateZ(-10deg) scale(1.0) translateX(0%);
            opacity: 1;
        }
        100% {
            opacity: 0;

        }
    }
</style>
