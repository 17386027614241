<script setup>
import { reactive, computed } from 'vue';
import { BarChart } from 'vue-chart-3';
import 'chart.js/auto';

const params = reactive({
    customersInitial: 5,
    customersMonthly: 5,
    monthlyChurn: 3,
    incomeGoal: 0,
    incomeGoalMonths: 0,
});

const price = computed(() => {
    return 149 * 0.4;
});

const table = computed(() => {
    const table = [];
    for (let index = 0; index < 60; index++) {
        const month = index + 1;
        const previousCustomers = index > 0 ? table[index - 1].customers : params.customersInitial;
        const newCustomers = index > 0 ? params.customersMonthly : 0;
        const lostCustomers = previousCustomers * (params.monthlyChurn / 100);
        const customers = previousCustomers + newCustomers - lostCustomers;
        const totalCommission = customers * price.value;
        const accumulatedCommission = totalCommission + (index > 0 ? table[index - 1].accumulatedCommission : 0);
        table.push({
            month,
            previousCustomers,
            newCustomers,
            lostCustomers,
            customers,
            totalCommission,
            accumulatedCommission
        });
    }
    return table;
});

const goalExceededMonthTotalCommission = computed(() => {
    if(params.incomeGoal <= 0) return 0;

    const month = table.value.find(item => item.totalCommission >= params.incomeGoal)?.month;
    return month || 0;
});

const goalMonthCustomers = computed(() => {
    if ((params.incomeGoal <= 0) || (params.incomeGoalMonths <= 0)) return 0;
    const goalCustomers = params.incomeGoal / price.value / params.incomeGoalMonths;
    return Math.ceil(goalCustomers);
});


const totalCommissionChartData = computed(() => {
    const backgroundColors = table.value.map(item => {
        return item.month === goalExceededMonthTotalCommission.value ? '#42b883' : '#a07cae'; // Highlight the exceeding month
    });

    return {
        labels: table.value.map(item => `Month ${item.month}`),
        datasets: [{
            label: 'Total Commission',
            data: table.value.map(item => item.totalCommission),
            backgroundColor: backgroundColors,
        }]
    };
});

const accumulatedCommissionChartData = computed(() => {
    return {
        labels: table.value.map(item => `Month ${item.month}`),
        datasets: [{
            label: 'Accumulated Commission',
            data: table.value.map(item => item.accumulatedCommission),
            backgroundColor: '#a07cae',
        }]
    };
});

const chartOptions = {
    plugins: {
        legend: {
            display: false
        },
        tooltip: {
            callbacks: {
                label: function(context) {
                    return `€ ${context.parsed.y.toLocaleString('sv', { maximumFractionDigits: 0 })}`;
                }
            }
        }
    },
    scales: {
        y: {
            ticks: {
                callback: function(value) {
                    return `€ ${value.toLocaleString('sv', { maximumFractionDigits: 0 })}`;
                }
            }
        }
    },
    responsive: true,
    maintainAspectRatio: false,
};
</script>

<template>
    <div>
        <div class="grid-cols-2 gap-8 pb-16 md:grid">
            <div class="col-span-1">

                <div class="grid-cols-2 gap-8 pb-3 md:grid">
                    <div class="col-span-1" v-html="_mft('calculator:commission.initialCustomersPurchasingSubscription')"></div>
                    <div class="col-span-1">
                        <input class="text-lg text-center border-2 border-gray-100 rounded-lg" type="number" v-model="params.customersInitial" />
                    </div>
                </div>

                <div class="grid-cols-2 gap-8 pb-3 md:grid">
                    <div class="col-span-1" v-html="_mft('calculator:commission.newMonthlyCustomersPurchasingSubscription')"></div>
                    <div class="col-span-1">
                        <input class="text-lg text-center border-2 border-gray-100 rounded-lg" type="number" v-model="params.customersMonthly" />
                    </div>
                </div>

                <div class="grid-cols-2 gap-8 pb-3 md:grid">
                    <div class="col-span-1" v-html="_mft('calculator:commission.customerChurnPerMonthPercentage')"></div>
                    <div class="col-span-1">
                        <input class="text-lg text-center border-2 border-gray-100 rounded-lg" type="number" v-model="params.monthlyChurn" /> %
                    </div>
                </div>

                <expandable-section>
                    <template v-slot:title>
                        {{ _mft('calculator:commission.advancedSettings.title') }}
                    </template>
                    <template v-slot:body>
                        <div class="grid-cols-2 gap-8 pb-3 md:grid">
                            <div class="col-span-1" v-html="_mft('calculator:commission.settings.targetMonthlyCommissionGoal')">

                            </div>
                            <div class="col-span-1">
                                <input class="text-lg text-center border-2 border-gray-100 rounded-lg" type="number" v-model="params.incomeGoal" /> €
                            </div>
                        </div>
                        <div class="grid-cols-2 gap-8 pb-3 md:grid">
                            <div class="col-span-1">
                                {{ _mft('calculator:commission.settings.monthsUntilCommissionGoalShouldBeReached') }}
                            </div>
                            <div class="col-span-1">
                                <input class="text-lg text-center border-2 border-gray-100 rounded-lg" type="number" v-model="params.incomeGoalMonths" /> months
                            </div>
                        </div>
                    </template>
                </expandable-section>


            </div>
            <div class="col-span-1 p-5 text-white rounded-lg shadow-xl bg-myflowPurple-800">
                <h1 class="mb-3 font-normal">These example numbers would give <b class="italic">€{{ table[59].accumulatedCommission.toLocaleString('sv', {maximumFractionDigits: 0}) }}</b> over a 5 year period. </h1>
                <h1 class="mb-3 font-normal">Even if the commission usually builds up over time, this would equal to an average of <b class="italic">€{{ (table[59].accumulatedCommission / 60).toLocaleString('sv', {maximumFractionDigits: 0}) }}</b> per month.</h1>
                <h1 class="mb-3 font-normal">Over time the average Customer Lifetime Value is <b class="italic">€{{ (table[59].accumulatedCommission / (params.customersInitial + (params.customersMonthly * 60))).toLocaleString('sv', {maximumFractionDigits: 0}) }}</b>, meaning that this is the expected commission for each customer.</h1>

                <h1
                    v-if="goalMonthCustomers > 0"
                    class="mb-3 font-normal"
                >You would need <b>{{ goalMonthCustomers }}</b> customers per month to reach your goal to reach your monthly goal of <b>€{{ params.incomeGoal.toLocaleString('sv', {maximumFractionDigits: 0}) }}</b></h1>
            </div>

            <div class="col-span-1 mt-4">
                <h5 class="mb-4 text-center">Monthly Commission</h5>
                <BarChart :chart-data="totalCommissionChartData" :options="chartOptions"/>
            </div>
            <div class="col-span-1 mt-4">
                <h5 class="mb-4 text-center">Accumulated Commission Commission</h5>
                <BarChart :chart-data="accumulatedCommissionChartData" :options="chartOptions"/>
            </div>


            <div class="col-span-2 mt-4">
                <h5 class="mb-4 text-center">Commission details</h5>
                <table class="min-w-full divide-y divide-gray-200 ">
                    <tr>
                        <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase text-start">Month</th>
                        <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase text-start">Previous customers</th>
                        <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase text-start">New customers</th>
                        <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase text-start">Churned customers</th>
                        <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase text-start">Total customers</th>
                        <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase text-start">Monthly commission</th>
                        <th scope="col" class="px-6 py-3 text-xs font-medium text-gray-500 uppercase text-start">Accumulated commission</th>
                    </tr>
                    <tr v-for="row in table" :key="row">
                        <td class="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap "><b>{{ row.month.toLocaleString('sv', {maximumFractionDigits: 0}) }}</b></td>
                        <td class="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap ">{{ row.previousCustomers.toLocaleString('sv', {maximumFractionDigits: 0}) }}</td>
                        <td class="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap ">{{ row.newCustomers.toLocaleString('sv', {maximumFractionDigits: 0}) }}</td>
                        <td class="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap ">{{ row.lostCustomers.toLocaleString('sv', {maximumFractionDigits: 0}) }}</td>
                        <td class="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap ">{{ row.customers.toLocaleString('sv', {maximumFractionDigits: 0}) }}</td>
                        <td class="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap ">€ {{ row.totalCommission.toLocaleString('sv', {maximumFractionDigits: 0}) }}</td>
                        <td class="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap ">€ {{ row.accumulatedCommission.toLocaleString('sv', {maximumFractionDigits: 0}) }}</td>
                    </tr>
                </table>
                <!-- Månad 1: {{ params.customersInitial * price }} €<br>
                <div v-for="index in 59" :key="index">
                    Månad {{ index + 1 }}:
                    {{ ((params.customersInitial + (index * params.customersMonthly)) * price) * (1 - (params.monthlyChurn / 100)) }}
                    €<br>
                </div> -->
            </div>
        </div>

    </div>
</template>
