<script setup>
    import { onMounted, ref, watch } from 'vue'
    import { useNotificationStore } from '@/stores/NotificationStore.js'

    const notificationStore = useNotificationStore()
    const echo = EchoFactory()
    const activeConversations = ref(0)
    const currentThread = ref(null)
    const displayChat = ref(false)
    const showPanel = ref(false)
    const newActivity = ref(false)
    // TODO move this into a central datastore
    const userCompanyId = ref(null)

    const loadSupportChatMessageThreads = () => {
        axios.get(route('support.chat.message.thread.index'))
            .then(response => {
                activeConversations.value = response.data.threads
                activeConversations.value.forEach((thread) => {
                    if( thread.has_unread_message ) {
                        newActivity.value = true
                    }

                    subscribeToThread(thread)
                })
        })
    }

    const subscribeToThread = (thread) => {
        echo
            .private('support.chat.thread.' + thread.id)
            .listen('.support.chat.thread.message', (e) => {
                newActivity.value = true

                notificationStore.addNotification({
                    type: 'info',
                    title: e.message.user.name,
                    message: e.message.content.substring(0, 48) + '...',
                    dismissAfter: 5000,
                })
            });
    }

    const activateMessageThread = (thread) => {
        currentThread.value = thread
        showPanel.value = false
        displayChat.value = true
    }

    const subscribeToThreads = async () => {
        const res = await axios.get(route('user.getCompanyId'))

        if (res.data.company_id) {
            userCompanyId.value = res.data.company_id
        }
    }

    const createMessageThread = () => {
        axios.post(route('support.chat.message.thread.create'))
            .then(response => {
                loadSupportChatMessageThreads()
                activateMessageThread(response.data.thread)
            })
    }

    const togglePanel = () => {
        newActivity.value = false
        showPanel.value = !showPanel.value
    }

    const createTicket = () => {
        axios.post(route('support.chat.message.thread.create-ticket', currentThread.value.id))
            .then(response => {
                notificationStore.addNotification({
                    type: 'success',
                    title: _mft('support:ticketCreated.notification'),
                    url: route('support.admin.ticket.show', response.data.ticket.id),
                    dismissAfter: 5000,
                })
            })
    }

    watch(() => userCompanyId, () => {
        echo
            .private('support.chat.company.' + userCompanyId.value)
            .listen('.support.chat.company.thread-created', (e) => {
                newActivity.value = true
                notificationStore.addNotification({
                    type: 'info',
                    title: e.thread.asking_user.name,
                    message: _mft('support:chat.newChatMessage'),
                    dismissAfter: 5000,
                })
            });
    }, {deep:true})

    onMounted(() => {
        loadSupportChatMessageThreads()
        subscribeToThreads()
    })

</script>
<template>
    <div class="relative flex items-center justify-center badge">
        <button
            class="relative flex items-center justify-center badge"
            @click="togglePanel()"
        >
            <div
                class="absolute flex items-center justify-center w-4 h-4 text-xs text-white transition rounded-full count -top-3 -right-3 bg-myflowPurple-400"
                v-if="newActivity"
            ></div>
            <svg
                class="w-5 h-5"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                stroke="currentColor"
            >
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 13.5997 2.37562 15.1116 3.04346 16.4525C3.22094 16.8088 3.28001 17.2161 3.17712 17.6006L2.58151 19.8267C2.32295 20.793 3.20701 21.677 4.17335 21.4185L6.39939 20.8229C6.78393 20.72 7.19121 20.7791 7.54753 20.9565C8.88837 21.6244 10.4003 22 12 22Z" stroke-width="1.5"/>
            </svg>
        </button>

        <div
            class="absolute z-30 w-64 p-4 mt-2 transform -translate-x-1/2 bg-white rounded-lg shadow-2xl top-full left-1/2"
            v-if="showPanel"
        >
            <ul>
                <li
                    v-for="thread in activeConversations"
                    class="py-1"
                >
                    <button
                        @click="activateMessageThread(thread)"
                        class="text-left"
                    >
                        <div>
                            {{ thread.asking_user.name }}
                        </div>
                        <div class="text-xs text-gray-400">
                            {{ thread.supporting_company.name }}
                        </div>
                    </button>
                </li>
                <li
                    v-if="activeConversations.length === 0"
                    class="mt-2"
                >
                    <button
                        @click="createMessageThread()"
                        class="px-2 py-1 bg-black rounded-md text-white w-full"
                    >
                        {{ _mft('support:chat.createNewMessageThread') }}
                    </button>
                </li>
            </ul>
        </div>
        <modal-slideout-with-slots
            v-if="displayChat"
            @close="displayChat = false"
        >
            <template #title>
                <h3>{{ _mft('support:chat.title') }}</h3>
                <div
                    v-if="currentThread.current_user_is_supporter"
                >
                    <p
                        class="mb-0"
                    >
                        {{ _mft('support:chat.with') }} {{ currentThread.asking_user.name }}
                    </p>
                    <div class="text-right">
                        <button
                            v-if="! currentThread.has_support_ticket"
                            class="underline"
                            @click="createTicket"
                        >
                            {{ _mft('support:ticket.create') }}
                        </button>
                        <span v-else>{{ _mft('support:ticket.created') }}</span>
                    </div>
                </div>
            </template>
            <template #body>
                <support-chat
                    :messageThreadId="currentThread.id"
                    messageListClass="h-full"
                />
            </template>
        </modal-slideout-with-slots>
    </div>
</template>
