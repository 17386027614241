<script setup>
import { reactive } from 'vue'

const props = defineProps({
    expertContractId: {
        type: Number,
        required: true
    },
    expertContractTitle: {
        type: String,
        required: false,
        default: ''
    }
})

const state = reactive({
    creating: false,
    templateTitle: props.expertContractTitle,
    submitting: false,
})

const submit = () => {
    if (state.submitting) { return; }
    state.submitting = true;
    axios.post(route('expert-contracts.create-template', props.expertContractId), {title: state.templateTitle})
        .then(response => {
            window.location.assign(response.data.redirect_url);
        })
        .catch(error => {
            state.submitting = false;
            console.error(error)
        }
    )
}

</script>
<template>
    <div class="relative">
        <button
            class="myflow-basic-button--secondary whitespace-nowrap"
            @click="state.creating = true"
        >
            {{ _mft('expertContract:saveAsTemplate') }}
        </button>
        <div
            v-if="state.creating"
            class="absolute top-0 right-0 p-8 bg-white rounded-lg shadow-lg w-72"
        >
            <input-text maxlength="255" v-model:content="state.templateTitle" label="Mallnamn" />
            <div class="flex justify-end space-x-2">
                <button
                    @click="state.creating = false"
                    class="myflow-basic-button--danger whitespace-nowrap"
                >
                    {{ _mft('shared:cancel') }}
                </button>
                <button
                    @click="submit"
                    class="myflow-basic-button--success whitespace-nowrap"
                    :class="{'opacity-50 cursor-wait': state.submitting}"
                >
                    {{ _mft('expertContract:createTemplate') }}
                </button>
            </div>
        </div>
    </div>
</template>
