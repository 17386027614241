<template>
        <section class="mb-8">
            <h2 class="text-3xl">{{text.h2Text}}</h2>
            <p class=" text-2xl">{{text.pText}}</p>
        </section>
</template>

<script>
export default {
    props: {
        text: Object
    },
    data() {
        return {
            t: this.text
        };
    },
};


</script>

<style scoped>

</style>
