<template>
    <div class="mt-8">
        <update-alert
            v-if="notification"
            class="absolute z-50 top-8 right-8 lg:right-4"
        >
            <template v-slot:header>{{ notification }}</template>
        </update-alert>

        <success-banner v-if="(_products == 0)">
            <template v-slot:header>
                {{ _mft('coachingProduct:getStarted.title') }}
            </template>
            <template v-slot:body>
                {{ _mft('coachingProduct:getStarted.body') }}
            </template>
        </success-banner>

        <draggable
            role="list"
            class="grid gap-4 lg:grid-cols-2 2xl:grid-cols-3"
            id="teleport-target-products"
            :component-data="{
                tag: 'ul',
                type: 'transition-group',
            }"
            v-model="_products"
            v-bind="dragOptions"
            @start="drag = true"
            @end="drag = false"
            item-key="id"
        >
            <template #item="{ element }">
                <card-basic class="relative">
                    <template v-slot:header>
                        <img
                            v-if="element.featured_image"
                            class="w-1/3 mb-4 rounded-full"
                            :src="element.featured_image"
                            :alt="_mft('coachingProduct:featuredImageFor') + ' ' + element.title"
                        />
                        <h2 class="pr-8 cursor-move lg:pr-2">{{ element.title }}</h2>
                    </template>
                    <template v-slot:body>
                        <i class="absolute mt-2 text-xl cursor-move text-wlPrimary fas fa-arrows top-1 right-4 lg:top-4 lg:right-8"></i>
                        <div v-html="element.description"></div>
                        <div class="flex justify-center">
                            <div
                                v-if="!element.published"
                                v-on:click="editProduct(element)"
                                class="px-2 py-1 m-1 mt-2 -mb-6 text-xs text-center text-white transform rounded-lg cursor-pointer bg-wlPrimary-400"
                            >{{  _mft('coachingProduct:notPublished') }}</div>
                            <div
                                v-if="element.wellness_applied_at && !element.wellness_confirmed_at"
                                class="px-2 py-1 m-1 mt-2 -mb-6 text-xs text-center text-white transform rounded-lg cursor-pointer bg-myflowGreen-400"
                            >{{  _mft('coachingProduct:awaitingApproval') }}</div>
                            <div
                                v-if="element.wellness_confirmed_at"
                                class="px-2 py-1 m-1 mt-2 -mb-6 text-xs text-center text-white transform rounded-lg cursor-pointer bg-myflowGreen-800"
                            >{{ _mft('coachingProduct:wellnessApproved') }}</div>
                        </div>

                        <hr class="mt-3 mb-3">
                        <i class="mr-2 text-wlPrimary fa-light fa-money-bill-wave"></i> {{ (element.price_including_vat / 100).toLocaleString('en-US').replace(',', ' ')  }} kr<br>
                        <i class="mr-2 text-wlPrimary fa-light fa-clock"></i> {{ _mft('coachingProduct:sessionDuration.label', {count: element.duration_quarters * 15 }) }}<br>
                        <i class="mr-2 text-wlPrimary fa-light fa-clone"></i> {{ _mft('coachingProduct:sessionQuantity.label', {count: element.session_quantity }) }}
                        <div class="flex flex-wrap gap-2 mt-3 text-xs">
                            <button
                                v-if="!element.wellness_applied_at"
                                class="myflow-basic-button"
                                v-on:click="editProduct(element)"
                            >
                                {{ _mft('shared:edit') }}
                            </button>
                            <button
                                v-if="element.deletable"
                                class="myflow-basic-button--danger"
                                @click.prevent="destroyProduct(element)"
                            >
                                {{ _mft('shared:delete') }}
                            </button>
                        </div>
                    </template>
                </card-basic>
            </template>
        </draggable>
        <Teleport
            v-if="doneLoading"
            to="#teleport-target-products"
        >
            <div class="w-full h-full pb-10">
                <div class="relative flex flex-col items-center justify-center h-full p-4 pt-3 mb-10 overflow-hidden border-2 border-gray-200 border-dashed rounded-card md:p-6 lg:p-8 md:pt-5 lg:pt-6">
                    <p
                        v-if="_products.length > 1"
                        class="text-gray-400"
                    >{{ _mft('coachingProduct:dragAndDropToChangeOrder') }}</p>
                    <button
                        class="myflow-basic-button"
                        v-on:click="createOpen = !createOpen"
                        id="create-coaching-product-button"
                    >
                        {{ _mft('coachingProduct:addProduct') }}
                    </button>
                </div>
            </div>
        </Teleport>
        <div>
            <basic-slideout
                v-model:open="createOpen"
            >
                <template v-slot:header>
                    <h1>{{ _mft('coachingProduct:addProduct') }}</h1>
                </template>

                <template v-slot:body>
                    <create-coaching-product />
                </template>
            </basic-slideout>

            <basic-slideout
                v-model:open="editOpen"
            >
                <template v-slot:header>
                    <h1>{{ _mft('coachingProduct:editProduct') }}</h1>
                </template>

                <template v-slot:body>
                    <edit-coaching-product
                        :product="editingProduct"
                    />
                </template>
            </basic-slideout>
        </div>
    </div>
</template>
<script>
import draggable from 'vuedraggable';

export default {
    components: {
        draggable
    },
    props: {
        products: {
            type: Object,
            default: {}
        },
    },
    data() {
        return {
            _products: [],
            createOpen: false,
            editOpen: false,
            editingProduct: {},
            dragOptions: {
                animation: 200,
                group: 'description',
                disabled: false,
                ghostClass: 'ghost',
            },
            drag: false,
            doneLoading: false,
            notification: ''
        }
    },
    methods: {
        editProduct(product){
            if(!product.wellness_applied_at) {
                this.editingProduct = product
                this.editOpen = true
            }
        },
        async setFeatured(product)
        {
            let res = await axios.post(route('coaching.products.setFeatured', product.id))

            if(res.status === 200){
                this._products.forEach(p => {
                    p.featured = false
                });
                product.featured = true
                this.notification = _mft('shared:done')
                let self = this
                setTimeout(function(){
                    self.notification = ''
                }, 3000)
            }
        },
        async unsetFeatured(product)
        {
            let res = await axios.post(route('coaching.products.unsetFeatured', product.id))

            if(res.status === 200){
                this._products.forEach(p => {
                    p.featured = false
                });

                this.notification = _mft('shared:done')
                let self = this
                setTimeout(function(){
                    self.notification = ''
                }, 3000)
            }
        },
        async saveProductOrder()
        {
            const product_ids = this._products.map(function (product, index, array) {
                return product.id;
            });

            let res = await axios.post(route('coaching.products.updateOrder', {product_ids: product_ids }))

            this.notification = _mft('coachingProduct:orderSaved')
            let self = this
            setTimeout(function(){
                self.notification = ''
            }, 3000)
        },
        async destroyProduct(product)
        {
            const response = await axios.delete(route('coaching.destroyProduct', product.id));
            this.$document.locationAssign(response.data.redirect);
        }
    },
    watch: {
        drag: function(val){
            if( val === false ){
                this.saveProductOrder()
            }
        }
    },
    mounted: function(){
        this.doneLoading = true
        this._products = this.products
    }
};
</script>
