<script setup>
import addItem from '../../components/navigation/settings/addItem.vue'
import editItem from '../../components/navigation/settings/editItem.vue'
import { computed, onMounted, ref } from 'vue'
import ButtonWithConfirmation from "../../components/buttons/buttonWithConfirmation.vue";
import SlideoutWithSlots from "../../components/elements/slideoutWithSlots.vue";
import IconPen from "../../components/icons/iconPen.vue";
import IconTrashcan from "../../components/icons/iconTrashcan.vue";
import { useNotificationStore } from '@/stores/NotificationStore.js'
import FrostedBar from "../../components/layout/pageHeadings/frostedBar.vue";

const notificationStore = useNotificationStore()

const props = defineProps({
    title: {
        type: String,
        required: false,
        default: () => _mft('setting:navigation.title')
    },
    navigationType: {
        type: String,
        required: true
    }
})

const navigationItems = ref([])
const openSlot = ref(null)
const editingItem = ref(null)
const previewDevice = ref(null)
const savingState = ref('')
const contentLoaded = ref(false)


const loadItems = async () => {
    const res = await axios.get(route('settings.navigation.show', {type: props.navigationType}))
    navigationItems.value = res.data.items
}

const selectSlot = (slotIndex) => {
    openSlot.value = slotIndex
}

const appendItem = (item) => {
    if (openSlot.value.length === 1) {
        navigationItems.value[openSlot.value[0]].children.push(item)
    } else {
        navigationItems.value.push(item)
    }
    openSlot.value = null
}

const removeItem = async (item) => {
    await axios.delete(route('settings.navigation.destroy', item));
    await loadItems();
}

const selectForEditing = (item) => {
    editingItem.value = item
}

const itemUpdated = () => {
    editingItem.value = null
}

const shiftItemUp = (index) => {
    if (index === 0) {
        return
    }
    const temp = navigationItems.value[index]
    navigationItems.value[index] = navigationItems.value[index - 1]
    navigationItems.value[index - 1] = temp

    for (let i = 0; i < navigationItems.value.length; i++) {
        navigationItems.value[i].order = i
    }

}

const shiftItemDown = (index) => {
    if (index === navigationItems.value.length - 1) {
        return
    }

    const temp = navigationItems.value[index]
    navigationItems.value[index] = navigationItems.value[index + 1]
    navigationItems.value[index + 1] = temp

    for (let i = 0; i < navigationItems.value.length; i++) {
        navigationItems.value[i].order = i
    }

}

const shiftSubItemUp = (item, index) => {
    if (index === 0) {
        return
    }
    const temp = item.children[index]
    item.children[index] = item.children[index - 1]
    item.children[index - 1] = temp

    for (let i = 0; i < item.children.length; i++) {
        item.children[i].order = i
    }

}

const shiftSubItemDown = (item, index) => {
    if (index === item.children.length - 1) {
        return
    }
    const temp = item.children[index]
    item.children[index] = item.children[index + 1]
    item.children[index + 1] = temp

    for (let i = 0; i < item.children.length; i++) {
        item.children[i].order = i
    }

}

const storeNavigation = async () => {
    savingState.value = 'waiting'
    const res = await axios.post(route('settings.navigation.store'), {
        items: navigationItems.value,
        type: props.navigationType
    })

    if (res.data.success) {
        savingState.value = 'success'
    } else {
        savingState.value = 'error'
    }

    loadItems();
}

const slideoutClosed = () => {
    openSlot.value = null
    editingItem.value = null
}

const togglePreview = (devicetype) => {
    if (previewDevice.value === devicetype) {
        previewDevice.value = null
        return
    }
    previewDevice.value = devicetype
}

const navigationPreview = computed(() => {
    return navigationItems.value.map(item => {
        return {
            name: item.label,
            href: item.href,
            subItems: item.children.map(subItem => {
                return {
                    name: subItem.label,
                    href: subItem.href
                }
            })
        }
    })
})

onMounted(() => {
    loadItems()
    contentLoaded.value = true;
})

</script>

<template>
    <div
        class="pb-8 transition-all"
        :class="previewDevice === 'desktop' || previewDevice === 'tablet' ? 'pt-24' : ''"
    >
        <div
            class="mb-4 w-full shadow-md z-topbar"
            :class="previewDevice === 'desktop' || previewDevice === 'tablet' ? 'fixed top-0 left-0 right-0' : 'hidden'"
        >
            <div class="relative h-16 px-4 py-2">
                <div class="absolute inset-0 bg-white/80 backdrop-blur-lg"></div>

                <div
                    class="relative mx-auto flex items-center justify-between"
                    :class="previewDevice === 'desktop' ? 'max-w-5xl' : 'max-w-3xl'"
                >
                    <div class="flex w-full items-center md:space-x-4">
                        <div>
                            <logo-icon class="mr-4 h-8 w-auto text-myflowPurple" />
                        </div>
                        <ul class="fixed inset-0 h-screen w-full overflow-x-hidden bg-white py-4 md:space-x-4 md:static md:flex md:h-full md:bg-transparent">
                            <li
                                v-for="(item, i) in navigationPreview"
                                class="flex items-center pr-4"
                            >
                                <customer-area-primary-navigation-item
                                    :item="item"
                                >
                                    <a
                                        :href="item.href"
                                        target="_blank"
                                        class="whitespace-nowrap py-3 pl-4 text-xs font-bold text-wlNavigation hover:underline focus:underline focus:outline-none md:px-0 md:py-0"
                                    >{{ item.name }}</a>
                                </customer-area-primary-navigation-item>
                            </li>
                        </ul>
                    </div>
                    <div>
                    </div>
                </div>
            </div>
        </div>

        <div class="md:flex space-x-4 justify-between items-center">
            <h2 class="mb-4">{{ props.title }}</h2>
            <div class="flex justify-end space-x-2 items-center">
                <button-success
                    @click="storeNavigation"
                    :disabled="savingState === 'waiting'"
                    :state="savingState"
                >{{ _mft('shared:action.save') }}</button-success>
            </div>
        </div>

        <div class="lg:grid lg:grid-cols-3 lg:gap-8">
            <div class="col-span-2">
                <ul
                    class="space-y-2"
                >
                    <li
                        v-for="(item, i) in navigationItems"
                        :key="i"
                        class="flex rounded-md bg-white p-2 md:p-4 shadow-md stretch"
                    >
                        <div class="-mt-2 mr-0.5 md:mr-2 flex flex-col">
                            <button
                                class="border-0 bg-transparent group"
                                @click="shiftItemUp(i)"
                            >
                                <svg
                                    aria-hidden="true"
                                    class="h-4 w-4 rotate-180 fill-gray-300 group-hover:fill-wlPrimary"
                                    viewBox="0 0 320 512" xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path>
                                </svg>
                            </button>
                            <button
                                class="border-0 bg-transparent group"
                                @click="shiftItemDown(i)"
                            >
                                <svg
                                    aria-hidden="true"
                                    class="h-4 w-4 fill-gray-300 group-hover:fill-wlPrimary"
                                    viewBox="0 0 320 512" xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path>
                                </svg>
                            </button>
                        </div>
                        <div class="w-full">
                            <div class="flex items-center justify-between pb-1 space-x-2 group">
                                <span class="font-bold">{{ item.label }}</span>
                                <div class="flex items-center space-x-2">
                                    <button-with-confirmation
                                        v-if="item.children.length === 0"
                                        @confirmed="removeItem(item.id)"
                                        size="sm"
                                        modalClass="absolute -top-2 -right-2 p-2 bg-white rounded shadow-md z-30"
                                        :confirmButtonLabel="_mft('setting:navigation.item.remove')"
                                        :confirm-text="_mft('setting:navigation.item.remove.confirm')"
                                    >
                                        <IconTrashcan class="h-3 w-3" />
                                    </button-with-confirmation>
                                    <button-primary
                                        size="sm"
                                        @click="selectForEditing(item)"
                                    >
                                        <IconPen class="h-3 w-3" />
                                    </button-primary>
                                </div>
                            </div>

                            <ul
                                class="ml-0.5 md:ml-2 w-full border-l pl-0.5 md:pl-2 space-y-1"
                            >
                                <li
                                    v-for="(subItem, si) in item.children"
                                    :key="si"
                                    class="flex w-full pr-2"
                                >
                                    <div class="mr-2 flex flex-col">
                                        <button
                                            class="border-0 bg-transparent group"
                                            @click="shiftSubItemUp(item, si)"
                                        >
                                            <svg
                                                aria-hidden="true"
                                                class="h-4 w-4 rotate-180 fill-gray-300 group-hover:fill-wlPrimary"
                                                viewBox="0 0 320 512" xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path>
                                            </svg>
                                        </button>
                                        <button
                                            class="border-0 bg-transparent group"
                                            @click="shiftSubItemDown(item, si)"
                                        >
                                            <svg
                                                aria-hidden="true"
                                                class="h-4 w-4 fill-gray-300 group-hover:fill-wlPrimary"
                                                viewBox="0 0 320 512" xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z"></path>
                                            </svg>
                                        </button>
                                    </div>
                                    <div class="flex w-full items-center justify-between space-x-2 group">
                                        <span>{{ subItem.label }}</span>
                                        <div class="flex items-center space-x-2">
                                            <button-with-confirmation
                                                @confirmed="removeItem(subItem.id)"
                                                size="sm"
                                                modalClass="absolute -top-2 -right-2 p-2 bg-white rounded shadow-md z-30"
                                                confirmButtonLabel="Ta bort"
                                                confirm-text="Vill du ta bort alternativet?"
                                            >
                                                <IconTrashcan class="h-3 w-3" />
                                            </button-with-confirmation>
                                            <button-primary
                                                size="sm"
                                                @click="selectForEditing(subItem)"
                                            >
                                                <IconPen class="h-3 w-3" />
                                            </button-primary>
                                        </div>
                                    </div>
                                </li>

                                <li>
                                    <button-base
                                        @click="selectSlot([i])"
                                        class="space-x-1"
                                        size="sm"
                                    >
                                        <template #icon>
                                            <svg
                                                class="-ml-1 h-4 w-4 fill-gray-400"
                                                viewBox="0 0 32 32"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                            >
                                                <g id="Page-1" stroke="none" stroke-width="1" fill-rule="evenodd">
                                                    <g id="Icon-Set-Filled" transform="translate(-466.000000, -1089.000000)">
                                                        <path d="M488,1106 L483,1106 L483,1111 C483,1111.55 482.553,1112 482,1112 C481.447,1112 481,1111.55 481,1111 L481,1106 L476,1106 C475.447,1106 475,1105.55 475,1105 C475,1104.45 475.447,1104 476,1104 L481,1104 L481,1099 C481,1098.45 481.447,1098 482,1098 C482.553,1098 483,1098.45 483,1099 L483,1104 L488,1104 C488.553,1104 489,1104.45 489,1105 C489,1105.55 488.553,1106 488,1106 L488,1106 Z M482,1089 C473.163,1089 466,1096.16 466,1105 C466,1113.84 473.163,1121 482,1121 C490.837,1121 498,1113.84 498,1105 C498,1096.16 490.837,1089 482,1089 L482,1089 Z">

                                                        </path>
                                                    </g>
                                                </g>
                                            </svg>
                                        </template>
                                        <span>{{ _mft('setting:navigation.item.addChildItem') }}</span>
                                    </button-base>
                                </li>
                            </ul>
                        </div>
                    </li>

                    <li class="mt-2 rounded-wl bg-white p-4 shadow-xl">
                        <button-base
                            @click="selectSlot([])"
                            size="sm"
                            class="flex space-x-1"
                        >
                            <template #icon>
                                <svg
                                    class="-ml-1 h-4 w-4 fill-gray-400"
                                    viewBox="0 0 32 32"
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                >
                                    <g id="Page-1" stroke="none" stroke-width="1" fill-rule="evenodd">
                                        <g id="Icon-Set-Filled" transform="translate(-466.000000, -1089.000000)">
                                            <path d="M488,1106 L483,1106 L483,1111 C483,1111.55 482.553,1112 482,1112 C481.447,1112 481,1111.55 481,1111 L481,1106 L476,1106 C475.447,1106 475,1105.55 475,1105 C475,1104.45 475.447,1104 476,1104 L481,1104 L481,1099 C481,1098.45 481.447,1098 482,1098 C482.553,1098 483,1098.45 483,1099 L483,1104 L488,1104 C488.553,1104 489,1104.45 489,1105 C489,1105.55 488.553,1106 488,1106 L488,1106 Z M482,1089 C473.163,1089 466,1096.16 466,1105 C466,1113.84 473.163,1121 482,1121 C490.837,1121 498,1113.84 498,1105 C498,1096.16 490.837,1089 482,1089 L482,1089 Z">

                                            </path>
                                        </g>
                                    </g>
                                </svg>
                            </template>
                            <span>{{ _mft('setting:navigation.item.addTopLevelItem') }}</span>
                        </button-base>
                    </li>
                </ul>
            </div>
            <div class="hidden">
                <h2>{{ _mft('shared:preview') }}</h2>
                <div class="flex space-x-2">
                    <button-secondary
                        @click="togglePreview('desktop')"
                    >
                        {{ _mft('setting:navigation.preview.computer') }}
                    </button-secondary>
                    <button-secondary
                        @click="togglePreview('tablet')"
                    >
                        {{ _mft('setting:navigation.preview.tablet') }}
                    </button-secondary>
                    <!--                    <button-secondary-->
                    <!--                        @click="togglePreview('phone')"-->
                    <!--                    >-->
                    <!--                        Telefon-->
                    <!--                    </button-secondary>-->
                </div>
            </div>
        </div>

        <slideout-with-slots
            v-if="openSlot !== null || editingItem !== null"
            @close="slideoutClosed"
        >
            <template #body>
                <addItem
                    v-if="openSlot !== null"
                    :navigation-type="props.navigationType"
                    @itemAdded="appendItem"
                    @cancel="openSlot = null"
                />
                <editItem
                    v-if="editingItem !== null"
                    :navigation-type="props.navigationType"
                    :item="editingItem"
                    @cancel="editingItem = null"
                    @itemUpdated="itemUpdated"
                />

            </template>
        </slideout-with-slots>
    </div>
</template>
