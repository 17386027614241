<script setup>
    import { reactive, ref, watch } from 'vue'
    import { useNotificationStore } from '@/stores/NotificationStore.js'
    const notificationStore = useNotificationStore()

    const props = defineProps({
        currentUserId: {
            type: Number,
            required: true
        },
        statuses: {
            type: Object,
            required: true
        },
        ticket: {
            type: Object,
            required: true
        }
    })

    const displayChat = ref(false)
    const _ticket = reactive(props.ticket)

    const updateTicket = async () => {
        const res = await axios.post(route('support.admin.ticket.update-status', _ticket.id), {
            ticket_status: _ticket.ticket_status
        })

        notificationStore.addNotification({
            type: 'success',
            message: 'Status uppdaterad',
            dismissAfter: 2500,
        })

    }

    watch(_ticket, (newValue, oldValue) => {
        updateTicket()
    })

</script>
<template>
    <div class="w-full">
        <a :href="route('support.admin.ticket.index')">Tillbaka till ärendelistan</a>
        <div class="flex items-center justify-between mb-4">
            <h1>Supportärende</h1>
        </div>
        <card-basic>
            <template v-slot:body>
                <div
                    class="grid grid-cols-2 gap-4 lg:gap-8"
                >
                    <div>
                        <info-field
                            class="mb-2"
                            label="Ärende skapat"
                            :value="ticket.created_at.display.full"
                        />
                        <info-field
                            class="mb-2"
                            label="Användare"
                            :value="ticket.user.name"
                        />
                        <info-field
                            class="mb-2"
                            label="Företag"
                            :value="ticket.company.name"
                        />

                        <button
                            class="myflow-basic-button"
                            @click="displayChat = !displayChat"
                        >
                            <template v-if="!displayChat">Visa chatt</template>
                            <template v-if="displayChat">Dölj chatt</template>
                        </button>

                        <modal-slideout-with-slots
                            v-if="displayChat"
                            @close="displayChat = false"
                        >
                            <template #title>
                                <h3>Support-chatt</h3>
                                <p class="mb-0">Med {{ ticket.user.name }}</p>
                            </template>
                            <template #body>
                                <support-chat
                                    :messageThreadId="props.ticket.message_thread_id"
                                    :currentUserId="props.currentUserId"
                                    messageListClass="h-full"
                                />
                            </template>
                        </modal-slideout-with-slots>
                    </div>
                    <div>
                        <input-select
                            v-model:content="_ticket.ticket_status"
                            :options="statuses"
                            label="Status"
                            required
                            placeholder=""
                            name="ticket_status"
                        />
                    </div>
                </div>
            </template>
        </card-basic>
    </div>
</template>
