<script setup>
    import {computed, onMounted, ref} from 'vue';
    import IconValidates from "../icons/iconValidates.vue";

    const props = defineProps({
        markClass: {
            type: String,
            required: false,
            default: 'w-8 h-8'
        }
    })

    const transitionIn = ref(false);

    const validatesClass = computed(() => {
        return transitionIn.value ? 'opacity-100' : 'opacity-0 validates-transitioned-out'
    })

    onMounted(() => {
        setTimeout(function(){
            transitionIn.value = true
        }, 100)
    })
</script>
<template>
  <span class="relative">
      <icon-validates
          class="text-sm fa-solid fa-check absolute left-0 top-0 transition-all duration-150"
          :class="[validatesClass, markClass]"
      />
  </span>
</template>
<style scoped>
span > * {
    transition: all 0.15s cubic-bezier(0.4, 0, 0.2, 1);

}

.validates-transitioned-out {
    opacity: 0;
    transform: scale(4);
}
</style>
