<script setup>
import {  computed } from "vue";

const props = defineProps({
    content: {
        type: Object,
        required: true,
    }
})

const headline = computed(() => {
    if (props.content?.title?.includes('[name]') && window.location.search.includes('name') ) {
        return props.content.title.replace('[name]', new URLSearchParams(window.location.search).get('name'));
    } else {
        return props.content?.title ?? '';
    }
})

const alignmentClass = computed(() => {
    return props.content.alignment === 'left' ? 'text-left' : props.content.alignment === 'right' ? 'text-right' : 'text-center';
})

</script>

<template>
    <div v-if="content">
        <h1
            v-if="content.level === 'h1' || !content.level"
            class="my-6 text-2xl md:text-4xl lg:text-5xl"
            :class="alignmentClass"
        >{{ headline }}</h1>
        <h2
            v-if="content.level === 'h2'"
            class="my-6 text-xl md:text-3xl lg:text-4xl"
            :class="alignmentClass"
        >{{ headline }}</h2>
        <h3
            v-if="content.level === 'h3'"
            class="my-6 text-lg md:text-2xl lg:text-3xl"
            :class="alignmentClass"
        >{{ headline }}</h3>
    </div>
</template>
