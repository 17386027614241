<template>
    <div>
        <toggle-switch
            name="history_available"
            :label="_mft('subscription:settings.historyAvailable')"
            v-model:content="_historyAvailable"
        />
        <input-select
            v-if="_historyAvailable"
            :label="_mft('subscription:settings.newSubscribersGetAccessTo')"
            :required="true"
            :options="historyAvailabilityTypes"
            labelSpecification="label"
            valueSpecification="value"
            v-model:content="_historyAvailabilityType"
            name="history_availability_type"
        />
        <input-select
            v-if="_historyAvailabilityType === 'days'"
            :label="_mft('subscription:settings.howFarBack')"
            :required="true"
            :options="historyAvailabilityValues"
            labelSpecification="label"
            valueSpecification="value"
            v-model:content="_historyAvailabilityValue"
            name="history_availability_value"
        />
    </div>
</template>
<script>
export default {
    components: {
    },
    props: {
        historyAvailable: {
            type: Boolean,
            required: true
        },
        historyAvailabilityType: {
            type: String,
            required: true
        },
        historyAvailabilityValue: {
            type: String,
            required: true
        }
    },
    methods: {
    },
    data() {
        return {
            _historyAvailable: this.historyAvailable,
            _historyAvailabilityType: this.historyAvailabilityType,
            _historyAvailabilityValue: this.historyAvailabilityValue,
            historyAvailabilityTypes: [
                {
                    value: 'all',
                    label: _mft('subscription:settings.historyAvailable.options.all'),
                },
                {
                    value: 'month',
                    label: _mft('subscription:settings.historyAvailable.options.month'),
                },
                {
                    value: 'days',
                    label: _mft('subscription:settings.historyAvailable.options.days'),
                },
            ],
            historyAvailabilityValues: [
                {
                    value: 7,
                    label: _mft('subscription:settings.availability.oneWeek'),
                },
                {
                    value: 14,
                    label: _mft('subscription:settings.availability.twoWeeks'),
                },
                {
                    value: 30,
                    label: _mft('subscription:settings.availability.oneMonth'),
                },
                {
                    value: 90,
                    label: _mft('subscription:settings.availability.threeMonths'),
                },
                {
                    value: 365,
                    label: _mft('subscription:settings.availability.oneYear'),
                },
            ]
        }
    },
    mounted() {
    }
}
</script>
