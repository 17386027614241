<template>

    <div class="flex">
        <button
            type="button"
            class="myflow-basic-button"
            v-on:click="openInSlideout"
        >
          {{ _mft('sharing:shareSlideover.shareWithFriendOrColleague') }}
        </button>
    </div>

    <basic-slideout
        v-model:open="open"
    >
        <template v-slot:header>
            <h1>{{ _mft('sharing:shareSlideover.whoShareWith') }}</h1>
        </template>

        <template v-slot:body>

          <h2>{{ _mft('sharing:shareSlideover.shareWith') }}</h2>
          <div>
            <button
              v-on:click="changeShareType('friend')"
              type="button"
              class="w-full mt-3"
              :class="shareTypeSelected('friend')"
            >{{ _mft('sharing:shareSlideover.share.withAFriend') }}</button>
          </div>
          <div>
            <button
              v-on:click="changeShareType('expert')"
              type="button"
              class="w-full mt-3"
              :class="shareTypeSelected('expert')"
            >{{ _mft('sharing:shareSlideover.share.withAnExpert') }}</button>
          </div>
          <!-- <div v-if="this.companySharing">
            <button
              v-on:click="changeShareType('coworker')"
              type="button"
              class="w-full mt-3"
              :class="shareTypeSelected('coworker')"
            >En kollega</button>
          </div> -->


          <div
            v-if="shareType"
            class="mt-3"
          >
            <h2>Jag vill dela genom att...</h2>
            <div>
              <button
                v-on:click="changeShareMedia('qr')"
                type="button"
                class="w-full mt-3 myflow-basic-button"
                :class="shareMediaSelected('qr')"
              >Dela QR-kod</button>
            </div>
            <div>
              <button
                v-on:click="changeShareMedia('link')"
                type="button"
                class="w-full mt-3 myflow-basic-button"
                :class="shareMediaSelected('link')"
              >Skicka en länk</button>
            </div>
            <div>
              <button
                v-on:click="changeShareMedia('email')"
                type="button"
                class="w-full mt-3 myflow-basic-button"
                :class="shareMediaSelected('email')"
              >Skicka ett mail</button>
            </div>
            <div v-if="this.shareType == 'coworker'">
              <button
                v-on:click="changeShareMedia('ping')"
                type="button"
                class="w-full mt-3 myflow-basic-button"
                :class="shareMediaSelected('ping')"
              >Pinga inne på myflow</button>
            </div>
          </div>


          <!-- CO WORKER SHARE -->
          <div
            class="mt-4"
            v-if="this.shareType == 'coworker'"
          >
            <div
              v-if="shareMedia == 'qr'"
            >
              <p>Den du delar med kan scanna QR-koden och kommer direkt till rätt del på vår plattform. </p>
              <vue-qr
                :text="visitUrl"
                :size="380"
                :correctLevel="3"
              ></vue-qr>
            </div>

            <div
              v-if="shareMedia == 'link'"
            >
              <p>Du kan direkt dela länken och när de klickar på den kommer de direkt till rätt del på vår plattform. </p>
              <code
                class="text-myflowPurple"
              ><i>{{ this.visitUrl }}</i></code>
            </div>

            <div v-if="shareMedia == 'ping'">
              <div
                v-for="worker in this.coWorkerList"
                v-bind:key="worker.id"
              >
                <p>Personerna du pingar och tipsar om detta kommer få en notis inne på myflow.</p>
                <button
                  type="button"
                  class="float-right myflow-basic-button"
                >Pinga</button>
                <span class="mt-3 font-semibold">{{ worker.name }} - {{ worker.email }}</span>
                <hr class="mt-4 mb-4">
              </div>
            </div>

            <div v-if="shareMedia == 'email'">
              <form @submit.prevent="submit">
                <input-text
                  placeholder=""
                  label="Namn"
                  :required="true"
                  v-model:content="emailName"
                  :content="emailName"
                  name="name"
                ></input-text>
                <input-text
                  placeholder=""
                  label="E-post"
                  :required="true"
                  v-model:content="emailEmail"
                  :content="emailEmail"
                  name="name"
                ></input-text>
                <input-text
                  placeholder=""
                  label="Jag tror att du vill kolla på detta för att..."
                  :required="true"
                  v-model:content="emailPurpose"
                  :content="emailPurpose"
                  name="name"
                ></input-text>
                <div class="grid-cols-2 gap-8 md:grid">
                  <div class="col-span-1">
                    <button
                      type="submit"
                      class="w-full myflow-basic-button"
                    >Skicka inbjudan</button>
                  </div>
                  <div class="col-span-1">
                    <button
                      v-on:click="openPreview()"
                      type="button"
                      class="w-full opacity-50 myflow-basic-button"
                    >Förhandsvisa</button>
                  </div>
                </div>
              </form>
              <div
                v-if="this.previewEmail"
              >
                <hr class="mt-4 mb-4">
                <b>Namn: {{ emailName }}</b> <br>
                <b>E-post: {{ emailEmail }}</b> <br>
                <b>Innehåll: </b> <br>
                <i>
                  Hej {{ emailName }}!<br>&nbsp;<br>

                  Jag tänkte tipsa dig om detta på <b>myflow</b>.<br>&nbsp;<br>

                  <div class="p-3 bg-gray-200">
                    <b>{{ sharingData.title }}</b><br>
                    <div v-html="sharingData.description"></div>
                  </div>

                  <br>&nbsp;<br><b>Jag tror att du att du hade gillat detta för att...</b><br>
                  {{ emailPurpose }}

                  <br>&nbsp;<br>
                  <a :href="shareUrl" class="myflow-basic-button" target="_blank">Kolla mer</a>

                  <br>&nbsp;<br>
                  <b>Mvh, {{ userData.name }} & myflow</b>
                </i>
                <br>&nbsp;<br>
                <br>&nbsp;<br>
              </div>
            </div>
          </div>

          <!-- FRIEND SHARE -->
          <div
            class="mt-4"
            v-if="this.shareType == 'friend'"
          >
            <h5>Dela med en vän</h5>

            <div v-if="this.shareMedia == 'link'">
              <p>Med länken nedan får den du bjuder in se två föreläsningar!</p>
              <span class="text-myflow"><i>{{ this.redirectURL }}</i></span>
            </div>

            <div v-if="this.shareMedia == 'qr'">
              <p>Den du delar med kan scanna QR-koden och kommer direkt till rätt del på vår plattform. </p>
              <vue-qr :text="shareUrl" :size="380" :correctLevel="3"></vue-qr>
            </div>
          </div>

          <!-- EXPERT SHARE -->
          <div
            class="mt-4"
            v-if="this.shareType == 'expert'"
          >
            <h5>Tipsa en expert</h5>

            <div v-if="this.shareMedia == 'link'">
              <span class="text-myflow"><i>{{ this.redirectURL }}/expert</i></span>
            </div>

            <div v-if="this.shareMedia == 'qr'">
              <p>Den du delar med kan scanna QR-koden och kommer direkt till rätt del på vår plattform. </p>
              <vue-qr :text="shareUrl + '/expert'" :size="380" :correctLevel="3"></vue-qr>
            </div>
          </div>

          <div v-if="shareMedia == 'email'">
            <form @submit.prevent="submit">
              <input-text
                placeholder=""
                label="Namn"
                :required="true"
                v-model:content="emailName"
                :content="emailName"
                name="name"
              ></input-text>
              <input-text
                placeholder=""
                label="E-post"
                :required="true"
                v-model:content="emailEmail"
                :content="emailEmail"
                name="name"
              ></input-text>
              <input-text
                placeholder=""
                label="Jag tror att du vill kolla på detta för att..."
                :required="true"
                v-model:content="emailPurpose"
                :content="emailPurpose"
                name="name"
              ></input-text>
              <div class="grid-cols-2 gap-8 md:grid">
                <div class="col-span-1">
                  <button
                    type="submit"
                    class="w-full myflow-basic-button"
                  >Skicka</button>
                </div>
                <div class="col-span-1">
                  <button
                    v-on:click="openPreview()"
                    type="button"
                    class="w-full opacity-50 myflow-basic-button"
                  >Förhandsvisa</button>
                </div>
              </div>
            </form>
            <div
              v-if="this.previewEmail"
            >
              <hr class="mt-4 mb-4">
              <b>Namn: {{ emailName }}</b> <br>
              <b>E-post: {{ emailEmail }}</b> <br>
              <b>Innehåll: </b> <br>
              <i>
                Hej {{ emailName }}!<br>&nbsp;<br>

                Jag tänkte tipsa dig om detta på <b>myflow</b>.<br>&nbsp;<br>

                <div class="p-3 bg-gray-200">
                  <b>{{ sharingData.title }}</b><br>
                  <div v-html="sharingData.description"></div>
                </div>

                <br>&nbsp;<br><b>Jag tror att du att du hade gillat detta för att...</b><br>
                {{ emailPurpose }}

                <br>&nbsp;<br>
                <a :href="shareUrl" class="myflow-basic-button" target="_blank">Kolla mer</a>

                <br>&nbsp;<br>
                <b>Mvh, {{ userData.name }} & myflow</b>
              </i>
              <br>&nbsp;<br>
              <br>&nbsp;<br>
            </div>
          </div>

        </template>
    </basic-slideout>
</template>
<script>

export default {
    props: {
      shareUrl: String,
      visitUrl: String,
      companyUser: Boolean,
      coWorkers: Object,
      sharingContext: Object,
      currentUser: Object
    },
    data: function(){
      return {
        open: false,
        redirectURL: this.shareUrl,
        companySharing: this.companyUser,
        shareType: null,
        shareMedia: null,
        coWorkerList: this.coWorkers,
        previewEmail: false,
        sharingData: this.sharingContext,
        userData: this.currentUser,
        emailName: '',
        emailEmail: '',
        emailPurpose: '',
      }
    },
    methods: {
      openInSlideout(){
        this.open = true;
      },
      closeSlideout(){
        this.open = false;
      },
      openPreview(){
        this.previewEmail = true;
      },
      changeShareType(type) {
        this.shareType = type;
      },
      changeShareMedia(type) {
        this.shareMedia = type;
      },
      shareTypeSelected(choice) {
        if (this.shareType == choice) {
          return 'myflow-basic-button--state-active';
        } else {
          return 'myflow-basic-button';
        }
      },
      shareMediaSelected(choice) {
        if (this.shareMedia == choice) {
          return 'myflow-basic-button--state-active';;
        } else {
          return 'myflow-basic-button';
        }
      },
      async submit() {
        axios.post(route('invite.TargetedInvite'), {
          receipientEmail: this.emailEmail,
          contentTitle: this.sharingData.title,
          contentDescription: this.sharingData.description,
          receipientName: this.emailName,
          purpose: this.emailPurpose,
          url: this.redirectURL
        });
        this.closeSlideout();
      }
    },
    mounted: function(){
    },
    computed: {
    },
    setup() {
    },
}
</script>
<style>

</style>
