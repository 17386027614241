<template>
  <div class="flex">
    <!-- Narrow sidebar -->
    <div class="hidden overflow-y-auto bg-gray-200 w-52 md:block">
      <div class="flex flex-col items-center w-full py-6">
        <div class="flex items-center flex-shrink-0 mb-8 lg:mb-4">
          <logo-symbol class="w-auto h-8 text-myflowPurple" />
        </div>
        <flash-message-emitter />
        <primary-navigation-header
            class="hidden md:block lg:hidden primarynavigationheader"
            :user-profile-img-url="_userProfileImgUrl"
        />
        <div
          class="flex flex-col justify-center w-full px-4 pt-4 pb-0 xl:space-x-2 md:pt-2 xl:hidden md:justify-between md:mt-4 lg:mt-0"
          v-if="secondaryNavigation.primaryCallToAction || secondaryNavigation.secondaryCallToAction"
        >
            <a
                v-if="secondaryNavigation.primaryCallToAction"
                :href="secondaryNavigation.primaryCallToAction.href"
                class="inline-block w-full px-2 py-1 mb-2 text-center text-white border border-transparent rounded-full xl:w-1/2 bg-myflowPurple hover:bg-myflowPurple-600 xl:mb-0"
            >
                {{ secondaryNavigation.primaryCallToAction.name }}
            </a>
            <a
                v-if="secondaryNavigation.secondaryCallToAction"
                :href="secondaryNavigation.secondaryCallToAction.href"
                class="inline-block w-full px-2 py-1 text-center border rounded-full xl:w-1/2 border-myflowPurple hover:bg-myflowPurple-100 text-myflowPurple"
            >
                {{ secondaryNavigation.secondaryCallToAction.name }}
            </a>
        </div>

        <primary-navigation-list
          :items="sidebarNavigation"
          heading=""
        />
        <primary-navigation-list
          :items="roleNavigation"
          :heading="headerNavigation"
        />
      </div>
      <div class="flex flex-col items-center justify-between w-full py-0 mb-3">
        <small class="text-center">
          <a :href="route('creator.expert')" class="mb-2">Expertregistrering</a><br>
          <a :href="route('specialist.create')" class="mb-2">myflow Specialist</a><br>
          <a :href="route('changelog.index')">Versionshistorik</a><br>
          <a :href="route('terms.general')">Villkor</a><br>
        </small>
        <div class="px-4 pt-4">
            <improvement-panel />
        </div>
      </div>
    </div>

    <!-- Mobile menu -->
    <TransitionRoot as="template" :show="mobileMenuOpen">
      <Dialog as="div" class="block md:hidden" @close="mobileMenuOpen = false">
        <div class="fixed inset-0 z-[99] flex justify-end">
          <div class="flex-shrink-0 w-14" aria-hidden="true">
            <!-- Dummy element to force sidebar to shrink to fit close icon -->
          </div>
          <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
            <DialogOverlay class="fixed inset-0 bg-gray-800 bg-opacity-90" />
          </TransitionChild>
          <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="translate-x-full" enter-to="-translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="-translate-x-0" leave-to="translate-x-full">
            <div class="relative flex flex-col flex-1 w-full max-w-xs pt-5 bg-gray-200">
              <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
                <div class="absolute p-1 top-1 -left-16">
                  <button type="button" class="flex items-center justify-center w-12 h-12 rounded-full focus:outline-none focus:ring-2 focus:ring-white" @click="mobileMenuOpen = false">
                    <XIcon class="w-6 h-6 text-white" aria-hidden="true" />
                    <span class="sr-only">Close sidebar</span>
                  </button>
                </div>
              </TransitionChild>
              <div class="z-40 flex items-center justify-around px-4 pb-1 mt-0 md:flex-shrink-0 md:pb-5 md:shadow-lg">
                  <primary-navigation-header :user-profile-img-url="_userProfileImgUrl" />
              </div>
              <div class="flex-1 h-0 px-2 overflow-y-auto">
                <div
                  class="flex flex-col justify-center p-4 pt-4 pb-0 md:flex-row md:space-x-2 md:pt-2 lg:hidden md:justify-between"
                  v-if="secondaryNavigation.primaryCallToAction || secondaryNavigation.secondaryCallToAction"
                >
                    <a
                        v-if="secondaryNavigation.primaryCallToAction"
                        :href="secondaryNavigation.primaryCallToAction.href"
                        target="_blank"
                        class="inline-block w-full px-2 py-1 mb-2 text-center text-white border border-transparent rounded-full md:mb-0 md:w-1/2 bg-myflowPurple hover:bg-myflowPurple-600"
                    >
                        {{ secondaryNavigation.primaryCallToAction.name }}
                    </a>
                    <a
                        v-if="secondaryNavigation.secondaryCallToAction"
                        :href="secondaryNavigation.secondaryCallToAction.href"
                        target="_blank"
                        class="inline-block w-full px-2 py-1 text-center border rounded-full md:w-1/2 border-myflowPurple hover:bg-myflowPurple-100 text-myflowPurple"
                    >
                        {{ secondaryNavigation.secondaryCallToAction.name }}
                    </a>
                </div>

                <nav class="flex flex-col h-full">
                  <primary-navigation-list
                    :items="sidebarNavigation"
                    heading=""
                    class="mb-2"
                  />

                  <primary-navigation-list
                    :items="roleNavigation"
                    :heading="headerNavigation"
                    style="padding-bottom: 100px;"
                  />
                </nav>
                <div class="absolute bottom-0 left-0 right-0 flex items-center justify-start w-full p-2 py-3 pl-8 text-xs bg-white text-myflowPurple-900">
                    <a class="text-myflowPurple-900" :href="route('specialist.create')">myflow Specialist</a>
                    <improvement-panel
                        :minimal="true"
                        @modalOpened="mobileMenuOpen = false"
                    />
                </div>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <!-- Content area -->
    <div class="flex flex-col flex-1">
      <header class="z-50 w-full">
        <div class="relative z-10 flex items-center justify-between flex-shrink-0 h-16 bg-gray-200 md:hidden lg:flex md:items-stretch">
          <logo-symbol class="w-auto h-6 ml-4 text-white md:ml-0 md:hidden" />
          <button type="button" class="relative px-4 text-gray-500 border-r border-gray-200 focus:outline-none md:hidden group" @click="mobileMenuOpen = true">

            <div class="relative w-6 h-6">

              <div
                class="absolute flex items-center justify-center w-5 h-5 text-xs text-white transition rounded-full count -top-2 -right-2 bg-myflowPurple-400 group-hover:bg-myflowPurple-900 group-hover:text-white group-focus:bg-myflowPurple-900 group-focus:text-white"
                v-if="notificationCount > 0"
              >
                {{ notificationCount }}
              </div>
              <span class="sr-only">Open sidebar</span>
              <MenuAlt3Icon class="w-6 h-6 stroke-current group-focus:text-myflowPurple-900" aria-hidden="true" />
            </div>
          </button>
          <secondary-navigation-bar
            :navigation="secondaryNavigation"
            :user-profile-img-url="_userProfileImgUrl"
          />
        </div>
      </header>

      <!-- Main content -->
      <main class="flex-1 bg-gray-200">
        <!-- Primary column -->
        <section
          aria-labelledby="primary-heading"
          class="z-20 flex flex-col flex-1 h-full min-w-0 shadow-lg lg:order-last lg:rounded-tl-xl bg-gray-50 min-h-contentarea"
        >
          <slot></slot>
        </section>
      </main>

    </div>
    <div id="teleport-target-modal"></div>
    <flash-messages />
  </div>
</template>

<script>
import { ref } from 'vue'
import {
  Dialog,
  DialogOverlay,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  TransitionChild,
  TransitionRoot,
} from '@headlessui/vue'
import {
  CogIcon,
  HomeIcon,
  MenuAlt3Icon,
  PlusSmIcon,
  XIcon,
  AcademicCapIcon,
  SparklesIcon,
  GiftIcon,
  BellIcon,
  UserGroupIcon,
  AdjustmentsIcon,
  TrendingUpIcon,
  GlobeIcon,
  VideoCameraIcon,
  CashIcon,
  ViewGridAddIcon,
  UsersIcon,
  LightBulbIcon,
  RssIcon,
  UserAddIcon,
  CubeTransparentIcon,
  MapIcon,
  PresentationChartBarIcon,
  FolderIcon,
  StatusOnlineIcon,
  StarIcon,
  InboxIcon
} from '@heroicons/vue/outline'
import { SearchIcon } from '@heroicons/vue/solid'

const sidebarNavigation = [
  { name: 'Dashboard',
    href: '/home',
    icon: HomeIcon,
    hasSubnav: true,
    current: false,
    comingSoon: false,
    routeName: 'home',
    notificationsCount: 0,
  },
  { name: 'Utbildning',
    href: '/course/guide',
    icon: AcademicCapIcon,
    hasSubnav: false,
    current: false,
    comingSoon: false,
    routeName: 'courses',
    notificationsCount: 0,
    subItems: [
    //   {
    //       name: 'Upptäck',
    //       href: '/course/guide',
    //       current: true,
    //       routeName: 'courses.contentByGuide',
    //   },
    //   {
    //       name: 'Repetera',
    //       href: '/courses/repetition',
    //       current: false,
    //       routeName: 'courses.repetition',
    //   },
    ]
  },
  { name: 'Coaching',
    href: '/coaching/guide',
    icon: LightBulbIcon,
    hasSubnav: false,
    current: false,
    comingSoon: false,
    routeName: 'coaching',
    notificationsCount: 0,
    subItems: [
      {
          name: 'Boka',
          href: '/coaching',
          current: true,
          routeName: 'coaching.index',
      },
      {
          name: 'Mina bokningar',
          href: route('coaching.client.session.index'),
          current: false,
          routeName: 'coaching.client.session.index',
      },
    ]
  },
  { name: 'Prenumerationer',
    href: '/subscriptions',
    icon: StatusOnlineIcon,
    hasSubnav: false,
    current: false,
    comingSoon: false,
    routeName: 'subscription',
    notificationsCount: 0,
    subItems: [
      {
          name: 'Prenumerationer',
          href: '/subscriptions',
          current: true,
          routeName: 'subscription.dashboard',
      },
      {
          name: 'Tidigare prenumerationer',
          href: '/subscriptions/history',
          current: false,
          routeName: 'subscription.history',
      },
    ]
  },
  { name: 'Bjud in',
    href: '/invite/dashboard',
    icon: GiftIcon,
    hasSubnav: false,
    current: false,
    comingSoon: false,
    routeName: 'invite',
    notificationsCount: 0
  },
  { name: 'Konto',
    href: '/profile/edit',
    icon: CogIcon,
    hasSubnav: false,
    current: false,
    comingSoon: false,
    routeName: 'profile',
    notificationsCount: 0
  },
]
const roleNavigation = [];

const userNavigation = [
  { name: 'Profil', href: '#' },
  { name: 'Logga ut', href: '/user/logout' },
]

export default {
  components: {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot,
    MenuAlt3Icon,
    PlusSmIcon,
    SearchIcon,
    XIcon,
    BellIcon,
    AdjustmentsIcon,
    ViewGridAddIcon,
    RssIcon,
    UserAddIcon,
    CubeTransparentIcon,
    MapIcon,
    PresentationChartBarIcon,
    FolderIcon,
    StatusOnlineIcon,
    StarIcon,
    GlobeIcon,
    InboxIcon
  },
  setup() {
    const mobileMenuOpen = ref(false)

    return {
      sidebarNavigation,
      userNavigation,
      mobileMenuOpen,
      roleNavigation
    }
  },
  props: {
    userId: {
        type: Number,
        required: true,
    },
    userProfileImgUrl: {
        type: String,
        default: null,
        required: false,
    },
    navigationItems: Array,
    navigationHeading: String,
  },
  data() {
    return{
      secondaryNavigation: {},
      notificationCount: 0,
      roleNavigation: this.navigationItems,
      headerNavigation: this.navigationHeading,
      loadedProfileImage: null,
      _userProfileImgUrl: null,
    }
  },
  async mounted() {
    this.sidebarNavigation.forEach(element => {
      element.current = this.isActive(element.routeName);
      if(element.current){
        this.setSecondaryNavigationFromParent(element);
      }
    });

    this.navigationItems?.forEach(element => {
      element.current = this.isActive(element.routeName, element.routeParams);
      if(element.current){
        this.setSecondaryNavigationFromParent(element);
      }
    });

    this.loadNotifications();
    if(this.userProfileImgUrl){
        this._userProfileImgUrl = this.userProfileImgUrl;
    }

    this.loadProfileImage();
  },
  methods: {
    isActive: function(routeName, routeParams) {
      if( this.isCurrentRoute(routeName, routeParams) || this.isPartOfCurrentRoute(routeName, routeParams) ){
        return true
      }
    },
    isCurrentRoute: function(routeName, routeParams){
      return route().current(routeName)
    },
    isPartOfCurrentRoute: function(routeName, routeParams){
      return route().current(routeName + '*', routeParams)
    },
    loadNotifications: async function(){
      let response = await axios.get(route('notifications'));
      this.notificationCount = response.data.unread_count;
    },
    setSecondaryNavigationFromParent(parentItem){
      this.secondaryNavigation = {
        navigationItems: parentItem.subItems,
        primaryCallToAction: parentItem.primaryCallToAction,
        secondaryCallToAction: parentItem.secondaryCallToAction
      }
    },
    loadProfileImage: async function() {
        await axios.get(route('user.getProfileImage', this.userId)).then((response) => {
            this.loadedProfileImage = response.data
        })
    }
  },
  watch: {
    mobileMenuOpen: function(val){
      if( val === false ){
        this.loadNotifications()
      }
    },
    loadedProfileImage: function(val){
        if(val){
            this._userProfileImgUrl = val
        }
    }
  },
}
</script>
<style scoped>
  /* .current-has-subnav {
    position: relative;
  }
  .current-has-subnav::after {
    position: absolute;
    top: 0;
    left: 100%;
    bottom: 0;
    width: 20px;
    background: #000;
    content: '';
  } */

  .min-h-contentarea {
    min-height: 100vh;
    min-height: calc(100vh - 4rem);
  }
</style>
