<template>

    <div v-if="selected.length > 0">
        <div class="fixed z-50 w-full max-w-lg px-4 transform -translate-x-1/2 left-1/2 lg:px-8 top-8">
            <div class="px-4 py-3 bg-white rounded-md shadow-lg space-y-4">
                <tags-multi-assign
                    :selected-leads="selected"
                    :show-outreach-initiate="outreachMultiInitiateEnabled"
                    @close="onTagsMultiAssignClose"
                    @doReloadLeads="updateLeads()"
                />
            </div>
        </div>
    </div>

    <mf-table v-if="(leads !== null) && (leads.length > 0)" :loading="loading" :data="leads" :columns="columns" :filters="filters" :searchable="searchable" :pageSize="50" @selectAll="toggleSelectAll" @filtersChanged="onFiltersChanged">
        <template #column-select="{ row }">
            <div :class="['flex', 'items-center', row.active ? null : 'opacity-50', row.can_view_details ? '' : 'blur-sm']">
                <div class="ml-4">
                    <i
                        class="text-2xl cursor-pointer fa-regular fa-square-check text-myflowGreen-800"
                        v-on:click="deselect(row.id)"
                        v-if="selected.includes(row.id)"
                    ></i>
                    <i
                        class="text-2xl text-gray-600 cursor-pointer fa-regular fa-square"
                        v-on:click="select(row.id)"
                        v-if="!selected.includes(row.id)"
                    ></i>
                </div>
            </div>
        </template>
        <template #column-name="{ row }" class="bg-black">
            <div :class="['flex', 'items-center', row.active ? null : 'opacity-50', row.can_view_details ? '' : 'blur-sm']">
                <div class="ml-4">
                    <div class="text-sm font-medium text-gray-900">
                        <b>{{row.name}}</b>
                    </div>
                </div>
            </div>
        </template>
        <template #column-company="{ row }">
            <div :class="row.can_view_details ? '' : 'blur-sm'">
                <div class="text-sm text-gray-900">{{row.company}}</div>
            </div>
        </template>
        <template #column-tags="{ row }">
            <div :class="row.can_view_details ? '' : 'blur-sm'">
                <div v-if="row.tags.length === 0" class="text-sm text-gray-500">{{ _mft('crm:tag.noTags') }}</div>
                <span
                    v-else
                    v-for="tag in row.tags"
                    :key="tag.id"
                >
                    <span class="flex-1 mb-1 mr-1 text-gray-500">
                        #{{ tag.lead_tag.name }}
                    </span><br>
                </span>
            </div>
        </template>
        <template #actions="{ row }">
            <a v-if="row.can_view_details" :href="route('crm.leads.view', {id: row.id})" target="_blank" class="text-sm font-medium text-right text-myflowPurple-600 hover:text-myflowPurple-900">
                {{ _mft('crm:lead.openInNewTab') }}
            </a>
        </template>
    </mf-table>
    <div v-else class="text-center">
        <mf-spinner-medium
        class="w-24 h-12"
        />
    </div>
</template>

<style lang="scss" scoped>
</style>

<script setup>
import { ref, toRefs, watch, onMounted, reactive } from 'vue';
import axios from 'axios';

const props = defineProps({
    // URL to leads API
    url: { type: String, required: true }
});

const { url } = toRefs(props);

const dateFormatter = new Intl.DateTimeFormat(['sv-SE'], {
    timeZone: 'Europe/Stockholm',
    dateStyle: 'short'
});

const select = (id) => {
    selected.value.push(id);
}

const deselect = (id) => {
    selected.value.splice(selected.value.indexOf(id), 1);
}

const toggleSelectAll = (selection) => {
    if(selected.value.length === selection.length) {
        selected.value = [];
    } else {
        selected.value = selection;
    }
}

const selected = ref([]);

const assignTags = (tagId) => {
    axios.post(route('crm.assignTags', tagId), {
        selected: selected.value
    }).then((response) => {
        window.location.reload();
    });
}

const outreachMultiInitiateEnabled = ref(false);

const onFiltersChanged = (filters) => {
    outreachMultiInitiateEnabled.value = false;
    filters.forEach((filter) => {
        if (filter.metadata?.enablesOutreachMultiInitiate === true) {
            outreachMultiInitiateEnabled.value = true;
        }
    });
}
const onTagsMultiAssignClose = () => {
    selected.value = [];
}

const leads = ref(null);

const loading = ref(false);

const updateLeads = async () => {
    let nextUrl = url.value;
    leads.value = [];

    loading.value = true;

    while (nextUrl) {
        const resp = await axios.get(nextUrl);
        nextUrl = resp.data.links?.next;

        const respData = resp.data.data.map((row) => ({
            ...row,
            // Convert arrays of objects to strings, as we only need them for searchability
        }));
        leads.value.push(...respData);
    }

    loading.value = false;
}
watch(url, updateLeads);
onMounted(updateLeads);

const columns = [
    {
        label: '',
        key: 'select',
        sort: 'select'
    },
    {
        label: _mft('crm:leadIndex.columnLabel.name'),
        key: 'name',
        sort: 'name'
    },
    {
        label: _mft('crm:leadIndex.columnLabel.company'),
        key: 'company',
        sort: 'company',
        desktop: true
    },
    {
        label: _mft('crm:leadIndex.columnLabel.tags'),
        key: 'tags',
        sort: 'tags',
        desktop: true
    },
];

const searchable = [
    'name',
    'email',
    'company',
    'title',
    'search_tags',
    'tags',
    'phone'
];

const filters = [
    {
        label: _mft('crm:leadIndex.filter.hasOptedOut'),
        items: [
            { label: _mft('shared:hasProperty.positive'), filter: { custom: (_v, row) => row.has_opted_out } },
            { label: _mft('shared:hasProperty.negative'), filter: { custom: (_v, row) => !row.has_opted_out } },
        ]
    },
    {
        label: _mft('crm:leadIndex.filter.availableForOutreach'),
        items: [
            { label: _mft('shared:hasProperty.positive'), metadata: { enablesOutreachMultiInitiate: true }, filter: { custom: (_v, row) => row.is_candidate_for_sales_process } },
            { label: _mft('shared:hasProperty.negative'), filter: { custom: (_v, row) => !row.is_candidate_for_sales_process } },
        ]
    },
    {
        label: _mft('crm:leadIndex.filter.hasReceivedAutomationsVia'),
        items: [
            { label: _mft('communication:sms'), filter: { custom: (_v, row) => row.received_sms } },
            { label: _mft('communication:email'), filter: { custom: (_v, row) => row.received_emails } },
        ]
    },
    {
        label: _mft('crm:leadIndex.filter.hasPhoneNumber'),
        items: [
            { label: _mft('shared:hasProperty.positive'), filter: { custom: (_v, row) => row.has_phone } },
            { label: _mft('shared:hasProperty.negative'), filter: { custom: (_v, row) => !row.has_phone } },
        ]
    },
    {
        label: _mft('crm:leadIndex.filter.hasEmailAddress'),
        items: [
            { label: _mft('shared:hasProperty.positive'), filter: { custom: (_v, row) => row.has_email } },
            { label: _mft('shared:hasProperty.negative'), filter: { custom: (_v, row) => !row.has_email } },
        ]
    },
    {
        label: _mft('crm:leadIndex.filter.hasSignedContract'),
        items: [
            { label: _mft('shared:hasProperty.positive'), filter: { custom: (_v, row) => row.has_signed_quotes } },
            { label: _mft('shared:hasProperty.negative'), filter: { custom: (_v, row) => !row.has_signed_quotes } },
        ]
    },
    {
        label: _mft('crm:leadIndex.filter.hasSignableContract'),
        items: [
            { label: _mft('shared:hasProperty.positive'), filter: { custom: (_v, row) => row.has_unsigned_quotes } },
            { label: _mft('shared:hasProperty.negative'), filter: { custom: (_v, row) => !row.has_unsigned_quotes } },
        ]
    },
    /* {
        label: 'Icke uppföljda leads',
        items: [
            { label: "Visa uppföljda", filter: { custom: (_v, row) => row.has_opted_out } },
            { label: "Visa EJ uppföljda", filter: { custom: (_v, row) => !row.has_opted_out } },
        ]
    }, */
    {
        label: _mft('crm:leadIndex.filter.action'),
        items: [
            { label: _mft('crm:leadIndex.filter.hasAction'), filter: { custom: (_v, row) => row.has_booked_action } },
            { label: _mft('crm:leadIndex.filter.hasNoAction'), filter: { custom: (_v, row) => row.has_no_booked_action } },
        ]
    },
]
</script>
