<script setup>

import {onMounted, ref} from "vue";
import { useNotificationStore } from '@/stores/NotificationStore.js'
const notificationStore = useNotificationStore()

const props = defineProps({
    leadId: {
        type: Number,
        required: true
    }
});

const assignedTags = ref({})
const editing = ref(false)

const loadTags = async () => {
    const res = await axios.get(route('crm.leads.view.tags', props.leadId))
    assignedTags.value = res.data.tags;
}

const removeTag = async (tag) => {
    const res = await axios.delete(route('crm.leads.view.tags.remove', [props.leadId, tag.id]), {})
    await loadTags()
}

onMounted(() => {
    loadTags()
})

</script>

<template>
    <div>
        <div v-if="! editing">
            <ul class="flex space-x-1 flex-wrap">
                <li
                    v-for="(tag, i) in assignedTags"
                    :key="i"
                    class="mb-1"
                >
                    <pill
                        class="border border-wlPrimary flex space-x-2 cursor-default"
                        size="sm"
                    >
                        <span>{{ tag.name }}</span>
                    </pill>
                </li>
                <li>
                    <button-primary
                        size="sm"
                        @click="editing = true"
                    >
                        <mf-translate domainkey="crm:manageTags"></mf-translate>
                    </button-primary>
                </li>
            </ul>
        </div>

        <div v-else>
            <div>
                <ul class="flex space-x-1 flex-wrap items-center">
                    <li
                        v-for="(tag, i) in assignedTags"
                        :key="i"
                        class="mb-1"
                    >
                        <pill
                            class="bg-wlPrimary text-wlPrimaryContrast flex space-x-2"
                            size="sm"
                        >
                            <span>{{ tag.name }}</span>
                            <span
                                class="bg-wlPrimaryContrast hover:bg-wlPrimary-light text-wlPrimary w-4 h-4 rounded-full flex items-center justify-center cursor-pointer"
                                @click="removeTag(tag)"
                            >
                                <i class="fa-solid fa-xmark"></i>
                            </span>
                        </pill>
                    </li>
                    <li class="mb-1">
                        <button-success
                            @click="editing = false"
                            size="sm"
                        >
                            <mf-translate domainkey="shared:done"></mf-translate>
                        </button-success>
                    </li>
                </ul>
            </div>
            <div class="mt-2">
                <tags-multi-assign
                    :selected-leads="[props.leadId]"
                    :selected-tag-ids="assignedTags.map(tag => tag.id)"
                    :instant-assign="true"
                    @doReloadLeads="loadTags()"
                    :show-topbar="false"
                ></tags-multi-assign>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
