<script setup>
import {computed, nextTick, onMounted, reactive, ref, watch} from 'vue'

    const emit = defineEmits(['preferencesUpdated'])

    const props = defineProps({
        expertContractId: {
            type: Number,
            required: true
        }
    })

    const state = reactive({
        loading: true,
    })

    const preferences = ref({})
    const conditions = ref({})
    const availableSignatureMethods = ref({})
    const signingStatus = ref(null)
    const signingStatusOptions = computed(() => {
        let options = [
            {
                value: 'disabled',
                label: _mft('expertContract:preferences.signingStatuses.disabled'),
                disabled: conditions.value.has_signed_signature_requests
            },
            {
                value: 'enabled',
                label: _mft('expertContract:preferences.signingStatuses.enabled'),
                disabled: conditions.value.has_signed_signature_requests
            },
        ]

        if (conditions.value.has_signed_signature_requests) {
            options = [{ value: 'inProgress', label: _mft('expertContract:preferences.signingStatuses.inProgress'), disabled: true }]
        }

        if (conditions.value.all_signature_requests_signed) {
            options = [{ value: 'completed', label: _mft('expertContract:preferences.signingStatuses.completed'), disabled: true }]
        }

        return options
    })

    const loadPreferences = async () => {
        state.loading = true
        const response = await axios.get(route('expert-contract-preferences.show', props.expertContractId))
        preferences.value = response.data.preferences
        conditions.value = response.data.conditions
        availableSignatureMethods.value = response.data.available_signature_methods
        setSigningStatus()

        await nextTick(() => {
            state.loading = false
        })
    }

    const setSigningStatus = () => {
        if (! preferences.value.signing_enabled) {
            signingStatus.value = 'disabled'
        } else if (conditions.value.has_signed_signature_requests) {
            signingStatus.value = 'inProgress'
            if (conditions.value.all_signature_requests_signed) {
                signingStatus.value = 'completed'
            }
        } else {
            signingStatus.value = 'enabled'
        }
    }

    const updatePreferences = async () => {
        const response = await axios.post(route('expert-contract-preferences.update', props.expertContractId), {
            signing_enabled: preferences.value.signing_enabled,
        })
        emit('preferencesUpdated', preferences.value)
    }

    const reload = () => {
        loadPreferences()
    }

    watch(preferences, () => {
        if (!state.loading) {
            updatePreferences()
        }
    }, { deep: true })

    watch(signingStatus, () => {
        preferences.value.signing_enabled = signingStatus.value === 'enabled';
    })

    onMounted(() => {
        loadPreferences()
    })

    defineExpose({
        reload
    })
</script>
<template>
    <card-basic>
        <template v-slot:body>
            <mf-spinner-medium v-if="state.loading" class="w-12 h-6"></mf-spinner-medium>
            <div
                v-else
                class="space-y-1 md:space-y-4"
            >
                <div v-if="conditions.has_signature_requests">
                    <input-select
                        v-model:content="signingStatus"
                        :options="signingStatusOptions"
                        :label="_mft('expertContract:preferences.enableSigning.title')"
                        required
                        placeholder=""
                        :disabled-by-default="conditions.has_signed_signature_requests"
                        name="status"
                    />

                    <p
                        v-if="conditions.all_signature_requests_signed && conditions.has_signature_requests && !conditions.has_signed_signature_requests"
                        class="italic text-gray-500"
                    >
                        {{ _mft('expertContract:preferences.signingStatus.allRecipientsSigned') }}
                    </p>
                </div>
                <div v-else>
                    <p
                        class="italic text-gray-500"
                    >
                        {{ _mft('expertContract:preferences.signingStatus.addRecipientsToManage') }}
                    </p>
                </div>
            </div>

        </template>
    </card-basic>
</template>
