<template>
    <div>
        <button
            class="space-x-1 text-center sm:text-left text-myflowPurple"
            @click="copyUrl"
            v-if="!copied"
        >
            <i class="fa-light fa-copy"></i> <span class="block sm:inline">{{ _mft('salesProcess:copyUniqueink') }}</span>
        </button>
        <button
            class="space-x-1 text-center sm:text-left text-myflowPurple"
            v-else
        >
            <i class="fa-light fa-check"></i> <span class="block sm:inline">{{ _mft('salesProcess:linkCopied') }}</span>
        </button>
    </div>
</template>

<script>
import { Clipboard } from "v-clipboard"

export default {
    props: {
        url: {
            type: String,
            required: true,
        },
        content: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            buttonLabel: '<i class="fa-light fa-copy"></i> <span class="block sm:inline">' + this.copyLabel + '</span>',
            copied: false,
        };
    },
    methods: {
        async copyUrl() {
            let buttonLabel = this.buttonLabel;
            let res = await Clipboard.copy(this.content + "\n" + this.url);
            this.copied = true;
            setTimeout(() => {
                this.copied = false;
            }, 3000);
        },
    },
};
</script>

<style scoped>
</style>
