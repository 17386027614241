<script setup>
import { computed, inject } from 'vue';

const props = defineProps({
    containerRef: {
        type: String,
        required: true
    },
    uuid: {
        type: String,
        require: true
    },
    content: {
        type: Object,
        required: true
    }
});

const formUrl = computed(() => {
    return route('block-editor-container.blocks.lead-capture.store', {
        containerRef: props.containerRef,
        blockUuid: props.uuid
    })
});
const text = computed(() => props.content.text);
const description = computed(() => props.content.description);
const policyUrl = computed(() => props.content.policy);
const redirectOnSuccess = computed(() => !!props.content.redirect);
const successMessage = computed(() => props.content.success_message ?? "Your details have been saved");

const collectTitle = computed(() => props.content.collect_title ?? false);
const collectPhone = computed(() => props.content.collect_phone ?? false);
const collectLinkedin = computed(() => props.content.collect_linkedin ?? false);
const collectCompany = computed(() => props.content.collect_company ?? true);
const collectOrgNr = computed(() => props.content.collect_org_nr ?? false);
const collectWebsite = computed(() => props.content.collect_website ?? false);

const $document = inject('$document');
const onSuccess = () => {
    if (redirectOnSuccess.value) {
        $document.locationAssign(props.content.redirect);
    }
};
</script>
<template>
    <div class="my-6">
        <h2 class="my-6 text-2xl text-center md:text-4xl lg:text-5xl" v-html="text"></h2>
        <div class="my-6 text-center" v-html="description"></div>

        <axios-form
            :action="formUrl"
            method="POST"
            @success="onSuccess"
        >
            <input v-if="props.content" type="hidden" name="leadTag" :value="props.content.leadTag" />

            <label class="block mb-1" for="`${uuid}-name`">{{ _mft('pagebuilderView:leadCapture.labels.name') }}*</label>
            <input type="text" maxlength="255" class="myflow-basic-input rounded-custom shadow-custom" id="`${uuid}-name`" name="name" required>

            <label class="block mb-1" for="`${uuid}-email`">{{ _mft('pagebuilderView:leadCapture.labels.email') }}*</label>
            <input type="email" class="myflow-basic-input rounded-custom shadow-custom" id="`${uuid}-email`" name="email" required>

            <template v-if="collectTitle">
                <label class="block mb-1" for="`${uuid}-title`">{{ _mft('pagebuilderView:leadCapture.labels.title') }}*</label>
                <input type="text" maxlength="255" class="myflow-basic-input rounded-custom shadow-custom" id="`${uuid}-title`" name="title" required>
            </template>

            <template v-if="collectPhone">
                <label class="block mb-1" for="`${uuid}-phone`">{{ _mft('pagebuilderView:leadCapture.labels.phone') }}*</label>
                <input type="tel" class="myflow-basic-input rounded-custom shadow-custom" id="`${uuid}-phone`" name="phone" required>
            </template>

            <template v-if="collectLinkedin">
                <label class="block mb-1" for="`${uuid}-linkedin`">{{ _mft('pagebuilderView:leadCapture.labels.linkedIn') }}*</label>
                <input type="text" maxlength="255" class="myflow-basic-input rounded-custom shadow-custom" id="`${uuid}-linkedin`" name="linkedin" required>
            </template>

            <template v-if="collectCompany">
                <label class="block mb-1" for="`${uuid}-company`">{{ _mft('pagebuilderView:leadCapture.labels.company') }}*</label>
                <input type="text" maxlength="255" class="myflow-basic-input rounded-custom shadow-custom" id="`${uuid}-company`" name="company" required>
            </template>

            <template v-if="collectOrgNr">
                <label class="block mb-1" for="`${uuid}-org_nr`">{{ _mft('pagebuilderView:leadCapture.labels.corporateIdentityNumber') }}*</label>
                <input type="text" maxlength="255" class="myflow-basic-input rounded-custom shadow-custom" id="`${uuid}-org_nr`" name="org_nr" required>
            </template>

            <template v-if="collectWebsite">
                <label class="block mb-1" for="`${uuid}-website`">{{ _mft('pagebuilderView:leadCapture.labels.website') }}*</label>
                <input type="text" maxlength="255" class="myflow-basic-input rounded-custom shadow-custom" id="`${uuid}-website`" name="website" required>
            </template>

            <label class="block mb-3">
                <input type="checkbox" class="mr-1 text-black" required> {{ _mft('pagebuilderView:leadCapture.acceptPolicy.text') }} <a :href="policyUrl" target="_blank" class="text-inherit"><u>{{ _mft('pagebuilderView:leadCapture.acceptPolicy.link') }}</u></a>
            </label>


            <button-primary type="submit" class="inline-block px-4 py-2 mt-2 overflow-hidden rounded-custom shadow-custom">
                {{ _mft('pagebuilderView:leadCapture.submit') }}
            </button-primary>

            <template v-if="!redirectOnSuccess" #success-content>
                <div class="flex items-center justify-center w-full mt-4 md:mt-6 lg:mt-12">
                    <p class="flex items-center justify-center p-5 shadow-custom rounded-custom tint-background">
                        {{ successMessage }}
                    </p>
                </div>
            </template>

        </axios-form>
    </div>
</template>
