<script setup>
    import { ColorPicker } from "vue3-colorpicker";
    import "vue3-colorpicker/style.css";
    import {nextTick, ref} from "vue";

    const props = defineProps({
        color: {
            type: String,
            required: false,
            default: 'hsl(0,0%,100%)',
        },
        debounce: {
            type: Number,
            required: false,
            default: 500
        },
        disableAlpha: {
            type: Boolean,
            required: false,
            default: true
        },
        format: {
            type: String,
            required: false,
            default: 'hsl',
        },
        showPalette: {
            type: Boolean,
            required: false,
            default: false,
        }
    })

    const selectedColor = ref(props.color);

    const emit = defineEmits(['colorChanged', 'clearColor']);

    const clearColor = () => {
        emit('clearColor')
        nextTick(() => {
            selectedColor.value = props.color
        })
    }

    const selectColor = (color) => {
        selectedColor.value = color;
        onUpdate(color);
    }

    const onUpdate = (color) => {
        emit('colorChanged', color);
    }


</script>

<template>
    <div>
        <ColorPicker
            class="w-full"
            id="colorpickerabc"
            is-widget
            :format="props.format"
            shape="circle"
            picker-type="chrome"
            picker-container="picker-container"
            use-type="pure"
            disable-history
            round-history
            :disable-alpha="props.disableAlpha"
            lang="En"
            :debounce="props.debounce"
            theme="white"
            v-model:pureColor="selectedColor"
            v-on:update:pureColor="onUpdate"
        />

        <color-palette
            v-if="showPalette"
            class="mt-2"
            :incoming-color="selectedColor"
            :show-clear-button="true"
            @colorSelected="selectColor"
            @clearColor="clearColor"
        ></color-palette>

    </div>
</template>

<style scoped>
    div :deep(.vc-colorpicker) {
        border-radius: 1rem;
        width: 100%;
        box-shadow: none;
    }

    div :deep(.vc-colorpicker--container) {
        padding: 0px;
    }
    div :deep(.vc-input-toggle) {
        display: none !important;
    }
</style>
