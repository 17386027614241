<script setup>

import {computed, ref, watch} from "vue";
import DetailExamples from "./DetailExamples.vue";

const emit = defineEmits(['update:details'])

const campaignName = ref('')
const goal = ref('')
const who = ref('')
const what = ref('')
const result = ref('')

const details = computed(() => {
    return {
        campaignName: campaignName.value,
        goal: goal.value,
        who: who.value,
        what: what.value,
        result: result.value,
    }
})

const goalExamples = [
    {
        label: 'Increased sales',
        content: 'Increase sales by 20%. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin hendrerit est sapien, id venenatis quam molestie eget. Suspendisse velit justo, efficitur sed quam quis, gravida scelerisque tortor. Vivamus ac magna id quam molestie laoreet.',
    },
    {
        label: 'More leads',
        content: 'Increase leads by 50%. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin hendrerit est sapien, id venenatis quam molestie eget. Suspendisse velit justo, efficitur sed quam quis, gravida scelerisque tortor. Vivamus ac magna id quam molestie laoreet.',
    },
    {
        label: 'Brand awareness',
        content: 'Increase brand awareness by 30%. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin hendrerit est sapien, id venenatis quam molestie eget. Suspendisse velit justo, efficitur sed quam quis, gravida scelerisque tortor. Vivamus ac magna id quam molestie laoreet.',
    },
    {
        label: 'Increased exposure',
        content: 'Increase website traffic by 40%. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin hendrerit est sapien, id venenatis quam molestie eget. Suspendisse velit justo, efficitur sed quam quis, gravida scelerisque tortor. Vivamus ac magna id quam molestie laoreet.',
    },
]

const inputValidates = computed(() => {
    return who.value.length > 80 &&
        result.value.length > 80 &&
        what.value.length > 80
})

watch(() => details, (newVal) => {
    emit('update:details', newVal.value)
}, {deep: true})

</script>

<template>
<div>
    <h1 class="mt-2 mb-0">{{ _mft('leadHunterCampaign:createNew') }}</h1>
    <p class="mb-8 text-lg text-gray-500">{{ _mft('leadHunterCampaign:details.title') }}</p>

    <card-basic>
        <input-text
            :label="_mft('leadHunterCampaign:details.campaignName.label')"
            v-model:content="campaignName"
        ></input-text>
        <p class="-mt-3 mb-0 text-xs text-gray-500">{{ _mft('leadHunterCampaign:details.campaignName.byline') }}</p>
    </card-basic>

    <card-basic>
        <div class="grid xl:grid-cols-3">
            <div class="xl:col-span-2 xl:pr-4">
                <input-label :label="_mft('leadHunterCampaign:details.who.title')" />
                <p class="mb-0 text-xs text-gray-500">{{ _mft('leadHunterCampaign:details.who.byline') }}</p>
                <input-textarea
                    v-model:content="who"
                ></input-textarea>
            </div>

            <div class="lg:flex lg:items-center xl:border-l xl:pl-4">
                <span
                    class="text-gray-500" v-if="who.split(' ').length < 20 && who.length > 1"
                    v-html="_mft('leadHunterCampaign:details.inputHelp', {count: 20 - who.split(' ').length})"
                ></span>
            </div>
            <!-- <div class="xl:border-l xl:pl-4">
                <DetailExamples
                    label="Goal examples"
                    :examples="goalExamples"
                    @use-example="goal = $event.content"
                />
            </div> -->
        </div>
    </card-basic>

    <card-basic>
        <div class="grid xl:grid-cols-3">
            <div class="xl:col-span-2 xl:pr-4">
                <input-label :label="_mft('leadHunterCampaign:details.what.title')" />
                <p class="mb-0 text-xs text-gray-500">
                    {{ _mft('leadHunterCampaign:details.what.byline') }}
                </p>
                <input-textarea
                    v-model:content="what"
                ></input-textarea>
            </div>

            <div class="lg:flex lg:items-center xl:border-l xl:pl-4">
                <span
                    class="text-gray-500" v-if="what.split(' ').length < 20 && what.length > 1"
                    v-html="_mft('leadHunterCampaign:details.inputHelp', {count: 20 - what.split(' ').length})"
                ></span>
            </div>
            <!-- <div class="xl:border-l xl:pl-4">
                <DetailExamples
                    label="Goal examples"
                    :examples="goalExamples"
                    @use-example="goal = $event.content"
                />
            </div> -->
        </div>
    </card-basic>

    <card-basic>
        <div class="grid xl:grid-cols-3">
            <div class="xl:col-span-2 xl:pr-4">
                <input-label :label="_mft('leadHunterCampaign:details.result.title')" />
                <p class="mb-0 text-xs text-gray-500">
                    {{ _mft('leadHunterCampaign:details.result.byline') }}
                </p>
                <input-textarea
                    v-model:content="result"
                ></input-textarea>
            </div>

            <div class="lg:flex lg:items-center xl:border-l xl:pl-4">
                <span
                    class="text-gray-500" v-if="result.split(' ').length < 20 && result.length > 1"
                    v-html="_mft('leadHunterCampaign:details.inputHelp', {count: 20 - result.split(' ').length})"
                ></span>
            </div>
            <!-- <div class="xl:border-l xl:pl-4">
                <DetailExamples
                    label="Goal examples"
                    :examples="goalExamples"
                    @use-example="goal = $event.content"
                />
            </div> -->
        </div>
    </card-basic>

    <div
        v-if="inputValidates"
        class="flex items-center justify-center"
    >
        <slot name="actions"></slot>
    </div>
    <div
        v-else
        class="text-center italic text-gray-500"
    >
        <p>{{ _mft('leadHunterCampaign:details.allFieldsRequired') }}</p>
    </div>

</div>
</template>

<style scoped>

</style>
