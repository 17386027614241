<template>
  <div class="flex-shrink-0 h-screen hidden md:block" :class="barClass">
    <div
      class="fixed top-0 left-28 bottom-0 border-r bg-white border-gray-200 flex flex-col items-center p-4 md:px-6 lg:px-8"
      :class="barClass"
    >

      <div class="rounded-full w-24 h-24 bg-gray-300 mb-4 mt-14"></div>
      <ul class="text-center">
          <li
              v-for="(item, i) in subnavItems"
              v-bind:key="i"
              class="mt-4"
          >
            <a :href="item.href" class="text-sm text-gray-800">
              {{ item.name }}
            </a>
          </li>
      </ul>
    </div>
  </div>
</template>
<script>

const subnavItems = [
  { name: 'Översikt', href: '#', current: true },
  { name: 'Mitt innehåll', href: '#', current: false },
  { name: 'Skapa innehåll', href: '#', current: false },
  { name: 'Statistik', href: '#', current: false },
  { name: 'Fakturor', href: '#', current: false },
  { name: 'Ekonomi', href: '#', current: false },
]

export default {
  setup() {

    return {
      subnavItems
    }
  },
  props: {
  },
  computed: {
    barClass () {
      return 'w-32 lg:w-40';
    }
  }
}
//h-screen flex flex-col items-center p-4 md:px-6 lg:px-8 border-r border-gray-200 -ml-4 md:-ml-6 lg:-ml-12 -mt-5 mr-4 md:mr-6 lg:mr-8
</script>