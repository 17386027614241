<script setup>

import {onMounted, reactive, ref} from "vue";

const moderation = reactive({
    posts: [],
    comments: []
});

const loadModeration = async () => {
    const res = await axios.get(route('community.admin.api.moderation'));

    moderation.posts = res.data.posts;
    moderation.comments = res.data.comments;
}

const moderate = async (type, action, id) => {
    const res = await axios.put(route('community.admin.api.updateFlag', [type, action, id]));

    await loadModeration();
}

onMounted(() => {
    loadModeration();
});

</script>

<template>
<div>
    <frosted-bar>
        <div class="flex items-center justify-between space-x-4">
            <h1>{{ _mft('community:moderation.title') }}</h1>
        </div>
    </frosted-bar>

    <card-tabbed class="mt-8 lg:mt-12">
        <card-tabbed-tab
            title="Posts"
            value="posts"
            :active="true"
        >
            <div
                v-if="moderation.posts.length === 0"
                class="text-gray-400 text-center"
            >
                {{ _mft('community:moderation.noPostsToModerate') }} <span class="inline-block text-2xl ml-1 transform translate-y-1">🥳</span>
            </div>
            <ul
                v-else
                class="divide-y -mx-4 md:-mx-6 lg:-mx-8"
            >
                <li
                    v-for="(moderatablePost, i) in moderation.posts"
                    :key="i"
                    class="py-2 hover:bg-gray-50 px-4 md:px-6 lg:px-8"
                >
                    <div class="flex justify-between space-x-4">
                        <div class="font-bold">
                            <a
                                :href="'mailto:' + moderatablePost.post.author.email"
                                target="_blank"
                                class="mf-link"
                            >
                                {{ moderatablePost.post.author.name }}
                            </a>
                        </div>
                        <div>
                            {{ moderatablePost.post.created_at_display }}
                        </div>
                    </div>
                    <div class="md:flex justify-between md:space-x-4 text-xs text-gray-400">
                        <div class="flex space-x-4">
                            <div class="mb-1 mb:mb-0">
                                #{{ moderatablePost.channel.name}}
                            </div>
                            <div>
                                <i class="fa-light fa-people-group w-4 h-4 leading-4" aria-hidden="true"></i>
                                {{ moderatablePost.community.name }}
                            </div>
                        </div>
                        <div>
                            {{ _mft('community:moderation.flaggedAtBy', {date: moderatablePost.created_at_display, name: moderatablePost.reporter.name}) }}
                        </div>
                    </div>
                    <div
                        class="whitespace-pre-line mt-1"
                        v-html="moderatablePost.post.content"
                    >
                    </div>
                    <div class="flex justify-end space-x-2">
                        <button-with-confirmation
                            size="sm"
                            @confirmed="moderate('post', 'rejected', moderatablePost.id)"
                            modalClass="absolute -top-2 -right-2 p-2 bg-white text-black rounded shadow-md z-20"
                            :buttonLabel="_mft('community:moderation.deletePost')"
                            :confirmButtonLabel="_mft('shared:confirm.continue')"
                            confirmButtonClass="myflow-basic-button--danger whitespace-nowrap"
                            :confirm-text="_mft('shared:confirm.areYouSure')"
                        ></button-with-confirmation>

                        <button-base
                            size="sm"
                            @click="moderate('post', 'approved', moderatablePost.id)"
                        >
                            {{ _mft('community:moderation.dismissFlag') }}
                        </button-base>
                    </div>
                </li>
            </ul>
        </card-tabbed-tab>
        <card-tabbed-tab
            title="Comments"
            value="comments"
        >
            <div
                v-if="moderation.comments.length === 0"
                class="text-gray-400 text-center"
            >
                {{ _mft('community:moderation.noCommentsToModerate') }} <span class="inline-block text-2xl ml-1 transform translate-y-1">🥳</span>
            </div>

            <ul
                v-else
                class="divide-y -mx-4 md:-mx-6 lg:-mx-8"
            >
                <li
                    v-for="(moderatableComment, i) in moderation.comments"
                    :key="i"
                    class="py-2 hover:bg-gray-50 px-4 md:px-6 lg:px-8"
                >
                    <div class="flex justify-between space-x-4">
                        <div class="font-bold">
                            <a
                                :href="'mailto:' + moderatableComment.comment.author.email"
                                target="_blank"
                                class="mf-link"
                            >
                                {{ moderatableComment.comment.author.name }}
                            </a>
                        </div>
                        <div>
                            {{ moderatableComment.post.created_at_display }}
                        </div>
                    </div>
                    <div class="md:flex justify-between md:space-x-4 text-xs text-gray-400">
                        <div class="flex space-x-4">
                            <div class="mb-1 mb:mb-0">
                                <i class="text-gray-500 mr-0.5 fa-light fa-file-lines leading-4" aria-hidden="true"></i>
                                {{ moderatableComment.post.content }}
                            </div>
                            <div class="mb-1 mb:mb-0">
                                <i class="text-gray-500 mr-0.5 ">#</i>
                                {{ moderatableComment.channel.name}}
                            </div>
                            <div>
                                <i class="text-gray-500 mr-0.5 fa-light fa-people-group leading-4" aria-hidden="true"></i>
                                {{ moderatableComment.community.name }}
                            </div>
                        </div>
                        <div>
                            {{ _mft('community:moderation.flaggedAtBy', {date: moderatableComment.created_at_display, name: moderatableComment.reporter.name}) }}
                        </div>
                    </div>
                    <div
                        class="whitespace-pre-line mt-1"
                        v-html="moderatableComment.comment.content"
                    >
                    </div>
                    <div class="flex justify-end space-x-2">
                        <button-with-confirmation
                            size="sm"
                            @confirmed="moderate('comment', 'rejected', moderatableComment.id)"
                            modalClass="absolute -top-2 -right-2 p-2 bg-white text-black rounded shadow-md z-20"
                            :buttonLabel="_mft('community:moderation.deleteComment')"
                            :confirmButtonLabel="_mft('shared:confirm.continue')"
                            confirmButtonClass="myflow-basic-button--danger whitespace-nowrap"
                            :confirm-text="_mft('shared:confirm.areYouSure')"
                        ></button-with-confirmation>

                        <button-base
                            size="sm"
                            @click="moderate('comment', 'approved', moderatableComment.id)"
                        >
                            {{ _mft('community:moderation.dismissFlag') }}
                        </button-base>
                    </div>
                </li>
            </ul>

        </card-tabbed-tab>
    </card-tabbed>

</div>
</template>
