/**
    Usage:

    import { useNotificationStore } from '@/stores/NotificationStore.js'
    const notificationStore = useNotificationStore()

    notificationStore.addNotification({
        type: 'success', // success, error, warning, info - defaults to info
        title: 'A new notification!', // Shown in bold
        message: 'My message goes here', // Plain text message, optional
        url: '', // Url to redirect to when use clicks notification
        actions: [{url: '', label: ''}], // Action link shown in notification, optional
        dismissAfter: 5000, // Set auto dismiss time in ms, set to null no never auto dismisss
    })
 */

import { defineStore } from 'pinia';
import { pusher } from 'pusher-js';
export const useNotificationStore = defineStore({
    id: 'notificationstore',
    state: () => ({
        notifications: [],
    }),
    actions: {
        addNotification(notification) {
            if (this.notificationAlreadyPresent(notification)) {
                return;
            }

            const type = notification.type ? notification.type : 'info';
            const title = notification.title ? notification.title : null;
            const message = notification.message ? notification.message : null;
            const url = notification.url ? notification.url : null;
            const actions = notification.actions ? notification.actions : null;
            const dismissAfter = notification.dismissAfter ? notification.dismissAfter : null;

            this.notifications.push({
                id: Math.floor(Date.now()/100) + '-' + this.notifications.length,
                type: type,
                title: title,
                message: message,
                url: url,
                actions: actions,
                dismissAfter: dismissAfter
            })
        },
        dismissNotification(id) {
            this.notifications = this.notifications.filter((notification) => notification.id !== id);
        },
    },
    getters: {
        notificationAlreadyPresent: (state) => (notification) => {
            return state.notifications.some((n) => n.title + n.message === notification.title + notification.message);
        },
        getNotifications() {
            return notifications;
        },
    },
    created() {
        // This is a WIP, there is no backend for this yet
        const channel = pusher.subscribe('flash-messages');
        channel.bind('flash-message', (data) => {
            this.addNotification(data.notification);
        });
    }
});
