<script setup>
import {ref, watch, onMounted, reactive} from 'vue'
import { useNotificationStore } from '@/stores/NotificationStore.js'
const notificationStore = useNotificationStore()

const props = defineProps({
    pageId: {
        type: Number,
        required: true,
    },
    pageType: {
        type: String,
        required: true,
    },
    slug: {
        type: String,
        default: '',
    }
})

const _slug = ref(props.slug)
const editing = ref(false)
const state = ref('');

const cleanSlug = () => {
    _slug.value = _slug.value.replace(' ', '-').replace(/[^0-9a-z-_]/g, '')
}

const save = async () => {
    if (_slug.value === props.slug) {
        editing.value = false
        return
    }

    state.value = 'waiting'
    const res = await axios.post(route('pages.updateSlug', [props.pageType, props.pageId]), {
        slug: _slug.value
    })

    if (res.data.success) {
        _slug.value = res.data.slug
        state.value = 'success'
        window.location.reload()
    } else {
        state.value = 'error'
        notificationStore.addNotification({
            type: 'error',
            message: res.data.message,
            dismissAfter: 5000,
        })
    }

}

const cancel = () => {
    editing.value = false
    _slug.value = props.slug
}

</script>

<template>
<div>
    <input-label
        label="page:pageSlug"
    ></input-label>
    <div
        v-if="!editing"
        class="flex justify-between space-x-4 items-center"
    >
        <input-text
            disabled
            class="w-full"
            v-model:content="_slug"
        ></input-text>
        <button-base
            style-type="transparent"
            size="sm"
            class="-mt-2"
            @click="editing = true"
        >
            <template #icon>
                <span class="fa-solid fa-pen-to-square text-gray-500 text-lg mr-1"></span>
            </template>
            <span>
                {{ _mft('shared:edit')}}
            </span>
        </button-base>
    </div>
    <div
        v-else
    >
        <div
            class="flex justify-between space-x-4 items-center"
        >
            <input-text
                class="w-full"
                @keyup="cleanSlug"
                v-model:content="_slug"
            />
            <div class="flex space-x-2 items-center">
                <button-success
                    size="sm"
                    class="-mt-2"
                    @click="save"
                    :state="state"
                >
                    <span>
                        {{ _mft('shared:action.save')}}
                    </span>
                </button-success>
                <button-danger
                    size="sm"
                    class="-mt-2"
                    @click="cancel"
                >
                    <span>
                        {{ _mft('shared:cancel')}}
                    </span>
                </button-danger>
            </div>
        </div>
        <p class="text-xs -mt-2 italic mb-4">{{ _mft('page:changingSlugBreaksLinks')}}</p>
    </div>
</div>
</template>

<style scoped>

</style>
