<template>
    <div class="flex flex-col gap-5">
        <input-media-bank-entry
            type="video"
            label="Välj en video från mediabiblioteket"
            :required="true"
            v-model:content="this.value.media_bank_entry_id"
        />
        <div v-if="previewUrl" class="mb-4">
            <media-bank-entries-display v-if="previewUrl" :data-url="previewUrl" />
        </div>
    </div>
</template>
<script>
export default {
    props: {
        modelValue: {},
    },
    emits: [
        'update:modelValue'
    ],
    data() {
        return {
            value: { id: null, media_bank_entry_id: null }
        };
    },
    computed: {
      previewUrl() {
        if (!this.value.media_bank_entry_id) { return; }

        return route('mediaBank.entries.show', this.value.media_bank_entry_id);
      }
    },
    async mounted() {
        this.captureModelValue();
    },
    watch: {
        modelValue() {
            if (JSON.stringify(this.value) === JSON.stringify(this.modelValue)) { return; }

            this.captureModelValue();
        },
      value: {
        handler() {
            this.$emit('update:modelValue', this.value);
        },
        deep: true
      }
    },
    methods: {
        captureModelValue() {
          this.value = {}
          this.value.id = this.modelValue ? this.modelValue.id : null;
          this.value.media_bank_entry_id = this.modelValue ? this.modelValue.media_bank_entry_id : null;
        }
    },
}
</script>
