<template>
    <div class="bg-gradient-to-r from-myflowPurple-900 to-myflowPurple-700 w-screen flex justify-center">
        <div style="max-width: 1300px;" class=" ml-4 mr-4 pb-16 pt-16">
            <div class="flex justify-center mb-16 mt-16">
                <div class=" w-full lg:w-6/12">
                    <h2 class="text-center text-white text-xl lg:text-4xl tracking-wider">“Inom fem år behöver var och en förnya 40-50% av nuvarande kompetenser”, 
                        enligt World Economic Forum.</h2>
                </div>
            </div>

            <div class="lg:m-0 flex flex-col justify-center lg:justify-around lg:flex-row text-center w-full">

                <statistics-text-component 
                v-for="statistics in statsArray"
                v-bind:key="statistics.percentage"
                :statistics="statistics"
                 />
                

            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            statsArray:[
            {
                percentage: "70%",
                text: "av medarbetarna anser att företagets erbjudande inom lärande inte räcker till.",
                source: "Gartner/The Future Learning Organization"
            },
            {
                percentage: "86%",
                text: "anser att det är nödvän-digt att bygga en kultur som stöttar kontinuerligt lärande.",
                source: "Deloitte Human Global Capital Trends"
            },
            {
                percentage: "76%",
                text: "anser att lärande är viktigt, men 69% är osäkra på hur de ska ställa om till en lärande organisation.",
                source: "HR-föreningen"
            },
        ]};
    },
};
</script>

<style scoped></style>
