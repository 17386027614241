<script setup>
    const props = defineProps({
        startDate: {
            type: String,
            required: true,
        },
        endDate: {
            type: String,
            required: false,
        },
        startTime: {
            type: String,
            required: false,
        },
        endTime: {
            type: String,
            required: false,
        },
    })
</script>
<template>
    <div>
        <div
            v-if="props.startDate !== props.endDate"
        >
            <div class="flex text-xs whitespace-nowrap pb-0.5 justify-between space-x-1.5">
                <span>{{ props.startDate }}</span>
                <span>{{ props.startTime }}</span>
            </div>
            <hr>
            <div class="flex text-xs whitespace-nowrap pt-0.5 justify-between space-x-1.5">
                <span>{{ props.endDate }}</span>
                <span>{{ props.endTime }}</span>
            </div>
        </div>
        <div v-else>
            <div class="text-md font-bold mb-0.5 text-center">{{ props.startDate }}</div>
            <div class="flex text-xs whitespace-nowrap text-center justify-center">
                <span>{{ props.startTime }}</span>
                <span v-if="props.endTime" class="px-0.5">-</span>
                <span v-if="props.endTime">{{ props.endTime }}</span>
            </div>
        </div>
    </div>
</template>
