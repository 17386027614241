<template>
  <div
    class="rounded-2xl overflow-hidden shadow-lg bg-myflowPurple bg-gradient-to-r from-myflowPurple-600 to-myflowPurple-900 flex flex-col justify-between h-full"
  >
    <div class="flex flex-col h-full">
      <div
        class="w-full bg-cover bg-center relative"
        style="padding-bottom: 75%;"
        :style="{ backgroundImage: 'url(' + profileImg + ')' }"
      >
      </div>
      <div
        class="px-4 pt-2 bg-white rounded-2xl shadow-md -mt-4 relative transition-all m-3 h-full"
        :class="[previewing ? 'pb-6' : 'pb-2' ]"
      >
        <div class="bg-white rounded-t-2xl pt-2 pb-2">
          <div class="relative">
            <h2 class="text-myflowPurple-600 leading-6 mb-1">
              <a href="#">
                {{ coach.name }}
              </a>
            </h2>
            <p class="text-gray-600 font-bold mb-2" v-if="coach.name">{{ coach.title }}</p>
          </div>
        </div>
        <a
          href="#"
          class="block transition-all overflow-hidden"
          :class="[previewing ? 'max-h-screen' : 'max-h-0']"
          v-html="coach.description"
        >
        </a>
        <button
          class="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 p-1 bg-myflowMagenta text-white rounded-full shadow-md transition ring-2 ring-white"
          :class="[previewing ? 'rotate-45' : 'rotate-0' ]"
          @click="toggleDescription"
        >
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4" />
          </svg>
        </button>
      </div>
    </div>
    <div class=" px-4 pb-0 flex justify-between items-center bg-myflowPurple bg-gradient-to-r from-myflowPurple-600 to-myflowPurple-900 text-white">

      <div class="space-x-1 flex justify-end flex-wrap p-2">
        <span
          v-for="category in coach.categories"
          v-bind:key="category.id"
          class="mr-2"
        >
          #{{category.coaching_category.name}}
        </span>
      </div>

      <a
        :href="route('coaching.show', coach.id)"
        :title="'Visa ' + coach.title"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          class="h-12 w-8 cursor-pointer py-1 text-white"
        >
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M14 5l7 7m0 0l-7 7m7-7H3" />
        </svg>
      </a>



    </div>
  </div>
</template>
<script>
export default {
  props: {
    coach: Object,
    coachProfileImgUrl: String
  },
  data: function(){
    return {
      previewing: false,
      profileImg: this.coachProfileImgUrl
    }
  },
  methods: {
    toggleDescription() {
      this.previewing = !this.previewing
    },
  },
  mounted() {
  },
  computed: {
    fill: function(){
      if( this.favorited ){
        return 'currentColor'
      } else {
        return 'none'
      }
    }
  }
}
</script>
<style scoped>
  .h-min {
    height: min-coach;
  }
</style>