<template>

    <event-statistics-block class="block md:hidden lg:hidden" />
    <event-text-block-component class="block md:hidden lg:hidden" v-bind="textBlockContent" />


    <div class="bg-myflowPurple-700 flex justify-center">
        <div style="max-width:1300px;" class="lg:flex containerHeight items-center">
            <div class="text-white p-10">
                <div class="mb-8">
                    <h2 class="text-myflowYellow text-4xl">VARFÖR</h2>
                    <h2 class="text-4xl">THE POWER OF LEARNING?</h2>
                </div>
                <div>
                    <ul class="text-2xl list-disc">
                        <li>Gör personlig utveckling till en enkel vana!</li>
                        <br />
                        <li>Kompetenssäkra hela teamet - verktyg för att kickstarta lärandet hos er.</li>
                        <br />
                        <li>
                            Motiverande och engagerade medarbetare stannar
                            längre och presterar bättre.
                        </li>
                        <br /> 
                        <li>
                            Skapa konkurrenskraft och nå tillväxtmålen genom en
                            personalstyrka som når sin fulla potential.
                        </li>
                    </ul>
                </div>
            </div>
            <div class="p-10">
                <div class="w-full h-full flex justify-center">
                    <img
                        src="https://myflow.fra1.digitaloceanspaces.com/tpol/anna-tablet.png"
                        alt="image of video example"
                        class="h-auto w-full md:w-8/12 object-center"
                        loading="lazy"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            textBlockContent:{
                content: "Kickstarta en lärande kultur i företaget – för att behålla talanger, bygga konkurrenskraft och nå tillväxtmålen!"
            }
        };
    },
};
</script>

<style scoped>
.containerHeight {
    min-height: 45rem;
}
</style>
