<script setup>
    import { computed, onMounted, ref, reactive, watch } from 'vue'

    const emit = defineEmits(['workflowChanged'])

    const props = defineProps({
        expertContractId: {
            type: Number,
            required: true
        },
        currentWorkflowId: {
            type: Number,
            required: false,
            default: null
        },
    })

    const workflows = ref([])
    const selectedWorkflowId = ref(props.currentWorkflowId)
    const inspectedWorkflowId = ref(null)
    const inspectedWorkflowLogs = ref([])

    const state = reactive({
        slideoutOpen: false,
    })

    const loadWorkflows = async () => {
        const response = await axios.get(route('expert-contracts.workflows.index'))
        workflows.value = response.data
    }

    const loadWorkflowLogs = async () => {
        const response = await axios.get(route('expert-contracts.workflow-logs.index', [props.expertContractId, inspectedWorkflowId.value]))
        inspectedWorkflowLogs.value = response.data
    }

    const activateWorkflow = async (workflowId) => {
        const response = await axios.post(route('expert-contracts.workflow.changeWorkflow', [props.expertContractId, workflowId]))
        selectedWorkflowId.value = workflowId
    }

    const resetWorkflow = async (workflowId) => {
        const response = await axios.post(route('expert-contracts.workflow.resetWorkflow', [props.expertContractId, workflowId]))
    }

    const disableWorkflows = async () => {
        const response = await axios.post(route('expert-contracts.workflow.disableWorkflows', props.expertContractId))
        selectedWorkflowId.value = null
    }

    const inspectWorkflow = (workflowId) => {
        inspectedWorkflowId.value = workflowId
    }

    const selectedWorkflow = computed(() => {
        return workflows.value.find(workflow => workflow.id == selectedWorkflowId.value)
    })

    const inspectedWorkflow = computed(() => {
        return workflows.value.find(workflow => workflow.id == inspectedWorkflowId.value)
    })

    watch(selectedWorkflow, (workflow) => {
        emit('workflowChanged', workflow)
    })

    watch(inspectedWorkflowId, () => {
        if (inspectedWorkflowId.value) {
            loadWorkflowLogs()
        }
    })

    onMounted(() => {
        loadWorkflows()
    })

</script>
<template>
    <card-basic>
        <template v-slot:body>
            <div>
                <ul class="grid grid-cols-2 gap-2 md:grid-cols-3 lg:grid-cols-4">
                    <li class="m-2 flex w-full flex-col justify-center rounded-xl border-2 border-dashed p-4 border-myflowGreen">
                        <div v-if="selectedWorkflowId" class="flex flex-col items-center space-y-2">
                            <p class="text-center font-bold">
                                {{ selectedWorkflow?.workflow_name }}
                            </p>

                            <div class="flex space-x-2">
                                <button-with-confirmation
                                    v-if="selectedWorkflowId"
                                    confirm-type="warning"
                                    size="sm"
                                    @confirmed="resetWorkflow(workflow.id)"
                                    modalClass="absolute -bottom-2 -left-2 p-2 bg-white rounded shadow-md z-modal"
                                    :buttonLabel="_mft('expertContract:workflow.reset')"
                                    buttonClass="myflow-basic-button--danger whitespace-nowrap text-xs"
                                    :confirmButtonLabel="_mft('expertContract:workflow.reset')"
                                    confirmButtonClass="myflow-basic-button--success whitespace-nowrap"
                                    :confirm-text="_mft('expertContract:workflow.resetConfirmText')"
                                />

                                <button-with-confirmation
                                    @confirmed="disableWorkflows"
                                    confirm-type="danger"
                                    size="sm"
                                    modalClass="absolute -bottom-2 -left-2 -translate-x-1/2 p-2 bg-white rounded shadow-md z-modal"
                                    :buttonLabel="_mft('expertContract:workflow.disable')"
                                    buttonClass="myflow-basic-button--secondary whitespace-nowrap text-xs"
                                    :confirmButtonLabel="_mft('expertContract:workflow.disable')"
                                    :confirm-text="_mft('expertContract:workflow.disableConfirmText')"
                                />
                            </div>

                            <button
                                @click="inspectWorkflow(selectedWorkflowId)"
                                class="mt-2 text-xs myflow-basic-button--tintless"
                            >
                                {{ _mft('expertContract:workflow.showWorkflow') }}
                            </button>
                        </div>
                        <b v-else class="text-center">{{ _mft('expertContract:workflow.noWorkflowEnabled') }}</b>
                    </li>
                    <li
                        v-for="(workflow, index) in workflows.filter(workflow => workflow.id != selectedWorkflowId)"
                    >
                        <div
                            class="m-2 flex w-full flex-col rounded-xl bg-gray-100 p-4"
                        >
                            <p class="text-center font-bold">
                                {{ workflow.workflow_name }}
                            </p>

                            <div class="flex flex-col justify-center text-center">
                                <span v-if="selectedWorkflowId === workflow.id">
                                    <button-success
                                        :disabled="true"
                                    >
                                        {{ _mft('expertContract:workflow.enabledWorkflow') }}
                                    </button-success>
                                </span>

                                <button-with-confirmation
                                    v-else
                                    @confirmed="activateWorkflow(workflow.id)"
                                    size="sm"
                                    :confirm-type="selectedWorkflowId ? 'secondary' : 'success'"
                                    modalClass="absolute -bottom-2 -left-2 p-2 bg-white rounded shadow-md z-10"
                                    :buttonLabel="selectedWorkflowId ? _mft('expertContract:workflow.switchWorkflow') : _mft('expertContract:workflow.enableWorkflow')"
                                    buttonClass="myflow-basic-button--success whitespace-nowrap text-xs"
                                    :confirmButtonLabel="selectedWorkflowId ? _mft('expertContract:workflow.switchWorkflow') : _mft('expertContract:workflow.enableWorkflow')"
                                    confirmButtonClass="myflow-basic-button--success whitespace-nowrap"
                                    :confirm-text="_mft('expertContract:workflow.switchWorkflowConfirmText')"
                                />

                                <button
                                    @click="inspectWorkflow(workflow.id)"
                                    class="mt-2 text-xs myflow-basic-button--tintless"
                                >
                                    {{ _mft('expertContract:workflow.showWorkflow') }}
                                </button>
                            </div>
                        </div>
                    </li>
                </ul>
                <div class="mt-2 text-right">
                    <a :href="route('expert-contracts.settings.edit')" target="_blank">
                        {{ _mft('expertContract:workflow.manageWorkflows') }}
                    </a>
                </div>
            </div>
            <slideout-with-slots
                v-if="inspectedWorkflowId"
                :title="_mft('expertContract:workflow.showWorkflow')"
                @close="inspectedWorkflowId = null"
            >
                <template #body>
                    <div class="pb-24">
                        <ul class="divide-y">
                            <li
                                v-for="(content, index) in inspectedWorkflow?.contents"
                                :key="index"
                                class="mb-4"
                            >
                                <div class="p-2">
                                    <div class="mb-2">
                                        <i
                                            v-if="content.content_type === 'sms'"
                                            class="mr-1 inline-block fa-mobile fa-solid text-myflowPurple-900"
                                        ></i>
                                        <i
                                            v-if="content.content_type === 'email'"
                                            class="mr-1 inline-block fa-envelope fa-solid text-myflowPurple-900"
                                        ></i>
                                        <span v-if="content.content_type === 'sms'">{{ _mft('expertContract:workflow.inspect.asSms') }} </span>
                                        <span v-if="content.content_type === 'email'">{{ _mft('expertContract:workflow.inspect.asEmail') }} </span>
                                        {{ _mft('expertContract:workflow.inspect.afterNDays', {count: content.days_delay}) }}:<br>
                                        <i>{{ content.content }}</i>
                                    </div>

                                    <ul>
                                        <li
                                            v-for="(log, li) in inspectedWorkflowLogs.filter(log => log.workflow_content_id == content.id)"
                                            :key="li"
                                            class="text-gray-500"
                                        >
                                            <div class="flex justify-between space-x-4">
                                                <span>{{ log.signature_request.signee_name }}</span>
                                                <span>{{ log.created_at }}</span>
                                            </div>

                                            <div
                                                v-if="!log.signature_request.signee_phone && inspectedWorkflow.content_type == 'sms'"
                                            >
                                                <i>{{ _mft('expertContract:workflow.inspect.recipientMissingPhonenumber') }}</i>
                                            </div>
                                            <div
                                                v-if="!log.signature_request.signee_email && inspectedWorkflow.content_type == 'email'"
                                            >
                                                <i>{{ _mft('expertContract:workflow.inspect.recipientMissingEmail') }}</i>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                        <p
                            v-if="inspectedWorkflow?.contents.length === 0"
                            class="text-gray-400 font-italic"
                        >
                            {{ _mft('expertContract:workflow.noContentAdded') }}
                        </p>
                    </div>
                </template>
            </slideout-with-slots>
        </template>
    </card-basic>
</template>
