<script setup>
import { computed } from 'vue'

const props = defineProps({
    content: {
        type: Object,
        required: true,
    },
});

const title = computed(() => props.content.title);
const body = computed(() => props.content.body);
const items = computed(() => props.content.items);
</script>
<template>
    <div class="my-6">
        <div class="bg-white shadow-custom rounded-wl overflow-hidden">
            <section class="p-6">
                <h2 class="pb-1 text-xl md:text-2xl lg:text-3xl">{{title}}</h2>
                <div v-html="body"></div>
                <ul class="pl-4 space-y-3 max-x-2xl">
                    <li v-for="(item, idx) in items" :key="idx">{{item.text}}</li>
                </ul>
            </section>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        title: { type: String, required: true },
        body: { type: String, required: true },
        items: { type: Array, required: true },
    }
};
</script>

<style scoped>
    li {
        list-style: none;
        position: relative;
    }
    li:before {
        position: absolute;
        top: 0.5rem;
        right: 100%;
        margin-right: 0.5rem;
        content: '';
        background: #000000;
        border-radius: 4px;
        display: inline-block;
        width: 0.4rem;
        height: 0.4rem;
    }
</style>
