<template>
  <div class="flex flex-col w-full rounded-lg shadow-lg overflow-hidden">
    <ColorPicker
      class="w-full"
      :color="primaryColor"
      @color-change="updateColor"
      alphaChannel="hide"
    >
      <template #hue-range-input-label>
        <span class="sr-only">Hue</span>
      </template>

      <template #alpha-range-input-label>
        <span class="sr-only">Alpha</span>
      </template>

      <template #copy-button>
        <span class="sr-only">Copy color</span>

        <svg
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="15"
          viewBox="0 0 15 15"
        >
          <path
            d="M5 0v2H1v13h12v-3h-1v2H2V5h10v3h1V2H9V0zm1 1h2v2h3v1H3V3h3z"
            fill="currentColor"
          />

          <path
            d="M10 7v2h5v2h-5v2l-3-3zM3 6h5v1H3zm0 2h3v1H3zm0 2h3v1H3zm0 2h5v1H3z"
            fill="currentColor"
          />
        </svg>
      </template>

      <template #format-switch-button>
        <span class="sr-only">Switch format</span>
      </template>
    </ColorPicker>
    <div
      class="w-full h-24 flex justify-center items-center"
      :style="previewStyle"
      v-if="showGradientPreview"
    ><p class="text-white italic m-0">Color sample.</p></div>
  </div>
</template>
<script>
import { ColorPicker } from 'vue-accessible-color-picker'

export default {
  components: {
    ColorPicker
  },
  props: {
    showGradientPreview: {
      type: Boolean,
      default: false
    },
    primaryColor: {
      type: String,
      default: '#9166a0'
    },
    secondaryColor: {
      type: String,
      default: '#622578'
    }
  },
  data() {
    return {
      selectedColor: '',
      calculatedSecondaryColor: ''
    }
  },
  mounted() {
    this.selectedColor = this.primaryColor
    this.calculatedSecondaryColor = this.secondaryColor
  },
  methods: {
    updateColor(eventData){
      let l;
      if( parseInt(eventData.colors.hsl.l * 10) < 1 ){
        l = 0.2
      } else if ( parseInt(eventData.colors.hsl.l * 10) > 8 ) {
        l = 0.9
      } else {
        l = eventData.colors.hsl.l
      }
      this.selectedColor = 'hsl(' + parseInt( 360 * eventData.colors.hsl.h, 10) + ' ' + parseInt(eventData.colors.hsl.s * 100, 10)  + '% ' + parseInt( l * 100, 10) + '%)'
      this.calculatedSecondaryColor = 'hsl(' + parseInt( 360 * eventData.colors.hsl.h, 10) + ' ' + parseInt(eventData.colors.hsl.s * 100, 10)  + '% ' + (parseInt( l * 100, 10) - 20) + '%)'

      this.$emit('updateSelectedColor', this.selectedColor)
      this.$emit('updateCalculatedSecondaryColor', this.calculatedSecondaryColor)
    }
  },
  computed: {
    previewStyle(){
      return {
        backgroundImage: 'linear-gradient(to right, ' + this.selectedColor + ', ' + this.calculatedSecondaryColor + ')'
      }
    }
  }
}
</script>

<style>
</style>

<style scoped>
  :deep(.vacp-color-space) {
    overflow: hidden;
    border-radius: 6px;
  }
  .vacp-color-picker {
    max-width: 100%;
  }
  .vacp-copy-button {
    display: none;
  }
  :deep(.vacp-color-inputs) {
    display: none;
  }
</style>
