<template>
    <div
      class="fixed top-0 bottom-0 left-0 right-0 flex items-center justify-center w-screen h-screen p-2 transition-opacity duration-300 transform bg-black bg-opacity-50 trailer-container"
      :class="containerClass"
    >
      <div class="relative w-full max-w-5xl">
        <button
          class="absolute z-30 p-2 text-white transform top-2 right-2"
          v-on:click="close()"
        >
          <CloseIcon class="w-12 h-12" />
        </button>
        <div class="relative w-full overflow-hidden bg-black pb-widescreen rounded-3xl">
          <media-bank-entries-display
            v-if="trailerVideoUrl"
            class="absolute inset-0 z-10 w-full h-full"
            spinner-class="absolute inset-0 z-10 w-full h-full"
            :data-url="trailerVideoUrl"
          >
          </media-bank-entries-display>
        </div>
      </div>
      <div class="fixed inset-0" v-on:click="close()"></div>
    </div>
</template>
<script>
import Spinner from '../mf/spinner/medium.vue'
import CloseIcon from '../icons/close.vue'
export default {
  components: {
    Spinner,
    CloseIcon
  },

  props: {
    trailerVideoUrl: String
  },

  data: function(){
    return {
      classes: [],
      closing: false,
      loading: false,
      loadingVideo: true,
      player: {}
    }
  },

  methods: {
    close() {
      let self = this
      this.closing = true

      setTimeout(function(){
        self.$emit('close')
      }, 300)
    },
    onLoadStart(player) {
        player.play();
      this.loadingVideo = false;
    }
  },

  mounted(){
    let self = this

    setTimeout(function(){
      self.loading = true
    }, 25)
  },

  computed: {
    containerClass: function(){
      let classes = []

      if( this.closing ){
        classes.push('opacity-0')
        return classes.join(' ');
      }

      if( this.loading ){
        classes.push('translate-y-0')
        classes.push('opacity-100')
      } else {
        classes.push('translate-y-full opacity-0')
      }

      return classes.join(' ')
    }
  },
}
</script>
<style scoped>
.pb-widescreen {
  padding-bottom: 56.25%;
}
</style>
