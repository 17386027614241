 <template>
    <Teleport
        v-if="isLoaded"
        to="#teleport-target-modal"
    >
        <div
            class="fixed inset-0 z-modal-base transition-all bg-opacity-20 duration-200"
            :class="containerTransitionClass"
            v-if="isOpen"
        >
            <div
                class="fixed inset-0 z-modal-backdrop cursor-pointer"
                v-on:click="closeModal"
            ></div>
            <div class="fixed z-modal top-4 bottom-24 lg:bottom-4 w-full flex justify-center items-start lg:items-center">
                <div
                    class="relative z-modal max-w-screen rounded-xl bg-white shadow-xl p-4 lg:p-8 transition-all duration-200 transform w-modal mt-8 lg:mt-12 mb-12 max-h-full overflow-y-scroll"
                    :class="[modalTransitionClass, showClose ? 'pt-8' : '']"
                >
                    <div
                        class="absolute -top-2 -right-2 left-1/2 transform -translatex-1/2 bg-myflowPurple z-50 p-4 rounded-lg shadow-xl text-white"
                        v-if="pendingClose"
                    >
                        {{ _mft('modal:basic.changesWillNotBeSaved') }}
                        <div class="flex justify-end space-x-2">
                            <button class="myflow-basic-button--inverted" v-on:click="abortClose">{{ _mft('shared:confirm.cancel') }}</button>
                            <button class="myflow-basic-button--danger" v-on:click="closeModal">{{ _mft('shared:confirm.positive') }}</button>
                        </div>
                    </div>
                    <div
                        class="absolute top-1 right-1 bg-white text-gray-400 border-gray-400 border-2 p-px rounded-full cursor-pointer hover:text-gray-500 hover:border-gray-500 transition-colors"
                        v-on:click="closeModal"
                        v-if="showClose"
                    >
                        <svg viewBox="0 0 24 24" class="fill-current w-4 h-4" xmlns="http://www.w3.org/2000/svg"><path d="m0 0h24v24h-24z" opacity="0" transform="matrix(-1 0 0 -1 24 24)"/><path d="m13.41 12 4.3-4.29a1 1 0 1 0 -1.42-1.42l-4.29 4.3-4.29-4.3a1 1 0 0 0 -1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42z"/></svg>
                    </div>
                    <h2
                        v-if="this.$slots.header"
                        class="mb-2"
                    >
                        <slot name="header"></slot>
                    </h2>
                    <slot name="body"></slot>
                </div>
            </div>
        </div>
    </Teleport>
</template>
<script>

export default {
    emits: ['closed'],
    props: {
        isDirty: {
            type: Boolean,
            default: false
        },
        open: {
            type: Boolean,
            default: false
        },
        showClose: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            isOpen: false,
            isLoaded: false,
            state: null,
            pendingClose: false
        }
    },
    methods: {
        closeModal(){
            let close = true;
            if( this.isDirty && this.pendingClose === false ){
                close = false;
                this.pendingClose = true;
            }
            if( close ){
                let self = this
                this.pendingClose = false
                self.setTransitionState('transition-out')
                setTimeout(function(){
                    self.$emit('closed');
                    self.isOpen = false;
                }, 200);
            }
        },
        abortClose(){
            this.pendingClose = false;
        },
        setTransitionState(state){
            this.state = state
        }
    },
    mounted(){
        this.isLoaded = true
    },
    watch: {
        open() {
            this.isOpen = this.open;
        },
        isOpen(newVal, oldVal) {
            let self = this;
            setTimeout(function(){
                if( oldVal === false ){
                    self.setTransitionState('transition-in')
                } else {
                    self.setTransitionState('transition-out')
                }
            }, 1)
        }
    },
    computed: {
        modalTransitionClass() {
            if( this.state === 'transition-in' ){
                return 'translate-y-0 scale-100 opacity-1'
            } else {
                return 'translate-y-24 scale-95 opacity-0'
            }
        },
        containerTransitionClass() {
            if( this.state === 'transition-in' ){
                return 'bg-black'
            } else {
                return ''
            }
        }
    }
}
</script>
<style scoped>
    .w-modal {
        width: 90vw;
        width: calc(100vw - 32px);
        max-width: 576px;
    }
</style>
