<script setup>
import {computed, onMounted, ref} from 'vue'

const props = defineProps({
    content: {
        type: Object,
        required: true,
    },
});

const url = computed(() => props.content.text.trim());
const calendlyLoader = ref(null);
const loaded = ref(false);

const initCalendly = () => {
    if (typeof Calendly !== 'undefined' && url.value) {
        Calendly.initInlineWidget({
            parentElement: document.getElementById('calendly-inline-widget'),
            resize: true,
        });
        loaded.value = true;
        clearInterval(calendlyLoader.value);
    }
}

onMounted(() => {
    calendlyLoader.value = setInterval(function(){
        if (!loaded.value) {
            if (typeof Calendly !== 'undefined') {
                initCalendly()
            }
        }
    }, 500)
});

</script>

<template>
    <div>
        <mf-spinner-medium v-if="!loaded" class="w-24 h-12"></mf-spinner-medium>
        <div
            class="calendly-inline-widget"
            id="calendly-inline-widget"
            style="min-width:100%;height:700px"
            :data-url="url + '?hide_event_type_details=1&hide_gdpr_banner=1'"
        ></div>
    </div>
</template>
<style>
#calendly-inline-widget iframe {
    height: 100%;
}
</style>

