<template>
    <div
        class="grid gap-5 mb-3"
        :class="layout === 'half' ? 'grid-cols-1' : 'grid-cols-1 lg:grid-cols-2'"
    >
        <input-media-bank-entry
            type="video"
            label="Välj en video från mediabiblioteket"
            :required="true"
            v-model:content="mediaBankEntryId"
        />
        <media-bank-entries-display v-if="previewUrl" :data-url="previewUrl" />
    </div>
</template>
<script>
export default {
    props: {
        modelValue: {},
        layout: {
            type: String,
            default: 'full'
        }
    },
    emits: [
        'update:modelValue'
    ],
    data() {
        return {
            mediaBankEntryId: null
        };
    },
    computed: {
      previewUrl() {
        if (!this.mediaBankEntryId) { return; }

        return route('mediaBank.entries.show', this.mediaBankEntryId);
      }
    },
    async mounted() {
        this.captureModelValue();
    },
    watch: {
        modelValue() {
            this.captureModelValue();
        },
        mediaBankEntryId() {
            this.$emit('update:modelValue', {
                media_bank_entry_id: this.mediaBankEntryId
            });
        }
    },
    methods: {
        captureModelValue() {
            const newValue = (this.modelValue ? this.modelValue.media_bank_entry_id : null);
            if (this.mediaBankEntryId === newValue) { return; }

            this.mediaBankEntryId = newValue;
        }
    },
}
</script>
