<template>
    <div class="mb-4">
        <input-label
            :label="label"
            :required="required"
        />
        <div
        class="relative mt-1 rounded-md shadow-sm"
        >
        <div class="relative">
            <input
                :type="inputtype"
                class="block w-full p-2 pr-8 mt-1 border rounded-md"
                :class="[inputClass, typeClass]"
                :placeholder="placeholder"
                :value="content"
                @input="$emit('update:content', $event.target.value)"
                @blur="validateEmail"
                :required="required"
                :disabled="disabled"
                :name="name"
                :maxlength="maxlength"
            />
            <div
                v-if="!didNotValidate && validationPerformed"
                class="absolute flex items-center justify-center w-5 h-5 text-white rounded-full top-2 right-3 bg-myflowGreen-400"
            >
            <i class="text-sm fa-solid fa-check"></i>
        </div>
    </div>
</div>
<input-error v-if="error" :error="error" />
<input-error v-if="validationError" :error="validationError" />

</div>
</template>
<script>
export default {
    setup() {

    },
    data: function(){
        return {
            inputClass: '',
            validationPerformed: false,
            didNotValidate: false,
            validationError: ''
        }
    },
    props: {
        content: String,
        error: {type:String,default:''},
        label: String,
        placeholder: String,
        required: Boolean,
        name: String,
        disabled: {type:Boolean,default:false},
        inputtype: {type:String,default:'text'},
        maxlength: { type: Number, required: false }
    },
    methods: {
        validateEmail() {
            let classes = [];
            if( this.content === '' ){
                classes.push('border-gray')
                this.didNotValidate = false;
                this.$emit('isEmailValid', false);
                return;
            }
            if( this.emailIsValid ){
                classes.push('border-gray')
                this.didNotValidate = false;
                this.$emit('isEmailValid', true);
            } else {
                classes.push('border-red-700')
                this.didNotValidate = true;
                this.validationError = _mft('form:inputEmail.validationError');
                this.$emit('isEmailValid', false);
            }
            this.inputClass = classes.join(' ')
            this.validationPerformed = true
        }
    },
    computed: {
        typeClass() {
            if( this.inputtype !== 'text' ) {
                return 'pr-10';
            }
        },
        emailIsValid() {
            const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            return emailPattern.test(this.content);
        }
    },
    watch: {
        error: function(error){
            let classes = [];
            if( error !== '' ){
                classes.push('border-red-700')
            } else {
                classes.push('border-gray')
            }
            this.inputClass = classes.join(' ')
        },
        content: function(content){
            this.didNotValidate = false;
            if( this.didNotValidate === true ){
                this.validateEmail()
            } else {
                this.validationPerformed = false;
                if (this.emailIsValid) {
                    this.validationPerformed = true;
                    this.didNotValidate = false;
                    this.validationError = '';
                    this.$emit('isEmailValid', true);
                } else {
                    this.$emit('isEmailValid', false);
                }
            }
        },
        didNotValidate: function(didNotValidate){
            if( didNotValidate === false ){
                this.validationError = '';
            }
        }
    }
}
</script>
