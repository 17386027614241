<template>
    <div>
        <div>
            <div>
                <div>
                    <h2 class="mb-4 text-center">{{ surveySettings.name }}</h2>
                    <p class="text-center" v-html="surveySettings.description"></p>
                </div>
                <div>
                    <form @submit.prevent="createVotingItem">
                        <input-text
                            :label="surveySettings.submit_suggestions_label"
                            required="required"
                            :content="content"
                            name="content"
                            v-model:content="content"
                        />

                        <div class="flex justify-end ">
                            <button
                                class="myflow-basic-button"
                                type="submit"
                            >{{ surveySettings.submit_suggestions_button }}</button>
                        </div>
                    </form>
                </div>
            </div>

            <div>

                <div class="flow-root">
                    <ul role="list" class="pt-4">
                        <li
                            class="p-3 m-3 bg-gray-100 shadow-md rounded-xl voting-option-item"
                            v-for="question in questions"
                            :key="question.id"
                        >
                            <div class="flex items-center space-x-4">
                                <div class="flex-shrink-0">
                                    <h2
                                        :class="question.vote_sum > 0 ? 'text-myflowGreen-600' : 'text-gray-700'"
                                    >{{ surveySettings.show_as_percentage ? question.vote_percentage + '%' : question.vote_sum }}</h2>
                                </div>
                                <div class="flex-shrink-0">
                                    <img class="w-8 h-8 rounded-full" :src="question.submitted_by_img" alt="">
                                </div>
                                <div class="flex-1 min-w-0">
                                    <span class="text-sm font-medium text-gray-900">{{ question.content }}</span><br>
                                    <span class="text-sm text-gray-500 truncate">{{ question.submitted_by_name }}</span>
                                </div>
                                <div>
                                    <DeleteVotingItemQuestion
                                        :question="question"
                                        @updateParent="getVotingItems"
                                    />
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    import {
        PencilAltIcon
    } from '@heroicons/vue/solid'
    import DeleteVotingItemQuestion from './deleteVotingItemQuestion.vue';

    export default {
        components: {
            PencilAltIcon,
            DeleteVotingItemQuestion
        },
        props: {
            surveyId: Number,
            loggedIn: Boolean,
        },

        data: function(){
            return {
                content: '',
                errors: [],
                working: false,
                survey: this.surveyId,
                questions: [],
                surveySettings: [],
            }
        },

        methods: {
            async createVotingItem() {
                if(this.content) {
                    this.working = true;
                    var formData = new FormData();
                    formData.append("content", this.content);

                    const res = await axios.post( route('surveys.addItem', this.survey) , formData);

                    this.resetCreateBlogForm();
                    this.getVotingItems();
                    this.working = false;
                } else {
                    this.errors.content = 'Fält saknas';
                }
            },

            async getVotingItems() {
                const res = await axios.get( route('surveys.getItems', this.survey));
                this.questions = res.data.data;
                this.sortedArray();
            },

            async getSurvey() {
                const res = await axios.get( route('surveys.getSurvey', this.survey));
                this.surveySettings = res.data;
            },

            resetCreateBlogForm(){
                this.content = '';
            },

            sortedArray() {
                this.questions.sort((a,b) => {
                    return b.vote_sum - a.vote_sum
                })
            },
        },

        mounted: function(){
            this.getVotingItems();
            this.getSurvey();
        },
        computed: {
        }
    }
</script>

<style scoped>
    .note-content a {
        text-decoration: underline;
    }
</style>
