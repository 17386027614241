<script setup>
    import { onMounted, ref, reactive } from 'vue'

    const bookingDidComplete = ref(false)
    const bookingDidFail = ref(false)

    const state = reactive({
        message: null,
        booking: null,
        error: null
    })

    const props = defineProps({
        integrationType: {
            type: String,
            required: true,
        },
        sessionRoute: {
            type: String,
            required: true,
        },
    })

    const session = ref({})

    const loadSession = async () => {
        const response = await axios.get(props.sessionRoute)
        session.value = response.data.session
    }

    const onBookingCompleted = (result) => {
        bookingDidComplete.value = true
        state.message = result.message
        state.booking = result.booking
    }

    const onBookingFailed = (result) => {
        bookingDidComplete.value = false
        bookingDidFail.value = true
        state.error = result.error
    }

    onMounted(() => {
        loadSession()
    })

</script>
<template>
    <div>
        <div v-if="!bookingDidComplete && !bookingDidFail">
            <h1>{{ _mft('coaching:session.book') }}</h1>
            <booking-cronofy-calendar
                v-if="session.booking"
                :booking-id="session.booking.id"
                @bookingCompleted="onBookingCompleted"
                @bookingFailed="onBookingFailed"
            ></booking-cronofy-calendar>
        </div>

        <card-basic
            v-if="bookingDidComplete"
            class="max-w-xl mt-4"
        >
            <template v-slot:header>
                <h2>{{ state.message }}</h2>
            </template>
            <template v-slot:body>
                <div
                    class="flex justify-between py-2 space-x-4"
                >
                    <div class="flex space-x-4">
                        <date-time-box
                            :startDate="state.booking.begins_at.date"
                            :endDate="state.booking.ends_at.date"
                            :startTime="state.booking.begins_at.time"
                            :endTime="state.booking.ends_at.time"
                            class="px-4 py-1 border-r"
                        />
                        <div>
                            <div class="font-bold">
                                {{ state.booking.product.title }}
                            </div>
                            <div class="text-xs text-gray-500">
                                {{ state.booking.product.provider.name }}
                            </div>
                        </div>
                    </div>
                    <div>
                        <a
                            :href="route('coaching.client.session.show', state.booking.id)"
                            class="myflow-basic-button"
                        >{{ _mft('shared:show') }}</a>
                    </div>
                </div>
            </template>
        </card-basic>

        <div v-if="bookingDidFail">
            <h2>{{ _mft('coaching:session.book') }}</h2>
            <a
                class="myflow-basic-button"
                :href="route('coaching.client.session.index')"
            >{{ _mft('shared:goBackTryAgain') }}</a>
        </div>

    </div>
</template>
