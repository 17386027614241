<template>

    <settings-custom-user-area
        label="Enable custom user area"
    />

    <hr class="my-8">

    <settings-navigation
        title="Website navigation"
        navigation-type="website"
    />

    <settings-navigation
        title="Customer area navigation"
        navigation-type="customerarea"
    />

    <hr class="my-8">

    <domain-validation-requests />

    <hr class="my-8">

    <manage-subdomains />

</template>
