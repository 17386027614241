<template>
    <div
        id="gaugeArea"
        class="flex justify-center"
    ></div>
</template>

<script>

import GaugeChart from 'gauge-chart';

export default {
    props: {
        score: Number
    },

    data: function() {
        return {
        }
    },

    mounted() {
        this.chart();
    },

    methods: {
        chart() {
            // Element inside which you want to see the chart
            let element = document.querySelector('#gaugeArea')

            // Properties of the gauge
            let gaugeOptions = {
                hasNeedle: true,
                needleColor: 'gray',
                needleUpdateSpeed: 1000,
                arcColors: [
                    '#a7175a',
                    '#d49a25',
                    '#53772b'
                ],
                arcDelimiters: [33,67],
                rangeLabel: ['0', '100'],
                centralLabel: ' ',
            }

            // Drawing and updating the chart
            GaugeChart.gaugeChart(element, 300, gaugeOptions).updateNeedle(this.score)
        },
    }
}
</script>
