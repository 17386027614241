<script setup>

const emit = defineEmits(['update:sentPerDay'])

const props = defineProps({
    sentPerDay: {
        type: Number,
        required: true
    },
})

</script>

<template>
    <div>
        <div class="mb-4">
            <input-label
                class="mb-2"
                :label="_mft('leadHunterCampaign:summary.emailsSentPerDay.label')"
            />
            <div class="flex space-x-2 items-center w-full">
                <input-range
                    :content="sentPerDay"
                    min="5"
                    max="50"
                    step="5"
                    @update:content="emit('update:sentPerDay', $event)"
                    class="w-fulln"
                />
                <span class="inline-block w-24 text-left">{{ _mft('leadHunterCampaign:summary.emailsSentPerDay.suffix', {count: sentPerDay}) }}</span>

            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
