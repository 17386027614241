<template>
    <card-basic>
        <template v-slot:header>
            <h2>
                {{ _mft('crm:signaturesList.title') }}
            </h2>
        </template>
        <template v-slot:body>

            <div v-for="signature in signatures" v-bind:key="signature.id">
                <a
                    :href="'/expert-contracts/'+signature.uuid"
                    target="_blank"
                    class="float-right"
                >{{ _mft('crm:signaturesList.goToContract') }}</a>

                <span v-if="signature.is_signed">
                    <i class="mr-1 fa-solid fa-signature text-myflowGreen-900"></i> {{ _mft('crm:signatureList.signed') }}
                </span>
                <span v-if="!signature.is_signed && signature.expert_contract_signing_enabled">
                    <i class="mr-1 text-myflowRed-900 fa-solid fa-signature-slash"></i> {{ _mft('crm:signaturesList.awaitingSignature') }}
                </span>
                <span v-if="!signature.is_signed && !signature.expert_contract_signing_enabled">
                    <i class="mr-1 text-myflowYellow-900 fa-solid fa-signature-slash"></i> {{ _mft('crm:signaturesList.signingNotEnabled') }}
                </span>

                <h5 class="text-gray-800">
                    {{ signature.expert_contract_title }}
                </h5>

                <hr class="mt-4 mb-4">
            </div>

            <div v-if="signatures.length === 0">
                <i>{{ _mft('crm:signaturesList.noContractsSent') }}</i>
            </div>
        </template>
    </card-basic>

</template>

<script>
import LeadBookingForm from './leadBookingForm.vue';

export default {
    components: {
        LeadBookingForm
    },
    props: {
        signatures: Object,
    },
    data: function(){
        return {
            signatures: null,
        }
    },
    methods: {
    },
    mounted: function(){
    },
}
</script>
