<script setup>
import PodiumCard from './leaderboardPodiumCard.vue'

const props = defineProps({
    period: String,
    contestants: {
        type: Array,
        required: true,
        default: [
            {
                name: 'Contestant 1',
                profileImage: 'https://cdnwp.tonyrobbins.com/wp-content/uploads/2020/12/S_20200823_MILLA-KUHTO_8089.jpg',
                analysesCompletedCount: 19,
                insightMeetingsBookedCount: 16
            },
            {
                name: 'Contestant 2',
                profileImage: 'https://cdnwp.tonyrobbins.com/wp-content/uploads/2020/12/S_20200823_MILLA-KUHTO_8089.jpg',
                analysesCompletedCount: 16,
                insightMeetingsBookedCount: 13
            },
            {
                name: 'Contestant 3',
                profileImage: 'https://cdnwp.tonyrobbins.com/wp-content/uploads/2020/12/S_20200823_MILLA-KUHTO_8089.jpg',
                analysesCompletedCount: 15,
                insightMeetingsBookedCount: 12
            },
            {
                name: 'Contestant 4',
                profileImage: 'https://cdnwp.tonyrobbins.com/wp-content/uploads/2020/12/S_20200823_MILLA-KUHTO_8089.jpg',
                analysesCompletedCount: 9,
                insightMeetingsBookedCount: 8
            },
            {
                name: 'Contestant 5',
                profileImage: 'https://cdnwp.tonyrobbins.com/wp-content/uploads/2020/12/S_20200823_MILLA-KUHTO_8089.jpg',
                analysesCompletedCount: 7,
                insightMeetingsBookedCount: 4
            },
        ]
    }
})

const nonPodiumContestants = props.contestants.slice(3)

</script>

<template>
    <div>

        <div class="grid items-end max-w-xl grid-cols-10 gap-4 mx-auto">
            <PodiumCard :contestant="props.contestants[0]" emoji="🥇" class="col-span-10 mx-auto md:col-span-4 md:order-2 md:pb-8 max-w-72 md:max-w-none md:mx-0"></PodiumCard>

            <PodiumCard :contestant="props.contestants[1]" emoji="🥈" class="col-span-5 mx-auto md:col-span-3 md:order-1 md:pb-6 max-w-48 md:max-w-none md:mx-0"></PodiumCard>

            <PodiumCard :contestant="props.contestants[2]" emoji="🥉" class="col-span-5 mx-auto md:col-span-3 md:order-3 md:pb-0 max-w-48 md:max-w-none md:mx-0"></PodiumCard>
        </div>
        <ul class="mt-4 space-y-4">
            <li
                v-for="(contestant, c) in nonPodiumContestants"
                class="grid items-center grid-cols-12 gap-2 px-2 py-2 bg-white rounded-2xl"
            >
                <div class="text-lg text-right text-gray-400">
                    {{ c + 4}}
                </div>
                <div class="col-span-5 font-bold">
                    {{ contestant.name }}
                </div>
                <div class="grid items-center grid-cols-3 col-span-3 gap-2">
                    <span class="text-2xl text-right">{{ contestant.analysesCompletedCount }}</span>
                    <span class="col-span-2 text-xs">
                        Genomförda<br>
                        analyser
                    </span>
                </div>
                <div class="grid items-center grid-cols-3 col-span-3 gap-2">
                    <span class="text-2xl text-right">{{ contestant.insightMeetingsBookedCount }}</span>
                        <span class="col-span-2 text-xs">
                        Insight<br>
                        Meetings<br>
                        bokade
                    </span>
                </div>
            </li>
        </ul>
    </div>

</template>

<style scoped>

</style>
