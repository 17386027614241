<script setup>
import MediaBankEntry from "./util/media-bank-entry.vue";
import {computed} from "vue";

const props = defineProps({
    containerRef: {
        type: String,
        required: true
    },
    uuid: {
        type: String,
        require: true
    },
    content: {
        type: Object,
        required: true
    },
    layout: {
        type: String,
        required: false
    }
})

const flexAlignment = {
    'center': 'justify-center',
    'left': 'justify-start',
    'right': 'justify-end',
}

const alignmentClass = computed(() => {
    if (props.content?.alignment) {
        return props.content.alignment === 'full-width' ? 'w-full' : flexAlignment[props.content.alignment];
    }
    return 'justify-start';
})

</script>
<template>
<div class="max-w-4xl mx-auto my-6 overflow-hidden rounded-custom shadow-custom">
    <div class="flex" :class="alignmentClass">
        <div :class="props.content.alignment === 'full-width' ? 'w-full' : 'w-2/3 sm:w-1/2 lg:w-1/2'">
            <component :is="content.link_url? 'a' : 'span'" :href="content.link_url" target="_blank" class="text-inherit">
                <MediaBankEntry
                    :container-ref="containerRef"
                    :block-uuid="uuid"
                    :block-content="content"
                    :media-bank-entry-id="content.media_bank_entry_id"
                    property-name="media_bank_entry_id"
                ></MediaBankEntry>
            </component>
        </div>
    </div>
</div>
</template>
