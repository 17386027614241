<script setup>
import {ref, watch} from 'vue';

    const emit = defineEmits(['progress', 'selected']);

    const props = defineProps({
        pressedKey: {
            type: String,
            required: false,
            default: null
        },
        subquestion: {
            type: Object,
            required: true
        },
        subquestionCount: {
            type: Number,
            required: false,
            default: 1
        },
        isCurrentSubquestion: {
            type: Boolean,
            required: false,
            default: false
        },
        preselected: {
            type: Array,
            required: false,
            default: null
        }
    });

    const selectedOptionValue = ref(null)
    const keyIndex = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];

    const selectOption = (option) => {
        selectedOptionValue.value = option.value;
        emit('selected', [selectedOptionValue.value])
    }

    watch(() => props.pressedKey, (value) => {
        if (value === 'Enter') {
            emit('progress')
            return
        }

        const index = keyIndex.indexOf(value)
        if ( index < 0 || !props.subquestion.options[index]) {
            return
        }

        if (!props.isCurrentSubquestion) {
            return
        }

        selectOption(props.subquestion.options[index])
    }, {deep: true})

    watch(() => props.preselected, (value) => {
        if (value && selectedOptionValue.value !== value[0] ) {
            selectOption({value: value[0]})
        }
    }, {deep: true})

</script>

<template>
    <div>
        <ul class="grid grid-cols-2 gap-4 items-stretch">
            <li
                v-for="(option, oi) in subquestion.options"
                class="text-center block"
            >
                <button-base
                    @click="selectOption(option)"
                    :style-type="option.value === selectedOptionValue ? 'primary' : 'secondary'"
                    class="w-full justify-start"
                    :class="subquestion.options.length > 6 ? 'py-2' : 'min-h-16'"
                >
                    <div>
                        <div class="flex justify-start items-center space-x-2">
                            <span
                                class="hidden md:flex w-2 h-2 p-2 text-xs items-center justify-center text-gray-500 bg-gray-200 border-gray-300 capitalize border rounded-md transition-opacity"
                                :class="isCurrentSubquestion ? 'opacity-100' : 'opacity-0'"
                            >{{ keyIndex[oi]}}</span>
                            <div class="text-left">
                                <span class="text-left">{{ option.label }}</span>
                                <span
                                    v-if="option.sublabel"
                                    class="text-xs block text-left mt-1"
                                >{{ option.sublabel }}</span>
                            </div>
                        </div>
                    </div>
                </button-base>
            </li>
        </ul>
    </div>
</template>

<style scoped>

</style>
