<script setup>
</script>

<template>
    <div>
        <settings-appearance />
    </div>
</template>

<style>
</style>
