<script setup>
import {onMounted, ref} from 'vue'
    import SignatureRequests from './signatureRequests.vue'
    import ExpertContractPreferences from './preferences.vue'
    import Workflows from './workflows.vue'
    import ContentEditor from './contentEditor.vue'
    import Messages from './messages.vue'

    const props = defineProps({
        expertContract: {
            type: Object,
            required: true
        },
        conditions: {
            type: Object,
            required: true
        }
    })

    const navigationOptions = [
        {
            label: _mft('expertContract:navigation.content'),
            name: 'content',
        },
        {
            label: _mft('expertContract:navigation.preferences'),
            name: 'preferences',
        },
        {
            label: _mft('expertContract:navigation.workflows'),
            name: 'workflows',
        },
        {
            label: _mft('expertContract:navigation.recipients'),
            name: 'recipients',
        },
    ]

    const preferencescomponent = ref(null)
    const messagescomponent = ref(null)
    const signaturerequestscomponent = ref(null)

    const modal = ref(null)
    const workflows = ref([])
    const workflowLogs = ref([])
    const savingTitleStatus = ref('')
    const selectedWorkflow = ref(null)
    const currentSection = ref(navigationOptions[0])
    const conditions = ref(props.conditions)
    const preferences = ref(false)

    const awaitingPdfState = ref('')

    const loadWorkflows = async () => {
        const response = await axios.get(route('expert-contracts.workflows.index'))
        workflows.value = response.data
    }

    const updateSelectedWorkflow = (workflow) => {
        selectedWorkflow.value = workflow
    }

    const performRefresh = () => {
        preferencescomponent.value.reload()
        messagescomponent.value.reload()
    }

    const setSection = (section) => {
        currentSection.value = section

        if (section.name === 'preferences') {
            preferencescomponent.value.reload()
        }
        if (section.name === 'recipients') {
            signaturerequestscomponent.value.reload()
        }
    }

    const updateTitle = async () => {
        savingTitleStatus.value = 'waiting'
        const res = await axios.post(route('expert-contract.updateTitle', props.expertContract.id), {
            title: props.expertContract.title
        })

        if (res.data.success === true) {
            savingTitleStatus.value = 'success'
        } else {
            savingTitleStatus.value = 'error'
        }
    }

    const loadPreferences = async () => {
        const response = await axios.get(route('expert-contract-preferences.show', props.expertContract.id))
        preferences.value = response.data.preferences
        conditions.value = response.data.conditions
    }

    const updatePreferences = (newPreferences) => {
        preferences.value = newPreferences
    }

    const requestPdfCopy = async () => {
        awaitingPdfState.value = 'waiting'
        const res = await axios.get(route('expert-contracts.createPdf', props.expertContract.id))

        if (res.data.success) {
            awaitingPdfState.value = 'success'
        } else {
            awaitingPdfState.value = 'error'
        }
    }

    onMounted(() => {
        loadWorkflows()
        loadPreferences()
    })

</script>
<template>
    <div>
        <div :class="preferences.messages_enabled ? 'flex space-x-8 items-center' : ''">
            <div
                v-if="! preferences.signing_enabled"
                class="flex items-center space-x-2"
            >
                <input-text
                    name="title"
                    :label="_mft('expertContract:title')"
                    class="w-full"
                    v-model:content="props.expertContract.title"
                    required
                ></input-text>
                <button-success
                    size="md"
                    @click="updateTitle"
                    :state="savingTitleStatus"
                    class="mt-2"
                >
                    {{ _mft('shared:action.save') }}
                </button-success>
            </div>
            <h2
                v-else
                class="pt-5 pb-8"
            >
                {{ props.expertContract.title }}
            </h2>
            <Messages
                ref="messagescomponent"
                :messaging-enabled="preferences.messages_enabled"
                :expert-contract-id="props.expertContract.id"
            />
        </div>
        <div
            v-if="conditions.has_signature_requests && conditions.all_signature_requests_signed"
            class="mb-2 -mt-2 flex justify-end"
        >
            <button-secondary
                size="sm"
                :state="awaitingPdfState"
                :disabled="awaitingPdfState === 'waiting'"
                @click="requestPdfCopy"
            >
                {{ _mft('expertContract:downloadCopyAsPdf') }}
            </button-secondary>
        </div>

        <button-bar
            :options="navigationOptions"
            @select="setSection"
        />

        <div
            :class="currentSection.name === 'content' ? '' : 'hidden'"
        >
            <ContentEditor
                v-if="preferences"
                :expert-contract="props.expertContract"
                :conditions="props.conditions"
                :signing-enabled="preferences.signing_enabled"
            />
            <mf-spinner-medium v-else class="w-12 h-6"></mf-spinner-medium>
        </div>

        <div
            :class="currentSection.name === 'preferences' ? '' : 'hidden'"
        >
            <ExpertContractPreferences
                ref="preferencescomponent"
                :expert-contract-id="props.expertContract.id"
                @preferencesUpdated="updatePreferences"
            />
        </div>

        <div
            :class="currentSection.name === 'workflows' ? '' : 'hidden'"
        >
            <Workflows
                :current-workflow-id="props.expertContract.workflow_id"
                :expert-contract-id="props.expertContract.id"
                @workflowChanged="updateSelectedWorkflow"
            />
        </div>

        <div
            :class="currentSection.name === 'recipients' ? '' : 'hidden'"
        >
            <SignatureRequests
                ref="signaturerequestscomponent"
                :expert-contract-id="props.expertContract.id"
                :selected-workflow="selectedWorkflow"
                @preferencesUpdated="updatePreferences"
                @doRefresh="performRefresh"
            />
        </div>
    </div>
</template>
