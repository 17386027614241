<script setup>

import {computed, onMounted, reactive, ref, watch} from 'vue'
import Question from './question.vue'
import CompletedMessage from "./CompletedMessage.vue";

const props = defineProps({
    inviterUuid: {
        type: String,
        required: false,
        default: null
    },
    userLoggedIn: {
        type: Boolean,
        required: false,
        default: false
    },
    userData: {
        type: Object,
        required: false,
        default: {name: '', email: ''}
    }
})

const initialProgress = 20;
const transitionIn = ref(false)

const entrymode = ref('register')
const analysisUuid = ref(null)
const userDetails = reactive({
    name: props.userData.name,
    email: props.userData.email,
})

const nameValidates = ref(false)
const nameConfirmed = ref(false)
const emailInput = ref(null)
const emailValidates = ref(false)
const accountAlreadyExists = ref(false)

const rocketStage = ref(0)

const confirmName = () => {
    nameConfirmed.value = true
    emailInput.value.focusInput()
}

const availableQuestions = computed(() => {
    return questions.value
})

const currentQuestionNumber = ref(-1)

const selectedValues = ref({})
const responses = ref({})

const loadForContinue = async () => {
    const continueUuid = new URLSearchParams(window.location.search).get('continue')

    if (! continueUuid) {
        return
    }

    analysisUuid.value = continueUuid

    const res = await axios.get(route('expert.analysis.continue', continueUuid))

    if (! res.data.analysis_data || ! res.data.analysis_data.responses) {
        return
    }

    selectedValues.value = res.data.analysis_data.selected_values
    responses.value = res.data.analysis_data.responses
    userDetails.name = res.data.analysis_data.name
    userDetails.email = res.data.analysis_data.email

    currentQuestionNumber.value = Object.keys(res.data.analysis_data.responses).length
}

const nextQuestion = () => {
    if (currentQuestionNumber.value < 0) {
        currentQuestionNumber.value++
        return
    }
    updateAnalysis()
    if (progressionPossible.value) {
        currentQuestionNumber.value++
    }
}

const previousQuestion = () => {
    currentQuestionNumber.value--
}

const finish = () => {
    currentQuestionNumber.value++
    updateAnalysis()
}

const createAnalysis = async () => {
    if (analysisUuid.value !== null) {
        nextQuestion()
        return
    }

    const response = await axios.post(route('expert.analysis.create'), {
        name: userDetails.name,
        email: userDetails.email,
        inviter_uuid: props.inviterUuid
    });

    if (response.data.error) {
        if (! props.userLoggedIn && response.data.status === 'email.exists') {
            accountAlreadyExists.value = true
            return;
        }
    }

    if (response.data.success) {
        analysisUuid.value = response.data.analysis_uuid

        const url = new URL(window.location);
        url.searchParams.set('continue', analysisUuid.value);
        window.history.pushState('', '', url.toString());

        nextQuestion()
    }
}

const updateAnalysis = async () => {
    const response = await axios.put(route('expert.analysis.update', analysisUuid.value), {
        analysis_uuid: analysisUuid.value,
        selected_values: selectedValues.value,
        responses: responses.value
    });

    if (!response.data.success) {
        console.error('Something went wrong', response)
    }
}

const updateSelected = (questionSlug, subquestionSlug, value) => {
    selectedValues.value = {
        ...selectedValues.value,
        [questionSlug]: {
            ...selectedValues.value[questionSlug],
            [subquestionSlug]: value
        }
    }

    const questionTitle = questions.value.find(question => question.slug === questionSlug).title
    const questionId = questions.value.find(question => question.slug === questionSlug).id
    const selectedSubquestionOption = questions.value.find(question => question.slug === questionSlug).subquestions.find(subquestion => subquestion.slug === subquestionSlug).options.find(option => value.includes(option.value))
    const selectedSubquestionOptions = questions.value.find(question => question.slug === questionSlug).subquestions.find(subquestion => subquestion.slug === subquestionSlug).options.filter(option => value.includes(option.value))

    if (selectedSubquestionOption === undefined) {
        return
    }

    responses.value = {
        ...responses.value,
        [questionSlug]: {
            ...responses.value[questionSlug],
            [subquestionSlug]: {
                questionId: questionId,
                questionTitle: questionTitle,
                response: selectedSubquestionOptions,
            }
        }

    }
}

const analysisCompleted = async () => {
    await axios.post(route('expert.analysis.completed', analysisUuid.value))

    window.location = route('expert.analyze.attach', {uuid: analysisUuid.value})
}

const populateUserFromLogin = (userdetails) => {
    userDetails.name = userdetails.name
    userDetails.email = userdetails.email
    createAnalysis()
}

const currentQuestion = computed(() => {
    return questions.value[currentQuestionNumber.value]
})

const progressionPossible = computed(() => {
    if (!selectedValues.value[currentQuestion.value.slug]) {
        return false
    }
    if (currentQuestion.value.subquestions.length > Object.keys(selectedValues.value[currentQuestion.value.slug]).length){
        return false
    }

    return true
})

const progress = computed(() => {
    if ((currentQuestionNumber.value) >= questions.value.length) {
        return 100
    }
    return Math.ceil(initialProgress + ((currentQuestionNumber.value) / questions.value.length * 100) - 20)
})

watch(() => currentQuestionNumber.value, (newValue) => {
    if (progress.value > 50) {
        rocketStage.value = 0
    }
    if (progress.value > 68) {
        rocketStage.value = 0
    }
    if (progress.value > 85) {
        rocketStage.value = 0
    }
    if (progress.value === 100) {
        rocketStage.value = 4
    }
}, {deep: true})

onMounted(() => {
    loadForContinue()
    setTimeout(function(){
        transitionIn.value = true
    }, 800)
})

const questions = computed(() => {
    return [
        {
            id: -1,
            slug: 'roleMatching',
            title: 'Who are you?',
            description: `Which role best matches your current situation ${userDetails.name}?`,
            choicecount: 1,
            type: 'grid-buttons',
            subquestions: [
                {
                    slug: 'current',
                    label: 'Current',
                    options: [
                        {
                            value: 'coach',
                            label: 'Coach',
                            sublabel: ''
                        },
                        {
                            value: 'consultant',
                            label: 'Consultant',
                            sublabel: ''
                        },
                        {
                            value: 'speaker-educator',
                            label: 'Speaker & educator',
                            sublabel: ''
                        },
                        {
                            value: 'creator',
                            label: 'Creator',
                            sublabel: ''
                        },
                    ]
                }
            ]
        },
        {
            id: 0,
            slug: 'monthlyRevenue',
            title: 'Monthly revenue',
            description: `Your target monthly income in the business in ${responses.value['goalAchievementTime']?.current.response[0].label}`,
            choicecount: 1,
            type: 'vertical-buttons',
            subquestions: [
                {
                    slug: 'current',
                    label: 'Current',
                    options: [
                        {
                            value: 'e0-e5k',
                            label: '€0 - €5k',
                            sublabel: ''
                        },
                        {
                            value: 'e5k-e10k',
                            label: '€5k - €10k',
                            sublabel: ''
                        },
                        {
                            value: 'e10k-e15k',
                            label: '€10k - €15k',
                            sublabel: ''
                        },
                        {
                            value: 'e15k-e20k',
                            label: '€15k - €20k',
                            sublabel: ''
                        },
                        {
                            value: 'pluse20k',
                            label: '+€20k 🚀',
                            sublabel: ''
                        },
                    ],
                },
                {
                    slug: 'dream',
                    label: 'Dream',
                    options: [
                        {
                            value: 'e5k-e10k',
                            label: '€5k - €10k',
                            sublabel: ''
                        },
                        {
                            value: 'e10k-e15k',
                            label: '€10k - €15k',
                            sublabel: ''
                        },
                        {
                            value: 'e15k-e20k',
                            label: '€15k - €20k',
                            sublabel: ''
                        },
                        {
                            value: 'pluse20k',
                            label: '+€20k 🚀',
                            sublabel: ''
                        },
                    ],
                }
            ]
        },
        {
            id: 1,
            slug: 'averageClient',
            title: 'Average client',
            description: 'What\'s your average deal size for a client?',
            choicecount: 1,
            type: 'vertical-buttons',
            subquestions: [
                {
                    slug: 'current',
                    label: 'Current',
                    options: [
                        {
                            value: 'e100-e500',
                            label: '€100 - €500',
                            sublabel: ''
                        },
                        {
                            value: 'e500-e1000',
                            label: '€500 - €1 000',
                            sublabel: ''
                        },
                        {
                            value: 'e1000-e2500',
                            label: '€1 000 - €2 500',
                            sublabel: ''
                        },
                        {
                            value: 'e2500-e5000',
                            label: '€2 500 - €5 000',
                            sublabel: ''
                        },
                        {
                            value: 'pluse5000',
                            label: '+€5 000 🚀',
                            sublabel: ''
                        },
                    ]
                },
                {
                    slug: 'dream',
                    label: 'Dream',
                    options: [
                        {
                            value: 'e100-e500',
                            label: '€100 - €500',
                            sublabel: ''
                        },
                        {
                            value: 'e500-e1000',
                            label: '€500 - €1 000',
                            sublabel: ''
                        },
                        {
                            value: 'e1000-e2500',
                            label: '€1 000 - €2 500',
                            sublabel: ''
                        },
                        {
                            value: 'e2500-e5000',
                            label: '€2 500 - €5 000',
                            sublabel: ''
                        },
                        {
                            value: 'pluse5000',
                            label: '+€5 000 🚀',
                            sublabel: ''
                        },
                    ]
                }
            ],
        },
        {
            id: 2,
            slug: 'averageBillingPerHour',
            title: 'Average billing per hour',
            description: 'What\'s your average billing per hour?',
            choicecount: 1,
            type: 'grid-buttons',
            subquestions: [
                {
                    slug: 'current',
                    label: 'Current',
                    options: [
                        {
                            value: '25',
                            label: '€25 - €50',
                            sublabel: ''
                        },
                        {
                            value: '50',
                            label: '€50 - €75',
                            sublabel: ''
                        },
                        {
                            value: '75',
                            label: '€75 - €100',
                            sublabel: ''
                        },
                        {
                            value: '100',
                            label: '€100 - €125',
                            sublabel: ''
                        },
                        {
                            value: '125',
                            label: '€125 - €150',
                            sublabel: ''
                        },
                        {
                            value: '150',
                            label: '€150 - €200',
                            sublabel: ''
                        },
                        {
                            value: '200',
                            label: '€200 - €250',
                            sublabel: ''
                        },
                        {
                            value: '250',
                            label: '€250 - €300',
                            sublabel: ''
                        },
                        {
                            value: '300',
                            label: '€300 - €350',
                            sublabel: ''
                        },
                        {
                            value: '350',
                            label: '€350 - €400',
                            sublabel: ''
                        },
                        {
                            value: '400',
                            label: '€400 - €500',
                            sublabel: ''
                        },
                        {
                            value: '500',
                            label: '€500 - €600',
                            sublabel: ''
                        },
                        {
                            value: '600',
                            label: '€600 - €700',
                            sublabel: ''
                        },
                        {
                            value: '700',
                            label: '€700+',
                            sublabel: ''
                        },
                    ]
                },
            ],
        },
        {
            id: 3,
            slug: 'availableTime',
            title: 'Available time',
            description: 'Without burnout, could you handle more clients?',
            choicecount: 1,
            type: 'grid-buttons',
            subquestions: [
                {
                    slug: 'current',
                    label: 'Current',
                    options: [
                        {
                            value: 'bring-it-on',
                            label: 'Bring it on',
                            sublabel: ''
                        },
                        {
                            value: 'fully-booked',
                            label: 'Fully booked',
                            sublabel: ''
                        },
                    ],
                }
            ]
        },
        {
            id: 4,
            slug: 'wastedTime',
            title: 'Wasted time',
            description: 'During an average week, how much time do you estimate could be spent on more productive tasks?',
            choicecount: 1,
            type: 'vertical-buttons',
            subquestions: [
                {
                    slug: 'current',
                    label: 'Current',
                    options: [
                        {
                            value: '2h-per-week',
                            label: '2h / week',
                            sublabel: ''
                        },
                        {
                            value: '4h-per-week',
                            label: '4h / week',
                            sublabel: ''
                        },
                        {
                            value: '6h-per-week',
                            label: '6h / week',
                            sublabel: ''
                        },
                        {
                            value: '8h-per-week',
                            label: '8h / week',
                            sublabel: ''
                        },
                        {
                            value: '10h-per-week',
                            label: '10h / week',
                            sublabel: ''
                        },
                    ],
                }
            ]
        },
        {
            id: 5,
            slug: 'typeOfBusiness',
            title: 'Type of business',
            description: 'How would you like to serve your clients?',
            choicecount: 1,
            type: 'vertical-buttons',
            subquestions: [
                {
                    slug: 'current',
                    label: 'Current',
                    options: [
                        {
                            value: 'on-demand',
                            label: 'On demand / Pre-recorded',
                            sublabel: 'My time is not required'
                        },
                        {
                            value: 'live-in-person',
                            label: 'Live / In person',
                            sublabel: 'I need to be present'
                        },
                        {
                            value: 'hybrid',
                            label: 'Hybrid',
                            sublabel: 'A bit of both'
                        },
                    ],
                },
                {
                    slug: 'dream',
                    label: 'Dream',
                    options: [
                        {
                            value: 'on-demand',
                            label: 'On demand / Pre-recorded',
                            sublabel: 'My time is not required'
                        },
                        {
                            value: 'live-in-person',
                            label: 'Live / In person',
                            sublabel: 'I need to be present'
                        },
                        {
                            value: 'hybrid',
                            label: 'Hybrid',
                            sublabel: 'A bit of both'
                        },
                    ],
                }
            ]
        },
        {
            id: 6,
            slug: 'coldLeads',
            title: 'Cold leads - monthly',
            description: 'How many cold leads do you receive each month?',
            choicecount: 1,
            type: 'vertical-buttons',
            subquestions: [
                {
                    slug: 'current',
                    label: 'Current',
                    options: [
                        {
                            value: '0-10',
                            label: '0 - 10',
                            sublabel: ''
                        },
                        {
                            value: '10-25',
                            label: '10 - 25',
                            sublabel: ''
                        },
                        {
                            value: '25-50',
                            label: '25 - 50',
                            sublabel: ''
                        },
                        {
                            value: '50-100',
                            label: '50 - 100',
                            sublabel: ''
                        },
                        {
                            value: '100plus',
                            label: 'More 🚀',
                            sublabel: ''
                        },
                    ],
                }
            ]
        },
        {
            id: 7,
            slug: 'hotLeads',
            title: 'Hot leads - monthly',
            description: 'How many qualified dialouges do you have each month?',
            choicecount: 1,
            type: 'vertical-buttons',
            subquestions: [
                {
                    slug: 'current',
                    label: 'Current',
                    options: [
                        {
                            value: '0-10',
                            label: '0 - 10',
                            sublabel: ''
                        },
                        {
                            value: '10-25',
                            label: '10 - 25',
                            sublabel: ''
                        },
                        {
                            value: '25-50',
                            label: '25 - 50',
                            sublabel: ''
                        },
                        {
                            value: '50-100',
                            label: '50 - 100',
                            sublabel: ''
                        },
                        {
                            value: '100plus',
                            label: 'More 🚀',
                            sublabel: ''
                        },
                    ],
                }
            ]
        },
        {
            id: 8,
            slug: 'quotesAndOffers',
            title: 'Quotes and offers - monthly',
            description: 'How many times per month do you send quotes and offers?',
            choicecount: 1,
            type: 'vertical-buttons',
            subquestions: [
                {
                    slug: 'current',
                    label: 'Current',
                    options: [
                        {
                            value: '0-5',
                            label: '0 - 5',
                            sublabel: ''
                        },
                        {
                            value: '5-10',
                            label: '5 - 10',
                            sublabel: ''
                        },
                        {
                            value: '10-15',
                            label: '10 - 15',
                            sublabel: ''
                        },
                        {
                            value: '15-25',
                            label: '15 - 25',
                            sublabel: ''
                        },
                        {
                            value: '25plus',
                            label: 'More 🚀',
                            sublabel: ''
                        },
                    ],
                }
            ]
        },
        {
            id: 9,
            slug: 'trafficSources',
            title: 'Traffic sources',
            description: 'How do your leads find you?',
            choicecount: null,
            type: 'vertical-buttons',
            subquestions: [
                {
                    slug: 'current',
                    label: 'Current',
                    options: [
                        {
                            value: 'website',
                            label: 'Website',
                            sublabel: ''
                        },
                        {
                            value: 'linkedin',
                            label: 'LinkedIn',
                            sublabel: ''
                        },
                        {
                            value: 'blog',
                            label: 'Blog',
                            sublabel: ''
                        },
                        {
                            value: 'social-media',
                            label: 'Social media',
                            sublabel: ''
                        },
                        {
                            value: 'advertising',
                            label: 'Advertising',
                            sublabel: ''
                        },
                        {
                            value: 'events',
                            label: 'Events',
                            sublabel: ''
                        },
                        {
                            value: 'referrals',
                            label: 'Referrals',
                            sublabel: ''
                        },
                    ],
                }
            ]
        },
        {
            id: 10,
            slug: 'technicalKnowledge',
            title: 'Technical Knowledge',
            description: 'How do your rate your technical skills?',
            choicecount: 1,
            type: 'vertical-buttons',
            subquestions: [
                {
                    slug: 'current',
                    label: 'Current',
                    options: [
                        {
                            value: 'i-have-seen-a-computer',
                            label: 'I have seen a computer',
                            sublabel: ''
                        },
                        {
                            value: 'im-not-afraid-of-word-and-excel',
                            label: 'I\'m not afraid of Word and Excel',
                            sublabel: ''
                        },
                        {
                            value: 'interested-rather-than-skilled',
                            label: 'Interested rather than skilled',
                            sublabel: ''
                        },
                        {
                            value: 'pretty-tech-savvy',
                            label: 'Pretty tech savvy',
                            sublabel: ''
                        },
                        {
                            value: 'call-me-iron-man',
                            label: 'Call me Iron Man',
                            sublabel: ''
                        },
                    ],
                }
            ]
        },
        {
            id: 11,
            slug: 'usedFeatures',
            title: 'Your toolbox',
            description: 'What tools are included in your day to day toolbox?',
            choicecount: null,
            type: 'vertical-buttons',
            subquestions: [
                {
                    slug: 'current',
                    label: 'Current',
                    options: [
                        {
                            value: 'email-campaigns',
                            label: 'Email campaigns',
                            sublabel: 'For example Mailchimp, ActiveCampaign, etc.'
                        },
                        {
                            value: 'crm',
                            label: 'CRM or customer database',
                            sublabel: 'Such as Hubspot, Salesforce, etc.'
                        },
                        {
                            value: 'digital-signing',
                            label: 'Digital signing',
                            sublabel: 'For example Docusign, SignRequest, etc.'
                        },
                        {
                            value: 'booking-and-scheduling',
                            label: 'Meeting booking and scheduling',
                            sublabel: 'Like Calendly, Acuity, etc.'
                        },
                        {
                            value: 'information-management',
                            label: 'Information management',
                            sublabel: 'Such as Notion, Confluence, One Note etc.'
                        },
                        {
                            value: 'quotes-and-offers',
                            label: 'Tools for quotes and offers',
                            sublabel: 'Such as Proposify, PandaDoc, etc.'
                        },
                        {
                            value: 'project-management',
                            label: 'Project management',
                            sublabel: 'Like Asana, Trello, etc.'
                        },
                        {
                            value: 'custom-forms',
                            label: 'Forms and surveys',
                            sublabel: 'For example Typeform, Google Forms, etc.'
                        },
                        {
                            value: 'sales-funnels',
                            label: 'Sales funnels',
                            sublabel: 'Like ClickFunnels, LeadPages, etc.'
                        },
                        {
                            value: 'chat-and-messaging',
                            label: 'Messaging and chat',
                            sublabel: 'Such as Intercom, Drift, etc.'
                        },
                    ],
                }
            ]
        },
        {
            id: 12,
            slug: 'clientAcquisition',
            title: 'Client Acquisition',
            description: 'How do you attract new clients to your business?',
            choicecount: 1,
            type: 'vertical-buttons',
            subquestions: [
                {
                    slug: 'current',
                    label: 'Current',
                    options: [
                        {
                            value: 'passive',
                            label: 'Passive',
                            sublabel: 'I rely on word-of-mouth and referrals'
                        },
                        {
                            value: 'active',
                            label: 'Active',
                            sublabel: 'I actively seek out new clients through marketing efforts'
                        },
                        {
                            value: 'strategic',
                            label: 'Strategic',
                            sublabel: 'I have a well-defined client acquisition strategy'
                        },
                        {
                            value: 'innovative',
                            label: 'Innovative',
                            sublabel: 'I use cutting-edge techniques to attract clients'
                        },
                    ],
                }
            ]
        },
        {
            id: 13,
            slug: 'clientRetentionStrategies',
            title: 'Client Retention Strategies',
            description: 'How do you ensure long-term relationships with your clients?',
            choicecount: 1,
            type: 'vertical-buttons',
            subquestions: [
                {
                    slug: 'current',
                    label: 'Current',
                    options: [
                        {
                            value: 'minimal',
                            label: 'Minimal',
                            sublabel: 'I rely mostly on one-time transactions'
                        },
                        {
                            value: 'occasional-follow-ups',
                            label: 'Occasional follow-ups',
                            sublabel: 'I occasionally check in with clients but not systematically'
                        },
                        {
                            value: 'regular-communication',
                            label: 'Regular communication',
                            sublabel: 'I maintain regular contact with clients to nurture relationships'
                        },
                        {
                            value: 'tailored-strategies',
                            label: 'Tailored strategies',
                            sublabel: 'I personalize my approach to each client to maximize retention'
                        }
                    ]
                }
            ]
        },
        {
            id: 14,
            slug: 'professionalDevelopment',
            title: 'Professional Development',
            description: 'How committed are you to continuously improving your business skills?',
            choicecount: 1,
            type: 'vertical-buttons',
            subquestions: [
                {
                    slug: 'current',
                    label: 'Current',
                    options: [
                        {
                            value: 'minimal',
                            label: 'Minimal',
                            sublabel: 'I rarely seek out opportunities for professional growth'
                        },
                        {
                            value: 'occasional',
                            label: 'Occasional',
                            sublabel: 'I attend workshops or webinars infrequently'
                        },
                        {
                            value: 'moderate',
                            label: 'Moderate',
                            sublabel: 'I actively pursue learning opportunities to enhance my coaching abilities'
                        },
                        {
                            value: 'extensive',
                            label: 'Extensive',
                            sublabel: 'I consistently invest time and resources in furthering my professional development'
                        }
                    ]
                }
            ]
        },
        {
            id: 15,
            slug: 'gatheringClientFeedback',
            title: 'Gathering Client Feedback',
            description: 'How do you collect feedback from your clients to improve your services?',
            choicecount: 1,
            type: 'vertical-buttons',
            subquestions: [
                {
                    slug: 'current',
                    label: 'Current',
                    options: [
                        {
                            value: 'no-feedback-system',
                            label: 'No feedback system',
                            sublabel: 'I don’t actively seek feedback from clients'
                        },
                        {
                            value: 'informal-feedback',
                            label: 'Informal feedback',
                            sublabel: 'I occasionally ask for feedback during or after sessions'
                        },
                        {
                            value: 'structured-feedback',
                            label: 'Structured feedback',
                            sublabel: 'I have a formal process for collecting feedback from clients'
                        },
                        {
                            value: 'continuous-improvement',
                            label: 'Continuous improvement',
                            sublabel: 'I regularly solicit and act upon feedback to refine my services'
                        }
                    ]
                }
            ]
        },
        {
            id: 16,
            slug: 'goalAchievementTime',
            title: 'When do you want to achieve your goals?',
            description: 'I want to have my transformation by...',
            choicecount: 1,
            type: 'vertical-buttons',
            subquestions: [
                {
                    slug: 'current',
                    label: 'Current',
                    options: [
                        {
                            value: '1-month',
                            label: '1 Month',
                            sublabel: 'Hustle Hard'
                        },
                        {
                            value: '3-months',
                            label: '3 Months',
                            sublabel: "Let's get after it"
                        },
                        {
                            value: '6-months',
                            label: '6 Months',
                            sublabel: 'Steady progress'
                        },
                        {
                            value: '12-months',
                            label: '12 Months',
                            sublabel: 'Slow and steady'
                        },
                    ],
                }
            ]
        },
    ]
})


</script>

<template>
    <div>
        <div
            class="fixed z-50 transition-all duration-1000 delay-700 -top-1 md:top-2 left-2 right-2 transform"
            :class="currentQuestionNumber < 0 ? '-translate-y-24' : progress >= 100 ? '-translate-y-24' : 'translate-y-0'"
        >
            <progress-bar
                :progress="progress"
                progress-class="bg-gray-200"
                bar-class="bg-wlPrimary"
            ></progress-bar>
        </div>

        <div
            class="fixed inset-0 px-4 flex justify-center items-center transform transition-transform duration-700 lg:duration-1000 origin-bottom md:z-10 bg-gradient-to-r from-myflowAbyss to-myflowAbyss-light"
        >
            <div
                v-if="currentQuestionNumber === -1"
                class="max-w-lg"
            >
                <div
                    class="mb-8 duration-1800 ease-out transition-all"
                    :class="transitionIn ? 'opacity-100' : 'opacity-0 translate-y-24'"
                >
                    <h1 class="text-2xl font-bold text-center text-white mb-2">
                        Analyze your business
                    </h1>
                    <p class="text-lg text-center text-white">
                        Analyze your data to get insights and make better decisions
                    </p>
                </div>

                <div
                    v-if="!props.userLoggedIn"
                >
                    <card-basic
                        class="duration-1800 ease-out delay-[1500ms] transition-all"
                        :class="transitionIn ? 'opacity-100' : 'opacity-0 translate-y-12'"
                    >
                        <div v-if="entrymode === 'register'">
                            <div>
                                <div
                                    v-if="!nameConfirmed"
                                    class="text-center mx-auto max-w-72 mb-4 text-gray-600"
                                >
                                    <p>
                                        Let's get started by entering a few details to personalise the analysis.<br>
                                    </p>
                                </div>

                                <input-text
                                    label="What's your name?"
                                    maxlength="255"
                                    name="name"
                                    required
                                    autofocus
                                    @validatesAs="$event === 'required' ? nameValidates = true : null"
                                    v-model:content="userDetails.name"
                                    @keyup.enter="confirmName"
                                />
                                <div
                                    class="overflow-hidden transition-all"
                                    :class="!nameConfirmed ? 'max-h-0' : 'max-h-screen'"
                                >
                                    <p class="mt-1 mb-2 text-center max-w-72 text-gray-600 mx-auto">
                                        Nice to see you {{ userDetails.name }}!<br>
                                        Where should we send a copy of the report once we're done?
                                    </p>

                                    <input-text
                                        class="tranform transition-all"
                                        :class="!nameConfirmed ? 'translate-y-12 opacity-0' : ''"
                                        label="Email"
                                        inputtype="email"
                                        maxlength="255"
                                        name="email"
                                        required
                                        ref="emailInput"
                                        @validatesAs="$event === 'email' ? emailValidates = true : null"
                                        @clearValidates="emailValidates = false"
                                        v-model:content="userDetails.email"
                                        @keyup.enter="createAnalysis"
                                    />

                                    <p class="text-xs max-w-72 mx-auto text-center">Don't worry - we'll also show you the report once your done. No need to wait for an email, ain't nobody got time for that.</p>

                                    <p class="text-xs max-w-80 mx-auto text-center">
                                        You'll obviously finish it right away since you're awesome, but if you don't, we will send you a reminder.
                                    </p>


                                    <div
                                        v-if="accountAlreadyExists"
                                        class="mt-4 text-center"
                                    >
                                        <h5>Awesome!</h5>
                                        <p>
                                            You seem to already have a myflow account, try logging in instead.
                                        </p>
                                    </div>
                                </div>

                            </div>

                            <div class="flex justify-center">
                                <button-success
                                    v-if="!nameConfirmed"
                                    :disabled="userDetails.name === ''"
                                    @click="confirmName"
                                >That's me</button-success>

                                <button-base
                                    v-if="nameConfirmed && !accountAlreadyExists"
                                    :style-type="!emailValidates ? 'disabled' : 'success'"
                                    @click="createAnalysis"
                                >Let's go!</button-base>

                                <button-success
                                    v-if="accountAlreadyExists"
                                    @click="entrymode = 'login'"
                                >Log in</button-success>

                            </div>
                            <div v-if="!accountAlreadyExists" class="text-center">
                                <p class="mt-8 text-gray-600 text-xs">Already have a myflow account?</p>
                                <button-secondary
                                    @click="entrymode = 'login'"
                                    size="sm"
                                >Log in</button-secondary>
                            </div>
                        </div>
                        <div v-if="entrymode === 'login'">
                            <div class="max-w-96 mx-auto">
                                <login-form
                                    @successfulLogin="populateUserFromLogin"
                                />
                            </div>
                            <div class="text-center mt-6">
                                <div>
                                    <p class="mt-2 text-gray-600 text-xs">Don't have an account?</p>
                                    <button-secondary
                                        @click="entrymode = 'register'"
                                        size="sm"
                                    >Continue without an account</button-secondary>
                                </div>
                            </div>
                        </div>
                    </card-basic>

                    <p
                        class="text-center text-gray-300 duration-1800 ease-out delay-[2100ms] transition-all text-xs"
                        :class="transitionIn ? 'opacity-100' : 'opacity-0 translate-y-8'"
                    >
                        Already have the facts and want to get started right away?<br>
                        <a
                            class="text-white underline"
                            :href="route('expert.register.shortcut', {inviter: props.inviterUuid})"
                        >Create an account</a>
                    </p>
                </div>
                <div
                    v-else
                    class="duration-1800 ease-out delay-[600ms] transition-all text-center"
                    :class="transitionIn ? 'opacity-100' : 'opacity-0 translate-y-12'"
                >
                    <button-success
                        @click="createAnalysis"
                    >Let's go!</button-success>
                </div>
            </div>
        </div>

        <div>
            <div
                v-for="(question, i) in availableQuestions"
                :key="i"
            >
                <Question
                    :question="question"
                    :number="i"
                    :currentQuestionNumber="currentQuestionNumber"
                    :selected-values="selectedValues[question.slug] || {}"
                    @selected="updateSelected"
                    @progress="nextQuestion"
                >
                    <template v-slot:actions>
                        <div class="flex justify-between">
                            <div
                                v-if="progress < 100"
                                class="md:z-30"
                            >
                                <button-secondary
                                    v-if="currentQuestionNumber > 0"
                                    @click="previousQuestion"
                                >
                                    Back
                                </button-secondary>
                            </div>
                            <div class="md:z-30">
                                <button-success
                                    v-if="currentQuestionNumber > -1 && currentQuestionNumber < questions.length - 1"
                                    :disabled="!progressionPossible"
                                    @click="nextQuestion"
                                >
                                    Continue
                                </button-success>
                                <button-success
                                    v-if="currentQuestionNumber === questions.length - 1"
                                    @click="finish"
                                >
                                    Finish
                                </button-success>
                            </div>
                        </div>
                    </template>
                </Question>
            </div>
        </div>

        <div
            class="fixed inset-0 px-4 flex justify-center items-center transform transition-transform duration-700 lg:duration-1000 origin-bottom md:z-10 bg-gradient-to-r from-myflowAbyss to-myflowAbyss-light"
            v-if="currentQuestionNumber >= questions.length"
        >
            <div>
                <CompletedMessage
                    @completed="analysisCompleted"
                />
            </div>
        </div>

        <div class="fixed bottom-0 left-0 right-0 z-50 flex justify-center">
            <rocket-launch
                :stage="rocketStage"
            ></rocket-launch>
        </div>
    </div>
</template>

<style scoped>

</style>
