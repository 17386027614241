<script setup>
    import { ref } from 'vue'
    import IconChevron from "../../icons/iconChevron.vue";

    const props = defineProps({
       item: {
          type: Object,
          required: true
       }
    });

    const navigationExpanded = ref(false)

    const toggleNavigation = () => {
        navigationExpanded.value = !navigationExpanded.value
    }

</script>
<template>
    <div
        class="w-full"
    >
        <div
            class="flex items-center justify-end group"
            :class="item.subItems?.length > 0 ? 'pr-2' : 'pr-6'"
        >
            <slot></slot>
            <button
                v-if="item.subItems?.length > 0"
                @click="toggleNavigation"
                class="relative ml-2 flex items-center text-gray-400 m-0.5 h-4 w-6"
            >
                <icon-chevron
                    class="absolute inset-0 transform transition h-4 w-4"
                    :class="navigationExpanded ? 'rotate-180 fill-wlPrimary' : 'fill-wlPrimary-light'"
                />
            </button>
        </div>
        <div
            v-if="item.subItems?.length > 0"
            class="w-full"
            :class="navigationExpanded ? 'block mb-2' : 'hidden'"
        >
            <ul class="border-r pr-3 mr-6 border-wlPrimary-light -translate-x-px">
                <li
                    v-for="(subItem, i) in item.subItems.filter(si => si.visible === true)"
                    class="px-1 py-1"
                >
                    <a
                        :href="subItem.href"
                        class="text-wlNavigation hover:underline hover:outline-none text-xs"
                    >
                        {{ subItem.name }}
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>
