<script setup>
import {computed, onMounted, reactive, ref} from 'vue';
    import { useNotificationStore } from '@/stores/NotificationStore.js'

    const notificationStore = useNotificationStore()
    const props = defineProps({
        analysisUuid: {
            type: String,
            required: false,
            default: ''
        },
        redirectRoute: {
            type: String,
            required: false,
            default: 'expert.analyze.attach'
        },
        inviterUuid: {
            type: String,
            required: false,
            default: null
        },
        presetUserName: {
            type: String,
            required: false,
            default: '',
        },
        presetUserEmail: {
            type: String,
            required: false,
            default: '',
        },
        submitLabel: {
            type: String,
            required: false,
            default: 'Register'
        }
    })

    const registration = reactive({
        user: {
            name: props.presetUserName,
            email: props.presetUserEmail,
            title: '',
            password: '',
        },
        company: {
            name: '',
            org_nr: '',
            city: '',
            postal_code: '',
            country: '',
        },
        term_agreements: {
            data_processing: false,
            business_terms: false,
            user_agreement: false,
        },
    });

    const nameValidates = ref(false)
    const titleValidates = ref(false)
    const emailValidates = ref(false)
    const passwordValidates = ref(false)

    const companyNameValidates = ref(false)
    const vatNumberValidates = ref(false)
    const companyCityValidates = ref(false)
    const companyZipcodeValidates = ref(false)
    const companyCountryValidates = computed(() => registration.company.country !== '')


    const step = ref('user');
    const working = ref(false)

    const userValidates = computed(() => {
        return nameValidates.value && titleValidates.value && emailValidates.value && passwordValidates.value
    })

    const companyValidates = computed(() => {
        return companyNameValidates.value
            && vatNumberValidates.value
            && companyCityValidates.value
            && companyZipcodeValidates.value
            && companyCountryValidates.value
            && registration.term_agreements.data_processing
            && registration.term_agreements.business_terms
            && registration.term_agreements.user_agreement
    })

    const goBackToUser = () => {
        step.value = 'user'
    }

    const continueToCompany = () => {
        if (!userValidates.value) {
            return
        }

        step.value = 'interlude'
        setTimeout(() => {
            step.value = ''
        }, 2900)
        setTimeout(() => {
            step.value = 'company'
        }, 3500)
    }

    const submitRegistration = async () => {
        if (working.value || !companyValidates.value || !userValidates.value) {
            return
        }
        working.value = true

        const registrationData = {
            name: registration.user.name,
            email: registration.user.email,
            title: registration.user.title,
            password: registration.user.password,
            company_name: registration.company.name,
            postal_code: registration.company.postal_code,
            city: registration.company.city,
            country: registration.company.country,
            redirectRoute: props.redirectRoute,
            redirectParams: {uuid: props.analysisUuid}
        }

        const res = await axios.post(route('expert.register.submit', props.inviterUuid), registrationData);

        if (res.data.success) {
            step.value = 'registered'

            setTimeout(function() {
                window.location.href = res.data.redirectUrl
            }, 2000)
        }
        if (res.data.error) {
            notificationStore.addNotification({
                type: 'warning',
                title: 'Email already registered',
                message: `There already is an account associated with ${registration.user.email}`,
                dismissAfter: 5000,
            })
        }
        working.value = false
    }

    onMounted(() => {
        window.addEventListener('keyup', (event) => {
            if (event.key === 'Enter') {
                if (step.value === 'user') {
                    continueToCompany()
                }
                if (step.value === 'company') {
                    submitRegistration()
                }
            }
        })
    })

</script>

<template>
    <div class="relative max-w-xl mx-auto">
        <div
            class="relative z-10 text-gray-900 transition-all"
            :class="step === 'user' ? '' : '-translate-x-vw'"
        >
            <card-basic>
                <h3 class="mb-0 text-center">Your user details</h3>
                <p class="mb-6 text-xs italic text-center text-gray-500">It's all about you</p>

                <div class="relative space-x-2">
                    <input-text
                        class="w-full"
                        type="text"
                        label="Name"
                        maxlength="255"
                        name="name"
                        @validatesAs="$event === 'required' ? nameValidates = true : null"
                        @clearValidates="nameValidates = false"
                        required
                        v-model:content="registration.user.name"
                    />
                    <div class="absolute top-8 right-8">
                        <validation-mark
                            class="text-myflowGreen-600"
                            markClass="w-6 h-6"
                            v-if="nameValidates"
                        />
                    </div>
                </div>
                <div class="relative space-x-2">
                    <input-text
                        label="Title"
                        maxlength="255"
                        name="email"
                        @validatesAs="$event === 'required' ? titleValidates = true : null"
                        @clearValidates="titleValidates = false"
                        required
                        v-model:content="registration.user.title"
                    />
                    <div class="absolute top-8 right-8">
                        <validation-mark
                            class="text-myflowGreen-600"
                            markClass="w-6 h-6"
                            v-if="titleValidates"
                        />
                    </div>
                </div>
                <div class="relative space-x-2">
                    <input-text
                        label="Email"
                        maxlength="255"
                        inputtype="email"
                        name="email"
                        @validatesAs="$event === 'email' ? emailValidates = true : null"
                        @clearValidates="emailValidates = false"
                        required
                        v-model:content="registration.user.email"
                    />
                    <div class="absolute top-8 right-8">
                        <validation-mark
                            class="text-myflowGreen-600"
                            markClass="w-6 h-6"
                            v-if="emailValidates"
                        />
                    </div>
                    <p class="-mt-3 text-xs">Make sure this is your business email</p>
                </div>
                <div class="relative space-x-2">
                    <div>
                        <input-text
                            label="Password"
                            inputtype="password"
                            maxlength="255"
                            name="password"
                            @validatesAs="$event === 'password' ? passwordValidates = true : null"
                            @clearValidates="passwordValidates = false"
                            required
                            v-model:content="registration.user.password"
                        />
                        <p class="-mt-2 text-xs">Min 8 characters, one lowercase letter, one uppercase letter and one number</p>
                    </div>
                    <div class="absolute top-8 right-8">
                        <validation-mark
                            class="text-myflowGreen-600"
                            markClass="w-6 h-6"
                            v-if="passwordValidates"
                        />
                    </div>
                </div>

                <h3 class="mt-4 mb-0 text-center">Company details</h3>
                <p class="mb-6 text-xs italic text-center text-gray-500">Where the business happens</p>

                <div class="relative space-x-2">
                    <input-text
                        class="w-full"
                        type="text"
                        label="Company name"
                        maxlength="255"
                        name="name"
                        @validatesAs="$event === 'required' ? companyNameValidates = true : null"
                        @clearValidates="companyNameValidates = false"
                        required
                        v-model:content="registration.company.name"
                    />
                    <div class="absolute top-8 right-8">
                        <validation-mark
                            class="text-myflowGreen-600"
                            markClass="w-6 h-6"
                            v-if="companyNameValidates"
                        />
                    </div>
                </div>
                <div class="relative space-x-2">
                    <input-text
                        class="w-full"
                        type="text"
                        label="expertPro:registration.businessId"
                        maxlength="255"
                        name="name"
                        @validatesAs="$event === 'required' ? vatNumberValidates = true : null"
                        @clearValidates="vatNumberValidates = false"
                        required
                        v-model:content="registration.company.org_nr"
                    />
                    <div class="absolute top-8 right-8">
                        <validation-mark
                            class="text-myflowGreen-600"
                            markClass="w-6 h-6"
                            v-if="vatNumberValidates"
                        />
                    </div>
                </div>
                <div class="relative space-x-2">
                    <input-text
                        class="w-full"
                        type="text"
                        label="Zipcode"
                        maxlength="255"
                        name="name"
                        @validatesAs="$event === 'required' ? companyZipcodeValidates = true : null"
                        @clearValidates="companyZipcodeValidates = false"
                        required
                        v-model:content="registration.company.postal_code"
                    />
                    <div class="absolute top-8 right-8">
                        <validation-mark
                            class="text-myflowGreen-600"
                            markClass="w-6 h-6"
                            v-if="companyZipcodeValidates"
                        />
                    </div>
                </div>
                <div class="relative space-x-2">
                    <input-text
                        class="w-full"
                        type="text"
                        label="City"
                        maxlength="255"
                        name="name"
                        @validatesAs="$event === 'required' ? companyCityValidates = true : null"
                        @clearValidates="companyCityValidates = false"
                        required
                        v-model:content="registration.company.city"
                    />
                    <div class="absolute top-8 right-8">
                        <validation-mark
                            class="text-myflowGreen-600"
                            markClass="w-6 h-6"
                            v-if="companyCityValidates"
                        />
                    </div>
                </div>
                <div class="relative space-x-2">
                    <div>
                        <label>Country</label>
                        <input-language
                            :required="true"
                            name="country"
                            v-model:content="registration.company.country"
                        ></input-language>
                    </div>
                    <div class="absolute top-12 right-8">
                        <validation-mark
                            class="text-myflowGreen-600"
                            markClass="w-6 h-6"
                            v-if="companyCountryValidates"
                        />
                    </div>
                </div>

                <div class="col-span-1">
                    <div>
                        <input
                            type="checkbox"
                            v-model="registration.term_agreements.data_processing"
                            name="agrees_to_data_processing"
                            required
                            value="yes"
                        >
                        <span>
                I accept the
                <a
                    :href="route('terms.pub')"
                    class="font-bold underline text-myflowPurple-900"
                    target="_blank"
                >data processing agreement</a>
            </span>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            v-model="registration.term_agreements.business_terms"
                            name="agrees_to_business_terms"
                            required
                            value="yes"
                        >
                        <span>
                I accept the
                <a
                    :href="route('terms.company')"
                    class="font-bold underline text-myflowPurple-900"
                    target="_blank"
                >business terms</a>
            </span>
                    </div>
                    <div>
                        <input
                            type="checkbox"
                            v-model="registration.term_agreements.user_agreement"
                            name="agrees_to_user_agreement"
                            required
                            value="yes"
                        >
                        <span>
                I accept the
                <a
                    :href="route('terms.general')"
                    class="font-bold underline text-myflowPurple-900"
                    target="_blank"
                >user agreement</a>
            </span>
                    </div>
                </div>

                <div class="flex justify-between mt-2">
                    <button-base
                        :style-type="userValidates && companyValidates ? 'success' : 'disabled'"
                        :disabled="!companyValidates || !userValidates || working"
                        @click="submitRegistration"
                    >
                        {{ props.submitLabel }}
                    </button-base>
                </div>



            </card-basic>
        </div>

        <div
            class="absolute inset-0 flex items-center justify-center p-8 transition-all duration-1000 delay-300"
            :class="step === 'interlude' ? 'opacity-100 scale-100' : 'opacity-0 scale-90'"
        >
            <div class="text-center">
                <p>Just a moment while we make sure everything is set up perfectly</p>
                <p class="italic strong">You look great by the way! ☀️</p>
            </div>
        </div>

        <div
            class="relative z-10 text-gray-900 transition-all"
            :class="step === 'company' ? '' : 'opacity-0 disabled-translate-x-vw'"
        >

        </div>

        <div
            class="absolute inset-0 z-0 flex items-center justify-center p-8 transition-all duration-1000 delay-300 pointer-events-none"
            :class="step === 'registered' ? 'opacity-100 scale-100' : 'opacity-0 scale-90'"
        >
            <div class="text-center">
                <h1>Sweet</h1>
                <p>Your account is set up and we're putting the final pieces in place</p>
            </div>
        </div>
    </div>
</template>

<style scoped>
.translate-x-vw {
    transform: translateX(100vw);
}
.-translate-x-vw {
    transform: translateX(-100vw);
}
</style>
