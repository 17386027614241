<script setup>

import {onMounted, reactive, ref, watch} from "vue";

const props = defineProps({
    fillColors: {
        type: Array,
        default: () => ['#7814d7', '#7814d7', '#7814d7', '#7814d7']
    },
    strokeColor: {
        type: String,
        default: () => '#ebdef8'
    },
    labelColor:{
        type: String,
        default: () => '#3f3f3f'
    },
    valueColor:{
        type: String,
        default: () => '#7814d7'
    },
    series: {
        type: Array,
        required: false,
        default: () => [44, 55, 41, 17, 15]
    },
    labels: {
        type: Array,
        required: false,
        default: () => ['A', 'B', 'C', 'D', 'E']
    },
    lazyRender: {
        type: Boolean,
        default: false
    }
})

const cseries = ref([]);

const options = reactive({
    labels: props.labels,
    stroke: {
        width: 2,
        color: props.strokeColor,
    },
    legend: {
        show: false,
    },
    dataLabels: {
        enabled: false,
    },
    plotOptions: {
        pie: {
            expandOnClick: false,
            stroke: {
                show: true,
            },
            donut: {
                labels: {
                    show: true,
                },
                dataLabels: {
                    show: false,
                }
            },
        },
    },
    colors: props.fillColors,
})

const target = ref(null);
const isVisible = ref(false);

const observer = new IntersectionObserver(
    ([entry]) => {
        isVisible.value = entry.isIntersecting;
    },
    {
        threshold: 0.5
    }
);

watch(() => isVisible.value, (newVal) => {
    if (props.lazyRender) {
        cseries.value = props.series;
    }
})

onMounted(() => {
    if (props.lazyRender) {
        cseries.value = props.series.map(() => 0);
    } else {
        cseries.value = props.series;
    }
    observer.observe(target.value.$el);
});

</script>

<template>
    <div class="relative group">
        <apexchart ref="target" type="donut" :options="options" :series="cseries"></apexchart>
        <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 group-hover:opacity-0 group-hover:translate-y-1/2 transition-all pointer-events-none">
            <slot name="label"></slot>
        </div>
    </div>
</template>

<style scoped>

</style>
