<script setup>

import {computed, onMounted, ref} from "vue";
import InputText from "../forms/inputText.vue";
import IconChevron from "../icons/iconChevron.vue";

const emit = defineEmits(['productSelected'])
const products = ref([])
const searchTerm = ref('')
const displayAllLimit = 3;
const selectedProduct = ref({})
const hasFocus = ref(false)
const props = defineProps({
    currency: {
        type: String,
        required: false,
        default: ''
    }
})

const loadProducts = async () => {
    const res = await axios.get(route('api.custom-products.index'))
    products.value = res.data.products
}

const selectProduct = (product) => {
    emit('productSelected', product)
    selectedProduct.value = product
    searchTerm.value = ''
}

const filteredProducts = computed(() => {
    if (searchTerm.value === '' && hasFocus) {
        if (products.value.length <= displayAllLimit) {
            return products.value
        }
    }
    return products.value.filter(product => {
        return product.title.toLowerCase().includes(searchTerm.value.toLowerCase())
    })
})

const limitedFilteredProducts = computed(() => {
    return filteredProducts.value.slice(0, 5)
})

const remainingProducts = computed(() => {
    return filteredProducts.value.length - 5
})

onMounted(() => {
    loadProducts()
})

</script>

<template>
<div :class="searchTerm.length > 0 || hasFocus ? 'absolute inset-0 pt-8 px-4 md:px-6 lg:px-8 bg-white w-full h-full z-20' : ''">
    <div class="relative">
        <span
            v-if="hasFocus"
            class="absolute bottom-full -left-3"
        >
            <button-base
                size="sm"
                style-type="transparent"
                class="text-center"
                @click="hasFocus = false"
            >
                <span class="flex items-center">
                    <icon-chevron class="w-4 h-4 rotate-90 fill-gray-400"></icon-chevron>
                    <span>{{ _mft('shared:navigation.back') }}</span>
                </span>
            </button-base>
        </span>
        <input-text
            v-if="products.length > displayAllLimit"
            v-model:content="searchTerm"
            @focus="hasFocus = true"
            :placeholder="_mft('timeTracking:entry.product.search')"
        />
    </div>
    <p
        v-if="products.length === 0"
        class="text-xs text-gray-400"
    >
        {{ _mft('timeTracking:entry.product.noneFound') }}
    </p>
    <div
        v-if="products.length <= displayAllLimit || searchTerm.length > 0 || hasFocus"
        :class="products.length > displayAllLimit ? 'overflow-y-scroll absolute left-0 right-0 md:left-2 md:right-2 lg:left-4 lg:right-4 bottom-0 top-20 bg-white' : ''"
        class="md:-mx-2 lg:-mx-4"
    >
        <ul
            :class="[products.length > displayAllLimit ? 'divide-y' : 'text-xs mt-0.5 rounded-t-md mb-4 md:px-2 lg:px-4', filteredProducts.length > displayAllLimit ? 'pt-2' : '']"
        >
            <li
                v-for="product in filteredProducts"
                :key="product.id"
                class="hover:bg-gray-50 cursor-pointer flex space-x-2 justify-between"
                :class="[products.length > displayAllLimit ? 'px-4 md:px-6 lg:px-8 py-2 first:-mt-2' : 'py-1', product.id === selectedProduct.id ? 'bg-gray-200' : 'hover:bg-gray-200']"
                @click="selectProduct(product)"
            >
                <button @click="selectProduct(product)">{{ product.title }}</button> <span class="text-gray-400">{{ product.price }} {{ currency }}/ h</span>
            </li>
            <li
                v-if="searchTerm.length > 0 && filteredProducts.length === 0"
                class="px-5 py-0 text-gray-400 mb-2"
            >
                {{ _mft('timeTracking:entry.product.noneFound') }}
            </li>
        </ul>
    </div>
</div>
</template>

<style scoped>

</style>
