<template>
    <mf-table v-if="users !== null" :data="users" :columns="columns" :filters="filters" :searchable="searchable">
        <template #column-name="{ row }">
            <div :class="['flex', 'items-center']">
                <div class="flex-shrink-0 w-10 h-10">
                    <img
                        :src="row.profile_img_url ? row.profile_img_url : defaultProfileImageUrl"
                        class="w-10 h-10 rounded-full"
                        alt=""
                    >
                </div>
                <div class="ml-4">
                    <div class="text-sm font-medium text-gray-900">
                        {{row.name}}
                    </div>
                </div>
            </div>
        </template>
        <template #column-created_at="{ row }">
            <span v-if="row.created_at" class="uppercase">
                {{row.created_at}}
            </span>
            <span v-else class="text-sm text-gray-500">{{ _mft('company:userIndex.noNotes') }}</span>
        </template>
        <template #column-role="{ row }">
                <span v-if="row.role == 'company_admin'" class="mb-1 align-bottom myflow-basic-pill">
                    {{ _mft('company:userIndex.role.administrator') }}
                </span>
                <span v-if="row.role == 'company_member'" class="mb-1 align-bottom">
                    {{ _mft('company:userIndex.role.user') }}
                </span>
        </template>
        <template #actions="{ row }">
            <a :href="route('company.users.view', row.id)" class="text-sm font-medium text-right text-myflowPurple-600 hover:text-myflowPurple-900">
                {{ _mft('shared:navigation.open') }}
            </a>
        </template>
    </mf-table>
    <div v-else class="text-center">
        <mf-spinner-medium
          class="w-24 h-12"
        />
    </div>
</template>

<style lang="scss" scoped>
</style>

<script setup>
import { ref, toRefs, watch, onMounted } from 'vue';
import axios from 'axios';

const props = defineProps({
    url: { type: String, required: true }
});

const { url } = toRefs(props);

const defaultProfileImageUrl = 'https://myflow.fra1.digitaloceanspaces.com/generic-profile-icon-23.jpeg';
const dateFormatter = new Intl.DateTimeFormat(['sv-SE'], {
    timeZone: 'Europe/Stockholm',
    dateStyle: 'short'
});

const users = ref(null);
const updateUsers = async () => {
    const res = await axios.get(url.value + '?cachebust');
    users.value = res.data.data.map((row) => ({
        ...row,
        created_at: row.created_at ? dateFormatter.format(new Date(row.created_at)) : null
    }));
}
watch(url, updateUsers);
onMounted(updateUsers);

const columns = [
    { label: _mft('company:userIndex.column.name'), key: 'name', sort: 'name' },
    { label: _mft('company:userIndex.column.email'), key: 'email', sort: 'email', desktop: true },
    { label: _mft('company:userIndex.column.added'), key: 'created_at', sort: 'created_at'},
    { label: _mft('company:userIndex.column.role'), key: 'role', sort: 'role', desktop: true },
];
const searchable = ['name', 'email', 'role', 'created_at'];
const filters = [
]
</script>
