<template>
        <tr class="border-b-2 border-white">
            <td>{{tR.event}}</td>
            <td><i :class="[tR.live]">{{tR.liveText}}</i></td>
            <td><i :class="[tR.online]"></i>{{tR.onlineText}}</td>
        </tr>
</template>

<script>
export default {
    props: {
        tableRow: Object
    },
    data() {
        return {
            tR: this.tableRow
        };
    },
};


</script>

<style scoped>

</style>
