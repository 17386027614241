<script setup>

import {computed, onMounted, ref} from "vue";

const props = defineProps({
    sessionId: {
        type: Number,
        required: true,
    }
})

const session = ref(null)
const savingNotesState = ref('')

const loadSession = async () => {
    const res = await axios.get(route('api.coaching2.client.session.show', props.sessionId))

    if (res.data.success) {
        session.value = res.data.session
    }
}

const saveNotes = async () => {
    savingNotesState.value = ''
    const res = await axios.put(route('api.coaching2.client.session.notes.update', session.value.id), {
        notes: session.value.customer_notes
    })

    if (res.data.success) {
        savingNotesState.value = 'success'
    } else {
        savingNotesState.value = 'error'
    }
}

const reloadPage = (e) => {
    location.reload()
}

const sessionLocationIsUrl = computed(() => {
    return session.value && session.value.location && session.value.location.startsWith('http')
})

onMounted(() => {
    loadSession();
})

</script>

<template>
    <div>
        <div v-if="session">
            <p
                class="w-full mx-auto mt-4 md:mt-8 lg:mt-12 pl-4 md:pl-6 lg:pl-8"
                :class="session.customer_notes_enabled ? 'max-w-5xl ' : 'max-w-2xl '"
            >
               <a :href="route('user.coaching')">
                   <i class="fa-solid fa-arrow-left mr-1"></i>
                   {{ _mft('offerable:session.backToList') }}
               </a>
            </p>
            <card-basic
                class="w-full mx-auto mt-4"
                :class="session.customer_notes_enabled ? 'max-w-5xl ' : 'max-w-2xl '"
            >
                <div class="flex justify-between items-start text-gray-500">
                    <div class="w-full mb-2">
                        <h1 class="text-lg">{{ session.title }}</h1>
                        <p v-if="session.coach" class="mb-2">{{ session.client.name }} & {{ session.coach.name }}</p>
                        <p
                            v-if="session.scheduled"
                            class="text-xs text-gray-500 space-x-2 mb-0"
                        >
                            <span>{{ session.scheduled_at_date }}</span>
                            <span>
                                    {{ session.scheduled_at_time_start }} - {{ session.scheduled_at_time_end }}
                                </span>
                        </p>
                        <p class="mb-0 text-xs space-x-1 tracking-wide">
                            <span>{{ _mft('offerable:session.status.count', {count: session.stats.session_number, total: session.stats.sessions_total}) }}</span>
                            <span class="text-gray-300"> | </span>
                            <span>{{ session.stats.session_duration }}</span>
                        </p>
                    </div>

                    <div class="flex flex-col items-end">
                        <span class="font-bold whitespace-nowrap">
                            {{ _mft('offerable:status.' + session.status) }}
                        </span>
                        <coaching-v2-sessions-booking-modal
                            :session="session"
                            @bookingSuccess="reloadPage"
                            @cancelSuccess="reloadPage"
                        />
                    </div>
                </div>

                <p>
                    <a
                        v-if="sessionLocationIsUrl"
                        :href="session.location.trim()"
                        class="text-myflowGreen underline"
                        target="_blank"
                    >
                        {{ session.location }}
                    </a>
                    <span v-else>
                            {{ session.location }}
                        </span>
                </p>

                <div
                    v-if="session.customer_notes_enabled"
                >
                    <input-editor
                        v-model:content="session.customer_notes"
                    ></input-editor>
                    <div class="flex justify-end">
                        <button-success
                            :state="savingNotesState"
                            @click="saveNotes"
                        >
                            {{ _mft('offerable:action.saveNotes') }}
                        </button-success>
                    </div>
                </div>
            </card-basic>
        </div>
        <mf-spinner-medium
            v-else
            class="w-24 h-12"
        />
    </div>
</template>
