<template>
    <div>
        <div class="grid gap-4 lg:grid-cols-1 md:gap-8">
            <div class="mt-2">
                <strong>Sidbredd</strong>
                <div class="grid grid-cols-3 gap-4 mb-4">
                    <div
                        class="relative"
                        v-on:click="selectedPagewidth = '36rem'"
                    >
                        <div
                            class="flex items-center p-1 pr-2 m-2 ml-0 bg-gray-200 rounded-full cursor-pointer select-none"
                        >
                            <div
                                class="w-4 h-4 ml-px mr-2 rounded-full border-2 border-white"
                                :class="selectedPagewidth === '36rem' ? 'bg-myflowPurple' : 'bg-white'"
                            ></div>
                            <span class="tracking-tighter">Smal</span>
                        </div>
                    </div>
                    <div
                        class="relative"
                        v-on:click="selectedPagewidth = '48rem'"
                    >
                        <div
                            class="flex items-center p-1 pr-2 m-2 ml-0 bg-gray-200 rounded-full cursor-pointer select-none"
                        >
                            <div
                                class="w-4 h-4 ml-px mr-2 rounded-full border-2 border-white"
                                :class="selectedPagewidth === '48rem' ? 'bg-myflowPurple' : 'bg-white'"
                            ></div>
                            <span class="tracking-normal">Mellan</span>
                        </div>
                    </div>
                    <div
                        class="relative"
                        v-on:click="selectedPagewidth = '64rem'"
                    >
                        <div
                            class="flex items-center p-1 pr-2 m-2 ml-0 bg-gray-200 rounded-full cursor-pointer select-none"
                        >
                            <div
                                class="w-4 h-4 ml-px mr-2 rounded-full border-2 border-white"
                                :class="selectedPagewidth === '64rem' ? 'bg-myflowPurple' : 'bg-white'"
                            ></div>
                            <span class="tracking-widest">Bred</span>
                        </div>
                    </div>
                </div>


                <strong>Textstorlek</strong>
                <div class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-2 xl:grid-cols-3 gap-4 mb-4 gap-y-0">
                    <div
                        class="relative"
                        v-on:click="selectedFontsize = '80%'"
                    >
                        <div
                            class="flex items-center p-1 pr-2 m-2 ml-0 bg-gray-200 rounded-full cursor-pointer select-none"
                            style="font-size: 80%;"
                        >
                            <div
                                class="w-4 h-4 ml-px mr-2 rounded-full border-2 border-white"
                                :class="selectedFontsize === '80%' ? 'bg-myflowPurple' : 'bg-white'"
                            ></div>
                            Ganska liten
                        </div>
                    </div>
                    <div
                        class="relative"
                        v-on:click="selectedFontsize = '100%'"
                    >
                        <div
                            class="flex items-center p-1 pr-2 m-2 ml-0 bg-gray-200 rounded-full cursor-pointer select-none"
                            style="font-size: 100%;"
                        >
                            <div
                                class="w-4 h-4 ml-px mr-2 rounded-full border-2 border-white"
                                :class="selectedFontsize === '100%' ? 'bg-myflowPurple' : 'bg-white'"
                            ></div>
                            Lagom
                        </div>
                    </div>
                    <div
                        class="relative"
                        v-on:click="selectedFontsize = '145%'"
                    >
                        <div
                            class="flex items-center p-1 pr-2 m-2 ml-0 bg-gray-200 rounded-full cursor-pointer select-none"
                            style="font-size: 145%;"
                        >
                            <div
                                class="w-4 h-4 ml-px mr-2 rounded-full border-2 border-white"
                                :class="selectedFontsize === '145%' ? 'bg-myflowPurple' : 'bg-white'"
                            ></div>
                            Jättestor
                        </div>
                    </div>
                </div>

                <strong>Runda hörn</strong>
                <p>Används bl.a. på kort, bilder och filmer</p>
                <div class="grid grid-cols-2 gap-4 mt-2 md:gap-6">
                    <div
                        class=""
                        :class="boxClass"
                        v-on:click="selectedRounding = '0rem'"
                    >
                        <div
                            class="flex items-center m-4 text-xs cursor-pointer select-none"
                        >
                            <div
                                class="w-4 h-4 ml-px mr-2 rounded-full border-2 border-white"
                                :class="selectedRounding === '0rem' ? 'bg-myflowPurple' : 'bg-white'"
                            ></div>
                            Vassa hörn
                        </div>
                    </div>

                    <div
                        class="rounded-md"
                        :class="boxClass"
                        v-on:click="selectedRounding = '0.375rem'"
                    >
                        <div
                            :class="radioPanelClass"
                        >
                            <div
                                class="w-4 h-4 ml-px mr-2 rounded-full border-2 border-white"
                                :class="selectedRounding === '0.375rem' ? 'bg-myflowPurple' : 'bg-white'"
                            ></div>
                            Mjuka hörn
                        </div>
                    </div>

                    <div
                        class="rounded-xl"
                        :class="boxClass"
                        v-on:click="selectedRounding = '0.75rem'"
                    >
                        <div
                            :class="radioPanelClass"
                        >
                            <div
                                class="w-4 h-4 ml-px mr-2 rounded-full border-2 border-white"
                                :class="selectedRounding === '0.75rem' ? 'bg-myflowPurple' : 'bg-white'"
                            ></div>
                            Fluffiga hörn
                        </div>
                    </div>

                    <div
                        class="rounded-3xl"
                        :class="boxClass"
                        v-on:click="selectedRounding = '1.5rem'"
                    >
                        <div
                            :class="radioPanelClass"
                        >
                            <div
                                class="w-4 h-4 ml-px mr-2 rounded-full border-2 border-white"
                                :class="selectedRounding === '1.5rem' ? 'bg-myflowPurple' : 'bg-white'"
                            ></div>
                            Nästan inte hörn längre
                        </div>
                    </div>

                </div>
            </div>


            <div class="mt-2">
                <strong>Skuggor</strong>
                <p>Används bl.a. på kort, bilder och filmer</p>
                <div class="grid grid-cols-2 gap-4 mt-2 md:gap-6">

                    <div
                        class="rounded-md"
                        :class="boxClass"
                        v-on:click="selectedShadow = 'none'"
                    >
                        <div
                            :class="radioPanelClass"
                        >
                            <div
                                class="w-4 h-4 ml-px mr-2 rounded-full border-2 border-white"
                                :class="selectedShadow === 'none' ? 'bg-myflowPurple' : 'bg-white'"
                            ></div>
                            Ingen skugga
                        </div>
                    </div>

                    <div
                        class="rounded-md shadow"
                        :class="boxClass"
                        v-on:click="selectedShadow = '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)'"
                    >
                        <div
                            :class="radioPanelClass"
                        >
                            <div
                                class="w-4 h-4 ml-px mr-2 rounded-full border-2 border-white"
                                :class="selectedShadow === '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)' ? 'bg-myflowPurple' : 'bg-white'"
                            ></div>
                            Lite skugga
                        </div>
                    </div>

                    <div
                        class="rounded-md shadow-lg"
                        :class="boxClass"
                        v-on:click="selectedShadow = '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)'"
                    >
                        <div
                            :class="radioPanelClass"
                        >
                            <div
                                class="w-4 h-4 ml-px mr-2 rounded-full border-2 border-white"
                                :class="selectedShadow === '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)' ? 'bg-myflowPurple' : 'bg-white'"
                            ></div>
                            Mycket skugga
                        </div>
                    </div>

                    <div
                        class="rounded-md shadow-2xl"
                        :class="boxClass"
                        v-on:click="selectedShadow = '0 25px 50px -12px rgb(0 0 0 / 0.25)'"
                    >
                        <div
                            :class="radioPanelClass"
                        >
                            <div
                                class="w-4 h-4 ml-px mr-2 rounded-full border-2 border-white"
                                :class="selectedShadow === '0 25px 50px -12px rgb(0 0 0 / 0.25)' ? 'bg-myflowPurple' : 'bg-white'"
                            ></div>
                            Jättemycket skugga
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showDemo" class="flex items-center justify-center w-full max-w-lg mx-auto mt-12 mb-6 bg-gray-200 aspect-video" :style="demoStyle"><strong class="text-2xl font-bold text-white -rotate-3">Demo</strong></div>

        <input type="hidden" name="rounding" v-model="selectedRounding">
        <input type="hidden" name="shadow" v-model="selectedShadow">
        <input type="hidden" name="fontsize" v-model="selectedFontsize">
        <input type="hidden" name="pagewidth" v-model="selectedPagewidth">
    </div>
</template>
<script>

export default {
    props: {
        rounding: String,
        shadow: String,
        fontsize: String,
        pagewidth: String,
    },

    data() {
        return {
            boxClass: 'relative flex items-end justify-start h-20 bg-gray-200',
            selectedRounding: '',
            selectedShadow: '',
            selectedFontsize: '',
            selectedPagewidth: '',
            radioPanelClass: 'flex items-center m-4 text-xs cursor-pointer select-none',
            showDemo: false
        }
    },

    mounted() {
        this.selectedRounding = this.rounding;
        this.selectedShadow = this.shadow;
        this.selectedFontsize = this.fontsize;
        this.selectedPagewidth = this.pagewidth;
    },

    methods: {
    },

    computed: {
        demoStyle() {
            return {
                boxShadow: this.selectedShadow,
                borderRadius: this.selectedRounding
            }
        }
    }
}

</script>

<style scoped>
    .aspect-video {
        aspect-ratio: 16 / 9;
    }
</style>
