
<template>
    <div>
        <div class="w-full mb-2">
            <v-select
                v-if="availableForms"
                :options="availableForms"
                :reduce="(o) => o.slug"
                label="title"
                v-model="formSlug"
            >
                <template #search="{attributes, events}">
                    <input
                        class="vs__search"
                        :required="!formSlug"
                        v-bind="attributes"
                        v-on="events"
                    />
                </template>
            </v-select>
            <mf-spinner-medium v-else class="w-16 h-8"></mf-spinner-medium>
        </div>

        <a :href="route('forms.index')" class="myflow-basic-button--secondary" target="_blank">Hantera formulär</a>
        <button type="button" @click.prevent="loadAvailableForms()" class="ml-2 myflow-basic-button--warning">Ladda om tillgängliga formulär</button>
    </div>
</template>
<script>
import vSelect from 'vue-select';
import axios from 'axios';

export default {
    components: {
        vSelect
    },
    props: {
        modelValue: {},
        layout: {
            type: String,
            default: 'full'
        }
    },
    emits: [
        'update:modelValue'
    ],
    data() {
        return {
            availableForms: null,
            formSlug: null
        };
    },
    async mounted() {
        await this.loadAvailableForms();
        this.captureModelValue();
    },
    watch: {
        modelValue() {
            this.captureModelValue();
        },
        formSlug() {
            this.$emit('update:modelValue', {
                form_slug: this.formSlug
            });
        }
    },
    methods: {
        async loadAvailableForms() {
            const response = await axios.get(route('forms.index'));
            this.availableForms = response.data.data.filter((form) => form.published);
            // Ensure formSlug is still valid, or remove it
            if (!this.availableForms.find((f) => f.slug === this.formSlug)) {
                this.formSlug = null;
            }
        },
        captureModelValue() {
            const newValue = (this.modelValue ? this.modelValue.form_slug : null);
            if (this.formSlug === newValue) { return; }

            this.formSlug = newValue;
        }
    },
}
</script>
