<template>
  <div>
    <div class="md:flex">
      <div class="relative w-full md:w-2/3">
        <content-video-player
          :course-id="course.id"
          :chapter-id="chapter.id"
          :video-id="chapter.video.id"
          @playheadTimeUpdate="handlePlayheadTimeUpdate"
          @timestampUpdate="handleTimestampUpdate"
          @progressUpdate="handleProgressUpdate"
          @playerInitialized="handlePlayerInitialized"
          :updateInterval="updateInterval"
          :paused="quickPause"
          ref="videoplayer"
        />
      </div>
      <div class="relative flex flex-col-reverse w-full md:w-1/3 lg:flex-col">
        <div class="relative h-full">
          <div
            class="overflow-scroll lg:absolute lg:inset-0"
            ref="notescontainer"
          >
            <ul
              class="flex flex-col min-h-full lg:flex-col-reverse"
              ref="noteslist"
            >
              <li
                v-for="(note, i) in notesList"
                v-bind:key="i"
                class="flex justify-between p-2 mx-2 my-1 mb-2 bg-white rounded-lg shadow-md group"
              >
                <p
                  class="relative text-xs leading-4 break-words break-all whitespace-pre-line"
                  :ref="'note-' + i"
                >{{ note.text }}</p>
                <p
                  class="flex items-end text-xs text-right group"
                >
                  <span class="flex flex-row items-end lg:flex-row-reverse">
                    <span
                      class="mr-1 text-gray-400 cursor-pointer lg:mr-0 lg:ml-1 hover:text-gray-600"
                      v-on:click="jumpToTime(note.timestamp, 5)"
                    >
                      {{ new Date(note.timestamp * 1000).toISOString().substr(14, 5) }}
                    </span>
                    <span
                      v-on:click="editNote(i)"
                      class="transition-opacity duration-100 cursor-pointer text-myflowPurple-400 hover:text-myflowPurple-600 lg:opacity-0 group-hover:opacity-100"
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                      </svg>
                    </span>
                  </span>
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div class="relative pb-6 mx-2 mt-2 bg-white border rounded-md shadow-md lg:mt-0 border-b-1 lg:border-none">
          <div
            class="absolute bottom-0 left-0 right-0 z-20 flex justify-end overflow-hidden text-right"
          >
            <button
              class="px-2 py-1 mb-1 text-xs text-white transition transform bg-red-400 rounded-full"
              :class="[editingNote !== null ? 'translate-y-0' : 'translate-y-12' ]"
              v-on:click="abortEditNote()"
            >
                {{ _mft('shared:cancel') }}
            </button>
            <button
              class="px-2 py-1 mb-1 ml-1 mr-1 text-xs rounded-full myflow-basic-button"
              v-on:click="storeNote()"
            >
                {{ _mft('shared:action.save') }}
            </button>
          </div>
          <textarea
            :placeholder="_mft('course:note.takeNotesHere')"
            class="w-full p-2 leading-4 resize-none focus:outline-none focus:ring-0"
            style="line-height:1.2em;"
            :style="{fontSize: fontSize + 'px'}"
            :rows="editorRows"
            v-on:keyup="catchKeyupEvent"
            v-on:keydown="catchKeydownEvent"
            v-model="note"
            ref="noteEditor"
          ></textarea>
        </div>
        <div
          class="absolute top-0 whitespace-pre-line bg-gray-300 opacity-0 pointer-events-none left-2 right-2"
          ref="noteMirror"
          style="line-height:1.2em;"
          :style="{fontSize: fontSize + 'px'}"
        >{{ note }} &nbsp;</div>
      </div>
    </div>
  </div>
</template>
<script>
import { nextTick } from 'vue'

export default {
  components: {
  },
  props: {
    course: Object,
    chapter: Object,
    videoId: String,
    updateInterval: {
      type: Number,
      default: 2000
    }
  },
  data() {
    return {
      note: '',
      currentPlayerTimestamp: 0,
      ctaState: 0,
      ctaProgressThreshold: 30,
      editingNote: null,
      editorRows: 1,
      quickPause: false,
      storedNotes: [],
      playbackUuid: null
    }
  },
  methods: {
    catchKeydownEvent(e){
      if( e.keyCode === 27 ){
        this.quickPause = !this.quickPause
      }
    },
    catchKeyupEvent(e){
      if( e.keyCode === 13 && e.shiftKey === false ){
        this.storeNote();
      }
      if( this.editingNote === null ){
        this.scrollToLastNote()
      }
    },
    jumpToTime(time, offset){
      this.$refs.videoplayer.jumpToTime(time - offset)
      window.history.replaceState(null, null, '?t=' + (time - offset));
    },
    handlePlayerInitialized(playbackUuid){
      this.setPlaybackUuid(playbackUuid)
      this.jumpToUrlParamTime()
    },
    jumpToUrlParamTime(){
      let params = new URLSearchParams(new URL(window.location.href).search);
      if( params.get('t') ){
        this.$refs.videoplayer.jumpToTime(params.get('t'))
      }
    },
    setPlaybackUuid(playbackUuid){
      this.playbackUuid = playbackUuid
    },
    async loadNotes(){
      let response = await axios.get(route('courses.chapters.getNotes', [this.course.id, this.chapter.id]))
      this.storedNotes = response.data.notes
      let self = this;
      nextTick(function () {
        self.scrollToLastNote()
      })
    },
    storeNote(){
      if( this.editingNote !== null ){
        this.storedNotes[this.editingNote].text = this.note
      } else {
        this.storedNotes.push({
          text: this.note.replace(/^\s+|\s+$/g, ""),
          timestamp: this.currentPlayerTimestamp
        })
      }
      this.note = ''
      this.editingNote = null
      let self = this;
      nextTick(function () {
        self.scrollToLastNote()
        self.note = ''
        self.$refs.noteEditor.setSelectionRange(0, 0);
      })

      axios.post(route('courses.chapters.note', [this.course.id, this.chapter.id]), {
        note: this.storedNotes
      } );

    },
    scrollToLastNote(){
      this.$refs.notescontainer.scrollTop = this.$refs.noteslist.scrollHeight;
    },
    handlePlayheadTimeUpdate({timestamp, event, interval}) {
      axios.put(route('courses.chapters.videos.progress', [this.course.id, this.chapter.id, this.chapter.video.id]), {
        playback_uuid: this.playbackUuid,
        playhead_position: timestamp,
        event: event,
        interval: interval
      })
    },
    handleTimestampUpdate(timestamp){
      this.currentPlayerTimestamp = timestamp
    },
    handleProgressUpdate(progress) {
      if( progress > this.ctaProgressThreshold && this.ctaState === 0 ){
        this.ctaState = 1;
      }
    },
    closeCta() {
      this.ctaState = 2;
    },
    editNote(n){
      let self = this
      let i = this.storedNotes.length - (n+1)
      this.editingNote = i
      if( !this.storedNotes[i] ){
        return
      }
      this.note = this.storedNotes[i].text
      nextTick(function () {
        self.$refs.noteEditor.setSelectionRange(9999, 9999);
      })

    },
    abortEditNote(){
      this.editingNote = null
      this.note = ''
    },
    setEditorHeight(){
      let height = this.$refs.noteMirror.clientHeight
      let rows = (height / this.fontSize)
      this.editorRows = rows
    }
  },
  mounted(){
    this.loadNotes();
    this.scrollToLastNote()
    this.isMounted = true
    this.setEditorHeight()
  },
  computed: {
    notesList: function(){
      return JSON.parse( JSON.stringify(this.storedNotes) ).reverse()
    },
    fontSize: function(){
      if( window.innerWidth > 768 ){
        return 13;
      } else {
        return 16;
      }
    }
  },
  watch: {
    note(){
      let self = this
      setTimeout(function(){
        self.setEditorHeight()
      }, 1);
    }
  },
}
</script>
<style scoped>

  .prosemirror-container {
    overflow: scroll;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
  }

  .ProseMirror {
    min-height: 4em;
    margin-bottom: 1em;
    transition: all 0.25s;
  }

  .ProseMirror {
    min-height: 14em;
  }

  .ProseMirror p {
    margin-bottom: 0.95em;
  }

  .ProseMirror a {
    text-decoration: underline;
  }

  .ProseMirror-focused {
    outline: none;
  }
</style>
