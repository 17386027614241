<script setup>
import { watch, nextTick, ref, computed, reactive, onMounted } from 'vue';
const emit = defineEmits(['unreadCountUpdate'])

const composerInput = ref(null);

const props = defineProps({
    postUrl: {
        type: String,
        required: true
    },
    getUrl: {
        type: String,
        required: true
    },
    markAsReadUrl: {
        type: String,
        required: true
    },
    pollForNew: {
        type: Boolean,
        default: false
    },
    pollingInterval: {
        type: Number,
        default: 5000
    },
    conversationClass: {
        type: String,
        default: 'absolute top-0 left-0 right-0 bottom-24 overflow-scroll'
    }
})

const state = reactive({
    message: '',
    messages: [],
    loaded: false,
    sending: false,
    poll: null
})

const loadConversation = () => {
    axios.get(props.getUrl)
        .then(response => {
            state.messages = response.data.data;
            state.loaded = true;
            emitUnreadCount();
        })
        .catch(error => {
            console.error(error);
        });
}

const sendMessage = () => {
    if (state.sending) {
        return;
    }
    state.sending = true;
    axios.post(props.postUrl, {
        message: state.message,
    })
    .then(response => {
            state.sending = false;
            state.message = '';
            state.messages = response.data.data;
            emitUnreadCount();

            nextTick(() => {
                composerInput.value.focus();
            })
        })
}

const emitUnreadCount = () => {
    emit('unreadCountUpdate', state.messages.filter(message => message.is_unread).length );
}

const markAsRead = () => {
    axios.post(props.markAsReadUrl)
        .then(response => {
            loadConversation();
        })
        .catch(error => {
            console.error(error);
        });
}

const messages = computed(() => {
    return state.messages.reverse();
})

const hasUnreadMessages = computed(() => {
    return state.messages.some(message => message.is_unread);
})

const messageIsSendable = computed(() => {
    return state.message.length > 0;
})

watch(props, () => {
    loadConversation();
})

onMounted(() => {
    loadConversation();
    composerInput.value.focus();

    if (props.pollForNew) {
        state.poll = setInterval(() => {
            loadConversation();
        }, props.pollingInterval);
    }
});

</script>
<template>
    <div class="text-xs relative h-full">
        <div :class="props.conversationClass">
            <ul
                class="px-4 pt-4 flex flex-col-reverse overflow-scroll h-full relative transition-all"
                :class="hasUnreadMessages ? 'pb-12': 'pb-0'"
            >
                <li
                    class="flex justify-center sm:justify-end absolute bottom-0 left-4 right-4"
                    v-if="hasUnreadMessages"
                >
                    <button
                        @click="markAsRead"
                        class="bg-myflowPurple text-white p-2 rounded-full flex justify-center mb-4 w-full sm:w-auto sm:px-4"
                    >
                        <span class="mr-2">
                            {{ _mft('message:conversation.markAsRead') }}
                        </span>
                        <svg class="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M3.02832 10L10.2246 14.8166C10.8661 15.2443 11.1869 15.4581 11.5336 15.5412C11.8399 15.6146 12.1593 15.6146 12.4657 15.5412C12.8124 15.4581 13.1332 15.2443 13.7747 14.8166L20.971 10M10.2981 4.06879L4.49814 7.71127C3.95121 8.05474 3.67775 8.22648 3.4794 8.45864C3.30385 8.66412 3.17176 8.90305 3.09111 9.161C3 9.45244 3 9.77535 3 10.4212V16.8C3 17.9201 3 18.4802 3.21799 18.908C3.40973 19.2843 3.71569 19.5903 4.09202 19.782C4.51984 20 5.07989 20 6.2 20H17.8C18.9201 20 19.4802 20 19.908 19.782C20.2843 19.5903 20.5903 19.2843 20.782 18.908C21 18.4802 21 17.9201 21 16.8V10.4212C21 9.77535 21 9.45244 20.9089 9.161C20.8282 8.90305 20.6962 8.66412 20.5206 8.45864C20.3223 8.22648 20.0488 8.05474 19.5019 7.71127L13.7019 4.06879C13.0846 3.68116 12.776 3.48735 12.4449 3.4118C12.152 3.34499 11.848 3.34499 11.5551 3.4118C11.224 3.48735 10.9154 3.68116 10.2981 4.06879Z" stroke-width="2" class="stroke-current" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </button>
                </li>
                <li
                    v-for="(message, i) in messages"
                    :class="message.is_sender ? 'justify-end' : 'justify-start'"
                    class="flex mb-6"
                >
                    <message-single
                        class="w-4/5"
                        :message="message"
                    ></message-single>
                </li>
            </ul>
        </div>
        <div class="flex w-full absolute bottom-0 left-0 right-0 p-2 h-24 opacity-50">
            <textarea
                :disabled="state.sending"
                v-model="state.message"
                @keydown.enter="sendMessage"
                :class="state.sending ? 'bg-gray-200' : ''"
                ref="composerInput"
                :placeholder="_mft('message:conversation.messagePlaceholder')"
                class="myflow-basic-input transition-colors min-h-[4rem]"
            ></textarea>
            <button
                @click="sendMessage"
                :disabled="state.sending || !messageIsSendable"
                :class="state.sending || !messageIsSendable ? 'bg-gray-500' : 'bg-myflowGreen-900'"
                class="flex items-center justify-center w-10 ml-2 text-sm text-white rounded-full h-9"
                type="submit"
            >
                <i class="fa-regular fa-paper-plane-top"></i>
            </button>
        </div>
    </div>
</template>
