 <script setup>
import {onMounted, provide, ref} from "vue";

    const props = defineProps({
        lead: {
            type: Object,
            required: true
        }
    })

    const emit = defineEmits(['update:lead'])
    const _lead = ref(null)

    const loadLead = async () => {
        const res = await axios.get(route('api.crm.lead.show', props.lead.id))

        _lead.value = res.data.lead
    }

    const updateLead = async () => {
        await axios.put(route('api.crm.lead.update', props.lead.id), _lead.value)
        emit('update:lead', _lead.value)
    }

    onMounted(() => {
        loadLead()
    })

    provide('lead', _lead)
</script>

<template>
  <div>
      <div
          v-if="_lead"
          class="pb-24"
      >
          <tags-manage-inline
              class="mb-8"
              ref="tagsManage"
              :lead-id="_lead.id"
          ></tags-manage-inline>

          <lead-details
              @update:lead="updateLead"
              @reload:lead="loadLead"
              :allow-delete="false"
          />
      </div>
      <mf-spinner-medium
          v-else
          class="w-24 h-12"
      />
  </div>
</template>

<style scoped>

</style>
