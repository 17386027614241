<script setup>
    import { CheckIcon, SwitchVerticalIcon } from "@heroicons/vue/outline"
    import draggable from 'vuedraggable';
    import { useNotificationStore } from '@/stores/NotificationStore.js'
    import { computed, ref, onMounted } from 'vue';

    const notificationStore = useNotificationStore()
    const onboardingModules = ref([])

    const props = defineProps({
        onboardingSteps: { type: Object, required: true }
    });

    onMounted(() => {
        onboardingModules.value = props.onboardingSteps;
    })

</script>
<template>
    <ul role="list" class="">
        <li
            v-for="element in onboardingModules"
            :key="element.id"
            :class="[element.isCompleted ? 'bg-gradient-to-tr from-white to-myflowGreen-100' : 'bg-white']"
            class="relative z-20 justify-between p-5 m-2 border-2 gap-x-6 rounded-xl"
        >
            <div class="grid-cols-5 gap-8 md:grid">
                <div class="col-span-3">
                    <div class="">
                        <p class="relative flex items-center pr-10 text-sm font-semibold leading-6 text-gray-900 xl:pr-0">
                            <i
                                class="flex-none mr-2 text-xl fa-light"
                                :class="[element.icon, element.isCompleted ? 'text-green-600' : 'text-myflowPurple-900']"
                            ></i>
                            <span :class="element.isCompleted ? 'line-through text-green-700' : ''">
                                {{ element.name }}
                            </span>
                        </p>
                        <p class="mt-1 text-xs leading-5 text-gray-500">{{ element.description }}</p>
                    </div>
                </div>
                <div class="col-span-2">
                    <div v-for="task in element.subtasks" v-bind:key="task.id">
                        <i class="mr-2 fa-regular" :class="task.isDone ? 'fa-check-square text-myflowGreen-900' : 'fa-square text-myflowYellow-900'"></i>
                        <a
                            :href="task.url"
                            target="_blank"
                            class="text-gray-900 underline decoration-gray-400"
                        >{{ task.name }}</a>
                    </div>
                </div>
            </div>
        </li>
    </ul>
</template>
<style>
    .flip-list-move {
    transition: transform 0.5s;
    }
</style>
