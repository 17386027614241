<template>
  <div class="mb-8 relative">
    <div class="overflow-hidden transition-all" :class="expanded ? 'max-h-max' : 'max-h-[7ch]'">
      <div v-html="description"></div>
    </div>
    <div v-if="expandable && !expanded" class="absolute bottom-0 right-0 left-0 bg-gradient-to-b from-transparent to-white text-center pt-4">
      <button @click.prevent="expanded = true" class="myflow-basic-button--secondary -mb-2">
          {{ _mft('shared:expand.showMore') }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
    description: { type: String, required: true },
    expandable: { type: Boolean, required: true }
});

const expanded = ref(false);
watch(() => props.expandable, (newExpandable) => {
    if (!newExpandable) { expanded.value = true; }
}, { immediate: true })
</script>
