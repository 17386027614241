<template>
        <section class="text-white w-full mb-8 lg:w-3/12">
                <p class="text-myflowYellow-700 text-6xl">{{statistics.percentage}}</p>
                <p class="text-lg">{{statistics.text}}</p>
                <p class="mt-4">{{statistics.source}}</p>
        </section>
</template>

<script>
export default {
    props: {
        statistics: Object,
    },
    data() {
        return {
            stats: this.statistics
        };
    },
};


</script>

<style scoped>

</style>
