<script setup>
    import { onMounted, ref } from 'vue'
    const props = defineProps({
        message: {
            type: Object,
            required: true
        },
        sentByCurrentUser: {
            type: Boolean,
            required: true
        }
    })

    const transitionClass = ref('opacity-0 translate-y-8')

    onMounted(() => {
        setTimeout(function(){
            transitionClass.value = 'opacity-100 translate-y-0'
        }, 100)
    })

</script>
<template>
    <div
        class="flex transition-all"
        :class="[props.sentByCurrentUser ? 'justify-end' : 'justify-start', transitionClass]"
    >
        <div
            class="relative w-11/12 px-4 py-2 mb-4 bg-white rounded-lg shadow-lg lg:w-2/3 max-w-max" style="min-width: 240px;"
        >
            <div class="mb-2">{{ message.content }}</div>
            <div class="z-10 flex justify-between space-x-2 text-xs text-gray-400">
                <span>{{ message.user.name }}</span>
                <span>{{ message.sent_at }}</span>
            </div>
        </div>
    </div>
</template>
