<template>
  <div>
    <button-secondary
        @click="playStorylaneDemo"
        class="cursor-pointer"
        v-if="guideModule && guideModule.embed_id"
    >
        <i class="fa-sharp fa-light fa-circle-info"></i>
        {{ _mft('support:showGuide') }}
    </button-secondary>
  </div>
</template>

<script setup>
import { onMounted, ref, reactive } from 'vue';
import load from '../../helpers/loadScript';

    const props = defineProps({
        module: {
            type: String,
            required: true,
            default: null
        },
    })

    const guideModule = ref([]);
    const userStatus = ref(false);

    const loadModule = async () => {
        const res = await axios.get(route('guideModule.getStory', props.module))
        guideModule.value = res.data.storyLaneGuide;
        userStatus.value = res.data.storyLaneGuideCompletions;

        if(userStatus.value === false) {
            playStorylaneDemo()
        }
    };

    const markAsStarted = async () => {
        await axios.post(route('guideModule.markAsStarted', props.module))
    };

    const markAsCompleted = async () => {
        await axios.post(route('guideModule.markAsCompleted', props.module))
    };

    const loadStorylaneScript = () => {
        const script = document.createElement('script');
        script.src = "https://js.storylane.io/js/v2/storylane.js";
        script.async = true;

        script.onload = () => {
            loadModule();
        };

        script.onerror = () => {
            console.error('Failed to load the Storylane script');
        };

        document.body.appendChild(script);
    };


    const playStorylaneDemo = () => {
        if (guideModule.value && guideModule.value.embed_id) {
            if (window.Storylane) {
                console.log('Storylane is ready. Playing demo with embed_id:', guideModule.value.embed_id);
                window.Storylane.Play({
                    type: 'popup',
                    demo_type: 'image',
                    width: 2560,
                    height: 1600,
                    scale: '0.95',
                    demo_url: 'https://app.storylane.io/demo/' + guideModule.value.embed_id,
                    padding_bottom: 'calc(62.50% + 25px)'
                });
            } else {
                setTimeout(() => {
                    playStorylaneDemo();
                }, 1000);
            }
        } else {
            console.warn('Guide module or embed ID not available');
        }
    };


    const handleStorylaneEvent = (event) => {
        if (event.data.message === 'storylane-demo-event') {
            const payload = event.data.payload;

            switch (payload.event) {
                case 'flow_start':
                    markAsStarted();
                    break;
                case 'flow_end':
                    markAsCompleted();
                    break;
            }
        }
    };

    const shouldShowGuide = () => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.has('showguide') && urlParams.get('showguide') === '1';
    };

    onMounted(() => {
        window.addEventListener('message', handleStorylaneEvent);
        loadStorylaneScript();

        if (shouldShowGuide()) {
            setTimeout(() => {
                playStorylaneDemo();
            }, 1000);
        } else {
        }
    });
</script>

<style scoped>
    .sl-preview-cta {
        background-color: #9939EB;
        border: none;
        border-radius: 8px;
        box-shadow: 0px 0px 15px rgba(26, 19, 72, 0.45);
        color: #FFFFFF;
        display: inline-block;
        font-family: Poppins, Arial, sans-serif;
        font-size: clamp(16px, 1.599vw, 20px);
        font-weight: 600;
        height: clamp(40px, 3.996vw, 50px);
        line-height: 1.2;
        padding: 0 clamp(15px, 1.776vw, 20px);
        text-overflow: ellipsis;
        transform: translateZ(0);
        transition: background 0.4s;
        white-space: nowrap;
        width: auto;
        z-index: 999999;
        cursor: pointer;
    }

    .sl-preview-cta:hover .sl-preview-cta-ripple {
        transition: all 1s cubic-bezier(0,0,.2,1);
        inset: -0.75em !important;
        opacity: 0 !important;
    }

    .sl-preview-cta:hover .sl-preview-cta-ripple-shadow {
        opacity: 0.125 !important;
    }
</style>
