<template>
  <div ref="root">
    <div
    >
      <!-- You can use styles and CSS on this iframe element where the video player will appear -->
      <div
        class="relative w-full bg-video-placeholder"
        style="padding-bottom: 56.25%"
      >
        <div
          v-if="videoLoading"
          class="text-white absolute inset-0 flex justify-center items-center"
        >
          <div class="loader"></div>
        </div>
        <iframe
          v-if="!videoLoading"
          :src="video.embed_url"
          style="border: none"
          class="absolute inset-0 w-full h-full"
          allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
          allowfullscreen="true"
          id="stream-player"
        ></iframe>
      </div>

    </div>
  </div>
</template>
<script>
export default {
  props: {
    video: {
      type: Object,
      default: null
    },
    updateInterval: {
      type: Number,
      default: 2000
    },
    paused: {
      type: Boolean,
      default: false
    },
    autoplay: {
      type: Boolean,
      required: false,
      default: false,
    }
  },
  data(){
    return {
      progressEmitter: false,
      timestampEmitter: false,
      player: null
    }
  },
  computed: {
    videoLoading() {
      return this.video == null;
    }
  },
  mounted(){
    if (this.video) { this.loadPlayer(); }
  },
  watch: {
    video() {
      if (this.video) { this.loadPlayer(); }
    },
    paused() {
      if(this.paused){
        this.player.pause()
      } else {
        this.player.play()
      }
    }
  },
  methods: {
    loadPlayer() {
      const self = this;

      const scriptSrc = 'https://embed.videodelivery.net/embed/sdk.latest.js';
      const existingScript = document.querySelector(`script[src='${scriptSrc}']`);
      const script = existingScript ?? document.createElement("script");

      const setup = () => {
        const player = Stream(self.$refs.root.querySelector("iframe"));

        player.addEventListener('loadstart', () => {
          self.$emit('cloudflare-loadstart', player);
        });

        player.addEventListener('play', () => {
          self.$emit('cloudflare-play', player);

          self.$emit('playheadTimeUpdate', {
            timestamp: parseInt(player.currentTime, 10),
            event: 'play',
            interval: self.updateInterval
          })
          self.$emit('timestampUpdate', parseInt(player.currentTime, 10))
          self.progressEmitter = setInterval(function(){
            self.$emit('playheadTimeUpdate', {
              timestamp: parseInt(player.currentTime, 10),
              event: 'playing',
              interval: self.updateInterval
            })
            self.$emit('progressUpdate', Math.round((player.currentTime / self.video.duration) * 100 ))
          }, self.updateInterval)
          self.timestampEmitter = setInterval(function(){
            self.$emit('timestampUpdate', parseInt(player.currentTime, 10))
          }, 1000)
        });

        player.addEventListener('seeked', () => {
          self.$emit('cloudflare-seeked', player);

          self.$emit('timestampUpdate', parseInt(player.currentTime, 10))
          self.$emit('playheadTimeUpdate', {
            timestamp: parseInt(player.currentTime, 10),
            event: 'seek',
            interval: self.updateInterval
          })
        });

        player.addEventListener('ended', () => {
          self.$emit('cloudflare-ended', player);

          self.$emit('timestampUpdate', parseInt(player.currentTime, 10))
          self.$emit('playheadTimeUpdate', {
            timestamp: parseInt(player.currentTime, 10),
            event: 'seek',
            interval: self.updateInterval
          })
        });

        player.addEventListener('pause', () => {
          self.$emit('cloudflare-pause', player);

          clearInterval(self.progressEmitter);
          clearInterval(self.timestampEmitter);
        });

        self.player = player;
        self.$emit('playerInitialized', self.video.playback_uuid);

        if (this.autoplay) {
          try {
            player.muted = true;
            player.play();
          } catch (error) {
            console.error('Error playing video', error);
          }
        }
      };

      if (existingScript && window.Stream) {
          setup();
      } else {
          script.addEventListener('load', setup);
      }

      if (!existingScript) {
        script.src = scriptSrc;
        document.head.appendChild(script);
      }
    },
    jumpToTime(seconds)
    {
      this.player.currentTime = seconds
      this.player.play()
    }
  }
}
</script>
<style scoped>
  .bg-video-placeholder {
    background-image: url('https://cdn.pixabay.com/photo/2016/12/07/15/57/blur-1889747_1280.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .loader {
    color: #4F0076;
    font-size: 20px;
    margin: 0px auto;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    text-indent: -9999em;
    -webkit-animation: load4 1.3s infinite linear;
    animation: load4 1.3s infinite linear;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
  }
  @-webkit-keyframes load4 {
    0%,
    100% {
      box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
      box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
      box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
      box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
  }
  @keyframes load4 {
    0%,
    100% {
      box-shadow: 0 -3em 0 0.2em, 2em -2em 0 0em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 0;
    }
    12.5% {
      box-shadow: 0 -3em 0 0, 2em -2em 0 0.2em, 3em 0 0 0, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    25% {
      box-shadow: 0 -3em 0 -0.5em, 2em -2em 0 0, 3em 0 0 0.2em, 2em 2em 0 0, 0 3em 0 -1em, -2em 2em 0 -1em, -3em 0 0 -1em, -2em -2em 0 -1em;
    }
    37.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 0, 2em 2em 0 0.2em, 0 3em 0 0em, -2em 2em 0 -1em, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    50% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 0em, 0 3em 0 0.2em, -2em 2em 0 0, -3em 0em 0 -1em, -2em -2em 0 -1em;
    }
    62.5% {
      box-shadow: 0 -3em 0 -1em, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 0, -2em 2em 0 0.2em, -3em 0 0 0, -2em -2em 0 -1em;
    }
    75% {
      box-shadow: 0em -3em 0 -1em, 2em -2em 0 -1em, 3em 0em 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0.2em, -2em -2em 0 0;
    }
    87.5% {
      box-shadow: 0em -3em 0 0, 2em -2em 0 -1em, 3em 0 0 -1em, 2em 2em 0 -1em, 0 3em 0 -1em, -2em 2em 0 0, -3em 0em 0 0, -2em -2em 0 0.2em;
    }
  }
</style>
