<script setup>

import InputEditor from "../forms/inputEditor.vue";
import {onMounted, ref} from "vue";

const props = defineProps({
    policyType: {
        type: String,
        required: true
    }

})

const policy = ref(null)
const storePolicyState = ref('')

const loadPolicy = async () => {
    const res = await axios.get(route('settings.policy.show', {type: props.policyType}))
    policy.value = res.data.policy
}

const storePolicy = async () => {
    storePolicyState.value = 'waiting'
    const res = await axios.post(route('settings.policy.update', {type: props.policyType}), {
        policy: policy.value,
    })
    if(res.data.success === true) {
        storePolicyState.value = 'success'
        return;
    }
    storePolicyState.value = 'error'
}


onMounted(() => {
    loadPolicy();
})

</script>

<template>
    <div v-if="policy">
        <div class="flex space-x-4 justify-between items-end">
            <h2>{{ _mft(policy.title) }}</h2>
            <span class="text-xs">{{ _mft('shared:date.lastUpdated') }} {{ policy.updated_at_display }}</span>
        </div>
        <input-editor
            v-model:content="policy.content"
        />
        <div class="flex justify-end">
            <button-success
                @click="storePolicy"
                :state="storePolicyState"
            >{{ _mft('shared:action.save') }}</button-success>
        </div>
    </div>
</template>

<style scoped>

</style>
