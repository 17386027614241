<template>
    <div>
        <form method="POST" :action="route('livestream.create') ">
            <label>Titel på Livestream</label>
            <input type="text"  maxlength="255" class="myflow-basic-input" name="title" required>

            <label>Starttid</label>
            <input type="datetime-local" class="myflow-basic-input" name="start_time" required>

            <label>Sluttid</label>
            <input type="datetime-local" class="myflow-basic-input" name="end_time" required>

            <input type="hidden" name="_token" :value="csrf">

            <button type="submit" class="myflow-basic-button">Lägg upp Livestream</button>
        </form>
    </div>
</template>

<script>
export default {
    props: ['url'],
    data() {
        return {
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            note: ''
        };
    },
};
</script>

<style scoped>
.fa-copy:active {
    transform: scale(1.1);
}
</style>
