<template>
  <div class="relative w-full pb-20">
    <h1 class="p-5 mb-2">Jag vill hitta en coach inom...</h1>

    <div
      class="flex flex-wrap w-full mb-8"
    >
      <div
        v-for="(category, i) in categories"
        v-bind:key="i"
        v-on:click="toggleFilter('category', category)"
      >
        <div class="m-2 text-center rounded-full">
            <div
              class="px-4 rounded-full lg:px-8"
              :class="[ category.selected ? optionSelectedClasses : optionNotSelectedClasses, optionBaseClasses ]"
            >
              <div
                class="relative text-center transition-all"
              >
                <span :class="optionLabelClasses" class="text-base">
                  {{ category.label }}
                </span>
              </div>
            </div>
          </div>
      </div>
    </div>


    <div class="grid-cols-3 gap-8 mb-8 md:grid">
      <div class="col-span-3">
        <div
          v-for="(filter, i) in filters"
          v-bind:key="i"
          class="h-auto pl-4 pr-2 mb-2 -ml-4 -mr-4 overflow-hidden"
          :class="filterContainerClass(filter)"
        >
            <h2 class="py-3 mb-1" v-if="filter.type != 'bool'">{{ filter.label }}</h2>
            <h2 class="py-3 mb-1" v-if="filter.heading">{{ filter.heading }}</h2>

            <div class="pl-4 pr-4 -ml-4 -mr-4 overflow-x-scroll lg:overflow-auto">
                <div class="pr-4" v-if="filter.type === 'large-inverted'">
                    <ul class="flex rounded-full side-scollable-list">
                    <li
                        v-for="(option, o) in filter.options"
                        v-bind:key="o"
                        class="px-4 lg:px-8 first:rounded-l-lg last:rounded-r-lg last:mr-2"
                        :class="[ option.selected ? invertedOptionSelectedClasses : invertedOptionNotSelectedClasses, invertedOptionBaseClasses ]"
                        v-on:click="toggleFilter(filter, option)"
                    >
                        <div
                        class="relative text-center transition-all"
                        >
                        <span
                            v-if="option.icon"
                            :class="optionIconClasses"
                            v-html="option.icon"
                        ></span>
                        <span class="font-bold text-md whitespace-nowrap" :class="optionLabelClasses">
                            {{ option.label }}
                        </span>
                        </div>

                    </li>
                    </ul>
                </div>

                <div class="mb-2" v-if="filter.type === 'default'">
                    <ul class="flex rounded-full side-scollable-list">
                    <li
                        v-for="(option, o) in filter.options"
                        v-bind:key="o"
                        class="px-4 lg:px-8 first:rounded-l-lg lg:first:rounded-l-full lg:first:pl-10 last:rounded-r-lg last:mr-2 lg:last:rounded-r-full lg:last:pr-10"
                        :class="[ option.selected ? optionSelectedClasses : optionNotSelectedClasses, optionBaseClasses ]"
                        v-on:click="toggleFilter(filter, option)"
                    >
                        <div
                        class="relative text-center transition-all"
                        >
                        <span
                            v-if="option.icon"
                            :class="optionIconClasses"
                            v-html="option.icon"
                        ></span>
                        <span class="whitespace-nowrap" :class="optionLabelClasses">
                            {{ option.label }}
                        </span>
                        </div>

                    </li>
                    </ul>
                </div>

                <div class="mb-2" v-if="filter.type === 'bool'">
                    <ul class="flex rounded-full side-scollable-list">
                        <!-- v-for="(option, o) in filter.options"
                        v-bind:key="o" -->
                    <li
                        class="px-4 lg:px-8 first:rounded-l-lg lg:first:rounded-l-full lg:first:pl-10 last:rounded-r-lg last:mr-2 lg:last:rounded-r-full lg:last:pr-10"
                        :class="[ filter.options[0].selected ? optionSelectedClasses : optionNotSelectedClasses, optionBaseClasses ]"
                        v-on:click="toggleFilter(filter, filter.options[0])"
                    >
                        <div
                        class="relative text-center transition-all"
                        >
                        <span>
                            {{ filter.label }}
                        </span>
                        </div>

                    </li>
                    </ul>
                </div>
            </div>
        </div>
      </div>
    </div>


    <h2
      class="sticky left-0 right-0 p-2 mb-4 text-xs font-light text-center text-gray-400 transition-opacity bg-white rounded-md shadow-md bottom-3 lg:bg-transparent lg:shadow-none lg:p-0 lg:text-lg lg:relative"
    >
      <span
        v-if="loadingStatus === 'loading' || loadingStatus === 'unloading'"
        class="text-pink-50"
      >
        <mf-spinner-medium
          class="w-24 h-12"
        />

      </span>
      <span
        v-else
      >
        <span
          v-if="filteredCoaches.length > 0"
        >
          <span v-if="areFiltersApplied">
            Hittade {{ resultCountString }}<span v-if="this.selectedCategory.name"> i {{ this.selectedCategory.name }}</span>:
          </span>
          <span v-else>
            Visar allt innehåll:
          </span>
        </span>
        <span v-if="filteredCoaches.length === 0 && loadingStatus === 'loaded'">
          Oops, filtreringen gav ingen träff. Testa gärna med något annat!
        </span>
      </span>
    </h2>

    <div
      class="grid grid-cols-2 gap-2 md:gap-8 lg:grid-cols-3 xl:grid-cols-4"
    >
      <coach-card
        v-for="coach in filteredCoaches"
        v-bind:key="coach.id"
        :coach="coach"
        :available-product-count="coach.available_offers_count"
        :coach-profile-img-url="coach.image"
        :class="[{'translate-y-1 opacity-0' : loadingStatus === 'unloading' }, {'-translate-y-1 opacity-0' : loadingStatus === 'loading'},  {'translate-y-0 opacity-1' : loadingStatus === 'loaded' }]"
      />


    </div>
    <div class="w-full" id="trailer-player-teleport-target"></div>
  </div>
</template>
<script>
export default {
  props: {
  },
  data: function(){
    return {
      loadingStatus: '',
      selectedCategory: {},
      optionBaseClasses: 'py-1 bg-white border-r last:border-r-0 cursor-pointer',
      optionSelectedClasses: 'bg-myflowPurple shadow-none text-white bg-gradient-to-b from-myflowPurple-500 to-myflowPurple-700',
      optionNotSelectedClasses: 'text-myflowPurple shadow-md',
      optionIconClasses: 'block w-6 h-6 lg:w-6 lg:h-6 mx-auto',
      optionLabelClasses: 'block text-center text-xs',
      invertedOptionBaseClasses: 'py-4 bg-myflowPurple border-r last:border-r-0 cursor-pointer',
      invertedOptionSelectedClasses: 'bg-myflowPurple-300 shadow-none text-white bg-gradient-to-b from-myflowPurple-500 to-myflowPurple-700',
      invertedOptionNotSelectedClasses: 'text-white shadow-md',
      categories: [],
      filters: [],
      appliedFilters: {},
      filteredCoaches: [],
    }
  },
  methods: {
    async loadFilterOptions(){
      try {
        let response = await axios.get(route('coachGuide.filteroptions'));

        this.categories = response.data.filtergroups.categories;
        this.filters = response.data.filtergroups.filters;
      } catch (error) {
        console.log(error);
      }
    },
    toggleFilter(filter, option) {
      option.selected = !option.selected
      this.setAppliedFilters()
      this.filterCourses()
    },
    transitionCoursesOut(){
      let self = this
      self.loadingStatus = 'unloading'
    },
    transitionCoursesIn(){
      let self = this
      self.loadingStatus = 'loading'
      setTimeout(function(){
        self.loadingStatus = 'loaded'
      }, 200)
    },
    setAppliedFilters(){
      let appliedFilters = {}
      this.categories.forEach(function(filter,i){
        if(filter.selected) {
          if(!appliedFilters['category'] ){
            appliedFilters['category'] = []
          }
          appliedFilters['category'].push(filter.value);
        }
      })

      this.filters.forEach(function(filter,i){
        filter.options.forEach(function(option,o){
          if( option.selected ){
            if( !appliedFilters[filter.name] ){
              appliedFilters[filter.name] = []
            }
            appliedFilters[filter.name].push(option.value)
          }
        })
      })
      this.appliedFilters = appliedFilters
    },
    filterCourses(){
      let self = this
      let included = true

      this.transitionCoursesOut()

      setTimeout(function(){
        self.loadCoaches();
      }, 100)
    },
    async loadCoaches(){
      this.loadingStatus = 'loading'
      let response = await axios.post(route('coaching.getFiltered'), {
        applied_filters: this.appliedFilters
      })
      this.filteredCoaches = response.data.coaches
      this.updateFilters(response.data.filtergroups.filters)
      this.transitionCoursesIn()
    },
    updateFilters(filters){
        let self = this
        filters.forEach((filter, i) => {
            filter.options.forEach((option, oi) => {
                self.filters[i].options[oi].label = option.label
                self.filters[i].options[oi].value = option.value
            })
        });
    }
  },
  computed: {
    resultCountString() {
      switch(this.filteredCoaches.length) {
        case 0:
          return 'ingen träff';
        case 1:
          return 'en träff';
        case 2:
          return 'två träffar';
        case 3:
          return 'tre träffar';
        case 4:
          return 'fyra träffar';
        case 5:
          return 'fem träffar';
        case 6:
          return 'sex träffar';
        case 7:
          return 'sju träffar';
        case 8:
          return 'åtta träffar';
        case 9:
          return 'nio träffar';
        default:
          return this.filteredCoaches.length + ' träffar';
      }
    },
    filterContainerClass: () => (filter) => {
      if( filter.type === 'bool' ){
        return 'inline-block'
      }
    },
    areFiltersApplied() {
      if( Object.keys(this.appliedFilters).length === 0 && Object.keys(this.selectedCategory).length === 0 ){
        return false;
      } else {
        return true;
      }
    }
  },
  mounted: function(){
    this.loadFilterOptions()
  },
  watch: {
    categories: {
      deep: true,
      handler: function(){
        this.filterCourses()
      }
    },
  },
}
</script>
<style lang="scss" scoped>
  @for $i from 1 through 100 {
    .content-cards > div:nth-child(#{$i}) {
      transition: all 0.2s ($i * 0.02 + s);
    }
  }

  .side-scollable-list {
    &:after {
      content: '';
      padding-right: 1rem;
    }
  }
</style>
