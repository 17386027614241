<template>
    <div v-if="value">
        <div v-for="(question, questionIdx) in value.questions" :key="questionIdx" class="my-3">
            <div class="flex justify-between">
                <span class="font-semibold">Fråga {{questionIdx + 1}}</span>

                <button v-if="value.questions.length > 1" @click.prevent="removeQuestion(questionIdx)" class="myflow-basic-button">
                    <i class="fa fa-trash"></i>
                </button>
            </div>
            <input-select
                label="Typ"
                :required="required"
                :options="optionsForType"
                v-model:content="question.type"
            />

            <input-text
                label="Fråga?"
                placeholder="Vad är er strategi för ..?"
                :required="required"
                :options="optionsForType"
                v-model:content="question.title"
            />

            <hr>
        </div>

        <div class="flex justify-end">
            <button @click.prevent="addQuestion()" class="myflow-basic-button">
                Lägg till fråga
            </button>
        </div>
    </div>
    <mf-spinner-medium v-else class="w-16 h-8"></mf-spinner-medium>
</template>
<script>
import { v4 as uuidv4 } from 'uuid';

export default {
    props: {
        required: Boolean,
        modelValue: {},
    },
    emits: [
        'update:modelValue'
    ],
    data() {
        return {
            optionsForType: [
                { value: 'text', label: 'Text' },
                { value: 'number', label: 'Nummer' }
            ],
            value: null
        };
    },
    async mounted() {
        this.captureModelValue();
    },
    watch: {
        modelValue() {
            if (JSON.stringify(this.value) === JSON.stringify(this.modelValue)) { return; }
            this.captureModelValue();
        },
        value: {
            handler() {
                this.$emit('update:modelValue', JSON.parse(JSON.stringify(this.value)));
            },
            deep: true
        }
    },
    methods: {
        captureModelValue() {
            const value = this.modelValue ? JSON.parse(JSON.stringify(this.modelValue)) : {};
            if (!value.id) { value.id = null; }
            if (!value.questions) { value.questions = []; }
            this.value = value;

            if (this.value.questions.length == 0) {
                this.addQuestion();
            }
        },
        addQuestion() {
            this.value.questions.push({
                uuid: uuidv4(),
                type: null,
                title: null
            });
        },
        removeQuestion(idx) {
            this.value.questions.splice(idx, 1);
        }
    },
}
</script>

