<template>
    <div class="grid-cols-2 gap-8 md:grid">
        <div class="col-span-2">
            <card-basic>
                <template v-slot:body>
                    <div class="grid-cols-3 gap-8 md:grid">
                        <div class="col-span-1">
                            <card-basic :style="selected(1)">
                                <template v-slot:body>
                                    <h2>Alternativ 1.</h2>

                                    <h5 class="mb-3">Summering av alternativ</h5>
                                    <ul>
                                        <li><b>Teckning av aktier</b></li>
                                        <li>• Köp av aktier för 3 266 kr</li>
                                        <li>&nbsp;</li>
                                        <li><b>Erbjudande för aktieägare</b></li>
                                        <li>• 6 månader gratis av myflows plattform (värde 3 600 kr)</li>
                                        <li>• 6 biljetter till digitalt event (värde 8 940 kr)</li>
                                        <li>• 1 coachingssession á 45 minuter (värde 2 490 kr)</li>
                                        <li>• All expertutbildning (värde 26 890 kr)</li>
                                        <li><b>Summa 6 634 kr exkl moms</b></li>
                                        <li>&nbsp;</li>
                                        <li><b>Summa för aktier & erbjudande:</b> 9 900 kr</li>
                                    </ul>

                                    <button class="block mt-4 myflow-basic-button" v-on:click="alternativePick(1)">Välj alternativ 1</button>
                                </template>
                            </card-basic>
                        </div>
                        <div class="col-span-1">
                            <card-basic :style="selected(2)">
                                <template v-slot:body>
                                    <h2>Alternativ 2.</h2>

                                    <h5 class="mb-3">Summering av alternativ</h5>
                                    <ul>
                                        <li><b>Teckning av aktier</b></li>
                                        <li>• Köp av aktier för 16 330 kr</li>
                                        <li>&nbsp;</li>
                                        <li><b>Erbjudande för aktieägare</b></li>
                                        <li>• 12 månader av myflows plattform (värde 7 200 kr)</li>
                                        <li>• 12 biljetter till digitalt event (värde 17 880 kr)</li>
                                        <li>• 2 coachingssessioner á 45 minuter (värde 4 980 kr)</li>
                                        <li>• All expertutbildning (värde 26 890 kr)</li>
                                        <li><b>Summa 8 570 kr exkl moms</b></li>
                                        <li>&nbsp;</li>
                                        <li><b>Summa för aktier & erbjudande:</b> 24 900 kr</li>
                                    </ul>

                                    <button class="block mt-4 myflow-basic-button" v-on:click="alternativePick(2)">Välj alternativ 2</button>
                                </template>
                            </card-basic>
                        </div>
                        <div class="col-span-1">
                            <card-basic :style="selected(3)">
                                <template v-slot:body>
                                    <h2>Alternativ 3.</h2>

                                    <h5 class="mb-3">Summering av alternativ</h5>
                                    <ul>
                                        <li><b>Teckning av aktier</b></li>
                                        <li>• Köp av aktier för 39 192 kr</li>
                                        <li>&nbsp;</li>
                                        <li><b>Erbjudande för aktieägare</b></li>
                                        <li>• 18 månader av myflows plattform (värde 10 800 kr)</li>
                                        <li>• 12 biljetter till digitalt event (värde 17 880 kr)</li>
                                        <li>• 3 coachingssessioner á 45 minuter (värde 7 470 kr)</li>
                                        <li>• All expertutbildning (värde 26 890 kr)</li>
                                        <li><b>Summa 10 708 kr exkl moms</b></li>
                                        <li>&nbsp;</li>
                                        <li><b>Summa för aktier & erbjudande:</b> 49 900 kr</li>
                                    </ul>

                                    <button class="block mt-4 myflow-basic-button" v-on:click="alternativePick(3)">Välj alternativ 3</button>
                                </template>
                            </card-basic>
                        </div>
                        <div class="col-span-3">
                            <div class="grid-cols-2 gap-8 md:grid">
                                <div class="col-span-1">
                                    <card-basic>
                                        <template v-slot:body>
                                            <h1 class="mb-3">Hur ser framtiden ut?</h1>

                                            <div v-if="alternative == 1">
                                                <p>När vi tillsammans når målen i budgeten, kommer du enligt prognos uppnå ett aktievärde på <b>15 993 kr</b> baserat på antalet aktier i <b>Alternativ 1</b>.</p>
                                            </div>
                                            <div v-if="alternative == 2">
                                                <p>När vi tillsammans når målen i budgeten, kommer du enligt prognos uppnå ett aktievärde på <b>79 965 kr</b> baserat på antalet aktier i <b>Alternativ 2</b>.</p>
                                            </div>
                                            <div v-if="alternative == 3">
                                                <p>När vi tillsammans når målen i budgeten, kommer du enligt prognos uppnå ett aktievärde på <b>191 917 kr</b> baserat på antalet aktier i <b>Alternativ 3</b>.</p>
                                            </div>

                                            <div v-if="!alternative">
                                                <i>Välj ett alternativ ovan för att se prognos på aktievärde.</i>
                                            </div>

                                            <p v-else><small><i>*Aktievärde är baserat på stegvis måluppfyllnad med 1 835 aktiva experter tills 31/12 2027. Historisk avkastning är ingen garanti för framtida avkastning</i></small></p>
                                        </template>
                                    </card-basic>
                                </div>
                                <div class="col-span-1">
                                    <card-basic>
                                        <template v-slot:body>
                                            <h1 class="mb-3">Kontakt</h1>

                                            <img src="https://myflow.fra1.digitaloceanspaces.com/providers/unsaved/X6YbY4Gt17bx9Xho8jQVp65t6bRZJvCyg5ZzDZQG.png" class="float-right object-cover object-center w-12 h-12 rounded-full">
                                            <h5>Robert Bloom</h5>
                                            <p>
                                                <b>Chief Growth Officer</b><br>
                                                <a href="mailto:robert@myflow.se" target="_blank">robert@myflow.se</a><br>
                                                0763 21 06 26
                                            </p>
                                        </template>
                                    </card-basic>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="grid-cols-4 gap-8 mt-4 md:grid">
                        <div class="col-span-2">

                            <div v-if="alternative == 1">
                                <h2 class="mb-3">Du har valt alternativ 1</h2>
                            </div>
                            <div v-if="alternative == 2">
                                <h2 class="mb-3">Du har valt alternativ 2</h2>
                            </div>
                            <div v-if="alternative == 3">
                                <h2 class="mb-3">Du har valt alternativ 3</h2>
                            </div>

                            <div v-if="alternative">
                                <form @submit.prevent>
                                    <input-text
                                        placeholder=""
                                        label="Namn på köpare (företagsnamn eller ditt privata namn)"
                                        :required="true"
                                        :content="name"
                                        name="name"
                                        v-model:content="name"
                                    ></input-text>
                                    <input-text
                                        placeholder=""
                                        label="Organisationsnummer / personnummer (skall matcha företagsnamn eller namn ovan)"
                                        :required="true"
                                        :content="organisationNumber"
                                        name="organisationNumber"
                                        v-model:content="organisationNumber"
                                    ></input-text>
                                    <input-text
                                        placeholder=""
                                        label="Gatunamn"
                                        :required="true"
                                        :content="streetName"
                                        name="streetName"
                                        v-model:content="streetName"
                                    ></input-text>
                                    <input-text
                                        placeholder=""
                                        label="Postnummer"
                                        :required="true"
                                        :content="postalCode"
                                        name="postalCode"
                                        v-model:content="postalCode"
                                    ></input-text>
                                    <input-text
                                        placeholder=""
                                        label="Ort"
                                        :required="true"
                                        :content="city"
                                        name="city"
                                        v-model:content="city"
                                    ></input-text>
                                    <input-text
                                        placeholder=""
                                        label="E-post för aktieinformation"
                                        :required="true"
                                        name="pricing_name"
                                        v-model:bind="userEmail"
                                        :content="userEmail"
                                        disabled="true"
                                    ></input-text>
                                    <button v-on:click="submit()" class="mt-4 myflow-basic-button">Skicka</button>
                                </form>
                            </div>
                        </div>
                        <div class="col-span-2">
                            <h2>Vad händer sen?</h2>
                            <div class="flow-root p-4">
                                <ul role="list" class="-mb-8">
                                    <li>
                                        <div class="relative pb-8">
                                            <span class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                                            <div class="relative flex space-x-3">
                                                <div>
                                                    <span class="flex items-center justify-center w-8 h-8 rounded-full bg-myflowGreen-600 ring-8 ring-white">
                                                        <!-- Heroicon name: solid/user -->
                                                        <svg class="w-5 h-5 text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                            <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                                        </svg>
                                                    </span>
                                                </div>
                                                <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                                    <div>
                                                        <p class="text-sm text-gray-500">Välj alternativ och fyll i uppgifter</p>
                                                    </div>
                                                    <div class="text-sm text-right text-gray-500 whitespace-nowrap">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="relative pb-8">
                                            <span class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                                            <div class="relative flex space-x-3">
                                                <div>
                                                    <span class="flex items-center justify-center w-8 h-8 rounded-full bg-myflowYellow-600 ring-8 ring-white">
                                                        <!-- Heroicon name: solid/user -->
                                                        <svg class="w-5 h-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                        </svg>
                                                    </span>
                                                </div>
                                                <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                                    <div>
                                                        <p class="text-sm text-gray-500">Signera teckningssedel och erbjudande direkt med Bank-ID</p>
                                                    </div>
                                                    <div class="text-sm text-right text-gray-500 whitespace-nowrap">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="relative pb-8">
                                            <span class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                                            <div class="relative flex space-x-3">
                                                <div>
                                                    <span class="flex items-center justify-center w-8 h-8 rounded-full bg-myflowYellow-600 ring-8 ring-white">
                                                        <!-- Heroicon name: solid/user -->
                                                        <svg class="w-5 h-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                        </svg>
                                                    </span>
                                                </div>
                                                <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                                    <div>
                                                        <p class="text-sm text-gray-500">Betala likvider för aktier enligt betalningsinstruktioner på e-post</p>
                                                    </div>
                                                    <div class="text-sm text-right text-gray-500 whitespace-nowrap">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="relative pb-8">
                                            <span class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                                            <div class="relative flex space-x-3">
                                                <div>
                                                    <span class="flex items-center justify-center w-8 h-8 rounded-full bg-myflowYellow-600 ring-8 ring-white">
                                                        <!-- Heroicon name: solid/user -->
                                                        <svg class="w-5 h-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                        </svg>
                                                    </span>
                                                </div>
                                                <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                                    <div>
                                                        <p class="text-sm text-gray-500">Faktura skickas för erbjudandet (biljetter, utbildning, coaching mm) till din e-post. </p>
                                                    </div>
                                                    <div class="text-sm text-right text-gray-500 whitespace-nowrap">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="relative pb-8">
                                            <span class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true"></span>
                                            <div class="relative flex space-x-3">
                                                <div>
                                                    <span class="flex items-center justify-center w-8 h-8 rounded-full bg-myflowYellow-600 ring-8 ring-white">
                                                        <!-- Heroicon name: solid/user -->
                                                        <svg class="w-5 h-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                        </svg>
                                                    </span>
                                                </div>
                                                <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                                    <div>
                                                        <p class="text-sm text-gray-500">Vid avslutad emission förs du in i aktieboken på <a href="https://nvr.se" target="_blank">NVR</a></p>
                                                    </div>
                                                    <div class="text-sm text-right text-gray-500 whitespace-nowrap">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="relative pb-8">
                                            <div class="relative flex space-x-3">
                                                <div>
                                                    <span class="flex items-center justify-center w-8 h-8 rounded-full bg-myflowYellow-600 ring-8 ring-white">
                                                        <!-- Heroicon name: solid/user -->
                                                        <svg class="w-5 h-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                        </svg>
                                                    </span>
                                                </div>
                                                <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                                                    <div>
                                                        <p class="text-sm text-gray-500">Vi hör av oss för att hjälpa dig komma igång med coaching och ta del av andra delar av erbjudandet!</p>
                                                    </div>
                                                    <div class="text-sm text-right text-gray-500 whitespace-nowrap">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </template>
            </card-basic>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        email: String,
        userId: String,
    },
    data: function () {
        return {
            userEmail: this.email,
            alternative: null,
            name: null,
            organisationNumber: null,
            streetName: null,
            postalCode: null,
            city: null,
            user_id: this.userId,
        };
    },
    methods: {
        alternativePick(choice) {
            this.alternative = choice;
        },
        selected(choice) {
            if (this.alternative == choice) {
                return 'border: 1px solid #9166a0 !important';
            } else {
                return '';
            }
        },
        submit() {
            const orderData = {
                order: {
                    alternative: this.alternative,
                    name: this.name,
                    organisationNumber: this.organisationNumber,
                    streetName: this.streetName,
                    postalCode: this.postalCode,
                    city: this.city,
                    user_id: this.user_id,
                    userEmail: this.userEmail
                }
            };
            axios.post(route('foundingmember.store'), orderData).then(function (response) {
                if( response.status === 200 ){
                    if( response.data.redirect ){
                        window.location = response.data.redirect;
                    }
                }
            })
        }
    },
    computed: {
    }
}
</script>

<style>
.selected_border {
    border: 1px solid #9166a0 !important;
}
</style>
