<template>
    <button-primary
        @click="toggleDone"
        :disabled="loading"
    >
        <span v-if="!completed">{{ _mft('course:markAsCompleted') }}</span>
        <span v-else>{{ _mft('course:markAsNotCompleted') }}</span>
    </button-primary>
</template>
<script>
export default {
    props: {
        chapterId: {
            type: Number,
            required: true
        },
        courseId: {
            type: Number,
            required: true
        },
    },
    data() {
        return {
            completed: false,
            loading: false,
        }
    },
    methods: {
        async loadCompletionStatus() {
            this.loading = true;
            const res = await axios.get(
                route(
                    'courses.chapters.completion',
                    [this.courseId, this.chapterId]
                )
            );

            this.completed = res.data.completed;
            this.loading = false;
        },
        async toggleDone() {
            this.loading = true;
            const res = await axios.post(
                route(
                    'courses.chapters.toggleCompletion',
                    [this.courseId, this.chapterId]
                ),
                { completed: !this.completed }
            );

            this.completed = res.data.completed;
            this.loading = false;
        }
    },
    mounted() {
        this.loadCompletionStatus();
    }
}
</script>
