<script setup>
    import {computed} from "vue";

    const props = defineProps({
        size: {
            type: String,
            default: 'md',
            options: ['xs', 'sm', 'md', 'lg', 'xl']
        },
    })

    const pillClasses = computed(() => {
        const sizes = {
            'line': 'rounded-full',
            'xs': 'px-1 py-0.5 text-xs rounded-md',
            'sm': 'px-2 py-1 text-xs rounded-md',
            'md': 'px-3 py-1.5 text-sm rounded-md',
            'lg': 'px-4 py-2 text-base rounded-md',
        }

        return [
            sizes[props.size],
        ]
    })

</script>

<template>
    <span :class="pillClasses">
        <slot></slot>
    </span>
</template>

<style scoped>

</style>
