<template>
    <div class="flex flex-row justify-center w-full align-center">
        <div class="max-w-3xl ml-4 mr-4 w-full">
            <h2
                class="mb-4 text-xl md:text-2xl lg:text-3xl"
                :class="'text-' + alignment"
            > {{title}}</h2>
            <p
                :class="'text-' + alignment"
                v-html="body"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: { type: String, required: true },
        body: { type: String, required: true },
        alignment: { type: String, required: true },
    },
};
</script>

<style scoped>
.imageStyling{
    height: 14rem;
    aspect-ratio: auto;
}
</style>
