<script setup>
import {computed, reactive, ref} from 'vue';
import FrostedBar from "../layout/pageHeadings/frostedBar.vue";

const props = defineProps({
    templates: {
        type: Object,
        required: false,
        default: []
    }
})

const expertContract = reactive({
    title: '',
});

const selectedTemplate = ref(null)
const selectedCreationOption = ref('empty')
const creationOptions = ref([
    { label: _mft('expertContract:starterTemplate.useEmpty'), value: 'empty', name: 'empty' },
    { label: _mft('expertContract:starterTemplate.useTemplate'), value: 'template', name: 'template' },
]);
const setCreationOption = (option) => {
    selectedCreationOption.value = option.value;
    if (option.value === 'empty') {
        selectedTemplate.value = null;
    }
};

const submit = async () => {
    if (!validates.value) {
        return;
    }
    const res = await axios.post(route('expert-contracts.store'), {
        title: expertContract.title,
        templateId: selectedTemplate.value,
    });

    window.location.href = res.data.redirect;
};

const validates = computed(() => {
    return expertContract.title.length > 0
})

</script>
<template>
    <div>
        <div class="md:sticky top-2 z-topbar">
            <frosted-bar>
                <div class="flex items-center justify-between space-x-4 f">
                    <h1 class="mb-3">{{ _mft('expertContract:create.title') }}</h1>

                    <div class="flex items-center space-x-4">
                        <div class="flex justify-end">
                            <button-primary
                                :disabled="!validates"
                                @click="submit"
                                type="submit"
                            >
                                {{ _mft('expertContract:createButton') }}
                            </button-primary>
                        </div>
                    </div>
                </div>
            </frosted-bar>
        </div>
        <div class="mt-12 xl:mt-24 mb-12 max-w-3xl mx-auto">
            <input-text
                name="title"
                :label="_mft('expertContract:title')"
                v-model:content="expertContract.title"
                required
            ></input-text>

            <button-bar
                :options="creationOptions"
                :selectedOptionName="'empty'"
                @select="setCreationOption"
            />

        </div>

        <div>
            <templates-list
                v-if="selectedCreationOption === 'template'"
                :show-empty-default="false"
                :read-only="true"
                :selected-template-id="selectedTemplate"
                preset-category="agreement"
                @selectTemplate="selectedTemplate = $event.id"
            ></templates-list>
        </div>
    </div>
</template>
