<script setup>
import IconPlus from "./icons/iconPlus.vue";
import IconMinus from "./icons/iconMinus.vue";
import {onMounted, ref, watch} from "vue";

const emit = defineEmits(['colorSelected', 'clearColor'])

const props = defineProps({
    incomingColor: {
        type: String,
        required: false,
        default: null
    },
    showClearButton: {
        type: Boolean,
        required: false,
        default: false
    }
})

const colors = ref([])

const useColor = (color) => {
    emit('colorSelected', color)
}

const addColor = (color) => {
    colors.value.push(color)
}

const removeColor = (index) => {
    colors.value.splice(index, 1)
}

const addIncomingColor = () => {
    colors.value.push(props.incomingColor)
}

const clearColor = () => {
    emit('clearColor')
}

const loadColorPalette = async () => {
    const res = await axios.get(route('settings.appearance.getColorPalette'));
    colors.value = res.data.colorPalette !== null ? res.data.colorPalette : []
}

const storeColorPalette = async () => {
    await axios.post(route('settings.appearance.storeColorPalette'), {
        colorPalette: colors.value
    });
}

onMounted(() => {
    loadColorPalette()
})

watch(() => colors , (newVal) => {
    storeColorPalette()
}, {deep: true})

defineExpose({addColor})
</script>

<template>
    <div>
        <div class="flex flex-wrap">
            <div
                v-for="(color, i) in colors"
                :key="i"
                class="mr-2 mb-2 rounded-wl checkerboard"
            >
                <div
                    class="flex h-10 w-10 cursor-pointer items-end justify-end rounded-wl group"
                    :style="{backgroundColor: color}"
                    @click="useColor(color)"
                >
                    <div
                        class="hidden translate-x-1 translate-y-1 transform rounded-full p-1 bg-myflowRed group-hover:block"
                        @click="removeColor(i)"
                    >
                        <icon-minus class="h-3 w-3 text-white" />
                    </div>
                </div>
            </div>
            <div
                v-if="incomingColor !== null && !colors.includes(incomingColor)"
                class="mr-2 mb-2 flex h-10 w-10 cursor-pointer items-center justify-center border-8 bg-gray-200 transition-all rounded-wl group"
                :style="{borderColor: incomingColor}"
                @click="addIncomingColor"
            >
                <icon-plus class="h-6 w-6 text-gray-600 transition-colors group-hover:text-gray-500" />
            </div>

            <div
                v-if="showClearButton && incomingColor"
                class="flex h-10 w-10 cursor-pointer items-center justify-center border-2 border-gray-400 transition-all rounded-wl group hover:border-gray-500"
                @click="clearColor"
            >
                <icon-plus class="h-6 w-6 rotate-45 text-gray-600 transition-colors group-hover:text-gray-500" />
            </div>

        </div>
    </div>
</template>

<style scoped>
.checkerboard {
    background-image: linear-gradient(45deg, #808080 25%, transparent 25%), linear-gradient(-45deg, #808080 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #808080 75%), linear-gradient(-45deg, transparent 75%, #808080 75%);
    background-size: 20px 20px;
    background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}
</style>
