/*
Basic usage:

Get a String response from the server/cache
_mfPrefGet('pages-sort-order').then(data => {
    setSortOrder(data)
})
In _mfPrefGet you might also want to move all data loading logic that depends on the result of the promise
inside the .then() block, eg. loading an index view with a specific sort order or filter applied.

Set a String value in the server/cache
_mfPrefSet('pages-sort-order', 'updated_at:desc')

 */

import axios from "axios";

export async function _mfPrefGet(key) {
    const res = await axios.get(route('mf.pref.get', [key]));
    return res.data
}

export async function _mfPrefSet(key, value) {
    const res = await axios.post(route('mf.pref.set', [key]), {
        value: value
    });
    return res.data
}
