<script setup>

// $emit('update:content', $event.target.value)

import { onMounted, reactive, watch} from "vue";

const emit = defineEmits(['update:content'])

const props = defineProps({
    content: {
        type: String,
        required: false
    },
    label: {
        type: String,
        required: false
    },
    presets: {
        type: Array,
        required: false,
        default: () => []
    }
})

const explodedValues = reactive({
    top: null,
    right: null,
    bottom: null,
    left: null
})

const setPreset = (preset) => {
    explodedValues.top = preset.value[0]
    explodedValues.right = preset.value[1]
    explodedValues.bottom = preset.value[2]
    explodedValues.left = preset.value[3]
}

onMounted(() => {
    if (props.content) {
        const values = props.content.split(' ')

        for (let i = 0; i < values.length; i++) {
            values[i] = parseInt(values[i].replace(/\D/g,''))
        }

        if (values.length === 1) {
            explodedValues.top = values[0]
            explodedValues.right = values[0]
            explodedValues.bottom = values[0]
            explodedValues.left = values[0]
        }

        if (values.length === 2) {
            explodedValues.top = values[0]
            explodedValues.right = values[1]
            explodedValues.bottom = values[0]
            explodedValues.left = values[1]
        }

        if (values.length === 3) {
            explodedValues.top = values[0]
            explodedValues.right = values[1]
            explodedValues.bottom = values[2]
            explodedValues.left = values[1]
        }

        if (values.length === 4) {
            explodedValues.top = values[0]
            explodedValues.right = values[1]
            explodedValues.bottom = values[2]
            explodedValues.left = values[3]
        }
    }
})

watch(() => explodedValues, (newVal) => {
    for (let key in explodedValues) {
        if ( isNaN( explodedValues[key])) {
            explodedValues[key] = 0
        }
    }

    const _content = `${newVal.top}px ${newVal.right}px ${newVal.bottom}px ${newVal.left}px`
    emit('update:content', _content)
}, {deep: true})

</script>
<template>
    <div class="mb-4">
        <input-label
            :label="props.label"
        />
        <div class="grid grid-cols-4 gap-2">
            <input-number v-model:content="explodedValues.top" :label="_mft('form:inputCss.top.label')"></input-number>
            <input-number v-model:content="explodedValues.right" :label="_mft('form:inputCss.right.label')"></input-number>
            <input-number v-model:content="explodedValues.bottom" :label="_mft('form:inputCss.bottom.label')"></input-number>
            <input-number v-model:content="explodedValues.left" :label="_mft('form:inputCss.left.label')"></input-number>
        </div>

        <ul class="flex space-x-1">
            <li
                v-for="(preset, i) in props.presets"
                :key="i"
            >
                <button-base
                    size="sm"
                    :style-type="JSON.stringify([explodedValues.top, explodedValues.right, explodedValues.bottom, explodedValues.left]) === JSON.stringify(preset.value) ? 'primary' : 'secondary'"
                    @click="setPreset(preset)"
                >{{ preset.label }}</button-base>
            </li>
        </ul>

    </div>
</template>
