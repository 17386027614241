<script setup>
import {computed, onMounted, ref, watch} from "vue";

    const props = defineProps({
        currentPageId: {
            type: Number,
            required: false,
            default: null
        }
    })

    const emit = defineEmits(['update:id'])

    const candidatePages = ref([])
    const pageId = ref(null)

    const loadPageCandidates = async () => {
        const res = await axios.get(route('api.invoice-links.purchase-completed-page-candidates'))
        candidatePages.value = res.data.pages
    }

    const handleSelect = (selected) => {
        pageId.value = selected.id
        emit('update:id', pageId.value)
    }

    const currentPageObject = computed(() => {
        return candidatePages.value.find(page => page.id === pageId.value)
    })

    const placeholder = computed( () => {
        return currentPageObject.value ? currentPageObject.value.title : 'Search'
    })

    watch(props, () => {
        pageId.value = props.currentPageId
    })

    onMounted(() => {
        loadPageCandidates()
        pageId.value = props.currentPageId
    })

</script>

<template>
    <div>
        <input-label :label="_mft('invoiceLink:purchaseCompletedPage')" />
        <input-searchable
            :searchableItems="candidatePages"
            searchableField="title"
            labelField="title"
            :placeholder="placeholder"
            v-model:content="currentPageObject"
            @selected="handleSelect"
        ></input-searchable>

    </div>
</template>

<style scoped>

</style>
