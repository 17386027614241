<script setup>
import { onMounted, reactive, computed } from 'vue';
import { XCircleIcon } from "@heroicons/vue/solid"

const emits = defineEmits(['selfDestruct']);

const props = defineProps({
    message: {
        type: Object,
        required: true
    },
})

const state = reactive({
    isTransitionedIn: false,
    destructTimeout: null,
})

const statusClass = computed(() => {
    switch (props.message.type) {
        case 'success':
            return 'bg-myflowGreen';
            break;

        case 'warning':
            return 'bg-yellow-400';
            break;

        case 'error':
            return 'bg-red-500';
            break;

        case 'info':
            return 'bg-gray-400';
            break;

        default:
            return 'bg-gray-400';
            break;
    }
})

const visibleDuration = props.message.dismissAfter || 5000;

const countdownDuration = computed(() => {
    return visibleDuration - 400; // leave room for initial transition delay + two transitions
})

onMounted(() => {
    setTimeout(function(){
        state.isTransitionedIn = true;
    }, 50)

    if (props.message.dismissAfter === null) {
        return;
    }

    setTimeout(function(){
        state.isTransitionedIn = false;
    }, countdownDuration)

    state.destructTimeout = setTimeout(function() {
        state.isTransitionedIn = false;
        setTimeout(function(){
            emits('selfDestruct', props.message.id);
        }, 200);
    }, visibleDuration);
})

const dismiss = () => {
    clearTimeout(state.destructTimeout);
    state.isTransitionedIn = false;
    setTimeout(function(){
        emits('selfDestruct', props.message.id);
    }, 200);
}

const tagType = computed(() => {
    if (props.message.url) {
        return 'a';
    } else {
        return 'div';
    }
})

</script>
<template>
    <div
        class="relative pl-2 transition-all duration-150 shadow-lg rounded-card group"
        :class="[statusClass, state.isTransitionedIn ? 'translate-x-0 opacity-100' : 'translate-x-full opacity-0']"
    >
        <div class="p-4 text-sm bg-white w-96 rounded-card">
            <component
                :is="tagType"
                class="block"
                :href="props.message.url"
            >
                <div
                    v-if="props.message.title"
                    class="m-0 mr-6 overflow-hidden font-bold overflow-ellipsis whitespace-nowrap"
                >
                    {{ _mft(props.message.title) }}
                </div>
                <div
                    v-if="props.message.message"
                    @click.stop
                    class="m-0"
                    :class="props.message.message.length > 160 ? 'max-h-[80px] relative overflow-hidden' : ''"
                >
                    {{ _mft(props.message.message) }}
                    <div
                        v-if="props.message.message.length > 160"
                        class="absolute bottom-0 left-0 right-0 w-full h-5 bg-gradient-to-t from-white to-transparent"
                    ></div>
                </div>
            </component>
            <div class="flex mt-1 space-x-2 text-xs">
                <a
                    v-for="(action, i) in props.message.actions"
                    class="mb-1 underline"
                    :href="action.url"
                >{{ _mft(action.label) }}</a>
            </div>
        </div>

        <countdown-circle
            v-if="props.message.dismissAfter"
            class="absolute z-10 top-1 right-1"
            :ringClass="'bg-gray-400'"
            :backgroundClass="'bg-white'"
            :duration="countdownDuration + 200"
            :hubcapSizePercent="80"
            :size="24"
        />

        <button
            class="absolute z-20 text-gray-800 transition-opacity rounded-full cursor-pointer md:opacity-50 md:hover:opacity-100 top-1 right-1"
            @click="dismiss"
        >
            <XCircleIcon class="w-6 h-6" />
        </button>
    </div>
</template>
