<template>
    <div class="overflow-hidden">
        <h3 class="">
            {{ title }}
        </h3>
        <hr class="mt-2 mb-2">
        <div>
            <div v-if="!collapsed" v-html="body"></div>
            <div v-if="collapsed" v-html="preview"></div>
        </div>
        <p class="flex justify-end">
            <span v-if="!collapsed" class="cursor-pointer" v-on:click="collapseExpand"><i><u>{{ _mft('shared:expand.showLess') }}</u></i></span>
            <span v-if="collapsed" class="cursor-pointer read-more" v-on:click="collapseExpand"><i><u>{{ _mft('shared:expand.showMore') }}</u></i></span>
        </p>
    </div>
</template>

<style lang="scss" scoped>
</style>

<script>
export default {
    props: {
        title: { type: String, required: true },
        body: { type: String, required: true },
        preview: { type: String, default: ''}
    },
    data() {
        return {
            collapsed: true,
        };
    },
    async mounted() {
    },
    methods: {
        collapseExpand() {
            if(this.collapsed === true) {
                this.collapsed = false;
            } else {
                this.collapsed = true;
            }
        },
    },
    computed: {
    }
}
</script>
