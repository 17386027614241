<template>
    <div class="flex flex-row justify-center w-full align-center">
        <div class="ml-4 mr-4 max-w-7xl">
            <div class="flex flex-col justify-center w-auto max-w-3xl">
                <div class="flex justify-center w-auto h-auto mb-3 overflow-hidden rounded-custom shadow-custom">
                    <media-bank-entries-display
                        alt=""
                        :data="mediaBankEntryData"
                    />
                </div>
                <div>
                    <p class="text-xs text-center">{{text}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        mediaBankEntryData: { type: Object, required: true },
        text: { type: String, required: true },
    }
};
</script>
