<script setup>
import Tag from './_tag.vue';
import { reactive, onMounted, computed, watch } from 'vue';

const emit = defineEmits(['update:includedTags', 'update:excludedTags', 'update:setDateRange', 'update:recipientCount']);

const props = defineProps({
    preincludedTags: {
        type: Array,
        default: () => []
    },
    preexcludedTags: {
        type: Array,
        default: () => []
    },
    timeRangeApplicable: {
        type: Boolean,
        required: false,
        default: false
    },
    dateRange: {
        type: Array,
        required: false,
    },
    recipientCounts: {
        type: Object,
        required: false
    }
})

const state = reactive({
    tags: [],
    loading: false,
    loadingTagsData: false,
    includedTags: props.preincludedTags,
    excludedTags: props.preexcludedTags,
})

const selectedRecipientCount = computed(() => {
    return props.recipientCounts.total;
})

const includedTagIds = computed(() => {
    return state.includedTags.map(tag => tag.id);
})
const excludedTagIds = computed(() => {
    return state.excludedTags.map(tag => tag.id);
})

watch(includedTagIds, (newValue, oldValue) => {
    loadRecipientCount()
})

watch(excludedTagIds, (newValue, oldValue) => {
    loadRecipientCount()
})

const loadRecipientCount = async () => {
    state.loadingTagsData = true;
    const res = await axios.get(route('crm.leadtags.count'), {
        params: {
            included_tags: includedTagIds.value,
            excluded_tags: excludedTagIds.value,
            once_assigned_in_time_tange_start: state.onceAssignedInTimeRangeStart,
            once_assigned_in_time_tange_end: state.onceAssignedInTimeRangeEnd,
        }
    });

    emit('update:recipientCount', res.data);
    state.loadingTagsData = false;
}

const loadTags = async () => {
    state.loading = true;
    const response = await axios.get(route('crm.leadtags.index'));
    state.tags = response.data.tags;
    state.loading = false;

    state.includedTags = props.preincludedTags;
    state.excludedTags = props.preexcludedTags;
}

const setDaterange = (data) => {
    state.onceAssignedInTimeRangeStart = data[0];
    state.onceAssignedInTimeRangeEnd = data[1];
    emit('update:setDateRange', data);
}

const presetDatetime = computed(() => {
    if (props.dateRange) {
        return props.dateRange;
    }
    const date = new Date();
    const minutes = date.getMinutes();
    const minutesToAdd = 15 - (minutes % 15);

    const roundedDate = new Date(date.getTime() + minutesToAdd * 60000);
    return roundedDate;
})

const include = ((tag) => {
    state.includedTags.push(tag)
    emit('update:includedTags', state.includedTags);
})
const unInclude = ((unincludedTag) => {
    state.includedTags = state.includedTags.filter(tag => tag.id !== unincludedTag.id);
    emit('update:includedTags', state.includedTags);
})

const exclude = ((tag) => {
    state.excludedTags.push(tag)
    emit('update:excludedTags', state.excludedTags);
})
const unExclude = ((unexcludedTag) => {
    state.excludedTags = state.excludedTags.filter(tag => tag.id !== unexcludedTag.id);
    emit('update:excludedTags', state.excludedTags);
})

onMounted(() => {
    loadTags();
})

watch(props, async (newValue, oldValue) => {
    state.includedTags = newValue.preincludedTags;
    state.excludedTags = newValue.preexcludedTags;
})

</script>
<template>
    <div>
        <h2>
            <span>{{ _mft('communication:nRecipientsSelected', {count: selectedRecipientCount}) }}</span>
        </h2>
        <p
            v-if="timeRangeApplicable"
        >
            {{ _mft('communication:timeRangeApplicable.description') }}
        </p>
        <div class="space-y-4 lg:flex lg:space-x-8 lg:space-y-0">
            <div v-if="props.timeRangeApplicable">
                <input-label :label="_mft('communication:startEndDate')" />

                <input-datepicker
                    name="date"
                    :date="presetDatetime"
                    :minDate="null"
                    :rangeMode="true"
                    :showTime="false"
                    @update:date="setDaterange"
                    class="w-full mt-1"
                />
            </div>
            <div class="w-full">
                <p>
                    {{ _mft('communication:selectRecipientsByTags') }}
                </p>
                <p v-if="state.loading" class="py-2 italic">{{ _mft('communication:loadingTags') }}</p>
                <ul class="divide-y">
                    <li
                        v-if="!state.loading && state.tags.length === 0"
                        class="py-2 italic text-gray-500"
                    >
                        {{ _mft('communication:noTagsFound') }}
                    </li>
                    <li
                        v-for="tag in state.tags"
                        :key="tag.id"
                        class="items-center justify-between py-2 md:py-1 md:flex md:space-x-4"
                    >
                        <Tag
                            :tag="tag"
                            :isIncluded="includedTagIds.includes(tag.id)"
                            :isExcluded="excludedTagIds.includes(tag.id)"
                            :loading="state.loadingTagsData"
                            @include="include"
                            @uninclude="unInclude"
                            @exclude="exclude"
                            @unexclude="unExclude"
                        />
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
