<script setup>
    import { useNotificationStore } from '@/stores/NotificationStore.js'
    import { onMounted, ref, computed } from 'vue'
    import Location from '../session/location.vue';

    const notificationStore = useNotificationStore()
    const props = defineProps({
        sessionRoute: {
            type: String,
            required: true,
        },
    })

    const session = ref({})
    const notes = ref({})

    const loadSession = async () => {
        const response = await axios.get(props.sessionRoute)
        session.value = response.data.session
        notes.value = response.data.notes
    }

    const sessionLoaded = computed(() => {
        return Object.keys(session.value).length > 0
    })

    const cancelBooking = async () => {
        const response = await axios.post(route('coaching.client.session.cancel', session.value.booking.id))

        if (response.data.success) {
            notificationStore.addNotification({
                type: 'success',
                title: response.data.message,
                dismissAfter: 5000,
            })

            loadSession();
        }
    }

    onMounted(() => {
        loadSession()
    })

</script>
<template>
    <div>
        <div class="flex flex-col-reverse items-start justify-between md:flex-row md:space-x-8">
            <div>
                <h1>
                    {{ session.booking?.product.title }}
                </h1>
                <div>
                    {{ _mft('coaching:sessionWith') }} {{ session.booking?.product.provider.name }}
                </div>
            </div>
            <div v-if="session.booking">
                <date-time-box
                    v-if="session.booking.begins_at.date"
                    :startDate="session.booking.begins_at.date"
                    :endDate="session.booking.ends_at.date"
                    :startTime="session.booking.begins_at.time"
                    :endTime="session.booking.ends_at.time"
                    class="px-4 py-1"
                />
                <p v-else>
                    {{ _mft('coaching:session.notBooked') }}
                </p>

                <div
                    v-if="session.booking.is_unbookable"
                    class="text-center"
                >
                    <button-with-confirmation
                        @confirmed="cancelBooking"
                        modalClass="absolute -top-2 -right-2 p-2 bg-white rounded shadow-md z-20"
                        :buttonLabel="_mft('coaching:session.cancel')"
                        buttonClass="myflow-basic-button--danger whitespace-nowrap text-xs"
                        :confirmButtonLabel="_mft('coaching:session.cancel')"
                        confirmButtonClass="myflow-basic-button--danger whitespace-nowrap"
                        :confirm-text="_mft('coaching:session.youAreCancelling') + ' ' + session.booking.product.provider.name + ' ' + _mft('coaching:sesssion.cancelling.clientNameWillBeNotified')"
                    />
                </div>
            </div>
        </div>

        <div class="mb-4" v-if="session.booking">
            <Location
                v-if="session.booking.meeting_location"
                :location="session.booking.meeting_location"
                :embedMeeting="false"
            />

            <slot name="embed"></slot>

            <!-- {{ session.booking?.meeting_location }} -->
        </div>

        <div class="grid gap-8 lg:grid-cols-2">
            <coaching-booking-note-editor
                v-if="sessionLoaded"
                :label="_mft('coaching:note.private.label')"
                :booking-id="session.booking.id"
                :note-key="session.note_keys.private"
            ></coaching-booking-note-editor>

            <coaching-booking-note-viewer
                v-if="sessionLoaded"
                :label="_mft('coaching:note.fromYourCoach')"
                :booking-id="session.booking.id"
                :note-key="session.note_keys.shared"
            ></coaching-booking-note-viewer>
        </div>
    </div>
</template>
