<!-- This example requires Tailwind CSS v2.0+ -->
<template>

  <div class="flex flex-col h-full">
    <header class="relative z-40 flex items-center justify-between flex-none py-4 border-b border-gray-200">
      <h1 class="text-lg font-semibold text-gray-900">
        <time datetime="2022-12">{{ weeklabel }}</time>
      </h1>
      <div class="flex items-center">
        <div class="hidden md:ml-4 md:flex md:items-center">
          <div class="flex text-xs rounded-md shadow-md">
            <button
              class="px-3 py-2 text-black bg-white hover:text-myflowPurple-600 rounded-l-md"
              v-on:click="getPreviousWeek()"
            >Föregående vecka</button>
            <div class="w-px bg-gray-100"></div>
            <button
              class="px-3 py-2 text-black bg-white hover:text-myflowPurple-600 rounded-r-md"
              v-on:click="getNextWeek()"
            >Nästa vecka</button>
          </div>
        </div>
      </div>
    </header>
    <div ref="container" class="flex flex-col flex-auto overflow-auto">
      <div style="width: 165%" class="flex flex-col flex-none max-w-full sm:max-w-none md:max-w-full">
        <div ref="containerNav" class="sticky top-0 z-30 flex-none bg-white shadow ring-1 ring-black ring-opacity-5 sm:pr-8">
          <div class="grid grid-cols-7 text-sm leading-6 text-gray-500 sm:hidden">
            <button type="button" class="flex flex-col items-center pt-2 pb-3">
              M
              <span class="flex items-center justify-center w-8 h-8 mt-1 font-semibold text-gray-900">10</span>
            </button>
            <button type="button" class="flex flex-col items-center pt-2 pb-3">
              T
              <span class="flex items-center justify-center w-8 h-8 mt-1 font-semibold text-gray-900">11</span>
            </button>
            <button type="button" class="flex flex-col items-center pt-2 pb-3">
              W
              <span class="flex items-center justify-center w-8 h-8 mt-1 font-semibold text-white bg-indigo-600 rounded-full">12</span>
            </button>
            <button type="button" class="flex flex-col items-center pt-2 pb-3">
              T
              <span class="flex items-center justify-center w-8 h-8 mt-1 font-semibold text-gray-900">13</span>
            </button>
            <button type="button" class="flex flex-col items-center pt-2 pb-3">
              F
              <span class="flex items-center justify-center w-8 h-8 mt-1 font-semibold text-gray-900">14</span>
            </button>
            <button type="button" class="flex flex-col items-center pt-2 pb-3">
              S
              <span class="flex items-center justify-center w-8 h-8 mt-1 font-semibold text-gray-900">15</span>
            </button>
            <button type="button" class="flex flex-col items-center pt-2 pb-3">
              S
              <span class="flex items-center justify-center w-8 h-8 mt-1 font-semibold text-gray-900">16</span>
            </button>
          </div>

          <div class="hidden grid-cols-7 -mr-px text-sm leading-6 text-gray-500 border-r border-gray-100 divide-x divide-gray-100 sm:grid">
            <div class="col-end-1 w-14" />
            <div
              class="flex items-center justify-center py-3 text-center"
              v-for="(date, i) in dates"
              v-bind:key="i"
            >
              <span>
                <span class="items-center justify-center font-semibold text-gray-900">
                  {{ date.weekdaylabel }}<br>
                  {{ date.datelabel }}
                </span>
              </span>
            </div>
          </div>
        </div>
        <div v-on:scroll="userScrolled" style="max-height:80vh; overflow-y: scroll;" class="bg-white" ref="calendarGrid">
          <div class="flex flex-auto">
            <div class="sticky left-0 z-10 flex-none bg-white w-14 ring-1 ring-gray-100" />
            <div class="relative grid flex-auto grid-cols-1 grid-rows-1">
              <!-- Horizontal lines -->
              <div class="grid col-start-1 col-end-2 row-start-1 divide-y divide-gray-100" style="grid-template-rows: repeat(48, minmax(3.5rem, 1fr))">
                <div ref="containerOffset" class="row-end-1 h-7" />

                <template
                  v-for="hour in ['00','01','02','03','04','05','06','07','08','09','10','11','12','13','14','15','16','17','18','19','20','21','22','23']"
                  v-bind:key="hour"
                >
                  <div>
                    <div class="sticky left-0 z-20 -mt-2.5 -ml-14 w-14 pr-2 text-right text-xs leading-5 text-gray-400">{{ hour }}:00</div>
                  </div>
                  <div />
                </template>
              </div>

              <!-- Vertical lines -->
              <div class="hidden grid-cols-7 col-start-1 col-end-2 grid-rows-1 row-start-1 divide-x divide-gray-100 sm:grid sm:grid-cols-7">
                <div class="col-start-1 row-span-full" />
                <div class="col-start-2 row-span-full" />
                <div class="col-start-3 row-span-full" />
                <div class="col-start-4 row-span-full" />
                <div class="col-start-5 row-span-full" />
                <div class="col-start-6 row-span-full" />
                <div class="col-start-7 row-span-full" />
                <div class="w-8 col-start-8 row-span-full" />
              </div>

              <!-- Available slots -->

              <div
                class="grid grid-cols-1 col-start-1 col-end-2 row-start-1 sm:grid-cols-7 sm:pr-8"
              >
                <DayBookableSlots
                  v-for="(day, i) in weekDays"
                  v-bind:key="i"
                  :day="day"
                  :firstAvailableQuarter="firstAvailableQuarter"
                  @addSpan="addSpan"
                />
              </div>


              <!-- Spans -->
              <ol class="grid grid-cols-1 col-start-1 col-end-7 row-start-1 sm:grid-cols-7 sm:pr-8" style="grid-template-rows: 1.75rem repeat(288, minmax(0, 1fr)) auto">
                <li
                  v-for="(availableSpan, i) in availableSpans"
                  v-bind:key="i"
                  class="relative flex transition-all group"
                  :class="[ 'col-start-' + availableSpan.weekday ]"
                  :style="{gridRow: (((availableSpan.startQuarter - firstAvailableQuarter) + 1) * 3) - 1 + ' / span ' + ((availableSpan.endQuarter - availableSpan.startQuarter) * 3) }"

                >
                  <button
                    class="absolute z-30 flex items-center justify-center px-1 py-1 text-white bg-gray-700 rounded-full cursor-pointer top-2 right-2 hover:bg-gray-800 transition-colors"
                    v-on:click="removeSpan(availableSpan)"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                    </svg>
                  </button>

                  <button
                    class="absolute z-30 flex items-center justify-center px-1 py-1 text-white bg-gray-700 rounded-full cursor-pointer top-10 right-2 hover:bg-gray-800 transition-colors"
                    v-on:click="beginCloneSpan(availableSpan)"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z" />

                    </svg>
                  </button>

                  <div class="absolute flex flex-col px-2 overflow-y-auto text-xs leading-5 rounded-lg group inset-1 bg-myflowPurple-500">
                    <p class="order-1 mt-2 text-right text-green-700"></p>
                  </div>
                </li>

                <calendar-booking
                  v-for="(bookedSpan, i) in bookedSpans"
                  v-bind:key="i"
                  :bookedSpan="bookedSpan"
                  :firstAvailableQuarter="firstAvailableQuarter"
                ></calendar-booking>

              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
    <basic-slideout
      v-model:open="cloningOpen"
    >
      <template v-slot:header>
        <h1>Kopiera kalendertid</h1>
      </template>

      <template v-slot:body>
        <calendar-span-clone-modal
          v-if="cloningSpan === null ? false : true"
          :span="cloningSpan"
          @reload="loadSpans"
          @close="closeCloning"
        />
      </template>
    </basic-slideout>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue'
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, DotsHorizontalIcon } from '@heroicons/vue/solid'
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue'
import DayBookableSlots from './dayBookableSlots.vue'

export default {
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    DotsHorizontalIcon,
    DayBookableSlots
  },
  setup() {
    const container = ref(null)
    const containerNav = ref(null)
    const containerOffset = ref(null)

    return {
      container,
      containerNav,
      containerOffset,
    }
  },
  data() {
    return {
      bookableSpans: [],
      availableSpans: [],
      bookedSpans: [],
      cloningOpen: false,
      cloningSpan: null,
      firstAvailableQuarter: 0,
      date: '',
      dates: [],
      weekdays: ['Mån', 'Tis', 'Ons', 'Tor', 'Fre', 'Lör', 'Sön'],
      weeklabel: '',
      userHasScrolled: false
    }
  },
  methods: {
    addSpan(span){
      this.availableSpans.push(span)

      axios.post(route('calendar.addSpan'), {
        span: span
      })
    },
    async removeSpan(span){
      await axios.post(route('calendar.removeSpan'), {
        date: span.date,
        firstQuarter: span.firstQuarter,
        endQuarter: span.endQuarter
      })
      this.loadSpans()
    },
    beginCloneSpan(span){
      this.cloningOpen = true
      this.cloningSpan = span
    },
    closeCloning(){
      this.cloningOpen = false
      this.cloningSpan = null
    },
    getPreviousWeek(){
      this.date = this.backOneWeek()
      this.loadSpans()
    },
    getCurrentWeek(){
      let weekdayIncrement = this.getCurrentDate()
    },
    getNextWeek(){
      this.date = this.date = this.forwardOneWeek()
      this.loadSpans()
    },
    backOneWeek(){
      const now = new Date(this.date)
      let date = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7)
      return date
    },
    forwardOneWeek(){
      const now = new Date(this.date)
      let date = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 7)
      return date
    },
    getCurrentDate() {
      const now = new Date()
      this.date = new Date(now.getFullYear(), now.getMonth(), now.getDate() )
    },
    async loadSpans() {
      let date = this.date.toISOString().slice(0, 10)
      let response = await axios.get(route('calendar.getWeek', date))
      this.weeklabel = response.data.dates.weeklabel
      this.dates = response.data.dates.dates
      this.bookableSpans = response.data.dates.bookable_blocks
      this.availableSpans = response.data.dates.available_blocks
      this.bookedSpans = response.data.dates.booked_blocks
      this.scrollToStartOfBusinessDay()
    },
    userScrolled() {
      this.userHasScrolled = true
    },
    scrollToStartOfBusinessDay() {
      if( !this.userHasScrolled ){
        this.$refs['calendarGrid'].scroll(0, 800)
      }
    }
  },
  mounted(){
    this.date = this.getCurrentDate()
    this.getCurrentWeek()
    this.loadSpans()
    this.scrollToStartOfBusinessDay()
  },
  computed: {
    weekDays: function(){
      return this.dates
    },
    visibleSpans(){
      let self = this
      let visibleSpans = []
      return this.bookableSpans
    }
  }
}
</script>