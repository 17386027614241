<template>
    <div>
        <a
            v-on:click="openInSlideout"
            class="bg-white rounded-md font-medium text-myflowPurple-600 hover:text-myflowPurple-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-myflowPurple-500 "
        >Lägg till i fler team</a>

        <basic-slideout
            v-model:open="open"
        >
            <template v-slot:header>
                <h1>Lägg till i fler team</h1>
            </template>

            <template v-slot:body>
                <form @submit.prevent="submit">
                    <div class="mb-6">
                        <fieldset v-for="team in this.companyTeams" v-bind:key="team.id">
                            <div class="mt-2 border-b border-gray-200 divide-y divide-gray-200">
                                <div class="relative flex items-start py-4">
                                    <div class="min-w-0 flex-1 text-sm">
                                        <label class="font-medium text-gray-700 select-none">{{ team.name }}</label>
                                    </div>
                                    <div class="ml-3 flex items-center h-5">
                                        <input
                                            name="teamlist[]"
                                            type="checkbox"
                                            class="focus:ring-myflowPurple-500 h-4 w-4 text-myflowPurple-600 border-gray-300 rounded"
                                            :value="team.id.toString()"
                                            v-model="form.teamlist"
                                            :disabled="isDisabled(team.id)"
                                        >
                                    </div>
                                </div>
                            </div>
                        </fieldset>
                    </div>

                    <input-button
                        :label="submitLabel"
                        :busy="isSubmitting"
                        type="submit"
                        class="float-right"
                    />
                </form>
            </template>
        </basic-slideout>
    </div>
</template>
<script>
export default {
    props: {
        teams: [],
        user: Number,
        userteams: [],
    },
    data() {
        return {
            open: false,
            form: {
                teamlist: this.userteams,
                userid: this.user,
            },
            errors: {
                teamlist: '',
            },
            isSubmitting: false,
            submitLabel: 'Lägg till i team',
            companyTeams: this.teams,
        };
    },
    methods: {
        openInSlideout(){
            this.open = true;
        },
        closeSlideout(){
            this.open = false;
        },
        submit() {
            let self = this;
            this.isSubmitting = true;

            let previousLabel = this.submitLabel;
            this.submitLabel = 'Sparar...';

            var formData = new FormData();
            for ( const key in this.form ) {
                formData.append(key, this.form[key]);
            }

            axios.post(route('company.teams.userteams'), formData).then(function (response) {
                if( response.status === 200 ){
                    if( response.data.redirect ){
                        window.location = response.data.redirect;
                    } else {
                        self.isSubmitting = false;
                        self.submitLabel = previousLabel;
                    }
                }
            })
            this.isSubmitting = false;
            self.submitLabel = previousLabel;
        },
        checkedCheck(id) {
            this.form.teamlist.includes(id);
            console.error(this.form.teamlist.includes(id));
        },
        isDisabled(id) {
            if(this.form.teamlist.length == 1 && this.form.teamlist.toString().includes(id.toString())) {
                return true;
            } else {
                return false;
            }
        }
    },
    mounted: function(){
    }
};
</script>