<template>
    <footer class="bg-myflowPurple-400 w-full flex justify-center flex-wrap">
        <div class="flex flex-col w-full">
            <div class="w-screen flex justify-center">
                <div
                    style="max-width: 1300px"
                    class="w-screen flex justify-center"
                >
                    <div
                        class="md:flex flex flex-wrap justify-center w-full containerHeight items-center"
                    >
                        <div
                            class="md:flex text-white h-auto w-full text-xl p-10"
                        >
                            <div class="flex flex-col pr-10 items-start">
                                <form target="_blank" action="https://myflow.se/">
                                    <button type="submit" class="buttonStyling">Om oss</button>
                                </form>
                                <form target="_blank" action="mailto:info@myflow.se">
                                    <button type="submit" class="buttonStyling">
                                        Kontakta oss
                                    </button>
                                </form>
                                <form target="_blank" action="mailto:robert@myflow.se">
                                    <button type="submit" class="buttonStyling">
                                        Ansök om partnerskap
                                    </button>
                                </form>
                                <form target="_blank" action="https://myflow.se/press/">
                                    <button type="submit" class="buttonStyling">Press</button>
                                </form>
                            </div>
                            <div class="flex flex-col items-start mt-8 md:m-0">
                                <form target="_blank" action="https://helasverigesamlas.se/cookies/">
                                    <button class="buttonStyling">
                                        Cookie policy
                                    </button>
                                </form>
                                <form target="_blank" action="https://app.myflow.se/privacy">
                                    <button class="buttonStyling">
                                        Integritetspolicy
                                    </button>
                                </form>
                                <form target="_blank" action="https://app.myflow.se/terms">
                                    <button class="buttonStyling">
                                        Allmäna villkor
                                    </button>
                                </form>
                            </div>
                        </div>

                        <div class="flex w-1/2">
                            <div
                                class="flex justify-center items-center p-10 w-full text-4xl"
                            >
                            <form target="_blank" action="https://www.linkedin.com/company/myflowlearning/">
                                <button class="buttonIcon">
                                    <i
                                        class="fa-brands fa-linkedin iconsStyling"
                                    ></i>
                                </button>
                            </form>
                            <form target="_blank" action="https://www.instagram.com/myflow.se/">
                                <button class="buttonIcon">
                                    <i
                                        class="fa-brands fa-instagram iconsStyling"
                                    ></i>
                                </button>
                            </form>
                            <form target="_blank" action="https://www.facebook.com/myflowlearning">
                                <button class="buttonIcon">
                                    <i
                                        class="fa-brands fa-facebook iconsStyling"
                                    ></i>
                                </button>
                            </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bg-myflowPurple-dark flex justify-center">
                <div class="w-full flex justify-center">
                    <div
                        style="max-width: 1300px"
                        class="flex w-full justify-center"
                    >
                        <div class="w-full flex justify-start">
                            <p class="text-white p-10">
                                Alla rättigheter reserverade <br />
                                Copyright © 2022 myflow Learning AB
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    data() {
        return {};
    },
};
</script>

<style scoped>
.buttonStyling {
    padding-bottom: 15px;
    font-weight: bold;
    width: auto;
}
.buttonStyling:hover {
    color: #622587;
}
.buttonIcon {
    background-color: #622587;
    border-radius: 8px;
    margin: 4px;
}
.buttonIcon:hover {
    background-color: #713a85;
    opacity: 0.6;
    transition: 0.3s;
}
.containerHeight {
    min-height: 25rem;
}
.iconsStyling {
    color: white;
    margin: 10px;
    font-size: smaller;
}
</style>
