<template>
    <div class=" background-image flex justify-center">
        <div
            style="max-width: 1300px"
            class="p-10 h-full flex justify-center items-center z-0"
        >
            <div class="flex text-myflowPurple w-full h-auto">
                <div
                    class="lg:w-1/2 lg:text-left p-3 sm:p-4 lg:p-12 bg-white bg-opacity-75 w-auto h-auto text-center justify-center items-center content-center"
                >
                    <event-online-live-text-component
                        v-for="text in textSections"
                        v-bind:key="text.h2Text"
                        :text="text"
                    />
                </div>
            </div>
        </div>
    </div>
    <div class="bg-myflowPurple-700 p-10 pt-24 pb-0 flex justify-center">
        <div
        style="max-width: 1300px"
        class="pb-4 flex justify-start lg:justify-center md:justify-center w-full lg:p-16 flex-col bg-myflowPurple-dark rounded-2xl border border-white text-white drop-shadow-lg"
        >
        <h2 class="text-3xl p-4 lg:p-0 mb-4">Detta ingår</h2>
        
        <table
        class="lg:table-auto text-center w-full text-md md:text-xl lg:text-xl leading-5 lg:leading-10"
        >
        <thead>
            <tr class="border-b-2 border-white text-white">
                <th></th>
                <th>På plats</th>
                <th>Online</th>
            </tr>
        </thead>
        
        <tbody>
            <event-table-component
            v-for="tableRow in tableRows"
            v-bind:key="tableRow.event"
            :tableRow="tableRow"
            />
        </tbody>
    </table>
</div>



</div>
</template>

<script>
export default {
    data() {
        return {
            tableRows: [
                {
                    event: 'Se alla talare live',
                    online: 'fa-sharp fa-solid fa-check',
                    live: 'fa-sharp fa-solid fa-check',
                },
                {
                    event: 'Kika i efterhand',
                    online: 'fa-sharp fa-solid fa-check',
                    live: 'fa-sharp fa-solid fa-check',
                },
                {
                    event: 'myflow kunskapsplattform med 100+ experter',
                    online: 'fa-sharp fa-solid fa-check',
                    live: 'fa-sharp fa-solid fa-check',
                },
                {
                    event: 'Antal personer',
                    online: '',
                    onlineText: 'Alla i konferensrummet ;)',
                    liveText: '1',
                },
                {
                    event: 'Lunch',
                    online: '',
                    live: 'fa-sharp fa-solid fa-check',
                },
                {
                    event: 'Mastermind-workshop',
                    online: '',
                    live: 'fa-sharp fa-solid fa-check',
                },
                {
                    event: 'Nätverka',
                    online: '',
                    live: 'fa-sharp fa-solid fa-check',
                },
                {
                    event: 'AW + Bubbel',
                    online: '',
                    live: 'fa-sharp fa-solid fa-check',
                },
            ],
            textSections: [
                {
                    h2Text: 'Anmälan',
                    pText: 'Delta som det passar er, live eller online.',
                },
                {
                    h2Text: 'Live på plats',
                    pText: 'Exklusivt på The Park på söder i Stockholm. 2.990 kr ink. moms (per person). Begränsat antal platser, max 50 personer.',
                },
                {
                    h2Text: 'Online',
                    pText: 'Digital halvdagsbiljett, 1.490 kr ink. moms. Kika live eller i efterhand. Access till myflow - online learning ingår.',
                },
            ],
        };
    },
};
</script>

<style scoped>
.background-image {
    background-image: url(https://myflow.fra1.digitaloceanspaces.com/tpol/IMG_3132.jpg  );
    background-size: cover;
    background-position: center;
    height: 100vh;
    width: 100%;
}
.background-image::before {
    content: '';
    position: absolute;
    height: 100vh;
    width: 100%;
    backdrop-filter: blur(5px);
}
.height {
    height: 100vh;
}
</style>
