<script setup>
    import { useSlots, computed, reactive } from 'vue';

    const slots = useSlots();

    const state = reactive({
        colcount: 7,
        introExpanded: false,
        introLength: 0,
        lengthCutoff: 300
    });

    Object.keys(slots).forEach((slot) => {
        switch (slot) {
            case 'image':
                state.colcount++;
                break;

            case 'intro':
                state.introLength = slots[slot]()[0].props.innerHTML.length;
                break;
        }

    });

    const hasSlot = ((slotname) => {
        return slots[slotname] !== undefined;
    });

    const infoColSpanClass = computed(() => {
        if( hasSlot('intro') ) {
            return 'col-span-3 lg:col-span-2';
        } else {
            return 'col-span-3 lg:col-span-2';
        }
    })

    const descriptionColSpanClass = computed(() => {
        if( hasSlot('intro') ) {
            return 'col-span-4 lg:col-span-5';
        } else {
            return 'col-span-4 lg:col-span-5';
        }
    })

</script>
<template>
    <div>
        <slot name="teleporter"></slot>

        <div
            :class="hasSlot('intro') ? 'lg:grid lg:grid-cols-3' : 'block'"
        >
            <div
                v-if="hasSlot('intro')"
                class="px-5 pb-2 mb:pb-0 my-5 border-b md:border-b-0 md:border-r col-span-1"
            >
                <div
                    class="relative"
                    :class="state.introExpanded || state.introLength <= state.lengthCutoff ? 'max-h-none pb-8' : 'min-h-[4rem] max-h-[16rem] overflow-hidden'"
                >
                    <slot name="intro"></slot>
                    <div
                        v-if="slots.intro && state.introLength > state.lengthCutoff"
                        class="absolute left-0 right-0 flex justify-center bg-gradient-to-t from-white to-transparent"
                        :class="state.introExpanded ? '-bottom-1 md:-bottom-4' : 'bottom-0'"
                    >
                        <button
                            class="bg-white shadow-lg p-2 rounded-full transition-all"
                            :class="state.introExpanded ? 'transform rotate-45' : 'mb-4'"
                            @click="state.introExpanded = !state.introExpanded"
                        >
                            <svg
                                data-v-62654aac=""
                                xmlns="http://www.w3.org/2000/svg"
                                class="w-6 h-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path data-v-62654aac="" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4v16m8-8H4"></path>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <div
                :class="['grid-cols-' + state.colcount, hasSlot('intro') ? 'gap-2 pb-5 md:py-5' : 'gap-8']"
                class="px-5 pb-5 md:grid rounded-xl col-span-2"
            >
                <div
                    v-if="hasSlot('image')"
                    class="col-span-1 px-12 md:p-0"
                >
                    <slot name="image"></slot>
                </div>

                <div :class="descriptionColSpanClass" class="pr-4">
                    <div
                        class="grid-cols-5 gap-x-8 gap-y-2 md:grid rounded-xl"
                        :class="hasSlot('intro') ? 'pb-5 md:py-1' : ''"
                    >
                        <div class="col-span-5">
                            <div
                                v-if="hasSlot('title')"
                                class="col-span-5"
                                slotname="title"
                            >
                                <slot name="title">

                                </slot>
                            </div>
                            <div
                                slotname="bio"
                            >
                                <slot name="bio"></slot>
                            </div>
                        </div>

                        <div
                            class="col-span-5"
                            slotname="description"
                        >
                            <div class="max-w-2xl">
                                <slot name="description"></slot>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    :class="infoColSpanClass"
                    class="mt-3 md:mt-0"
                >
                    <slot name="info"></slot>
                </div>
            </div>
        </div>
        <div
            v-if="hasSlot('footer')"
            class="px-5"
        >
            <slot name="footer"></slot>
        </div>
    </div>
</template>
