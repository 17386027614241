<template>
    <div class="flex justify-center">
        <div style="max-width: 1300px;" class="flex flex-wrap justify-center w-10/12 mt-8 mb-8 md:mt-12 md:mb-12 lg:mt-24 lg:mb-24" :class="[expertData.flexSetting]">
                <div class="mb-12 overflow-hidden border-2 border-white rounded-full lg:h-full lg:m-0 h-24 w-24 lg:w-36 lg:h-36 lg:m-12">
                    <img loading="lazy" class="aspect-auto" :src="expertData.img" alt="expert image">
                </div>
                <div class="w-full h-auto mb-4 text-white lg:w-8/12">
                    <h3 class="text-4xl">{{expertData.introText}}</h3>
                    <section class="flex flex-col mt-4 mb-4 lg:flex-row">
                        <h3 class="text-2xl text-myflowYellow-500 lg:mr-8">{{expertData.name}}</h3>
                        <h3 class="text-xl ">{{expertData.expertise}}</h3>
                    </section>
                    <p class="mb-4 text-lg">{{expertData.expertInfo}}</p>
                    <p class="text-lg text-myflowYellow-700">{{expertData.expertIntro}}</p>
                </div>
            </div>
    </div>
</template>

<script>
export default {
    props: {
        expert: Object
    },
    data() {
        return {
            expertData: this.expert
        };
    },
};


</script>

<style scoped>

</style>
