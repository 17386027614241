<script setup>
import { reactive } from 'vue'

const props = defineProps({
    conversations: Object
})

const state = reactive({
    conversations: props.conversations,
    conversation: null
})

const loadConversation = (conversation) => {
    state.conversation = conversation
}

const updateUnreadCount = (count) => {
    if (count > 0) {
        state.conversations.forEach((conversation) => {
            if (conversation.key === state.conversation.key) {
                conversation.hasUnreadMessages = true;
            }
        });
    } else {
        state.conversations.forEach((conversation) => {
            if (conversation.key === state.conversation.key) {
                conversation.hasUnreadMessages = false;
            }
        });
    }
}

</script>
<template>
    <card-basic>
        <template #body>
            <div class="grid md:grid-cols-3 gap-4 items-stretch h-[75vh] md:h-[65vh]">
                <div
                    class="md:border-r pr-4 flex flex-col justify-stretch"
                    :class="state.conversation ? 'hidden md:block' : 'block'"
                >
                    <h3>{{ _mft('message:conversation.domainNamePlural') }}</h3>
                    <div
                        class="overflow-y-scroll h-full relative -ml-2"
                    >
                        <ul class="absolute inset-0">
                            <li
                                v-for="(conversation, i) in state.conversations"
                                :key="i"
                                @click="loadConversation(conversation)"
                                :class="conversation.key === state.conversation?.key ? 'bg-gray-100' : ''"
                                class="cursor-pointer px-2 py-2 rounded-lg relative my-1"
                            >
                                <div
                                    :class="conversation.hasUnreadMessages ? 'font-bold' : ''"
                                    class="pr-4"
                                >{{ conversation.title }}</div>
                                <div class="text-xs text-gray-400">{{ conversation.lastMessageAtPretty }}</div>
                                <div
                                    v-if="conversation.hasUnreadMessages"
                                    class="absolute top-1 right-1 bg-myflowPurple rounded-full w-3 h-3"
                                >
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div
                    class="col-span-2"
                    :class="state.conversation ? 'block' : 'hidden md:block'"
                >
                    <button
                        @click="state.conversation = null"
                        class="block md:hidden myflow-basic-button mb-1"
                    >&larr; {{ _mft('shared:navigation.back') }}</button>
                    <div
                        v-if="state.conversation"
                        class="relative h-full w-full pb-4 md:pb-0"
                    >
                        <message-conversation
                            :get-url="state.conversation.getUrl"
                            :post-url="state.conversation.postUrl"
                            :mark-as-read-url="state.conversation.markAsReadUrl"
                            :poll-for-new="true"
                            @unreadCountUpdate="updateUnreadCount"
                        ></message-conversation>
                    </div>
                    <!-- {{ state.conversation }} -->
                </div>
            </div>
        </template>
    </card-basic>
</template>
