<template>
    <div v-if="!isDone">
        <h5>Signering</h5>

        <template v-if="qrEnabled">
            <p>Använd BankID-appen för att scanna bilden nedan, eller <strong><a :href="autoStartUrl" target="_blank">öppna BankID på denna enhet.</a></strong></p>

            <div v-if="qrEnabled && currentQrToken">
                <vue-qr :text="currentQrToken" :size="380" :correctLevel="3"></vue-qr>
            </div>
        </template>
        <template v-else>
            <p><a :href="autoStartUrl" target="_blank">Öppna BankID på denna enhet.</a></p>
        </template>
    </div>
</template>

<style type="text/css" scoped>
    img {
        max-width: 100%;
        height: auto;
    }
</style>

<script>
import axios from 'axios';
import { v4 as uuid } from 'uuid';

export default {
    props: {
        // request is a `App\Http\ResourcesBankIdRequestResource`
        request: {
            type: Object,
            required: true
        }
    },
    data() {
        const qrTokens = [...this.request.qr_tokens];
        let redirectUrl = `${document.location}#${uuid()}`;
        return {
            orderRef: this.request.order_ref,
            autoStartUrl: `bankid:///?autostarttoken=${this.request.auto_start_token}&redirect=${encodeURIComponent(redirectUrl)}`,

            qrEnabled: this.request.enable_qr,
            qrTokens: qrTokens,
            currentQrToken: qrTokens && qrTokens.shift(),

            eventChannel: this.request.event_channel,
            completedEvent: this.request.completed_event,
            failedEvent: this.request.failed_event,

            statusUrl: this.request.status_url,

            isDone: false,
            echo: EchoFactory(),
            intervals: []
        };
    },
    async mounted() {
        if (this.eventChannel) {
            this.echo
                .private(this.eventChannel)
                .listen(this.completedEvent, this.onCompleted)
                .listen(this.failedEvent, this.onFailed);
        }

        this.intervals.push(setInterval(() => {
            const nextToken = this.qrTokens.shift();
            if (nextToken) {
                this.currentQrToken = nextToken;
            }
        }, 1000));

        this.intervals.push(setInterval(async () => {
            if (this.isDone) { return; }

            try {
                const response = await axios.get(this.statusUrl);

                if (response.data.is_completed) {
                    this.onCompleted(response.data);
                } else {
                    this.onFailed(response.data);
                }
            } catch (err) {
                if (err.response.status !== 404) { throw err; }
            }
        }, 5000));
    },
    beforeUnmount() {
        if (this.eventChannel) {
            this.echo.leave(this.eventChannel);
        }

        for (let interval in this.intervals) {
            clearInterval(interval);
        }
    },
    methods: {
        onCompleted(data) {
            if (this.isDone) { return; }

            if (data.order_ref == this.orderRef) {
                this.isDone = true;
                this.$emit('completed');
            }
        },
        onFailed(data) {
            if (this.isDone) { return; }

            if (data.order_ref == this.orderRef) {
                this.isDone = true;
                this.$emit('failed');
            }
        }
    }
};
</script>
