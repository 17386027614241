<script setup>
import {onMounted, ref} from "vue";

const props = defineProps({
    frontPageType: {
        type: String,
        required: true
    },
    label: {
        type: String,
        required: false,
        default: ''
    }
})

const storeSettingsState = ref('')
const frontPageId = ref(null)
const pages = ref([])

const storeFrontPage = async () => {
    if (frontPageId === null) {
        return false
    }
    storeSettingsState.value = 'waiting'
    const res = await axios.post(route('settings.frontpage.update', {type: props.frontPageType}), {
        front_page_type: props.frontPageType,
        front_page_id: frontPageId.value,
    })

    if(res.data.success !== true) {
        storeSettingsState.value = 'error'
    } else {
        storeSettingsState.value = 'success'
    }
}

const loadFrontpageSettings = async () => {
    const res = await axios.get(route('settings.frontpage.show', {type: props.frontPageType}))
    pages.value = res.data.pages
    frontPageId.value = res.data.front_page_id
}


onMounted(() => {
    loadFrontpageSettings()
})

</script>

<template>
    <div>
        <div class="flex justify-between items-center space-x-4">
            <div class="flex items-center justify-between space-x-4">
                <input-select
                    v-model:content="frontPageId"
                    :options="pages"
                    labelSpecification="title"
                    valueSpecification="id"
                    :label="props.label"
                />
            </div>
            <button-success
                @click="storeFrontPage"
                :disabled="frontPageId === null"
                :state="storeSettingsState"
            >{{ _mft('setting:saveSettings') }}</button-success>
        </div>
    </div>
</template>

<style scoped>

</style>
