<script setup>
import { onMounted, ref, computed, inject, watch } from 'vue';
import axios from 'axios';

const props = defineProps({
    answer: String,
    question: String,
    enabled: Boolean,
});

const yesterdaysQuestion = ref(props.yesterdayQuestion);
const answer = ref(props.answer);
const question = ref(props.question);
const enabled = ref(props.enabled);

</script>

<template>
    <div>
        <div
            v-if="enabled"
            class="mb-2"
        >
            <div class="text-xs text-gray-400 leading-2">{{ question }}</div>
            <div>{{ answer }}</div>
        </div>
    </div>
</template>
