<template>
    <div>
        <section class="text-center" style="filter:blur(2px)">
            <div class="grid grid-cols-7 text-xs leading-6 text-gray-500">
                <div>M</div>
                <div>T</div>
                <div>W</div>
                <div>T</div>
                <div>F</div>
                <div>S</div>
                <div>S</div>
            </div>
            <div class="grid grid-cols-7 gap-px mt-2 text-sm bg-gray-200 rounded-lg shadow isolate ring-1 ring-gray-200">
                <button type="button" class="bg-gray-50 py-1.5 text-gray-400 hover:bg-gray-100 focus:z-10">
                    <time datetime="2021-12-27" class="flex items-center justify-center mx-auto rounded-full h-7 w-7">27</time>
                </button>
                <button type="button" class="bg-gray-50 py-1.5 text-gray-400 hover:bg-gray-100 focus:z-10">
                    <time datetime="2021-12-28" class="flex items-center justify-center mx-auto rounded-full h-7 w-7">28</time>
                </button>
                <button type="button" class="bg-gray-50 py-1.5 text-gray-400 hover:bg-gray-100 focus:z-10">
                    <time datetime="2021-12-29" class="flex items-center justify-center mx-auto rounded-full h-7 w-7">29</time>
                </button>
                <button type="button" class="bg-gray-50 py-1.5 text-gray-400 hover:bg-gray-100 focus:z-10">
                    <time datetime="2021-12-30" class="flex items-center justify-center mx-auto rounded-full h-7 w-7">30</time>
                </button>
                <button type="button" class="bg-gray-50 py-1.5 text-gray-400 hover:bg-gray-100 focus:z-10">
                    <time datetime="2021-12-31" class="flex items-center justify-center mx-auto rounded-full h-7 w-7">31</time>
                </button>
                <button type="button" class="bg-white py-1.5 hover:bg-gray-100 focus:z-10">
                    <time class="flex items-center justify-center mx-auto rounded-full h-7 w-7">1</time>
                </button>
                <button type="button" class="rounded-tr-lg bg-white py-1.5 hover:bg-gray-100 focus:z-10">
                    <time class="flex items-center justify-center mx-auto rounded-full h-7 w-7">2</time>
                </button>
                <button type="button" class="bg-white py-1.5 hover:bg-gray-100 focus:z-10">
                    <time class="flex items-center justify-center mx-auto rounded-full h-7 w-7">3</time>
                </button>
                <button type="button" class="bg-white py-1.5 hover:bg-gray-100 focus:z-10">
                    <time class="flex items-center justify-center mx-auto rounded-full h-7 w-7">4</time>
                </button>
                <button type="button" class="bg-white py-1.5 hover:bg-gray-100 focus:z-10">
                    <time class="flex items-center justify-center mx-auto rounded-full h-7 w-7">5</time>
                </button>
                <button type="button" class="bg-white py-1.5 hover:bg-gray-100 focus:z-10">
                    <time class="flex items-center justify-center mx-auto rounded-full h-7 w-7">6</time>
                </button>
                <button type="button" class="bg-white py-1.5 hover:bg-gray-100 focus:z-10">
                    <time class="flex items-center justify-center mx-auto rounded-full h-7 w-7">7</time>
                </button>
                <button type="button" class="bg-white py-1.5 hover:bg-gray-100 focus:z-10">
                    <time class="flex items-center justify-center mx-auto rounded-full h-7 w-7">8</time>
                </button>
                <button type="button" class="bg-white py-1.5 hover:bg-gray-100 focus:z-10">
                    <time class="flex items-center justify-center mx-auto rounded-full h-7 w-7">9</time>
                </button>
                <button type="button" class="bg-white py-1.5 hover:bg-gray-100 focus:z-10">
                    <time class="flex items-center justify-center mx-auto rounded-full h-7 w-7">10</time>
                </button>
                <button type="button" class="bg-white py-1.5 hover:bg-gray-100 focus:z-10">
                    <time class="flex items-center justify-center mx-auto rounded-full h-7 w-7">11</time>
                </button>
                <button type="button" class="bg-white py-1.5 hover:bg-gray-100 focus:z-10">
                    <time class="flex items-center justify-center mx-auto rounded-full h-7 w-7">12</time>
                </button>
                <button type="button" class="bg-white py-1.5 hover:bg-gray-100 focus:z-10">
                    <time class="flex items-center justify-center mx-auto rounded-full h-7 w-7">13</time>
                </button>
                <button type="button" class="bg-white py-1.5 hover:bg-gray-100 focus:z-10">
                    <time class="flex items-center justify-center mx-auto rounded-full h-7 w-7">14</time>
                </button>
                <button type="button" class="bg-white py-1.5 hover:bg-gray-100 focus:z-10">
                    <time class="flex items-center justify-center mx-auto rounded-full h-7 w-7">15</time>
                </button>
                <button type="button" class="bg-white py-1.5 hover:bg-gray-100 focus:z-10">
                    <time class="flex items-center justify-center mx-auto rounded-full h-7 w-7">16</time>
                </button>
                <button type="button" class="bg-white py-1.5 hover:bg-gray-100 focus:z-10">
                    <time class="flex items-center justify-center mx-auto rounded-full h-7 w-7">17</time>
                </button>
                <button type="button" class="bg-white py-1.5 hover:bg-gray-100 focus:z-10">
                    <time class="flex items-center justify-center mx-auto rounded-full h-7 w-7">18</time>
                </button>
                <button type="button" class="bg-white py-1.5 hover:bg-gray-100 focus:z-10">
                    <time class="flex items-center justify-center mx-auto rounded-full h-7 w-7">19</time>
                </button>
                <button type="button" class="bg-white py-1.5 hover:bg-gray-100 focus:z-10">
                    <time class="flex items-center justify-center mx-auto rounded-full h-7 w-7">20</time>
                </button>
                <button type="button" class="bg-white py-1.5 hover:bg-gray-100 focus:z-10">
                    <time class="flex items-center justify-center mx-auto rounded-full h-7 w-7">21</time>
                </button>
                <button type="button" class="bg-white py-1.5 hover:bg-gray-100 focus:z-10">
                    <time class="flex items-center justify-center mx-auto rounded-full h-7 w-7">22</time>
                </button>
                <button type="button" class="bg-white py-1.5 hover:bg-gray-100 focus:z-10">
                    <time class="flex items-center justify-center mx-auto rounded-full h-7 w-7">23</time>
                </button>
                <button type="button" class="bg-white py-1.5 hover:bg-gray-100 focus:z-10">
                    <time class="flex items-center justify-center mx-auto rounded-full h-7 w-7">24</time>
                </button>
                <button type="button" class="bg-white py-1.5 hover:bg-gray-100 focus:z-10">
                    <time class="flex items-center justify-center mx-auto rounded-full h-7 w-7">25</time>
                </button>
                <button type="button" class="bg-white py-1.5 hover:bg-gray-100 focus:z-10">
                    <time class="flex items-center justify-center mx-auto rounded-full h-7 w-7">26</time>
                </button>
                <button type="button" class="bg-white py-1.5 hover:bg-gray-100 focus:z-10">
                    <time class="flex items-center justify-center mx-auto rounded-full h-7 w-7">27</time>
                </button>
                <button type="button" class="bg-white py-1.5 hover:bg-gray-100 focus:z-10">
                    <time class="flex items-center justify-center mx-auto rounded-full h-7 w-7">28</time>
                </button>
                <button type="button" class="bg-white py-1.5 hover:bg-gray-100 focus:z-10">
                    <time class="flex items-center justify-center mx-auto rounded-full h-7 w-7">29</time>
                </button>
                <button type="button" class="bg-white py-1.5 hover:bg-gray-100 focus:z-10">
                    <time class="flex items-center justify-center mx-auto rounded-full h-7 w-7">30</time>
                </button>
                <button type="button" class="rounded-bl-lg bg-white py-1.5 hover:bg-gray-100 focus:z-10">
                    <time class="flex items-center justify-center mx-auto rounded-full h-7 w-7">31</time>
                </button>
                <button type="button" class="bg-gray-50 py-1.5 text-gray-400 hover:bg-gray-100 focus:z-10">
                    <time class="flex items-center justify-center mx-auto rounded-full h-7 w-7">1</time>
                </button>
                <button type="button" class="bg-gray-50 py-1.5 text-gray-400 hover:bg-gray-100 focus:z-10">
                    <time class="flex items-center justify-center mx-auto rounded-full h-7 w-7">2</time>
                </button>
                <button type="button" class="bg-gray-50 py-1.5 text-gray-400 hover:bg-gray-100 focus:z-10">
                    <time class="flex items-center justify-center mx-auto rounded-full h-7 w-7">3</time>
                </button>
                <button type="button" class="bg-gray-50 py-1.5 text-gray-400 hover:bg-gray-100 focus:z-10">
                    <time class="flex items-center justify-center mx-auto rounded-full h-7 w-7">4</time>
                </button>
                <button type="button" class="bg-gray-50 py-1.5 text-gray-400 hover:bg-gray-100 focus:z-10">
                    <time class="flex items-center justify-center mx-auto rounded-full h-7 w-7">5</time>
                </button>
                <button type="button" class="rounded-br-lg bg-gray-50 py-1.5 text-gray-400 hover:bg-gray-100 focus:z-10">
                    <time class="flex items-center justify-center mx-auto rounded-full h-7 w-7">6</time>
                </button>
            </div>
        </section>
    </div>
</template>
