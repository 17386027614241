<template>
    <div class="">
        <div class="border-solid border-2 border-white rounded-full w-12 lg:w-24 lg:h-24 overflow-hidden">
               <img loading="lazy" :src="image.img" alt="expert image">
        </div>
    </div>
</template>

<script>
export default {
    props: {
        img: Object,
    },
    data() {
        return {
            image: this.img
        };
    },
};


</script>

<style scoped>

</style>
