<script setup>
import {computed, onMounted} from 'vue'

const props = defineProps({
    content: {
        type: Object,
        required: true,
    },
});

const url = computed(() => props.content?.url.trim());
const attributeAllow = computed(() => props.content?.attributeAllow.trim());
const phoneHeight = computed(() => props.content?.phoneHeight.trim());
const desktopHeight = computed(() => props.content?.desktopHeight.trim());

onMounted(() => {
});

</script>

<template>
    <iframe
        :src="url"
        width="100%"
        allowfullscreen
        content="text/html"
        class="border-0"
        :allow="attributeAllow"
    ></iframe>
</template>

<style>
    iframe {
        width: 100%;
        height: v-bind('phoneHeight');
    }

    @media screen and (min-width: 768px) {
        iframe {
            height: v-bind('desktopHeight');
        }
    }
</style>
