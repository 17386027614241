<script setup>
import { computed, onMounted, ref, reactive, watch } from 'vue';
import { loadStripe } from '@stripe/stripe-js';
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { CheckIcon } from '@heroicons/vue/outline'
import axios from 'axios'

const open = ref(false)
const selectedPaymentMethod = ref(null)
const waiting = ref(false)
const purchaseStatus = ref('pending');
const errorMessage = ref(null);

const setupIntentClientSecret = reactive({
    value: null,
})

const state = reactive({
    billingDetails: false,
    selectPaymentMethod: false,
    cardDetails: false,
    invoiceConfirm: false,
})

const availableMethods = reactive({
    free: false,
    card: false,
    invoice: false,
})

const paymentMethodMeta = reactive({
    invoice: {
        invoiceTermDays: null,
    }
})

const billingDetails = reactive({
    name: '',
    line1: '',
    line2: '',
    postal_code: '',
    city: '',
    country: '',
})

const props = defineProps({
    publicKey: String,
    buttonText: {
        type: String,
        default: 'Köp nu'
    },
    purchasableObjectId: {
        type: String,
        required: true
    },
    purchasableObjectType: {
        type: String,
        required: true
    },
    purchasableObjectPrice: {
        type: Number,
        required: false,
        default: null
    },
    purchasableObjectDisplayPrice: {
        type: Number,
        required: false,
        default: null
    },
    purchasableObjectName: {
        type: String,
        required: false,
        default: null
    },
    purchasableObjectSellerName: {
        type: String,
        required: true,
    },
    postPurchaseRedirect: {
        type: String,
        required: false,
        default: null
    },
});

const cardStyle = {
    style: {
        base: {
            color: '#32325d',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
                color: '#575d63'
            }
        },
        invalid: {
            color: '#a7175a',
            iconColor: '#a7175a'
        }
    }
};

const stripePromise = loadStripe(props.publicKey);
const stripe = ref(null);
const cardElement = ref(null);
const postPurchaseRedirect = ref(props.postPurchaseRedirect);

const stripeSetupIntent = async () => {

    const res = await axios.post(route('payment.setupIntentClientSecret'), {
        id: props.purchasableObjectId,
        type: props.purchasableObjectType
    });

    setupIntentClientSecret.value = res.data.client_secret;

    stripe.value = await stripePromise;
    const elements = stripe.value.elements();
    cardElement.value = elements.create('card', cardStyle);
    cardElement.value.mount('#card-element');
}

const checkPaymentProfile = async () => {
    waiting.value = true;
    const res = await axios.get(route('payment.checkPaymentProfile'));

    if(res.data.payment_profile == false) {
        state.billingDetails = true;
    }
    else {
        state.selectPaymentMethod = true;

        billingDetails.name = res.data.profile.billing_name
        billingDetails.line1 = res.data.profile.billing_address_1
        billingDetails.line2 = res.data.profile.billing_address_2
        billingDetails.postal_code = res.data.profile.billing_postal_code
        billingDetails.city = res.data.profile.billing_postal_city
        billingDetails.country = res.data.profile.billing_postal_country
    }
    waiting.value = false;
}

const checkPaymentMethodAvailability = async () => {
    waiting.value = true;
    const res = await axios.post(route('payment.checkPaymentMethodAvailability'), {
        id: props.purchasableObjectId,
        type: props.purchasableObjectType
    });

    availableMethods.free = res.data.free;
    availableMethods.card = res.data.card;
    availableMethods.invoice = res.data.invoice;

    paymentMethodMeta.invoice.invoiceTermDays = res.data.invoice_term_days;

    waiting.value = false;
}

const handleSubmitBillingDetails = async () => {
    // Should be within try/catch, but we'll do that later, you know, future us problems
    waiting.value = true;
    const res = await axios.post(route('payment.handleSubmitBillingDetails'), {
        id: props.purchasableObjectId,
        type: props.purchasableObjectType,
        name: billingDetails.name,
        line1: billingDetails.line1,
        line2: billingDetails.line2,
        postal_code: billingDetails.postal_code,
        city: billingDetails.city,
        country: billingDetails.country,
    });

    checkPaymentProfile(); // To see if we should show the payment method selection, or restart

    state.billingDetails = false;
    if (selectedPaymentMethod.value == 'invoice') {
        state.invoiceConfirm = true;
    }
    waiting.value = false;
}

const selectPaymentMethod = (method) => {
    selectedPaymentMethod.value = method
    purchaseStatus.value = 'pending'

    if(method == 'invoice') {
        state.invoiceConfirm = false;
        state.cardDetails = false;
        state.billingDetails = true;
    }
    else if(method == 'card') {
        state.invoiceConfirm = false;
        state.cardDetails = true;
        state.billingDetails = false;
        stripeSetupIntent();
    }
    else if(method == 'free') {
        handleSubmitConfirmation();
    }
}

const handleSubmitConfirmation = async () => {
    waiting.value = true;

    if(selectedPaymentMethod.value == 'card') {
        if (!stripe.value) {
            return;
        }

        const result = await stripe.value.confirmCardSetup(setupIntentClientSecret.value, {
            payment_method: {
                card: cardElement.value,
            },
        });

        const res = await axios.post(route('payment.confirmPurchase'), {
            id: props.purchasableObjectId,
            type: props.purchasableObjectType,
            payment_method: 'card',
        });

        if (res.data.status == 'success') {
            purchaseStatus.value = 'completed';
            postPurchaseRedirect.value = res.data.redirect_url;
        }
    }

    if(selectedPaymentMethod.value == 'invoice') {
        const res = await axios.post(route('payment.confirmPurchase'), {
            id: props.purchasableObjectId,
            type: props.purchasableObjectType,
            payment_method: 'invoice',
        });

        if (res.data.status == 'success') {
            purchaseStatus.value = 'completed';
            postPurchaseRedirect.value = res.data.redirect_url;
        }
    }

    if(selectedPaymentMethod.value == 'free') {
        const res = await axios.post(route('payment.confirmPurchase'), {
            id: props.purchasableObjectId,
            type: props.purchasableObjectType,
            payment_method: 'free',
        });

        if (res.data.status == 'success') {
            purchaseStatus.value = 'completed';
            postPurchaseRedirect.value = res.data.redirect_url;
        }
    }

    waiting.value = false;
};

const performPostPurchaseAction = () => {
    if (postPurchaseRedirect) {
        window.location.href = postPurchaseRedirect.value;
    } else {
        window.location.reload();
    }
}

const completePurchase = () => {
    purchaseStatus.value = 'completed'
}

const declinePurchase = () => {
    purchaseStatus.value = 'declined'
}

const paymentMethodAvailable = computed(() => {
    if (availableMethods.free || availableMethods.invoice || availableMethods.card) {
        return true;
    }
    return false;
})

const billingDetailsAreValid = computed(() => {
    let valid = true;

    if (!billingDetails) {
        return false;
    }

    if (billingDetails.name.length === 0) {
        valid = false;
    }
    if (billingDetails.line1.length === 0) {
        valid = false;
    }
    if (billingDetails.postal_code.length < 3) {
        valid = false;
    }
    if (billingDetails.city.length === 0) {
        valid = false;
    }
    if (billingDetails.country === '') {
        valid = false;
    }
    return valid;
});

const payNowButtonLabel = computed(() => {
    if (selectedPaymentMethod.value == 'invoice') {
        return 'Köp nu'
    }
    else if (selectedPaymentMethod.value == 'card') {
        return 'Köp nu'
    }
    else {
        return 'Välj betalningsmetod'
    }
})

const cancelButtonAvailable = computed(() => {
    if (purchaseStatus.value === 'completed') {
        return false;
    }
    return true;
})

const isFree = computed(() => {
    if (props.purchasableObjectPrice !== null && props.purchasableObjectPrice === 0) {
        return true;
    }
    return false;
})

const invoiceTermDays = computed(() => {
    if (paymentMethodMeta.invoice.invoiceTermDays ) {
        return paymentMethodMeta.invoice.invoiceTermDays;
    } else {
        return 30;
    }
})

watch(() => purchaseStatus.value, (newVal) => {
    if (newVal == 'completed') {
        selectedPaymentMethod.value = null;
        state.selectPaymentMethod = false;
        state.cardDetails = false;
        state.billingDetails = false;
        state.invoiceConfirm = false;
    }
    else if (newVal == 'declined') {
        selectedPaymentMethod.value = null;
        state.selectPaymentMethod = true;
        state.cardDetails = false;
        state.billingDetails = false;
        state.invoiceConfirm = false;
    }
    else if (newVal == 'pending') {
        errorMessage.value = null;
    }
})

onMounted(() => {
    checkPaymentProfile();
    checkPaymentMethodAvailability();
});

</script>

<template>
    <div>
        <button
            class="w-full myflow-basic-button"
            @click="open = true"
        >
            {{ props.buttonText }}
            <span v-if="purchasableObjectDisplayPrice"> för {{ purchasableObjectDisplayPrice }} kr</span>
        </button>
    </div>
    <TransitionRoot as="template" :show="open">
        <Dialog as="div" class="relative z-50">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <div class="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
            </TransitionChild>

            <div class="fixed inset-0 z-50 w-screen overflow-y-auto">
                <div class="flex items-end justify-center min-h-full p-4 text-center sm:items-center sm:p-0">
                    <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
                        <DialogPanel class="relative px-4 pt-5 pb-4 overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                            <div
                                v-if="waiting"
                                class="absolute inset-0 z-20 flex items-center justify-center bg-white/50"
                            >
                                <mf-spinner-medium class="w-24 h-12"></mf-spinner-medium>
                            </div>

                            <div
                                v-if="purchaseStatus === 'completed'"
                                class="text-center"
                            >
                                <h2>Köpet genomfört!</h2>
                                <p class="italic"></p>
                                <button
                                    class="relative z-40 w-full myflow-basic-button"
                                    @click="performPostPurchaseAction"
                                >Fortsätt</button>
                                <confetti-bomb />
                            </div>

                            <div
                                v-if="purchaseStatus === 'declined'"
                                class="text-center"
                            >
                                <h2>Köpet avvisades!</h2>
                            </div>

                            <div>
                                <div>
                                    <div class="mt-3 text-center sm:mt-5">
                                        <div class="mt-2">
                                            <div class="p-4 rounded-lg" v-if="state.selectPaymentMethod">
                                                <div
                                                    v-if="!paymentMethodAvailable"
                                                    class="py-4"
                                                >
                                                    <p>{{ props.purchasableObjectSellerName }} har inga betalmetoder aktiverade</p>
                                                </div>
                                                <div class="flex justify-center w-full space-x-4 text-center">
                                                    <button
                                                        v-if="availableMethods.free"
                                                        class="w-1/2 p-4 transition-colors border-2 rounded-lg"
                                                        :class="selectedPaymentMethod === 'free' ? 'border-black text-black' : 'hover:border-gray-400 text-gray-500 hover:text-gray-600'"
                                                        @click="selectPaymentMethod('free')"
                                                    >
                                                        <i class="text-4xl fa-light fa-gift"></i>
                                                        <div class="mt-1 leading-tight text-center text-gray-600">
                                                            <div>Kostnadsfritt</div>
                                                            <div class="text-xs"></div>
                                                        </div>
                                                    </button>
                                                    <button
                                                        v-if="!availableMethods.free && availableMethods.invoice"
                                                        class="w-1/2 p-4 transition-colors border-2 rounded-lg"
                                                        :class="selectedPaymentMethod === 'invoice' ? 'border-black text-black' : 'hover:border-gray-400 text-gray-500 hover:text-gray-600'"
                                                        @click="selectPaymentMethod('invoice')"
                                                    >
                                                        <i class="text-4xl fa-light fa-file-invoice"></i>
                                                        <div class="mt-1 leading-tight text-center text-gray-600">
                                                            <div>Faktura</div>
                                                            <div class="text-xs">{{ invoiceTermDays }} dagars betalningsvillkor</div>
                                                        </div>
                                                    </button>
                                                    <button
                                                        v-if="!availableMethods.free && availableMethods.card"
                                                        class="w-1/2 p-4 transition-colors border-2 rounded-lg"
                                                        :class="selectedPaymentMethod === 'card' ? 'border-black text-black' : 'hover:border-gray-400 text-gray-500 hover:text-gray-600'"
                                                        @click="selectPaymentMethod('card')"
                                                    >
                                                        <i class="text-4xl fa-light fa-credit-card"></i>
                                                        <div class="mt-1 leading-tight text-center text-gray-600">
                                                            <div>Kortbetalning</div>
                                                            <div class="text-xs">via Stripe</div>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>

                                            <p
                                                v-if="errorMessage"
                                                class="text-xs text-center text-red-500"
                                            >{{ errorMessage }}</p>

                                            <div class="p-4 rounded-lg" v-if="state.billingDetails">
                                                <h5 class="mb-4">Kunduppgifter</h5>

                                                <input-text
                                                    label="Namn på kvitto/faktura"
                                                    :required="true"
                                                    v-model:content="billingDetails.name"
                                                />
                                                <input-text
                                                    label="Adressrad 1"
                                                    :required="true"
                                                    v-model:content="billingDetails.line1"
                                                />
                                                <input-text
                                                    label="Adressrad 2"
                                                    :required="false"
                                                    v-model:content="billingDetails.line2"
                                                />
                                                <input-text
                                                    label="Postnummer"
                                                    :required="true"
                                                    v-model:content="billingDetails.postal_code"
                                                />
                                                <input-text
                                                    label="Stad"
                                                    :required="true"
                                                    v-model:content="billingDetails.city"
                                                />
                                                <input-language
                                                    label="Land"
                                                    :required="true"
                                                    v-model:content="billingDetails.country"
                                                />
                                            </div>

                                            <div class="px-4 pt-4 mt-4 mb-4 rounded-lg" v-if="state.cardDetails">
                                                <h5 class="mb-4 text-gray-600">Ange dina kortuppgifter</h5>
                                                <div id="card-element"></div><br>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:gap-3"
                                    :class="state.billingDetails || state.cardDetails ||(!state.billingDetails && state.invoiceConfirm) ? 'sm:grid-cols-2' : 'sm:grid-cols-1'"
                                >
                                    <div class="sm:col-start-2" v-if="state.billingDetails">
                                        <button
                                            v-if="billingDetailsAreValid"
                                            type="button"
                                            class="w-full myflow-basic-button"
                                            @click="handleSubmitBillingDetails"
                                        >Spara kunduppgifter</button>
                                        <button
                                            v-else
                                            type="button"
                                            class="w-full myflow-basic-button--state-disabled"
                                        >Fyll i alla uppgifter för att spara</button>
                                    </div>

                                    <div class="sm:col-start-2" v-if="state.cardDetails || (!state.billingDetails && state.invoiceConfirm)">
                                        <button
                                            class="w-full myflow-basic-button"
                                            @click="handleSubmitConfirmation"
                                        >{{ payNowButtonLabel }}</button>
                                    </div>

                                    <button
                                        v-if="cancelButtonAvailable"
                                        type="button"
                                        class="w-full mt-4 myflow-basic-button--secondary sm:col-start-1 sm:mt-0"
                                        @click="open = false"
                                    >Avbryt</button>
                                </div>
                            </div>
                            <div class="pt-4 mt-4 text-xs text-center text-gray-500 border-t">
                                Du kommer att köpa <i class="font-semibold">{{ props.purchasableObjectName ? props.purchasableObjectName : 'detta' }}</i> från <i class="font-semibold">{{ props.purchasableObjectSellerName }}</i>
                            </div>
                            <!-- <div class="pt-4 mt-4">
                                <p class="bold">Debug tools</p>
                                <p class="text-xs">
                                    {{ state }}
                                </p>
                                <div class="flex space-x-1">
                                    <button class="text-xs myflow-basic-button--secondary" @click="completePurchase">Lyckat köp</button>
                                    <button class="text-xs myflow-basic-button--secondary" @click="declinePurchase">Misslyckat köp</button>
                                </div>
                            </div> -->
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>

</template>
